import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBreadcrumbs } from './appBreadcrumbs';
import OrganizationLogo from './organizationLogo';
import { Box } from '../../1-primative';
import { AppBar, IconButton, Toolbar } from '../../2-component';
import EntityMenu from '../../3-pattern/entityMenu/entityMenu';
import { Notifications } from '../notifications/notifications';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { clientHostedLogout } from '../../../providers/CIAMProvider';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { NavigationStyles } from '../../../interfaces';
import Sidebar from './sidebar';
import { useLayoutTokens } from './layout.tokens';
import { useAuthContext } from '../../../providers/ovApolloProvider';

const Layout = () => {
  const { activeOrganization, userContext, closed } = useContext(UserContext);
  const { permissions } = usePermissions();
  const { appLogout } = useAuthContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();
  const { sys, comp } = useThemeTokens(useLayoutTokens());

  const logout = async () => {
    await appLogout();
    if (activeOrganization.clientHostedLoginRedirectUri && activeOrganization.clientHostedLoginURL) {
      await clientHostedLogout(activeOrganization.clientHostedLoginURL, activeOrganization.clientHostedLoginRedirectUri);
    }
  };

  const hasNotifications = permissions.includes('read:notification');

  // This it to make pages scroll to top after every navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const appBarMobileAndTabletColor = comp.layout.backgroundColor;
  const appBarDesktopColor = comp.layout.backgroundColor;
  let displayMenuBar = activeOrganization.displayMenuBar ?? true;

  if (!displayMenuBar && userContext.organization && activeOrganization.id !== userContext.organization.id) {
    // MenuBar should be disabled only when accessing from the corresponding org
    displayMenuBar = true;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {displayMenuBar === true && (
        <AppBar
          position="fixed"
            sx={{
              width: { md: '100%' },
              transition: 'all 0.3s',
              backgroundColor: {
                xs: appBarMobileAndTabletColor,
                md: appBarDesktopColor,
              },
            }}
          elevation={0}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={() => setMobileOpen(!mobileOpen)} size="small" sx={{
                color: comp.layout.textColor,
                mr: 1,
                display: {
                  xs: !isUnifiedClientExperience ? 'block' : 'none',
                  lg: 'none',
                },
              }}>
                <MenuIcon />
              </IconButton>
              <OrganizationLogo textColor={comp.layout.textColor} />
            </Box>
            <Box display='flex' justifyContent='end' alignItems='center'>
              {hasNotifications && (<Notifications color={comp.layout.textColor} />)}
              <EntityMenu logout={logout}/>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <Box
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          width: { md: '100%', width: '1vw' },
          maxWidth: {
            xs: 'calc(100% - 32px)',
            sm: 'calc(100% - 48px)',
          },
          backgroundColor: sys.color.background,
          marginTop: isUnifiedClientExperience && displayMenuBar === false ? 0 : 7,
        }}
      >
      {/* This is where actual component gets rendered (see appRoutes.tsx) */}
      <Box justifyContent='start' alignItems='center' sx={{ height: '100%' }} display='flex'>
        { !isUnifiedClientExperience && (<Sidebar drawerWidth={closed ? 48 : 240 } setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />)}
        <Box
          width='100%'
          sx={{
            transition: 'all 0.4s',
            ml: {
              xs: '0px',
              sm: '0px',
              md: '0px',
              lg: !isUnifiedClientExperience ? `${closed ? 48 : 240}px` : '0px',
            },
            maxWidth: {
              lg: `calc(100% - ${!isUnifiedClientExperience ? `${closed ? 48 : 240}px` : '0px'})`,
            },
          }}
        >
          { !isUnifiedClientExperience && (<Box mb={3} mt={1}><AppBreadcrumbs /></Box>) }
          { !displayMenuBar && (
            <Box display="flex" flexDirection="column" alignContent="center" flexWrap="wrap">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  height: '32px',
                  mb: 2,
                }}
              >
                <Box display='flex' justifyContent='end' alignItems='center' marginLeft='auto'>
                  {hasNotifications && (<Notifications color={comp.layout.textColor} />)}
                  <EntityMenu logout={logout} />
                </Box>
              </Box>
            </Box>
          )}
          <Outlet/>
        </Box>
      </Box>
    </Box>
  </Box>
  );
};

export default Layout;
