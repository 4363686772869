import { useContext, useState } from 'react';
import {
  LinearProgress, linearProgressClasses, styled,
} from '@mui/material';
import { isNil } from 'lodash/fp';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MemberTag } from 'pages/household/components/memberTag';
import { useHouseholdContext } from 'pages/household';
import { memberColors } from 'pages/household/components/memberColors';
import { Typography, Box } from '../../1-primative';
import {
  Tooltip, Card, CardContent,
} from '../../2-component';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Goal, GoalStates, Statistics } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { currencySymbol } from '../../../util/currency';
import { translateBackend } from '../../../assets/i18n/config';

export const BorderLinearProgress = styled(LinearProgress)(() => {
  const { sys } = useThemeTokens();

  return {
    height: 6,
    marginTop: '6px',
    borderRadius: sys.borderRadius.round,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: sys.color.primaryContainer,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: sys.color.primary,
    },
  };
});

interface MultiLinearProgressProps {
  maximum: number
  values: number[]
  names: string[]
  onHover: (index: number | null) => void
  marginTop?: string
}

const MultiLinearProgress = ({
  maximum, values, names, onHover, marginTop = '32px',
}: MultiLinearProgressProps) => {
  const height = 12;
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const { sys } = useThemeTokens();
  const totalValue = values.reduce((acc, present) => acc + present);
  const hasSurpassedTarget = totalValue > maximum;
  const totalToBeUsed = hasSurpassedTarget ? totalValue : maximum;
  return (
    <span style={{
      display: 'block',
      width: '100%',
      height,
      backgroundColor: sys.color.primaryContainer,
      borderRadius: height / 2,
      marginTop,
    }}>
      {maximum > 0 && values.map((value, index) => (
        <span
          key={index}
          onMouseOver={() => { setHoverIndex(index); onHover(index); }}
          onMouseOut={() => { setHoverIndex(null); onHover(null); }}
        >
          <span
            style={{
              display: 'inline-block',
              height,
              width: `${Math.min(100 * value / totalToBeUsed, 100)}%`,
              backgroundColor: memberColors(index).bar,
              borderRadius: height / 2,
            }}
          >
            {index === hoverIndex
              ? <Typography
                colorVariant='variant' fontWeight={500} fontSize="12px"
                style={{
                  display: 'inline',
                  position: 'relative',
                  bottom: '24px',
                  overflow: 'visible',
                  whiteSpace: 'nowrap',
                }}>
                {names[index]}
              </Typography>
              : <>&nbsp;</>
            }
          </span>
        </span>
      ))}
    </span>
  );
};

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const GoalItem = ({
  goal, tag, onlyUserId, objectId, enableTooltip = true, options,
}: {
  goal: Goal, tag?: Tag, onlyUserId?: string, objectId: string, enableTooltip?: boolean, options?: any,
}) => {
  const CARD_HEIGHT = '112px';
  const { t } = useTranslation(['client']);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { sys } = useThemeTokens();
  const navigate = useNavigate();
  const householdContext = useHouseholdContext();
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const awaitingSubAccountCount: number = goal.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  const useCustodianData = options.useExternalStatisticsEnabled ? options.useExternalStatisticsEnabled : false;

  function isValidSubAccount(subAccount: any) {
    const isCorrectOwner = onlyUserId === undefined || subAccount?.account?.user?.id === onlyUserId;
    const isActive = subAccount.state !== 'INACTIVE';
    return isCorrectOwner && isActive;
  }

  const numSubAccountsLinked = goal.subAccounts?.filter(isValidSubAccount).length ?? 0;

  const numExternalHoldingsLinked = goal.custodianStatistics?.holdings?.length ?? 0;

  /* For household views, split statistics by member */
  const onlyUserIndex = householdContext?.indexedMembers[`${onlyUserId}`];
  interface NameAndStats { name: string, statistics: Statistics }
  let orderedStatisticsByMember: NameAndStats[] = [];
  if (householdContext?.members && (goal as any).user0statistics) {
    orderedStatisticsByMember = householdContext?.members.map((member, index) => (
      { name: member.name, statistics: (goal as any)[`user${index}statistics`] }
    ));
  }

  const selectedStatistic = (useCustodianData ? goal.custodianStatistics : onlyUserIndex !== undefined ? orderedStatisticsByMember?.[onlyUserIndex]?.statistics : goal?.statistics);

  const marketValue = selectedStatistic?.marketValueCents ?? 0;

  let shownMarketValue = marketValue;
  let shownSimpleReturnAmount = selectedStatistic?.simpleReturnAmount ?? 0;
  let shownSimpleReturnPercent = selectedStatistic?.simpleReturnPercent ?? 0;
  if (hoverIndex !== null) {
    const partialStatistics: Statistics = orderedStatisticsByMember[hoverIndex].statistics;
    shownMarketValue = partialStatistics.marketValueCents ?? 0;
    shownSimpleReturnAmount = partialStatistics.simpleReturnAmount ?? 0;
    shownSimpleReturnPercent = partialStatistics.simpleReturnPercent ?? 0;
  }

  const targetValue = goal.targetAmountCents ?? 0;
  const percentageOfTarget = targetValue === 0 ? 0 : Math.min(marketValue / targetValue, 1.00);

  if (onlyUserIndex !== undefined) {
    orderedStatisticsByMember = orderedStatisticsByMember.filter((stats: NameAndStats, index: number) => index === onlyUserIndex);
  }
  return (
    <Card
      hover
      onClick={() => {
        sendAnalytic(ovAnalyticsEvents.homepageGoalSelect);
        navigate(`goal/${goal.id}`);
      }}
      sx={{
        height: CARD_HEIGHT,
        cursor: 'pointer',
        backgroundColor: goal.state === GoalStates.COMPLETED ? sys.color.disabled : sys.color.surface,
        padding: '4px 16px 12px',
        boxSizing: 'border-box',
      }}
    >
      <CardContent
        sx={{
          padding: '0px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        data-testid={`goal-${goal?.name}`}
      >
        <Box>
          <Box display="flex" justifyContent="end" sx={{
            position: 'relative', top: '6px', right: '-6px', height: '8px',
          }}>
            <Box display="flex" flexDirection="row" >
              {awaitingSubAccountCount > 0 && enableTooltip && (
                  <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
                    <Box
                        sx={{
                          height: '8px',
                          width: '8px',
                          borderRadius: '50%',
                          backgroundColor: sys.color.negative,
                        }}
                    />
                  </Tooltip>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <Typography variant='titleMedium' weight='bold' sx={{
                  color: goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : sys.color.onSurface,
                }}>{goal.name ? goal.name : t(`goalType.${goal.type}`)}</Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" mt={0.5}>
                {tag ? <MemberTag sequenceNumber={tag.sequenceNumber} name={tag.text} /> : (<Box height='16.5px' width={1} />)}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="end">
              <Typography variant='titleMedium' weight='bold' sx={{
                color: goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : sys.color.onSurface,
              }}>{formatMoneyValue(shownMarketValue, currencySymbol[goal?.statistics?.currency ?? CurrencyCodes.CAD])}</Typography>
              {(isNil(options.showSimpleRateOfReturn) || options.showSimpleRateOfReturn) && (<Typography variant='bodySmall'
               color={goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : (shownSimpleReturnAmount) >= 0 ? sys.color.positive : sys.color.negative}
              >
                {formatMoneyValue(shownSimpleReturnAmount, currencySymbol[goal?.statistics?.currency ?? CurrencyCodes.CAD])} {`(${formatPercentValue(shownSimpleReturnPercent)})`}
              </Typography>)}
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '6.5px' }}>
          {(isNil(options.showGoalCompletion) || options.showGoalCompletion) && (Object.keys(orderedStatisticsByMember).length < 2
            ? <BorderLinearProgress variant="determinate"
              value={percentageOfTarget * 100}
            />
            : <MultiLinearProgress
              maximum={targetValue}
              values={orderedStatisticsByMember.map((x) => x.statistics?.marketValueCents ?? 0)}
              names={orderedStatisticsByMember.map((x) => x.name)}
              onHover={setHoverIndex}
              marginTop='0px'
            />
          )}
          <Box display="flex" justifyContent={(isNil(options.showLinkedSubAccounts) || options.showLinkedSubAccounts || options.showExternalHoldings || isNil(options.showExternalHoldings))
            ? 'space-between' : 'flex-end'} marginTop={'5.5px'}>
            {(options.showLinkedSubAccounts === true || isNil(options.showLinkedSubAccounts)) && (
              <Typography
                variant="bodySmall"
                sx={{ color: goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : sys.color.onSurfaceVariant }}
              >
                {translateBackend(options.linkedSubAccounts)
                  ? `${numSubAccountsLinked} ${translateBackend(options.linkedSubAccounts)}`
                  : t('goalsSummary.subAccountsLinked', { count: numSubAccountsLinked })}
              </Typography>
            )}
            {(options.showExternalHoldings === true) && (
              <Typography
                variant="bodySmall"
                sx={{ color: goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : sys.color.onSurfaceVariant }}
              >
                {translateBackend(options.linkedExternalHoldings)
                  ? `${numExternalHoldingsLinked ?? 0} ${translateBackend(options.linkedExternalHoldings)}`
                  : t('goalsSummary.externalHoldingsLinked', { count: numExternalHoldingsLinked ?? 0 })}
              </Typography>
            )}
            {(isNil(options.showGoalCompletion) || options.showGoalCompletion)
            && (<Typography variant='bodySmall' sx={{ color: goal.state === GoalStates.COMPLETED ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
              {formatPercentValue(percentageOfTarget, 0)} {t('goalsSummary.ofTargetAmount')}
            </Typography>)}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GoalItem;
