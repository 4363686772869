import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Delete } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import { Box } from '../../../../1-primative';
import {
  Button,
  Dialog, DialogFooter, DialogTitle, IconButton, Table, TableCell, TableHeadCell, TableRow,
} from '../../../../2-component';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useLocalization } from '../../../../../util/useLocalization';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { NewTaxLot } from './newTaxLot';

const DELETE_TAX_LOT = gql`
  mutation deleteTaxLot($input: DeleteTaxLotInput!) {
    deleteTaxLot(input: $input) {
      success
    }
  }
`;

export interface TaxLot {
  id: string,
  date: string,
  quantity: number,
  priceCents: number,
}

export const TaxLotDialog = ({
  taxLots, open, setOpen, financialProduct, options, accountId, refetch,
}: {
  taxLots: TaxLot[], open: boolean, setOpen: (open: boolean) => void, financialProduct: any, options: any, accountId: string, refetch: () => void,
}) => {
  const [newOpen, setNewOpen] = useState(false);
  const [taxLotList, setTaxLotList] = useState(taxLots);
  const { t } = useTranslation(['client', 'shared']);
  const { localizedDate } = useLocalization();
  const { sys } = useThemeTokens();

  useEffect(() => {
    setTaxLotList(taxLots);
  }, [taxLots]);

  const [deleteTaxLot] = useMutation(DELETE_TAX_LOT);

  if (!financialProduct) return null;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('taxLotDetails')} - {translateBackend(financialProduct.translatedName)}
      </DialogTitle>
      <Table>
        <TableRow>
          <TableHeadCell>{t('taxLotTable.openDate')}</TableHeadCell>
          <TableHeadCell right>{t('taxLotTable.quantity')}</TableHeadCell>
          <TableHeadCell right>{t('taxLotTable.price')}</TableHeadCell>
          <TableHeadCell right>{t('taxLotTable.costBasis')}</TableHeadCell>
          <TableHeadCell right>{t('taxLotTable.marketValue')}</TableHeadCell>
          <TableHeadCell right>{t('taxLotTable.gainsLoss')}</TableHeadCell>
          {options.manageTaxLots && (
            <TableHeadCell></TableHeadCell>
          )}
        </TableRow>
        {taxLotList.length === 0 && (
          <TableRow>
            <TableCell colSpan={options.manageTaxLots ? 7 : 6} sx={{ textAlign: 'center' }}>{t('taxLotTable.noTaxLots')}</TableCell>
          </TableRow>
        )}
        {taxLotList.map((x: any) => (
          <TableRow key={x.id}>
            <TableCell>{localizedDate(x.date)}</TableCell>
            <TableCell number>{x.quantity}</TableCell>
            <TableCell number>{formatMoneyValue(x.priceCents)}</TableCell>
            <TableCell number>{formatMoneyValue(x.quantity * x.priceCents)}</TableCell>
            <TableCell number>{formatMoneyValue(x.quantity * financialProduct.currentPriceCents)}</TableCell>
            <TableCell
              number
              sx={{
                color: financialProduct.currentPriceCents === x.priceCents ? 'inherit' : financialProduct.currentPriceCents > x.priceCents ? sys.color.positive : sys.color.negative,
              }}
            >
              {formatMoneyValue(x.quantity * financialProduct.currentPriceCents - x.quantity * x.priceCents)} ({formatPercentValue(financialProduct.currentPriceCents / x.priceCents - 1)})
            </TableCell>
            {options.manageTaxLots && (
              <TableCell sx={{ textAlign: 'center' }}>
                <IconButton
                  onClick={() => {
                    deleteTaxLot({
                      variables: {
                        input: {
                          accountId,
                          financialProductId: financialProduct.id,
                          taxLotId: x.id,
                        },
                      },
                    });
                    setTaxLotList(taxLotList.filter((y: any) => y.id !== x.id));
                    refetch();
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
      {options.manageTaxLots && (
        <DialogFooter>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button label={t('shared:add')} leadingIcon={Add} onClick={() => setNewOpen(true)} />
          </Box>
          <NewTaxLot open={newOpen} setOpen={setNewOpen} onCreate={(newTaxLot: any) => {
            setTaxLotList([...taxLotList, newTaxLot]);
            refetch();
          }} accountId={accountId} financialProductId={financialProduct.id} />
        </DialogFooter>
      )}
    </Dialog>
  );
};
