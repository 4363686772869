/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Box, Skeleton, Typography,
} from '../../../1-primative';
import {
  Card,
  Pagination,
  Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { LedgerCell } from './components/ledgerCell';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';
import { calculateTableSplit, PAGE_HEIGHT, PrintContext } from '../../../5-page/viewClientReport/components/printReport';

const FETCH_TRANSACTIONS_QUERY = `query fetchTransactions($input: FetchTransactionsInput!) {
  fetchTransactions(input: $input) {
    transactions {
      id
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      financialProduct {
        id
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
      subAccount {
        id
        goal {
          name
          type
        }
      }
    }
    totalCount
  }
}`;
const FETCH_LEDGER = gql`${FETCH_TRANSACTIONS_QUERY}`;

const FETCH_CUSTODIAN_TRANSACTIONS_QUERY = `query fetchCustodianTransactions($input: FetchCustodianTransactionsInput!) {
  fetchCustodianTransactions(input: $input) {
    transactions {
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      custodianTransactionType
      financialProduct {
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalCount
  }
}`;
const FETCH_CUSTODIAN_LEDGER = gql`${FETCH_CUSTODIAN_TRANSACTIONS_QUERY}`;

const DEFAULT_TABLE = [
  {
    label: {
      en: 'Account',
      fr: 'Compte',
    },
    type: 'account',
  },
  {
    label: {
      en: 'Type',
      fr: 'Type',
    },
    type: 'type',
  },
  {
    label: {
      en: 'Currency',
      fr: '',
    },
    type: 'currency',
  },
  {
    label: {
      en: 'Cost Price',
      fr: 'Prix d\'achat',
    },
    type: 'costPrice',
  },
  {
    label: {
      en: 'Quantity',
      fr: 'Quantité',
    },
    type: 'quantity',
  },
  {
    label: {
      en: 'Value',
      fr: 'Valeur',
    },
    type: 'value',
  },
  {
    label: {
      en: 'Date',
      fr: 'Date',
    },
    type: 'date',
  },
];

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalIds: [objectId] };
    case PageObjectType.ACCOUNT:
      return { accountIds: [objectId] };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountIds: [objectId] };
    default:
      return {};
  }
};

export const Ledger = ({
  objectId, objectType, options, print = false, i = 0,
}: {
  objectId: string, objectType: PageObjectType, options: any, print?: boolean, i?: number,
}) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { timePeriod } = useClientReportContext();
  const [printRowSplit, setPrintRowSplit] = useState<number[]>([]);
  const [startOnNew, setStartOnNew] = useState(false);
  const [previousPageHeight, setPreviousPageHeight] = useState(0);
  const { setOptions, options: printOptions } = useContext(PrintContext);
  const [page, setPage] = useState<any>(1);
  const [pagination, setPagination] = useState<any>({
    offSet: 0, perPage: print ? 1000 : 15,
  });
  const [transactions, setTransactions] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const useCustodianData = [PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && options.useCustodianStatistics;

  const [fetchLedger, { data, loading }] = useLazyQuery(FETCH_LEDGER, {
    fetchPolicy: 'no-cache',
    onCompleted: (d: any) => {
      setTransactions(d.fetchTransactions.transactions);
      setTotalCount(Number(d.fetchTransactions.totalCount || 0));
    },
  });

  const [fetchCustodianLedger, { data: custodianData, loading: custodianLoading }] = useLazyQuery(FETCH_CUSTODIAN_LEDGER, {
    fetchPolicy: 'no-cache',
    onCompleted: (d: any) => {
      setTransactions(d.fetchCustodianTransactions.transactions);
      setTotalCount(Number(d.fetchCustodianTransactions.totalCount || 0));
    },
  });

  useEffect(() => {
    const heightLeft = (i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0));

    if (heightLeft !== previousPageHeight) {
      setPreviousPageHeight(heightLeft);
    }
  }, [printOptions, setPreviousPageHeight]);

  useEffect(() => {
    if (data || custodianData) {
      const copy = [...printOptions];
      // Title
      const titleHeight = 56;

      const rowSplit = calculateTableSplit({
        headerHeight: 48,
        rowHeight: 33,
        totalRows: data ? data.fetchTransactions.totalCount : custodianData.fetchCustodianTransactions.totalCount,
        footerHeight: 48,
        pageHeight: previousPageHeight - titleHeight,
      });

      setPrintRowSplit(rowSplit.splitRows);
      setStartOnNew(rowSplit.startOnNew);

      copy[i] = {
        heightLeftOnLastPage: rowSplit.heightLeft - 40, // bottom margin
        loading: false,
      };

      setOptions(copy);
    }
  }, [data, custodianData, previousPageHeight, setOptions]);

  useEffect(() => {
    const variables = {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          dateAfter: dayjs(timePeriod.startDate).format('YYYY-MM-DD'),
          dateBefore: dayjs(timePeriod.endDate).format('YYYY-MM-DD'),
        },
        pagination: {
          ...pagination, sortField: 'date', sortDesc: false,
        },
      },
    };
    if (useCustodianData) {
      fetchCustodianLedger({ variables });
    } else {
      fetchLedger({ variables });
    }
  }, [objectType, objectId, pagination, timePeriod]);

  useEffect(() => {
    setPagination({ offSet: (page - 1) * pagination.perPage, perPage: pagination.perPage });
  }, [page]);

  const LedgerTable = ({ trs }: { trs: any[] }) => (
    <Box sx={{ overflowX: 'auto' }}>
      <Table>
        <TableBody>
          <TableRow>
            { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
              <TableHeadCell key={x.type} isFirst={idx === 0} right={![
                'account', 'type', 'date',
                'currency', 'description', 'custodianType',
                'bookValue', 'security',
              ].includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
            ))}
          </TableRow>
          { (useCustodianData ? custodianLoading : loading) ? (
            <>
              {[...Array(15)].map((x, index) => (
                <TableRow key={index}>
                  { (options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                    <TableCell dense isFirst={idx === 0} key={idx}><Skeleton width='100%' height='16px' /></TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <>
              {trs.map((transaction: any) => (
                <TableRow key={transaction.id} hover>
                  { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                    <LedgerCell isFirst={idx === 0} key={x.key} transaction={transaction} type={x.type} view={objectType} />
                  ))}
                </TableRow>
              ))}
            </>
          )}
          {trs.length === 0 && !(useCustodianData ? custodianLoading : loading) && (
            <TableRow>
              <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );

  return (
    <>
      { startOnNew && i !== 0 && (<Box sx={{ pageBreakBefore: 'always', mt: '40px' }} />)}
      <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.title')}</Typography>
      {
        print
          ? printRowSplit.map((num, index) => (
            <Box sx={{ pageBreakBefore: index !== 0 ? 'always' : undefined, marginTop: index !== 0 ? '40px' : undefined }} key={index}>
              { index !== 0 && (
                <Typography variant='headingXSmall' mb={2}>
                  {options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.title')} {t('shared:continued')}
                </Typography>
              )}
              <Card sx={{ mt: 2 }} variant='outlined'>
                <LedgerTable trs={transactions.slice(index * num, (index + 1) * num)} />
              </Card>
            </Box>
          ))
          : (
            <Card sx={{ mt: 2 }}>
              <LedgerTable trs={transactions} />
              <Box display='flex' justifyContent='flex-end' sx={{ p: 1 }}>
                <Pagination
                  size='small'
                  page={page}
                  perPage={pagination.perPage}
                  count={Math.ceil(totalCount / pagination.perPage)}
                  onChange={(e, newPage) => setPage(newPage)}
                  onChangePerPage={(newPerPage) => setPagination({ ...pagination, perPage: newPerPage })}
                />
              </Box>
            </Card>
          )
      }
    </>
  );
};
