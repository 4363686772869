import { ConditionDocument } from '@onevesthq/ov-conditions';
import { Organization } from './organization';
import { TranslatedString } from './shared';

type ClientReportTemplateStates = 'DRAFT' | 'ACTIVE' | 'INACTIVE';

type ClientReportTemplateObjectTypes = 'USER' | 'ACCOUNT' | 'GOAL' | 'SUB_ACCOUNT' | 'CLIENT_GROUP';

type ClientReportFrequencies = 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY' | 'ONE_TIME';

type ClientReportComparisonOperators = 'EQUALS' | 'NOT_EQUALS' | 'INCLUDES' | 'NOT_INCLUDES' | 'IN' | 'NOT_IN' | 'GREATER_THAN' | 'LESS_THAN' | 'GREATER_THAN_AGO' | 'LESS_THAN_AGO';

export enum ClientReportTransitions {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  REVIEW = 'review',
  ARCHIVE = 'archive',
}
export interface ClientReportTriggerRule {
  field: string
  comparison: ClientReportComparisonOperators
  value: string
}

export interface ClientReportScheduler {
  frequency: ClientReportFrequencies
  dayOfMonthOrWeek: number
  enableNotification: boolean
  triggerRules: [ClientReportTriggerRule]
  oneTimeDateStart: Date
  oneTimeDateEnd: Date
}

export interface ClientReportTemplate {
  id: string
  state: ClientReportTemplateStates
  translatedName: TranslatedString
  translatedDescription: TranslatedString
  type: string
  s3key: TranslatedString
  objectType: ClientReportTemplateObjectTypes
  templateParameters: [string]
  scheduler: ClientReportScheduler
  organization: Organization
  reportType: 'HTML' | 'PAGE_CONFIGURATION'
  clientReportTemplatePageConfiguration: {
    id: string
    type: string
    widgets: {
      id: string
      type: string
      options: any
    }[]
    options: any
  }
  condition: ConditionDocument
}

export interface ReportVariableMeta {
  name: string,
  type: string,
  description: string
}
