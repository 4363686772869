import {
  TextField, ListItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import { UserContext } from '../../../providers/userContextProvider';

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

const NewOrganization = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['orgSettings']);
  const { activeOrganization } = useContext(UserContext);
  const [organization, setOrganization] = useState({
    name: '',
    subdomain: '',
    parentId: '',
  });

  const [createOrganization, { loading }] = useMutation(CREATE_ORGANIZATION, {
    variables: {
      input: organization,
    },
  });

  useEffect(() => {
    setOrganization({ ...organization, parentId: activeOrganization.id ?? '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  const updateField = (event: any) => {
    const key: 'name' | 'subdomain' = event.target.id;

    if (key === 'subdomain') {
      if (!event.target.value.match(' ')) {
        setOrganization({ ...organization, [key]: (event.target.value as string).toLowerCase() });
      }
    } else {
      setOrganization({ ...organization, [key]: event.target.value as string });
    }
  };

  const createOrg = async (event: any) => {
    await createOrganization();
    afterCreate();
  };

  return (
    <CreateNewModal loading={loading} title={t('newOrganizationModal.title')} onSubmit={createOrg} sx={{ float: 'right', m: 1 }}>
      <ListItem>
        <TextField label={t('organizationDetails.name')} sx={{ width: '100%' }} value={organization.name} onChange={updateField} id='name' />
      </ListItem>
      <ListItem>
        <TextField label={t('organizationDetails.subdomain')} sx={{ width: '100%' }} value={organization.subdomain} onChange={updateField} id='subdomain' />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={organization.parentId}
          label={t('organizationDetails.parent')}
          onChange={(event: any) => setOrganization({ ...organization, parentId: event.target.value as string })}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewOrganization;
