import {
  LinkOutlined, MailOutline, Phone, SvgIconComponent, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import {
  Avatar, Card, IconButton, TextField,
} from '../../2-component';
import { InlineImage } from '../../../pages/client/components/documents/inlineImage';
import { InlineImageLocal } from '../../../pages/client/components/documents/inlineImageLocal';
import { Divider } from '../../2-component/divider/divider';
import { formatPhoneNumber, parseLink } from '../../../util';
import useCopyToClipboard from '../../../util/useCopyToClipboard';

const AvatarChild = ({ options }: { options: any }): JSX.Element => {
  if (options?.attachment?.file) return (<InlineImageLocal file={options?.attachment?.file} style={inlineImageStyle} />);
  if (options?.attachment?.value) return (<InlineImage fileDocumentId={options?.attachment?.value} style={inlineImageStyle} />);
  if (options?.attachment) return (<InlineImage fileDocumentId={options?.attachment} style={inlineImageStyle} />);
  return (
    <Typography variant={'trimmedMedium'} sx={{ fontSize: 24 }}>
      {translateBackend(options?.name) ? translateBackend(options?.name).split('')[0].toLocaleUpperCase() : ''}
    </Typography>
  );
};

const ListTile = ({
  Icon, text, color, link, onClick,
} : {
  Icon: SvgIconComponent, text?: string, color?: string, link?: string, onClick?: () => void
}): JSX.Element => (
    <Box display='flex' alignItems='center' marginTop={1} onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
    }}>
      <Icon sx={{ fontSize: '20px', mr: 1 }} />
      <Typography variant='bodyLarge' sx={{ color }}>
        {!link && (text !== '' ? text : '...')}
        {link && (<a href={parseLink(link)} onClick={(event) => handleClick(event, link)} style={{ color: 'inherit', textDecoration: 'none' }}>{text}</a>)}
      </Typography>
    </Box>
);

const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
  event.preventDefault();
  event.stopPropagation();
  if (link.startsWith('mailto')) {
    window.location.href = link;
    return;
  }
  window.open(parseLink(link), '_blank', 'noopener,noreferrer');
};

export const AdvisorContactItem = ({ options, onChange }: { options: any, onChange: (organizationUserId: string, hide: boolean, order: number) => void }) => {
  const { sys } = useThemeTokens();
  const [copy] = useCopyToClipboard();

  return (
    <Card hover onClick={() => { copy(options?.link || options?.email); }} sx={{ cursor: 'pointer', backgroundColor: options?.hide ? sys.color.disabled : sys.color.surface }}>
      <Box data-testid='advisor-contact-item' p={'16px'} px={'24px'}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" paddingBottom={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {options?.displayAdvisorImageOrAvatar && (
              <Box mr={1}>
                <Avatar size='xlarge'>
                  <AvatarChild options={options} />
                </Avatar>
              </Box>
            )}
            <Box >
              {options?.showName && (<Typography variant='titleMedium' sx={{ color: sys.color.onSurface }}>{translateBackend(options?.name)}</Typography>)}
              {options?.showRole && (<Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>{translateBackend(options?.role)}</Typography>)}
            </Box>
          </Box>

          {(options?.organizationUserId && options?.allowEditing) && (
            <Box display="flex" justifyContent='end' alignItems="center">
                <TextField
                  size='small'
                  type='number'
                  onChange={(e: any) => onChange(options.organizationUserId, options.hide, e.target.value)}
                  value={options.order}
                  sx={{ width: '60px', mr: 1 }}
                  onClick={(e: any) => e.stopPropagation()}
                  data-testid="advisor-sort-order"
                />

                <IconButton data-testid="advisor-visibility" onClick={(e) => {
                  e.stopPropagation();
                  onChange(options.organizationUserId, !options?.hide, options.order);
                }}>
                  {options?.hide ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </Box>
          )}
        </Box>

        <Divider />
        <Box paddingTop={1} display='inline-flex' flexDirection='column'>
          {options?.showPhoneNumber && (
            <ListTile
              Icon={Phone}
              color={sys.color.onSurfaceVariant}
              text={formatPhoneNumber(options?.phoneNumber ?? '...')}
              onClick={() => copy(formatPhoneNumber(options?.phoneNumber))}
            />
          )}
          {options?.showEmail && (
            <ListTile
              Icon={MailOutline}
              color={sys.color.onSurfaceVariant}
              text={options?.email ?? '...'}
              link={options.email !== '' ? `mailto:${options.email}` : undefined}
              onClick={() => copy(options?.email)}
            />
          )}
          {options?.showLink && (
            <ListTile
              Icon={LinkOutlined}
              color={sys.color.onSurfaceVariant}
              text={(options?.displayNickName && translateBackend(options?.nickName)) || options?.link}
              link={options?.link}
              onClick={() => copy(options?.link)}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};

const inlineImageStyle: React.CSSProperties = {
  objectFit: 'cover',
  height: '48px',
  width: '48px',
};
