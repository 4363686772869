import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { SubStepTypes } from 'interfaces';
import { isEmpty } from 'lodash/fp';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { PersonsOfInterestVisual } from './personsOfInterest.visual';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER = (derivedCustomFields?: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        isOfficerOfPublicCompany
        isOwnerOfPublicCompany
        isMemberOfIiroc
        politicallyExposedForeignPerson
        politicallyExposedDomesticPerson
        closeAssociateOfPEP
        insiderSymbols
        ssSymbols
        isEntityRegulated
        regulatorName
        firmNumber
        isReportingIssuer
        issuerName
        issuerExchange
        issuerTicker
        isRegisteredWithCRA
        isNonProfit
        isForThirdParty
        charityRegistrationNumber
        receivesDonationsFromPublic
        isAFinancialEntity
        isAffiliateOfFinancialEntity
        instructionsFromFinancialEntity
        isPublicBody
        isLargeCorporation
        powerOfAttorneyGranted
        headOfInternationalOrganization
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const PersonsOfInterest = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const enabledCustomFields = getEnabledCustomFields(options);
  const [userData, setUserData] = useState<any>({
    isOfficerOfPublicCompany: null,
    isOwnerOfPublicCompany: null,
    isMemberOfIiroc: null,
    politicallyExposedForeignPerson: null,
    politicallyExposedDomesticPerson: null,
    closeAssociateOfPEP: null,
    insiderSymbols: null,
    ssSymbols: null,
    isEntityRegulated: null,
    regulatorName: null,
    firmNumber: null,
    isReportingIssuer: null,
    issuerName: null,
    issuerExchange: null,
    issuerTicker: null,
    isNonProfit: null,
    isRegisteredWithCRA: null,
    charityRegistrationNumber: null,
    receivesDonationsFromPublic: null,
    isForThirdParty: null,
    isAFinancialEntity: null,
    isAffiliateOfFinancialEntity: null,
    instructionsFromFinancialEntity: null,
    isPublicBody: null,
    isLargeCorporation: null,
    powerOfAttorneyGranted: null,
    headOfInternationalOrganization: null,
    customFields: [],
  });
  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.PERSONS_OF_INTEREST,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      await fetchUser({
        variables: {
          userId,
        },
        query: customFieldKeys ? FETCH_USER(customFieldKeys) : undefined,
      });
    },
  });

  const [fetchUser, { data, loading }] = useLazyQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: defaultdata } = useQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0,
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        ssSymbols: !userData.isOfficerOfPublicCompany && !userData.isOwnerOfPublicCompany ? [] : userData.ssSymbols,
        insiderSymbols: !userData.isMemberOfIiroc ? [] : userData.insiderSymbols,
        customFields: modifyUpdatingCustomFields(userData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
        userId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        isOfficerOfPublicCompany: data.fetchUser.user.isOfficerOfPublicCompany || false,
        isOwnerOfPublicCompany: data.fetchUser.user.isOwnerOfPublicCompany || false,
        isMemberOfIiroc: data.fetchUser.user.isMemberOfIiroc || false,
        politicallyExposedForeignPerson: data.fetchUser.user.politicallyExposedForeignPerson || false,
        politicallyExposedDomesticPerson: data.fetchUser.user.politicallyExposedDomesticPerson || false,
        closeAssociateOfPEP: data.fetchUser.user.closeAssociateOfPEP || false,
        insiderSymbols: data.fetchUser.user.insiderSymbols || undefined,
        ssSymbols: data.fetchUser.user.ssSymbols || undefined,
        isEntityRegulated: data.fetchUser.user.isEntityRegulated || false,
        regulatorName: data.fetchUser.user.regulatorName || undefined,
        firmNumber: data.fetchUser.user.firmNumber || undefined,
        isReportingIssuer: data.fetchUser.user.isReportingIssuer || false,
        issuerName: data.fetchUser.user.issuerName || undefined,
        issuerExchange: data.fetchUser.user.issuerExchange || undefined,
        issuerTicker: data.fetchUser.user.issuerTicker || undefined,
        isNonProfit: data.fetchUser.user.isNonProfit || false,
        isRegisteredWithCRA: data.fetchUser.user.isRegisteredWithCRA || false,
        charityRegistrationNumber: data.fetchUser.user.charityRegistrationNumber || undefined,
        receivesDonationsFromPublic: data.fetchUser.user.receivesDonationsFromPublic || false,
        isForThirdParty: data.fetchUser.user.isForThirdParty || false,
        isAFinancialEntity: data.fetchUser.user.isAFinancialEntity || false,
        isAffiliateOfFinancialEntity: data.fetchUser.user.isAffiliateOfFinancialEntity || false,
        instructionsFromFinancialEntity: data.fetchUser.user.instructionsFromFinancialEntity || false,
        isPublicBody: data.fetchUser.user.isPublicBody || false,
        isLargeCorporation: data.fetchUser.user.isLargeCorporation || false,
        powerOfAttorneyGranted: data.fetchUser.user.powerOfAttorneyGranted || false,
        headOfInternationalOrganization: data.fetchUser.user.headOfInternationalOrganization || false,
        customFields: data.fetchUser?.user?.customFields || [],
      });
    }
    if (defaultdata) {
      setUserData({
        isOfficerOfPublicCompany: defaultdata.fetchUser.user.isOfficerOfPublicCompany || false,
        isOwnerOfPublicCompany: defaultdata.fetchUser.user.isOwnerOfPublicCompany || false,
        isMemberOfIiroc: defaultdata.fetchUser.user.isMemberOfIiroc || false,
        politicallyExposedForeignPerson: defaultdata.fetchUser.user.politicallyExposedForeignPerson || false,
        politicallyExposedDomesticPerson: defaultdata.fetchUser.user.politicallyExposedDomesticPerson || false,
        closeAssociateOfPEP: defaultdata.fetchUser.user.closeAssociateOfPEP || false,
        insiderSymbols: defaultdata.fetchUser.user.insiderSymbols || undefined,
        ssSymbols: defaultdata.fetchUser.user.ssSymbols || undefined,
        isEntityRegulated: defaultdata.fetchUser.user.isEntityRegulated || false,
        regulatorName: defaultdata.fetchUser.user.regulatorName || undefined,
        firmNumber: defaultdata.fetchUser.user.firmNumber || undefined,
        isReportingIssuer: defaultdata.fetchUser.user.isReportingIssuer || false,
        issuerName: defaultdata.fetchUser.user.issuerName || undefined,
        issuerExchange: defaultdata.fetchUser.user.issuerExchange || undefined,
        issuerTicker: defaultdata.fetchUser.user.issuerTicker || undefined,
        isNonProfit: defaultdata.fetchUser.user.isNonProfit || false,
        isRegisteredWithCRA: defaultdata.fetchUser.user.isRegisteredWithCRA || false,
        charityRegistrationNumber: defaultdata.fetchUser.user.charityRegistrationNumber || undefined,
        receivesDonationsFromPublic: defaultdata.fetchUser.user.receivesDonationsFromPublic || false,
        isForThirdParty: defaultdata.fetchUser.user.isForThirdParty || false,
        isAFinancialEntity: defaultdata.fetchUser.user.isAFinancialEntity || false,
        isAffiliateOfFinancialEntity: defaultdata.fetchUser.user.isAffiliateOfFinancialEntity || false,
        instructionsFromFinancialEntity: defaultdata.fetchUser.user.instructionsFromFinancialEntity || false,
        isPublicBody: defaultdata.fetchUser.user.isPublicBody || false,
        isLargeCorporation: defaultdata.fetchUser.user.isLargeCorporation || false,
        powerOfAttorneyGranted: defaultdata.fetchUser.user.powerOfAttorneyGranted || false,
        headOfInternationalOrganization: defaultdata.fetchUser.user.headOfInternationalOrganization || false,
        customFields: defaultdata.fetchUser?.user?.customFields || [],
      });
    }
  }, [data, defaultdata]);

  return (
    <PersonsOfInterestVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading || customFieldLoading}
      activeCustomFields={derivedCustomFieldsKeys}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default PersonsOfInterest;
