import { ConditionDocument } from '@onevesthq/ov-conditions';
import { Organization } from './organization';
import { TranslatedString } from './shared';

export enum WorkflowObjectTypes {
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  GOAL = 'GOAL',
}

export enum TriggerType {
  MANUAL = 'MANUAL',
  EVENT = 'EVENT',
}

export enum TriggerRuleComparisonTypes {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_AGO = 'GREATER_THAN_AGO',
  LESS_THAN_AGO = 'LESS_THAN_AGO',
}

export interface TriggerRule {
  field: string,
  comparison: TriggerRuleComparisonTypes,
  value: any,
}

export enum SubStepTypes {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  NON_INDIVIDUAL_INFORMATION = 'NON_INDIVIDUAL_INFORMATION',
  RELATIONSHIP_INFORMATION = 'RELATIONSHIP_INFORMATION',
  EMPLOYMENT_INFORMATION = 'EMPLOYMENT_INFORMATION',
  RESIDENCY_INFORMATION = 'RESIDENCY_INFORMATION',
  PERSONS_OF_INTEREST = 'PERSONS_OF_INTEREST',
  INCOME_ASSETS_AND_DEBTS = 'INCOME_ASSETS_AND_DEBTS',
  INVESTMENT_KNOWLEDGE = 'INVESTMENT_KNOWLEDGE',
  RISK_QUESTION_1 = 'RISK_QUESTION_1',
  RISK_QUESTION_2 = 'RISK_QUESTION_2',
  ID_VERIFICATION = 'ID_VERIFICATION',
  APPROVAL = 'APPROVAL',
  DOCUMENTS = 'DOCUMENTS',
  ACCOUNTS = 'ACCOUNTS',
  GOALS = 'GOALS',
  SUB_ACCOUNTS = 'SUB_ACCOUNTS',
  TRANSFERS = 'TRANSFERS',
  HOUSEHOLD = 'HOUSEHOLD',
  ACTION_NOTIFY = 'ACTION_NOTIFY',
  ACTION_UPDATE_USER = 'ACTION_UPDATE_USER',
  ACTION_UPDATE_ACCOUNT = 'ACTION_UPDATE_ACCOUNT',
  ACTION_ACTIVATE_ALL_SUB_ACCOUNTS = 'ACTION_ACTIVATE_ALL_SUB_ACCOUNTS',
  PROJECTIONS = 'PROJECTIONS',
  EDIT_ACCOUNT = 'EDIT_ACCOUNT',
  EDIT_GOAL = 'EDIT_GOAL',
  REVIEW = 'REVIEW',
  DEPOSIT_TYPE = 'DEPOSIT_TYPE',
  DEPOSIT_FORM = 'DEPOSIT_FORM',
  DEPOSIT_REVIEW = 'DEPOSIT_REVIEW',
  RISK_PROFILE = 'RISK_PROFILE',
}

export enum CompleteableBys {
  USER = 'USER',
  ORGANIZATION_USER = 'ORGANIZATION_USER',
}

export interface WorkflowBranch {
  id?: string,
  condition?: ConditionDocument,
  subStepKey?: string,
  contextObject?: null | 'ACCOUNT' | 'GOAL',
}

export interface NodePosition {
  x: number,
  y: number,
}

export interface SubStep {
  id?: string,
  key?: string,
  type?: SubStepTypes,
  options?: any,
  skippable?: boolean,
  canGoBack?: boolean,
  completeableBy?: CompleteableBys[],
  rolesCompleteableBy?: string,
  branches?: WorkflowBranch[],
  nodePosition?: NodePosition,
  createdAt?: Date,
  updatedAt?: Date,
}

export interface Step {
  id?: string,
  name?: TranslatedString,
  subSteps?: SubStep[],
  branches?: WorkflowBranch[],
  startNodePosition?: NodePosition,
  endNodePosition?: NodePosition,
  createdAt?: Date,
  updatedAt?: Date,
}

export interface Workflow {
  id?: string,
  name?: TranslatedString,
  objectType?: WorkflowObjectTypes,
  triggerType?: TriggerType,
  triggerRules?: TriggerRule[],
  eventType?: string,
  steps?: Step[],
  version?: number,
  createdAt?: Date,
  updatedAt?: Date,
  organization?: Organization,
  state?: WorkflowStates,
  complex?: boolean,
}

export enum WorkflowStates {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface WorkflowTransition {
  name: string,
  from: WorkflowStates[]
}

export const workflowTransitions: WorkflowTransition[] = [
  {
    name: 'activate',
    from: [WorkflowStates.DRAFT, WorkflowStates.ARCHIVED],
  },
  {
    name: 'draft',
    from: [WorkflowStates.ACTIVE, WorkflowStates.ARCHIVED],
  },
  {
    name: 'archive',
    from: [WorkflowStates.DRAFT, WorkflowStates.ACTIVE],
  },
];

export enum WorkflowCompletionStates {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export interface WorkflowCompletion {
  id?: string,
  context?: any,
  createdAt?: Date,
  currentStep?: Step
  currentStepId?: string
  currentSubStep?: SubStep
  currentSubStepId?: string,
  objectId?: string,
  objectType?: WorkflowObjectTypes,
  organization?: Organization,
  state?: WorkflowCompletionStates,
  updatedAt?: Date
  workflow?: Workflow,
}
