import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  SxProps,
} from '@mui/material';
import { SPACING } from 'ovComponents/0-tokens';
import { useAccordionTokens } from './accordian.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

interface AccordionProps extends MuiAccordionProps {
  multiple?: boolean,
  fullWidthOnMobile?: boolean,
}

export const Accordion = ({ multiple = false, fullWidthOnMobile = false, ...props }: AccordionProps) => {
  const tokens = useThemeTokens(useAccordionTokens());
  const { comp } = tokens;

  const defaultAccordionSx: SxProps = {
    '&.MuiAccordion-root': {
      border: props.variant === 'elevation' ? 'none' : `1px solid ${comp.accordion.borderColor}`,
      marginLeft: { xs: fullWidthOnMobile ? '-16px' : 0, sm: 0 },
      marginRight: { xs: fullWidthOnMobile ? '-16px' : 0, sm: 0 },
      borderRadius: { xs: fullWidthOnMobile ? 0 : comp.accordion.borderRadius, sm: !multiple ? comp.accordion.borderRadius : 0 },
      marginBottom: props.variant === 'elevation' ? '' : `${1 * SPACING}px`,
      boxShadow: 'unset',
      '&:first-of-type': {
        borderRadius: { xs: fullWidthOnMobile ? 0 : `${comp.accordion.borderRadius} ${comp.accordion.borderRadius} 0 0`, sm: `${comp.accordion.borderRadius} ${comp.accordion.borderRadius} 0 0` },
        '.MuiAccordionSummary-root': {
          borderTop: props.variant === 'elevation' && multiple ? 'none' : `1px solid ${comp.accordion.multipleBorderColor}`,
        },
      },
      '&:last-of-type': {
        borderRadius: { xs: fullWidthOnMobile ? 0 : `0 0 ${comp.accordion.borderRadius} ${comp.accordion.borderRadius}`, sm: `0 0 ${comp.accordion.borderRadius} ${comp.accordion.borderRadius}` },
        '.MuiCollapse-root': {
          borderRadius: `0 0 ${comp.accordion.borderRadius} ${comp.accordion.borderRadius}`,
        },
      },
      '&:last-of-type&:first-of-type': {
        borderRadius: { xs: fullWidthOnMobile ? 0 : comp.accordion.borderRadius, sm: comp.accordion.borderRadius },
        '.MuiCollapse-root': {
          borderRadius: comp.accordion.borderRadius,
        },
      },
      '.MuiAccordionSummary-root': {
        borderTop: props.variant === 'elevation' && multiple ? `1px solid ${comp.accordion.multipleBorderColor}` : 'none',
      },
    },
  };

  const mergedProps = {
    ...props,
    ...{ sx: [{ ...defaultAccordionSx }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])] },
    disableGutters: true,
  };
  return <MuiAccordion {...mergedProps} />;
};
export const AccordionDetails = (props: AccordionDetailsProps) => (<MuiAccordionDetails {...props} />);
export const AccordionSummary = (props: AccordionSummaryProps) => (<MuiAccordionSummary {...props} />);
