import {
  Box, Paper, Tab, Tabs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from 'components/tabs/ovTabs';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import Flags from './components/flags';
import Jobs from './components/jobs';
import Activities from './components/activities';

const Reconciliation = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reconciliation']);

  const availableTabs = [
    {
      id: 'flags',
      label: t('tabs.flags'),
      permission: 'read:flags',
      component: <Flags />,
    },
    {
      id: 'activities',
      label: t('tabs.activities'),
      permission: 'read:custodian_activity_basic',
      component: <Activities />,
    },
    {
      id: 'jobs',
      label: t('tabs.jobs'),
      permission: 'read:recon_jobs ', // Added the space deliberately to match the exact permission name "read:recon_jobs "
      component: <Jobs />,
    },
  ].filter((availableTab) => permissions.includes(availableTab.permission));

  const [tab, setTab] = usePageState(availableTabs[0]?.id || 'flags', 'tab');

  if (availableTabs.length === 0) return null;
  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {availableTabs.map((tabItem) => (
            <Tab
              key={tabItem.id}
              label={tabItem.label}
              value={tabItem.id}
              disableRipple
            />
          ))}
        </Tabs>
      </Box>

      {availableTabs.map((tabItem) => (
        <TabPanel
          key={tabItem.id}
          value={tab}
          index={tabItem.id}
        >
          {tabItem.component}
        </TabPanel>
      ))}
    </Paper>
  );
};

export default Reconciliation;
