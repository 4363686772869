import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import { CreateIdVerificationWizard, CreateIdVerificationWizardProps } from './wizard';

export const CreateIdVerificationWizardButton = (props: CreateIdVerificationWizardProps) => {
  const [wizardShown, setWizardShown] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button variant='text' onClick={() => { setWizardShown(true); }} startIcon={<AddIcon data-testid="add-icon" />}>
        {t('shared:add')}
      </Button>
      { wizardShown
        && <CreateIdVerificationWizard {...props} handleClose={() => setWizardShown(false)}/>
      }
    </>
  );
};
