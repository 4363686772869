import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { pick } from 'lodash/fp';
import NumberFormat from 'react-number-format';
import { phoneValidator } from '../client';
import Notes from '../../components/notes';
import AddressField from '../../components/inputs/addressField';
import { ClientGroupSummary } from '../../components/layout/client/clientGroupSummary';
import { ClientGroup, Organization, PhysicalAddress } from '../../interfaces';
import { usePageState } from '../../util/usePageState';
import CorporationDetails from './components/CorporationDetails';
import AffiliatesTab from './components/AffiliatesTab';
import CorporationDocuments from './components/CorporationDocuments';
import CorporationOverview from './components/CorporationOverview';
import CorporationStatements from './components/CorporationStatements';
import CorporationTrades from './components/CorporationTrades';
import CorporationTransactions from './components/CorporationTransactions';
import CorporationTransfers from './components/CorporationTransfers';
import { TabPanel, Tab, Tabs } from '../../components/tabs/ovTabs';
import { usePermissions } from '../../providers/userContextProvider';
import CreateNewModal from '../../components/modals/createNewModal';
import { UPDATE_CLIENT_GROUP } from '../household/components/editHousehold';
import { NoteObjectTypes } from '../../interfaces/note';
import { LocalizedDatePicker } from '../../components/fields/localizedDatePicker';

export const FETCH_CORPORATION = gql`
  fragment RelevantUserDetails on User {
    id firstName middleName lastName primaryEmail dateOfBirth
  }
  query fetchCorporation($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        type
        name
        primaryUser {
          ...RelevantUserDetails
        }
        relationships {
          type
          user {
            ...RelevantUserDetails
          }
        }
        businessType
        corporationCountry
        establishedDate
        incorporationNumber
        taxNumber
        businessIsMemberOfIiroc
        primaryEmail
        physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
        completedAt
        phone
        organization { id name }
        incompleteFields
        applicableBillingSchedule { id frequency }
        applicableFeeTier { id name }
      }
    }
  }
`;
interface CorporateContextType {
  orgSettings: Organization;
}

const CorporateContext = createContext<CorporateContextType | undefined>(undefined);
export const useCorporateContext = () => useContext(CorporateContext);

const Corporation = () => {
  const { t } = useTranslation('corporations');
  const { permissions, accessiblePages } = usePermissions();
  const params = useParams();
  const [editClientGroupOpen, setEditClientGroupOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  const [clientGroup, setClientGroup] = useState<ClientGroup>();
  const [clientGroupOriginal, setClientGroupOriginal] = useState<ClientGroup>();
  const [phone, setPhone] = useState();
  const { loading } = useQuery(FETCH_CORPORATION, {
    variables: { id: params.clientGroupId },
    onCompleted: (data: any) => {
      setClientGroup(data.fetchClientGroup.clientGroup);
      setClientGroupOriginal(data.fetchClientGroup.clientGroup);
    },
  });
  const [tab, setTab] = usePageState('overview', 'tab');

  const [updateClientGroupMutation] = useMutation(UPDATE_CLIENT_GROUP, {
    variables: {},
    refetchQueries: [FETCH_CORPORATION],
  });
  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }
  const validPhysicalAddress = (address: PhysicalAddress): boolean => {
    if (!address) return false;
    if (!address.country || !address.province || !address.city || !address.streetName || !address.postal || !address.houseNumber) return false;
    return true;
  };
  const onUpdateClientgroup = () => {
    setEditClientGroupOpen(false);
    if (!clientGroup || !clientGroupOriginal) return;
    const changedObjects = (Object.keys(clientGroup) as (keyof typeof clientGroup)[]).filter((key) => clientGroup[key] !== clientGroupOriginal[key]);
    updateClientGroupMutation({
      variables: {
        input: {
          clientGroupId: clientGroup?.id,
          ...pick(changedObjects, clientGroup),
        },
      },
    }).then();
  };
  const validEmail = (email: string | undefined): boolean => {
    if (email === undefined) return false;
    const rule = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return rule.test(email);
  };
  const editingFormValid = (): boolean => {
    if (!clientGroup) return false;
    return (
      (
        !permissions.includes('write:client_low_risk_pii')
        || (clientGroup.establishedDate !== '')
        || (clientGroup.primaryEmail !== '')
      )
      && validPhysicalAddress(clientGroup.physicalAddress as PhysicalAddress)
      && phoneValidator(clientGroup.phone ?? '')
    );
  };
  if (!clientGroup) return <></>;

  return (
    <CorporateContext.Provider value={{
      orgSettings: clientGroup.organization as Organization,
    }}>
      <Box>
        <ClientGroupSummary clientGroup={clientGroup} onHighlightClicked={(prop) => {
          setEditClientGroupOpen(true);
          setSelectedHighlight(prop);
        }} />
        <Tabs value={tab} onChange={(event, newValue) => { setTab(newValue); }}
          variant="scrollable" scrollButtons="auto"
          sx={{
            borderBottom: 1, borderColor: 'divider', marginTop: 2, marginBottom: 2,
          }}
        >
          <Tab label={t('tabs.overview')} value="overview" />
          <Tab label={t('tabs.details')} value="details" />
          <Tab label={t('tabs.affiliates')} value="affiliates" />
          {permissions.includes('read:files')
            && <Tab label={t('tabs.documents')} value="documents" />
          }
          <Tab label={t('tabs.statements')} value="statements" />
          <Tab label={t('tabs.transfers')} value="transfers" />
          <Tab label={t('tabs.trades')} value="trades" />
          {accessiblePages.includes('TRANSACTIONS')
            && <Tab label={t('tabs.ledger')} value="ledger" />
          }
          <Tab label={t('tabs.notes')} value="notes" />
        </Tabs>
        <TabPanel value={tab} index="overview">
          <CorporationOverview clientGroup={clientGroup} />
        </TabPanel>
        <TabPanel value={tab} index="details">
          <CorporationDetails clientGroup={clientGroup} />
        </TabPanel>
        <TabPanel value={tab} index="affiliates">
          <AffiliatesTab clientGroup={clientGroup} />
        </TabPanel>
        <TabPanel value={tab} index="documents">
          <Card>
            <CorporationDocuments clientGroup={clientGroup} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="statements">
          <Card>
            <CorporationStatements clientGroup={clientGroup} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="transfers">
          <Card>
            <CorporationTransfers clientGroup={clientGroup} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="trades">
          <Card>
            <CorporationTrades clientGroup={clientGroup} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="ledger">
          <Card>
            <CorporationTransactions clientGroup={clientGroup} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="notes">
          <Card>
            <Notes objectId={clientGroup.id} objectType={NoteObjectTypes.CLIENT_GROUP} />
          </Card>
        </TabPanel>
        {editClientGroupOpen && (<CreateNewModal
          loading={loading}
          forceOpen={true}
          buttonType="NONE"
          buttonText={t('buttons:save')}
          title={t('edit.title')}
          onSubmit={() => onUpdateClientgroup()}
          buttonVariant="contained"
          onClose={() => {
            setSelectedHighlight('');
            setEditClientGroupOpen(false);
          }}
          disabled={!editingFormValid()}
        >
          <Grid container spacing={2} padding={2}>
            {permissions.includes('write:client_low_risk_pii') && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label={t('corporations:edit.name')}
                    fullWidth
                    required
                    autoFocus={selectedHighlight === 'name'}
                    value={clientGroup?.name}
                    onChange={(e) => {
                      if (clientGroup) {
                        setClientGroup({ ...clientGroup, name: e.target.value });
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <LocalizedDatePicker
                    label={t('corporations:edit.establishedDate') + ((!clientGroup?.establishedDate) ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
                    value={clientGroup?.establishedDate}
                    onChange={(date) => {
                      if (clientGroup) {
                        setClientGroup({ ...clientGroup, establishedDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
                      }
                    }}
                    renderInput={(params1) => <TextField fullWidth {...params1} error={!(clientGroup?.establishedDate)} autoFocus={selectedHighlight === 'establishedDate'} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('corporations:edit.primaryEmail') + (!validEmail(clientGroup?.primaryEmail || '') ? `: ${t('client:profile.fieldValidations.primaryEmail')}` : '')}
                    fullWidth
                    required
                    autoFocus={selectedHighlight === 'primaryEmail'}
                    value={clientGroup?.primaryEmail}
                    error={!validEmail(clientGroup?.primaryEmail || '')}
                    onChange={(e) => {
                      if (clientGroup) {
                        setClientGroup({ ...clientGroup, primaryEmail: e.target.value });
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    label={(phoneValidator(clientGroup?.phone ?? '') ? `${t('details.phone')}` : `${t('client:profile.fieldValidations.canadianNumberValidation')}`)}
                    fullWidth
                    format="+1 (###) ###-####"
                    mask=""
                    autoFocus={selectedHighlight === 'phone'}
                    value={phone ?? clientGroup.phone}
                    error={!phoneValidator(clientGroup?.phone ?? '')}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      if (clientGroup) {
                        setPhone(formattedValue);
                        setClientGroup({ ...clientGroup, phone: value });
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('corporations:edit.incorporationNumber')}
                    fullWidth
                    autoFocus={selectedHighlight === 'incorporationNumber'}
                    value={clientGroup?.incorporationNumber}
                    onChange={(e) => {
                      if (clientGroup) {
                        setClientGroup({ ...clientGroup, incorporationNumber: e.target.value });
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('corporations:edit.taxNumber')}
                    fullWidth
                    autoFocus={selectedHighlight === 'taxNumber'}
                    value={clientGroup?.taxNumber}
                    onChange={(e) => {
                      if (clientGroup) {
                        setClientGroup({ ...clientGroup, taxNumber: e.target.value });
                      }
                    }} />
                </Grid>
              </>
            )}
            {permissions.includes('write:client_high_risk_pii') && (
              <Grid item xs={12}>
                <AddressField
                  autoFocus={selectedHighlight === 'physicalAddress'}
                  label={t('corporations:edit.physicalAddress') + (!validPhysicalAddress(clientGroup?.physicalAddress as PhysicalAddress) ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
                  error={!validPhysicalAddress(clientGroup?.physicalAddress as PhysicalAddress)}
                  address={clientGroup?.physicalAddress as PhysicalAddress}
                  onChange={(physicalAddress) => {
                    if (clientGroup) {
                      setClientGroup({ ...clientGroup, physicalAddress });
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CreateNewModal>)}
      </Box>
    </CorporateContext.Provider>
  );
};

export default Corporation;
