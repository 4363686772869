import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { usePermissions } from '../../providers/userContextProvider';
import { Organization } from '../../interfaces';
import { FulltextSearchSelect, SearchResults } from './fulltextSearchSelect';

const SEARCH_ORGANIZATIONS = gql`
  query searchOrganizations($searchText: String!, $childrenFor: ObjectID) {
    fetchOrganizations(input: { filter: { searchText: $searchText, childrenFor: $childrenFor }}) {
      organizations {
        id
        name
      }
      totalCount
    }
  }
`;

const FETCH_ORGANIZATION_NAME = gql`
  query fetchOrganizationName($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        name
      }
    }
  }
`;

const OrganizationSelect = ({
  onChange, value, label, size,
  autoFocus,
  childrenFor, error,
}: {
  onChange: (event: any) => void, value: string | undefined, label: string, size?: 'small' | 'medium' | undefined,
  autoFocus?: boolean,
  childrenFor?: string, error?: boolean,
}) => {
  const { permissions } = usePermissions();
  const [searchOrganizations] = useLazyQuery(SEARCH_ORGANIZATIONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [organization, setOrganization] = useState<Organization>({ id: value ?? '' });
  const { loading } = useQuery(FETCH_ORGANIZATION_NAME, {
    skip: !value,
    variables: { organizationId: value },
    onCompleted: ((data:any) => {
      setOrganization({ id: value ?? '', name: data.fetchOrganization.organization.name });
    }),
  });

  useEffect(() => {
    onChange({ target: { value: organization?.id ?? '' } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id]);

  if (!permissions.includes('read:organizations')) return <></>;

  const searchFn = (searchText:string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    searchOrganizations({
      variables: {
        searchText, childrenFor,
      },
      onCompleted: (data) => {
        resolve({
          items: data.fetchOrganizations.organizations,
          count: Number(data.fetchOrganizations.totalCount),
        });
      },
      onError: () => {
        resolve({
          items: [],
          count: 0,
        });
      },
    });
  });

  const generateOrganizationNameString = (org: Organization):string => org.name ?? '';

  return (
    <FulltextSearchSelect
      label={label}
      size={size}
      disabled={loading}
      error={error}
      item={organization}
      setItem={(o) => setOrganization(o as Organization)}
      searchFn={searchFn}
      primaryLabelFn={generateOrganizationNameString}
      autoFocus={autoFocus}
      fullWidth
    />
  );
};

export default OrganizationSelect;

export const testables = {
  SEARCH_ORGANIZATIONS,
  FETCH_ORGANIZATION_NAME,
};
