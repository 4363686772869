import {
  TextField, ListItem, ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Switch, Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Dispatch, SetStateAction, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FeeGrid, FeeTier, FeeTierStates } from '../../../interfaces';
import NumberField from '../../../components/inputs/numberField';
import BuildGrid from './buildGrid';

export const FETCH_FEE_TIERS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      feeTiers {
        id
        default
        organization { id }
      }
    }
  }
`;

const FeeGridForm = ({
  feeTier,
  setFeeTier,
  type,
  setType,
  disabled = false,
  organizationId,
}: {
  feeTier: FeeTier,
  setFeeTier: Dispatch<SetStateAction<FeeTier>>,
  type: 'FLAT' | 'TIERED',
  setType: (newType: 'FLAT' | 'TIERED') => void,
  disabled?: boolean,
  organizationId?: string,
}) => {
  const { t } = useTranslation(['feeAndBilling']);
  const setGrid = (grid: FeeGrid[]) => setFeeTier((prev) => ({ ...prev, grid }));
  const defaultedFeeTier = (feeGrid: FeeTier) => feeGrid.default && (feeGrid.default === true);
  const [defaultExists, setDefaultExists] = useState<boolean>(false);

  useQuery(FETCH_FEE_TIERS, {
    variables: {
      input: {
        filter: {
          organizationId,
          default: true,
          states: [FeeTierStates.ACTIVE],
        },
      },
    },
    onCompleted: (res: any) => {
      const feeTiers = res?.fetchFeeTiers?.feeTiers;
      if (feeTiers && organizationId) {
        const nonInheritedFeetiers = feeTiers.filter((item: any) => item.organization?.id === organizationId);
        setDefaultExists((nonInheritedFeetiers.length > 0));
      }
    },
  });

  return (
    <>
      <ListItem>
        <TextField
          label={t('feeAndBilling:feeGrid.edit.name')}
          fullWidth
          size='small'
          disabled={disabled}
          value={feeTier.name ?? ''}
          onChange={(e) => setFeeTier((prev) => ({ ...prev, name: e.target.value }))}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%', mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <>
                <ToggleButtonGroup
                  color="primary"
                  value={type}
                  exclusive
                  size='small'
                  onChange={(e, newType) => {
                    if (newType) {
                      setType(newType);
                    }
                  }}
                  disabled={disabled}
                  sx={{ marginLeft: '16px', minWidth: '150px' }}
                >
                  <ToggleButton sx={{ width: '50%' }} value="FLAT">{t('feeAndBilling:feeGrid.edit.type.flat')}</ToggleButton>
                  <ToggleButton sx={{ width: '50%' }} fullWidth value="TIERED">{t('feeAndBilling:feeGrid.edit.type.tiered')}</ToggleButton>
                </ToggleButtonGroup>
                <Tooltip sx={{ ml: 2 }} title={
                  <div style={{ whiteSpace: 'pre-line' }}>{t('feeAndBilling:feeGrid.edit.type.tooltip')}</div>
                }>
                  <InfoIcon color='secondary' />
                </Tooltip>
              </>
            }
            disabled={disabled}
            sx={{ marginLeft: 0 }}
            labelPlacement='start'
            label={t('feeAndBilling:feeGrid.edit.type.label')}
          />
        </FormGroup>
      </ListItem>
      {type === 'FLAT' && (
        <ListItem>
          <NumberField
            label={t('feeAndBilling:feeGrid.edit.flatAnnualFeeBpsLabel')}
            fullWidth
            size='small'
            disabled={disabled}
            decimalPlaces={2}
            value={feeTier.flatAnnualFeeBps ?? ''}
            setNumberValue={(value) => setFeeTier((prev) => ({ ...prev, flatAnnualFeeBps: value }))}
          />
        </ListItem>
      )}
      {type === 'TIERED' && (
        <>
          <BuildGrid grid={feeTier.grid?.length ? feeTier.grid : [{ minMarketValueCents: 0 }]} setGrid={setGrid} disabled={disabled} />
          <ListItem>
            <FormGroup sx={{ height: '100%', mt: 1, mb: 1 }}>
              <FormControlLabel
                control={
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={(feeTier.blendedGrid ?? true) ? 'BLENDED' : 'VOLUME'}
                      exclusive
                      onChange={(e, newType) => {
                        if (newType) {
                          setFeeTier((prev) => ({ ...prev, blendedGrid: newType === 'BLENDED' }));
                        }
                      }}
                      size='small'
                      disabled={disabled}
                      sx={{ marginLeft: '16px', minWidth: '150px' }}
                    >
                      <ToggleButton sx={{ width: '50%' }} value="BLENDED">{t('feeAndBilling:feeGrid.edit.grid.type.blended')}</ToggleButton>
                      <ToggleButton sx={{ width: '50%' }} value="VOLUME">{t('feeAndBilling:feeGrid.edit.grid.type.volume')}</ToggleButton>
                    </ToggleButtonGroup>
                    <Tooltip sx={{ ml: 2 }} title={
                      <div style={{ whiteSpace: 'pre-line' }}>{t('feeAndBilling:feeGrid.edit.grid.type.tooltip')}</div>
                    }>
                      <InfoIcon color='secondary' />
                    </Tooltip>
                  </>
                }
                disabled={disabled}
                sx={{ marginLeft: 0 }}
                labelPlacement='start'
                label={t('feeAndBilling:feeGrid.edit.grid.type.label')}
              />
            </FormGroup>
          </ListItem>
        </>
      )}
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              disabled={disabled}
              checked={feeTier.chargeForCash ?? true}
              onChange={async (event) => setFeeTier((prev) => ({ ...prev, chargeForCash: event.target.checked }))}
            />}
            label={t('feeAndBilling:feeGrid.edit.chargeForCash.label')}
          />
        </FormGroup>
        <Tooltip title={t('feeAndBilling:feeGrid.edit.chargeForCash.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              disabled={disabled}
              checked={feeTier.chargeForCashOnHold ?? true}
              onChange={async (event) => setFeeTier((prev) => ({ ...prev, chargeForCashOnHold: event.target.checked }))}
            />}
            label={t('feeAndBilling:feeGrid.edit.chargeForCashOnHold.label')}
          />
        </FormGroup>
        <Tooltip title={t('feeAndBilling:feeGrid.edit.chargeForCashOnHold.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              disabled={disabled}
              checked={feeTier.billableAUM ?? false}
              onChange={async (event) => setFeeTier((prev) => ({ ...prev, billableAUM: event.target.checked }))}
            />}
            label={t('feeAndBilling:feeGrid.edit.billable.label')}
          />
        </FormGroup>
        <Tooltip title={t('feeAndBilling:feeGrid.edit.billable.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </ListItem>
      <ListItem>
        <Tooltip
          title={
            (defaultExists && !defaultedFeeTier(feeTier)) ? t('feeAndBilling:feeGrid.edit.default.defaultFlagTooltip') : ''
          }
        >
          <FormGroup sx={{ height: '100%' }}>
            <FormControlLabel
              control={<Switch
                disabled={disabled || (feeTier.default ? false : defaultExists)}
                checked={feeTier.default}
                onChange={async (event) => {
                  setFeeTier((prev) => ({ ...prev, default: event.target.checked }));
                }}
              />}
              label={t('feeAndBilling:feeGrid.edit.default.label')}
            />
          </FormGroup>
        </Tooltip>
        <Tooltip title={t('feeAndBilling:feeGrid.edit.default.tooltip')}><InfoIcon color='secondary' /></Tooltip>
      </ListItem>
    </>
  );
};

export default FeeGridForm;
