import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { Affiliation } from 'interfaces';
import { generateClientNameString } from 'util/index';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'ovComponents/2-component';
import { colors } from 'theme/colors';

const tableHeadSx: SxProps = {
  backgroundColor: '#F0F4F8',
  borderRadius: '2px',
  border: 'none',
  color: colors.gray500,
};

interface AffiliationTableProps {
  affiliations?: Affiliation[],
  onDelete?: (affiliation: Affiliation) => void,
  showType?: boolean,
  showRelation?: boolean,
  showAllocation?: boolean,
  allowDelete?: boolean,
}

export const AffiliationTable = (props: AffiliationTableProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationRelations', 'affiliationTypes']);
  const getHandleDeleteFn = (affiliation: Affiliation) => (() => props.onDelete?.(affiliation));
  const affiliations = props?.affiliations ?? [];
  const numSecondaryColumns = [props.showType, props.showRelation, props.showAllocation].filter(Boolean).length;
  const secondaryColumnWidth = Math.min(60 / (numSecondaryColumns || 1), 30);
  const DeleteButton = ({ onClick, sx }: { onClick?: () => void, sx?: SxProps }) => <IconButton onClick={onClick} sx={sx} aria-label="delete" size='small'><DeleteIcon /></IconButton>;

  return (affiliations.length > 0
    ? (
      <Table size='small' aria-label="table">
        <TableHead sx={tableHeadSx}>
          <TableRow>
            <TableHeadCell>{t('workflowCompletions:baseAccountCreation.affiliationTable.nameHeader')}</TableHeadCell>
            {props.showType && <TableHeadCell sx={{ width: `${secondaryColumnWidth}%` }}>{t('workflowCompletions:baseAccountCreation.affiliationTable.typeHeader')}</TableHeadCell>}
            {props.showRelation && <TableHeadCell sx={{ width: `${secondaryColumnWidth}%` }}>{t('workflowCompletions:baseAccountCreation.affiliationTable.relationHeader')}</TableHeadCell>}
            {props.showAllocation && <TableHeadCell sx={{ width: `${secondaryColumnWidth}%` }}>{t('workflowCompletions:baseAccountCreation.affiliationTable.allocationHeader')}</TableHeadCell>}
            {props.allowDelete && <TableHeadCell padding='checkbox'><DeleteButton sx={{ visibility: 'hidden' }}/></TableHeadCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {affiliations.map((affiliation, idx) => (
            <TableRow hover key={idx}>
              <TableCell>{generateClientNameString(affiliation.user)}</TableCell>
              {props.showType && <TableCell sx={{ width: `${secondaryColumnWidth}%` }}>{t(`affiliationTypes:${affiliation.type}`)}</TableCell>}
              {props.showRelation && <TableCell sx={{ width: `${secondaryColumnWidth}%` }}>{t(`affiliationTypes:affiliationRelationship.${affiliation.relation}`)}</TableCell>}
              {props.showAllocation && <TableCell sx={{ width: `${secondaryColumnWidth}%` }}>{`${affiliation.allocation ?? 0}%`}</TableCell>}
              {props.allowDelete && <TableCell padding='checkbox'><DeleteButton onClick={getHandleDeleteFn(affiliation)}/></TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
    : <></>
  );
};
