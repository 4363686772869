import { Typography, Box, Paper } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { UserContext } from '../../../providers/userContextProvider';
import { BillingSchedule } from '../../../interfaces';
import { formatDay } from '../../../components/fields/scheduleDateSelect';

export const FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE = gql`
  query fetchOrganizationDefaultBillingSchedule($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        defaultBillingSchedule {
          id
          default
          frequency
          nextCalculationDate
          nextBillingDate
          organization { name }
        }
      }
    }
  }
`;

export const DefaultOrgBillingScheduleBox = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation();

  const [defaultBillingSchedule, setDefaultBillingSchedule] = useState<BillingSchedule | null>(null);

  const { loading } = useQuery(FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE, {
    variables: { organizationId: activeOrganization.id },
    onCompleted: (res: any) => {
      setDefaultBillingSchedule(res.fetchOrganization?.organization?.defaultBillingSchedule);
    },
  });

  return (
    <Box mb={2}>
      <Paper sx={{ padding: 2 }}>
        <Typography>
          {t('feeAndBilling:billingSchedule.defaultBillingScheduleFor')}
          &nbsp;{activeOrganization?.name}:&nbsp;
        </Typography>
        <Typography>
          {!loading && (
            defaultBillingSchedule === null ? 'none' : (
              `${t(`feeAndBilling:billingSchedule.frequencies.${defaultBillingSchedule.frequency}`)} - ${formatDay(dayjs(defaultBillingSchedule.nextBillingDate).date())}`
            )
          )}
        </Typography>
      </Paper>
    </Box>
  );
};
