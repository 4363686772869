/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters } from '../../../ovComponents/3-pattern';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { FETCH_TRANSACTIONS_QUERY } from './transactionsTable';
import { useClientContext } from '../../../pages/client';
import { useHouseholdContext } from '../../../pages/household';
import { useCorporateContext } from '../../../pages/corporation';

const DownloadTransactions = ({ filter, queryFilter }: { filter: any; queryFilter: any }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();

  const isCanReadFirstOrLastNames = permissions.includes('read:client_low_risk_pii');

  const columns: Column[] = [
    { gqlAlias: 'subAccount.account.id', header: t('components:transaction.table.account') },
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'user.firstName', header: t('components:transaction.table.userFirstName') }]) || []),
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'user.lastName', header: t('components:transaction.table.userLastName') }]) || []),
    { gqlAlias: 'subAccount.account.type', header: t('components:transaction.table.accountType') },
    { gqlAlias: 'date', header: t('components:transaction.table.date') },
    { gqlAlias: 'type', header: t('components:transaction.table.type') },
    { gqlAlias: 'quantity', header: t('components:transaction.table.quantity') },
    { gqlAlias: 'valueCents', header: t('components:transaction.table.valueCents'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'priceCents', header: t('components:transaction.table.bookCost'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'description', header: t('components:transaction.table.description') },
  ];

  const additionalColumns: Column[] = [
    { gqlAlias: 'currency', header: t('components:transaction.table.currency') },
    { gqlAlias: 'settleDate', header: t('components:transaction.table.settleDate') },
    { gqlAlias: 'settleDateToWithdraw', header: t('components:transaction.table.settleDateToWithdraw') },
    { gqlAlias: 'subAccount.id', header: t('components:transaction.table.subAccount') },
    { gqlAlias: 'goal.id', header: t('components:transaction.table.goal') },
    { gqlAlias: 'user.id', header: t('components:transaction.table.user') },
    { gqlAlias: 'organization.id', header: t('components:transaction.table.organization') },
    { gqlAlias: 'objectType', header: t('components:transaction.table.objectType') },
    { gqlAlias: 'objectId', header: t('components:transaction.table.objectId') },
    { gqlAlias: 'subAccount.account.householdClientGroup.id', header: t('components:transaction.table.householdClientGroup') },
  ];

  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);

  const config: DownloadButtonProps = {
    gql: FETCH_TRANSACTIONS_QUERY(permissions),
    fileName: `${t('components:transaction.title')}_${dayjs().format('YYYY-MM-DD')}`,
    filter: {
      ...filter,
      subAccountIds: queryFilter?.subAccountIds,
      organizationId: organizationId ?? activeOrganization.id,
    },
    additionalFilter: queryFilter,
    queryPath: 'fetchTransactions',
    datasetPath: 'transactions',
    columns,
    additionalColumns,
    sortField: 'date',
    sortDesc: false,
    progressTitle: t('components:transaction.exportProgressTitle'),
  };

  return (
    <DownloadButton
      configurable
      gql={config.gql}
      fileName={config.fileName}
      filter={config.filter}
      additionalFilter={config.additionalFilter}
      queryPath={config.queryPath}
      datasetPath={config.datasetPath}
      columns={config.columns}
      additionalColumns={config.additionalColumns}
      sortField={config.sortField}
      sortDesc={config.sortDesc}
      progressTitle={config.progressTitle}
    />
  );
};

export default DownloadTransactions;
