import { Snackbar } from '@mui/material';
import {
  createContext, useCallback, useContext, useState,
} from 'react';
import { Alert } from '../ovComponents/2-component/alert/alert';

export interface ToastProps {
  message: string,
  severity: 'error' | 'info' | 'success' | 'warning',
}
type ShowToastType = (props: ToastProps) => void;

type ToastContextType = {
  showToast: ShowToastType,
  setToastTheme: (comp: any) => void;
};
const ToastContext = createContext<ToastContextType>({ showToast: () => { }, setToastTheme: () => { } });

const ToastContextProvider = ({ children }: { children: any }) => {
  const [globalSnackbar, setGlobalSnackbar] = useState<{ open: boolean, props?: ToastProps }>({ open: false });
  const [toastTheme, setToastTheme] = useState<any>({});
  const closeSnackbar = (): void => setGlobalSnackbar({ open: false });

  const showToast = useCallback((props: ToastProps) => {
    setGlobalSnackbar({ open: true, props });
  }, []);

  const setToastThemeToken = useCallback((comp: any) => {
    setToastTheme(comp);
  }, []);

  const Toast = () => (
    <Snackbar open={globalSnackbar.open} onClose={closeSnackbar}>
      <div>
        <Alert severity={globalSnackbar?.props?.severity || 'info'} comp={toastTheme}>
          {globalSnackbar?.props?.message || ''}
        </Alert>
      </div>
    </Snackbar>
  );

  return (
    <ToastContext.Provider value={{ showToast, setToastTheme: setToastThemeToken }}>
      {children}
      <Toast />
    </ToastContext.Provider>
  );
};

const useGlobalToast = () => useContext(ToastContext);

export {
  ToastContextProvider, useGlobalToast,
};

export default ToastContextProvider;
