import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RevenueShareTable from './components/revenueShareTable';

const FeeReport = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <RevenueShareTable />
    </Paper>
  );
};

export default FeeReport;
