import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';

const styles = {
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '624px',
      },
    },
  },
  dialogButton: {
    minWidth: '160px',
    fontWeight: 700,
    textTransform: 'none',
  },
  closeButton: {
    float: 'right',
    width: '13.91px',
    height: '13.91px',
    fontWeight: 500,
    color: colors.black,
  },
};

const ConfirmationModal = ({
  onConfirm, onCancel, children, title, open, bodyText, confirmButton, cancelButton, maxWidth = 'sm', loading = false, dialogStyles, confirmColor,
}: {
  onConfirm: () => void,
  onCancel: () => void,
  title: string,
  open: boolean,
  bodyText?: string,
  children?: any,
  confirmButton?: string,
  cancelButton?: string,
  maxWidth?: any,
  loading?: boolean,
  dialogStyles?: any,
  confirmColor?: 'inherit' | 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning',
}) => {
  const { t } = useTranslation('shared');
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      sx={dialogStyles?.dialogContainer ?? styles.dialogContainer}
    >
      <DialogTitle sx={{ fontWeight: dialogStyles?.dialogContentStyles ? dialogStyles?.dialogContentStyles?.title?.fontWeight : 700 }}>
        {title}
        <IconButton sx={{ ...styles.closeButton, color: dialogStyles?.dalogClose?.color }} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: dialogStyles ? dialogStyles?.dialogContentStyles?.width : '400px' }}>
        {children}
        {!children && bodyText && (
          <Typography>{bodyText}</Typography>
        )}
        <Grid container justifyContent="space-between" paddingTop={5}>
          <Grid item>
            <Button sx={dialogStyles?.cancelButton ?? styles.dialogButton} type='button' variant='outlined' disabled={loading} onClick={() => onCancel()}>
              {cancelButton ?? t('shared:cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button sx={dialogStyles?.dialogButton ?? styles.dialogButton} type='button' color={confirmColor ?? undefined} variant='contained' disabled={loading} onClick={() => onConfirm()}>
              {confirmButton ?? t('shared:proceed')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
