import { CurrencyCodes } from '@onevesthq/ov-enums';
import { round } from 'lodash';
import { isNull } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Holding } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatDecimalNumber, formatMoneyValue, formatPercentValue } from '../../../../../util';
import { currencySymbol } from '../../../../../util/currency';
import { Link } from '../../../../1-primative';
import { TableCell } from '../../../../2-component';
import { CurrencyFlag } from '../../../../3-pattern';

export const SecurityHoldingCell = ({
  holding, type, history, totalMarketValue, useCustodianData, displayCurrency, isFirst, maxQuantityDecimalPlaces,
}: {
  holding: Holding, type: string, history: any[], totalMarketValue: number,
  useCustodianData?: boolean, missesFxRate?: boolean, displayCurrency?: boolean, isFirst?: boolean, maxQuantityDecimalPlaces?: number,
}) => {
  const { sys } = useThemeTokens();
  const [nativeCurrency, setNativeCurrency] = useState(CurrencyCodes.CAD);

  const find1Day = (hold: Holding) => history[history.length - 1]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find3Day = (hold: Holding) => history[history.length - 3]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find1Week = (hold: Holding) => history[history.length - 7]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find1Month = (hold: Holding) => history[0]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);

  useEffect(() => {
    setNativeCurrency(holding?.originalCurrency?.currency ?? holding.currency ?? CurrencyCodes.CAD);
  }, [holding]);

  const isNotCashHolding = isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash;

  const leadingComponent = useMemo(() => (
    displayCurrency && nativeCurrency ? <CurrencyFlag baseCurrency={nativeCurrency} /> : undefined
  ), [displayCurrency, nativeCurrency]);

  const getColorByValue = (value: number) => {
    if (value === 0) return sys.color.onSurface;
    return value >= 0 ? sys.color.positive : sys.color.negative;
  };

  const oneDayDifferenceCents = () => (
    round((holding.totalCents ?? 0) - (useCustodianData ? (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0) : ((find1Day(holding)?.totalCents) ?? 0)))
  );

  const threeDayDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find3Day(holding)?.totalCents) ?? 0)
  );

  const oneWeekDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Week(holding)?.totalCents) ?? 0)
  );

  const oneMonthDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Month(holding)?.totalCents) ?? 0)
  );

  switch (type) {
    case 'symbol':
      return (
        <TableCell maxWidth='250px' isFirst={isFirst} sx={{ minWidth: '60px' }} leadingComponent={leadingComponent}>
          {holding.financialProduct?.ticker || 'CAD'}
        </TableCell>
      );
    case 'securityName':
      return (
        <TableCell maxWidth='250px' leadingComponent={leadingComponent} isFirst={isFirst}>
          {holding.financialProduct?.url ? (
            <Link href={holding.financialProduct.url} target='_blank'>
              {translateBackend(holding.financialProduct.translatedName)}
            </Link>
          ) : (
            <>{translateBackend(holding.financialProduct?.translatedName)}</>
          )}
        </TableCell>
      );
    case 'quantity':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding ? formatDecimalNumber(holding.quantity, maxQuantityDecimalPlaces) : '-'}
        </TableCell>
      );
    case 'bookCostNative':
      return (
        <TableCell right number isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue(holding?.originalCurrency?.adjustedCostBaseCents ?? holding.adjustedCostBaseCents, currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'bookCost':
      return (
        <TableCell right number isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue(holding.adjustedCostBaseCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'bookValue':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue((holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPriceNative':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue((holding?.originalCurrency?.currentPriceCents ?? holding.currentPriceCents), currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPrice':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding
            ? formatMoneyValue(holding.currentPriceCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
            : '-'
          }
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right',
          color: ((useCustodianData ? (holding.currentPriceCents ?? 0)
            : (holding.financialProduct?.currentPriceCents ?? 0) ?? 0) * (holding.quantity ?? 0)) - ((holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0)) >= 0
            ? sys.color.positive : sys.color.negative,
          whiteSpace: 'nowrap',
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(
                ((useCustodianData
                  ? (holding.currentPriceCents ?? 0)
                  : (holding.financialProduct?.currentPriceCents ?? 0) ?? 0) * (holding.quantity ?? 0)) - ((holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0)),
                currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$',
              )} `}
              ({formatPercentValue(
                ((useCustodianData
                  ? (holding.currentPriceCents ?? 0)
                  : (holding.financialProduct?.currentPriceCents ?? 0) ?? 0) - (holding.adjustedCostBaseCents ?? 0)) / ((holding.adjustedCostBaseCents ?? 0) === 0
                  ? 1 : (holding.adjustedCostBaseCents ?? 0)),
              )})
            </>
          ) : '-'}
        </TableCell>
      );
    case 'oneDayChange':
      return (
        <TableCell right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneDayDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              ({formatPercentValue(oneDayDifferenceCents() / (useCustodianData ? (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0)
                : ((find1Day(holding)?.totalCents) ?? 0)))})
            </>
          ) : '-'}
        </TableCell>
      );
    case 'threeDayChange':
      return (
        <TableCell right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(threeDayDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(threeDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              ({formatPercentValue((threeDayDifferenceCents()
              ) / ((find3Day(holding)?.totalCents) ?? 0))})
            </>
          ) : '-'}
        </TableCell>
      );
    case 'oneWeekChange':
      return (
        <TableCell right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneWeekDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneWeekDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              ({formatPercentValue((oneWeekDifferenceCents()
              ) / ((find1Week(holding)?.totalCents) ?? 0))})
            </>
          ) : '-'}
        </TableCell>
      );
    case 'oneMonthChange':
      return (
        <TableCell right number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', whiteSpace: 'nowrap', color: getColorByValue(oneMonthDifferenceCents()),
        }}>
          {isNotCashHolding ? (
            <>
              {`${formatMoneyValue(oneMonthDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
              ({formatPercentValue((oneMonthDifferenceCents()
              ) / ((find1Month(holding)?.totalCents) ?? 0))})
            </>) : '-'}
        </TableCell>
      );
    case 'percentOfTotal':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {isNotCashHolding ? formatPercentValue((holding.totalCents ?? 0) / totalMarketValue) : '-'}
        </TableCell>
      );
    case 'currentValue':
    case 'marketValueNative':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {formatMoneyValue((holding?.originalCurrency?.totalCents ?? holding.totalCents ?? 0), currencySymbol[nativeCurrency] ?? '$')}
        </TableCell>
      );
    case 'marketValue':
      return (
        <TableCell right number leadingComponent={leadingComponent} isFirst={isFirst}>
          {holding?.originalCurrency ? formatMoneyValue(holding.totalCents, currencySymbol[holding.currency as CurrencyCodes ?? CurrencyCodes.CAD]) : '-'}
        </TableCell>
      );
    default:
      return (
        <TableCell leadingComponent={leadingComponent} isFirst={isFirst}>
          {(holding as any)[type]}
        </TableCell>
      );
  }
};
