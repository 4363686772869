import { ListItem, TextField, Typography } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import PermissionsSelect from '../../../components/inputs/permissionsSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import { UserContext } from '../../../providers/userContextProvider';

const CREATE_ROLE = gql`
  mutation createApiToken($input: CreateApiTokenInput!) {
    createApiToken(input: $input) {
      apiToken {
        id
      }
    }
  }
`;

const NewApiToken = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg: string }) => {
  const { t } = useTranslation(['devSettings']);
  const { userContext } = useContext(UserContext);
  const [apiToken, setApiToken] = useState({
    name: '',
    organizationId: defaultOrg,
    permissions: [],
  });
  const [initialState] = useState({
    name: '',
    organizationId: defaultOrg,
    permissions: [],
  });
  const [createApiToken, { loading }] = useMutation(CREATE_ROLE, {
    variables: {
      input: apiToken,
    },
  });

  const create = async (event: any) => {
    await createApiToken();
    afterCreate();
  };

  return (
    <CreateNewModal
      initialState={initialState}
      state={apiToken}
      loading={loading} title={t('apiTokenModal.title')} onSubmit={create} sx={{ float: 'right', m: 1 }}>
      <ListItem>
        <TextField
          fullWidth
          label={t('apiTokenModal.name')}
          value={apiToken.name}
          onChange={(event: any) => setApiToken({ ...apiToken, name: event.target.value })} />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={apiToken.organizationId}
          label={t('apiTokenModal.organization')}
          onChange={(event: any) => setApiToken({ ...apiToken, organizationId: event.target.value as string })}
        />
      </ListItem>
      <ListItem>
        <Typography>
          {t('apiTokenModal.permissions')}
        </Typography>
      </ListItem>
      <ListItem>
        <PermissionsSelect
          permissions={apiToken.permissions}
          scopedPermissions={userContext.role?.permissions}
          onChange={(event: any) => {
            let permissions: any = [...apiToken.permissions];
            if (event.target.checked) {
              permissions.push(event.target.value);
            } else {
              permissions = remove((x) => x === event.target.value, permissions);
            }
            setApiToken({ ...apiToken, permissions });
          }}/>
      </ListItem>
    </CreateNewModal>
  );
};

export default NewApiToken;
