import Default from '../../../assets/images/bank-logo/000_default.png';
import BMO from '../../../assets/images/bank-logo/001_BMO.png';
import ScotiaBank from '../../../assets/images/bank-logo/002_Scotiabank.png';
import RBC from '../../../assets/images/bank-logo/003_RBC.jpg';
import TD from '../../../assets/images/bank-logo/004_TD.png';
import NationalBank from '../../../assets/images/bank-logo/006_National_Bank_of_Canada.png';
import CIBC from '../../../assets/images/bank-logo/010_CIBC.png';
import Simplii from '../../../assets/images/bank-logo/010_Simplii.png';
import HSBC from '../../../assets/images/bank-logo/016_HSBC_Bank_Canada.jpg';
import CWTC from '../../../assets/images/bank-logo/030_Canadian_Western_Trust_Company.jpg';
import LBC from '../../../assets/images/bank-logo/039_Laurentian_Bank_Of_Canada.jpg';
import ATB from '../../../assets/images/bank-logo/219_ATB_Financial.jpg';
import MBC from '../../../assets/images/bank-logo/540_Manulife_Bank_of_Canada.png';
import Tangerine from '../../../assets/images/bank-logo/612_Tangerine.png';
import EQ from '../../../assets/images/bank-logo/623_EQ_Bank.png';
import Flinks from '../../../assets/images/bank-logo/777_Flinks.png';
import CoastCapital from '../../../assets/images/bank-logo/809_Coast_Capital.png';
import Vancity from '../../../assets/images/bank-logo/809_Vancity.png';
import Desjardins from '../../../assets/images/bank-logo/815_Desjardins.png';
import Meridian from '../../../assets/images/bank-logo/837_Meridian.png';

interface BankInfo {
  institutionNumber: string,
  iconPath: string,
  transitNumbers?: string[],
}

const simpliiTransitNumbers: string[] = ['30800'];

const coastCapitalTransitNumbers: string[] = [
  '19610', '17260', '19150', '30160', '17120', '17210', '17170',
  '19130', '17160', '17430', '19700', '17480', '17250', '19180',
  '17110', '17270', '17420', '19650', '19710', '17240', '30130',
  '30190', '17230', '19690', '17410', '19670', '19110', '19170',
  '19120', '17150', '19620', '19630', '17470', '19680', '19640',
  '30150', '17220', '30170', '30040', '30100', '30120', '18930',
  '18920', '18950', '19720', '02410', '30110', '30050', '18940',
  '18910', '30020', '02430', '30140', '30030',
];

const vancityTransitNumbers: string[] = [
  '07230', '16160', '16360', '03210', '16370', '15460', '16540',
  '16510', '15410', '16380', '02740', '16390', '15480', '16430',
  '15440', '16490', '16560', '23220', '07260', '07210', '15470',
  '16410', '07290', '15400', '16530', '15420', '16460', '12180',
  '12110', '07240', '16500', '15490', '16470', '16520', '16310',
  '16480', '16330', '16320', '16300', '07250', '16180', '16170',
  '16130', '16350', '16140', '16190', '16340', '07200', '16110',
  '16120', '16420', '07270', '02730', '02720', '07220', '02710',
  '16150', '16450',
];

const bankAccountInfo: BankInfo[] = [
  { institutionNumber: '000', iconPath: Default },
  { institutionNumber: '001', iconPath: BMO },
  { institutionNumber: '002', iconPath: ScotiaBank },
  { institutionNumber: '003', iconPath: RBC },
  { institutionNumber: '004', iconPath: TD },
  { institutionNumber: '006', iconPath: NationalBank },
  { institutionNumber: '010', iconPath: CIBC },
  { institutionNumber: '010', iconPath: Simplii, transitNumbers: simpliiTransitNumbers },
  { institutionNumber: '016', iconPath: HSBC },
  { institutionNumber: '030', iconPath: CWTC },
  { institutionNumber: '039', iconPath: LBC },
  { institutionNumber: '219', iconPath: ATB },
  { institutionNumber: '540', iconPath: MBC },
  { institutionNumber: '612', iconPath: Tangerine },
  { institutionNumber: '623', iconPath: EQ },
  { institutionNumber: '777', iconPath: Flinks },
  { institutionNumber: '809', iconPath: CoastCapital, transitNumbers: coastCapitalTransitNumbers },
  { institutionNumber: '809', iconPath: Vancity, transitNumbers: vancityTransitNumbers },
  { institutionNumber: '815', iconPath: Desjardins },
  { institutionNumber: '837', iconPath: Meridian },
];

export const getBankIconPath = (institutionNumber: string, transitNumberDisclosed: string) => {
  if (!['010', '809'].includes(institutionNumber)) {
    return bankAccountInfo.find((x) => x.institutionNumber === institutionNumber)?.iconPath ?? Default;
  }
  if (institutionNumber === '809') {
    return bankAccountInfo.find(
      (x) => (x.institutionNumber === institutionNumber) && (x.transitNumbers?.includes(transitNumberDisclosed)),
    )?.iconPath ?? Default;
  }
  if (institutionNumber === '010' && transitNumberDisclosed === '30800') return Simplii;
  if (institutionNumber === '010') return CIBC;
  return Default;
};
