import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid, Skeleton, Typography,
} from '../../1-primative';
import { Button } from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';
import { ArticleItem } from '../articleItem/articleItem';
import { UserContext } from '../../../providers/userContextProvider';

export const FETCH_ARTICLES = gql`
  query fetchArticles($input: FetchArticlesInput!) {
    fetchArticles(input: $input) {
      articles {
        id
        articleUrl
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        organization {
          id
        }
        state
        translatedDescription {
          en
          fr
        }
        translatedName {
          en
          fr
        }
        imageUrl
        updatedAt
      }
      totalCount
    }
  }
`;

export const NewsAndInsights = ({ options, widgetId }: { options: any, widgetId?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('shared');
  const { activeOrganization } = useContext(UserContext);
  const {
    data, previousData, loading,
  } = useQuery(FETCH_ARTICLES, {
    variables: {
      input: {
        filter: {
          state: 'ACTIVE',
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: options.showAll ? 1000 : 3,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  if ((data || previousData)?.fetchArticles?.totalCount === 0) {
    return (<Box></Box>);
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="32px">
        <Typography variant='headingSmall'>{translateBackend(options.customTitle)}</Typography>
        {(!options.showAll && (data || previousData)?.fetchArticles?.totalCount > 3) && (
          <Button
            variant='text'
            onClick={() => navigate(`newsAndInsights/${widgetId}`)}
            label={t('viewAll')}
          />
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        { loading ? [1, 2, 3].map((index) => (
          <Grid item xs={12} md={4} key={index}>
            <Skeleton height={options.displayImageEnabled ? '380px' : '176px'} width='100%' variant='rectangular' sx={{ borderRadius: '16px' }} />
          </Grid>
        )) : (data || previousData)?.fetchArticles?.articles?.map((article: any, index: number) => (
          <Grid item xs={12} md={4} key={index}>
            <ArticleItem options={options} article={article} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
