export enum NoteTypes {
  ORGANIZATIONAL = 'ORGANIZATIONAL',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum NoteStates {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETE = 'DELETE',
}
export enum NoteObjectTypes {
  USER = 'USER',
  CLIENT_GROUP = 'CLIENT_GROUP',
}

export interface Note {
  id?: string
  objectId: string,
  objectType: NoteObjectTypes,
  content?: string,
  type?: NoteTypes,
  state?: NoteStates,
  author: any,
  createdAt?: string,
}
