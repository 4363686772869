import { createTheme, ThemeOptions } from '@mui/material';
import convert from 'color-convert';
import { colors } from './colors';
import { useThemeTokens } from '../providers/themeTokenProvider';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    notesUserTitle: true;
    notesFooter: true;
    wizardTitle: true,
    wizardSubTitle: true,
  }
}
const oneHubFonts = {
  h1: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h2: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h3: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h4: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  base: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
};

const fonts = {
  ...oneHubFonts,
};

export const useThemeOptions = (): ThemeOptions => {
  const { sys } = useThemeTokens();
  const rgb = convert.hex.rgb(sys.color.background || '#000000');

  return {
    typography: {
      fontFamily: sys.font?.base || fonts.base,
    },
    palette: {
      // mode: 'dark',
      primary: {
        main: sys.color?.primary || colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      success: {
        main: sys.color?.positive || colors.success,
      },
      // warning: {
      // main: colors.warning,
      // },
      error: {
        main: sys.color?.negative || colors.error,
      },
      background: {
        default: sys.color?.background || colors.background,
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 0,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputLabelProps: { shrink: true },
        },
        styleOverrides: {
          root: {
            '&.white': {
              borderColors: 'white',
              '& .MuiInputBase-input': {
                color: 'white',
              },
              '& fieldset': {
                borderColor: 'white',
              },
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: sys.borderRadius?.xxl,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.85)`,
          },
          invisible: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '100px',
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            p: 10,
            marginLeft: 8,
            marginRight: 8,
            minWidth: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingTop: 8,
            paddingBottom: 8,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '0px -4px 25px rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
          },
        },
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          autoHideDuration: 5000,
        },
        styleOverrides: {
          root: {
            marginTop: '32px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 8,

          },
          icon: {
            fontSize: 14,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.button-disclaimer-responsive': {
              display: 'block',
              textAlign: 'center',
              marginBottom: '16px',
            },
          },
        },
        variants: [
          {
            props: { variant: 'notesFooter' },
            style: {
              fontSize: 12,
              fontWeight: 500,
              fontFamily: sys.font?.base || fonts.base,
              lineHeight: '14.4px',
              fontStyle: 'normal',
              display: 'block',
            },
          },
          {
            props: { variant: 'notesUserTitle' },
            style: {
              fontSize: 14,
              fontWeight: 700,
              fontFamily: sys.font?.base || fonts.base,
              lineHeight: '16.8px',
              fontStyle: 'normal',
              display: 'block',
            },
          },
          {
            props: { variant: 'wizardTitle' },
            style: {
              fontSize: 28,
              fontWeight: 600,
              color: colors.wizardTitle,
              fontFamily: sys.font?.base || fonts.base,
              lineHeight: '34px',
              fontStyle: 'normal',
              display: 'block',
            },
          },
          {
            props: { variant: 'wizardSubTitle' },
            style: {
              fontSize: 24,
              fontWeight: 500,
              color: colors.black,
              fontFamily: sys.font?.base || fonts.base,
              lineHeight: '20px',
              fontStyle: 'normal',
              display: 'block',
            },
          },
        ],
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Causten Round';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Causten Round'), local('Causten Round Regular'), url('./fonts/Causten-Round-Regular.ttf') format('otf');
          }
          @font-face {
            font-family: 'Causten Round';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Causten Round'), local('Causten Round Semi Bold'), url('./fonts/Causten-Round-Semi-Bold.ttf') format('otf');
          }
          @font-face {
            font-family: 'Causten Round';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('Causten Round'), local('Causten Round Bold'), url('./fonts/Causten-Round-Bold.ttf') format('otf');
          }
          @font-face {
            font-family: 'Causten Round';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Causten Round'), local('Causten Round Extra Bold'), url('./fonts/Causten-Round-Extra-Bold.ttf') format('otf');
          }
          @font-face {
            font-family: 'Causten Round';
            font-style: normal;
            font-display: swap;
            font-weight: 800;
            src: local('Causten Round'), local('Causten Round Black'), url('./fonts/Causten-Round-Black.ttf') format('otf');
          }
          @font-face {
            font-family: 'Interphases Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('Interphases Pro'), local('TT Interphases Pro DemiBold'), url('./fonts/interphasesPro/TT-Interphases-Pro-DemiBold.ttf') format('otf');
          }
          @font-face {
            font-family: 'Interphases Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Interphases Pro'), local('TT Interphases Pro Medium'), url('./fonts/interphasesPro/TT-Interphases-Pro-Medium.ttf') format('otf');
          }
          @font-face {
            font-family: 'Interphases Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Interphases Pro'), local('TT Interphases Pro Regular'), url('./fonts/interphasesPro/TT-Interphases-Pro-Regular.ttf') format('otf');
          }
          @font-face {
            font-family: 'Tiempos Headline';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Tiempos Headline'), local('Tiempos Headline Regular'), url('./fonts/TiemposText-Regular.ttf') format('otf');
          }
        `,
      },
    },
  };
};

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: fonts.base,
  },
  palette: {
    // mode: 'dark',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    // warning: {
    // main: colors.warning,
    // },
    error: {
      main: colors.error,
    },
    background: {
      default: colors.background,
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
      styleOverrides: {
        root: {
          '&.white': {
            borderColors: 'white',
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& fieldset': {
              borderColor: 'white',
            },
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          p: 10,
          marginLeft: 8,
          marginRight: 8,
          minWidth: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px -4px 25px rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 5000,
      },
      styleOverrides: {
        root: {
          marginTop: '32px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,

        },
        icon: {
          fontSize: 14,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.button-disclaimer-responsive': {
            display: 'block',
            textAlign: 'center',
            marginBottom: '16px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'notesFooter' },
          style: {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: fonts.base,
            lineHeight: '14.4px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'notesUserTitle' },
          style: {
            fontSize: 14,
            fontWeight: 700,
            fontFamily: fonts.base,
            lineHeight: '16.8px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'wizardTitle' },
          style: {
            fontSize: 28,
            fontWeight: 600,
            color: colors.wizardTitle,
            fontFamily: fonts.base,
            lineHeight: '34px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'wizardSubTitle' },
          style: {
            fontSize: 24,
            fontWeight: 500,
            color: colors.black,
            fontFamily: fonts.base,
            lineHeight: '20px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Causten Round';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Causten Round'), local('Causten Round Regular'), url('./fonts/Causten-Round-Regular.ttf') format('otf');
        }
        @font-face {
          font-family: 'Causten Round';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Causten Round'), local('Causten Round Semi Bold'), url('./fonts/Causten-Round-Semi-Bold.ttf') format('otf');
        }
        @font-face {
          font-family: 'Causten Round';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Causten Round'), local('Causten Round Bold'), url('./fonts/Causten-Round-Bold.ttf') format('otf');
        }
        @font-face {
          font-family: 'Causten Round';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Causten Round'), local('Causten Round Extra Bold'), url('./fonts/Causten-Round-Extra-Bold.ttf') format('otf');
        }
        @font-face {
          font-family: 'Causten Round';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Causten Round'), local('Causten Round Black'), url('./fonts/Causten-Round-Black.ttf') format('otf');
        }
        @font-face {
          font-family: 'Interphases Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Interphases Pro'), local('TT Interphases Pro DemiBold'), url('./fonts/interphasesPro/TT-Interphases-Pro-DemiBold.ttf') format('otf');
        }
        @font-face {
          font-family: 'Interphases Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Interphases Pro'), local('TT Interphases Pro Medium'), url('./fonts/interphasesPro/TT-Interphases-Pro-Medium.ttf') format('otf');
        }
        @font-face {
          font-family: 'Interphases Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Interphases Pro'), local('TT Interphases Pro Regular'), url('./fonts/interphasesPro/TT-Interphases-Pro-Regular.ttf') format('otf');
        }
        @font-face {
          font-family: 'Tiempos Headline';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Tiempos Headline'), local('Tiempos Headline Regular'), url('./fonts/TiemposText-Regular.ttf') format('otf');
        }
      `,
    },
  },
};

export default createTheme(themeOptions);
