import { CSSProperties } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { SxProps } from '@mui/material';

export const Icon = ({ icon, size = 'medium', sx }: {
  icon: SvgIconComponent,
  size?: 'xSmall' | 'small' | 'medium' | 'large' | string,
  sx?: SxProps,
}) => {
  const NewIcon = icon;
  const style: CSSProperties = {};

  switch (size) {
    case 'xSmall':
      style.width = '12px';
      style.height = '12px';
      break;
    case 'small':
      style.width = '16px';
      style.height = '16px';
      break;
    case 'medium':
      style.width = '24px';
      style.height = '24px';
      break;
    case 'large':
      style.width = '32px';
      style.height = '32px';
      break;
    default:
      style.width = size;
      style.height = size;
      break;
  }

  return (<NewIcon style={style} sx={sx}/>);
};
