import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useContext } from 'react';
import { SelectField } from '../../2-component';
import { Theme } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_AVAILABLE_THEMES = gql`
  query fetchAvailableThemes($input: FetchAvailableThemesInput!) {
    fetchAvailableThemes(input: $input) {
      themes {
        translatedName {
          en fr
        }
        translatedDescription {
          en fr
        }
        id
      }
    }
  }
`;

interface ThemeSelectProp {
  label: string,
  onThemeSelect: (theme: Theme) => void,
  selectedTheme: Theme | undefined,
  sx?: any,
  size?: 'small' | 'medium',
}

export const ThemeSelect = ({
  label, onThemeSelect, selectedTheme, sx = {}, size,
}: ThemeSelectProp) => {
  const { activeOrganization } = useContext(UserContext);

  const { data, loading } = useQuery(FETCH_AVAILABLE_THEMES, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        suitabilityScore: 100,
      },
    },
  });
  return (
    <SelectField
      onChange={(e: any) => onThemeSelect(data?.fetchAvailableThemes?.themes?.find((item: Theme) => item.id === e.target.value))}
      label={label}
      fullWidth
      sx={sx}
      size={size}
      value={selectedTheme?.id ?? ''}
      disabled={loading}
    >
      {
        data?.fetchAvailableThemes?.themes?.map((theme: Theme) => (
          <MenuItem value={theme.id}>{translateBackend(theme.translatedName)}</MenuItem>
        )) ?? []
      }
    </SelectField>
  );
};
