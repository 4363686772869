import {
  Card, Box, CircularProgress, Stack, Menu, Checkbox, FormControlLabel, FormGroup, ListItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RoundButton } from 'components/inputs/roundButton';
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from 'react';
import { Typography, colors } from 'ovComponents';
import { SubAccountCard } from './subAccountCard';
import { SubAccount, SubAccountStates } from '../../interfaces';
import SubAccountTableHeader from './subAccountTableHeader';
import { CreateSubAccountWizardButton } from '../wizards/createSubAccountWizard/button';
import { usePermissions } from '../../providers/userContextProvider';

export const FETCH_SUB_ACCOUNTS = (permissions: string[]) => gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        id
        name
        state
        createdAt
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
            statistics {
              marketValueCents
            }
            subAccounts {
              id
              account {
                type
              }
              goal {
                name
                financialProduct {
                  translatedName {
                    en
                  }
                }
              }
              financialProduct {
                translatedName {
                  en
                }
              }
            }
          }
        }
        goal {
          id
          type
          name
          financialProduct {
            id
            translatedName {
              en
            }
          }
        }
        skipIPS
        allowClientDeposits
        isPartial
        theme {
          id
          translatedName {
            en
          }
        }
        financialProduct {
          id
          translatedName {
            en
          }
          riskReturnLevel
        }
        suggestedFinancialProduct {
          id
          translatedName {
            en
          }
        }
        applicableBillingSchedule {
          id
          frequency
        }
        applicableFeeTier{
          id
          flatAnnualFeeBps
        }
        statistics {
          marketValueCents
          simpleReturnAmount
          simpleReturnPercent
        }
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNTS_LOW_RES = (permissions: string[]) => gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        id
        name
        state
        createdAt
        account {
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
            statistics {
              marketValueCents
            }
            subAccounts {
              id
            }
          }
        }
        goal {
          type
          name
          financialProduct {
            id
            translatedName {
              en
            }
          }
        }
        financialProduct {
          id
          translatedName {
            en
          }
          riskReturnLevel
        }
        suggestedFinancialProduct {
          id
          translatedName {
            en
          }
        }
        statistics {
          marketValueCents
        }
        skipIPS
        allowClientDeposits
        isPartial
      }
    }
  }
`;
const SubAccounts = ({
  ofType, ofId, disabled, hasClientGroup,
}: { ofType: 'GOAL' | 'ACCOUNT', ofId: string, disabled?: boolean, hasClientGroup?: boolean }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { loading, data, refetch } = useQuery(FETCH_SUB_ACCOUNTS_LOW_RES(permissions), {
    variables: {
      input: {
        filter: {
          ...ofType === 'GOAL' && { goalId: ofId },
          ...ofType === 'ACCOUNT' && { accountId: ofId },
        },
        pagination: { perPage: 1000 },
      },
    },
  });
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>({
    [SubAccountStates.ACTIVE]: true,
    [SubAccountStates.AWAITING_APPROVAL]: true,
    [SubAccountStates.INACTIVE]: false,
  });

  if (loading || permissions.length === 0) {
    return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress sx={{ marginTop: 50 }} />
    </Box>
    );
  }

  const statesShown = Object.keys(filterByState).filter((key) => filterByState[key]);
  const subAccounts = data?.fetchSubAccounts?.subAccounts.filter((x: any) => statesShown.includes(x.state));
  const totalMarketValue = subAccounts.length > 0 ? subAccounts.map((x: SubAccount) => x.statistics?.marketValueCents).reduce((a: number, b: number) => a + b) : 0;

  const FilterByStateMenu = () => (
    <ListItem>
      <Stack>
        <Typography variant='headingXSmall'>{t('subaccount:subaccountStateLabel')}</Typography>
        { Object.values(SubAccountStates).map((state, index) => <FormGroup key={index}>
            <FormControlLabel
              control={<Checkbox checked={filterByState[state]}/>}
              onClick={() => setFilterByState({ ...filterByState, [state]: !filterByState[state] })}
              label={t(`subAccountState.${state}`)}
            />
          </FormGroup>)}
        </Stack>
    </ListItem>
  );

  return (
    <Card variant="outlined" sx={{ mb: '20px', border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
      <Box sx={{ margin: '15px 15px' }}>
        <Box display="flex" alignItems="center" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} mb="20px" mr="24px">
          <Typography variant="headingSmall" sx={{ fontWeight: 600, mr: 0.7 }}>{t('subaccount:subAccount')}</Typography>
          <Box>
            <RoundButton color='secondary'
              style={{ backgroundColor: filterMenuAnchorEl && colors.neutral300 }}
              onClick={(e:React.MouseEvent<HTMLElement>) => setFilterMenuAnchorEl(filterMenuAnchorEl ? null : e.currentTarget)}
            >
              <TuneIcon/>
            </RoundButton>
            <Menu
              open={!!filterMenuAnchorEl}
              anchorEl={filterMenuAnchorEl}
              onClose={() => setFilterMenuAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <FilterByStateMenu/>
            </Menu>
            <CreateSubAccountWizardButton forObject={ofType} forId={ofId} disabled={disabled} />
          </Box>
        </Box>
        <SubAccountTableHeader type={ofType} hasClientGroup={hasClientGroup}/>
        <Stack spacing={2}>
          {subAccounts.map((subaccount: SubAccount, index: number) => (
            <SubAccountCard key={index} ofType={ofType} subaccount={subaccount} totalMarketValue={totalMarketValue} hasClientGroup={hasClientGroup} refetch={refetch} />
          ))}
        </Stack>
        {
          (subAccounts.length === 0)
          && <Box sx={{
            display: 'flex', alignItems: 'center', margin: '50px', justifyContent: 'center',
          }}>
            <Typography variant='bodyLarge'>{t(`subaccount:noLinkedSubAccountTo${ofType}`)}</Typography>
          </Box>
        }
      </Box>
    </Card>
  );
};

export default SubAccounts;
