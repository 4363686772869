import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { SubStepTypes } from 'interfaces';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { EmploymentInformationVisual } from './employmentInformation.visual';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER = (derivedCustomFields?: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        employmentStatus
        companyType
        jobTitle
        studentAreaOfStudy
        employerName
        employerCompanyAddress { city province streetName postal unitNumber houseNumber neighborhood country }
        employmentSituation
        employmentSituationOtherDescription
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const EmploymentInformation = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const enabledCustomFields = getEnabledCustomFields(options);
  const [userData, setUserData] = useState<any>({
    employmentStatus: '',
    companyType: '',
    jobTitle: '',
    studentAreaOfStudy: '',
    employerName: '',
    employmentSituation: '',
    employmentSituationOtherDescription: '',
    employerCompanyAddress: {
      city: '',
      province: '',
      streetName: '',
      postal: '',
      unitNumber: '',
      houseNumber: '',
      neighborhood: '',
      country: '',
    },
    customFields: [],
  });

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.EMPLOYMENT_INFORMATION,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      await fetchUser({
        variables: {
          userId,
        },
        query: customFieldKeys ? FETCH_USER(customFieldKeys) : undefined,
      });
    },
  });

  const [fetchUser, { data, loading }] = useLazyQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: defaultdata } = useQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0,
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        employerCompanyAddress: isEmpty(userData.employerCompanyAddress) ? undefined : userData.employerCompanyAddress,
        customFields: modifyUpdatingCustomFields(userData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
        userId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        employmentStatus: data.fetchUser.user.employmentStatus || undefined,
        companyType: data.fetchUser.user.companyType || undefined,
        jobTitle: data.fetchUser.user.jobTitle || undefined,
        studentAreaOfStudy: data.fetchUser.user.studentAreaOfStudy || undefined,
        employerName: data.fetchUser.user.employerName || undefined,
        employmentSituation: data.fetchUser.user.employmentSituation || undefined,
        employmentSituationOtherDescription: data.fetchUser.user.employmentSituationOtherDescription || undefined,
        employerCompanyAddress: data.fetchUser.user.employerCompanyAddress ? {
          city: data.fetchUser.user.employerCompanyAddress?.city || undefined,
          province: data.fetchUser.user.employerCompanyAddress?.province || undefined,
          streetName: data.fetchUser.user.employerCompanyAddress?.streetName || undefined,
          postal: data.fetchUser.user.employerCompanyAddress?.postal || undefined,
          unitNumber: data.fetchUser.user.employerCompanyAddress?.unitNumber || undefined,
          houseNumber: data.fetchUser.user.employerCompanyAddress?.houseNumber || undefined,
          neighborhood: data.fetchUser.user.employerCompanyAddress?.neighborhood || undefined,
          country: data.fetchUser.user.employerCompanyAddress?.country || undefined,
        } : {},
        customFields: data.fetchUser?.user?.customFields || [],
      });
    }
    if (defaultdata) {
      setUserData({
        employmentStatus: defaultdata.fetchUser.user.employmentStatus || undefined,
        companyType: defaultdata.fetchUser.user.companyType || undefined,
        jobTitle: defaultdata.fetchUser.user.jobTitle || undefined,
        studentAreaOfStudy: defaultdata.fetchUser.user.studentAreaOfStudy || undefined,
        employerName: defaultdata.fetchUser.user.employerName || undefined,
        employmentSituation: defaultdata.fetchUser.user.employmentSituation || undefined,
        employmentSituationOtherDescription: defaultdata.fetchUser.user.employmentSituationOtherDescription || undefined,
        employerCompanyAddress: defaultdata.fetchUser.user.employerCompanyAddress ? {
          city: defaultdata.fetchUser.user.employerCompanyAddress?.city || undefined,
          province: defaultdata.fetchUser.user.employerCompanyAddress?.province || undefined,
          streetName: defaultdata.fetchUser.user.employerCompanyAddress?.streetName || undefined,
          postal: defaultdata.fetchUser.user.employerCompanyAddress?.postal || undefined,
          unitNumber: defaultdata.fetchUser.user.employerCompanyAddress?.unitNumber || undefined,
          houseNumber: defaultdata.fetchUser.user.employerCompanyAddress?.houseNumber || undefined,
          neighborhood: defaultdata.fetchUser.user.employerCompanyAddress?.neighborhood || undefined,
          country: defaultdata.fetchUser.user.employerCompanyAddress?.country || undefined,
        } : {},
        customFields: defaultdata.fetchUser?.user?.customFields || [],
      });
    }
  }, [data, defaultdata]);

  return (
    <EmploymentInformationVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading || customFieldLoading}
      activeCustomFields={derivedCustomFieldsKeys}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default EmploymentInformation;
