import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../providers/userContextProvider';
import { FeeTier, FeeTierStates } from '../../interfaces';

export const FETCH_FEE_TIERS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      feeTiers {
        id
        name
        organization {
          id
        }
      }
    }
  }
`;

const FeeGridSelect = ({
  setValue, value, label, disabled = false, readonly = false, size, includeAnyOption = false, organizationId, focused, showInherited,
}: {
  setValue: (newValue: string) => void, value: string, label: string, disabled?: boolean, readonly?: boolean, size?: 'small' | 'medium',
  includeAnyOption?: boolean, organizationId?: string, focused?: boolean, showInherited?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'components']);
  const { activeOrganization } = useContext(UserContext);
  const { error, data } = useQuery(FETCH_FEE_TIERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          states: FeeTierStates.ACTIVE,
        },
        pagination: { perPage: 1000 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <TextField
      select
      focused={focused}
      value={value === '' ? 'any' : value}
      label={label}
      fullWidth
      onChange={(event) => { setValue(event.target.value === 'any' ? '' : event.target.value); }}
      disabled={disabled}
      size={size}
      InputProps={{
        readOnly: readonly,
      }}
    >
      { includeAnyOption && (
        <MenuItem value="any">{t('components:any')}</MenuItem>
      )}
      {
        showInherited && (
          <MenuItem key="inherited" value="inherited">
            {t('feeAndBilling:feeGrid.inherited')}
          </MenuItem>
        )
      }
      {
        (
          data?.fetchFeeTiers.feeTiers.map((x: FeeTier) => (
            <MenuItem
              key={ x.id }
              value={ x.id }
            >
              {x.name}
            </MenuItem>
          ))
        )
      }
    </TextField>
  );
};

export default FeeGridSelect;
