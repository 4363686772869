import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  ListItem,
} from '@mui/material';
import { round } from 'lodash';
import { useGlobalToast } from '../../providers/globalToastProvider';
import AmountField from '../inputs/amountField';
import { SubAccountBilling } from '../../interfaces/subAccountBilling';
import FormModal from './formModal';
import NewNote, { CREATE_NOTE, TRANSITION_NOTE } from '../notes/newNote';
import { NoteObjectTypes, NoteTypes } from '../../interfaces/note';
import { Checkbox } from '../../ovComponents/2-component';

const MAX_CHARACTER_LIMIT = 1000;

const SALES_TAX_AB = 0.05;
const SALES_TAX_BC = 0.05;
const SALES_TAX_MB = 0.05;
const SALES_TAX_NB = 0.15;
const SALES_TAX_NL = 0.15;
const SALES_TAX_NT = 0.05;
const SALES_TAX_NS = 0.15;
const SALES_TAX_NU = 0.05;
const SALES_TAX_ON = 0.13;
const SALES_TAX_PE = 0.15;
const SALES_TAX_QC = 0.14975;
const SALES_TAX_SK = 0.05;
const SALES_TAX_YT = 0.05;

// TODO fetch the actual tax rates from backend (see defineUserSalesTaxExpression in fee-service)
const calculateAdjustedSalesTaxCents = (province: string, adjustedFeeCents?: number): number => {
  if (adjustedFeeCents) {
    switch (province) {
      case 'AB':
        return (adjustedFeeCents * SALES_TAX_AB);

      case 'BC':
        return (adjustedFeeCents * SALES_TAX_BC);

      case 'MB':
        return (adjustedFeeCents * SALES_TAX_MB);

      case 'NB':
        return (adjustedFeeCents * SALES_TAX_NB);

      case 'NL':
        return (adjustedFeeCents * SALES_TAX_NL);

      case 'NT':
        return (adjustedFeeCents * SALES_TAX_NT);

      case 'NS':
        return (adjustedFeeCents * SALES_TAX_NS);

      case 'NU':
        return (adjustedFeeCents * SALES_TAX_NU);

      case 'ON':
        return (adjustedFeeCents * SALES_TAX_ON);

      case 'PE':
        return (adjustedFeeCents * SALES_TAX_PE);

      case 'QC':
        return (adjustedFeeCents * SALES_TAX_QC);

      case 'SK':
        return (adjustedFeeCents * SALES_TAX_SK);

      case 'YT':
        return (adjustedFeeCents * SALES_TAX_YT);

      default:
        return 0;
    }
  }
  return 0;
};

export const UPDATE_SUB_ACCOUNT_BILLING = gql`
  mutation updateSubAccountBilling($input: UpdateSubAccountBillingInput!) {
    updateSubAccountBilling(input: $input) {
      subAccountBilling {
        id
      }
    }
  }
`;

interface SubAccountBillingUpdateInputPros {
  adjustedFeeCents: number,
  adjustedSalesTaxCents: number,
  notes?: string,
}

const emptyInputState = { adjustedFeeCents: 0, adjustedSalesTaxCents: 0 };

const OverrideFeesModal = ({
  afterUpdate, subAccountBilling, subAccountTitle, open, handleClose,
}: { afterUpdate: () => void, subAccountBilling: SubAccountBilling, subAccountTitle: string, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation();
  const { showToast } = useGlobalToast();
  const [updateInput, setUpdateInput] = useState<SubAccountBillingUpdateInputPros>(emptyInputState);
  const [checked, setChecked] = useState<boolean>(false);
  const [note, setNote] = useState({
    content: updateInput.notes ?? '',
    type: NoteTypes.ORGANIZATIONAL,
    objectId: subAccountBilling?.subAccount?.account?.user?.id,
    objectType: NoteObjectTypes.USER,
  });

  const overriden = () => subAccountBilling.adjustedFeeCents && subAccountBilling?.adjustedFeeCents > 0;

  useEffect(() => {
    setUpdateInput({
      adjustedFeeCents: subAccountBilling?.adjustedFeeCents ?? 0,
      adjustedSalesTaxCents: subAccountBilling?.adjustedSalesTaxCents ?? 0,
      notes: subAccountBilling?.notes,
    });
  }, [subAccountBilling]);

  const [updateSubAccountBilling, { loading }] = useMutation(UPDATE_SUB_ACCOUNT_BILLING, {
    variables: {
      input: {
        adjustedFeeCents: updateInput.adjustedFeeCents,
        adjustedSalesTaxCents: updateInput.adjustedSalesTaxCents,
        notes: updateInput.notes,
        subAccountBillingId: subAccountBilling.id,
      },
    },
  });

  const [createNote] = useMutation(CREATE_NOTE, {
    variables: {
      input: note,
    },
    onCompleted: (value: any) => {
      if (value?.createNote?.note?.id) {
        publishNote({
          variables: {
            input: {
              noteId: value.createNote.note.id,
              transition: 'publish',
            },
          },
        });
      }
    },
  });
  const [publishNote] = useMutation(TRANSITION_NOTE);

  const update = async (event: any) => {
    event.preventDefault();
    if (overriden()) {
      // TODO Delete note that was created on update (back end required)
      const response = await updateSubAccountBilling({
        variables: {
          input: {
            adjustedFeeCents: 0,
            adjustedSalesTaxCents: 0,
            notes: undefined,
            subAccountBillingId: subAccountBilling.id,
          },
        },
      });
      if (response?.data) {
        showToast({ severity: 'info', message: t('feeAndBilling:feeReport.overrideFees.undoMessage') });
      }
      setUpdateInput(emptyInputState);
      handleClose();
      afterUpdate();
    } else {
      const response = await updateSubAccountBilling();
      if (response?.data) {
        showToast({ severity: 'info', message: t('feeAndBilling:feeReport.overrideFees.toastMessage') });
      }
      if (checked) await createNote();
      setUpdateInput(emptyInputState);
      afterUpdate();
      handleClose();
    }
  };

  return (
    <FormModal
      open={open}
      loading={loading}
      title={t('feeAndBilling:feeReport.overrideFees.title')}
      formButton={overriden() ? t('shared:undo') : t('shared:save')}
      onSubmit={update}
      handleClose={handleClose}
    >
      <ListItem>
        <AmountField
          label={t('feeAndBilling:feeReport.overrideFees.adjustedFeeCents')}
          variant="outlined"
          value={updateInput.adjustedFeeCents}
          fullWidth
          onChange={(e: any) => {
            setUpdateInput((prev) => ({
              ...prev,
              adjustedFeeCents: e.target.valueCents,
              adjustedSalesTaxCents: round(calculateAdjustedSalesTaxCents(
                subAccountBilling?.subAccount?.account?.user?.physicalAddress.province,
                e.target.valueCents,
              )),
            }));
          }}
        />
      </ListItem>
      <ListItem>
        <AmountField
          label={t('feeAndBilling:feeReport.overrideFees.adjustedSalesTaxCents')}
          variant="outlined"
          value={round(updateInput.adjustedSalesTaxCents)}
          fullWidth
          disabled
        />
      </ListItem>
      <ListItem>
        <NewNote
          title={t('feeAndBilling:feeReport.overrideFees.note')}
          characterCountLimiter={MAX_CHARACTER_LIMIT}
          noteObjectType={NoteObjectTypes.USER}
          hidePostAndClearButton={true}
          onChange={(e: any) => {
            setUpdateInput((prev) => ({
              ...prev,
              notes: e,
            }));
            setNote((n) => ({ ...n, content: `${e} - Note from SubAccount: ${subAccountTitle}` }));
          }}
        />
      </ListItem>
      <ListItem>
        <Checkbox
          label={t('feeAndBilling:feeReport.overrideFees.copyToClient')}
          checked={checked}
          onChange={(e) => setChecked(e)}
        />
      </ListItem>
    </FormModal>
  );
};

export default OverrideFeesModal;
