import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, TextField, Grid,
  InputAdornment, Menu, ListItem, Stack, FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { colors } from 'ovComponents';
import Search from '@mui/icons-material/Search';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoundButton } from 'components/inputs/roundButton';
import TuneIcon from '@mui/icons-material/Tune';
import { UserContext } from '../../../providers/userContextProvider';
import { NewHousehold } from './newHousehold';
import { usePageState } from '../../../util/usePageState';
import { ClientGroupTypes } from '../../../interfaces';

interface Household {
  id: string,
  type: string,
  name: string,
  organization: {
    name: string,
  },
  primaryUser: {
    firstName: string,
    primaryEmail: string,
  }
  relationships: any[],
}
export const FETCH_HOUSEHOLDS = gql`
  query fetchHouseholds($input: FetchClientGroupsInput!) {
    fetchClientGroups(input: $input) {
      clientGroups{
        id
        name
        type
        primaryUser{
          firstName
          primaryEmail
        }
        organization{
          name
        }
        relationships{
          type
          user{
            id
          }
        }
      }
      totalCount
    }
  }
`;

export const HouseHoldsTable = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['clients', 'household']);
  const navigate = useNavigate();
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>({ ACTIVE: true, ARCHIVED: false });
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const pageSize = 15;
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_HOUSEHOLDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          type: ClientGroupTypes.Household,
          searchText,
          states: Object.keys(filterByState).filter((key) => filterByState[key]),
        },
        pagination: {
          sortField: 'name', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);

  const HouseholdsFilterMenu = () => (
    <ListItem>
      <Stack>
        <Typography>{t('household:householdStateLabel')}</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={filterByState.ACTIVE}/>}
            onClick={() => setFilterByState({ ...filterByState, ACTIVE: !filterByState.ACTIVE })}
            label={t('household:householdState.ACTIVE')} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={filterByState.ARCHIVED}/>}
            onClick={() => setFilterByState({ ...filterByState, ARCHIVED: !filterByState.ARCHIVED })}
            label={t('household:householdState.ARCHIVED')} />
        </FormGroup>
      </Stack>
    </ListItem>
  );

  return (
    <Box sx={{ overflowX: 'auto' }}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <TextField
                label={t('search')}
                sx={{ width: '300px', m: 1 }}
                size='small'
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  setPage(1);
                }}
                id='searchText'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <div>
                <RoundButton color='secondary'
                  style={{ backgroundColor: filterMenuAnchorEl && colors.neutral300, marginTop: '6px' }}
                  onClick={(e:React.MouseEvent<HTMLElement>) => setFilterMenuAnchorEl(filterMenuAnchorEl ? null : e.currentTarget)}
                >
                  <TuneIcon/>
                </RoundButton>
                <Menu
                  open={!!filterMenuAnchorEl}
                  anchorEl={filterMenuAnchorEl}
                  onClose={() => setFilterMenuAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <HouseholdsFilterMenu/>
                </Menu>
                <NewHousehold afterCreate={refetch} />
              </div>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('household:table.headers.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('household:table.headers.contact')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('household:table.headers.email')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('household:table.headers.relationCount')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('household:table.headers.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchClientGroups?.clientGroups?.map((houseHoldItem: Household) => (
                <TableRow
                  hover
                  key={houseHoldItem.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/households/${houseHoldItem.id}`)}
                >
                  <TableCell component="th" scope="row">
                    {houseHoldItem.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {houseHoldItem.primaryUser.firstName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {houseHoldItem.primaryUser.primaryEmail}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {houseHoldItem.relationships.length}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {houseHoldItem.organization.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchClientGroups?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};
