import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface StatusBarTokens {
  statusBar: {
    containerColor?: string;
    borderRadius?: string;
    activeColor?: string;
    borderColor?: string,
    elevation?: string;
  },
}

export const useStatusBarTokens = (): StatusBarTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: StatusBarTokens = {
    statusBar: {
      containerColor: sys.color.warningVariant,
      borderRadius: sys.borderRadius.lg,
      activeColor: sys.color.warning,
      borderColor: sys.color.warning,
      elevation: sys.elevation.surface,
    },
  };

  return comp;
};
