import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton, Menu, MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CancelWorkFlowDialog from 'ovComponents/3-pattern/cancelWorkFlowDialog/cancelWorkFlowDialog';
import { WorkflowCompletion } from '../workflowCompletion/workflowCompletion';
import {
  Box, Grid, PageObjectType, StatusBar, Typography,
} from '../..';
import { UserContext } from '../../../providers/userContextProvider';
import { UnsignedDocumentsWorkflow } from '../unsignedDocumentsWorkflow/unsignedDocumentsWorkflow';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';

interface ActiveWorkflow {
  name: string,
  id: string,
  percentage: number,
  workflowCompletion: any,
  currentSubStep: any,
  currentStep: any,
}

export const ActiveWorkflowsVisual = ({
  activeWorkflows, incompleteDocuments, refetch,
  activeWorkflowCompletionId, setActiveWorkflowCompletionId,
  userId,
}: {
  activeWorkflows: ActiveWorkflow[], incompleteDocuments: boolean, refetch: any,
  activeWorkflowCompletionId?: string, setActiveWorkflowCompletionId: (id: string) => void
  userId?: string
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [open, setOpen] = useState(false);
  const [iDOpen, setIDOpen] = useState(false);
  const { userContext } = useContext(UserContext);
  const [workflowCompletion, setWorkflowCompletion] = useState({});
  const [openMenu, setOpenMenu] = useState<{
    anchorElement: null | HTMLElement,
    open: boolean,
    index: number,
  }>({ anchorElement: null, open: false, index: 0 });
  const [openCloseWorkflowConfirmation, setOpenCloseWorkflowConfirmation] = useState({ open: false, workflowId: '' });
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.stopPropagation();
    setOpenMenu({ anchorElement: event.currentTarget, open: true, index });
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenMenu({ anchorElement: null, open: false, index: 0 });
  };

  useEffect(() => {
    if (activeWorkflowCompletionId) {
      const aw = activeWorkflows.find((activeWorkflow: ActiveWorkflow) => activeWorkflow.id === activeWorkflowCompletionId);
      if (aw) {
        setOpen(true);
        setWorkflowCompletion(aw.workflowCompletion);
      }
    }
  }, [activeWorkflowCompletionId, activeWorkflows]);

  return (<Grid container spacing={2} mb={3} mt={1} sx={{ transition: 'all .5s', height: (activeWorkflows.length + (incompleteDocuments ? 1 : 0)) > 0 ? 'auto' : '0px' }}>
    {(incompleteDocuments && userId) && (
      <Grid item xs={12} md={(activeWorkflows.length + (incompleteDocuments ? 1 : 0)) > 1 ? 6 : 12}>
        <StatusBar percentage={0} testId='data-test-id-sign-documents' onClick={() => {
          setIDOpen(true);
        }}>
          <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center' width={0} flex={1}>
              <NotificationImportantIcon sx={{ mr: 1 }} />
              <Typography variant='bodyLarge' sx={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', pr: 2, alignItems: 'center',
              }}>
                <b>Sign Documents</b>
              </Typography>
            </Box>
          </Box>
        </StatusBar>
        <UnsignedDocumentsWorkflow setOpen={setIDOpen} open={iDOpen} objectId={userId} objectType={PageObjectType.INDIVIDUAL} />
      </Grid>
    )}
    {activeWorkflows.map((activeWorkflow: ActiveWorkflow, index: number) => (
      <Grid item xs={12} md={(activeWorkflows.length + (incompleteDocuments ? 1 : 0)) > 1 ? 6 : 12} key={activeWorkflow.id}>
        <StatusBar percentage={activeWorkflow.percentage} onClick={() => {
          if (activeWorkflow.percentage === 0) {
            sendAnalytic(ovAnalyticsEvents.homepageStartNewWorkflowSelect);
          } else {
            sendAnalytic(ovAnalyticsEvents.homepageContinueInProgressWorkflowSelect);
          }
          setOpen(true);
          setActiveWorkflowCompletionId(activeWorkflow.workflowCompletion.id);
          setWorkflowCompletion(activeWorkflow.workflowCompletion);
        }}>
          <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center' width={0} flex={1}>
              <NotificationImportantIcon sx={{ mr: 1 }} />
              <Typography variant='bodyLarge' sx={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', pr: 2, alignItems: 'center',
              }}>
                <b>{activeWorkflow.name}</b> - {activeWorkflow?.currentStep?.name.en}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant='headingXSmall'>{activeWorkflow.percentage.toFixed(0)}%</Typography>
              {userContext?.role?.navigationStyle === 'FULL' && (
                <>
                  <IconButton onClick={(e) => handleClick(e, index)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    open={openMenu.open && openMenu.index === index}
                    onClose={handleClose}
                    anchorEl={openMenu.anchorElement}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '.MuiMenu-paper': {
                        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleClose(event);
                        setOpenCloseWorkflowConfirmation({ open: true, workflowId: activeWorkflow.id });
                      }}>
                      <Typography variant="button">Cancel Workflow</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}

            </Box>
          </Box>
        </StatusBar>
      </Grid>
    ))}
    <WorkflowCompletion workflowCompletion={workflowCompletion} open={open} onClose={() => {
      setOpen(false);
      setWorkflowCompletion({});
      refetch();
    }} />
    <CancelWorkFlowDialog
      open={openCloseWorkflowConfirmation.open}
      setCloseDialog={(value) => {
        setOpenCloseWorkflowConfirmation({ open: value, workflowId: '' });
        refetch();
      }}
      worflowId={openCloseWorkflowConfirmation.workflowId}
    />
  </Grid>);
};

export default ActiveWorkflowsVisual;
