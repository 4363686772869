import { Organization } from './organization';

export enum FeeTierStates {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum FeeGridScopeTypes {
  CLIENT_GROUP = 'CLIENT_GROUP',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
}

export interface FeeGrid {
  minMarketValueCents?: number,
  annualFeeBps?: number,
  annualFeeCents?: number,
}

export interface FeeTier {
  id?: string,
  name?: string,
  default?: boolean,
  grid?: FeeGrid[],
  gridScopeType?: FeeGridScopeTypes,
  blendedGrid?: boolean,
  flatAnnualFeeBps?: number,
  discountPercentage?: number,
  state?: FeeTierStates,
  requestId?: string,
  chargeForCash?: boolean,
  chargeForCashOnHold?: boolean,
  createdAt?: Date,
  updatedAt?: Date,
  organization?: Organization,
  billableAUM?: boolean,
}
