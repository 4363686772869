import { useTranslation } from 'react-i18next';
import {
  Grid,
  Switch,
  TranslatableTextField,
} from '../..';

export const DepositTypeOptionsEdit = ({
  optionList, setOptionList,
}: {
  optionList: any, setOptionList: (x: any) => void,
}) => {
  const { t } = useTranslation('workflowCompletions');
  return (
    <>
      {optionList?.map((option: any, num: number) => (
        <Grid container display='flex' key={num} flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1}>
          <Grid item xs={10}>
            <TranslatableTextField
              label={t(`depositTypeOptions.${option.value}`)}
              value={option.label}
              fullWidth
              onChange={(value) => {
                const newOptions = [...optionList];
                newOptions[num] = { ...option, label: value };
                setOptionList(newOptions);
              }}
            />
          </Grid>
          <Grid item xs={2} justifyContent='center' display='flex' sx={{ mt: 2 }}>
            <Switch sx={{ mb: 1 }} checked={option?.enabled} onChange={(e: any) => {
              const newOptions = [...optionList];
              newOptions[num] = { ...option, enabled: e };
              setOptionList(newOptions);
            }}></Switch>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
