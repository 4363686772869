import { gql, useMutation, useQuery } from '@apollo/client';
import { ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from 'ovComponents';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';
import { FETCH_GOALS } from '../layout/goals/goalsSummary';
import { FETCH_GOAL } from '../../pages/goalsDetails';
import { getSubAccountName, SubAccount, SubAccountStates } from '../../interfaces';

const FETCH_SUBACCOUNTS_OF_GOAL = gql`
  query fetchSubAccounts($goalId: ObjectID!) {
    fetchSubAccounts(input: { filter: { goalId: $goalId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state
        account {
          id
          type
          custodianAccountNumber
          householdClientGroup { id }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

const DISSOCIATE_GOAL_FROM_HOUSEHOLD = gql`
  mutation dissociateGoalFromHousehold($goalId: ObjectID!) {
    updateGoal (
      input: {
        goalId: $goalId
        householdClientGroupId: null
      }
    ) {
      goal {
        user { id }
      }
    }
  }
`;

export const DissociateGoalFromHouseholdModal = ({
  handleClose, goalId, householdName,
}: { goalId: string, handleClose: () => void, householdName:string }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [householdSubaccounts, setHouseholdSubaccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_GOAL, {
    variables: { goalId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHouseholdSubaccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
    },
  });

  const dissociationCompleted = (data: { updateGoal: { goal: { user: { id: string } } } }): void => {
    handleClose();
    navigate(`/clients/${data.updateGoal.goal.user.id}/goal/${goalId}`);
    showToast({ message: t('goalsDetails:dissociateGoalDialog.success'), severity: 'success' });
  };

  const [dissociateAccountMutation] = useMutation(DISSOCIATE_GOAL_FROM_HOUSEHOLD, {
    variables: {
      goalId,
    },
    refetchQueries: [FETCH_GOAL(permissions), FETCH_GOALS(), FETCH_USER(permissions)],
    onCompleted: dissociationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('goalsDetails:dissociateGoalDialog.title', { name: householdName })}
      open={true}
      maxWidth='sm'
      confirmButton={t('goalsDetails:dissociateGoalDialog.continueButton')}
      cancelButton={t('goalsDetails:dissociateGoalDialog.cancelButton')}
      onConfirm={() => dissociateAccountMutation()}
      onCancel={handleClose}
    >
      <Typography component='div' sx={{ minHeight: '6em' }}>
        {loading && <Typography color={colors.neutral400}>{t('goalsDetails:dissociateGoalDialog.checkingSubaccounts')}...</Typography>}
        {householdSubaccounts.length > 0
        && <>
          {t('goalsDetails:dissociateGoalDialog.thereAreSubAccounts')}
          {householdSubaccounts.map((sa) => <ListItem key={sa.id}>{getSubAccountName(sa)}</ListItem>)}
        </>
        }
        {!loading && householdSubaccounts.length === 0 && <>
          {t('goalsDetails:dissociateGoalDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
