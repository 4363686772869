import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  User,
  RelatedEntity,
} from 'interfaces';
import { FETCH_USER_RELATED_ENTITIES } from 'pages/client/components/relatedEntitiesTable';
import { UPDATE_USER_RELATED_ENTITIES } from 'pages/client/components/relatedEntityEdit';
import { usePermissions } from 'providers/userContextProvider';
import { useGlobalToast } from 'providers/globalToastProvider';
import { RelatedEntitiesVisual } from './relatedEntities.visual';

export const RelatedEntities = ({
  options,
  userId,
  onNext,
  stepLoading,
}: {
  options: any,
  userId: string,
  onNext: () => void,
  stepLoading: boolean
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const [user, setUser] = useState<User>();

  const { loading: fetchUserLoading } = useQuery(FETCH_USER_RELATED_ENTITIES(permissions), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => setUser(data.fetchUser.user),
  });

  const [updateUserRelatedEntities, { loading: updateUserRelatedEntitiesLoading }] = useMutation(UPDATE_USER_RELATED_ENTITIES, {
    refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
  });

  const handleDraftSubmission = async (draftRelatedEntity: RelatedEntity) => {
    const existingRelatedEntitiesInputs = user?.relatedEntities?.map((relEnt) => ({ entityId: relEnt.entity.id, relation: relEnt.relation })) ?? [];

    await updateUserRelatedEntities({
      variables: {
        userId,
        relatedEntities: [
          ...existingRelatedEntitiesInputs,
          { entityId: draftRelatedEntity.entity.id, relation: draftRelatedEntity.relation },
        ],
      },
      onCompleted: () => {
        showToast({ severity: 'success', message: t('workflowCompletions:relatedEntities.newRelatedEntitySuccess') });
      },
    });
  };

  const loading = stepLoading || fetchUserLoading || updateUserRelatedEntitiesLoading;

  return (
    <RelatedEntitiesVisual
      user={user}
      options={options}
      loading={loading}
      onContinue={onNext}
      onDraftSubmission={handleDraftSubmission}
    />
  );
};
