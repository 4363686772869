import {
  List, ListItem, MenuItem, SxProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { validateFields, FormErrors, FieldOptions } from 'ovComponents/4-module/workflowCompletion/subSteps/utils';
import { useHouseholdContext } from 'pages/household';
import { useClientContext } from 'pages/client';
import { IntegrationProvider } from 'interfaces';
import { Typography } from 'ovComponents/1-primative/typography/typography.stories';
import { CountryCodes, Jurisdictions } from '@onevesthq/ov-enums';
import { unset } from 'lodash';
import { generateAddressString } from '../../../util';
import { PhysicalAddress, Integration } from '../../../interfaces';
import {
  Button, Dialog, Grid, TextField, SelectField, DialogTitle,
} from '../..';
import GoogleAddress from './googleAddress';
import CanadaPostAddress from './canadaPostAddress';
import { getCountryName } from '../../resources';

const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations($input: FetchIntegrationsInput!) {
    fetchIntegrations(input: $input) {
      integrations {
        id
        configuration
        provider
        type
      }
      totalCount
    }
  }
`;

const linkLikeButon = {
  textDecoration: 'underline',
  color: 'blue',
  backgroundColor: '#ffffff00',
  borderStyle: 'none',
  fontSize: '16px',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
};

export const AddressField = ({
  onChange,
  label,
  address,
  error = false,
  disabled = false,
  fullWidth,
  autoFocus,
  onFocus,
  sx,
  errorText,
  onBlur,
  infoTooltip,
  manualAddressEntry,
  defaultAddressProvider,
  lockMessage,
  testId,
}: {
  onChange: (e: PhysicalAddress) => void;
  label: string;
  rows?: number;
  readonly?: boolean;
  autoFocus?: boolean;
  address: PhysicalAddress;
  error?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  onBlur?: any;
  onFocus?: any;
  errorText?: string;
  infoTooltip?: string;
  manualAddressEntry?: boolean;
  defaultAddressProvider?: IntegrationProvider.GOOGLE | IntegrationProvider.CANADA_POST;
  lockMessage?: string;
  testId?: string;
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const { permissions } = usePermissions();
  const [focused, setFocused] = useState<string[]>([]);
  const { activeOrganization } = useContext(UserContext);
  const householdContext = useHouseholdContext();
  const clientContext = useClientContext();
  const [physicalAddress, setPhysicalAddress] = useState<PhysicalAddress>({
    city: address?.city || '',
    country: address?.country,
    houseNumber: address?.houseNumber || '',
    neighborhood: address?.neighborhood || '',
    postal: address?.postal || '',
    jurisdiction: address?.jurisdiction || '',
    streetName: address?.streetName || '',
    unitNumber: address?.unitNumber || '',
  });
  const [providerFault, setProviderFault] = useState(false);

  const fieldOptions: FieldOptions = useMemo(
    () => ({
      city: { required: true },
      country: { required: true },
      houseNumber: { required: true },
      neighborhood: { required: false },
      postal: { required: true },
      ...(physicalAddress?.country === CountryCodes.CA || physicalAddress?.country === CountryCodes.US ? { jurisdiction: { required: true } } : {}),
      streetName: { required: true },
      unitNumber: { required: false },
    }),
    [physicalAddress.country],
  );

  const organizationId = clientContext?.orgSettings.id || householdContext?.orgSettings.id || activeOrganization?.id;
  const allowedCountries = useMemo(() => {
    let codes = activeOrganization.applicableLocalization?.countries ?? [CountryCodes.CA];
    if (address?.country && !codes.includes(address.country as CountryCodes)) codes = [address.country as CountryCodes, ...codes];
    return codes;
  }, [activeOrganization.applicableLocalization?.countries, address?.country]);

  const { data } = useQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId,
          type: 'ADDRESS',
        },
      },
    },
    skip: !permissions.includes('read:integrations'),
  });
  const integrationsList: Integration[] = data?.fetchIntegrations?.integrations;
  const googleMapApiKey = integrationsList?.find((x) => x.provider === 'GOOGLE')?.configuration?.apiKey;
  const canadaPostApiKey = integrationsList?.find((x) => x.provider === 'CANADA_POST')?.configuration?.apiKey;

  const getAddressProvider = () => {
    if (defaultAddressProvider) return defaultAddressProvider;
    if (address?.country === CountryCodes.US && googleMapApiKey) return IntegrationProvider.GOOGLE;
    if (allowedCountries.includes(CountryCodes.CA) && canadaPostApiKey) return IntegrationProvider.CANADA_POST;
    return IntegrationProvider.GOOGLE;
  };

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, physicalAddress, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [physicalAddress, fieldOptions],
  );

  useEffect(() => {
    if (address) {
      unset(address, '__typename');
      setPhysicalAddress(address);
    }
  }, [address]);

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    setOpen(false);
    onChange(physicalAddress);
  };

  const jurisdictions: Jurisdictions[] = useMemo(() => Object.values(Jurisdictions).filter((j) => j.startsWith(`${physicalAddress?.country}_`)), [physicalAddress?.country]);

  const jurisdictionLabel: string = useMemo(() => {
    switch (physicalAddress?.country) {
      case CountryCodes.CA:
        return t('components:address.province');
      case CountryCodes.US:
        return t('components:address.state');
    }
    return '';
  }, [physicalAddress?.country, t]);

  const postalCodeLabel: string = useMemo(() => {
    switch (physicalAddress?.country) {
      case CountryCodes.CA:
        return t('components:address.postal');
      case CountryCodes.US:
        return t('components:address.zip');
      case CountryCodes.GB:
        return t('components:address.postcode');
    }
    return '';
  }, [physicalAddress?.country, t]);

  const formButtonDisabled = !physicalAddress.houseNumber
    || !physicalAddress.streetName
    || !physicalAddress.city
    || !physicalAddress.country
    || !physicalAddress.postal
    || !physicalAddress.jurisdiction;

  return (
    <>
      {getAddressProvider() === IntegrationProvider.GOOGLE && providerFault === false && (
        <GoogleAddress
          testId={testId ?? 'google-address'}
          label={label}
          sx={sx}
          allowedCountries={allowedCountries}
          addressString={generateAddressString(physicalAddress)}
          addressCountry={physicalAddress?.country}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          error={error}
          disabled={disabled}
          apiKey={googleMapApiKey}
          infoTooltip={infoTooltip}
          lockMessage={lockMessage}
          setAddress={(item) => {
            setPhysicalAddress({
              city: item.city ?? '',
              jurisdiction: item.jurisdiction,
              streetName: item.streetName ?? '',
              postal: item.postal ?? '',
              unitNumber: item.unitNumber ?? '',
              houseNumber: item.houseNumber,
              neighborhood: item.neighborhood,
              country: item.country,
            });
          }}
          openManualInput={() => setOpen(true)}
        />
      )}
      {getAddressProvider() === IntegrationProvider.CANADA_POST && providerFault === false && (
        <CanadaPostAddress
          testId={testId ?? 'canada-post-address'}
          label={label}
          sx={sx}
          addressString={generateAddressString(physicalAddress)}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
          infoTooltip={infoTooltip}
          apiKey={canadaPostApiKey}
          modalOpen={open}
          lockMessage={lockMessage}
          setAddress={(item) => {
            setOpen(true);
            setPhysicalAddress({
              city: item.city ?? '',
              jurisdiction: item.jurisdiction ?? '',
              streetName: item.streetName ?? '',
              postal: item.postal ?? '',
              unitNumber: item.unitNumber ?? '',
              houseNumber: item.houseNumber,
              neighborhood: item.neighborhood,
              country: item.country,
            });
          }}
          onLookupFault={() => {
            setProviderFault(true);
            setOpen(true);
          }}
        />
      )}
      {providerFault && <TextField testId='provider-fault' label={label} fullWidth value={generateAddressString(physicalAddress)} onClick={() => setOpen(true)} />}
      {manualAddressEntry && (
        <Typography data-testid='address-manual-entry-btn' variant='bodyLarge' component='div' sx={{ mt: 1 }}>
          {t('components:address.enterYourAddressManually')}&nbsp;
          <button
            onClick={(e) => {
              setOpen(true);
              e.preventDefault();
            }}
            style={linkLikeButon}
          >
            {t('components:address.here')}
          </button>
        </Typography>
      )}
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{t('components:address.title')}</DialogTitle>
        <Grid container spacing={2} px={2}>
          <Grid item xs={6} key={t('address.unitNumber') as string}>
            <TextField
              testId={testId ? `${testId}-unit-number` : 'unit-number'}
              fullWidth
              value={physicalAddress.unitNumber}
              label={t('components:address.unitNumber')}
              onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, unitNumber: e.target.value }))}
              onBlur={() => setFocused([...focused, 'unitNumber'])}
              errorText={errors?.unitNumber?.message}
              error={!!errors?.unitNumber}
            />
          </Grid>
          <Grid item xs={6} key={t('components:address.houseNumber') as string}>
            <TextField
              testId={testId ? `${testId}-house-number` : 'house-number'}
              fullWidth
              value={physicalAddress.houseNumber}
              label={t('components:address.houseNumber')}
              onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, houseNumber: e.target.value }))}
              onBlur={() => setFocused([...focused, 'houseNumber'])}
              errorText={errors?.houseNumber?.message}
              error={!!errors?.houseNumber}
            />
          </Grid>
          <Grid item xs={12} key={t('components:address.streetName') as string}>
            <TextField
              testId={testId ? `${testId}-street-name` : 'street-name'}
              fullWidth
              value={physicalAddress.streetName}
              label={t('components:address.streetName')}
              onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, streetName: e.target.value }))}
              onBlur={() => setFocused([...focused, 'streetName'])}
              errorText={errors?.streetName?.message}
              error={!!errors?.streetName}
            />
          </Grid>
          <Grid item xs={6} key={t('components:address.city') as string}>
            <TextField
              testId={testId ? `${testId}-city` : 'city'}
              fullWidth
              value={physicalAddress.city}
              label={t('components:address.city')}
              onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, city: e.target.value }))}
              onBlur={() => setFocused([...focused, 'city'])}
              errorText={errors?.city?.message}
              error={!!errors?.city}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              testId={testId ? `${testId}-country` : 'country'}
              fullWidth
              value={physicalAddress?.country}
              label={t('components:address.country')}
              onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, country: e.target.value, jurisdiction: undefined }))}
              onBlur={() => setFocused([...focused, 'country'])}
              errorText={errors?.country?.message}
              error={!!errors?.country}
            >
              {allowedCountries.map((cc) => (
                <MenuItem data-testid={testId ? `${testId}-country-${cc.toLowerCase()}` : cc.toLowerCase()} key={cc} value={cc}>
                  {getCountryName(cc)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={6}>
            {postalCodeLabel && (
              <TextField
                testId={testId ? `${testId}-postal-code` : 'postal-code'}
                fullWidth
                value={physicalAddress.postal}
                label={postalCodeLabel}
                onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, postal: e.target.value }))}
                onBlur={() => setFocused([...focused, 'postal'])}
                errorText={errors?.postal?.message}
                error={!!errors?.postal}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {jurisdictionLabel && (
              <SelectField
                testId={testId ? `${testId}-jurisdiction` : 'jurisdiction'}
                fullWidth
                value={physicalAddress.jurisdiction}
                label={jurisdictionLabel}
                onChange={(e: any) => setPhysicalAddress((prevState) => ({ ...prevState, jurisdiction: e.target.value }))}
                disabled={!physicalAddress.country}
                onBlur={() => setFocused([...focused, 'jurisdiction'])}
                errorText={errors?.jurisdiction?.message}
                error={!!errors?.jurisdiction}
              >
                {jurisdictions.map((jurisdiction: string) => (
                  <MenuItem data-testid={testId ? `${testId}-jurisdiction-${jurisdiction.toLowerCase()}` : jurisdiction.toLowerCase()} key={jurisdiction} value={jurisdiction}>
                    {t(`geoNames:${jurisdiction}`)}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
        </Grid>
        <List>
          <ListItem>
            <Button dataTestId='manual-entry-confirm-button' fullWidth label={t('done')} variant='filled' onClick={submit} disabled={formButtonDisabled}/>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
};

export default AddressField;
