import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const InstrumentInfo = ({ options }: { options: any }) => {
  const DEFAULT_COLUMNS = [
    {
      headerName: 'custom.header.bid',
      field: 'q._BID',
    },
    {
      headerName: 'custom.header.ask',
      field: 'q._ASK',
    },
    {
      headerName: 'custom.header.blendedbid',
      field: 'calc.BLENDED_BID',
    },
    {
      headerName: 'custom.header.blendedask',
      field: 'calc.BLENDED_ASK',
    },
  ];

  useRefinitivWidget('#instrument-info-widget', 'WInstrumentInfo', {
    ric: options.instrumentInfoRic,
    columns: options.instrumentInfoColumns ?? DEFAULT_COLUMNS,
  });

  return (
    <Card>
      <div id='instrument-info-widget'></div>
    </Card>
  );
};
