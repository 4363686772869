import {
  Grid, Typography,
} from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const UploadedFile = ({ file, onDelete }: { file: File, onDelete?: () => void, }) => {
  const summaryFileStyles = {
    cage: {
      height: '70px',
      background: '#e7e4ec',
      borderRadius: '5px',
      paddingTop: '20px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    fileName: {
      overflowWrap: 'break-word',
    },
    closeIcon: {
      cursor: 'pointer',
    },
  };
  return (<Grid container sx={summaryFileStyles.cage}>
    <Grid item xs={12}>
      <Grid container justifyContent='space-between'>
        <Grid item xs={1}>
          <FilePresentIcon />
        </Grid>
        <Grid item xs={10} sx={summaryFileStyles.fileName}>
          <Typography>{file.name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <HighlightOffIcon onClick={onDelete} sx={summaryFileStyles.closeIcon} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>);
};
