import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserContext } from '../../providers/userContextProvider';
import { BillingSchedule, BillingScheduleStates } from '../../interfaces';
import { formatDay } from '../fields/scheduleDateSelect';

export const FETCH_BILLING_SCHEDULES = gql`
  query fetchBillingSchedules($input: FetchBillingSchedulesInput!) {
    fetchBillingSchedules(input: $input) {
      billingSchedules {
        id
        frequency
        nextBillingDate
        organization {
          id
        }
      }
    }
  }
`;

const BillingScheduleSelect = ({
  setValue, value, label, disabled = false, readonly = false, size, includeAnyOption = false, organizationId, focused, isInherited, applicableBillingScheduleId, showInherited,
}: {
  setValue: (event: any) => void, value: string, label: string, disabled?: boolean, readonly?: boolean, size?: 'small' | 'medium',
  includeAnyOption?: boolean, organizationId?: string, focused?: boolean, isInherited?: boolean, applicableBillingScheduleId?: string, showInherited?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'components']);
  const { activeOrganization } = useContext(UserContext);
  const { error, data } = useQuery(FETCH_BILLING_SCHEDULES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          states: BillingScheduleStates.ACTIVE,
        },
        pagination: { perPage: 25 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <TextField
      select
      focused={focused}
      value={value === '' ? 'any' : value}
      label={label}
      fullWidth
      onChange={(event) => { setValue(event.target.value === 'any' ? '' : event.target.value); }}
      disabled={disabled}
      size={size}
      InputProps={{
        readOnly: readonly,
      }}
    >
      { includeAnyOption && (
        <MenuItem value="any">{t('components:any')}</MenuItem>
      )}
      { showInherited && (
        <MenuItem key="inherited" value="inherited">
          {t('feeAndBilling:feeGrid.inherited')}
        </MenuItem>
      )
      }
      { data?.fetchBillingSchedules.billingSchedules.map((x: BillingSchedule) => (
          <MenuItem key={ x.id } value={ x.id }>
            {`${t(`feeAndBilling:billingSchedule.frequencies.${x.frequency}`)} - ${formatDay(dayjs(x.nextBillingDate).date())}` }
          </MenuItem>
      ))
      }
    </TextField>
  );
};

export default BillingScheduleSelect;
