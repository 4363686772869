import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_HOUSEHOLD } from 'pages/household';
import { FETCH_USER } from 'pages/client';
import { RelationshipInput } from '../../../../interfaces';
import { StepProps } from '../wizard';
import { usePermissions } from '../../../../providers/userContextProvider';
import { AddClientToHouseholdActionStepResult } from './addClientToHouseholdActionStepResult';

export const FETCH_CLIENTGROUP_RELATIONSHIPS = gql`
  query fetchClientGroupRelationships($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId:$clientGroupId) {
      clientGroup {
        relationships {
          type
          user { id }
          accessType
        }
      }
    }
  }
`;

export const UPDATE_CLIENTGROUP_RELATIONSHIPS = gql`
  mutation updateClientGroupRelationships($clientGroupId: ObjectID!, $relationships: [RelationshipInput]) {
    updateClientGroup(input: {
      clientGroupId: $clientGroupId
      relationships: $relationships
    }) {
      clientGroup {
        id
      }
    }
  }
`;

export function AddClientToHouseholdActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const { permissions } = usePermissions();
  const [keepHouseholdName] = useState(state.clientGroup?.name ?? '');
  const [keepHouseholdId] = useState(state.clientGroup?.id ?? '');
  const [clientGroupId] = useState(state.clientGroup?.id);
  const [fetchClientGroupRelationships] = useLazyQuery(FETCH_CLIENTGROUP_RELATIONSHIPS, { variables: { clientGroupId } });
  const [updateRelationships, {
    loading, called, data, error,
  }] = useMutation(UPDATE_CLIENTGROUP_RELATIONSHIPS);

  /* run the mutation as soon as permissions are loaded */
  useEffect(() => {
    if (permissions.length === 0) return;
    doAddMember();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const doAddMember = () => {
    if (called) return;

    fetchClientGroupRelationships({
      fetchPolicy: 'no-cache',
      onCompleted: (existingData) => {
        mutationEvent('started');

        const existingRelationships = existingData.fetchClientGroup.clientGroup.relationships.map((r: any) => ({
          type: r.type,
          userId: r.user.id,
          accessType: r.accessType,
        }));
        const newRelationship:RelationshipInput = {
          type: state.relationship,
          userId: context.userId,
          accessType: state.accessType,
        };
        updateRelationships({
          variables: {
            clientGroupId: state.clientGroup?.id,
            relationships: [...existingRelationships, newRelationship],
          },
          refetchQueries: [FETCH_HOUSEHOLD(permissions), FETCH_USER(permissions)],
          onCompleted: () => mutationEvent('succeeded'),
          onError: () => mutationEvent('failed'),
        });
      },
    });
  };

  return (
    <AddClientToHouseholdActionStepResult
      state={state}
      context={context}
      householdName={keepHouseholdName}
      householdId={keepHouseholdId}
      continueTo={continueTo}
      loading={loading}
      succeeded={!!data}
      error={error}
    />
  );
}
