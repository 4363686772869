import {
  Button,
  TextField,
  ListItem,
  Grid,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import FormModal from 'components/modals/formModal';
import DroppableFileInput from 'pages/client/components/droppableFileInput';
import { useGlobalToast } from 'providers/globalToastProvider';
import { FileDocumentObjectTypes, FileDocumentTypes } from 'interfaces/fileDocument';
import { BulkImport, ImportFileTypes } from '../../../interfaces/bulkImport';
import { FETCH_QUEUED_FILES } from '../index';

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input: FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input: $input) {
      temporarySignedURL
    }
  }
`;

export const FETCH_TEMPLATE_DOWNLOAD_URL = gql`
  query fetchImportFileTemplate ($importFileType: ImportFileTypes!) {
    fetchImportFileTemplate(importFileType: $importFileType) {
      downloadUrl
    }
  }
`;

const ADD_IMPORT_FILE = gql`
  mutation addImportFile($input: AddImportFileInput!){
    addImportFile(input: $input) {
      importFile {
        id
      }
    }
  }
`;

const AddImportFileModal = ({ bulkImport, handleClose }: {
  bulkImport: BulkImport,
  handleClose: () => void,
}) => {
  const { t } = useTranslation('components');
  const [file, setFile] = useState<File>();
  const [type, setType] = useState(ImportFileTypes.USER);
  const [uploadPending, setUploadPending] = useState(false);
  const { showToast } = useGlobalToast();

  const [addImportFile] = useMutation(ADD_IMPORT_FILE, { refetchQueries: [FETCH_QUEUED_FILES] });
  const [fetchFileUploadUrl, { loading: loadingUploadUrl }] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [fetchTemplateDownloadUrl] = useLazyQuery(FETCH_TEMPLATE_DOWNLOAD_URL, { fetchPolicy: 'no-cache' });

  const downloadTemplate = async () => {
    const queryResult = await fetchTemplateDownloadUrl({ variables: { importFileType: type } });
    const templateUrl = queryResult?.data?.fetchImportFileTemplate?.downloadUrl;
    if (!templateUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Error on fetching template URL: ${queryResult?.error}` });
      return;
    }
    if (window && templateUrl) {
      window.open(templateUrl, '_blank');
    }
  };

  const doTheUpload = async () => {
    if (!file) return;

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({
      variables: {
        input: {
          objectId: bulkImport.id,
          objectType: FileDocumentObjectTypes.BULK_IMPORT,
          type: FileDocumentTypes.BULK_IMPORT,
          fileName: file.name,
        },
      },
    });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Upload failed, step 1 of 3 (FETCH_FILE_UPLOAD_URL) ${queryResult?.error}` });
      return;
    }

    /* (2) do the upload */
    try {
      setUploadPending(true);
      const uploaded:Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: file }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e:any) {
      showToast({ severity: 'error', message: `Upload failed, step 2 of 3 (HTTP PUT) ${e}` });
      return;
    } finally {
      setUploadPending(false);
    }

    /* (3) create the importFile within backend */
    try {
      await addImportFile({
        variables: {
          input: {
            fileName: file.name,
            type,
            bulkImportId: bulkImport.id,
          },
        },
      });
    } catch (error) {
      showToast({ severity: 'error', message: `Upload failed, step 3 of 3 (ADD_IMPORT_FILE) ${error}` });
      return;
    }

    showToast({ severity: 'success', message: `Document uploaded successfully: ${file.name}` });
    handleClose();
  };

  return (
    <FormModal
      onSubmit={async (event: any) => {
        event.preventDefault();
        await doTheUpload();
      } }
      loading={false}
      title={t('bulkImport:addFileModal.title')}
      open={true}
      handleClose={handleClose}
      formButton={t('bulkImport:addFileModal.button')}
      disabled={ !file || loadingUploadUrl || uploadPending }
    >
      <ListItem>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              select
              value={type}
              label={t('bulkImport:addFileModal.type')}
              fullWidth
              size='small'
              onChange={(e: any) => setType(e.target.value)}
            >
              {Object.keys(ImportFileTypes).map((option) => (
                <MenuItem key={option} value={option}>{t(`bulkImport:fileTypes.${option}`)}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}/>
          <Grid item xs={4}>
            <Button fullWidth onClick={downloadTemplate}>
              { t('bulkImport:addFileModal.downloadTemplateButton') }
            </Button>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <DroppableFileInput
          onFileChosen={(dropFile: File) => {
            setFile(dropFile);
          }}
        />
      </ListItem>
    </FormModal>
  );
};

export default AddImportFileModal;
