import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme/colors';
import { FETCH_AWAITING_INSTIUTIONS_COUNT } from '../../../providers/globalStatsHooks';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { usePermissions } from '../../../providers/userContextProvider';
import AddressField from '../../../components/inputs/addressField';
import { FETCH_INSTITUTIONS, stateInstitutionStateColor } from './institutionsTable';
import { generateAddressString } from '../../../util';
import { useLocalization } from '../../../util/useLocalization';

export const TRANSITION_INSTITUTION = gql`
  mutation transitionInstitution($input: TransitionInstitutionInput!) {
    transitionInstitution(input: $input) {
      institution {
        id
      }
    }
  }
`;

export const UPDATE_INSTITUTION = gql`
  mutation updateInstitution($input: UpdateInstitutionInput!) {
    updateInstitution(input: $input) {
      institution {
        id
      }
    }
  }
`;

export const FETCH_INSTITUTION = gql`
  query fetchInstitution($id: ID!) {
    fetchInstitution(id: $id) {
      institution {
        id
        name
        physicalAddress{
          streetName
          city
          houseNumber
          neighborhood
          postal
          province
          unitNumber
        }
        isDraft
        state
        stateChanges
        createdAt
        updatedAt
      }
    }
  }
`;
enum InstitutionStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
const transitions = [
  {
    name: 'approve',
    from: [InstitutionStates.AWAITING_REVIEW],
  },
  {
    name: 'reject',
    from: [InstitutionStates.AWAITING_REVIEW, InstitutionStates.APPROVED],
  },
];
export const styles = {
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '624px',
      },
    },
  },
  dialogButton: {
    minWidth: '160px',
    fontWeight: 600,
    fontSize: '14px',
    marginLeft: '16px',
  },
  closeButton: {
    float: 'right',
    width: '13.91px',
    height: '13.91px',
    fontWeight: 500,
    color: colors.black,
  },
  fileCard: {
    width: '100%',
    maxHeight: '350px',
  },
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '424px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '376px',
      title: {
        fontWeight: 700,
      },
    },
    dialogButton: {
      minWidth: '160px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
};
const InstitutionViewer = ({
  institution, open, handleClose,
}: { institution: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['components', 'institutionsReview']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [transitionInstitution] = useMutation(TRANSITION_INSTITUTION, {
    refetchQueries: [FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });
  const [institutionName, setInstitutionName] = useState(institution.name || '');
  const [updatedInstitutionObject, setUpdatedInstitutionObject] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentTransition, setCurrentTransition] = useState('');
  const [fetchInstitution, { data: institutionResponse, error }] = useLazyQuery(FETCH_INSTITUTION);
  const [updateInstitution] = useMutation(UPDATE_INSTITUTION, {
    refetchQueries: [FETCH_INSTITUTION, FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });
  useEffect(() => {
    if (institution.id) {
      fetchInstitution({
        variables: { id: institution.id },
        fetchPolicy: 'no-cache',
      });
    }
  }, [institution.id, fetchInstitution]);

  if (error) (<Typography>Error</Typography>);

  const confirmTransition = async () => {
    await transitionInstitution({ variables: { input: { institutionId: institution.id, transition: currentTransition } } });
    setOpenConfirmationModal(false);
    handleClose();
  };

  const institutionData = institutionResponse?.fetchInstitution?.institution || institution;

  useEffect(() => {
    if (institutionData) {
      setInstitutionName(institutionData.name || '');
      setUpdatedInstitutionObject(institutionData);
    }
  }, [institutionData]);

  if (!institution) return <></>;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      sx={styles.dialogContainer}
    >
      <DialogTitle sx={{
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        {`${t('institutionsReview:institutionPrefix')} ${institutionName}`}
      </DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sx={{ display: 'flex' }}>
            <Typography fontSize='small'>
              {t('institutionsReview:filters.state')}:&nbsp;
            </Typography>
            <Chip size='small' label={t(`institutionsReview:filters.stateOptions.${institutionData.state}`)} color={stateInstitutionStateColor(institutionData.state)} />
          </Grid>
          {institutionData.state === 'APPROVED' && (<Grid mt={1} item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('institutionsReview:filters.approvedDate')}:&nbsp; </Typography>
            <Typography fontSize='small'>{localizedDate(institutionData.updatedAt)}</Typography>
          </Grid>)}
          <Grid item xs={12} mt={1} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('institutionsReview:filters.requestedDate')}:&nbsp; </Typography>
            <Typography fontSize='small'>{localizedDate(institutionData.createdAt)}</Typography>
          </Grid>
          <Grid mt={1} item xs={12} sx={{ display: 'flex' }}>
            {permissions.includes('write:institution_basic')
              && (<TextField label={t('institutionsReview:filters.name')} fullWidth value={institutionName} onChange={(e) => {
                setInstitutionName(e.target.value);
              }} />)}
          </Grid>
          <Grid item xs={12} mt={1.5} sx={{ display: 'flex' }}>
            {permissions.includes('write:institution_basic') ? (<AddressField address={institution.physicalAddress} label="Address" onChange={(address) => {
              setUpdatedInstitutionObject((prev: any) => ({ ...prev, physicalAddress: address }));
            }} />) : (
              <>
                <Typography fontSize='small'>{t('institutionsReview:filters.address')}:&nbsp; </Typography>
                <Typography fontSize='small'>{generateAddressString(institution.physicalAddress)}</Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container paddingTop={5}>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item>
              {permissions.includes('write:institution_basic') && (<Button sx={{ ...styles.dialogButton, marginLeft: '0px' }}
                key='update-institution'
                type='button'
                variant='contained'
                onClick={() => {
                  const payload = {
                    id: institution.id,
                    physicalAddress: {
                      city: updatedInstitutionObject.physicalAddress.city,
                      houseNumber: updatedInstitutionObject.physicalAddress.houseNumber,
                      neighborhood: updatedInstitutionObject.physicalAddress.neighborhood,
                      postal: updatedInstitutionObject.physicalAddress.postal,
                      province: updatedInstitutionObject.physicalAddress.province,
                      streetName: updatedInstitutionObject.physicalAddress.streetName,
                      unitNumber: updatedInstitutionObject.physicalAddress.unitNumber,
                    },
                    name: institutionName,
                  };
                  updateInstitution({
                    variables: {
                      input: payload,
                    },
                  }).then();
                }}>
                {t('institutionsReview:update')}
              </Button>)}
            </Grid>
            <Grid item>
              {permissions.includes('transition:institution_basic') && transitions.map((transition: any) => (
                transition.from.includes(institutionData.state) && (
                  <Button sx={styles.dialogButton} key={transition.name} type='button' color={transition.name === 'reject' ? 'error' : 'success'} variant='contained' onClick={async () => {
                    setCurrentTransition(transition.name);
                    setOpenConfirmationModal(true);
                  }}>
                    {t(`institutionsReview:institutionTransitions.${transition.name}`)}
                  </Button>
                )
              ))}
            </Grid>
          </Grid>
        </Grid>

      </DialogContent>
      <ConfirmationModal
        dialogStyles={styles.confirmation}
        open={openConfirmationModal}
        title={t('institutionsReview:moderateInstitution')}
        bodyText={t('institutionsReview:confirmationDialogTitle', { transition: currentTransition })}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => confirmTransition()}
      />
    </Dialog>
  );
};

export default InstitutionViewer;
