import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { WorkflowContext } from '../../workflowCompletion';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { ReviewBankAccountInformationVisual } from './reviewBankAccountInformation.visual';

const FETCH_BANK_ACCOUNTS = gql`
  query fetchBankAccounts($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        bankAccounts {
          id
          name
          bankAccountNumber
          institutionNumber
        }
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($bankAccountId: ObjectID!) {
    deleteBankAccount(bankAccountId: $bankAccountId) {
      bankAccount {
        id
      }
    }
  }
`;

const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      bankAccount {
        id
      }
    }
  }
`;

export const ReviewBankAccountInformation = ({
  options,
  userId,
  onNext,
  stepLoading,
  grid = false,
  updateMode = false,
}: {
  options: any;
  userId: string;
  onNext: () => void;
  stepLoading: boolean;
  grid?: boolean;
  updateMode?: boolean;
}) => {
  const { workflowData } = useContext(WorkflowContext);
  const { permissions } = usePermissions();
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [updateBankAccount] = useMutation(UPDATE_BANK_ACCOUNT);
  const onContinue = async () => {
    onNext();
  };

  const { loading, refetch } = useQuery(FETCH_BANK_ACCOUNTS, {
    skip: userId === '' || !permissions.includes('read:bank_account_basic'),
    variables: { userId: userId ?? workflowData.currentUserId },
    onCompleted: (data) => {
      setBankAccounts(data.fetchUser.user.bankAccounts);
    },
  });

  const [deleteBankAccountMutation] = useMutation(DELETE_BANK_ACCOUNT, {
    refetchQueries: [FETCH_BANK_ACCOUNTS],
  });

  const onRemove = async (bankAccountId: string) => {
    await deleteBankAccountMutation({
      variables: {
        bankAccountId,
      },
    });
  };

  const onEdit = async (bankAccountId: string, nickName: string) => {
    await updateBankAccount({
      variables: {
        input: {
          bankAccountId,
          name: nickName,
        },
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  return (
    <ReviewBankAccountInformationVisual
      options={options}
      userId={userId ?? workflowData.currentUserId}
      bankAccounts={bankAccounts}
      continueFunc={onContinue}
      loading={stepLoading || loading}
      refetchBankAccounts={refetch}
      onRemove={onRemove}
      onEdit={onEdit}
      grid={grid}
      updateMode={updateMode}
    />
  );
};

export default ReviewBankAccountInformation;
