import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { RiskProfileVisual } from './riskProfile.visual';
import { WorkflowContext } from '../../workflowCompletion';

const FETCH_USER = () => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        suggestedFinancialProduct {
          id
          riskProfileName {
            en
            fr
          }
          riskProfileDescription {
            en
            fr
          }
          children {
            id
            percentage
            financialProduct {
              id
              translatedName {
                en
                fr
              }
              primaryAssetClass {
                id
                translatedName {
                  en
                  fr
                }
                translatedDescription {
                  en
                  fr
                }
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const RiskProfile = ({
  options,
  userId,
  onNext,
  stepLoading,
}: {
  options: any
  userId: string,
  onNext: () => void,
  stepLoading: boolean,
}) => {
  const { workflowData } = useContext(WorkflowContext);
  const [riskProfileData, setRiskProfileData] = useState<any>({
    riskProfileName: null,
    riskProfileDescription: null,
    children: [],
  });

  const { data, loading } = useQuery(FETCH_USER(), {
    variables: { userId: workflowData.currentUserId ?? userId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setRiskProfileData({
        id: data.fetchUser.user.id,
        riskProfileName: data.fetchUser.user.suggestedFinancialProduct?.riskProfileName?.en,
        riskProfileDescription: data.fetchUser.user.suggestedFinancialProduct?.riskProfileDescription?.en,
        children: data.fetchUser.user.suggestedFinancialProduct?.children,
      });
    }
  }, [data]);

  return (
    <RiskProfileVisual
      options={options}
      riskProfileData={riskProfileData}
      loading={loading || stepLoading }
      continueFunc={onNext}
    />
  );
};
