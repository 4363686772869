import { TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OVTextField {
  label: string,
  fullWidth?: boolean,
  value: string,
  required?: boolean,
  onChange: (event: any) => void,
  helperText?: string,
  InputProps?: any,
  multiline?: boolean,
  rows?: number,
}
const OvTextField = (props: OVTextField) => {
  const { t } = useTranslation('shared');
  const [enableFieldError, setEnableFieldError] = useState(false);

  return (
    <TextField
      label={props.label}
      fullWidth={props.fullWidth}
      value={props.value}
      onChange={props.onChange}
      error={(props.value === '' && enableFieldError)}
      helperText={(props.value === '' && enableFieldError) ? props.helperText ?? t('requiedField') : ''}
      onBlur={() => {
        if (props.required) {
          setEnableFieldError(true);
        }
      }}
      required={props.required}
      InputProps={props.InputProps}
    />
  );
};

export default OvTextField;
