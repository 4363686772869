import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import {
  Box, Card, CircularProgress, Stack, Tab, Tabs,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConfirmationModal from 'components/modals/confirmationModal';
import { TabPanel } from 'components/tabs/ovTabs';
import { delay } from '../../util';
import AssetsOverview from '../../components/layout/client/assetsOverview';
import CollapseHoldings from '../../components/layout/client/collapseHoldings';
import TransactionsTable from '../../components/tables/transactionsTable/transactionsTable';
import { TransfersTable } from '../../components/tables/transfersTable/transfersTable';
import { Goal, SubAccount } from '../../interfaces';
import { ObjectType } from '../../providers/statsHooks';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import { usePageState } from '../../util/usePageState';
import EditSubAccount from '../client/components/editSubAccount';
import { SubAccountSummary } from './components/subAccountSummary';
import EditSubAccountBilling from '../client/components/editSubAccountBilling';
import TradesTable from '../../components/tables/tradesTable/tradesTable';
import { PageObjectType, ViewPage } from '../../ovComponents';
import { Portfolio } from '../../ovComponents/4-module/widgets/portfolio/portfolio';

export const FETCH_SUB_ACCOUNT = (permissions: string[]) => gql`
  query fetchSubAccount($id: ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        id
        name
        state
        liquidated
        createdAt
        tradingProcess
        calculatedSuitabilityScore
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            organization {
              id
            }
            statistics {
              marketValueCents
            }
            subAccounts {
              id
              account {
                type
              }
              goal {
                name
              }
              financialProduct {
                translatedName {
                  en
                }
              }
            }
          }
        }
        goal {
          id
          name
          financialProduct {
            id
            translatedName {
              en
            }
            theme {
              id
              translatedName {
                en
              }
            }
          }
        }
        skipIPS
        allowClientDeposits
        isPartial
        theme {
          id
          translatedName {
            en
          }
        }
        financialProduct {
          id
          translatedName {
            en
          }
          riskReturnLevel
        }
        suggestedFinancialProduct {
          id
        }
        ${permissions.includes('read:billing_schedule') ? 'applicableBillingSchedule { id frequency }' : ''}
        ${permissions.includes('read:fee_tier') ? 'applicableFeeTier { id name }' : ''}
        ${permissions.includes('read:fee_tier') ? 'feeTier { id name }' : ''}
        ${permissions.includes('read:billing_schedule') ? 'billingSchedule { id frequency }' : ''}
        statistics {
          marketValueCents
          simpleReturnAmount
          simpleReturnPercent
        }
      }
    }
  }
`;

const FETCH_USER_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
      }
    }
  }
`;

export const TRANSITION_SUB_ACCOUNT = gql`
  mutation transitionSubAccount($input: TransitionSubAccountInput!) {
    transitionSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const SubAccountsDetail = () => {
  const { t } = useTranslation('subAccountsDetail');
  const params = useParams();
  const { permissions, accessiblePages } = usePermissions();
  const [statsNeedUpdate, setStatsNeedUpdate] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [editBillingAndFees, setEditBillingAndFees] = useState(false);
  const [subAccount, setSubAccount] = useState<SubAccount>();
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const { userContext } = useContext(UserContext);

  const [fetchUserGoals] = useLazyQuery(FETCH_USER_GOALS, {
    onCompleted: (e) => {
      setGoals([...e.fetchGoals.goals]);
    },
  });

  const fetchGoals = (userId: string): void => {
    fetchUserGoals({
      variables: {
        input: {
          filter: {
            userId,
          },
        },
      },
    });
  };

  const { loading, refetch } = useQuery(FETCH_SUB_ACCOUNT(permissions), {
    skip: permissions.length === 0,
    variables: { id: params.subAccountId },
    onCompleted: (data: any) => {
      setSubAccount(data.fetchSubAccount.subAccount);
      fetchGoals(data.fetchSubAccount.subAccount.account.user.id);
    },
  });

  const [transitionSubAccount] = useMutation(TRANSITION_SUB_ACCOUNT, {
    refetchQueries: [FETCH_SUB_ACCOUNT(permissions)],
  });

  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT, {
    onCompleted: async () => {
      await delay(250);
      refetch();
    },
  });

  const [tab, setTab] = usePageState('overview', 'tab');

  if (userContext.role?.subAccountPageConfiguration) {
    return <ViewPage pageConfiguration={userContext.role.subAccountPageConfiguration} />;
  }

  if (!subAccount) return <></>;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  const transitionSubAccountState = async (transitionName: string) => {
    await transitionSubAccount({
      variables: { input: { subAccountId: subAccount.id, transition: transitionName } },
    });
  };

  return (
    <Box>
      <SubAccountSummary
        subAccount={subAccount}
        onHighlightClicked={(prop) => {
          if (prop === 'billingCycle' || prop === 'feeGrid') {
            setEditBillingAndFees(true);
          } else {
            setEditOpen(true);
          }
          setSelectedHighlight(prop);
        }}
        updateSubAccount={() => setEditOpen((prevState) => (!prevState))}
        transitionSubAccount={(transition) => transitionSubAccountState(transition)}
        deactivateSubAccount={() => setDeactivateConfirmationOpen(true)}
      />
      <Box mt={4} mb={3}>
        <Tabs value={tab} onChange={(event, newValue) => { setTab(newValue); }} variant="scrollable" scrollButtons="auto">
          <Tab label={t('tabs.overview')} value="overview" />
          {subAccount.financialProduct?.id && permissions.includes('read:bulk_trade_request') && !subAccount.goal.financialProduct && (
            <Tab label={t('tabs.portfolio')} value="portfolio" />
          )}
          <Tab label={t('tabs.transfers')} value="transfers" />
          {permissions.includes('read:trade_basic')
            && <Tab label={t('tabs.trades')} value="trades" />
          }
          {accessiblePages.includes('TRANSACTIONS')
            && <Tab label={t('tabs.ledger')} value="ledger" />
          }
        </Tabs>
      </Box>
      <TabPanel value={tab} index="overview">
        <Stack spacing={2}>
          <AssetsOverview id={subAccount.id} type={ObjectType.SUB_ACCOUNT} needUpdate={statsNeedUpdate} />
          <CollapseHoldings
            id={subAccount.id} type={ObjectType.SUB_ACCOUNT}
            isExpanded removeTarget={!!subAccount.goal.financialProduct}
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index="transfers">
        <Card>
          <TransfersTable
            filter={{
              userId: subAccount.account.user.id,
              subAccountId: subAccount.id,
              accountId: subAccount.account.id,
            }}
            accountType={subAccount.account.type}
            onTransition={refetch}
            onNewTransfer={() => {
              refetch();
              setStatsNeedUpdate(statsNeedUpdate + 1);
            }}
            level="ACCOUNT"
            subAccountMarketValueCents={subAccount?.statistics?.marketValueCents}
          />
        </Card>
      </TabPanel>
      <TabPanel value={tab} index="trades">
        <Card>
          <TradesTable objectType='SUB_TRADE' filter={{ userId: subAccount.account.user.id, subAccountId: subAccount.id }} />
        </Card>
      </TabPanel>
      {subAccount.financialProduct?.id && permissions.includes('read:bulk_trade_request') && !subAccount.goal.financialProduct && (
        <TabPanel value={tab} index="portfolio">
          <Portfolio id={subAccount?.id} type={PageObjectType.SUB_ACCOUNT} />
        </TabPanel>
      )}
      <TabPanel value={tab} index="ledger">
        <Card>
          <TransactionsTable filter={{
            subAccountIds: [subAccount.id],
            userId: subAccount.account.user.id,
          }} onChange={() => {
            refetch();
            setStatsNeedUpdate(statsNeedUpdate + 1);
          }} />
        </Card>
      </TabPanel>
      {editOpen && <EditSubAccount
        afterUpdate={() => {
          setEditOpen(false);
          refetch();
        }}
        subAccountToUpdate={subAccount}
        goalList={goals}
        highlight={selectedHighlight}
        open={true}
        handleClose={() => {
          setEditOpen(false);
          setSelectedHighlight('');
        }}
      />
      }
      {
        editBillingAndFees && (
          <EditSubAccountBilling
            afterUpdate={() => {
              setEditBillingAndFees(false);
              refetch();
            }}
            subAccountToUpdate={subAccount}
            highlight={selectedHighlight}
            open={editBillingAndFees}
            handleClose={() => {
              setEditBillingAndFees(false);
              setSelectedHighlight('');
            }}
          />
        )
      }
      {
        deactivateConfirmationOpen && (
          <ConfirmationModal
            open={true}
            title={t('subaccount:closeSubaccountModal.title')}
            bodyText={t('subaccount:closeSubaccountModal.body')}
            onConfirm={async () => {
              await closeSubAccount({
                variables: { input: { subAccountId: subAccount.id, inactiveReason: 'OTHER' } },
              }).then();
              setDeactivateConfirmationOpen(false);
            }}
            onCancel={() => setDeactivateConfirmationOpen(false)}
            confirmButton={t('subaccount:closeSubaccountModal.close')}
            cancelButton={t('subaccount:closeSubaccountModal:cancel')}
          />
        )
      }

    </Box>
  );
};

export default SubAccountsDetail;
export { SubAccountBreadcrumb } from './subAccountBreadcrumb';
export const testables = {
  FETCH_USER_GOALS,
};
