import { Trans } from 'react-i18next';
import { Box, Typography } from './1-primative';
import { SysTokens } from './0-tokens';

export const TransferErrorBanner = ({ sys, htmlString, showHeader = true }: { sys: SysTokens, htmlString: string, showHeader?: boolean }) => (
    <Box sx={{
      mt: sys.spacing.xl,
      backgroundColor: sys.color.negativeVariant,
      padding: '11px 16px 13px 16px',
      borderRadius: sys.borderRadius.md,
    }}>
      {
        showHeader && (
          <Typography variant='bodyMedium' weight='bold' sx={{ mb: '4px', color: sys.color.negativeOutline }}>
            <Trans i18nKey="transfer:withdrawal.withdrawErrorHeader"/>
          </Typography>
        )
      }
      <Typography variant='bodySmall' sx={{ color: sys.color.negativeOutline }}>
        <div
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      </Typography>
    </Box>
);
