import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { RateOfReturnInformation } from '../widgets/rateOfReturnInformation';
import {
  Typography, Box, Grid, Skeleton,
} from '../../1-primative';
import InfoButton from '../../2-component/infoButton/infoButton';
import { translateBackend } from '../../../assets/i18n/config';
import type { Statistics } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { formatMoneyValue, formatPercentValue } from '../../../util';

import ArrowDownRightRed from '../../../assets/images/icons-outline/arrow-down-right-red.svg';
import ArrowUpRightGreen from '../../../assets/images/icons-outline/arrow-up-right-green.svg';
import MarketValueIndicatorIcon from '../../../assets/images/market-value-indicator-icon.svg';

// TODO: Replace with new components
import { ValueSuperscriptedCents } from '../../3-pattern';
import { useLineChartTokens } from '../../2-component/lineChart/lineChart.tokens';

export const NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE = 16;

export const getNetContributionWithCurrency = (value: number, currency = 'USD'): string => new Intl.NumberFormat('en-US', {
  currency,
  style: 'currency',
}).format(value / 100);

interface IMarketValueContent {
  readonly options?: any;
  readonly statistics: Statistics | null;
  readonly mouseIsOverChart?: boolean;
  readonly loading?: boolean;
  readonly activeCurrency: CurrencyCodes;
  readonly showNetContributionOnHover?: boolean;
  readonly showTimeWeightedReturn?: boolean;
  readonly showMoneyWeightedReturn?: boolean;
  readonly showSimpleRateOfReturn?: boolean;
  readonly activeNetContributionCents?: number;
  readonly activeMarketValueCents?: number;
  readonly displayCurrency?: boolean;
}

export const MarketValueContent: React.FC<IMarketValueContent> = ({
  statistics,
  mouseIsOverChart,
  loading,
  showNetContributionOnHover,
  options,
  displayCurrency,
  activeMarketValueCents,
  activeCurrency,
  activeNetContributionCents,
  showTimeWeightedReturn = false,
  showMoneyWeightedReturn = false,
  showSimpleRateOfReturn = false,
}) => {
  const { t } = useTranslation(['client', 'components']);
  const { sys, comp } = useThemeTokens(useLineChartTokens());

  const foreignExchangeRates = statistics?.foreignExchangeRates ?? [];
  const showExchangeRates = foreignExchangeRates.length > 0 && !!options.displayFxRate;

  return (
    <Grid item xs={12} sm={5} data-testid='market-value-chart-content'>
      <Box display='flex'>
        <Typography data-testid='market-value-chart-total-assets' sx={{ color: sys.color.outline, mr: 1 }} variant='labelLarge'>
          {t('assetsOverview.totalAssets')}
        </Typography>
        {(showTimeWeightedReturn || showMoneyWeightedReturn || showSimpleRateOfReturn || showExchangeRates) && (
          <InfoButton testId='market-value-chart-total-assets-info' title={t('performanceMetricsModal.title')} items={[
            {
              show: showTimeWeightedReturn,
              information: translateBackend(options.timeWeightedReturnDefinition),
              title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('performanceMetricsModal.timeWeightedReturns'),
            },
            {
              show: showMoneyWeightedReturn,
              information: translateBackend(options.moneyWeightedReturnDefinition),
              title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('performanceMetricsModal.moneyWeightedReturns'),
            },
            {
              show: showSimpleRateOfReturn,
              information: translateBackend(options.simpleRateOfReturnDefinition),
              title: options.simpleRateOfReturnLabel ? translateBackend(options.simpleRateOfReturnLabel) : t('performanceMetricsModal.simpleRateOfReturn'),
            },
            {
              show: showExchangeRates,
              information: (
                <>
                  {foreignExchangeRates.map((elem: any, ind: number) => (
                    <Fragment key={ind}>
                      {t('performanceMetricsModal.fxRate.body', { from: elem.from, to: elem.to, rate: elem.rate })}<br />
                    </Fragment>
                  ))}
                </>
              ),
              title: t('performanceMetricsModal.fxRate.title'),
            },
          ]} />
        )}
      </Box>

      {loading ? (
        <>
          <Skeleton width='200px' height='33px' variant='text' data-testid='market-value-chart-skeleton' />
          <Skeleton width='100px' height='19px' variant='text' data-testid='market-value-chart-skeleton' />
        </>
      ) : (
        <>
          <Box display='flex' gap={1}>
            <ValueSuperscriptedCents
              testId='market-value-chart-value-cent'
              value={mouseIsOverChart ? activeMarketValueCents : statistics?.marketValueCents}
              mainVariant='headingLarge'
              centsVariant='titleLarge'
              currency={activeCurrency}
              color={mouseIsOverChart ? comp.lineChart.color : undefined}
            />
            {displayCurrency && (
              <Typography sx={{ color: mouseIsOverChart ? comp.lineChart.color : undefined }} variant='titleLarge'>
                {`${activeCurrency}`}
              </Typography>
            )}
          </Box>
          <Box minHeight='50px' maxHeight='50px' sx={{ marginTop: '0.25em' }} boxSizing='border-box'>
            {!showNetContributionOnHover && showSimpleRateOfReturn ? (
              <Typography
                data-testid='market-value-chart-simple-rate-of-return'
                variant='bodySmall'
                sx={{ color: (statistics?.simpleReturnAmountCents ?? 0) >= 0 ? sys.color.positive : sys.color.negative }}
              >
                <img style={{ verticalAlign: 'bottom' }} alt='arrow-icon' src={(statistics?.simpleReturnAmountCents ?? 0) >= 0 ? ArrowUpRightGreen : ArrowDownRightRed} />
                {formatMoneyValue(statistics?.simpleReturnAmountCents)} ({formatPercentValue(statistics?.simpleReturnPercent ?? 0)})
              </Typography>
            ) : (
              <Box sx={{ height: '1px' }} data-testid='market-value-chart-empty-ror' />
            )}

            {!showNetContributionOnHover && (showTimeWeightedReturn || showMoneyWeightedReturn) && (
              <Box mt={1}>
                <RateOfReturnInformation testId='market-value-chart-rate-of-return' rateOfReturns={[
                  {
                    title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('assetsOverview.timeWeightedRoRAbbreviation'),
                    stat: formatPercentValue(statistics?.timeWeightedReturn ?? 0),
                    show: showTimeWeightedReturn,
                    testId: 'market-value-chart-time-weighted-rate-of-return',
                  },
                  {
                    title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('assetsOverview.moneyWeightedRoRAbbreviation'),
                    stat: formatPercentValue(statistics?.moneyWeightedReturn ?? 0),
                    show: showMoneyWeightedReturn,
                    testId: 'market-value-chart-money-weighted-rate-of-return',
                  },
                ]} />
              </Box>
            )}

            {showNetContributionOnHover && (
              <Box display='flex' alignItems='center' data-testid='market-value-chart-net-contributions'>
                <img src={MarketValueIndicatorIcon} height={`${NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE}px`} width={`${NET_CONTRIBUTION_INDICATOR_IMAGE_SIZE}px`} alt='market-value-indicator' />
                <Typography variant='bodyLarge' ml={sys.spacing.md}>
                  {`${translateBackend(options.netContributionsLabel)}: ${getNetContributionWithCurrency(activeNetContributionCents ?? 0, activeCurrency)}`}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Grid>
  );
};
