/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, CircularProgress, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import { CountryCodes } from '@onevesthq/ov-enums';
import { FeatureFlag, FeatureFlagOption } from '../../../interfaces/organizationFeatureFlag';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const FETCH_ORGANIZATION_FEATURE_FLAGS = (organizationId: string) => gql`
  query fetchOrganizationFeatureFlags {
    fetchOrganization(organizationId:"${organizationId}") {
      organization {
        id
        availableFeatureFlags
      }
    }
    fetchOrganizationFeatureFlag(organizationId:"${organizationId}") {
      organizationFeatureFlag {
        id
        featureFlags { type enabled }
      }
    }
    fetchFeatureFlags {
      featureFlags {
        value
        group
        translatedDescription { en }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_FEATURE_FLAG = gql`
  mutation updateOrganizationFeatureFlag($input: UpdateOrganizationFeatureFlagInput!) {
    updateOrganizationFeatureFlag(input: $input) {
      organizationFeatureFlag {
        id
      }
    }
  }
`;

type FeatureFlagState = 'ON' | 'OFF' | 'UNDEFINED';
interface FeatureFlagRow {
  key: string,
  title: string,
  group: string,
  originalState: FeatureFlagState,
  state: FeatureFlagState,
  isEnabled?: boolean,
}
interface GroupRow {
  key: string,
  expanded: boolean,
}

const findFeatureFlagRowState = (orgFeatureFlags: FeatureFlagOption[], type = ''): FeatureFlagState => {
  const found = orgFeatureFlags.find((f) => f.type === type);
  if (!found) return 'UNDEFINED';
  return found.enabled ? 'ON' : 'OFF';
};

const findFeatureFlagRowIsEnabled = (availableFeatureFlags: string[], type = ''): boolean => availableFeatureFlags.some((x) => x === type);

const OrganizationFeatureFlagSettings = () => {
  const { t } = useTranslation(['orgSettings']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const [groups, setGroups] = useState<GroupRow[]>([]);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagRow[]>([]);
  const [organizationFeatureFlagId, setOrganizationFeatureFlagId] = useState('');
  const canWrite = permissions.includes('write:feature_flags');

  const { loading, data, refetch } = useQuery(FETCH_ORGANIZATION_FEATURE_FLAGS(activeOrganization.id ?? ''));

  const [updateOrganizationFeatureFlag, { loading: updateLoading }] = useMutation(UPDATE_ORGANIZATION_FEATURE_FLAG, {
    variables: {
      input: {
        organizationFeatureFlagId,
        featureFlags: [] as { type: string, enabled: boolean }[],
      },
    },
  });

  useEffect(() => {
    if (activeOrganization.id) {
      refetch();
      setFeatureFlags([]);
      setGroups([]);
      setOrganizationFeatureFlagId('');
    }
  }, [activeOrganization.id]);

  useEffect(() => {
    if (data) {
      const { availableFeatureFlags } = data.fetchOrganization.organization;
      const orgFeatureFlags = data.fetchOrganizationFeatureFlag.organizationFeatureFlag.featureFlags;
      const flags = data.fetchFeatureFlags.featureFlags;
      setFeatureFlags((prev) => flags.map((f: FeatureFlag) => {
        const state = findFeatureFlagRowState(orgFeatureFlags, f.value);
        const editedState = prev.find((ff) => ff.key === f.value)?.state;
        return {
          key: f.value,
          title: f.translatedDescription?.en,
          group: f.group,
          originalState: state,
          state: editedState ?? state,
          isEnabled: findFeatureFlagRowIsEnabled(availableFeatureFlags, f.value),
        };
      }));
      const uniqGroups: string[] = uniq(data.fetchFeatureFlags.featureFlags.map((f: FeatureFlag) => f.group));
      const filteredGroups: string[] = uniqGroups.filter(omitOutOfCountryAccountTypeFFs);

      setGroups(filteredGroups.map((g) => ({
        key: g,
        expanded: true,
      })));
      setOrganizationFeatureFlagId(data.fetchOrganizationFeatureFlag.organizationFeatureFlag.id);
    }
  }, [data]);

  const omitOutOfCountryAccountTypeFFs = (group: string) => {
    const countries = activeOrganization.applicableLocalization?.countries;
    if (!countries) return true;

    if (group.startsWith('ACCOUNT_TYPES')) {
      if (group === 'ACCOUNT_TYPES' && !countries.includes(CountryCodes.CA)) return false;
      if (group === 'ACCOUNT_TYPES_USA' && !countries.includes(CountryCodes.US)) return false;
      if (group === 'ACCOUNT_TYPES_UK' && !countries.includes(CountryCodes.GB)) return false;
    }

    return true;
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setGroups((prev) => prev.map((h) => (h.key === panel ? { ...h, expanded: isExpanded } : h)));
  };

  const onUpdateClick = (group: string) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const featureFlagsToUpdate = [
      ...featureFlags.filter((f) => f.group !== group && f.originalState !== 'UNDEFINED').map((f) => ({ type: f.key, enabled: f.originalState === 'ON' })),
      ...featureFlags.filter((f) => f.group === group && f.state !== 'UNDEFINED').map((f) => ({ type: f.key, enabled: f.state === 'ON' })),
    ];
    await updateOrganizationFeatureFlag({
      variables: {
        input: {
          organizationFeatureFlagId,
          featureFlags: featureFlagsToUpdate,
        },
      },
    }).then((res) => {
      setTimeout(() => {
        if (res?.data) {
          refetch();
          showToast({ severity: 'info', message: t('orgSettings:featureFlags.updatedToastMessage') });
        }
      }, 1000);
    });
  };

  return (
    <Box sx={{ p: 1 }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          {groups.map((g) => (
            <Accordion key={g.key} expanded={g.expanded} onChange={handleAccordionChange(g.key)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
              >
                <Typography>{t(`orgSettings:featureFlags.groups.${g.key}`)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width='30%' sx={{ minWidth: '420px' }} ><Typography variant='overline'>{t('orgSettings:featureFlags.table.featureFlags')}</Typography></TableCell>
                      <TableCell>
                        <Box sx={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px',
                        }}>
                          <Typography variant='overline'>{t('orgSettings:featureFlags.table.status')}</Typography>
                          <Tooltip title={t('orgSettings:featureFlags.tooltip.status')}><InfoIcon sx={{ width: '15px' }} color='secondary' /></Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px',
                        }}>
                          <Typography variant='overline'>{t('orgSettings:featureFlags.table.orgSettings')}</Typography>
                          <Tooltip title={
                            <div style={{ whiteSpace: 'pre-line' }}>{t('orgSettings:featureFlags.tooltip.orgSettings')}</div>
                          }>
                            <InfoIcon sx={{ width: '15px' }} color='secondary' />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {featureFlags.filter((f) => f.group === g.key).sort((a, b) => a.title.localeCompare(b.title)).map((f) => (
                      <TableRow key={f.key} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell><Typography>{f.title}</Typography></TableCell>
                        <TableCell align='center'>
                          <Chip size='small'
                            label={f.isEnabled ? t('orgSettings:featureFlags.status.enabled') : t('orgSettings:featureFlags.status.disabled')}
                            color={f.isEnabled ? 'success' : 'error'}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <ToggleButtonGroup
                            color="primary"
                            value={f.state}
                            exclusive
                            size='small'
                            onChange={(e, newState) => {
                              if (newState) {
                                setFeatureFlags((prev) => prev.map((h) => (h.key === f.key ? { ...h, state: newState } : h)));
                              }
                            }}
                            disabled={updateLoading || !canWrite}
                            sx={{
                              marginLeft: '16px',
                              minWidth: '300px',
                              alignContent: 'space-between',
                              ...(f.state !== f.originalState ? { border: 1, borderColor: 'secondary' } : {}),
                            }}
                          >
                            <ToggleButton sx={{ width: '33%' }} value="ON">{t('orgSettings:featureFlags.settings.on')}</ToggleButton>
                            <ToggleButton sx={{ width: '33%' }} value="OFF">{t('orgSettings:featureFlags.settings.off')}</ToggleButton>
                            <ToggleButton sx={{ width: '34%' }} value="UNDEFINED">{t('orgSettings:featureFlags.settings.undefined')}</ToggleButton>
                          </ToggleButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {canWrite && (
                  <Button
                    type='submit'
                    disabled={loading || updateLoading || featureFlags.filter((f) => f.group === g.key && f.originalState !== f.state).length === 0}
                    variant='contained'
                    sx={{ float: 'right', m: '16px 0px' }}
                    onClick={onUpdateClick(g.key)}
                  >
                    {t('orgSettings:featureFlags.saveButton')}
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </Box>
  );
};

export default OrganizationFeatureFlagSettings;
