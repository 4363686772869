import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Button, Typography, Box, ActionContext,
} from '../../../..';
import { getSubAccountName } from '../../../../../interfaces';
import { formatMoneyValue } from '../../../../../util';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  CREATE_DEPOSIT_TRANSFER, CREATE_EXTERNAL_TRANSFER, CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS, TRANSFER_BETWEEN_SUB_ACCOUNTS,
} from './depositReview.queries';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useLocalization } from '../../../../../util/useLocalization';
import { DepositTransferContext } from '../../../depositWorkflow/depositWorkflow';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';

export const DepositReview = ({
  options, onNext, dummyDeposit, setDummyDeposit,
}: {
  options: any, onNext: any, dummyDeposit?: any, setDummyDeposit?: any,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { localizedDate } = useLocalization();
  const { transferData, setTransferData } = useContext(DepositTransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const applicableData = dummyDeposit ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyDeposit ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;
  const { refetch, refreshWidgets } = useContext(ActionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useGlobalToast();
  const { sys } = useThemeTokens();

  const [createDepositTransfer] = useMutation(CREATE_DEPOSIT_TRANSFER);
  const [createScheduledDepositTransfer] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER);
  const [createTransferBetweenSubAccounts] = useMutation(TRANSFER_BETWEEN_SUB_ACCOUNTS);
  const [createTransferAllBetweenSubAccounts] = useMutation(TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS);
  const [createExternalTransfer] = useMutation(CREATE_EXTERNAL_TRANSFER);

  const fullTransfer = () => applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97;

  const submit = () => {
    setLoading(true);
    if (applicableData?.type === 'EFT') {
      if (applicableData?.schedule === 'ONE_TIME' && !applicableData?.scheduledDate) {
        createDepositTransfer({
          variables: {
            input: {
              amountCents: applicableData?.amountCents,
              bankAccountId: applicableData?.bankAccount.id,
              subAccountId: applicableData?.subAccount.id,
              fiscalYear: applicableData?.fiscalYear,
            },
          },
          onCompleted: () => {
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      } else {
        createScheduledDepositTransfer({
          variables: {
            input: {
              amountCents: applicableData?.amountCents,
              bankAccountId: applicableData?.bankAccount.id,
              subAccountId: applicableData?.subAccount.id,
              scheduledDate: applicableData?.scheduledDate,
              frequency: applicableData?.schedule,
            },
          },
          onCompleted: () => {
            updateContextTransfer({
              value: { ...applicableData, skipDocuments: false },
              existingContext: applicableData,
              setApplicationContext,
              setWorkflowCompletionContext,
            });
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else if (applicableData?.type === 'INTERNAL_TRANSFER') {
      if (!fullTransfer()) {
        createTransferBetweenSubAccounts({
          variables: {
            input: {
              amountCents: applicableData?.amountCents,
              fromSubAccountId: applicableData?.fromSubAccount.id,
              toSubAccountId: applicableData?.subAccount.id,
            },
          },
          onCompleted: () => {
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      } else {
        createTransferAllBetweenSubAccounts({
          variables: {
            input: {
              fromSubAccountId: applicableData?.fromSubAccount.id,
              toSubAccountId: applicableData?.subAccount.id,
            },
          },
          onCompleted: () => {
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else {
      createExternalTransfer({
        variables: {
          input: {
            amountCents: applicableData?.amountCents,
            subAccountId: applicableData?.subAccount.id,
            institution: { id: applicableData?.institution.id },
            transferAccountNumber: applicableData?.accountNumber,
            transferMethod: applicableData?.transferType,
            transferAccount: applicableData?.accountType,
          },
        },
        onCompleted: () => {
          refetch();
          setLoading(false);
          showToast({
            message: t('depositReview.success'),
            severity: 'success',
          });
          if (refreshWidgets) refreshWidgets();
        },
      });
    }
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      { applicableData?.type === 'EFT' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.fromBankAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{`${applicableData?.bankAccount?.name} ${applicableData?.bankAccount?.bankAccountNumber}`}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          { applicableData?.schedule !== 'ONE_TIME' && (
            <>
              <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
                <Typography variant='bodyLarge'>{t('depositReview.schedule')}</Typography>
                <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`schedule.${applicableData?.schedule}`)}</Typography>
              </Box>
              <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
                <Typography variant='bodyLarge'>{t('depositReview.startsOn')}</Typography>
                <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{localizedDate(applicableData?.scheduledDate)}</Typography>
              </Box>
            </>
          )}
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.eftDisclaimer)}</ReactMarkdown>
            </Typography>
          </Box>
        </>
      )}
      { applicableData?.type === 'INTERNAL_TRANSFER' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.fromSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(applicableData?.fromSubAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.internalTransferDisclaimer)}</ReactMarkdown>
            </Typography>
          </Box>
        </>
      )}
      { applicableData?.type === 'EXTERNAL_TRANSFER' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(applicableData?.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.financialInstitution')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{applicableData?.institution.name}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.accountNumber')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{applicableData?.accountNumber}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.accountType')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{applicableData?.accountType}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.transferType')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`depositReview.${applicableData?.transferType}`)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(applicableData?.amountCents)}</Typography>
          </Box>
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.externalTransferDisclaimer)}</ReactMarkdown>
            </Typography>
          </Box>
        </>
      )}
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-review-continue-button' label={t('depositReview.createDeposit')} sx={{ mt: 3, textAlign: 'center' }} disabled={loading} onClick={submit} />
      </Box>
    </>
  );
};

export default DepositReview;
