/* eslint-disable no-await-in-loop */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './createGoalWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { PageObjectType } from '../../5-page';
import { WorkflowContext } from '../workflowCompletion/workflowCompletion';
import { delay } from '../../../util';
import { useGlobalToast } from '../../../providers/globalToastProvider';

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

const ARCHIVE_GOAL = gql`
  mutation archiveGoal($input: ArchiveGoalInput!) {
    archiveGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const FETCH_ACCOUNT_SUBACCOUNT = gql`
query fetchAccount($accountId: ObjectID!) {
  fetchAccount(accountId: $accountId) {
    account {
      id
      subAccounts {
        id
        state
      }
    }
  }
}
`;

export const CreateGoalWorkflow = ({
  open, setOpen, objectId, objectType, refetch,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType, refetch: () => void,
}) => {
  const { showToast } = useGlobalToast();
  const [workflowData, setWorkflowData] = useState<any>({});
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({
    objectId,
    ...initialWorkflowCompletion,
  });

  const updateNext = () => {
    updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
      refetch();
      setOpen(false);
      setWorkflowCompletion({
        objectId,
        ...initialWorkflowCompletion,
      });
    });
  };

  const updatePrevious = () => {
    updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
  };

  const [archiveGoal] = useMutation(ARCHIVE_GOAL, {
    variables: {
      input: {
        goalId: workflowData.currentGoalId,
      },
    },
    errorPolicy: 'ignore',
  });

  const [fetchAccount] = useLazyQuery(FETCH_ACCOUNT_SUBACCOUNT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [closeAccount] = useMutation(CLOSE_ACCOUNT, {
    variables: {
      input: {
        accountId: workflowData.currentAccountId,
        inactiveReason: 'OTHER',
      },
    },
  });

  const canCloseAccount = (account: any) => {
    const { subAccounts } = account.data.fetchAccount.account;
    const allInactive = subAccounts.every((subAccount: any) => subAccount.state === 'INACTIVE');
    const noSubAccounts = subAccounts.length === 0;

    const canClose = noSubAccounts || allInactive;

    return canClose;
  };

  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT, {
    variables: {
      input: {
        subAccountId: workflowData.currentSubAccountId,
        inactiveReason: 'OTHER',
      },
    },
    onCompleted: async () => {
      for (let i = 1; i < 10; i++) {
        try {
          await delay(250);
          const response = await archiveGoal();
          if (!response.errors) {
            showToast({ message: 'Goal creation has been successfully exited', severity: 'success' });
            break;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        if (i === 10) {
          showToast({ message: 'Error closing goal', severity: 'error' });
        }
      }

      const response = await fetchAccount({
        variables: {
          accountId: workflowData.currentAccountId,
        },
      });

      if (canCloseAccount(response)) {
        await closeAccount();
      }
      setWorkflowData({});
    },
  });

  const closeGoalWorkflow = async () => {
    if (workflowData.currentSubAccountId) {
      await closeSubAccount();
    }
    if (workflowData.currentGoalId && !workflowData.currentSubAccountId) {
      await archiveGoal();
      setWorkflowData({});
    }
  };

  return (<WorkflowContext.Provider value={{
    workflowData,
    setWorkflowData,
  }}>
      <WorkflowCompletionVisual workflowCompletion={workflowCompletion} open={open} onClose={async () => {
        await closeGoalWorkflow();
        setOpen(false);
        setWorkflowCompletion({
          objectId,
          ...initialWorkflowCompletion,
        });
      }} onNext={updateNext} previousStep={updatePrevious} loading={false} />
    </WorkflowContext.Provider>
  );
};
