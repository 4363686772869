import { CurrencyCodes } from '@onevesthq/ov-enums';
import { Box } from '../../1-primative';
import { getCurrencyFlag } from '../../../util/currency';

interface IBaseCurrencyFlag {
  baseCurrency: CurrencyCodes;
}

export const CurrencyFlag: React.FC<IBaseCurrencyFlag> = ({ baseCurrency }) => (
  <Box height={20} width={20} borderRadius={10} overflow='hidden'>
    <img alt={baseCurrency} src={getCurrencyFlag(baseCurrency)} style={{ objectFit: 'cover', height: '100%' }} />
  </Box>
);
