import { Typography } from '@mui/material';

const StatsBlock = ({ title, value, lowerCase }: { title: string, value: string, lowerCase?: boolean }) => (
    <>
      <Typography variant={lowerCase ? 'caption' : 'overline'} sx={{ lineHeight: 1 }}>{ title }</Typography>
      <Typography variant='h5' sx={{ lineHeight: 1 }}>{ value }</Typography>
    </>
);

export default StatsBlock;
