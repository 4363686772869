import ErrorIcon from '@mui/icons-material/Error';
import { Stack } from '../../../../1-primative';
import { Alert } from '../../../../2-component/alert/alert';
import i18n from '../../../../../assets/i18n/config';
import { SubTradeRequest } from '../../../../../interfaces/subTradeRequest';

export enum PortfolioWarningKeys {
  ACCOUNT_IS_BLOCKED,
  SUB_ACCOUNT_IS_BLOCKED,
  ILLIQUID_PRODUCTS,
  PENDING_TRANSACTION_USD_CURRENCY,
  PENDING_TRANSACTION_MF_SELL_ALL,
}

const PORTFOLIO_WARNING_KEY_TO_I18N_STR = {
  [PortfolioWarningKeys.ACCOUNT_IS_BLOCKED]: 'accountIsBlocked',
  [PortfolioWarningKeys.SUB_ACCOUNT_IS_BLOCKED]: 'subAccountIsBlocked',
  [PortfolioWarningKeys.ILLIQUID_PRODUCTS]: 'illiquidProducts',
  [PortfolioWarningKeys.PENDING_TRANSACTION_USD_CURRENCY]: 'pendingTransactionUsdCurrency',
  [PortfolioWarningKeys.PENDING_TRANSACTION_MF_SELL_ALL]: 'pendingTransactionMfSellAll',
};

export type PortfolioWarningsMap = Map<PortfolioWarningKeys, string>;
export type IlliquidProductsMap = Map<Date, Set<string>>;

/**
 * adds a new portfolio warning using the portfolioWarningKey that is mapped with the translation strings in the PORTFOLIO_WARNING_KEY_TO_I18N_STR
 * @warn the portfolioWarnings map is mutated and the key is only added once
 */
export const addPortfolioWarning = (portfolioWarningKey: PortfolioWarningKeys, portfolioWarnings: PortfolioWarningsMap, translationProps?: Record<string, any>): PortfolioWarningsMap => {
  if (!portfolioWarnings.has(portfolioWarningKey)) {
    const message = i18n.t(`components:portfolioWarnings.${PORTFOLIO_WARNING_KEY_TO_I18N_STR[portfolioWarningKey]}`, translationProps);

    portfolioWarnings.set(portfolioWarningKey, message);
  }

  return portfolioWarnings;
};

/**
 * maps a date with a list of tickers
 * @warn the illiquidProducts map is mutated and the ticker and date are only added once
 */
export const buildIlliquidProduct = (trade: SubTradeRequest, illiquidProducts: IlliquidProductsMap): IlliquidProductsMap => {
  const blockedUntil = trade?.blockedUntil ?? null;
  const ticker = trade?.financialProduct?.ticker ?? null;

  if (blockedUntil && ticker) illiquidProducts.set(blockedUntil, new Set(illiquidProducts.get(blockedUntil)).add(ticker));

  return illiquidProducts;
};

/**
 * @param localizedDate is provided in the src/util/useLocalization.tsx
 */
export const illiquidProductsToString = (illiquidProducts: IlliquidProductsMap, localizedDate: (date: Date) => string): string => {
  if (!illiquidProducts.size) return '';

  const datesAndTickers: string[] = [];

  illiquidProducts.forEach((tickets: Set<string>, date: Date) => {
    datesAndTickers.push(`${Array.from(tickets.values()).join(', ')} (${localizedDate(date)})`);
  });

  return datesAndTickers.join('; ');
};

export const PortfolioWarnings = ({ portfolioWarnings }: { portfolioWarnings: PortfolioWarningsMap }) => {
  if (!portfolioWarnings.size) return <></>;

  const warnings = Array.from(portfolioWarnings.values());

  return (
    <Stack p={2} spacing={1}>
      {warnings.map((warning: string) => (
        <Alert icon={<ErrorIcon fontSize='inherit' />} severity='error'>
          {warning}
        </Alert>
      ))}
    </Stack>
  );
};
