import { useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_SECURITIES } from '../../../components/inputs/securitySelect';
import { Branch, FinancialProduct } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';

const CopyFromExcel = ({
  afterUpdate, open, handleClose,
}: { afterUpdate: (copiedBranches: Branch[]) => void, open: boolean, handleClose: () => void, }) => {
  const { t } = useTranslation('rebalance');
  const [content, setContent] = useState('');
  const { activeOrganization } = useContext(UserContext);
  const { loading, error, data: securitiesData } = useQuery(FETCH_SECURITIES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id, state: 'ACTIVE' },
        pagination: { perPage: 300, sortField: 'ticker' },
      },
    },
  });

  const list = useMemo(() => !loading && securitiesData?.fetchFinancialProducts?.financialProducts, [securitiesData, loading]);

  const generateContent = () => {
    const mappedValues = content.split('\n').map((x: any) => x.split('\t'));
    const copiedBranches: Branch[] = [];
    mappedValues.forEach((line: any) => {
      const relatedFinancialProduct: FinancialProduct = list.find((f: any) => f.ticker === line[0]);
      if (relatedFinancialProduct) {
        const newBranch: Branch = {
          id: relatedFinancialProduct.id,
          financialProduct: relatedFinancialProduct,
          percentage: parseFloat(line[1]),
        };
        copiedBranches.push(newBranch);
      }
    });
    afterUpdate(copiedBranches);
  };
  if (error) (<Typography>Error</Typography>);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>{t('pasteFromExcel')}</DialogTitle>
        <DialogContent>
          <Typography>{t('orderOfPortfolioContent')}</Typography>
          <TextField multiline rows={10} value={content} onChange={(e) => setContent(e.target.value)} fullWidth/>
          <Button variant="contained" sx={{ marginTop: 1 }} onClick={generateContent}>{t('generate')}</Button>
        </DialogContent>
   </Dialog>
  );
};

export default CopyFromExcel;
