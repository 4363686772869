import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Badge, Tooltip } from 'ovComponents/2-component';
import { UserContext } from 'providers/userContextProvider';
import { Box, Typography } from 'ovComponents/1-primative';

export const PendingTransactionBadge = ({ transaction }: { transaction: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);

  if (!activeOrganization?.allowPendingTransactions || !transaction.pending) return null;

  return (
    <Tooltip
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography sx={{ color: 'white' }}>
            {t('components:transaction.pendingConfirmation')}
          </Typography>
          <Typography sx={{ color: 'white' }}>
            {t('components:transaction.expectedSettlementDate', {
              settlementDate: transaction.settleDate
                ? dayjs(transaction.settleDate).format('YYYY-MM-DD')
                : 'TBD',
            })}
          </Typography>
        </Box>
      }
      placement='top'
    >
      <span style={{ marginLeft: '8px' }}>
        <Badge label={t('components:transaction.requested')} />
      </span>
    </Tooltip>
  );
};
