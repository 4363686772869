import { useTranslation } from 'react-i18next';
import { alpha, SxProps } from '@mui/material';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
} from 'ovComponents';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { TranslatedString } from 'interfaces';
import TranslatableString from 'components/inputs/translatableString';
import {
  BasicFieldEdit,
} from './basicField';

interface FieldEditProps {
  option: any,
  options: any,
  setOptions: (x: any) => void,
  i: number,
  enableTranslation?: boolean,
  displayTextLabel?: string,
}

interface SelectOption {
  label?: string,
  value?: string,
  displayText?: TranslatedString,
}

interface SelectOptionEditorProps {
  idx: number,
  action: SelectOptionAction,
  label?: string,
  value?: any
  onAction: () => void,
  onLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  translatedString?: TranslatedString,
  onTranslatedLabelChange: (e: any) => void,
  displayTextLabel?: string,
}

type SelectOptionAction = 'add' | 'remove';

const I18N_PREFIX = 'workflowCompletions:subStepOptions.optionTypes.BASIC_OR_SELECT_FIELD';

const iconButtonSx: SxProps = {
  backgroundColor: alpha('#000', 0.06),
  '&:hover': {
    backgroundColor: alpha('#000', 0.12),
  },
};
const iconButtonTranslationSx: SxProps = {
  height: '45px',
  width: '45px',
  position: 'relative',
  top: '5px',
};
const valuefieldPlacement: SxProps = {
  marginTop: '-11px',
  paddingTop: '15px',
};
const mapToValuePlacement: SxProps = {
  position: 'relative',
  left: '25px',
  top: '5px',
};
const displayTextStyle = {
  height: '40px',
  position: 'relative',
  top: '7px',
};
const SelectOptionEditor = (props: SelectOptionEditorProps) => (
  <Grid item container xs={12} spacing={1} m={props.displayTextLabel ? 1 : 0}>
    {props.displayTextLabel ? (<Grid item xs={6}>
      <TranslatableString
        onChange={(value) => {
          if (props.onTranslatedLabelChange) {
            props.onTranslatedLabelChange(value);
          }
        }}
        value={props.translatedString}
        label={props.displayTextLabel ?? ''}
        rows={1}
        sx={displayTextStyle}
      />
    </Grid>) : (<Grid item xs={6}>
      <TextField value={props.label ?? ''} onChange={props.onLabelChange} fullWidth />
    </Grid>)}
    <Grid item xs={4}>
      <TextField sx={{ ...(props.displayTextLabel ? valuefieldPlacement : {}) }} value={props.value ?? ''} onChange={props.onValueChange} fullWidth />
    </Grid>
    <Grid item xs={2} display='flex' justifyContent='center'>
      <IconButton onClick={props.onAction} sx={{ ...iconButtonSx, ...(props.displayTextLabel ? iconButtonTranslationSx : {}) }}>
        {props.action === 'add'
          ? <Add />
          : <Remove />}
      </IconButton>
    </Grid>
  </Grid>
);

export const SelectFieldEditor = (props: FieldEditProps) => {
  const { t } = useTranslation(['workflowCompletions']);
  const initSelectOption = (): SelectOption => ({
    label: undefined,
    value: undefined,
    displayText: {
      fr: '',
      en: '',
    },
  });
  const {
    option, options, setOptions, i, enableTranslation, displayTextLabel,
  } = props;
  const selectOpts = option?.value?.selectField ?? {};
  const defaultSelect = option?.options?.disableCustomSelect;

  // Helper function to encapsulate verbose references when
  // updating options[field].value.selectField
  const updateSelectOptions = (opts: object) => {
    const newOptions = [...options];
    newOptions[i].value = {
      ...option.value,
      selectField: {
        ...option.value.selectField,
        ...opts,
      },
    };
    setOptions(newOptions);
  };

  const handleIsSelect = (onoff: boolean) => {
    updateSelectOptions({
      checked: onoff,
      options: option.value?.selectField?.options ?? [initSelectOption()],
    });
  };

  const generateSelectOptionProps = (optionData: SelectOption, idx: number): SelectOptionEditorProps => {
    const handleAddSelectOption = () => {
      updateSelectOptions({
        options: [
          ...selectOpts.options,
          initSelectOption(),
        ],
      });
    };

    const handleRemoveSelectOption = () => {
      updateSelectOptions({
        options: selectOpts.options.filter((_: SelectOption, j: number) => j !== idx),
      });
    };

    const action: SelectOptionAction = idx === selectOpts.options.length - 1 ? 'add' : 'remove';

    const onAction = action === 'add' ? handleAddSelectOption : handleRemoveSelectOption;

    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSelectOptions({
        options: selectOpts.options.map((optData: SelectOption, j: number) => (
          j === idx ? { ...optData, label: e.target.value } : optData
        )),
      });
    };

    const onTranslatedLabelChange = (e: any) => {
      updateSelectOptions({
        ...selectOpts.options,
        options: selectOpts.options.map((optData: SelectOption, j: number) => (
          j === idx ? {
            ...optData,
            displayText: {
              en: e.en,
              fr: e.fr,
            },
          } : optData
        )),
      });
    };

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSelectOptions({
        options: selectOpts.options.map((optData: SelectOption, j: number) => (
          j === idx ? { ...optData, value: e.target.value } : optData
        )),
      });
    };

    return {
      idx,
      action,
      label: optionData.label,
      value: optionData.value,
      onAction,
      onLabelChange,
      onValueChange,
      onTranslatedLabelChange,
      translatedString: optionData.displayText,
    };
  };

  return (
    <Grid container gap={1}>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          {enableTranslation ? (<></>) : (<Checkbox
            size='small'
            label={t(`${I18N_PREFIX}.${defaultSelect ? 'isDefaultSelectLabel' : 'isSelectLabel'}`)}
            checked={selectOpts?.checked}
            onChange={handleIsSelect}
          />)}
        </Grid>
        <Grid item xs={6}>
          {(!defaultSelect && selectOpts?.checked)
            && <Typography variant='bodySmall' sx={{ ...(enableTranslation ? mapToValuePlacement : {}) }}>
              {t(`${I18N_PREFIX}.valueColLabel`)}
            </Typography>
          }
        </Grid>
        {(!defaultSelect && selectOpts?.checked)
          && selectOpts?.options.map((optionData: SelectOption, idx: number) => (
            <SelectOptionEditor key={idx} displayTextLabel={displayTextLabel} {...generateSelectOptionProps(optionData, idx)} />
          ))
        }
      </Grid>
    </Grid>
  );
};

export const BasicOrSelectFieldEdit = (props: FieldEditProps) => {
  const {
    option, options, setOptions, i,
  } = props;
  return (
    <Box display='flex' flexDirection='column' sx={{ mb: 2 }}>
      <BasicFieldEdit
        option={option}
        options={options}
        setOptions={setOptions}
        i={i}
      />
      <SelectFieldEditor {...props} />
    </Box>
  );
};
