import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import {
  Account,
  DraftGoal as DraftGoalInterface,
  FinancialProduct,
  Goal,
  GoalTypes,
  Theme,
  User,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Grid,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Dialog,
  Form,
  SelectField,
} from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ExistingGoal, DraftGoal } from './goalReviewItem';
import { NewGoalForm } from './newGoalForm.visual';
import { GoalScope } from './interfaces';

export const GoalsVisual = ({
  options,
  goals,
  draftGoals,
  updateDraftGoals,
  loading,
  continueFunc,
  generateDraftGoal,
  calculateGoalSuitabilityScoreFn,
  fetchAccountsFn,
  fetchUserFn,
  fetchPortfolioThemesFn,
  fetchModelPortfoliosFn,
}: {
  options: any,
  goals: Goal[],
  draftGoals: DraftGoalInterface[],
  updateDraftGoals: (value: DraftGoalInterface[] | ((prevState: DraftGoalInterface[]) => DraftGoalInterface[])) => void,
  loading: boolean,
  continueFunc: any,
  generateDraftGoal: () => DraftGoalInterface,
  calculateGoalSuitabilityScoreFn?: (draftGoal: DraftGoalInterface) => Promise<number>
  fetchUserFn?: () => Promise<User>,
  fetchAccountsFn?: (goalScope: GoalScope, objectId: string) => Promise<Account[]>,
  fetchPortfolioThemesFn?: () => Promise<Theme[]>,
  fetchModelPortfoliosFn?: (themeId: string) => Promise<FinancialProduct[]>,
}) => {
  const { t } = useTranslation(['client', 'workflowCompletions']);
  const [selectedGoalType, setSelectedGoalType] = useState<GoalTypes | ''>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>();
  const [newGoalData, setNewGoalData] = useState<DraftGoalInterface>(generateDraftGoal());
  const [goalSuitabilityScore, setGoalSuitabilityScore] = useState<number>(0);
  const [portfolioThemes, setPortfolioThemes] = useState<Theme[]>([]);
  const [modelPortfolios, setModelPortfolios] = useState<FinancialProduct[]>([]);

  const handleStartGoalCreation = async () => {
    if (selectedGoalType) {
      setNewGoalData({ ...generateDraftGoal(), ...{ type: selectedGoalType } });
      if (fetchPortfolioThemesFn) {
        setPortfolioThemes(await fetchPortfolioThemesFn());
      }
      if (fetchUserFn) {
        const user = await fetchUserFn();
        setUserData(user);
      }
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddGoal = (draftGoal: DraftGoalInterface) => {
    updateDraftGoals((prevState) => ([...prevState, draftGoal]));
    setSelectedGoalType('');
  };

  const getHandleDeleteDraftGoalFn = (index: number) => {
    const handlerFn = () => updateDraftGoals((prevState) => prevState.filter((_, i) => i !== index));
    return handlerFn;
  };

  const handleNewGoalDataChange = (newState: DraftGoalInterface) => {
    setNewGoalData((prevState) => {
      // If suitability criteria has changed, recalculate goal suitability score
      if (calculateGoalSuitabilityScoreFn && (
        prevState.riskQuestion1 !== newState.riskQuestion1
        || prevState.timeHorizon !== newState.timeHorizon
      )) {
        calculateGoalSuitabilityScoreFn(newState).then((score) => setGoalSuitabilityScore(score));
      }

      return newState;
    });
  };

  const handlePortfolioThemeChange = (theme: Theme) => {
    if (fetchModelPortfoliosFn && theme.id) {
      fetchModelPortfoliosFn(theme.id).then((data) => setModelPortfolios(data));
    }
  };

  const goalTypeOptions = Object.values(GoalTypes).map((item, index) => (
    <MenuItem key={index} value={item}>{t(`client:goalType.${item}`)}</MenuItem>
  ));
  const goalSelector = (
    <Grid
      container
      display='flex'
      flexDirection='row'
      sx={{ mb: 2 }}
      justifyContent='space-between'
      spacing={1}
      alignItems='end'
    >
      <Grid item xs={8}>
        <SelectField
          label={t('workflowCompletions:goals.type')}
          value={selectedGoalType}
          onChange={(e: SelectChangeEvent) => setSelectedGoalType(GoalTypes[e.target.value as keyof typeof GoalTypes])}
          fullWidth
        >
          {goalTypeOptions}
        </SelectField>
      </Grid>
      <Grid item xs={4}>
        <Button
          onClick={handleStartGoalCreation}
          variant='outlined'
          label={t('workflowCompletions:goals.start')}
          type='button'
        />
      </Grid>
    </Grid>
  );

  return (
    <Form onSubmit={continueFunc}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <Box display='flex' flexDirection='column' justifyContent='space-between' gap={3}>
      {
        goals.length > 0 && <Box>
          <Typography variant='headingOverline' sx={{ mb: 2, color: colors.neutral600 }}>{t('workflowCompletions:goals.existingGoals')}</Typography>
          { goals.map((goal, index) => <ExistingGoal key={index} goal={goal}/>) }
        </Box>
      }
      {
        draftGoals.length > 0 && <>
        <Typography variant='headingOverline' sx={{ color: colors.neutral600 }}>{t('workflowCompletions:goals.draftGoals')}</Typography>
          <Stack gap={1}>
            { draftGoals.map((item, index) => <DraftGoal goal={item} key={index} onDelete={getHandleDeleteDraftGoalFn(index)} />) }
          </Stack>
        </>
    }
      {goalSelector}
      {
        draftGoals.length > 0 && (
          <Box display='flex' justifyContent='end'>
            <Button
              type='submit'
              color='primary'
              label={t('workflowCompletions:goals.createGoals', { count: draftGoals.length })}
            />
          </Box>
        )
      }

      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        <NewGoalForm
          userData={userData}
          newGoalData={newGoalData}
          goalSuitabilityScore={goalSuitabilityScore}
          portfolioThemes={portfolioThemes}
          modelPortfolios={modelPortfolios}
          showPortfolioSection={options?.assignPortfolio}
          showAccountLinkingSection={options?.linkAccounts}
          updateNewGoalData={handleNewGoalDataChange}
          fetchAccountsFn={fetchAccountsFn}
          onPortfolioThemeChange={handlePortfolioThemeChange}
          onClose={handleModalClose}
          onSubmit={handleAddGoal}
        />
      </Dialog>
      </Box>
    </Form>
  );
};
