import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const FETCH_DASHBOARD = gql`
  query fetchOrganizationDashboard($dashboardId: ObjectID!) {
    fetchOrganizationDashboard(dashboardId: $dashboardId) {
      dashboard {
        id
        name
      }
    }
  }
`;

export function DashboardBreadcrumb() {
  const { dashboardId } = useParams();

  const { data } = useQuery(FETCH_DASHBOARD, {
    variables: { dashboardId },
  });

  return data ? data.fetchOrganizationDashboard?.dashboard.name : 'Dashboard';
}
