import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../2-component';
import { Typography } from '../../1-primative';

const BankAccountCardSkeleton = () => {
  const { t } = useTranslation();

  return (
    <EmptyState>
      <Typography variant='bodyLarge'>{t('client:bankAccount.youDontHaveAnyLinkedBankAccounts')}</Typography>
    </EmptyState>
  );
};

export default BankAccountCardSkeleton;
