import { Organization, AuthenticationConnections } from './organization';
import { AuthAudienceTypes } from './organizationUser';
import { TranslatedString } from './shared';

export enum NavigationStyles {
  FULL = 'FULL',
  SIMPLE = 'SIMPLE',
}

export interface Role {
  id?: string,
  translatedName?: TranslatedString,
  translatedDescription?: TranslatedString,
  permissions?: string[],
  accessiblePages?: string[],
  dashboards?: any[],
  publicRoleProfile?: PublicRoleProfile,
  organization?: Organization,
  roleProfile?: any,
  authAudience?: AuthAudienceTypes,
  navigationStyle?: NavigationStyles,
  individualPageConfiguration?: any,
  nonIndividualPageConfiguration?: any,
  goalPageConfiguration?: any,
  accountPageConfiguration?: any,
  householdPageConfiguration?: any,
  makeHouseholdDefaultView?: boolean,
  subAccountPageConfiguration?: any,
  defaultAuthenticationConnection?: AuthenticationConnections,
}

export interface PublicRoleProfile {
  id: string,
  organziation: Partial<Organization>,
  translatedDescription: {
    en: string,
    fr?: string,
  },
  translatedName: {
    en: string,
    fr?: string,
  }
}
