import { ListItem } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from '../../../interfaces';
import FormModal from '../../../components/modals/formModal';
import TranslatableString from '../../../components/inputs/translatableString';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import ConfirmationModal from '../../../components/modals/confirmationModal';

export const TRIGGER_MODEL_PORTFOLIO_RESIGN = gql`
mutation triggerModelPortfolioResign($input: TriggerModelPortfolioResignInput!) {
  triggerModelPortfolioResign(input: $input)
}
`;
const ResignIPS = ({ modelId, open, handleClose }: { modelId: string, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation('buildModels');
  const [translatedReason, setTranslatedReason] = useState<TranslatedString>({ en: '', fr: '' });
  const { showToast } = useGlobalToast();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [triggerModelResign, { loading }] = useMutation(TRIGGER_MODEL_PORTFOLIO_RESIGN, {
    variables: {
      input: {
        modelPortfolioId: modelId,
        translatedReasonForUpdate: translatedReason,
      },
    },
  });

  const onConfirm = async () => {
    await triggerModelResign();
    showToast({ severity: 'info', message: t('edit.triggerReSign.toastMessage') });
    setConfirmationOpen(false);
    handleClose();
  };

  const onCancel = () => {
    setConfirmationOpen(false);
    handleClose();
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setConfirmationOpen(true);
  };

  const disabled = !translatedReason.en;

  return (
    <FormModal
      open={open}
      loading={loading}
      disabled={disabled}
      title={t('buildModels:edit.triggerReSign.button')}
      onSubmit={onSubmit}
      formButton={t('buildModels:edit.triggerReSign.triggerButton')}
      handleClose={() => handleClose()}
    >
      <ListItem>
        <TranslatableString label={t('buildModels:edit.triggerReSign.updateReason')} value={translatedReason} onChange={(e) => setTranslatedReason(e)} />
      </ListItem>
      <ConfirmationModal
        open={confirmationOpen}
        loading={loading}
        title={t('buildModels:edit.triggerReSign.confirmationTitle')}
        bodyText={t('buildModels:edit.triggerReSign.confirmationMessage')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    </FormModal>
  );
};

export default ResignIPS;
