import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import backgroundImage from 'assets/images/brand_background_dark.png';
import {
  AppBar, Box, Button, Toolbar, Typography,
} from 'ovComponents';
import { useContext } from 'react';
import { UserContext } from '../../providers/userContextProvider';
import { translateBackend } from '../../assets/i18n/config';
import OrganizationLogo from '../../ovComponents/4-module/layout/organizationLogo';
import { useThemeTokens } from '../../providers/themeTokenProvider';
import { useLayoutTokens } from '../../ovComponents/4-module/layout/layout.tokens';

export const Anchor = styled('a')(({ theme }) => ({
  color: 'white',
  '&:visited': {
    color: 'white',
  },
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

const ErrorBoundary = () => {
  const { t } = useTranslation(['errorBoundaries.json', 'workflowCompletions']);
  const { activeOrganization } = useContext(UserContext);

  const { comp } = useThemeTokens(useLayoutTokens());
  const SHOW_NAVBAR = activeOrganization.errorPageShowNavbar ?? false;
  const ERROR_PAGE_BACKGROUND_COLOR = activeOrganization.errorPageBackgroundColor;
  const ERROR_PAGE_BACKGROUND_IMAGE = activeOrganization.errorPageBackgroundImage ?? backgroundImage;
  const ERROR_PAGE_TITLE = activeOrganization.errorPageMessage ? translateBackend(activeOrganization.errorPageMessage) : t('errorBoundaries:base.title');
  const ERROR_PAGE_DESCRIPTION = activeOrganization.errorPageDescription ? translateBackend(activeOrganization.errorPageDescription) : t('errorBoundaries:base.subtitle');
  const ERROR_PAGE_BUTTON_TEXT = activeOrganization.errorPageButtonText ? translateBackend(activeOrganization.errorPageButtonText) : t('errorBoundaries:base.redirectLabel');
  const SUPPORT_URL = activeOrganization.supportUrl ?? 'https://onevest.zendesk.com';

  return (
    <>
      { SHOW_NAVBAR && (<AppBar
        position="fixed"
        sx={{
          width: { md: '100%' },
          transition: 'all 0.3s',
          backgroundColor: {
            xs: comp.layout.backgroundColor,
            md: comp.layout.backgroundColor,
          },
        }}
        elevation={0}
      >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                  <OrganizationLogo textColor={comp.layout.textColor} />
              </Box>
          </Toolbar>
      </AppBar>)}
      <section
        style={{
          position: 'absolute',
          backgroundImage: `url(${ERROR_PAGE_BACKGROUND_IMAGE})`,
          backgroundColor: ERROR_PAGE_BACKGROUND_COLOR,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
    >
      <Box display='flex' flexDirection='column' alignItems='center' color='white' gap={3} px={5}>
        <Typography
          variant='bodyLarge'
          sx={{
            fontSize: '56px',
            lineHeight: '64px',
            margin: 2,
            textAlign: 'center',
          }}
        >
          {ERROR_PAGE_TITLE}
        </Typography>
        <Typography variant='trimmedLarge' sx={{ fontWeight: '400', textAlign: 'center' }}>{ERROR_PAGE_DESCRIPTION}</Typography>
        <Button onClick={() => { window.location.href = '/'; }} label={ERROR_PAGE_BUTTON_TEXT} />
        <Anchor href={SUPPORT_URL} color='white'>
          <Typography variant='labelLarge'>{t('errorBoundaries:base.helpCenterLink')}</Typography>
        </Anchor>
      </Box>
    </section>
      </>
  );
};

export default ErrorBoundary;
