import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import max from 'lodash/max';
import min from 'lodash/min';
import monteCarlo from '@onevesthq/ov-monte-carlo';
import { Line } from 'react-chartjs-2';
import HandlerIcon from 'assets/images/menu/projection-handler.svg';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { translateBackend } from 'assets/i18n/config';
import { MenuItem } from '@mui/material';
import {
  Button, TextField, Typography, Form, Box, Grid, CircularProgress, Dialog, DialogContent, SelectField,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { additionalInfo } from '../../../configurableOptionFields';
import InfoDialog from '../../../../2-component/infoDialog/infoDialog';

export const FinancialProjectionVisual = ({
  options, loading, onNext, stepLoading, goalData, readonly = false, workflowCompletion,
}: {
  options: any,
  loading: boolean, onNext: () => void,
  stepLoading: boolean,
  readonly?: boolean,
  goalData?: any,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const showGoalCompletionTime = goalData === undefined || !!goalData?.targetAmountCents;
  const { t } = useTranslation(['shared', 'financialProjection', 'workflowCompletions']);
  const { sys } = useThemeTokens();
  const DEFAULT_RATE_OF_RETURN = goalData ? (((goalData?.suggestedFinancialProduct?.forecastedRateOfReturn || 0.07) / 100) + 1) : 1.0506;
  const DEFAULT_DEVIATION = goalData ? ((goalData?.suggestedFinancialProduct?.forecastedStandardDeviation || 1.05) / 100) : 0.0875;
  const DEFAULT_YEARS = 30;
  const [isLoading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [frequency, setFrequency] = useState('MONTHLY');
  const [initialInvestment, setInitialInvestment] = useState(
    options?.initialInvestment?.defaultValue ? Number(translateBackend(options?.initialInvestment?.defaultValue)) : 1000,
  );
  const [monthlyInvestment, setMonthlyInvestment] = useState(
    options?.monthlyInvestment?.defaultValue ? Number(translateBackend(options?.monthlyInvestment?.defaultValue)) : 10,
  );
  const [lessLikelyLowerBound, setLessLikelyLowerBound] = useState('');
  const [simulationGraphData, setSimulationGraphData] = useState<any>({});
  const [lessLikelyUpperBound, setLessLikelyUpperBound] = useState('');
  const [likelyLowerBound, setLikelyLowerBound] = useState('');
  const [likelyUpperBound, setLikelyUpperBound] = useState('');
  const [labels, setLabels] = useState(['']);
  const [maxYVal, setmaxYVal] = useState(0);
  const [minVal, setminVal] = useState(0);
  const [goalCompletionYears, setGoalCompletionYears] = useState(0);
  const [currentYear, setCurrentYear] = useState(DEFAULT_YEARS);
  const formatter = Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const loaded = useRef<boolean>(false);
  useEffect(() => {
    if (options.frequency && options?.options) {
      setFrequency(options?.options[0]?.value ?? 'MONTHLY');
    } else {
      setFrequency('MONTHLY');
    }
  }, [options]);
  const submit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsProjectionsContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    onNext();
  };
  const handleAmountChange = (value: string): void => {
    loaded.current = false;
    setMonthlyInvestment(parseInt(value, 10));
  };
  const handleInitialAmountChange = (value: string): void => {
    loaded.current = false;
    setInitialInvestment(parseInt(value, 10));
  };
  const handleFrequencyChange = (value: string): void => {
    loaded.current = false;
    setFrequency(value);
  };
  const getInitialDepositBasedOnFrequency = (projectedFrequency: string, investment: number): number => {
    switch (projectedFrequency) {
      case 'WEEKLY':
        return investment * 4;
      case 'BI_WEEKLY':
        return investment * 2;
      case 'MONTHLY':
        return investment;
      case 'SEMI_MONTHLY':
        return investment / 2;
      case 'QUARTERLY':
        return investment / 4;
      default:
        return investment;
    }
  };
  useEffect(() => {
    const labelsYears: string[] = [];
    const runMonteCarlo = async (): Promise<any> => {
      const simulationData = await monteCarlo({
        baseValue: initialInvestment,
        monthlyContribution: getInitialDepositBasedOnFrequency(frequency, monthlyInvestment),
        rateOfReturn: DEFAULT_RATE_OF_RETURN,
        deviation: DEFAULT_DEVIATION,
        years: currentYear,
        timesRun: 4000,
      });
      for (let i = 0; i < currentYear; i += 1) {
        labelsYears.push(i.toString());
      }
      loaded.current = true;
      setLabels(labelsYears);
      setSimulationGraphData(simulationData);
      const lllb = `$${formatter.format(Number(max(simulationData[10]) || 0))}`;
      const llub = `$${formatter.format(Number(max(simulationData[90]) || 0))}`;
      const llb = `$${formatter.format(Number(max(simulationData[25]) || 0))}`;
      const lub = `$${formatter.format(Number(max(simulationData[75]) || 0))}`;
      setLessLikelyLowerBound(lllb);
      setLessLikelyUpperBound(llub);
      setLikelyLowerBound(llb);
      setLikelyUpperBound(lub);
      setmaxYVal(Number(max(simulationData[90])));
      setminVal(Number(min(simulationData[10])));
      let sourceVal = 0;
      if (goalData?.targetAmountCents) {
        sourceVal = goalData.targetAmountCents / 100;
      }
      // calculated closest goal completion year
      const closest = simulationData[90].reduce((prev, curr) => ((Math.abs(curr - sourceVal) < Math.abs(prev - sourceVal) ? curr : prev)));
      setGoalCompletionYears(simulationData[90].indexOf(closest) + 1);
      setLoading(false);
    };
    if (!loaded.current && !loading) {
      setLoading(true);
      runMonteCarlo();
    }
  }, [loaded,
    loading,
    initialInvestment,
    monthlyInvestment,
    formatter,
    goalData?.targetAmountCents,
    goalData?.suggestedFinancialProduct?.forecastedRateOfReturn,
    goalData?.suggestedFinancialProduct?.forecastedStandardDeviation,
    DEFAULT_RATE_OF_RETURN,
    DEFAULT_DEVIATION,
    currentYear,
    frequency,
  ]);
  useEffect(() => {
    const defaultInitialInvestment = options?.initialInvestment?.defaultValue ? Number(translateBackend(options?.initialInvestment?.defaultValue)) : 1000;
    setInitialInvestment(defaultInitialInvestment);

    const defaultMonthlyInvestment = options?.monthlyInvestment?.defaultValue ? Number(translateBackend(options?.monthlyInvestment?.defaultValue)) : 1000;
    setMonthlyInvestment(defaultMonthlyInvestment);
    const defaultYears = options?.years?.defaultValue ? Number(translateBackend(options?.years?.defaultValue)) : DEFAULT_YEARS;
    if (defaultYears !== currentYear) {
      loaded.current = false;
      setCurrentYear(defaultYears);
    }
  }, [currentYear, options]);
  const startCoordinatesPlugin = {
    id: 'startCoordinatesPlugin',
    afterDraw: (chart: any, _easing: any) => {
      const { ctx } = chart;
      let count = 0;
      chart.data.datasets?.forEach((dataset: any, datasetIndex: any) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (meta) {
          count = (meta.data[0]?.y ?? 0) + count;
        }
      });
      const meta = chart.getDatasetMeta(0);
      if (meta) {
        const startX = meta.data[0]?.x ?? 0;
        ctx.beginPath();
        ctx.arc(startX, count / chart.data.datasets.length, 5, 0, 2 * Math.PI); // Draw a circle at starting point
        ctx.fillStyle = sys.color.primary;
        ctx.fill();
        ctx.closePath();
      }
    },
  };

  const customYPillPlugin = {
    id: 'customYPillPlugin',
    afterDraw: (chart: any, _easing: any) => {
      const { ctx } = chart;
      const yAxis = chart.scales.y;

      if (yAxis) {
        const { ticks } = yAxis;
        const tickFontSize = yAxis.options.ticks.fontSize;
        const tickPadding = yAxis.options.ticks.padding - 10;
        ctx.font = `normal 500 12px ${sys.font.base}`;
        ctx.save();
        const simpleFormatter = Intl.NumberFormat('en', { notation: 'compact' });
        ticks.forEach((tick: any, index: any) => {
          const label = `$${simpleFormatter.format(tick.value)}`;
          const cantShow = [0, 1, ticks.length - 1].includes(index);
          const xPos = yAxis.left - tickPadding + 5; // Adjust the horizontal position as needed
          const yPos = yAxis.getPixelForTick(index) - tickFontSize / 2 - 2; // Adjust the vertical position as needed
          const tickWidth = ctx.measureText(label).width;
          const boxWidth = 40; // tickWidth + 20; // Add padding
          const boxHeight = 20; // tickFontSize + 4; // Adjust the height of the box as needed
          const cornerRadius = 4; // Adjust the corner radius as needed

          ctx.fillStyle = cantShow ? '#ffffff00' : sys.color.supportOneVariant; // Adjust color and opacity as needed
          ctx.beginPath();
          ctx.moveTo(xPos + cornerRadius, yPos);
          ctx.lineTo(xPos + boxWidth - cornerRadius, yPos);
          ctx.arc(xPos + boxWidth - cornerRadius, yPos + cornerRadius, cornerRadius, -Math.PI / 2, 0);
          ctx.lineTo(xPos + boxWidth, yPos + boxHeight - cornerRadius);
          ctx.arc(xPos + boxWidth - cornerRadius, yPos + boxHeight - cornerRadius, cornerRadius, 0, Math.PI / 2);
          ctx.lineTo(xPos + cornerRadius, yPos + boxHeight);
          ctx.arc(xPos + cornerRadius, yPos + boxHeight - cornerRadius, cornerRadius, Math.PI / 2, Math.PI);
          ctx.lineTo(xPos, yPos + cornerRadius);
          ctx.arc(xPos + cornerRadius, yPos + cornerRadius, cornerRadius, Math.PI, -Math.PI / 2);
          ctx.closePath();
          ctx.fill();

          // Draw the tick label centered within the box
          ctx.fillStyle = cantShow ? '#ffffff00' : sys.color.primary;
          ctx.fillText(label, xPos + ((boxWidth - tickWidth) / 2), yPos + 14);
        });
        ctx.restore();
      }
    },
  };
  const tooltipLine = {
    id: 'tooltipLine',
    afterDatasetsDraw: (chart: any) => {
      const ProjectionHandlerIcon = new Image();
      ProjectionHandlerIcon.src = HandlerIcon;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.hovered = true;
        const el = document.getElementById('chart');
        if (el) {
          el.style.cursor = 'pointer';
        }
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        ctx3.beginPath();
        ctx3.fillStyle = '#000000';// colors.black;
        if (activePoint.index > 27) {
          ctx3.fillRect(activePoint.element.x - 50, chart.chartArea.top, 60, 20);
        } else {
          ctx3.fillRect(activePoint.element.x - 30, chart.chartArea.top, 60, 20);
        }
        ctx3.font = '18px';
        ctx3.fillStyle = '#FFFFFF'; // colors.neutralWhite;
        if (activePoint.index > 27) {
          ctx3.fillText(`Year ${activePoint.index + 1}`, activePoint.element.x - 45, chart.chartArea.top + 15);
        } else {
          ctx3.fillText(`Year ${activePoint.index + 1}`, activePoint.element.x - 20, chart.chartArea.top + 15);
        }
        ctx3.stroke();
        // setCurrentYear(activePoint.index + 1);
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        ctx3.moveTo(activePoint.element.x, chart.chartArea.top + 5);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = '#2B4763'; // colors.secondary;
        ctx3.stroke();
        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = '#2B4763'; // colors.secondary;
        ctx3.stroke();
        ctx3.beginPath();
        if (activePoint.element.x) {
          ctx3.drawImage(ProjectionHandlerIcon, (activePoint.element.x - 37), (chart.chartArea.bottom - 40));
        }
        ctx3.stroke();
        ctx3.restore();
      }
    },
  };
  const externalTooltipHandler = (context: any): void => {
    // Tooltip Element
    const { tooltip } = context;
    const lllb = `$${formatter.format(Number(tooltip.dataPoints[0].raw) || 0)}`;
    const llub = `$${formatter.format(Number(tooltip.dataPoints[4].raw) || 0)}`;
    const llb = `$${formatter.format(Number(tooltip.dataPoints[1].raw) || 0)}`;
    const lub = `$${formatter.format(Number(tooltip.dataPoints[3].raw) || 0)}`;
    setLessLikelyLowerBound(lllb);
    setLessLikelyUpperBound(llub);
    setLikelyLowerBound(llb);
    setLikelyUpperBound(lub);
  };
  const INDEX = 'index';
  const NEAREST = 'nearest';
  const optionss = {
    events: ['click' as const, 'touchstart' as const, 'touchmove' as const],
    animation: {
      duration: 0,
    },
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        enabled: false,
        position: 'nearest' as typeof NEAREST,
        external: externalTooltipHandler,
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      intersect: false,
      mode: 'index',
    },
    responsive: true,
    elements: { point: { radius: 0 }, line: { fill: false } },
    scales: {
      y: {
        ticks: {
          padding: 6,
          fontSize: 12,
          display: false,
          minVal: minVal || 0,
          maxVal: maxYVal,
          color: '#ffffff00',
        },
        grid: {
          display: true,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        display: false,
        gridLines: { display: false },
        ticks: {
          display: false,
        },
        scaleShowLabels: false,
        grid: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        data: simulationGraphData[10],
        borderColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        backgroundColor: '#FFF', // colors.baseComponentBackground,
        pointColor: '#2B456399',
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 1,
      },
      {
        data: simulationGraphData[25],
        borderColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        backgroundColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        pointColor: '#2B456399',
        borderWidth: 3,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 2,
      },
      {
        data: simulationGraphData[50],
        borderColor: '#FFFFFF', // colors.neutralWhite,
        pointColor: '#FFFFFF', // colors.neutralWhite,
        borderWidth: 3,
        fill: false,
        pointRadius: 0,
        pointStyle: 'circle',
        order: 3,
      },
      {
        data: simulationGraphData[75],
        borderColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        backgroundColor: sys.color.primaryVariantHover, // colors.projectionBackgroundDark,
        pointColor: '#2B456399',
        borderWidth: 3,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 4,
      },
      {
        data: simulationGraphData[90],
        borderColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        backgroundColor: sys.color.primaryVariant, // colors.projectionBackgroundLight,
        pointColor: '#2B456399',
        fill: 'origin',
        tension: 0.01,
        borderWidth: 3,
        pointRadius: 0,
        pointStyle: 'none',
        order: 5,
      },
    ],
  };
  const chartRef = useRef<any>();
  const showFrequencyDropdown = options.frequency && options?.options?.length > 0;

  // useEffect(() => {
  //   if (isLoading || loading) return;
  //   const showTooltip = () => {
  //     if (chartRef.current) {
  //       const chartInstance = chartRef.current;
  //       const datasetIndex = 0; // Assuming only one dataset
  //       const activeElements = chartInstance.tooltip._active;
  //       const meta = chartInstance.getDatasetMeta(datasetIndex).data[0];
  //       // for (let i = 0; i < activeElements.length; i++) {
  //       //   if (meta._index === activeElements[i]._index) { return; }
  //       // }
  //       activeElements.push(meta);
  //       chartInstance.tooltip._active = activeElements;
  //       chartInstance.tooltip.update(true);
  //       chartInstance.draw();
  //     }
  //   };
  //   // showTooltip();
  // }, [isLoading, loading]);
  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge'>
        {translateBackend(options?.title)}
        {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
      </Typography>
      <Box display="flex" alignItems="flex-start" mt={sys.spacing.lg}>
        <Typography variant='bodyLarge' colorVariant='variant'>
          <span>
            {translateBackend(options?.subtitle)}
            {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%', display: 'flex', mt: sys.spacing.lg, mb: sys.spacing.xxl,
        }}
      >
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: '12px',
                height: '12px',
                background: sys.color.primary,
                borderRadius: sys.borderRadius.xs,
                marginRight: sys.spacing.md,
              }}
            />
            <Typography variant="bodySmall">{t('financialProjection:moreLikely')}</Typography>
          </Box>
          <Typography variant="bodyLarge" weight='bold' sx={{ marginTop: sys.spacing.sm }}>
            {`${likelyLowerBound} - ${likelyUpperBound}`}
          </Typography>
        </Box>
        <Box
          sx={{
            height: '44px',
            width: '1px',
            backgroundColor: sys.color.outline,
            mx: sys.spacing.xl,
          }}
        />
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: '12px',
                height: '12px',
                background: sys.color.primaryVariantHover,
                borderRadius: sys.borderRadius.xs,
                marginRight: sys.spacing.md,
              }}
            />
            <Typography variant="bodySmall">{t('financialProjection:lessLikely')}</Typography>
          </Box>
          <Typography variant="bodyLarge" weight='bold' sx={{ marginTop: sys.spacing.sm }}>
            {`${lessLikelyLowerBound} - ${lessLikelyUpperBound}`}
          </Typography>
        </Box>
      </Box>
      {
        showGoalCompletionTime && (
          <Box display='flex' sx={{ mb: sys.spacing.xxl }}>
            <Typography variant='bodyMedium' sx={{ mr: sys.spacing.sm, fontWeight: 400 }}>{t('financialProjection:yearCompletion')}</Typography>
            <Typography variant='bodyMedium' weight='bold'>{t('financialProjection:years', { years: goalCompletionYears })}</Typography>
          </Box>
        )
      }
      <Grid container gap={2} justifyContent="center">
        {
          isLoading || loading ? (
            <CircularProgress sx={{ m: 18 }} />
          ) : (<Line
            ref={chartRef}
            options={optionss}
            plugins={[tooltipLine, customYPillPlugin, startCoordinatesPlugin]}
            data={data}
            id="chart"
          />)
        }
      </Grid>
      <Box display='flex' sx={{ marginTop: sys.spacing.lg }}>
        <Typography variant='bodyMedium' sx={{ mr: sys.spacing.sm, fontWeight: 400 }}>{t('financialProjection:adjustNote')}</Typography>
      </Box>
      <Grid container columnSpacing={1} sx={{ marginY: sys.spacing.lg }} alignItems="end">
        <Grid item xs={showFrequencyDropdown ? 4 : 6} md={showFrequencyDropdown ? 4 : 6}>
          <TextField
            onChange={(e: any) => {
              if (!readonly) handleInitialAmountChange(e.target.value);
            }}
            disabled={loading}
            leadingIcon='dollar'
            type='dollar'
            placeholder={translateBackend(options?.initialInvestment?.hintText)}
            label={translateBackend(options?.initialInvestment)}
            infoTooltip={options?.initialInvestment?.additionalInfo?.checked && translateBackend(options?.initialInvestment?.additionalInfo)}
            fullWidth
            value={initialInvestment}
          />
        </Grid>
        <Grid item xs={showFrequencyDropdown ? 4 : 6} md={showFrequencyDropdown ? 4 : 6}>
          <TextField
            onChange={(e: any) => {
              if (!readonly) handleAmountChange(e.target.value);
            }}
            disabled={loading}
            leadingIcon='dollar'
            type='dollar'
            placeholder={translateBackend(options?.monthlyInvestment?.hintText)}
            label={translateBackend(options?.monthlyInvestment)}
            fullWidth
            value={monthlyInvestment}
            infoTooltip={options?.monthlyInvestment?.additionalInfo?.checked && translateBackend(options?.monthlyInvestment?.additionalInfo)}
          />
        </Grid>
        {showFrequencyDropdown && (<Grid item xs={4} md={4}>
          <SelectField
              onChange={(e: any) => {
                if (!readonly) handleFrequencyChange(e.target.value);
              }}
              label={t('financialProjection:frequency')}
              fullWidth
              value={frequency}
          >
            {options?.options?.map((option: any) => (
                <MenuItem value={option.value}>{translateBackend(option.label)}</MenuItem>
            ))}
          </SelectField>
        </Grid>)}
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button label={t('shared:continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
      <Dialog onBackdropClick={() => setOpenDialog(!openDialog)} open={openDialog}>
        <DialogContent sx={{ width: '300px' }}>
          <Typography variant='bodyLarge'>
            {translateBackend(options?.title?.additionalInfo)}
          </Typography>
        </DialogContent>
      </Dialog>
    </Form>
  );
};

export default FinancialProjectionVisual;
