import {
  Box,
  Card, CircularProgress, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { colors } from 'ovComponents';
import { AddRelatedEntityWizardButton } from 'components/wizards/addRelatedEntity/button';
import { gql, useQuery } from '@apollo/client';
import { usePermissions } from 'providers/userContextProvider';
import { RelatedEntity, User } from '../../../interfaces';
import { entityLink, formatPhoneNumber, generateClientNameString } from '../../../util';
import { RelatedEntityEdit } from './relatedEntityEdit';
import { RelatedEntityRemoveDialog } from './relatedEntityRemoveDialog';

export const FETCH_USER_RELATED_ENTITIES = (permissions: string[]) => gql`
  query fetchUserRelatedEntities($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id type state
        ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
        entityName
        relatedEntities {
          relation
          entity {
            id type state
            ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
            entityName
          }
        }
      }
    }
  }
`;

export const RelatedEntitiesTable = ({ user }: { user: User }) => {
  const { t } = useTranslation('nonIndividualClients');
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const [relatedEntities, setRelatedEntities] = useState<[RelatedEntity]>();

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenuEntity, setContextMenuEntity] = useState<null | RelatedEntity>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [relationEditorOpen, setRelationEditorOpen] = useState(false);

  // fetchUserRelatedEntities
  const { loading } = useQuery(FETCH_USER_RELATED_ENTITIES(permissions), {
    variables: { userId: user.id },
    onCompleted: ((data) => setRelatedEntities(data.fetchUser.user.relatedEntities)),
  });

  if (loading || !relatedEntities) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  const closeContextMenu = () => {
    setContextMenuAnchorEl(null);
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='overline'>{t('table.name')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('shared:relationship')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('shared:phoneNumber')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('shared:email')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('shared:status')}</Typography></TableCell>
            <TableCell align='right'>
              <AddRelatedEntityWizardButton user={user}/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedEntities.map((relatedEntity, index) => (
            <TableRow
              key={index}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '3.6em', cursor: 'pointer' }}
              onClick={() => navigate(entityLink(relatedEntity.entity))}
            >
              <TableCell>
                {generateClientNameString(relatedEntity.entity)}
              </TableCell>
              <TableCell>
                {t(`relatedEntityTypes:${relatedEntity.relation}`)}
              </TableCell>
              <TableCell>
                {formatPhoneNumber(relatedEntity.entity.phone)}
              </TableCell>
              <TableCell>
                {relatedEntity.entity.primaryEmail}
              </TableCell>
              <TableCell>
                {t(`client:details.state.${relatedEntity.entity.state}`)}
              </TableCell>
              <TableCell
                align='right'
                sx={{ cursor: 'default' }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setContextMenuAnchorEl(event.currentTarget);
                  setContextMenuEntity(relatedEntity);
                }}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Context drop-down menu */}
      <Menu
        anchorEl={contextMenuAnchorEl}
        open={contextMenuAnchorEl !== null}
        onClose={closeContextMenu}
        onClick={closeContextMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setRelationEditorOpen(true)}>
          <EditRoundedIcon/>
          <Typography ml='0.5em'>{t('editRelationship')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeleteConfirmationOpen(true)} sx={{ color: colors.negative }}>
          <DeleteForeverIcon/>
          <Typography ml='0.5em'>{t('deleteRelationship')}</Typography>
        </MenuItem>
      </Menu>

      {/* Relation removal confirmation dialog */}
      { deleteConfirmationOpen && contextMenuEntity && (
        <RelatedEntityRemoveDialog
          user={user}
          existingRelatedEntities={relatedEntities}
          entityToRemove={contextMenuEntity.entity}
          onClose={() => setDeleteConfirmationOpen(false)}
        />
      )}

      {/* Member edit dialog */}
      { relationEditorOpen && contextMenuEntity && (
        <RelatedEntityEdit
          user={user}
          existingRelatedEntities={relatedEntities}
          entityToUpdate={contextMenuEntity}
          onClose={() => setRelationEditorOpen(false)}
        />
      )}

    </Card>
  );
};
