import { useThemeTokens } from '../../../providers/themeTokenProvider';
import {
  Box, Link, Typography,
} from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import { Card } from '../../2-component';
import { TranslatedString } from '../../../interfaces';
import FallBackImage from '../../../assets/images/articles/fallback-image.png';

interface ArticleProp {
  id: string,
  imageUrl: string,
  translatedName: TranslatedString,
  translatedDescription: TranslatedString
  articleUrl: string,
  state: 'ACTIVE' | 'INACTIVE' | 'DRAFT',
}

export const ArticleItem = ({ options, article }: { options: any, article: ArticleProp }) => {
  const { sys } = useThemeTokens();
  return (
    <Link href={article.articleUrl} target='_blank' rel='noopener noreferrer' key={article.id} sx={{ textDecoration: 'none' }}>
      <Card hover sx={{ cursor: 'pointer' }}>
        {
          options.displayImageEnabled && (
            <img
              src={article.imageUrl || FallBackImage}
              alt='article'
              style={{
                width: '100%',
                height: '200px',
                maxHeight: '200px',
                objectFit: 'cover',
                borderTopLeftRadius: sys.borderRadius.xl,
                borderTopRightRadius: sys.borderRadius.xl,
              }}
            />
          )
        }
        <Box
          sx={{
            height: '176px',
            maxHeight: '176px',
            boxSizing: 'border-box',
            overflow: 'hidden',
          }}
          padding='20px'
        >
          <Typography
            variant='titleMedium'
            sx={{
              mb: 1,
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              display: '-webkit-box', /* Flexbox for ellipsis */
              '-webkit-box-orient': 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {translateBackend(article.translatedName)}
          </Typography>
          <Typography
            variant='bodyMedium'
            sx={{
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              display: '-webkit-box', /* Flexbox for ellipsis */
              '-webkit-box-orient': 'vertical',
              WebkitLineClamp: 4,
              maxHeight: '136px',
              color: sys.color.onSurfaceVariant,
            }}
          >
            {translateBackend(article.translatedDescription)}
          </Typography>
        </Box>
      </Card>
    </Link>
  );
};
