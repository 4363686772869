import {
  TextField, ListItem, Button, MenuItem, Box, IconButton, InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import FormModal from '../../../components/modals/formModal';
import { notesStyles } from '../../../components/notes';
import { AffiliationTypes } from '../../../interfaces';

interface Relationship {
  type: string,
  primaryEmail: string,
  userId: string,
  gender: string,
  allocation: number
}

const EditRelationshipUser = (
  {
    onUserVerify, onUserDelete, allowedTypes, email, icon, affiliationType, accountType, gender, allocation,
  }:
  {
    onUserVerify: (relationship?: Relationship | undefined) => void,
    onUserDelete?: (relationship?: Relationship | undefined) => void,
    affiliationType: AffiliationTypes,
    email: string,
    allowedTypes?: string[],
    icon?: JSX.Element,
    accountType?: string,
    gender?: string,
    allocation?: number,
  },
) => {
  const { t } = useTranslation(['clients', 'household', 'affiliationTypes']);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    relationType: affiliationType,
    userId: '',
    gender,
    allocation,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateType = (event: any) => {
    setUser({ ...user, relationType: event.target.value });
  };

  const updateGender = (event: any) => {
    setUser({ ...user, gender: event.target.value });
  };

  const updateAllocation = (event: any) => {
    const key: 'allocation' = event.target.id;
    setUser({ ...user, [key]: event.target.value as number });
  };

  const addRelationship = async (event: any) => {
    onUserVerify({
      primaryEmail: email,
      userId: user.userId,
      type: user.relationType,
      gender: user.gender || '',
      allocation: Number(user.allocation) || 0,
    });
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  const removeRelationship = async () => {
    if (onUserDelete) {
      onUserDelete({
        primaryEmail: email,
        userId: user.userId,
        type: user.relationType,
        gender: user.gender || '',
        allocation: Number(user.allocation) || 0,
      });
    }
    setOpenConfirmationModal(false);
    setOpen(false);
  };

  const disabled = !(user.relationType && email);
  const primaryBeneficiaryResp = (user.relationType === AffiliationTypes.PrimaryBeneficiary
    && accountType
    && accountType.includes('RESP'));
  const genderList = ['MALE', 'FEMALE'];
  const isSuccessorOrOther = (user.relationType === AffiliationTypes.Successor || user.relationType === AffiliationTypes.Other);
  const isCorporate = (accountType && accountType.includes('CASH'));
  const isSuccesorFilterAccount = (): boolean => {
    const accounts = /(TFSA|SPOUSAL_RRIF|LIF|RRIF|RLIF|LRIF|PRIF|)/;
    if (accountType) {
      return (accounts.test(accountType) && user.relationType === AffiliationTypes.Successor);
    }
    return false;
  };

  return (
    <Box>
      <IconButton style={{ marginTop: '-2px' }} data-testid="add-button" onClick={handleClickOpen}>
        {icon}
      </IconButton>
      <FormModal
        loading={false}
        disabled={disabled}
        open={open}
        title= {t('household:relationModal.updateAffiliateTitle')}
        formButton={t('household:relationModal.updateRelation')}
        onSubmit={(event) => addRelationship(event)}
        hideSubmitButton={isSuccesorFilterAccount()}
        handleClose={handleClose}
      >
        <ListItem>
          {/* free text relationship type */}
          {allowedTypes === undefined
            && <TextField
              autoFocus
              label={t('household:relationModal.relationship')}
              data-testid="relation-user-type"
              sx={{ width: '100%' }}
              value={user.relationType}
              onChange={updateType}
            />
          }
          {/* relationship type on of set */}
            {(allowedTypes !== undefined && isCorporate) ? (
              <TextField
              select
              autoFocus
              label={t('household:relationModal.relationship')}
              data-testid="relation-user-type"
              sx={{ width: '100%' }}
              value={user.relationType}
              onChange={updateType}
            >
              {allowedTypes.map((type) => <MenuItem key={type} value={type}>{t(`affiliationTypes:${type}`)}</MenuItem>)}
              </TextField>) : (
              <TextField
              autoFocus
              label={t('household:relationModal.relationship')}
              data-testid="relation-user-type"
              sx={{ width: '100%' }}
              value={t(`affiliationTypes:${user.relationType}`)}
              disabled={true}
            >
              </TextField>
            )
          }
        </ListItem>
        <ListItem>
          <TextField
            label={t('household:relationModal.userEmail')}
            sx={{ width: '100%' }}
            data-testid="relation-user-email"
            value={email}
            id='primaryEmail'
            type='email'
            disabled
          />
        </ListItem>
        {(primaryBeneficiaryResp) ? (
          <ListItem>
            <TextField
              select
              label={t('client:details.gender')}
              data-testid="gender"
              sx={{ width: '100%' }}
              value={user.gender}
              onChange={updateGender}
            >
              {genderList.map((item) => <MenuItem key={item} value={item}>{t(`client:edit.genderOptions.${item}`)}</MenuItem>)}
            </TextField>
          </ListItem>)
          : (!isSuccessorOrOther && !isCorporate) ? (<ListItem>
            <TextField
              label={t('household:relationModal.allocation')}
              fullWidth
              data-testid="relation-user-allocation"
              value={allocation}
              onChange={updateAllocation}
              error={(Number(user.allocation) > 100)}
              id='allocation'
              type='number'
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, inputProps: { min: 0, max: 100 } }}
            />
          </ListItem>)
            : <>
            </>
        }
        <ListItem>
        {(!primaryBeneficiaryResp && !isSuccesorFilterAccount()) ? (
            <Button variant="outlined" sx={{ mt: '20px' }} onClick={() => setOpenConfirmationModal(true)} fullWidth>{t('household:relationModal.removeAffiliate')}</Button>
        )
          : isSuccesorFilterAccount() ? (
          <Button variant="contained" sx={{ mt: '20px' }} onClick={() => setOpenConfirmationModal(true)} fullWidth>{t('household:relationModal.removeAffiliate')}</Button>
          ) : <>
            </>
          }
        </ListItem>
      </FormModal>
      <ConfirmationModal
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => removeRelationship()}
        open={openConfirmationModal}
        title={t('household:relationModal.removeAffiliate')}
        bodyText={t('household:relationModal.removeAffiliateConfirmation')}
        dialogStyles={notesStyles.confirmation}
      />
    </Box>
  );
};

export default EditRelationshipUser;
