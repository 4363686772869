import { ListItemText, Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { SwapHoriz } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { InlineImage } from '../../2-component/inlineImage/inlineImage';
import { OrganizationSwitcher } from './organizationSwitcher';
import { NavigationStyles } from '../../../interfaces';

export const FETCH_LOGO = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {
      perPage: 1
      sortDesc: false,
      sortField: "createdAt"
    }
  }) {
    fileDocuments {
      id
      name
    }
  }
}`;

const OrganizationLogo = ({ textColor }: { textColor: string }) => {
  const { activeOrganization, userContext } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [hover, setHover] = useState(false);
  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;

  const { loading } = useQuery(FETCH_LOGO, {
    variables: {
      filter:
      {
        organizationId: activeOrganization.id,
        types: ['LOGO'],
        objectTypes: ['ORGANIZATION'],
      },
    },
    onCompleted(data) {
      setDocuments(data.fetchFileDocuments.fileDocuments);
    },
  });

  if (loading) return <Box></Box>;

  return (
    <>
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => !isUnifiedClientExperience && setOpen(true)}
        sx={{ cursor: !isUnifiedClientExperience ? 'pointer' : 'inherit', position: 'relative' }}
      >
        {documents.length > 0 ? (
          <Box sx={{ height: '32px', maxWidth: '250px' }}>
            <InlineImage fileDocumentId={documents[0].id} style={{ maxHeight: '32px' }} />
          </Box>
        ) : (
          <ListItemText primary={activeOrganization.name} sx={{ color: textColor, margin: 0 }}/>
        )}
        <SwapHoriz sx={{
          color: textColor,
          fontSize: '1.5rem',
          position: 'absolute',
          right: hover ? -30 : -10,
          top: '50%',
          transform: 'translateY(-50%)',
          transition: 'all 0.3s',
          opacity: !isUnifiedClientExperience && hover ? 1 : 0,
        }} />
      </Box>
      <OrganizationSwitcher open={open} setOpen={setOpen} />
    </>
  );
};

export default OrganizationLogo;
