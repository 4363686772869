import { useContext, useState } from 'react';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './unsignedDocumentsWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { ActionContext, PageObjectType } from '../../5-page';

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

export const UnsignedDocumentsWorkflow = ({
  open, setOpen, objectId, objectType,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType,
}) => {
  const { refetch } = useContext(ActionContext);
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({
    objectId,
    ...initialWorkflowCompletion,
  });

  const updateNext = () => {
    updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
      setOpen(false);
      setWorkflowCompletion({
        objectId,
        ...initialWorkflowCompletion,
      });
      refetch();
    });
  };

  const updatePrevious = () => {
    updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
  };

  return (<>
      <WorkflowCompletionVisual workflowCompletion={workflowCompletion} open={open} onClose={() => {
        refetch();
        setOpen(false);
        setWorkflowCompletion({
          objectId,
          ...initialWorkflowCompletion,
        });
      }} onNext={updateNext} previousStep={updatePrevious} loading={false} />
    </>
  );
};
