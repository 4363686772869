import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Tooltip } from '../tooltip/tooltip';

interface IconButtonProps extends MuiIconButtonProps {
  label?: string;
}

export const IconButton = (props: IconButtonProps) => {
  const { sys } = useThemeTokens();

  if (props.label) {
    return (
      <Tooltip title={props.label} placement='top' >
        <MuiIconButton sx={{
          height: props.size === 'small' ? '24px' : '40px', color: sys.color.onSurface, width: props.size === 'small' ? '24px' : '40px', ...props.sx,
        }} {...props} />
      </Tooltip>

    );
  }

  return (
    <MuiIconButton sx={{
      height: props.size === 'small' ? '24px' : '40px', color: sys.color.onSurface, width: props.size === 'small' ? '24px' : '40px', ...props.sx,
    }} {...props} />
  );
};
