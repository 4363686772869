import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useHouseholdContext } from 'pages/household';
import { useClientContext } from 'pages/client';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { BasicsStep } from './steps/basicsStep';
import { CreateGoalActionStep } from './steps/createGoalActionStep';
import { PickPortfolioStep } from './steps/pickPortfolioStep';
import { SummaryStep } from './steps/summaryStep';

type Step = 'BASICS' | 'PICK_PORTFOLIO' | 'SUMMARY' | 'CREATE_GOAL_ACTION';

const steps:Record<Step, StepComponent> = {
  BASICS: BasicsStep,
  PICK_PORTFOLIO: PickPortfolioStep,
  SUMMARY: SummaryStep,
  CREATE_GOAL_ACTION: CreateGoalActionStep,
};

interface WizardContext {
  forId: string
  forObject: 'USER' | 'CLIENT_GROUP'
  displayName: string
  allowPortfolioPerGoal: boolean
  organizationId: string,
  clientGroupType?: 'HOUSEHOLD' | 'BUSINESS',
}

interface WizardState {
  type: string,
  name: string,
  timeHorizon: string,
  targetAmountCents: number,
  riskQuestion1: string,
  applyPortfolio?: undefined | boolean,
  portfolioThemeId?: string,
  portfolioFinancialProductId?: string,
  tradingProcess?: string,
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  type: '',
  name: '',
  timeHorizon: '',
  targetAmountCents: 0,
  riskQuestion1: '',
};

export interface CreateGoalWizardProps {
  forObject: 'USER' | 'CLIENT_GROUP'
  forId: string,
}

export function CreateGoalWizard({
  forId, forObject, handleClose,
}: CreateGoalWizardProps & WizardControlProps) {
  const { t } = useTranslation('createGoalWizard');
  const householdContext = useHouseholdContext();
  const clientContext = useClientContext();

  const { data: nameQuery } = useQuery(forObject === 'USER' ? FETCH_USER_NAME : FETCH_CLIENT_GROUP_NAME, {
    variables: { id: forId },
  });

  const allowPortfolioPerGoal = clientContext?.orgSettings.allowPortfolioPerGoal || householdContext?.orgSettings.allowPortfolioPerGoal;
  initial.applyPortfolio = allowPortfolioPerGoal ? true : undefined;

  const organizationId = clientContext?.orgSettings.id || householdContext?.orgSettings.id;

  return (
    <WizardBase
      title={t('title')}
      context={{
        forObject,
        forId,
        displayName: nameQuery?.fetch.the.name,
        allowPortfolioPerGoal,
        organizationId,
        clientGroupType: nameQuery?.fetch?.the?.type || '',
      }}
      steps={steps}
      firstStep={'BASICS'}
      initialState={initial}
      handleClose={handleClose}
      minimumHeight={630}
    />
  );
}

const FETCH_CLIENT_GROUP_NAME = gql`
  query fetchClientGroupName($id: ObjectID!) {
    fetch:fetchClientGroup(clientGroupId: $id) {
      the:clientGroup {
        name
        type
      }
    }
  }
`;

const FETCH_USER_NAME = gql`
  query fetchUserName($id: ObjectID!) {
    fetch:fetchUser(userId: $id) {
      the:user {
        name:firstName
      }
    }
  }
`;

export const testables = {
  FETCH_CLIENT_GROUP_NAME,
  FETCH_USER_NAME,
};
