import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../../1-primative';
import {
  Card, CardContent,
  MenuItem, SelectField, IconButton,
} from '../../../2-component';

export default function ColumnsSelect({
  availableColumns, columns, onChange, setColumns,
}: { availableColumns: string[], columns: string[], onChange: (event: any) => void, setColumns: (e: any) => void }) {
  const { t } = useTranslation(['customReports']);

  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) return;
    const updatedList = [...columns];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setColumns(updatedList);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              { columns.map((column: string, index: number) => (
                <Draggable key={column} draggableId={column} index={index}>
                  {(provided2) => (
                    <div
                      ref={provided2.innerRef}
                      {...provided2.dragHandleProps}
                      {...provided2.draggableProps}
                    >
                      <Card variant='outlined' sx={{ mb: 1 }}>
                        <CardContent sx={{ padding: '8px 16px !important' }}>
                          <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography>{ t(`column.${column}`) }</Typography>
                            <IconButton size='small' onClick={() => {
                              const items = [...columns];
                              items.splice(index, 1);
                              setColumns(items);
                            }}>
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Card variant='outlined'>
        <CardContent sx={{ padding: '16px !important' }}>
          <SelectField
            multiple
            label={t('addColumn')}
            value={columns}
            onChange={onChange}
            fullWidth
            renderValue={() => (<>{t('addColumn')}</>)}
          >
            {
              availableColumns.map((x: any) => (
                <MenuItem key={x} value={ x }>
                  {t(`column.${x}`) }
                </MenuItem>
              ))
            }
          </SelectField>
        </CardContent>
      </Card>
    </Box>
  );
}
