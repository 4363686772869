import type { SxProps } from '@mui/material';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useContext, useEffect, useState } from 'react';
import MultiCurrency from '../../../assets/images/custom/multiCurrency.svg';
import type { Statistics } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { UserContext } from '../../../providers/userContextProvider';
import { formatMoneyValue } from '../../../util';
import { Box, Typography } from '../../1-primative';
import { Tooltip } from '../../2-component';

interface IGroupedMarketValue {
  currency: CurrencyCodes;
  marketValueCents: number;
}

interface ICurrencyIndicatorProps {
  titleSx?: SxProps
  statistics?: Statistics;
  useExternalStatisticsEnabled?: boolean;
  readFromSnapshot?: boolean;
}

export const CurrencyIndicator: React.FC<ICurrencyIndicatorProps> = ({
  titleSx,
  statistics,
  useExternalStatisticsEnabled,
  readFromSnapshot = false,
}) => {
  const { sys } = useThemeTokens();
  const { activeCurrency } = useContext(UserContext);
  const [groupedMarketValue, setGroupedMarketValue] = useState<IGroupedMarketValue[]>([]);

  useEffect(() => {
    const holdings = readFromSnapshot ? statistics?.historySnapshot?.holdings : statistics?.holdings;
    if (!useExternalStatisticsEnabled && holdings && holdings.some((holding) => holding.originalCurrency?.currency !== activeCurrency)) {
      const marketValueByCurrency = holdings.reduce<IGroupedMarketValue[]>((acc, holding) => {
        if (holding.originalCurrency) {
          const { originalCurrency } = holding;
          const currencyHolding = acc.find((elem) => elem.currency === originalCurrency.currency);
          if (currencyHolding) {
            currencyHolding.marketValueCents += originalCurrency.totalCents ?? 0;
          } else {
            acc.push({
              currency: originalCurrency.currency ?? CurrencyCodes.CAD,
              marketValueCents: originalCurrency.totalCents ?? 0,
            });
          }
        }
        return acc;
      }, []);
      marketValueByCurrency.sort(sortMarketValueByCurrency);
      setGroupedMarketValue(marketValueByCurrency);
    } else if (useExternalStatisticsEnabled && statistics?.originalCurrencies && statistics?.originalCurrencies.some((elem) => elem.currency !== activeCurrency)) {
      const marketValueByCurrency = statistics.originalCurrencies;
      marketValueByCurrency?.sort(sortMarketValueByCurrency);
      setGroupedMarketValue(marketValueByCurrency);
    }
  }, [statistics, activeCurrency, useExternalStatisticsEnabled, readFromSnapshot]);

  if (groupedMarketValue.length === 0) {
    return null;
  }

  return (
    <Tooltip
      title={
        <>
          {groupedMarketValue.map((elem) => (
            <Typography key={elem.currency} variant='trimmedSmall' color={sys.color.onPrimary} sx={titleSx}>
              {`${elem.currency} ${formatMoneyValue(elem.marketValueCents, '', 2, false)}`}
            </Typography>
          ))}
        </>
      }
      placement='top'
      data-testid='multi-currency-tooltip'
    >
      <Box sx={{ width: '20px', height: '20px' }} data-testid='multi-currency-container'>
        <img src={MultiCurrency} alt='multi-currency' style={{ width: '20px', height: '20px' }} data-testid='multi-currency-image' />
      </Box>
    </Tooltip>
  );
};

type CurrencyValue = Pick<IGroupedMarketValue, 'currency'>;

const sortMarketValueByCurrency = (a: CurrencyValue, b: CurrencyValue) => {
  if (a.currency > b.currency) return 1;
  return a.currency < b.currency ? -1 : 0;
};
