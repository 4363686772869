import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Icon, Typography } from '../../../../../../1-primative';
import { Card, CardContent, IconButton } from '../../../../../../2-component';
import { SubAccountTransfer } from './subAccountTransfer.dialogContent';

interface SubAccountTransferReviewItemProps {
  transfer: SubAccountTransfer,
  onDeleteTransfer: () => void,
}
const SubAccountTransferReviewItem = ({ transfer, onDeleteTransfer }: SubAccountTransferReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'accountTypes']);
  const defineTitle = (): string => t('workflowCompletions:subAccountTransferReviewPanel.title');

  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box pl={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
          <Typography variant='bodyLarge' sx={{ mt: 1, fontWeight: '600' }}>
              {defineTitle()}
          </Typography>
          {
              'id' in transfer && transfer.id ? undefined : <IconButton onClick={() => onDeleteTransfer()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:subAccountTransferReviewPanel.fromPortfolio')}</Typography>
            <Typography variant='labelLarge'>{t(`accountTypes:${transfer?.subAccount?.account?.type ?? ''}`)}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:subAccountTransferReviewPanel.toPortfolio')}</Typography>
            <Typography variant='labelLarge'>{t(`accountTypes:${transfer?.subAccountIn?.account?.type ?? ''}`)}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:subAccountTransferReviewPanel.amount')}</Typography>
            <Typography variant='labelLarge'>{t(`$${transfer?.amountCents && (transfer.amountCents / 100).toLocaleString('en-US')}`)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubAccountTransferReviewItem;
