import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { FeeTier, FeeTierStates } from '../../../interfaces';
import {
  SelectField, MenuItem,
} from '../../2-component';

export const FETCH_FEE_TIERS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      feeTiers {
        id
        name
        organization {
          id
        }
      }
    }
  }
`;

const FeeGridSelect = ({
  setValue, value, label, disabled = false, size, includeAnyOption = false, organizationId, showInherited,
}: {
  setValue: (newValue: string) => void, value: string, label: string, disabled?: boolean, readonly?: boolean, size?: 'small' | 'medium',
  includeAnyOption?: boolean, organizationId?: string, showInherited?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling', 'components']);
  const { activeOrganization } = useContext(UserContext);
  const { data } = useQuery(FETCH_FEE_TIERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          states: FeeTierStates.ACTIVE,
        },
        pagination: { perPage: 1000 },
      },
    },
  });

  return (
    <SelectField
      value={value === '' ? 'any' : value}
      label={label}
      fullWidth
      onChange={(event: any) => { setValue(event.target.value === 'any' ? '' : event.target.value); }}
      disabled={disabled}
      size={size}
    >
      { includeAnyOption && (
        <MenuItem value="any">{t('components:any')}</MenuItem>
      )}
      {
        showInherited && (
          <MenuItem key="inherited" value="inherited">
            {t('feeAndBilling:feeGrid.inherited')}
          </MenuItem>
        )
      }
      {
        (
          data?.fetchFeeTiers.feeTiers.map((x: FeeTier) => (
            <MenuItem
              key={ x.id }
              value={ x.id }
            >
              {x.name}
            </MenuItem>
          ))
        )
      }
    </SelectField>
  );
};

export default FeeGridSelect;
