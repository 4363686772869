/* keep this synchronized with account-service */
export enum AffiliationRelations {
  Child = 'CHILD',
  Grandchild = 'GRANDCHILD',
  Grandparent = 'GRANDPARENT',
  Parent = 'PARENT',
  Sibling = 'SIBLING',
  CommonLaw = 'COMMON_LAW',
  NephewNiece = 'NEPHEW_NIECE',
  Spouse = 'SPOUSE',
  PrimaryCaregiver = 'PRIMARY_CAREGIVER',
  Guardian = 'GUARDIAN',
  Other = 'OTHER',
}

export const RespSinglePrimaryBeneficiaryRelations = [
  AffiliationRelations.Child,
  AffiliationRelations.Grandchild,
  AffiliationRelations.NephewNiece,
  AffiliationRelations.Sibling,
  AffiliationRelations.Other,
];

export const RespFamilyPrimaryBeneficiaryRelations = [
  AffiliationRelations.Child,
  AffiliationRelations.Grandchild,
  AffiliationRelations.Sibling,
];

export const JointAccountHolderRelations = [
  AffiliationRelations.Spouse,
  AffiliationRelations.CommonLaw,
];
