import { gql } from '@apollo/client';
import { TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAccountTypes, EntityTypes } from 'interfaces';
import { useHouseholdContext } from '../../pages/household';
import { useClientContext } from '../../pages/client';

export const FETCH_ACCOUNT_TYPES = gql`
  query fetchAccountTypes {
    fetchAccountTypes {
      accountTypes {
        value
        translatedName {
          en
        }
      }
    }
  }
`;

const AccountTypeSelect = ({
  onChange,
  value,
  label,
  size = 'small',
  omitAny = false,
  sortAlphebeticaly = false,
  userType,
}: {
  onChange: (event: any) => void;
  value?: string;
  label?: string;
  size?: 'small' | 'medium';
  omitAny?: boolean;
  sortAlphebeticaly?: boolean;
  userType: EntityTypes | string;
}) => {
  const { t } = useTranslation(['client', 'components', 'accountTypes']);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const accountTypesData = getAccountTypes(
    userType,
    userType === 'CLIENT_GROUP' ? householdContext?.orgSettings?.availableFeatureFlags : clientContext?.orgSettings?.availableFeatureFlags,
    userType === 'CLIENT_GROUP' ? householdContext?.orgSettings?.applicableLocalization?.countries : clientContext?.orgSettings?.applicableLocalization?.countries,
  );

  return (
    <>
      <TextField select label={label ?? t('client:accountType')} fullWidth value={value ?? 'ANY'} onChange={(e) => onChange(e.target.value)} size={size}>
        {!omitAny && <MenuItem value='ANY'>{t('components:any')}</MenuItem>}
        {accountTypesData?.map((a: any) => (
          <MenuItem key={a} value={a}>
            {t(`accountTypes:${a}`)}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default AccountTypeSelect;
