import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { findLastIndex } from 'lodash';
import { isEmpty } from 'lodash/fp';
import {
  Button,
  Typography,
  Form,
  Box,
  CircularProgress,
} from '../../../..';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Goal, SubAccount, SubAccountStates } from '../../../../../interfaces';
import HoldingCard from '../../../widgets/holdings/components/holdingCard';
import { useAccordionTokens } from '../../../../2-component/accordion/accordian.tokens';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const ReviewPortfolioChangesVisual = ({
  options,
  goals,
  loading,
  continueFunc,
}: {
  options: any;
  goals: Goal[];
  loading: boolean;
  continueFunc: any;
}) => {
  const { t } = useTranslation(['client', 'accountTypes']);
  const tokens = useThemeTokens(useAccordionTokens());
  const { comp } = tokens;
  const submit = () => {
    continueFunc();
  };

  if (!options) return <></>;

  const displayPortfoliolevelGoal = (goal: Goal) => goal?.subAccounts
    && goal?.subAccounts.length > 0 && (
      <>
        <Box key={goal.id} sx={{
          display: 'flex',
          borderBottom: `1px solid ${comp.accordion.multipleBorderColor}`,
          mb: 1,
          pb: 1,
        }}>
          {goal.subAccounts?.filter((s: SubAccount) => (s.state !== SubAccountStates.INACTIVE)).map((subAccount: SubAccount, i: number) => (
            <Typography variant='trimmedSmall' sx={{ fontWeight: 600 }}>
             {t(`accountTypes:${subAccount?.account?.type}`)}
             {i !== findLastIndex(goal.subAccounts) && <span>,</span>}
            </Typography>
          ))}
        </Box>
        {goal?.financialProduct && <HoldingCard options={options} goalId={goal.id} financialProduct={goal?.financialProduct} type='CURRENT' level='GOAL' />}

        {goal?.suggestedFinancialProduct && <HoldingCard options={options} goalId={goal.id} financialProduct={goal?.suggestedFinancialProduct} type='SUGGESTED' level='GOAL' />}
      </>
  );

  const displayPortfolioInSubAccountlevelGoal = (goal: Goal) => goal.subAccounts?.filter((s: SubAccount) => (s.state !== SubAccountStates.INACTIVE))?.map((subAccount: SubAccount) => (
      <>
        <Typography key={subAccount.id} variant='headingXSmall' sx={{
          mt: 2,
          mb: 3,
          borderBottom: `1px solid ${comp.accordion.multipleBorderColor}`,
        }}>
          {t(`accountTypes:${subAccount?.account?.type}`)}
        </Typography>

        {subAccount?.financialProduct && <HoldingCard
          options={options}
          goalId={goal.id}
          financialProduct={subAccount?.financialProduct} type='CURRENT' subAccountId={subAccount.id} level='SUB_ACCOUNT' />}

        {subAccount?.suggestedFinancialProduct && (
          <HoldingCard goalId={goal.id} options={options} financialProduct={subAccount?.suggestedFinancialProduct} type='SUGGESTED' subAccountId={subAccount.id} level='SUB_ACCOUNT' />
        )}
      </>
  ));

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
          {translateBackend(options?.subtitle)}
        </ReactMarkdown>
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        goals
          .filter((a: Goal) => a?.subAccounts && !isEmpty(a?.subAccounts) && a?.subAccounts.filter((s: SubAccount) => (s.state !== SubAccountStates.INACTIVE)).length > 0)
          .map((goal: Goal) => (
            <Box sx={{ mb: 4, width: '100%' }}>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant='headingXSmall'>{t(`goalType.${goal.type}`)}:</Typography>
                <Typography variant='headingXSmall' sx={{ fontWeight: 400, ml: 1 }}>
                  {goal.name}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Box sx={{ mb: 2 }}>{goal?.financialProduct && displayPortfoliolevelGoal(goal)}</Box>
                <Box sx={{ mb: 2 }}>{!goal?.financialProduct && !isEmpty(goal.subAccounts) && displayPortfolioInSubAccountlevelGoal(goal)}</Box>
              </Box>
            </Box>
          ))
      )}

      <Box display='flex' justifyContent='end'>
        <Button label={t('saveAndContinue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default ReviewPortfolioChangesVisual;
