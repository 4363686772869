import { useContext } from 'react';
import {
  MobileDatePicker, MobileDatePickerProps, MobileDateTimePicker, MobileDateTimePickerProps,
} from '@mui/x-date-pickers';
import { UserContext } from '../../providers/userContextProvider';

type LocalizedDatePickerProps = Omit<MobileDatePickerProps<any, any>, 'inputFormat' | 'toolbarFormat'>;
/**
 * Date picker that observes organization's localization settings
 */
export const LocalizedDatePicker = (props: LocalizedDatePickerProps) => {
  const { activeOrganization } = useContext(UserContext);
  const format = activeOrganization.applicableLocalization?.dateFormat ?? 'YYYY-MM-DD';

  return <MobileDatePicker
    inputFormat={format}
    toolbarFormat={format}
    {...props}
  />;
};

type LocalizedDateTimePickerProps = Omit<MobileDateTimePickerProps<any, any>, 'inputFormat' | 'toolbarFormat'>;

/**
 * Date+Time picker that observes organization's localization settings
 */
export const LocalizedDateTimePicker = (props: LocalizedDateTimePickerProps) => {
  const { activeOrganization } = useContext(UserContext);
  const dateFormat = activeOrganization.applicableLocalization?.dateFormat ?? 'YYYY-MM-DD';
  const format = `${dateFormat}  hh:mma`;

  return <MobileDateTimePicker
    inputFormat={format}
    toolbarFormat={format}
    {...props}
  />;
};
