import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ConfigureWorkflowPageVisual } from './configureWorkflowPage.visual';
import { conditionGraphql } from '../../3-pattern/ruleBuilder/ruleBuilder';

const FETCH_WORKFLOW = gql`
  query fetchWorkflow($workflowId: ObjectID!) {
    fetchWorkflow(workflowId: $workflowId) {
      workflow {
        id
        complex
        name { en }
        version
        triggerType
        eventType
        organization {
          id
        }
        state
        steps {
          id
          name { en }
          branches {
            subStepKey
            ${conditionGraphql}
          }
          startNodePosition {
            x
            y
          }
          endNodePosition {
            x
            y
          }
          subSteps {
            id
            key
            type
            options
            canGoBack
            completeableBy
            rolesCompleteableBy {
              id
            }
            branches {
              subStepKey
              ${conditionGraphql}
              contextObject
            }
            nodePosition {
              x
              y
            }
            skippable
          }
        }
        triggerRules {
          field
          comparison
          value
        }
      }
    }
  }
`;

const UPDATE_WORKFLOW = gql`
  mutation updateWorkflow($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      workflow {
        id
      }
    }
  }
`;

const TRANSITION_WORKFLOW = gql`
  mutation transitionWorkflow($input: TransitionWorkflowInput!) {
    transitionWorkflow(input: $input) {
      workflow {
        id
        state
      }
    }
  }
`;

export const ConfigureWorkflowPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(FETCH_WORKFLOW, {
    variables: { workflowId: id },
  });

  const [updateWorkflow, { loading: updateWorkflowPending }] = useMutation(UPDATE_WORKFLOW, {
    refetchQueries: [FETCH_WORKFLOW],
  });
  const [transitionWorkflow, { loading: transitionWorkflowPending }] = useMutation(TRANSITION_WORKFLOW, {
    refetchQueries: [FETCH_WORKFLOW],
  });

  return (
    <div>
      <ConfigureWorkflowPageVisual
        data={data}
        loading={loading || updateWorkflowPending || transitionWorkflowPending}
        updateWorkflow={updateWorkflow}
        transitionWorkflow={transitionWorkflow}
      />
    </div>
  );
};

export default ConfigureWorkflowPage;
