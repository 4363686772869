/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Edit } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Responsive as GridLayout, WidthProvider } from 'react-grid-layout';
import { max, omit } from 'lodash/fp';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Widget from '../dashboard/components/widget';
import { Button, IconButton } from '../../2-component';
import { WidgetModal } from './components/widgetModal';
import { Box, Grid, Skeleton } from '../../1-primative';
import { useGlobalToast } from '../../../providers/globalToastProvider';

const FETCH_DASHBOARD = gql`
  query fetchOrganizationDashboard($dashboardId: ObjectID!) {
    fetchOrganizationDashboard(dashboardId: $dashboardId) {
      dashboard {
        id
        name
        widgets {
          type
          columns
          filters {
            comparison
            field
            value
          }
          format
          grouping {
            field
            interval
            type
            valueAggregation
            valueField
          }
          height
          width
          x
          y
          reportType
          sortDesc
          sortField
          title
        }
      }
    }
  }
`;

const UPDATE_DASHBOARD = gql`
  mutation UpdateOrganizationDashboard($input: UpdateOrganizationDashboardInput!) {
    updateOrganizationDashboard(input: $input) {
      dashboard {
        id  
      }
    }
  }
`;

interface GridObject {
  i: number;
  x: number;
  y: number;
  w: number;
  h: number;
}

const ResponsiveGridLayout = WidthProvider(GridLayout);

export const EditDashboard = () => {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { showToast } = useGlobalToast();
  const [grid, setGrid] = useState<GridObject[]>([]);
  const [open, setOpen] = useState(false);
  const [widgets, setWidgets] = useState<any[]>([]);
  const [widget, setWidget] = useState<any>(null);
  const [widgetIndex, setWidgetIndex] = useState<any>(null);
  const { t } = useTranslation(['customReports', 'shared']);

  const { data, loading } = useQuery(FETCH_DASHBOARD, {
    variables: { dashboardId },
    fetchPolicy: 'no-cache',
  });

  const [updateDashboard] = useMutation(UPDATE_DASHBOARD);

  const newWidget = () => ({
    width: 3,
    height: 2,
    x: 0,
    y: max(widgets.map((x: any) => x.y + x.h)),
    title: '',
    format: '',
    type: '',
    reportType: '',
    columns: [],
    sortDesc: false,
    sortField: 'id',
    filters: [],
    grouping: {
      field: '',
      interval: '',
      type: '',
      valueAggregation: '',
      valueField: '',
    },
  });

  useEffect(() => {
    if (data?.fetchOrganizationDashboard?.dashboard) {
      setWidgets(data.fetchOrganizationDashboard.dashboard.widgets.map((x: any) => ({
        ...omit(['__typename'], x),
        filters: x.filters.map((filter:any) => omit(['__typename'], filter)),
        grouping: omit(['__typename'], x.grouping),
      })));
    }
  }, [data]);

  const save = async () => {
    await updateDashboard({
      variables: {
        input: {
          dashboardId,
          widgets: widgets.map((x: any, i: any) => ({
            height: grid[i].h,
            width: grid[i].w,
            x: grid[i].x,
            y: grid[i].y,
            title: x.title,
            format: x.format,
            type: x.type,
            reportType: x.reportType,
            columns: x.columns,
            sortDesc: x.sortDesc,
            sortField: x.sortField,
            filters: x.filters,
            grouping: x.grouping,
          })),
        },
      },
      onCompleted: () => showToast({ message: t('successSaveDashboard'), severity: 'success' }),
    });
  };

  useEffect(() => {
    let x = 0;
    let y = 0;
    const mapped = widgets.map((w: any, index: number) => {
      if (x + w.width > 12) {
        x = 0;
        y += (widgets[index - 1] as any ?? { height: 1 }).height ?? 0;
      }

      const map = {
        i: index,
        x: w.x ?? x,
        y: w.y ?? y,
        w: w.width,
        h: w.height,
      };

      x += w.width;
      return map;
    });
    setGrid(mapped);
  }, [widgets]);

  if (loading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ResponsiveGridLayout
        rowHeight={128}
        layouts={{
          lg: grid,
        } as any}
        onLayoutChange={(layout: any) => setGrid(layout)}
        cols={{
          lg: 12, md: 12, sm: 12, xs: 12, xxs: 12,
        }}
        margin={[16, 16]}
      >
        {widgets.map((w: any, index: number) => (
          <div key={index} data-grid={grid[index]}>
            <Widget
              widget={{ ...w, width: grid[index]?.w, height: grid[index]?.h }}
              setOpen={() => {}}
              setActiveWidget={() => {}}
            />
            <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => {
              setWidget(w);
              setWidgetIndex(index);
              setOpen(true);
            }}>
              <Edit />
            </IconButton>
          </div>
        ))}
      </ResponsiveGridLayout>
      <Box display='flex' justifyContent='end' mt={4}>
        <Button onClick={() => {
          setWidget(newWidget());
          setWidgetIndex(widgets.length);
          setOpen(true);
        }} label={t('addWidget')} variant='tonal' leadingIcon={Add} />
        <Button sx={{ ml: 2 }} onClick={() => {
          save();
        }} label={t('shared:save')} />
      </Box>
      <WidgetModal
        open={open}
        setOpen={setOpen}
        activeWidget={widget}
        updateWidget={(w: any) => {
          const newWidgets = [...widgets];
          newWidgets[widgetIndex] = w;
          setWidgets(newWidgets);
          setWidget(null);
        }}
        removeWidget={() => {
          const newWidgets = [...widgets];
          newWidgets.splice(widgetIndex, 1);
          setWidgets(newWidgets);
          setWidget(null);
        }}
      />
    </>
  );
};
