/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  InputAdornment,
  ListItem,
  MenuItem,
  Modal,
  TextField,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import {
  AffiliationTypes,
  BankAccount,
  Goal,
  SubAccount,
} from '../../../interfaces';
import CreateNewModal from '../../modals/createNewModal';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import { BankAccountSelect } from '../../inputs/bankAccountSelect';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import AmountField from '../../inputs/amountField';
import { FETCH_FUND_TRANSFER_INSTRUCTIONS } from './paymentIntructionsTable';
import { PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES } from './transfersTable';
import { usePermissions } from '../../../providers/userContextProvider';
import { useLocalization } from '../../../util/useLocalization';

dayjs.extend(utc);

export const CREATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation createScheduledIncomeFundTransfer($input: CreateScheduledIncomeFundTransferInput!) {
    createScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

const FETCH_ACCOUNT = (permissions: string[]) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        }
        scheduledIncomeFundTransfer {
          id
          subAccount {
            financialProduct{
              translatedName {
                en
              }
            }
            goal {
              name
              type
            }
            account {
              type
            }
          }
        }
        affiliations {
          type
          user {
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
          }
        }
        subAccounts {
          id
          state
        }
      }
    }
  }
`;

export const FETCH_GOAL_SCHEDULED_INCOME_TRANSFERS = (permissions: string[]) => gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        }
        id
        subAccounts {
          id
          account {
            id
            type
            scheduledIncomeFundTransfer {
              id
              state
              subAccount {
                account {
                  user {
                    id
                  }
                  type
                  affiliations{
                    user {
                      id
                      ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
                      ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
                      ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
                    }
                  }
                }
              }
              amountPayableType
              dateOfBirth
              frequency
              minimumAnnualAmountCents
              annualAmountCents
              scheduledDate
              bankAccount {
                id
                name
                institutionNumber
                bankAccountNumber
              }
            }
            affiliations{
              type
              user {
                id
                ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
                ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
                ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
              }
            }
          }
        }
      }
    }
  }
`;
const FETCH_ACCOUNTS = (permissions: string[]) => gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        type
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        }
        scheduledIncomeFundTransfer {
          id
          subAccount {
            financialProduct{
              translatedName {
                en
              }
            }
            goal {
              name
              type
            }
            account {
              type
            }
          }
        }
        affiliations {
          type
          user {
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
          }
        }
        subAccounts {
          id
          state
        }
      }
    }
  }
`;
export const FETCH_CLIENT_GROUP_TRANSFER_INFORMATION = (permissions: string[]) => gql`
  query fetchClientGroup($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        primaryUser {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        }
        accounts {
          id
          type
          user {
            id
          }
          scheduledIncomeFundTransfer {
            id
            subAccount {
              financialProduct{
                translatedName {
                  en
                }
              }
              goal {
                name
                type
              }
              account {
                type
              }
            }
          }
          affiliations {
            type
            user {
              ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
              ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
              ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
            }
          }
          subAccounts {
            id
            state
          }
        }
      }
    }
  }
`;
interface ScheduledFundTransferTransferProp {
  amountPayableType: 'GROSS' | 'NET',
  typeOfAnnualPaymentAmount: 'CUSTOM' | 'MINIMUM_ANNUAL_PAYMENT',
  taxOption: 'NO_TAX_ON_MINIMUM' | 'SPECIAL_TAX_RATE' | 'TAX_ON_FULL' | undefined,
  specialTaxRate: number | undefined,
  state: 'ARCHIVED' | 'ACTIVE',
  frequency: string,
  scheduledDate: string,
  subAccountId: string,
  minimumAnnualAmountCents: number | undefined,
  transferredAmountCents: number | undefined,
  annualAmountCents: number | undefined,
  bankAccountId: string,
  bankAccount?: BankAccount,
  subAccount?: SubAccount,
  dateOfBirth: string,
}

const emptyScheduledFundTransferFormState: ScheduledFundTransferTransferProp = {
  amountPayableType: 'GROSS',
  state: 'ACTIVE',
  typeOfAnnualPaymentAmount: 'CUSTOM',
  taxOption: 'NO_TAX_ON_MINIMUM',
  specialTaxRate: 0,
  frequency: '',
  scheduledDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  subAccountId: '',
  minimumAnnualAmountCents: 0,
  transferredAmountCents: 0,
  annualAmountCents: 0,
  bankAccountId: '',
  bankAccount: undefined,
  subAccount: undefined,
  dateOfBirth: '',
};

const NewScheduledIncomeFundTransfer = ({
  afterCreate,
  forObject,
  forId,
  longLabel = false,
  subAccountId,
}: {
  afterCreate: () => void,
  forObject: 'SUB_ACCOUNT' | 'ACCOUNT' | 'USER' | 'GOAL' | 'CLIENT_GROUP',
  forId: string,
  longLabel?: boolean,
  subAccountId?: string,
}) => {
  let relatedSubAccounts: any[] = [];
  let affiliationusers: any[] = [];
  const { permissions } = usePermissions();
  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const { t } = useTranslation(['components', 'shared', 'client']);
  const { localizedDate } = useLocalization();
  const { showToast } = useGlobalToast();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [tempDate, setTempDate] = useState('');
  const [starting, setStarting] = useState('');
  const [goalUserId, setGoalUserId] = useState('');
  const [clientGroupPrimaryUserUserId, setClientGroupPrimaryUserUserId] = useState('');
  const [scheduledFundTransfer, setScheduledFunTransfer] = useState<ScheduledFundTransferTransferProp>(emptyScheduledFundTransferFormState);

  const [createAdjustmentTransfer, opt1] = useMutation(CREATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        amountPayableType: scheduledFundTransfer.amountPayableType ?? undefined,
        dateOfBirth: scheduledFundTransfer.dateOfBirth === '' ? undefined : scheduledFundTransfer.dateOfBirth,
        frequency: scheduledFundTransfer.frequency,
        annualAmountCents: scheduledFundTransfer.annualAmountCents ?? undefined,
        scheduledDate: scheduledFundTransfer.scheduledDate ?? undefined,
        bankAccountId: scheduledFundTransfer.bankAccountId,
        subAccountId: relatedSubAccounts.length === 1 ? relatedSubAccounts[0].id : scheduledFundTransfer.subAccountId,
        transferredAmountCents: scheduledFundTransfer.annualAmountCents,
        specialTaxRate: scheduledFundTransfer.specialTaxRate ?? undefined,
        taxOption: scheduledFundTransfer.taxOption,
      },
    },
    refetchQueries: [FETCH_FUND_TRANSFER_INSTRUCTIONS],
  });
  const [fetchAccounts, { data: accountsData }] = useLazyQuery(FETCH_ACCOUNTS(permissions), {
    variables: {
      input: {
        filter: {
          userId: forId,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
  });

  const [fetchAccount, { data: accountData }] = useLazyQuery(FETCH_ACCOUNT(permissions), {
    variables: { accountId: forId },
  });

  const [fetchGoalTransfers, { data: goalData }] = useLazyQuery(FETCH_GOAL_SCHEDULED_INCOME_TRANSFERS(permissions), {
    variables: { id: forId },
  });

  const [fetchClientGroupTransfers, { data: clientGroupData }] = useLazyQuery(FETCH_CLIENT_GROUP_TRANSFER_INFORMATION(permissions), {
    variables: { id: forId },
  });

  affiliationusers = accountData?.fetchAccount?.account?.affiliations.filter((x: any) => x?.type === AffiliationTypes.Other) || [];
  if (accountData?.fetchAccount?.account?.user?.dateOfBirth) {
    affiliationusers = affiliationusers.concat([{
      user: accountData?.fetchAccount?.account?.user,
    }]);
  }
  relatedSubAccounts = accountData?.fetchAccount?.account?.subAccounts || [];
  if (accountsData?.fetchAccounts?.accounts) {
    accountsData?.fetchAccounts?.accounts.map((a: any) => {
      if (PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES.includes(a.type)) {
        relatedSubAccounts = relatedSubAccounts.concat(a.subAccounts);
      }
      affiliationusers = affiliationusers.concat(a.affiliations.filter((x: any) => (x?.user?.dateOfBirth && x?.type === AffiliationTypes.Other)));
      if (a?.user?.dateOfBirth) {
        affiliationusers = affiliationusers.concat([{
          user: a?.user,
        }]);
      }
    });
  }
  if (goalData?.fetchGoal?.goal?.subAccounts) {
    goalData?.fetchGoal?.goal?.subAccounts.map((a: any) => {
      if (PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES.includes(a?.account?.type)) {
        relatedSubAccounts = relatedSubAccounts.concat(a);
      }
      affiliationusers = affiliationusers.concat(a?.account?.affiliations.filter((x: any) => (x?.user?.dateOfBirth && x?.type === AffiliationTypes.Other)));
    });
    if (goalData?.fetchGoal?.goal?.user?.dateOfBirth) {
      affiliationusers = affiliationusers.concat([{
        user: goalData?.fetchGoal?.goal?.user,
      }]);
    }
  }
  if (clientGroupData?.fetchClientGroup?.clientGroup) {
    clientGroupData?.fetchClientGroup?.clientGroup?.accounts.map((a: any) => {
      if (PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES.includes(a.type)) {
        relatedSubAccounts = relatedSubAccounts.concat(a.subAccounts);
      }
      affiliationusers = affiliationusers.concat(a.affiliations.filter((x: any) => (x?.user?.dateOfBirth && x?.type === AffiliationTypes.Other)));
    });
    if (clientGroupData?.fetchClientGroup?.clientGroup?.primaryUser.dateOfBirth) {
      affiliationusers = affiliationusers.concat([{
        user: clientGroupData?.fetchClientGroup?.clientGroup?.primaryUser,
      }]);
    }
  }
  useEffect(() => {
    if (forObject === 'GOAL') {
      fetchGoalTransfers();
    } else if (forObject === 'USER') {
      fetchAccounts();
    } else if (forObject === 'CLIENT_GROUP') {
      fetchClientGroupTransfers();
    } else {
      fetchAccount();
    }
  }, [forObject]);

  const confirmDate = (): void => {
    setScheduledFunTransfer({ ...scheduledFundTransfer, scheduledDate: tempDate });
    setOpenCalendar(false);
  };
  const goalName = (goal: Goal | undefined) => {
    if (goal === undefined) return '';
    return (goal.name && goal.name[0] !== '6' ? goal.name : t(`client:goalType.${goal.type}`));
  };
  const accountName = (subAccount: SubAccount) => (
    `${t(`accountTypes:${subAccount?.account?.type}`)} - ${goalName(subAccount?.goal)} ${subAccount?.financialProduct && `- ${subAccount?.financialProduct?.translatedName?.en}`}`
  );

  const create = async () => {
    if (accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer && forObject === 'ACCOUNT') {
      const transferData = accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer;
      showToast({
        severity: 'error',
        message: t('components:scheduledFundTransferModal.warningToastMessage', { content: accountName(transferData.subAccount) }),
      });
    } else {
      const createResponse = await createAdjustmentTransfer({
        variables: {
          input: {
            amountPayableType: scheduledFundTransfer.amountPayableType ?? undefined,
            dateOfBirth: scheduledFundTransfer.dateOfBirth === '' ? undefined : scheduledFundTransfer.dateOfBirth,
            frequency: scheduledFundTransfer.frequency,
            annualAmountCents: scheduledFundTransfer.annualAmountCents ?? undefined,
            scheduledDate: scheduledFundTransfer.scheduledDate ?? undefined,
            bankAccountId: scheduledFundTransfer.bankAccountId,
            subAccountId: relatedSubAccounts.length === 1 ? relatedSubAccounts[0].id : scheduledFundTransfer.subAccountId,
            transferredAmountCents: scheduledFundTransfer.annualAmountCents,
            specialTaxRate: Number(scheduledFundTransfer.specialTaxRate),
            taxOption: scheduledFundTransfer.taxOption,
          },
        },
      });
      if (createResponse?.data) {
        showToast({ severity: 'info', message: t('components:transferModal.createdToastMessage') });
      }
      setScheduledFunTransfer(scheduledFundTransfer);
      afterCreate();
    }
  };

  const formatDateForUI = (): string => {
    if (scheduledFundTransfer.scheduledDate) {
      return ` (${localizedDate(scheduledFundTransfer.scheduledDate)})`;
    }
    return '';
  };

  const generateClientNameWithDob = (client: any): string => {
    const clientDob = dayjs(client.dateOfBirth);
    return `${client.firstName} ${client.lastName} (${dayjs().diff(clientDob, 'year')} years)`;
  };
  const disabled = !(scheduledFundTransfer.bankAccountId
    // && scheduledFundTransfer.subAccountId
    && scheduledFundTransfer.scheduledDate
    && scheduledFundTransfer.frequency);

  useEffect(() => {
    setScheduledFunTransfer({ ...scheduledFundTransfer, subAccountId: accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer?.subAccount.id });
  }, [accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer]);

  useEffect(() => {
    setClientGroupPrimaryUserUserId(clientGroupData?.fetchClientGroup?.clientGroup?.primaryUser?.id);
  }, [clientGroupData?.fetchClientGroup?.clientGroup]);

  useEffect(() => {
    setGoalUserId(goalData?.fetchGoal?.goal?.user?.id);
  }, [goalData?.fetchGoal?.goal?.user]);

  const getForId = (type: 'GOAL' | 'CLIENT_GROUP'): string => {
    if (type === 'GOAL') {
      return goalUserId;
    }
    return clientGroupPrimaryUserUserId;
  };
  return (
    <CreateNewModal loading={opt1.loading} disabled={disabled} title={t('components:scheduledFundTransferModal.title')} onSubmit={create}
      {...longLabel && { modalButton: t('transfersTable.addTransfer') }}
    >
      <ListItem>
        <TextField
          select
          key='dateOfBirth'
          value={scheduledFundTransfer.dateOfBirth}
          label={t('components:scheduledFundTransferModal.dateOfBirth')}
          fullWidth
          onChange={(e: any) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, dateOfBirth: e.target.value });
          }}
        >
          {affiliationusers.map((a: any) => (<MenuItem key={a?.user?.id} value={a.user.dateOfBirth}>{generateClientNameWithDob(a.user)}</MenuItem>))}
        </TextField>
      </ListItem>

      {(relatedSubAccounts.length > 1) && forObject === 'ACCOUNT' && (<ListItem>
        <SubAccountSelect
          label={t('components:scheduledFundTransferModal.subAccount')}
          value={scheduledFundTransfer.subAccountId}
          setSubAccount={(e) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, subAccountId: e.target.value });
          }}
          forObject='ACCOUNT'
          forId={forId}
          selectFirst
        />
      </ListItem>)}

      {(forObject === 'USER' || forObject === 'GOAL' || forObject === 'CLIENT_GROUP') && (<ListItem>
        <SubAccountSelect
          label={t('components:scheduledFundTransferModal.subAccount')}
          value={scheduledFundTransfer.subAccountId}
          setSubAccount={(e) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, subAccountId: e.target.value });
          }}
          forObject='USER'
          forId={forObject !== 'USER' ? getForId(forObject) : forId}
          selectFirst
        />
      </ListItem>)}

      <ListItem>
        <TextField
          select
          value={scheduledFundTransfer.typeOfAnnualPaymentAmount}
          label={t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmount')}
          fullWidth
          onChange={(e: any) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, typeOfAnnualPaymentAmount: e.target.value });
          }}
        >
          <MenuItem key='CUSTOM' value='CUSTOM'>{t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmountSources.custom')}</MenuItem>
          <MenuItem key='MINIMUM_ANNUAL_PAYMENT' value='MINIMUM_ANNUAL_PAYMENT'>{t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmountSources.minimumAnnualPayment')}</MenuItem>
        </TextField>
      </ListItem>

      {scheduledFundTransfer.typeOfAnnualPaymentAmount === 'CUSTOM' && (
        <>
          <ListItem>
            <AmountField
              label={t('components:scheduledFundTransferModal.amount')}
              variant="outlined"
              value={scheduledFundTransfer.annualAmountCents}
              fullWidth
              onChange={(e: any) => {
                setScheduledFunTransfer({ ...scheduledFundTransfer, annualAmountCents: e.target.valueCents });
              }}
            />
          </ListItem>

          <ListItem>
            <TextField
              select
              value={scheduledFundTransfer.amountPayableType}
              label={t('components:scheduledFundTransferModal.amountPayableType')}
              fullWidth
              onChange={(e: any) => {
                setScheduledFunTransfer({ ...scheduledFundTransfer, amountPayableType: e.target.value });
              }}
            >
              <MenuItem key='GROSS' value='GROSS'>{t('components:scheduledFundTransferModal.amountPayableTypeSources.gross')}</MenuItem>
              <MenuItem key='NET' value='NET'>{t('components:scheduledFundTransferModal.amountPayableTypeSources.net')}</MenuItem>
            </TextField>
          </ListItem>
        </>
      )}

      <ListItem>
        <TextField
          select
          value={scheduledFundTransfer.frequency}
          label={t('components:scheduledFundTransferModal.schedule')}
          fullWidth
          data-testid="frequency-dropdown"
          onChange={(e: any) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, frequency: e.target.value });
          }}
        >
          <MenuItem key='MONTHLY' value='MONTHLY'>{t('components:scheduledFundTransferModal.scheduleOptions.MONTHLY')}</MenuItem>
          <MenuItem key='QUARTERLY' value='QUARTERLY'>{t('components:scheduledFundTransferModal.scheduleOptions.QUARTERLY')}</MenuItem>
          <MenuItem key='SEMI_ANNUALLY' value='SEMI_ANNUALLY'>{t('components:scheduledFundTransferModal.scheduleOptions.SEMI_ANNUALLY')}</MenuItem>
          <MenuItem key='ANNUALLY' value='ANNUALLY'>{t('components:scheduledFundTransferModal.scheduleOptions.ANNUALLY')}</MenuItem>
        </TextField>
      </ListItem>

      <ListItem>
        <TextField
          select
          value={starting}
          label={t('components:transferModal.starting')}
          fullWidth
          data-testid="starting-dropdown"
          onChange={(e: any) => {
            setStarting(e.target.value);
          }}
        >
          <MenuItem key='now' value='now'>{t('components:transferModal.startingOptions.now')}</MenuItem>
          <MenuItem key='custom' onClick={() => setOpenCalendar(true)} value='futureDate'>{`${t('components:transferModal.startingOptions.futureDate')} ${formatDateForUI()}`}</MenuItem>
        </TextField>
      </ListItem>
      <ListItem>
        <TextField
          select
          value={scheduledFundTransfer.taxOption}
          label={t('components:scheduledFundTransferModal.taxOptionType')}
          fullWidth
          onChange={(e: any) => {
            setScheduledFunTransfer({ ...scheduledFundTransfer, taxOption: e.target.value });
          }}
        >
          <MenuItem key='NO_TAX_ON_MINIMUM' value='NO_TAX_ON_MINIMUM'>{t('components:scheduledFundTransferModal.taxOptions.NO_TAX_ON_MINIMUM')}</MenuItem>
          <MenuItem key='SPECIAL_TAX_RATE' value='SPECIAL_TAX_RATE'>{t('components:scheduledFundTransferModal.taxOptions.SPECIAL_TAX_RATE')}</MenuItem>
          <MenuItem key='TAX_ON_FULL' value='TAX_ON_FULL'>{t('components:scheduledFundTransferModal.taxOptions.TAX_ON_FULL')}</MenuItem>
        </TextField>
      </ListItem>
      {scheduledFundTransfer.taxOption === 'SPECIAL_TAX_RATE' && (
        <>
          <ListItem>
            <TextField
              label={t('components:scheduledFundTransferModal.specialTaxRate')}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, max: 100 },
              }}
              variant="outlined"
              value={scheduledFundTransfer.specialTaxRate}
              fullWidth
              onChange={(e: any) => {
                setScheduledFunTransfer({ ...scheduledFundTransfer, specialTaxRate: e.target.value });
              }}
            />
          </ListItem>
        </>
      )}
      <ListItem>
        <BankAccountSelect
          setBankAccount={(value: string) => setScheduledFunTransfer({ ...scheduledFundTransfer, bankAccountId: value })}
          value={scheduledFundTransfer.bankAccountId}
          label={t('components:scheduledFundTransferModal.bankAccount')}
          userId={forObject === 'USER' ? forId : accountData?.fetchAccount?.account?.user?.id ?? ''}
        />
      </ListItem>

      <Modal
        open={openCalendar}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <Box display="flex" flexDirection="column">
          <LocalizedDatePicker
            open
            disablePast
            value={tempDate}
            minDate={nextDay}
            onChange={(newValue: any) => { setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD')); }}
            onClose={confirmDate}
            renderInput={(params: any) => (
              <TextField
                sx={{ marginBottom: '32px' }}
                variant="standard"
                {...params}
                fullWidth
              />
            )}
          />
          <Box display="flex" style={{ backgroundColor: 'white' }}>
            <Button variant="outlined" onClick={() => setOpenCalendar(false)} style={{ width: '100%', margin: '0.25em' }}>{t('shared:cancel')}</Button>
            <Button variant="contained" onClick={() => confirmDate()} style={{ width: '100%', margin: '0.25em' }}>{t('shared:confirm')}</Button>
          </Box>
        </Box>
      </Modal>
    </CreateNewModal>
  );
};

export default NewScheduledIncomeFundTransfer;
