import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box } from '../../../1-primative';
import {
  Button, ClientSelectField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, TextField,
} from '../../../2-component';
import { OrganizationSelect } from '../../../3-pattern';
// import NewClient from '../../clients/components/newClient';
import { UserContext } from '../../../../providers/userContextProvider';
import { User } from '../../../../interfaces';

const CREATE_CLIENT_GROUP = gql`
mutation createClientGroup($input: CreateClientGroupInput!) {
  createClientGroup(input: $input) {
    clientGroup {
      id
    }
  }
}
`;

export const NewHousehold = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['household']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>(activeOrganization.id ?? '');
  const [primaryUser, setPrimaryUser] = useState<User>();
  const [open, setOpen] = useState(false);

  const [createHouseholdObject, { loading }] = useMutation(CREATE_CLIENT_GROUP, {
    variables: {
      input: {
        name,
        organizationId,
        type: 'HOUSEHOLD',
        primaryUserId: primaryUser?.id,
        relationships: [{
          type: 'Primary',
          userId: primaryUser?.id,
          accessType: 'EDIT',
        }],
      },
    },
  });

  const createHousehold = async (event: any) => {
    const response = await createHouseholdObject();
    afterCreate();
    setName('');
    setPrimaryUser(undefined);
    navigate(`/households/${response.data.createClientGroup.clientGroup.id}`);
  };

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={createHousehold}>
          <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {t('addModal.title')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label={t('addModal.name')}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              data-testid="household-name"
              sx={{ mb: 2 }}
              fullWidth
            />
            <OrganizationSelect
              value={organizationId}
              label={t('addModal.organization')}
              onChange={(value: string) => setOrganizationId(value)}
              sx={{ mb: 2 }}
            />
            <ClientSelectField
              label={t('addModal.primaryUser')}
              user={primaryUser}
              setUser={setPrimaryUser}
              fullWidth
            />
            {/* <NewClient
              buttonType='NONE'
              modalButton={t('relationModal.createNewClient')}
              forceOpen
              newClient={(u: User) => setPrimaryUser(u)}
            /> */}
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:create')}
                type='submit'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export const testables = {
  CREATE_CLIENT_GROUP,
};
