import {
  Box,
  Card, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { AddHouseholdMemberWizardButton } from 'components/wizards/addHouseholdMember/button';
import { EditHouseholdMemberWizard } from 'components/wizards/editHouseholdMember/wizard';
import { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Tooltip, colors } from 'ovComponents';
import {
  ClientGroup,
  EntityTypes,
  Relationship,
  User,
} from '../../../interfaces';
import { formatMoneyValue, formatPhoneNumber, generateClientNameString } from '../../../util';
import { MemberBadge } from './memberBadge';
import { HouseholdMemberRemoveDialog } from './HouseholdMembersRemoveDialog';

export const HouseholdMembers = ({ clientGroup }: { clientGroup: ClientGroup }) => {
  const { t } = useTranslation('household');
  const navigate = useNavigate();
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenuRelation, setContextMenuRelation] = useState<null | Relationship>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [memberEditorOpen, setMemberEditorOpen] = useState(false);
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  const relationships: Relationship[] = clientGroup.relationships ?? [];

  const closeContextMenu = () => {
    setContextMenuAnchorEl(null);
  };

  const netWorthCents = (user: User) => (user.financialLiquidAssetsCents ?? 0)
    + (user.financialFixedAssetsCents ?? 0)
    - (user.totalDebtCents ?? 0);

  const netWorthTooltip = `${t('client:details.financialLiquidAssetsCents')} + ${t('client:details.financialFixedAssetsCents')} - ${t('client:details.totalDebtCents')}`;

  const isIndividualUser = (user: User): boolean => user && (!user.type || user.type === EntityTypes.INDIVIDUAL);

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='overline'>{t('membersTable.name')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.relationship')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.phoneNumber')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.email')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.netWorth')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.annualIncome')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.status')}</Typography></TableCell>
            <TableCell><Typography variant='overline'>{t('membersTable.permissions')}</Typography></TableCell>
            <TableCell align='right'>
              <AddHouseholdMemberWizardButton clientGroupId={clientGroup.id} primaryUserName={generateClientNameString(clientGroup.primaryUser)} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relationships.map((relationship, index) => (
            <TableRow
              key={index}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '3.6em', cursor: 'pointer' }}
              onClick={() => navigate(`/${isIndividualUser(relationship.user) ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${relationship.user.id}`)}
            >
              <TableCell>
                {relationship.user.firstName
                  ? <MemberBadge sequenceNumber={index} initials={(relationship.user.firstName ?? '').substring(0, 1).toLocaleUpperCase()} />
                  : <MemberBadge sequenceNumber={index} initials={(relationship.user.entityName ?? '').substring(0, 1).toLocaleUpperCase()} />
                }
                {generateClientNameString(relationship.user)}
              </TableCell>
              <TableCell>
                {clientGroup.primaryUser.id === relationship.user.id
                  ? t('summary.primaryContact')
                  : relationship.type
                }
              </TableCell>
              <TableCell>
                {formatPhoneNumber(relationship.user.phone)}
              </TableCell>
              <TableCell>
                {relationship.user.primaryEmail}
              </TableCell>
              <TableCell>
                <Tooltip title={netWorthTooltip}>
                  {formatMoneyValue(netWorthCents(relationship.user))}
                </Tooltip>
              </TableCell>
              <TableCell>
                {formatMoneyValue(relationship.user.annualIncomeCents)}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {relationship.user.state === 'ACTIVE'
                    ? <CheckCircleIcon color='success' sx={{ fontSize: '16px' }} />
                    : <ReportProblemIcon color='error' sx={{ fontSize: '16px' }} />
                  }
                  <Box ml={0.5}>
                    {t(`client:details.state.${relationship.user.state}`)}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                {t(`household:accessTypes.${relationship.accessType}`)}
              </TableCell>
              <TableCell
                align='right'
                sx={{ cursor: 'default' }}
                onClick={(e) => e.stopPropagation()}
              >
                {relationship.user.id !== clientGroup.primaryUser.id && (
                  <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setContextMenuAnchorEl(event.currentTarget);
                    setContextMenuRelation(relationship);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Context drop-down menu */}
      <Menu
        anchorEl={contextMenuAnchorEl}
        open={contextMenuAnchorEl !== null}
        onClose={closeContextMenu}
        onClick={closeContextMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setMemberEditorOpen(true)}>
          <EditRoundedIcon />
          <Typography ml='0.5em'>{t('membersTable.editHouseholdMember')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeleteConfirmationOpen(true)} sx={{ color: colors.negative }}>
          <DeleteForeverIcon />
          <Typography ml='0.5em'>{t('membersTable.removeFromHousehold')}</Typography>
        </MenuItem>
      </Menu>

      {/* Member removal confirmation dialog */}
      {deleteConfirmationOpen && contextMenuRelation?.user && (
        <HouseholdMemberRemoveDialog
          member={contextMenuRelation?.user}
          household={clientGroup}
          onClose={() => setDeleteConfirmationOpen(false)}
        />
      )}

      {/* Member edit dialog */}
      {memberEditorOpen && contextMenuRelation?.user && (
        <EditHouseholdMemberWizard
          clientGroupId={clientGroup.id}
          relationship={contextMenuRelation}
          primaryUserName={generateClientNameString(clientGroup.primaryUser)}
          handleClose={() => setMemberEditorOpen(false)}
        />
      )}

    </Card>
  );
};
