import {
  filter, intersection, isNull, map, pick, pickBy,
} from 'lodash/fp';
import { DocumentNode, gql, useMutation } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';
import { ClientGroup } from '../../../interfaces';
import { Field } from '../../client/components/profile';
import { usePermissions } from '../../../providers/userContextProvider';
import EditableDetailsBox from '../../../components/inputs/editableDetailsBox';

const UPDATE_CLINETGROUP = gql`
  mutation updateClientGroup($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup {
        id
      }
    }
  }
`;

const CorporationDetailBox = ({
  title, children, clientGroup, section, buttonText, fields = [], refetchQueries, modalButton,
}: { title: string, children: any, clientGroup?: ClientGroup, section?: string, buttonText?: string, fields?: Field[],
  refetchQueries: DocumentNode[], modalButton?: string,
}) => {
  const { permissions } = usePermissions();

  const updateableFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));

  const [updateClientGroup, { loading }] = useMutation(UPDATE_CLINETGROUP);

  const onUpdate = async (corporateAccountToUpdate: any) => {
    await updateClientGroup({
      variables: {
        input: {
          clientGroupId: corporateAccountToUpdate?.id,
          ...pick(intersection(updateableFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), omitDeep(corporateAccountToUpdate, '__typename'))),
        },
      },
      refetchQueries,
    });
  };

  return (
    <EditableDetailsBox
      title={title}
      object={clientGroup}
      section={section}
      buttonText={buttonText}
      fields={fields}
      i18nextNamespace='corporations'
      onUpdate={onUpdate}
      modalButton={modalButton}
      loading={loading}
      children={children}
    />
  );
};

export default CorporationDetailBox;
