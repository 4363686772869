import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  InputAdornment,
  TextField,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Paper,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import TuneIcon from '@mui/icons-material/Tune';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Search } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { FeeTier, FeeTierStates } from '../../../interfaces';
import EditFeeGrid from './editFeeGrid';
import NewFeeGrid from './newFeeGrid';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const FETCH_FEE_GRIDS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      totalCount
      feeTiers {
        id
        state
        name
        default
        chargeForCash
        chargeForCashOnHold
        flatAnnualFeeBps
        grid { minMarketValueCents annualFeeBps annualFeeCents }
        gridScopeType
        blendedGrid
        organization { id name }
      }
    }
  }
`;

const TRANSITION_FEE_GRID = gql`
  mutation transitionFeeTier($input: TransitionFeeTierInput!) {
    transitionFeeTier(input: $input) {
      feeTier {
        id
      }
    }
  }
`;

const FeeGridTable = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const [feeGridOpen, setFeeGridOpen] = useState(false);
  const [selectedFeeGrid, setSelectedFeeGrid] = useState<any>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [searchText, setSearchText] = usePageState('', 'q');

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_FEE_GRIDS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          name: searchText,
          states: [FeeTierStates.ACTIVE, FeeTierStates.INACTIVE],
        },
        pagination: {
          sortField: 'name', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);

  const openContextMenu = (onElement: HTMLElement, feeTier: FeeTier) => {
    setSelectedFeeGrid(feeTier);
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);

  const [transitionFeeTier, { loading: transitionLoading }] = useMutation(TRANSITION_FEE_GRID, {
    refetchQueries: [FETCH_FEE_GRIDS],
  });

  const toggleFeeTierState = async (feeTier: FeeTier) => {
    const response = await transitionFeeTier({
      variables: {
        input: {
          feeTierId: feeTier.id,
          transition: feeTier.state === FeeTierStates.ACTIVE ? 'deactivate' : 'activate',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'info', message: t('feeAndBilling:feeGrid.edit.updatedToastMessage') });
    }
  };

  const deleteFeeTier = async (feeTier: FeeTier) => {
    const response = await transitionFeeTier({
      variables: {
        input: {
          feeTierId: feeTier.id,
          transition: 'archive',
        },
      },
    });
    if (response?.data) {
      showToast({ severity: 'info', message: t('feeAndBilling:feeGrid.edit.deletedToastMessage') });
    }
    setDeleteConfirmationOpen(false);
  };

  const canWrite = permissions.includes('write:fee_tier') && permissions.includes('transition:fee_tier');
  const isInheirited = (feeTier: FeeTier) => feeTier.organization?.id !== activeOrganization.id;

  if (error) (<Typography>Error</Typography>);

  if (loading && !previousData) {
    (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress sx={{ m: 18 }} />
    </Box>
    );
  }

  return (
    <>
      <Paper>
        <Grid container sx={{ p: 2 }} spacing={1} justifyContent='space-between'>
          <Grid item>
            <TextField
              label={t('feeAndBilling:feeGrid.nameFilter')}
              sx={{ width: '300px', m: 1 }}
              size='small'
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                setPage(1);
              }}
              id='searchText'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
              <Tooltip title={t('feeAndBilling:feeGrid.clearFilter')} placement="right">
                <IconButton sx={{ cursor: 'pointer', mt: 1 }} onClick={() => {
                  setSearchText('');
                  setPage(1);
                }} >
                  <TuneIcon />
                </IconButton>
              </Tooltip>
          </Grid>
          {canWrite && (
            <Grid item sx={{ marginRight: 1 }}>
              <NewFeeGrid />
            </Grid>
          )}
        </Grid>
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='overline'>{t('feeAndBilling:feeGrid.table.name')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('feeAndBilling:feeGrid.table.type')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('feeAndBilling:feeGrid.table.state')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('feeAndBilling:feeGrid.table.organization')}</Typography></TableCell>
              {canWrite && (<TableCell></TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || previousData)?.fetchFeeTiers?.feeTiers?.map((feeTier: FeeTier) => (
              <TableRow
                hover
                onClick={(e) => {
                  setSelectedFeeGrid(feeTier);
                  setFeeGridOpen(true);
                }}
                key={feeTier.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              >
                <TableCell><Typography>{feeTier.name}</Typography></TableCell>
                <TableCell>
                  <Typography>
                    {feeTier.flatAnnualFeeBps
                      ? t('feeAndBilling:feeGrid.table.flat')
                      : (feeTier.blendedGrid ? t('feeAndBilling:feeGrid.table.blendedGrid') : t('feeAndBilling:feeGrid.table.volumeGrid'))
                    }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip size='small'
                    label={feeTier.state === FeeTierStates.ACTIVE ? t('feeAndBilling:feeGrid.table.active') : t('feeAndBilling:feeGrid.table.inactive')}
                    color={feeTier.state === FeeTierStates.ACTIVE ? 'success' : 'error'}
                  />
                </TableCell>
                <TableCell>
                  <Typography>{feeTier.organization?.name}</Typography>
                </TableCell>
                {canWrite && (
                  <TableCell align="right">
                    {!isInheirited(feeTier) ? (
                      <IconButton
                        size='small'
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          event.preventDefault();
                          event.stopPropagation();
                          openContextMenu(event.currentTarget, feeTier);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    ) : (
                      <Tooltip title={t('shared:inheritedTooltip', { model: 'Fee Grid', organization: feeTier.organization?.name })}>
                        <Chip size='medium' label={t('shared:inheritedFlag')} />
                      </Tooltip>)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchFeeTiers?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        {selectedFeeGrid && (
          <EditFeeGrid
            feeTierToUpdate={selectedFeeGrid}
            open={feeGridOpen}
            handleClose={() => {
              setFeeGridOpen(false);
              setSelectedFeeGrid(null);
            }}
            canWrite={canWrite}
            isInheirited={isInheirited(selectedFeeGrid)}
            organizationId={activeOrganization?.id}
          />
        )}
      </Paper>
      {selectedFeeGrid && canWrite && (
        <>
          <Menu
            anchorEl={contextMenuAnchorEl}
            open={contextMenuOpen}
            onClose={closeContextMenu}
            onClick={closeContextMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx = {{ cursor: 'pointer' }}
          >
            <MenuItem onClick={() => toggleFeeTierState(selectedFeeGrid)}>{selectedFeeGrid.state === FeeTierStates.ACTIVE ? t('shared:deactivate') : t('shared:activate')}</MenuItem>
            <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>{t('shared:delete')}</MenuItem>
          </Menu>
          <ConfirmationModal
            open={deleteConfirmationOpen}
            loading={transitionLoading}
            title={t('feeAndBilling:feeGrid.delete.confirmationTitle')}
            bodyText={t('feeAndBilling:feeGrid.delete.confirmationMessage')}
            onConfirm={() => deleteFeeTier(selectedFeeGrid)}
            onCancel={() => setDeleteConfirmationOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default FeeGridTable;
