import {
  Dialog, DialogContent, Typography, Grid, Table, TableHead,
  TableRow, TableCell, TableBody, Collapse,
} from '@mui/material';

const RiskReasonsModal = ({
  title, description, open, handleClose, riskReasons, children, score,
}: {
  title: string, description: string, open: boolean, handleClose: () => void, riskReasons?: any[], children?: any, score?: number
}) => (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogContent>
        {children}
        <>
            <Grid container justifyContent='space-evenly'>
            <Grid item sx={{ textAlign: 'center' }}>
              <Table aria-label="collapsible table">
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant='overline'>
                          {title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                          <Typography variant='overline'>
                            {description}
                          </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {riskReasons?.map((reason: any) => (
                      <TableRow key={reason}>
                        <TableCell component="th" scope="row">
                          {reason.translatedDisplayName?.en}
                        </TableCell>
                        <TableCell>
                          {reason.translatedDescription?.en}
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                </Collapse>
              </Table>
            </Grid>
          </Grid>
        </>
      </DialogContent>
    </Dialog>
);

export default RiskReasonsModal;
