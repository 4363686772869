import {
  ListItem, Typography, Radio,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_ACCOUNTS } from '../inputs/accountSelect';
import { Account, AccountTypes, getAccountName } from '../../interfaces';
import { FETCH_ACCOUNT } from '../../pages/accountsDetail';
import { usePermissions } from '../../providers/userContextProvider';
import FormModal from './formModal';

export const UPDATE_ACCOUNT_FEE_PAYMENT = gql`
mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    account{
      id
    }
  }
}
`;

interface AccountInfo { type: string, id: string, custodianAccountNumber: string }

const FeePaymentModal = ({
  handleClose, open, account,
}: { account: Account, handleClose: () => void, open: boolean }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['feePayment', 'configureModels', 'accountTypes']);
  const { loading, data: AccountsData } = useQuery(FETCH_ACCOUNTS(permissions), {
    variables: {
      filter: {
        userId: account.user.id,
        states: ['ACTIVE', 'REQUESTED', 'FROZEN', 'FAILED', 'INITIATED', 'READY'],
      },
    },
  });

  const [selectedPaymentAccountId, setSelectedPaymentAccountId] = useState(account.feePaymentAccount?.id ?? account.id);
  const [updateAccount, { loading: updateLoading }] = useMutation(UPDATE_ACCOUNT_FEE_PAYMENT, {
    variables: {
      input: {
        feePaymentAccountId: selectedPaymentAccountId === account.id ? null : selectedPaymentAccountId,
        accountId: account.id,
      },
    },
    refetchQueries: [FETCH_ACCOUNT(permissions)],
  });
  const onSave = (event: any) => {
    event.preventDefault();
    updateAccount();
    handleClose();
  };
  /* eslint-disable no-unsafe-optional-chaining */
  let sortedAccounts: AccountInfo[] = [];
  if (AccountsData) {
    sortedAccounts = [
      ...AccountsData?.fetchAccounts?.accounts.filter((x: AccountInfo) => (x.id === account.id)),
      ...AccountsData?.fetchAccounts?.accounts
        .filter((x: AccountInfo) => (x.id !== account.id && [AccountTypes.CASH_JOINT, AccountTypes.PERSONAL].includes(x.type as AccountTypes))),
    ];
  }

  return (
    <FormModal
      onSubmit={onSave}
      loading={loading}
      disabled={updateLoading || loading}
      title={t('feePayment:title')}
      open={open}
      handleClose={handleClose}
      formButton={t('shared:save')}
      maxWidth='sm'
      subTitle={t('feePayment:subTitle')}
    >
      {
        loading ? <ListItem>...</ListItem>
          : (sortedAccounts.map((x: AccountInfo) => (
            <ListItem
              style={{
                cursor: 'pointer',
                paddingTop: 0,
                paddingBottom: 0,
              }}
              key={x.id}
              value={x.id}
              onClick={() => {
                setSelectedPaymentAccountId(x.id);
              }}
              data-testid={x.id}
            >
              <Radio checked={selectedPaymentAccountId === x.id} />
              <Typography>
                {`${getAccountName(x as Partial<Account>)} ${x.id === account.id ? t('feePayment:currentAccount') : ''}`}
              </Typography>
            </ListItem>
          ))
          )
      }
      <ListItem />
    </FormModal>
  );
};

export default FeePaymentModal;
