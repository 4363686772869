import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ConfirmationModal from 'components/modals/confirmationModal';
import { FETCH_CLIENTGROUP_RELATIONSHIPS, UPDATE_CLIENTGROUP_RELATIONSHIPS } from 'components/wizards/addHouseholdMember/steps/addHouseholdMemeberActionStep';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { usePermissions } from 'providers/userContextProvider';
import { useGlobalToast } from 'providers/globalToastProvider';
import FormModal from 'components/modals/formModal';
import { ListItem, Typography } from '@mui/material';
import { FETCH_USER } from 'pages/client';
import { ClientGroup, Relationship, User } from '../../../interfaces';
import { generateClientNameString } from '../../../util';
import { FETCH_HOUSEHOLD } from '..';

export const HouseholdMemberRemoveDialog = ({ member, household, onClose }: { member: User, household: ClientGroup, onClose: () => void }) => {
  const { t } = useTranslation('household');
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const [numLinkedAccounts, setNumLinkedAccounts] = useState<number | undefined>();
  const [deletePending, setDeletePending] = useState(false);
  const [fetchClientGroupRelationships] = useLazyQuery(FETCH_CLIENTGROUP_RELATIONSHIPS, { variables: { clientGroupId: household.id }, fetchPolicy: 'no-cache' });
  const [updateRelationships] = useMutation(UPDATE_CLIENTGROUP_RELATIONSHIPS);

  useQuery(FETCH_HOUSEHOLD_LINKED_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: member.id,
      clientGroupId: household.id,
    },
    onCompleted: (data) => {
      setNumLinkedAccounts(Number(data.fetchAccounts.totalCount));
    },
  });

  const deleteMember = (removeUserId: string) => {
    setDeletePending(true);
    fetchClientGroupRelationships({
      onCompleted: (existingData) => {
        const existingRelationships = existingData.fetchClientGroup.clientGroup.relationships.map((r: Relationship) => ({
          type: r.type,
          userId: r.user.id,
          accessType: r.accessType,
        }));
        updateRelationships({
          variables: {
            clientGroupId: household.id,
            relationships: existingRelationships.filter((r:any) => r.userId !== removeUserId),
          },
          refetchQueries: [FETCH_HOUSEHOLD(permissions), FETCH_USER(permissions)],
          onCompleted: () => {
            setDeletePending(false);
            showToast({ severity: 'success', message: t('remove.memberHasBeenRemoved') });
            onClose();
          },
        });
      },
      onError: () => setDeletePending(false),
    });
  };

  if (numLinkedAccounts === undefined) {
    return (
      <></>
    );
  }

  if (numLinkedAccounts !== 0) {
    return (
      <FormModal
        hideSubmitButton={numLinkedAccounts === undefined}
        onSubmit={onClose}
        handleClose={onClose}
        loading={false}
        title={t('remove.accountsExist')}
        open={true}
        formButton={t('shared:back')}
        maxWidth='xs'
      >
        <ListItem>
          <Typography>
          {t('remove.thereAreAccountLinked', { clientName: generateClientNameString(member), count: numLinkedAccounts })}
          </Typography>
        </ListItem>
        <></>
      </FormModal>
    );
  }

  /* numLinkedAccounts === 0 */
  return (
    <ConfirmationModal
      open={true}
      title={t('remove.areYouSure', { clientName: generateClientNameString(member), householdName: household.name })}
      bodyText={t('remove.thisWillNotDelete')}
      onConfirm={() => deleteMember(member.id) }
      onCancel={onClose}
      confirmButton={t('remove.yesRemove')}
      cancelButton={t('shared:cancel')}
      loading={deletePending}
      confirmColor='error'
    />
  );
};

export const FETCH_HOUSEHOLD_LINKED_ACCOUNTS = gql`
  query fetchHouseholdLinkedAccounts($userId: ObjectID!, $clientGroupId: ObjectID!) {
    fetchAccounts(input: {
      filter: {
        clientGroupId: $clientGroupId
        userId: $userId
      }
    }) {
      totalCount
    }
  }
`;
