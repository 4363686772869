import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Icon, Typography } from '../../../../../../1-primative';
import {
  Button, Form,
} from '../../../../../../2-component';
import { SubAccount, TranslatedString } from '../../../../../../../interfaces';
import { SubAccountSelect } from '../../../../../../3-pattern/subaccountSelect/subAccountSelect';
import { AmountField } from '../../../../../../3-pattern/amountField/amountField';
import { useThemeTokens } from '../../../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../../../assets/i18n/config';

export interface SubAccountTransfer {
  subAccount?: SubAccount,
  fromSubAccountId?: string,
  amountCents?: number,
  toSubAccountId?: string,
  subAccountIn?: SubAccount,
}

interface SubAccountTransferDialogContentProps {
  onTransferCreated: (transfer: SubAccountTransfer) => void,
  userData: any,
  onDialogClose: () => void,
  disclaimer?: TranslatedString | null,
  options?: any,
}

const SubAccountTransferDialogContent = ({
  onTransferCreated, userData, onDialogClose, disclaimer,
}: SubAccountTransferDialogContentProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes', 'components']);
  const [inboundAccount, setInboundAccount] = useState<SubAccount | undefined>();
  const [outboundAccount, setOutboundAccount] = useState<SubAccount | undefined>();
  const [amount, setAmount] = useState('');
  const { sys } = useThemeTokens();

  const submit = () => {
    onTransferCreated({
      amountCents: Number(amount) * 100,
      fromSubAccountId: outboundAccount?.id ?? '',
      toSubAccountId: inboundAccount?.id ?? '',
      subAccount: outboundAccount,
      subAccountIn: inboundAccount,
    });
  };
  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:subAccountTransferCreation.title')}</Typography>
      <SubAccountSelect
        sx={{ mt: '24px' }}
        selectedSubAccount={outboundAccount}
        onSubAccountSelect={setOutboundAccount}
        userId={userData.id}
        label={t('workflowCompletions:subAccountTransferCreation.from')}
      />
      <SubAccountSelect
        sx={{ mt: '24px' }}
        selectedSubAccount={inboundAccount}
        onSubAccountSelect={setInboundAccount}
        userId={userData.id}
        label={t('workflowCompletions:subAccountTransferCreation.to')}
        subAccountsToExclude={[outboundAccount?.id ?? '']}
      />
        <Grid
            container
            display='flex'
            flexDirection='row'
            sx={{ mt: '20px', mb: '24px' }}
            justifyContent='space-between'
            spacing={1}
            alignItems='end'
        >
          <Grid item xs={4}>
            <AmountField amount={amount} setAmount={setAmount} />
          </Grid>
        </Grid>
      {disclaimer && (
        <Box sx={{
          mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
        }}>
          <Typography variant='bodySmall'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(disclaimer)}</ReactMarkdown>
          </Typography>
        </Box>
      )
      }
        <Box width='100%' display='flex' justifyContent='end'>
          <Button
            color='primary'
            label={t('workflowCompletions:addTransfer')}
            disabled={false}
            sx={{ mt: 3 }}
            type='submit'
          />
        </Box>
    </Form>
  );
};

export default SubAccountTransferDialogContent;
