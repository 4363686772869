import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { PortfolioSubAccount } from './interfaces';
import { TableHeadCell, Tooltip } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import TaxRank from './taxRank';

export const SubAccountTableHeadCell = ({ subAccount }: { subAccount: PortfolioSubAccount }) => {
  const { t } = useTranslation(['components', 'accountTypes']);

  return (
    <TableHeadCell
      sx={{ whiteSpace: 'nowrap' }}
    >
      <Box sx={{ width: `${Math.max(subAccount.name.length * 10, 100)}px` }} display='flex' justifyContent='start' alignItems='center'>
        <TaxRank taxRank={subAccount.taxRank ?? 0} />

        <Typography sx={{ whiteSpace: 'nowrap' }}>{subAccount.name}</Typography>

        {!!subAccount.inactive && (
          <Tooltip title={t('portfolioTable.subAccountIsInactive')}>
            <LockIcon sx={{ verticalAlign: 'text-top', fontSize: '14px' }} />
          </Tooltip>
        )}
      </Box>
    </TableHeadCell>
  );
};
