import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import { generateClientNameString } from '../../util';

const FETCH_USER_NAME = (permissions: string[]) => gql`
  query fetchUserName($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        entityName # for NonIndividual Clients from Households route
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
      }
    }
  }
`;

export function ClientBreadcrumb() {
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);

  const userId = paramsUserId ?? activeEntity?.id;
  const { data } = useQuery(FETCH_USER_NAME(permissions), { variables: { userId } });

  return data ? generateClientNameString(data.fetchUser?.user) : t('client:client');
}
