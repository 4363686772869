import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClientGroup } from '../../../interfaces';
import CorporationDetailBox from './CorporationDetailBox';
import { Date } from '../../../components/misc/date/date';
import { CountryName } from '../../../components/fields/countryName';
import { generateAddressString } from '../../../util';
import { Field } from '../../client/components/profile';
import Phone from '../../../components/fields/phone';
import { FETCH_CORPORATION } from '..';

const CorporationDetails = ({ clientGroup }: { clientGroup: ClientGroup }) => {
  const { t } = useTranslation(['corporations', 'feeAndBilling', 'shared', 'client']);

  const detailsFields: Field[] = [
    {
      key: 'name',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.name'),
    },
    {
      key: 'incorporationNumber',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.incorporationNumber'),
    },
    {
      key: 'businessIsMemberOfIiroc',
      permission: 'client_group',
      column: 12,
      type: 'booleanSelect',
      label: t('corporations:edit.businessIsMemberOfIiroc'),
    },
    {
      key: 'taxNumber',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.taxNumber'),
    },
    {
      key: 'businessType',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.businessType'),
      type: 'select',
      options: ['CORPORATION', 'LLC', 'PARTNERSHIP', 'SOLE_PROPRIETORSHIP'],
    },
    {
      key: 'establishedDate',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.establishedDate'),
      type: 'date',
    },
    {
      key: 'physicalAddress',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.physicalAddress'),
      type: 'address',
    },
  ];

  const contactFields: Field[] = [
    {
      key: 'primaryEmail',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.primaryEmail'),
      validation: {
        rule: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'client:profile.fieldValidations.primaryEmail',
      },
    },
    {
      key: 'phone',
      permission: 'client_group',
      column: 12,
      label: t('corporations:edit.phone'),
      validation: {
        rule: /^([0-9]{10})?$/, // ^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        message: 'client:profile.fieldValidations.phone',
      },
    },
  ];

  const feeFields: Field[] = [
    {
      key: 'feeTierId',
      permission: 'client_group',
      column: 12,
      label: t('feeAndBilling:feeReport.table.feeGrid'),
      type: 'feeTier',
    },
    {
      key: 'billingScheduleId',
      permission: 'client_group',
      column: 12,
      label: t('feeAndBilling:feeReport.table.billingSchedule'),
      type: 'billingSchedule',
    },
  ];

  return (
    <Grid container spacing={2} sx={{ overflowWrap: 'break-word' }}>
      <Grid item xs={12} lg={6}>
        <CorporationDetailBox
          title={t('corporations:details.accountInformation')}
          clientGroup={clientGroup}
          section="DETAILS"
          buttonText={t('client:edit.buttons.save')}
          fields={detailsFields}
          refetchQueries={[FETCH_CORPORATION]}
          modalButton={t('client:form.edit')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.name')}</Typography>
              <Typography>{clientGroup.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.esablishedDate')}</Typography>
              <Date date={clientGroup.establishedDate} />
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:edit.corporationCountry')}</Typography>
              <CountryName code={clientGroup.corporationCountry ?? ''} />
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.address')}</Typography>
              <Typography>{generateAddressString(clientGroup.physicalAddress)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.type')}</Typography>
              <Typography>{t(`corporations:types.${clientGroup.businessType}`)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.incorporationNumber')}</Typography>
              <Typography>{clientGroup.incorporationNumber}</Typography>
            </Grid>
            {
              clientGroup.businessIsMemberOfIiroc === undefined ? undefined : (
                <Grid item xs={6}>
                  <Typography fontWeight='bold'>{t('corporations:details.businessIsMemberOfIiroc')}</Typography>
                  <Typography>{clientGroup.businessIsMemberOfIiroc ? t('shared:yes') : t('shared:no')}</Typography>
                </Grid>
              )
            }
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.taxNumber')}</Typography>
              <Typography>{clientGroup.taxNumber}</Typography>
            </Grid>
          </Grid>
        </CorporationDetailBox>
      </Grid>
      <Grid item xs={12} lg={6}>
        <CorporationDetailBox
          title={t('corporations:details.fees')}
          section="FEES"
          clientGroup={clientGroup}
          buttonText={t('client:edit.buttons.save')}
          fields={feeFields}
          refetchQueries={[FETCH_CORPORATION]}
          modalButton={t('client:form.edit')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.feeGrid')}</Typography>
              <Typography>{clientGroup.applicableFeeTier?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.billingCycle')}</Typography>
              {clientGroup.applicableBillingSchedule?.frequency
                && <Typography>{t(`feeAndBilling:billingSchedule.frequencies.${clientGroup.applicableBillingSchedule?.frequency}`)}</Typography>
              }
            </Grid>
          </Grid>
        </CorporationDetailBox>
        <CorporationDetailBox
          title={t('corporations:details.contactInformation')}
          section="CONTACT"
          clientGroup={clientGroup}
          buttonText={t('client:edit.buttons.save')}
          fields={contactFields}
          refetchQueries={[FETCH_CORPORATION]}
          modalButton={t('client:form.edit')}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.email')}</Typography>
              <Typography>{clientGroup.primaryEmail}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight='bold'>{t('corporations:details.phone')}</Typography>
              <Phone phone={clientGroup.phone} />
            </Grid>
          </Grid>
        </CorporationDetailBox>
      </Grid>
    </Grid>
  );
};

export default CorporationDetails;
