/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery } from '@apollo/client';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Box, RefBox, Skeleton, Typography,
} from '../../1-primative';
import { PageObjectType } from '../../5-page';
import { getBackendLanguage, translateBackend } from '../../../assets/i18n/config';
import { Card, CardContent } from '../../2-component';
import { PAGE_HEIGHT, PrintContext } from '../../5-page/viewClientReport/components/printReport';

const FETCH_FILL_TEMPLATE = gql`
  query fetchFillTemplate($input: FetchFillTemplateInput!) {
    fetchFillTemplate(input: $input) {
      template
    }
  }
`;
export const HtmlTemplate = ({
  objectId, objectType, options = {}, i = 0, print = false,
}: { objectId: string; objectType: PageObjectType; options?: any; i?: number; print?: boolean }) => {
  const language = getBackendLanguage();
  const [newPage, setNewPage] = useState(false);
  const widgetRef = useRef<HTMLInputElement | null>(null);
  const { setOptions, options: printOptions } = useContext(PrintContext);
  const { data, loading } = useQuery(FETCH_FILL_TEMPLATE, {
    variables: {
      input: {
        encoded: true,
        language,
        objectId,
        objectType,
        template: options.template[language],
      },
    },
  });

  const filledHtml = data?.fetchFillTemplate?.template;

  useEffect(() => {
    if (!loading && widgetRef.current) {
      const widgetHeight = widgetRef.current?.offsetHeight;
      const copy = [...printOptions];

      copy[i] = {
        heightLeftOnLastPage: (i === 0 ? PAGE_HEIGHT - widgetHeight : (printOptions[i - 1]?.heightLeftOnLastPage || 0)) - widgetHeight,
        loading: false,
      };

      setOptions(copy);
      setNewPage((i === 0 ? PAGE_HEIGHT - widgetHeight : (printOptions[i - 1]?.heightLeftOnLastPage || 0)) < widgetHeight);
    }
  }, [i, loading]);

  const htmlRenderer = (component: JSX.Element) => {
    switch (options.showInCard) {
      case true:
        return (
          <Card variant={print ? 'outlined' : 'elevated'}>
            <CardContent sx={{ overflow: 'auto', '&:last-child': { paddingBottom: '16px' } }}>{component}</CardContent>
          </Card>
        );
      default:
        return <Box overflow={'auto'}>{component}</Box>;
    }
  };
  return (
    <RefBox ref={widgetRef} className='ql-snow' sx={{
      pageBreakBefore: newPage ? 'always' : undefined,
      mt: print ? '40px' : undefined,
    }}>
      {translateBackend(options.customTitle) !== '' && (
        <Box display={'flex'} pb={2}>
          <Typography variant='headingSmall'>{translateBackend(options.customTitle)}</Typography>
        </Box>
      )}
      {loading
        && htmlRenderer(
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Skeleton variant='rounded' height={'80px'} />
            <Skeleton variant='rounded' height={'40px'} />
            <Skeleton variant='rounded' height={'80px'} />
            <Skeleton variant='rounded' height={'40px'} />
          </Box>,
        )}
      {data
        && htmlRenderer(
          <Typography
            className='ql-editor'
            variant='none'
            dangerouslySetInnerHTML={{
              __html: atob(filledHtml),
            }}
          ></Typography>,
        )}
    </RefBox>
  );
};
