import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '../../1-primative';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
} from '../../2-component';
import { Goal } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { formatMoneyValue } from '../../../util';
import { useAccordionTokens } from '../../2-component/accordion/accordian.tokens';

const goalTitles = {
  mainTitle: {
    fontWeight: 600,
  },
  description: {
    fontWeight: 400,
  },
};

const GoalCard = ({ goal, onEdit, options }: { goal: Goal, onEdit?: (goal: Goal) => any, options: any }) => {
  const { t } = useTranslation(['client', 'workflowCompletions']);
  const tokens = useThemeTokens(useAccordionTokens());
  const { comp } = tokens;
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        '&:before': {
          display: 'none',
          border: 'none',
        },
        '&.MuiAccordion-root': {
          border: `1px solid ${comp.accordion.multipleBorderColor}`,
        },
        '&:last-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
        '&:last-of-type&:first-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
        '&:first-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header-2'
        sx={{
          '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' },
          '.MuiAccordionSummary-expandIconWrapper': { position: 'absolute', top: '10px', right: '15px' },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge' sx={goalTitles.mainTitle}>{goal.name}</Typography>
            {options.editGoal && options.goalWorkflow !== 'default' && (<EditIcon
              sx={{ marginRight: '35px', fontSize: '15px' }}
              onClick={() => {
                if (onEdit) {
                  onEdit(goal);
                }
              }}
            />)}
          </Box>
          <hr style={{ borderTop: `1px solid ${comp.accordion.multipleBorderColor}` }}></hr>
          {!expanded && (
            <Box sx={{ width: '100%' }} mt={2} display='block'>
              <Table>
                <TableBody>
                  <tr>
                    <td style={{ width: '15%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('workflowCompletions:reviewGoal.goalCard.type')}</Typography>
                    </td>
                    <td style={{ width: '85%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`goalType.${goal.type}`)}</Typography>
                    </td>
                  </tr>

                  {options.displayTimeHorizon && (<tr>
                    <td style={{ width: '15%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('workflowCompletions:reviewGoal.goalCard.time')}</Typography>
                    </td>
                    <td style={{ width: '85%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`timeHorizonOptions.${goal.timeHorizon}`)}</Typography>
                    </td>
                  </tr>)}

                  {options.displayGoalTargetAmount && (<tr>
                    <td style={{ width: '15%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('workflowCompletions:reviewGoal.goalCard.target')}</Typography>
                    </td>
                    <td style={{ width: '85%' }}>
                      {' '}
                      <Typography variant='bodyMedium' sx={goalTitles.description}>{formatMoneyValue(goal.targetAmountCents ?? 0)}</Typography>
                    </td>
                  </tr>)}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('goalTypes')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`goalType.${goal.type}`)}</Typography>
        </Box>

        {options.displayTimeHorizon && (<Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('timeHorizon')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`timeHorizonOptions.${goal.timeHorizon}`)}</Typography>
        </Box>)}

        {options.displayGoalTargetAmount && (<Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('targetAmount')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{formatMoneyValue(goal.targetAmountCents ?? 0)}</Typography>
        </Box>)}

        {options.displayRiskAmount && (<Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('workflowCompletions:goals.riskQuestion1')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`workflowCompletions:goals.riskQuestion1Responses.${goal.riskQuestion1}`)}</Typography>
        </Box>)}

        {options.displayGoalQuestion1 && (<Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('details.riskQuestion1')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`details.riskQuestion1Answer.${goal.riskQuestion1}`)}</Typography>
        </Box>)}

        {options.displayGoalQuestion2 && (<Box sx={{ mb: 2 }}>
          <Typography variant='bodyMedium' sx={goalTitles.mainTitle}>{t('details.riskQuestion2')}</Typography>
          <Typography variant='bodyMedium' sx={goalTitles.description}>{t(`details.riskQuestion2Answer.${goal.riskQuestion1}`)}</Typography>
        </Box>)}

      </AccordionDetails>
    </Accordion>
  );
};

export default GoalCard;
