import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SuspiciousTransfersTable from '../../components/tables/transfersTable/suspiciousTransfersTable';

const SuspiciousTransfers = () => {
  useNavigate();

  return (
    <Paper>
      <SuspiciousTransfersTable filter={{}} />
    </Paper>
  );
};

export default SuspiciousTransfers;
