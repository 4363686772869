import {
  useEffect, useState, useContext,
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UserContext } from 'providers/userContextProvider';
import { PendingTransfersVisual } from './pendingTransfers.visual';
import {
  FETCH_PENDING_TRANSFERS, FETCH_SCHEDULED_TRANSFERS, TRANSITION_SCHEDULED_TRANSFER, TRANSITION_TRANSFER,
  FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION,
} from './pendingTransfers.queries';
import { PageObjectType } from '../../../5-page';

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalId: objectId };
    case PageObjectType.ACCOUNT:
      return { accountId: objectId };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountId: objectId };
    default:
      return {};
  }
};

export const PendingTransfers = ({ objectId, objectType, options = {} }: { objectId: string, objectType: PageObjectType, options?: any }) => {
  const [pendingTransfers, setPendingTransfers] = useState<any[]>([]);
  const [custodianUpcomingTransactions, setCustodianUpcomingTransactions] = useState([]);
  const { custodianConnection } = useContext(UserContext);

  const includeUpComingTransactions = (custodianConnection?.enableFetchCustodianUpcomingTransactions || options.useCustodianUpcomingTransactions) && objectType === PageObjectType.ACCOUNT;

  const { data: initiatedTransfers, loading: initiatedLoading, refetch: initiatedRefetch } = useQuery(FETCH_PENDING_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'INITIATED',
        },
      },
    },
  });

  const { data: readyTransfers, loading: readyLoading, refetch: readyRefetch } = useQuery(FETCH_PENDING_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'READY',
        },
      },
    },
  });

  const { data: reviewTransfers, loading: reviewLoading, refetch: reviewRefetch } = useQuery(FETCH_PENDING_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'REVIEWING',
        },
      },
    },
  });

  const { data: requestedTransfers, loading: reqLoading, refetch: reqRefetch } = useQuery(FETCH_PENDING_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'REQUESTED',
        },
      },
    },
  });

  const { data: processingTransfers, loading: procLoading, refetch: procRefetch } = useQuery(FETCH_PENDING_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'PROCESSING',
        },
      },
    },
  });

  const { data: scheduledTransfers, loading: scheduledLoading, refetch: scheduledRefetch } = useQuery(FETCH_SCHEDULED_TRANSFERS, {
    variables: {
      input: {
        filter: {
          ...baseFilter(objectType, objectId),
          state: 'ACTIVE',
        },
      },
    },
  });

  const [fetchTransactions, { loading: upcomingTransactionsLoading, refetch: upcomingTransactions }] = useLazyQuery(FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION, {
    variables: {
      ...baseFilter(objectType, objectId),
    },
    onCompleted: (responseData) => {
      if (responseData?.fetchAccount?.account?.custodianUpcomingTransactions?.upcomingTransactions) {
        const { type, custodianUpcomingTransactions: { upcomingTransactions: transactions } } = responseData.fetchAccount.account;

        if (transactions && transactions.length > 0) {
          const upcomingTransactionsRef = transactions.map((transaction: any) => ({
            ...transaction,
            accountType: type,
          }));
          setCustodianUpcomingTransactions(upcomingTransactionsRef);
        }
      }
    },
  });

  useEffect(() => {
    if (options.useCustodianUpcomingTransactions && includeUpComingTransactions) {
      fetchTransactions();
    }
  }, [fetchTransactions, options.useCustodianUpcomingTransactions, includeUpComingTransactions, objectType, objectId]);

  const refetchAll = () => {
    initiatedRefetch();
    readyRefetch();
    reviewRefetch();
    reqRefetch();
    procRefetch();
    scheduledRefetch();
    if (options.useCustodianUpcomingTransactions && includeUpComingTransactions) upcomingTransactions();
  };

  const [transitionTransfer] = useMutation(TRANSITION_TRANSFER);
  const [transitionScheduledTransfer] = useMutation(TRANSITION_SCHEDULED_TRANSFER);

  const mapTransfers = (transfers: any) => (transfers?.fetchTransfers?.transfers || []);

  useEffect(() => {
    setPendingTransfers([
      ...mapTransfers(initiatedTransfers),
      ...mapTransfers(readyTransfers),
      ...mapTransfers(reviewTransfers),
      ...mapTransfers(requestedTransfers),
      ...mapTransfers(processingTransfers),
    ]);
  }, [initiatedTransfers, readyTransfers, requestedTransfers, processingTransfers, reviewTransfers]);

  const transition = (transferId: string, isScheduled: boolean, variables: any) => {
    if (isScheduled) {
      transitionScheduledTransfer({
        variables: {
          scheduledTransferId: transferId,
        },
        onCompleted: refetchAll,
      });
    } else {
      transitionTransfer({
        variables: {
          input: {
            transferId,
            ...variables,
          },
        },
        onCompleted: refetchAll,
      });
    }
  };

  return (
    <PendingTransfersVisual
      pendingTransfers={pendingTransfers || []}
      scheduledTransfers={scheduledTransfers?.fetchScheduledTransfers?.scheduledTransfers || []}
      loading={initiatedLoading || readyLoading || reqLoading || procLoading || scheduledLoading || reviewLoading || upcomingTransactionsLoading}
      transition={transition}
      options={options}
      refetchAll={refetchAll}
      upcomingTransactions={custodianUpcomingTransactions || []}
    />
  );
};
