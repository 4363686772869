import { gql } from '@apollo/client';

export const FETCH_PENDING_TRANSFERS = gql`
  query fetchTransfers($input: FetchTransfersInput!) {
    fetchTransfers(input: $input) {
      transfers {
        id
        amountCents
        subAccount {
          id
          account {
            id
            type
          }
          goal {
            name
          }
        }
        bankAccount {
          name
          institutionNumber
          bankAccountNumber
          transitNumber
        }
        source
        state
        type
        createdAt
        stateChanges
      }
      totalCount
    }
  }
`;

export const FETCH_SCHEDULED_TRANSFERS = gql`
  query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
    fetchScheduledTransfers(input: $input) {
      scheduledTransfers {
        id
        amountCents
        subAccount {
          id
          account {
            id
            type
          }
          goal {
            name
          }
        }
        bankAccount {
          name
          institutionNumber
          bankAccountNumber
          transitNumber
        }
        type
        frequency
        state
        scheduledDate
      }
      totalCount
    }
  }
`;

export const TRANSITION_TRANSFER = gql`
  mutation transitionTransfer($input: TransitionTransferInput!) {
    transitionTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const TRANSITION_SCHEDULED_TRANSFER = gql`
  mutation cancelScheduledTransfer($scheduledTransferId: ObjectID!) {
    cancelScheduledTransfer(scheduledTransferId: $scheduledTransferId) {
      scheduledTransfer {
        id
      }
    }
  }
`;

export const FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION = gql`
  query fetchAccount($accountId: ObjectID!){
    fetchAccount(accountId: $accountId){
      account{
        type
        custodianUpcomingTransactions {
          totalCount: totalTransfersCount,
          upcomingTransactions {
            amountCents,
            type
            frequency
            amountCents
            createdAt
            scheduledDate
            state
            source
            bankAccount {
              institutionNumber
              bankAccountNumber
              transitNumber
            }
            financialProduct {
              cusip
            }
          }
        }
      }
    }
  }
`;
