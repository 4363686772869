import {
  IconButton, SxProps, TableCell, TableRow, TableRowProps, Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { FC, useState } from 'react';

export interface ExpandableRow {
  id: string,
  name: string,
  level: number,
  expandedRows?: ExpandableRow[],
}

interface Props<T extends ExpandableRow> {
  row: T,
  DataComponent: FC<{ row: T }>,
  textColumnStyles?: SxProps,
  tableCellStyles?: SxProps,
  onRowClick?: (row: T) => void,
}

const ExpandableTableRow = <T extends ExpandableRow>({
  row, DataComponent, textColumnStyles, tableCellStyles = {}, onRowClick, ...tableRowProps
}: Props<T> & TableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onExpandClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <TableRow
        onClick={() => {
          if (onRowClick) onRowClick(row);
        }}
        {...tableRowProps}
      >
        <TableCell sx={{
          ...tableCellStyles, display: 'flex', alignItems: 'center', pl: `${row.level * 20}px`,
        }}>
          {row.expandedRows && row.expandedRows.length > 0 ? (
            <IconButton onClick={onExpandClick} data-testid='expand-icon'>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <IconButton disabled><HorizontalRuleIcon /></IconButton>
          )}
          <Typography sx={textColumnStyles}>{row.name}</Typography>
        </TableCell>
        <DataComponent row={row} />
      </TableRow>
      {isExpanded && row.expandedRows && row.expandedRows.length > 0 && (
        row.expandedRows.map((child) => (
          <ExpandableTableRow
            key={child.id}
            row={child as T}
            DataComponent={DataComponent}
            textColumnStyles={textColumnStyles}
            tableCellStyles={tableCellStyles}
            onRowClick={onRowClick}
            {...tableRowProps}
          />
        ))
      )}
    </>
  );
};

export default ExpandableTableRow;
