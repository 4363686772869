import { TableCell, TableRowProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import i18n from '../../../assets/i18n/config';
import { AccountBilling, GroupedBilling, UserBilling } from '../../../interfaces/groupedBilling';
import { SubAccountBilling } from '../../../interfaces/subAccountBilling';
import ExpandableTableRow from '../../../components/fields/expandableTableRow';
import { Date } from '../../../components/misc/date/date';
import { formatMoneyValue } from '../../../util';
import { stickyColumn, textColumnStyles } from '../../../util/styles';
import { getAccountName, getSubAccountName } from '../../../interfaces';

type ObjectType = 'USER' | 'ACCOUNT' | 'SUB_ACCOUNT' | 'CLIENT_GROUP';
export interface FeeReportDataRow {
  id: string,
  objectId: string,
  objectType: ObjectType,
  level: number,
  name: string,
  title: string,
  billingCycleId: string,
  expandedRows?: FeeReportDataRow[],
  organization?: string,
  billingSchedule?: string,
  billingDate?: string,
  feeGrid?: string,
  from?: string,
  to?: string,
  billableAumOnLastDay: number,
  appliedReductionsOnLastDay?: number,
  acuredFees: number,
  salesTax: number,
  projectedFeeAndTax: number,
  feePaymentAccount: string,
  cashAvailable: number,
  totalFeesAndTax?: number,
}

const mapSubAccountBilling = (subAccountBilling: SubAccountBilling, level: number, title?: string, organization?: string): FeeReportDataRow => ({
  id: subAccountBilling.id ?? '',
  objectId: subAccountBilling.subAccount?.id ?? '',
  objectType: 'SUB_ACCOUNT',
  level,
  name: getSubAccountName(subAccountBilling.subAccount),
  title: `${title ? `${title} - ` : ''}${getSubAccountName(subAccountBilling.subAccount)}`,
  billingCycleId: subAccountBilling.billingCycle?.id ?? '',
  billingSchedule: subAccountBilling.billingSchedule?.frequency ? i18n.t(`feeAndBilling:billingSchedule.frequencies.${subAccountBilling.billingSchedule?.frequency}`) : '',
  billingDate: subAccountBilling.billingDate,
  feeGrid: subAccountBilling.feeTier?.name,
  from: subAccountBilling.startDate,
  to: subAccountBilling.endDate,
  billableAumOnLastDay: subAccountBilling.marketValueCentsOnLastDay ?? 0,
  appliedReductionsOnLastDay: ((subAccountBilling.marketValueCentsOnLastDay ?? 0) - (subAccountBilling.chargeableMarketValueCentsOnLastDay ?? 0)) ?? 0,
  acuredFees: subAccountBilling.adjustedFeeCents ?? subAccountBilling.feeCents ?? 0,
  salesTax: subAccountBilling.adjustedSalesTaxCents ?? subAccountBilling.salesTaxCents ?? 0,
  projectedFeeAndTax: subAccountBilling.projectedFeeAndTaxCents ?? 0,
  feePaymentAccount: subAccountBilling.feePaymentAccount ? getAccountName(subAccountBilling.feePaymentAccount) : getAccountName(subAccountBilling.subAccount?.account),
  cashAvailable: (
    subAccountBilling.feePaymentAccount?.statistics?.moneyAvailableCents
    ?? subAccountBilling.subAccount?.statistics?.moneyAvailableCents ?? 0
  ),
  organization,
});

const mapExpendedRows = (object: Partial<UserBilling & AccountBilling & GroupedBilling>, level: number, title?: string, organization?: string): FeeReportDataRow => {
  let id = '';
  let name = '';
  const newTitle = `${title ? `${title} - ` : ''}`;
  let subRows: FeeReportDataRow[] = [];
  let objectId = '';
  let objectType: ObjectType = 'SUB_ACCOUNT';
  if (object.account) {
    id = object.account.id ?? '';
    name = object.account.type ?? '';
    subRows = object.subAccountBillings?.map((s) => mapSubAccountBilling(s, level + 1, `${newTitle}${name}`, organization)) ?? [];
    objectId = id;
    objectType = 'ACCOUNT';
  } else if (object.user) {
    id = object.user.id ?? '';
    name = `${object.user?.firstName} ${object.user?.lastName}`;
    if ((!object.user?.firstName && !object.user?.lastName) && object.user?.entityName) {
      name = object.user?.entityName; // display as corporate name
    }
    subRows = object.accounts?.map((s) => mapExpendedRows(s, level + 1, `${newTitle}${name}`, organization)) ?? [];
    objectId = id;
    objectType = 'USER';
  } else if (object.clientGroup) {
    subRows = (object.accounts ?? object.users)?.map((s) => mapExpendedRows(s, level + 1, `${newTitle}${name}`, organization)) ?? [];
    id = object.clientGroup.id ?? '';
    name = object.clientGroup.name ?? '';
    objectId = id;
    objectType = 'CLIENT_GROUP';
  }
  subRows.sort((a, b) => a.acuredFees - b.acuredFees);
  let billableAumOnLastDay = 0;
  let acuredFees = 0;
  let salesTax = 0;
  let projectedFeeAndTax = 0;
  let cashAvailable = 0;
  let appliedReductionsOnLastDay = 0;
  subRows.forEach((s) => {
    billableAumOnLastDay += s.billableAumOnLastDay;
    appliedReductionsOnLastDay += s?.appliedReductionsOnLastDay ?? 0;
    acuredFees += s.acuredFees;
    salesTax += s.salesTax;
    cashAvailable += s.cashAvailable;
    projectedFeeAndTax += s.projectedFeeAndTax;
  });
  const row: FeeReportDataRow = {
    id,
    level,
    name,
    title: `${newTitle}${name}`,
    expandedRows: subRows,
    objectId,
    objectType,
    billableAumOnLastDay,
    appliedReductionsOnLastDay,
    acuredFees,
    salesTax,
    projectedFeeAndTax,
    cashAvailable,
    billingSchedule: subRows[0].billingSchedule,
    billingDate: subRows[0].billingDate,
    feeGrid: subRows[0].feeGrid,
    from: subRows[0].from,
    to: subRows[0].to,
    billingCycleId: subRows[0].billingCycleId,
    organization,
    feePaymentAccount: subRows[0].feePaymentAccount,
    totalFeesAndTax: salesTax + acuredFees,
  };
  return row;
};

const mapGroupedBilling = (groupedBilling: GroupedBilling): FeeReportDataRow => {
  const organization = groupedBilling.clientGroup?.organization?.name ?? groupedBilling.user?.organization?.name;
  const row = mapExpendedRows(groupedBilling, 0, '', organization);
  return row;
};

const FeeReportColumns = ({ row }: { row: FeeReportDataRow }) => (
  <>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography sx={textColumnStyles}>{row.organization}</Typography></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography sx={textColumnStyles}>{row.billingSchedule}</Typography></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Date variant='subtitle2' date={row.billingDate} /></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography sx={textColumnStyles}>{row.feeGrid}</Typography></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Date variant='subtitle2' date={row.from} /></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }}><Date variant='subtitle2' date={dayjs(row.to).subtract(1, 'day')} /></TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(row.billableAumOnLastDay)}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue((row.appliedReductionsOnLastDay))}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(row.acuredFees)}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(row.salesTax)}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(row.salesTax + row.acuredFees)}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(row.projectedFeeAndTax)}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{row.feePaymentAccount}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
      <Typography color={row.cashAvailable < (row.acuredFees + row.salesTax) ? 'error' : ''} fontSize="small">
        {formatMoneyValue(row.cashAvailable)}
      </Typography>
    </TableCell>
  </>
);

export const FeeReportRow = ({ groupedBilling, onSelectFeeRow, ...tableRowProps }: {
  groupedBilling: GroupedBilling,
  onSelectFeeRow: (row: FeeReportDataRow) => void,
} & TableRowProps) => {
  const row = mapGroupedBilling(groupedBilling);
  return (
    <ExpandableTableRow
      row={row}
      DataComponent={FeeReportColumns}
      textColumnStyles={{ ...textColumnStyles, maxWidth: '250px' }}
      tableCellStyles={stickyColumn}
      onRowClick={onSelectFeeRow}
      {...tableRowProps}
    />
  );
};
