/* eslint-disable no-param-reassign */
import { translateBackend } from 'assets/i18n/config';
import {
  Box,
  IconButton,
  useTokens,
} from 'ovComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '../../1-primative/typography/typography.stories';
import { DroppableFileInput, FileTypes } from '../../2-component/droppableFileInput/droppableFileInput';
import { InlineImage } from '../../../pages/client/components/documents/inlineImage';
import { base64ToFile } from '../workflowCompletion/subSteps/utils';
import { InlineImageLocal } from '../../../pages/client/components/documents/inlineImageLocal';

interface DoUploadProps {
  objectId?: string,
  userId?: string,
  subStep: any,
  fetchFileUploadUrl: any,
  createFileDocument: any,
  key?: any,
}

interface DeleteFileProps {
  subStep: any,
  deleteFileDocument: any,
  key?: any,
}

export const doUpload = async ({
  objectId, userId, subStep, fetchFileUploadUrl, createFileDocument, key,
}: DoUploadProps): Promise<any> => {
  const { name, base64String } = (subStep?.[key] || subStep.options.attachment).file;
  const uploadingFile = base64ToFile(base64String, name);
  const createFileInput: any = {
    objectType: 'ORGANIZATION',
    objectId,
    fileName: name,
    type: 'WORKFLOW',
  };

  /* (1) fetch the S3 upload URL from backend */
  const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId } } });
  const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
  if (!uploadUrl || queryResult?.error) {
    return;
  }
  /* (2) do the upload */
  try {
    const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
    if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
  } catch (e: any) {
    return;
  }

  /* (3) create the fileDocument within backend */
  createFileInput.name = uploadingFile.name;
  createFileInput.mediaType = uploadingFile.type;
  createFileInput.permissionType = 'PUBLIC';
  createFileInput.sourceType = 'WORKFLOW';

  try {
    await createFileDocument({
      variables: { input: createFileInput },
      onCompleted(data: any) {
        (subStep?.[key] || subStep.options.attachment).value = data.createFileDocument.fileDocument.id;
        delete (subStep?.[key] || subStep.options.attachment).file;
      },
    });
  } catch (error) {
    delete (subStep?.[key] || subStep.options.attachment).file;
  }
};

export const deleteFile = async ({ subStep, deleteFileDocument, key }: DeleteFileProps) => {
  await deleteFileDocument({
    variables: { fileDocumentId: (subStep?.[key] || subStep.options.attachment)?.docToDelete },
    onCompleted() {
      delete subStep.options?.attachment?.docToDelete;
    },
  });
};

export const Attachment = ({
  option, options, setOptions, i, type, thumbNail,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number, type?: string, thumbNail?: boolean,
}) => {
  const { sys } = useTokens({});

  const hasAttachment = option?.value?.value || option?.value?.file;
  const acceptedFiles: FileTypes[] = Object.values(FileTypes).filter((x) => ![FileTypes.PDF, FileTypes.CSV].includes(x));

  const resetFileImageAndSrc = async () => {
    const newOptions: any = [...options];
    newOptions[i].value = {
      ...option.value,
      file: undefined,
      value: undefined,
      docToDelete: option?.value?.value,
    };
    setOptions(newOptions);
  };

  const handleFileInputChange = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      const newOptions = [...options];
      newOptions[i].value = {
        ...option.value,
        file: { name: file.name, base64String },
      };
      setOptions(newOptions);
    };
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography sx={{ marginBottom: sys.spacing.md, color: sys.color.onSurfaceVariant }}>{translateBackend(option.label)}</Typography>
          {hasAttachment && (
            <IconButton onClick={resetFileImageAndSrc}>
              <DeleteIcon sx={{ color: sys.color.onSurface }} />
            </IconButton>
          )}
        </Box>
        {!hasAttachment && (
          <DroppableFileInput thumbNail={thumbNail} fileMaxSize={20} acceptedTypes={acceptedFiles} onFileChosen={(file) => handleFileInputChange(file)} />
        )}
        {option?.value?.file && (
          <InlineImageLocal file={option?.value?.file} style={{ objectFit: 'contain', height: thumbNail ? '90px' : '300px', borderRadius: '15px' }} />
        )}
        {option?.value?.value && (
          <InlineImage fileDocumentId={option?.value?.value} style={{ objectFit: 'contain', height: thumbNail ? '90px' : '300px', borderRadius: '15px' }} />
        )}
      </Box>
    </Box>
  );
};

export default Attachment;
