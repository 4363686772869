import {
  createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useNavigate,
} from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ClientReportTemplateBreadcrumb } from 'ovComponents/5-page/clientReportTemplate/clientReportTemplateBreadcrumb';
import AccountsReview from '../../pages/accountsReview';
import BillingSchedules from '../../pages/billingSchedules';
import BuildModels, { PortfolioModelBreadcrumb } from '../../pages/buildModels';
import ClientV2, { ClientBreadcrumb, EntityBreadcrumb } from '../../pages/client';
import Corporation from '../../pages/corporation';
import Corporations from '../../pages/corporations';
import DevSettings from '../../pages/devSettings';
import FeeGrids from '../../pages/feeGrids';
import HouseHold from '../../pages/household';
import OrgSettings from '../../pages/orgSettings';
import { useMenuSections } from './navMenu';
import Rebalance, { RebalanceBreadcrumb } from '../../pages/rebalance';
import RebalanceReportV1 from '../../pages/rebalanceReportV1';
import Rebalances from '../../pages/rebalances';
import BulkTraders from '../../pages/bulkTraders';
import BulkTrader, { BulkTraderBreadcrumb } from '../../pages/bulkTrader';
import BulkImports from '../../pages/bulkImports';
import BulkImport, { BulkImportBreadcrumb } from '../../pages/bulkImportFiles';
import BulkImportFileLineItems, { ImportFileBreadcrumb } from '../../pages/bulkImportFileLineItems';
import Reconciliation from '../../ovComponents/5-page/reconciliation/reconciliation';
import AnnualSuitabilityReview from '../../pages/reports/annualSuitabilityReview';
import BankAccountsToReview from '../../pages/bankAccountsToReview';
import ComplianceReview from '../../pages/reports/complianceReview';
import ElderlyClients from '../../pages/reports/elderlyClients';
import SuspiciousTransfers from '../../pages/reports/suspiciousTransfers';
import RevenueShareReport from '../../pages/revenueShareReport';
import Schedules from '../../pages/schedules';
import StatementsReview from '../../pages/statementsReview';
import TaxLossHarvesting from '../../pages/taxLossHarvesting';
import TaxLossHarvestingEvents, { TaxLossHarvestingBreadcrumb } from '../../pages/taxLossHarvestingEvents';
import Transactions from '../../pages/transactions';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import ErrorBoundary from './errorBoundary';
import GoalsDetail, { GoalBreadcrumb } from '../../pages/goalsDetails';
import AccountsDetail, { AccountBreadcrumb } from '../../pages/accountsDetail';
import SubAccountsDetail, { SubAccountBreadcrumb } from '../../pages/subAccountsDetail';
import IncurredFees from '../../pages/IncurredFees';
import InstitutionsToReview from '../../pages/InstitutionsToReview';
import FeeReport, { FeeReportBreadcrumb } from '../../pages/feeReport';
import DailyFeeDetails, { DailyFeeDetailsBreadcrumb } from '../../pages/dailyFeeDetails';
import PortfolioOptimizers from '../../pages/portfolioOptimizers';
import PortfolioOptimizer from '../../pages/portfolioOptimizer';
import filterHouseholdsWithAccess from '../../util/filterHouseholdsWithAccess';

import Layout from '../../ovComponents/4-module/layout/layout';
import {
  ClientReportTemplate, ConfigureWorkflowPage, PageConfiguration, ViewPage, ManageNewsAndInsight,
  AllNewsAndInsights, Households, ClientGroupBreadcrumb, Individuals, NonIndividuals, NewReport,
  ViewReport, Reports, CustomReportBreadcrumb, Dashboard, DashboardBreadcrumb, ModelPortfolios,
  EditDashboard, ViewClientReport, RebalanceReport, Workflows, ProductShelf, AssetClasses,
  BulkUpsertRequests, BulkUpsertItems,
} from '../../ovComponents/5-page';
import OrgDetails, { OrgDetailsBreadcrumb } from '../../pages/orgDetails';
import { RefinitivShowcase } from '../../pages/refinitivShowcase';
import EditFormTemplate from '../../ovComponents/5-page/documentManagement/editFormTemplate';
import { BulkUpsertItemsBreadcrumb } from '../../ovComponents/5-page/bulkUpsertRequests/components/bulkUpsertItemsBreadcrumb';

export const AppRouter = () => {
  const { accessiblePages } = usePermissions();
  const { userContext, activeEntity } = useContext(UserContext);

  const households = filterHouseholdsWithAccess((activeEntity?.households || []), activeEntity?.id);
  const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && households.length > 0;

  const routesArray = createRoutesFromElements(accesibleRoutes(accessiblePages, isHouseholdDefaultView));
  const router = createBrowserRouter(routesArray);

  return (
    <RouterProvider router={router} />
  );
};

const newsAndInsightsRoutes = (
  <Route>
    <Route path="newsAndInsights/:widgetId" element={<AllNewsAndInsights />} handle={{ breadcrumb: 'navMenu:menu.newsAndInsights' }} />
  </Route>
);

const goalAccountSubaccountRoutes = (
  <Route>
    <Route path="goal/:goalId" handle={{ breadcrumb: GoalBreadcrumb }}>
      <Route index element={<GoalsDetail />} />
      <Route path="subaccount/:subAccountId" element={<SubAccountsDetail />} handle={{ breadcrumb: SubAccountBreadcrumb }} />
    </Route>
    <Route path="account/:accountId" handle={{ breadcrumb: AccountBreadcrumb }}>
      <Route index element={<AccountsDetail />} />
      <Route path="subaccount/:subAccountId" element={<SubAccountsDetail />} handle={{ breadcrumb: SubAccountBreadcrumb }} />
    </Route>
  </Route>
);

const householdRoutes = (
  <Route>
    <Route path="household/:clientGroupId" handle={{ breadcrumb: ClientGroupBreadcrumb }}>
      <Route index element={<HouseHold />} />
      {goalAccountSubaccountRoutes}
      {newsAndInsightsRoutes}
    </Route>
  </Route>
);

const accesibleRoutes = (accessiblePages: string[], isHouseholdDefaultView?: boolean) => (
  <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />} >
    <Route index element={<FallbackRoute />} />

    {accessiblePages.includes('HOME') && (
      <Route path="dashboard/:dashboardId" element={<Dashboard />} handle={{ breadcrumb: DashboardBreadcrumb }} />
    )}
    {accessiblePages.includes('PRODUCT_SHELF') && (
      <Route path="productShelf" element={<ProductShelf />} handle={{ breadcrumb: 'navMenu:menu.productShelfPage' }} />
    )}
    {accessiblePages.includes('ASSET_CLASSES') && (
      <Route path="assetClasses" element={<AssetClasses />} handle={{ breadcrumb: 'navMenu:menu.assetClassesPage' }} />
    )}
    {accessiblePages.includes('SCHEDULES') && (
      <Route path="schedules" element={<Schedules />} handle={{ breadcrumb: 'navMenu:menu.tradingSchedules' }} />
    )}
    {(accessiblePages.includes('HOUSEHOLDS') || isHouseholdDefaultView) && (
      <Route path="households" handle={{ breadcrumb: 'navMenu:menu.households' }}>
        {accessiblePages.includes('HOUSEHOLDS') && <Route index element={<Households />} />}
        <Route path=":clientGroupId" handle={{ breadcrumb: ClientGroupBreadcrumb }}>
          <Route index element={<HouseHold />} />
          {(accessiblePages.includes('CLIENTS') || accessiblePages.includes('INDIVIDUAL_CLIENTS') || accessiblePages.includes('NON_INDIVIDUAL_CLIENTS')) && (
            <Route>
              <Route path=":clients/:userId" handle={{ breadcrumb: ClientBreadcrumb }}>
                <Route index element={<ClientV2 />} />
                {goalAccountSubaccountRoutes}
                {newsAndInsightsRoutes}
              </Route>
              {goalAccountSubaccountRoutes}
              {newsAndInsightsRoutes}
            </Route>
          )}
        </Route>
      </Route>
    )}
    {(accessiblePages.includes('INDIVIDUAL_CLIENTS') || accessiblePages.includes('CLIENTS')) && (
      <Route>
        <Route path="clients" handle={{ breadcrumb: 'navMenu:menu.individuals' }} >
          <Route index element={<Individuals />} />
          <Route path=":userId" handle={{ breadcrumb: ClientBreadcrumb }} >
            <Route index element={<ClientV2 />} />
            {goalAccountSubaccountRoutes}
            {householdRoutes}
            {newsAndInsightsRoutes}
          </Route>
        </Route>
      </Route>
    )}
    {accessiblePages.includes('NON_INDIVIDUAL_CLIENTS') && (
      <Route>
        <Route path="nonIndividualClients" handle={{ breadcrumb: 'navMenu:menu.nonIndividualClients' }} >
          <Route index element={<NonIndividuals />} />
          <Route path=":userId" handle={{ breadcrumb: EntityBreadcrumb }} >
            <Route index element={<ClientV2 />} />
            {goalAccountSubaccountRoutes}
            {newsAndInsightsRoutes}
          </Route>
        </Route>
      </Route>
    )}
    {accessiblePages.includes('CORPORATIONS') && (
      <Route path="corporations" handle={{ breadcrumb: 'navMenu:menu.corporations' }}>
        <Route index element={<Corporations />} />
        <Route path=":clientGroupId" handle={{ breadcrumb: ClientGroupBreadcrumb }}>
          <Route index element={<Corporation />} />
          {accessiblePages.includes('CLIENTS') && goalAccountSubaccountRoutes}
        </Route>
      </Route>
    )}
    {accessiblePages.includes('BUILD_MODELS') && (
      <Route>
        <Route path="buildModels" handle={{ breadcrumb: 'navMenu:menu.modelPortfolios' }}>
          <Route index element={<BuildModels />} />
          <Route path=":id" element={<BuildModels />} handle={{ breadcrumb: PortfolioModelBreadcrumb }} />
        </Route>
      </Route>
    )}
    {accessiblePages.includes('MODEL_PORTFOLIOS') && (
      <Route>
        <Route path="modelPortfolios" handle={{ breadcrumb: 'navMenu:menu.modelPortfoliosV2' }}>
          <Route index element={<ModelPortfolios />} />
          <Route path=":id" element={<BuildModels />} handle={{ breadcrumb: PortfolioModelBreadcrumb }} />
        </Route>
      </Route>
    )}
    {accessiblePages.includes('REBALANCING') && (
      <Route>
        <Route path="rebalanceReportv1" element={<RebalanceReportV1 />} handle={{ breadcrumb: 'navMenu:menu.rebalanceReportV1' }} />
        <Route path="rebalances" handle={{ breadcrumb: 'navMenu:menu.rebalancing' }}>
          <Route index element={<Rebalances />} />
          <Route path=":id" element={<Rebalance />} handle={{ breadcrumb: RebalanceBreadcrumb }} />
        </Route>
      </Route>
    )}
    {accessiblePages.includes('REBALANCE_REPORT') && (
      <Route path="rebalanceReport" element={<RebalanceReport />} handle={{ breadcrumb: 'navMenu:menu.rebalanceReport' }} />
    )}
    {accessiblePages.includes('PORTFOLIO_OPTIMIZER') && (
      <Route path='portfolioOptimizers' handle={{ breadcrumb: 'navMenu:menu.portfolioOptimizer' }}>
        <Route index element={<PortfolioOptimizers />} />
        <Route path=':id' element={<PortfolioOptimizer />} /> {/* todo breadcrumb, check once the UI is done ? */}
      </Route>
    )}
    {accessiblePages.includes('BULK_TRADER') && (
      <Route>
        <Route path="bulkTrader" handle={{ breadcrumb: 'navMenu:menu.bulkTrader' }}>
          <Route index element={<BulkTraders />} />
          <Route path=":id" element={<BulkTrader />} handle={{ breadcrumb: BulkTraderBreadcrumb }} />
        </Route>
      </Route>
    )}
    {accessiblePages.includes('BULK_IMPORT') && (
      <Route>
        <Route path="bulkImport" handle={{ breadcrumb: 'navMenu:menu.bulkImport' }}>
          <Route index element={<BulkImports />} />
          <Route path=":bulkImportId" handle={{ breadcrumb: BulkImportBreadcrumb }}>
            <Route index element={<BulkImport />} />
            <Route path="file/:importFileId" element={<BulkImportFileLineItems />} handle={{ breadcrumb: ImportFileBreadcrumb }} />
          </Route>
        </Route>
      </Route>
    )}
    {accessiblePages.includes('TAX_LOSS_HARVESTING') && (
      <Route>
        <Route path="taxLossHarvesting" handle={{ breadcrumb: 'navMenu:menu.taxLossHarvesting' }} >
          <Route index element={<TaxLossHarvesting />} />
          <Route path=":id" element={<TaxLossHarvestingEvents />} handle={{ breadcrumb: TaxLossHarvestingBreadcrumb }} />
        </Route>
      </Route>
    )}
    {accessiblePages.includes('ELDERLY_CLIENTS') && (
      <Route path="elderlyClients" element={<ElderlyClients />} handle={{ breadcrumb: 'navMenu:menu.elderlyClients' }} />
    )}
    {accessiblePages.includes('SUSPICIOUS_TRANSACTIONS') && (
      <Route path="transactionMonitoringTool" element={<SuspiciousTransfers />} handle={{ breadcrumb: 'navMenu:menu.transactionMonitoringTool' }} />
    )}
    {accessiblePages.includes('BANK_ACCOUNTS_TO_REVIEW') && (
      <Route path="bankAccountsToReview" element={<BankAccountsToReview />} handle={{ breadcrumb: 'navMenu:menu.bankAccountsToReview' }} />
    )}
    {accessiblePages.includes('INSTITUTIONS_TO_REVIEW') && (
      <Route path="institutionsToReview" element={<InstitutionsToReview />} handle={{ breadcrumb: 'navMenu:menu.institutionsToReview' }} />
    )}
    {accessiblePages.includes('ANNUAL_SUITABILITY_REVIEW') && (
      <Route path="annualSuitabilityReview" element={<AnnualSuitabilityReview />} handle={{ breadcrumb: 'navMenu:menu.annualSuitabilityReview' }} />
    )}
    {accessiblePages.includes('COMPLIANCE_REVIEW') && (
      <Route path="complianceReview" element={<ComplianceReview />} handle={{ breadcrumb: 'navMenu:menu.complianceReview' }} />
    )}
    {accessiblePages.includes('RECONCILIATION') && (
      <Route path="reconciliation" element={<Reconciliation />} handle={{ breadcrumb: 'navMenu:menu.reconciliation' }} />
    )}
    {accessiblePages.includes('STATEMENTS_REVIEW') && (
      <Route path="statementsReview" element={<StatementsReview />} handle={{ breadcrumb: 'navMenu:menu.statementsReview' }} />
    )}
    {accessiblePages.includes('TRANSACTIONS') && (
      <Route path="transactions" element={<Transactions />} handle={{ breadcrumb: 'navMenu:menu.transactions' }} />
    )}
    {accessiblePages.includes('ACCOUNTS_REVIEW') && (
      <Route path="accountsReview" element={<AccountsReview />} handle={{ breadcrumb: 'navMenu:menu.accountsReview' }} />
    )}
    {accessiblePages.includes('FEE_GRIDS') && (
      <Route path="feeGrids" element={<FeeGrids />} handle={{ breadcrumb: 'navMenu:menu.feeGrids' }} />
    )}
    {accessiblePages.includes('BILLING_SCHEDULES') && (
      <Route path="billingSchedules" element={<BillingSchedules />} handle={{ breadcrumb: 'navMenu:menu.billingSchedules' }} />
    )}
    {accessiblePages.includes('BILLING_MANAGEMENT') && (
      <Route>
        <Route path="billingManagement" handle={{ breadcrumb: 'navMenu:menu.incurredFees' }}>
          <Route index element={<IncurredFees />} />
          <Route path=":billingCycleId" handle={{ breadcrumb: FeeReportBreadcrumb }}>
            <Route index element={<FeeReport />} />
            <Route path="subAccount/:subAccountId" element={<DailyFeeDetails />} handle={{ breadcrumb: DailyFeeDetailsBreadcrumb }} />
          </Route>
        </Route>
      </Route>
    )}
    {accessiblePages.includes('ORGANIZATION_REVENUE_SHARE') && (
      <Route path="revenueShareReport" element={<RevenueShareReport />} handle={{ breadcrumb: 'navMenu:menu.revenueShareReport' }} />
    )}
    {accessiblePages.includes('ORGANIZATION_SETTINGS') && (
      <Route path="orgSettings" handle={{ breadcrumb: 'navMenu:menu.orgSettings' }}>
        <Route index element={<OrgSettings />} />
        <Route path="configureWorkflow/:id" handle={{ breadcrumb: 'navMenu:menu.configureWorkflow' }} element={<ConfigureWorkflowPage />} />
        <Route path="pageConfiguration/:id" handle={{ breadcrumb: 'navMenu:menu.pageConfiguration' }} element={<PageConfiguration />} />
        <Route path="clientReportTemplate/:id" handle={{ breadcrumb: ClientReportTemplateBreadcrumb }} element={<ClientReportTemplate />} />
        <Route path="orgDetails/:id" handle={{ breadcrumb: OrgDetailsBreadcrumb }} element={<OrgDetails />} />
        <Route path="editDashboard/:dashboardId" handle={{ breadcrumb: DashboardBreadcrumb }} element={<EditDashboard />} />
        <Route path="formTemplate/:id" handle={{ breadcrumb: 'navMenu:menu.formTemplateEditor' }} element={<EditFormTemplate />} />
      </Route>
    )}
    {accessiblePages.includes('NEWS_AND_INSIGHT') && (
      <Route path="newsAndInsights" handle={{ breadcrumb: 'navMenu:menu.newsAndInsights' }}>
        <Route index element={<ManageNewsAndInsight />} />
      </Route>
    )}

    {/* TODO: Temp path for testing */}
    <Route path="viewPage/:id/:objectId" element={<ViewPage />} />
    <Route path="viewClientReport/:clientReportTemplateId/:objectId" element={<ViewClientReport />} />

    {accessiblePages.includes('DEVELOPER_SETTINGS') && (
      <Route>
        <Route path="devSettings" element={<DevSettings />} handle={{ breadcrumb: 'navMenu:menu.devSettings' }} />
        <Route path="bulkUpsertRequests" handle={{ breadcrumb: 'navMenu:menu.bulkUpsertRequests' }}>
          <Route index element={<BulkUpsertRequests />} />
          <Route path=":bulkUpsertRequestId" element={<BulkUpsertItems />} handle={{ breadcrumb: BulkUpsertItemsBreadcrumb }}/>
        </Route>
      </Route>
    )}
    {accessiblePages.includes('REPORTS') && (
      <Route path="reports" handle={{ breadcrumb: 'navMenu:menu.reports' }} >
        <Route index element={<Reports />} />
        <Route path="new" element={<NewReport />} handle={{ breadcrumb: 'customReports:newReport' }} />
        <Route path=":id" element={<ViewReport />} handle={{ breadcrumb: CustomReportBreadcrumb }} />
      </Route>
    )}
    {accessiblePages.includes('WORKFLOWS') && (
      <Route path="workflows" element={<Workflows />} handle={{ breadcrumb: 'navMenu:menu.workflows' }} />
    )}

    <Route path="refinitivShowcase" element={<RefinitivShowcase />} />

    <Route path="*" element={<FallbackRoute />} />
  </Route>
);

/* Access to the non-accessible or non-existent URI */
const FallbackRoute = () => {
  const { accessiblePages } = usePermissions();
  const navigate = useNavigate();
  const pageIsAccessible = (key: string): boolean => accessiblePages.includes(key);

  /* Redirects the browser to the first accessible page */
  const allMenuItems = useMenuSections().map((sect) => sect.subSections).flat();
  const accessibleMenuItems = allMenuItems.filter((item) => pageIsAccessible(item.accessKey));
  const firstAccessiblePath = accessibleMenuItems[0]?.path;
  useEffect(() => {
    if (firstAccessiblePath) {
      navigate(firstAccessiblePath);
    }
  }, [navigate, firstAccessiblePath]);

  return (<></>);
};
