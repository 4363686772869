import {
  ChangeEvent,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { generateClientNameString } from 'util/index';
import {
  getAccountName,
  getGoalName,
  FinancialProduct,
  Theme,
  AffiliationTypes,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Grid,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
  MenuItem,
  SelectField,
  Switch,
} from 'ovComponents/2-component';
import { DraftFinancialProduct } from './interfaces';

export const FinancialProductSelectionForm = ({
  draftFinancialProduct,
  portfolioThemes,
  modelPortfolios,
  updateDraft,
  onPortfolioThemeChange,
  onClose,
  onSubmit,
}: {
  draftFinancialProduct?: DraftFinancialProduct
  portfolioThemes: Theme[],
  modelPortfolios: FinancialProduct[],
  updateDraft: (subAccountId: string, draft: DraftFinancialProduct) => void,
  onPortfolioThemeChange: (theme: Theme) => void,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const { t } = useTranslation(['accountTypes', 'client', 'workflowCompletions']);
  if (!draftFinancialProduct) return (<Typography variant='bodyLarge' sx={{ textAlign: 'center' }}>No content</Typography>);
  const { subAccount } = draftFinancialProduct;
  const { goal, account } = subAccount;
  const title = t('workflowCompletions:subAccounts.formTitle', {
    goalName: getGoalName(goal),
    accountName: getAccountName(account, false),
  });
  const isSubmissionDisabled = !(
    draftFinancialProduct.subAccount
    && draftFinancialProduct.financialProduct
  );

  const handlePortfolioThemeChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPortfolioTheme = portfolioThemes.find((item) => item.id === e.target.value);
    updateDraft(subAccount.id, { ...draftFinancialProduct, theme: newPortfolioTheme });
    if (newPortfolioTheme) onPortfolioThemeChange(newPortfolioTheme);
  };

  const handleModelPortfolioChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newModelPortfolio = modelPortfolios.find((item) => item.id === e.target.value);
    updateDraft(subAccount.id, { ...draftFinancialProduct, financialProduct: newModelPortfolio });
  };

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  const getSuitabilityScoreElement = (text: string, score: number): ReactNode => {
    if (text && score !== undefined) {
      return (
        <Grid item xs={12} display="flex" flexDirection="row">
          <Typography variant='labelLarge' sx={{ color: colors.neutral800 }}>
            {text}:&nbsp;
          </Typography>
          <Typography variant='labelLarge' weight='bold' sx={{ color: colors.neutral800 }}>
            {score}
          </Typography>
        </Grid>
      );
    }
    return <></>;
  };

  const suitabilityScoresElement = (
    <>
      {getSuitabilityScoreElement(
        t('workflowCompletions:goals.suitability.individualSuitabilityScore', { name: generateClientNameString(account.user, true) }),
        account.user.suitabilityScore ?? 0,
      )}
      {(account?.affiliations ?? [])
        .filter((affiliate) => affiliate.type === AffiliationTypes.Joint)
        .map((affiliate) => getSuitabilityScoreElement(
          t('workflowCompletions:goals.suitability.individualSuitabilityScore', { name: generateClientNameString(affiliate.user, true) }),
          affiliate.user.suitabilityScore ?? 0,
        ))
      }
      {getSuitabilityScoreElement(t('workflowCompletions:goals.suitability.goalSuitabilityScore'), goal.suitabilityScore ?? 0) }
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{title}</Typography>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        <Grid item container xs={12} spacing={1}>
          <Grid item>
            <Typography variant='headingXSmall' sx={{ color: colors.neutral800 }}>
              {t('workflowCompletions:subAccounts.suitability.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='bodyMedium' sx={{ color: colors.neutral600 }}>
              {t('workflowCompletions:subAccounts.suitability.info')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          {suitabilityScoresElement}
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.portfolioTheme')}
            value={draftFinancialProduct.theme?.id}
            onChange={handlePortfolioThemeChange}
            fullWidth
          >
            { portfolioThemes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>) }
          </SelectField>
        </Grid>
        {draftFinancialProduct.theme && <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.portfolioModel')}
            value={draftFinancialProduct.financialProduct?.id}
            onChange={handleModelPortfolioChange}
            fullWidth
            >
              { modelPortfolios.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>) }
          </SelectField>
        </Grid>
        }
        <Grid item xs={12}>
          <Switch
            label={t('workflowCompletions:subAccounts.requireIPS')}
            checked={!draftFinancialProduct.skipIPS}
            onChange={(onoff: boolean) => { updateDraft(subAccount.id, { ...draftFinancialProduct, skipIPS: onoff }); }}
            labelPosition='left'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Switch
            label={t('workflowCompletions:subAccounts.allowFractionalShares')}
            checked={draftFinancialProduct.isPartial}
            onChange={(onoff: boolean) => { updateDraft(subAccount.id, { ...draftFinancialProduct, isPartial: onoff }); }}
            labelPosition='left'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Switch
            label={t('workflowCompletions:subAccounts.allowClientDeposits')}
            checked={draftFinancialProduct.allowClientDeposits}
            onChange={(onoff: boolean) => { updateDraft(subAccount.id, { ...draftFinancialProduct, allowClientDeposits: onoff }); }}
            labelPosition='left'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} justifyContent='end' display='flex'>
          <Button
            type='submit'
            disabled={isSubmissionDisabled}
            color='primary'
            label={t('workflowCompletions:subAccounts.linkSubAccount')}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
