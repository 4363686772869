import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FETCH_HOUSEHOLD } from '../../../../pages/household';
import { usePermissions } from '../../../../providers/userContextProvider';
import { FETCH_GOALS } from '../../../layout/goals/goalsSummary';
import { StepProps } from '../wizard';
import { CreateGoalActionStepResult } from './createGoalActionStepResult';

const CREATE_GOAL = gql`
  mutation createGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      goal {
        id
        name
      }
    }
  }
`;

export function CreateGoalActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const forUserId = context.forObject === 'USER' ? context.forId : undefined;
  const { permissions } = usePermissions();
  const [fetchClientGroup, {
    called: fetchCalled, loading: fetchLoading, error: fetchError,
  }] = useLazyQuery(FETCH_CLIENT_GROUP_PRIMARY_USER);
  const [createGoal, {
    loading, called, data, error,
  }] = useMutation(CREATE_GOAL, {
    refetchQueries: [FETCH_GOALS(forUserId), FETCH_HOUSEHOLD(permissions)],
    awaitRefetchQueries: true,
  });

  /* Temporary. Once createGoal() cease to require userId, remove this */
  const getPrimaryUserOfClientGroup = async (clientGroupId: string) => {
    const query = await fetchClientGroup({ variables: { id: clientGroupId }, fetchPolicy: 'no-cache' });
    return Promise.resolve(query.data.fetchClientGroup.clientGroup.primaryUser.id);
  };

  const doCreateGoal = async () => {
    mutationEvent('started');
    createGoal({
      variables: {
        input: {
          ...(context.forObject === 'USER' && {
            userId: context.forId,
          }),
          ...(context.forObject === 'CLIENT_GROUP' && {
            clientGroupId: context.forId,
            userId: await getPrimaryUserOfClientGroup(context.forId),
          }),
          type: state.type,
          name: state.name,
          timeHorizon: state.timeHorizon,
          targetAmountCents: state.targetAmountCents,
          riskQuestion1: state.riskQuestion1,
          ...(state.applyPortfolio && {
            financialProductId: state.portfolioFinancialProductId,
          }),
          ...(state.tradingProcess && { tradingProcess: state.tradingProcess }),
        },
      },
      onCompleted: () => {
        mutationEvent('succeeded');
        window.location.reload();
      },
      onError: () => mutationEvent('failed'),
    });
  };
  if (!fetchCalled && !called && state.type) {
    doCreateGoal();
  }

  return (
    <CreateGoalActionStepResult
      state={state}
      context={context}
      continueTo={continueTo}
      loading={fetchLoading || loading}
      succeeded={!!data} newGoalId={data?.createGoal.goal.id}
      error={fetchError ?? error}
      newGoalName={data?.createGoal.goal.name}
    />
  );
}

const FETCH_CLIENT_GROUP_PRIMARY_USER = gql`
  query fetchClientGroupPrimaryUser($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        primaryUser { id }
      }
    }
  }
`;
