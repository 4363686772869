import {
  Table, TableRow, TableCell, TableBody,
} from '@mui/material';
import theme from '../../theme';
import { colors } from '../../theme/colors';

interface Stat {
  key: string,
  value: string,
}

const StatsTable = ({ stats }: {
  stats: Stat[]
}) => (
    <Table aria-label="table">
      <TableBody>
        {stats.map((stat: any, index) => (
          <TableRow key={index} sx={{
            height: '40px',
            '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.action.hover,
            },
          }}>
            <TableCell sx={{ border: '0', color: colors.gray500 }}>{stat.key}</TableCell>
            <TableCell sx={{
              border: '0', fontSize: '16px', fontWeight: 600, textAlign: 'right',
            }}>{stat.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
);

export default StatsTable;
