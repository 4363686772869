import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { generateClientNameString } from 'util/index';
import {
  Account,
  ClientGroup,
  DraftGoal,
  FinancialProduct,
  GoalRiskLevels,
  GoalTimeHorizons,
  Theme,
  User,
  getAccountName,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Grid,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import {
  Autocomplete,
  Button,
  Form,
  MenuItem,
  Radio,
  RadioGroup,
  SelectField,
  Switch,
  TextField,
} from 'ovComponents/2-component';
import { AmountField } from 'ovComponents/3-pattern';
import { GoalScope } from './interfaces';

export const NewGoalForm = ({
  userData,
  newGoalData,
  goalSuitabilityScore,
  portfolioThemes,
  modelPortfolios,
  showPortfolioSection,
  showAccountLinkingSection,
  updateNewGoalData,
  fetchAccountsFn,
  onPortfolioThemeChange,
  onClose,
  onSubmit,
}: {
  userData?: User,
  newGoalData: DraftGoal,
  goalSuitabilityScore: number,
  portfolioThemes: Theme[],
  modelPortfolios: FinancialProduct[],
  showPortfolioSection: boolean,
  showAccountLinkingSection: boolean,
  updateNewGoalData: (value: DraftGoal) => void,
  fetchAccountsFn?: (goalScope: GoalScope, objectId: string) => Promise<Account[]>,
  onPortfolioThemeChange: (theme: Theme) => void,
  onClose: () => void,
  onSubmit: (draftGoal: DraftGoal) => void,
}) => {
  const { t } = useTranslation(['accountTypes', 'client', 'workflowCompletions']);
  const households: ClientGroup[] = useMemo(() => userData?.households ?? [], [userData]);
  const titleFragment1 = t('workflowCompletions:goals.newGoalPrefix');
  const titleFragment2 = newGoalData?.type ? ` - ${t(`client:goalType.${newGoalData.type}`)}` : '';
  const title = `${titleFragment1}${titleFragment2}`;
  const riskQuestion1Options = [GoalRiskLevels.LEVEL_1, GoalRiskLevels.LEVEL_3, GoalRiskLevels.LEVEL_5];
  const timeHorizonOptions = Object.values(GoalTimeHorizons).filter((item) => item !== GoalTimeHorizons.VERY_LONG_TERM);
  const isSubmissionDisabled = !(
    newGoalData.type
    && newGoalData.timeHorizon
    && newGoalData.targetAmountCents
    && newGoalData.riskQuestion1
  );

  const [showPortfolioSelector, setShowPortfolioSelector] = useState<boolean>(false);
  const [showHouseholdSelector, setShowHouseholdSelector] = useState<boolean>(false);
  const [showGoalScopeSelector, setShowGoalScopeSelector] = useState<boolean>(false);
  const [portfolioTheme, setPortfolioTheme] = useState<Theme | undefined>();
  const [goalScope, setGoalScope] = useState<GoalScope>(GoalScope.INDIVIDUAL);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const handleSubmit = () => {
    // If user finalizes as an Individual goal, remove household context from final draft
    if (goalScope === GoalScope.INDIVIDUAL) {
      const { householdClientGroup, ...draftWithoutHousehold } = newGoalData;
      updateNewGoalData(draftWithoutHousehold);
      // Explicitly calling onSubmit with `draftWithoutHousehold` because of latency with state updates
      onSubmit(draftWithoutHousehold);
    } else {
      onSubmit(newGoalData);
    }
    onClose();
  };

  const handleGoalScopeChange = (e:ChangeEvent<HTMLInputElement>) => {
    const newGoalScope = e.target.value as GoalScope;
    setGoalScope(newGoalScope);
    if (households?.length === 1) updateNewGoalData({ ...newGoalData, householdClientGroup: households[0] });
  };

  const handleHouseholdChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newHousehold = households.find((item) => item.id === e.target.value);
    if (newHousehold) updateNewGoalData({ ...newGoalData, householdClientGroup: newHousehold });
  };

  const handlePortfolioThemeChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPortfolioTheme = portfolioThemes.find((item) => item.id === e.target.value);
    setPortfolioTheme(newPortfolioTheme);
    if (newPortfolioTheme) onPortfolioThemeChange(newPortfolioTheme);
  };

  const handleModelPortfolioChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newModelPortfolio = modelPortfolios.find((item) => item.id === e.target.value);
    updateNewGoalData({ ...newGoalData, financialProduct: newModelPortfolio });
  };

  const clearAccountsLinked = () => {
    updateNewGoalData({ ...newGoalData, accountsLinked: [] });
  };

  const fetchAccounts = useCallback(async () => {
    clearAccountsLinked();
    if (fetchAccountsFn) {
      if (goalScope === GoalScope.HOUSEHOLD && !newGoalData?.householdClientGroup?.id) {
        setAccounts([]);
      } else {
        const accountsData = await fetchAccountsFn(
          goalScope,
          (goalScope === GoalScope.HOUSEHOLD ? newGoalData?.householdClientGroup?.id : userData?.id) ?? '',
        );
        setAccounts(accountsData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalScope, newGoalData?.householdClientGroup, userData?.id]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts, goalScope, newGoalData?.householdClientGroup]);

  useEffect(() => {
    setShowHouseholdSelector(goalScope === GoalScope.HOUSEHOLD && households.length > 1);
    setShowGoalScopeSelector(households.length > 0);
  }, [goalScope, households]);

  const goalScopeSelector = (
    <Grid item xs={12}>
      <Typography variant='monospacedLarge' sx={{ color: colors.neutral600 }}>{t('workflowCompletions:goals.scopeLabel')}</Typography>
      <RadioGroup
        value={goalScope}
        onChange={handleGoalScopeChange}
      >
        {
          Object.values(GoalScope).map((item, index) => (
            <Radio key={index} value={item} label={t(`workflowCompletions:goals.scopes.${item}`)} />
          ))
        }
      </RadioGroup>
    </Grid>
  );

  function getSuitabilityScoreElement(text: string, score: number): ReactNode {
    if (text && score !== undefined) {
      return (
        <Box display="flex" flexDirection="row">
          <Typography variant='bodyMedium' sx={{ color: colors.neutral800 }}>
            {text}:&nbsp;
          </Typography>
          <Typography variant='bodyMedium' weight='bold' sx={{ color: colors.neutral800 }}>
            {score}
          </Typography>
        </Box>
      );
    }
    return <></>;
  }

  const getUserSuitabilityScoreElement = (user?: User): ReactNode => (getSuitabilityScoreElement(
    t('workflowCompletions:goals.suitability.individualSuitabilityScore', { name: generateClientNameString(user, true) }),
    user?.suitabilityScore ?? 0,
  ));

  const portfolioSection = (
    <>
      <Grid item xs={12}>
        <Switch
          label={t('workflowCompletions:goals.applyPortfolioAtGoal')}
          checked={showPortfolioSelector}
          onChange={(onoff: boolean) => setShowPortfolioSelector(onoff)}
          labelPosition='left'
          fullWidth
        />
      </Grid>
      { showPortfolioSelector
        && <>
        <Grid item xs={12}>
          <Typography variant='bodyLarge' sx={{ mb: 1, color: colors.neutral800 }}>
            {t('workflowCompletions:goals.suitability.info')}
          </Typography>
          {
            goalScope === GoalScope.INDIVIDUAL
              ? getUserSuitabilityScoreElement(userData)
              : newGoalData?.householdClientGroup?.relationships?.map((relationship) => getUserSuitabilityScoreElement(relationship.user))
          }
          { getSuitabilityScoreElement(t('workflowCompletions:goals.suitability.goalSuitabilityScore'), goalSuitabilityScore) }
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.portfolioTheme')}
            value={portfolioTheme}
            onChange={handlePortfolioThemeChange}
            fullWidth
          >
            { portfolioThemes.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>) }
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.portfolioModel')}
            onChange={handleModelPortfolioChange}
            fullWidth>
            { modelPortfolios.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>) }
          </SelectField>
        </Grid>
      </>}
    </>
  );
  const accountLinkingSection = (
    <Grid item xs={12}>
      <Typography variant='headingXSmall' sx={{ mb: 1, color: colors.neutral800 }}>
        {t('workflowCompletions:goals.accountLinking.title')}
      </Typography>
      <Typography variant='bodyMedium' sx={{ mb: 1, color: colors.neutral600 }}>
        {t('workflowCompletions:goals.accountLinking.info')}
      </Typography>
      <Autocomplete
        label={t('workflowCompletions:goals.accountLinking.inputLabel')}
        options={accounts}
        value={newGoalData?.accountsLinked ?? []}
        multiple
        fullWidth
        getOptionLabel={(option: any) => getAccountName(option)}
        onChange={(e: React.SyntheticEvent, value: any) => updateNewGoalData({ ...newGoalData, accountsLinked: value }) }
      />
    </Grid>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{title}</Typography>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        { showGoalScopeSelector && goalScopeSelector }
        { showHouseholdSelector && <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.selectHousehold')}
            value={newGoalData?.householdClientGroup?.id}
            onChange={handleHouseholdChange}
            fullWidth
          >
            {
              households.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        }
        <Grid item xs={12}>
          <TextField
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateNewGoalData({ ...newGoalData, name: e.target.value })}
            label={t('client:goalName')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={t('client:timeHorizon')}
            value={newGoalData.timeHorizon}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateNewGoalData({ ...newGoalData, timeHorizon: e.target.value as GoalTimeHorizons })}
            fullWidth
          >
            {
              timeHorizonOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t(`client:timeHorizonOptions.${item}`)}
                </MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        <Grid item xs={4}>
          <AmountField
            label={t('client:targetAmount')}
            amount={newGoalData.targetAmountCents ? (newGoalData.targetAmountCents / 100).toString() : ''}
            setAmount={(amount: string) => (
              updateNewGoalData({ ...newGoalData, targetAmountCents: amount ? Math.round(parseFloat(amount) * 100) : 0 })
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={t('workflowCompletions:goals.riskQuestion1')}
            value={newGoalData.riskQuestion1}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => updateNewGoalData({ ...newGoalData, riskQuestion1: e.target.value as GoalRiskLevels })}
            fullWidth
          >
            {
              riskQuestion1Options.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t(`workflowCompletions:goals.riskQuestion1Responses.${item}`)}
                </MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        { showPortfolioSection && portfolioSection }
        <Grid item xs={12}>
          <Switch
            label={t('workflowCompletions:goals.taxOptimized')}
            onChange={(onoff: boolean) => updateNewGoalData({ ...newGoalData, taxOptimized: onoff })}
            infoTooltip={t('workflowCompletions:goals.taxOptimizedInfo')}
            labelPosition='left'
            fullWidth
          />
        </Grid>
        { showAccountLinkingSection && accountLinkingSection }
        <Box justifyContent='end' display='flex' width='100%'>
          <Box>
            <Button
              type='submit'
              disabled={isSubmissionDisabled}
              color='primary'
              sx={{ mt: 3 }}
              label={t('workflowCompletions:goals.addGoal')}
            />
          </Box>
        </Box>
      </Grid>
    </Form>
  );
};
