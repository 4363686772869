import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DailyFeeDetailsTable from './components/dailyFeeDetailsTable';

const DailyFeeDetails = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <DailyFeeDetailsTable />
    </Paper>
  );
};

export default DailyFeeDetails;
export { DailyFeeDetailsBreadcrumb } from './dailyFeeDetailsBreadcrumb';
