import { type ReactNode, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import type { Hexcode } from '../../0-tokens';
import { Box, Typography } from '../../1-primative';
import { Dialog, DialogContent, DialogTitle } from '../dialog/dialog';
import { IconButton } from '../iconButton/iconButton';

interface InfoItem {
  show: boolean,
  information: string | ReactNode,
  title: string,
}

interface InfoButtonProps {
  items: InfoItem[],
  color?: Hexcode,
  title: string,
  testId?: string,
}

const InfoButton = ({
  items, color, title, testId = 'info-button',
}: InfoButtonProps) => {
  const { sys } = useThemeTokens();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ color: color ?? sys.color.onSurfaceVariant, cursor: 'pointer' }} onClick={() => setOpen(true)} data-testid={testId}>
        <InfoOutlinedIcon fontSize='small' data-testid={`${testId}-icon`} />
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth data-testid={`${testId}-dialog`}>
        <DialogTitle data-testid={`${testId}-dialog-title`}>
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Typography variant='headingSmall' sx={{ mr: 0.5 }}>{title}</Typography>
            <IconButton onClick={() => setOpen(false)} aria-label="close" size='medium'><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>
        <DialogContent data-testid={`${testId}-dialog-content`}>
          {items.filter((item) => item.show).map((elem: InfoItem, index: number) => (
            <Box sx={{ mt: index > 0 ? 2 : 0 }} key={index} data-testid={`${testId}-dialog-content-item`}>
              <Typography variant='bodyLarge' sx={{ fontWeight: 500, color: sys.color.onSurface }}>{elem.title}</Typography>
              <Typography variant='bodyMedium' sx={{ mt: 1, fontWeight: 400, color: sys.color.onSurface }}>{elem.information}</Typography>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoButton;
