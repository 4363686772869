import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface BarChartTokens {
  barChart: {
    color?: string;
    tension?: number;
    showAxis?: boolean;
    radius?: number;
  },
}

export const useBarChartTokens = (): BarChartTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: BarChartTokens = {
    barChart: {
      color: sys.color.primary,
      showAxis: false,
      radius: 4,
    },
  };

  return comp;
};
