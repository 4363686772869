import { ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CurrentIntegrationType, NewIntegrationType } from '../../../interfaces';
import { Grid } from '../../../ovComponents/1-primative';
import { Switch } from '../../../ovComponents/2-component';
import { integrationConfig } from './integrationConfig';

interface IntegrationScopeProps {
  integration: NewIntegrationType | CurrentIntegrationType;
  setIntegrationScope: (scope: string[]) => void;
}

const IntegrationScope = ({ integration, setIntegrationScope }: IntegrationScopeProps) => {
  const { t } = useTranslation(['devSettings']);
  const scopeOptions = integrationConfig[integration?.type]?.scopeOptions;
  if (!scopeOptions || scopeOptions.length === 0) {
    return (<></>);
  }
  return (
    <ListItem>
      <Grid item container spacing={1}>
        {scopeOptions && scopeOptions.map((o, idx) => (
          <Grid item xs={6} key={idx}>
            <Switch
              label={t(`integrationsModal.scopeOptions.${integration.type}.${o}`)}
              checked={integration.scope?.includes(o) || false}
              onChange={(e: boolean) => {
                let updatedScope = integration.scope || [];
                if (updatedScope.includes(o) && !e) {
                  updatedScope = updatedScope.filter((s) => s !== o);
                } else if (!updatedScope.includes(o) && e) {
                  updatedScope.push(o);
                }
                setIntegrationScope(updatedScope);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </ListItem>
  );
};

export default IntegrationScope;
