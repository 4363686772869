import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  ListItem, MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Typography } from 'ovComponents';
import { CountryCodes } from '@onevesthq/ov-enums';
import { countryList, getCountryName } from '../../../ovComponents/resources';
import { BareLocalization } from '../../../interfaces';
import { resources as supportedLanguages } from '../../../assets/i18n/config';

const FETCH_COUNTRY_AND_CURRENCY_CODES = gql`
  query fetchCurrencyCodes {
    CurrencyCodes:__type(name: "CurrencyCodes") {
      enumValues { name }
    }
  }
`;

const LocalizationForm = (
  {
    localization,
    setLocalization,
    readonly = false,
  }: {
    localization: BareLocalization,
    setLocalization: Dispatch<SetStateAction<BareLocalization>>
    readonly?: boolean
  },
) => {
  const { t } = useTranslation('orgSettings');
  const { data: codes, loading: codesLoading } = useQuery(FETCH_COUNTRY_AND_CURRENCY_CODES);

  const handleLanguageCheckbox = (e: any) => {
    const { checked, value: lang } = e.target;

    if (checked) {
      // languages.push(lang); // add
      setLocalization((previous) => ({ ...previous, languages: [...previous.languages, lang] }));
    } else {
      // languages = languages.filter((l) => l !== lang); // remove
      setLocalization((previous) => ({ ...previous, languages: previous.languages.filter((l) => l !== lang) }));
    }
  };

  useEffect(() => {
    if (localization.languages.length === 0) {
      setLocalization({ ...localization, defaultLanguage: '' });
    }
    if (localization.languages.length === 1) {
      setLocalization({ ...localization, defaultLanguage: localization.languages[0] });
    }
    if (localization.languages.length > 1 && !localization.languages.includes(localization.defaultLanguage)) {
      setLocalization({ ...localization, defaultLanguage: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localization.languages]);

  const languageName = (lang: string) => t(`localizationSettings.language.${lang}`);

  return (
    <>
      <ListItem>
        <TextField
          fullWidth
          value={localization.name}
          label={t('localizationModal.name')}
          onChange={(e: any) => setLocalization({ ...localization, name: e.target.value })}
          helperText={t('localizationModal.nameHelper')}
          required
          disabled={readonly}
        />
      </ListItem>

      <ListItem>
        {/* Country Select */}
        <FormControl fullWidth>
          <InputLabel shrink variant="outlined">{t('localizationModal.countries')}</InputLabel>
          <Select multiple notched
            value={localization.countries}
            label={t('localizationModal.countries')}
            onChange={(e: any) => setLocalization({ ...localization, countries: e.target.value })}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={`${value} - ${getCountryName(value)}`} />
                ))}
              </Box>
            )}
            required
            disabled={readonly}
          >
            {countryList.map((country, index) => <MenuItem key={index} value={country.code}>
              <Checkbox checked={localization.countries.indexOf(country.code as CountryCodes) > -1} />
              {country.code} - {country.name}
            </MenuItem>)}
          </Select>
          <FormHelperText>{t('localizationModal.countriesHelper')}</FormHelperText>
        </FormControl>
      </ListItem>

      <ListItem>
        {/* Currency Select */}
        <TextField select
          fullWidth
          value={localization.defaultCurrency}
          label={t('localizationModal.defaultCurrency')}
          onChange={(e: any) => setLocalization({ ...localization, defaultCurrency: e.target.value })}
          helperText={t('localizationModal.defaultCurrencyHelper')}
          required
          disabled={codesLoading || readonly}
        >
          {codes?.CurrencyCodes.enumValues.map((enumValue: any, index: number) => <MenuItem key={index} value={enumValue.name}>
            {enumValue.name}
          </MenuItem>)}
        </TextField>
      </ListItem>

      <ListItem>
        {/* Languages Select */}
        <FormControl fullWidth sx={{ margin: 2 }}>
          <FormLabel sx={{ transform: 'translate(-65px, 0px) scale(0.75)' }}>{t('localizationModal.languages')}</FormLabel>
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth
                disabled={readonly}
              >
                {Object.keys(supportedLanguages).map((lang) => <FormControlLabel
                  key={lang}
                  label={languageName(lang)}
                  control={<Checkbox
                    value={lang}
                    checked={localization.languages.includes(lang)}
                    onChange={handleLanguageCheckbox}
                  />}
                />)
                }
              </FormControl>
            </Grid>
            {localization.languages.length > 1
              && <Grid item xs={6}>
                <TextField select
                  fullWidth
                  label={t('localizationModal.defaultLanguage')}
                  value={localization.defaultLanguage}
                  onChange={(e: any) => setLocalization({ ...localization, defaultLanguage: e.target.value })}
                  disabled={readonly}
                >
                  {localization.languages.map((lang: any) => <MenuItem key={lang} value={lang}>
                    {languageName(lang)}
                  </MenuItem>)}
                </TextField>
              </Grid>
            }
          </Grid>
        </FormControl>
      </ListItem >

      <ListItem>
        {/* Date Format and Examples */}
        <FormControl fullWidth>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                value={localization.dateFormat}
                label={t('localizationModal.dateFormat')}
                onChange={(e: any) => setLocalization({ ...localization, dateFormat: e.target.value })}
                required
                disabled={readonly}
              />
              <FormHelperText>
                {t('localizationModal.dateFormatHelper1')}
                <a href="https://day.js.org/docs/en/display/format">
                  {t('localizationModal.dateFormatHelper2')}
                </a>
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('localizationModal.datePreview')}:</Typography>
              {localization.dateFormat && (<>
                <Typography>
                  {dayjs().set('date', 1).format(localization.dateFormat)}&nbsp;
                  ({t('localizationModal.firstDay')})
                </Typography>
                <Typography>
                  {dayjs().format(localization.dateFormat)}&nbsp;
                  ({t('localizationModal.today')})
                </Typography>
                <Typography>
                  {dayjs().endOf('month').format(localization.dateFormat)}&nbsp;
                  ({t('localizationModal.lastDay')})
                </Typography>
              </>)}
            </Grid>
          </Grid>
        </FormControl>
      </ListItem >
    </>
  );
};

export default LocalizationForm;
