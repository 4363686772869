import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const MarketList = ({ options }: { options: any }) => {
  useRefinitivWidget('#market-list-widget', 'WMarketList', { rics: options.marketListRics });

  return (
    <Card>
      <div id='market-list-widget'></div>
    </Card>
  );
};
