import { useTranslation } from 'react-i18next';
import {
  Switch,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';

export const FilterIndividuals = ({
  completedUsersOnly, setCompletedUsersOnly,
}: {
  completedUsersOnly: boolean, setCompletedUsersOnly: (e: boolean) => void,
}) => {
  const { t } = useTranslation(['clients', 'shared']);

  return (
    <FilterModal filterExists={completedUsersOnly}>
      <Switch label={t('completedUsersOnly')} checked={completedUsersOnly} onChange={(checked: boolean) => setCompletedUsersOnly(checked)} />
    </FilterModal>
  );
};
