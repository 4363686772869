import {
  LocationOnOutlined, CalendarToday, MailOutline, AccountBalanceOutlined, Phone,
} from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LanguageIcon from '@mui/icons-material/Language';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { intersection, isEmpty } from 'lodash/fp';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { Box, Button } from '@mui/material';
import ClientDetailBoxAlert from 'pages/client/components/clientDetailBoxAlert';
import EditIcon from '@mui/icons-material/Edit';
import { Summary, Pill, Highlight } from './summary';
import { formatPhoneNumber, generateClientNameString, generateShortAddressString } from '../../../util';
import {
  SUITABILITY_REVIEW_VALIDITY_MONTHS, User, UserStates, WorkflowStates,
} from '../../../interfaces';
import { colors } from '../../../theme/colors';
import ConfirmationModal from '../../modals/confirmationModal';
import { usePermissions } from '../../../providers/userContextProvider';
import useCopyToClipboard from '../../../util/useCopyToClipboard';
import { ActiveWorkflows } from '../../../ovComponents/4-module/activeWorkflows/activeWorkflows';
import { useLocalization } from '../../../util/useLocalization';

const TRANSITION_USER = gql`
  mutation transitionUser($input: TransitionUserInput!) {
    transitionUser(input: $input) {
      user {
        id
        state
      }
    }
  }
`;

export const FETCH_WORKFLOWS = gql`
  query fetchWorkflows($input: FetchWorkflowsInput!) {
    fetchWorkflows(input: $input) {
      workflows {
        id
        name {
          en
        }
      }
      totalCount
    }
  }
`;

export const CREATE_WORKFLOW_COMPLETION = gql`
  mutation createWorkflowCompletion($input: CreateWorkflowCompletionInput!) {
    createWorkflowCompletion(input: $input) {
      workflowCompletion {
        id
      }
    }
  }
`;

export function ClientSummary({
  user, updateProfile, updateOrganization, onHighlightClicked, addClientToHousehold, refetch,
}: {
  user: User, updateProfile: () => void, updateOrganization: () => void, onHighlightClicked: (prop: any) => void, addClientToHousehold: () => void, refetch: () => void,
}) {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const [copy] = useCopyToClipboard();
  const [transitionUser] = useMutation(TRANSITION_USER);
  const [deactivationDialogOpen, setDeactivationDialogOpen] = useState(false);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const { localizedDate } = useLocalization();
  const skipWorkflow = !permissions.includes('read:workflow') || !permissions.includes('write:workflow_completion');

  const [activeWorkflowCompletionId, setActiveWorkflowCompletionId] = useState<any>(null);
  const { data: workflowsData } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: user.organization.id,
          state: WorkflowStates.ACTIVE,
        },
        pagination: {
          perPage: 50,
        },
      },
    },
    skip: skipWorkflow,
  });
  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      setActiveWorkflowCompletionId(data.createWorkflowCompletion.workflowCompletion.id);
    },
  });

  useEffect(() => {
    if (workflowsData) {
      setWorkflows(workflowsData.fetchWorkflows.workflows.map((workflow: any) => ({
        style: { display: 'flex', justifyContent: 'right' },
        prefixIcon: <PlayCircleOutlineIcon style={{ marginRight: '10px' }} />,
        label: `Start ${workflow.name.en}`,
        onClick: () => {
          createWorkflowCompletion({
            variables: {
              input: {
                objectId: user.id,
                objectType: 'USER',
                workflowId: workflow.id,
                organizationId: user.organization.id,
              },
            },
          });
        },
      })));
    }
  }, [workflowsData, createWorkflowCompletion, user.id, user.organization.id]);

  if (!user) return <></>;

  const isIndividual = user.type === 'INDIVIDUAL';

  const basicInfo = ['firstName', 'lastName', 'physicalAddress', 'dateOfBirth', 'primaryEmail'];
  const basicMissingInfo = intersection(user.incompleteFields, basicInfo);
  const showPills: Pill[] = [];
  const showHighlights: Highlight[] = [];

  showPills.push({
    label: t(`details.state.${user.state}`),
    leadingIcon: user.state === 'ACTIVE' ? 'checkmark' : 'problem',
    ...(user.state === UserStates.FROZEN || user.state === UserStates.INACTIVE) && { tooltip: t(`details.stateTooltip.${user.state}`) },
  });

  if (isEmpty(user.incompleteFields)) {
    showPills.push({
      label: t('completed'),
      leadingIcon: 'checkmark',
    });
  } else {
    showPills.push({
      label: t('notCompleted'),
      leadingIcon: 'problem',
      tooltip: <>
        {t('incompleteFields')}
        {user.incompleteFields?.map((message, index) => <li key={index}>{message}</li>)}
      </>,
    });
  }

  const incompleteFormAgreementsCount = user.allIncompleteFormAgreements?.length ?? 0;

  if (incompleteFormAgreementsCount) {
    showPills.push({
      label: t('details.unsignedDocuments', { count: incompleteFormAgreementsCount }),
      leadingIcon: 'problem',
      tooltip: t('details.unsignedDocumentsTooltip'),
    });
  } else {
    showPills.push({
      label: t('details.allDocumentSigned'),
      leadingIcon: 'checkmark',
    });
  }

  if (isIndividual) {
    showPills.push({
      label: `ID ${user.iDVerified ? t('idVerified') : t('idNotVerified')}`,
      leadingIcon: user.iDVerified ? 'checkmark' : 'problem',
      ...(!user.iDVerified && { tooltip: t('details.idVerificationNotCompletedTooltip') }),
    });
  }

  if (permissions.includes('read:client_low_risk_pii')) {
    showPills.push({
      label: `${t('profile.compliance')} ${t(`edit.complianceStateOptions.${user.complianceState}`)}`,
      leadingIcon: user.complianceState === 'APPROVED' ? 'checkmark' : 'problem',
      ...(user.complianceState !== 'APPROVED' && { tooltip: t('details.complianceStateNotApprovedTooltip') }),
    });
  }

  if (user.lastSuitabilityReviewAt !== null) {
    const lastSuitabilityReviewMonthsAgo = dayjs().diff(user.lastSuitabilityReviewAt, 'month');

    if (lastSuitabilityReviewMonthsAgo < SUITABILITY_REVIEW_VALIDITY_MONTHS) {
      showPills.push({
        label: t('details.annualInfoUpToDate'),
        leadingIcon: 'checkmark',
      });
    } else {
      showPills.push({
        label: t('details.annualInfoNeedsReview'),
        leadingIcon: 'problem',
        tooltip: t('details.annualInfoNeedsReviewTooltip', { months: lastSuitabilityReviewMonthsAgo }),
      });
    }
  }

  if (isIndividual && permissions.includes('read:client_high_risk_pii')) {
    showHighlights.push({
      icon: <CalendarToday sx={{ color: isNull(user.dateOfBirth) ? `${colors.error} !important` : 'inherit' }} />,
      label: user.dateOfBirth
        ? localizedDate(user.dateOfBirth)
        : t('details.needsToBeFilled'),
      tooltip: t('details.dateOfBirth'),
      onClick: () => onHighlightClicked('dateOfBirth'),
      isAlert: isNull(user.dateOfBirth),
    });
  }

  if (!isIndividual) {
    showHighlights.push({
      icon: <CalendarToday sx={{ color: isNull(user.establishedDate) ? `${colors.error} !important` : 'inherit' }} />,
      leadingLabel: t('details.establishedDate'),
      label: user.establishedDate
        ? localizedDate(user.establishedDate)
        : t('details.needsToBeFilled'),
      onClick: () => onHighlightClicked('establishedDate'),
      isAlert: isNull(user.establishedDate),
    });
  }

  if (permissions.includes('read:client_low_risk_pii')) {
    showHighlights.push({
      icon: <MailOutline sx={{ color: !user.primaryEmail ? `${colors.error} !important` : 'inherit' }} />,
      label: user.primaryEmail ?? t('details.needsToBeFilled'),
      onClick: () => onHighlightClicked('primaryEmail'),
      isAlert: !user.primaryEmail,
    });
  }

  if (permissions.includes('read:client_low_risk_pii')) {
    showHighlights.push({
      icon: <Phone sx={{ color: !user.phone ? `${colors.error} !important` : 'inherit' }} />,
      label: user.phone ? formatPhoneNumber(user.phone) : t('details.needsToBeFilled'),
      onClick: () => onHighlightClicked('phone'),
      isAlert: !user.phone,
    });
  }

  if (permissions.includes('read:client_high_risk_pii')) {
    const physicalAddressString = generateShortAddressString(user.physicalAddress);
    showHighlights.push({
      icon: <LocationOnOutlined sx={{ color: physicalAddressString === '' ? `${colors.error} !important` : 'inherit' }} />,
      label: physicalAddressString === '' ? t('details.needsToBeFilled') : physicalAddressString,
      onClick: () => onHighlightClicked('physicalAddress'),
      isAlert: physicalAddressString === '',
    });
  }

  if (!isIndividual) {
    const relationsCount = (user?.relatedEntities ?? []).length;
    showHighlights.push({
      label: t('details.numClientsInEntity', { count: relationsCount }),
    });
  }

  if (isIndividual) {
    showHighlights.push({
      icon: <LanguageIcon />,
      label: !isNull(user.language) ? t(`components:languages.${user.language?.toLowerCase()}`) : t('components:languages.english'),
      onClick: () => onHighlightClicked('language'),
    });
  }

  if (!isIndividual) {
    showHighlights.push({
      leadingLabel: t('nonIndividualClients:entityType'),
      label: t(`entityTypes:${user.type}`),
      onClick: () => onHighlightClicked('entityType'),
    });
  }

  showHighlights.push({
    icon: <AccountBalanceOutlined />,
    label: user.organization?.name ?? '',
    onClick: permissions.includes('write:client_authentication') ? () => onHighlightClicked('organization') : undefined,
  });

  const transitions = [];
  if (permissions.includes('transition:client_basic')) {
    if (user.state === UserStates.FROZEN) {
      transitions.push({
        label: t('details.activate'),
        prefixIcon: <CheckCircleIcon style={{ marginRight: '10px', color: colors.primary }} />,
        style: { display: 'flex', justifyContent: 'right' },
        onClick: () => {
          transitionUser({
            variables: { input: { userId: user.id, transition: 'activate' } },
          });
        },
      });
    }
    if (user.state === UserStates.ACTIVE) {
      transitions.push({
        label: t('menu.freezeClient'),
        prefixIcon: <BlockRoundedIcon style={{ marginRight: '10px', color: colors.warning }} />,
        style: { display: 'flex', justifyContent: 'right' },
        onClick: () => {
          transitionUser({
            variables: { input: { userId: user.id, transition: 'freeze' } },
          });
        },
      });

      transitions.push({
        label: t('details.deactivate'),
        prefixIcon: <DeleteForeverIcon style={{ marginRight: '10px', color: colors.error }} />,
        style: { display: 'flex', justifyContent: 'right' },
        onClick: () => setDeactivationDialogOpen(true),
      });
    }
  }

  return (
    <>
      {!isEmpty(basicMissingInfo) && (
        <ClientDetailBoxAlert
          action={
            <Button sx={{ color: '#111315' }} variant='text' onClick={updateProfile} startIcon={(<EditIcon data-testid="add-icon" />)} data-testid='edit-button'>
              {t('client:form.edit')}
            </Button>
          }
          content={t('client:details.errorMessages.fieldsToBeFilled')}
        />
      )}

      <Summary
        title={generateClientNameString(user)}
        householdLinks={user.households}
        pills={showPills}
        highlights={showHighlights}
        contextMenu={
          [
            ...workflows,
            {
              style: { display: 'flex', justifyContent: 'right' },
              prefixIcon: <EditRoundedIcon style={{ marginRight: '10px' }} />,
              label: t('menu.editProfile'),
              onClick: () => updateProfile(),
            },
            ...[permissions.includes('write:client_authentication') && {
              style: { display: 'flex', justifyContent: 'right' },
              prefixIcon: <AccountBalanceOutlined style={{ marginRight: '10px' }} />,
              label: t('menu.moveToAnotherOrganization'),
              onClick: () => updateOrganization(),
            }],
            {
              style: { display: 'flex', justifyContent: 'right' },
              prefixIcon: <ContentCopyRoundedIcon style={{ marginRight: '10px' }} />,
              label: t('menu.copyID'),
              subtitle: user.id,
              onClick: () => { copy(user.id); },
            },
            {
              style: { display: 'flex', justifyContent: 'right' },
              prefixIcon: <ContentCopyRoundedIcon style={{ marginRight: '10px' }} />,
              label: t('menu.copyProfileURL'),
              onClick: () => { copy(window.location.href); },
            },
            {
              style: { display: 'flex', justifyContent: 'right' },
              prefixIcon: <SupervisedUserCircleIcon style={{ marginRight: '10px' }} />,
              label: t('menu.addToHousehold'),
              onClick: () => { addClientToHousehold(); },
            },
            ...transitions,
          ]
        }
      />
      {!skipWorkflow && (
        <Box mt={2}>
          <ActiveWorkflows userId={user.id} activeWorkflowCompletionId={activeWorkflowCompletionId} refetchObject={refetch} />
        </Box>
      )}
      <ConfirmationModal
        onCancel={() => setDeactivationDialogOpen(false)}
        onConfirm={async () => {
          transitionUser({
            variables: { input: { userId: user.id, transition: 'deactivate' } },
          });
          setDeactivationDialogOpen(false);
        }}
        open={deactivationDialogOpen}
        title={`${t('details.deactivationConfirmation.title', { clientName: generateClientNameString(user) })}`}
        bodyText={`${t('details.deactivationConfirmation.body')}`}
        confirmButton={t('details.deactivate')}
      />
    </>
  );
}
