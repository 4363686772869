import { Paper } from '@mui/material';
import PortfolioOptimizersTable from './components/table';

const PortfolioOptimizers = () => (
  <Paper sx={{ width: '100%' }}>
    <PortfolioOptimizersTable />
  </Paper>
);

export default PortfolioOptimizers;
