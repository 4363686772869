import { Field } from './profile';
import EditableDetailsBox from '../../../components/inputs/editableDetailsBox';

export interface DetailMeta {
  label?: string,
  showAlert?: boolean,
  canUpdate?: boolean,
  emptyMeta?: {
    emptyStateType?: 'Not Available' | 'Required',
    notAvailableMeta?: {
      header: string,
      body: string,
    },
    requiredMeta?: {
      header: string,
      buttonText: string,
    },
  },
  actionItem?: React.ReactNode,
}

interface Props {
  title: string,
  state: 'Empty' | 'Completed' | 'Error',
  children: any,
  modalTitle?: string,
  loading?: boolean,
  user: any,
  section?: string,
  buttonText?: string,
  fields?: Field[],
  meta?: DetailMeta,
  onUpdate?: (object: any) => void,
  modalTitlePrefix?: string,
  disableWhenVerifying?: boolean,
  modalButton?: string,
  icon?: any,
  isIdVerification?: boolean,
}

const ClientDetailBox = (
  {
    title, modalTitle, children, user, state, section, buttonText, fields = [], meta, onUpdate, loading, modalTitlePrefix,
    disableWhenVerifying = false, modalButton, icon, isIdVerification,
  }: Props,
) => (
    <EditableDetailsBox
      title={title}
      modalTitle={modalTitle}
      object={user}
      section={section}
      buttonText={buttonText}
      fields={fields}
      i18nextNamespace='client'
      onUpdate={(data) => onUpdate && onUpdate(data)}
      loading={!!loading}
      children={children}
      state={state}
      meta={meta}
      disableWhenVerifying={disableWhenVerifying}
      modalTitlePrefix={modalTitlePrefix}
      modalButton={modalButton}
      icon={icon}
      isIdVerification={isIdVerification}
      userId={user?.id}
    />
);

export default ClientDetailBox;
