import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface LineChartTokens {
  lineChart: {
    color?: string;
    secondaryLine?: string;
    tension?: number;
    showAxis?: boolean;
    timeFrameButtonHeight?: string;
  },
}

export const useLineChartTokens = (): LineChartTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: LineChartTokens = {
    lineChart: {
      color: sys.color.primary,
      secondaryLine: sys.color.primaryHover,
      tension: 0.3,
      showAxis: false,
      timeFrameButtonHeight: 'auto',
    },
  };

  return comp;
};
