import { SearchSecuritiesField } from 'ovComponents/2-component';
import { Box, Typography } from '../../../../../1-primative';
import { translateBackend } from '../../../../../../assets/i18n/config';

export const SearchSecurities = ({
  options = {},
}: {
  options?: any,
}): JSX.Element => (
  <>
    <Box display="flex" alignItems={'center'}>
      <Typography variant='headingSmall'>{options.customTitle ?? translateBackend(options.customTitle)}</Typography>
    </Box>
    <SearchSecuritiesField />
  </>
);
