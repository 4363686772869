import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BulkUpsertRequestFilter, FilterBulkUpsertRequests } from './components/filterBulkUpsertRequests';
import { Box, Skeleton } from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination,
  CardContent, TableHeadCell,
  Card,
  SelectField,
  MenuItem,
} from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { IntegrationType } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';
import { BulkUpsertRequest, BulkUpsertRequestObjectTypes, BulkUpsertRequestStates } from '../../../interfaces/bulkUpsertRequest';

const FETCH_BULK_UPSERT_REQUESTS = gql`
  query fetchBulkUpsertRequests($input: FetchBulkUpsertRequestsInput!) {
    fetchBulkUpsertRequests(input: $input) {
      bulkUpsertRequests {
        id
        state
        createdAt
        objectType
        queuedItemsCount
        failedItemsCount
        processedItemsCount
        organizationUser { id firstName lastName }
        apiToken { id name }
      }
      totalCount
    }
  }
`;

export const BulkUpsertRequests = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['devSettings']);
  const { localizedDateTime } = useLocalization();
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [integrationId] = usePageState('', 'int');
  const [state, setState] = usePageState<BulkUpsertRequestStates | undefined>(undefined, 'st');
  const [objectType, setObjectType] = usePageState<BulkUpsertRequestObjectTypes | undefined>(undefined, 'tp');
  const [createdAtStart, setCreatedAtStart] = usePageState<string | undefined>(undefined, 'start');
  const [createdAtEnd, setCreatedAtEnd] = usePageState<string | undefined>(undefined, 'end');
  const [sortField, setSortField] = usePageState('createdAt', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading, data, previousData,
  } = useQuery(FETCH_BULK_UPSERT_REQUESTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          ...(integrationId ? { integrationId } : {}),
          ...(state ? { state } : {}),
          ...(objectType ? { objectType } : {}),
          ...(createdAtStart ? { createdAtStart: `${createdAtStart}T00:00:00.000Z` } : {}),
          ...(createdAtEnd ? { createdAtEnd: `${createdAtEnd}T00:00:00.000Z` } : {}),
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const getRanByName = (bulkUpsertRequest: BulkUpsertRequest) => {
    if (bulkUpsertRequest.organizationUser) {
      return `${bulkUpsertRequest.organizationUser.firstName} ${bulkUpsertRequest.organizationUser.lastName}`;
    }
    if (bulkUpsertRequest.apiToken) {
      return bulkUpsertRequest.apiToken.name;
    }
    return '-';
  };

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };
  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);
  return (
    <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <SelectField
              value={state || ''}
              fullWidth
              label=''
              onChange={(e: any) => {
                setState(e.target.value || undefined);
                setPage(1);
              }}
              sx={{ width: '180px' }}
            >
              <MenuItem value={''}>-</MenuItem>
              {Object.keys(BulkUpsertRequestStates).map((s) => (
                <MenuItem key={s} value={s}>{t(`devSettings:bulkUpsertRequest.states.${s}`)}</MenuItem>
              ))}
            </SelectField>
            <Box display='flex'>
              <FilterBulkUpsertRequests
                filter={{
                  objectType, createdAtStart, createdAtEnd,
                }}
                setFilter={(filter: BulkUpsertRequestFilter) => {
                  setObjectType(filter.objectType);
                  setCreatedAtStart(filter.createdAtStart);
                  setCreatedAtEnd(filter.createdAtEnd);
                  setPage(1);
                }}
              />
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell isSortable onClick={() => onSort('createdAt')} sortDirection={sortDirection('createdAt')}>{t('bulkUpsertRequest.table.createdAt')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.ranBy')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.objectType')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.state')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.queuedItemsCount')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.failedItemsCount')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.table.processedItemsCount')}</TableHeadCell>
              </TableRow>
              { loading && !previousData && [...Array(15)].map((_x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchBulkUpsertRequests?.bulkUpsertRequests?.map((bulkUpsertRequest: BulkUpsertRequest) => (
                <TableRow
                  hover
                  key={bulkUpsertRequest.id}
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/bulkUpsertRequests/${bulkUpsertRequest.id}`)}
                >
                  <TableCell>{localizedDateTime(bulkUpsertRequest.createdAt)}</TableCell>
                  <TableCell>{getRanByName(bulkUpsertRequest)}</TableCell>
                  <TableCell>{t(`integrationsModal.scopeOptions.${IntegrationType.DATA_INGESTION}.${bulkUpsertRequest.objectType}`)}</TableCell>
                  <TableCell>{t(`bulkUpsertRequest.states.${bulkUpsertRequest.state}`)}</TableCell>
                  <TableCell right>{bulkUpsertRequest.queuedItemsCount}</TableCell>
                  <TableCell right>{bulkUpsertRequest.failedItemsCount}</TableCell>
                  <TableCell right>{bulkUpsertRequest.processedItemsCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchBulkUpsertRequests?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box >
    </Card>
  );
};

export default BulkUpsertRequests;
