import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_USER_RELATED_ENTITIES } from 'pages/client/components/relatedEntitiesTable';
import { UPDATE_USER_RELATED_ENTITIES } from 'pages/client/components/relatedEntityEdit';
import { RelatedEntity, RelatedEntityInput } from '../../../../interfaces';
import { generateClientNameString } from '../../../../util';
import { StepProps } from '../wizard';
import { usePermissions } from '../../../../providers/userContextProvider';
import { AddRelatedEntityActionStepResult } from './addRelatedEntityActionStepResult';

export function AddRelatedEntityActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const { permissions } = usePermissions();
  const [addedEntityName] = useState(generateClientNameString(state.newEntity));

  const [updateUserRelatedEntities, {
    loading, called, data, error,
  }] = useMutation(UPDATE_USER_RELATED_ENTITIES);

  /* run the mutation as soon as permissions and existing relations are loaded */
  useEffect(() => {
    if (permissions.length === 0) return;
    if (context.existingRelatedEntities === null) return;
    if (called) return;

    doAddRelation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, permissions, context]);

  const doAddRelation = () => {
    if (!state.relation) return;

    mutationEvent('started');

    const copyOfexistingRelatedEntities = context.existingRelatedEntities.map((r: RelatedEntity):RelatedEntityInput => ({
      relation: r.relation,
      entityId: r.entity.id,
    }));
    const newRelatedEntity:RelatedEntityInput = {
      relation: state.relation,
      entityId: state.newEntity?.id ?? '',
    };

    updateUserRelatedEntities({
      variables: {
        userId: context.userId,
        relatedEntities: [...copyOfexistingRelatedEntities, newRelatedEntity],
      },
      refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
      onCompleted: () => mutationEvent('succeeded'),
      onError: () => mutationEvent('failed'),
    });
  };

  return (
    <AddRelatedEntityActionStepResult
      state={state}
      context={context}
      continueTo={continueTo}
      loading={loading}
      succeeded={!!data}
      error={error}
      addedEntityName={addedEntityName}
    />
  );
}
