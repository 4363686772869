export interface SpacingTokens {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

export const spacing: SpacingTokens = {
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '24px',
};
