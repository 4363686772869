import { useTranslation } from 'react-i18next';
import { useGlobalToast } from 'providers/globalToastProvider';
import { SubAccount, SubAccountStates, getAccountName } from '../../../interfaces';
import { Highlight, Pill, Summary } from '../../../components/layout/client/summary';

interface SummaryProps {
  subAccount: SubAccount
  updateSubAccount: () => void
  onHighlightClicked: (prop: any) => void
  transitionSubAccount: (transitionName: string) => void
  deactivateSubAccount: () => void
}

export function SubAccountSummary({
  subAccount, updateSubAccount, onHighlightClicked, transitionSubAccount, deactivateSubAccount,
}: SummaryProps) {
  const { t } = useTranslation(['accountsDetail', 'accountTypes', 'subAccountsDetail', 'client', 'subaccount']);
  const { showToast } = useGlobalToast();

  if (!subAccount) return <></>;
  const portfolioName = subAccount?.theme?.translatedName?.en;
  const subAccountName = subAccount?.name ?? `${t(`accountTypes:${subAccount?.account.type}`)} ${portfolioName}`;
  const financialProductId = subAccount.goal?.financialProduct?.id ?? subAccount.financialProduct?.id;
  const recommended: boolean = financialProductId === subAccount.suggestedFinancialProduct?.id;

  const showPills: Pill[] = [
    {
      label: `${t('subAccountsDetail:account')}: ${getAccountName(subAccount.account)}`,
    },
    {
      label: t(`subAccountsDetail:state.${subAccount.state}`),
      leadingIcon: subAccount.state === 'ACTIVE' ? 'checkmark' : 'problem',
    },
    {
      label: t('subAccountsDetail:highlights.suitabilityScore', { score: subAccount.calculatedSuitabilityScore }),
      leadingIcon: 'checkmark',
    },
  ];

  const highlights: Highlight[] = [
    {
      label: t('subAccountsDetail:highlights.goalType', { goalType: subAccount?.goal?.name ?? '' }),
      onClick: () => onHighlightClicked('goalType'),
    },
    {
      label: t('subAccountsDetail:highlights.portfolio', {
        portfolioType: subAccount?.goal?.financialProduct ? subAccount.goal.financialProduct?.translatedName?.en : subAccount.financialProduct?.translatedName?.en,
      }),
      onClick: () => onHighlightClicked('portfolio'),
    },
    {
      label: t('subAccountsDetail:highlights.portfolioTheme', {
        portfolioTheme: subAccount?.goal?.financialProduct?.theme ? subAccount.goal.financialProduct.theme?.translatedName?.en : subAccount?.theme?.translatedName?.en ?? '',
      }),
      onClick: () => onHighlightClicked('portfolioTheme'),
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.feeGrid'),
      label: subAccount?.feeTier?.name ?? t('shared:inheritedDashWhat', { what: subAccount?.applicableFeeTier?.name }),
      onClick: () => onHighlightClicked('feeGrid'),
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.billingCycle'),
      label: subAccount?.billingSchedule?.frequency
        ? t(`subAccountsDetail:frequency.${subAccount?.billingSchedule?.frequency}`)
        : t('shared:inheritedDashWhat', { what: subAccount?.applicableBillingSchedule?.frequency }),
      onClick: () => onHighlightClicked('billingCycle'),
    },
    {
      label: t(`subAccountsDetail:highlights.skipIPS.${subAccount?.skipIPS}`),
      onClick: () => onHighlightClicked('skipIPS'),
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.allowDeposit'),
      label: t(subAccount?.allowClientDeposits ? 'shared:yes' : 'shared:no'),
      onClick: () => onHighlightClicked('allowDeposit'),
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.allowFractionalShares'),
      label: t(subAccount?.isPartial ? 'shared:yes' : 'shared:no'),
      onClick: () => onHighlightClicked('allowFractionalShares'),
    },
    {
      label: t(`subAccountsDetail:${recommended ? 'acceptablePortfolio' : 'notAcceptablePortfolio'}`),
      onClick: () => onHighlightClicked('portfolio'),
      tooltip: !recommended ? t('subAccountsDetail:notAcceptableFinancialProduct') : undefined,
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.tradingProcess'),
      label: t(`client:tradingProcessItems.${subAccount.tradingProcess}`),
      onClick: () => onHighlightClicked('tradingProcess'),
    },
    {
      leadingLabel: t('subAccountsDetail:highlights.liquidated'),
      label: t(`shared:${subAccount?.liquidated ? 'yes' : 'no'}`),
      onClick: () => onHighlightClicked('liquidated'),
    },
  ];

  const transitions = [
    {
      name: 'activate',
      from: [SubAccountStates.AWAITING_APPROVAL],
    },
    {
      name: 'review',
      from: [SubAccountStates.ACTIVE],
    },
  ];

  const transitionMenuItems = transitions
    .filter((transition) => transition.from.includes(subAccount.state))
    .map((transition) => ({
      label: t(`components:subAccountTransitions.${transition.name}`),
      onClick: () => transitionSubAccount(transition.name),
    }));

  const reactivate = () => {
    transitionSubAccount('activate');
    showToast({ severity: 'success', message: t('subaccount:subAccountSuccessToast') });
  };

  return (
    <Summary
      title={subAccountName}
      pills={showPills}
      highlights={highlights}
      contextShowId={subAccount.id}
      contextMenu={
       subAccount.state !== SubAccountStates.INACTIVE ? [
         {
           label: t('subAccountsDetail:editSubAccount'),
           onClick: updateSubAccount,
         },
         ...transitionMenuItems,
         {
           label: t('subaccount:close'),
           onClick: deactivateSubAccount,
         },
       ] : [
         {
           label: t('subaccount:reactivateSubAccount'),
           onClick: reactivate,
         },
       ]
      }
    />
  );
}
