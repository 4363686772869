import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useHouseholdContext } from 'pages/household';
import { MemberTag } from 'pages/household/components/memberTag';
import { useClientContext } from 'pages/client';
import { Badge, Tooltip } from 'ovComponents';
import { colors } from '../../../theme/colors';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Account, getAccountNumber, getIconForState } from '../../../interfaces';

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const AccountItem = ({ account, tag }: { account: Account, tag?: Tag }) => {
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const navigate = useNavigate();
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const getPercentageOfTotalAsset = (): number => {
    const totalMarketValueCents: number = clientContext?.totalMarketValueCents || householdContext?.totalMarketValueCents || 0;
    const percentage = (account?.statistics?.marketValueCents ?? 0) / totalMarketValueCents;
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  const subAccounts = account.subAccounts?.filter((x: any) => x.state !== 'INACTIVE');
  const awaitingSubAccountCount: number = account.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  return (
    <Box
      onClick={() => navigate(`account/${account.id}`)}
      padding="16px"
      border='1px solid #DBDFE3'
      marginBottom='16px'
      borderRadius='4px'
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ position: 'relative', top: '-25px', height: '15px' }}>
        <Box display="flex" flexDirection="row">
          { tag && <MemberTag sequenceNumber={tag.sequenceNumber} name={tag.text} /> }
        </Box>
        <Box display="flex" flexDirection="row" >
          { awaitingSubAccountCount > 0 && (
            <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
              <Badge label={awaitingSubAccountCount.toString()} color='negative'/>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" marginTop="-15px" marginBottom="15px">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography fontWeight={600} fontSize="16px">{t(`accountTypes:${account.type}`)}</Typography>
            <Typography marginTop={0.25} fontWeight={500} fontSize="10px" ml={0.5}>#{getAccountNumber(account)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            {getIconForState(account.state, '14px')}
            <Typography fontWeight={600} sx={{ ml: '3px' }} fontSize="12px">{t(`accountsDetail:accountState.${account.state}`)}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography fontWeight={600} fontSize="18px">
            {formatMoneyValue(account?.statistics?.marketValueCents ?? 0)}
          </Typography>
          <Typography color={(account?.statistics?.simpleReturnAmount ?? 0) >= 0 ? colors.success : 'error'} fontWeight={500} fontSize="12px">
            {`${formatMoneyValue(account?.statistics?.simpleReturnAmount ?? 0)}
            (${formatPercentValue(account?.statistics?.simpleReturnPercent ?? 0)})`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" marginTop="31px">
          <Typography color={colors.gray500} fontWeight={500} fontSize="12px">
            {t('accountsSummary.subAccountsLinked', { count: subAccounts?.length ?? 0 })}
          </Typography>
          <Typography color={colors.gray500} fontWeight={500} fontSize="12px">
            {t('accountsSummary.ofTotalAssets', { percentage: formatPercentValue(getPercentageOfTotalAsset(), 0) })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountItem;
