import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import uniqueId from 'lodash/uniqueId';
import { useEffect, useState } from 'react';
import { usePermissions } from '../../../../../providers/userContextProvider';
import RichTextEditor from '../../../../../components/inputs/richTextEditor';
import { NoteObjectTypes, NoteTypes } from '../../../../../interfaces/note';
import {
  Typography, Box,
} from '../../../../1-primative';
import { Button, Card, CardContent } from '../../../../2-component';
import { ConfirmationModal } from '../../../../3-pattern';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

export const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export const TRANSITION_NOTE = gql`
  mutation transitionNote($input: TransitionNoteInput!) {
    transitionNote(input: $input) {
      note {
        id
      }
    }
  }
`;
interface NewNoteProp {
  objectId?: string,
  noteObjectType: NoteObjectTypes,
  afterUpdate?: () => void,
  hidePostAndClearButton?: boolean,
  data?: string,
  title?: string,
  onChange?: ((e: any) => void),
  characterCountLimiter?: number,
  contentLengthChanged?: (contentLength: number) => void,
}
const MAX_CHARACTER_LIMIT = 10000;

const NewNote = ({
  objectId, noteObjectType, afterUpdate, hidePostAndClearButton, data, onChange, title, characterCountLimiter = MAX_CHARACTER_LIMIT, contentLengthChanged,
}: NewNoteProp) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('buildModels');
  const { sys } = useThemeTokens();
  const [clearConfirmationOpen, setClearConfirmationOpen] = useState(false);
  const [note, setNote] = useState({
    content: data ?? '',
    type: NoteTypes.ORGANIZATIONAL,
    objectId,
    objectType: noteObjectType || NoteObjectTypes.USER,
  });
  const [contentLength, setContentLength] = useState(0);
  const [key, setKey] = useState('key');
  const canWrite = (): boolean => (permissions.includes('write:notes') || false);
  const clearContent = () => {
    setNote({
      content: '', type: NoteTypes.ORGANIZATIONAL, objectId, objectType: noteObjectType || NoteObjectTypes.USER,
    });
    setKey(uniqueId());
  };
  useEffect(() => {
    if (contentLengthChanged) contentLengthChanged(contentLength);
  }, [contentLength, contentLengthChanged]);

  const [createNote, { loading }] = useMutation(CREATE_NOTE, {
    variables: {
      input: {
        ...note,
        content: note.content.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
      },
    },
    onCompleted: (value: any) => {
      if (value?.createNote?.note?.id) {
        publishNote({
          variables: {
            input: {
              noteId: value.createNote.note.id,
              transition: 'publish',
            },
          },
          onCompleted: () => {
            clearContent();
            if (afterUpdate) afterUpdate();
          },
        });
      }
    },
  });
  const [publishNote] = useMutation(TRANSITION_NOTE);

  useEffect(() => {
    setKey(uniqueId()); // Run  this once to eleminate Toolbar issue
  }, []);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <RichTextEditor
            onChange={(value) => {
              if (characterCountLimiter && (contentLength + 1) > characterCountLimiter) return;
              if (onChange) onChange(value);
              setNote((n) => ({ ...n, content: value }));
            }}
            value={note?.content ?? ''}
            readonly={!canWrite()}
            noteType={note.type || NoteTypes.ORGANIZATIONAL}
            onChangeNoteType={(newType: NoteTypes) => {
              setNote((n) => ({ ...n, type: newType }));
            }}
            setContentLength={setContentLength}
            key={key}
            customEditorStyle={{
              '.public-DraftEditor-content': {
                paddingBottom: '25px',
                fontFamily: sys.font.base,
              },
            }}
            customToolbarStyle={{
              marginTop: '20px',
              bottom: '18px',
              position: 'absolute',
              '>div': {
                border: 'none',
                boxShadow: 'none',
                height: '20px',
              },
            }}
          />
          <Box display='flex' justifyContent='end'>
            <Typography data-testid='content-length' variant='labelSmall' colorVariant='variant'>
              {contentLength.toLocaleString()} / {characterCountLimiter?.toLocaleString()} {t('newNote.characters')}
            </Typography>
          </Box>
          {!hidePostAndClearButton && canWrite() && (
            <Box display='flex' justifyContent='end' mt={2}>
              <Button
                type="button"
                label={t('buildModels:edit.clear')}
                data-testid='clear-note'
                disabled={loading || contentLength === 0}
                variant="outlined"
                onClick={() => setClearConfirmationOpen(true)}
              />
              <Button
                type='submit'
                label={t('buildModels:edit.post')}
                data-testid='save-note'
                sx={{ ml: 1 }}
                disabled={loading || contentLength === 0 || (contentLength > characterCountLimiter)}
                onClick={async () => {
                  await createNote();
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <ConfirmationModal
        open={clearConfirmationOpen}
        loading={loading}
        title={t('newNote.clearTitle')}
        bodyText={t('newNote.clearSubText')}
        onConfirm={() => {
          clearContent();
          setClearConfirmationOpen(false);
        }}
        onCancel={() => setClearConfirmationOpen(false)}
        confirmButtonLabel={t('newNote.clear')}
        cancelButtonLabel={t('newNote.cancelClear')}
      />
    </>
  );
};

export default NewNote;
