import { useTranslation } from 'react-i18next';
import { colors } from '../../../../theme/colors';
import { BulkUpsertItem, BulkUpsertItemStates, getExternalId } from '../../../../interfaces/bulkUpsertRequest';
import {
  Dialog, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableRow,
} from '../../../2-component';
import { Box, Grid, Typography } from '../../../1-primative';
import { Alert } from '../../../2-component/alert/alert';
import { CopyId } from '../../../3-pattern';

const UpsertItemModal = ({ upsertItem, handleClose }: {
  upsertItem?: BulkUpsertItem,
  handleClose: () => void,
}) => {
  const { t } = useTranslation('devSettings');

  if (!upsertItem) return <></>;

  return (
    <Dialog open onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>{t('bulkUpsertRequest.items.upsertItemModal.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} xs={8} alignItems='start' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant='titleMedium'>{t('bulkUpsertRequest.items.upsertItemModal.summary.batchId')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <CopyId id={upsertItem.batchId || '-'} />
          </Grid>
          <Grid item xs={3}>
            <Typography variant='titleMedium'>{t('bulkUpsertRequest.items.upsertItemModal.summary.oneVestId')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <CopyId id={upsertItem.oneVestId || '-'} />
          </Grid>
          <Grid item xs={3}>
            <Typography variant='titleMedium'>{t('bulkUpsertRequest.items.upsertItemModal.summary.externalId')}</Typography>
          </Grid>
          <Grid item xs={9}>
          <CopyId id={getExternalId(upsertItem) || '-'} />
          </Grid>
        </Grid>

        {upsertItem.state === BulkUpsertItemStates.FAILED && upsertItem.outputErrors && upsertItem.outputErrors.length > 0 && (
          <Box mb={2}>
            <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
              { t('bulkUpsertRequest.items.upsertItemModal.errorTitle') }
              <Table>
                <TableBody>
                  <TableRow backgroundColor='transparent'>
                    <TableCell>{t('bulkUpsertRequest.items.upsertItemModal.errorCode')}</TableCell>
                    <TableCell>{t('bulkUpsertRequest.items.upsertItemModal.errorField')}</TableCell>
                    <TableCell>{t('bulkUpsertRequest.items.upsertItemModal.errorDescription')}</TableCell>
                  </TableRow>
                  {upsertItem.outputErrors.map((e, i) => (
                    <TableRow key={i} backgroundColor='transparent'>
                      <TableCell component="th" scope="row">{e.code}</TableCell>
                      <TableCell>{e.field}</TableCell>
                      <TableCell>{e.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Alert>
          </Box>
        )}
        <Box mb={2} sx={{ background: colors.noteBackground, p: 1, borderRadius: '8px' }}>
          <pre>{JSON.stringify({
            integrations: upsertItem.integrations?.map((i) => ({ integrationId: i.integrationId, externalId: i.externalId, externalData: i.externalData || undefined })),
            ...upsertItem.rawData,
          }, null, 2) }</pre>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpsertItemModal;
