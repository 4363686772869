import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import {
  EXCHANGES_COUNTRIES_MAP, Exchanges, FinancialProductState,
} from '../../../../interfaces/financialProduct';
import { Box } from '../../../1-primative';
import {
  TextField, TranslatableTextField, Switch, MenuItem,
} from '../../../2-component';
import {
  FormModal, ScheduleSelect, SecuritySelect, AssetClassSelect, AmountField,
} from '../../../3-pattern';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { FETCH_FINANCIAL_PRODUCT } from '../../../../components/tables/tradesTable/newTrade';

const UPDATE_PRODUCT = gql`
mutation updateFinancialProduct($input: UpdateFinancialProductInput!) {
  updateFinancialProduct(input: $input) {
    financialProduct {
      id
    }
  }
}
`;

const TRANSITION_PRODUCT = gql`
  mutation transitionFinancialProduct($input: TransitionFinancialProductInput!) {
    transitionFinancialProduct(input: $input) {
      financialProduct {
        id
      }
    }
  }
`;

export const isAlphaNumeric = (str: string): boolean => /^[a-zA-Z0-9]+$/.test(str);
export const isAlphanumericOrHasFullstop = (str: string): boolean => /^(\.[a-zA-Z0-9]+|[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*)$/.test(str);
export const isValidCusipLength = (str: string): boolean => str.length === 9 || str.length === 22;

const EditProduct = ({
  afterUpdate, productToUpdate, open, handleClose,
}: {
  afterUpdate: () => void, productToUpdate: any, open: boolean, handleClose: () => void,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['configureModels', 'shared']);
  const { showToast } = useGlobalToast();
  const [product, setProduct] = useState(productToUpdate);
  const [initialState] = useState(productToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const [enableSchedules, setEnableSchedules] = useState(!!productToUpdate?.schedule);
  const [enableTertiaryAssetClass, setEnableTertiaryAssetClass] = useState(!!productToUpdate?.tertiaryAssetClass);
  const [invalidCusip, setInvalidCusip] = useState<boolean | undefined>(undefined);
  const [invalidIsin, setInvalidIsin] = useState<boolean | undefined>(undefined);
  const [invalidRic, setInvalidRic] = useState<boolean | undefined>(undefined);
  const { activeOrganization } = useContext(UserContext);

  const getChangedFields = (): Record<string, any> => {
    const fieldTransforms = {
      currentPriceCents: (value: number) => Number(value),
      // add more fields, if needed
    } as const;

    return Object.entries(fieldTransforms).reduce<Record<string, any>>((acc, [field, transform]) => {
      const currentValue = product?.[field];
      const originalValue = productToUpdate?.[field];

      if (originalValue !== undefined && transform(currentValue) !== transform(originalValue)) {
        return { ...acc, [field]: transform(currentValue) };
      }

      return acc;
    }, {});
  };

  const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT, {
    variables: {
      input: {
        financialProductId: product?.id,
        translatedName: { en: product?.translatedName?.en, fr: product?.translatedName?.fr },
        ticker: product?.ticker,
        exchange: product?.exchange,
        primaryAssetClassId: product?.primaryAssetClass?.id ?? '',
        secondaryAssetClassId: product?.secondaryAssetClass?.id ?? '',
        tertiaryAssetClassId: enableTertiaryAssetClass ? product?.tertiaryAssetClass?.id ?? '' : null,
        substituteFinancialProductId: product?.substituteFinancialProduct?.id ?? undefined,
        autoUpdatePrices: product?.autoUpdatePrices,
        scheduleId: enableSchedules ? product?.schedule?.id : null,
        waivedFeePercentage: product?.waivedFeePercentage,
        taxRank: product?.taxRank,
        volatile: product?.volatile,
        settlementDays: product?.settlementDays,
        url: product?.url,
        isPartial: product?.isPartial,
        cusip: product?.cusip ?? undefined,
        isin: product?.isin ?? undefined,
        ric: product?.ric ?? undefined,
        currency: product?.currency ?? undefined,
        ...getChangedFields(),
      },
    },
  });

  useEffect(() => {
    if (!productToUpdate || !activeOrganization) return;

    const isCurrentCurrencySupported = activeOrganization.productShelfCurrencies?.includes(productToUpdate.currency);

    const updatedProduct = {
      ...productToUpdate,
      currency: productToUpdate.currency,
    };

    if (!isCurrentCurrencySupported) {
      const defaultCurrency = activeOrganization.applicableLocalization.defaultCurrency as CurrencyCodes;
      const hasDefaultCurrencyInShelf = activeOrganization.productShelfCurrencies?.includes(defaultCurrency);

      updatedProduct.currency = hasDefaultCurrencyInShelf
        ? defaultCurrency
        : (activeOrganization.productShelfCurrencies?.[0] || CurrencyCodes.CAD);
    }

    setLocalOpen(open);
    setEnableTertiaryAssetClass(!!productToUpdate?.tertiaryAssetClass);
    setEnableSchedules(!!productToUpdate?.schedule);
    setProduct(updatedProduct);
  }, [productToUpdate, activeOrganization, open]);

  const update = async (event: any) => {
    await updateProduct();
    afterUpdate();
  };

  const [transitionProduct] = useMutation(TRANSITION_PRODUCT);
  const [fetchProductPriceHistoryURL] = useLazyQuery(FETCH_FINANCIAL_PRODUCT, {
    variables: {
      input: {
        financialProductId: product?.id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (!product) {
    return <></>;
  }

  const disabled = !product?.ticker
  || !product?.translatedName?.en
  || !product?.exchange
  || !product?.primaryAssetClass?.id
  || !product?.secondaryAssetClass?.id
  || (product?.cusip && (!isAlphaNumeric(product?.cusip) || !isValidCusipLength(product?.cusip)))
  || (product?.isin && !isAlphaNumeric(product?.isin))
  || (product?.ric && !isAlphanumericOrHasFullstop(product?.ric))
  || (enableTertiaryAssetClass && !product?.tertiaryAssetClass?.id);

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();

  const transition = async (name: string) => {
    await transitionProduct({ variables: { input: { financialProductId: product.id, transition: name } } });
    if (name === 'archive') showToast({ severity: 'info', message: t('productModal.archiveToastMessage') });
    afterUpdate();
  };

  let menuItems: any[] | undefined = [];

  const getDownloadUrl = async () => {
    const result = await fetchProductPriceHistoryURL();
    const priceHistoryUrl = result?.data?.fetchFinancialProduct?.financialProduct?.priceHistoryUrl;
    if (!priceHistoryUrl) showToast({ severity: 'error', message: t('productModal.noHistoryToastMessage', { ticker: product?.ticker }) });
    return priceHistoryUrl;
  };

  const downloadPriceHistoryCSV = async () => {
    const url = await getDownloadUrl();
    window.open(url, '_blank');
  };

  if (permissions.includes('transition:financial_products')) {
    if (product.state !== FinancialProductState.ARCHIVED) {
      menuItems.push(
        <MenuItem key='archive' onClick={async () => transition('archive')}>
          {t('productModal.archive')}
        </MenuItem>,
      );
    }
  }

  if (permissions.includes('read:financial_products_snapshots')) {
    menuItems.push(
      <MenuItem key='download' onClick={async () => downloadPriceHistoryCSV()}>
        {t('productModal.download')}
      </MenuItem>,
    );
  }

  if (!menuItems.length) {
    menuItems = undefined;
  }

  return (
    <FormModal
      loading={loading}
      disabled={disabled}
      title={t('productModal.editTitle')}
      formButton={t('shared:update')}
      onSubmit={update}
      open={localOpen}
      handleClose={handleClose}
      menuItems={menuItems}
      state={product}
      initialState={initialState}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label={t('productModal.ticker')} fullWidth value={product.ticker} onChange={(e: any) => setProduct({ ...product, ticker: e.target.value })} />
        <TranslatableTextField label={t('productModal.name')} fullWidth value={product.translatedName} onChange={(e: any) => setProduct({ ...product, translatedName: e })} />
        <TextField select label={t('productModal.exchange')} fullWidth value={product.exchange} onChange={(e: any) => setProduct({ ...product, exchange: e.target.value })}>
        {exchanges.map((option: any) => (
          <MenuItem key={option} value={option}>
            {option} {EXCHANGES_COUNTRIES_MAP[option].country ? `(${EXCHANGES_COUNTRIES_MAP[option].country})` : ''}
          </MenuItem>
        ))}
        </TextField>
        <TextField
          label={t('productModal.originalCurrency')}
          fullWidth
          value={product.exchange ? EXCHANGES_COUNTRIES_MAP[product.exchange].currency : ''}
          disabled // This field is read-only as it's determined by the exchange
        />

        <TextField
          select
          label={t('productModal.displayCurrency')}
          fullWidth
          value={product.currency || CurrencyCodes.CAD}
          onChange={(e: any) => {
            setProduct({
              ...product,
              currency: e.target.value as CurrencyCodes,
            });
          }}
        >
          {activeOrganization?.productShelfCurrencies && activeOrganization.productShelfCurrencies.length
            ? activeOrganization.productShelfCurrencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
            ))
            : <MenuItem value={CurrencyCodes.CAD}>{CurrencyCodes.CAD}</MenuItem> }
        </TextField>

        <AssetClassSelect
          value={product?.primaryAssetClass?.id ?? ''}
          setAssetClass={(e: any) => {
            setProduct({ ...product, primaryAssetClass: { id: e.target.value } });
          }}
          label={t('productModal.primaryAssetClass')}
        />
        <AssetClassSelect
          value={product?.secondaryAssetClass?.id ?? ''}
          setAssetClass={(e: any) => {
            setProduct({ ...product, secondaryAssetClass: { id: e.target.value } });
          }}
          label={t('productModal.secondaryAssetClass')}
        />
        <SecuritySelect
          value={product?.substituteFinancialProduct?.id ?? ''}
          setSecurity={(chosenProduct) => {
            setProduct({ ...product, substituteFinancialProduct: { id: chosenProduct.id } });
          }}
          label={t('productModal.substituteProduct')}
          exceptTickers={[product.ticker]}
        />
        <AmountField
          label={t('productModal.currentPrice')}
          fullWidth
          amount={(product.currentPriceCents / 100).toString()}
          setAmount={(e: any) => setProduct({ ...product, currentPriceCents: Math.round(Number(e) * 100) })}
        />
        <Switch
          label={t('productModal.autoUpdatePrices')}
          checked={product.autoUpdatePrices}
          onChange={async (checked) => setProduct({ ...product, autoUpdatePrices: checked })}
        />
        <Switch
          checked={enableSchedules}
          label={t('productModal.illiquidMessage')}
          onChange={async (checked) => {
            setEnableSchedules(checked);
          }}
        />
        <Switch
          label={t('productModal.volatile')}
          checked={product.volatile}
          onChange={async (checked) => setProduct({ ...product, volatile: checked })}
        />
        <Switch
          checked={product.isPartial}
          onChange={async (checked) => setProduct({ ...product, isPartial: checked })}
          label={t('productModal.isPartial')}
        />
        <Switch
          checked={enableTertiaryAssetClass}
          label={t('productModal.tertiaryAssetClass')}
          onChange={(checked) => {
            setEnableTertiaryAssetClass(checked);
            if (!checked) setProduct({ ...product, tertiaryAssetClassId: null });
          }}
        />
        {enableTertiaryAssetClass && (
          <AssetClassSelect
            value={product?.tertiaryAssetClass?.id ?? ''}
            setAssetClass={(e: any) => {
              setProduct({ ...product, tertiaryAssetClass: { id: e.target.value } });
            }}
            label={t('productModal.tertiaryAssetClass')}
          />
        )}
        {enableSchedules ? (
          <ScheduleSelect
            value={product?.schedule ? product.schedule.id : ''}
            setSchedule={(e: any) => {
              setProduct({ ...product, schedule: { id: e.target.value } });
            }}
            setScheduleData={(schedule: any) => {
              setProduct({ ...product, schedule });
            }}
            label={t('productModal.schedule')}
          />) : null}
        {enableSchedules && product?.schedule?.nextDate ? (
          <TextField
            label={t('productModal.nextDate')}
            fullWidth
            value={product.schedule.nextDate}
            disabled />
        ) : null}
        <TextField
          fullWidth
          label={t('productModal.waivedFeePercentage')}
          type='number'
          value={product.waivedFeePercentage ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const waivedFeePercentage = round(parseFloat(e.target.value), 2);
              if (waivedFeePercentage >= 0 && waivedFeePercentage <= 100) {
                setProduct((prev: any) => ({ ...prev, waivedFeePercentage }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, waivedFeePercentage: null }));
            }
          }}
          trailingIcon='percent'
        />
        <TextField
          fullWidth
          label={t('productModal.taxRank')}
          type='number'
          value={product.taxRank ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const taxRank = round(parseFloat(e.target.value), 2);
              if (taxRank >= 0) {
                setProduct((prev: any) => ({ ...prev, taxRank }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, taxRank: null }));
            }
          }}
        />
        <TextField
          fullWidth
          label={t('productModal.settlementDays')}
          type='number'
          value={product.settlementDays ?? ''}
          onChange={(e: any) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const settlementDays = round(parseFloat(e.target.value), 2);
              if (settlementDays >= 0) {
                setProduct((prev: any) => ({ ...prev, settlementDays }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, settlementDays: null }));
            }
          }}
        />
        <TextField
          fullWidth
          label={t('productModal.url')}
          value={product.url ?? ''}
          onChange={(e: any) => setProduct((prev: any) => ({ ...prev, url: e.target.value }))}
        />
        <TextField
          fullWidth
          label={t('productModal.cusip')}
          value={product.cusip ?? ''}
          onChange={(e: any) => {
            const cusipValue = e.target.value;
            setInvalidCusip(cusipValue ? !isAlphaNumeric(cusipValue) || !isValidCusipLength(cusipValue) : undefined);
            setProduct((prev: any) => ({ ...prev, cusip: cusipValue || undefined }));
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, cusip: product.cusip }))}
          error={invalidCusip}
          infoTooltip={invalidCusip ? t('productModal.cusipLengthErrorMessage') : t('productModal.alphaNumericErrorMessage')}
          inputProps={{ maxLength: 22 }}
        />
        <TextField
          fullWidth
          label={t('productModal.isin')}
          value={product.isin ?? ''}
          onChange={(e: any) => {
            if (e.target.value !== '') {
              setInvalidIsin(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, isin: e.target.value }));
            } else {
              setInvalidIsin(undefined);
              setProduct((prev: any) => ({ ...prev, isin: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, isin: product.isin }))}
          error={invalidIsin}
          infoTooltip={invalidIsin ? t('productModal.alphaNumericErrorMessage') : '12 characters are required.'}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          fullWidth
          label={t('productModal.ric')}
          value={product.ric ?? ''}
          onChange={(e: any) => {
            if (e.target.value !== '') {
              setInvalidRic(!isAlphanumericOrHasFullstop(e.target.value));
              setProduct((prev: any) => ({ ...prev, ric: e.target.value }));
            } else {
              setInvalidRic(undefined);
              setProduct((prev: any) => ({ ...prev, ric: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, ric: product.ric }))}
          error={invalidRic}
          infoTooltip={invalidRic ? t('productModal.alphaNumericWithDotErrorMessage') : ''}
        />
      </Box>
    </FormModal>
  );
};

export default EditProduct;
