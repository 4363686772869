import { Paper } from '@mui/material';
import RebalancesTable from './components/rebalancesTable';

const Rebalances = () => (
    <Paper sx={{ width: '100%' }}>
      <RebalancesTable />
    </Paper>
);

export default Rebalances;
