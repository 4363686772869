/* eslint-disable implicit-arrow-linebreak */
import { useEffect, useState } from 'react';
import { ListItem, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import FormModal from '../../modals/formModal';
import AmountField from '../../inputs/amountField';
import { usePermissions } from '../../../providers/userContextProvider';
import { FETCH_TRANSFERS } from './oneoffTransfersTable';
import { TransferSources, TransferType } from '../../../interfaces/transfer';
import { useGlobalToast } from '../../../providers/globalToastProvider';

interface UpdatedTransfer {
  transferId?: string;
  source?: TransferSources;
  amountCents?: number;
  bankAccountId?: string;
  sourceId?: string;
  type?: TransferType;
}

const UPDATE_TRANSFER = gql`
  mutation updateTransfer($input: UpdateTransferInput!) {
    updateTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

const isSourceIdRequired = (transfer: UpdatedTransfer) =>
  transfer?.source && [TransferSources.FEE, TransferSources.TO_SUB_ACCOUNT, TransferSources.FROM_SUB_ACCOUNT, TransferSources.TO_ACCOUNT, TransferSources.FROM_ACCOUNT].includes(transfer?.source);
const isBankAccountRequired = (transfer: UpdatedTransfer) => transfer?.source && [TransferSources.USER].includes(transfer?.source);

const UpdateTransfer = ({ activeTransfer, open, onClose }: { activeTransfer: Record<string, any>; open: boolean; onClose: () => void }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();

  const [transfer, setTransfer] = useState<UpdatedTransfer>({});

  const [updateTransfer, opts] = useMutation(UPDATE_TRANSFER, {
    variables: { input: transfer },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
    onCompleted: () => setTransfer({}),
  });

  const { loading } = opts;

  const onSubmit = () => {
    if (!transfer.transferId) return;

    if (!transfer.source && !transfer.amountCents) return;

    if (transfer.source === activeTransfer.source && transfer.amountCents === activeTransfer.amountCents && transfer.type === activeTransfer.type) return;

    if (isSourceIdRequired(transfer) && !transfer?.sourceId) {
      showToast({ message: t('transferUpdate.sourceIdRequired'), severity: 'warning' });

      return;
    }

    if (isBankAccountRequired(transfer) && !transfer?.bankAccountId) {
      showToast({ message: t('transferUpdate.bankAccountIdRequired'), severity: 'warning' });

      return;
    }

    updateTransfer();
  };

  useEffect(() => {
    setTransfer({
      transferId: activeTransfer?.id ?? undefined,
      source: activeTransfer?.source ?? undefined,
      amountCents: activeTransfer?.amountCents ?? undefined,
      sourceId: activeTransfer?.sourceId ?? undefined,
      bankAccountId: activeTransfer?.bankAccount?.id ?? undefined,
      type: activeTransfer?.type ?? undefined,
    });
  }, [activeTransfer]);

  if (!permissions.includes('write:adjustment_transfer')) return <></>;

  return (
    <FormModal
      onSubmit={(e: any) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit();
        onClose();
      }}
      loading={loading}
      title={t('components:transferModal.updateTitle')}
      open={open}
      handleClose={() => onClose()}
      formButton={t('components:transferModal.updateBtnTitle')}
      maxWidth='sm'
    >
      <ListItem>
        <TextField select value={transfer?.source ?? TransferSources.USER} label={t('activityTable.source')} onChange={(e: any) => setTransfer({ ...transfer, source: e.target.value })} fullWidth>
          {Object.values(TransferSources).map((x: any) => (
            <MenuItem value={x} key={x}>
              {t(`transferSources.${x}`)}
            </MenuItem>
          ))}
        </TextField>
      </ListItem>

      <ListItem>
        <AmountField
          label={t('amount')}
          variant='outlined'
          value={transfer?.amountCents ?? 0}
          fullWidth
          onChange={(e: any) => {
            setTransfer({ ...transfer, amountCents: e.target.valueCents });
          }}
        />
      </ListItem>

      <ListItem>
        <TextField select value={transfer?.type ?? TransferType.Deposit} label={t('components:transferModal.type')} onChange={(e: any) => setTransfer({ ...transfer, type: e.target.value })} fullWidth>
          <MenuItem value={TransferType.Deposit}>{t('components:transferModal.deposit')}</MenuItem>
          <MenuItem value={TransferType.Withdraw}>{t('components:transferModal.withdraw')}</MenuItem>
        </TextField>
      </ListItem>

      {isSourceIdRequired(transfer) && (
        <ListItem>
          <TextField value={transfer?.sourceId} label={t('activityTable.sourceId')} onChange={(e: any) => setTransfer({ ...transfer, sourceId: e.target.value })} fullWidth></TextField>
        </ListItem>
      )}

      {isBankAccountRequired(transfer) && (
        <ListItem>
          <TextField value={transfer?.bankAccountId} label={t('activityTable.bankAccountId')} onChange={(e: any) => setTransfer({ ...transfer, bankAccountId: e.target.value })} fullWidth></TextField>
        </ListItem>
      )}
    </FormModal>
  );
};

export default UpdateTransfer;
