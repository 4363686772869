interface FormProps {
  children?: React.ReactNode[] | React.ReactNode,
  onSubmit?: any,
  testId?: string,
}

export const Form = ({
  children, onSubmit, testId,
}: FormProps) => (
  <form
    data-testid = {testId ?? 'form'}
    onSubmit={async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      await onSubmit();
    }}
  >
    {children}
  </form>
);

export default Form;
