import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import {
  Add, DragIndicator, MoreVert, OpenInNew,
} from '@mui/icons-material';
import {
  Badge,
  Box, Button, Card, CardContent,
  Grid,
  IconButton,
  MenuItem,
  SelectField,
  TextField,
  Typography,
} from '../../..';
import { reorderColumns } from '../utils';
import { StepEditor } from './stepEditor';
import { translateBackend } from '../../../../assets/i18n/config';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import BuildEventTriggerRules from './buildEventTriggerRules';
import { Step, Workflow } from '../../../../interfaces';
import { BetaBadge } from '../../../3-pattern';

export const ComplexWorkflowEditor = ({
  columns, setColumns, workflow, setWorkflow, update, eventTypes, handleMenuClick,
}: {
  columns: Step[], setColumns: (c: Step[]) => void, workflow: Workflow, setWorkflow: (w: Workflow) => void, update: () => void, eventTypes: any, handleMenuClick: (e: any) => any,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'orgSettings']);
  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<Step>({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [newChanges, setNewChanges] = useState(false);
  const { sys } = useThemeTokens();

  const onDragEnd = (result: any) => {
    const newColumns = reorderColumns(result, columns);
    setColumns(newColumns);
    setNewChanges(true);
  };

  const updateStep = (step: any) => {
    const newColumns = [...columns];
    newColumns[activeIndex] = step;
    setColumns(newColumns);
    setNewChanges(true);
  };

  const updateWorkflow = (updates: any) => {
    setWorkflow(updates);
    setNewChanges(true);
  };

  const stateColor = () => {
    switch (workflow.state) {
      case ('DRAFT'):
        return 'warning';
      case ('ARCHIVED'):
        return 'negative';
      default:
        return 'positive';
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingMedium'>{t('editWorkflow')}</Typography>
          <BetaBadge />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center' mb={2}>
              <Typography variant='headingSmall'>{t('Settings')}</Typography>
                <Badge label={t(`orgSettings:workflowStates.${workflow.state}`)} color={stateColor()}/>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='end' width='100%' gap={2}>
              <TextField label={t('workflowName')} sx={{ width: '100%' }} fullWidth value={workflow.name?.en ?? ''} onChange={(e: any) => {
                updateWorkflow({ ...workflow, name: { en: e.target.value, fr: e.target.value } });
              }} />
              <SelectField label={t('trigger')} sx={{ width: '100%' }} fullWidth value={workflow.triggerType || ''} onChange={(e: any) => {
                updateWorkflow({ ...workflow, triggerType: e.target.value });
              }}>
                <MenuItem value='MANUAL'>{t('triggerType.MANUAL')}</MenuItem>
                <MenuItem value='EVENT'>{t('triggerType.EVENT')}</MenuItem>
              </SelectField>
              {workflow.triggerType === 'EVENT' && (
                <SelectField label={t('event')} sx={{ width: '100%' }} fullWidth value={workflow.eventType || ''} onChange={(e: any) => {
                  updateWorkflow({ ...workflow, eventType: e.target.value });
                }}>
                  {eventTypes.USER.map((type: string) => (
                    <MenuItem key={type} value={type}>{t(`eventType.${type}`)}</MenuItem>
                  ))}
                </SelectField>
              )}
              {workflow.triggerType === 'EVENT' && (<BuildEventTriggerRules workflow={workflow} setWorkflow={updateWorkflow} card={false} />)}
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' gap={2} width='100%'>
                <Box>
                  {newChanges && <Badge label={t('unsavedChanges')} color='warning' />}
                </Box>
                <Box display='flex' gap={2}>
                  <IconButton
                    onClick={handleMenuClick}
                    title='Workflow Menu'
                    aria-label='workflow-menu'
                  >
                    <MoreVert />
                  </IconButton>
                  <Button label={t('saveWorkflow')} onClick={() => {
                    update();
                    setNewChanges(false);
                  }} />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={7}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Card>
            <CardContent>
              <Typography variant='headingSmall' sx={{ mb: 2 }}>{t('sections')}</Typography>
              <Box display='flex' flexDirection='column' overflow='auto'>
                <Droppable droppableId='steps'>
                  {(provided: DroppableProvided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {columns.map((column: any, index: number) => (
                        <Draggable key={column.id} draggableId={column.id} index={index}>
                          {(dragProvided: any) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <Card
                                key={column.id}
                                sx={{ mb: 2 }}
                                variant='outlined'
                              >
                                <CardContent sx={{ paddingBottom: '16px !important' }}>
                                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Box display='flex' justifyContent='start' gap={1} alignItems='center'>
                                      <DragIndicator sx={{ color: sys.color.onSurfaceVariant }} />
                                      <Typography variant='headingSmall'>{translateBackend(column.name)}</Typography>
                                    </Box>
                                    <IconButton onClick={() => {
                                      setActiveStep(column);
                                      setActiveIndex(index);
                                      setOpen(true);
                                    }}>
                                      <OpenInNew />
                                    </IconButton>
                                  </Box>
                                </CardContent>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                { activeStep?.name && (
                  <StepEditor
                    open={open}
                    setOpen={setOpen}
                    step={activeStep}
                    updateStep={updateStep}
                    workflow={workflow}
                    removeStep={() => {
                      const newColumns = columns.filter((x: Step) => x.id !== activeStep.id);
                      setColumns(newColumns);
                      setOpen(false);
                      setNewChanges(true);
                    }}
                  />
                )}
                <Box display='flex' justifyContent='end'>
                  <Button label={t('addSection')} leadingIcon={Add} variant='tonal' onClick={() => {
                    const newColumns = [...columns, {
                      id: `step-${Math.random()}`,
                      name: { en: 'New Section', fr: 'Nouvelle Section' },
                      subSteps: [],
                    }];
                    setColumns(newColumns);
                    setNewChanges(true);
                  }} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};
