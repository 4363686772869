import {
  Button, Dialog, DialogTitle, IconButton,
  InputAdornment, List, ListItem, SxProps, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import { useState } from 'react';
import { TranslatedString } from '../../interfaces';
import OvTextField from '../fields/ovTextField';

const TranslatableString = ({
  onChange, value, label, rows = 1, readonly = false, required = false, sx,
}: {
  onChange: ((e: any) => void),
  value?: TranslatedString,
  label: string,
  rows?: number,
  readonly?: boolean,
  required?: boolean,
  sx?: SxProps,
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);

  if (!value) return <></>;

  return (
    <>
      <OvTextField
        label={label}
        fullWidth
        value={value?.en ?? ''}
        onChange={(e) => onChange({ ...value, en: e.target.value })}
        multiline={rows > 1}
        rows={rows > 1 ? rows : undefined}
        required={required}
        InputProps={{
          readOnly: readonly,
          style: sx ?? undefined,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={() => setOpen(!open)}
                edge="end"
              >
                <TranslateIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{t('translate')} - {label}</DialogTitle>
          <List>
            <ListItem>
              <TextField
                fullWidth
                value={value?.en}
                label={t('languages.english')}
                onChange={(e) => onChange({ ...value, en: e.target.value })}
                multiline={rows > 1}
                rows={rows > 1 ? rows : undefined}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                fullWidth
                value={value?.fr}
                label={t('languages.french')}
                onChange={(e) => onChange({ ...value, fr: e.target.value })}
                multiline={rows > 1}
                rows={rows > 1 ? rows : undefined}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </ListItem>
            <ListItem>
              <Button fullWidth variant='contained' onClick={() => setOpen(false)}>
                { t('done') }
              </Button>
            </ListItem>
          </List>
      </Dialog>
    </>
  );
};

export default TranslatableString;
