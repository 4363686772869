import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useContext } from 'react';
import { UserContext } from '../../../../../providers/userContextProvider';
import { Grid } from '../../../../1-primative';
import { Tooltip } from '../../../../2-component';
import { formatMoneyValue } from '../../../../../util';

const ChangeTooltip = ({
  change, original, total = false, flip = false, isSellAll = false,
}: {
  change: number, original: number, total?:boolean, flip?: boolean, isSellAll?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);

  const isPositive = () => {
    if (change > 0 && !flip) {
      return true;
    }
    if (change > 0 && flip) {
      return false;
    }
    if (change < 0 && !flip) {
      return false;
    }
    return true;
  };

  const getTooltipText = () => {
    const buyOrSell = isPositive() ? 'BUY' : 'SELL';
    const pendingText = activeOrganization?.allowPendingTransactions ? ' pending reconciliation' : '';
    return `${isSellAll ? 'SELL ALL\n' : ''}ORIGINAL: ${formatMoneyValue(original)}\n${
      total ? 'TOTAL ' : ''
    }${buyOrSell}${pendingText}: ${formatMoneyValue(Math.abs(change))}`;
  };

  return (
    <>
      { change !== 0 ? (
        <Grid item>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
              {getTooltipText()}
              </div>
            }
          >
            {change > 0 ? (
              <ArrowUpwardIcon color='success' sx={{ fontSize: '14px' }} />
            ) : (
              <ArrowDownwardIcon color='error' sx={{ fontSize: '14px' }} />
            )}
          </Tooltip>
        </Grid>
      ) : (
        <FiberManualRecordIcon color='disabled' sx={{ fontSize: '14px' }} />
      )}
    </>
  );
};

export default ChangeTooltip;
