import { useContext, useState } from 'react';
import { useClientContext } from 'pages/client';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import {
  Account,
  AccountStates,
  CreateGoalInput,
  DraftGoal,
  FinancialProduct,
  FinancialProductState,
  Goal as GoalInterface,
  GoalRiskLevels,
  GoalStates,
  GoalTimeHorizons,
  GoalTypes,
  Theme,
  User,
} from 'interfaces';
import { UserContext } from 'providers/userContextProvider';
import { GoalsVisual } from './goals.visual';
import { GoalScope } from './interfaces';

const CREATE_GOAL = gql`
  mutation createGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      goal {
        id
        name
      }
    }
  }
`;

const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        goal {
          id
          name
        }
        account {
          id
          type
        }
      }
    }
  }
`;

const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        user { id }
        name
        type
        state
        targetAmountCents
        taxOptimized
        timeHorizon
        riskQuestion1
        householdClientGroup {
          id
          name
        }
        financialProduct {
          name
          theme { name }
        }
      }
    }
  }
`;

const CALCULATE_GOAL_SUITABILITY_SCORE = gql`
  query calculateGoalSuitabilityScore($input: CalculateGoalSuitabilityScoreInput!) {
    calculateGoalSuitabilityScore(input: $input)
  }
`;

const FETCH_PORTFOLIO_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        name
      }
    }
  }
`;

const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        name
        theme { name }
      }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        middleName
        lastName
        entityName
        suitabilityScore
        households {
          id
          name
          relationships {
            type
            user {
              id
              firstName
              suitabilityScore
            }
          }
        }
      }
    }
  }
`;

const FETCH_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        type
        custodianAccountNumber
      }
    }
  }
`;

export const Goals = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const organizationId = activeOrganization.id ?? clientContext?.orgSettings.id;
  const [goals, setGoals] = useState<GoalInterface[]>([]);
  const [draftGoals, setDraftGoals] = useState<DraftGoal[]>([]);
  const generateDraftGoal = (): DraftGoal => ({
    userId,
    type: GoalTypes.BUILD_WEALTH,
    riskQuestion1: GoalRiskLevels.LEVEL_3,
    timeHorizon: GoalTimeHorizons.MID_TERM,
  });

  const [calculateGoalSuitabilityScore] = useLazyQuery(CALCULATE_GOAL_SUITABILITY_SCORE, {
    fetchPolicy: 'no-cache',
  });

  const calculateGoalSuitabilityScoreFn = (draftGoal: CreateGoalInput): Promise<number> => new Promise<number>((resolve) => {
    calculateGoalSuitabilityScore({
      variables: {
        input: {
          timeHorizon: draftGoal.timeHorizon,
          riskQuestion1: draftGoal.riskQuestion1,
        },
      },
      onCompleted: (data) => resolve(data.calculateGoalSuitabilityScore),
      onError: () => resolve(0),
    });
  });

  const [fetchPortfolioThemes] = useLazyQuery(FETCH_PORTFOLIO_THEMES);
  const fetchPortfolioThemesFn = (): Promise<Theme[]> => new Promise<Theme[]>((resolve) => {
    fetchPortfolioThemes({
      variables: {
        input: {
          filter: { organizationId },
          pagination: { perPage: 1000 },
        },
      },
      onCompleted: (data) => resolve(data.fetchThemes.themes),
    });
  });

  const [fetchModelPortfolios] = useLazyQuery(FETCH_MODEL_PORTFOLIOS);
  const fetchModelPortfoliosFn = (themeId:string): Promise<FinancialProduct[]> => new Promise<FinancialProduct[]>((resolve) => {
    fetchModelPortfolios({
      variables: {
        input: {
          filter: {
            organizationId,
            themeId,
            state: FinancialProductState.ACTIVE,
          },
          pagination: { perPage: 1000 },
        },
      },
      onCompleted: (data) => resolve(data.fetchModelPortfolios.modelPortfolios),
    });
  });

  const [fetchUser] = useLazyQuery(FETCH_USER);
  const fetchUserFn = (): Promise<User> => new Promise<User>((resolve) => {
    fetchUser({
      variables: { userId },
      onCompleted: (data) => resolve(data.fetchUser.user),
    });
  });

  const { loading: goalsLoading } = useQuery(FETCH_GOALS, {
    variables: {
      input: {
        filter: {
          userId,
          state: GoalStates.ACTIVE,
        },
        pagination: { perPage: 1000 },
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: ((goalsData) => {
      setGoals(goalsData.fetchGoals.goals);
    }),
  });

  const [fetchAccounts] = useLazyQuery(FETCH_ACCOUNTS);
  const fetchAccountsFn = (goalScope: GoalScope, objectId: string): Promise<Account[]> => new Promise<Account[]>((resolve) => {
    fetchAccounts({
      variables: {
        input: {
          filter: {
            ...(goalScope === GoalScope.INDIVIDUAL && { userId: objectId }),
            ...(goalScope === GoalScope.HOUSEHOLD && { clientGroupId: objectId }),
            states: Object.values(AccountStates).filter((state) => ![
              AccountStates.FAILED,
              AccountStates.INACTIVE,
              AccountStates.CANCELED,
            ].includes(state)),
          },
        },
        pagination: {
          perPage: 1000,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => resolve(data.fetchAccounts.accounts),
    });
  });

  const [createGoal, {
    loading: createGoalLoading,
  }] = useMutation(CREATE_GOAL, { refetchQueries: [FETCH_GOALS] });

  const [createSubAccount, {
    loading: createSubAccountLoading,
  }] = useMutation(CREATE_SUB_ACCOUNT);

  const createSubAccountsforGoal = async (goalId:string, accounts: Account[]) => {
    if (accounts?.length ?? 0) {
      await Promise.all(accounts.map(async (account) => {
        const createSubAccountResponse = await createSubAccount({
          variables: {
            input: {
              userId,
              goalId,
              accountId: account.id,
              accountType: account.type,
            },
          },
        });
        return createSubAccountResponse;
      }));
    }
  };

  const handleGoalsCreation = async () => {
    if (draftGoals.length === 0) return;
    await Promise.all(draftGoals.map(async (draftGoal) => {
      let response = { goal: draftGoal, status: true };
      const createGoalResponse = await createGoal({
        variables: {
          input: {
            userId: draftGoal.userId,
            type: draftGoal.type,
            riskQuestion1: draftGoal.riskQuestion1,
            timeHorizon: draftGoal.timeHorizon,
            financialProductId: draftGoal?.financialProduct?.id,
            householdClientGroupId: draftGoal?.householdClientGroup?.id,
            name: draftGoal.name,
            targetAmountCents: draftGoal.targetAmountCents,
            taxOptimized: draftGoal.taxOptimized,
          },
        },
        onError: (error) => {
          response = { ...response, status: false };
        },
        onCompleted: async (data) => {
          const goalCreated = data.createGoal.goal;
          if (draftGoal?.accountsLinked !== undefined) {
            createSubAccountsforGoal(goalCreated.id, draftGoal.accountsLinked);
          }
        },
      });
      return { ...response, goal: createGoalResponse.data.createGoal.goal };
    }));
    onNext();
  };

  const loading = stepLoading || goalsLoading || createGoalLoading || createSubAccountLoading;
  return (
    <>
    {
      !loading && <GoalsVisual
        options={options}
        goals={goals}
        draftGoals={draftGoals}
        updateDraftGoals={setDraftGoals}
        loading={loading}
        continueFunc={handleGoalsCreation}
        generateDraftGoal={generateDraftGoal}
        calculateGoalSuitabilityScoreFn={calculateGoalSuitabilityScoreFn}
        fetchUserFn={fetchUserFn}
        fetchAccountsFn={fetchAccountsFn}
        fetchPortfolioThemesFn={fetchPortfolioThemesFn}
        fetchModelPortfoliosFn={fetchModelPortfoliosFn}
      />
    }
    </>
  );
};
