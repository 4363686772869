import { gql } from '@apollo/client';

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($transactionId: ObjectID!) {
    deleteTransaction(transactionId: $transactionId) {
      transaction {
        id
      }
    }
  }
`;

export const FETCH_TRANSACTIONS = (permissions: string[]) => gql`
  query fetchTransactions($input: FetchTransactionsInput!) {
  fetchTransactions(input: $input) {
      totalCount
      transactions {
        id
        type
        subAccount {
          id
          goal {
            id
            name
          }
          account {
            id
            type
            householdClientGroup {
              id
            }
          }
        }
        financialProduct { id ticker }
        date
        quantity
        priceCents
        valueCents
        currency
        valueCents
        description
        settleDate
        settleDateToWithdraw
        goal { id }
        organization { id }
        objectType
        objectId
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
        }
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT = (permissions: string[]) => gql`
  query fetchSubAccount($id: ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        id
        name
        state
        liquidated
        createdAt
        tradingProcess
        calculatedSuitabilityScore
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            organization {
              id
            }
            statistics {
              marketValueCents
            }
            subAccounts {
              id
              account {
                type
              }
              goal {
                name
              }
              financialProduct {
                translatedName {
                  en
                }
              }
            }
          }
        }
        goal {
          id
          name
          financialProduct {
            id
            translatedName {
              en
            }
            theme {
              id
              translatedName {
                en
              }
            }
          }
        }
        skipIPS
        allowClientDeposits
        isPartial
        theme {
          id
          translatedName {
            en
          }
        }
        financialProduct {
          id
          translatedName {
            en
          }
          riskReturnLevel
        }
        suggestedFinancialProduct {
          id
        }
        ${permissions.includes('read:billing_schedule') ? 'applicableBillingSchedule { id frequency }' : ''}
        ${permissions.includes('read:fee_tier') ? 'applicableFeeTier { id name }' : ''}
        ${permissions.includes('read:fee_tier') ? 'feeTier { id name }' : ''}
        ${permissions.includes('read:billing_schedule') ? 'billingSchedule { id frequency }' : ''}
        statistics {
          marketValueCents
          simpleReturnAmount
          simpleReturnPercent
        }
      }
    }
  }
`;

export const FETCH_FINANCIAL_PRODUCT = gql`
  query fetchFinancialProduct($input: FetchFinancialProductInput!) {
    fetchFinancialProduct(input: $input) {
      financialProduct {
        isPartial
        currentPriceCents
        priceHistoryUrl
      }
    }
  }
`;
