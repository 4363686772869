import {
  Typography, Table, TableHead, TableRow, TableCell, TableBody, Chip, IconButton, Menu, MenuItem, Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { ObjectType } from '../../providers/statsHooks';
import { usePermissions } from '../../providers/userContextProvider';
import { formatMoneyValue } from '../../util';
import EditSubAccount from '../../pages/client/components/editSubAccount';

enum SubAccountStates {
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

const TRANSITION_SUB_ACCOUNT = gql`
  mutation transitionSubAccount($input: TransitionSubAccountInput!) {
    transitionSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const SubAccountsTable = ({
  subAccounts, setObject, object, firstColumn, refetch = () => { }, actionElement,
}: {
  subAccounts: any[], setObject: any, object: any, firstColumn: 'account' | 'goal', refetch?: () => void, actionElement?: JSX.Element,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('components');
  const [activeSubAccount, setActiveSubAccount] = useState<any>({});
  const [editOpen, setEditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const [transitionSubAccount] = useMutation(TRANSITION_SUB_ACCOUNT);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const state = (subAccount: any) => {
    if (subAccount.pendingWithdrawAll?.id) {
      return 'WITHDRAW_ALL';
    }

    return subAccount.state;
  };

  const transitions = [
    {
      name: 'activate',
      from: [SubAccountStates.AWAITING_APPROVAL],
    },
    {
      name: 'review',
      from: [SubAccountStates.ACTIVE],
    },
    {
      name: 'deactivate',
      from: [SubAccountStates.ACTIVE, SubAccountStates.AWAITING_APPROVAL],
    },
  ];

  return (
    <Grid container sx={{ overflow: 'hidden' }}>
      <Grid item xs={12} sx={{ overflow: 'auto' }}>

        <Table aria-label="table">
          <TableHead>
            <TableRow>
              {firstColumn === 'account' ? (
                <TableCell><Typography variant='overline'>{t('subAccountTable.account')}</Typography></TableCell>
              ) : (
                <TableCell><Typography variant='overline'>{t('subAccountTable.goal')}</Typography></TableCell>
              )}
              <TableCell><Typography variant='overline'>{t('subAccountTable.theme')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.suggestedPortfolio')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.portfolio')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.requireIPS')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.allowClientDeposits')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.allowFractionalShares')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('subAccountTable.state')}</Typography></TableCell>
              <TableCell align='right'><Typography variant='overline'>{t('subAccountTable.assets')}</Typography></TableCell>
              <TableCell>
                {actionElement || (<Typography variant='overline'>{t('subAccountTable.actions')}</Typography>)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subAccounts.filter((x: any) => x.state !== 'INACTIVE').map((subAccount: any) => (
              <TableRow key={subAccount.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                setObject({ type: ObjectType.SUB_ACCOUNT, id: subAccount.id });
              }} selected={object.id === subAccount.id}>
                {firstColumn === 'account' ? (
                  <TableCell><Chip size='small' label={t(`accountTypes:${subAccount.account.type}`)} /></TableCell>
                ) : (
                  <TableCell>{subAccount.goal?.name && subAccount.goal?.name[0] !== '6' ? subAccount.goal?.name : t(`goalType.${subAccount.goal?.type}`)}</TableCell>
                )}
                <TableCell>{subAccount.theme?.translatedName?.en || t('none')}</TableCell>
                <TableCell>{subAccount.suggestedFinancialProduct?.translatedName?.en}</TableCell>
                <TableCell>{subAccount.financialProduct?.translatedName?.en}</TableCell>
                <TableCell>{!subAccount.skipIPS ? t('Yes') : t('No')}</TableCell>
                <TableCell>{subAccount.allowClientDeposits ? t('True') : t('False')}</TableCell>
                <TableCell>{subAccount.isPartial ? t('Yes') : t('No')}</TableCell>
                <TableCell><Chip size='small' color={state(subAccount) === 'ACTIVE' ? 'success' : 'warning'} label={t(`subAccountState.${state(subAccount)}`)} /></TableCell>
                <TableCell align='right'>{formatMoneyValue(subAccount.stats.marketValueCents)}</TableCell>
                {permissions.includes('transition:account') && (
                  <TableCell align='right'><IconButton size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveSubAccount(subAccount);
                      handleClick(e);
                    }}
                  ><MoreVertIcon /></IconButton></TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Typography variant='caption' sx={{ p: 2 }}>{activeSubAccount.id}</Typography>
            <MenuItem onClick={() => setEditOpen(true)}>{t('edit')}</MenuItem>
            {transitions.map((transition: any) => (
              transition.from.includes(activeSubAccount.state) && (
                <MenuItem key={transition.name} onClick={async (e) => {
                  await transitionSubAccount({ variables: { input: { subAccountId: activeSubAccount.id, transition: transition.name } } });
                  refetch();
                  handleClose();
                }}>
                  {t(`subAccountTransitions.${transition.name}`)}
                </MenuItem>
              )
            ))}
          </Menu>
          <EditSubAccount afterUpdate={() => {
            setEditOpen(false);
            refetch();
          }} subAccountToUpdate={activeSubAccount} open={editOpen} handleClose={() => setEditOpen(false)} />
        </Table>
      </Grid>
    </Grid>
  );
};

export default SubAccountsTable;
