import { Paper } from '@mui/material';
import BulkTradersTable from './components/bulkTradersTable';

const BulkTraders = () => (
    <Paper sx={{ width: '100%' }}>
      <BulkTradersTable />
    </Paper>
);

export default BulkTraders;
