import {
  Box,
  Button, CircularProgress, ListItem, Typography,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ReturnPathContext } from 'components/wizards/wizardBase';
import { getSubAccountName } from 'interfaces/subAccount';
import { colors } from '../../../../theme/colors';
import { StepProps } from '../wizard';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subHeader: {
    maxWidth: '285px',
    textAlign: 'center',
  },
  divider: {
    color: colors.neutral80,
    width: '100%',
  },
  loader: {
    marginTop: '6px',
    float: 'right',
  },
  icon: {
    width: '64px',
    height: '64px',
  },
};
type ActionStepProps = {
  loading: boolean,
  succeeded: boolean,
  error: ApolloError | undefined,
  newSubAccount: any,
};

export function SubAccountSummaryActionStepResult({
  state, context, continueTo,
  loading, succeeded, error, newSubAccount,
}: Omit<StepProps, 'setState' | 'mutationEvent'> & ActionStepProps) {
  const { t } = useTranslation('createSubAccountWizard');
  const navigate = useNavigate();
  const returnPath = useContext(ReturnPathContext);

  const generateThemeName = () => {
    let derivedName = newSubAccount?.goal?.financialProduct?.theme?.translatedName?.en || '';
    if (newSubAccount?.goal?.financialProduct?.theme?.translatedName?.en) {
      derivedName = newSubAccount?.goal?.financialProduct?.theme?.translatedName?.en;
    } else {
      derivedName = newSubAccount?.theme?.translatedName?.en;
    }
    return derivedName;
  };
  const generatePortfolioName = (): string => {
    if (newSubAccount?.financialProduct?.translatedName?.en) return newSubAccount?.financialProduct?.translatedName?.en;
    return newSubAccount?.goal?.financialProduct?.translatedName?.en || '';
  };
  return (
    <>
      <ListItem sx={styles.center}>
        {loading && <CircularProgress size='30px' sx={styles.loader} />}
        {succeeded && <CheckCircleOutlineIcon sx={{ ...styles.icon, color: colors.success }} />}
        {error && <HighlightOffIcon sx={{ ...styles.icon, color: colors.error }} />}
      </ListItem>
      <ListItem sx={styles.center}>
        {succeeded && <Typography variant='h5' sx={styles.subHeader}>
          {t('subAccountSummary.added')}</Typography>
        }
        {error && <Typography variant='h5' sx={{ ...styles.subHeader, color: colors.error }}>
          {t('subAccountSummary.failed')}
        </Typography>}
      </ListItem>
      <Box sx={{ minHeight: '300px' }}>
        <ListItem>
          <hr style={styles.divider} />
        </ListItem>
        <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.name')}</Typography>
        <Typography variant='body2' fontWeight={700}>{getSubAccountName(newSubAccount, false)}</Typography>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.portfolioTheme')}</Typography>
        <Typography variant='body2' fontWeight={700}>{generateThemeName()}</Typography>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.Portfolio')}</Typography>
        <Typography variant='body2' fontWeight={700}>{generatePortfolioName()}</Typography>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.requireIPS')}</Typography>
        <Typography variant='body2' fontWeight={700}>{(newSubAccount?.skipIPS) ? 'No' : 'Yes' }</Typography>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.allowClientDeposit')}</Typography>
        <Typography variant='body2' fontWeight={700}>{(newSubAccount?.allowClientDeposits) ? 'Yes' : 'No' }</Typography>
      </ListItem>
      <ListItem sx={styles.listItem}>
        <Typography variant='body2'>{t('subAccountSummary.allowFractionalShare')}</Typography>
        <Typography variant='body2' fontWeight={700}>{(newSubAccount?.isPartial) ? 'Yes' : 'No'}</Typography>
      </ListItem>
      </Box>
      <ListItem>
        {returnPath ? (
          <Button fullWidth variant='text' onClick={() => returnPath.action(newSubAccount.id)} >
            {returnPath.callToAction}
          </Button>
        ) : (
          <Button fullWidth variant='text' data-testid='confirmButton' onClick={() => navigate(`subaccount/${newSubAccount.id}`)} >
            {t('actions.goToSubaccount')}
          </Button>
        )}
      </ListItem>
    </>
  );
}
