/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters } from '../../../ovComponents/3-pattern';
import { UserContext } from '../../../providers/userContextProvider';
import { FETCH_FLAGS_QUERY } from './flags';
import { FETCH_CUSTODIAN_ACTIVITIES_QUERY } from './activities';

const DownloadReconciliation = ({ tab, queryFilter }: { tab: 'flags' | 'activities'; queryFilter: any }) => {
  const { t } = useTranslation(['reconciliation']);
  const { activeOrganization } = useContext(UserContext);

  const flagsColumns: Column[] = [
    { gqlAlias: 'account.user.id', header: t('table.client') },
    { gqlAlias: 'account.user.firstName', header: t('table.userFirstName') },
    { gqlAlias: 'account.user.lastName', header: t('table.userLastName') },
    { gqlAlias: 'type', header: t('table.type') },
    { gqlAlias: 'message', header: t('table.message') },
    { gqlAlias: 'createdAt', header: t('table.createdAt') },
    { gqlAlias: 'state', header: t('table.state') },
  ];

  const additionalFlagsColumns: Column[] = [
    { gqlAlias: 'organization.id', header: t('table.organization') },
    { gqlAlias: 'objectType', header: t('table.objectType') },
  ];

  const activitiesColumns = [
    { gqlAlias: 'account.user.id', header: t('table.client') },
    { gqlAlias: 'account.user.firstName', header: t('table.userFirstName') },
    { gqlAlias: 'account.user.lastName', header: t('table.userLastName') },
    { gqlAlias: 'processDate', header: t('table.date') },
    { gqlAlias: 'transactionType', header: t('table.type') },
    { gqlAlias: 'amountValueCents', header: t('table.amount'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'account.type', header: t('table.accountType') },
    { gqlAlias: 'symbol', header: t('table.ticker') },
    { gqlAlias: 'quantity', header: t('table.quantity') },
    { gqlAlias: 'priceCents', header: t('table.price'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'state', header: t('table.state') },
  ];

  const additionalActivitiesColumns: Column[] = [
    { gqlAlias: 'processDate', header: t('table.processDate') },
    { gqlAlias: 'currency', header: t('table.currency') },
    { gqlAlias: 'effectiveDate', header: t('table.effectiveDate') },
    { gqlAlias: 'settleDate', header: t('table.settleDate') },
    { gqlAlias: 'custodianAccountNumber', header: t('table.custodianAccountNumber') },
    { gqlAlias: 'clientName', header: t('table.clientName') },
    { gqlAlias: 'transactionId', header: t('table.transaction') },
    { gqlAlias: 'subTransactionId', header: t('table.subTransaction') },
    { gqlAlias: 'cusip', header: t('table.cusip') },
    { gqlAlias: 'transactionDesc', header: t('table.transactionDesc') },
    { gqlAlias: 'transactionType', header: t('table.transactionType') },
    { gqlAlias: 'commission', header: t('table.commission') },
    { gqlAlias: 'accruedInterest', header: t('table.accruedInterest') },
    { gqlAlias: 'securityType', header: t('table.securityType') },
    { gqlAlias: 'account.organization.id', header: t('table.organization') },
    { gqlAlias: 'account.organization.custodian.id', header: t('table.custodian') },
  ];

  const config: Record<string, DownloadButtonProps> = {
    flags: {
      gql: FETCH_FLAGS_QUERY,
      fileName: `${t('tabs.flags')}_${dayjs().format('YYYY-MM-DD')}`,
      filter: { organizationId: activeOrganization.id },
      additionalFilter: queryFilter,
      queryPath: 'fetchFlags',
      datasetPath: 'flags',
      columns: flagsColumns,
      additionalColumns: additionalFlagsColumns,
      sortField: 'createdAt',
      sortDesc: true,
      progressTitle: t('exportProgressTitleFlags'),
    },
    activities: {
      gql: FETCH_CUSTODIAN_ACTIVITIES_QUERY,
      fileName: `${t('tabs.activities')}_${dayjs().format('YYYY-MM-DD')}`,
      filter: { organizationId: activeOrganization.id },
      additionalFilter: queryFilter,
      queryPath: 'fetchCustodianActivities',
      datasetPath: 'custodianActivities',
      columns: activitiesColumns,
      additionalColumns: additionalActivitiesColumns,
      sortField: 'createdAt',
      sortDesc: true,
      progressTitle: t('exportProgressTitleActivities'),
    },
  };

  return (
    <DownloadButton
      configurable
      gql={config[tab].gql}
      fileName={config[tab].fileName}
      filter={config[tab].filter}
      additionalFilter={config[tab].additionalFilter}
      queryPath={config[tab].queryPath}
      datasetPath={config[tab].datasetPath}
      columns={config[tab].columns}
      additionalColumns={config[tab].additionalColumns}
      sortField={config[tab].sortField}
      sortDesc={config[tab].sortDesc}
      progressTitle={config[tab].progressTitle}
    />
  );
};

export default DownloadReconciliation;
