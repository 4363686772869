/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect } from 'react';
import { usePageState } from 'util/usePageState';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { ActiveWorkflowsVisual } from './activeWorkflows.visual';
import { ActionContext } from '../../5-page/viewPage/viewPage';
import { SubStepTypes, WorkflowCompletion } from '../../../interfaces';

export const ActiveWorkflows = ({ userId, activeWorkflowCompletionId, refetchObject }: { userId: string, activeWorkflowCompletionId?: string, refetchObject?: () => void }) => {
  const [params, setSearchParams] = useSearchParams();
  const [workflowCompletionId, setWorkflowCompletionId] = usePageState<string | undefined>(undefined, 'workflow');
  const {
    activeWorkflows,
    unsignedDocuments, refetch,
    refreshWidgets,
  } = useContext(ActionContext);

  const percentage = (wc: WorkflowCompletion) => {
    if (wc.workflow?.complex) {
      const index = wc.workflow?.steps?.findIndex((step: any) => step.id === wc.currentStep?.id) ?? 0;
      return (index / (wc.workflow?.steps?.length ?? 1)) * 100;
    }
    const allSubSteps = wc.workflow?.steps?.reduce((acc: any[], step: any) => [...acc, ...step.subSteps], []) ?? [];
    const index = allSubSteps.findIndex((subStep: any) => subStep.id === wc.currentSubStep?.id);
    return (index / allSubSteps.length) * 100;
  };

  const upcomingDocumentStepInActiveWorkflows = !!activeWorkflows.find((w) => {
    const currentStepIndex = w?.workflow?.steps?.findIndex((s) => s.id === w.currentStep?.id);
    if (!isNil(currentStepIndex)) {
      const currentSubStepIndex = w?.workflow?.steps?.[currentStepIndex]?.subSteps?.findIndex((s) => s.id === w.currentSubStep?.id);
      if (!isNil(currentSubStepIndex)) {
        return !!w?.workflow?.steps?.find((step, i) => (i >= currentStepIndex && (
          (i === currentStepIndex && step.subSteps?.find((subStep, si) => si >= currentSubStepIndex && subStep.type === SubStepTypes.DOCUMENTS))
          || (i > currentStepIndex && step.subSteps?.find((subStep) => subStep.type === SubStepTypes.DOCUMENTS))
        )));
      }
    }
    return false;
  });

  useEffect(() => {
    if (params.get('workflowCompletion')) {
      setWorkflowCompletionId(params.get('workflowCompletion')!);
      params.delete('workflowCompletion');
      // setSearchParams(params);
    } else if (params.get('autoOpenWorkflow') && activeWorkflows && activeWorkflows.length === 1) {
      params.delete('autoOpenWorkflow');
      setWorkflowCompletionId(activeWorkflows[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, setSearchParams, activeWorkflows]);

  useEffect(() => {
    if (activeWorkflowCompletionId) {
      refetch();
      setWorkflowCompletionId(activeWorkflowCompletionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkflowCompletionId]);

  return (
    <ActiveWorkflowsVisual
      refetch={() => {
        refetch();
        if (refetchObject) {
          refetchObject();
        } else if (refreshWidgets) refreshWidgets();
        setWorkflowCompletionId(undefined);
        setSearchParams(params, { replace: true });
      }}
      userId={userId}
      incompleteDocuments={unsignedDocuments && !upcomingDocumentStepInActiveWorkflows}
      activeWorkflowCompletionId={workflowCompletionId}
      setActiveWorkflowCompletionId={setWorkflowCompletionId}
      activeWorkflows={activeWorkflows.map((activeWorkflow: any) => ({
        name: activeWorkflow.workflow.name.en,
        id: activeWorkflow.id,
        percentage: percentage(activeWorkflow),
        workflowCompletion: activeWorkflow,
        currentSubStep: activeWorkflow.currentSubStep,
        currentStep: activeWorkflow.currentStep,
      }))}
    />
  );
};
