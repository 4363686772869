/* eslint-disable max-len */
import { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  List, ListItem, MenuItem, TableCellProps,
} from '@mui/material';
import { UserContext } from 'providers/userContextProvider';
import {
  getAccountTypes,
  Account,
  AccountStates,
  AccountTypes,
  Affiliation,
  User,
} from 'interfaces';
import { generateClientNameString } from 'util/index';
import { Grid, Box, Typography } from 'ovComponents/1-primative';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Form,
  SelectField,
  Dialog,
  Table,
  TableBody,
  TableCell as OvTableCell,
  TableHead,
  TableHeadCell as OvTableHeadCell,
  TableRow,
} from 'ovComponents/2-component';
import { ConfirmationModal, ConfirmButtonColors } from 'ovComponents/3-pattern';
import { translateBackend } from 'assets/i18n/config';
import {
  AccountDialogContent,
  AccountReviewItem,
  CorporateCashDialogContent,
  CorporateCashReviewItem,
  JointCashDialogContent,
  JointCashReviewItem,
  JointRespFamilyDialogContent,
  JointRespFamilyReviewItem,
  JointRespSingleDialogContent,
  JointRespSingleReviewItem,
  RespAdultDialogContent,
  RespAdultReviewItem,
  RespFamilyDialogContent,
  RespFamilyReviewItem,
  RespSingleDialogContent,
  RespSingleReviewItem,
  RRSPDialogContent,
  RRSPReviewItem,
  TFSAReviewItem,
  TSFADialogContent,
} from './types';
import { IncompleteAffiliation } from './utils';
import { FHSADialogContent } from './types/fhsa/fhsa.dialogContent';
import { FHSAReviewItem } from './types/fhsa/fhsa.reviewItem';

const numIncompleteAffiliationsTableCols = 3;
const tableCellSx = { width: `${100 / numIncompleteAffiliationsTableCols}%` };
const TableCell = (props: TableCellProps) => <OvTableCell sx={tableCellSx} {...props}>{props.children}</OvTableCell>;
const TableHeadCell = (props: TableCellProps) => <OvTableHeadCell sx={tableCellSx} {...props}>{props.children}</OvTableHeadCell>;

export const AccountVisual = ({
  options,
  userData,
  isLoading,
  updateAccounts,
  accounts,
  incompleteAffiliations,
  onCreateAccounts,
  continueFunc,
  updateUser,
}: {
  options: any,
  userData: User,
  isLoading: boolean,
  accounts: Account[],
  incompleteAffiliations:{ [accountId: string]: IncompleteAffiliation[] },
  updateAccounts: (accounts: Account[]) => void,
  updateUser: (user: User) => void,
  onCreateAccounts: () => void,
  continueFunc: () => void,
}) => {
  const { t } = useTranslation(['accountTypes', 'affiliationTypes', 'client', 'workflowCompletions']);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAccountType, setSelectedAccountType] = useState<string>('');
  const { activeOrganization } = useContext(UserContext);
  const openModal = (value = true) => {
    setOpen(value);
  };

  const initDraftAccount = (accountType: string): Account => ({
    type: accountType as AccountTypes,
    applyForGovFunds: [],
    id: '',
    state: AccountStates.ACTIVE,
    user: userData,
    affiliations: [],
  });

  const handleStartAccountCreation = (accountType: string): void => {
    const AccountCreationContent = getAccountContent(accountType);
    if (AccountCreationContent) {
      openModal(true);
    } else {
      handleAccountCreated(initDraftAccount(accountType));
    }
  };

  const handleAccountCreated = (account: Account): void => {
    setSelectedAccountType('');
    updateAccounts([...accounts, account]);
    setOpen(false);
  };

  const getAccountContent = (value: string): JSX.Element | null => {
    const defaultProps = {
      userData,
      onDialogClose: () => openModal(false),
      onAccountCreated: handleAccountCreated,
      showGoalLinkingSection: options?.linkGoals,
    };

    switch (value) {
      case 'CORPORATE_CASH': {
        return (<CorporateCashDialogContent {...defaultProps} />);
      }
      case 'TFSA': {
        return (<TSFADialogContent {...defaultProps} />);
      }
      case 'FHSA': {
        return (<FHSADialogContent {...defaultProps} />);
      }
      case 'RRSP': {
        return (<RRSPDialogContent {...defaultProps} />);
      }
      case 'RESP_SINGLE': {
        return (<RespSingleDialogContent {...defaultProps} />);
      }
      case 'RESP_SINGLE_JOINT': {
        return (<JointRespSingleDialogContent {...defaultProps} />);
      }
      case 'RESP_FAMILY_JOINT': {
        return (<JointRespFamilyDialogContent {...defaultProps} />);
      }
      case 'CASH_JOINT': {
        return (<JointCashDialogContent {...defaultProps} />);
      }
      case 'RESP_FAMILY': {
        return (<RespFamilyDialogContent {...defaultProps} />);
      }
      case 'RESP_ADULT': {
        return (<RespAdultDialogContent {...{
          ...defaultProps,
          onAccountCreated: (account) => {
            handleAccountCreated(account);
            updateUser(account.user);
          },
        }}
        />);
      }
      default: {
        // Logic to determine if the minimal conditions are met to warrant
        // displaying the account creation form
        const bypassAccountCreationForm = !defaultProps.showGoalLinkingSection;
        if (bypassAccountCreationForm) return null;
        return (<AccountDialogContent {...{ ...defaultProps, accountType: (selectedAccountType as AccountTypes) }} />);
      }
    }
  };
  const deleteAccount = (index: number): void => {
    // slice not working for useState array
    const tempAccount: Account[] = [];
    accounts.forEach((item: Account, index2: number) => {
      if (index2 !== index) tempAccount.push(item);
    });
    updateAccounts([...tempAccount]);
  };
  const getUpdateAffiliationsFn = (indexAccount: number) => (
    (affiliations: Affiliation[]) => {
      const updatedAccounts = accounts.map((item: Account, index: number) => (index === indexAccount ? { ...item, affiliations } : item));
      updateAccounts(updatedAccounts);
    }
  );
  const getAccountReviewItem = (account: Account, index: number): JSX.Element => {
    const defaultProps = {
      account,
      key: index,
      onDeleteAccount: () => deleteAccount(index),
    };
    switch (account.type) {
      case 'CORPORATE_CASH': {
        return (<CorporateCashReviewItem {...{
          ...defaultProps,
          updateAffiliations: getUpdateAffiliationsFn(index),
        }}
        />);
      }
      case 'TFSA': {
        return (<TFSAReviewItem {...defaultProps} />);
      }
      case 'FHSA': {
        return (<FHSAReviewItem {...defaultProps} />);
      }
      case 'RESP_SINGLE': {
        return (<RespSingleReviewItem {...defaultProps} />);
      }
      case 'RRSP': {
        return (<RRSPReviewItem {...defaultProps} />);
      }
      case 'RESP_FAMILY': {
        return (<RespFamilyReviewItem {...defaultProps} />);
      }
      case 'RESP_ADULT': {
        return (<RespAdultReviewItem {...defaultProps} />);
      }
      case 'RESP_SINGLE_JOINT': {
        return (<JointRespSingleReviewItem {...defaultProps} />);
      }
      case 'RESP_FAMILY_JOINT': {
        return (<JointRespFamilyReviewItem {...defaultProps} />);
      }
      case 'CASH_JOINT': {
        return (<JointCashReviewItem {...defaultProps} />);
      }
      default: {
        return (<AccountReviewItem {...defaultProps} />);
      }
    }
  };

  const getIncompleteAffiliationRow = (entry: IncompleteAffiliation, index: number) => (
    <TableRow key={index}>
      <TableCell>{generateClientNameString(entry?.affiliation?.user)}</TableCell>
      <TableCell>{t(`affiliationTypes:${entry?.affiliation?.type}`)}</TableCell>
      <TableCell>
        <List>{entry.incompleteFields.map((field, index2) => <ListItem key={index2}>{t(`client:details.${field}`)}</ListItem>)}</List>
      </TableCell>
    </TableRow>
  );

  const getAccountSection = (entries: IncompleteAffiliation[]): JSX.Element => {
    const { account } = entries[0];

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant='bodyLarge' weight='bold'>{t(`accountTypes:${account.type}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>{t('workflowCompletions:baseAccountCreation.affiliationTable.nameHeader')}</TableHeadCell>
                <TableHeadCell>{t('workflowCompletions:baseAccountCreation.affiliationTable.typeHeader')}</TableHeadCell>
                <TableHeadCell>{t('workflowCompletions:baseAccountCreation.affiliationTable.requiredInfoHeader')}</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry, index) => getIncompleteAffiliationRow(entry, index))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  };

  const getConfirmationModal = (): JSX.Element => {
    const sections = (
      <Grid container gap={1}>
        <Grid item xs={12}>
          <Typography variant='bodyLarge'>{t('workflowCompletions:baseAccountCreation.incompleteAffiliationWarning.bodyText')}</Typography>
        </Grid>
        {Object.values(incompleteAffiliations).map((accountEntries) => <Grid item xs={12}>{getAccountSection(accountEntries)}</Grid>)}
      </Grid>
    );

    return (
      <ConfirmationModal
        open={true}
        onConfirm={continueFunc}
        onCancel={continueFunc}
        title={t('workflowCompletions:baseAccountCreation.incompleteAffiliationWarning.title')}
        children={sections}
        confirmButtonLabel={'Continue'}
        confirmButtonColor={ConfirmButtonColors.primary}
        showCancelButton={false}
      />
    );
  };

  // new accounts do not have an ID yet
  const accountsToBeCreated = accounts.filter((account: Account) => !account.id);

  return (
    <>
    {!isEmpty(incompleteAffiliations) && getConfirmationModal()}
    <Form onSubmit={onCreateAccounts}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {accounts.map((item: Account, index: number) => getAccountReviewItem(item, index))}
      <Grid
        container
        display='flex'
        flexDirection='row'
        sx={{ mb: 2 }}
        justifyContent='space-between'
        spacing={1}
        alignItems='end'
      >
        <Grid item xs={8}>
          <SelectField
            onChange={(e: any) => setSelectedAccountType(e.target.value)}
            label={t('workflowCompletions:accountType')}
            fullWidth
            value={selectedAccountType}
          >
            {getAccountTypes(userData.type, activeOrganization.availableFeatureFlags, activeOrganization.applicableLocalization?.countries).map((accountType) => (<MenuItem key={accountType} value={accountType}>{t(`accountTypes:${accountType}`)}</MenuItem>))}
          </SelectField>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='outlined'
            disabled={!selectedAccountType}
            onClick={() => handleStartAccountCreation(selectedAccountType)}
            label={t('workflowCompletions:start')}
            sx={{ textAlign: 'center' }}
            type='button'
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end">
        {
          accountsToBeCreated.filter((account: Account) => !account.id).length > 0 ? (
            <Button
              label={t('workflowCompletions:createAccountButtonTitle', { number: accountsToBeCreated.length })}
              sx={{
                mt: 3,
              }}
              type='submit'
            />
          ) : undefined
        }
      </Box>
      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        {getAccountContent(selectedAccountType)}
      </Dialog>
    </Form>
    </>
  );
};

export default AccountVisual;
