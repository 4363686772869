import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Paper, Typography, Stepper, Step, StepLabel, Button, CircularProgress, Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { indexOf } from 'lodash/fp';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TradesTable from '../../components/tables/tradesTable/tradesTable';
import UpdateTradesTable from './components/updateTradesTable';
import { useGlobalToast } from '../../providers/globalToastProvider';
import ConfirmationModal from '../../components/modals/confirmationModal';
import { UserContext } from '../../providers/userContextProvider';

const FETCH_REBALANCE = gql`
  query fetchRebalance($rebalanceId: ObjectID!) {
    fetchRebalance(rebalanceId: $rebalanceId) {
      rebalance {
        id
        createdAt
        buyBulkTradeFileUrl
        sellBulkTradeFileUrl
        mutualFundFileUrl
        allocationFileUrl
        ptfBulkTradeFileUrl
        state
        actualQuantitiesSet
      }
    }
  }
`;

const TRANSITION_REBALANCE = gql`
  mutation transitionRebalance($input: TransitionRebalanceInput!) {
    transitionRebalance(input: $input) {
      rebalance {
        id
        state
      }
    }
  }
`;

const states = [
  'GENERATING',
  'INITIATED',
  'BULK_TRADE_COMPLETED',
  'FIX_TRADE_INITIATED',
  'COMPLETED',
  'FIX_TRADE_COMPLETED',
];

const Rebalance = () => {
  const { t } = useTranslation('rebalance');
  const params = useParams();
  const rebalanceId = params.id;
  const [activeStep, setActiveStep] = useState(0);
  const [rebalance, setRebalance] = useState<any>({});
  const [enableFixTrading, setEnableFixTrading] = useState(false);
  const [fixTradingInitiated, setFixTradingInitated] = useState(false);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const [discrepancyConfirmationOpen, setDiscrepancyConfirmationOpen] = useState(false);
  const [buyTradesWarnings, setBuyTradesWarnings] = useState(false);
  const [sellTradesWarnings, setSellTradesWarnings] = useState(false);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const steps = [t('generating'), t('reviewTrades'), t('updateTrades'), t('completed')];

  const {
    data, loading, startPolling, refetch,
  } = useQuery(FETCH_REBALANCE, {
    variables: {
      rebalanceId,
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionRebalance, { loading: transitionLoading }] = useMutation(TRANSITION_REBALANCE);

  startPolling(5000);

  useEffect(() => {
    if (data) {
      setRebalance(data.fetchRebalance.rebalance);
      setActiveStep(indexOf(rebalance.state, states));
    }
    const isFixtradingAvailable = activeOrganization?.availableFeatureFlags?.includes('FIX_TRADING') || false;
    setEnableFixTrading(isFixtradingAvailable);
  }, [data, rebalance, activeOrganization]);

  const onClickNext = async () => {
    if (buyTradesWarnings || sellTradesWarnings) {
      setDiscrepancyConfirmationOpen(true);
    } else {
      goNext();
    }
  };

  const callFIXTradingAPI = async () => {
    setFixTradingInitated(true);
    await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'initiateFixTrade' } } });
    refetch();
  };

  const goNext = async () => {
    await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'initiateAllocation' } } });
    await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'completeAllocation' } } });
    await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'complete' } } });
    refetch();
  };

  return (
    <Box>
      {loading && !rebalance ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : rebalance.state === 'CANCELED' ? (<Typography textAlign='center' color='error' variant='h4' sx={{ marginTop: 5 }}>{t('rebalanceCanceled')}</Typography>) : (
        <>
          <Paper sx={{ width: '100%', marginTop: 5 }}>
            <Stepper activeStep={activeStep} sx={{ p: 5 }}>
              {steps.map((label: string) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            { (activeStep === 0 || activeStep === 3) && (
              <Box sx={{ alignItems: 'center', justifyContent: 'center', p: '350px' }}>
                <Typography textAlign='center' variant='h5' sx={{ marginBottom: 3 }}>{t('generatingDescription')}</Typography>
                <Box textAlign='center'>
                  <CircularProgress size='60px'/>
                </Box>
              </Box>
            )}
            { activeStep === 1 && (
              <>
                <TradesTable filter={{ rebalanceId: rebalance.id }} showClient={true} showExpected={true} />
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    <Button
                      variant='outlined'
                      sx={{ m: 1, textAlign: 'right' }}
                      color='error'
                      disabled={transitionLoading}
                      onClick={async () => {
                        await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'cancel' } } });
                        refetch();
                      }}
                    >{t('cancel')}</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      sx={{ m: 1, textAlign: 'right' }}
                      disabled={transitionLoading}
                      onClick={async () => {
                        await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'initiateBulkTrade' } } });
                        await transitionRebalance({ variables: { input: { rebalanceId: rebalance.id, transition: 'completeBulkTrade' } } });
                        refetch();
                      }}
                    >{t('next')}</Button>
                  </Grid>
                </Grid>
              </>
            )}
            { activeStep === 2 && (
              <>
                <Grid container sx={{ p: 1 }}>
                  <Grid item>
                    <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={rebalance.buyBulkTradeFileUrl} download>{t('buyTradeFile')}</Button>
                    <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={rebalance.sellBulkTradeFileUrl} download>{t('sellTradeFile')}</Button>
                    <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={rebalance.mutualFundFileUrl} download>{t('mutualFundTradeFile')}</Button>
                    { rebalance.ptfBulkTradeFileUrl
                      && <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={rebalance.ptfBulkTradeFileUrl} download>{t('ptfBulkTradeFile')}</Button>
                    }
                    {
                      enableFixTrading && (
                        <Button variant='contained' sx={{ marginRight: 1 }} onClick={callFIXTradingAPI}>{t('tradeViaFix')}</Button>
                      )
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={6} sx={{ minWidth: '40em' }}>
                    <Paper>
                      <UpdateTradesTable rebalanceId={rebalance.id} type='BUY' setDiscrepancyWarning={setBuyTradesWarnings}/>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sx={{ minWidth: '40em' }}>
                    <Paper>
                      <UpdateTradesTable rebalanceId={rebalance.id} type='SELL' setDiscrepancyWarning={setSellTradesWarnings} />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container sx={{ direction: 'row', justifyContent: 'space-evenly' }}>
                  <Grid item xs>
                    <Button
                      variant='contained'
                      sx={{ m: 1, textAlign: 'left' }}
                      color='error'
                      disabled={transitionLoading}
                      onClick={() => {
                        setCancelConfirmationOpen(true);
                      }}
                    >{t('cancel')}</Button>
                  </Grid>
                  <Grid item>
                    {!rebalance.actualQuantitiesSet && (
                      <Typography color='error' variant='overline'>{t('allPricesNotUpdated')}</Typography>
                    )}
                    <Button
                      variant='contained'
                      sx={{ m: 1, textAlign: 'right' }}
                      disabled={transitionLoading}
                      onClick={onClickNext}
                    >{t('next')}</Button>
                  </Grid>
                </Grid>
              </>
            )}
            { activeStep === (fixTradingInitiated ? 5 : 4) && (
              <>
                <Box sx={{ p: 15 }}>
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <CheckCircleIcon color='success' sx={{ fontSize: '100px' }} />
                    </Grid>
                  </Grid>
                  <Typography textAlign='center' color='primary' variant='h4' sx={{ p: 3 }}>{t('rebalanceCompleted')}</Typography>
                </Box>
                <TradesTable filter={{ rebalanceId: rebalance.id }} showClient={true} showExpected={true} />
              </>
            )}
          </Paper>
          <ConfirmationModal
            open={cancelConfirmationOpen}
            onCancel={() => setCancelConfirmationOpen(false)}
            onConfirm={async () => {
              await transitionRebalance({
                variables: { input: { rebalanceId: rebalance.id, transition: 'cancel' } },
              });
              showToast({ severity: 'info', message: t('cancelTriggeredMessage') });
              setCancelConfirmationOpen(false);
            }}
            loading={loading}
            bodyText={t('cancelConfirmationDialog')}
            title={t('cancelRebalanceTrades')}
          />
          <ConfirmationModal
            open={discrepancyConfirmationOpen}
            onCancel={() => setDiscrepancyConfirmationOpen(false)}
            onConfirm={() => {
              setDiscrepancyConfirmationOpen(false);
              goNext();
            }}
            title={t('discrepancyConfirmationDialog.title')}
            bodyText={t('discrepancyConfirmationDialog.text')}
          />
        </>
      )}
    </Box>
  );
};

export default Rebalance;
export { RebalanceBreadcrumb } from './rebalanceBreadcrumb';
