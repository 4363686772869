import { CSSProperties, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { base64ToFile } from '../../../../ovComponents/4-module/workflowCompletion/subSteps/utils';

interface InlineImageLocalProps {
  file: {
    name: string,
    base64String: string,
  };
  style?: CSSProperties;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
};

export function InlineImageLocal({ file, style }: InlineImageLocalProps) {
  const { name, base64String } = file;
  const baseFile = base64ToFile(base64String, name);
  const isJsonFile = name.split('.')[1] === 'json';
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    if (isJsonFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const { result } = reader;
        if (result && typeof result === 'string') {
          setAnimationData(JSON.parse(result));
        }
      };
      reader.readAsText(baseFile);
    }
    // eslint-disable-next-line
  }, [isJsonFile]);

  return (
    <>
      {!isJsonFile && (
        <img src={URL.createObjectURL(baseFile)} alt={name} width="100%" style={style ?? { borderTop: '1px solid grey' }} />
      )}
      {isJsonFile && (
        <Lottie options={{ ...defaultOptions, animationData }} height={style?.height} width="100%" />
      )}
    </>
  );
}
