import {
  ListItem, TextField, MenuItem, FormGroup, FormControlLabel, Switch, Box, Accordion, AccordionSummary, AccordionDetails, Grid, List, Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';
import ThemeSelect from 'components/inputs/themeSelect';
import { colors } from 'theme/colors';
import FormModal from '../../../modals/formModal';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import AmountField from '../../../inputs/amountField';
import { usePermissions } from '../../../../providers/userContextProvider';
import {
  Goal, GoalRiskLevels, GoalTimeHorizons, GOAL_TRADING_PROCESS_LIST, goalRiskLevels, goalTypes, timeHorizons, SubAccount, getAccountNumber,
} from '../../../../interfaces';
import { GoalTypes } from '../../../../ovComponents/resources';
import { FETCH_GOAL } from '../../../../pages/goalsDetails';
import ModelPortfoliosSelect from '../../../inputs/modelPortfoliosSelect';

const FETCH_GOAL_SUITABILITY_SCORE = gql`
  query fetchGoalSuitabilityScore($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        suitabilityScore
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const OVERRIDE_ACCOUNTS_TAX_RANKS = gql`
  mutation overrideAccountsTaxRanks($input: OverrideAccountsTaxRanksInput!) {
    overrideAccountsTaxRanks(input: $input)
  }
`;

interface OverrideTaxRanks {
  id: string;
  overrideTaxRank: string | number;
  type?: string;
  custodianAccountNumber?: string;
  defaultTaxRank?: string | number;
}

const EditGoal = ({
  afterUpdate, goalToUpdate, open, handleClose, highlight,
}: {
  afterUpdate: () => void,
  goalToUpdate: Goal,
  open: boolean,
  highlight?: string,
  handleClose: () => void,
}) => {
  const { t } = useTranslation(['client']);
  const { showToast } = useGlobalToast();
  const [goal, setGoal] = useState(goalToUpdate);
  const [goalApplyPortfolio, setGoalApplyPortfolio] = useState(!!goalToUpdate.financialProduct);
  const [goalPortfolioThemeId, setGoalPortfolioThemeId] = useState(goalToUpdate.financialProduct?.theme?.id);
  const [goalPortfolioFinancialProductId, setGoalPortfolioFinancialProductId] = useState(goalToUpdate.financialProduct?.id);
  const [tradingProcess, setTradingProcess] = useState(goalToUpdate.tradingProcess);
  const { permissions } = usePermissions();
  const suitabilityScoreQuery = useQuery(FETCH_GOAL_SUITABILITY_SCORE, { variables: { id: goal.id } });
  const [updateGoal, { loading }] = useMutation(UPDATE_GOAL, {
    refetchQueries: [FETCH_GOAL(permissions)],
  });
  const [overrideAccountsTaxRanks] = useMutation(OVERRIDE_ACCOUNTS_TAX_RANKS);
  const [overrideTaxRanks, setOverrideTaxRanks] = useState<OverrideTaxRanks[]>([]);
  const [initialOverrideTaxRanks, setInitialOverrideTaxRanks] = useState<OverrideTaxRanks[]>([]);

  useEffect(() => {
    if (goalApplyPortfolio === false) {
      setGoalPortfolioThemeId(undefined);
      setGoalPortfolioFinancialProductId(undefined);
      setTradingProcess('NO_AUTOMATION');
    }

    if (goalToUpdate?.subAccounts?.length) {
      const activeSubAccounts = goalToUpdate?.subAccounts?.filter((x: any) => x.state !== 'INACTIVE');

      if (activeSubAccounts.length) {
        const currentTaxRanks = activeSubAccounts.reduce(
          (arr: any, subAccount: SubAccount) => [
            ...arr,
            {
              id: subAccount?.account?.id,
              overrideTaxRank: subAccount?.account?.overrideTaxRank ?? '',
              type: subAccount?.account?.type,
              custodianAccountNumber: subAccount?.account?.custodianAccountNumber,
              defaultTaxRank: subAccount?.account?.defaultTaxRank ?? '',
            },
          ],
          [],
        );

        setOverrideTaxRanks(currentTaxRanks);
        setInitialOverrideTaxRanks(currentTaxRanks);
      } else {
        setOverrideTaxRanks([]);
        setInitialOverrideTaxRanks([]);
      }
    }
  }, [goalApplyPortfolio, goalToUpdate]);

  if (open && !permissions.includes('write:goal_basic')) {
    showToast({ severity: 'warning', message: t('edit.goal.noPermissions') });
    handleClose();
    return <></>;
  }

  const updateOverrideTaxRanks = (id: string, overrideTaxRank: any) => {
    if (overrideTaxRank && overrideTaxRank <= 0) return;

    const overriddenTaxRanks = overrideTaxRanks.map((item: any) => ({
      ...item,
      overrideTaxRank: item.id === id ? overrideTaxRank : item.overrideTaxRank,
    }));

    setOverrideTaxRanks(overriddenTaxRanks);
  };

  const update = async (event: any) => {
    event.preventDefault();
    const inputProps = {
      name: goal.name,
      type: goal.type,
      timeHorizon: goal.timeHorizon,
      targetAmountCents: goal.targetAmountCents,
      riskQuestion1: goal.riskQuestion1,
      financialProductId: goalApplyPortfolio ? goalPortfolioFinancialProductId : null,
      tradingProcess: goalApplyPortfolio ? tradingProcess : 'NO_AUTOMATION',
    };

    const response = await updateGoal({
      variables: {
        input: {
          goalId: goalToUpdate?.id,
          ...inputProps,
        },
      },
    });

    let isSuccess = !!response?.data;

    if (!isEqual(overrideTaxRanks, initialOverrideTaxRanks)) {
      const accountsTaxRanks = overrideTaxRanks
        .filter((item) => initialOverrideTaxRanks.find((item2) => item2.id === item.id)?.overrideTaxRank !== item.overrideTaxRank)
        .map((item: any) => ({ accountId: item.id, overrideTaxRank: !item.overrideTaxRank ? null : Number(item.overrideTaxRank) }));

      if (accountsTaxRanks.length) {
        const overrideTaxRanksResponse = await overrideAccountsTaxRanks({
          variables: {
            input: {
              accountsTaxRanks,
            },
          },
        });

        isSuccess = isSuccess && !!overrideTaxRanksResponse?.data;
      }
    }

    if (isSuccess) {
      showToast({ severity: 'info', message: t('edit.goal.updatedToastMessage') });
      afterUpdate();
    }
  };

  const allowPortfolioPerGoal = !!goalToUpdate.organization?.allowPortfolioPerGoal;
  const highlightPortfolioSwitch = allowPortfolioPerGoal && highlight === 'goalPortfolio' && !goalToUpdate.financialProduct;
  const disabled = (goalApplyPortfolio && (!goalPortfolioFinancialProductId || !goal.tradingProcess));
  return (
    <FormModal
      onSubmit={update}
      loading={loading}
      disabled={disabled}
      title={t('details.updateGoal')}
      open={open}
      handleClose={handleClose}
      formButton={t('shared:update')}
      maxWidth='sm'
    >
      <ListItem>
        <TextField
          disabled={disabled}
          data-testid="goalTypes"
          focused={highlight === 'goalType'}
          select label={t('goalTypes')} fullWidth value={goal.type} onChange={(e) => setGoal({ ...goal, type: e.target.value as GoalTypes })}>
            {goalTypes.map((x: string) => (
                <MenuItem key={x} value={x}>{t(`goalType.${x}`)}</MenuItem>
            ))}
        </TextField>
      </ListItem>
      <ListItem>
        <TextField
            disabled={disabled}
            data-testid="goalName"
            label={t('goalName')}
            focused={highlight === 'goalName'}
            fullWidth
            size='small'
            value={goal.name ?? ''}
            onChange={(e) => setGoal({ ...goal, name: e.target.value })}
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={disabled}
          data-testid="timeHorizon"
          focused={highlight === 'goalTimeHorizon'}
          select label={t('timeHorizon')} fullWidth value={goal.timeHorizon ?? ''} onChange={(e) => setGoal({ ...goal, timeHorizon: e.target.value as GoalTimeHorizons })}>
             {timeHorizons.filter((item) => (item !== GoalTimeHorizons.VERY_LONG_TERM)).map((horizon: string) => (
                <MenuItem key={horizon} value={horizon}>{t(`timeHorizonOptions.${horizon}`)}</MenuItem>
             ))}
        </TextField>
      </ListItem>
      <ListItem>
        <AmountField
            disabled={disabled}
            label={t('targetAmount')}
            fullWidth
            focused={highlight === 'goalTargetAmount'}
            size='small'
            data-testid="targetAmount"
            value={(goal.targetAmountCents ?? 0)}
            onChange={(e) => setGoal({ ...goal, targetAmountCents: Math.round(parseFloat(e.target.value) * 100) })}
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={disabled}
          focused={highlight === 'goalRiskLevel'}
          data-testid="riskQuestion1"
          select label={t('details.riskQuestion1')} fullWidth value={goal.riskQuestion1 ?? ''} onChange={(e) => setGoal({ ...goal, riskQuestion1: e.target.value as GoalRiskLevels })}>
            {goalRiskLevels.map((x: string) => (
                <MenuItem key={x} value={x}>{t(`edit.riskQuestion1Options.${x}`)}</MenuItem>
            ))}
        </TextField>
      </ListItem>
      { (allowPortfolioPerGoal || goalPortfolioThemeId) && (
        <ListItem>
          <FormGroup sx={{ marginLeft: 0, marginRight: 0, width: '100%' }}>
            <FormControlLabel sx={{
              margin: 0,
              ...(highlightPortfolioSwitch && {
                borderWidth: '2px', borderColor: colors.primary, borderStyle: 'solid', borderRadius: '4px',
              }),
            }}
              control={<Switch
                checked={goalApplyPortfolio}
                onChange={(e) => setGoalApplyPortfolio(e.target.checked)}
              />}
              label={t('edit.goal.applyPortfolioAtGoal')}
            />
          </FormGroup>
        </ListItem>
      )}
      <Box>
        { goalApplyPortfolio && (
          <>
          <ListItem>
            <ThemeSelect
              value={goalPortfolioThemeId ?? ''}
              onChange={(newValue) => { setGoalPortfolioThemeId(newValue); setGoalPortfolioFinancialProductId(undefined); }}
              label={t('client:selectTheme')}
              suitabilityScore={suitabilityScoreQuery?.data?.fetchGoal.goal.suitabilityScore}
            />
          </ListItem>
          { goalPortfolioThemeId && (
            <ListItem>
              <ModelPortfoliosSelect
                focused={highlight === 'goalPortfolio' && !highlightPortfolioSwitch}
                value={goalPortfolioFinancialProductId || ''}
                themeId={goalPortfolioThemeId}
                onChange={(e) => setGoalPortfolioFinancialProductId(e.target.value)}
                label={t('client:selectProduct')}
                suggestableOnly
              />
            </ListItem>
          )}
          </>
        )}
      </Box>
      { goalApplyPortfolio && (
        <ListItem>
          <TextField
            select
            fullWidth
            value={tradingProcess}
            focused={highlight === 'tradingProcess'}
            label={t('tradingProcess')}
            onChange={(e) => setTradingProcess(e.target.value)}
          >
            {GOAL_TRADING_PROCESS_LIST.map((x: any) => (
              <MenuItem key={x.value} value={x.value}>
                {x.name}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
      )}

      { !!overrideTaxRanks.length && (
        <ListItem>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{t('overrideAccountsTaxRanks')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <Grid container alignItems='center'>
                    <Grid item xs={4}>
                      <Typography fontWeight={500} fontSize="14px">{t('accountTypes')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={500} fontSize="14px">{t('overrideTaxRanks')}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: 4 }}>
                      <Typography fontWeight={500} fontSize="14px">{t('defaultTaxRanks')}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                {overrideTaxRanks.map((item: OverrideTaxRanks) => (
                  <ListItem key={item.id}>
                    <Grid container alignItems='center'>
                      <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize="16px">{t(`accountTypes:${item.type}`)}</Typography>
                        <Typography fontWeight={500} fontSize="10px">#{getAccountNumber(item as any)}</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField fullWidth type='number' value={item.overrideTaxRank} onChange={(value) => updateOverrideTaxRanks(item.id, value.target.value)} />
                      </Grid>

                      <Grid item xs={4} sx={{ paddingLeft: 5 }}>
                        <Typography fontWeight={400} fontSize="14px">{item.defaultTaxRank}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      )}
    </FormModal>
  );
};

export default EditGoal;

export const testables = {
  UPDATE_GOAL,
  FETCH_GOAL_SUITABILITY_SCORE,
};
