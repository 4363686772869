import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../2-component/avatar/avatar';
import { Dialog, DialogContent, DialogTitle } from '../../2-component/dialog/dialog';
import { MenuItem } from '../../2-component/menuItem/menuItem';
import { CircularProgress } from '../../2-component/circularProgress/circularProgress';
import { Organization } from '../../../interfaces';
import { Box, Grid, Typography } from '../../1-primative';
import { paletteBase } from '../../0-tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const FETCH_PUBLIC_ORGANIZATIONS = gql`
  query fetchPublicOrganizations($input: FetchPublicOrganizationsInput!) {
    fetchPublicOrganizations(input: $input) {
      organizations {
        id
        subdomain
        name
        theme {
          authenticationTheme {
            logo
            primaryColor
            pageBackgroundColor
          }
        }
      }
      totalCount
    }
  }
`;

export const PublicOrganizationSelectDialog = ({
  filter,
  open,
  onOrgSelect,
}: {
  filter?: { childrenFor?: string, searchText?: string, referenceIds?: string[] },
  open: boolean,
  onOrgSelect: (organization: Organization) => void,
}) => {
  const { t } = useTranslation('authentication');
  const { sys } = useThemeTokens();
  const { data, loading } = useQuery(FETCH_PUBLIC_ORGANIZATIONS, {
    variables: {
      input: {
        filter,
      },
    },
  });
  const OrgAvatar = (props: { name?: string, logo?: string, fgColor?: string, bgColor?: string }) => {
    if (!props.logo && !props.name) return <></>;
    return (
      <Avatar state='highlight' size='large' fgColor={props.fgColor || paletteBase.supportOne60} bgColor={props.bgColor || paletteBase.supportOne40}>
        {props.logo ? (<img alt='org-logo' width='100%' height='auto' src={props.logo} />) : (props?.name?.[0]?.toUpperCase())}
      </Avatar>
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth='xs'>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid container>
          <Grid item md={12} justifyContent='space-between' display='flex'>
            <Typography variant='titleLarge'>{t('publicOrganizationSelectDialog.title')}</Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant='bodyLarge' mt={3}>{t('publicOrganizationSelectDialog.subTitle')}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (data?.fetchPublicOrganizations?.organizations || []).map((org: Organization) => (
          <Box key={org.id} display='flex' alignItems='center' flexDirection='row' justifyContent='space-between' marginBottom={sys.spacing.xxl}>
            <MenuItem
              key={org.id}
              sx={{
                pv: 2, display: 'flex', justifyContent: 'space-between', width: '100%',
              }}
              onClick={() => { onOrgSelect(org); }}
            >
              <Box display='flex' flexDirection='row' alignItems='center'>
                <span style={{ marginRight: sys.spacing.lg }}>{
                  <OrgAvatar
                    name={org.name}
                    logo={org.theme?.authenticationTheme?.logo}
                    fgColor={org.theme?.authenticationTheme?.primaryColor}
                    bgColor={org.theme?.authenticationTheme?.pageBackgroundColor}
                  />
                }</span>
                <Typography
                  variant='bodyLarge'
                  weight='bold'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    marginRight: sys.spacing.md,
                  }}
                >
                  {org.name}
                </Typography>
              </Box>
            </MenuItem>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};
