import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const FundAvgAnnualReturns = ({ options }: { options?: any }) => {
  useRefinitivWidget('#fund-avg-annual-returns', 'WFundAvgAnnualReturns', options);

  return (
    <Card>
      <div id='fund-avg-annual-returns'></div>
    </Card>
  );
};
