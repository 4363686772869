import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DraftSubTradeRequest, PortfolioSubAccount } from './interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { getSubAccountCurrentCents, sumPendingTradesCentsByCondition } from './utils';
import { formatMoneyValue } from '../../../../../util';
import { TableCell, Tooltip } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import ChangeTooltip from './changeTooltip';

export const SubAccountTableCell = ({ subAccount, pendingTrades }: { subAccount: PortfolioSubAccount; pendingTrades: Map<string, DraftSubTradeRequest>[] }) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { sys } = useThemeTokens();

  const pendingTradesCents = useMemo(() => sumPendingTradesCentsByCondition(pendingTrades, (trade) => trade.subAccountId === subAccount.id), [subAccount, pendingTrades]);
  const current = useMemo(() => getSubAccountCurrentCents(subAccount, pendingTradesCents), [subAccount, pendingTradesCents]);

  const isSubAccountPendingWithdrawal = subAccount?.pendingWithdrawCents > 0 || subAccount?.pendingContributionCents < 0;

  const TooltipTitle = () => (
    <div style={{ whiteSpace: 'pre-line' }}>
      {t('portfolioTable.pendingWithdraw')}: {formatMoneyValue((subAccount?.pendingWithdrawCents ?? subAccount?.pendingContributionCents) || 0)}
    </div>
  );

  return (
    <TableCell sx={{ height: '40px' }}>
      <Box justifyContent='start' flexDirection='row' display='flex' alignItems='center' gap={1}>
        <Box>
          {/* { title: "Tooltip title. Zero-length titles string, undefined, null and false are never displayed." } */}
          <Tooltip title={isSubAccountPendingWithdrawal ? <TooltipTitle /> : null}>
            <Typography
              sx={{
                fontVariantNumeric: 'tabular-nums',
                whiteSpace: 'nowrap',
                color: isSubAccountPendingWithdrawal ? sys.color.warning : undefined,
              }}
            >
              {t('portfolioTable.target')}: <b>{formatMoneyValue(subAccount.expectedCashCents)}</b>
            </Typography>
          </Tooltip>

          <Typography sx={{ fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap' }}>
            {t('portfolioTable.current')}: <b>{formatMoneyValue(current)}</b>
          </Typography>
        </Box>

        <ChangeTooltip change={pendingTradesCents * -1} original={subAccount.cashCents - subAccount.cashOnHoldToTradeCents} flip total />
      </Box>
    </TableCell>
  );
};
