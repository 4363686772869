import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Grid, Button, InputAdornment, TextField, IconButton, Menu, MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { NoPermissionAlert } from '../../../components/misc/noPermissionAlert';
import { usePageState } from '../../../util/usePageState';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import SelectOptimizerModal from './selectOptimizerModal';
import { TRANSITION_PORTFOLIO_OPTIMIZER } from '../../portfolioOptimizer/components/portfolioOptimizerCancelButton';
import { PortfolioOptimizer, PortfolioOptimizerStates } from '../../../interfaces/portfolioOptimizer';

export const FETCH_PORTFOLIO_OPTIMIZERS = gql`
  query fetchPortfolioOptimizers($input: FetchPortfolioOptimizersInput!) {
    fetchPortfolioOptimizers(input: $input) {
      portfolioOptimizers {
        id
        type
        state
        numPortfolios
        organization {
          name
        }
        createdAt
      }
      totalCount
    }
  }
`;

const transitions = [
  {
    name: 'cancel',
    from: [
      PortfolioOptimizerStates.OPTIMAL_PORTFOLIOS_GENERATED,
      PortfolioOptimizerStates.REDEMPTIONS_SOLVED,
      PortfolioOptimizerStates.PURCHASES_SOLVED,
      PortfolioOptimizerStates.REBALANCED,
      PortfolioOptimizerStates.TAX_OPTIMIZED,
    ],
  },
];

export const isActionDisabled = (transition: string, portfolioOptimizer: PortfolioOptimizer): boolean => {
  const from = transitions.find((item) => item.name === transition)?.from ?? [];

  return !from.includes(portfolioOptimizer.state);
};

const PortfolioOptimizersTable = () => {
  const pageSize = 15;
  const { t } = useTranslation('portfolioOptimizer');
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const { permissions } = usePermissions();
  const [isSelectOptimizerOpen, setIsSelectOptimizerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activePortfolioOptimizer, setActivePortfolioOptimizer] = useState<any>({});

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_PORTFOLIO_OPTIMIZERS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt',
          sortDesc: false,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    skip: !permissions.includes('read:portfolio_optimizer'),
    fetchPolicy: 'no-cache',
  });

  const [transitionPortfolioOptimizer] = useMutation(TRANSITION_PORTFOLIO_OPTIMIZER);

  if (!permissions.includes('read:portfolio_optimizer')) return <NoPermissionAlert missing='read:portfolio_optimizer' />;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClick = async (event: any) => {
    await transitionPortfolioOptimizer({ variables: { input: { portfolioOptimizerId: activePortfolioOptimizer.id, transition: event } } });
    refetch();
  };

  return (
    <Box sx={{ width: '100%', overflowY: 'auto' }}>
      <Grid container display='flex' justifyContent='space-between'>
        <Grid item display='flex'>
          <TextField
            disabled // TODO this is for future reference, either finalize or remove
            sx={{ width: '300px', m: 2 }}
            size='small'
            id='searchText'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item m={1}>
          {permissions.includes('write:portfolio_optimizer') && (
            <Button
              variant='outlined'
              sx={{
                float: 'right',
                m: 1,
              }}
              data-testid='run-button'
              onClick={() => setIsSelectOptimizerOpen(true)}
            >
              {t('table.run')}
            </Button>
          )}
        </Grid>
      </Grid>

      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label='table' data-testid='portfolio-optimizers-table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='overline'>{t('table.ranAt')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.type')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.numPortfolios')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.organization')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.state')}</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography variant='overline'>{t('table.actions')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchPortfolioOptimizers?.portfolioOptimizers?.map((line: PortfolioOptimizer) => (
                <TableRow
                  hover
                  onClick={() => {
                    navigate(`/portfolioOptimizers/${line.id}`);
                  }}
                  key={line.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell>
                    <DateTime variant='subtitle2' date={line.createdAt} />
                  </TableCell>
                  <TableCell>{t(`portfolioOptimizerType.${line.type}`)}</TableCell>
                  <TableCell>{line.numPortfolios}</TableCell>
                  <TableCell>{line.organization?.name}</TableCell>
                  <TableCell>{t(`portfolioOptimizerState.${line.state}`)}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation();
                        setActivePortfolioOptimizer(line);
                        handleClick(e);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {(data || previousData)?.fetchPortfolioOptimizers?.portfolioOptimizers?.length > 0
              && transitions.map((transition: any) => (
                <MenuItem
                  key={transition.name}
                  disabled={isActionDisabled(transition.name, activePortfolioOptimizer)}
                  onClick={() => {
                    onClick(transition.name);
                  }}
                >
                  {t(`transitions.${transition.name}`)}
                </MenuItem>
              ))}
          </Menu>

          <Pagination
            count={Math.ceil(((data || previousData)?.fetchPortfolioOptimizers?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />

          {isSelectOptimizerOpen && <SelectOptimizerModal handleClose={() => setIsSelectOptimizerOpen(false)} />}
        </>
      )}
    </Box>
  );
};

export default PortfolioOptimizersTable;
