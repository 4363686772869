export const initialConfigs: any = {
  PERSONAL_INFORMATION: {
    firstName: {
      enabled: true,
      label: {
        en: 'First Name',
        fr: 'Prénom',
      },
      required: 'ERROR',
    },
    middleName: {
      enabled: true,
      label: {
        en: 'Middle Name',
        fr: 'Deuxième prénom',
      },
      required: 'NOT_REQUIRED',
    },
    lastName: {
      enabled: true,
      label: {
        en: 'Last Name',
        fr: 'Nom de famille',
      },
      required: 'ERROR',
    },
    primaryEmail: {
      enabled: true,
      label: {
        en: 'Email',
        fr: 'Courriel',
      },
      required: 'ERROR',
    },
    phone: {
      enabled: true,
      label: {
        en: 'Phone',
        fr: 'Téléphone',
      },
      required: 'ERROR',
    },
    physicalAddress: {
      enabled: true,
      label: {
        en: 'Physical Address',
        fr: 'Adresse physique',
      },
      required: 'ERROR',
    },
    language: {
      enabled: true,
      label: {
        en: 'Language',
        fr: 'Langue',
      },
      required: 'ERROR',
    },
  },
  NON_INDIVIDUAL_INFORMATION: {
    primaryEmail: {
      enabled: true,
      label: {
        en: 'Email',
        fr: 'Courriel',
      },
      required: 'ERROR',
    },
    phone: {
      enabled: true,
      label: {
        en: 'Phone',
        fr: 'Téléphone',
      },
      required: 'ERROR',
    },
    physicalAddress: {
      enabled: true,
      label: {
        en: 'Physical Address',
        fr: 'Adresse physique',
      },
      required: 'ERROR',
    },
    language: {
      enabled: true,
      label: {
        en: 'Language',
        fr: 'Langue',
      },
      required: 'ERROR',
    },
  },
  GOALS_AND_ACCOUNTS: {
    addNewGoalEnabled: true,
    addNewAccountEnabled: false,
    filterEnabled: false,
  },
  GOALS: {
    addNewGoalEnabled: true,
    filterEnabled: false,
  },
  ACCOUNTS: {
    addNewAccountEnabled: false,
    filterEnabled: false,
  },
};
