import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Chip, Button,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewApiToken from './newApiToken';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import ViewApiToken from './viewApiToken';

const FETCH_API_TOKENSS = gql`
  query me {
    me {
      apiTokens {
        id
        name
        state
        organization {
          id
          name
        }
        jwtToken
        permissions
      }
    }
  }
`;

const DEACTIVATE_TOKEN = gql`
  mutation deactivateToken($input: TransitionApiTokenInput!) {
    transitionApiToken(input: $input) {
      apiToken {
        id
      }
    }
  }
`;

const ApiToken = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [apiTokenOpen, setApiTokenOpen] = useState(false);
  const [selectedApiToken, setSelectedApiToken] = useState<any>(null);
  const { t } = useTranslation(['devSettings']);
  const {
    loading, error, data, refetch,
  } = useQuery(FETCH_API_TOKENSS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {},
      },
    },
  });

  const [deactivateApiToken] = useMutation(DEACTIVATE_TOKEN);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              { permissions.includes('write:api_tokens') && (
                <NewApiToken afterCreate={refetch} defaultOrg={activeOrganization.id ?? ''} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('apiTokenTable.name')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('apiTokenTable.noPermissions')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('apiTokenTable.organization')}</Typography></TableCell>
                <TableCell align="right">{t('apiTokenTable.state')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.me?.apiTokens?.map((apiToken: any) => (
                <TableRow
                  hover
                  key={apiToken.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedApiToken(apiToken);
                    setApiTokenOpen(true);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {apiToken.name}
                  </TableCell>
                  <TableCell align="right">{apiToken.permissions?.length}</TableCell>
                  <TableCell>{apiToken.organization.name}</TableCell>
                  <TableCell align="right">{apiToken.state === 'ACTIVE' ? (
                    <Button size='small' variant='outlined' color='error' onClick={async (e) => {
                      e.stopPropagation();
                      await deactivateApiToken({ variables: { input: { apiTokenId: apiToken.id, transition: 'deactivate' } } });
                      refetch();
                    }}>
                      {t('deactivate')}
                    </Button>
                  ) : (
                    <Chip label={t('deactivated')} color='error' />
                  )}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ViewApiToken apiTokenToView={selectedApiToken} open={apiTokenOpen} handleClose={() => setApiTokenOpen(false)} />
        </>
      )}
    </Box>
  );
};

export default ApiToken;
