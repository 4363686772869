import { useState, useContext, useCallback } from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { gql, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '../../1-primative';
import { UserContext } from '../../../providers/userContextProvider';
import { Autocomplete } from '../autocomplete/autocomplete';
import { currencyFlags, getCurrencyFlag } from '../../../util/currency';
import { RefinitivSecurityModal } from '../../4-module/widgets/refinitivSecurityModal/refinitivSecurityModal';
import { CircularProgress } from '../circularProgress/circularProgress';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Card } from '..';

const SEARCH_DEBOUNCE_IN_MS = 500;

interface Suggestion {
  ticker?: string;
  name?: string;
  exchange?: string;
  ric?: string;
  currency?: string;
  id?: string;
  type?: string;
}

interface SearchResults {
  suggestions: Suggestion[];
}

export const SEARCH_PRODUCTS_IN_PROVIDER = gql`
  query searchProductsInProvider($input: SearchProductsInProviderInput!) {
    searchProductsInProvider(input: $input) {
      searchSuggestions {
        name
        ric
        ticker
        exchange
        currency
        type
      }
    }
  }
`;

const hasCurrencyFlag = (currency: string) => Object.keys(currencyFlags).includes(currency);

export const SearchSecuritiesField = () => {
  const { t } = useTranslation();
  const { sys } = useThemeTokens();
  const { activeOrganization } = useContext(UserContext);
  const [searchResult, setSearchResult] = useState<SearchResults>({ suggestions: [] });
  const [valueToView, setValueToView] = useState<Suggestion | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [ricCode, setRicCode] = useState<string>('');
  const [nothingFoundBoxOpen, setNothingFoundBoxOpen] = useState<boolean>(false);

  const [searchProductsInProvider, { loading }] = useLazyQuery(SEARCH_PRODUCTS_IN_PROVIDER);

  const handleInputChange = (value: string) => {
    if (value === '') {
      setSearchResult({
        suggestions: [],
      });
      setNothingFoundBoxOpen(false);
    }

    searchProductsInProvider({
      variables: {
        input: { searchText: value, organizationId: activeOrganization.id },
      },
      onCompleted: (data) => {
        const suggestions = data?.searchProductsInProvider?.searchSuggestions;
        if (suggestions) {
          if (suggestions.length === 0) {
            setNothingFoundBoxOpen(true);
            setSearchResult({
              suggestions: [],
            });
          } else {
            setSearchResult({
              suggestions: suggestions.map((s: Suggestion) => ({
                ...s,
                currency: s?.currency?.toUpperCase(),
                id: `${[...suggestions].indexOf(s)}`,
              })),
            });
            setNothingFoundBoxOpen(false);
          }
        } else {
          setNothingFoundBoxOpen(true);
        }
      },
      onError: () => {
        setSearchResult({
          suggestions: [],
        });
        setNothingFoundBoxOpen(true);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postponedCall = useCallback(
    debounce((value) => {
      if (value) {
        handleInputChange(value);
      }
    }, SEARCH_DEBOUNCE_IN_MS),
    [],
  );

  return (
    <>
      <Card sx={{ mt: 6, overflowX: 'auto' }}>
        <Box sx={{ p: 2 }}>
          <Autocomplete
            testId='searchSecuritiesInput'
            label={''}
            freeSolo
            loading={loading}
            options={searchResult.suggestions}
            isOptionEqualToValue={(option: Suggestion, value: Suggestion) => option.id === value.id}
            onInputChange={(e: React.SyntheticEvent, value: string) => postponedCall(value)}
            getOptionLabel={(option) => option?.ric}
            renderOption={(props: any, option: Suggestion) => (
              <li {...props}>
                <Grid container display={'grid'} gridTemplateColumns={'0.2fr 0.2fr 1fr 0fr'} justifyItems='left'>
                  <Grid item sx={{ fontWeight: 'bold' }}>
                    {option?.ticker}
                  </Grid>
                  <Grid item>{option?.type}</Grid>
                  <Grid item>{option?.name}</Grid>
                  <Grid item>
                    {option.currency && hasCurrencyFlag(option.currency) && <img src={getCurrencyFlag(option.currency as CurrencyCodes)} alt='' style={{ width: '1.66em', height: '1.66em' }} />}
                    {option.currency && !hasCurrencyFlag(option.currency) && (
                      <Typography width='1.66em' height='1.66em' marginRight={'0.2em'}>
                        {option.currency}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </li>
            )}
            fullWidth
            filterOptions={(options: Suggestion[]) => options}
            value={valueToView}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position='end'>{loading ? <CircularProgress color='inherit' size={30} /> : null}</InputAdornment>,
            }}
            onChange={(e: React.SyntheticEvent, newValue: Suggestion | null) => {
              setValueToView(newValue);
              if (newValue && newValue?.ric) {
                setRicCode(newValue?.ric);
                setModalOpen(true);
              }
            }}
          />
          {nothingFoundBoxOpen && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mt: '0.8em',
                ml: '0.8em',
              }}
            >
              <Typography
                variant='bodyMedium' sx={{ fontSize: '1em', fontWeight: 400, color: sys.color.onSurfaceVariant }}
              >{t('components:searchSecuritiesField.nothingFoundMessage')}</Typography>
            </Box>
          )}
        </Box>
      </Card>
      {modalOpen && (
        <RefinitivSecurityModal
          onClose={() => {
            setModalOpen(false);
            setRicCode('');
            setNothingFoundBoxOpen(false);
          }}
          ricCode={ricCode}
        />
      )}
    </>
  );
};

export default SearchSecuritiesField;
