import { merge } from 'lodash/fp';
import { OverrideRefTokens, RefTokens, ref } from './ref';
import { OverrideSysTokens, SysTokens, useSysTokens } from './sys';

export * from './sys';
export * from './ref/palette/palette';
export * from './layout';

export interface Tokens {
  ref: RefTokens,
  sys: SysTokens,
  comp?: any,
}

export interface OverrideTokens {
  ref?: OverrideRefTokens,
  sys?: OverrideSysTokens,
  comp?: any,
}

export const useTokens = (override: OverrideTokens): Tokens => {
  const refTokens = merge(ref, override.ref);
  const sysTokens = useSysTokens(override);

  return {
    ref: refTokens,
    sys: sysTokens,
  };
};
