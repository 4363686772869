import { useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link as MuiLink,
  Pagination,
  Chip,
  IconButton,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import BankAccountDocumentViewer from './BankAccountDocumentViewer';
import { Date } from '../../../components/misc/date/date';
import { useLocalization } from '../../../util/useLocalization';
import { generateClientNameString } from '../../../util';

export const FETCH_BANK_ACCOUNTS = gql`
  query fetchBankAccounts($input: FetchBankAccountsInput!) {
    fetchBankAccounts(input: $input) {
      bankAccounts {
        id
        name
        state
        bankAccountNumber
        transitNumber
        institutionNumber
        user{
          id
          firstName
          entityName
          lastName
          organization{
            id
            name
          }
        }
        createdAt
        updatedAt
        stateChanges
      }
      totalCount
    }
  }
`;
export const stateBankAccountStateColor = (state: any) => (state === 'APPROVED' ? 'success' : ['REJECTED'].includes(state) ? 'error' : 'warning');
const calculateDaysWaiting = (bankAccount: any): number => {
  const createdDate = dayjs(bankAccount.createdAt).utc();
  const toDay = dayjs().utc();
  return toDay.diff(createdDate, 'days') || 0;
};

const BankAccountsTable = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['bankAccountsReview', 'client']);
  const { localizedDate } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const [currentBankAccount, setCurrentBankAccount] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');

  const [filterState, setFilterState] = usePageState('AWAITING_REVIEW', 'state');
  const [filterOrganizationId, setFilterOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');

  const [openViewer, setOpenViewer] = useState(false);

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BANK_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          state: filterState === 'ANY' ? undefined : filterState,
          organizationId: filterOrganizationId || activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: filterState !== 'AWAITING_REVIEW', perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <>
      <Grid container>
        <Grid item>
          <List sx={{ minWidth: '250px' }}>
            <ListItem>
              <TextField
                select
                size='small'
                label={t('bankAccountsReview:filters.state')}
                fullWidth
                value={filterState || ''}
                onChange={(e) => {
                  setPage(1);
                  setFilterState(e.target.value);
                }}>
                <MenuItem value='ANY'>{t('bankAccountsReview:filters.any')}</MenuItem>
                <MenuItem value='AWAITING_REVIEW'>{t('bankAccountsReview:filters.awaitingReview')}</MenuItem>
                <MenuItem value='APPROVED'>{t('bankAccountsReview:filters.approved')}</MenuItem>
                <MenuItem value='REJECTED'>{t('bankAccountsReview:filters.rejected')}</MenuItem>
              </TextField>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <List sx={{ minWidth: '250px' }}>
            <ListItem>
              <OrganizationSelect
                label={t('bankAccountsReview:filters.organization')}
                onChange={(event) => {
                  setPage(1);
                  setFilterOrganizationId(event.target.value);
                }}
                value={filterOrganizationId}
                childrenFor={activeOrganization.id}
                size='small'
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      ) : (
        <>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.clientName')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.organization')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.state')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.requestedDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.approvedDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.daysWaiting')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.bankAccountNumber')}</Typography></TableCell>
                {permissions.includes('transition:bank_account_basic') && (<TableCell><Typography variant='overline'>{t('bankAccountsReview:filters.actions')}</Typography></TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchBankAccounts?.bankAccounts?.map((bankAccount: any) => (
                <TableRow
                  hover
                  key={bankAccount.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell component="th" scope="row">
                    <MuiLink component={Link} to={`/clients/${bankAccount.user.id}`}>{generateClientNameString(bankAccount.user, false, true)}</MuiLink>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bankAccount.user.organization.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip size='small' label={t(`bankAccountsReview:filters.stateOptions.${bankAccount.state}`)} color={stateBankAccountStateColor(bankAccount.state)} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Date date={bankAccount.createdAt} fontSize='small' />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bankAccount.state !== 'APPROVED' ? '-' : (<Typography fontSize='small'>{localizedDate(bankAccount.updatedAt)}</Typography>)}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ color: calculateDaysWaiting(bankAccount) > 3 ? 'red' : 'inherit' }}>
                    {['APPROVED', 'REJECTED'].includes(bankAccount.state) && '-'}
                    {bankAccount.state === 'AWAITING_REVIEW'
                      && (<Chip size='small' label={calculateDaysWaiting(bankAccount)} color={calculateDaysWaiting(bankAccount) > 3 ? 'error' : 'success'} />)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {bankAccount.bankAccountNumber}
                  </TableCell>
                  {permissions.includes('transition:bank_account_basic') && (
                    <TableCell align='right'>
                      <IconButton onClick={() => {
                        setCurrentBankAccount(bankAccount);
                        setOpenViewer(true);
                      }}>
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchBankAccounts?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </Grid>
          <BankAccountDocumentViewer
            open={openViewer}
            handleClose={() => setOpenViewer(false)}
            bankAccount={currentBankAccount}
          />
        </>
      )}
    </>
  );
};

export default BankAccountsTable;
