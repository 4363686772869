import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { IconButton } from '../../../ovComponents/2-component';
import { CreateGoalWizard, CreateGoalWizardProps } from './wizard';

export function CreateGoalWizardButton(props: CreateGoalWizardProps) {
  const [wizardShown, setWizardShown] = useState(false);

  return (
    <>
      <IconButton onClick={() => { setWizardShown(true); }}>
        <AddIcon data-testid='initiate-goal-wizard' />
      </IconButton>
      { wizardShown
        && <CreateGoalWizard {...props} handleClose={() => setWizardShown(false)}/>
      }
    </>
  );
}
