import { useCallback, useEffect, useState } from 'react';
import { Chip, SxProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete } from '../autocomplete/autocomplete';

interface Item {
  id: string,
  label: string,
}

export const DataSearchSelect = ({
  dataId,
  setData,
  searchData,
  otherFilters,
  mapViewItem,
  label,
  disabled,
  fullWidth,
  labelSx,
  width,
}: {
  dataId: string,
  setData: (id: string | undefined) => void,
  searchData: (options: any) => void,
  otherFilters?: any,
  mapViewItem: (data: any) => Item[],
  label: string,
  disabled?: boolean,
  fullWidth?: boolean,
  labelSx?: SxProps,
  width?: string,
}) => {
  const [searchResults, setSearchResults] = useState<Item[]>([]);

  const searchFn = useCallback((query: string) => {
    if (dataId && query !== '') return;
    searchData({
      variables: {
        ...otherFilters,
        query,
      },
      onCompleted: (data: any) => {
        setSearchResults(mapViewItem(data));
      },
      onError: () => {
        setSearchResults([]);
      },
    });
  }, [otherFilters, mapViewItem, searchData, dataId]);

  useEffect(() => {
    // Initial query to prevent empty dropdown
    searchFn('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      width={width}
      disabled={disabled}
      inputReadOnly={!!dataId}
      options={searchResults}
      fullWidth={fullWidth}
      label={label ?? ''}
      freeSolo
      getOptionLabel={(option) => option.label}
      onInputChange={(e: any, value: any) => searchFn(value)}
      onChange={(e: any, value: any, reason: string) => {
        if (reason === 'clear') {
          setData(undefined);
          searchFn('');
        } else if (value.id) {
          const result = searchResults.find((item) => item.id === value.id);
          setData(result?.id);
        }
      }}
      renderTags={(tagValue: any, getTagProps: any) => tagValue.map((option: any, index: number) => (
        <Chip
          label={option}
          sx={{ marginTop: '-2px !important' }}
          {...getTagProps({ index })}
          size='small'
          onDelete={undefined}
        />
      ))
      }
      componentsProps={{ popupIndicator: { sx: { display: 'none' } } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        readOnly: !!dataId,
      }}
      labelSx={labelSx}
    />
  );
};
