/* eslint-disable arrow-body-style */
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Grid, Typography,
} from '../../1-primative';
import {
  Card, CardContent, CircularProgress, Table, TableBody, TableCell, TableHeadCell,
  TableRow, BarChart,
} from '../../2-component';
import { getBackendLanguage, translateBackend } from '../../../assets/i18n/config';
import { PageObjectType } from '../../5-page';
import { ObjectType, useStats } from '../../../providers/statsHooks';
import { getCurrencyFlag } from '../../../util/currency';
import { formatMoneyValue } from '../../../util';
import { UserContext } from '../../../providers/userContextProvider';

const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const ProjectedIncomeReport = ({
  objectId,
  objectType,
  options = {},
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
}): JSX.Element => {
  const { t } = useTranslation('projectedIncomeReport');
  const [tableData, setTableData] = useState<any>({});
  const [chartData, setChartData] = useState<any>({});
  const { activeCurrency } = useContext(UserContext);

  const { statistics, loading } = useStats({
    type: objectTypeMapping(objectType),
    id: objectId,
    useCustodianData: true,
    currency: activeCurrency,
    fetchProjectedIncome: true,
  });

  useEffect(() => {
    if (statistics && statistics.projectedIncome) {
      const incomeLots = statistics.projectedIncome.filter((income) => (income.amountCents ?? 0) > 0);
      const tempTableData: any = {};
      const tempChartData: any = {};
      [...Array(12)].forEach((i: number, index: number) => {
        tempChartData[dayjs().add(index, 'month').locale(getBackendLanguage()).format('MMM YYYY')] = 0;
      });
      incomeLots.forEach((incomeLot: any) => {
        const lot = { date: dayjs(incomeLot.date, 'YYYYMMDD').toDate(), amount: incomeLot.amountCents };
        if (tempTableData[incomeLot.financialProduct.translatedName.en]) {
          tempTableData[incomeLot.financialProduct.translatedName.en].lots.push(lot);
          tempTableData[incomeLot.financialProduct.translatedName.en].total += incomeLot.amountCents;
        } else {
          tempTableData[incomeLot.financialProduct.translatedName.en] = {
            lots: [lot],
            total: incomeLot.amountCents,
            currency: incomeLot.currency,
          };
        }
        if (tempChartData[dayjs(incomeLot.date, 'YYYYMMDD').locale(getBackendLanguage()).format('MMM YYYY')] >= 0) {
          tempChartData[dayjs(incomeLot.date, 'YYYYMMDD').locale(getBackendLanguage()).format('MMM YYYY')] += incomeLot.amountCents;
        }
      });
      setChartData(tempChartData);
      setTableData(tempTableData);
    }
  }, [statistics]);

  return (
    <>
    {options.useExternalStatistics && (
      <>
        {options.customTitle && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>
          </Box>
        )}
        <Card>
          {
            loading ? (
              <Box display="flex" justifyContent="center" alignItems='center' height={140}>
                <CircularProgress />
              </Box>
            ) : (
              Object.keys(tableData).length === 0 ? (
                <Typography variant='bodyMedium' textAlign='center' p={2}>
                  {t('noData')}
                </Typography>
              ) : (
                <>
                  <CardContent>
                    {options.showDisclaimer && (
                      <Typography variant='bodySmall' colorVariant='variant' mb={2}>
                        <InfoOutlined sx={{ fontSize: 14, verticalAlign: 'text-bottom', mr: 0.5 }} />
                        {translateBackend(options.disclaimer)}
                        </Typography>
                    )}
                    <Grid container minHeight={300} p={1} spacing={2}>
                      <Grid item xs={12} md={9}>
                        <BarChart labels={Object.keys(chartData)} datasets={[{ label: t('projectedIncome'), data: Object.values(chartData) }]} />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant='labelLarge'>
                          {t('totalProjectedIncome')}
                        </Typography>
                        <Typography variant='headingSmall'>
                          {formatMoneyValue(Object.keys(tableData).reduce((acc: number, key: string) => acc + tableData[key].total, 0))}
                        </Typography>
                        <Typography variant='labelLarge' mt={1}>
                          {t('monthlyAverageIncome')}
                        </Typography>
                        <Typography variant='headingSmall'>
                          {formatMoneyValue((Object.keys(tableData).reduce((acc: number, key: string) => acc + tableData[key].total, 0) / 12))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {options.showTable && (
                    <Box overflow='auto'>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableHeadCell>
                            </TableHeadCell>
                            {
                              [...Array(12)].map((i: number, index: number) => (
                                <TableHeadCell key={index} right>
                                  {dayjs().add(index, 'month').locale(getBackendLanguage()).format('MMM YYYY')}
                                </TableHeadCell>
                              ))
                            }
                          </TableRow>
                          {
                            Object.keys(tableData).map((key: string, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Box display='flex' alignItems='center' sx={{ wordBreak: 'normal' }}>
                                    <img alt={tableData[key].currency} src={getCurrencyFlag(tableData[key].currency)} style={{ objectFit: 'cover', height: '100%', marginRight: '8px' }}/>
                                    {key}
                                  </Box>
                                </TableCell>
                                {
                                  [...Array(12)].map((i: number, index2: number) => (
                                    <TableCell key={index2} number right sx={{ wordBreak: 'normal' }}>
                                      {formatMoneyValue(
                                        tableData[key].lots.filter((lot: any) => {
                                          return dayjs(lot.date).isSame(dayjs().add(index2, 'month'), 'month');
                                        }).reduce((acc: number, lot: any) => acc + lot.amount, 0),
                                      )}
                                    </TableCell>
                                  ))
                                }
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </Box>
                  )}
                </>
              )
            )
          }
        </Card>
      </>
    )}
    </>
  );
};
