import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle, Form, TranslatableTextField, SelectField, Button, MenuItem, TextField,
} from '../../../ovComponents';
import { SchedulerFrequency } from '../../../ovComponents/5-page/clientReportTemplate/components/frequency';
import { UserContext } from '../../../providers/userContextProvider';
import { TranslatedString } from '../../../interfaces';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

interface CreateClientReportTemplate {
  name: TranslatedString;
  description: TranslatedString;
  objectType: string;
  reportType: string;
  schedulerFrequency: string;
  schedulerOneTimeStartDate: Date | string;
  schedulerOneTimeEndDate: Date | string;
}

const CREATE_CLIENT_REPORT_TEMPLATE = gql`
  mutation createClientReportTemplate($input: CreateClientReportTemplateInput!) {
    createClientReportTemplate(input: $input) {
      clientReportTemplate {
        id
        objectType
      }
    }
  }
`;

const CREATE_CLIENT_REPORT_TEMPLATE_PAGE_CONFIGURATION = gql`
  mutation createClientReportTemplatePageConfiguration($input: CreateClientReportTemplatePageConfigurationInput!) {
    createClientReportTemplatePageConfiguration(input: $input) {
      clientReportTemplatePageConfiguration {
        id
      }
    }
  }
`;

const initialTemplate = {
  name: { en: '', fr: '' },
  description: { en: '', fr: '' },
  objectType: '',
  reportType: '',
  schedulerFrequency: '',
  schedulerOneTimeStartDate: '',
  schedulerOneTimeEndDate: '',
};

const NewClientReportTemplate = ({ open, setOpen, afterCreate }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; afterCreate: () => void }) => {
  const { t } = useTranslation('clientReportTemplate');
  const { activeOrganization } = useContext(UserContext);
  const [templateToCreate, setTemplateToCreate] = useState<CreateClientReportTemplate>(initialTemplate);
  const [focused, setFocused] = useState<string[]>([]);
  const objectTypes = useMemo(() => ['ACCOUNT', 'CLIENT_GROUP', 'GOAL', 'SUB_ACCOUNT', 'INDIVIDUAL', 'NON_INDIVIDUAL'], []);
  const reportTypes = useMemo(() => ['PAGE_CONFIGURATION'], []);
  const [createClientReportTemplatePageConfiguration] = useMutation(CREATE_CLIENT_REPORT_TEMPLATE_PAGE_CONFIGURATION);
  const [createClientReportTemplate] = useMutation(CREATE_CLIENT_REPORT_TEMPLATE, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        translatedName: {
          en: templateToCreate?.name?.en,
          fr: templateToCreate?.name?.fr,
        },
        translatedDescription: {
          en: templateToCreate?.description?.en,
          fr: templateToCreate?.description?.fr,
        },
        objectType: templateToCreate?.objectType,
        reportType: templateToCreate?.reportType,
        schedulerFrequency: templateToCreate?.schedulerFrequency,
        schedulerOneTimeDateStart: templateToCreate.schedulerFrequency === SchedulerFrequency.ONE_TIME ? dayjs(templateToCreate.schedulerOneTimeStartDate).format('YYYY-MM-DD') : undefined,
        schedulerOneTimeDateEnd: templateToCreate.schedulerFrequency === SchedulerFrequency.ONE_TIME ? dayjs(templateToCreate.schedulerOneTimeEndDate).format('YYYY-MM-DD') : undefined,
        schedulerDayOfMonthOrWeek: 1,
        templateParameters: [],
      },
    },
  });

  const close = () => {
    setTemplateToCreate(initialTemplate);
    setFocused([]);
    setOpen(false);
  };

  const validate = () => {
    const invalidFieldSet: string[] = [];

    if (!templateToCreate.name.en && !templateToCreate.name.fr) {
      invalidFieldSet.push('name');
    }

    if (!templateToCreate.description.en && !templateToCreate.description.fr) {
      invalidFieldSet.push('description');
    }

    if (!templateToCreate.objectType) {
      invalidFieldSet.push('objectType');
    }

    if (!templateToCreate.reportType) {
      invalidFieldSet.push('reportType');
    }

    if (!templateToCreate.schedulerFrequency) {
      invalidFieldSet.push('schedulerFrequency');
    }

    if (templateToCreate.schedulerFrequency === 'ONE_TIME' && !templateToCreate.schedulerOneTimeStartDate) {
      invalidFieldSet.push('oneTimeStartDate');
    }

    if (templateToCreate.schedulerFrequency === 'ONE_TIME' && !templateToCreate.schedulerOneTimeEndDate) {
      invalidFieldSet.push('oneTimeEndDate');
    }

    setFocused(invalidFieldSet);
    return invalidFieldSet.length === 0;
  };

  const createDashboard = async () => {
    if (!validate()) {
      return;
    }

    const { data } = await createClientReportTemplate();
    const templateId = data?.createClientReportTemplate?.clientReportTemplate?.id;
    const templateObjectType = data?.createClientReportTemplate?.clientReportTemplate?.objectType;

    if (templateToCreate.reportType === 'PAGE_CONFIGURATION') {
      await createClientReportTemplatePageConfiguration({
        variables: {
          input: {
            clientReportTemplateId: templateId,
            type: templateObjectType === 'CLIENT_GROUP' ? 'HOUSEHOLD' : templateObjectType,
            widgets: [],
          },
        },
      });
    }

    afterCreate();
    setTemplateToCreate(initialTemplate);
    setFocused([]);
    setOpen(false);
  };

  return (
    <Dialog fullWidth open={open} onClose={close}>
      <Form onSubmit={createDashboard}>
        <DialogTitle onClose={close}>{t('newModal.title')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TranslatableTextField
            fullWidth
            label={t('newModal.name')}
            onBlur={() => setFocused([...focused, 'name'])}
            error={!templateToCreate.name.en && !templateToCreate.name.fr && focused.includes('name')}
            value={templateToCreate?.name ?? { en: '', fr: '' }}
            onChange={(e: TranslatedString) => setTemplateToCreate({ ...templateToCreate, name: e })}
          />
          <TranslatableTextField
            fullWidth
            label={t('shared:description')}
            onBlur={() => setFocused([...focused, 'description'])}
            error={!templateToCreate.description.en && !templateToCreate.description.fr && focused.includes('description')}
            value={templateToCreate?.description ?? { en: '', fr: '' }}
            onChange={(e: TranslatedString) => setTemplateToCreate({ ...templateToCreate, description: e })}
          />
          <SelectField
            fullWidth
            label={t('newModal.objectType')}
            error={!templateToCreate.objectType && focused.includes('objectType')}
            errorText={t('components:translatableString.errorText')}
            onBlur={() => setFocused([...focused, 'objectType'])}
            value={templateToCreate.objectType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemplateToCreate({ ...templateToCreate, objectType: e.target.value })}
          >
            {objectTypes.map((objectType: any) => (
              <MenuItem value={objectType}>{t(`orgSettings:clientReportTemplates.associations.${objectType}`)}</MenuItem>
            ))}
          </SelectField>
          <SelectField
            fullWidth
            label={t('newModal.reportType')}
            error={!templateToCreate.reportType && focused.includes('reportType')}
            errorText={t('components:translatableString.errorText')}
            onBlur={() => setFocused([...focused, 'reportType'])}
            value={templateToCreate.reportType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemplateToCreate({ ...templateToCreate, reportType: e.target.value })}
          >
            {reportTypes.map((reportType: any) => (
              <MenuItem value={reportType}>{t(`newModal.reportTypes.${reportType}`)}</MenuItem>
            ))}
          </SelectField>
          <SelectField
            fullWidth
            label={t('newModal.frequency')}
            error={!templateToCreate.schedulerFrequency && focused.includes('schedulerFrequency')}
            errorText={t('components:translatableString.errorText')}
            onBlur={() => setFocused([...focused, 'schedulerFrequency'])}
            value={templateToCreate.schedulerFrequency}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemplateToCreate({ ...templateToCreate, schedulerFrequency: e.target.value })}
          >
            {Object.keys(SchedulerFrequency).map((frequency: any) => (
              <MenuItem value={frequency}>{t(`frequencies.${frequency.toLowerCase()}`)}</MenuItem>
            ))}
          </SelectField>
          {templateToCreate.schedulerFrequency === 'ONE_TIME' && (
            <>
              <LocalizedDatePicker
                value={templateToCreate.schedulerOneTimeStartDate}
                onChange={(date: Date) => setTemplateToCreate({ ...templateToCreate, schedulerOneTimeStartDate: date })}
                onAccept={(date: Date) => setTemplateToCreate({ ...templateToCreate, schedulerOneTimeStartDate: date })}
                renderInput={(params: any) => (
                  <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    {...params}
                    type='default'
                    label={t('frequencies.oneTimeStart')}
                    onBlur={() => setFocused([...focused, 'oneTimeStartDate'])}
                    error={!templateToCreate.schedulerOneTimeStartDate && focused.includes('oneTimeStartDate')}
                    errorText={t('components:translatableString.errorText')}
                  />
                )}
              />
              <LocalizedDatePicker
                value={templateToCreate.schedulerOneTimeEndDate}
                onChange={(date: Date) => setTemplateToCreate({ ...templateToCreate, schedulerOneTimeEndDate: date })}
                onAccept={(date: Date) => setTemplateToCreate({ ...templateToCreate, schedulerOneTimeEndDate: date })}
                renderInput={(params: any) => (
                  <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    {...params}
                    type='default'
                    label={t('frequencies.oneTimeEnd')}
                    onBlur={() => setFocused([...focused, 'oneTimeEndDate'])}
                    error={!templateToCreate.schedulerOneTimeEndDate && focused.includes('oneTimeEndDate')}
                    errorText={t('components:translatableString.errorText')}
                  />
                )}
              />
            </>
          )}
        </DialogContent>
        <DialogFooter sx={{ gap: 1, padding: '12px' }}>
          <Button variant='tonal' label={t('shared:cancel')} onClick={close} />
          <Button label={t('shared:create')} type='submit' data-testid='clientreporttemplate-create-button' />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default NewClientReportTemplate;
