/* eslint-disable react-hooks/exhaustive-deps */
import { Box, ListItem, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { TransferProp } from './newTransfer';
import { formatMoneyValue } from '../../../util';

export interface CalculateWithdrawalFeesData {
  calculateWithdrawFees: {
    withdrawFees: WithdrawFee[];
  };
}

export interface WithdrawFee {
  type: string;
  amountCents: number;
}

export const CALCULATE_WITHDRAWAL_FEES = gql`
  query calculateWithdrawFees($input: CalculateWithdrawFeesInput!) {
    calculateWithdrawFees(input: $input) {
      withdrawFees {
        type
        amountCents
      }
    }
  }
`;

const CalculateFeesForNewTransfer = ({
  transfer,
  accountId,
  exceedWithdrawAllLimit,
  onFeesReceived,
}: {
  transfer: TransferProp;
  accountId: string;
  exceedWithdrawAllLimit: boolean;
  onFeesReceived: (fees: WithdrawFee[]) => void;
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const [fetchingFees, setFetchingFees] = useState(false);
  const [withdrawalFees, setWithdrawalFees] = useState<{ type: string; amountCents: number }[]>([]);

  const setCalculateWithdrawalFeesData = (data: CalculateWithdrawalFeesData): void => {
    let feesCents = 0;

    data.calculateWithdrawFees.withdrawFees.forEach((withdrawFee) => {
      feesCents += withdrawFee.amountCents;
    });

    const withdrawFees = [...data.calculateWithdrawFees.withdrawFees];

    let eftOutAmountCents = 0;

    if (transfer.amountCents) {
      eftOutAmountCents = transfer.amountCents - feesCents;
    } else if (transfer.netAmountCents) {
      eftOutAmountCents = transfer.netAmountCents;
    } else if (transfer.isWithdrawAll) {
      eftOutAmountCents = transfer.subAccountMarketValueCents - feesCents;
    }

    onFeesReceived(withdrawFees);

    withdrawFees.push({
      type: 'EFT_OUT',
      amountCents: eftOutAmountCents,
    });

    setWithdrawalFees(withdrawFees);
    setFetchingFees(false);
  };

  const [calculateWithdrawalFees] = useLazyQuery(CALCULATE_WITHDRAWAL_FEES, {
    onCompleted: (e) => setCalculateWithdrawalFeesData(e),
    onError: () => setFetchingFees(false),
  });

  const runCalculateWithdrawalFees = useCallback(
    debounce((variables) => {
      setFetchingFees(true);
      calculateWithdrawalFees({ variables });
    }, 500),
    [],
  );

  useEffect(() => {
    if (transfer.subAccountId && accountId && transfer.source !== 'ADJUSTMENT') {
      if (transfer.amountCents || transfer.netAmountCents) {
        runCalculateWithdrawalFees({
          input: {
            accountId,
            amountCents: transfer.isWithdrawAll ? transfer.subAccountMarketValueCents : transfer.amountCents || transfer.netAmountCents,
            isfullWithdraw: transfer.isWithdrawAll || exceedWithdrawAllLimit,
            ...(transfer.netAmountCents && { net: true }),
          },
        });
      } else {
        setWithdrawalFees([]);
      }
    } else {
      setWithdrawalFees([]);
    }
  }, [accountId, transfer]);

  const breakdownItems = (children: JSX.Element, value: string, key?: string): JSX.Element => (
    <Box key={key}>
      <Box display='flex' alignItems='center'>
        {children}
      </Box>
      <Typography variant='body2'>{value}</Typography>
    </Box>
  );

  return (
    <>
      {withdrawalFees.length > 0 && (
        <ListItem>
          {fetchingFees
            ? t('components:transferModal.fetchingFees')
            : withdrawalFees.map((withdrawalFee) => breakdownItems(
                  <Typography variant='body2' style={{ marginBottom: 0, marginRight: '7.5px' }}>
                    {withdrawalFee.type === 'EFT_OUT' ? t('components:transferModal.eftOut') : t(`components:transferSources.${withdrawalFee.type}`)}
                  </Typography>,
                  formatMoneyValue(withdrawalFee.amountCents, '$', 2, false),
                  withdrawalFee.type,
            ))}
        </ListItem>
      )}
    </>
  );
};

export default CalculateFeesForNewTransfer;
