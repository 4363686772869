import { gql } from '@apollo/client';

export const PREVIEW_FORM_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) {
      html
    }
  }
`;

export const FETCH_FORM_AGREEMENT_SIGNED_URL = gql`
  query fetchFormAgreement($input: FetchFormAgreementInput!) {
    fetchFormAgreement(input: $input) {
      id
      signedUrl
    }
  }
`;

export const SIGN_FORM_AGREMETNS = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
        type
        version
        signedAt
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_INCOMPLETE_FORMS = gql`
  query fetchSubAccountIncompleteForms($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        state
        account {
          id
          type
          state
        }
        allIncompleteFormAgreements {
          type
          minVersion
          user {
            id
          }
          account {
            id
          }
          goal {
            id
            name
          }
          subAccount {
            id
          }
          scheduledTransfer {
            id
            type
          }
          translatedDisplayName {
            en
            fr
          }
          templateUrl
        }
      }
    }
  }
`;

export const FETCH_ALL_LATEST_FORM_AGREEMENT = gql`
  query fetchFormAgreements($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        allLatestFormAgreements{
          id
          type
          translatedDisplayName {
            en
            fr
          }
          signedAt
          user{
            id
          }
          account{
            id
            type
            custodianAccountNumber
          }
          subAccount{
            id
            goal {
              name
            }
            account {
              id
              type
              custodianAccountNumber
            }
          }
          goal{
            id
            name
          }
          signedUrl
          scheduledTransfer {
            state
            subAccount {
              account {
                id
                type
                custodianAccountNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_ACCOUNT_LATEST_FORM_AGREEMENT = gql`
  query fetchAccountFormAgreements($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        latestFormAgreements{
          id
          type
          translatedDisplayName {
            en
            fr
          }
          signedAt
          user{
            id
          }
          account{
            id
            type
            custodianAccountNumber
          }
          subAccount{
            id
            goal {
              name
            }
            account {
              id
              type
              custodianAccountNumber
            }
          }
          goal{
            id
            name
          }
          signedUrl
          scheduledTransfer {
            state
            subAccount {
              account {
                id
                type
                custodianAccountNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_HOUSEHOLD_LATEST_FORM_AGREEMENT = gql`
  query fetchClientGroupFormAgreements($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        allLatestFormAgreements{
          id
          type
          translatedDisplayName {
            en
            fr
          }
          signedAt
          user{
            id
          }
          account{
            id
            type
            custodianAccountNumber
          }
          subAccount{
            id
            goal {
              name
            }
            account {
              id
              type
              custodianAccountNumber
            }
          }
          goal{
            id
            name
          }
          signedUrl
          scheduledTransfer {
            state
            subAccount {
              account {
                id
                type
                custodianAccountNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const RENDER_FORM_AGREEMENT = gql`
  query renderFormAgreement($input: RenderFormAgreementInput!) {
    renderFormAgreement(input: $input) {
      html
    }
  }
`;

export const FETCH_ACCOUNT_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        type
        fileName
        translatedFileName {
          en
          fr
        }
        signedUrl
        state
        referenceDate
      }
      totalCount
    }
  }
`;

export const FETCH_FILE_DOCUMENTS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        type
        name
        fileName
        uploadedAt
        downloadUrl
      }
      totalCount
    }
  }
`;

export const FETCH_USER_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        custodianAccountNumber
        type
      }
    }
  }
`;

export const FETCH_CUSTODIAN_STATEMENTS = gql`
query fetchCustodianStatements($input: FetchCustodianStatementsInput!) {
  fetchCustodianStatements(input: $input) {
    totalCount
    statements{
      referenceDate
      fileName
      s3key
      signedUrl
      signedUrlFormat
      translatedFileName{
        en
      }
    }
  }
}
`;
