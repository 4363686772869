import { Account, AccountTypes } from './account';
import { BankAccount } from './bankAccount';
import { FinancialProduct } from './financialProduct';
import { PhysicalAddress } from './physicalAddress';
import { SubAccount } from './subAccount';
import { User } from './user';

/** Supported Transfer sources. */
export enum TransferSources {
  USER = 'USER',
  FEE = 'FEE',
  DEREGISTRATION = 'DEREGISTRATION',
  DEREGISTRATION_TAX = 'DEREGISTRATION_TAX',
  WITHHOLDING_TAX = 'WITHHOLDING_TAX',
  DIVIDEND = 'DIVIDEND',
  DISTRIBUTION = 'DISTRIBUTION',
  TO_SUB_ACCOUNT = 'TO_SUB_ACCOUNT',
  FROM_SUB_ACCOUNT = 'FROM_SUB_ACCOUNT',
  TO_ACCOUNT = 'TO_ACCOUNT',
  FROM_ACCOUNT = 'FROM_ACCOUNT',
  EXTERNAL_TRANSFER_IN = 'EXTERNAL_TRANSFER_IN',
  EXTERNAL_TRANSFER_OUT = 'EXTERNAL_TRANSFER_OUT',
  ADJUSTMENT = 'ADJUSTMENT',
  CUSTODIAN_ACTIVITY = 'CUSTODIAN_ACTIVITY',
  GOV_CONTRIBUTIONS = 'GOV_CONTRIBUTIONS',
  INCOME_FUND_TRANSFER = 'INCOME_FUND_TRANSFER',
  CHEQUE = 'CHEQUE',
}

/** Transfer processing steps. */
export enum TransferState {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Processing = 'PROCESSING',
  Ready = 'READY',
  Reconciled = 'RECONCILED',
  Requested = 'REQUESTED',
  Reviewing = 'REVIEWING',
}

/** Transfer processing steps. */
export enum ScheduleTransferState {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
}

/** Supported Transfer types. */
export enum TransferType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
}

export enum WithdrawalMode {
  Gross = 'GROSS',
  Net = 'NET',
}

/** Withdrawal reasons. */
export enum WithdrawalReason {
  Car = 'CAR',
  CompletedMyGoal = 'COMPLETED_MY_GOAL',
  DissatisfiedWithOnevest = 'DISSATISFIED_WITH_ONEVEST',
  Education = 'EDUCATION',
  Emergency = 'EMERGENCY',
  HomeBuyersPlan = 'HOME_BUYERS_PLAN',
  House = 'HOUSE',
  InvestingIsNotForMe = 'INVESTING_IS_NOT_FOR_ME',
  LifelongLearningPlan = 'LIFELONG_LEARNING_PLAN',
  MajorPurchase = 'MAJOR_PURCHASE',
  Other = 'OTHER',
  ParentalLeave = 'PARENTAL_LEAVE',
  PoorReturns = 'POOR_RETURNS',
  RetirementIncome = 'RETIREMENT_INCOME',
  StartABusiness = 'START_A_BUSINESS',
  Vacation = 'VACATION',
  Wedding = 'WEDDING',
}

export enum ManualProcessTypes {
  CloseAccount = 'CLOSE_ACCOUNT',
  TransferFromOutsideAccount = 'TRANSFER_FROM_OUTSIDE_ACCOUNT',
}

export enum ExternalTransferTypes {
  AllInCash = 'ALL_IN_CASH',
  PartialCash = 'PARTIAL_CASH',
}

export type Transfer = {
  __typename?: 'Transfer';
  amountCents: number;
  bankAccount?: BankAccount;
  createdAt: Date;
  dividendFor?: FinancialProduct;
  failReason?: string;
  id: string;
  instantInvest?: boolean;
  isRecurring?: boolean;
  isWithdrawAll?: boolean;
  reconciledAt?: Date;
  requestId?: string;
  source: TransferSources;
  sourceId?: string;
  state: TransferState;
  stateChanges?: any[];
  subAccount: SubAccount;
  type: TransferType;
  withdrawalReason?: WithdrawalReason;
  withdrawalReasonDescription?: string;
  isSuspicious: boolean;
  alertReport: any;
  manualProcess: ManualProcess;
  scheduledDate?: Date
};

enum Frequency {
  'ONE_TIME',
  'DAILY',
  'WEEKLY',
  'SEMI_MONTHLY',
  'MONTHLY',
  'LAST_DAY_OF_THE_MONTH',
  'BI_WEEKLY',
}

enum ScheduledTransferStates {
  'ACTIVE',
  'DRAFT',
  'CANCELED',
}

export type ScheduledTransfer = {
  requestId?: string
  id: string
  type: TransferType
  subAccount?: SubAccount
  frequency?: Frequency
  scheduledDate?: Date
  endDate?: Date
  amountCents?: number
  state?: ScheduledTransferStates
  bankAccount?: BankAccount
  toSubAccount?: SubAccount
  transferAll?: boolean
  withdrawalReason?: WithdrawalReason
  withdrawalReasonDescription?: string
  createdAt?: Date
};

export type Institution = {
  __typename?: 'Institution';
  id: string;
  isDraft: string;
  name: string;
  physicalAddress?: PhysicalAddress;
};

enum ManualProcessStates {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Processing = 'PROCESSING',
  Reconciled = 'RECONCILED',
}

export type ManualProcess = {
  account: Account;
  createdAt: Date;
  id: string;
  institution?: Institution;
  signedUrl?: string;
  state?: ManualProcessStates;
  stateChanges?: any[];
  subAccount: SubAccount;
  transferAccount?: AccountTypes;
  transferAccountNumber?: string;
  transferMethod?: ExternalTransferTypes;
  type: ManualProcessTypes;
  user: User;
};
