import { useTranslation } from 'react-i18next';
import { TranslatedString } from 'interfaces';
import {
  Box,
} from 'ovComponents/1-primative';
import {
  Checkbox, TextField,
  TranslatableTextField,
} from 'ovComponents/2-component';
import { useEffect } from 'react';
import { translateBackend } from '../../../assets/i18n/config';

interface FieldEditProps {
  option: any,
  options: any,
  setOptions: (x: any) => void,
  i: number,
}

const I18N_PREFIX = 'workflowCompletions:subStepOptions.optionTypes.DEFAULT_VALUE_FIELD';

export const DefaultValue = (props: FieldEditProps) => {
  const {
    option, options, setOptions, i,
  } = props;
  const { t } = useTranslation(['workflowCompletions']);
  const defaultValueOpts = option?.value?.defaultValue ?? {
    checked: false,
    en: '',
    fr: '',
  };

  const getCheckboxLabel = (): string => {
    const defaultValue = option.options?.defaultValueOptions?.label ?? option.options.defaultValueLabel;
    if (defaultValue && defaultValue.en) {
      return translateBackend(defaultValue);
    }
    return t(`${I18N_PREFIX}.checkboxLabel`);
  };

  // Helper function to encapsulate verbose references when
  // updating options[field].value.selectField
  const updateDefaultValueOptions = (opts: object) => {
    const newOptions = [...options];
    newOptions[i].value = {
      ...option.value,
      defaultValue: {
        ...defaultValueOpts,
        ...opts,
      },
    };
    setOptions(newOptions);
  };

  const handleChecked = (onoff: boolean) => {
    updateDefaultValueOptions({ checked: onoff });
  };

  const handleTextChange = (value: TranslatedString) => {
    updateDefaultValueOptions(value);
  };

  useEffect(() => {
    if (option.value.defaultValue) {
      handleTextChange(option.value.defaultValue);
    } else {
      let defaultValue;
      const defaultObj: any = option.options?.defaultValueOptions?.default;
      if (defaultObj) {
        if ('value' in defaultObj) {
          defaultValue = {
            en: option.options?.defaultValueOptions?.default?.value,
          };
        } else {
          defaultValue = {
            en: option.options?.defaultValueOptions?.default?.en,
            fr: option.options?.defaultValueOptions?.default?.fr,
          };
        }
      } else {
        defaultValue = option?.options?.defaultValue;
      }
      handleTextChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isBasicField = option.options?.defaultValueOptions?.optionType === 'BASIC_FIELD';
  return (
    <Box>
      <Checkbox
        size='small'
        label={getCheckboxLabel()}
        checked={defaultValueOpts.checked}
        onChange={handleChecked}
      />
      {defaultValueOpts.checked
        && (isBasicField ? (
          <TextField
            value={option?.value?.defaultValue.en}
            fullWidth
            rows={1}
            onChange={(e: any) => {
              const value: TranslatedString = {
                en: e.target.value,
              };
              handleTextChange(value);
            }}
          />
        ) : (
          <TranslatableTextField
            value={option?.value?.defaultValue}
            dialogLabel={t(`${I18N_PREFIX}.checkboxLabel`)}
            fullWidth
            rows={1}
            onChange={handleTextChange}
          />
        ))
      }
    </Box>
  );
};
