import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Typography, Grid, Card, CardContent, Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import BookmarkAddRoundedIcon from '@mui/icons-material/BookmarkAddRounded';
import BookmarkRemoveRoundedIcon from '@mui/icons-material/BookmarkRemoveRounded';
import { formatMoneyValue } from '../../../util';
import { colors } from '../../../theme/colors';

const FETCH_REBALANCE_METRICS = gql`
  query fetchRebalanceMetrics($input: FetchRebalanceMetricsInput!) {
    fetchRebalanceMetrics(input: $input) {
      rebalanceMetrics{
        state
        id
        accounts
        users
        sellQuantity
        buyQuantity
        sellValueCents
        buyValueCents
        screenedAt
        type
        subAccounts
      }

    }
  }
`;

const CREATE_REBALANCE_METRICS = gql`
  mutation calculatePreRebalanceMetrics($input: CalculatePreRebalanceMetricsInput!) {
    calculatePreRebalanceMetrics(input: $input) {
      rebalanceMetrics{
        id
        state
      }
    }
  }
`;

const styles = {
  metricsCage: {
    border: `1px solid ${colors.gray500}`,
    height: '70px',
    display: 'block',
    justifyContent: 'center',
    marginLeft: '8px',
    textAlign: 'center',
  },
  header: {
    color: colors.gray500,
    position: 'relative',
    // top: '-10px',
    width: '100%',
  },
  loading: {
    color: colors.gray500,
    position: 'relative',
    top: '-10px',
    marginLeft: '8px',
    width: '100%',
  },
  stat: {
    fontWeight: 700,
    width: '100%',
    wordBreak: 'break-all',
  },
  boughtIcons: {
    color: '#2CA58D',
  },
  soldIcons: {
    color: '#E65C64',
  },
};

const Metrics = ({ organizationId }: { organizationId: string }) => {
  const { t } = useTranslation(['rebalanceReportV1']);

  const [fetchRebalanceMetrics, { data: rebalanceMetricsResponse, error: rebalanceMetricsError, stopPolling }] = useLazyQuery(FETCH_REBALANCE_METRICS, {
    variables: {
      input: {},
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });

  const [calculatePreRebalanceMetrics] = useMutation(CREATE_REBALANCE_METRICS, {
    variables: {
      input: {
        organizationId,
      },
    },
    onCompleted: (data) => {
      fetchRebalanceMetrics({
        variables: {
          input: {
            rebalanceMetricsId: data?.calculatePreRebalanceMetrics?.rebalanceMetrics?.id,
          },
        },
      });
    },
  });
  if (rebalanceMetricsResponse && rebalanceMetricsResponse.fetchRebalanceMetrics.rebalanceMetrics.state === 'COMPLETED') {
    stopPolling();
  }

  useEffect(() => {
    calculatePreRebalanceMetrics({
      variables: {
        input: {
          organizationId,
        },
      },
    });
  }, [calculatePreRebalanceMetrics, organizationId]);

  if (rebalanceMetricsError || rebalanceMetricsError) (<Typography>Error</Typography>);

  return (
    <>
      {rebalanceMetricsResponse && rebalanceMetricsResponse.fetchRebalanceMetrics.rebalanceMetrics.state === 'GENERATING'
        && <Typography sx={styles.loading} variant="caption">{t('metrics.generating')}</Typography>
      }
      {rebalanceMetricsResponse && rebalanceMetricsResponse.fetchRebalanceMetrics.rebalanceMetrics.state === 'COMPLETED'
        && <Typography sx={styles.loading} variant="caption">{t('metrics.generatedAt')} {dayjs(rebalanceMetricsResponse.fetchRebalanceMetrics.rebalanceMetrics.screenedAt).format()}</Typography>
      }

      <Grid container spacing={2} columns={10}>
        <Grid item xs={2}>
          <Card variant="outlined" sx={{ minWidth: '200px', height: '130px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box><PeopleIcon sx={{ color: colors.blue400 }}/></Box>
              <Typography sx={styles.header} variant="subtitle2">{t('metrics.totalAccounts')}</Typography>
              <Typography variant="h5" sx={styles.stat}> {(rebalanceMetricsResponse?.fetchRebalanceMetrics?.rebalanceMetrics.accounts || 0).toLocaleString()} </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card variant="outlined" sx={{ minWidth: '200px', height: '130px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box><AddCircleRoundedIcon sx={styles.boughtIcons}/></Box>
              <Typography sx={styles.header} variant="subtitle2">{t('metrics.totalBuy')}</Typography>
              <Typography variant="h5" sx={styles.stat}> {formatMoneyValue(rebalanceMetricsResponse?.fetchRebalanceMetrics?.rebalanceMetrics.buyValueCents || 0)} </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={2}>
          <Card variant="outlined" sx={{ minWidth: '200px', height: '130px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box><BookmarkAddRoundedIcon sx={styles.boughtIcons}/></Box>
              <Typography sx={styles.header} variant="subtitle2">{t('metrics.totalBuyShares')}</Typography>
              <Typography variant="h5" sx={styles.stat}> {(rebalanceMetricsResponse?.fetchRebalanceMetrics?.rebalanceMetrics.buyQuantity || 0).toLocaleString()} </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={2}>
          <Card variant="outlined" sx={{ minWidth: '200px', height: '130px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box><RemoveCircleRoundedIcon sx={styles.soldIcons} /></Box>
              <Typography sx={styles.header} variant="subtitle2">{t('metrics.totalSell')}</Typography>
              <Typography variant="h5" sx={styles.stat}> {formatMoneyValue(rebalanceMetricsResponse?.fetchRebalanceMetrics?.rebalanceMetrics.sellValueCents || 0)} </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={2}>
          <Card variant="outlined" sx={{ minWidth: '200px', height: '130px' }}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Box><BookmarkRemoveRoundedIcon sx={styles.soldIcons} /></Box>
              <Typography sx={styles.header} variant="subtitle2">{t('metrics.totalSellShares')}</Typography>
              <Typography variant="h5" sx={styles.stat}> {(rebalanceMetricsResponse?.fetchRebalanceMetrics?.rebalanceMetrics.sellQuantity || 0).toLocaleString()} </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Metrics;
