import { WorkflowCompletion } from '../../4-module/workflowCompletion/workflowCompletion';

export const InnerWorkflowModal = ({
  open,
  setOpen,
  newActiveWorkflow,
  refetch,
  updateParent,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  newActiveWorkflow: any;
  refetch?: () => any,
  updateParent?: (activeCompletion?: any) => any,
}) => (
  <WorkflowCompletion
    workflowCompletion={newActiveWorkflow}
    open={open}
    onClose={() => {
      setOpen(false);
      if (refetch) {
        refetch();
      }
    }}
    isInnerWorkflow
    updateParent={updateParent}
  />
);
