import { Grid, ListItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import AmountField from '../../../components/inputs/amountField';
import NumberField from '../../../components/inputs/numberField';
import { FeeGrid } from '../../../interfaces';
import { colors } from '../../../theme/colors';

const BuildGrid = ({
  grid, setGrid, disabled = false,
} : {
  grid: FeeGrid[], setGrid: (grid: FeeGrid[]) => void, disabled?: boolean,
}) => {
  const { t } = useTranslation(['feeAndBilling']);
  const mappedGrid = grid.map((g, i, list) => ({
    ...g,
    minRangeAmount: (g.minMarketValueCents ?? 0) + (i > 0 ? 1 : 0),
    maxRangeAmount: (list.length - 1 > i) ? list[i + 1].minMarketValueCents : undefined,
  }));
  return (
    <>
      {mappedGrid.map((g, i) => (
        <ListItem key={i}>
          <Grid container spacing={1}>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.minAmount')}
                variant="outlined"
                value={g.minRangeAmount}
                fullWidth
                size='small'
                disabled
                data-testid={`${i}-minRange`}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>{t('feeAndBilling:feeGrid.edit.grid.to')}</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.maxAmount')}
                variant="outlined"
                value={g.maxRangeAmount}
                fullWidth
                size='small'
                disabled={disabled || i >= 9}
                data-testid={`${i}-maxRange`}
                placeholder={t('feeAndBilling:feeGrid.edit.grid.above')}
                onChange={(e: any) => {
                  if (i >= 9) return;
                  if (mappedGrid.length === i + 1 && e.target.valueCents) {
                    mappedGrid.push({ minRangeAmount: (e.target.valueCents + 1), maxRangeAmount: undefined });
                  }
                  if (mappedGrid[i + 1] && e.target.valueCents) {
                    mappedGrid[i + 1].minMarketValueCents = e.target.valueCents;
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>=</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <NumberField
                label={t('feeAndBilling:feeGrid.edit.grid.annualFeeBps')}
                fullWidth
                size='small'
                disabled={disabled}
                data-testid={`${i}-annualFeeBps`}
                decimalPlaces={2}
                value={g.annualFeeBps ?? ''}
                setNumberValue={(value) => {
                  if (mappedGrid[i]) {
                    mappedGrid[i].annualFeeBps = value;
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              <Typography>+</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <AmountField
                label={t('feeAndBilling:feeGrid.edit.grid.annualFeeCents')}
                variant="outlined"
                value={g.annualFeeCents ?? ''}
                fullWidth
                size='small'
                disabled={disabled}
                data-testid={`${i}-annualFeeCents`}
                onChange={(e: any) => {
                  if (mappedGrid[i]) {
                    mappedGrid[i].annualFeeCents = e.target.valueCents;
                    setGrid(mappedGrid);
                  }
                }}
              />
            </Grid>
            <Grid item xs={0.5} sx={{ alignSelf: 'center' }}>
              {grid && i > 0 && !disabled && (
                <CloseIcon onClick={() => setGrid(grid.filter((f, index) => index !== i))} sx={{ cursor: 'pointer', color: colors.primary }}/>
              )}
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </>
  );
};

export default BuildGrid;
