import { Box, Typography } from '../../../1-primative';
import { Card, CardContent } from '../../../2-component';
import { MarketValueChart } from '../../../4-module/clientReportWidgets/marketValueChart/marketValueChart';
import { Accounts } from '../../../4-module/clientReportWidgets/accounts/accounts';
import { Ledger } from '../../../4-module/clientReportWidgets/ledger/ledger';
import { Holdings } from '../../../4-module/clientReportWidgets/holdings/holdings';
import ErrorBoundary from '../../clientReportTemplate/components/pageConfiguration/errorBoundary';
import { PageObjectType } from '../../pageConfiguration/pageConfiguration';
import { HtmlTemplate } from '../../../4-module/widgets/htmlTemplate';
import { RateOfReturns } from '../../../4-module/clientReportWidgets/rateOfReturns/rateOfReturns';

export const PrintWidget = ({
  type,
  i,
  objectId,
  options,
  objectType,
  widgetId,
  userId,
}: {
  type: string;
  i: number;
  objectId: string;
  options: any;
  objectType: PageObjectType;
  widgetId?: string;
  userId?: string;
}) => {
  const widget = (widgetType: string) => {
    switch (widgetType) {
      case 'MARKET_VALUE_CHART':
        return <MarketValueChart data-testid='market-value-chart' type={objectType} id={objectId} options={options} i={i} print={true} />;
      case 'ACCOUNTS':
        return <Accounts data-testid='accounts-widget' objectType={objectType} objectId={objectId} options={options} i={i} print={true} />;
      case 'LEDGER':
        return <Ledger data-testid='ledger-widget' objectType={objectType} objectId={objectId} options={options} i={i} print={true} />;
      case 'HOLDINGS':
        return <Holdings data-testid='holdings-widget' objectType={objectType} objectId={objectId} options={options} i={i} print={true} />;
      case 'HTML_TEMPLATE':
        return <HtmlTemplate data-testid='html-template-widget' objectType={objectType} objectId={objectId} options={options} i={i} print={true} />;
      case 'RATE_OF_RETURNS':
        return <RateOfReturns data-testid='rate-of-returns-widget' objectType={objectType} objectId={objectId} options={options} i={i} print={true} />;
      default:
        return (
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ justifyContent: 'space-between', display: 'flex' }}>
              <Typography variant='headingXSmall'>{type}</Typography>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <ErrorBoundary>
      <Box height='100%' sx={{ position: 'relative', mb: 4 }}>
        {widget(type)}
      </Box>
    </ErrorBoundary>
  );
};
