import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Update } from '@mui/icons-material';

const StatusChip = ({ label, color }: { label: string, color: 'success' | 'pending' }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {color === 'success' ? <CheckCircleIcon color='success' sx={{ fontSize: '20px' }} /> : <Update sx={{ fontSize: '20px', color: '#667380' }} />}
    <Typography sx={{
      display: 'inline', fontSize: '14px', fontWeight: '600', ml: '10px !important',
    }}>{label}</Typography>
  </Box>
);

export default StatusChip;
