import { gql } from '@apollo/client';

const FEE_DETAILS = `
  feeTier {
    id
    name
  }
  billingSchedule {
    id
  }
`;

export const USER_FETCH = gql`
  query fetchUser($id: ObjectID!) {
    fetchUser(userId: $id) {
      user {
        ${FEE_DETAILS}
      }
    }
  }
`;

export const ACCOUNT_FETCH = gql`
  query fetchAccount($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        ${FEE_DETAILS}
        user {
          id
        }
        feePaymentAccount {
          id
        }
      }
    }
  }
`;

export const SUB_ACCOUNT_FETCH = gql`
  query fetchSubAccount($id: ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        ${FEE_DETAILS}
      }
    }
  }
`;

export const HOUSEHOLD_FETCH = gql`
  query fetchClientGroup($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        ${FEE_DETAILS}
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const UPDATE_HOUSEHOLD = gql`
  mutation updateClientGroup($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup {
        id
      }
    }
  }
`;

export const FETCH_ACCOUNTS = (permissions:string[]) => gql`
  query fetchAccounts($filter: AccountsQueryFilter!) {
    fetchAccounts(input:{filter:$filter, pagination:{perPage: 1000}}) {
      accounts {
        id
        type
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
      }
    }
  }
`;
