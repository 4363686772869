import { ReviewVisual } from './review.visual';

export const Review = ({
  options, onNext, stepLoading,
}: { options: any, onNext: () => void, stepLoading: boolean, }) => (
  <ReviewVisual
    options={options}
    continueFunc={onNext}
    loading={stepLoading}
  />
);

export default Review;
