import { Alert, AlertTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoPermissionAlert = ({ missing = '' }:{ missing?:string }) => {
  const { t } = useTranslation();
  return (
    <Alert severity="warning" sx={{ padding: 5 }}>
      <AlertTitle>{t('shared:noPermission')}</AlertTitle>
      {missing && (
        <Typography variant='caption'>{t('shared:missingPermissionIs')}: &nbsp;
          <kbd>{missing}</kbd>
        </Typography>
      )}
    </Alert>
  );
};
