import { gql, useQuery } from '@apollo/client';
import { Search } from '@mui/icons-material';
import {
  Box, CircularProgress, Grid, InputAdornment, Pagination, Paper, Table,
  TableBody, TableCell, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../providers/userContextProvider';
import { formatMoneyValue } from '../../util';
import { usePageState } from '../../util/usePageState';
import { Date } from '../../components/misc/date/date';
import { ClientGroup } from '../../interfaces';
import NewCorporation from './newCorporation';

export const FETCH_CORPORATIONS = gql`
  query fetchCorporations($searchText: String, $organizationId: ObjectID, $pagination: UserPaginationAndSorting) {
    fetchClientGroups(input:{
      filter: {
        type: BUSINESS
        searchText: $searchText
        organizationId: $organizationId
      }
      pagination: $pagination
    }) {
      totalCount
      clientGroups {
        id
        type
        name
        primaryEmail
        primaryUser { firstName lastName }
        completedAt
        accounts { id }
        statistics { marketValueCents }
        organization { name }
      }
    }
  }
`;

const Corporations = () => {
  const { t } = useTranslation('corporations');
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const pageSize = 15;
  const { loading, data, previousData } = useQuery(FETCH_CORPORATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchText,
      organizationId: activeOrganization.id,
      pagination: {
        sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
      },
    },
  });

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <Paper>
        <Grid container justifyContent='space-between'>
          <TextField
            label={t('search')}
            sx={{ width: '300px', m: 1 }}
            size='small'
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            id='searchText'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Grid item m={1}>
            <NewCorporation/>
          </Grid>
        </Grid>
        {loading && !previousData ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.primaryContact')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.primaryEmail')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.completedAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.numberOfAccounts')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.totalAssets')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchClientGroups?.clientGroups?.map((cg: ClientGroup) => (
                <TableRow
                  hover
                  key={cg.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/corporations/${cg.id}`)}
                >
                  <TableCell>
                    {cg.name}
                  </TableCell>
                  <TableCell>
                    {cg.primaryUser.firstName} {cg.primaryUser.lastName}
                  </TableCell>
                  <TableCell>
                    {cg.primaryEmail}
                  </TableCell>
                  <TableCell>
                    <Date variant='subtitle2' date={cg.completedAt} />
                  </TableCell>
                  <TableCell>
                    {cg.accounts?.length ?? 0}
                  </TableCell>
                  <TableCell align="right">
                    {formatMoneyValue(cg.statistics?.marketValueCents)}
                    </TableCell>
                  <TableCell align="right">
                    {cg.organization?.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchClientGroups?.totalCount ?? 0) / pageSize)}
            onChange={(_e, newPage) => setPage(newPage)} page={page}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
        )}
      </Paper>
    </Box>
  );
};

export default Corporations;
