import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { SubStepTypes } from 'interfaces';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { EditGoalVisual } from './editGoal.visual';
import { WorkflowContext } from '../../workflowCompletion';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER_GOAL = (derivedCustomFields?: string[]) => gql`
  query fetchUserGoal($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        goals {
          id
          name
          timeHorizon
          targetAmountCents
          householdClientGroup {
            id
            name
          }
          ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
            key
            value
            customField { id translatedName { en fr } translatedDescription{ en fr} type format}
            selectedOptions { value displayText { en fr } }
          }` : ''}
        }
      }
    }
  }
`;

const FETCH_GOAL = (derivedCustomFields?: string[]) => gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
        timeHorizon
        targetAmountCents
        householdClientGroup {
          id
          name
        }
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const EditGoal = ({
  options, userId, onNext, stepLoading, grid = false, updateMode = false,
  workflowCompletion,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData } = useContext(WorkflowContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const { permissions } = usePermissions();
  const enabledCustomFields = getEnabledCustomFields(options);
  const isNewGoal = workflowData?.currentGoalId;
  const [goalData, setGoalData] = useState<any>({
    goalId: null,
    name: '',
    timeHorizon: '',
    targetAmountCents: '',
    householdClientGroupId: '',
    customFields: [],
  });

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.EDIT_GOAL,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    fetchPolicy: 'no-cache',
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      if (isNewGoal) {
        await fetchGoal({
          variables: {
            goalId: workflowData?.currentGoalId,
          },
          query: customFieldKeys ? FETCH_GOAL(customFieldKeys) : undefined,
        });
      } else {
        await fetchUserGoal({
          variables: {
            userId,
          },
          query: customFieldKeys ? FETCH_USER_GOAL(customFieldKeys) : undefined,
        });
      }
    },
  });

  const [fetchGoal, { data, loading }] = useLazyQuery(FETCH_GOAL(), {
    variables: { goalId: workflowData?.currentGoalId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const { data: defaultdata } = useQuery(FETCH_GOAL(), {
    variables: { goalId: workflowData?.currentGoalId },
    fetchPolicy: 'no-cache',
    skip: !isNewGoal || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0),
  });

  const [fetchUserGoal, { data: userDataResponse }] = useLazyQuery(FETCH_USER_GOAL(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: userData } = useQuery(FETCH_USER_GOAL(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: isNewGoal || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0),
  });

  const [updateGoal, { loading: updateGoalLoading }] = useMutation(UPDATE_GOAL, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...goalData,
        customFields: modifyUpdatingCustomFields(goalData?.customFields, derivedCustomFields || [], derivedCustomFieldsKeys),
      },
    },
  });

  useEffect(() => {
    if (data || defaultdata) {
      setGoalData({
        goalId: (data ?? defaultdata).fetchGoal.goal.id,
        name: (data ?? defaultdata).fetchGoal.goal.name || undefined,
        // If it is a new goal, do not preselect the option used to stub out the goal creation
        timeHorizon: (data ?? defaultdata).fetchGoal.goal.timeHorizon || undefined,
        targetAmountCents: (data ?? defaultdata).fetchGoal.goal.targetAmountCents || undefined,
        householdClientGroupId: (data ?? defaultdata).fetchGoal.goal?.householdClientGroup ? (data ?? defaultdata).fetchGoal.goal?.householdClientGroup.id : undefined,
        customFields: (data ?? defaultdata).fetchGoal.goal?.customFields || [],
      });
    }
  }, [data, isNewGoal, defaultdata]);

  useEffect(() => {
    if (userData || userDataResponse) {
      const firstGoal = (userData ?? userDataResponse).fetchUser.user.goals[0];
      if (!firstGoal) {
        return;
      }
      setGoalData({
        goalId: firstGoal.id,
        name: firstGoal.name || undefined,
        timeHorizon: firstGoal.timeHorizon || undefined,
        targetAmountCents: firstGoal.targetAmountCents || undefined,
        householdClientGroupId: firstGoal?.householdClientGroup ? firstGoal?.householdClientGroup.id : undefined,
        customFields: firstGoal?.customFields || [],
      });
    }
  }, [userData, onNext, userDataResponse]);
  return (
    <EditGoalVisual
      options={options}
      goalData={goalData}
      updateGoal={setGoalData}
      continueFunc={updateGoal}
      loading={customFieldLoading || loading || updateGoalLoading || stepLoading}
      grid={grid}
      updateMode={updateMode}
      activeCustomFields={derivedCustomFieldsKeys}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default EditGoal;
