import { useContext, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { BackupTable, Cancel, CheckCircle } from '@mui/icons-material';
import { CardContent, IconButton } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import SubTradeRequestsTable, { TradeToVerify } from './subTradeRequestsTable';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useLocalization } from '../../../../../util/useLocalization';
import { DownloadSubTradeRequests } from './downloadSubTradeRequests';
import { BulkTradeRequest } from '../../../../../interfaces/bulkTradeRequest';
import { TradeVerificationStatus } from '../../../../../interfaces/subTradeRequest';

export const CANCEL_BULK_TRADE = gql`
  mutation transitionBulkTradeRequest($input: TransitionBulkTradeRequestInput!) {
    transitionBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CANCEL_SUB_TRADE_REQUESTS = gql`
  mutation cancelSubTradeRequests($input: CancelSubTradeRequestsInput!) {
    cancelSubTradeRequests(input: $input)
  }
`;

export const VERIFY_SUB_TRADE_REQUESTS = gql`
  mutation verifySubTradeRequests($input: VerifySubTradeRequestsInput!) {
    verifySubTradeRequests(input: $input)
  }
`;

const BulkTradeRequests = ({
  bulkTradeRequest,
  afterCancel,
  afterVerified,
  isCancelSubTradesPerPortfolioReport,
  sourceId,
  isCancelDisabled,
  portfolioOptimizerCreatedAt,
  openTradePairs,
  showTradePairs,
}: {
  bulkTradeRequest?: BulkTradeRequest;
  afterCancel: () => void;
  afterVerified: () => void;
  isCancelSubTradesPerPortfolioReport?: boolean;
  sourceId?: string;
  isCancelDisabled?: boolean;
  portfolioOptimizerCreatedAt?: Date;
  openTradePairs: () => void;
  showTradePairs?: boolean;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const { t } = useTranslation(['components', 'shared']);
  const { localizedDateTime } = useLocalization();
  const { showToast } = useGlobalToast();
  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);

  const [transitionBulkTradeRequest] = useMutation(CANCEL_BULK_TRADE, {
    variables: {
      input: {
        bulkTradeRequestId: bulkTradeRequest?.id,
        transition: 'cancel',
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [cancelSubTradeRequests] = useMutation(CANCEL_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        bulkTradeRequestId: bulkTradeRequest?.id,
        sourceId,
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [verifySubTradeRequests] = useMutation(VERIFY_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        subTradeRequestIds: tradesToVerify.map((trade: TradeToVerify) => trade?.id),
      },
    },
    onCompleted: () => {
      afterVerified();
      setTradesToVerify([]);
      const status = getVerificationStatus(tradesToVerify);
      showToast({
        severity: 'success',
        message: getToastMessage(status),
      });
    },
  });

  const cancel = async () => {
    if (isCancelSubTradesPerPortfolioReport && sourceId) {
      cancelSubTradeRequests();
    } else {
      transitionBulkTradeRequest();
    }
  };

  const getVerificationStatus = (trades: TradeToVerify[]): TradeVerificationStatus => {
    const verifiedCount = trades.filter((trade) => trade.verified).length;
    const totalCount = trades.length;

    if (verifiedCount === totalCount) {
      return TradeVerificationStatus.TO_UNVERIFIED;
    } if (verifiedCount === 0) {
      return TradeVerificationStatus.TO_VERIFIED;
    }
    return TradeVerificationStatus.SOME_VERIFIED_UNVERIFIED;
  };

  const getToastMessage = (status: TradeVerificationStatus): string => {
    switch (status) {
      case TradeVerificationStatus.TO_VERIFIED:
        return t('components:generateTrades.verifyTradesResult');
      case TradeVerificationStatus.TO_UNVERIFIED:
        return t('components:generateTrades.unverifyTradesResult');
      case TradeVerificationStatus.SOME_VERIFIED_UNVERIFIED:
        return t('components:generateTrades.verifyUnverifyTradesResult');
      default:
        return t('components:generateTrades.verifyTradesResult');
    }
  };

  const isShowVerificationBtn = permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false);
  const isShowCancelTradesBtn = permissions.includes('transition:sub_trade_request') && (!(isCancelSubTradesPerPortfolioReport && sourceId) ? true : isCancelSubTradesPerPortfolioReport && sourceId);

  return (
    <>
      {bulkTradeRequest ? (
        <>
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='headingXSmall'>{t('components:generateTrades.activeTradeRequests')}</Typography>

              {!!portfolioOptimizerCreatedAt && (
                <Typography variant='headingXSmall'>
                  ({t('components:generateTrades.generatedByPortfolioOptimizer', { portfolioOptimizerCreatedAt: localizedDateTime(portfolioOptimizerCreatedAt) })})
                </Typography>
              )}

              <Box display='flex' alignItems='center' justifyContent='end' gap={1}>
                {isShowVerificationBtn && (
                  <IconButton
                    onClick={() => {
                      if (tradesToVerify.length) verifySubTradeRequests();
                    }}
                    disabled={!tradesToVerify.length}
                    label={t('components:generateTrades.verifyUnverifyTrades')}
                  >
                    <CheckCircle />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && <DownloadSubTradeRequests filter={{ bulkTradeRequestId: bulkTradeRequest.id, sourceId }} />}

                {showTradePairs && (
                  <IconButton onClick={openTradePairs} label={t('showPairs')}>
                    <BackupTable />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && (
                  <IconButton disabled={isCancelDisabled} onClick={() => cancel()} label={t('components:generateTrades.cancelTrades')}>
                    <Cancel />
                  </IconButton>
                )}
              </Box>
            </Box>
          </CardContent>

          <SubTradeRequestsTable bulkTradeRequestId={bulkTradeRequest.id} sourceId={sourceId} onTradesToVerifyUpdate={(trades: TradeToVerify[]) => setTradesToVerify(trades)} />
        </>
      ) : (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left' justifyContent='center'>
            <InfoRoundedIcon sx={{ mr: 1 }} />
            <Typography variant='bodyLarge' weight='bold'>
              {t('components:generateTrades.noActiveTradeRequests')}
            </Typography>
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default BulkTradeRequests;
