import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { PortfolioOptimizerTransition } from '..';

export const TRANSITION_PORTFOLIO_OPTIMIZER = gql`
  mutation transitionPortfolioOptimizer($input: TransitionPortfolioOptimizerInput!) {
    transitionPortfolioOptimizer(input: $input) {
      portfolioOptimizer {
        state
      }
    }
  }
`;

const PortfolioOptimizerCancelButton = ({ portfolioOptimizerId, disabled, setIsTransitioned }: { portfolioOptimizerId: string; disabled?: boolean, setIsTransitioned?: () => void }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('portfolioOptimizer');
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const [isTransitioned, setLocalIsTransitioned] = useState<boolean>(false);

  const [transitionPortfolioOptimizer] = useMutation(TRANSITION_PORTFOLIO_OPTIMIZER, {
    onCompleted: (data) => {
      setLocalIsTransitioned(true);

      if (setIsTransitioned) {
        setIsTransitioned();
      }

      showToast({
        message: t('detail.portfolioOptimizerNewState', { state: t(`portfolioOptimizerState.${data.transitionPortfolioOptimizer.portfolioOptimizer.state}`) }),
        severity: 'success',
      });

      navigate('/portfolioOptimizers');
    },
  });

  if (!permissions.includes('transition:portfolio_optimizer')) {
    return <></>;
  }

  return (
    <Button
      data-testid='cancel-button'
      sx={{
        m: 1,
      }}
      disabled={disabled || isTransitioned}
      variant='outlined'
      onClick={() => transitionPortfolioOptimizer({
        variables: {
          input: {
            portfolioOptimizerId,
            transition: PortfolioOptimizerTransition.CANCEL,
          },
        },
      })
      }
    >
      {t('detail.cancel')}
    </Button>
  );
};

export default PortfolioOptimizerCancelButton;
