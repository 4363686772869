/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Typography, Box, Table, TableHead,
  TableRow, TableCell, TableBody,
  Chip, MenuItem, IconButton,
  Menu,
  Grid,
  Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Date } from '../../misc/date/date';
import { formatMoneyValue, generateBankDetailString } from '../../../util';
import { usePermissions } from '../../../providers/userContextProvider';
import EditScheduledIncomeFundTransfer from './editScheduledIncomeFundTransfer';
import NewScheduledIncomeFundTransfer from './newScheduledIncomeFundTransfer';

const ARCHIVED_SCHEDULED_FUND_TRANSFER = gql`
  mutation transitionScheduledIncomeFundTransfer($input: TransitionScheduledIncomeFundTransferInput!) {
    transitionScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

export const FETCH_FUND_TRANSFER_INSTRUCTIONS = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        scheduledIncomeFundTransfer {
          id
          state
          amountPayableType
          dateOfBirth
          frequency
          minimumAnnualAmountCents
          annualAmountCents
          scheduledDate
          bankAccount {
            id
            name
            institutionNumber
            bankAccountNumber
          }
          subAccount {
            account {
              affiliations{
                user {
                  id
                  firstName
                  dateOfBirth
                  lastName
                }
              }
              user {
                id
              }
              type
            }
          }
          taxOption
          specialTaxRate
        }
        affiliations{
          user {
            id
            firstName
            dateOfBirth
            lastName
          }
        }
      }
    }
  }
`;

export const FETCH_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        type
        scheduledIncomeFundTransfer {
          id
          state
          subAccount {
            account {
              user {
                id
              }
              type
              affiliations{
                user {
                  id
                  firstName
                  dateOfBirth
                  lastName
                }
              }
            }
          }
          amountPayableType
          dateOfBirth
          frequency
          minimumAnnualAmountCents
          annualAmountCents
          scheduledDate
          bankAccount {
            id
            name
            institutionNumber
            bankAccountNumber
          }
        }
        affiliations{
          user {
            id
            firstName
            dateOfBirth
            lastName
          }
        }
      }
    }
  }
`;
export const FETCH_GOAL_SCHEDULED_INCOME_TRANSFERS = gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        id
        subAccounts {
          id
          account {
            id
            type
            scheduledIncomeFundTransfer {
              id
              state
              subAccount {
                account {
                  user {
                    id
                  }
                  type
                  affiliations{
                    user {
                      id
                      firstName
                      dateOfBirth
                      lastName
                    }
                  }
                }
              }
              amountPayableType
              dateOfBirth
              frequency
              minimumAnnualAmountCents
              annualAmountCents
              scheduledDate
              bankAccount {
                id
                name
                institutionNumber
                bankAccountNumber
              }
            }
            affiliations{
              user {
                id
                firstName
                dateOfBirth
                lastName
              }
            }
          }
        }
      }
    }
  }
`;
export const FETCH_CLIENT_GROUP = gql`
  query fetchClientGroup($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        accounts {
          id
          type
          scheduledIncomeFundTransfer {
            id
            state
            subAccount {
              account {
                user {
                  id
                }
                type
                affiliations{
                  user {
                    id
                    firstName
                    dateOfBirth
                    lastName
                  }
                }
              }
            }
            amountPayableType
            dateOfBirth
            frequency
            minimumAnnualAmountCents
            annualAmountCents
            scheduledDate
            bankAccount {
              id
              name
              institutionNumber
              bankAccountNumber
            }
          }
          affiliations{
            user {
              id
              firstName
              dateOfBirth
              lastName
            }
          }
        }
      }
    }
  }
`;
export const PaymentIntructionsTable = (
  {
    filter, onTransition = () => { }, onNewTransfer = () => { }, level,
  }: {
    filter: any, onTransition?: () => void, onNewTransfer?: any,
    level?: 'CLIENT' | 'ACCOUNT' | 'SUB_ACCOUNT' | 'GOAL' | 'CLIENT_GROUP',
  },
) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [openEdit, setOpenEdit] = useState(false);
  const [isMultiple] = useState((level === 'CLIENT' || level === 'GOAL' || level === 'CLIENT_GROUP'));
  const [activeScheduledFundTransfer, setActiveScheduledFundTransfer] = useState<any>({});
  const [scheduledTransfersAnchorEl, setScheduledTransfersAnchorEl] = useState<null | HTMLElement>(null);
  const scheduledFundTransferMenuOpen = Boolean(scheduledTransfersAnchorEl);
  const [archiveScheduledFundTransfer] = useMutation(ARCHIVED_SCHEDULED_FUND_TRANSFER);
  const stateColor = (state: any) => (state === 'ACTIVE' ? 'success' : 'warning');
  const handleScheduleTransferMenuClose = () => {
    setScheduledTransfersAnchorEl(null);
  };
  let fundTransfer: any;
  const fundTransferMulti: any[] = [];
  const [fetchAccounts, { data: accountsData, refetch: accountsRefetch }] = useLazyQuery(FETCH_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          userId: filter.userId,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
  });

  const [fetchGoalTransfers, { data: goalData, refetch: goalRefetch }] = useLazyQuery(FETCH_GOAL_SCHEDULED_INCOME_TRANSFERS, {
    variables: { id: filter.goalId },
  });
  const [fetchAccount, { data: accountData, refetch: accountRefetch }] = useLazyQuery(FETCH_FUND_TRANSFER_INSTRUCTIONS, {
    variables: { accountId: filter.accountId },
  });
  const [fetchClientGroup, { data: clientGroupData, refetch: clientGroupRefetch }] = useLazyQuery(FETCH_CLIENT_GROUP, {
    variables: { id: filter.clientGroupId },
  });

  useEffect(() => {
    if (level === 'CLIENT') {
      fetchAccounts();
    }
    if (level === 'CLIENT_GROUP') {
      fetchClientGroup();
    }
    if (level === 'ACCOUNT') {
      fetchAccount();
    }
    if (level === 'GOAL') {
      fetchGoalTransfers();
    }
  }, [level]);

  if (accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer) {
    fundTransfer = accountData?.fetchAccount?.account?.scheduledIncomeFundTransfer;
  }

  if (clientGroupData?.fetchClientGroup?.clientGroup) {
    clientGroupData?.fetchClientGroup?.clientGroup?.accounts.map((a: any) => {
      if (a.scheduledIncomeFundTransfer) {
        fundTransferMulti.push(a.scheduledIncomeFundTransfer);
      }
    });
  }

  if (accountsData?.fetchAccounts?.accounts) {
    accountsData?.fetchAccounts?.accounts.map((a: any) => {
      if (a.scheduledIncomeFundTransfer) {
        fundTransferMulti.push(a.scheduledIncomeFundTransfer);
      }
    });
  }

  if (goalData?.fetchGoal?.goal?.subAccounts) {
    goalData?.fetchGoal?.goal?.subAccounts?.map((a: any) => {
      if (a?.account?.scheduledIncomeFundTransfer) {
        fundTransferMulti.push(a?.account?.scheduledIncomeFundTransfer);
      }
    });
  }

  return (
    <Box>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.account')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.dob')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.paymentType')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.depositTo')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.amount')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.frequency')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.nextScheduledDate')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.state')}</Typography></TableCell>
            {permissions.includes('transition:transfer') && (
              <TableCell align='right'><Typography variant='overline'>{t('activityTable.actions')}</Typography></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isMultiple && fundTransferMulti.map((fundTransferItem: any) => (
            <TableRow
              hover
              onClick={() => {
                setActiveScheduledFundTransfer(fundTransferItem);
                setOpenEdit(true);
              }}
              selected={fundTransferItem.id === activeScheduledFundTransfer.id}
              key={fundTransferItem.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
            >
              <TableCell component="th" scope="row">
                {t(`accountTypes:${fundTransferItem?.subAccount?.account.type}`)}
              </TableCell>
              <TableCell component="th" scope="row">
                <Date variant='subtitle2' date={fundTransferItem.dateOfBirth} />
              </TableCell>
              <TableCell><Chip size='small' label={fundTransferItem.amountPayableType ?? 'MIN'} color='default' /></TableCell>

              <TableCell><b>{generateBankDetailString(fundTransferItem.bankAccount)}</b></TableCell>

              <TableCell>{formatMoneyValue(fundTransferItem.annualAmountCents)}</TableCell>

              <TableCell>{t(`scheduledFundTransferModal.scheduleOptions.${fundTransferItem.frequency}`)}</TableCell>

              <TableCell><Date variant='subtitle2' date={fundTransferItem.scheduledDate} /></TableCell>

              <TableCell>
                <Chip
                  size='small'
                  label={fundTransferItem?.state}
                  color={stateColor(fundTransferItem?.state)}
                />
              </TableCell>
              {permissions.includes('transition:transfer') && fundTransferItem.state === 'ACTIVE'
                && (
                  <TableCell align='right'>
                    <IconButton
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation();
                        setScheduledTransfersAnchorEl(e.currentTarget);
                        setActiveScheduledFundTransfer(fundTransferItem);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>)}
            </TableRow>
          ))}
          {(!isMultiple && fundTransfer) && (
            <TableRow
            hover
            onClick={() => {
              setActiveScheduledFundTransfer(fundTransfer);
              setOpenEdit(true);
            }}
            selected={fundTransfer.id === activeScheduledFundTransfer.id}
            key={fundTransfer.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
          >
            <TableCell component="th" scope="row">
              {t(`accountTypes:${accountData?.fetchAccount?.account.type}`)}
            </TableCell>
            <TableCell component="th" scope="row">
              <Date variant='subtitle2' date={fundTransfer.dateOfBirth} />
            </TableCell>
            <TableCell><Chip size='small' label={fundTransfer.amountPayableType} color='default' /></TableCell>

            <TableCell><b>{generateBankDetailString(fundTransfer.bankAccount)}</b></TableCell>

            <TableCell>{formatMoneyValue(fundTransfer.annualAmountCents)}</TableCell>

            <TableCell>{t(`scheduledFundTransferModal.scheduleOptions.${fundTransfer.frequency}`)}</TableCell>

            <TableCell><Date variant='subtitle2' date={fundTransfer.scheduledDate} /></TableCell>

            <TableCell>
              <Chip
                size='small'
                label={fundTransfer?.state}
                color={stateColor(fundTransfer?.state)}
              />
            </TableCell>
            {permissions.includes('transition:transfer') && fundTransfer.state === 'ACTIVE'
              && (
                <TableCell align='right'>
                  <IconButton
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      setScheduledTransfersAnchorEl(e.currentTarget);
                      setActiveScheduledFundTransfer(fundTransfer);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
          </TableRow>
          )}
        </TableBody>
      </Table>
      {(!fundTransfer || fundTransferMulti.length === 0) && (
        <Stack sx={{ marginTop: '5em', marginBottom: '5em' }}>
          <Grid display="flex" justifyContent="center">
            <InfoIcon />
          </Grid>
          <Grid display="flex" justifyContent="center">
            <Typography sx={{ fontWeight: '600' }}>
              {t('activityTable.noScheduledIncomeFundTransfersExist')}
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" sx={{ marginTop: '1em' }}>
            <NewScheduledIncomeFundTransfer
              afterCreate={onNewTransfer}
              {...filter.accountId && { forObject: 'ACCOUNT', forId: filter.accountId }}
              {...filter.userId && level === 'CLIENT' && { forObject: 'USER', forId: filter.userId }}
              {...filter.goalId && level === 'GOAL' && { forObject: 'GOAL', forId: filter.goalId }}
              {...filter.clientGroupId && level === 'CLIENT_GROUP' && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
              subAccountId={filter.subAccountId}
            />
          </Grid>
        </Stack>
      )
      }
      <Menu
        anchorEl={scheduledTransfersAnchorEl}
        id="scheduled-transfer-menu"
        open={scheduledFundTransferMenuOpen}
        onClose={handleScheduleTransferMenuClose}
        onClick={handleScheduleTransferMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key='edit'
          onClick={async () => {
            setActiveScheduledFundTransfer(activeScheduledFundTransfer);
            setOpenEdit(true);
          }}
        >
          {t('components:scheduledFundTransferModal.edit')}
        </MenuItem>
        <MenuItem
          key='archive'
          onClick={async () => {
            archiveScheduledFundTransfer({
              variables: {
                input:
                {
                  scheduledIncomeFundTransferId: activeScheduledFundTransfer.id,
                  transition: 'archive',
                },
              },
            }).then(() => accountsRefetch());
          }}
        >
          {t('components:scheduledFundTransferModal.archive')}
        </MenuItem>
      </Menu>
      <EditScheduledIncomeFundTransfer
        afterUpdate={() => {
          if (level === 'CLIENT') {
            accountsRefetch();
          }
          if (level === 'ACCOUNT') {
            accountRefetch();
          }
          if (level === 'CLIENT_GROUP') {
            clientGroupRefetch();
          }
          if (level === 'GOAL') {
            goalRefetch();
          }
          setOpenEdit(false);
        }}
        accountId={filter.accountId}
        scheduledIncomeFundTransferToUpdate={activeScheduledFundTransfer}
        handleClose={() => setOpenEdit(false)}
        open={openEdit}
      />
      </Box>
  );
};
