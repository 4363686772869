import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, TranslatableTextField,
} from '../../../ovComponents';
import { ClientReportTemplate } from '../../../interfaces';

const UPDATE_CLIENT_REPORT_TEMPLATE = gql`
  mutation updateClientReportTemplate($clientReportTemplateId: ObjectID!, $input: UpdateClientReportTemplateInput!) {
    updateClientReportTemplate(clientReportTemplateId: $clientReportTemplateId, input: $input) {
      clientReportTemplate {
        id
      }
    }
  }
`;

const EditClientReportTemplate = ({
  open,
  setOpen,
  clientReportTemplate,
  afterUpdate,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  clientReportTemplate: Partial<ClientReportTemplate>;
  afterUpdate: () => void;
}) => {
  const { t } = useTranslation('clientReportTemplate');
  const [focused, setFocused] = useState<string[]>([]);
  const [templateToUpdate, setTemplateToUpdate] = useState({
    id: clientReportTemplate?.id,
    name: clientReportTemplate?.translatedName,
    description: clientReportTemplate?.translatedDescription,
    type: clientReportTemplate?.type,
  });
  const [updateClientReportTemplate, { loading }] = useMutation(UPDATE_CLIENT_REPORT_TEMPLATE, {
    variables: {
      clientReportTemplateId: templateToUpdate?.id,
      input: {
        translatedName: {
          en: templateToUpdate?.name?.en,
          fr: templateToUpdate?.name?.fr,
        },
        translatedDescription: {
          en: templateToUpdate?.description?.en,
          fr: templateToUpdate?.description?.fr,
        },
      },
    },
  });

  const validate = () => {
    const invalidFieldSet: string[] = [];

    if (!templateToUpdate?.name?.en && !templateToUpdate?.name?.fr) {
      invalidFieldSet.push('name');
    }

    if (!templateToUpdate?.description?.en && !templateToUpdate?.description?.fr) {
      invalidFieldSet.push('description');
    }

    setFocused(invalidFieldSet);
    return invalidFieldSet.length === 0;
  };

  const updateDashboard = async () => {
    if (!validate()) {
      return;
    }
    await updateClientReportTemplate();
    afterUpdate();
    setOpen(false);
  };

  useEffect(() => {
    if (clientReportTemplate) {
      setTemplateToUpdate({
        id: clientReportTemplate?.id,
        name: clientReportTemplate?.translatedName,
        description: clientReportTemplate?.translatedDescription,
        type: clientReportTemplate?.type,
      });
    }
  }, [clientReportTemplate]);

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <Form onSubmit={updateDashboard}>
        <DialogTitle onClose={() => setOpen(false)}>
          {t('editModal.title')}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TranslatableTextField
            fullWidth
            label={t('editModal.name')}
            error={(!templateToUpdate?.name?.en && !templateToUpdate?.name?.fr) && focused.includes('name')}
            onBlur={() => setFocused([...focused, 'name'])}
            value={templateToUpdate?.name ?? {}}
            onChange={(e) => setTemplateToUpdate({ ...templateToUpdate, name: e })} />
          <TranslatableTextField
            fullWidth
            label={t('editModal.description')}
            error={(!templateToUpdate?.description?.en && !templateToUpdate?.description?.fr) && focused.includes('description')}
            onBlur={() => setFocused([...focused, 'description'])}
            value={templateToUpdate?.description ?? {}}
            onChange={(e) => setTemplateToUpdate({ ...templateToUpdate, description: e })} />
        </DialogContent>
        <DialogFooter sx={{ gap: 1, padding: '12px' }}>
          <Button variant='tonal' label={t('shared:cancel')} onClick={() => setOpen(false)} />
          <Button disabled={loading} label={t('shared:update')} type='submit' data-testid='clientreporttemplate-edit-button' />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default EditClientReportTemplate;
