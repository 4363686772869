import { gql } from '@apollo/client';

export const FETCH_MEMBERS = (useCustodianData: boolean | undefined, currency: string | undefined) => gql`
  query fetchClientGroup($clientGroupId: ObjectID!, $currency: StatisticsCurrencyTypes) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        id
        relationships {
          type
          user {
            id
            firstName
            lastName
            entityName
            statistics(input: {currency: $currency}) {
              marketValueCents
              currency
            }
            ${useCustodianData ? `custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} { marketValueCents currency }` : ''}
          }
        }
      }
    }
  }
`;
