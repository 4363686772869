import { useQuery } from '@apollo/client';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../providers/userContextProvider';
import { colors } from '../../../../theme/colors';
import { FETCH_IDS } from '../iDs';
import { useLocalization } from '../../../../util/useLocalization';

export const OneHubDocumentTypes = [
  'CITIZENSHIP_CARD', 'DRIVERS_LICENSE', 'HEALTH_CARD',
  'INDIAN_STATUS_CARD', 'PASSPORT', 'PERMANENT_RESIDENT_CARD', 'PROVINCIAL_ID',
  'OTHER', 'MARRIAGE_LICENCE', 'BANK_STATEMENT', 'UTILITY_STATEMENT', 'CREDIT_FILE', 'GLOBAL_ENTRY_CARD',
  'NEXUS', 'GOV_ISSUED_CPP_STATEMENT', 'GOV_ISSUED_VEHICLE_REGISTRATION', 'BENEFITS_STATEMENT', 'CANADA_411', 'RECORD_OF_EMPLOYMENT',
  'INSURANCE_DOCUMENT', 'INVESTMENT_ACCOUNT_STATEMENT', 'BIRTH_CERTIFICATE', 'DIVORCE_DOCUMENTATION', 'CITIZENSHIP_CERTIFICATE',
  'TRAVEL_VISA', 'SCHOOL_DOCUMENT', 'CAD_CREDIT_BUREAU_PRODUCT', 'CREDIT_CARD_STATEMENT', 'LOAN_ACCOUNT_STATEMENT', 'PROCESSED_CHEQUE',
  'CONFIRMATION_FROM_FINANCIAL_ENTITY', 'MICRO_DEPOSIT',
];
export const VerificationMethods = ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'DUAL_METHOD', 'IN_PERSON_VALIDATION'];
export const IdVerificationsTable = ({ userId, onClick }: {
  userId: string,
  onClick: (clickedId: any) => void,
}) => {
  const { permissions } = usePermissions();
  const pageSize = 15;
  const { t } = useTranslation(['client', 'base']);
  const { localizedDate } = useLocalization();
  const fetchedIDs = useQuery(FETCH_IDS, {
    variables: {
      input: {
        filter: {
          userId,
        },
        pagination: {
          sortField: 'documentExpiryDate', sortDesc: false, perPage: pageSize, offSet: 0,
        },
      },
    },
    skip: !permissions.includes('read:id_verification'),
  });
  const displayDocumentType = (currentVal: string): string => {
    if (currentVal === '' || !currentVal) return t('client:edit.noJDocumentType');
    return OneHubDocumentTypes.includes(currentVal) ? t(`client:documentTypes.${currentVal}`) : currentVal;
  };
  const displayMethodOfIdVerification = (currentVal: string): string => {
    if (currentVal === '' || !currentVal) return t('client:edit.noJDocumentType');
    return VerificationMethods.includes(currentVal) ? t(`client:edit.methodOfIDVerificationOptions.${currentVal}`) : currentVal;
  };
  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.gray200, borderRadius: '25px 25px 0px 0px' }}>
              <TableCell style={{ fontSize: '14px', fontWeight: '600', borderRight: `1.5px solid ${colors.white}` }}>{t('client:details.idVerificationBox.methodOfIdVerification')}</TableCell>
              <TableCell style={{ fontSize: '14px', fontWeight: '600', borderRight: `1.5px solid ${colors.white}` }}>{t('client:details.idVerificationBox.typeTitle')}</TableCell>
              <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('client:details.idVerificationBox.expiryDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(fetchedIDs.data)?.fetchUserIdVerifications?.idVerifications?.map((idV: any, index: number) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => onClick(idV)}
              >
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }} component="th" scope="row">
                  <Box sx={{ display: 'grid' }}>
                    <span>{displayMethodOfIdVerification(idV.methodOfIDVerification)}</span>
                    {(idV.methodOfIDVerification === 'DUAL_METHOD') && (<span></span>)}
                  </Box>
                </TableCell>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>
                  <Box sx={{ display: 'grid' }}>
                    <span>{displayDocumentType(idV.documentType)}</span>
                    <span>{idV.methodOfIDVerification === 'DUAL_METHOD' && displayDocumentType(idV.secondaryDocumentType)}</span>
                  </Box>
                </TableCell>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>
                  <Box sx={{ display: 'grid' }}>
                    <span>{localizedDate(idV.documentExpiryDate) ?? t('client:edit.noExpiryDate')}</span>
                    <span>{idV.methodOfIDVerification === 'DUAL_METHOD' && (localizedDate(idV.secondaryDocumentExpiryDate) ?? t('client:edit.noExpiryDate'))}</span>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Grid>);
};
