import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Icon, Typography } from '../../../../../../1-primative';
import { Account, Transfer } from '../../../../../../../interfaces';
import { Card, CardContent, IconButton } from '../../../../../../2-component';
import { ExternalTransfer } from './externalTransfer.dialogContent';

interface TfsaReviewItemProps {
  transfer: Transfer | ExternalTransfer,
  onDeleteTransfer: (account?: Account) => void,
}
const ExternalTransferReviewItem = ({ transfer, onDeleteTransfer }: TfsaReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'accountTypes']);
  // eslint-disable-next-line max-len
  const defineTitle = (): string => t('workflowCompletions:externalTransferReviewPanel.title', { amount: `$${(transfer.amountCents / 100).toLocaleString('en-US')}`, accountType: t(`accountTypes:${transfer?.subAccount?.account?.type ?? ''}`) });
  const defineInstitutionName = (): string => {
    if ('manualProcess' in transfer) {
      return transfer?.manualProcess?.institution?.name ?? '';
    }
    return transfer.institution?.name ?? '';
  };
  const defineInstitutionNumber = (): string => {
    if ('manualProcess' in transfer) {
      return transfer?.manualProcess?.transferAccountNumber ?? '';
    }
    if ('institution' in transfer) {
      return transfer?.transferAccountNumber ?? '';
    }
    return '';
  };
  const defineTransferMethod = (): string => {
    if ('manualProcess' in transfer) {
      return transfer?.manualProcess?.transferMethod ?? '';
    }
    if ('transferMethod' in transfer) {
      return transfer?.transferMethod ?? '';
    }
    return '';
  };
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box pl={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
          <Typography variant='bodyLarge' sx={{ mt: 1, fontWeight: '600' }}>
              {defineTitle()}
          </Typography>
          {
              'id' in transfer && transfer.id ? undefined : <IconButton onClick={() => onDeleteTransfer()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:externalTransferReviewPanel.institution')}</Typography>
          <Typography variant='labelLarge'>{defineInstitutionName()}</Typography>
        </Box>
        <Box sx={{ mt: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:externalTransferReviewPanel.account')}</Typography>
            <Typography variant='labelLarge'>{defineInstitutionNumber()}</Typography>
        </Box>
        <Box sx={{ mt: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:externalTransferReviewPanel.transferMethod')}</Typography>
            <Typography variant='labelLarge'>
              {t(`workflowCompletions:transferMethodOptions.${defineTransferMethod()}`)}
            </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExternalTransferReviewItem;
