import { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash/fp';
import { TransferVisual } from './transfer.visual';
import { Transfer as TransferInterface } from '../../../../../interfaces';
import { FETCH_TRANSFERS } from '../../../../../components/tables/transfersTable/oneoffTransfersTable';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { BankAccountTransfer } from './types/bankAccountTransfer/bankAccountTransfer.dialogContent';
import { ExternalTransfer } from './types/externalTransfer/externalTransfer.dialogContent';
import {
  CREATE_EFT_DEPOSIT_TRANSFER,
  CREATE_EXTERNAL_TRANSFER,
  CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  CREATE_TRANSFER_BETWEEN_SUB_ACCOUNT,
} from '../../../../../components/tables/transfersTable/newTransfer';
import { CANCEL_SCHEDULED_TRANSFER } from '../../../../../components/tables/transfersTable/scheduledTransfersTable';
import { SubAccountTransfer } from './types/subAccountTransfer/subAccountTransfer.dialogContent';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        middleName
        lastName
        primaryEmail
        phone
        dateOfBirth
        language
        preferredMethodOfCommunication
        inProvinceSince
        gender
        physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
        accounts {
          id
          type
          applyForGovFunds
          state
          affiliations {
            id
            allocation
            relation
            signatureRequired
            type
            user {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const CANCEL_DEPOSIT_TRANSFER = gql`
  mutation cancelDepositTransfer($transferId: ObjectID!) {
    cancelDepositTransfer(transferId: $transferId) {
      transfer {
        id
      }
    }
  }
`;

export const Transfer = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const [userData, setUserData] = useState<any>({
    id: userId,
    firstName: '',
    middleName: '',
    lastName: '',
    primaryEmail: '',
    phone: '',
    dateOfBirth: '',
    language: '',
    preferredMethodOfCommunication: '',
    inProvinceSince: '',
    gender: '',
    physicalAddress: {
      city: '',
      province: '',
      streetName: '',
      postal: '',
      unitNumber: '',
      houseNumber: '',
      neighborhood: '',
      country: '',
    },
  });
  const [transfers, setTransfers] = useState<(TransferInterface | BankAccountTransfer | ExternalTransfer | SubAccountTransfer)[]>([]);
  const [isCreatingTransfers, setIsCreatingTransfers] = useState<boolean>(false);
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [createExternalTransfer] = useMutation(CREATE_EXTERNAL_TRANSFER);
  const [createEtfScheduledDepositTransfer] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER);
  const [createEtfDepositTransfer] = useMutation(CREATE_EFT_DEPOSIT_TRANSFER);
  const [cancelScheduledTransfer] = useMutation(CANCEL_SCHEDULED_TRANSFER);
  const [cancelDepositTransfer] = useMutation(CANCEL_DEPOSIT_TRANSFER);
  const [createTransferBetweenSubAccount] = useMutation(CREATE_TRANSFER_BETWEEN_SUB_ACCOUNT);

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });
  const cancelTransfer = (transfer?: TransferInterface) => {
    if (!transfer) return;
    if (transfer.scheduledDate) {
      cancelScheduledTransfer({ variables: { scheduledTransferId: transfer.id } }).then();
    } else {
      cancelDepositTransfer({ variables: { transferId: transfer.id } }).then();
    }
  };
  const createExternalTransferMethod = async (transfer: ExternalTransfer): Promise<boolean> => {
    let isSuccessful = true;
    await createExternalTransfer({
      variables: {
        input: {
          amountCents: transfer.amountCents,
          institution: {
            id: transfer.institution?.id,
            name: transfer.institution?.name,
          },
          subAccountId: transfer.subAccount?.id,
          transferAccount: transfer.subAccount?.account.type,
          transferAccountNumber: transfer.transferAccountNumber,
          transferMethod: transfer.transferMethod,
        },
      },
      onCompleted: () => {
        isSuccessful = true;
      },
      onError: () => {
        isSuccessful = false;
      },
    }).then();
    return isSuccessful;
  };
  const createEFTTransferMethod = async (transfer: BankAccountTransfer): Promise<{ isSuccessful: boolean, transfer?: TransferInterface }> => {
    let isSuccessful = true;
    let savedTransfer;
    if (transfer.isRecurring) {
      await createEtfScheduledDepositTransfer({
        variables: {
          input: {
            ...pick(['amountCents', 'bankAccountId', 'frequency', 'scheduledDate', 'subAccountId'], transfer),
            bankAccountId: transfer.bankAccount.id,
            subAccountId: transfer.subAccount?.id,
          },
        },
        onCompleted: (e) => { savedTransfer = e.createScheduledDepositTransfer.scheduledTransfer; },
        onError: () => { isSuccessful = false; },
      }).then();
    } else {
      await createEtfDepositTransfer({
        variables: {
          input: {
            amountCents: transfer.amountCents,
            bankAccountId: transfer.bankAccount.id,
            subAccountId: transfer.subAccount?.id,
          },
        },
        onCompleted: (e) => { savedTransfer = e.createDepositTransfer.transfer; },
        onError: () => { isSuccessful = false; },
      }).then();
    }
    return { isSuccessful, transfer: savedTransfer };
  };
  const createSubAccountTransferMethod = async (transfer: SubAccountTransfer): Promise<boolean> => {
    let isSuccessful = true;
    await createTransferBetweenSubAccount({
      variables: {
        input: { ...pick(['toSubAccountId', 'amountCents'], transfer), fromSubAccountId: transfer.fromSubAccountId },
      },
      onCompleted: () => {
        isSuccessful = true;
      },
      onError: () => {
        isSuccessful = false;
      },
    }).then();
    return isSuccessful;
  };

  const createAllTransfers = (): void => {
    const status: { isSuccessful: boolean, transfer?: TransferInterface }[] = [];
    let externalTransferHasIssues = false;
    let subAccountTransferHasIssues = false;
    setIsCreatingTransfers(true);
    Promise.all(transfers.map(async (transfer) => {
      if ('institution' in transfer && !('id' in transfer)) {
        const response = await createExternalTransferMethod(transfer);
        if (!response) externalTransferHasIssues = !response;
      }
      if ('bankAccount' in transfer && !('id' in transfer)) {
        const response = await createEFTTransferMethod(transfer).then();
        status.push(response);
      }
      if ('fromSubAccountId' in transfer && !('id' in transfer)) {
        const response = await createSubAccountTransferMethod(transfer).then();
        if (!response) subAccountTransferHasIssues = !response;
      }
    })).then((r) => {
      setIsCreatingTransfers(false);
      const failedEFTTransfers = status.filter((stat) => !stat.isSuccessful);
      if (failedEFTTransfers.length > 0 || (externalTransferHasIssues && status.length > 0) || (subAccountTransferHasIssues && status.length > 0)) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        status.filter((stat) => stat.isSuccessful).forEach((data) => {
          cancelTransfer(data.transfer);
        });
      } else {
        onNext();
      }
    });
  };
  const { data: fetchTransfersData } = useQuery(FETCH_TRANSFERS(permissions), {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          userId,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: 20, offSet: 0,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (fetchTransfersData) {
      // eslint-disable-next-line max-len
      setTransfers(fetchTransfersData.fetchTransfers.transfers.filter((transfer: { source: string; state: string, }) => (transfer.source === 'EXTERNAL_TRANSFER_IN' || transfer.source === 'USER') && (transfer.state === 'INITIATED' || transfer.state === 'READY' || transfer.state === 'REQUESTED' || transfer.state === 'PROCESSING')));
    }
    if (data) {
      setUserData({ id: userId, ...data.fetchUser.user });
    }
  }, [data, userId, fetchTransfersData]);

  return (
    <TransferVisual
      options={options}
      transfers={transfers}
      updateTransfers={setTransfers}
      updateUser={setUserData}
      userData={userData}
      continueFunc={createAllTransfers}
      isLoading={loading || isCreatingTransfers || stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default Transfer;
