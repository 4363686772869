import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { BillingCycle } from '../../interfaces/billingCycle';
import { useLocalization } from '../../util/useLocalization';

const FETCH_BILLING_CYCLE_BREADCRUMB = gql`
  query fetchBillingCycleBreadcrumb($billingCycleId: ObjectID!) {
    fetchBillingCycle(billingCycleId: $billingCycleId) {
      billingCycle {
        organization { id name }
        startDate
        endDate
      }
    }
  }
`;

export const FeeReportBreadcrumb = () => {
  const { localizedDate } = useLocalization();

  const { data } = useQuery(FETCH_BILLING_CYCLE_BREADCRUMB, { variables: { billingCycleId: useParams().billingCycleId } });
  const billingCycle: BillingCycle | undefined = data?.fetchBillingCycle?.billingCycle;
  if (!billingCycle) return '';

  return `${billingCycle.organization?.name} ${billingCycle?.startDate && billingCycle?.endDate
    ? `(${localizedDate(billingCycle.startDate)} - ${localizedDate(dayjs(billingCycle.endDate).subtract(1, 'day'))})`
    : ''
  }`;
};
