import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Typography } from '../..';
import { useBadgeTokens } from './badge.tokens';

export type BadgeProps = {
  label: string
  color?: 'neutral' | 'negative' | 'warning' | 'positive',
  variant?: 'number' | 'text',
  backgroundColor?: string,
  textColorOverride?: string,
  sx?: any,
};

export const Badge = ({
  label, color = 'neutral', variant = 'text', sx = {},
}: BadgeProps) => {
  const { comp } = useThemeTokens(useBadgeTokens());
  const textColor = variant === 'number' ? comp.badge.number.textColor : comp.badge[variant][color].textColor;
  const bgColor = variant === 'number' ? comp.badge.number.backgroundColor : comp.badge[variant][color].backgroundColor;

  return (
    <span>
      <Typography variant='labelSmall' sx={{
        height: variant === 'number' ? '16px' : '20px',
        display: 'inline-block',
        textAlign: 'center',
        color: textColor,
        backgroundColor: bgColor,
        borderRadius: comp.badge[variant].borderRadius,
        minWidth: '16px',
        alignContent: 'center',
        padding: '1px 4px 1px 4px',
        ...sx,
      }}
      >
        {label}
      </Typography>
    </span>
  );
};
