import {
  Grid, ListItem, TextField, Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { AccountTypes } from '../../../interfaces';
import { FETCH_ACCOUNT_TYPES } from '../../../components/inputs/accountTypeSelect';
import { translateBackend } from '../../../assets/i18n/config';

export interface AccountTypeTaxRank {
  accountType: AccountTypes,
  taxRank: number,
}

const AccountTaxRanks = ({ taxRanks, setTaxRank }: { taxRanks: AccountTypeTaxRank[], setTaxRank: React.Dispatch<React.SetStateAction<AccountTypeTaxRank[]>> }) => {
  const { error, data } = useQuery(FETCH_ACCOUNT_TYPES);
  if (error) (<Typography>Error</Typography>);
  let accountTypesData: any[] = [];
  if (data) {
    accountTypesData = accountTypesData.concat(data.fetchAccountTypes.accountTypes).filter(
      (t) => t.value && !t.value.startsWith('USA_') && !t.value.startsWith('UK_'),
    );
    if (accountTypesData !== undefined && accountTypesData.length > 0) {
      accountTypesData = accountTypesData.sort((a: any, b: any) => (translateBackend(a.translatedName).localeCompare(translateBackend(b.translatedName))));
    }
  }

  const accountTaxRank = (accountType: AccountTypes): number | '' => taxRanks?.find((x) => x.accountType === accountType)?.taxRank ?? '';
  const updateFields = (accountType: AccountTypes, inputValue?: number) => {
    let value = 1;

    if (inputValue && inputValue > 0) value = inputValue;

    const newArr: AccountTypeTaxRank[] = taxRanks.map((x) => ({ ...x, __typename: undefined }));
    const index = newArr.findIndex((x) => x.accountType === accountType);
    if (index !== -1) {
      newArr[index] = { accountType, taxRank: value };
    } else {
      newArr.push({ accountType, taxRank: value });
    }

    setTaxRank(newArr);
  };

  return (
    <>
      {accountTypesData.map((x: any) => (
        <ListItem key={x.value}>
          <Grid container alignItems='center'>
            <Grid item xs={4}>
              <Typography>{translateBackend(x.translatedName)}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type='number'
                value={accountTaxRank(x.value)}
                onChange={(value) => updateFields(x.value, Number(value.target.value))}
              />
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </>
  );
};

export default AccountTaxRanks;
