import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button, Dialog, DialogContent, DialogTitle, Form, IconButton, MenuItem, SelectField, Switch, TextField, TranslatableTextField,
} from '../../../2-component';
import { Box, Grid, Typography } from '../../../1-primative';
import { PageObjectType } from '../pageConfiguration';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { NumberField } from '../../../3-pattern';
import { WorkflowSelectFieldEdit } from '../../../4-module/configurableOptionFields';

export const EditPage = ({ page, setPage }: { page: any; setPage: (e: any) => void }) => {
  const { t } = useTranslation(['pageConfiguration', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const defaultDepositWorkflowLabel = {
    en: t('pageConfiguration:editPage.options.defaultDepositWorkflow.label'),
    fr: t('pageConfiguration:editPage.options.defaultDepositWorkflow.label'),
  };
  const defaultDepositWorkflowOption = {
    __typename: 'WidgetOption',
    default: undefined,
    key: 'depositWorkflow',
    options: [{ label: defaultDepositWorkflowLabel, value: undefined }],
    showIf: { hideDeposit: false },
    optionType: 'WORKFLOW_SELECT_FIELD',
    label: undefined,
    value: undefined,
  };
  const defaultWithdrawWorkflowOption = {
    __typename: 'WidgetOption',
    default: undefined,
    key: 'withdrawWorkflow',
    options: [{ label: defaultDepositWorkflowLabel, value: undefined }],
    showIf: { hideDeposit: false },
    optionType: 'WORKFLOW_SELECT_FIELD',
    label: undefined,
    value: undefined,
  };
  const initialOptions = {
    depositWorkflow: page.options?.depositWorkflow ?? defaultDepositWorkflowOption,
    withdrawWorkflow: page.options?.withdrawWorkflow ?? defaultWithdrawWorkflowOption,
    maxPageWidth: page.options?.maxPageWidth,
    greeting: page.options?.greeting ?? false,
    enableContent: page.options?.enableContent ?? false,
    content: page.options?.content ?? '',
    translatedContent: page.options?.translatedContent ?? {},
    mobileContentDescriptionBanner: page.options?.mobileContentDescriptionBanner ?? {},
    mobileContentDescriptionTitle: page.options?.mobileContentDescriptionTitle ?? {},
    mobileContentDescription: page.options?.mobileContentDescription ?? {},
    hideDeposit: page.options?.hideDeposit ?? false,
    hideWithdraw: page.options?.hideWithdraw ?? false,
    ...([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(page.type)
      ? {
        canTriggerWorkflows: page.options?.canTriggerWorkflows ?? false,
        canChangeOrganization: page.options?.canChangeOrganization ?? false,
        canChangeHousehold: page.options?.canChangeHousehold ?? false,
        canFreezeClient: page.options?.canFreezeClient ?? false,
        canDeactivateClient: page.options?.canDeactivateClient ?? false,
      }
      : {}),
    ...(page.type === PageObjectType.GOAL ? { canCloseGoal: page.options?.canCloseGoal === true || !('canCloseGoal' in page.options) } : {}),
    ...(page.type === PageObjectType.ACCOUNT
      ? {
        enableAddingAccountToHousehold: page.options?.enableAddingAccountToHousehold ?? false,
        canCloseAccount: page.options?.canCloseAccount ?? activeOrganization.availableFeatureFlags?.includes('CLOSE_ACCOUNT'),
        canForceOpenAccount: page.options?.canForceOpenAccount ?? false,
        enableAccountEditing: page.options?.enableAccountEditing ?? activeOrganization.availableFeatureFlags?.includes('CLOSE_ACCOUNT'),
        nickName: {
          enabled: page.options?.nickName?.enabled ?? !activeOrganization.availableFeatureFlags?.includes('CLOSE_ACCOUNT'),
          required: page.options?.nickName?.required ?? 'ERROR',
          label: {
            en: page.options?.nickName?.label?.en ?? 'Nickname',
            fr: page.options?.nickName?.label?.fr ?? '',
          },
        },
        state: {
          enabled: page.options?.state?.enabled ?? false,
          required: page.options?.state?.required ?? 'ERROR',
          label: {
            en: page.options?.state?.label?.en ?? 'Account status',
            fr: page.options?.state?.label?.fr ?? '',
          },
        },
        custodianAccountNumber: {
          enabled: page.options?.custodianAccountNumber?.enabled ?? false,
          required: page.options?.custodianAccountNumber?.required ?? 'ERROR',
          label: {
            en: page.options?.custodianAccountNumber?.label?.en ?? 'Account number',
            fr: page.options?.custodianAccountNumber?.label?.fr ?? '',
          },
        },
      }
      : {}),
    ...(page.type === PageObjectType.SUB_ACCOUNT ? { canCloseSubAccount: page.options?.canCloseSubAccount === true || !('canCloseSubAccount' in page.options) } : {}),
  };

  const accountOptions = ['nickName', 'state', 'custodianAccountNumber'];
  const [open, setOpen] = useState<boolean>(false);
  const [pageName, setPageName] = useState<string>(page.name ?? '');
  const [options, setOptions] = useState<any>(initialOptions);

  const handleClose = () => {
    setOptions(initialOptions);
    setOpen(false);
  };
  const onSubmit = () => {
    setPage({
      ...page,
      name: pageName,
      options,
    });
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} label={t('pageConfiguration:editPage.button')} variant='outlined' />
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Typography variant='headingSmall' sx={{ mr: 0.5 }}>
              {t('pageConfiguration:editPage.title')}
            </Typography>
            <IconButton onClick={handleClose} aria-label='close' size='medium'>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={onSubmit}>
            <TextField fullWidth label={t('pageConfiguration:pageName')} value={pageName} onChange={(e: any) => setPageName(e.target.value)} sx={{ mb: 2 }} />
            <NumberField
              label={t('pageConfiguration:editPage.options.maxPageWidth')}
              number={options.maxPageWidth}
              setNumber={(e: any) => setOptions({ ...options, maxPageWidth: e })}
              sx={{ mb: 2 }}
            />
            {page.type === PageObjectType.INDIVIDUAL && (
              <Box sx={{ mb: 2 }}>
                <Switch label={t('pageConfiguration:editPage.options.greeting')} checked={options.greeting} onChange={(e: any) => setOptions({ ...options, greeting: e })} />
              </Box>
            )}
            {page.type === PageObjectType.ACCOUNT && (
              <Box sx={{ mb: 2 }}>
                <Switch
                  label={t('pageConfiguration:editPage.options.enableAddingAccountToHousehold')}
                  checked={options.enableAddingAccountToHousehold}
                  onChange={(e: any) => setOptions({ ...options, enableAddingAccountToHousehold: e })}
                />
              </Box>
            )}
            <Box sx={{ mb: 2 }}>
              <Switch label={t('pageConfiguration:editPage.options.addContent')} checked={options.enableContent} onChange={(e: any) => setOptions({ ...options, enableContent: e })} />
            </Box>
            {options.enableContent && [PageObjectType.INDIVIDUAL, PageObjectType.HOUSEHOLD].includes(page.type) && (
              <TranslatableTextField
                fullWidth
                label={t('pageConfiguration:editPage.options.mobileContentDescriptionBanner')}
                value={options.mobileContentDescriptionBanner}
                onChange={(e: any) => setOptions({ ...options, mobileContentDescriptionBanner: e })}
                sx={{ mb: 2 }}
                error={(options?.mobileContentDescriptionBanner?.en?.length ?? 0) > 50}
                errorText={t('pageConfiguration:editPage.options.mobileContentDescriptionBannerError')}
              />
            )}
            {options.enableContent && [PageObjectType.INDIVIDUAL, PageObjectType.HOUSEHOLD].includes(page.type) && (
              <TranslatableTextField
                fullWidth
                label={t('pageConfiguration:editPage.options.mobileContentDescriptionTitle')}
                value={options.mobileContentDescriptionTitle}
                onChange={(e: any) => setOptions({ ...options, mobileContentDescriptionTitle: e })}
                sx={{ mb: 2 }}
              />
            )}
            {options.enableContent && [PageObjectType.INDIVIDUAL, PageObjectType.HOUSEHOLD].includes(page.type) && (
              <TranslatableTextField
                fullWidth
                label={t('pageConfiguration:editPage.options.mobileContentDescription')}
                multiline
                rows={3}
                value={options.mobileContentDescription}
                onChange={(e: any) => setOptions({ ...options, mobileContentDescription: e })}
                sx={{ mb: 2 }}
              />
            )}
            {options.enableContent && (
              <TranslatableTextField
                fullWidth
                label={t('pageConfiguration:editPage.options.contentDescription')}
                multiline
                rows={3}
                value={options.translatedContent}
                onChange={(e: any) => setOptions({ ...options, translatedContent: e })}
                sx={{ mb: 2 }}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <Switch label={t('pageConfiguration:editPage.options.deposit')} checked={!options.hideDeposit} onChange={(e: any) => setOptions({ ...options, hideDeposit: !e })} />
            </Box>
            {!options.hideDeposit && activeOrganization.availableFeatureFlags?.includes('DEPOSIT_WORKFLOW') && (
              <WorkflowSelectFieldEdit
                option={options.depositWorkflow}
                options={[options]}
                setOptions={(value) => setOptions({ ...options, depositWorkflow: value[0] })}
                i={0}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <Switch label={t('pageConfiguration:editPage.options.withdraw')} checked={!options.hideWithdraw} onChange={(e: any) => setOptions({ ...options, hideWithdraw: !e })} />
            </Box>
            {!options.hideWithdraw && activeOrganization.availableFeatureFlags?.includes('WITHDRAW_WORKFLOW') && (
              <WorkflowSelectFieldEdit
                option={options.withdrawWorkflow}
                options={[options]}
                setOptions={(value) => setOptions({ ...options, withdrawWorkflow: value[0] })}
                i={0}
              />
            )}
            {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(page.type)
              && ['canTriggerWorkflows', 'canChangeOrganization', 'canChangeHousehold', 'canFreezeClient', 'canDeactivateClient'].map((option: string) => (
                <Box sx={{ mb: 2 }}>
                  <Switch label={t(`pageConfiguration:editPage.options.${option}`)} checked={options[option]} onChange={(e: any) => setOptions({ ...options, [option]: e })} />
                </Box>
              ))}
            {page.type === PageObjectType.GOAL && (
              <Box sx={{ mb: 2 }}>
                <Switch label={t('pageConfiguration:editPage.options.canCloseGoal')} checked={options.canCloseGoal} onChange={(e: any) => setOptions({ ...options, canCloseGoal: e })} />
              </Box>
            )}
            {page.type === PageObjectType.SUB_ACCOUNT && (
              <Box sx={{ mb: 2 }}>
                <Switch
                  label={t('pageConfiguration:editPage.options.canCloseSubAccount')}
                  checked={options.canCloseSubAccount || !('canCloseSubAccount' in options)}
                  onChange={(e: any) => setOptions({ ...options, canCloseSubAccount: e })}
                />
              </Box>
            )}
            {page.type === PageObjectType.ACCOUNT && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Switch
                    label={t('pageConfiguration:editPage.options.enableAccountEditing')}
                    checked={options.enableAccountEditing}
                    onChange={(e: any) => setOptions({ ...options, enableAccountEditing: e })}
                  />
                </Box>
                {options.enableAccountEditing && (
                  <>
                    {accountOptions.map((option) => (
                      <Grid key={option} container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1} alignItems='end'>
                        <Grid item xs={6}>
                          <TranslatableTextField
                            label={t(`editPage.options.${option}`)}
                            value={options[option].label}
                            disabled={!options[option].enabled}
                            fullWidth
                            onChange={(value) => {
                              const newOptions = { ...options };
                              const newCurrentOption = { ...newOptions[option] };
                              newCurrentOption.label = value;
                              newOptions[option] = newCurrentOption;
                              setOptions({ ...newOptions });
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectField
                            label={t('shared:required')}
                            value={options[option].required}
                            disabled={!options[option].enabled}
                            fullWidth
                            onChange={(e: any) => {
                              const newOptions = { ...options };
                              const newCurrentOption = { ...newOptions[option] };
                              newCurrentOption.required = e.target.value;
                              newOptions[option] = newCurrentOption;
                              setOptions({ ...newOptions });
                            }}
                          >
                            <MenuItem value='ERROR'>{t('shared:error')}</MenuItem>
                            <MenuItem value='WARNING'>{t('shared:warning')}</MenuItem>
                            <MenuItem value='NOT_REQUIRED'>{t('shared:notRequired')}</MenuItem>
                            <MenuItem value='NOT_EDITABLE'>{t('shared:notEditable')}</MenuItem>
                          </SelectField>
                        </Grid>
                        <Grid item xs={2} justifyContent='center' display='flex' sx={{ mt: 2 }}>
                          <Switch
                            sx={{ mb: 1 }}
                            checked={options[option].enabled}
                            onChange={(e: any) => {
                              const newOptions = { ...options };
                              const newCurrentOption = { ...newOptions[option] };
                              newCurrentOption.enabled = e;
                              newOptions[option] = newCurrentOption;
                              setOptions({ ...newOptions });
                            }}
                          ></Switch>
                        </Grid>
                      </Grid>
                    ))}
                    <Box sx={{ mb: 2 }}>
                      <Switch label={t('pageConfiguration:editPage.options.canCloseAccount')} checked={options.canCloseAccount} onChange={(e: any) => setOptions({ ...options, canCloseAccount: e })} />
                    </Box>
                    {permissions.includes('write:force_account_open') && (
                      <Box sx={{ mb: 2 }}>
                        <Switch
                          label={t('pageConfiguration:editPage.options.canForceOpenAccount')}
                          checked={options.canForceOpenAccount}
                          onChange={(e: any) => setOptions({ ...options, canForceOpenAccount: e })}
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
            <Box display='flex' flexDirection='row' justifyContent='end'>
              <Button type='submit' label={t('pageConfiguration:save')} sx={{ mt: 3 }} />
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
