import {
  Box,
  Button, Typography,
} from '@mui/material';
import { colors } from 'ovComponents';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../wizard';

const styles = {
  listItem: {
    marginTop: '1em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export function SummaryStep({
  state, context, continueTo,
}: StepProps) {
  const { t } = useTranslation('addClientToHouseholdWizard');

  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box m={2}>
        <Typography align='center' variant='wizardSubTitle' mb={1}>{t('memberSummary')}</Typography>
        <Typography align='center' variant='subtitle1' mb={2}>{t('pleaseReview')}</Typography>
        <hr style={{ height: 0, borderColor: colors.neutral300 }} />
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('name')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {context.userName}
          </Typography>
        </Box>
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('householdName')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {state.clientGroup?.name}
          </Typography>
        </Box>
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('relationship')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {t(`affiliationTypes:affiliationRelationship.${state.relationship}`)}
          </Typography>
        </Box>
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('accessType')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {t(`household:accessTypes.${state.accessType}`)}
          </Typography>
        </Box>
      </Box>

      <Box m={2}>
        <Button type='submit' fullWidth variant='contained'
          onClick={() => continueTo('ADD_CLIENT_TO_HOUSEHOLD')}
        >
          {t('addToHousehold')}
        </Button>
      </Box>
    </Box>
  );
}
