import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface AccordionTokens {
  accordion: {
    borderRadius?: string;
    borderColor?: string;
    multipleBorderColor?: string;
  },
}

export const useAccordionTokens = (): AccordionTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: AccordionTokens = {
    accordion: {
      borderRadius: sys.borderRadius.xl,
      borderColor: sys.color.outline,
      multipleBorderColor: sys.color.outlineVariant,
    },
  };

  return comp;
};
