/* eslint-disable object-curly-newline */
/* eslint-disable no-continue */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { round } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import GenerateTradesModal from './components/generateTradesModal';
import BulkTradeRequests from './components/bulkTradeRequests';
import SmallDollarPercentage from './components/smallDollarPercentage';
import { delay } from '../../../../util';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { PortfolioReport, PortfolioReportRefreshStates } from '../../../../interfaces';
import SubTradeRequestPairsModal from './components/subTradeRequestPairsModal';
import { Box, Typography, Grid } from '../../../1-primative';
import { Switch, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Card, Button, TableHeadCell, Menu, MenuItem } from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { SubTradeRequest, SubTradeRequestStates } from '../../../../interfaces/subTradeRequest';
import { PortfolioOptimizer, PortfolioOptimizerStates } from '../../../../interfaces/portfolioOptimizer';
import { BulkTradeRequest, BulkTradeRequestStates } from '../../../../interfaces/bulkTradeRequest';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './components/interfaces';
import { FETCH_BULK_TRADES, FETCH_OPTIMAL_PORTFOLIO, FETCH_PORTFOLIO_REPORTS, FETCH_SUB_TRADES, REFRESH_PORTFOLIO_REPORT } from './components/queries';
import {
  areTradesAmountCentsDifferent,
  buildDraftSubTradeRequest,
  buildNewDraftTrade,
  buildNewOptimalDraftTrade,
  buildPortfolioSubAccount,
  buildSecurity,
  collectPendingTransactionTickers,
  getKey,
  getSubAccountCurrentCents,
  sortPortfolioReportSubAccounts,
  sortSecurities,
  sumPendingTradesCentsByCondition,
} from './components/utils';
import { SecurityTableRow } from './components/securityTableRow';
import { SecurityTotalTableRow } from './components/securityTotalTableRow';
import { SubAccountTableHeadCell } from './components/subAccountTableHeadCell';
import { SubAccountTableCell } from './components/subAccountTableCell';
import { SubAccountHoldingTableCell } from './components/subAccountHoldingTableCell';
import { useLocalization } from '../../../../util/useLocalization';
import { RUN_PORTFOLIO_OPTIMIZER } from '../../../../pages/portfolioOptimizers/components/selectOptimizerModal';
import { FETCH_PORTFOLIO_OPTIMIZER } from '../../../../pages/portfolioOptimizer';
import { OptimalPortfolio } from '../../../../interfaces/optimalPortfolio';
import {
  addPortfolioWarning,
  buildIlliquidProduct,
  IlliquidProductsMap,
  illiquidProductsToString,
  PortfolioWarningKeys,
  PortfolioWarnings,
  PortfolioWarningsMap,
} from './components/portfolioWarnings';

const POLLING_REFRESH_INTERVAL = 500; // milliseconds (0.5 second)
const TIMEOUT_INTERVAL = 10000; // milliseconds (10 seconds)

export const Portfolio = ({
  id,
  type,
  options,
  portfolioReportId,
  setIsTradesUpdated,
  onPortfolioRefresh,
  hideTitle = false,
}: {
  id: string;
  type: PageObjectType;
  options?: Record<string, any>;
  portfolioReportId?: string;
  setIsTradesUpdated?: (state: boolean) => void;
  onPortfolioRefresh?: (portfolioReport: PortfolioReport) => void;
  hideTitle?: boolean;
}) => {
  const { t } = useTranslation(['components', 'accountTypes', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { sys } = useThemeTokens();
  const { localizedDateTime, localizedDate } = useLocalization();

  const [securities, setSecurities] = useState<PortfolioSecurity[]>([]);
  const [subAccounts, setSubAccounts] = useState<PortfolioSubAccount[]>([]);
  const [portfolioReport, setPortfolioReport] = useState<PortfolioReport | undefined>(undefined);
  const [lastOptimizedBy, setLastOptimizedBy] = useState<PortfolioOptimizer | undefined>(undefined);
  const [bulkTradeRequest, setBulkTradeRequest] = useState<BulkTradeRequest | undefined>(undefined);

  const [draftTrades, setDraftTrades] = useState<Map<string, DraftSubTradeRequest>>(new Map());
  const [initialDraftTrades, setInitialDraftTrades] = useState<Map<string, DraftSubTradeRequest>>(new Map());
  const [processingTrades, setProcessingTrades] = useState<Map<string, DraftSubTradeRequest>>(new Map());

  const [isPortfolioEditable, setIsPortfolioEditable] = useState(false);
  const [autoFilled, setAutoFilled] = useState<boolean>(false);
  const [openTradePairs, setOpenTradePairs] = useState<boolean>(false);
  const [viewPreTradePositions, setViewPreTradePositions] = useState<boolean>(false);
  const [isDraftTradesUpdated, setIsDraftTradesUpdated] = useState<boolean>(false);
  const [isErrorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [portfolioNotFound, setPortfolioNotFound] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isRefreshRequested, setIsRefreshRequested] = useState<boolean>(false);
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false);
  const [loadingPortfolioOptimizerPolling, setLoadingPortfolioOptimizerPolling] = useState(false);
  const [loadingPortfolioReportsPolling, setLoadingPortfolioReportsPolling] = useState<boolean>(false);
  const [portfolioWarnings, setPortfolioWarnings] = useState<PortfolioWarningsMap>(new Map());
  const illiquidProducts: IlliquidProductsMap = new Map();

  const canSeePortfolio = permissions.some((x) => ['read:rebalance_basic', 'read:portfolio_optimizer'].includes(x)) && permissions.includes('read:bulk_trade_request');
  const canSeeTradePairsModal = activeOrganization?.allowViewSubTradeRequestPairs && permissions.includes('write:sub_trade_request');
  const canSeeAutoRebalance = permissions.includes('write:portfolio_optimizer') && permissions.includes('read:portfolio_optimizer');

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetReferences = () => {
    // unsetting the reference so the portfolio will behave like a new one (create a new bulk trade request)
    setLastOptimizedBy(undefined);
    setBulkTradeRequest(undefined);

    fetchBulkTrades({
      variables: {
        input: {
          filter: {
            sourceId: id,
            state: BulkTradeRequestStates.REQUESTED,
          },
        },
      },
    });
  };

  const resetAllMaps = () => {
    setDraftTrades(new Map());
    setInitialDraftTrades(new Map());
    setProcessingTrades(new Map());
    setPortfolioWarnings(new Map());

    setLastOptimizedBy(undefined);
    setBulkTradeRequest(undefined);
  };

  const refreshPortfolio = () => {
    resetAllMaps();

    if (portfolioReportId || portfolioReport?.id) {
      refreshPortfolioReport({
        variables: { input: { portfolioReportId: portfolioReportId ?? portfolioReport?.id } },
      });

      return;
    }

    refetchPortfolioReports();
  };

  const processPortfolioReportData = (portfolioReportData: PortfolioReport) => {
    setPortfolioReport(portfolioReportData);

    if (onPortfolioRefresh) onPortfolioRefresh(portfolioReportData);

    const localLastOptimizedBy = portfolioReportData?.lastOptimizedBy;

    if (localLastOptimizedBy) {
      setLastOptimizedBy(localLastOptimizedBy);
    }

    fetchBulkTrades({
      variables: {
        input: {
          filter: {
            sourceId: localLastOptimizedBy?.id ?? id,
            ...(!localLastOptimizedBy ? { state: BulkTradeRequestStates.REQUESTED } : {}),
          },
        },
      },
    });
  };

  const recordTimeOut = () => setTimeout(() => setIsTimedOut(true), TIMEOUT_INTERVAL);

  const [fetchPortfolioReports, {
    loading: loadingPortfolioReports,
    refetch: refetchPortfolioReports,
    startPolling: startPollingPortfolioReports,
    stopPolling: stopPollingPortfolioReports,
  }] = useLazyQuery(FETCH_PORTFOLIO_REPORTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          ...(type === PageObjectType.GOAL ? { goalId: id } : { subAccountId: id }),
          organizationId: activeOrganization?.id,
        },
      },
    },
    onCompleted: (data: any) => {
      const portfolioReportData: PortfolioReport = data?.fetchPortfolioReports?.portfolioReports?.[0] ?? null;

      if (!portfolioReportData) {
        setPortfolioNotFound(true);

        return;
      }

      const isUpdating = portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATING;
      const isUpdated = portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATED;

      if (!isRefreshRequested && isUpdating) {
        setLoadingPortfolioReportsPolling(true);
        setIsRefreshRequested(true);
        startPollingPortfolioReports(POLLING_REFRESH_INTERVAL);
        recordTimeOut();
      }

      if (!isRefreshRequested && isUpdated) {
        setIsRefreshRequested(true);

        refreshPortfolioReport({
          variables: { input: { portfolioReportId: portfolioReportData?.id } },
        });
      }

      if (isRefreshRequested && (isUpdated || (isUpdating && loadingPortfolioReportsPolling && isTimedOut))) {
        if (isTimedOut) setIsTimedOut(false);

        setIsRefreshRequested(false);
        setLoadingPortfolioReportsPolling(false);
        stopPollingPortfolioReports();

        processPortfolioReportData(portfolioReportData);
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [refreshPortfolioReport, { loading: loadingRefreshPortfolioReport }] = useMutation(REFRESH_PORTFOLIO_REPORT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      const portfolioReportData: PortfolioReport = data?.refreshPortfolioReport?.portfolioReport ?? null;

      if (!portfolioReportData) {
        setPortfolioNotFound(true);

        return;
      }

      if (portfolioReportData.refreshState === PortfolioReportRefreshStates.UPDATING) {
        setIsRefreshRequested(true);
        setLoadingPortfolioReportsPolling(true);
        startPollingPortfolioReports(POLLING_REFRESH_INTERVAL);
        recordTimeOut();

        return;
      }

      processPortfolioReportData(portfolioReportData);
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [runPortfolioOptimizer] = useMutation(RUN_PORTFOLIO_OPTIMIZER, {
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [fetchPortfolioOptimizer, { stopPolling: stopPollingPortfolioOptimizer }] = useLazyQuery(FETCH_PORTFOLIO_OPTIMIZER, {
    pollInterval: 500,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: any) => {
      const { portfolioOptimizer }: { portfolioOptimizer: PortfolioOptimizer } = response.fetchPortfolioOptimizer;

      if ([PortfolioOptimizerStates.COMPLETED, PortfolioOptimizerStates.CANCELED].includes(portfolioOptimizer?.state)) {
        stopPollingPortfolioOptimizer();
        setLoadingPortfolioOptimizerPolling(false);

        if ([PortfolioOptimizerStates.COMPLETED].includes(portfolioOptimizer?.state)) {
          fetchOptimalPortfolio();
        }
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [fetchOptimalPortfolio, { loading: loadingOptimalPortfolio }] = useLazyQuery(FETCH_OPTIMAL_PORTFOLIO, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { input: { optimalPortfolioId: portfolioReport?.id } },
    onCompleted: (response: any) => {
      const { optimalPortfolio }: { optimalPortfolio: OptimalPortfolio } = response.fetchOptimalPortfolio;

      if (optimalPortfolio) {
        const localDraftTrades = new Map<string, DraftSubTradeRequest>();

        for (const subAccount of optimalPortfolio.subAccounts) {
          for (const optimalHolding of subAccount.optimalHoldings) {
            const key = getKey(optimalHolding.financialProduct, subAccount.subAccount);

            const trade: DraftSubTradeRequest = buildNewOptimalDraftTrade(optimalHolding, subAccount, securities, subAccounts);

            localDraftTrades.set(key, trade);
          }
        }

        if (localDraftTrades.size) {
          setDraftTrades(new Map(localDraftTrades));
          setAutoFilled(true);
        }
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  const [fetchBulkTrades, { loading: loadingBulkTrades }] = useLazyQuery(FETCH_BULK_TRADES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: any) => {
      const localBulkTradeRequest = response?.fetchBulkTradeRequests?.bulkTradeRequests?.[0] ?? undefined;

      setBulkTradeRequest(localBulkTradeRequest);
      setIsPortfolioEditable(false);

      if (!localBulkTradeRequest) {
        setIsPortfolioEditable(true);
      }

      if (lastOptimizedBy) {
        setIsPortfolioEditable(true); // there is an active bulk trade request created by an optimizer - trades can be updated
      }

      if (localBulkTradeRequest) {
        // when a bulk trade request is a part of a optimizer run but it was CANCELED or COMPLETED we start a new one
        if (lastOptimizedBy && [BulkTradeRequestStates.CANCELED, BulkTradeRequestStates.COMPLETED].includes(localBulkTradeRequest.state)) {
          resetReferences();

          return;
        }

        fetchSubTrades({
          variables: {
            input: {
              filter: {
                bulkTradeRequestId: localBulkTradeRequest?.id,
                ...(lastOptimizedBy && { sourceId: id }),
              },
              pagination: {
                sortField: 'subAccount',
                perPage: 100,
              },
            },
          },
        });
      }
    },
    onError: () => {
      setErrorOccurred(true);
    },
  });

  // !todo: pass down to the BulkTradeRequests and SubTradeRequestPairsModal instead of making 3 separate calls to fetch the same sub-trade requests
  const [fetchSubTrades, { loading: loadingSubTrades }] = useLazyQuery(FETCH_SUB_TRADES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: any) => {
      if (response?.fetchSubTradeRequests?.subTradeRequests?.length) {
        const { subTradeRequests }: { subTradeRequests: SubTradeRequest[] } = response.fetchSubTradeRequests;

        /* when a bulkTradeRequest is generated by an optimizer and all of the subTradeRequests that match { bulkTradeRequest: 'id', sourceId: 'id' }
        are either in CANCELED or RECONCILED states, we consider this bulkTradeRequest COMPLETED and will start a new one */
        const processedStates = [SubTradeRequestStates.CANCELED, SubTradeRequestStates.RECONCILED];
        const numOfProcessedSubTradeRequests = subTradeRequests?.filter((trade: any) => processedStates.includes(trade.state))?.length ?? 0;
        // making sure that there are no pending splits left
        const numOfPendingSplits = subTradeRequests
          ?.reduce((items: any[], trade: any) => {
            items.push(...trade.splits);
            return items;
          }, [])
          ?.filter((trade: any) => !processedStates.includes(trade.state)).length ?? 0;

        if (!numOfPendingSplits && numOfProcessedSubTradeRequests === subTradeRequests.length) {
          resetReferences();

          return;
        }

        const localDraftTrades = new Map<string, DraftSubTradeRequest>();
        const localProcessingTrades = new Map<string, DraftSubTradeRequest>();

        const usdTickers: string[] = [];
        const mfSellAllTickers: string[] = [];

        for (const subTradeRequest of subTradeRequests) {
          collectPendingTransactionTickers(
            subTradeRequest,
            usdTickers,
            mfSellAllTickers,
            activeOrganization,
          );
          const trade = buildDraftSubTradeRequest(subTradeRequest, t);

          if (!trade.financialProductId || !trade.subAccountId) continue;

          const key = getKey(trade.financialProductId, trade.subAccountId);

          if ([SubTradeRequestStates.READY, SubTradeRequestStates.INITIATED].includes(subTradeRequest.state)) {
            localDraftTrades.set(key, trade as DraftSubTradeRequest);

            if (subTradeRequest?.blockedUntilAccountActive) addPortfolioWarning(PortfolioWarningKeys.ACCOUNT_IS_BLOCKED, portfolioWarnings);
            if (subTradeRequest?.blockedUntilSubAccountActive) addPortfolioWarning(PortfolioWarningKeys.SUB_ACCOUNT_IS_BLOCKED, portfolioWarnings);
            if (subTradeRequest?.blockedUntil) buildIlliquidProduct(subTradeRequest, illiquidProducts);
          }

          if ([SubTradeRequestStates.REQUESTED].includes(subTradeRequest.state)) localProcessingTrades.set(key, trade as DraftSubTradeRequest);
        }

        setDraftTrades(new Map(localDraftTrades));
        setInitialDraftTrades(new Map(localDraftTrades));
        setProcessingTrades(new Map(localProcessingTrades));

        if (illiquidProducts.size) addPortfolioWarning(PortfolioWarningKeys.ILLIQUID_PRODUCTS, portfolioWarnings, { datesAndTickers: illiquidProductsToString(illiquidProducts, localizedDate) });
        if (usdTickers.length > 0) {
          addPortfolioWarning(
            PortfolioWarningKeys.PENDING_TRANSACTION_USD_CURRENCY,
            portfolioWarnings,
            { tickers: usdTickers.join(', ') },
          );
        }
        if (mfSellAllTickers.length > 0) {
          addPortfolioWarning(
            PortfolioWarningKeys.PENDING_TRANSACTION_MF_SELL_ALL,
            portfolioWarnings,
            { tickers: mfSellAllTickers.join(', ') },
          );
        }
        setPortfolioWarnings(portfolioWarnings);
      }
    },
  });

  const buildPortfolio = useCallback(() => {
    if (!portfolioReport) return;

    const localPortfolioSubAccounts: PortfolioSubAccount[] = [];

    for (const subAccountReport of portfolioReport?.subAccounts ?? []) {
      const { subAccount } = subAccountReport;

      if (!subAccount?.id) continue;

      localPortfolioSubAccounts.push(buildPortfolioSubAccount(subAccountReport, t));
    }

    localPortfolioSubAccounts.sort(sortPortfolioReportSubAccounts);

    setSubAccounts(localPortfolioSubAccounts);

    const localSecurities: PortfolioSecurity[] = [];

    for (const security of portfolioReport?.holdings ?? []) {
      const { financialProduct } = security;

      if (!financialProduct?.id) continue;

      if (!security.taxRank && security.expectedValueCents === 0 && security.valueCents === 0) continue;

      const localSubAccounts = localPortfolioSubAccounts.map((subAccount) => {
        // find a sub-account's holding for this security
        const holding = subAccount?.holdings?.find((h) => h.financialProduct.id === financialProduct.id);

        return { ...subAccount, holding };
      });

      localSecurities.push(buildSecurity(security, localSubAccounts));
    }

    localSecurities.sort(sortSecurities);

    setSecurities(localSecurities);
  }, [t, portfolioReport]);

  useEffect(() => {
    if (!portfolioReportId && canSeePortfolio && id && activeOrganization?.id) {
      fetchPortfolioReports();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (portfolioReportId) {
      refreshPortfolioReport({
        variables: { input: { portfolioReportId } },
      });
    }
  }, [portfolioReportId, refreshPortfolioReport]);

  useEffect(() => {
    if (portfolioReport) {
      buildPortfolio();
    }
  }, [portfolioReport, buildPortfolio]);

  useEffect(() => {
    const isTradesUpdated = areTradesAmountCentsDifferent(draftTrades, initialDraftTrades);

    setIsDraftTradesUpdated(isTradesUpdated);

    if (setIsTradesUpdated) setIsTradesUpdated(isTradesUpdated);
  }, [draftTrades, initialDraftTrades, setIsTradesUpdated]);

  const updateDraftTrades = useCallback(
    (security: PortfolioSecurity, subAccount: PortfolioSubAccount, amount: string) => {
      const newAmount = round((parseFloat(amount) || 0) * 100, 2);

      const key = getKey(security.id, subAccount.id);

      if (newAmount === (subAccount.holding?.valueCents || 0)) {
        draftTrades.delete(key);

        return;
      }

      const newDraftTrade: DraftSubTradeRequest = buildNewDraftTrade(newAmount, subAccount, security);

      draftTrades.set(key, { ...draftTrades.get(key), ...newDraftTrade });
    },
    [draftTrades],
  );

  const handleAutoFillToTarget = useCallback(() => {
    if (securities.length) {
      securities?.forEach((security) => {
        if (security.expectedValueCents !== (security.subAccounts[0]?.holding?.valueCents ?? 0)) {
          updateDraftTrades(security, security.subAccounts[0], `${round(security.expectedValueCents / 100, 2)}`);
        }
      });

      setDraftTrades(new Map(draftTrades));
    }
  }, [draftTrades, securities, updateDraftTrades]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHoldingChange = useCallback(
    debounce((security: PortfolioSecurity, subAccount: PortfolioSubAccount, amount: string) => {
      updateDraftTrades(security, subAccount, amount);

      setDraftTrades(new Map(draftTrades));
    }, 300),
    [draftTrades, updateDraftTrades],
  );

  const isDisableAmountFieldByKey = useCallback(
    (key: string, isSubAccountInactive: boolean): boolean => {
      if (isSubAccountInactive) return true;

      if (!lastOptimizedBy && bulkTradeRequest) return true;

      if (lastOptimizedBy && !activeOrganization.allowPostOptimizationEditing) return true;

      if (lastOptimizedBy && activeOrganization.allowPostOptimizationEditing) {
        const trade = processingTrades.get(key); // if a trade is in this map it means it can't be updated

        if (trade) return true;
      }

      return false;
    },
    [lastOptimizedBy, bulkTradeRequest, activeOrganization, processingTrades],
  );

  const isCashNegative = useCallback((): boolean => {
    const negativeSubAccounts = subAccounts.filter((subAccount) => {
      const pendingTradesCents = sumPendingTradesCentsByCondition([draftTrades, processingTrades], (trade) => trade.subAccountId === subAccount.id);

      return getSubAccountCurrentCents(subAccount, pendingTradesCents) < 0;
    });

    return negativeSubAccounts.length > 0;
  }, [subAccounts, draftTrades, processingTrades]);

  const isConfirmTradesDisabled = useCallback(() => {
    if (lastOptimizedBy && !activeOrganization.allowPostOptimizationEditing) return true;

    if (!lastOptimizedBy && bulkTradeRequest) return true;

    if (!isPortfolioEditable) return true;

    if (isCashNegative()) return true;

    if (!isDraftTradesUpdated) return true;

    return false;
  }, [lastOptimizedBy, activeOrganization, bulkTradeRequest, isPortfolioEditable, isCashNegative, isDraftTradesUpdated]);

  const isAutoFillToTargetDisabled = useCallback(() => {
    for (const security of securities) {
      if (security.expectedValueCents !== (security.subAccounts[0]?.holding?.valueCents ?? 0)) return false;
    }

    return true;
  }, [securities]);

  const isExtendTableRow = !!processingTrades?.size || !!draftTrades?.size;

  const autoFillToTarget = () => {
    if (!autoFilled) {
      setAutoFilled(true);
      handleAutoFillToTarget();
    }
  };

  if (!canSeePortfolio) {
    return <>{t('shared:noPermission')}</>;
  }

  if (
    loadingPortfolioReports
    || loadingRefreshPortfolioReport
    || loadingBulkTrades
    || loadingSubTrades
    || loadingPortfolioOptimizerPolling
    || loadingOptimalPortfolio
    || loadingPortfolioReportsPolling
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ m: 18 }} />
      </Box>
    );
  }

  if (portfolioNotFound) {
    return type === PageObjectType.GOAL ? (
      <Typography color={sys.color.negative}>{t('errorPortfolioViewUnderSubAccount')}</Typography>
    ) : (
      <Typography color={sys.color.negative}>{t('errorPortfolioViewUnderGoal')}</Typography>
    );
  }

  if (isErrorOccurred) {
    return <Typography color={sys.color.negative}>{t('errorOccurredWhileLoadingPortfolio')}</Typography>;
  }

  return (
    <>
      {!hideTitle && (
        <Typography variant='headingSmall' mb={2}>
          {options?.customTitle ? translateBackend(options.customTitle) : t('portfolio')}
        </Typography>
      )}

      <Card sx={{ overflowX: 'auto' }}>
        <PortfolioWarnings portfolioWarnings={portfolioWarnings} />

        <Box p={2} justifyContent='space-between' display='flex' alignItems='center'>
          <div>
            <Switch size='small' label={t('viewPreTradePositions')} onChange={(e) => setViewPreTradePositions(e)} />
          </div>

          <div>
            <Grid>
              <Button
                variant='outlined'
                sx={{ mr: '10px' }}
                disabled={!isPortfolioEditable || !isDraftTradesUpdated}
                onClick={() => {
                  setAutoFilled(false);
                  setDraftTrades(initialDraftTrades.size ? new Map(initialDraftTrades) : new Map());
                }}
                label={t('revert')}
              />

              {subAccounts.length === 1 && (
                <>
                  {canSeeAutoRebalance && (
                    <>
                      <Button
                        label={t('autoRebalance')}
                        variant='filled'
                        sx={{ mr: '10px' }}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        disabled={!isPortfolioEditable || isAutoFillToTargetDisabled()}
                      />

                      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ mt: 1 }} disableAutoFocus={true} disableAutoFocusItem={true}>
                        <MenuItem
                          onClick={async () => {
                            if (portfolioReport?.id) {
                              setLoadingPortfolioOptimizerPolling(true);

                              const portfolioOptimizer = await runPortfolioOptimizer({
                                variables: {
                                  input: {
                                    organizationId: activeOrganization.id,
                                    portfolioReportId: portfolioReport.id,
                                    dryRun: true,
                                  },
                                },
                              });

                              const portfolioOptimizerId = portfolioOptimizer?.data?.runPortfolioOptimizer?.portfolioOptimizer?.id ?? null;

                              if (portfolioOptimizerId) fetchPortfolioOptimizer({ variables: { input: { portfolioOptimizerId } } });
                            }

                            handleClose();
                          }}
                        >
                          {t('optimizer')}
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            autoFillToTarget();
                            handleClose();
                          }}
                        >
                          {t('autoFillToTarget')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}

                  {!canSeeAutoRebalance && (
                    <Button
                      variant='outlined'
                      sx={{ mr: '10px' }}
                      disabled={!isPortfolioEditable || isAutoFillToTargetDisabled() || autoFilled}
                      onClick={autoFillToTarget}
                      label={t('autoFillToTarget')}
                    />
                  )}
                </>
              )}

              <GenerateTradesModal
                trades={Array.from(draftTrades?.values() ?? []).filter((trade: any) => trade.amountCents !== 0)}
                afterCreate={async () => {
                  // await the update of a portfolio report to sync
                  await delay(500);
                  refreshPortfolio();
                  setAutoFilled(false);

                  if (canSeeTradePairsModal) {
                    setOpenTradePairs(true);
                  }
                }}
                type={type}
                id={id}
                modalOpenDisabled={isConfirmTradesDisabled()}
                bulkTradeId={lastOptimizedBy?.id ? bulkTradeRequest?.id : undefined}
                portfolioOptimizerId={lastOptimizedBy?.id}
                bulkTradeRequest={bulkTradeRequest}
              />
            </Grid>

            <Grid pt={1} textAlign={'right'}>
              {!!portfolioReport?.updatedAt && (
                <Typography color={sys.color.onSurfaceVariant}>
                  {t('portfolioLastUpdated')} {localizedDateTime(portfolioReport?.updatedAt)}
                </Typography>
              )}
            </Grid>
          </div>
        </Box>

        <Grid container>
          <Grid item xs='auto'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>{t('portfolioTable.security')}</TableHeadCell>
                  <TableHeadCell number>{t('portfolioTable.target')}</TableHeadCell>
                  <TableHeadCell number>{t('portfolioTable.current')}</TableHeadCell>
                  <TableHeadCell number>{t('portfolioTable.deviation')}</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <SecurityTableRow
                  isCash={true}
                  security={{
                    id: 'CASH',
                    ticker: t('portfolioTable.cash'),
                    expectedValueCents: portfolioReport?.expectedCashCents ?? 0,
                    expectedPercentage: portfolioReport?.expectedCashPercentage ?? 0,
                    currentPriceCents: 100,
                    translatedName: t('canadianCash'),
                    taxRank: null,
                    financialProductTaxRank: 99,
                    valueCents: 0,
                    subAccounts: [],
                  }}
                  subAccounts={subAccounts}
                  portfolioReport={portfolioReport}
                  pendingTrades={[draftTrades, processingTrades]}
                  isMakeHigher={viewPreTradePositions && isExtendTableRow}
                />

                {securities?.map((security) => (
                  <SecurityTableRow
                    key={security.id}
                    security={security}
                    subAccounts={subAccounts}
                    portfolioReport={portfolioReport}
                    pendingTrades={[draftTrades, processingTrades]}
                    isMakeHigher={viewPreTradePositions && isExtendTableRow}
                  />
                ))}

                <SecurityTotalTableRow securities={securities} subAccounts={subAccounts} portfolioReport={portfolioReport} pendingTrades={[draftTrades, processingTrades]} />
              </TableBody>
            </Table>
          </Grid>

          <Grid item sx={{ overflowX: 'auto' }} xs>
            <Table>
              <TableHead>
                <TableRow>
                  {subAccounts.map((subAccount) => (
                    <SubAccountTableHeadCell
                      key={subAccount.id}
                      subAccount={subAccount}
                    />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow key={'subAccount_stats'}>
                  {subAccounts?.map((subAccount) => (
                    <SubAccountTableCell key={subAccount.id} subAccount={subAccount} pendingTrades={[draftTrades, processingTrades]} />
                  ))}
                </TableRow>

                {securities?.map((security) => (
                  <TableRow key={security.id}>
                    {security.subAccounts.map((subAccount) => (
                      <SubAccountHoldingTableCell
                        key={getKey(security.id, subAccount.id)}
                        security={security}
                        subAccount={subAccount}
                        pendingTrades={[draftTrades, processingTrades]}
                        handleHoldingChange={handleHoldingChange}
                        isDisabled={isDisableAmountFieldByKey(getKey(security.id, subAccount.id), subAccount.inactive)}
                        viewPreTradePositions={viewPreTradePositions}
                        isMakeHigher={viewPreTradePositions && isExtendTableRow}
                      />
                    ))}
                  </TableRow>
                ))}

                <TableRow key={'subAccount_totals'}>
                  {subAccounts?.map((subAccount) => (
                    <TableCell key={subAccount.id}>
                      <SmallDollarPercentage bold cents={subAccount.marketValueCents} showPercentage={false} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <BulkTradeRequests
          bulkTradeRequest={bulkTradeRequest}
          sourceId={lastOptimizedBy?.id && id}
          isCancelDisabled={!!processingTrades.size}
          isCancelSubTradesPerPortfolioReport={!!lastOptimizedBy}
          portfolioOptimizerCreatedAt={lastOptimizedBy?.createdAt}
          openTradePairs={() => setOpenTradePairs(true)}
          showTradePairs={canSeeTradePairsModal}
          afterCancel={async () => {
            // await the update of a portfolio report to sync
            await delay(500);
            setAutoFilled(false);
            refreshPortfolio();
          }}
          afterVerified={async () => {
            // await the update of a portfolio report to sync
            await delay(500);
            refreshPortfolio();
          }}
        />
      </Card>

      {canSeeTradePairsModal && (
        <SubTradeRequestPairsModal
          open={openTradePairs}
          onClose={() => setOpenTradePairs(false)}
          bulkTradeRequestId={bulkTradeRequest?.id}
          sourceId={lastOptimizedBy ? id : undefined}
          refetch={refreshPortfolio}
        />
      )}
    </>
  );
};
