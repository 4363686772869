import {
  BasicFieldEdit,
  BasicOrSelectFieldEdit,
  BooleanEdit,
  SelectFieldEdit,
  TranslatedStringEdit,
  TableEdit,
  ArrayField,
  PlainTextField,
  WorkflowSelectFieldEdit,
  Attachment,
  CountrySpecificTaxIdEdit,
  HtmlTemplate,
  CheckboxEdit,
} from '.';
import { NumberFieldEdit } from './numberFieldEdit';

export enum FieldTypes {
  BASIC_FIELD = 'BASIC_FIELD',
  BASIC_OR_SELECT_FIELD = 'BASIC_OR_SELECT_FIELD',
  PLAIN_TEXT_FIELD = 'PLAIN_TEXT_FIELD',
  BOOLEAN = 'BOOLEAN',
  DATA_FIELDS = 'DATA_FIELDS',
  INVESTMENT_KNOWLEDGE_OPTIONS = 'INVESTMENT_KNOWLEDGE_OPTIONS',
  OPTIONAL_TRANSLATED_STRING = 'OPTIONAL_TRANSLATED_STRING',
  RISK_QUESTION_OPTIONS = 'RISK_QUESTION_OPTIONS',
  SELECT_FIELD = 'SELECT_FIELD',
  WORKFLOW_SELECT_FIELD = 'WORKFLOW_SELECT_FIELD',
  TRANSLATED_STRING = 'TRANSLATED_STRING',
  TABLE = 'TABLE',
  ARRAY = 'ARRAY',
  TIME_HORIZON_OPTIONS = 'TIME_HORIZON_OPTIONS',
  ATTACHMENT = 'ATTACHMENT',
  COUNTRY_SPECIFIC_TAX_ID = 'COUNTRY_SPECIFIC_TAX_ID',
  EXTENDED_BOOLEAN = 'EXTENDED_BOOLEAN',
  LIST_TILE = 'LIST_TILE',
  SELECT_CUSTOM_FIELDS = 'SELECT_CUSTOM_FIELDS',
  SCHEDULE_FREQUENCY_OPTIONS = 'SCHEDULE_FREQUENCY_OPTIONS',
  HTML_TEMPLATE = 'HTML_TEMPLATE',
  CHECKBOX = 'CHECKBOX',
  DEPOSIT_TYPE_SELECT_FIELD = 'DEPOSIT_TYPE_SELECT_FIELD',
  TRANSFER_TYPE_OPTIONS = 'TRANSFER_TYPE_OPTIONS',
  NUMBER_FIELD = 'NUMBER_FIELD',
}

export const EditField = ({
  option, options, setOptions, i, optionType, widgetType,
}: {
  option: any, options: any, setOptions: (o: any) => void, i: number, optionType: FieldTypes, widgetType: string,
}): JSX.Element => {
  let Component: any;
  switch (optionType) {
    case FieldTypes.TRANSLATED_STRING:
    case FieldTypes.OPTIONAL_TRANSLATED_STRING:
      Component = TranslatedStringEdit;
      break;
    case FieldTypes.BOOLEAN:
      Component = BooleanEdit;
      break;
    case FieldTypes.BASIC_FIELD:
      Component = BasicFieldEdit;
      break;
    case FieldTypes.BASIC_OR_SELECT_FIELD:
      Component = BasicOrSelectFieldEdit;
      break;
    case FieldTypes.SELECT_FIELD:
      Component = SelectFieldEdit;
      break;
    case FieldTypes.NUMBER_FIELD:
      Component = NumberFieldEdit;
      break;
    case FieldTypes.WORKFLOW_SELECT_FIELD:
      Component = WorkflowSelectFieldEdit;
      break;
    case FieldTypes.TABLE:
      Component = TableEdit;
      break;
    case FieldTypes.ARRAY:
      Component = ArrayField;
      break;
    case FieldTypes.PLAIN_TEXT_FIELD:
      Component = PlainTextField;
      break;
    case FieldTypes.ATTACHMENT:
      Component = Attachment;
      break;
    case FieldTypes.COUNTRY_SPECIFIC_TAX_ID:
      Component = CountrySpecificTaxIdEdit;
      break;
    case FieldTypes.HTML_TEMPLATE:
      Component = HtmlTemplate;
      break;
    case FieldTypes.CHECKBOX:
      Component = CheckboxEdit;
      break;
    default:
      Component = null;
  }
  return Component ? <Component key={option.key} option={option} options={options} setOptions={setOptions} i={i} type={widgetType} /> : <></>;
};
