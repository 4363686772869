import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { SubStepTypes } from 'interfaces/workflow';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from 'interfaces/customField';
import { CountryCodes } from '@onevesthq/ov-enums';
import { PersonalInformationVisual } from './personalInformation.visual';
import { EnabledJurisdictions } from '../../../../../interfaces';
import { WorkflowContext } from '../../workflowCompletion';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER = (derivedCustomFields?: string[], enableCustodianCustomFields?: boolean) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        middleName
        lastName
        primaryEmail
        phone
        dateOfBirth
        language
        preferredMethodOfCommunication
        inProvinceSince
        gender
        physicalAddress { city jurisdiction streetName postal unitNumber houseNumber neighborhood country }
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
        ${enableCustodianCustomFields ? 'custodianCustomFields { key value }' : ''}
        organization {
          jurisdictions { all only }
          applicableLocalization { countries }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const PersonalInformation = ({
  options, userId, onNext, stepLoading, grid = false, updateMode = false, workflowCompletion,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const { permissions } = usePermissions();
  const enabledCustomFields = getEnabledCustomFields(options);
  const { custodianConnection } = useContext(UserContext);
  const enableCustodianCustomFields = custodianConnection?.enableFetchCustodianCustomFields;
  const [userData, setUserData] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    primaryEmail: '',
    phone: '',
    dateOfBirth: '',
    language: '',
    preferredMethodOfCommunication: '',
    inProvinceSince: '',
    gender: '',
    physicalAddress: {
      city: '',
      Jurisdiction: '',
      streetName: '',
      postal: '',
      unitNumber: '',
      houseNumber: '',
      neighborhood: '',
      country: '',
    },
    customFields: [],
  });
  const [applicableJurisdictions, setApplicableJurisdictions] = useState<EnabledJurisdictions>({ all: true });
  const [applicableCountries, setApplicableCountries] = useState<CountryCodes[]>([]);

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.PERSONAL_INFORMATION,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    fetchPolicy: 'no-cache',
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      await fetchUser({
        variables: {
          userId,
        },
        query: customFieldKeys ? FETCH_USER(customFieldKeys, enableCustodianCustomFields) : undefined,
      });
      await setWorkflowData({
        ...workflowData,
        currentUserId: userId,
      });
    },
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [fetchUser, { data, loading }] = useLazyQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: defaultdata } = useQuery(FETCH_USER(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0,
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        physicalAddress: isEmpty(userData.physicalAddress) ? undefined : userData.physicalAddress,
        customFields: modifyUpdatingCustomFields(userData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
        userId,
      },
    },
  });

  useEffect(() => {
    const dataTemplate = data || defaultdata;
    if (dataTemplate) {
      const combinedCustomFields = dataTemplate.fetchUser?.user?.customFields || [];
      if (dataTemplate.fetchUser?.user?.custodianCustomFields) {
        combinedCustomFields.push(...(dataTemplate.fetchUser?.user?.custodianCustomFields || []));
      }

      setUserData({
        firstName: dataTemplate.fetchUser.user.firstName || undefined,
        middleName: dataTemplate.fetchUser.user.middleName || undefined,
        lastName: dataTemplate.fetchUser.user.lastName || undefined,
        primaryEmail: dataTemplate.fetchUser.user.primaryEmail || undefined,
        phone: dataTemplate.fetchUser.user.phone || undefined,
        dateOfBirth: dataTemplate.fetchUser.user.dateOfBirth || undefined,
        language: dataTemplate.fetchUser.user.language || undefined,
        preferredMethodOfCommunication: dataTemplate.fetchUser.user.preferredMethodOfCommunication || undefined,
        inProvinceSince: dataTemplate.fetchUser.user.inProvinceSince || undefined,
        gender: dataTemplate.fetchUser.user.gender || undefined,
        physicalAddress: dataTemplate.fetchUser.user.physicalAddress ? {
          city: dataTemplate.fetchUser.user.physicalAddress?.city || undefined,
          jurisdiction: dataTemplate.fetchUser.user.physicalAddress?.jurisdiction || undefined,
          streetName: dataTemplate.fetchUser.user.physicalAddress?.streetName || undefined,
          postal: dataTemplate.fetchUser.user.physicalAddress?.postal || undefined,
          unitNumber: dataTemplate.fetchUser.user.physicalAddress?.unitNumber || undefined,
          houseNumber: dataTemplate.fetchUser.user.physicalAddress?.houseNumber || undefined,
          neighborhood: dataTemplate.fetchUser.user.physicalAddress?.neighborhood || undefined,
          country: dataTemplate.fetchUser.user.physicalAddress?.country || undefined,
        } : {},
        customFields: combinedCustomFields,
      });
      setApplicableJurisdictions(dataTemplate.fetchUser?.user?.organization?.jurisdictions);
      setApplicableCountries(dataTemplate.fetchUser?.user?.organization?.applicableLocalization?.countries);
    }
  }, [data, defaultdata]);

  return (
    <PersonalInformationVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={customFieldLoading || loading || updateUserLoading || stepLoading}
      grid={grid}
      updateMode={updateMode}
      activeCustomFields={derivedCustomFieldsKeys}
      applicableJurisdictions={applicableJurisdictions}
      applicableCountries={applicableCountries}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default PersonalInformation;
