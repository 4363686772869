import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Account } from 'interfaces';
import { Box, Icon, Typography } from 'ovComponents/1-primative';
import { Card, CardContent, IconButton } from 'ovComponents/2-component';
import { BeneficiaryTable } from 'ovComponents/3-pattern';
import { GoalLinkingReview } from '../../goalLinking';
import { getJointAffiliate } from '../../utils';

interface JointCashReviewItemProps {
  account: Account,
  onDeleteAccount: (account?: Account) => void,
}
export const JointCashReviewItem = ({ account, onDeleteAccount }: JointCashReviewItemProps) => {
  const { t } = useTranslation(['affiliationTypes', 'shared', 'workflowCompletions']);
  const jointAffiliate = getJointAffiliate(account);

  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ pl: 0.9 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant='bodyLarge' weight='bold' sx={{ mt: 1 }}>{t('workflowCompletions:accountReviewPanel.jointCash')}</Typography>
          {
            account.id ? undefined : <IconButton onClick={() => onDeleteAccount()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{`${t('workflowCompletions:jointAccountHolder.label')}:`}</Typography>
          <Typography variant='bodyMedium'>{jointAffiliate ? t('shared:yes') : t('shared:no')}</Typography>
        </Box>
        {jointAffiliate && <BeneficiaryTable beneficiaries={[jointAffiliate]} hideAllocation={true} allowDelete={false}/>}
        <Box sx={{ mt: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <GoalLinkingReview account={account} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default JointCashReviewItem;
