import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MenuItem } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { AffiliationRelations, ClientGroupMemberAccessTypes } from 'interfaces';
import {
  Box,
  Grid,
  Typography,
} from 'ovComponents/1-primative';
import {
  Autocomplete,
  Button,
  Form,
  Segment,
  SegmentedControl,
  SegmentValue,
  SelectField,
  TextField,
} from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';
import { DraftHousehold, ExistingHousehold } from './interfaces';

export enum HouseholdActions {
  JOIN = 'joinHousehold',
  CREATE = 'createHousehold',
}

interface SelectOption {
  value?: string,
  label?: string,
}

export const HouseholdVisual = ({
  options,
  loading,
  continueFunc,
  householdAction,
  updateHouseholdAction,
  feeTierOptions,
  billingScheduleOptions,
  newHouseholdData,
  updateNewHouseholdData,
  householdOptions,
  joinHouseholdData,
  updateJoinHouseholdData,
  onHouseholdInputChange,
}: {
  options: any,
  loading: boolean,
  continueFunc: any,
  householdAction?: SegmentValue,
  updateHouseholdAction: (value: SegmentValue) => void,
  feeTierOptions: SelectOption[],
  billingScheduleOptions: SelectOption[],
  newHouseholdData?: DraftHousehold,
  updateNewHouseholdData: (value: DraftHousehold) => void,
  householdOptions: SelectOption[],
  joinHouseholdData?: ExistingHousehold,
  updateJoinHouseholdData: (value: ExistingHousehold) => void,
  onHouseholdInputChange?: (value: string) => void,
}) => {
  const { t } = useTranslation(['affiliationTypes', 'household', 'workflowCompletions']);

  const segments: Segment[] = [
    { value: HouseholdActions.CREATE, label: t('workflowCompletions:household.create') },
    { value: HouseholdActions.JOIN, label: t('workflowCompletions:household.join') },
  ];

  const createHouseholdForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth label={t('workflowCompletions:household.name')}
          onChange={(e: any) => updateNewHouseholdData({ ...newHouseholdData, name: e.target.value })}
        />
      </Grid>
      { options?.allowFeeGrid && (
        <Grid item xs>
          <SelectField fullWidth label={t('workflowCompletions:household.feeGrid')}
            onChange={(e: any) => updateNewHouseholdData({ ...newHouseholdData, feeTierId: e.target.value })}
          >
            { feeTierOptions.map((feeTier: SelectOption) => <MenuItem value={feeTier.value}>{feeTier.label}</MenuItem>) }
          </SelectField>
        </Grid>
      )}
      { options?.allowBillingSchedule && (
        <Grid item xs>
          <SelectField fullWidth label={t('workflowCompletions:household.billingSchedule')}
            onChange={(e: any) => updateNewHouseholdData({ ...newHouseholdData, billingScheduleId: e.target.value })}
          >
            { billingScheduleOptions.map((billingSchedule: SelectOption) => <MenuItem value={billingSchedule.value}>{billingSchedule.label}</MenuItem>) }
          </SelectField>
        </Grid>
      )}
    </Grid>
  );

  const householdRelationshipOptions = Object.values(AffiliationRelations).map((relation, index) => (
    <MenuItem key={index} value={relation}>{t(`affiliationTypes:affiliationRelationship.${relation}`)}</MenuItem>
  ));
  const accessTypeOptions = Object.values(ClientGroupMemberAccessTypes).map((accessType, index) => (
    <MenuItem key={index} value={accessType}>{t(`household:accessTypes.${accessType}`)}</MenuItem>
  ));

  const joinHouseholdForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          label={t('workflowCompletions:household.name')}
          value={joinHouseholdData?.clientGroup}
          options={householdOptions}
          onChange={(e:any, value:any) => updateJoinHouseholdData({ ...joinHouseholdData, clientGroup: value })}
          onInputChange={(e: React.SyntheticEvent, value: string) => onHouseholdInputChange?.(value) }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: undefined,
          }}
          fullWidth
        ></Autocomplete>
      </Grid>
      <Grid item xs={12}>
        <SelectField fullWidth label={t('workflowCompletions:household.relationshipToPrimaryContact')}
          value={joinHouseholdData?.relationshipType}
          onChange={(e: any) => updateJoinHouseholdData({ ...joinHouseholdData, relationshipType: e.target.value })}
        >
          { householdRelationshipOptions }
        </SelectField>
      </Grid>
      <Grid item xs={12}>
        <SelectField fullWidth label={t('workflowCompletions:household.permissions')}
          value={joinHouseholdData?.accessType}
          onChange={(e: any) => updateJoinHouseholdData({ ...joinHouseholdData, accessType: e.target.value })}
        >
          { accessTypeOptions }
        </SelectField>
      </Grid>
    </Grid>
  );

  return (
    <Form onSubmit={continueFunc}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <SegmentedControl sx={{ mb: 4 }} value={householdAction} segments={segments} exclusive={true} fullWidth enforceActive onChange={updateHouseholdAction} />
      { householdAction === HouseholdActions.CREATE && createHouseholdForm }
      { householdAction === HouseholdActions.JOIN && joinHouseholdForm }
      <Box display='flex' justifyContent='end'>
        <Button disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit'
          label={householdAction === HouseholdActions.CREATE ? t('workflowCompletions:household.createSubmit') : t('workflowCompletions:household.joinSubmit') }
        />
      </Box>

    </Form>
  );
};
