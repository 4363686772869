import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { generateClientNameString } from 'util/index';
import { Affiliation } from 'interfaces';
import { colors } from 'theme/colors';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'ovComponents/2-component';

const tableHeadSx: SxProps = {
  backgroundColor: '#F0F4F8',
  borderRadius: '2px',
  border: 'none',
  color: colors.gray500,
};

export const BeneficiaryTable = ({
  beneficiaries,
  setBeneficiaries,
  hideAllocation,
  allowDelete = true,
}: {
  beneficiaries: Affiliation[],
  setBeneficiaries?: (beneficiaries: Affiliation[]) => void,
  hideAllocation?: boolean,
  allowDelete?: boolean,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const deleteAffiliation = (deleteIndex: number): void => {
    setBeneficiaries?.(beneficiaries.filter((_predicate, index) => index !== deleteIndex));
  };
  const DeleteButton = ({ onClick, sx }: { onClick?: () => void, sx?: SxProps }) => <IconButton onClick={onClick} sx={sx} aria-label="delete" size='small'><DeleteIcon /></IconButton>;

  const relationshipColSx = { width: hideAllocation ? '50%' : '30%' };

  return (
    beneficiaries.length > 0 ? (
        <Table size='small' aria-label="table">
          <TableHead sx={tableHeadSx}>
            <TableRow>
              <TableHeadCell>{t('workflowCompletions:accountReviewPanel.tableItems.name')}</TableHeadCell>
              <TableHeadCell sx={relationshipColSx}>{t('workflowCompletions:accountReviewPanel.tableItems.relationship')}</TableHeadCell>
              {!hideAllocation && <TableHeadCell sx={{ width: '20%' }}>{t('workflowCompletions:accountReviewPanel.tableItems.allocation')}</TableHeadCell>}
              {allowDelete && <TableHeadCell padding='checkbox'><DeleteButton sx={{ visibility: 'hidden' }}/></TableHeadCell>}
            </TableRow>
          </TableHead>
          <TableBody>
          {
            beneficiaries.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{generateClientNameString(item.user)}</TableCell>
                <TableCell sx={relationshipColSx}>{t(`affiliationTypes:affiliationRelationship.${item.relation}`)}</TableCell>
                {!hideAllocation && <TableCell sx={{ width: '20%' }}>{`${item.allocation ?? 0}%`}</TableCell>}
                {allowDelete && <TableCell padding='checkbox'><DeleteButton onClick={() => deleteAffiliation(index)}/></TableCell>}
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
    ) : <></>
  );
};
