export interface StateMachineChanges {
  id: string;
  from: string;
  to: string;
  createdAt: Date;
  updatedAt: Date;
}

export const STATE_CHANGES_FIELD = `#graphql
  stateMachineChanges {
    id
    from
    to
    createdAt
    updatedAt
  }
`;
