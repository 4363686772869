import {
  Box,
  IconButton, ListItem, ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { InlineImage } from '../../client/components/documents/inlineImage';
import SelectableFileInput from '../../client/components/selectableFileInput';
import { DELETE_FILE_DOCUMENT } from '../../client/components/documents/documentViewer';
import ConfirmationModal from '../../../components/modals/confirmationModal';

export const FETCH_ERROR_PAGE_BACKGROUND_IMAGE = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {
      perPage: 1
      sortDesc: false,
      sortField: "createdAt"
    }
  }) {
    fileDocuments {
      id
      name
    }
  }
}`;

const EditOrganizationErrorPageBackgroundImage = ({
  title, organizationId, onFileChosen, onFileDeleted,
}: { title: string, organizationId: string, onFileChosen: (file: File | undefined) => void, onFileDeleted?: () => void }) => {
  const { t } = useTranslation(['client', 'orgSettings']);
  const [documents, setDocuments] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { loading } = useQuery(FETCH_ERROR_PAGE_BACKGROUND_IMAGE, {
    variables: {
      filter:
      {
        organizationId,
        types: ['ERROR_PAGE_BACKGROUND_IMAGE'],
        objectTypes: ['ORGANIZATION'],
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setDocuments(data.fetchFileDocuments.fileDocuments);
    },
  });

  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT, {
    variables: {
      fileDocumentId: documents[0]?.id ?? '',
    },
    refetchQueries: [FETCH_ERROR_PAGE_BACKGROUND_IMAGE],
    onCompleted() {
      setOpenModal(false);
      if (onFileDeleted) onFileDeleted();
    },
  });

  const textStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    m: '10px',
  };

  if (loading) return <></>;

  return (
    <>
      {documents.length > 0
        ? (<ListItem >
          <Box sx={{ maxHeight: '50px', maxWidth: '50px' }}>
            <InlineImage fileDocumentId={documents[0].id} />
          </Box>
          <ListItemText sx={textStyle} primary={documents[0].name} />
          <IconButton sx={{ color: '#1D1D1D' }} onClick={() => {
            setOpenModal(true);
          }}>
            <Delete />
          </IconButton>
        </ListItem>)
        : <SelectableFileInput
          title={title ?? t('droppableFileInput.chooseAFile')}
          onFileChosen={(dropFile: File | undefined) => {
            onFileChosen(dropFile);
          }}
        />
      }
      <ConfirmationModal
        title={t('orgSettings:deleteErrorPageImageModal.title')}
        bodyText={t('orgSettings:deleteErrorPageImageModal.subtitle')}
        open={openModal}
        maxWidth='sm'
        confirmButton={t('orgSettings:deleteErrorPageImageModal.yesDelete')}
        cancelButton={t('orgSettings:deleteErrorPageImageModal.cancel')}
        onConfirm={() => deleteFileDocument()}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default EditOrganizationErrorPageBackgroundImage;
