import { CircularProgress } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageConfigurationVisual } from './pageConfiguration.visual';
import { FETCH_PAGE_CONFIGURATION, UPDATE_PAGE_CONFIGURATION } from './pageConfiguration.queries';
import { Box } from '../../1-primative';
import { TranslatedString } from '../../../interfaces';

export enum PageObjectType {
  INDIVIDUAL = 'INDIVIDUAL',
  NON_INDIVIDUAL = 'NON_INDIVIDUAL',
  GOAL = 'GOAL',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  HOUSEHOLD = 'HOUSEHOLD',
}
export interface ClientPageInterface {
  name: string;
  tabs: TabInterface[];
}

export interface TabInterface {
  label: TranslatedString;
  icon: string;
  widgets: WidgetInterface[];
  url?: string;
}

export interface WidgetInterface {
  type: string;
  options: any;
}

export const PageConfiguration = () => {
  const [page, setPage] = useState<ClientPageInterface | undefined>(undefined);
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(FETCH_PAGE_CONFIGURATION, {
    variables: { pageConfigurationId: id },
  });

  const [update, { data: updateData }] = useMutation(UPDATE_PAGE_CONFIGURATION);

  useEffect(() => {
    if (data) {
      setPage(data.fetchPageConfiguration.pageConfiguration);
    }
  }, [data]);

  useEffect(() => {
    if (updateData) {
      setPage(updateData.updatePageConfiguration.pageConfiguration);
    }
  }, [updateData]);

  if (loading || !page) {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return <PageConfigurationVisual page={page} setPage={setPage} update={update} />;
};
