import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_HOUSEHOLD } from 'pages/household';
import { RelationshipInput } from '../../../../interfaces';
import { generateClientNameString } from '../../../../util';
import { StepProps } from '../wizard';
import { usePermissions } from '../../../../providers/userContextProvider';
import { AddHouseholdMemeberActionStepResult } from './addHouseholdMemeberActionStepResult';

export const FETCH_CLIENTGROUP_RELATIONSHIPS = gql`
  query fetchClientGroupRelationships($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId:$clientGroupId) {
      clientGroup {
        relationships {
          type
          user { id }
          accessType
        }
      }
    }
  }
`;

export const UPDATE_CLIENTGROUP_RELATIONSHIPS = gql`
  mutation updateClientGroupRelationships($clientGroupId: ObjectID!, $relationships: [RelationshipInput]) {
    updateClientGroup(input: {
      clientGroupId: $clientGroupId
      relationships: $relationships
    }) {
      clientGroup {
        id
      }
    }
  }
`;

export function AddHouseholdMemeberActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const { permissions } = usePermissions();
  const [memberDisplayName] = useState(generateClientNameString(state.user));
  const [fetchClientGroupRelationships] = useLazyQuery(FETCH_CLIENTGROUP_RELATIONSHIPS, { variables: { clientGroupId: context.clientGroupId } });
  const [updateRelationships, {
    loading, called, data, error,
  }] = useMutation(UPDATE_CLIENTGROUP_RELATIONSHIPS);

  /* run the mutation as soon as permissions are loaded */
  useEffect(() => {
    if (permissions.length === 0) return;
    if (called) return;

    doAddMember();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, permissions]);

  const doAddMember = () => {
    fetchClientGroupRelationships({
      fetchPolicy: 'no-cache',
      onCompleted: (existingData) => {
        mutationEvent('started');

        const existingRelationships = existingData.fetchClientGroup.clientGroup.relationships.map((r: any) => ({
          type: r.type,
          userId: r.user.id,
          accessType: r.accessType,
        }));
        const newRelationship:RelationshipInput = {
          type: state.relationship,
          userId: state.user?.id ?? '',
          accessType: state.accessType,
        };
        updateRelationships({
          variables: {
            clientGroupId: context.clientGroupId,
            relationships: [...existingRelationships, newRelationship],
          },
          refetchQueries: [FETCH_HOUSEHOLD(permissions)],
          onCompleted: () => mutationEvent('succeeded'),
          onError: () => mutationEvent('failed'),
        });
      },
    });
  };

  return (
    <AddHouseholdMemeberActionStepResult
      state={state}
      context={context}
      continueTo={continueTo}
      loading={loading}
      succeeded={!!data}
      error={error}
      memberDisplayName={memberDisplayName}
    />
  );
}
