import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client';
import debounce from 'lodash/debounce';
import { formatDay } from 'components/fields/scheduleDateSelect';
import { UserContext } from 'providers/userContextProvider';
import {
  BillingSchedule,
  BillingScheduleStates,
  ClientGroup,
  ClientGroupMemberAccessTypes,
  ClientGroupStates,
  ClientGroupTypes,
  FeeTier,
  FeeTierStates,
  Relationship,
} from 'interfaces';
import { SegmentValue } from 'ovComponents/2-component';
import { DraftHousehold, ExistingHousehold, HouseholdRelationship } from './interfaces';
import { HouseholdActions, HouseholdVisual } from './household.visual';

const MAX_PER_PAGE = 100;
const SEARCH_DEBOUNCE_IN_MS = 500;

const FETCH_USER_HOUSEHOLDS = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        households {
          id
          name
          state
          organization { id }
          relationships {
            type
            user { id }
            accessType
          }
        }
      }
    }
  }
`;

const FETCH_FEE_TIERS = gql`
  query fetchFeeTiers($input: FetchFeeTiersInput!) {
    fetchFeeTiers(input: $input) {
      feeTiers {
        id
        name
      }
    }
  }
`;

const FETCH_BILLING_SCHEDULES = gql`
  query fetchBillingSchedules($input: FetchBillingSchedulesInput!) {
    fetchBillingSchedules(input: $input) {
      billingSchedules {
        id
        frequency
        nextBillingDate
      }
    }
  }
`;

const FETCH_CLIENT_GROUPS = gql`
  query fetchClientGroups($input: FetchClientGroupsInput!) {
    fetchClientGroups(input: $input) {
      clientGroups{
        id
        name
        relationships {
          type
          user { id }
          accessType
        }
      }
    }
  }
`;

const CREATE_CLIENT_GROUP = gql`
mutation createClientGroup($input: CreateClientGroupInput!) {
  createClientGroup(input: $input) {
    clientGroup {
      id
    }
  }
}
`;

const UPDATE_HOUSEHOLD_RELATIONSHIPS = gql`
  mutation updateHouseholdRelationships($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup {
        id
      }
    }
  }
`;

export const Household = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const { t } = useTranslation(['feeAndBilling', 'components']);
  const { activeOrganization } = useContext(UserContext);
  const [householdAction, setHouseholdAction] = useState<SegmentValue>(options?.defaultAction?.value || HouseholdActions.CREATE);
  const [households, setHouseholds] = useState<ClientGroup[]>([]);
  const [newHouseholdData, setNewHouseholdData] = useState<DraftHousehold>({
    name: '',
    primaryUserId: userId,
    organizationId: activeOrganization.id || undefined,
    type: ClientGroupTypes.Household,
    relationships: [{
      type: 'Primary',
      userId,
      accessType: ClientGroupMemberAccessTypes.View,
    }],
    feeTierId: undefined,
    billingScheduleId: undefined,
  });
  const [joinHouseholdData, setJoinHouseholdData] = useState<ExistingHousehold>({
    clientGroup: {
      id: '',
      label: '',
    },
    relationshipType: undefined,
    accessType: undefined,
  });

  const { loading: feeTiersLoading, data: feeTiersData } = useQuery(FETCH_FEE_TIERS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          states: FeeTierStates.ACTIVE,
        },
        pagination: { perPage: MAX_PER_PAGE },
      },
    },
  });
  const { loading: billingSchedulesLoading, data: billingSchedulesData } = useQuery(FETCH_BILLING_SCHEDULES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          states: BillingScheduleStates.ACTIVE,
        },
        pagination: { perPage: MAX_PER_PAGE },
      },
    },
  });

  const { loading: userHouseholdsLoading } = useQuery(FETCH_USER_HOUSEHOLDS, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    onCompleted: ((data) => {
      const userHouseholdsData = data.fetchUser.user?.households ?? [];

      // Determine if any households the user is affiliated with are active and part of the active organization
      const affiliatedHouseholds = userHouseholdsData.filter((household: ClientGroup) => (
        household.state === ClientGroupStates.ACTIVE
        && household.relationships.some((relationship) => relationship.user.id === userId)
      ));
      if (affiliatedHouseholds.length > 0) {
        setHouseholdAction(HouseholdActions.JOIN);

        // Set the initial information to the existing relationship
        const initialHousehold: ClientGroup = affiliatedHouseholds[0];
        const existingRelationship = initialHousehold.relationships.find((relationship: Relationship) => relationship.user.id === userId);

        setJoinHouseholdData({
          clientGroup: {
            id: initialHousehold.id,
            label: initialHousehold.name,
          },
          relationshipType: existingRelationship?.type as HouseholdRelationship,
          accessType: existingRelationship?.accessType as ClientGroupMemberAccessTypes,
        });
      }
    }),

  });

  const fetchClientGroupsFilter = {
    organizationId: activeOrganization.id,
    states: ClientGroupStates.ACTIVE,
    type: ClientGroupTypes.Household,
    searchText: '',
  };

  const { loading: clientGroupsLoading, refetch: clientGroupsRefetch } = useQuery(FETCH_CLIENT_GROUPS, {
    variables: {
      input: {
        filter: fetchClientGroupsFilter,
        pagination: { perPage: MAX_PER_PAGE },
      },
    },
    onCompleted: ((data) => {
      const householdsData = data.fetchClientGroups.clientGroups;
      setHouseholds(householdsData);
    }),
  });

  const handleHouseholdHoldInputChange = (value: string) => {
    clientGroupsRefetch({
      input: {
        filter: { ...fetchClientGroupsFilter, searchText: value },
        pagination: { perPage: MAX_PER_PAGE },
      },
    });
  };

  function getFeeTierOptions(feeTiers: FeeTier[]): Array<{ value?: string; label?: string }> {
    return (
      feeTiers.map((feeTier: FeeTier) => ({ value: feeTier.id, label: feeTier.name }))
    );
  }

  function getBillingScheduleOptions(billingSchedules: BillingSchedule[]): Array<{ value?: string; label?: string }> {
    function formatBillingSchedule(billingSchedule: BillingSchedule) {
      const dayOfMonth = formatDay(dayjs(billingSchedule.nextBillingDate).date());
      const frequency = t(`feeAndBilling:billingSchedule.frequencies.${billingSchedule.frequency}`);
      return `${frequency} - ${dayOfMonth}`;
    }

    return (
      billingSchedules.map((billingSchedule: BillingSchedule) => ({
        value: billingSchedule.id,
        label: formatBillingSchedule(billingSchedule),
      }))
    );
  }

  function getHouseholdOptions(householdItems: ClientGroup[]): Array<{ id?: string; label?: string }> {
    return (
      householdItems.map((household: ClientGroup) => ({ id: household.id, label: household.name }))
    );
  }

  const [createHousehold, { loading: createHouseholdLoading }] = useMutation(CREATE_CLIENT_GROUP, {
    onCompleted: () => onNext(),
    variables: {
      input: { ...newHouseholdData },
    },
  });

  const [updateRelationships, { loading: updaterRelationshipsLoading }] = useMutation(
    UPDATE_HOUSEHOLD_RELATIONSHIPS,
    { refetchQueries: [FETCH_CLIENT_GROUPS] },
  );
  function joinHousehold() {
    const selectedHousehold = households.find(
      (household: ClientGroup) => household.id === joinHouseholdData?.clientGroup?.id,
    );

    const existingRelationships = selectedHousehold ? selectedHousehold.relationships.map((relationship: Relationship) => ({
      userId: relationship.user.id,
      type: relationship.type,
      accessType: relationship.accessType,
    })) : [];

    const newRelationship = {
      userId,
      type: joinHouseholdData?.relationshipType,
      accessType: joinHouseholdData?.accessType,
    };

    // If a similar relationship already exists for the selected household, skip creation
    const matchingExistingRelationship = existingRelationships.find((relationship) => (
      relationship.userId === newRelationship.userId
      && relationship.type === newRelationship.type
      && relationship.accessType === newRelationship.accessType
    ));

    if (matchingExistingRelationship) {
      onNext();
    } else {
      updateRelationships({
        onCompleted: () => onNext(),
        variables: {
          input: {
            clientGroupId: joinHouseholdData?.clientGroup?.id,
            relationships: [...existingRelationships, newRelationship],
          },
        },
      });
    }
  }

  function commitHouseholdChange() {
    if (householdAction === HouseholdActions.CREATE) {
      createHousehold();
    } else {
      joinHousehold();
    }
  }

  const loading = (
    stepLoading
    || userHouseholdsLoading
    || feeTiersLoading
    || billingSchedulesLoading
    || clientGroupsLoading
    || createHouseholdLoading
    || updaterRelationshipsLoading
  );
  return (
    <>
    {
      !loading && <HouseholdVisual
        options={options}
        loading={loading}
        continueFunc={commitHouseholdChange}
        householdAction={householdAction}
        updateHouseholdAction={setHouseholdAction}
        feeTierOptions={getFeeTierOptions(feeTiersData.fetchFeeTiers.feeTiers)}
        billingScheduleOptions={getBillingScheduleOptions(billingSchedulesData.fetchBillingSchedules.billingSchedules)}
        newHouseholdData={newHouseholdData}
        updateNewHouseholdData={setNewHouseholdData}
        householdOptions={getHouseholdOptions(households)}
        joinHouseholdData={joinHouseholdData}
        updateJoinHouseholdData={setJoinHouseholdData}
        onHouseholdInputChange={debounce(handleHouseholdHoldInputChange, SEARCH_DEBOUNCE_IN_MS)}
      />
    }
    </>
  );
};
