import { translateBackend } from 'assets/i18n/config';
import {
  Grid, MenuItem, SelectField, Switch, TranslatableTextField,
} from 'ovComponents';
import { useTranslation } from 'react-i18next';

export const BasicFieldEdit = ({
  option, options, setOptions, i, type,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number, type?: string,
}) => {
  const { t } = useTranslation('shared');
  const canShowNotEditableField = (): boolean => [
    'PERSONAL_INFORMATION',
    'RELATIONSHIP_INFORMATION',
    'INCOME_ASSETS_AND_DEBTS',
    'PERSONS_OF_INTEREST',
    'RESIDENCY_INFORMATION',
    'EMPLOYMENT_INFORMATION',
    'NON_INDIVIDUAL_INFORMATION',
    'GOAL_INFORMATION',
  ].includes(type ?? '');
  return (
    <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1} alignItems='end'>
      <Grid item xs={6}>
        <TranslatableTextField
          label={translateBackend(option.label)}
          value={option.value.label}
          disabled={!option.value?.enabled}
          fullWidth
          onChange={(value) => {
            const newOptions = [...options];
            newOptions[i].value = { ...option.value, label: value };
            setOptions(newOptions);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          label={t('required')}
          value={option.value.required}
          disabled={!option.value?.enabled}
          fullWidth
          onChange={(e: any) => {
            const newOptions = [...options];
            newOptions[i].value = { ...option.value, required: e.target.value };
            setOptions(newOptions);
          }}
        >
          <MenuItem value='ERROR'>{t('error')}</MenuItem>
          <MenuItem value='WARNING'>{t('warning')}</MenuItem>
          <MenuItem value='NOT_REQUIRED'>{t('notRequired')}</MenuItem>
          {canShowNotEditableField() && <MenuItem value='NOT_EDITABLE'>{t('notEditable')}</MenuItem>}
        </SelectField>
      </Grid>
      <Grid item xs={2} justifyContent='center' display='flex' sx={{ mt: 2 }}>
        <Switch sx={{ mb: 1 }} checked={option.value?.enabled} onChange={(e: any) => {
          const newOptions = [...options];
          newOptions[i].value = { ...option.value, enabled: e };
          setOptions(newOptions);
        }}></Switch>
      </Grid>
    </Grid>
  );
};
