import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItem, Typography } from '@mui/material';
import { colors } from 'ovComponents';
import { FETCH_ACCOUNT } from 'pages/accountsDetail';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';
import { getSubAccountName, SubAccount, SubAccountStates } from '../../interfaces';

const FETCH_SUBACCOUNTS_OF_ACCOUNT = gql`
  query fetchSubAccounts($accountId: ObjectID!) {
    fetchSubAccounts(input: { filter: { accountId: $accountId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state
        goal {
          id
          type
          name
          householdClientGroup { id }
          subAccounts { id state account { id type } }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

const DISSOCIATE_ACCOUNT_FROM_HOUSEHOLD = gql`
  mutation dissociateAccountFromHousehold($accountId: ObjectID!) {
    updateAccount (
      input: {
        accountId: $accountId
        householdClientGroupId: null
      }
    ) {
      account {
        user { id }
      }
    }
  }
`;

const isSubAccountBlocked = (subAccount: SubAccount, accountId: string) => (
  subAccount.state !== SubAccountStates.INACTIVE
  && !!subAccount?.goal.householdClientGroup
  && !!subAccount?.goal?.subAccounts?.find((s) => s.state !== SubAccountStates.INACTIVE && s.account.id !== accountId)
);

export const DissociateAccountFromHouseholdModal = ({
  handleClose, accountId, householdName,
}: { accountId: string, handleClose: () => void, householdName:string }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [householdSubaccounts, setHouseholdSubaccounts] = useState<(SubAccount & { otherAccountsCount?: number })[]>([]);
  const [hasBlockedGoal, setHasBlockedGoal] = useState(false);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_ACCOUNT, {
    variables: { accountId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHouseholdSubaccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
      setHasBlockedGoal(!!data.fetchSubAccounts.subAccounts.find((subAccount: SubAccount) => (
        isSubAccountBlocked(subAccount, accountId)
      )));
    },
  });

  const dissociationCompleted = (data: { updateAccount: { account: { user: { id: string } } } }): void => {
    handleClose();
    navigate(`/clients/${data.updateAccount.account.user.id}/account/${accountId}`);
    showToast({ message: t('accountsDetail:dissociateAccountDialog.success'), severity: 'success' });
  };

  const [dissociateAccountMutation] = useMutation(DISSOCIATE_ACCOUNT_FROM_HOUSEHOLD, {
    variables: {
      accountId,
    },
    refetchQueries: [FETCH_ACCOUNT(permissions), FETCH_ACCOUNTS(permissions), FETCH_USER(permissions)],
    onCompleted: dissociationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('accountsDetail:dissociateAccountDialog.title', { name: householdName })}
      open={true}
      maxWidth='sm'
      confirmButton={hasBlockedGoal || loading
        ? t('shared:okGotIt')
        : t('accountsDetail:dissociateAccountDialog.continueButton')
      }
      cancelButton={t('accountsDetail:dissociateAccountDialog.cancelButton')}
      onConfirm={() => (hasBlockedGoal ? handleClose() : dissociateAccountMutation())}
      onCancel={handleClose}
      >
      <Typography component='div' sx={{ minHeight: '6em' }}>
        {loading && <Typography color={colors.neutral400}>{t('accountsDetail:dissociateAccountDialog.checkingSubaccounts')}...</Typography>}
        {!hasBlockedGoal && householdSubaccounts.length > 0
        && <>
          {t('accountsDetail:dissociateAccountDialog.thereAreSubAccounts')}
          {householdSubaccounts.map((sa) => <ListItem key={sa.id}>{getSubAccountName(sa)}</ListItem>)}
        </>
        }
        {hasBlockedGoal && <>
          {t('accountsDetail:dissociateAccountDialog.thereAreBlockedSubAccounts')}
          {householdSubaccounts.map((sa) => <ListItem key={sa.id}>{`${getSubAccountName(sa)}${isSubAccountBlocked(sa, accountId) ? ' *' : ''}`}</ListItem>)}
          <ListItem>{t('accountsDetail:dissociateAccountDialog.thereAreBlockedSubAccountsFootnote')}</ListItem>
        </>}
        {!loading && !hasBlockedGoal && householdSubaccounts.length === 0 && <>
          {t('accountsDetail:dissociateAccountDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
