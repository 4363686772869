import { Grid } from '@mui/material';
import AccountsSummary from '../../../components/layout/accounts/AccountsSummary';
import AssetsOverview from '../../../components/layout/client/assetsOverview';
import GoalsSummary from '../../../components/layout/goals/goalsSummary';
import { ObjectType } from '../../../providers/statsHooks';
import { usePermissions } from '../../../providers/userContextProvider';
import CollapseHoldings from '../../../components/layout/client/collapseHoldings';
import { CreateAccountWizardButton } from '../../../components/wizards/createAccountWizard/button';

export function ClientOverview({ userId }: { userId: string }) {
  const { permissions } = usePermissions();

  const showGoals = permissions.includes('read:goal_basic');
  const showAccounts = permissions.includes('read:account_basic');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AssetsOverview type={ObjectType.USER} id={userId} />
      </Grid>
      <Grid item xs={12}>
        <CollapseHoldings id={userId} type={ObjectType.USER} />
      </Grid>
      { showGoals
        && <Grid item md={6} xs={12}>
          <GoalsSummary objectId={userId} objectType={ObjectType.USER}/>
        </Grid>
      }
      { showAccounts
        && <Grid item md={6} xs={12}>
          <AccountsSummary objectType={ObjectType.USER} objectId={userId} newAccountComponent={<CreateAccountWizardButton forObject='USER' forId={userId}/>}/>
        </Grid>
      }
    </Grid>
  );
}
