import {
  Button, Dialog, DialogTitle, Grid,
  List, ListItem, MenuItem, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CountryCodes } from '@onevesthq/ov-enums';
import { generateAddressString } from '../../util';
import { PhysicalAddress } from '../../interfaces';
import { provinces } from '../../ovComponents/resources';

const AddressField = ({
  onChange, label, rows = 1, readonly = false, address, autoFocus = false, error = false,
}: {
  onChange: ((e: PhysicalAddress) => void),
  label: string,
  rows?: number,
  readonly?: boolean,
  autoFocus?: boolean,
  address: PhysicalAddress,
  error?: boolean
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);
  const [physicalAddress, setPhysicalAddress] = useState<PhysicalAddress>({
    city: address?.city || '',
    country: address?.country,
    houseNumber: address?.houseNumber || '',
    neighborhood: address?.neighborhood || '',
    postal: address?.postal || '',
    province: address?.province || '',
    streetName: address?.streetName || '',
    unitNumber: address?.unitNumber || '',
  });

  return (
    <>
      <TextField
        fullWidth
        autoFocus={autoFocus}
        data-testid="addressTextField"
        value={generateAddressString(physicalAddress)}
        onClick={() => setOpen(!open)}
        label={label}
        multiline={rows > 1}
        rows={rows > 1 ? rows : undefined}
        InputProps={{
          autoComplete: 'none',
          readOnly: readonly,
        }}
        error={error}
      />
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{t('components:address.title')}</DialogTitle>
          <Grid container spacing={2} px={2}>
            <Grid item xs={6} key={t('address.unitNumber') as string}>
              <TextField
                fullWidth
                value={physicalAddress.unitNumber}
                data-testid="unitNumberTextField"
                label={t('components:address.unitNumber')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, unitNumber: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            <Grid item xs={6} key={t('components:address.houseNumber') as string}>
              <TextField
                fullWidth
                data-testid="houseNumberTextField"
                value={physicalAddress.houseNumber}
                label={t('components:address.houseNumber')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, houseNumber: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            <Grid item xs={12} key={t('components:address.streetName') as string}>
              <TextField
                fullWidth
                data-testid="streetTextField"
                value={physicalAddress.streetName}
                label={t('components:address.streetName')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, streetName: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            <Grid item xs={6} key={t('components:address.city') as string}>
              <TextField
                fullWidth
                data-testid="cityTextField"
                value={physicalAddress.city}
                label={t('components:address.city')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, city: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            <Grid item xs={6} key={t('components:address.province') as string}>
              <TextField
                fullWidth
                select
                data-testid="provinceTextField"
                value={physicalAddress.province}
                label={t('components:address.province')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, province: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              >
                {
                  provinces.map((province: { key: string, value: string }) => (
                    <MenuItem key={province.key} value={province.key}>{province.value}</MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item xs={6} key={t('components:address.postal') as string}>
              <TextField
                fullWidth
                data-testid="postalTextField"
                value={physicalAddress.postal}
                label={t('components:address.postal')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, postal: e.target.value }))}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            <Grid item xs={6} key={t('components:address.country') as string}>
              <TextField
                fullWidth
                select
                data-testid="countryTextField"
                value={physicalAddress.country}
                label={t('components:address.country')}
                onChange={(e) => setPhysicalAddress((prevState) => ({ ...prevState, country: e.target.value as CountryCodes }))}
                InputProps={{
                  readOnly: readonly,
                }}
              >
                <MenuItem key='CA' value='CA'>Canada</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <List>
            <ListItem>
              <Button data-testid="confirmButton" fullWidth variant='contained' onClick={() => {
                setOpen(false);
                onChange(physicalAddress);
              }}>
                {t('done')}
              </Button>
            </ListItem>
          </List>
      </Dialog>
    </>
  );
};

export default AddressField;
