import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { ClientReportTemplate, ClientReportTemplate as ClientReportTemplateType } from '../../../../interfaces';
import { Box, Typography } from '../../../1-primative';
import {
  Button, Card, CardContent, TextField,
} from '../../../2-component';
import { SelectionTile } from '../../../3-pattern';
import { LocalizedDatePicker } from '../../../../components/fields/localizedDatePicker';
import { UPDATE_CLIENT_REPORT_TEMPLATE } from './rules';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

export enum SchedulerFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
  ONE_TIME = 'ONE_TIME',
}

export const Frequency = ({
  template,
  setFrequency,
  setStartDate,
  setEndDate,
  refetch,
}: {
  template: ClientReportTemplate;
  setFrequency: (newFreq: ClientReportTemplateType['scheduler']['frequency']) => void;
  setStartDate: (newFreq: ClientReportTemplateType['scheduler']['oneTimeDateStart']) => void;
  setEndDate: (newFreq: ClientReportTemplateType['scheduler']['oneTimeDateEnd']) => void;
  refetch: () => void;
}) => {
  const { t } = useTranslation('clientReportTemplate');
  const { showToast } = useGlobalToast();
  const [focused, setFocused] = useState<string[]>([]);
  const oneTime = template.scheduler.frequency === 'ONE_TIME';

  const [updateClientReportTemplate, { loading }] = useMutation(UPDATE_CLIENT_REPORT_TEMPLATE, {
    variables: {
      id: template.id,
      input: {
        schedulerOneTimeDateStart: template?.scheduler?.frequency === 'ONE_TIME' ? dayjs(template?.scheduler?.oneTimeDateStart).format('YYYY-MM-DD') : undefined,
        schedulerOneTimeDateEnd: template?.scheduler?.frequency === 'ONE_TIME' ? dayjs(template?.scheduler?.oneTimeDateEnd).format('YYYY-MM-DD') : undefined,
        schedulerFrequency: template?.scheduler?.frequency,
      },
    },
    onCompleted: () => {
      showToast({ message: t('savedSuccessfully'), severity: 'success' });
      refetch();
    },
  });

  const validate = () => {
    const invalidFieldSet: string[] = [];

    if (!template.scheduler.oneTimeDateStart) {
      invalidFieldSet.push('oneTimeDateStart');
    }

    if (!template.scheduler.oneTimeDateEnd) {
      invalidFieldSet.push('oneTimeDateEnd');
    }

    setFocused(invalidFieldSet);
    return invalidFieldSet.length === 0;
  };

  const save = async () => {
    if (!validate() && oneTime) {
      return;
    }

    await updateClientReportTemplate();
    setFocused([]);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant='titleMedium'>{t('frequencyTitle')}</Typography>
          <SelectionTile
            value={template.scheduler.frequency}
            direction='row'
            xs={12}
            options={[
              { label: t('frequencies.monthly'), subtitle: t('frequencies.onceAMonth'), value: 'MONTHLY' },
              { label: t('frequencies.quarterly'), subtitle: t('frequencies.onceAQuarter'), value: 'QUARTERLY' },
              { label: t('frequencies.yearly'), subtitle: t('frequencies.onceAYear'), value: 'YEARLY' },
              { label: t('frequencies.one_time'), subtitle: t('frequencies.oneTimeRange'), value: 'ONE_TIME' },
            ]}
            onChange={(e: any) => setFrequency(e.target.value)}
          />
          {oneTime && (
            <Box display={'flex'} gap={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <LocalizedDatePicker
                disableFuture={true}
                value={template.scheduler.oneTimeDateStart}
                onChange={(date: Date) => setStartDate(dayjs(date).utc().toDate())}
                onAccept={(date: Date) => setStartDate(dayjs(date).utc().toDate())}
                renderInput={(params: any) => (
                  <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    {...params}
                    type='default'
                    label={t('frequencies.oneTimeStart')}
                    onBlur={() => setFocused([...focused, 'oneTimeDateStart'])}
                    error={!template.scheduler.oneTimeDateStart && focused.includes('oneTimeDateStart')}
                    errorText={t('components:translatableString.errorText')}
                  />
                )}
              />
              <LocalizedDatePicker
                disableFuture={true}
                value={template.scheduler.oneTimeDateEnd}
                onChange={(date: Date) => setEndDate(dayjs(date).utc().toDate())}
                onAccept={(date: Date) => setEndDate(dayjs(date).utc().toDate())}
                renderInput={(params: any) => (
                  <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    {...params}
                    type='default'
                    label={t('frequencies.oneTimeEnd')}
                    onBlur={() => setFocused([...focused, 'oneTimeDateEnd'])}
                    error={!template.scheduler.oneTimeDateEnd && focused.includes('oneTimeDateEnd')}
                    errorText={t('components:translatableString.errorText')}
                  />
                )}
              />
            </Box>
          )}
          <Box display='flex' justifyContent='end'>
            <Button disabled={loading} label={t('shared:save')} onClick={save} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
