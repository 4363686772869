import {
  Box,
  Button, ListItem, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { formatMoneyValue } from '../../../../util';
import { StepProps } from '../wizard';

const ReviewListItem = ({ label, value }: { label: string, value: string }) => (
  <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Typography variant='body2'>{label}</Typography>
    <Typography variant='body2' fontWeight={700}>{value}</Typography>
  </ListItem>
);

export function SummaryStep({
  state, continueTo,
}: StepProps) {
  const { t } = useTranslation('createGoalWizard');

  const { data: names } = useQuery(FETCH_MODEL_AND_THEME_NAME, {
    variables: { id: state.portfolioFinancialProductId },
    skip: !state.portfolioFinancialProductId,
  });

  return (
    <>
      <Box m={2}>
        <Typography align='center' variant='wizardSubTitle' mb={1}>{t('goalSummary')}</Typography>
        <Typography align='center' variant='subtitle1' mb={2}>{t('pleaseReview')}</Typography>
        <hr/>
      </Box>

      <Box>
        <ReviewListItem label={t('client:goalTypes')} value={t(`client:goalType.${state.type}`)}/>
        <ReviewListItem label={t('client:goalName')} value={state.name}/>
        <ReviewListItem label={t('client:timeHorizon')} value={t(`client:timeHorizonOptions.${state.timeHorizon}`)}/>
        <ReviewListItem label={t('client:targetAmount')} value={formatMoneyValue(state.targetAmountCents)}/>
        <ReviewListItem label={t('client:details.riskQuestion1')} value={t(`client:edit.riskQuestion1Options.${state.riskQuestion1}`)}/>
        { state.applyPortfolio
          ? <>
              <ReviewListItem label={t('client:selectTheme')} value={names?.fetchModelPortfolio.modelPortfolio.theme.name ?? ''}/>
              <ReviewListItem label={t('client:selectProduct')} value={names?.fetchModelPortfolio.modelPortfolio.name ?? ''}/>
            </>
          : <ReviewListItem label={t('basics.applyPortfolioAtGoal')} value={t('shared:no')}/>
          }
      </Box>

      <ListItem sx={{ flex: 1, alignItems: 'end', marginBottom: 1 }}>
        <Button type='submit' fullWidth variant='contained' sx={{ textTransform: 'none' }}
          data-testid="create-goal-action-btn"
          onClick={() => continueTo('CREATE_GOAL_ACTION')}
        >
          {t('createGoal')}
        </Button>
      </ListItem>
    </>
  );
}

const FETCH_MODEL_AND_THEME_NAME = gql`
  query fetchModelAndThemeName($id: ObjectID!) {
    fetchModelPortfolio(input:{modelPortfolioId: $id}) {
      modelPortfolio {
        theme { name }
        name
      }
    }
  }
`;
