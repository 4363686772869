import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../../util/useLocalization';

const FETCH_BULK_IMPORT_BREADCRUMB = gql`
  query fetchBulkImportTimestamp($bulkImportId: ObjectID!) {
    fetchBulkImport(bulkImportId: $bulkImportId) {
      bulkImport {
        createdAt
      }
    }
  }
`;

export function BulkImportBreadcrumb() {
  const { data } = useQuery(FETCH_BULK_IMPORT_BREADCRUMB, { variables: { bulkImportId: useParams().bulkImportId } });
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();

  return `${t('bulkImport:bulkImportOn')} ${data ? localizedDateTime(data.fetchBulkImport?.bulkImport.createdAt) : ''}`;
}
