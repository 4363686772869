import {
  List, ListItem, ListItemButton, ListItemText, CircularProgress, Grid, Chip, Accordion, AccordionSummary, Typography, AccordionDetails, IconButton,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import NewModel from './newModel';
import EditTheme from './editTheme';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { FinancialProduct, FinancialProductState, Theme } from '../../../interfaces';
import TransitionTheme from './transitionTheme';

const MAX_THEME_MODELS_PER_PAGE = 12;

export const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        name
        state
      }
    }
  }
`;

export const financialProductStateColor = (state: FinancialProductState | undefined): 'warning' | 'success' | 'error' => {
  if (state === 'DRAFT') {
    return 'warning';
  }

  if (state === 'ACTIVE') {
    return 'success';
  }

  return 'error';
};

const ThemeModels = ({
  theme, expanded, handleChange, refetchThemes,
}: { theme: Theme, expanded: string | undefined, handleChange: (e: any) => void, refetchThemes: () => void }) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState(expanded === theme.id);
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  const { palette } = useTheme();
  const params = useParams();
  const financialProductId = params.id;

  const { loading, data, refetch } = useQuery(FETCH_MODEL_PORTFOLIOS, {
    variables: {
      input: {
        filter: {
          themeId: theme.id,
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: MAX_THEME_MODELS_PER_PAGE },
      },
    },
  });

  useEffect(() => {
    setOpen(expanded === theme.id);
  }, [expanded, setOpen, theme]);

  useEffect(() => {
    if (data && data.fetchModelPortfolios.modelPortfolios.map((x: FinancialProduct) => x.id).includes(financialProductId)) {
      setOpen(true);
    }
  }, [data, setOpen, financialProductId]);

  return (
    <Accordion key={theme.id} expanded={open} onChange={() => handleChange(theme.id)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}>
        <Typography sx={{ lineHeight: 2 }}>{theme.translatedName?.en}</Typography>

        <div>
          <TransitionTheme theme={theme} afterUpdate={() => refetchThemes()} modelPortfolios={data?.fetchModelPortfolios?.modelPortfolios} />

          {permissions.includes('write:themes') && (
            <IconButton
              size='small'
              sx={{ marginRight: 1 }}
              onClick={() => {
                setEditOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List sx={{ p: 0, maxHeight: '500px', overflow: 'auto' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            data.fetchModelPortfolios.modelPortfolios.map((fp: FinancialProduct) => (
              <ListItem key={fp.id} disablePadding component={Link} to={`/buildModels/${fp.id}`}>
                <ListItemButton selected={financialProductId === fp.id} sx={{ color: palette.text.primary }}>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <ListItemText>{fp.name}</ListItemText>
                    </Grid>
                    <Grid item>
                      <ListItemText>
                        <Chip label={fp.state} size='small' color={financialProductStateColor(fp.state)} />
                      </ListItemText>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
            ))
          )}
          {permissions.includes('write:model_portfolios') && (
            <ListItem>
              <NewModel
                themeId={theme.id}
                afterCreate={(id) => {
                  navigate(`/modelPortfolios/${id}`);
                  refetch();
                }}
              />
            </ListItem>
          )}
        </List>
      </AccordionDetails>
      <EditTheme
        open={editOpen}
        themeToUpdate={theme}
        afterUpdate={() => {
          setEditOpen(false);
          refetchThemes();
        }}
      />
    </Accordion>
  );
};

export default ThemeModels;
