import { gql, useQuery } from '@apollo/client';
import { CountryCodes } from '@onevesthq/ov-enums';
import { compact, difference } from 'lodash/fp';
import { useContext, useState } from 'react';
import { UserContext } from '../providers/userContextProvider';
import { useClientContext } from '../pages/client';

export enum IdVerificationStates {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum IdVerificationMethods {
  DIGITAL_DOCUMENT_CHECK = 'DIGITAL_DOCUMENT_CHECK',
  CREDIT_CHECK = 'CREDIT_CHECK',
  IN_PERSON_VALIDATION = 'IN_PERSON_VALIDATION',
  DUAL_METHOD = 'DUAL_METHOD',
  CONFIRMATION_OF_EXISTENCE = 'CONFIRMATION_OF_EXISTENCE',
}

const INTROSPECT_ID_DOCUMENT_TYPES = gql`
  query introspectIdDocumentTypes {
    idDocumentTypes:__type(name:"IdDocumentTypes") {
      enumValues { name }
    }
  }
`;

/**
 * List of ID Verification Document Types that are only meaningful in a specific country
 * (see user-service for the complete list)
 */
const COUNTRY_SPECIFIC_ID_VERIFICATION_DOCUMENT_TYPES: { [K in CountryCodes]?: string[]; } = {
  CA: [
    'BENEFITS_STATEMENT',
    'CAD_CREDIT_BUREAU_PRODUCT',
    'CANADA_411',
    'CITIZENSHIP_CARD',
    'CITIZENSHIP_CERTIFICATE',
    'CONFIRMATION_FROM_FINANCIAL_ENTITY',
    'CREDIT_CARD_STATEMENT',
    'CREDIT_FILE',
    'DIVORCE_DOCUMENTATION',
    'GLOBAL_ENTRY_CARD',
    'GOV_ISSUED_CPP_STATEMENT',
    'GOV_ISSUED_VEHICLE_REGISTRATION',
    'HEALTH_CARD',
    'ID',
    'INDIAN_STATUS_CARD',
    'INSURANCE_DOCUMENT',
    'INVESTMENT_ACCOUNT_STATEMENT',
    'LOAN_ACCOUNT_STATEMENT',
    'MICRO_DEPOSIT',
    'NEXUS',
    'OTHER',
    'PERMANENT_RESIDENT_CARD',
    'PROCESSED_CHEQUE',
    'PROPERTY_TAX_ASSESSMENT',
    'PROVINCIAL_ID',
    'RECORD_OF_EMPLOYMENT',
    'SCHOOL_DOCUMENT',
    'TRAVEL_VISA',
    'UTILITY_STATEMENT',
  ],
  US: [
    'MILITARY_ID',
    'NEXUS',
    'PERMANENT_RESIDENT_CARD',
    'SOCIAL_SECURITY_CARD',
    'STATE_ID',
  ],
};

/**
 * Loads all idDocumentTypes from backend
 */
const useBackendIdDocumentTypes = () => {
  const [idDocumentTypes, setTypes] = useState<string[]>([]);

  const { loading } = useQuery(INTROSPECT_ID_DOCUMENT_TYPES, {
    onCompleted: (data) => {
      const allTypes = data.idDocumentTypes.enumValues.map((typedef: { name: string }) => typedef.name);
      setTypes(allTypes.sort());
    },
  });

  return { idDocumentTypes, loading };
};

/**
 * Loads all country-specific idDocumentTypes from backend
 */
export const useCountrySpecificBackendIdDocumentTypes = () => {
  const { idDocumentTypes, loading } = useBackendIdDocumentTypes();

  const clientContext = useClientContext();
  const { activeOrganization } = useContext(UserContext);
  const countries = clientContext?.orgSettings.applicableLocalization?.countries ?? activeOrganization.applicableLocalization?.countries;

  const skipCountries = difference(Object.keys(COUNTRY_SPECIFIC_ID_VERIFICATION_DOCUMENT_TYPES), countries) as CountryCodes[];
  const allowList = compact(countries.map((c) => COUNTRY_SPECIFIC_ID_VERIFICATION_DOCUMENT_TYPES[c]).flat());
  const blockList = compact(skipCountries.map((c) => COUNTRY_SPECIFIC_ID_VERIFICATION_DOCUMENT_TYPES[c]).flat());

  const filteredTypes = idDocumentTypes.filter((t: string) => allowList.includes(t) || !blockList.includes(t));

  return { idDocumentTypes: filteredTypes, loading };
};

const informationSourcesCanada = [
  'ALBERTA', 'BRITISH_COLUMBIA', 'MANITOBA', 'NEW_BRUNSWICK', 'NEW_FOUNDLAND_AND_LABRADOR', 'NOVA_SCOTIA', 'ONTARIO',
  'PRINCE_EDWARD_ISLAND', 'SASKATCHEWAN', 'QUEBEC', 'YUKON', 'NORTH_WEST_TERRITORIES', 'NUNAVUT',
  'CA',
];

const informationSourcesUS = [
  'ALASKA', 'ALABAMA', 'ARIZONA', 'ARKANSAS', 'CALIFORNIA', 'COLORADO', 'CONNECTICUT', 'DELAWARE',
  'DISTRICT_OF_COLUMBIA', 'FLORIDA', 'GEORGIA', 'GUAM', 'HAWAII', 'IDAHO',
  'ILLINOIS', 'INDIANA', 'IOWA', 'KANSAS', 'KENTUCKY', 'LOUISIANA', 'MAINE',
  'MARSHALL_ISLAND', 'MICHIGAN', 'MARYLAND', 'MASSACHUSETTS', 'MINNESOTA',
  'MISSISSIPPI', 'MISSOURI', 'MONTANA', 'NEBTASKA', 'NEVADA', 'NEW_HAMPSHIRE', 'NEW_JERSEY',
  'NEW_MEXICO', 'NEW_YORK', 'NORTH_CAROLINA', 'NORTH_DAKOTA', 'OHIO', 'OKLAHOMA',
  'OREGON', 'PALAU', 'PENNSYLVANIA', 'PUERTO_RICO', 'RHODE_ISLAND', 'SOUTH_CAROLINA',
  'SOUTH_DAKOTA', 'TENNESSEE', 'TEXAS', 'UTAH', 'US_VIRGIN_ISLAND', 'VERMONT',
  'VIGINIA', 'WASHINGTON', 'WEST_VIRGINIA', 'WINSCONSIN', 'WYOMING', 'US',
];

export const useCountrySpecificInformationSources = (): string[] => {
  const clientContext = useClientContext();
  const { activeOrganization } = useContext(UserContext);
  const countries = clientContext?.orgSettings.applicableLocalization?.countries ?? activeOrganization.applicableLocalization?.countries;

  return [
    ...countries.includes(CountryCodes.CA) ? informationSourcesCanada : [],
    ...countries.includes(CountryCodes.US) ? informationSourcesUS : [],
  ];
};

export interface IdVerification {
  id?: string,
  documentType?: string,
  documentIssuingJurisdication?: string
  documentExpiryDate?: Date,
  methodOfIDVerification?: IdVerificationMethods,
  verifiedDate?: Date,
  userName?: string,
  documentID?: string,
  secondaryDocumentType?: string
  secondaryDocumentUserName?: string
  secondaryDocumentID?: string
  secondaryDocumentIssuingJurisdication?: string
  secondaryDocumentExpiryDate?: Date
  state?: IdVerificationStates
  creditFileSource?: string,
}
