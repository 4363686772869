import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, Button,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePageState } from '../../../util/usePageState';

const FETCH_BULK_TRADE_RUNS = gql`
  query fetchBulkTradeRuns($input: FetchBulkTradeRunsInput!) {
    fetchBulkTradeRuns(input: $input) {
      bulkTradeRuns {
        id
        createdAt
        buyBulkTradeFileUrl
        sellBulkTradeFileUrl
        mutualFundFileUrl
        allocationFileUrls
        state
        bulkPTFFileUrls
        ptfAllocationFileUrls
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

export const CREATE_BULK_TRADE_RUN = gql`
  mutation generateBulkTrades($input: GenerateBulkTradesInput!){
    generateBulkTrades(input: $input) {
      bulkTradeRun {
        id
      }
    }
  }
`;

const BulkTradersTable = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['bulkTrader']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BULK_TRADE_RUNS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [newBulkTrader, { data: newData, loading: newLoading }] = useMutation(CREATE_BULK_TRADE_RUN);

  useEffect(() => {
    if (newData) {
      navigate(`/bulkTrader/${newData.generateBulkTrades.bulkTradeRun.id}`);
    }
  }, [newData, navigate]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {permissions.includes('write:bulk_trade_run') && (
            <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
              <Grid item>
                <Button disabled={newLoading} variant='contained' onClick={() => {
                  newBulkTrader({
                    variables: {
                      input: { autoFillActualQuantity: false, organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('startNew')}</Button>
              </Grid>
            </Grid>
          )}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.createdAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.files')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchBulkTradeRuns?.bulkTradeRuns?.map((bulkTradeRun: any) => (
                <TableRow
                  hover
                  key={bulkTradeRun.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/bulkTrader/${bulkTradeRun.id}`)}
                >
                  <TableCell><DateTime variant='subtitle2' date={bulkTradeRun.createdAt} /></TableCell>
                  <TableCell>
                    {bulkTradeRun.buyBulkTradeFileUrl && (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={bulkTradeRun.buyBulkTradeFileUrl} download>{t('buyTradeFile')}</Button>
                    )}
                    {bulkTradeRun.sellBulkTradeFileUrl && (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={bulkTradeRun.sellBulkTradeFileUrl} download>{t('sellTradeFile')}</Button>
                    )}
                    {bulkTradeRun.mutualFundFileUrl && (
                      <Button onClick={
                        (e) => e.stopPropagation()
                        } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={bulkTradeRun.mutualFundFileUrl} download>{t('mutualFundTradeFile')}</Button>
                    )}
                    {bulkTradeRun.bulkPTFFileUrls.map((fileUrl: string, index: number) => (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={fileUrl} download>{t('ptfBulkTradeFile')}: {index + 1}</Button>
                    ))}
                    {bulkTradeRun.allocationFileUrls.map((fileUrl: string, index: number) => (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={fileUrl} download>{t('allocationFile')}: {index + 1}</Button>
                    ))}
                    {bulkTradeRun.ptfAllocationFileUrls.map((fileUrl: string, index: number) => (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={fileUrl} download>{t('ptfAllocationFile')}: {index + 1}</Button>
                    ))}
                  </TableCell>
                  <TableCell>{bulkTradeRun.organization.name}</TableCell>
                  <TableCell align="right"><Chip label={bulkTradeRun.state} size='small' color={bulkTradeRun.state === 'COMPLETED' ? 'success' : 'warning'}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchBulkTradeRuns?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default BulkTradersTable;
