import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface CardTokens {
  card: {
    elevation?: string;
    hoverElevation?: string;
    borderRadius?: string;
    border?: string;
    background?: {
      color?: string;
    },
    text?: {
      color?: string;
    },
  },
}

export const useCardTokens = (): CardTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: CardTokens = {
    card: {
      elevation: sys.elevation.surface,
      hoverElevation: sys.elevation.low,
      borderRadius: sys.borderRadius.xl,
      border: `1px solid ${sys.color.outline}`,
      background: {
        color: sys.color.surface,
      },
      text: {
        color: sys.color.onSurface,
      },
    },
  };

  return comp;
};
