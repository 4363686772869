import { useEffect, useState } from 'react';
import { CheckCircle, Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { SxProps } from '@mui/material';
import {
  getAccountName,
  getGoalName,
  AccountTypes,
  AccountStates,
  EntityTypes,
  FinancialProduct,
  SubAccount,
  SubAccountStates,
  Theme,
  User,
  UserStates,
} from 'interfaces';
import MugImage from 'assets/images/custom/mug.svg';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Dialog,
  StatusTag,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'ovComponents/2-component';
import { ConfirmationModal, ConfirmButtonColors } from 'ovComponents/3-pattern';
import { translateBackend } from 'assets/i18n/config';
import { DraftFinancialProduct } from './interfaces';
import { FinancialProductSelectionForm } from './financialProductSelection.visual';

const tableHeadSx: SxProps = {
  textTransform: 'uppercase',
  color: colors.neutral600,
};
const tableRowSx: SxProps = {
  border: 'none',
  py: 2,

};

export const SubAccountsVisual = ({
  options,
  loading,
  subAccounts,
  activeSubAccountId,
  draftFinancialProducts,
  portfolioThemes,
  modelPortfolios,
  updateDraft,
  onSubAccountSelection,
  onPortfolioThemeChange,
  onDraftSubmission,
  onContinue,
}: {
  options: any,
  loading: boolean,
  subAccounts: SubAccount[],
  activeSubAccountId: string,
  draftFinancialProducts: { [subAccountId: string]: DraftFinancialProduct },
  portfolioThemes: Theme[],
  modelPortfolios: FinancialProduct[],
  updateDraft: (subAccountId: string, draft: DraftFinancialProduct) => void,
  onSubAccountSelection: (subAccount: SubAccount) => void,
  onPortfolioThemeChange: (theme: Theme) => void,
  onDraftSubmission: () => void,
  onContinue: () => void,
}) => {
  const { t } = useTranslation('client, workflowCompletions');
  const [open, setOpen] = useState(false);
  const [draftFinancialProduct, setDraftFinancialProduct] = useState<DraftFinancialProduct>();
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [warningModalProps, setWarningModalProps] = useState({
    open: false,
    onConfirm: () => {},
    onCancel: () => {},
    title: '',
    bodyText: '',
    confirmButtonLabel: '',
    cancelButtonLabel: '',
    confirmButtonColor: ConfirmButtonColors.primary,
    showCancelButton: true,
  });

  useEffect(() => {
    setDraftFinancialProduct(draftFinancialProducts[activeSubAccountId]);
  }, [draftFinancialProducts, activeSubAccountId]);

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    const numSubAcountsWithoutFinancialProducts = subAccounts.filter((subAccount) => !subAccount.financialProduct).length;
    const requireAllProducts = options.requireAllSubAccountProducts;
    if (numSubAcountsWithoutFinancialProducts > 0) {
      const pluralSuffix = numSubAcountsWithoutFinancialProducts > 1 ? 's' : '';
      setWarningModalProps({
        open: true,
        onConfirm: () => {
          setShowWarningModal(false);
          if (!requireAllProducts) {
            onContinue();
          }
        },
        onCancel: () => setShowWarningModal(false),
        title: t('workflowCompletions:subAccounts.warningModal.title', { numSubAccounts: numSubAcountsWithoutFinancialProducts, pluralSuffix }),
        bodyText: t('workflowCompletions:subAccounts.warningModal.body', { numSubAccounts: numSubAcountsWithoutFinancialProducts, pluralSuffix }),
        confirmButtonLabel: t(`workflowCompletions:subAccounts.warningModal.${requireAllProducts ? 'returnButtonLabel' : 'confirmButtonLabel'}`),
        cancelButtonLabel: t('workflowCompletions:subAccounts.warningModal.cancelButtonLabel'),
        confirmButtonColor: ConfirmButtonColors.primary,
        showCancelButton: !requireAllProducts,
      });
      setShowWarningModal(true);
    } else {
      onContinue();
    }
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <Box display='flex' flexDirection='column' justifyContent='space-between' gap={3}>
        {!loading && subAccounts?.length === 0 ? (
          <Box display='flex' alignItems='center' flexDirection='column'>
            <img src={MugImage} alt="mug-icon" />
            <Typography variant='bodyLarge' sx={{
              mt: 1, maxWidth: '500px', textAlign: 'center', mb: 3,
            }}>{t('workflowCompletions:subAccounts.summaryTable.noSubAccounts')}</Typography>
        </Box>
        ) : (
          <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='labelSmall' sx={tableHeadSx}>{t('workflowCompletions:subAccounts.summaryTable.goal')}</Typography></TableCell>
              <TableCell><Typography variant='labelSmall' sx={tableHeadSx}>{t('workflowCompletions:subAccounts.summaryTable.account')}</Typography></TableCell>
              <TableCell><Typography variant='labelSmall' sx={tableHeadSx}>{t('workflowCompletions:subAccounts.summaryTable.product')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subAccounts?.map((subAccount: SubAccount) => (
              <TableRow
                key={subAccount.id}
                hover
                sx={{ cursor: subAccount.financialProduct ? 'not-allowed' : 'pointer' }}
                onClick={() => {
                  if (subAccount.financialProduct) return;
                  onSubAccountSelection(subAccount);
                  setOpen(true);
                }}
              >
                <TableCell sx={tableRowSx}><Typography variant='bodyMedium'>{getGoalName(subAccount.goal) || t('workflowCompletions:goals.goal')}</Typography></TableCell>
                <TableCell sx={tableRowSx}><Typography variant='bodyMedium'>{getAccountName(subAccount.account, false)}</Typography></TableCell>
                <TableCell sx={tableRowSx}>
                  <StatusTag
                    color={subAccount.financialProduct ? 'positive' : 'warning'}
                    icon={subAccount.financialProduct ? CheckCircle : Warning}
                    label={subAccount.financialProduct ? (translateBackend(subAccount?.financialProduct?.translatedName)) : t('workflowCompletions:subAccounts.summaryTable.productNotSet')}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        )}
        <Box display='flex' justifyContent='end'>
          <Button label={t('client:continue')} disabled={loading} onClick={handleContinue} />
        </Box>

        <Dialog
          sx={{
            '.MuiDialog-container': {
              '.MuiPaper-root': {
                padding: '20px',
              },
            },
          }}
          open={open}
          onClose={handleModalClose}
          fullWidth
        >
          <FinancialProductSelectionForm
            draftFinancialProduct={draftFinancialProduct}
            portfolioThemes={portfolioThemes}
            modelPortfolios={modelPortfolios}
            updateDraft={updateDraft}
            onPortfolioThemeChange={onPortfolioThemeChange}
            onClose={handleModalClose}
            onSubmit={onDraftSubmission}
          />
        </Dialog>
        {showWarningModal && <ConfirmationModal {...warningModalProps} />}
      </Box>
    </>
  );
};

const exampleUser: User = {
  id: 'user',
  subAccounts: [],
  goals: [],
  accounts: [],
  type: EntityTypes.INDIVIDUAL,
  organization: { id: 'org' },
  state: UserStates.ACTIVE,
  physicalAddress: '',
};

export const subAccountsVisualPropsExample = {
  options: {},
  loading: false,
  subAccounts: [
    {
      id: '1',
      state: SubAccountStates.ACTIVE,
      account: {
        id: 'account1',
        type: AccountTypes.TFSA,
        applyForGovFunds: [],
        state: AccountStates.ACTIVE,
        user: exampleUser,
      },
      goal: {
        id: 'goal1',
        name: 'Buy a car',
        user: exampleUser,
      },
      financialProduct: {
        translatedName: { en: 'Very Low Risk - Core' },
      },
    },
    {
      id: '2',
      state: SubAccountStates.ACTIVE,
      account: {
        id: 'account2',
        type: AccountTypes.RRSP,
        applyForGovFunds: [],
        state: AccountStates.ACTIVE,
        user: exampleUser,
      },
      goal: {
        id: 'goal2',
        name: 'Buy a house',
        user: exampleUser,
      },
    },
  ],
  activeSubAccountId: '',
  draftFinancialProducts: {},
  portfolioThemes: [],
  modelPortfolios: [],
  updateDraft: () => {},
  onDraftSubmission: () => {},
  onSubAccountSelection: () => {},
  onPortfolioThemeChange: () => {},
  onContinue: () => {},
};

export default SubAccountsVisual;
