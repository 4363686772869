import { TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EntityTypes } from '../../interfaces';

export const EntityTypeSelect = ({
  nonIndividual, setValue, value, autoFocus,
}: {
  nonIndividual?: true, setValue: (newValue: EntityTypes | '') => void, value: EntityTypes | '', autoFocus?: boolean
}) => {
  const { t } = useTranslation('components');

  return (
    <>
      <TextField select fullWidth
        label={t('nonIndividualClients:entityType')}
        value={value}
        onChange={(event) => setValue(event?.target.value as EntityTypes | '')}
        autoFocus={autoFocus}
      >
        { !nonIndividual
        && <MenuItem value='INDIVIDUAL'>{t('entityTypes:INDIVIDUAL')}</MenuItem>
        }
        <MenuItem value='PUBLICLY_LISTED_ENTITY'>{t('entityTypes:PUBLICLY_LISTED_ENTITY')}</MenuItem>
        <MenuItem value='PRIVATE_COMPANY'>{t('entityTypes:PRIVATE_COMPANY')}</MenuItem>
        <MenuItem value='PARTNERSHIP'>{t('entityTypes:PARTNERSHIP')}</MenuItem>
        <MenuItem value='SOLE_PROPRIETORSHIP'>{t('entityTypes:SOLE_PROPRIETORSHIP')}</MenuItem>
        <MenuItem value='TRUST'>{t('entityTypes:TRUST')}</MenuItem>
        <MenuItem value='INVESTMENT_FUND'>{t('entityTypes:INVESTMENT_FUND')}</MenuItem>
        <MenuItem value='REGULATED_ENTITY'>{t('entityTypes:REGULATED_ENTITY')}</MenuItem>
        <MenuItem value='OTHER'>{t('entityTypes:OTHER')}</MenuItem>
      </TextField>
    </>
  );
};
