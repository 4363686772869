import { Typography, Chip } from '@mui/material';

const ScoreBlock = ({ title, value, color }: { title: string, value: string, color: any }) => (
    <>
      <Typography variant='overline' sx={{ lineHeight: 2 }}>{ title }</Typography>
      <Typography variant='h5' sx={{ lineHeight: 1 }}>
        <Chip
          size='medium'
          label={value}
          color={color}
        />
      </Typography>
    </>
);

export default ScoreBlock;
