import i18n from '../../../../assets/i18n/config';
import { CustomFieldObjectTypes, CustomFieldTypes, CustomFieldSource } from '../../../../interfaces/customField';

export interface ObjectType {
  value: CustomFieldObjectTypes,
  key: CustomFieldObjectTypes,
  label: any,
}

export interface CustomFieldType {
  value: CustomFieldTypes,
  key: CustomFieldTypes,
  label: any,
}

export interface FieldSource {
  value: CustomFieldSource,
  key: CustomFieldSource,
  label: any,
}
export const objectTypes: ObjectType[] = [
  {
    value: CustomFieldObjectTypes.ACCOUNT,
    key: CustomFieldObjectTypes.ACCOUNT,
    label: i18n.t('orgSettings:customFieldModal.objectTypes.ACCOUNT'),
  },
  {
    value: CustomFieldObjectTypes.ENTITY,
    key: CustomFieldObjectTypes.ENTITY,
    label: i18n.t('orgSettings:customFieldModal.objectTypes.ENTITY'),
  },
  {
    value: CustomFieldObjectTypes.GOAL,
    key: CustomFieldObjectTypes.GOAL,
    label: i18n.t('orgSettings:customFieldModal.objectTypes.GOAL'),
  },
  {
    value: CustomFieldObjectTypes.HOUSEHOLD,
    key: CustomFieldObjectTypes.HOUSEHOLD,
    label: i18n.t('orgSettings:customFieldModal.objectTypes.HOUSEHOLD'),
  },
  {
    value: CustomFieldObjectTypes.SUB_ACCOUNT,
    key: CustomFieldObjectTypes.SUB_ACCOUNT,
    label: i18n.t('orgSettings:customFieldModal.objectTypes.SUB_ACCOUNT'),
  },
];

export const customFieldTypes: CustomFieldType[] = [
  {
    value: CustomFieldTypes.BOOLEAN,
    key: CustomFieldTypes.BOOLEAN,
    label: i18n.t('orgSettings:customFieldModal.types.BOOLEAN'),
  },
  {
    value: CustomFieldTypes.CUSTOM_OBJECT,
    key: CustomFieldTypes.CUSTOM_OBJECT,
    label: i18n.t('orgSettings:customFieldModal.types.CUSTOM_OBJECT'),
  },
  {
    value: CustomFieldTypes.DATE,
    key: CustomFieldTypes.DATE,
    label: i18n.t('orgSettings:customFieldModal.types.DATE'),
  },
  {
    value: CustomFieldTypes.DECIMAL,
    key: CustomFieldTypes.DECIMAL,
    label: i18n.t('orgSettings:customFieldModal.types.DECIMAL'),
  },
  {
    value: CustomFieldTypes.INTEGER,
    key: CustomFieldTypes.INTEGER,
    label: i18n.t('orgSettings:customFieldModal.types.INTEGER'),
  },
  {
    value: CustomFieldTypes.MULTIPLE_SELECT,
    key: CustomFieldTypes.MULTIPLE_SELECT,
    label: i18n.t('orgSettings:customFieldModal.types.MULTIPLE_SELECT'),
  },
  {
    value: CustomFieldTypes.SINGLE_SELECT,
    key: CustomFieldTypes.SINGLE_SELECT,
    label: i18n.t('orgSettings:customFieldModal.types.SINGLE_SELECT'),
  },
  {
    value: CustomFieldTypes.TEXT,
    key: CustomFieldTypes.TEXT,
    label: i18n.t('orgSettings:customFieldModal.types.TEXT'),
  },
];

export const customFieldSource: FieldSource[] = [
  {
    value: CustomFieldSource.CUSTODIAN,
    key: CustomFieldSource.CUSTODIAN,
    label: i18n.t('orgSettings:customFieldModal.source.CUSTODIAN'),
  },
  {
    value: CustomFieldSource.INTERNAL,
    key: CustomFieldSource.INTERNAL,
    label: i18n.t('orgSettings:customFieldModal.source.INTERNAL'),
  },
];
