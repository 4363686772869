export const reorder = (result: any, columns: any[]): any[] => {
  const { destination, source } = result;

  if (!destination) {
    return columns;
  }

  if (
    destination.droppableId === source.droppableId
    && destination.index === source.index
  ) {
    return columns;
  }

  if (destination.droppableId === source.droppableId) {
    const column = columns.find((c: any) => c.id === source.droppableId);
    const newSubSteps = Array.from(column.subSteps);
    const movedObject = newSubSteps.splice(source.index, 1)[0];
    newSubSteps.splice(destination.index, 0, movedObject);

    const newColumns = columns.map((c: any) => {
      if (c.id === source.droppableId) {
        return {
          ...c,
          subSteps: newSubSteps,
        };
      }
      return c;
    });

    return newColumns;
  }

  const oldColumn = columns.find((c: any) => c.id === source.droppableId);
  const oldSubSteps = Array.from(oldColumn.subSteps);
  const movedObject = oldSubSteps.splice(source.index, 1)[0];

  const newColumn = columns.find((c: any) => c.id === destination.droppableId);
  const newSubSteps = Array.from(newColumn.subSteps);
  newSubSteps.splice(destination.index, 0, movedObject);

  const newColumns = columns.map((c: any) => {
    if (c.id === source.droppableId) {
      return {
        ...c,
        subSteps: oldSubSteps,
      };
    }
    if (c.id === destination.droppableId) {
      return {
        ...c,
        subSteps: newSubSteps,
      };
    }
    return c;
  });

  return newColumns;
};

export const reorderColumns = (result: any, columns: any[]): any[] => {
  const { source, destination } = result;

  if (!destination) {
    return columns;
  }

  const newColumns = Array.from(columns);
  const [movedColumn] = newColumns.splice(source.index, 1);
  newColumns.splice(destination.index, 0, movedColumn);

  return newColumns;
};

export const updateWorkflowSubSteps = (currentSubSteps: string[], availableFeatureFlags?: string[]) => {
  if (availableFeatureFlags?.includes('DEPOSIT_WORKFLOW')) {
    if (!currentSubSteps.includes('DEPOSIT_TYPE')) currentSubSteps.push('DEPOSIT_TYPE');
    if (!currentSubSteps.includes('DEPOSIT_FORM')) currentSubSteps.push('DEPOSIT_FORM');
    if (!currentSubSteps.includes('DEPOSIT_REVIEW')) currentSubSteps.push('DEPOSIT_REVIEW');
  }
  if (availableFeatureFlags?.includes('WITHDRAW_WORKFLOW')) {
    if (!currentSubSteps.includes('WITHDRAW_FORM')) currentSubSteps.push('WITHDRAW_FORM');
    if (!currentSubSteps.includes('WITHDRAW_REVIEW')) currentSubSteps.push('WITHDRAW_REVIEW');
  }
};
