import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, PageObjectType } from '../..';
import { Widget } from '../pageConfiguration/components/widget';
import { UserContext } from '../../../providers/userContextProvider';
import { NavigationStyles } from '../../../interfaces';
import { AppBreadcrumbs } from '../../../components/layout/appBreadcrumbs';

export const AllNewsAndInsights = () => {
  const { userContext, activeOrganization } = useContext(UserContext);
  const { widgetId } = useParams();
  // Add new configurations here
  const allNewsAndInsightsConfiguration = [
    userContext.role?.individualPageConfiguration,
    userContext.role?.nonIndividualPageConfiguration,
    userContext.role?.goalPageConfiguration,
    userContext.role?.accountPageConfiguration,
    userContext.role?.householdPageConfiguration,
    userContext.role?.subAccountPageConfiguration].reduce((prev: any, curr: any) => {
    const widget = curr?.tabs?.reduce((widgetArray: any, currTab: any) => {
      const widgets = currTab.widgets.filter((item: any) => item.type === 'NEWS_AND_INSIGHTS');
      return [...widgetArray, ...widgets];
    }, []) ?? [];
    return [...prev, ...widget];
  }, []);
  const newsAndInsightConfiguration = allNewsAndInsightsConfiguration.find((item: any) => widgetId === item.id) ?? allNewsAndInsightsConfiguration[0];
  return (
    <>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' } }} justifyContent={'center'} width='100%' mt={2} ml={{ xs: 0, sm: 2 }}>
        <Box maxWidth='1080px' width='100%' mt={2} ml={{ xs: 0, sm: 2 }}>
          {activeOrganization.displayMenuBar && userContext.role?.navigationStyle === NavigationStyles.SIMPLE && (
              <AppBreadcrumbs />
          )}
          <Widget
            type="NEWS_AND_INSIGHTS"
            i={1}
            objectId=""
            viewOnly
            options={{
              ...newsAndInsightConfiguration.options,
              showAll: true,
            }}
            objectType={PageObjectType.INDIVIDUAL}
          />
        </Box>
      </Box>
    </>
  );
};

export default AllNewsAndInsights;
