import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { AddHouseholdMemberWizard, AddHouseholdMemberWizardProps } from './wizard';

export function AddHouseholdMemberWizardButton(props: AddHouseholdMemberWizardProps) {
  const [wizardShown, setWizardShown] = useState(false);

  return (
    <>
      <Button variant='text' color='secondary' onClick={() => { setWizardShown(true); }}>
        <AddIcon />
      </Button>
      { wizardShown
        && <AddHouseholdMemberWizard {...props} handleClose={() => setWizardShown(false)}/>
      }
    </>
  );
}
