import DOMPurify from 'dompurify';

if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: (string) => {
      if (string.includes('dom-purify-bypass="true"')) {
        return string;
      }
      const sanitized = DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true });
      return sanitized;
    },
    createScriptURL: (string) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
    createScript: (string) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
  });
}
