import { Typography } from '@mui/material';
import parsePhoneNumber from 'libphonenumber-js';

const Phone = (props: any) => {
  if (!props.phone) {
    return null;
  }

  const phone = parsePhoneNumber(`+1${props.phone}`);

  return (
    <Typography { ...props }>
      { `+1 ${phone?.formatNational()}` }
    </Typography>
  );
};

export default Phone;
