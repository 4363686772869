import { Typography } from '@mui/material';
import { round } from 'lodash';
import { formatMoneyValue } from '../../../../util';
import { colors } from '../../../../theme/colors';

const SmallDollarPercentage = ({
  cents,
  percentage,
  bold = false,
  showColor = false,
  showPercentage = true,
  isRedColor = false,
}: {
  cents: number;
  percentage?: number;
  bold?: boolean;
  showColor?: boolean;
  showPercentage?: boolean;
  isRedColor?: boolean;
}) => {
  const value = formatMoneyValue(round(cents) || 0);
  return (
    <Typography
      variant='caption'
      sx={{ fontWeight: bold ? 800 : 400, fontVariantNumeric: 'tabular-nums' }}
      color={
        (showColor && isRedColor) ? 'error' : (showColor && percentage ? (Math.abs(percentage) > 0.1 ? 'error' : showColor && Math.abs(percentage) > 0.05 ? colors.warning : 'default') : 'default')
      }
    >
      {value}
      {showPercentage && (
        <>
          <br />
          {!isFinite(percentage || 0) ? '100.00' : round((percentage || 0) * 100, 2).toFixed(2)}
          %
        </>
      )}
    </Typography>
  );
};

export default SmallDollarPercentage;
