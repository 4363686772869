import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { gql, useQuery } from '@apollo/client';
import TuneIcon from '@mui/icons-material/Tune';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import { OrganizationBilling } from '../../../interfaces/organizationBilling';
import { textColumnStyles } from '../../../util/styles';
import { formatMoneyValue } from '../../../util';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

export const FETCH_ORGANIZATION_BILLINGS = gql`
  query fetchOrganizationBillings($input: FetchOrganizationBillingsInput!) {
    fetchOrganizationBillings(input: $input) {
      totalCount
      organizationBillings {
        id
        organization { id name }
        feeCents
        userManagementFeeCents
        revenueShareCents
        revenueShareTaxCents
      }
    }
  }
`;

const RevenueShareTable = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['feeAndBilling', 'shared', 'components']);
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [page, setPage] = usePageState(1, 'page');

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id]);

  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_ORGANIZATION_BILLINGS, {
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
        },
        pagination: { perPage: pageSize, offSet: (page - 1) * pageSize },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ p: 2 }} spacing={1}>
            <Grid item xs={2.5}>
              <OrganizationSelect
                label={t('feeAndBilling:feeReport.table.organization')}
                onChange={(event) => setOrganizationId(event.target.value)}
                value={organizationId}
                childrenFor={activeOrganization.id}
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <LocalizedDatePicker
                label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
                value={dateAfter || null}
                onChange={(date) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: dateAfter && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
              />
            </Grid>
            <Grid item xs={2}>
              <LocalizedDatePicker
                label={t('feeAndBilling:feeReport.filters.billingDateTo')}
                value={dateBefore || null}
                onChange={(date) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: dateBefore && (<IconButton onClick={() => setDateBefore('')}><ClearIcon /></IconButton>) }}
              />
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title={t('feeAndBilling:feeGrid.clearFilter')} placement="right">
                <IconButton sx={{ cursor: 'pointer' }} onClick={() => {
                  setOrganizationId(activeOrganization.id ?? '');
                  setDateAfter('');
                  setDateBefore('');
                  setPage(1);
                }} >
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:revenueShareReport.table.organization')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:revenueShareReport.table.feeCents')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:revenueShareReport.table.userManagementFeeCents')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:revenueShareReport.table.revenueShareCents')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:revenueShareReport.table.revenueShareTaxCents')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchOrganizationBillings?.organizationBillings?.map((item: OrganizationBilling) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography sx={textColumnStyles}>{item.organization?.name}</Typography></TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(item.feeCents)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(item.userManagementFeeCents)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(item.revenueShareCents)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(item.revenueShareTaxCents)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchGroupedBillings?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default RevenueShareTable;
