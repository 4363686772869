import {
  Typography, Table, TableHead,
  TableRow, TableCell, TableBody,
  Alert,
  Box,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { usePermissions } from '../../../providers/userContextProvider';

import { ImportFileLineItem, ImportFileLineItemStates } from '../../../interfaces/bulkImport';
import { colors } from '../../../theme/colors';
import { FETCH_IMPORT_FILE_LINE_ITEMS } from './importFileLineItemsTable';
import { FETCH_QUEUED_LINE_ITEMS } from '..';

const UPDATE_IMPORT_FILE_LINE_ITEM = gql`
  mutation updateImportFileLineItem($input: UpdateImportFileLineItemInput!){
    updateImportFileLineItem(input: $input) {
      importFileLineItem {
        id
        state
      }
    }
  }
`;

const isValidJson = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
};

const LineItemModal = ({ lineItem, handleClose, disabledEditLineItemButton }: {
  lineItem: ImportFileLineItem,
  handleClose: () => void,
  disabledEditLineItemButton: boolean,
}) => {
  const { t } = useTranslation('components');
  const { permissions } = usePermissions();
  const [editMode, setEditMode] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updatedRawData, setUpdatedRawData] = useState<any>(lineItem.rawData);
  const [updatedRawDataStr, setUpdatedRawDataStr] = useState(JSON.stringify(lineItem.rawData, null, 2));

  const [editLineItem] = useMutation(UPDATE_IMPORT_FILE_LINE_ITEM, { refetchQueries: [FETCH_IMPORT_FILE_LINE_ITEMS, FETCH_QUEUED_LINE_ITEMS] });

  if (!lineItem) return <></>;

  const onEditClick = () => setEditMode(true);
  const onEditCancelClick = () => {
    setEditMode(false);
    setUpdatedRawData(lineItem.rawData);
    setIsUpdated(false);
  };
  const onEditConfirmClick = () => {
    setEditMode(false);
    setUpdatedRawData(JSON.parse(updatedRawDataStr));
    setIsUpdated(JSON.stringify(lineItem.rawData) !== updatedRawDataStr);
  };
  const onSave = () => {
    editLineItem({
      variables: {
        input: {
          importFileLineItemId: lineItem.id,
          rawData: updatedRawData,
        },
      },
    });
    handleClose();
  };

  const canUpdate = (
    permissions.includes('write:bulk_import')
    && lineItem.state === ImportFileLineItemStates.FAILED
    && !disabledEditLineItemButton
  );

  return (
    <Dialog open onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>{t('bulkImport:lineItemModal.title')}</DialogTitle>
      <DialogContent>
        {lineItem.state === ImportFileLineItemStates.FAILED && lineItem.outputErrors && lineItem.outputErrors.length > 0 && (
          <Box mb={2}>
            <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
              { t('bulkImport:lineItemModal.errorTitle') }
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkImport:lineItemModal.errorCode')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='overline'>{t('bulkImport:lineItemModal.errorField')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='overline'>{t('bulkImport:lineItemModal.errorDescription')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItem.outputErrors.map((e, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">{e.code}</TableCell>
                      <TableCell>{e.field}</TableCell>
                      <TableCell>{e.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Alert>
          </Box>
        )}
        {!editMode && (
          <Box mb={2} sx={{ background: colors.noteBackground, p: 1, borderRadius: '8px' }}>
            {canUpdate && (
              <IconButton sx={{ float: 'right', color: colors.black }} onClick={onEditClick}>
                <EditIcon />
              </IconButton>
            )}
            <pre>{JSON.stringify(updatedRawData, null, 2) }</pre>
          </Box>
        )}
        {editMode && (
          <Box mb={2} sx={{ p: 1, borderRadius: '8px' }}>
            <Box sx={{ float: 'right', color: colors.black }}>
              <IconButton sx={{ float: 'right', color: colors.black }} onClick={onEditConfirmClick} disabled={!isValidJson(updatedRawDataStr)}>
                <CheckIcon />
              </IconButton>
              <IconButton sx={{ float: 'right', color: colors.black }} onClick={onEditCancelClick}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              value={updatedRawDataStr}
              onChange={(e) => setUpdatedRawDataStr(e.target.value)}
              multiline
            />
          </Box>
        )}
        {isUpdated && (
          <Button fullWidth variant='contained' onClick={onSave} disabled={editMode}>
            { t('bulkImport:lineItemModal.save') }
          </Button>
        )}
        {!isUpdated && canUpdate && (
          <Button fullWidth variant='contained' onClick={onSave} disabled={editMode}>
            { t('bulkImport:lineItemModal.addBackToQueue') }
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LineItemModal;
