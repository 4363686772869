import { ClientGroupMemberAccessType, Relationship } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { generateClientNameString } from '../../../util';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { EditMemberStep } from './steps/editMemberStep';

type Step = 'EDIT_MEMBER';

const steps:Record<Step, StepComponent> = {
  EDIT_MEMBER: EditMemberStep,
};

interface WizardContext {
  clientGroupId: string,
  primaryUserName: string,
  memberUserId: string,
  memberUserName: string,
}

interface WizardState {
  relationType: string
  accessType: ClientGroupMemberAccessType
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

export interface AddHouseholdMemberWizardProps {
  clientGroupId: string,
  primaryUserName: string,
  relationship: Relationship,
}

export function EditHouseholdMemberWizard({
  clientGroupId, primaryUserName, relationship, handleClose,
}: AddHouseholdMemberWizardProps & WizardControlProps) {
  const { t } = useTranslation('editHouseholdMemberWizard');

  const initial:WizardState = {
    relationType: relationship.type,
    accessType: relationship.accessType ?? 'NO_ACCESS',
  };

  return (
    <WizardBase
      title={t('title')}
      context={{
        clientGroupId,
        primaryUserName,
        memberUserId: relationship.user.id,
        memberUserName: generateClientNameString(relationship.user),
      }}
      steps={steps}
      firstStep={'EDIT_MEMBER'}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
