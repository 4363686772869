import { Button } from '@mui/material';

const diameter = 40;

export const RoundButton = (props:any) => <Button
    variant='text'
    sx={{
      borderRadius: diameter / 2, width: diameter, minWidth: diameter, height: diameter,
    }}
    {...props}
  >
    {props.children}
  </Button>;
