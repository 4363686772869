import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Icon, Typography } from 'ovComponents/1-primative';
import { Account, ApplyForGovFunds } from 'interfaces';
import { Card, CardContent, IconButton } from 'ovComponents/2-component';
import { GoalLinkingReview } from '../../goalLinking';

interface RespAdultReviewItemProps {
  account: Account,
  onDeleteAccount: (account?: Account) => void,
}
export const RespAdultReviewItem = ({ account, onDeleteAccount }: RespAdultReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ pl: 0.9 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant='bodyLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.respAdult')}</Typography>
          {
            account.id ? undefined : <IconButton onClick={() => onDeleteAccount()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.basicCanadaEducationSavingsGrant')}</Typography>
          <Typography variant='bodyMedium'>{account.applyForGovFunds?.includes(ApplyForGovFunds.BASIC_CESG) ? 'Yes' : 'No'}</Typography>
        </Box>
        <Box sx={{ mt: 1, mb: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.canadaLearningBond')}</Typography>
          <Typography variant='bodyMedium'>{account.applyForGovFunds?.includes(ApplyForGovFunds.CLB) ? 'Yes' : 'No'}</Typography>
        </Box>
        <Box sx={{ mt: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <GoalLinkingReview account={account} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default RespAdultReviewItem;
