import {
  Typography, Menu, ListItem, ListItemButton, Grid, ListItemText, Skeleton,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import {
  useContext, useState, useMemo, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import NavOrganizationList from '../lists/navOrganizationList';
import OrganizationLogo from './organizationLogo';

export const FETCH_ORGANIZATIONS = gql`
  query fetchOrganizations($input: FetchOrganizationsInput!) {
    fetchOrganizations(input: $input) {
      organizations {
        id
        name
        parent {
          id
        }
      }
    }
  }
`;

const NavOrganizationSelect = ({
  onChange, value, textColor, backgroundColor,
}: { onChange: (event: any) => void, value: string, textColor: string, backgroundColor: string }) => {
  const { permissions } = usePermissions();
  const { userContext, activeOrganization, setOrganizationIds } = useContext(UserContext);
  const { loading, error, data } = useQuery(FETCH_ORGANIZATIONS, {
    variables: {
      input: {
        filter: {},
        pagination: { perPage: 1000, sortField: 'name' },
      },
    },
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    if (loading) return;
    if (oneOrgOnly) {
      navigateHome();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (data?.fetchOrganizations?.organizations) {
      setOrganizationIds(data.fetchOrganizations.organizations.map((o: { id: string }) => o.id));
    }
  }, [data, setOrganizationIds]);

  const oneOrgOnly: boolean = useMemo(() => !loading && data?.fetchOrganizations?.organizations.length === 1, [loading, data]);

  const handleChange = (event: any, nodeId: any) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) return;
    handleClose();
    onChange(nodeId);
  };

  if (error) (<Typography>Error</Typography>);

  const MAX_LOGO_HEIGHT_PX = 24;

  return (
    <>
      {permissions.includes('read:organizations') ? (
        <>
          <ListItem sx={{ backgroundColor }} disablePadding>
            <ListItemButton onClick={handleClick} sx={{ padding: '18px 24px' }}>
              { loading
                ? <Skeleton variant='rectangular' height={`${MAX_LOGO_HEIGHT_PX}px`} width='100%' />
                : (<Grid container justifyContent='space-between'>
                  {permissions.includes('read:organization_files_basic') ? (
                    <Grid item>
                      <OrganizationLogo textColor={textColor} />
                    </Grid>
                  ) : <>
                    {activeOrganization.theme?.logo ? (
                      <img src={activeOrganization.theme?.logo} alt={activeOrganization.name} style={{ maxHeight: `${MAX_LOGO_HEIGHT_PX}px` }} />
                    ) : (
                      <ListItemText primary={activeOrganization.name} sx={{ color: textColor }} />
                    )}
                  </>}
                  {!oneOrgOnly ? (
                    <Grid item style={{ maxHeight: `${MAX_LOGO_HEIGHT_PX}px` }}>
                      {open ? <ExpandLess sx={{ color: textColor }} /> : <ExpandMore sx={{ color: textColor }} />}
                    </Grid>
                  ) : <></>}
                </Grid>)
              }
            </ListItemButton>
          </ListItem>
          {!oneOrgOnly ? (
            <Menu
              id='org-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                '.MuiPaper-root': {
                  width: '344px', left: '0 !important', maxWidth: '344px', backgroundColor, color: textColor,
                },
              }}
            >
              {
                <NavOrganizationList
                  treeItems={data?.fetchOrganizations?.organizations ?? []}
                  handleChange={(event, nodeId) => handleChange(event, nodeId)}
                  selected={activeOrganization.id}
                  parentOrg={userContext.organization?.id}
                />
              }
            </Menu>
          ) : <></>}
        </>
      ) : (<></>)}
    </>
  );
};

export default NavOrganizationSelect;
