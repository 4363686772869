import { gql, useMutation } from '@apollo/client';
import {
  Box, Typography, Grid, Button, Paper, CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { useGlobalToast } from '../../providers/globalToastProvider';
import TaxLossHarvestsTable from './components/taxLossHarvestsTable';

export const TRIGGER_TLH_MUTATION = gql`
  mutation triggerTLHAnalysis($organizationId: ObjectID) {
    triggerTLHAnalysis(input: { organizationId: $organizationId }) {
      job { id }
    }
  }
`;

const TaxLossHarvesting = () => {
  const { t } = useTranslation('taxLossHarvesting');
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const [triggerTaxLossHarvest, { loading: triggerPending }] = useMutation(
    TRIGGER_TLH_MUTATION,
    {
      variables: {
        organizationId: activeOrganization.id,
      },
      onCompleted(data) {
        if (data?.triggerTLHAnalysis?.job?.id) {
          showToast({ severity: 'info', message: t('newTLHJobStarted') });
          setLastTriggerId(data.triggerTLHAnalysis.job.id);
        } else {
          showToast({ severity: 'error', message: t('newTLHJobFailed') });
        }
      },
    },
  );
  const [lastTriggerId, setLastTriggerId] = useState('');

  return (
    <Box>
      <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
        <Grid item>
          { triggerPending && (
              <CircularProgress size={32}/>
          )
          }
        </Grid>
        <Grid item>
          <Button type='button' variant='contained'
            disabled={triggerPending || !permissions.includes('write:tax_loss_harvesting_event')}
            onClick={ () => triggerTaxLossHarvest() }
          >
            {t('taxLossHarvesting:startTaxLossHarvesting')}
          </Button>
          { !permissions.includes('write:tax_loss_harvesting_event') && (
            <Box>
              <Typography variant="caption">{t('taxLossHarvesting:youDontHavePermission')}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Paper sx={{ width: '100%', marginTop: 2 }}>
        <TaxLossHarvestsTable refetchWhenChanged={lastTriggerId}/>
      </Paper>
    </Box>
  );
};

export default TaxLossHarvesting;
