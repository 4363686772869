/* eslint-disable implicit-arrow-linebreak */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TextField,
  Typography,
  TableRow,
  TableCell,
  Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FormTypes } from 'interfaces';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import DocumentViewerModal from './documents/documentViewer';
import DocumentUpload from './documentUpload';
import { NoPermissionAlert } from '../../../components/misc/noPermissionAlert';
import { FileDocumentTypes } from '../../../interfaces/fileDocument';
import {
  getRelatedObjectTypeAndId, getDocumentName, CombinedDocumentAndAgreement, RelatedTo, generateColor, objectTypeSortingWeight, FETCH_DOCUMENTS,
} from './documents';

export const FETCH_ALL_CLIENT_GROUP_AGREEMENTS = gql`
  query fetchClientGroupAgreements($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        allLatestFormAgreements {
          id
          subAccount { id account { user { id firstName lastName } } }
          account { id user { id firstName lastName } }
          goal { id user { id firstName lastName } }
          user { id firstName lastName entityName }
          scheduledTransfer { id state frequency subAccount { id account { type } account { user { id firstName lastName } } } }
          displayName
          type
          signedAt
          signedUrl
          templateUrl
        }
        allIncompleteFormAgreements {
          subAccount { id account { user { id firstName lastName } } }
          account { id user { id firstName lastName } }
          goal { id user { id firstName lastName } }
          user { id firstName lastName entityName }
          scheduledTransfer { id state frequency subAccount { id account { type } account { user { id firstName lastName } } } }
          translatedDisplayName { en }
          type
          changeType
          templateUrl
        }
      }
    }
  }
`;

type CombinedHouseholdDocumentAndAgreement = CombinedDocumentAndAgreement & {
  clientId: string,
  clientFirstName?: string,
  clientLastName?: string,
};

const getRelatedClient = (object: any): {
  clientId?: string,
  clientFirstName?: string,
  clientLastName?: string,
} => {
  if (object.user?.id) return { clientId: object.user.id, clientFirstName: object.user.firstName, clientLastName: object.user.lastName };
  if (object.account?.id) return { clientId: object.account.user.id, clientFirstName: object.account.user.firstName, clientLastName: object.account.user.lastName };
  if (object.subAccount?.id) return { clientId: object.subAccount.account.user.id, clientFirstName: object.subAccount.account.user.firstName, clientLastName: object.subAccount.account.user.lastName };
  if (object.goal?.id) return { clientId: object.goal.user.id, clientFirstName: object.goal.user.firstName, clientLastName: object.goal.user.lastName };
  if (object.scheduledTransfer?.id && object.scheduledTransfer.subAccount) {
    return {
      clientId: object.scheduledTransfer.subAccount.account.user.id,
      clientFirstName: object.scheduledTransfer.subAccount.account.user.firstName,
      clientLastName: object.scheduledTransfer.subAccount.account.user.lastName,
    };
  }
  return {};
};

const getClientName = (doc: any) => (doc?.clientFirstName !== null ? `${doc?.clientFirstName} ${doc?.clientLastName}` : doc?.user?.entityName);

const combineDocsAndAgreements = (documents: any, agreements: any): CombinedHouseholdDocumentAndAgreement[] => {
  const combined: CombinedHouseholdDocumentAndAgreement[] = [];
  if (documents?.fetchFileDocuments) {
    combined.push(...documents.fetchFileDocuments.fileDocuments.map((d: any) => ({
      ...d,
      id: d.id,
      type: d.type,
      clientId: d.user.id,
      clientFirstName: d.user?.firstName,
      clientLastName: d.user?.lastName,
      name: d.name,
      fileName: d.fileName,
      mediaType: d.mediaType,
      uploadedAt: d.uploadedAt,
      updatedAt: d.updatedAt,
      relatedObjectType: d.objectType,
      relatedObjectId: d.objectId,
      creatorId: d.creator?.id,
      state: 'SAVED',
      isFileDocument: true,
    })));
  }
  if (agreements.fetchClientGroup) {
    combined.push(...agreements.fetchClientGroup.clientGroup.allLatestFormAgreements.map((a: any) => ({
      ...a,
      id: a.id,
      displayName: a.displayName,
      type: a.type,
      ...getRelatedClient(a),
      signedAt: a.signedAt,
      signedUrl: a.signedUrl,
      templateUrl: a.templateUrl,
      ...getRelatedObjectTypeAndId(a),
      state: 'SIGNED',
    })));
    combined.push(...agreements.fetchClientGroup.clientGroup.allIncompleteFormAgreements.map((a: any) => ({
      ...a,
      displayName: a.translatedDisplayName?.en,
      type: a.type,
      ...getRelatedClient(a),
      changeType: a.changeType,
      templateUrl: a.templateUrl,
      ...getRelatedObjectTypeAndId(a),
      state: 'PENDING',
    })));
  }

  return combined;
};

const HouseholdDocuments = ({
  subtitle,
  userIds,
  clientGroupId,
}: {
  subtitle?: string,
  userIds: string[],
  clientGroupId: string,
}) => {
  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const [searchByName, setSearchByName] = usePageState('', 'docName');
  const [searchByType, setSearchByType] = usePageState('any', 'docType');
  const [searchByState, setSearchByState] = usePageState('any', 'docState');
  const [documentsCombined, setDocumentsCombined] = useState<CombinedHouseholdDocumentAndAgreement[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<CombinedHouseholdDocumentAndAgreement[]>([]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<number | null>(null);

  const { data: documents, loading: docsLoading } = useQuery(FETCH_DOCUMENTS, {
    variables: {
      filter:
        { userIds, types: ['OTHER', 'BANKING'] },
    },
  });
  const { data: agreements, loading: agreementsLoading } = useQuery(FETCH_ALL_CLIENT_GROUP_AGREEMENTS, {
    variables: {
      id: clientGroupId,
    },
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  useEffect(() => {
    if (docsLoading || agreementsLoading || !documents || !agreements) return;
    setDocumentsCombined(combineDocsAndAgreements(documents, agreements));
  }, [documents, agreements, docsLoading, agreementsLoading]);

  useEffect(() => {
    const filtered = documentsCombined.filter((doc: any) => {
      if (searchByName) {
        if (
          !doc.translatedDisplayName?.en?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.displayName?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.name?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.fileName?.toLowerCase().includes(searchByName.toLowerCase())
        ) { return false; }
      }
      if (searchByType !== 'any') {
        if (doc.type !== searchByType) return false;
      }
      if (searchByState !== 'any') {
        if (doc.state !== searchByState) return false;
      }
      return true;
    });

    filtered.sort((a: any, b: any) =>
      /* sort by document relation: client, account, subaccount */
      objectTypeSortingWeight(b.relatedObjectType) - objectTypeSortingWeight(a.relatedObjectType)
      /* sort alphabetically by document name */
      + Math.sign(getDocumentName(a).localeCompare(getDocumentName(b))));
    setDocumentsFiltered(filtered);
  }, [documentsCombined, searchByName, searchByType, searchByState]);

  if (!permissions.includes('read:files')) return <NoPermissionAlert missing='read:files' />;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item>
          <Typography variant='h6'>{t('documents.title')}</Typography>
          {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
        </Grid>
        <Grid item>
          <Button variant='outlined' color='primary' size='small' onClick={() => setUploadModalOpen(true)} disabled={!permissions.includes('write:files')}>
            {t('documents.addDocument')}
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} sx={{ paddingTop: 2 }} spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={t('documents.searchByName')}
            value={searchByName}
            onChange={(e: any) => setSearchByName(e.target.value)}
            placeholder={t('documents.typeToSearch')}
            sx={{ width: '100%' }}
            size="small"
          >
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField select
            label={t('documents.docType')}
            value={searchByType}
            onChange={(e: any) => setSearchByType(e.target.value)}
            sx={{ width: '100%' }}
            fullWidth
            size="small"
          >
            <MenuItem key='any' value={'any'}>{t('documents.any')}</MenuItem>
            {Object.values(FileDocumentTypes).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            {Object.values(FormTypes).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField select
            label={t('documents.docState')}
            value={searchByState}
            onChange={(e: any) => setSearchByState(e.target.value)}
            sx={{ width: '100%' }}
            fullWidth
            size="small"
          >
            <MenuItem value={'any'}>{t('documents.any')}</MenuItem>
            <MenuItem value={'PENDING'}>{t('documents.PENDING')}</MenuItem>
            <MenuItem value={'SAVED'}>{t('documents.SAVED')}</MenuItem>
            <MenuItem value={'SIGNED'}>{t('documents.SIGNED')}</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      {docsLoading || agreementsLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      )
        : <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='overline'>{t('documents.name')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('details.clientName')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.associatedTo')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.dateUpdated')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.state')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsFiltered.map((doc: any, index: number) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedDocumentIndex(index);
                }}
              >
                <TableCell sx={{ wordBreak: 'break-all' }}>
                  {getDocumentName(doc)}
                  {(doc.relatedObjectType === 'SCHEDULED_TRANSFER') && doc?.scheduledTransfer?.state === 'CANCELED' ? ` [${t('canceled')}]` : null}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-all' }}>
                  {getClientName(doc)}
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-all' }}>
                  <RelatedTo doc={doc} relatedObjectType={doc.relatedObjectType} relatedObjectId={doc.relatedObjectId} />
                </TableCell>
                <TableCell>
                  <DateTime variant="subtitle2" date={doc.updatedAt || doc.signedAt || doc.uploadedAt} />
                </TableCell>
                <TableCell>
                  <Chip label={t(`documents.${doc.state}`)} color={generateColor(doc.state)}></Chip>
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      }
      {selectedDocumentIndex !== null
        && <DocumentViewerModal
          onModalClose={() => setSelectedDocumentIndex(null)}
          afterSigned={() => setSelectedDocumentIndex(null)}
          title={getDocumentName(documentsFiltered[selectedDocumentIndex])}
          doc={documentsFiltered[selectedDocumentIndex]}
          userId={documentsFiltered[selectedDocumentIndex].clientId}
        />
      }
      {
        uploadModalOpen
        && <DocumentUpload
          forUserId={userIds[0]}
          handleClose={() => { setUploadModalOpen(false); }}
          clientGroupId={clientGroupId}
        />
      }
    </Box>
  );
};

export default HouseholdDocuments;
