import {
  TextField, ListItem, FormGroup, FormControlLabel, Switch,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatableString from '../../../components/inputs/translatableString';
import FormModal from '../../../components/modals/formModal';
import { Theme } from '../../../interfaces';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import ThemeIconSelect from '../../../components/inputs/themeIconSelect';

const UPDATE_THEME = gql`
mutation updateTheme($input: UpdateThemeInput!) {
  updateTheme(input: $input) {
    theme {
      id
    }
  }
}
`;

const EditTheme = ({ afterUpdate, themeToUpdate, open }: { afterUpdate: () => void, themeToUpdate: Theme, open: boolean }) => {
  const { t } = useTranslation(['buildModels']);
  const { showToast } = useGlobalToast();
  const [theme, setTheme] = useState(themeToUpdate);
  const [initialState] = useState(themeToUpdate);

  useEffect(() => {
    setTheme(themeToUpdate);
  }, [open, themeToUpdate]);

  const [updateTheme, { loading }] = useMutation(UPDATE_THEME, {
    variables: {
      input: {
        themeId: theme.id,
        key: theme.key,
        translatedName: { en: theme.translatedName?.en, fr: theme.translatedName?.fr },
        translatedDescription: { en: theme.translatedDescription?.en, fr: theme.translatedDescription?.fr },
        translatedShortDescription: { en: theme.translatedShortDescription?.en, fr: theme.translatedShortDescription?.fr },
        core: theme.core,
        iconKey: theme.iconKey || 'DEFAULT',
      },
      errorMessage: t('buildModels:themeModal.errorMessages.update'),
    },
  });

  const close = () => {
    setTheme(initialState);
    afterUpdate();
  };

  const create = async (event: any) => {
    event.preventDefault();
    const response = await updateTheme();

    if (response.data) {
      showToast({ severity: 'info', message: t('buildModels:themeModal.successMessages.update') });
    }

    close();
  };

  const validation = /^.*\S.*$/;

  const isValidModal = validation.test(theme?.key ?? '')
    && validation.test(theme?.translatedName?.en ?? '')
    && validation.test(theme?.translatedShortDescription?.en ?? '')
    && validation.test(theme?.translatedDescription?.en ?? '');

  return (
  <FormModal
    open={open}
    loading={loading}
    state={theme}
    initialState={initialState}
    title={t('themeModal.updateTitle')}
    onSubmit={create}
    formButton={t('update')}
    handleClose={() => close()}
    disabled={!isValidModal}
  >
    <ListItem>
      <TextField label={t('themeModal.key')} fullWidth value={theme.key} onChange={(e) => setTheme({ ...theme, key: e.target.value })} id='key' />
    </ListItem>
    <ListItem>
      <TranslatableString label={t('themeModal.name')} value={theme.translatedName || {}} onChange={(e) => setTheme({ ...theme, translatedName: e })} />
    </ListItem>
    <ListItem>
      <TranslatableString label={t('themeModal.shortDescription')} value={theme.translatedShortDescription || {}} onChange={(e) => setTheme({ ...theme, translatedShortDescription: e })} rows={ 2 } />
    </ListItem>
    <ListItem>
      <TranslatableString label={t('themeModal.description')} value={theme.translatedDescription || {}} onChange={(e) => setTheme({ ...theme, translatedDescription: e })} rows={ 3 } />
    </ListItem>
    <ListItem>
      <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <FormControlLabel
          control={<Switch
            checked={theme?.core}
            onChange={async (e: any) => setTheme({ ...theme, core: e.target.checked })}
          />}
          label={t('themeModal.defaultRecommendation')}
        />
      </FormGroup>
    </ListItem>
    <ListItem>
        <ThemeIconSelect
          onChange={(event: any) => {
            setTheme({ ...theme, iconKey: event.target.value });
          }}
          value={theme.iconKey || 'DEFAULT'}
          label={t('themeModal.iconKey')}
        />
      </ListItem>
  </FormModal>
  );
};

export default EditTheme;
