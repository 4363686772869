import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination, TextField, InputAdornment, Button,
} from '@mui/material';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import NewOrganizationUser from './newOrganizationUser';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditOrganizationUser from './editOrganizationUser';
import { usePageState } from '../../../util/usePageState';
import { AuthAudienceTypes, mapOrganizationUserLoginStatus, OrganizationUser } from '../../../interfaces/organizationUser';
import RoleSelect from '../../../components/inputs/roleSelect';
import SendOrganizationUserInvitations from './sendOrganizationUserInvitations';
import { useLocalization } from '../../../util/useLocalization';

type Users = Required<{ id: string }>;
export type SearchResults = { users: Users[]; count: number };

const MAX_SEARCH_RESULTS = 50;
export const SEARCH_USERS = (permissions: string[]) => gql`
  query searchOrgUsers($query: String!, $organizationId: ObjectID!, $roleIds: [ObjectID!], $authAudience: AuthAudienceTypes!, $offSet: Int!) {
    fetchOrganizationUsers(input: {
      filter: { searchText: $query, organizationId: $organizationId, roleIds: $roleIds, authAudience: $authAudience }
      pagination: { perPage:${MAX_SEARCH_RESULTS}, sortDesc: true, sortField: "firstName", offSet: $offSet },
    }){
      totalCount
      organizationUsers {
        id
        firstName
        lastName
        language
        email
        phone
        avatar
        referenceId
        lastLoggedInAt
        auth0invitationId
        auth0invitationExpiresAt
        authConnectionType
        mfaEnrollmentId
        role {
          id
          translatedName { en }
          defaultAuthenticationConnection
        }
        accessType
        organization {
          id
          name
          enableMultiFactorAuthentication
          defaultAuthenticationConnection
        }
      }
    }
  }
`;

const FETCH_ORG_PENDING_INVITATIONS = gql`
  query fetchOrgPendingInvitations($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        id
        pendingInvitationsCount
      }
    }
  }
`;

const OrganizationUsers = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 50;
  const [selectedOrganizationUser, setSelectedOrganizationUser] = useState<OrganizationUser>();
  const [roleId, setRoleId] = usePageState('', 'roleId');
  const [searchValue, setSearchValue] = useState('');
  const [foundUsers, setFoundUsers] = useState<SearchResults>();
  const [sendInvitesDialogOpen, setSendInvitesDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const { localizedDateTime } = useLocalization();
  const [searchUsers, {
    data, error, refetch, loading,
  }] = useLazyQuery(SEARCH_USERS(permissions), {
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    const searchFn = (query: string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
      searchUsers({
        variables: {
          query,
          organizationId: activeOrganization.id,
          authAudience: AuthAudienceTypes.ONEHUB, // exclude Client Auth profiles
          ...(roleId ? { roleIds: roleId } : {}),
          offSet: (page - 1) * pageSize,
        },
        onCompleted: (searchData) => {
          resolve({
            users: searchData.fetchOrganizationUsers.organizationUsers,
            count: Number(searchData.fetchOrganizationUsers.totalCount),
          });
        },
        onError: () => {
          resolve({
            users: [],
            count: 0,
          });
        },
      });
    });
    searchFn(searchValue).then((found) => {
      setFoundUsers(found);
    });
  }, [activeOrganization.id, searchUsers, searchValue, roleId, page, data]);

  const mapStatusText = (user: OrganizationUser) => {
    if (user.referenceId && user.lastLoggedInAt) {
      return localizedDateTime(user.lastLoggedInAt);
    }
    const status = mapOrganizationUserLoginStatus(user);
    return t(`userTable.status.${status}`);
  };

  const pendingInvitationsQuery = useQuery(FETCH_ORG_PENDING_INVITATIONS, {
    variables: {
      organizationId: activeOrganization.id,
    },
  });

  const pendingInvitationsCount = pendingInvitationsQuery.data?.fetchOrganization?.organization?.pendingInvitationsCount ?? 0;
  const canSendInvites = (
    permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users')
  ) && pendingInvitationsCount > 0;

  const handleSendInviteClick = () => {
    setSendInvitesDialogOpen(true);
  };

  const afterUserCreate = () => {
    refetch();
    pendingInvitationsQuery.refetch();
  };

  if (error) <Typography>Error</Typography>;

  return (
    <Box sx={{ m: '-24px -24px' }}>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item xs={4}>
          <TextField
            label={t('userTable.userSearch')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size='small'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <RoleSelect
            value={roleId}
            label={t('userModal.role')}
            includeEmpty
            size='small'
            onChange={(event: any) => setRoleId(event.target.value)}
            organizationId={activeOrganization.id ?? ''}
            disabled={activeOrganization.id === ''}
          />
        </Grid>
        <Grid item xs={5}>
          {canSendInvites && <Button variant='text' onClick={handleSendInviteClick}>{t('userTable.inviteUsersButton')}</Button>}
          {permissions.includes('write:organization_users') && <NewOrganizationUser afterCreate={afterUserCreate} defaultOrg={activeOrganization.id} />}
        </Grid>
      </Grid>
      <>
      {loading ? (
         <Box sx={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
         }}>
          <CircularProgress sx={{ m: 5 }}/>
        </Box>
      ) : (
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='overline'>{t('userTable.name')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='overline'>{t('userTable.email')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='overline'>{t('userTable.role')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='overline'>{t('userTable.accessType')}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='overline'>{t('userTable.organization')}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='overline'>{t('userTable.status.header')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {foundUsers?.users?.map((user: OrganizationUser) => (
            <TableRow
              key={user.id}
              sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {
                setSelectedOrganizationUser(user);
                setUpdateDialogOpen(true);
              }}
            >
              <TableCell component='th' scope='row'>
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user?.role?.translatedName?.en}</TableCell>
              <TableCell>{user.accessType}</TableCell>
              <TableCell align='right'>{user?.organization?.name}</TableCell>
              <TableCell align='right'>{mapStatusText(user)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      )}
      </>
      <Pagination
        count={Math.ceil((foundUsers?.count ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      {selectedOrganizationUser && (
        <EditOrganizationUser
          open={updateDialogOpen}
          organizationUserToUpdate={selectedOrganizationUser}
          afterUpdate={() => {
            setUpdateDialogOpen(false);
            refetch();
            pendingInvitationsQuery.refetch();
          }}
          handleClose={() => setUpdateDialogOpen(false)}
        />
      )}
      {canSendInvites && sendInvitesDialogOpen && (
        <SendOrganizationUserInvitations
          open={sendInvitesDialogOpen}
          pendingUsersCount={pendingInvitationsCount}
          onComplete={() => {
            setSendInvitesDialogOpen(false);
            refetch();
            pendingInvitationsQuery.refetch();
          }}
          handleClose={() => setSendInvitesDialogOpen(false)}
        />
      )}
    </Box>
  );
};

export default OrganizationUsers;
