import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';

const ClientDetailBoxAlert = ({ action, content }: { action?: JSX.Element, content?: string }) => {
  const { t } = useTranslation(['client']);
  return (
    <Alert
      variant="outlined"
      sx={{ backgroundColor: '#FFF2CC', marginBottom: '10px', borderColor: '#D8A100' }}
      iconMapping={{
        success: <ReportProblemRoundedIcon sx={{ color: '#D8A100' }} />,
      }}
      action={action}
    >
      <Markup content={content ?? t('client:details.errorMessages.profileIncomplete')} />
    </Alert>
  );
};

export default ClientDetailBoxAlert;
