import { CircularProgress } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { isNull } from 'lodash/fp';
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { ViewPageVisual } from './viewPage.visual';
import { ClientPageInterface } from '../pageConfiguration/pageConfiguration';
import { PAGE_CONFIGURATION } from '../pageConfiguration/pageConfiguration.queries';
import { Box } from '../../1-primative';
import { ClientContext } from '../../../pages/client';
import { Organization, WorkflowCompletion } from '../../../interfaces';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { FETCH_USER_INCOMPLETE_DOCUMENTS, GET_ACTIVE_WORKFLOWS } from './viewPage.queries';

const FETCH_PAGE_CONFIGURATION = gql`
  query fetchPageConfiguration($pageConfigurationId: ObjectID!) {
    fetchPageConfiguration (pageConfigurationId: $pageConfigurationId) {
      ${PAGE_CONFIGURATION}
    }
  }
`;

const FETCH_BASE_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        completedAt
        statistics {
          marketValueCents
        }
        organization {
          id
          availableFeatureFlags
        }
      }
    }
  }
`;

interface ActionContextInterface {
  activeWorkflows: WorkflowCompletion[],
  unsignedDocuments: boolean,
  refetch: () => void,
  refreshWidgets?: () => void,
}

export const ActionContext = createContext<ActionContextInterface>({
  activeWorkflows: [],
  unsignedDocuments: false,
  refetch: () => {},
  refreshWidgets: () => {},
});

export const ViewPage = ({ pageConfiguration }: { pageConfiguration?: any }) => {
  const { permissions } = usePermissions();
  const [page, setPage] = useState<ClientPageInterface | undefined>(pageConfiguration);
  const { id, objectId, userId: paramsUserId } = useParams<{ id: string, objectId?: string, userId?: string }>();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const [refreshIndex, setRefreshIndex] = useState(0);
  const refreshWidgets = () => {
    setRefreshIndex((prev) => (prev + 1));
  };
  const { data: workflowsData, refetch } = useQuery(GET_ACTIVE_WORKFLOWS, {
    variables: { userId },
    skip: !permissions.includes('read:workflow_completion') || !userId,
    fetchPolicy: 'no-cache',
  });

  const { data: incompleteData, refetch: incompleteRefetch } = useQuery(FETCH_USER_INCOMPLETE_DOCUMENTS, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: !userId,
  });

  const { data, loading } = useQuery(FETCH_PAGE_CONFIGURATION, {
    variables: { pageConfigurationId: id },
    skip: pageConfiguration,
  });

  const { data: userData, loading: userLoading } = useQuery(FETCH_BASE_USER, {
    variables: { userId: objectId || userId },
    skip: !objectId && !userId,
  });

  useEffect(() => {
    if (data) {
      setPage(data.fetchPageConfiguration.pageConfiguration);
    } else if (pageConfiguration) setPage(pageConfiguration);
  }, [data, pageConfiguration]);

  if (loading || userLoading || !page) {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ActionContext.Provider value={{
      refetch: () => {
        refetch();
        incompleteRefetch();
      },
      refreshWidgets: () => refreshWidgets(),
      activeWorkflows: workflowsData?.fetchUser?.user?.activeWorkflowCompletions ?? [],
      unsignedDocuments: (!isNull(incompleteData?.fetchUser?.user?.completedAt) && (incompleteData?.fetchUser?.user?.allIncompleteFormAgreements?.length > 0)),
    }}>
      <ClientContext.Provider value={{
        orgSettings: userData?.fetchUser?.user?.organization as Organization,
        totalMarketValueCents: userData?.fetchUser?.user?.statistics?.marketValueCents ?? 0,
      }}>
        <ViewPageVisual key={refreshIndex} page={page} />
      </ClientContext.Provider>
    </ActionContext.Provider>
  );
};
