import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';

export const TypeSelect = ({
  options, onNext, data, onChangeSetData,
}: {
  options: any, onNext: any, data: any, onChangeSetData: Dispatch<SetStateAction<any>>
}) => {
  const { t } = useTranslation('client');
  const typeAttribute = options?.typeAttribute || 'type';
  const submit = () => {
    if (data[typeAttribute]) {
      onNext();
    }
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <SelectionTile
        testId='entity-type-select'
        onChange={(e: any) => onChangeSetData((prev: any) => ({ ...prev, [typeAttribute]: e.target.value }))}
        value={data[typeAttribute]}
        options={options?.options?.map((option: any) => (
          { value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description) }
        ))}
      />

      <Box display='flex' justifyContent='end'>
        <Button dataTestId='type-select-continue-button' label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default TypeSelect;
