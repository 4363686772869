import {
  Box, CircularProgress, Card, CardContent,
  Pagination,
  Menu,
  ListItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ObjectType } from 'providers/statsHooks';
import TuneIcon from '@mui/icons-material/Tune';
import { RoundButton } from 'components/inputs/roundButton';
import { colors } from 'ovComponents';
import { uniq } from 'lodash/fp';
import { useClientContext } from 'pages/client';
import { useHouseholdContext } from 'pages/household';
import GoalItem from './goalItem';
import { Goal, GoalStates } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';
import { CreateGoalWizardButton } from '../../wizards/createGoalWizard/button';

type SequenceAndId = { sequence: number, id: string };
export const FETCH_GOALS = (forUserId?: string, withMemberStats?: SequenceAndId[]) => gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        state
        targetAmountCents
        timeHorizon
        riskQuestion1
        subAccounts {
          state
          account {
            user {
              id
            }
          }
        }
        ${forUserId ? `statistics(forUserId: "${forUserId}")` : 'statistics'} {
          marketValueCents
          simpleReturnPercent
          simpleReturnAmount
        }
        ${(withMemberStats || []).map(({ id, sequence }) => `
          user${sequence}statistics:statistics(forUserId:"${id}") {
            marketValueCents
            simpleReturnPercent
            simpleReturnAmount
          }
        `)}
        householdClientGroup {
          id
          name
        }
      }
      totalCount
    }
  }
`;

const PAGE_SIZE = 10;

const GoalsSummary = (
  { objectType, objectId, onlyUserId }:
  { objectType: ObjectType.USER | ObjectType.CLIENT_GROUP, objectId: string, onlyUserId?: string },
) => {
  const { t } = useTranslation(['client']);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = usePageState(1, 'goalsPage');
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>({ [GoalStates.ACTIVE]: true, [GoalStates.COMPLETED]: false });
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const forUserId = objectType === ObjectType.USER ? objectId : undefined;
  const withMemberStats:SequenceAndId[] | undefined = householdContext
    && Object.entries(householdContext.indexedMembers).map(([id, index]) => ({ id, sequence: index }));

  const { loading } = useQuery(FETCH_GOALS(forUserId, withMemberStats), {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          ...(objectType === ObjectType.USER && { userId: objectId }),
          ...(objectType === ObjectType.CLIENT_GROUP && { clientGroupId: objectId }),
          states: Object.keys(filterByState).filter((key) => filterByState[key]),
        },
        pagination: {
          perPage: 1000,
        },
      },
    },
    onCompleted: (e) => {
      setGoals([...e.fetchGoals.goals]);
      setTotalCount(e.fetchGoals.totalCount);
    },
  });

  const goalsSorted = goals.sort((a, b) => (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0));
  const goalsPaginated = goalsSorted.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByState]);

  const householdLinkedGoals = goals.filter((g) => g.householdClientGroup);
  const householdIds = uniq(householdLinkedGoals.map((g) => g.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTag = (goal:Goal) => {
    if (clientContext) {
      if (!goal.householdClientGroup) return undefined;

      return {
        sequenceNumber: indexedHouseholdIds[goal.householdClientGroup.id],
        text: `${goal.householdClientGroup.name} ${t('shared:household')}`,
      };
    }

    return undefined;
  };

  const GoalsFilterMenu = () => (
    <ListItem>
      <Stack>
        <Typography fontWeight={600} fontSize="20px">{t('goalsSummary.goalStateLabel')}</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={filterByState[GoalStates.ACTIVE]}/>}
            onClick={() => setFilterByState({ ...filterByState, [GoalStates.ACTIVE]: !filterByState[GoalStates.ACTIVE] })}
            label={t('goalsSummary.goalState.ACTIVE')} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={filterByState[GoalStates.COMPLETED]}/>}
            onClick={() => setFilterByState({ ...filterByState, [GoalStates.COMPLETED]: !filterByState[GoalStates.COMPLETED] })}
            label={t('goalsSummary.goalState.COMPLETED')} />
        </FormGroup>
      </Stack>
    </ListItem>
  );

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" marginBottom="9px">
          <Box display="flex" alignItems={'center'}>
            <Typography fontWeight={600} fontSize="20px">{t('goalsSummary.header')}</Typography>
          </Box>
          <div>
            <RoundButton color='secondary'
              style={{ backgroundColor: filterMenuAnchorEl && colors.neutral300 }}
              onClick={(e:React.MouseEvent<HTMLElement>) => setFilterMenuAnchorEl(filterMenuAnchorEl ? null : e.currentTarget)}
            >
              <TuneIcon/>
            </RoundButton>
            <Menu
              open={!!filterMenuAnchorEl}
              anchorEl={filterMenuAnchorEl}
              onClose={() => setFilterMenuAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <GoalsFilterMenu/>
            </Menu>
            { objectType === ObjectType.USER && (
              <CreateGoalWizardButton forObject='USER' forId={objectId}/>
            )}
            { objectType === ObjectType.CLIENT_GROUP && (
              <CreateGoalWizardButton forObject='CLIENT_GROUP' forId={objectId}/>
            )}
          </div>
        </Box>

        {loading
          ? <CircularProgress />
          : goalsPaginated.map((goal) => (
            <GoalItem
              key={goal.id}
              goal={goal}
              tag={getTag(goal)}
              onlyUserId={onlyUserId}
            />
          ))
        }

        {
          totalCount > PAGE_SIZE ? (
            <Box display='flex' flexDirection='column' alignItems='end'>
              <Pagination
                count={Math.ceil(totalCount / PAGE_SIZE)}
                sx={{ marginBottom: '10px' }}
                page={page}
                onChange={(e, newPage) => {
                  setPage(newPage);
                }}
              />
            </Box>
          ) : undefined
        }
      </CardContent>
    </Card >
  );
};

export default GoalsSummary;
