import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Button, CircularProgress, Paper, Typography, Grid,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PortfolioOptimizerCancelButton from './components/portfolioOptimizerCancelButton';
import PortfolioReportsTable from './components/portfolioReportsTable';
import { NoPermissionAlert } from '../../components/misc/noPermissionAlert';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import { CREATE_BULK_TRADE_RUN } from '../bulkTraders/components/bulkTradersTable';
import {
  OPTIMIZER_COMPLETED_STATES, OPTIMIZER_PROCESSING_STATES, PortfolioOptimizer as PortfolioOptimizerDocument, PortfolioOptimizerStates,
} from '../../interfaces/portfolioOptimizer';
import { isActionDisabled } from '../portfolioOptimizers/components/table';

export const FETCH_PORTFOLIO_OPTIMIZER = gql`
  query fetchPortfolioOptimizer($input: FetchPortfolioOptimizerInput!) {
    fetchPortfolioOptimizer(input: $input) {
      portfolioOptimizer {
        state
        numPortfolios
        expectedTotalSubTradeRequests
        subTradeRequestsCreated
      }
    }
  }
`;

export enum PortfolioOptimizerTransition {
  CANCEL = 'cancel',
  COMPLETE = 'complete',
}

const PortfolioOptimizer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const portfolioOptimizerId = params.id;
  const { t } = useTranslation('portfolioOptimizer');
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [isTransitioned, setIsTransitioned] = useState<boolean>(false);

  const { loading, data, stopPolling } = useQuery(FETCH_PORTFOLIO_OPTIMIZER, {
    pollInterval: 5000,
    variables: {
      input: {
        portfolioOptimizerId,
      },
    },
    skip: !permissions.includes('read:portfolio_optimizer'),
    fetchPolicy: 'no-cache',
  });

  const [newBulkTrader, { data: dataBulkTrader, loading: loadingBulkTrader }] = useMutation(CREATE_BULK_TRADE_RUN);

  useEffect(() => {
    if (dataBulkTrader) {
      navigate(`/bulkTrader/${dataBulkTrader.generateBulkTrades.bulkTradeRun.id}`);
    }
  }, [dataBulkTrader, navigate]);

  if (!permissions.includes('read:portfolio_optimizer')) return <NoPermissionAlert missing='read:portfolio_optimizer' />;

  const portfolioOptimizer: PortfolioOptimizerDocument = data?.fetchPortfolioOptimizer?.portfolioOptimizer ?? null;

  if (!portfolioOptimizer) {
    return <></>;
  }

  const isPortfolioOptimizerInProgress = OPTIMIZER_PROCESSING_STATES.includes(portfolioOptimizer.state)
    || (portfolioOptimizer.expectedTotalSubTradeRequests !== portfolioOptimizer.subTradeRequestsCreated);

  const isPortfolioOptimizerGenerated = OPTIMIZER_COMPLETED_STATES.includes(portfolioOptimizer.state)
    && (portfolioOptimizer.expectedTotalSubTradeRequests === portfolioOptimizer.subTradeRequestsCreated);

  if (!isPortfolioOptimizerInProgress) {
    stopPolling();
  }

  if (!portfolioOptimizerId) {
    return <></>;
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', overflowY: 'auto' }}>
        {(loading || isPortfolioOptimizerInProgress) && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{!isTransitioned && <CircularProgress sx={{ m: 15 }} data-testid='loading-spinner' />}</Box>

            {isPortfolioOptimizerInProgress && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <PortfolioOptimizerCancelButton portfolioOptimizerId={portfolioOptimizerId} setIsTransitioned={() => setIsTransitioned(true)} />
              </Box>
            )}
          </>
        )}

        {!loading && isPortfolioOptimizerGenerated && (
          <>
            <Grid container display='flex' justifyContent='space-between'>
              <Grid item display='flex' m={3} mb={0}>
                <Typography variant='h5'>{t('detail.summaryResults')}</Typography>
              </Grid>
              <Grid item m={1}>
                <PortfolioOptimizerCancelButton disabled={isActionDisabled(PortfolioOptimizerTransition.CANCEL, portfolioOptimizer)} portfolioOptimizerId={portfolioOptimizerId} />

                <Button
                  sx={{ m: 1 }}
                  variant='contained'
                  data-testid='to-bulk-trader-button'
                  disabled={loadingBulkTrader}
                  onClick={() => {
                    newBulkTrader({
                      variables: {
                        input: { autoFillActualQuantity: false, organizationId: activeOrganization.id, portfolioOptimizerId },
                      },
                    });
                  }}
                >
                  {t('detail.proceedToBulkTrader')}
                </Button>
              </Grid>
            </Grid>

            <PortfolioReportsTable portfolioOptimizerId={portfolioOptimizerId} />
          </>
        )}

        {!loading && portfolioOptimizer.state === PortfolioOptimizerStates.CANCELED && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography m={5}>{t('detail.portfolioOptimizerState', { state: t(`portfolioOptimizerState.${portfolioOptimizer.state}`) })}</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default PortfolioOptimizer;
