export const updateContextTransfer = ({
  existingContext = {},
  value,
  setApplicationContext,
  setWorkflowCompletionContext,
}: {
  existingContext: any;
  value: any;
  setApplicationContext?: any;
  setWorkflowCompletionContext?: any;
}) => {
  if (setApplicationContext) {
    setApplicationContext({ ...existingContext, ...value });
  } else {
    setWorkflowCompletionContext({
      ...existingContext,
      ...value,
    });
  }
};
