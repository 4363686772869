import { Box } from '@mui/material';

export { Tab, Tabs } from '@mui/material';

/*
 The <TabContext> and <TabPanel> from @mui/lab break functionality of children components
 (their mount/unmount effects are called too often)

 This is a replacement.
*/

export const TabPanel = (props: any) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ov-tabpanel-${index}`}
      aria-labelledby={`ov-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};
