import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../2-component';

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($articleId: ObjectID!) {
    deleteArticle(articleId: $articleId) {
      deleted
    }
  }
`;

export const DELETE_ARTICLE_IMAGE = gql`
  mutation deleteArticleImage($input: DeleteArticleImageInput!) {
    deleteArticleImage(input: $input) {
      article {
        id
      }
    }
  }
`;

export const FETCH_DOCUMENTS = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {perPage: 1000}
  }) {
    fileDocuments {
      id type name fileName mediaType uploadedAt updatedAt
      objectType objectId
      sharedClient sharedCustodian
      creator { id }
      user { id firstName lastName }
    }
  }
}`;

export const DeleteArticle = ({
  open, setOpen, article, refetch, loading,
}: {
  open: boolean, setOpen: (open: boolean) => void, article: any, refetch: () => void, loading: boolean,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const [fetchDocuments] = useLazyQuery(FETCH_DOCUMENTS, {
    variables: {
      filter: {
        sourceIds: [article.current?.id],
        types: ['ARTICLE'],
      },
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  });
  const [deleteArticleImageMutation] = useMutation(DELETE_ARTICLE_IMAGE, {
    variables: {
      input: {
        articleId: article.current?.id ?? '',
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteArticleMutation] = useMutation(DELETE_ARTICLE, {
    variables: {
      articleId: article.current?.id ?? '',
    },
  });

  const deleteArticle = async () => {
    // Deletes the image first if any
    const documentsResponse = await fetchDocuments();
    if ((documentsResponse?.data?.fetchFileDocuments?.fileDocuments?.length ?? 0) > 0) {
      await deleteArticleImageMutation();
    }
    await deleteArticleMutation();
    refetch();
    setOpen(false);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {t('deleteArticle')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="bodyLarge">
        {t('deleteArticleMessage')}
        </Typography>
      </DialogContent>
      <DialogFooter>
        <Button
          variant='tonal'
          disabled={loading}
          label={t('cancel')}
          onClick={() => setOpen(false)}
        />
        <Button
          disabled={loading}
          label={t('confirm')}
          onClick={() => deleteArticle()}
        />
      </DialogFooter>
    </Dialog>
  );
};
