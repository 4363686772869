import {
  createContext, SetStateAction, useState, Dispatch, useContext,
} from 'react';
import { useMutation } from '@apollo/client';
import { useAuthContext } from 'providers/ovApolloProvider';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './createEntityWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { EntityTypes, User } from '../../../interfaces/user';
import { CREATE_ENTITY, UserContext } from '../../../providers/userContextProvider';
import { delay } from '../../../util';
import { auth0RefreshTokenSilently } from '../../../providers/auth0Client';

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

const initialEntity = {
  type: EntityTypes.INDIVIDUAL,
};

export const EntityContext = createContext<{
  entityData: Partial<User>,
  setEntityData: Dispatch<SetStateAction<Partial<User>>>,
}>({
  entityData: {},
  setEntityData: (() => { }),
});

export const CreateEntityWorkflow = ({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) => {
  const { userContext, setActiveEntity } = useContext(UserContext);
  const [entityData, setEntityData] = useState<Partial<User>>({ ...initialEntity, primaryEmail: userContext.email });
  const [workflowCompletion, setWorkflowCompletion] = useState<any>(initialWorkflowCompletion);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthToken } = useAuthContext();

  const [createEntity] = useMutation(CREATE_ENTITY);

  const onComplete = () => {
    setOpen(false);
    setEntityData(initialEntity);
    setWorkflowCompletion(initialWorkflowCompletion);
  };

  const submit = async () => {
    setLoading(true);
    createEntity({
      variables: {
        input: {
          type: entityData.type,
          primaryEmail: entityData.primaryEmail,
          firstName: entityData.firstName || undefined,
          middleName: entityData.middleName || undefined,
          lastName: entityData.lastName || undefined,
          entityName: entityData.entityName || undefined,
        },
      },
      onCompleted: async (data) => {
        if (data.createUser.user.id) {
          // TODO: add GraphQL subscription to confirm it's ready to redirect user
          await delay(2000);
          const newToken = await auth0RefreshTokenSilently({
            redirectToPath: `/clients/${data.createUser.user.id}?autoOpenWorkflow=true`,
            orgSubdomain: userContext.organization?.subdomain,
          });
          if (newToken) setAuthToken(newToken);
          setActiveEntity(data.createUser.user);
        }
      },
    });
  };

  const updateFormSubStepOptions = () => {
    const formSubStep = workflowCompletion?.currentStep?.subSteps[2];
    return {
      ...formSubStep,
      options: Object.keys(formSubStep.options).reduce((prev: any, k) => {
        // eslint-disable-next-line no-param-reassign
        prev[k] = {
          ...formSubStep.options[k],
          enabled: (
            (
              (entityData.type === EntityTypes.INDIVIDUAL && k === 'entityName')
              || (entityData.type !== EntityTypes.INDIVIDUAL && ['firstName', 'middleName', 'lastName'].includes(k))
            ) ? false : formSubStep.options[k].enabled
          ),
        };
        return prev;
      }, {}),
    };
  };

  const updateNext = () => {
    if (entityData.type === EntityTypes.INDIVIDUAL && workflowCompletion.currentSubStep.id === '1') {
      setWorkflowCompletion((prev: any) => ({
        ...prev,
        currentSubStep: updateFormSubStepOptions(),
      }));
    } else if (workflowCompletion.currentSubStep.id === '2') {
      setWorkflowCompletion((prev: any) => ({
        ...prev,
        currentSubStep: updateFormSubStepOptions(),
      }));
    } else if (workflowCompletion.currentSubStep.id === '3') {
      submit();
    } else {
      updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
        onComplete();
      });
    }
  };

  const updatePrevious = () => {
    if (entityData.type === EntityTypes.INDIVIDUAL && workflowCompletion.currentSubStep.id === '3') {
      setWorkflowCompletion((prev: any) => ({
        ...prev,
        currentSubStep: workflowCompletion?.currentStep?.subSteps[0],
      }));
    } else {
      updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
    }
  };

  return (<EntityContext.Provider value={{
    entityData,
    setEntityData,
  }}>
    <WorkflowCompletionVisual
      workflowCompletion={workflowCompletion}
      open={open}
      onClose={onComplete}
      onNext={updateNext}
      previousStep={updatePrevious}
      loading={loading}
      data={entityData}
      onChangeSetData={setEntityData}
    />
  </EntityContext.Provider>
  );
};
