import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '../../../../1-primative';
import { Accordion, AccordionSummary, AccordionDetails } from '../../../../2-component';
import { FinancialProduct, SubAccount } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { calculatePrimaryAssetClassHoldings } from '../holdings.resources';
import { AccordionHoldings } from './accordionHoldings';
import { useAccordionTokens } from '../../../../2-component/accordion/accordian.tokens';

const generateRiskLevel = (riskRetunLevel?: number) => {
  if (riskRetunLevel && riskRetunLevel > 0 && riskRetunLevel < 21) {
    return 'VERY_LOW';
  }
  if (riskRetunLevel && riskRetunLevel > 20 && riskRetunLevel < 41) {
    return 'LOW';
  }
  if (riskRetunLevel && riskRetunLevel > 40 && riskRetunLevel < 61) {
    return 'MEDIUM';
  }
  if (riskRetunLevel && riskRetunLevel > 60 && riskRetunLevel < 81) {
    return 'HIGH';
  }
  if (riskRetunLevel && riskRetunLevel > 80 && riskRetunLevel < 101) {
    return 'VERY_HIGH';
  }
  return null;
};
const AVAILABLE_THEMES = `
  availableThemes {
    id
    key
    translatedName { en fr}
    translatedDescription { en fr}
  }
`;
const SUGGESTED_PRODUCT = `
  id
  translatedName { en fr}
  riskReturnLevel
  theme {
    id
    key
    translatedName { en fr}
    translatedDescription { en fr}
  }
  primaryAssetClass {
    id
    translatedName { en fr}
    translatedDescription { en fr}
    key
  }
  secondaryAssetClass {
    id
    translatedName { en fr}
    translatedDescription { en fr}
    key
  }
  translatedName { en fr}
  url
  children {
    id
    percentage
    taxRank
    financialProduct {
      id
      translatedName { en fr}
      riskReturnLevel
      primaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
      theme {
        id
        key
        translatedName { en fr}
        translatedDescription { en fr}
      }
      secondaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
    }
  }
`;
const FINANCIAL_PRODUCT = `
financialProduct {
  id
  translatedName { en fr}
  riskReturnLevel
  theme {
    id
    key
    translatedName { en fr}
    translatedDescription { en fr}
  }
  primaryAssetClass {
    id
    translatedName { en fr}
    translatedDescription { en fr}
    key
  }
  secondaryAssetClass {
    id
    translatedName { en fr}
    translatedDescription { en fr}
    key
  }
  children {
    id
    percentage
    taxRank
    financialProduct {
      id
      translatedName { en fr}
      riskReturnLevel
      primaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
      theme {
        id
        key
        translatedName { en fr}
        translatedDescription { en fr}
      }
      secondaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
    }
  }
}
`;
export const FETCH_FINANCIAL_PRODUCT = gql`
  query fetchFinancialProduct($input: FetchFinancialProductInput!) {
    fetchFinancialProduct(input: $input) {
      ${FINANCIAL_PRODUCT}
    }
  }
`;

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
        type
        state
        ${AVAILABLE_THEMES}
        ${FINANCIAL_PRODUCT}
        suggestedFinancialProduct {
          ${SUGGESTED_PRODUCT}
        }
        subAccounts {
          id
          account {
            type
          }
          ${AVAILABLE_THEMES}
          ${FINANCIAL_PRODUCT}
          suggestedFinancialProduct {
            ${SUGGESTED_PRODUCT}
          }
        }
      }
    }
  }
`;

interface HoldingCardProps {
  financialProduct?: FinancialProduct,
  type: 'CURRENT' | 'SUGGESTED',
  onEdit?: (selectedId: string) => any,
  goalId?: string,
  level: 'GOAL' | 'SUB_ACCOUNT',
  subAccountId?: string,
  options: any,
}

const HoldingCard = ({
  financialProduct,
  type,
  goalId,
  level,
  subAccountId,
  options,
}: HoldingCardProps) => {
  const { t } = useTranslation(['client']);
  const { sys } = useThemeTokens();
  const tokens = useThemeTokens(useAccordionTokens());
  const { comp } = tokens;
  const [expanded, setExpanded] = useState(false);
  const [availableThemVal, setAvailableThemeVal] = useState<any>();
  const [holdings, setHoldings] = useState<any[]>();

  const { data: goalData } = useQuery(FETCH_GOAL, {
    variables: { goalId },
    fetchPolicy: 'no-cache',
    skip: !goalId || !expanded,
  });

  useEffect(() => {
    if (level === 'GOAL') {
      if (type === 'CURRENT' && goalData) {
        setAvailableThemeVal(goalData.fetchGoal.goal.availableThemes);
        if (goalData.fetchGoal.goal?.financialProduct?.children) {
          const h = calculatePrimaryAssetClassHoldings(goalData.fetchGoal.goal?.financialProduct?.children.map((x: any) => ({ ...x, totalCents: x.percentage })), 100);
          setHoldings(h ?? []);
        }
      }
      if (type === 'SUGGESTED' && goalData) {
        setAvailableThemeVal(goalData.fetchGoal.goal.availableThemes);
        if (goalData.fetchGoal.goal?.suggestedFinancialProduct?.children) {
          const h = calculatePrimaryAssetClassHoldings(goalData.fetchGoal.goal?.suggestedFinancialProduct?.children.map((x: any) => ({ ...x, totalCents: x.percentage })), 100);
          setHoldings(h ?? []);
        }
      }
    }
    if (level === 'SUB_ACCOUNT' && subAccountId) {
      const relatedSubAccount = goalData?.fetchGoal.goal?.subAccounts?.find((a: SubAccount) => (a.id === subAccountId));
      if (type === 'CURRENT' && goalData && relatedSubAccount) {
        setAvailableThemeVal(relatedSubAccount?.availableThemes);
        if (relatedSubAccount?.financialProduct?.children) {
          const h = calculatePrimaryAssetClassHoldings(relatedSubAccount?.financialProduct?.children.map((x: any) => ({ ...x, totalCents: x.percentage })), 100);
          setHoldings(h ?? []);
        }
      }
      if (type === 'SUGGESTED' && goalData && relatedSubAccount) {
        setAvailableThemeVal(relatedSubAccount.availableThemes);
        if (relatedSubAccount?.suggestedFinancialProduct?.children) {
          const h = calculatePrimaryAssetClassHoldings(relatedSubAccount?.suggestedFinancialProduct?.children.map((x: any) => ({ ...x, totalCents: x.percentage })), 100);
          setHoldings(h ?? []);
        }
      }
    }
  }, [goalData, type, level, subAccountId]);

  const showUrlModelPortfolioUrl = (options?.displayModelPortfolioUrl && financialProduct?.url !== null)
    ? <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{`[Portfolio fund facts](${financialProduct?.url})`}
    </ReactMarkdown>
    : <></>;

  return (
    <Accordion
      expanded={expanded}
      variant='outlined'
      disableGutters
      onChange={() => setExpanded(!expanded)}
      sx={{
        '&:before': {
          display: 'none',
          border: 'none',
        },
        '&.MuiAccordion-root': {
          border: `1px solid ${type === 'CURRENT' ? comp.accordion.multipleBorderColor : sys.color.positive}`,
        },
        '&:last-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
        '&:last-of-type&:first-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
        '&:first-of-type': {
          borderRadius: `${comp.accordion.borderRadius} !important`,
          '.MuiCollapse-root': {
            borderRadius: `${comp.accordion.borderRadius} !important`,
          },
        },
        '.MuiAccordionSummary-root': { borderTop: 'none !important' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' },
          '.MuiAccordionSummary-expandIconWrapper': { position: 'absolute', top: '10px', right: '15px' },
          '.MuiAccordionSummary-root': { borderTop: 'none' },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='titleMedium'>
              {t(`${type === 'CURRENT' ? 'currentPortfolio' : 'recommendedPortfolio'}`)}:
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>{financialProduct?.riskReturnLevel ? t(`riskReturnLevels.${generateRiskLevel(financialProduct?.riskReturnLevel)}`) : ''}</span>
               - {translateBackend(financialProduct?.theme?.translatedName)}
            </Typography>
          </Box>
          <Box display='block' justifyContent='space-between' sx={{ mt: 1 }}>
            <Typography variant='bodySmall'>{translateBackend(financialProduct?.theme?.translatedDescription)}</Typography>
            {showUrlModelPortfolioUrl}
          </Box>
        </Box>
      </AccordionSummary>
      {goalData && (<AccordionDetails>
        <hr style={{ borderTop: `1px solid ${comp.accordion.multipleBorderColor}` }}></hr>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='titleMedium'>{t('portfolioBreakdown')}</Typography>
        </Box>
        <br></br>
        <Typography variant='bodySmall' sx={{ ml: 1 }}>
            {translateBackend(availableThemVal?.translatedDescription)}
        </Typography>
        <AccordionHoldings assetClassHoldings={holdings ?? []} loading={false} />
      </AccordionDetails>)}
    </Accordion>
  );
};
export default HoldingCard;
