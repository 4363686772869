import {
  Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubAccountTableHeader = ({ type, hasClientGroup }: { type: 'GOAL' | 'ACCOUNT', hasClientGroup?: boolean }) => {
  const { t } = useTranslation(['components', 'subaccount']);

  const textStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  };

  return (
    <Grid alignItems="start" container spacing={1} style={{ padding: '10px' }}>
      {hasClientGroup && (<Grid item xs={2}>
        <Typography variant="subtitle2" sx={textStyle}>{t('subaccount:owner')}</Typography>
      </Grid>)}
      <Grid item xs={hasClientGroup ? 2.5 : 3.0}>
        <Typography variant="subtitle2" sx={textStyle}>{
          t(`subaccount:${type === 'ACCOUNT' ? 'Goal' : 'Account'}`)
        }</Typography>
      </Grid>
      <Grid item xs={hasClientGroup ? 2.5 : 3.5}>
        <Typography variant="subtitle2" sx={textStyle}>{t('subaccount:portfolio')}</Typography>
      </Grid>
      <Grid item xs={hasClientGroup ? 1.25 : 1.75}>
        <Typography variant="subtitle2" sx={textStyle}>{t(`subaccount:percentageOfTotal${type}`)}</Typography>
      </Grid>
      <Grid item xs={1.25}>
        <Typography variant="subtitle2" sx={textStyle}>{t('subaccount:balance')}</Typography>
      </Grid>
      <Grid item xs={2.5}>
        <Typography variant="subtitle2" sx={textStyle}>{t('subaccount:status')}</Typography>
      </Grid>
    </Grid>
  );
};

export default SubAccountTableHeader;
