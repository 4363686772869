import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import NewWorkflow from './newWorkflow';

const FETCH_WORKFLOWS = gql`
  query fetchWorkflows($input: FetchWorkflowsInput!) {
    fetchWorkflows(input: $input) {
      workflows {
        id
        state
        name { en }
        steps {
          id
        }
        organization {
          name
        }
      }
    }
  }
`;

const Workflows = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              { permissions.includes('write:workflow') && (
                <NewWorkflow afterCreate={refetch} defaultOrg={activeOrganization.id} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('workflowTable.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('workflowTable.state')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('workflowTable.numberOfSteps')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('workflowTable.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchWorkflows?.workflows?.map((workflow: any) => (
                <TableRow
                  key={workflow.id}
                  sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    navigate(`configureWorkflow/${workflow.id}`);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {workflow.name.en}
                  </TableCell>
                  <TableCell>{t(`workflowStates.${workflow.state}`)}</TableCell>
                  <TableCell>{workflow.steps.length}</TableCell>
                  <TableCell align="right">{workflow?.organization?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchWorkflows?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default Workflows;
