import { gql } from '@apollo/client';
import { PageObjectType } from '../../../5-page/clientReportTemplate/components/pageConfiguration/pageConfiguration';

export const FETCH_CLIENT_REPORT_TEMPLATES = gql`
  query fetchClientReportTemplates($input: FetchClientReportTemplatesInput!) {
    fetchClientReportTemplates(input: $input) {
      clientReportTemplates {
        id
        objectType
        reportType
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        scheduler {
          frequency
        }
      }
      totalCount
    }
  }
`;

export const FETCH_USER = (reports: any) => {
  const query = `
    query fetchUser($objectId: ObjectID!) {
      fetchUser(userId: $objectId) {
        user {
          id
          firstName
          lastName
          state
          ${reports?.accounts?.length > 0 ? 'accounts { id type custodianAccountNumber state }' : ''}
          ${reports?.goals?.length > 0 ? 'goals { id type name state}' : ''}
          ${reports?.subAccounts?.length > 0 ? 'subAccounts { id state goal { type } account { type } }' : ''}
        }
      }
    }
  `;
  return gql(query);
};

export const FETCH_CLIENT_GROUP = (reports: any) => {
  const query = `
    query fetchClientGroup($objectId: ObjectID!) {
      fetchClientGroup(clientGroupId: $objectId) {
        clientGroup {
          id
          name
          ${reports?.individuals?.length > 0 || reports?.nonIndividuals?.length > 0 ? 'relationships { user { id firstName lastName state } }' : ''}
          ${reports?.accounts?.length > 0 ? 'accounts { id type custodianAccountNumber state }' : ''}
          ${reports?.goals?.length > 0 ? 'goals { id type name }' : ''}
          ${reports?.subAccounts?.length > 0 ? 'subAccounts { id goal { type } account { type } }' : ''}
        }
      }
    }
  `;
  return gql(query);
};

export const FETCH_ACCOUNT = (reports: any) => {
  const query = `
  query fetchAccount($objectId: ObjectID!) {
    fetchAccount(accountId: $objectId) {
      account {
        id
        type
        ${reports?.subAccounts?.length > 0 ? 'subAccounts { id goal { type } account { type  } }' : ''}
      }
    }
  }
  `;
  return gql(query);
};

export const FETCH_GOAL = (reports: any) => {
  const query = `
  query fetchGoal($objectId: ObjectID!) {
    fetchGoal(accountId: $objectId) {
      goal {
        id
        name
        type
        ${reports?.subAccounts?.length > 0 ? 'subAccounts { id goal { type } account { type } }' : ''}
      }
    }
  }
  `;
  return gql(query);
};

export const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($objectId: ObjectID!) {
    fetchSubAccount(subAccountId: $objectId) {
      subAccount {
        id
        goal { type }
        account { type }
      }
    }
  }
`;

export const chooseQuery = ({ objectType, templates }: { objectType: PageObjectType; templates: any }) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
    case PageObjectType.NON_INDIVIDUAL:
      return FETCH_USER(templates);
    case PageObjectType.HOUSEHOLD:
      return FETCH_CLIENT_GROUP(templates);
    case PageObjectType.ACCOUNT:
      return FETCH_ACCOUNT(templates);
    case PageObjectType.GOAL:
      return FETCH_GOAL(templates);
    case PageObjectType.SUB_ACCOUNT:
      return FETCH_SUB_ACCOUNT;
    default:
      return gql`
        query {
          __typename
        }
      `;
  }
};

export const objectPath = ({ objectType, data }: { objectType: PageObjectType, data: any }) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
    case PageObjectType.NON_INDIVIDUAL:
      return data?.fetchUser?.user;
    case PageObjectType.HOUSEHOLD:
      return data.fetchClientGroup.clientGroup;
    case PageObjectType.ACCOUNT:
      return data.fetchAccount.account;
    case PageObjectType.GOAL:
      return data.fetchGoal.goal;
    case PageObjectType.SUB_ACCOUNT:
      return data.fetchSubAccount.subAccount;
    default:
      return '';
  }
};
