import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';

const warningIconStyles = {
  fontSize: 'small',
  color: '#ed6c02',
  width: '1rem',
  height: '1rem',
};

const defaultStyles = {
  fontSize: 'small',
  color: '#c4c5c9',
  width: '1rem',
  height: '1rem',
};

const WarningOnOff = ({ on, useDot }: { on: boolean, useDot?: boolean }) => (
  on
    ? (useDot ? <CircleIcon sx={warningIconStyles} /> : <WarningIcon sx={warningIconStyles} />)
    : <CircleIcon sx={defaultStyles} />
);

export default WarningOnOff;
