import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Account,
  Affiliation,
  DraftAccount,
} from 'interfaces';
import { generateClientNameString } from 'util/index';

import { Box, Icon, Typography } from 'ovComponents/1-primative';
import {
  Card,
  CardContent,
  IconButton,
} from 'ovComponents/2-component';
import { GoalLinkingReview } from '../../goalLinking';
import { AffiliationTable } from '../../affiliationTable';
import { checkAffiliationMatch } from './corporateCash.dialogContent';

interface CorporateCashReviewItemProps {
  account: Account | DraftAccount,
  updateAffiliations: (affilations: Affiliation[]) => void,
  onDeleteAccount: () => void,
}
const hasAffiliates = (account: Account | DraftAccount) => (account?.affiliations ?? []).length > 0;
const isDraft = (account: Account | DraftAccount) => !account?.id;

const DetailEntry = (props: any) => <Box display='flex' flexDirection='row' justifyContent='space-between' {...props}></Box>;

export const CorporateCashReviewItem = (props: CorporateCashReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationRelationship']);
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <DetailEntry sx={{ mb: 2 }}>
          <Typography variant='bodyLarge' weight='bold' sx={{ mt: 1 }}>{t('workflowCompletions:accountReviewPanel.corporateCash')}</Typography>
          {
            props.account.id ? undefined : <IconButton onClick={props.onDeleteAccount}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </DetailEntry>
        <AccountDetails account={props.account} updateAffiliations={props.updateAffiliations} />
      </CardContent>
    </Card>
  );
};

const AccountDetails = ({
  account,
  updateAffiliations,
}: {
  account: Account | DraftAccount,
  updateAffiliations: (affilations: Affiliation[]) => void,
}) => {
  const { t } = useTranslation(['affiliationRelationship', 'shared', 'workflowCompletions']);
  const handleDeleteAffiliation = (affiliation: Affiliation) => {
    if (!isDraft(account) || !account.affiliations) return;
    const newAffiliations = account.affiliations.filter((x) => !checkAffiliationMatch(x, affiliation));
    updateAffiliations(newAffiliations);
  };

  return (<>
    <Box display='flex' flexDirection='column' gap={1}>
      <DetailEntry>
        <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:corporateCashAccountReivew.corporationName')}</Typography>
        <Typography variant='bodyMedium'>{generateClientNameString(account?.user)}</Typography>
      </DetailEntry>
      <DetailEntry>
        <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:corporateCashAccountReivew.affiliates')}</Typography>
        <Typography variant='bodyMedium'>{hasAffiliates(account) ? t('shared:yes') : t('shared:no')}</Typography>
      </DetailEntry>
      { hasAffiliates(account)
        && <DetailEntry>
          <AffiliationTable
            affiliations={account?.affiliations}
            showType={true}
            showAllocation={true}
            allowDelete={isDraft(account)}
            onDelete={handleDeleteAffiliation}
          />
        </DetailEntry>}
        <DetailEntry>
          <GoalLinkingReview account={account} />
        </DetailEntry>
    </Box>

  </>);
};
