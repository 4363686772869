import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BankAccountsTable from './components/bankAccountsTable';

const BankAccountsToReview = () => {
  useNavigate();
  return (
    <Paper sx={{ width: '100%' }}>
      <BankAccountsTable />
    </Paper>
  );
};

export default BankAccountsToReview;
