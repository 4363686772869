import {
  Button, Dialog, DialogTitle, List, ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import { useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { TranslatedString } from '../../interfaces';
import RichTextEditor from './richTextEditor';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';

const TranslatableRichTextEditor = ({
  onChange, value = { en: '' }, label, readonly = false,
}: {
  onChange: ((e: any) => void),
  value?: TranslatedString,
  label: string,
  rows?: number,
  readonly?: boolean
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState('key');

  return (
    <>
      <RichTextEditor
        onChange={(en) => onChange({ ...value, en })}
        value={value?.en ?? ''}
        label={label}
        readonly={readonly}
        iconButton={{
          icon: <TranslateIcon />,
          onClick: () => setOpen(!open),
        }}
        key={key}
      />
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{t('translate')} - {label}</DialogTitle>
          <List>
            <ListItem>
              <RichTextEditor
                onChange={(en) => onChange({ ...value, en })}
                value={value?.en ?? ''}
                label={t('languages.english')}
                readonly={readonly}
              />
            </ListItem>
            <ListItem>
              <RichTextEditor
                onChange={(fr) => onChange({ ...value, fr })}
                value={value?.fr ?? ''}
                label={t('languages.french')}
                readonly={readonly}
              />
            </ListItem>
            <ListItem>
              <Button fullWidth variant='contained' onClick={() => {
                setKey(uniqueId());
                setOpen(false);
              }}>
                { t('done') }
              </Button>
            </ListItem>
          </List>
      </Dialog>
    </>
  );
};

export default TranslatableRichTextEditor;
