/* eslint-disable react-hooks/exhaustive-deps */

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  useState, createContext, useContext, useEffect,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { SchedulerFrequency } from '../ovComponents/5-page/clientReportTemplate/components/frequency';

dayjs.extend(quarterOfYear);

const OBJECT_TYPE_ATTRIBUTES = `
  id
  createdAt
`;

const USER_FETCH = gql`
  query fetchUser($id: ObjectID!){
    fetchUser(userId: $id) {
      user {
        ${OBJECT_TYPE_ATTRIBUTES}
      }
    }
  }
`;

const ACCOUNT_FETCH = gql`
  query fetchAccount($id: ObjectID!){
    fetchAccount(accountId: $id) {
      account {
        ${OBJECT_TYPE_ATTRIBUTES}
      }
    }
  }
`;

const SUB_ACCOUNT_FETCH = gql`
  query fetchSubAccount($id: ObjectID!){
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        ${OBJECT_TYPE_ATTRIBUTES}
      }
    }
  }
`;

const GOAL_FETCH = gql`
  query fetchGoal($id: ObjectID!){
    fetchGoal(goalId: $id) {
      goal {
        ${OBJECT_TYPE_ATTRIBUTES}
      }
    }
  }
`;

const HOUSEHOLD_FETCH = gql`
  query fetchClientGroup($id: ObjectID!){
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        ${OBJECT_TYPE_ATTRIBUTES}
      }
    }
  }
`;

type ClientReportContextType = {
  setObjectContext: React.Dispatch<React.SetStateAction<any | undefined>>;
  objectContext: any;
  reportFrequency: any;
  setReportFrequency: React.Dispatch<React.SetStateAction<any | undefined>>;
  timePeriod: TimePeriod;
  setTimePeriod: React.Dispatch<React.SetStateAction<TimePeriod>>;
  timePeriods: TimePeriod[];
  setObjectCreatedAt: React.Dispatch<React.SetStateAction<Date>>;
  objectCreatedAt: Date;
  setOneTimeStart: React.Dispatch<React.SetStateAction<Date>>;
  oneTimeStart: Date;
  setOneTimeEnd: React.Dispatch<React.SetStateAction<Date>>;
  oneTimeEnd: Date;
};

interface TimePeriod {
  startDate: Date;
  endDate: Date;
  label: string;
}

interface ObjectContext {
  objectType: string;
  objectId: string;
}

export const ClientReportContext = createContext<ClientReportContextType>({
  setObjectContext: () => {},
  objectContext: {},
  reportFrequency: {},
  setReportFrequency: () => {},
  timePeriod: {
    label: '',
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  },
  setTimePeriod: () => {},
  timePeriods: [],
  setObjectCreatedAt: () => {},
  objectCreatedAt: new Date(),
  setOneTimeStart: () => {},
  oneTimeStart: new Date(),
  setOneTimeEnd: () => {},
  oneTimeEnd: new Date(),
});

const ClientReportContextProvider = ({ children }: { children: any }) => {
  const [objectContext, setObjectContext] = useState<ObjectContext>();
  const [reportFrequency, setReportFrequency] = useState<SchedulerFrequency>();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>({
    label: '',
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
  });
  const [timePeriods, setTimePeriods] = useState<TimePeriod[]>([]);
  const [objectCreatedAt, setObjectCreatedAt] = useState<Date>(new Date());
  const [oneTimeStart, setOneTimeStart] = useState<Date>(new Date());
  const [oneTimeEnd, setOneTimeEnd] = useState<Date>(new Date());

  const chooseQuery = () => {
    switch (objectContext?.objectType) {
      case 'INDIVIDUAL':
      case 'NON_INDIVIDUAL':
        return USER_FETCH;
      case 'GOAL':
        return GOAL_FETCH;
      case 'ACCOUNT':
        return ACCOUNT_FETCH;
      case 'SUB_ACCOUNT':
        return SUB_ACCOUNT_FETCH;
      case 'HOUSEHOLD':
        return HOUSEHOLD_FETCH;
      default:
        return gql`
          query {
            __typename
          }
        `;
    }
  };

  const path = (data: any) => {
    switch (objectContext?.objectType) {
      case 'INDIVIDUAL':
      case 'NON_INDIVIDUAL':
        return data?.fetchUser?.user;
      case 'GOAL':
        return data?.fetchGoal?.goal;
      case 'ACCOUNT':
        return data?.fetchAccount?.account;
      case 'SUB_ACCOUNT':
        return data?.fetchSubAccount?.subAccount;
      case 'HOUSEHOLD':
        return data?.fetchClientGroup?.clientGroup;
      default:
        return '';
    }
  };

  const calculateTimePeriod = ({ freq }: { freq?: SchedulerFrequency }) => {
    const currentDate = dayjs.utc();
    const createdAt = path(data)?.createdAt;

    const dateToLabel = ({ date }: { date: Date }) => {
      const monthToString = dayjs.utc(date).format('MMMM');
      const quarterToString = dayjs.utc(date).quarter();
      const yearToString = dayjs.utc(date).format('YYYY');
      switch (freq) {
        case SchedulerFrequency.MONTHLY:
          return `${monthToString} ${yearToString} `;
        case SchedulerFrequency.QUARTERLY:
          return `Quarter, ${quarterToString} - ${yearToString} `;
        case SchedulerFrequency.YEARLY:
          return `${yearToString} `;
        default:
          return '';
      }
    };

    const oneTimeToLabel = ({ date }: { date: Date }) => dayjs.utc(date).format('MMMM DD, YYYY');

    const adjustStartDate = (date: dayjs.Dayjs) => {
      switch (freq) {
        case SchedulerFrequency.MONTHLY:
          return date.add(1, 'month').date(1);
        case SchedulerFrequency.QUARTERLY:
          return date.startOf('quarter').add(3, 'month').date(1);
        case SchedulerFrequency.YEARLY:
          return date.add(1, 'year').month(0).date(1);
        default:
          return date;
      }
    };

    const reportFilter = () => {
      switch (freq) {
        case SchedulerFrequency.MONTHLY:
          return currentDate.startOf('month');
        case SchedulerFrequency.QUARTERLY:
          return currentDate.startOf('quarter');
        case SchedulerFrequency.YEARLY:
          return currentDate.startOf('year');
        default:
          return dayjs().utc();
      }
    };

    const reports = [];
    let nextStartDate = dayjs(createdAt);

    if (freq === SchedulerFrequency.ONE_TIME) {
      reports.unshift({
        startDate: oneTimeStart,
        endDate: oneTimeEnd,
        label: `${oneTimeToLabel({ date: oneTimeStart })} - ${oneTimeToLabel({ date: oneTimeEnd })}`,
      });

      return reports;
    }

    while (nextStartDate.isBefore(reportFilter())) {
      let endDate = dayjs().toDate();

      switch (freq) {
        case SchedulerFrequency.MONTHLY:
          endDate = nextStartDate.endOf('month').toDate();
          break;
        case SchedulerFrequency.QUARTERLY:
          endDate = nextStartDate.endOf('quarter').toDate();
          break;
        case SchedulerFrequency.YEARLY:
          endDate = nextStartDate.endOf('year').toDate();
          if (!currentDate.isAfter(endDate)) {
            return reports;
          }
          break;
        default:
          break;
      }

      reports.unshift({
        startDate: nextStartDate.toDate(),
        endDate,
        label: dateToLabel({ date: nextStartDate.toDate() }),
      });

      nextStartDate = adjustStartDate(nextStartDate);
    }

    return reports;
  };

  const { data } = useQuery(chooseQuery(), {
    variables: {
      id: objectContext?.objectId,
    },
    skip: !objectContext?.objectId,
  });

  useEffect(() => {
    setTimePeriods(calculateTimePeriod({ freq: reportFrequency }));
  }, [data, reportFrequency, oneTimeStart, oneTimeEnd]);

  useEffect(() => {
    setObjectCreatedAt(path(data)?.createdAt);
  }, [data]);

  useEffect(() => {
    setTimePeriod(timePeriods[0]);
  }, [timePeriods]);

  return (
    <ClientReportContext.Provider
      value={{
        objectContext,
        setObjectContext,
        reportFrequency,
        setReportFrequency,
        timePeriod,
        setTimePeriod,
        timePeriods,
        setObjectCreatedAt,
        objectCreatedAt,
        setOneTimeStart,
        oneTimeStart,
        setOneTimeEnd,
        oneTimeEnd,
      }}
    >
      {localStorage.getItem('activeOrganizationData') && children}
    </ClientReportContext.Provider>
  );
};

export const useClientReportContext = (): ClientReportContextType => {
  const context = useContext(ClientReportContext);

  return context;
};

export default ClientReportContextProvider;
