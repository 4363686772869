/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { TFunction } from 'i18next';
import { round } from 'lodash';
import { Currencies } from 'interfaces/transaction';
import { Exchanges } from 'interfaces';
import { SubAccountReport } from '../../../../../interfaces/subAccountReport';
import { SubAccountStates } from '../../../../../interfaces/subAccount';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './interfaces';
import { HoldingReport } from '../../../../../interfaces/holdingReport';
import { translateBackend } from '../../../../../assets/i18n/config';
import { SubTradeRequest, SubTradeRequestStates, SubTradeRequestTypes } from '../../../../../interfaces/subTradeRequest';
import { SysTokens } from '../../../../0-tokens/sys';
import { OptimalHolding, OptimalSubAccount } from '../../../../../interfaces/optimalPortfolio';
import { TradeBase, TradeToVerify } from './subTradeRequestsTable';

export const buildPortfolioSubAccount = (subAccountReport: SubAccountReport, t: TFunction): PortfolioSubAccount => {
  const { subAccount } = subAccountReport;

  return {
    id: subAccount.id,
    name: `${subAccount.account.user.firstName}'s ${t(`accountTypes:${subAccount.account.type}`)}`,
    taxRank: subAccount.account.taxRank ?? 0,
    holdings: subAccountReport.holdings,
    inactive: subAccount.state === SubAccountStates.INACTIVE,
    marketValueCents: subAccountReport.marketValueCents,
    expectedCashCents: subAccountReport.expectedCashCents,
    cashCents: subAccountReport.cashCents,
    pendingWithdrawCents: subAccountReport.pendingWithdrawCents,
    pendingContributionCents: subAccountReport.pendingContributionCents,
    cashOnHoldToTradeCents: subAccountReport.cashOnHoldToTradeCents,
  };
};

export const buildSecurity = (security: HoldingReport, subAccounts: PortfolioSubAccount[]): PortfolioSecurity => {
  const { financialProduct } = security;

  return {
    id: financialProduct?.id ?? '',
    ticker: financialProduct?.ticker ?? '',
    expectedValueCents: security.expectedValueCents,
    expectedPercentage: security.expectedPercentage,
    currentPriceCents: financialProduct?.currentPriceCents ?? 0,
    translatedName: translateBackend(financialProduct?.translatedName) || '',
    taxRank: security.taxRank || null,
    financialProductTaxRank: financialProduct?.taxRank ?? 0,
    valueCents: security.valueCents,
    subAccounts,
  };
};

export const buildDraftSubTradeRequest = (subTradeRequest: SubTradeRequest, t: TFunction): DraftSubTradeRequest => ({
  id: subTradeRequest.id,
  financialProductId: subTradeRequest?.financialProduct?.id ?? '',
  isSellAll: subTradeRequest.isSellAll,
  subAccountId: subTradeRequest?.subAccount?.id ?? '',
  amountCents: (subTradeRequest?.preSplitMoneyAllocatedCents ?? subTradeRequest.moneyAllocatedCents) * (subTradeRequest.type === SubTradeRequestTypes.SELL ? -1 : 1),
  type: subTradeRequest.type,
  state: subTradeRequest.state,
  ticker: subTradeRequest?.financialProduct?.ticker ?? '',
  accountName: `${subTradeRequest?.subAccount?.account?.user?.firstName}'s ${t(`accountTypes:${subTradeRequest?.subAccount?.account?.type}`)}`,
});

export const buildDraftTrade = ({ id, financialProductId, subAccountId, amountCents, type, isSellAll, maxQuantity, ticker, accountName }: DraftSubTradeRequest): DraftSubTradeRequest => ({
  ...(id && { id }),
  financialProductId,
  subAccountId,
  amountCents,
  type,
  isSellAll,
  ...(isSellAll && { maxQuantity }), // when selling all, we need to send a quantity to create a sell all sub-trade request
  ticker,
  accountName,
  isTemp: true,
});

const getAmountCents = (newAmountCents: number, currentAmountCents: number) => round(newAmountCents - currentAmountCents, 2);
const getTradeType = (newAmountCents: number, currentAmountCents: number) => (newAmountCents > currentAmountCents ? SubTradeRequestTypes.BUY : SubTradeRequestTypes.SELL);
const checkIsSellAll = (newAmountCents: number, currentAmountCents: number) => newAmountCents === 0 && currentAmountCents >= 0;

export const buildNewDraftTrade = (newAmountCents: number, subAccount: PortfolioSubAccount, security: PortfolioSecurity): DraftSubTradeRequest => {
  const currentAmountCents = subAccount?.holding?.valueCents ?? 0;
  const amountCents = getAmountCents(newAmountCents, currentAmountCents);
  const type = getTradeType(newAmountCents, currentAmountCents);
  const isSellAll = checkIsSellAll(newAmountCents, currentAmountCents);

  return buildDraftTrade({
    financialProductId: security.id,
    subAccountId: subAccount.id,
    amountCents,
    type,
    isSellAll,
    maxQuantity: subAccount?.holding?.quantity,
    ticker: security.ticker,
    accountName: subAccount.name,
  });
};

export const buildNewOptimalDraftTrade = (optimalHolding: OptimalHolding, subAccount: OptimalSubAccount, securities: PortfolioSecurity[], subAccounts: PortfolioSubAccount[]): DraftSubTradeRequest => {
  const newAmountCents = round(subAccount.preTradeHoldingAllocations[optimalHolding.index], 2);

  const amountCents = newAmountCents === 0 ? -optimalHolding.valueCents : newAmountCents;
  const type = subAccount.preTradeHoldingAllocations[optimalHolding.index] > 0 ? SubTradeRequestTypes.BUY : SubTradeRequestTypes.SELL;
  const isSellAll = checkIsSellAll(subAccount.preTradeHoldingAllocations[optimalHolding.index], optimalHolding.valueCents);
  const maxQuantity = subAccount.currentHoldingQuantities[optimalHolding.index];
  const ticker = securities.find((item) => item.id === optimalHolding.financialProduct)?.ticker ?? '';
  const accountName = subAccounts.find((item) => item.id === subAccount.subAccount)?.name ?? '';

  return buildDraftTrade({
    financialProductId: optimalHolding.financialProduct,
    subAccountId: subAccount.subAccount,
    amountCents,
    type,
    isSellAll,
    maxQuantity,
    ticker,
    accountName,
  });
};

// returns a unique key for a pending sub trade requests map
export const getKey = (securityId: string, subAccountId: string) => `${securityId}_${subAccountId}`;

export const findPendingTrade = (key: string, maps: Map<string, DraftSubTradeRequest>[]): DraftSubTradeRequest | null => {
  if (!maps.length) return null;

  for (const map of maps) {
    const trade = map.get(key);

    if (trade) return trade;
  }

  return null;
};

export const mergePendingTradesMaps = (maps: Map<string, DraftSubTradeRequest>[]): Map<string, DraftSubTradeRequest> => {
  if (!maps.length) return new Map();

  // need a copy to avoid original array mutation
  const localMaps = maps.map((map) => new Map(map));

  const firstMap = localMaps.shift();

  const pendingTrades = new Map<string, DraftSubTradeRequest>(firstMap);

  for (const map of localMaps) {
    map.forEach((v, k) => pendingTrades.set(k, v));
  }

  return pendingTrades;
};

export const getPendingAmountCentsByKey = (key: string, maps: Map<string, DraftSubTradeRequest>[]): number => findPendingTrade(key, maps)?.amountCents ?? 0;

export const isSellAllByKey = (key: string, maps: Map<string, DraftSubTradeRequest>[]): boolean => findPendingTrade(key, maps)?.isSellAll ?? false;

export const getAmountFieldInputColorByKey = (key: string, pendingAmountCents: number, maps: Map<string, DraftSubTradeRequest>[], sys: SysTokens): string | undefined => {
  if (Math.abs(pendingAmountCents) <= 0) return sys.color.onSurface;

  const trade: DraftSubTradeRequest | null = findPendingTrade(key, maps);

  return (trade?.type === SubTradeRequestTypes.BUY ? sys.color.positive : sys.color.negative) ?? sys.color.onSurface;
};

export const sumPendingTradesCentsByCondition = (maps: Map<string, DraftSubTradeRequest>[], callbackFn?: (trade: DraftSubTradeRequest) => boolean): number => {
  let sum = 0;

  const pendingTrades = mergePendingTradesMaps(maps);

  pendingTrades.forEach((trade) => {
    if (callbackFn && callbackFn(trade)) sum += trade.amountCents;

    if (!callbackFn) sum += trade.amountCents;
  });

  return sum;
};

export const getSubAccountCurrentCents = (subAccount: PortfolioSubAccount, subAccountPendingTradesCents: number) => {
  const { cashCents, cashOnHoldToTradeCents } = subAccount;

  return cashCents - subAccountPendingTradesCents - cashOnHoldToTradeCents;
};

export const sumSubAccountsCurrentCents = (subAccounts: PortfolioSubAccount[], maps: Map<string, DraftSubTradeRequest>[]): number =>
  subAccounts.reduce((prev: number, subAccount) => {
    const pendingTradesCents = sumPendingTradesCentsByCondition(maps, (trade) => trade.subAccountId === subAccount.id);
    const current = getSubAccountCurrentCents(subAccount, pendingTradesCents);

    return prev + current;
  }, 0);

export const sumSecuritiesCurrentCents = (securities: PortfolioSecurity[], maps: Map<string, DraftSubTradeRequest>[]): number =>
  securities?.reduce((prev: number, security) => {
    const pendingTradeCents = sumPendingTradesCentsByCondition(maps, (trade) => trade.financialProductId === security.id);

    return prev + (security.valueCents + pendingTradeCents);
  }, 0);

export const sumSecuritiesDeviationCents = (securities: PortfolioSecurity[], maps: Map<string, DraftSubTradeRequest>[]): number =>
  securities?.reduce((prev: number, security) => {
    const pendingTradesCents = sumPendingTradesCentsByCondition(maps, (trade) => trade.financialProductId === security.id);

    return prev + (security.valueCents + pendingTradesCents - security.expectedValueCents);
  }, 0);

export const sortSecurities = (a: PortfolioSecurity, b: PortfolioSecurity) => {
  const taxRankA = a.taxRank ?? 1000; // will be moved to the bottom and ordered by a ticker
  const taxRankB = b.taxRank ?? 1000;

  return taxRankA === taxRankB ? a.ticker.localeCompare(b.ticker) : taxRankA - taxRankB;
};

export const sortPortfolioReportSubAccounts = (a: PortfolioSubAccount, b: PortfolioSubAccount) => (a.taxRank === b.taxRank ? a.name.localeCompare(b.name) : a.taxRank - b.taxRank);

export const areTradesAmountCentsDifferent = (draftTrades: Map<string, DraftSubTradeRequest>, initialDraftTrades: Map<string, DraftSubTradeRequest>): boolean => {
  // !todo: can't use the full potential of maps, iterators and generators in the current js version,
  // getting an error: Type 'IterableIterator<[string, DraftSubTradeRequest]>' can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher.
  const localDraftTradesToArray = Array.from(draftTrades.entries());

  for (const [key, trade] of localDraftTradesToArray) {
    const initialDraftTrade = initialDraftTrades.get(key);

    if (!initialDraftTrade) return true; // couldn't find a trade which means that the trade is a new one

    // !todo: should be updated when working with optimizer and splits as the amountCents will be different for a split
    if (initialDraftTrade.amountCents !== trade.amountCents) return true;
  }

  return false;
};

export const collectPendingTransactionTickers = (
  subTradeRequest: any,
  usdTickers: string[],
  mfSellAllTickers: string[],
  organization: any,
) => {
  if (!organization?.allowPendingTransactions) return;
  if (
    subTradeRequest.financialProduct.currency === Currencies.USD
    && subTradeRequest.state === SubTradeRequestStates.REQUESTED
    && !usdTickers.includes(subTradeRequest.financialProduct.ticker)
  ) {
    usdTickers.push(subTradeRequest.financialProduct.ticker);
  }
  if (
    subTradeRequest.isSellAll === true
    && subTradeRequest.state === SubTradeRequestStates.REQUESTED
    && subTradeRequest.financialProduct.exchange === Exchanges.FUNDSERV
    && !mfSellAllTickers.includes(subTradeRequest.financialProduct.ticker)
  ) {
    mfSellAllTickers.push(subTradeRequest.financialProduct.ticker);
  }
};

export const buildInitialTradeToVerifyState = (trade: TradeBase): TradeToVerify => ({
  id: trade.id,
  selected: !!trade.verifiedAt && (!!trade.verifiedBy),
  state: trade.state,
  verified: !!trade.verifiedAt,
});
