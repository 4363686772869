import ReactMarkdown from 'react-markdown';
import { translateBackend } from 'assets/i18n/config';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mui/material';
import { useContext } from 'react';
import {
  Button, Typography, Form, Box, Grid,
} from '../../../..';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { useTabNavTokens } from '../../../../3-pattern/tabNav/tabNav.tokens';
import { InlineImageLocal } from '../../../../../pages/client/components/documents/inlineImageLocal';
import { InlineImage } from '../../../../../pages/client/components/documents/inlineImage';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';

export const ReviewVisual = ({
  options, loading, continueFunc,
}: {
  options: any, loading: boolean, continueFunc: any,
}) => {
  const { t } = useTranslation(['workflowCompletions']);
  const tokens = useThemeTokens(useTabNavTokens());
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { comp } = tokens;
  const submit = () => {
    sendAnalytic(ovAnalyticsEvents.workflowsReviewContinueButtonSelect);
    continueFunc();
  };

  const styles: any = {
    objectFit: 'cover',
    height: '60px',
    width: '80px',
    borderRadius: '15px',
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 5 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {options?.options?.map((option: any) => (
        <Box display="flex" mb={5} sx={{ opacity: option.state === 'DISABLED' ? 0.4 : 1 }}>
          {option?.value?.file && (
            <Box mr={2}>
              <InlineImageLocal file={option?.value?.file} style={styles} />
            </Box>
          )}
          {option?.value?.value && (
            <Box mr={2}>
              <InlineImage fileDocumentId={option?.value?.value} style={styles} />
            </Box>
          )}
          <Grid container xs={12}>
            <Grid item xs={option?.icon ? 11 : 12}>
              <Box>
                <Typography variant="headingSmall" mb={0.5}>{translateBackend(option.title)}</Typography>
                <Typography variant="bodyLarge">{translateBackend(option.subtitle)}</Typography>
              </Box>
            </Grid>
            {option?.icon ? (
              <Grid item ml='auto' xs={option.trailing ? 1 : 0}>
                <Icon sx={{ fontSize: '30px', color: comp.tabNav.text.active }}>{option.icon}</Icon>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ))
      }
      <Box display='flex' justifyContent='end'>
        <Button label={t('workflowCompletions:continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default ReviewVisual;
