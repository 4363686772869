import {
  Typography, Accordion, AccordionSummary, AccordionDetails, Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../providers/userContextProvider';
import UpdateUserSection from './updateUserSection';
import IDs from './iDs';
import { usePageState } from '../../../util/usePageState';

export interface Field {
  key: string,
  permission: string,
  column: number,
  type?: 'text' | 'number' | 'date' | 'select' | 'multiSelect' | 'booleanSelect' | 'switch' | 'cents' | 'text_array' |
  'address' | 'foreignTax' | 'citizenship' | 'feeTier' | 'billingSchedule' | 'riskReason' | 'note' | 'hidden' |
  'nonIndividualEntityType' | 'taxId' | 'radioGroup',
  options?: any,
  showIf?: any,
  showIfAny?: any,
  dontFetch?: boolean,
  placeholder?: string,
  validation?: {
    rule?: RegExp,
    message: string,
    maxLength?: number,
  },
  label?: string,
  required?: boolean,
  defaultValue?: any,
  parentObject?: string,
  booleanSelectType?: 'TrueOrFalse' | 'YesOrNo',
  decimalPlaces?: number,
  readonly?: boolean,
}

const detailsFields: Field[] = [
  {
    key: 'firstName',
    permission: 'client_low_risk_pii',
    column: 4,
  },
  {
    key: 'middleName',
    permission: 'client_low_risk_pii',
    column: 4,
  },
  {
    key: 'lastName',
    permission: 'client_low_risk_pii',
    column: 4,
  },
  {
    key: 'primaryEmail',
    permission: 'client_low_risk_pii',
    column: 6,
    validation: {
      rule: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'client:profile.fieldValidations.primaryEmail',
    },
  },
  {
    key: 'phone',
    permission: 'client_high_risk_pii',
    column: 6,
    validation: {
      rule: /^([0-9]{10})?$/, // ^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      message: 'client:profile.fieldValidations.phone',
    },
  },
  {
    key: 'dateOfBirth',
    permission: 'client_high_risk_pii',
    column: 4,
    type: 'date',
  },
  {
    key: 'maritalStatus',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'select',
    options: ['SINGLE', 'MARRIED', 'COMMON_LAW', 'DIVORCED', 'SEPARATED', 'WIDOWED'],
  },
  {
    key: 'citizenships',
    permission: 'client_low_risk_pii',
    column: 12,
    type: 'citizenship',
  },
  {
    key: 'physicalAddress',
    permission: 'client_low_risk_pii',
    column: 12,
    type: 'address',
  },
  {
    key: 'foreignTaxInformation',
    permission: 'client_low_risk_pii',
    column: 12,
    type: 'foreignTax',
  },
];

const employmentFields: Field[] = [
  {
    key: 'employmentStatus',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'select',
    options: ['EMPLOYED', 'SELF_EMPLOYED', 'UNEMPLOYED', 'STUDENT', 'RETIRED'],
  },
  {
    key: 'jobTitle',
    permission: 'client_low_risk_pii',
    column: 4,
    showIf: {
      employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'],
    },
  },
  {
    key: 'companyType',
    permission: 'client_low_risk_pii',
    column: 4,
    showIf: {
      employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'],
    },
  },
  {
    key: 'studentAreaOfStudy',
    permission: 'client_low_risk_pii',
    column: 4,
    showIf: {
      employmentStatus: ['STUDENT'],
    },
  },
  {
    key: 'sin',
    permission: 'client_high_risk_pii',
    column: 4,
    dontFetch: true,
    placeholder: 'Will not show, update only', // TODO: Get translations out of function
    validation: {
      rule: /^[0-9]{9}$/,
      message: 'client:profile.fieldValidations.sin',
    },
  },
];

const complianceFields: Field[] = [
  {
    key: 'isOfficerOfPublicCompany',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'isOwnerOfPublicCompany',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'ssSymbols',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'text_array',
  },
  {
    key: 'isMemberOfIiroc',
    permission: 'client_low_risk_pii',
    column: 8,
    type: 'switch',
  },
  {
    key: 'insiderSymbols',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'text_array',
  },
  {
    key: 'politicallyExposedDomesticPerson',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'politicallyExposedForeignPerson',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'closeAssociateOfPEP',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'headOfInternationalOrganization',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'switch',
  },
  {
    key: 'powerOfAttorneyGranted',
    permission: 'client_low_risk_pii',
    column: 8,
    type: 'switch',
  },
  {
    key: 'complianceState',
    permission: 'client_low_risk_pii',
    column: 4,
    type: 'select',
    options: ['APPROVED', 'NEEDS_REVIEW', 'REJECTED'],
  },
  {
    key: 'complianceIssueSource',
    permission: 'client_low_risk_pii',
    column: 8,
  },
];

const suitabilityFields: Field[] = [
  {
    key: 'annualIncomeCents',
    permission: 'client_suitability',
    column: 4,
    type: 'cents',
  },
  {
    key: 'financialLiquidAssetsCents',
    permission: 'client_suitability',
    column: 4,
    type: 'cents',
  },
  {
    key: 'financialFixedAssetsCents',
    permission: 'client_suitability',
    column: 4,
    type: 'cents',
  },
  {
    key: 'totalDebtCents',
    permission: 'client_suitability',
    column: 4,
    type: 'cents',
  },
  {
    key: 'annualDebtPaymentsCents',
    permission: 'client_suitability',
    column: 8,
    type: 'cents',
  },
  {
    key: 'investmentKnowledge',
    permission: 'client_suitability',
    column: 4,
    type: 'select',
    options: ['NONE', 'SOME', 'LIMITED', 'EXPERT'],
  },
  {
    key: 'riskQuestion1',
    permission: 'client_suitability',
    column: 4,
    type: 'select',
    options: ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4', 'LEVEL_5'],
  },
  {
    key: 'riskQuestion2',
    permission: 'client_suitability',
    column: 4,
    type: 'select',
    options: ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4', 'LEVEL_5'],
  },
];

export const FETCH_USER = (permissions: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        ${[
    ...detailsFields, ...employmentFields, ...complianceFields, ...suitabilityFields,
  ].filter((x: Field) => permissions.includes(`read:${x.permission}`) && !x.dontFetch)
    .map((x) => {
      if (x.key === 'physicalAddress') {
        return 'physicalAddress { city country houseNumber province neighborhood postal streetName unitNumber }';
      }
      if (x.key === 'foreignTaxInformation') {
        return 'foreignTaxInformation { foreignTaxCountry foreignTaxNumber }';
      }
      return x.key;
    }).join('\n')}
        ${permissions.includes('read:client_high_risk_pii') ? 'iDVerified' : ''}
        organization {
          name
        }
        completedAt
        ${permissions.includes('read:client_suitability') ? 'suitabilityScore' : ''}
    }
    }
  }
`;

const Profile = ({ userId, baseRefetch }: { userId: string, baseRefetch: () => void }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['client']);
  const { data, refetch } = useQuery(FETCH_USER(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      userId,
    },
  });

  const [expanded, setExpanded] = usePageState('', 'panel');
  const [user, setUser] = useState({
    suitabilityScore: null,
  });

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const refetchAll = () => {
    refetch();
    baseRefetch();
  };

  if (!data) (<></>);

  useEffect(() => {
    if (data) {
      setUser(data.fetchUser.user);
    }
  }, [data]);

  return (
    <>
      {(permissions.includes('read:client_low_risk_pii') || permissions.includes('read:client_high_risk_pii')) && (
        <Accordion expanded={expanded === 'details'} onChange={handleChange('details')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
          >
            <Typography>{t('profile.details')}</Typography>
            {data && (<Chip
              size='small'
              label={data.fetchUser.user.completedAt ? t('completed') : t('notCompleted')}
              color={data.fetchUser.user.completedAt ? 'success' : 'error'}
            />)}
          </AccordionSummary>
          <AccordionDetails>
            {data && (
              <UpdateUserSection baseUser={user} refetch={refetchAll} sectionName="details" fields={detailsFields} open={expanded === 'details'} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {(permissions.includes('read:client_low_risk_pii') || permissions.includes('read:client_high_risk_pii')) && (
        <Accordion expanded={expanded === 'employment'} onChange={handleChange('employment')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('profile.employment')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data && (
              <UpdateUserSection baseUser={user} sectionName="employment" refetch={refetchAll} fields={employmentFields} open={expanded === 'employment'} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {permissions.includes('read:client_low_risk_pii') && (
        <Accordion expanded={expanded === 'compliance'} onChange={handleChange('compliance')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
          >
            <Typography>{t('profile.compliance')}</Typography>
            {data
              && <Chip
                size='small'
                label={t(`edit.complianceStateOptions.${data.fetchUser.user.complianceState}`)}
                color={data.fetchUser.user.complianceState === 'APPROVED' ? 'success' : 'error'}
              />
            }
          </AccordionSummary>
          <AccordionDetails>
            {data && (
              <UpdateUserSection baseUser={user} sectionName="compliance" refetch={refetchAll} fields={complianceFields} open={expanded === 'compliance'} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {permissions.includes('read:client_suitability') && (
        <Accordion expanded={expanded === 'suitability'} onChange={handleChange('suitability')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
          >
            <Typography>{t('profile.suitability')}</Typography>
            {data && <Chip label={`${t('suitabilityScore')}: ${data.fetchUser.user.suitabilityScore || t('notCompleted')}`} size='small' />}
          </AccordionSummary>
          <AccordionDetails>
            {data && (
              <UpdateUserSection baseUser={user} sectionName="suitability" refetch={refetchAll} fields={suitabilityFields} open={expanded === 'compliance'} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {permissions.includes('read:client_high_risk_pii') && (
        <Accordion expanded={expanded === 'verification'} onChange={handleChange('verification')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
          >
            <Typography>{t('profile.idVerification')}</Typography>
            <Chip label={data?.fetchUser?.user?.iDVerified ? t('idVerified') : t('idNotVerified')} size='small' color={data?.fetchUser?.user?.iDVerified ? 'success' : 'error'} />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {data && (
              <IDs userId={userId} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default Profile;
