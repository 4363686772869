import { useContext } from 'react';
import { EditAccount as ConfigEditAccount } from '../workflowCompletion/subSteps/editAccount/editAccount';
import { UserContext } from '../../../providers/userContextProvider';

export const EditAccount = ({ objectId, options }: { objectId: string, options: any, }) => {
  const { activeEntity, userId } = useContext(UserContext);
  return (
    <ConfigEditAccount
      userId={activeEntity?.id ?? userId}
      previousStep={() => {}}
      direction='FORWARD'
      accountId={objectId}
      options={options}
      onNext={() => {}}
      stepLoading={false}
      grid
      updateMode
    />
  );
};
