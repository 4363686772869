import Documents from 'pages/client/components/documents';
import { ClientGroup } from '../../../interfaces';

const CorporationDocuments = ({ clientGroup }: { clientGroup: ClientGroup }) => (
  <>
    {/* <HouseholdDocuments userId={allUsersIds[0]} userIds={allUsersIds} clientGroupId={clientGroup.id} /> */}
    <Documents userId={clientGroup.primaryUser.id} clientGroupId={clientGroup.id} />
  </>
);

export default CorporationDocuments;
