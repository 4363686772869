import {
  Dialog, DialogTitle, DialogContent, Chip, Typography, Grid,
} from '@mui/material';
import {
  Timeline, TimelineItem, TimelineSeparator, TimelineDot,
  TimelineConnector, TimelineContent, TimelineOppositeContent,
} from '@mui/lab';
import { DateTime } from '../misc/dateTime/dateTime';

const stateColor = (state: any) => (
  ['ACTIVE', 'RECONCILED', 'COMPLETED'].includes(state)
    ? 'success'
    : ['FAILED', 'CANCELED', 'FROZEN', 'INACTIVE'].includes(state)
      ? 'error'
      : ['INITIATED', 'CREATED'].includes(state)
        ? 'default'
        : 'warning'
);

const StateChangesModal = ({
  title, open, handleClose, stateChanges, children, id,
}: {
  title: string, open: boolean, handleClose: () => void, stateChanges: any[], children?: any, id: string
}) => (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='caption'>{id}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {children}
        <Timeline>
          { stateChanges?.map((event: any, index: number) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent color="text.secondary">
                <DateTime date={event.createdAt} />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                { stateChanges.length !== (index + 1) && (<TimelineConnector />) }
              </TimelineSeparator>
              <TimelineContent><Chip size='small' label={event.to} color={stateColor(event.to)} /></TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </Dialog>
);

export default StateChangesModal;
