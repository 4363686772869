/**
 * Lazy-loads more javascript/css files in parallel
 *
 * @param scripts List of script file URsI, ie. ['/scripts/someSpecificScript.js']
 * @returns a promise to be awaited
 */
export const loadScriptsConcurrently = async (scripts: string[]) => Promise.all(scripts.map((src) => loadScript(src)));

/**
 * Lazy-loads any javascript/css file on the fly, appending it into `<head>...</head>`.
 * Subsequent calls with same `src` have no effect.
 *
 * @param src script file URI, ie. /scripts/someSpecificScript.js
 */
export function loadScript(src: string): Promise<void> {
  const head = document.getElementsByTagName('head')[0];

  const uniqueId = `lazyload:${src}`;
  const existingElement = document.getElementById(uniqueId);
  if (existingElement) {
    // Script is already fully loaded: no action
    if (existingElement.dataset.loading !== 'loading') {
      return Promise.resolve();
    }

    // Script is already being loaded in another thread: hook their onLoad() and wait
    return new Promise((resolve, reject) => {
      existingElement.addEventListener('load', () => resolve());
    });
  }

  let newElement: HTMLScriptElement | HTMLLinkElement;
  if (src.endsWith('.js')) {
    newElement = document.createElement('script');
    newElement.type = 'text/javascript';
    newElement.src = src;
  } else if (src.endsWith('.css')) {
    newElement = document.createElement('link');
    newElement.rel = 'stylesheet';
    newElement.href = src;
  } else {
    return Promise.reject();
  }
  newElement.id = uniqueId;
  newElement.dataset.loading = 'loading';

  return new Promise((resolve, reject) => {
    newElement.onload = () => {
      newElement.dataset.loading = undefined;
      resolve();
    };

    newElement.onerror = () => {
      reject(new Error(`Error when loading script: ${src}`));
      newElement.remove();
    };

    head.appendChild(newElement);
  });
}
