import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { FETCH_HOUSEHOLD } from 'pages/household';
import { StepProps } from '../wizard';
import { AccountTypes } from '../../../../interfaces';
import { NewAccountSummaryActionStepResult } from './newAccountActionStepResult';
import { visibleFields } from './chooseAccountTypeStep';
import { usePermissions } from '../../../../providers/userContextProvider';
import { FETCH_USER } from '../../../../pages/client';

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        id
        type
        applyForGovFunds
        householdClientGroup {
          id
          name
        }
        corporationClientGroup {
          id
          name
        }
        sourceOfFunds
        jurisdiction
        custodianAccountNumber
        user { firstName middleName lastName }
      }
    }
  }
`;

export function NewAccountActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const { permissions } = usePermissions();
  const [createAccount, {
    loading, called, data, error,
  }] = useMutation(CREATE_ACCOUNT);

  const generateGovFunds = (): string[] => {
    const govFunds = [];
    if (state.isAdditionalCesg) {
      govFunds.push('ADDITIONAL_CESG');
    }
    if (state.isBasicCesg) {
      govFunds.push('BASIC_CESG');
    }
    if (state.isClb) {
      govFunds.push('CLB');
    }
    return govFunds;
  };
  const isGovFundsAvailable = visibleFields(state.accountType as AccountTypes).includes('ADDITIONAL_CESG')
    || visibleFields(state.accountType as AccountTypes).includes('BASIC_CESG')
    || visibleFields(state.accountType as AccountTypes).includes('CLB');

  /* run the mutation as soon as permissions are loaded */
  useEffect(() => {
    if (permissions.length === 0) return;
    if (called) return;

    doCreateAccount();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, permissions]);

  const doCreateAccount = () => {
    const accountprops = {
      type: state.accountType,
      sourceOfFunds: visibleFields(state.accountType as AccountTypes).includes('sourceOfFunds') ? state.sourceOfFunds : undefined,
      jurisdiction: visibleFields(state.accountType as AccountTypes).includes('jurisdiction') ? state.jurisdiction : undefined,
      applyForGovFunds: isGovFundsAvailable ? generateGovFunds() : undefined,
    };

    mutationEvent('started');

    createAccount({
      variables: {
        input: {
          userId: context.forObject === 'USER' ? context.forId : state.householdMember,
          householdClientGroupId: context.forObject === 'CLIENT_GROUP' ? context.forId : undefined,
          corporationClientGroupId: state.corporationClientGroupId,
          ...accountprops,
        },
      },
      refetchQueries: [FETCH_USER(permissions), FETCH_ACCOUNTS(permissions), FETCH_HOUSEHOLD(permissions)],
      onCompleted: () => mutationEvent('succeeded'),
      onError: () => mutationEvent('failed'),
    });
  };

  return (
    <NewAccountSummaryActionStepResult
      state={state}
      context={context}
      continueTo={continueTo}
      loading={loading}
      succeeded={!!data}
      account={data?.createAccount.account}
      error={error}
    />
  );
}
