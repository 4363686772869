import {
  createContext, useContext,
} from 'react';
import { merge } from 'lodash/fp';
import { Tokens, useTokens } from '../ovComponents/0-tokens';
import { UserContext } from './userContextProvider';

// eslint-disable-next-line react-hooks/rules-of-hooks
const TokenContext = createContext<Tokens>(useTokens({}));

const ThemeTokenContextProvider = ({ children }: { children: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const override = merge({ ref: {}, sys: {}, comp: {} }, activeOrganization.themeTokens);
  const tokens = useTokens(override);

  return (
    <TokenContext.Provider value={tokens}>
      {children}
    </TokenContext.Provider>
  );
};

const ThemeTokenContextConsumer = TokenContext.Consumer;

const useThemeTokens = (compTokens?: any): Tokens => {
  const { activeOrganization } = useContext(UserContext);
  const tokens = useContext(TokenContext);

  return { ...tokens, comp: merge(compTokens, activeOrganization.themeTokens?.comp || {}) };
};

export {
  TokenContext, ThemeTokenContextConsumer, useThemeTokens,
};

export default ThemeTokenContextProvider;
