import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Box, ActionContext,
} from '../../../..';
import { TransferContext } from '../../../withdrawWorkflow/withdrawWorkflow';
import { getSubAccountName } from '../../../../../interfaces';
import { formatMoneyValue } from '../../../../../util';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import {
  CREATE_SCHEDULED_WITHDRAW_TRANSFER,
  CREATE_WITHDRAW_ALL_TRANSFER,
  CREATE_WITHDRAW_TRANSFER,
} from './withdrawReview.queries';
import { WorkflowContext } from '../../workflowCompletion';

export const WithdrawReview = ({
  options, onNext, dummyWithdraw, setDummyWithdraw,
}: {
  options: any, onNext: any, dummyWithdraw?: any, setDummyWithdraw?: any,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'transfer']);
  const { transferData } = useContext(TransferContext);
  const { workflowData } = useContext(WorkflowContext);
  const applicableData = dummyWithdraw ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);

  const { refetch, refreshWidgets } = useContext(ActionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useGlobalToast();

  const [createWithdrawTransfer] = useMutation(CREATE_WITHDRAW_TRANSFER);
  const [createScheduledWithdrawTransfer] = useMutation(CREATE_SCHEDULED_WITHDRAW_TRANSFER);
  const [createWithdrawAllTransfer] = useMutation(CREATE_WITHDRAW_ALL_TRANSFER);

  const fullTransfer = () => applicableData?.subAccount?.statistics?.marketValueCents && applicableData.amountCents > (applicableData?.subAccount?.statistics?.marketValueCents || 0) * 0.97;

  const submit = () => {
    setLoading(true);
    if (applicableData.frequency !== 'ONE_TIME') {
      createScheduledWithdrawTransfer({
        variables: {
          input: {
            bankAccountId: applicableData.bankAccount.id,
            subAccountId: applicableData.subAccount.id,
            amountCents: applicableData.amountCents,
            frequency: applicableData.frequency,
            scheduledDate: applicableData.scheduledDate,
            withdrawalReason: applicableData.withdrawalReason,
          },
        },
        onCompleted: () => {
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      }).then();
    } else if (fullTransfer()) {
      createWithdrawAllTransfer({
        variables: {
          input: {
            bankAccountId: applicableData.bankAccount.id,
            subAccountId: applicableData.subAccount.id,
          },
        },
        onCompleted: () => {
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      });
    } else if (applicableData.frequency === 'ONE_TIME' && applicableData?.scheduledDate) {
      createScheduledWithdrawTransfer({
        variables: {
          input: {
            bankAccountId: applicableData.bankAccount.id,
            subAccountId: applicableData.subAccount.id,
            amountCents: applicableData.amountCents,
            frequency: applicableData.frequency,
            scheduledDate: applicableData.scheduledDate,
            withdrawalReason: applicableData.withdrawalReason,
          },
        },
        onCompleted: () => {
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      }).then();
    } else {
      createWithdrawTransfer({
        variables: {
          input: {
            amountCents: applicableData.amountCents,
            bankAccountId: applicableData.bankAccount.id,
            subAccountId: applicableData.subAccount.id,
            withdrawalReason: applicableData.withdrawalReason,
          },
        },
        onCompleted: () => {
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      });
    }
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.fromSubAccount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(applicableData?.subAccount)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.toBankAccount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{`${applicableData?.bankAccount?.name} ${applicableData?.bankAccount?.bankAccountNumber}`}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.amount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(applicableData?.amountCents)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:withdrawReview.frequency')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`transfer:frequencyOption.${applicableData?.frequency}`)}</Typography>
      </Box>
      {
        applicableData.frequency !== 'ONE_TIME' && (
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('workflowCompletions:withdrawReview.startsOn')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{applicableData?.scheduledDate}</Typography>
          </Box>
        )
      }
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.reason')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`transfer:withdrawal.withdrawalReasonOptions.${applicableData?.withdrawalReason}`)}</Typography>
      </Box>
      <Box display='flex' justifyContent='end'>
        <Button label={t('workflowCompletions:depositReview.createWithdraw')} sx={{ mt: 3, textAlign: 'center' }} disabled={loading} onClick={submit} />
      </Box>
    </>
  );
};

export default WithdrawReview;
