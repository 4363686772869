import { SxProps } from '@mui/material';
import { SysTokens } from '../../../ovComponents';

export const editorStyle = (sys: SysTokens): SxProps => ({
  boxSizing: 'border-box',
  border: '1px solid #AAB4BF',
  borderRadius: '8px',
  cursor: 'text',
  padding: '16px',
  minHeight: '160px',
  width: '100%',
  '.public-DraftEditor-content': {
    maxHeight: '200px',
    overflow: 'auto',
    fontFamily: sys.font.base,
    '.RichEditor-blockquote': {
      borderLeft: '5px solid #eee',
      color: '#666',
      fontFamily: sys.font.base,
      fontStyle: 'italic',
      margin: '16px 0',
      padding: '5px 20px',
    },
    '.public-DraftStyleDefault-pre': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: sys.font.base,
      fontSize: '16px',
      padding: '2px',
    },
    '.align-right': {
      textAlign: 'right',
    },
    '.align-center': {
      textAlign: 'center',
    },
    '.align-left': {
      textAlign: 'left',
    },
  },
  '>label': {
    zIndex: 1,
    background: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

export const toolbarStyle: SxProps = {
  position: 'relative',
  marginTop: '80px',
  zIndex: 2,
  height: '20px',
  alignItems: 'center',
  display: 'flex',
};
