import { useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CREATE_ENTITY as CREATE_NON_INDIVIDUAL_ENTITY, UserContext } from 'providers/userContextProvider';
import { useAuthContext } from 'providers/ovApolloProvider';
import { useNavigate } from 'react-router-dom';
import NonIndividualInformationVisual from './nonIndividualSetup.visual';
import {
  EntityTypes, Languages, NavigationStyles, User,
} from '../../../../../interfaces';
import { delay } from '../../../../../util';
import { auth0RefreshTokenSilently } from '../../../../../providers/auth0Client';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        primaryEmail
        language
        organization { id }
      }
    }
  }
`;

export const NonIndividualSetup = ({
  options, userId, onNext, stepLoading, workflowCompletionId, workflowCompletion,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletionId: string,
  workflowCompletion?: any,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userContext, setActiveEntity } = useContext(UserContext);
  const { setAuthToken } = useAuthContext();

  const { data, loading: fetchLoading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [createEntity, { loading: createEntityLoading }] = useMutation(CREATE_NON_INDIVIDUAL_ENTITY);

  const createNonIndividualEntity = () => {
    const user: Partial<User> = data?.fetchUser?.user;
    setLoading(true);
    createEntity({
      variables: {
        input: {
          primaryEmail: user?.primaryEmail,
          language: user?.language ?? Languages.ENGLISH,
          organizationId: user?.organization?.id,
          type: options.defaultEntityType || EntityTypes.PRIVATE_COMPANY,
        },
      },
      onCompleted: async (resp) => {
        if (resp.createUser.user.id) {
          // TODO: add GraphQL subscription to confirm it's ready to redirect user
          await delay(2000);
          const newToken = await auth0RefreshTokenSilently({
            redirectToPath: `/clients/${resp.createUser.user.id}?autoOpenWorkflow=true`,
            orgSubdomain: userContext.organization?.subdomain,
          });
          if (newToken) setAuthToken(newToken);
          if (userContext.role?.navigationStyle === NavigationStyles.FULL) {
            navigate(`/nonIndividualClients/${resp.createUser.user.id}?autoOpenWorkflow=true`);
          } else {
            setActiveEntity(resp.createUser.user);
          }
          onNext();
        }
      },
    });
  };

  return (
    <NonIndividualInformationVisual
      options={options}
      onNext={onNext}
      createNonIndividualEntity={createNonIndividualEntity}
      loading={loading || fetchLoading || createEntityLoading || stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default NonIndividualSetup;
