import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Icon, Typography } from '../../../../../../1-primative';
import { Transfer } from '../../../../../../../interfaces';
import { Card, CardContent, IconButton } from '../../../../../../2-component';
import { BankAccountTransfer } from './bankAccountTransfer.dialogContent';

interface BankAccountTransferReviewItemProps {
  transfer: Transfer | BankAccountTransfer,
  onDeleteTransfer: () => void,
}
const BankAccountTransferReviewItem = ({ transfer, onDeleteTransfer }: BankAccountTransferReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'accountTypes']);
  const defineTitle = (): string => t(
    'workflowCompletions:bankAccountTransferReviewPanel.title',
    {
      amount: `$${(transfer.amountCents / 100).toLocaleString('en-US')}`,
      accountType: t(`accountTypes:${transfer?.subAccount?.account?.type ?? ''}`),
    },
  );
  const getBankName = (): string => transfer.bankAccount?.name ?? '';
  const getInstitutionNumber = (): string => transfer.bankAccount?.institutionNumber ?? '';
  const getTransitNumber = (): string => transfer.bankAccount?.transitNumberDisclosed ?? transfer.bankAccount?.transitNumber ?? '';
  const getBankAccountNumber = (): string => transfer.bankAccount?.bankAccountNumberDisclosed ?? transfer.bankAccount?.bankAccountNumber ?? '';
  const getFrequency = (): string => {
    if ('frequency' in transfer) {
      return t(`client:frequency.${transfer.frequency}`);
    }
    return '';
  };
  const isRecurring = () => {
    if ('isRecurring' in transfer) return transfer.isRecurring;
    return false;
  };
  const getIsRecurring = (): string => (isRecurring() ? 'Yes' : 'No');
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box pl={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
          <Typography variant='bodyLarge' sx={{ mt: 1, fontWeight: '600' }}>
              {defineTitle()}
          </Typography>
          {
              'id' in transfer && transfer.id ? undefined : <IconButton onClick={() => onDeleteTransfer()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.type')}</Typography>
            <Typography variant='labelLarge'>{t('workflowCompletions:bankAccountTransferReviewPanel.deposit')}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.bankAccount')}</Typography>
          <Typography variant='labelLarge'>{getBankName()}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.institutionNumber')}</Typography>
            <Typography variant='labelLarge'>{getInstitutionNumber()}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.transitNumber')}</Typography>
          <Typography variant='labelLarge'>{getTransitNumber()}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.accountNumber')}</Typography>
            <Typography variant='labelLarge'>{getBankAccountNumber()}</Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.recurring')}</Typography>
            <Typography variant='labelLarge'>{getIsRecurring()}</Typography>
        </Box>
        {
          isRecurring() ? (
            <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.frequency')}</Typography>
              <Typography variant='labelLarge'>{getFrequency()}</Typography>
            </Box>
          ) : undefined
        }
        {
          isRecurring() ? (
            <Box sx={{ mt: 2, mb: 1, px: '8px' }} display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography variant='bodySmall' weight='bold'>{t('workflowCompletions:bankAccountTransferReviewPanel.startDate')}</Typography>
              <Typography variant='labelLarge'>{transfer.scheduledDate?.toString() ?? ''}</Typography>
            </Box>
          ) : undefined
        }
      </CardContent>
    </Card>
  );
};

export default BankAccountTransferReviewItem;
