import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const FundAllocation = ({ options }: { options?: any }) => {
  useRefinitivWidget('#fund-allocation-widget', 'WFundAllocation', options);

  return (
    <Card>
      <div id='fund-allocation-widget'></div>
    </Card>
  );
};
