import {
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountsTable from './components/accountsTable';

const AccountsReview = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <AccountsTable />
    </Paper>
  );
};

export default AccountsReview;
