import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '../../../1-primative';
import {
  Button,
  TextField,
  TranslatableTextField,
} from '../../../2-component';
import { DroppableFileInput, FileTypes } from '../../../2-component/droppableFileInput/droppableFileInput';
import { TranslatedString } from '../../../../interfaces';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';

export interface Article {
  id?: string,
  articleUrl: string,
  translatedName: TranslatedString,
  translatedDescription: TranslatedString
}

export const CreateAndEditNewsAndInsightsForm = ({
  imageSrc, resetFileImageAndSrc, handleFileInputChange, article,
  setArticleToUpdate, errorFields, handleValidationClear,
}: {
  imageSrc: string | null, resetFileImageAndSrc: any, handleFileInputChange: (file: File) => void, article: Article,
  setArticleToUpdate: any, errorFields: string[], handleValidationClear: (value: string, field: string) => void,
}) => {
  const { t } = useTranslation('newsAndInsight');
  const { sys } = useThemeTokens();
  return (
      <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant='bodyLarge' mb={1}>{t('newsAndInsight:imageOptional')}</Typography>
              {
                  imageSrc && (<Button size='sm' sx={{ mb: 1 }} onClick={resetFileImageAndSrc} variant='text' label={t('newsAndInsight:remove')} trailingIcon={DeleteIcon} />)
              }
          </Box>
          {
              !imageSrc && (<DroppableFileInput acceptedTypes={[FileTypes.PNG, FileTypes.JPG, FileTypes.JPEG]} onFileChosen={(file) => handleFileInputChange(file)} />)
          }
          {
              imageSrc && (<img src={imageSrc} alt='articleImage' width='100%' />)
          }
          <TranslatableTextField
              label={t('newsAndInsight:title')}
              fullWidth
              sx={{ marginTop: sys.spacing.xxl, marginBottom: sys.spacing.xxl }}
              value={article.translatedName}
              error={errorFields.includes('translatedName')}
              errorText={t('newsAndInsight:errorStateMessage')}
              onChange={((translatedString: TranslatedString) => {
                handleValidationClear(translatedString.en ?? '', 'translatedName');
                setArticleToUpdate({
                  ...article,
                  translatedName: translatedString,
                });
              })}
          />
          <TextField
              label={t('newsAndInsight:linkToArticle')}
              fullWidth
              sx={{ marginBottom: sys.spacing.xxl }}
              value={article.articleUrl}
              error={errorFields.includes('articleUrl')}
              errorText={t('newsAndInsight:errorUrlStateMessage')}
              onChange={(e: any) => {
                handleValidationClear(e.target.value, 'articleUrl');
                setArticleToUpdate({
                  ...article,
                  articleUrl: e.target.value,
                });
              }}
          />
          <TranslatableTextField
              label={t('newsAndInsight:summary')}
              fullWidth
              multiline
              rows={4}
              sx={{ marginBottom: sys.spacing.xxl }}
              value={article.translatedDescription}
              error={errorFields.includes('translatedDescription')}
              errorText={t('newsAndInsight:errorStateMessage')}
              onChange={(translatedString: TranslatedString) => {
                handleValidationClear(translatedString.en ?? '', 'translatedDescription');
                setArticleToUpdate({
                  ...article,
                  translatedDescription: translatedString,
                });
              }}
          />
      </>
  );
};
