import { CopyAll } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const CopyId = ({ id }: { id: string }) => {
  const { t } = useTranslation('shared');
  const { sys } = useThemeTokens();
  const { showToast } = useGlobalToast();

  return (
    <Box sx={{
      background: sys.color.background,
      padding: '0px 16px',
      height: '32px',
      borderRadius: sys.borderRadius.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: sys.color.neutralPressed,
      },
    }} onClick={() => {
      navigator.clipboard.writeText(id);
      showToast({ severity: 'success', message: t('copiedToClipboard') });
    }}>
      <Typography variant='labelLarge'>{id}</Typography>
      <CopyAll sx={{ fontSize: '16px', ml: 1 }}/>
    </Box>
  );
};
