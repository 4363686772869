import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  GenericStepProps,
  StepComponent,
  WizardBase,
  WizardControlProps,
} from '../wizardBase';
import { SummaryStep } from './steps/summaryStep';
import { FirstDocumentStep } from './steps/firstDocumentStep';
import { SecondDocumentStep } from './steps/secondDocumentStep';

type Step = 'FIRST_DOCUMENT_STEP' | 'SECOND_DOCUMENT_STEP' | 'SUMMARY_STEP';

const steps: Record<Step, StepComponent> = {
  FIRST_DOCUMENT_STEP: FirstDocumentStep,
  SECOND_DOCUMENT_STEP: SecondDocumentStep,
  SUMMARY_STEP: SummaryStep,
};

interface WizardContext {
  userId: string,
}

export type IdDocumentType =
  'DIGITAL_DOCUMENT_CHECK' |
  'DUAL_METHOD' |
  'IN_PERSON_VALIDATION';

interface WizardState {
  methodOfIDVerification: IdDocumentType
  documentOne?: {
    documentID: string,
    documentIssuingJurisdication: string,
    documentExpiryDate: string,
    documentType: string,
    documentName: string,
    userName: string,
    file?: File,
  }
  documentTwo?: {
    secondaryDocumentID: string,
    secondaryDocumentIssuingJurisdication: string,
    secondaryDocumentExpiryDate: string,
    secondaryDocumentType: string,
    documentName: string,
    secondaryDocumentUserName: string,
    file?: File,
  }
  verifiedDate: string,
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

export interface CreateIdVerificationWizardProps {
  userId: string,
}

export function CreateIdVerificationWizard({
  userId, handleClose,
}: CreateIdVerificationWizardProps & WizardControlProps) {
  const { t } = useTranslation('addIdVerificationWizard');
  const initial: WizardState = {
    methodOfIDVerification: 'DUAL_METHOD',
    documentOne: {
      documentID: '',
      documentIssuingJurisdication: '',
      documentExpiryDate: '',
      documentType: '',
      documentName: '',
      userName: '',
      file: undefined,
    },
    documentTwo: {
      secondaryDocumentID: '',
      secondaryDocumentIssuingJurisdication: '',
      secondaryDocumentExpiryDate: '',
      secondaryDocumentType: '',
      secondaryDocumentUserName: '',
      documentName: '',
      file: undefined,
    },
    verifiedDate: dayjs().format('YYYY-MM-DD'),
  };

  return (
    <WizardBase
      title={t('title')}
      context={{
        userId,
      }}
      steps={steps}
      firstStep={'FIRST_DOCUMENT_STEP'}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
