import {
  Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination,
  Tooltip, Chip, Menu, MenuItem, IconButton,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModal from 'components/modals/confirmationModal';
import { Typography } from '../../../ovComponents';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { Localization } from '../../../interfaces';
import NewLocalization from './newLocalization';
import EditLocalization from './editLocalization';
import { getCountryName } from '../../../ovComponents/resources';
import { FETCH_LOCALIZATIONS_BY_ORG } from '../../orgDetails';

export const FETCH_LOCALIZATIONS = gql`
  query fetchLocalizations($input: FetchLocalizationsInput!) {
    fetchLocalizations(input: $input) {
      localizations {
        id
        organization { id name }
        name
        countries
        defaultCurrency
        languages
        defaultLanguage
        dateFormat
      }
      totalCount
    }
  }
`;

const DELETE_LOCALIZATION = gql`
  mutation deleteLocalization($localizationId: ObjectID!) {
    deleteLocalization(localizationId: $localizationId)
  }
`;

const LocalizationSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const { t } = useTranslation('orgSettings');
  const [selectedRecord, setSelectedRecord] = useState<Localization | null>(null);
  const [selectedRecordMenuAnchor, setSelectedRecordMenuAnchor] = useState<null | HTMLElement>(null);
  const [deletedRecord, setDeletedRecord] = useState<Localization | null>(null);

  const { loading, data, previousData } = useQuery(FETCH_LOCALIZATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          perPage: pageSize, offSet: (page - 1) * pageSize, sortField: 'name', sortDesc: false,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteLocalization] = useMutation(DELETE_LOCALIZATION, { refetchQueries: [FETCH_LOCALIZATIONS, FETCH_LOCALIZATIONS_BY_ORG] });

  const doDelete = (localizationId: string) => {
    deleteLocalization({ variables: { localizationId } });
  };

  const languageName = (lang: string) => t(`localizationSettings.language.${lang}`);

  const isInherited = (l: Localization) => l.organization?.id !== activeOrganization.id;

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant='titleLarge'>{t('tabs.localizationSettings')}</Typography>
        </Grid>
        {permissions.includes('write:localizations') && (
          <Grid item xs={3}>
            <NewLocalization />
          </Grid>
        )}
      </Grid>

      <Box >
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 30 }} />
          </Box>
        ) : (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant='headingOverline'>{t('shared:name')}</Typography></TableCell>
                  <TableCell><Typography variant='headingOverline'>{t('localizationSettings.countries')}</Typography></TableCell>
                  <TableCell><Typography variant='headingOverline'>{t('localizationSettings.defaultCurrency')}</Typography></TableCell>
                  <TableCell><Typography variant='headingOverline'>{t('localizationSettings.languages')}</Typography></TableCell>
                  <TableCell><Typography variant='headingOverline'>{t('localizationSettings.dateFormat')}</Typography></TableCell>
                  <TableCell><Typography variant='headingOverline'>{t('shared:organization')}</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.fetchLocalizations?.localizations?.map((localization: Localization, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {
                      setSelectedRecord(localization);
                      setUpdateDialogOpen(true);
                    }}
                  >
                    <TableCell component="th" scope="row">{localization.name}</TableCell>
                    <TableCell>
                      {localization.countries.map(getCountryName).join(', ')}
                    </TableCell>
                    <TableCell>{localization.defaultCurrency}</TableCell>
                    <TableCell>
                      {localization.languages.length === 1 && languageName(localization.languages[0])}
                      {localization.languages.length > 1
                        && localization.languages.map((lang) => (<span key={lang}>
                          {languageName(lang)}
                          {lang === localization.defaultLanguage && <> ({t('localizationSettings.default')})</>}
                          <br />
                        </span>))
                      }
                    </TableCell>
                    <TableCell>
                      <code>{localization.dateFormat}</code>
                      {localization.dateFormat
                        && <Typography color='gray'>
                          <i>{dayjs().format(localization.dateFormat)}</i>
                        </Typography>
                      }
                    </TableCell>
                    <TableCell>
                      {isInherited(localization)
                        ? (<Tooltip title={t('shared:inheritedTooltip', { model: t('tabs.localizationSettings'), organization: localization.organization?.name })}>
                          <Chip size='medium' label={t('shared:inheritedFlag')} />
                        </Tooltip>)
                        : (localization.organization?.name)}
                    </TableCell>
                    <TableCell onClick={(e) => { e.stopPropagation(); }}>
                      <IconButton size='small'
                        onClick={(e) => {
                          setSelectedRecord(localization);
                          setSelectedRecordMenuAnchor(e.currentTarget);
                        }}
                      ><MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchLocalizations?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </>
        )}
      </Box>

      {/* Context per-row menu */}
      <Menu
        anchorEl={selectedRecordMenuAnchor}
        open={!!selectedRecordMenuAnchor}
        onClose={() => setSelectedRecordMenuAnchor(null)}
        onClick={() => setSelectedRecordMenuAnchor(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography sx={{ p: 2 }}>{selectedRecord?.id}</Typography>
        <MenuItem
          disabled={!!selectedRecord && isInherited(selectedRecord)}
          onClick={() => setDeletedRecord(selectedRecord)}><DeleteIcon />&nbsp; {t('shared:delete')}
        </MenuItem>
      </Menu>

      {/* per-row editing dialog */}
      {updateDialogOpen && selectedRecord && (
        <EditLocalization
          localization={selectedRecord}
          handleClose={() => setUpdateDialogOpen(false)}
          inherited={isInherited(selectedRecord)}
        />
      )}

      {/* Deletion - confirmation modal */}
      {deletedRecord && <ConfirmationModal
        open
        loading={false}
        title={t('localizationSettings.deleteConfirmationModal.title')}
        onConfirm={async () => {
          doDelete(deletedRecord.id);
          setDeletedRecord(null);
        }}
        onCancel={() => setDeletedRecord(null)}
        confirmButton={t('localizationSettings.deleteConfirmationModal.confirm')}
        cancelButton={t('localizationSettings.deleteConfirmationModal.cancel')}
      >
        <Typography my={1}>
          {t('shared:name')}: <b>{deletedRecord.name}</b>
        </Typography>
        <Typography my={1}>
          {t('localizationSettings.countries')}: {deletedRecord.countries.map(getCountryName).join(', ')}
        </Typography>
        <Typography my={1}>
          {t('localizationSettings.defaultCurrency')}: {deletedRecord.defaultCurrency}
        </Typography>
      </ConfirmationModal>
      }
    </>
  );
};

export default LocalizationSettings;
