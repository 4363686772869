import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Divider from '@mui/material/Divider';
import { FormGroup } from '@mui/material';
import {
  AccountStates,
  AccountTypes,
  Affiliation,
  AffiliationRelations,
  AffiliationTypes,
  ApplyForGovFunds,
  DraftAccount,
  Goal,
  RespFamilyPrimaryBeneficiaryRelations,
} from 'interfaces';
import {
  Typography, Grid,
} from 'ovComponents/1-primative';
import {
  Button,
  Checkbox,
  Form,
  HelpText,
  IconButton,
  Radio,
  RadioGroup,
  SelectField,
  MenuItem,
} from 'ovComponents/2-component';
import { colors } from 'theme/colors';
import { ClientSelectField } from 'ovComponents/2-component/clientSelectField/clientSelect';
import { BeneficiaryTable } from 'ovComponents/3-pattern';
import {
  validateFields, FormErrors, FieldOptions,
} from 'ovComponents/4-module/workflowCompletion/subSteps/utils';
import { upsertAffiliation } from '../../utils';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';

interface RespFamilyDialogContentInterface {
  userData: any,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

const initAffiliation: Partial<Affiliation> = {
  allocation: 0,
  relation: AffiliationRelations.Child,
  type: AffiliationTypes.PrimaryBeneficiary,
  user: undefined,
};

export const RespFamilyDialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: RespFamilyDialogContentInterface) => {
  const { t } = useTranslation(['affiliationTypes', 'components', 'workflowCompletions']);
  const [yes, no] = [t('components:booleanOptions.yes'), t('components:booleanOptions.no')];
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const [applyForGovFunds, setApplyForGovFunds] = useState<ApplyForGovFunds[]>([]);
  const [affiliations, setAffiliations] = useState<Affiliation[]>([]);
  const [affiliation, setAffiliation] = useState<Partial<Affiliation>>(initAffiliation);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const goals = getValidGoals(userData);
  const clearPreviousAffiliation = () => setAffiliation({ ...initAffiliation, user: affiliation.user });
  const clearFocused = (fieldsToClear?: string[]) => setFocused((prevState) => (
    fieldsToClear === undefined
      ? []
      : prevState.filter((field) => !fieldsToClear?.includes(field))
  ));
  const handleApplyForGovFundSelect = (govFund: ApplyForGovFunds, checked: boolean) => {
    setFocused([...focused, 'applyForGovFunds']);
    if (checked) {
      setApplyForGovFunds((prevState) => ([...prevState, govFund]));
    } else {
      setApplyForGovFunds([...applyForGovFunds.filter((item) => item !== govFund)]);
    }
  };
  const isNotSelfRelation = useCallback((aff: Affiliation) => userData.id && aff?.user?.id !== userData?.id, [userData.id]);
  const hasBeneficiary = !!affiliations.find(isNotSelfRelation);
  const userIsARelation = (relation: AffiliationRelations) => !!affiliations.find((item) => item.relation === relation && item.user?.id === userData.id);
  const handleUserAsGuardianOrCareGiver = (relation: AffiliationRelations, checked: boolean) => {
    if (checked) {
      const userAffiliation = {
        relation,
        type: AffiliationTypes.Other,
        user: userData,
      };
      setAffiliations((prevState) => ([...prevState, userAffiliation]));
    } else {
      setAffiliations([...affiliations.filter((item) => item.user?.id !== userData.id || item.relation !== relation)]);
    }
  };

  const fieldOptions: FieldOptions = useMemo(() => ({
    applyForGovFunds: { required: true },
    primaryBeneficiary: { required: true },
    affiliationUser: { required: true },
    affiliationType: { required: true },
    affiliationRelation: { required: true },
  }), []);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const data = {
      applyForGovFunds,
      primaryBeneficiary: affiliations.filter(isNotSelfRelation),
      affiliationUser: hasBeneficiary || affiliation.user,
      affiliationType: hasBeneficiary || affiliation.type,
      affiliationRelation: hasBeneficiary || affiliation.relation,
    };
    const newErrors = validateFields(fieldOptions, data, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [affiliation, affiliations, applyForGovFunds, fieldOptions, hasBeneficiary, isNotSelfRelation]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    onAccountCreated({
      type: AccountTypes.RESP_FAMILY,
      applyForGovFunds,
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      affiliations,
      goalsLinked,
    });
  };
  const addAffiliation = (): void => {
    const fieldsToCheck = ['affiliationUser', 'affiliationType', 'affiliationRelation'];
    const formErrors = validate(fieldsToCheck);
    if (formErrors) {
      setFocused([...focused, ...Object.keys(formErrors)]);
      return;
    }
    setAffiliations((prevState) => upsertAffiliation(affiliation as Affiliation, prevState));
    clearPreviousAffiliation();
    clearFocused(fieldsToCheck);
  };

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  return (
    <Form onSubmit={submit}>
      <Grid container gap={3}>
        <Grid item container>
          <Grid item xs={12} display='flex' justifyContent='end'><IconButton onClick={onDialogClose} aria-label="close" size='medium'><CloseRoundedIcon /></IconButton></Grid>
          <Grid item xs={12}><Typography variant='headingMedium'>{t('workflowCompletions:respAccountCreation.respFamilyTitle')}</Typography></Grid>
        </Grid>
        <Grid item xs={12}><Typography variant='bodyLarge'>{t('workflowCompletions:respAccountCreation.subtitle')}</Typography></Grid>
        <Grid item container gap={1}>
          <Grid item xs={12}>
            <FormGroup row sx={{ '&>*': { mr: 3 } }}>
              <Checkbox
                label={t('workflowCompletions:respAccountCreation.basicCanadaEducationSavingsGrant')}
                checked={applyForGovFunds.includes(ApplyForGovFunds.BASIC_CESG)}
                onChange={(checked) => handleApplyForGovFundSelect(ApplyForGovFunds.BASIC_CESG, checked)}
              />
              <Checkbox
                label={t('workflowCompletions:respAccountCreation.additionalCESG')}
                checked={applyForGovFunds.includes(ApplyForGovFunds.ADDITIONAL_CESG)}
                onChange={(checked) => handleApplyForGovFundSelect(ApplyForGovFunds.ADDITIONAL_CESG, checked)}
              />
              <Checkbox
                label={t('workflowCompletions:respAccountCreation.canadaLearningBond')}
                checked={applyForGovFunds.includes(ApplyForGovFunds.CLB)}
                onChange={(checked) => handleApplyForGovFundSelect(ApplyForGovFunds.CLB, checked)}
              />
            </FormGroup>
          </Grid>
          {errors?.applyForGovFunds && <Grid item xs={12}><HelpText tone='error' text={errors?.applyForGovFunds?.message} /></Grid>}
          <Grid item xs={12}><Divider variant='fullWidth' /></Grid>
        </Grid>
        <Grid item container xs={12} gap={1}>
          <Grid item xs={12}><Typography variant='headingXSmall'>{t('workflowCompletions:respAccountCreation.addAnAffiliate')}</Typography></Grid>
          { hasBeneficiary
            ? <BeneficiaryTable
              hideAllocation
              beneficiaries={affiliations}
              setBeneficiaries={(items) => setAffiliations([...items])}
            />
            : <Typography variant='monospacedLarge' sx={{ color: errors?.primaryBeneficiary ? colors.error : colors.neutral600 }}>
              {t('workflowCompletions:respAccountCreation.respAccountsMustHaveAtLeastOneAffiliate')}
            </Typography>
          }
        </Grid>
        <Grid item container xs={12} gap={3}>
          <Grid item xs={12}>
            <ClientSelectField
              label={t('workflowCompletions:respAccountCreation.name')}
              user={affiliation.user as any}
              setUser={(item) => setAffiliation((prevState) => ({ ...prevState, user: item }))}
              onBlur={() => setFocused([...focused, 'affiliationUser'])}
              fullWidth
              error={!!errors?.affiliationUser}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              onChange={(e: any) => setAffiliation((prevState) => ({ ...prevState, type: e.target.value }))}
              onBlur={() => setFocused([...focused, 'affiliationType'])}
              label={t('workflowCompletions:respAccountCreation.affiliationType')}
              fullWidth
              value={affiliation.type}
              error={!!errors?.affiliationType}
            >
              <MenuItem value='PRIMARY_BENEFICIARY'>{t('affiliationTypes:PRIMARY_BENEFICIARY')}</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              onChange={(e: any) => setAffiliation((prevState) => ({ ...prevState, relation: e.target.value }))}
              label={t('workflowCompletions:respAccountCreation.relationshipToAccountHolder')}
              fullWidth
              value={affiliation.relation}
              onBlur={() => setFocused([...focused, 'affiliationRelation'])}
              error={!!errors?.affiliationRelation}
            >
              {RespFamilyPrimaryBeneficiaryRelations.map((value, index) => (
                <MenuItem value={value} key={index}>{t(`affiliationTypes:affiliationRelationship.${value}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <Button
                variant='outlined'
                leadingIcon={AddRoundedIcon}
                label={t('workflowCompletions:baseAccountCreation.addAffiliate')}
                onClick={() => {
                  setFocused([...focused, 'primaryBeneficiary']);
                  addAffiliation();
                }}
                type='button'
              />
          </Grid>
        </Grid>
        <Grid item xs={12}><Divider variant='fullWidth' /></Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant='button' sx={{ color: colors.neutral600 }}>{t('workflowCompletions:respAccountCreation.isLegalGuardian')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              onChange={(e: any) => handleUserAsGuardianOrCareGiver(AffiliationRelations.Guardian, e.target.value === 'Yes')}
              label=''
              fullWidth
              value={userIsARelation(AffiliationRelations.Guardian) ? 'Yes' : 'No'}
            >
              <Radio value='Yes' size='small' disabled={!hasBeneficiary} label={yes} />
              <Radio value='No' size='small' disabled={!hasBeneficiary} label={no} />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant='button' sx={{ color: colors.neutral600 }}>{t('workflowCompletions:respAccountCreation.isCareGiver')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              onChange={(e: any) => handleUserAsGuardianOrCareGiver(AffiliationRelations.PrimaryCaregiver, e.target.value === 'Yes')}
              label=''
              fullWidth
              value={userIsARelation(AffiliationRelations.PrimaryCaregiver) ? 'Yes' : 'No'}
            >
              <Radio value='Yes' size='small' disabled={!hasBeneficiary} label={yes} />
              <Radio value='No' size='small' disabled={!hasBeneficiary} label={no} />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}><Divider variant='fullWidth' /></Grid>
        {showGoalLinkingSection && <Grid item xs={12} >
          <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
        </Grid>}
        <Grid item xs={12} display='flex' justifyContent='end'>
          <Button
            color='primary'
            label={t('workflowCompletions:respAccountCreation.addAccount')}
            type='submit'
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default RespFamilyDialogContent;
