import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, Button,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePageState } from '../../../util/usePageState';

const FETCH_REBALANCES = gql`
  query fetchRebalances($input: FetchRebalancesInput!) {
    fetchRebalances(input: $input) {
      rebalances {
        id
        createdAt
        buyBulkTradeFileUrl
        sellBulkTradeFileUrl
        mutualFundFileUrl
        allocationFileUrl
        allocationFileUrls
        ptfBulkTradeFileUrl
        state
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

const CREATE_REBALANCE = gql`
  mutation rebalanceSubAccounts($input: RebalanceSubAccountsInput!){
    rebalanceSubAccounts(input: $input) {
      rebalance {
        id
      }
    }
  }
`;

const RebalancesTable = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['rebalances']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_REBALANCES, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [newRebalance, { data: newData, loading: newLoading }] = useMutation(CREATE_REBALANCE);

  useEffect(() => {
    if (newData) {
      navigate(`/rebalances/${newData.rebalanceSubAccounts.rebalance.id}`);
    }
  }, [newData, navigate]);

  if (error) (<Typography>Error</Typography>);

  const downloadFilesByUrls = async (urls: string[]) => {
    // Consecutive download is limited to 10 files in Google Chrome.
    // Setting a 500ms delay between each download solves the problem.
    urls.forEach((url: any, idx: any) => {
      setTimeout(() => {
        const anchor = document.createElement('a');
        const fileName = url.match(/\/([^/]+)\?/)?.[1] ?? `Allocation_${idx}`;

        anchor.href = url;
        anchor.download = fileName;

        document.body.appendChild(anchor);

        anchor.click();

        document.body.removeChild(anchor);
      }, idx * 500);
    });
  };

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {permissions.includes('write:rebalance_basic') && (
            <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
              <Grid item>
                <Button disabled={newLoading} variant='contained' onClick={() => {
                  newRebalance({
                    variables: {
                      input: { full: false, organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('contributionRebalance')}</Button>
              </Grid>
              <Grid item>
                <Button disabled={newLoading} variant='outlined' onClick={() => {
                  newRebalance({
                    variables: {
                      input: { full: true, organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('driftRebalance')}</Button>
              </Grid>
              <Grid item>
                <Button disabled={newLoading} variant='contained' onClick={() => {
                  newRebalance({
                    variables: {
                      input: { v2: true, full: false, organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('contributionRebalance')} - v2 statistics</Button>
              </Grid>
              <Grid item>
                <Button disabled={newLoading} variant='outlined' onClick={() => {
                  newRebalance({
                    variables: {
                      input: { v2: true, full: true, organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('driftRebalance')} - v2 statistics</Button>
              </Grid>
            </Grid>
          )}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.createdAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.files')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchRebalances?.rebalances?.map((rebalance: any) => (
                <TableRow
                  hover
                  key={rebalance.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/rebalances/${rebalance.id}`)}
                >
                  <TableCell><DateTime variant='subtitle2' date={rebalance.createdAt} /></TableCell>
                  <TableCell>
                    {rebalance.buyBulkTradeFileUrl && (
                      <Button onClick={(e) => e.stopPropagation()} variant='outlined' sx={{ marginRight: 1 }} size ='small' href={rebalance.buyBulkTradeFileUrl} download>{t('buyTradeFile')}</Button>
                    )}
                    {rebalance.sellBulkTradeFileUrl && (
                      <Button onClick={(e) => e.stopPropagation()} variant='outlined' sx={{ marginRight: 1 }} size ='small' href={rebalance.sellBulkTradeFileUrl} download>{t('sellTradeFile')}</Button>
                    )}
                    {rebalance.mutualFundFileUrl && (
                      <Button onClick={
                        (e) => e.stopPropagation()
                        } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={rebalance.mutualFundFileUrl} download>{t('mutualFundTradeFile')}</Button>
                    )}
                    {rebalance.allocationFileUrl && (
                      <Button onClick={(e) => {
                        e.stopPropagation();
                        downloadFilesByUrls(rebalance.allocationFileUrls);
                      }} variant='outlined' sx={{ marginRight: 1 }} size ='small'>{t('allocationFile')}</Button>
                    )}
                    {rebalance.ptfBulkTradeFileUrl && (
                      <Button onClick={
                        (e) => e.stopPropagation()
                      } variant='outlined' sx={{ marginRight: 1 }} size ='small' href={rebalance.ptfBulkTradeFileUrl} download>{t('ptfBulkTradeFile')}</Button>
                    )}
                  </TableCell>
                  <TableCell>{rebalance.organization.name}</TableCell>
                  <TableCell align="right"><Chip label={rebalance.state} size='small' color={rebalance.state === 'COMPLETED' ? 'success' : 'warning'}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchRebalances?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default RebalancesTable;
