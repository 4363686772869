import { TextField, MenuItem } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Account, getAccountName } from 'interfaces';
import { usePermissions } from 'providers/userContextProvider';
import { generateClientNameString } from '../../util';

export const FETCH_ACCOUNTS = (permissions:string[]) => gql`
  query fetchAccounts($filter: AccountsQueryFilter!) {
    fetchAccounts(input:{filter:$filter, pagination:{perPage: 1000}}) {
      accounts {
        id
        type
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
        }
      }
    }
  }
`;

const AccountSelect = ({
  forObject,
  forId,
  setAccount,
  label = '',
  selectFirst = false,
  disabled = false,
  size,
  accountIdValue,
  getActiveAccountCount,
  anyOptionLabel,
}: {
  forObject: 'USER' | 'CLIENT_GROUP'
  forId: string,
  setAccount: (event: any) => void,
  label?: string,
  selectFirst?: boolean,
  disabled?: boolean
  size?: 'small' | 'medium' | undefined,
  accountIdValue?: string,
  getActiveAccountCount?: (num: number) => void,
  anyOptionLabel?: string,
}) => {
  const { permissions } = usePermissions();
  const { loading } = useQuery(FETCH_ACCOUNTS(permissions), {
    variables: {
      filter: {
        ...forObject === 'USER' ? { userId: forId } : { clientGroupId: forId },
        states: ['ACTIVE', 'REQUESTED', 'FROZEN', 'FAILED', 'INITIATED', 'READY'],
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setAccountsShown(data.fetchAccounts.accounts);
      if (getActiveAccountCount) getActiveAccountCount(data.fetchAccounts.accounts.length);
    },
  });

  const [accountsShown, setAccountsShown] = useState<Account[]>([]);

  useEffect(() => {
    if (selectFirst && accountIdValue === '' && accountsShown.length > 0) {
      setAccount(accountsShown[0]?.id);
    }
  }, [selectFirst, accountsShown, accountIdValue, setAccount]);

  const showOwnerName: boolean = forObject === 'CLIENT_GROUP';

  return (
    <>
      <TextField
        select
        value={accountIdValue}
        label={label}
        size={size}
        sx={{ width: '100%' }}
        onChange={(e: any) => {
          setAccount(e.target.value);
        }}
        disabled={disabled}
      >
        {anyOptionLabel && <MenuItem key='ANY' selected value='ANY'>{anyOptionLabel}</MenuItem>}
        {
          (loading) ? <MenuItem>...</MenuItem> : (
            accountsShown.map((a: any) => (
              <MenuItem key={a.id} value={a.id}>
                {showOwnerName && `${generateClientNameString(a.user)} - ` }
                { getAccountName(a) }
              </MenuItem>
            )))
        }
      </TextField>
    </>
  );
};

export default AccountSelect;
