import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import { Account } from 'interfaces';
import { Card, CardContent, IconButton } from 'ovComponents/2-component';
import { GoalLinkingReview } from '../goalLinking';

interface AccountReviewItemProps {
  account: Account,
  onDeleteAccount: (account?: Account) => void,
}

export const AccountReviewItem = ({ account, onDeleteAccount }: AccountReviewItemProps) => {
  const { t } = useTranslation(['accountTypes']);
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ pl: 0.9 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant='bodyLarge' weight='bold'>{t(`accountTypes:${account.type}`)}</Typography>
          {
            account.id ? undefined : <IconButton onClick={() => onDeleteAccount()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <GoalLinkingReview account={account} />
        </Box>
      </CardContent>
    </Card>
  );
};

export const getAcccountDetailComponent = ({ field, value }: { field: string, value: string }): JSX.Element => (
  <>
    <Typography variant='labelLarge' weight='bold'>{field}</Typography>
    <Typography variant='bodyMedium'>{value}</Typography>
  </>
);

export default AccountReviewItem;
