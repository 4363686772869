import { Typography } from '@mui/material';
import { colors } from '../../theme/colors';

const StatsBlock = ({ title, value, big }: { title: string, value: string, big?: boolean }) => (
    <>
      <Typography sx={{ fontSize: '14px', color: colors.gray500, lineHeight: '18px' }}>{ title }</Typography>
      <Typography sx={{ lineHeight: big ? '40px' : '30px', fontSize: big ? '32px' : '20px', fontWeight: 600 }}>{ value }</Typography>
    </>
);

export default StatsBlock;
