import { Chip, ListItem, Tooltip } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { BareLocalization, Localization } from '../../../interfaces';
import FormModal from '../../../components/modals/formModal';
import LocalizationForm from './localizationForm';
import { FETCH_LOCALIZATIONS } from './localizationSettings';
import { FETCH_LOCALIZATIONS_BY_ORG } from '../../orgDetails';

const UPDATE_LOCALIZATION = gql`
mutation updateLocalization($localizationId: ObjectID!, $localization: LocalizationInput!) {
  updateLocalization(localizationId: $localizationId, localization: $localization) {
    localization {
      id
    }
  }
}
`;

const EditLocalization = ({
  localization, handleClose, inherited,
}: { localization: Localization, handleClose: () => void, inherited?: boolean }) => {
  const { t } = useTranslation(['orgSettings']);

  const [editingLocalization, setEditingLocalization] = useState<BareLocalization>(
    omit(localization, ['id', 'organization', '__typename']) as BareLocalization,
  );

  const [updateLocalization, { loading }] = useMutation(UPDATE_LOCALIZATION, {
    variables: {
      localizationId: localization.id,
      localization: editingLocalization,
    },
    refetchQueries: [FETCH_LOCALIZATIONS, FETCH_LOCALIZATIONS_BY_ORG],
    onCompleted: handleClose,
  });

  const allFieldsValid = editingLocalization.name
    && editingLocalization.countries.length > 0
    && editingLocalization.defaultCurrency
    && editingLocalization.languages.length > 0
    && editingLocalization.defaultLanguage
    && editingLocalization.dateFormat;

  return (
    <>
      <FormModal
        open
        disabled={inherited || !allFieldsValid}
        loading={loading}
        title={t('localizationModal.editTitle')}
        formButton={t('update')}
        onSubmit={updateLocalization}
        handleClose={handleClose}
      >
        {inherited && (<ListItem><Tooltip title={t('shared:inheritedTooltip', { model: t('tabs.localizationSettings'), organization: localization.organization?.name })}>
          <Chip size='medium' label={t('shared:inheritedFlag')} />
        </Tooltip></ListItem>)}
        <LocalizationForm
          localization={editingLocalization}
          setLocalization={setEditingLocalization}
          readonly={inherited}
        />
      </FormModal>
    </>
  );
};

export default EditLocalization;
