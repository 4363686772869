import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, Button,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePageState } from '../../../util/usePageState';
import { BulkImport, getStatusColor } from '../../../interfaces/bulkImport';
import { getOrganizationUserName } from '../../../interfaces/organizationUser';

const FETCH_BULK_IMPORTS = gql`
  query fetchBulkImports($input: FetchBulkImportsInput!) {
    fetchBulkImports(input: $input) {
      totalCount
      bulkImports {
        id
        organization { id name }
        name
        createdAt
        state
        organizationUser { id firstName lastName }
      }
    }
  }
`;

const CREATE_BULK_IMPORT = gql`
  mutation createBulkImport($input: CreateBulkImportInput!){
    createBulkImport(input: $input) {
      bulkImport {
        id
      }
    }
  }
`;

const BulkImportsTable = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['bulkImport']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BULK_IMPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [newBulkImport, { data: newData, loading: newLoading }] = useMutation(CREATE_BULK_IMPORT);

  useEffect(() => {
    if (newData) {
      navigate(`/bulkImport/${newData.createBulkImport.bulkImport.id}`);
    }
  }, [newData, navigate]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {permissions.includes('write:bulk_import') && (
            <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
              <Grid item>
                <Button disabled={newLoading} variant='contained' onClick={() => {
                  newBulkImport({
                    variables: {
                      input: { organizationId: activeOrganization.id },
                    },
                  });
                }}>{t('startNew')}</Button>
              </Grid>
            </Grid>
          )}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.createdAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.createdBy')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchBulkImports?.bulkImports?.map((bulkImport: BulkImport) => (
                <TableRow
                  hover
                  key={bulkImport.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/bulkImport/${bulkImport.id}`)}
                >
                  <TableCell><DateTime variant='subtitle2' date={bulkImport.createdAt} /></TableCell>
                  <TableCell>{getOrganizationUserName(bulkImport.organizationUser)}</TableCell>
                  <TableCell>{bulkImport.organization?.name}</TableCell>
                  <TableCell align="right"><Chip label={bulkImport.state} size='small' color={getStatusColor(bulkImport.state)}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchBulkImports?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default BulkImportsTable;
