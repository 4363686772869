import theme from '../theme';

export const stickyColumn = {
  position: 'sticky',
  left: 0,
  background: theme.palette.background.default,
  borderRight: '2px solid black !important',
  minWidth: '200px',
};

export const textColumnStyles = {
  fontSize: 'small',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '150px',
  whiteSpace: 'nowrap',
};

export const stickyPosition = {
  position: 'sticky',
  left: 0,
};
