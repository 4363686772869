import { useTranslation } from 'react-i18next';
import SimpleCodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import { useMemo, useState } from 'react';
import {
  Grid, List, ListItem, ListItemText,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box, Typography,
} from '../../../1-primative';
import {
  Badge, Card, CardContent, TextField,
} from '../../../2-component';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { ReportVariableMeta } from '../../../../interfaces';

export const editorHeight = 'calc(100vh - 400px)';

export const SearchableListOfVariables = ({ availableVariables }: { availableVariables: ReportVariableMeta[] }) => {
  const { t } = useTranslation('clientReportTemplate');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredAvailableVariables = useMemo(
    () => availableVariables.filter((v) => {
      const lcSearchTerm = searchTerm.toLocaleLowerCase();
      return searchTerm === ''
        || v.name.toLocaleLowerCase().indexOf(lcSearchTerm) !== -1
        || v.description.toLocaleLowerCase().indexOf(lcSearchTerm) !== -1;
    }),
    [availableVariables, searchTerm],
  );

  return <Box
    style={{
      height: editorHeight,
      overflow: 'auto',
      fontSize: '66%',
    }}
  >
    <Box style={{ marginLeft: '1em' }}>
      <Typography variant='bodyMedium' align='center'>
        <HelpOutlineIcon fontSize='small' /> &nbsp;
        <span style={{ position: 'relative', top: '-5px' }}>
          {t('variablesAvailableForThisTemplate')}
        </span>
      </Typography>
      <TextField
        leadingIcon='search'
        fullWidth
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
      />
    </Box>
    <List>
      {(filteredAvailableVariables ?? []).map((variable) => <ListItem>
        <ListItemText
          primary={<>
            <code style={{ fontSize: '75%' }}>{variable.name}</code> &nbsp;
            <Badge color='neutral' label={variable.type} />
          </>}
          secondary={<small>{variable.description}</small>}
        />
      </ListItem>)}
    </List>
  </Box>;
};

export const Editor = ({
  templateHtml, setTemplateHtml, disabled, availableVariables,
}: {
  templateHtml: string, setTemplateHtml: (newValue: string) => void, disabled: boolean, availableVariables?: ReportVariableMeta[]
}) => {
  const { sys } = useThemeTokens();
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <div
              style={{
                height: editorHeight,
                overflow: 'auto',
              }}
            >
              <SimpleCodeEditor
                value={templateHtml}
                onValueChange={setTemplateHtml}
                highlight={(htmlCode) => highlight(htmlCode, languages.html, 'html')}
                padding={5}
                style={{
                  backgroundColor: sys.color.neutralHover,
                  fontFamily: sys.font.base,
                  fontSize: 11,
                }}
                disabled={disabled}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            {availableVariables
              && <SearchableListOfVariables availableVariables={availableVariables} />
            }
          </Grid>
        </Grid >
      </CardContent>
    </Card>
  );
};
