import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  AccountTypes,
  AccountStates,
  Goal,
  User,
  DraftAccount,
} from 'interfaces';
import {
  Box,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
} from 'ovComponents/2-component';
import { GoalLinkingInput, getValidGoals } from '../goalLinking';

export const AccountDialogContent = ({
  accountType,
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: {
  accountType: AccountTypes,
  userData: User,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}) => {
  const { t } = useTranslation(['accountTypes', 'workflowCompletions']);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const goals = getValidGoals(userData);
  const submit = () => {
    onAccountCreated({
      type: accountType,
      applyForGovFunds: [],
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      goalsLinked,
    });
  };

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium'/></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t(`accountTypes:${accountType}`)}</Typography>
      <Box sx={{ mt: 4 }}>
        <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
      </Box>
      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:baseAccountCreation.addAccount')}
          disabled={false}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};
