import HouseholdDocuments from 'pages/client/components/householdDocuments';
import { ClientGroup } from '../../../interfaces';

const HouseholdDocumentsTab = ({ clientGroup }: { clientGroup: ClientGroup }) => {
  const allUsersIds: string[] = clientGroup.relationships.map(
    (rel) => rel.user.id,
  );
  return (
    <>
      <HouseholdDocuments userIds={allUsersIds} clientGroupId={clientGroup.id}/>
    </>
  );
};

export default HouseholdDocumentsTab;
