/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters } from '../../../ovComponents/3-pattern';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { useClientContext } from '../../../pages/client';
import { useHouseholdContext } from '../../../pages/household';
import { useCorporateContext } from '../../../pages/corporation';
import { FETCH_SUB_TRADES_QUERY, FETCH_TRADES_QUERY } from './tradesTable';

const DownloadTrades = ({ filter, queryFilter, isTrades }: { filter: any; queryFilter: any; isTrades: boolean }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();

  const isCanReadFirstOrLastNames = permissions.includes('read:client_low_risk_pii');

  const columns: Column[] = [
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: isTrades ? 'account.user.firstName' : 'subAccount.account.user.firstName', header: t('tradeTable.userFirstName') }]) || []),
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: isTrades ? 'account.user.lastName' : 'subAccount.account.user.lastName', header: t('tradeTable.userLastName') }]) || []),
    { gqlAlias: isTrades ? 'account.id' : 'subAccount.account.id', header: t('tradeTable.account') },
    { gqlAlias: 'financialProduct.ticker', header: t('tradeTable.security') },
    { gqlAlias: 'type', header: t('tradeTable.type') },
    { gqlAlias: 'actualQuantity', header: t('tradeTable.quantity') },
    { gqlAlias: 'actualTradePriceCents', header: t('tradeTable.price'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'state', header: t('tradeTable.state') },
    { gqlAlias: 'createdAt', header: t('tradeTable.createdAt') },
    { gqlAlias: 'reconciledAt', header: t('tradeTable.reconciledAt') },
  ];

  const additionalColumns: Column[] = [
    { gqlAlias: isTrades ? 'expectedQuantity' : 'trade.expectedQuantity', header: t('tradeTable.expectedQuantity') },
    { gqlAlias: isTrades ? 'expectedTradePriceCents' : 'trade.expectedTradePriceCents', header: t('tradeTable.expectedPrice'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'moneyAllocatedCents', header: t('tradeTable.expectedTotalValue'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'financialProduct.currency', header: t('tradeTable.currency') },
    { gqlAlias: 'rebalance.id', header: t('tradeTable.rebalance') },
    { gqlAlias: isTrades ? 'account.user.id' : 'subAccount.account.user.id', header: t('tradeTable.user') },
    { gqlAlias: isTrades ? 'account.organization.id' : 'subAccount.account.organization.id', header: t('tradeTable.organization') },
  ];

  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);

  const config: DownloadButtonProps = {
    gql: isTrades ? FETCH_TRADES_QUERY(permissions) : FETCH_SUB_TRADES_QUERY(permissions),
    fileName: `${t('components:trades.title')}_${dayjs().format('YYYY-MM-DD')}`,
    filter: { ...filter, organizationId: organizationId ?? activeOrganization.id },
    additionalFilter: queryFilter,
    queryPath: isTrades ? 'fetchTrades' : 'fetchSubTrades',
    datasetPath: isTrades ? 'trades' : 'subTrades',
    columns,
    additionalColumns,
    sortField: 'createdAt',
    sortDesc: false,
    progressTitle: t('components:trades.exportProgressTitle'),
  };

  return (
    <DownloadButton
      configurable
      gql={config.gql}
      fileName={config.fileName}
      filter={config.filter}
      additionalFilter={config.additionalFilter}
      additionalColumns={config.additionalColumns}
      queryPath={config.queryPath}
      datasetPath={config.datasetPath}
      columns={config.columns}
      sortField={config.sortField}
      sortDesc={config.sortDesc}
      progressTitle={config.progressTitle}
    />
  );
};

export default DownloadTrades;
