import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isBoolean, pick } from 'lodash/fp';
import { useGlobalToast } from 'providers/globalToastProvider';
import {
  SubStepTypes,
  TriggerType,
  Workflow,
  WorkflowStates,
  workflowTransitions,
} from 'interfaces/workflow';
import { Menu, MenuItem } from '../../2-component';
import { deleteFile, doUpload } from '../../4-module/configurableOptionFields/attachment';
import { UserContext } from '../../../providers/userContextProvider';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { DELETE_FILE_DOCUMENT } from '../../../pages/client/components/documents/documentViewer';
import { BasicWorkflowEditor } from './components/basicWorkflowEditor';
import { ComplexWorkflowEditor } from './components/complexWorkflowEditor';
import { mapRules } from '../clientReportTemplate/components/rules';

const eventTypes = {
  USER: [
    'CREATED',
    'PERSONAL_INFORMATION_UPDATED',
    'SUITABILITY_SCORE_UPDATED',
    'SUITABILITY_REVIEW_DUE',
  ],
};
const getValidTransitions = (state: WorkflowStates | undefined) => (
  workflowTransitions.filter((transition) => (state ? transition.from.includes(state) : false))
);

export const ConfigureWorkflowPageVisual = ({
  data,
  loading,
  updateWorkflow,
  transitionWorkflow,
}: {
  data: any,
  loading: boolean,
  updateWorkflow: (input: any) => void,
  transitionWorkflow: (input: any) => void,
}) => {
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['workflowCompletions', 'orgSettings']);
  const { activeOrganization, userContext } = useContext(UserContext);
  const [columns, setColumns] = useState<any>([]);
  const [workflow, setWorkflow] = useState<Workflow>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT);
  const subStepWithAttachment = [SubStepTypes.RISK_QUESTION_1, SubStepTypes.RISK_QUESTION_2];

  useEffect(() => {
    if (data) {
      setColumns(data.fetchWorkflow.workflow.steps);
      setWorkflow(data.fetchWorkflow.workflow);
    }
  }, [data]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleWorkflowTransition = (transition: string) => {
    if (!workflow) return;
    transitionWorkflow({
      variables: {
        input: {
          workflowId: workflow.id,
          transition,
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('savedWorkflowMessage') })),
    });
  };

  const uploadOrDeleteDocument = async (subStep: any): Promise<any> => {
    const newSubStep = { ...subStep };
    if (subStep?.options?.attachment?.file) {
      await doUpload({
        objectId: activeOrganization.id, userId: userContext.id, subStep: newSubStep, fetchFileUploadUrl, createFileDocument,
      });
    }
    if (subStep?.options?.attachment?.docToDelete) {
      await deleteFile({ subStep: newSubStep, deleteFileDocument });
    }
    return newSubStep;
  };

  const handleReviewAttachments = async (subStep: any): Promise<any> => {
    const newSubStep = { ...subStep };
    await Promise.all(newSubStep?.options?.options.map(async (item: any, i: number) => {
      if (item?.value?.file) {
        await doUpload({
          objectId: activeOrganization.id, userId: userContext.id, subStep: item, fetchFileUploadUrl, createFileDocument, key: 'value',
        });
      }
      if (item?.value?.docToDelete) {
        await deleteFile({ subStep: item, deleteFileDocument });
      }
    }));
    return newSubStep;
  };

  const handleDocumentChange = async () => {
    const newColumns = await Promise.all(columns.map(async (column: any) => {
      const newSubSteps = await Promise.all(column.subSteps.map(async (subStep: any) => {
        if (subStepWithAttachment.includes(subStep.type)) {
          const newSubStep = await uploadOrDeleteDocument(subStep);
          return newSubStep;
        }
        if (subStep.type === SubStepTypes.REVIEW) {
          const newSubStep = await handleReviewAttachments(subStep);
          return newSubStep;
        }
        return subStep;
      }));
      return { ...column, subSteps: newSubSteps };
    }));
    setColumns(newColumns);
  };

  const update = async () => {
    await handleDocumentChange();
    updateWorkflow({
      variables: {
        input: {
          workflowId: workflow.id,
          name: pick(['en', 'fr'], workflow.name),
          triggerType: workflow.triggerType,
          eventType: workflow.eventType,
          steps: columns.map((column: any) => ({
            name: pick(['en', 'fr'], column.name),
            startNodePosition: column.startNodePosition ? { x: column.startNodePosition.x, y: column.startNodePosition.y } : null,
            endNodePosition: column.endNodePosition ? { x: column.endNodePosition.x, y: column.endNodePosition.y } : null,
            branches: column.branches?.map((b: any) => ({
              subStepKey: b.subStepKey,
              contextObject: b.contextObject === 'CLIENT' ? null : b.contextObject,
              condition: b.condition ? {
                ruleGroup: {
                  rules: mapRules(b.condition.ruleGroup.rules),
                  combinator: b.condition.ruleGroup.combinator,
                },
              } : undefined,
            })),
            subSteps: column.subSteps?.map((subStep: any) => ({
              key: subStep.key,
              nodePosition: subStep.nodePosition ? { x: subStep.nodePosition.x, y: subStep.nodePosition.y } : null,
              branches: subStep.branches?.map((b: any) => ({
                subStepKey: b.subStepKey,
                contextObject: b.contextObject === 'CLIENT' ? null : b.contextObject,
                condition: b.condition ? {
                  ruleGroup: {
                    rules: mapRules(b.condition.ruleGroup.rules),
                    combinator: b.condition.ruleGroup.combinator,
                  },
                } : undefined,
              })),
              type: subStep.type,
              options: subStep.options,
              canGoBack: isBoolean(subStep.canGoBack) ? subStep.canGoBack : true,
              completeableBy: subStep.completeableBy || ['ORGANIZATION_USER'],
              rolesCompleteableBy: subStep.rolesCompleteableBy?.map((x: any) => x.id ?? x) || [],
              skippable: subStep.skippable || false,
            })),
          })),
          triggerRules: (
            workflow.triggerType === TriggerType.EVENT && workflow.triggerRules
          ) ? workflow.triggerRules.filter((r) => !!r.field && !!r.value).map((r) => pick(['field', 'comparison', 'value'], r)) : [],
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('savedWorkflowMessage') })),
    });
  };

  return (
    <>
      {workflow ? (
        <>
          {workflow.complex ? (
            <ComplexWorkflowEditor
              columns={columns}
              setColumns={setColumns}
              workflow={workflow}
              update={update}
              setWorkflow={setWorkflow}
              handleMenuClick={handleMenuClick}
              eventTypes={eventTypes}
            />
          ) : (
            <BasicWorkflowEditor
              columns={columns}
              setColumns={setColumns}
              workflow={workflow}
              update={update}
              setWorkflow={setWorkflow}
              handleMenuClick={handleMenuClick}
              eventTypes={eventTypes}
            />
          )}
        </>
      ) : <></>}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getValidTransitions(workflow.state).map((transition) => (
          <MenuItem
            key={transition.name}
            onClick={() => {
              handleWorkflowTransition(transition.name);
            }}
          >
            {t(`orgSettings:workflowTransitions.${transition.name}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ConfigureWorkflowPageVisual;
