import React, { useContext, useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Close, Edit } from '@mui/icons-material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
  Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Button,
  SelectField,
  MenuItem,
  Switch,
} from '../../../2-component';
import {
  FETCH_MODEL_PORTFOLIOS, FETCH_SUB_ACCOUNT, UPDATE_SUB_ACCOUNT,
  FETCH_GOAL, UPDATE_GOAL,
} from './changeTheme.queries';
import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from '../../../1-primative';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../assets/i18n/config';
import { ThemeSelect } from '../../../3-pattern/themeSelect/themeSelect';
import { UserContext } from '../../../../providers/userContextProvider';
import { PageObjectType } from '../../../5-page';
import { SubAccount } from '../../../../interfaces';

const SUGGESTED_PRODUCT = `#graphql
  id
  translatedName { en fr}
  url
  children {
    id
    percentage
    financialProduct {
      id
      translatedName { en fr}
      primaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
      secondaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
    }
  }
`;

const SUGGEST_PORTFOLIO = gql`
  query suggestPortfolio($suitabilityScore: Int!, $organizationId: ObjectID!, $themeId: ObjectID) {
    suggestFinancialProduct(input: {
      suitabilityScore: $suitabilityScore
      organizationId: $organizationId
      themeId: $themeId
    }) {
      financialProduct {
        ${SUGGESTED_PRODUCT}
      }
    }
  }
`;

export const ChangeTheme = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { t } = useTranslation('components');
  const [theme, setTheme] = useState<any>();
  const [modelPortfolio, setModelPortfolio] = useState<any>();
  const [portfolios, setPortfolios] = useState<any[]>();
  const [object, setObject] = useState<any>();
  const [subAccountUpdateData, setSubAccountUpdateData] = useState<Partial<SubAccount>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { sys } = useThemeTokens();
  const { activeOrganization } = useContext(UserContext);
  const [suggestPortfolio] = useLazyQuery(SUGGEST_PORTFOLIO);

  const { data: subAccountData, loading: subAccountLoading, refetch: subAccountRefetch } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: {
      subAccountId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.SUB_ACCOUNT,
  });

  const { data: goalData, loading: goalLoading, refetch: goalRefetch } = useQuery(FETCH_GOAL(options.useExternalSuitabilityScore === true), {
    variables: {
      goalId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.GOAL,
  });

  const refetch = () => {
    if (objectType === PageObjectType.SUB_ACCOUNT) subAccountRefetch();
    if (objectType === PageObjectType.GOAL) goalRefetch();
  };

  useQuery(FETCH_MODEL_PORTFOLIOS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeId: theme?.id,
          state: 'ACTIVE',
        },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (d: any) => {
      setPortfolios(d.fetchModelPortfolios.modelPortfolios);
    },
    fetchPolicy: 'no-cache',
  });

  const [updatePortfolio] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL);
  const [updateTheme] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL, {
    variables: {
      input: {
        subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
        goalId: objectType === PageObjectType.GOAL ? objectId : undefined,
        themeId: objectType === PageObjectType.SUB_ACCOUNT ? theme?.id : undefined,
        financialProductId: options.setModelPortfolio ? modelPortfolio?.id : undefined,
        ...(objectType === PageObjectType.SUB_ACCOUNT ? {
          skipIPS: options.enableSkipIPSEdit ? subAccountUpdateData.skipIPS : undefined,
          isPartial: options.enableIsPartialEdit ? subAccountUpdateData.isPartial : undefined,
          allowClientDeposits: options.enableAllowClientDepositsEdit ? subAccountUpdateData.allowClientDeposits : undefined,
        } : {}),
      },
    },
    onCompleted: (d: any) => {
      if (!options.setModelPortfolio && objectType === PageObjectType.SUB_ACCOUNT) {
        updatePortfolio({
          variables: {
            input: {
              subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
              financialProductId: d.updateSubAccount?.subAccount?.suggestedFinancialProduct?.id,
            },
          },
          onCompleted: () => {
            setOpen(false);
            refetch();
          },
        }).then();
      } else {
        setOpen(false);
        refetch();
      }
    },
  });

  const submitTheme = async () => {
    if ((objectType === PageObjectType.GOAL) && object.financialProduct && (object?.financialProduct?.theme.id !== theme.id) && options.allowModelPortfolioEdit !== true) {
      const response = await suggestPortfolio({
        variables: {
          suitabilityScore: options.useExternalSuitabilityScore === true ? object.custodianSuitabilityScore : object.suitabilityScore,
          organizationId: activeOrganization.id,
          themeId: theme.id,
        },
      });
      updateTheme({
        variables: {
          input: {
            subAccountId: undefined,
            goalId: objectId,
            themeId: undefined,
            financialProductId: response.data.suggestFinancialProduct.financialProduct.id,
          },
        },
      }).then();
    } else {
      updateTheme().then();
    }
  };

  useEffect(() => {
    if (subAccountData || goalData) {
      const d = objectType === PageObjectType.SUB_ACCOUNT ? subAccountData.fetchSubAccount.subAccount : goalData.fetchGoal.goal;
      setObject(d);
      setTheme(d?.theme || d?.financialProduct?.theme);
      setModelPortfolio(d.financialProduct);
      setSubAccountUpdateData({
        skipIPS: d?.skipIPS,
        allowClientDeposits: d?.allowClientDeposits,
        isPartial: d?.isPartial,
      });
    }
  }, [subAccountData, goalData, objectType]);
  useEffect(() => {
    if (subAccountLoading || goalLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [subAccountLoading, goalLoading]);

  if (loading) {
    return (
      <Skeleton width='100%' height='80px' variant='rectangular'></Skeleton>
    );
  }
  return (
    <Card>
      <CardContent sx={{ px: 0, pb: '0 !important', backgroundColor: sys.color.surface }}>
        <Box sx={{ pb: 1.5, pt: 0.5, px: 2 }}>
          <Typography variant='titleMedium'>{t('portfolioHighlights')}</Typography>
        </Box>
        {
          options.displayPortfolioTheme && (
            <Box
              sx={{
                backgroundColor: sys.color.surfaceContainer,
                px: 2,
                height: 40,
              }} display='flex' alignItems="center" justifyContent='space-between'>
              <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>{t('changeTheme.portfolioTheme')}:</Typography>
              <Box display='flex' alignItems='center'>
                {options.allowPortfolioThemeEdit && (
                  <IconButton size='medium' sx={{ height: '32px' }} onClick={() => setOpen(true)}><Edit sx={{ fontSize: '16px' }} /></IconButton>)
                }
                <Typography variant='bodyMedium'>
                  {translateBackend(object?.theme?.translatedName || object?.financialProduct?.theme?.translatedName) || t('changeTheme.unset')}
                </Typography>
              </Box>
            </Box>
          )
        }
        {options.displayPortfolioTheme && (
          <Box
            sx={{
              px: 2,
              height: 40,
            }}
            display='flex' alignItems="center" justifyContent='space-between'>
            <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>{t('changeTheme.modelPortfolio')}:</Typography>
            <Box display='flex' alignItems='center'>
              {options.allowModelPortfolioEdit && (
                <IconButton size='medium' sx={{ height: '32px' }} onClick={() => setOpen(true)}><Edit sx={{ fontSize: '16px' }} /></IconButton>)
              }
              <Typography
                style={{
                  whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '180px',
                }}
                variant='bodyMedium'
              >{translateBackend(object?.financialProduct?.translatedName) || t('changeTheme.unset')}</Typography>
            </Box>
          </Box>
        )}
        {
          options.showFundFacts && object?.financialProduct?.url && (
            <Box
              sx={{
                backgroundColor: options.displayPortfolioTheme === options.setModelPortfolio ? sys.color.surfaceContainer : undefined,
                px: 2,
                height: 40,
              }}
              display='flex'
              flexDirection='row'
              alignItems="center"
              justifyContent='space-between'
            >
              <Box display='flex' flexDirection='row'>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>{translateBackend(options.fundFactsLabel)}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={(e) => window.open(object?.financialProduct?.url, '_blank', 'noopener,noreferrer')}
                >
                  <OpenInNewRoundedIcon />
                </IconButton>
                <Typography variant='bodyMedium'>{t('view')}</Typography>
              </Box>
            </Box>
          )
        }
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              {t('changeTheme.portfolioTheme')}
              <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {options.allowPortfolioThemeEdit && (
              <ThemeSelect
                label={t('changeTheme.portfolioTheme')}
                onThemeSelect={(th: any) => setTheme(th)}
                selectedTheme={theme}
              />
            )}
            {options.allowPortfolioThemeEdit && (
              <Typography variant='bodyLarge' sx={{ mt: 2 }}>{translateBackend(theme?.translatedDescription || {})}</Typography>
            )}
            {options.allowModelPortfolioEdit && (
              <SelectField
                label={t('changeTheme.modelPortfolio')}
                value={modelPortfolio?.id}
                onChange={(e: any) => setModelPortfolio(portfolios?.find((x: any) => x.id === e.target.value))}
                sx={{ mt: 2 }}
                fullWidth
              >
                {portfolios?.map((p: any) => (
                  <MenuItem key={p.id} value={p.id}>
                    {translateBackend(p.translatedName)}{object?.suggestedFinancialProduct?.id === p.id && ` (${t('changeTheme.suggested')})`}
                  </MenuItem>
                ))}
              </SelectField>
            )}
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {options.enableSkipIPSEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.requireIPS')}
                    checked={!subAccountUpdateData.skipIPS}
                    onChange={(onoff: boolean) => { setSubAccountUpdateData({ ...subAccountUpdateData, skipIPS: !onoff }); }}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
              {options.enableIsPartialEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.allowFractionalShares')}
                    checked={subAccountUpdateData.isPartial}
                    onChange={(onoff: boolean) => { setSubAccountUpdateData({ ...subAccountUpdateData, isPartial: onoff }); }}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
              {options.enableAllowClientDepositsEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.allowClientDeposits')}
                    checked={subAccountUpdateData.allowClientDeposits}
                    onChange={(onoff: boolean) => { setSubAccountUpdateData({ ...subAccountUpdateData, allowClientDeposits: onoff }); }}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
              <Button label={t('changeTheme.save')} onClick={() => submitTheme()} />
            </Box>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
