import { useTranslation } from 'react-i18next';
import { Close, Download } from '@mui/icons-material';
import { translateBackend } from 'assets/i18n/config';
import { TranslatedString } from 'interfaces';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  IconButton,
  PdfViewer,
} from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import { downloadHtmlAsPdf } from '../../../../../util/downloadHtmlAsPdf';

interface Props {
  handleClose: () => void,
  open: boolean,
  content: string,
  pdfUrl?: string,
  documentId?: string
  translatedDisplayName?: TranslatedString,
  downloadFile?: (id: string) => void,
}

export const DocumentDialog = ({
  open, handleClose, content, pdfUrl, documentId, translatedDisplayName, downloadFile,
}: Props): JSX.Element => {
  const { t } = useTranslation(['client']);
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth='lg'
    >
      {translatedDisplayName && (
        <DialogTitle>
          <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
            {translateBackend(translatedDisplayName)}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
      )}
      <DialogContent>
        <Box height='100%' overflow='auto'>
          {content && (
            <Typography id="modal-modal-description" dangerouslySetInnerHTML={{ __html: `${content}` }} />
          )}
          {!content && pdfUrl && (
            <PdfViewer pdfUrl={pdfUrl} />
          )}
        </Box>
        <Box display='flex' justifyContent='flex-end' marginTop={2}>
          {documentId && pdfUrl && downloadFile && (
            <Button variant='outlined'
              label={t('client:documents.download')}
              onClick={() => downloadFile(documentId)}
            />
          )}
        </Box>
      </DialogContent>
      {content && (
        <DialogFooter>
          <Box display='flex' justifyContent='flex-end' m={1}>
          <Button variant='outlined'
            leadingIcon={Download}
            label={t('client:documents.downloadHtmlAsPdf')}
            onClick={() => downloadHtmlAsPdf(content, translateBackend(translatedDisplayName))}
          />
          </Box>
        </DialogFooter>
      )}
    </Dialog>
  );
};
