import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileDownloader } from './fileDownloader';

export function FileDownloaderButton({ fileDocument }: { fileDocument: any }) {
  const { t } = useTranslation('client');

  return (
    <FileDownloader fileDocument={fileDocument}>
      <Button variant='outlined'>
        {t('client:documents.download')}
      </Button>
    </FileDownloader>
  );
}
