import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Account, ApplyForGovFunds } from 'interfaces';
import {
  Box,
  Grid,
  Typography,
} from 'ovComponents/1-primative';
import {
  Card,
  CardContent,
  IconButton,
} from 'ovComponents/2-component';
import { getAcccountDetailComponent } from '../account.reviewItem';
import { AffiliationTable } from '../../affiliationTable';
import { GoalLinkingReview } from '../../goalLinking';
import { getSortedAffiliations, sortedAffiliationRelations } from '../../utils';

interface RespFamilyReviewItemProps {
  account: Account,
  onDeleteAccount: () => void,
}
export const RespFamilyReviewItem = ({ account, onDeleteAccount }: RespFamilyReviewItemProps) => {
  const { t } = useTranslation(['affiliationTypes', 'components', 'workflowCompletions']);
  const [yes, no] = [t('components:booleanOptions.yes'), t('components:booleanOptions.no')];
  const hasSuccessor: boolean = account.affiliations && account.affiliations?.length > 0 ? true : false ?? false;
  const sortedAffiliations = getSortedAffiliations(account.affiliations ?? [], 'relation', sortedAffiliationRelations);

  const accountDetailsConfig = [
    {
      field: t('workflowCompletions:accountReviewPanel.basicCanadaEducationSavingsGrant'),
      value: account.applyForGovFunds?.includes(ApplyForGovFunds.BASIC_CESG) ? yes : no,
    },
    {
      field: t('workflowCompletions:accountReviewPanel.canadaLearningBond'),
      value: account.applyForGovFunds?.includes(ApplyForGovFunds.CLB) ? yes : no,
    },
    {
      field: t('workflowCompletions:accountReviewPanel.additionalCESG'),
      value: account.applyForGovFunds?.includes(ApplyForGovFunds.ADDITIONAL_CESG) ? yes : no,
    },
    {
      field: t('workflowCompletions:accountReviewPanel.affiliate'),
      value: hasSuccessor ? yes : no,
    },
  ];

  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Grid container gap={3}>
          <Grid item container justifyContent='space-between' alignItems='center'>
            <Grid item><Typography variant='bodyLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.respFamily')}</Typography></Grid>
            {!account.id && <Grid item display='flex' justifyContent='end'>
              <IconButton onClick={onDeleteAccount} aria-label="close" size='small'><CloseRoundedIcon /></IconButton>
            </Grid>}
          </Grid>
          <Grid item container gap={1}>
            {accountDetailsConfig.map((config) => (
              <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between'>
                {getAcccountDetailComponent(config)}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <AffiliationTable affiliations={sortedAffiliations} showType={true} showRelation={true} />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <GoalLinkingReview account={account} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RespFamilyReviewItem;
