import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabProps,
  TabsProps,
  SxProps,
} from '@mui/material';
import {
  TabPanel as MuiTabPanel,
  TabContext as MuiTabContext,
  TabList as MuiTabList,
  TabPanelProps, TabContextProps, TabListProps,
} from '@mui/lab';
import { SPACING } from 'ovComponents/0-tokens';
import { Typography } from 'ovComponents/1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

export const TabPanel = (props: TabPanelProps) => (<MuiTabPanel {...props} />);
export const TabContext = (props: TabContextProps) => (<MuiTabContext {...props} />);
export const TabList = (props: TabListProps) => (<MuiTabList {...props} />);

export const Tab = (props: TabProps) => {
  const styledLabel = <Typography variant='labelSmall'>{ props.label }</Typography>;
  const { sys } = useThemeTokens();

  const defaultTabSx: SxProps = {
    '&.MuiTab-root': {
      minHeight: '40px',
      minWidth: `${3 * SPACING}px`,
      height: '40px',
      textTransform: 'none',
      color: sys.color.onSurfaceVariant,
      paddingLeft: 0,
      paddingRight: 0,
      mx: `${1 * SPACING}px`,
    },
    '&:hover': {
      color: sys.color.onSurface,
    },
    '&.Mui-disabled': {
      color: sys.color.disabled,
    },
    '&.Mui-selected': {
      color: sys.color.primary,
    },
  };

  return (
    <MuiTab
      {...props}
      sx={[{ ...defaultTabSx }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
      label={styledLabel}
    />);
};

export const Tabs = (props: TabsProps) => {
  const { sys } = useThemeTokens();

  const defaultTabsSx: SxProps = {
    '&.MuiTabs-root': {
      minHeight: `${3 * SPACING}px`,
    },
    '.MuiTabs-indicator': {
      backgroundColor: sys.color.primary,
    },
    '.MuiTabs-flexContainer': {
      gap: `${4 * SPACING}px`,
      overflow: 'auto',
    },
  };

  return (<MuiTabs sx={{ ...props.sx, ...defaultTabsSx }} {...props} />);
};
