import {
  Typography, Box, CircularProgress, Pagination, Grid, IconButton, Tooltip, Alert,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Note, NoteObjectTypes, NoteStates } from '../../interfaces/note';
import RichTextEditor from '../inputs/richTextEditor';
import NewNote from './newNote';
import EditNoteType from './editNoteType';
import ConfirmationModal from '../modals/confirmationModal';
import { colors } from '../../theme/colors';
import { useLocalization } from '../../util/useLocalization';

export const FETCH_NOTES = gql`
  query fetchNotes($input: FetchNotesInput!) {
    fetchNotes(input: $input) {
      notes {
        id
        state
        type
        content
        createdAt
        updatedAt
        author {
          id
          email
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_NOTE = gql`
  mutation transitionNote($input: TransitionNoteInput!) {
    transitionNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export const notesStyles = {
  editorCustomStyles: {
    border: 'none !importanrt',
    minHeight: '65px',
    background: colors.noteBackground,
    marginTop: '8px',
    borderRadius: '8px',
    fontSize: '16px',
    padding: '16px 24px 16px 16px',
  },
  editIcon: {
    position: 'relative',
    top: '4px',
    fontSize: '12px',
    paddingBottom: '2px',
  },
  noteTypesText: {
    color: colors.noteTagLine,
    fontSize: '12px',
    display: 'flex',
  },
  timeStamp: {
    position: 'relative',
    top: '8px',
  },
  deleteRow: {
    top: '40px',
    zIndex: 1,
    right: '10px',
    color: colors.noteTagLine,
  },
  saveText: {
    top: '2px',
    color: colors.noteTagLine,
    fontSize: '12px',
    position: 'relative',
  },
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '424px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '376px',
      title: {
        fontWeight: 700,
      },
    },
    dialogButton: {
      minWidth: '160px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
};

const Notes = ({ objectId, objectIds, objectType }: { objectId?: string, objectIds?: string[], objectType: NoteObjectTypes }) => {
  const { permissions } = usePermissions();
  const { userContext } = useContext(UserContext);
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();
  const [page, setPage] = useState(1);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const pageSize = 10;
  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_NOTES, {
    variables: {
      input: {
        filter: {
          ...(objectId && { objectId }),
          ...(objectIds && { objectIds }),
          objectType,
          state: NoteStates.PUBLISHED,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });
  const [updateNoteType, setupdateNoteType] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>();
  const [selectedType, setSelectedType] = useState('ORGANIZATIONAL');
  const [deleteNote] = useMutation(TRANSITION_NOTE);
  const onCancelDelete = () => {
    setConfirmationOpen(false);
  };
  const onConfirmDelete = async () => {
    await deleteNote({
      variables: {
        input: {
          noteId: selectedNote.id,
          transition: 'delete',
        },
      },
    });
    refetch();
    setConfirmationOpen(false);
  };

  const allowAddNoteForm = permissions.includes('write:notes') && objectId;
  const notes = (data || previousData)?.fetchNotes.notes ?? [];

  return (
    <Box p={2}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {allowAddNoteForm ? (
            <Grid container>
              <NewNote objectId={objectId} noteObjectType={objectType} afterUpdate={refetch} />
            </Grid>
          ) : (
            notes.length === 0
            && <Alert severity="info" variant="outlined" sx={{ margin: '16px' }}>
              {t('buildModels:newNote.thereAreNoNotes')}
            </Alert>
          )}

          {notes.length > 0
            && <>
              <Grid container sx={{ mt: 1 }}>
                {notes.map((note: Note) => (
                  <Grid container sx={{ mb: 2 }} key={note.id}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="notesUserTitle"><b>{`${note.author.firstName} ${note.author.lastName}`}</b>{t('buildModels:newNote.noteUserPostFix')}</Typography>
                      {permissions.includes('transition:notes') && (
                        <Tooltip title={(userContext?.id !== note.author.id) ? t('buildModels:newNote.deleteWarning') : ''} placement="left-end">
                          <IconButton
                            data-testid="edit-button"
                            edge="end"
                            size="small"
                            onClick={async () => {
                              if (userContext?.id === note.author.id) {
                                setSelectedNote(note);
                                setConfirmationOpen(true);
                              }
                            }}
                            sx={notesStyles.deleteRow}
                          >
                            <DeleteIcon sx={{ fontSize: '12px' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        value={note.content || ''}
                        label=''
                        readonly
                        key={note?.id}
                        customEditorStyle={notesStyles.editorCustomStyles}
                      />
                    </Grid>
                    <Grid item justifyContent='space-between' xs={12} sx={notesStyles.noteTypesText}>
                      <EditNoteType
                        note={note}
                        notesStyles={notesStyles}
                        refetch={refetch}
                        selectedType={selectedType}
                        setSelectedNote={setSelectedNote}
                        setSelectedType={setSelectedType}
                        setupdateNoteType={setupdateNoteType}
                        updateNoteType={updateNoteType}
                        key={note?.id}
                        selectedNote={selectedNote}
                      />
                      <Grid item sx={notesStyles.timeStamp}>
                        <Typography variant="notesFooter">{localizedDateTime(note.createdAt)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchNotes?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </>
          }

          <ConfirmationModal
            open={confirmationOpen}
            loading={loading}
            title={t('buildModels:newNote.deleteTitle')}
            bodyText={t('buildModels:newNote.deleteSubText')}
            onConfirm={() => onConfirmDelete()}
            onCancel={() => onCancelDelete()}
            confirmButton={t('buildModels:newNote.delete')}
            cancelButton={t('buildModels:newNote.cancelDelete')}
            dialogStyles={notesStyles.confirmation}
          />
        </>
      )}
    </Box>
  );
};

export default Notes;
