/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ListItem,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link as MuiLink,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from '../../../components/modals/formModal';
import RoleSelect from '../../../components/inputs/roleSelect';
import DeleteOrganizationUser from './deleteOrganizationUser';
import { entityName } from '../../../util';
import {
  mapOrganizationUserLoginStatus, OrganizationUser, OrganizationUserAccessTypes,
  OrganizationUserEntity, OrganizationUserLoginStatus,
} from '../../../interfaces/organizationUser';
import { usePermissions } from '../../../providers/userContextProvider';
import ResetOrganizationUserMultifactor from './resetOrganizationUserMultifactor';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { Languages } from '../../../interfaces';
import SendOrganizationUserInvitations from './sendOrganizationUserInvitations';
import RevokeOrganizationUserInvitation from './revokeOrganizationUserInvitation';
import { base64ToFile } from '../../../ovComponents/4-module/workflowCompletion/subSteps/utils';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../../ovComponents/3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { DELETE_FILE_DOCUMENT } from '../../../ovComponents/5-page/manageNewsAndInsights/components/editArticle';
import { DroppableFileInput, FileTypes } from '../../../ovComponents/2-component';
import { InlineImage } from '../../../ovComponents/2-component/inlineImage/inlineImage';

const UPDATE_ORGANIZATION_USER = gql`
mutation updateOrganizationUser($input: UpdateOrganizationUserInput!) {
  updateOrganizationUser(input: $input) {
    organizationUser {
      id
    }
  }
}
`;

const FETCH_ORGANIZATION_USER_ENTITIES = gql`
query fetchOrganizationUserEntities($organizationUserId: ObjectID!) {
  fetchOrganizationUser(organizationUserId: $organizationUserId) {
    organizationUser {
      id
      avatar
      entities {
        relation
        entity {
          id
          type
          entityName
          firstName
          lastName
        }
      }
    }
  }
}
`;

const EditOrganizationUser = ({
  afterUpdate, organizationUserToUpdate, open, handleClose,
}: { afterUpdate: () => void, organizationUserToUpdate: OrganizationUser, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['orgSettings']);
  const { permissions } = usePermissions();
  const [organizationUser, setOrganizationUser] = useState(organizationUserToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetMfaDialogOpen, setResetMfaDialogOpen] = useState(false);
  const [emailUpdateConfirmationOpen, setEmailUpdateConfirmationOpen] = useState(false);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [revokeInviteDialogOpen, setRevokeInviteDialogOpen] = useState(false);
  const [entityExpanded, setEntityExpanded] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument, { loading: createLoading }] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument, { loading: deleteLoading }] = useMutation(DELETE_FILE_DOCUMENT);
  const languages = Object.values(Languages);

  const canUpdateEmail = permissions.includes('write:organization_user_authentication');
  const canResetMfa = canUpdateEmail && (organizationUserToUpdate.phone || organizationUserToUpdate?.mfaEnrollmentId) && organizationUserToUpdate.organization?.enableMultiFactorAuthentication;

  const canSeeEntities = permissions.includes('read:organization_user_entities');

  const { loading: entitiesLoading, data: entitiesData } = useQuery(FETCH_ORGANIZATION_USER_ENTITIES, {
    variables: {
      organizationUserId: organizationUserToUpdate.id,
    },
    skip: !canSeeEntities,
  });
  const isEntitiesDataReady = !!entitiesData && !entitiesLoading;

  const loginStatus = mapOrganizationUserLoginStatus(organizationUserToUpdate);
  const canSendInvite = (
    permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users')
  ) && [
    OrganizationUserLoginStatus.NOT_INVITED, OrganizationUserLoginStatus.INVITATION_EXPIRED,
  ].includes(loginStatus);
  const canRevokeInvite = (
    (permissions.includes('write:invite_organization_users') || permissions.includes('write:organization_users'))
    && (loginStatus === OrganizationUserLoginStatus.INVITED)
  );
  const inviteTextKey = loginStatus === OrganizationUserLoginStatus.INVITATION_EXPIRED ? 'resendInvitation' : 'inviteUser';

  const isEmailUpdated = organizationUserToUpdate.email && organizationUserToUpdate.email.toLowerCase() !== organizationUser.email?.toLowerCase();
  const isPhoneUpdated = organizationUserToUpdate.phone && organizationUserToUpdate.phone !== organizationUser.phone;

  const [updateOrganizationUser, { loading }] = useMutation(UPDATE_ORGANIZATION_USER, {
    variables: {
      input: {
        organizationUserId: organizationUser?.id,
        firstName: organizationUser?.firstName,
        lastName: organizationUser?.lastName,
        language: organizationUser?.language ?? Languages.ENGLISH,
        roleId: organizationUser?.role?.id,
        accessType: organizationUser?.accessType,
        avatar: organizationUser?.avatar,
        ...(canUpdateEmail && organizationUser.phone && (
          isPhoneUpdated || !organizationUserToUpdate.phone
        ) ? { phone: organizationUser.phone } : {}),
        ...(canUpdateEmail && organizationUser.email && (
          isEmailUpdated || !organizationUserToUpdate.email
        ) ? { email: organizationUser.email } : {}),
      },
    },
  });

  const setAvatar = async (file: File) => {
    setStartLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      doUpload(base64String);
    };
  };

  const doUpload = async (newAvatar: string): Promise<any> => {
    const uploadingFile = base64ToFile(newAvatar!, 'avatar');
    const createFileInput: any = {
      objectType: 'ORGANIZATION_USER',
      objectId: organizationUser.id,
      fileName: 'avatar',
      type: 'AVATAR',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: organizationUser.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      return;
    }
    /* (2) do the upload */
    try {
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      return;
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ORGANIZATION_USER';

    await createFileDocument({
      variables: { input: createFileInput },
      onCompleted: async (data: any) => {
        setOrganizationUser({ ...organizationUser, avatar: data.createFileDocument.fileDocument.id });
        setStartLoading(false);
      },
    });
  };

  useEffect(() => {
    setLocalOpen(open);
    setOrganizationUser(organizationUserToUpdate);
  }, [organizationUserToUpdate, open]);

  const doUpdate = async () => {
    await updateOrganizationUser();
    afterUpdate();
  };

  const update = async (event: any) => {
    event.preventDefault();
    if (isEmailUpdated && canUpdateEmail) {
      setEmailUpdateConfirmationOpen(true);
    } else {
      doUpdate();
    }
  };

  const deleteFile = async () => {
    await deleteFileDocument({
      variables: { fileDocumentId: organizationUser.avatar },
      onCompleted(data: any) {
        setOrganizationUser({ ...organizationUser, avatar: undefined });
        updateOrganizationUser({
          variables: {
            input: {
              organizationUserId: organizationUser.id,
              avatar: null,
            },
          },
        } as any);
      },
    });
  };

  const updateField = (event: any) => {
    const key: 'name' | 'subdomain' = event.target.id;
    setOrganizationUser({ ...organizationUser, [key]: event.target.value as string });
  };

  const onDeleteMenuClick = () => {
    setDeleteDialogOpen(true);
  };
  const onResetMfaMenuClick = () => {
    setResetMfaDialogOpen(true);
  };
  const onSendInviteMenuClick = () => {
    setSendInviteDialogOpen(true);
  };
  const onRevokeInviteMenuClick = () => {
    setRevokeInviteDialogOpen(true);
  };

  if (!organizationUser) {
    return <></>;
  }
  const menuItems = [
    <MenuItem key='menu1' onClick={onDeleteMenuClick} >{t('shared:delete')}</MenuItem>,
    ...(canResetMfa ? [<MenuItem key='menu2' onClick={onResetMfaMenuClick} >{t('userModal.resetMfa')}</MenuItem>] : []),
    ...(canSendInvite ? [<MenuItem key='menu3' onClick={onSendInviteMenuClick} >{t(`userModal.${inviteTextKey}`)}</MenuItem>] : []),
    ...(canRevokeInvite ? [<MenuItem key='menu4' onClick={onRevokeInviteMenuClick} >{t('userModal.revokeInvitation')}</MenuItem>] : []),
  ];
  return (
    <>
      <FormModal
        loading={loading || createLoading || deleteLoading || startLoading}
        title={t('userModal.editTitle')}
        formButton={t('update')}
        onSubmit={update}
        open={localOpen}
        handleClose={handleClose}
        menuItems={menuItems}
      >
        <ListItem>
          <TextField label={t('userModal.firstName')} sx={{ width: '100%' }} value={organizationUser.firstName ?? ''} onChange={updateField} id='firstName' />
        </ListItem>
        <ListItem>
          <TextField label={t('userModal.lastName')} sx={{ width: '100%' }} value={organizationUser.lastName ?? ''} onChange={updateField} id='lastName' />
        </ListItem>
        <ListItem>
          <TextField label={t('userModal.email')} required sx={{ width: '100%' }} value={organizationUser.email ?? ''} onChange={updateField} id='email' disabled={!canUpdateEmail} />
        </ListItem>
        <ListItem>
          <TextField
            label={t(`userModal.${organizationUser?.mfaEnrollmentId ? 'phoneUsedForLogin' : 'phone'}`)}
            sx={{ width: '100%' }}
            id='phone'
            value={organizationUser.phone}
            onChange={updateField}
            disabled={!!organizationUserToUpdate?.mfaEnrollmentId}
          />
        </ListItem>
        <ListItem>
          <FormControl>
            <FormLabel>{t('userModal.language')}</FormLabel>
            <RadioGroup
              row
              value={organizationUser.language ?? Languages.ENGLISH}
              onChange={(e) => setOrganizationUser({ ...organizationUser, language: e.target.value as Languages })}
            >
              {languages.map((lang) => <FormControlLabel key={lang as string} value={lang} control={<Radio />} label={t(`userModal.languageList.${lang}`)} />)}
            </RadioGroup>
          </FormControl>
        </ListItem>
        {organizationUser.role?.id && organizationUser.organization?.id && (
          <ListItem>
            <RoleSelect
              value={organizationUser.role.id}
              label={t('userModal.role')}
              onChange={(event: any) => setOrganizationUser({ ...organizationUser, role: { id: event.target.value as string } })}
              organizationId={organizationUser.organization?.id}
              disabled={organizationUser.organization?.id === ''}
            />
          </ListItem>
        )}
        <ListItem>
          <TextField
            select
            value={organizationUser.accessType}
            label={t('userModal.accessType')}
            sx={{ width: '100%' }}
            onChange={(event: any) => setOrganizationUser({ ...organizationUser, accessType: event.target.value })}
          >
            <MenuItem key='1' value={OrganizationUserAccessTypes.ORGANIZATION}>{OrganizationUserAccessTypes.ORGANIZATION}</MenuItem>
            <MenuItem key='2' value={OrganizationUserAccessTypes.ENTITY}>{OrganizationUserAccessTypes.ENTITY}</MenuItem>
          </TextField>
        </ListItem>
        <ListItem>
          <Box display='flex' flexDirection='column' width='100%'>
            <FormLabel sx={{ mb: 1 }}>{t('userModal.avatar')}</FormLabel>
            {!organizationUser.avatar && (
              <DroppableFileInput onFileChosen={(file: File) => setAvatar(file)} acceptedTypes={[FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.HEIC]} fileMaxSize={3} />
            )}
            {organizationUser.avatar && (
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <InlineImage fileDocumentId={organizationUser.avatar} style={{
                  height: '100px', width: '100px', borderRadius: '100px', marginBottom: '8px', objectFit: 'cover',
                }} />
                <Button onClick={() => deleteFile()} variant='text'>{t('shared:delete')}</Button>
              </Box>
            )}
          </Box>
        </ListItem>
        {organizationUser.accessType === OrganizationUserAccessTypes.ENTITY && canSeeEntities && (
          <ListItem>
            <Accordion expanded={entityExpanded && isEntitiesDataReady} disabled={!isEntitiesDataReady} onChange={() => setEntityExpanded((prev) => !prev)} sx={{ width: '100%', mt: 2, mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
              >
                <Typography>{t('userEntityTable.title')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table aria-label="simple table" sx={{ mb: 2, padding: 10 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography variant='overline'>{t('userEntityTable.name')}</Typography></TableCell>
                      <TableCell><Typography variant='overline'>{t('userEntityTable.relation')}</Typography></TableCell>
                      <TableCell><Typography variant='overline'>{t('userEntityTable.entityType')}</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entitiesData?.fetchOrganizationUser?.organizationUser?.entities?.map((e: OrganizationUserEntity) => (
                      <TableRow
                        hover
                        key={e.entity?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                      >
                        <TableCell component="th" scope="row">
                          <MuiLink component={Link} to={`/clients/${e.entity?.id}`}>{entityName(e.entity)}</MuiLink>
                        </TableCell>
                        <TableCell>{t(`components:accessToEntity.relations.${e.relation}`)}</TableCell>
                        <TableCell>{t(`entityTypes:${e.entity?.type}`)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        )}
      </FormModal>
      <DeleteOrganizationUser
        open={deleteDialogOpen}
        organizationUserToDelete={organizationUser}
        afterDelete={() => {
          setDeleteDialogOpen(false);
          afterUpdate();
        }}
        handleClose={() => setDeleteDialogOpen(false)}
      />
      {canResetMfa && (
        <ResetOrganizationUserMultifactor
          open={resetMfaDialogOpen}
          organizationUserToReset={organizationUser}
          afterReset={() => {
            setResetMfaDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setResetMfaDialogOpen(false)}
        />
      )}
      {canUpdateEmail && isEmailUpdated && (
        <ConfirmationModal
          title={t('emailUpdateConfirmation.title')}
          bodyText={t('emailUpdateConfirmation.body', { email: organizationUser.email })}
          open={emailUpdateConfirmationOpen}
          maxWidth='sm'
          onConfirm={async () => {
            setEmailUpdateConfirmationOpen(false);
            doUpdate();
          }}
          onCancel={() => {
            setEmailUpdateConfirmationOpen(false);
          }}
        />
      )}
      {canSendInvite && sendInviteDialogOpen && (
        <SendOrganizationUserInvitations
          open={sendInviteDialogOpen}
          organizationUser={organizationUser}
          onComplete={() => {
            setSendInviteDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setSendInviteDialogOpen(false)}
        />
      )}
      {canRevokeInvite && revokeInviteDialogOpen && (
        <RevokeOrganizationUserInvitation
          open={revokeInviteDialogOpen}
          organizationUser={organizationUser}
          onComplete={() => {
            setRevokeInviteDialogOpen(false);
            afterUpdate();
          }}
          handleClose={() => setRevokeInviteDialogOpen(false)}
        />
      )}
    </>
  );
};

export default EditOrganizationUser;
