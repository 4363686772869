import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Icon, Typography } from 'ovComponents/1-primative';
import { colors } from 'theme/colors';
import { Account, AffiliationTypes, Affiliation } from 'interfaces';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'ovComponents/2-component';
import { generateClientNameString } from 'util/index';
import { GoalLinkingReview } from '../../goalLinking';

interface FhsaReviewItemProps {
  account: Account,
  onDeleteAccount: (account?: Account) => void,
}
export const FHSAReviewItem = ({ account, onDeleteAccount }: FhsaReviewItemProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationRelationship']);
  const hasSuccessor = (): boolean => {
    const response = account.affiliations?.find((item) => item.type === AffiliationTypes.Successor);
    return !!response;
  };
  const getSuccessor = (): Affiliation[] => account.affiliations?.filter((item) => item.type === AffiliationTypes.Successor) ?? [];
  const hasBeneficiary = (): boolean => {
    const response = account.affiliations?.find((item) => item.type !== AffiliationTypes.Successor);
    return !!response;
  };
  const getBeneficiary = (): Affiliation[] => account.affiliations?.filter((item) => item.type !== AffiliationTypes.Successor) ?? [];
  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent>
        <Box pl={0.9} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
          <Typography variant='bodyLarge' weight='bold' sx={{ mt: 1 }}>{t('workflowCompletions:accountReviewPanel.tfsa')}</Typography>
          {
            account.id ? undefined : <IconButton onClick={() => onDeleteAccount()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
          }
        </Box>
        <Box sx={{ mt: 2, mb: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.successor')}</Typography>
          <Typography variant='bodyMedium'>{hasSuccessor() ? 'Yes' : 'No'}</Typography>
        </Box>
        {
          hasSuccessor() ? (
            <Table
              sx={{
                mb: 2, tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '2px',
              }}
              aria-label="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: '#F0F4F8',
                      borderRadius: '2px',
                      border: 'none',
                      padding: '8px 5px',
                    }}
                    width="20%"
                  >
                    <Typography sx={{ color: colors.gray500 }} variant='headingOverline'>{t('workflowCompletions:accountReviewPanel.tableItems.name')}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F0F4F8',
                      borderRadius: '2px',
                      border: 'none',
                      padding: '8px 5px',
                    }}
                    width="20%"
                  >
                    <Typography sx={{ color: colors.gray500 }} variant='headingOverline'>{t('workflowCompletions:accountReviewPanel.tableItems.relationship')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  getSuccessor().map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                    >
                      <TableCell sx={{ padding: '8px 5px' }}>{generateClientNameString(item.user)}</TableCell>
                      <TableCell sx={{ padding: '8px 5px' }}>{t(`affiliationTypes:affiliationRelationship.${item.relation}`)}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          ) : undefined
        }
        <Box sx={{ mt: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:accountReviewPanel.beneficiary')}</Typography>
          <Typography variant='bodyMedium'>{hasBeneficiary() ? 'Yes' : 'No'}</Typography>
        </Box>
        {
          hasBeneficiary() ? (
            <Table
              sx={{
                tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '2px',
              }}
              aria-label="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: '#F0F4F8',
                      borderRadius: '2px',
                      border: 'none',
                      padding: '8px 5px',
                    }}
                    width="40%"
                  >
                    <Typography sx={{ color: colors.gray500 }} variant='headingOverline'>{t('workflowCompletions:accountReviewPanel.tableItems.name')}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F0F4F8',
                      borderRadius: '2px',
                      border: 'none',
                      padding: '8px 5px',
                    }}
                    width="40%"
                  >
                    <Typography sx={{ color: colors.gray500 }} variant='headingOverline'>{t('workflowCompletions:accountReviewPanel.tableItems.relationship')}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F0F4F8',
                      borderRadius: '2px',
                      border: 'none',
                      padding: '8px 5px',
                    }}
                    width="20%"
                  >
                    <Typography sx={{ color: colors.gray500 }} variant='headingOverline'>{t('workflowCompletions:accountReviewPanel.tableItems.allocation')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  getBeneficiary().map((item) => (
                    <TableRow
                      hover
                      key={' '}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                    >
                      <TableCell sx={{ padding: '8px 5px' }}>{generateClientNameString(item.user)}</TableCell>
                      <TableCell sx={{ padding: '8px 5px' }}>{t(`affiliationTypes:affiliationRelationship.${item.relation}`)}</TableCell>
                      <TableCell sx={{ padding: '8px 5px' }}>{t(`${item.allocation}%`)}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          ) : undefined
        }
        <Box sx={{ mt: 1, px: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <GoalLinkingReview account={account} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default FHSAReviewItem;
