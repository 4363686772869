import { Chip } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import { colors } from '../../theme/colors';

const SummaryChip = ({
  icon, leadingLabel, label, property, onClick, isAlert = false,
}: {
  icon: ReactElement<any, string | JSXElementConstructor<any>> | undefined,
  leadingLabel?: string,
  label: string,
  property?: string,
  onClick?: () => void,
  isAlert?: boolean
}) => {
  const labelContent = leadingLabel
    ? <div><span style={{ color: colors.gray500 }}>{leadingLabel}:</span>&nbsp;{label}</div>
    : label;

  return (
    <Chip
      onClick={onClick}
      icon={icon}
      label={labelContent}
      sx={{
        borderRadius: '20px',
        background: 'white',
        border: `1px solid ${isAlert ? colors.error : colors.neutral80}`,
        mr: 1,
        cursor: onClick ? 'pointer' : 'default',
        color: isAlert ? colors.error : 'inherit',
      }}
    />
  );
};

export default SummaryChip;
