import {
  Typography, Box, LinearProgress, linearProgressClasses, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { MemberTag } from 'pages/household/components/memberTag';
import { useHouseholdContext } from 'pages/household';
import { colors as oldColors } from 'theme/colors';
import { memberColors } from 'pages/household/components/memberColors';
import { useState } from 'react';
import { Badge, Tooltip } from 'ovComponents';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Goal, GoalStates, Statistics } from '../../../interfaces';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: oldColors.goalsProgressBar,
  },
}));

interface MultiLinearProgressProps {
  maximum: number
  values: number[]
  names: string[]
  onHover: (index: number | null) => void
}

const MultiLinearProgress = ({
  maximum, values, names, onHover,
}: MultiLinearProgressProps) => {
  const height = 12;
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <span style={{
      display: 'block',
      width: '100%',
      height,
      backgroundColor: oldColors.gray100,
      borderRadius: height / 2,
      marginTop: '32px',
    }}>
      {values.map((value, index) => (
        <span
          key={index}
          onMouseOver={() => { setHoverIndex(index); onHover(index); }}
          onMouseOut={() => { setHoverIndex(null); onHover(null); }}
        >
          <span
            style={{
              display: 'inline-block',
              height,
              width: `${Math.min(100 * value / maximum, 100)}%`,
              backgroundColor: memberColors(index).bar,
              borderRadius: height / 2,
            }}
          >
            { index === hoverIndex
              ? <Typography
                  color={oldColors.gray500} fontWeight={500} fontSize="12px"
                  style={{
                    display: 'inline',
                    position: 'relative',
                    bottom: '24px',
                    overflow: 'visible',
                    whiteSpace: 'nowrap',
                  }}>
                  {names[index]}
                </Typography>
              : <>&nbsp;</>
            }
          </span>
        </span>
      ))}
    </span>
  );
};

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const GoalItem = ({ goal, tag, onlyUserId }: { goal: Goal, tag?: Tag, onlyUserId?: string }) => {
  const { t } = useTranslation(['client']);
  const navigate = useNavigate();
  const householdContext = useHouseholdContext();
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const awaitingSubAccountCount: number = goal.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  function isValidSubAccount(subAccount: any) {
    const isCorrectOwner = onlyUserId === undefined || subAccount?.account?.user?.id === onlyUserId;
    const isActive = subAccount.state !== 'INACTIVE';
    return isCorrectOwner && isActive;
  }

  const numSubAccountsLinked = goal.subAccounts?.filter(isValidSubAccount).length ?? 0;

  /* For household views, split statistics by member */
  const onlyUserIndex = householdContext?.indexedMembers[`${onlyUserId}`];
  interface NameAndStats { name: string, statistics: Statistics }
  let orderedStatisticsByMember: NameAndStats[] = [];
  if (householdContext?.members && (goal as any).user0statistics) {
    orderedStatisticsByMember = householdContext?.members.map((member, index) => (
      { name: member.name, statistics: (goal as any)[`user${index}statistics`] }
    ));
  }
  const selectedStatistic = onlyUserIndex !== undefined ? orderedStatisticsByMember[onlyUserIndex].statistics : goal.statistics;
  const marketValue = selectedStatistic?.marketValueCents ?? 0;

  let shownMarketValue = marketValue;
  let shownSimpleReturnAmount = selectedStatistic?.simpleReturnAmount ?? 0;
  let shownSimpleReturnPercent = selectedStatistic?.simpleReturnPercent ?? 0;
  if (hoverIndex !== null) {
    const partialStatistics:Statistics = orderedStatisticsByMember[hoverIndex].statistics;
    shownMarketValue = partialStatistics.marketValueCents ?? 0;
    shownSimpleReturnAmount = partialStatistics.simpleReturnAmount ?? 0;
    shownSimpleReturnPercent = partialStatistics.simpleReturnPercent ?? 0;
  }

  const targetValue = goal.targetAmountCents ?? 0;
  const percentageOfTarget = targetValue === 0 ? 0 : Math.min(marketValue / targetValue, 1.00);

  if (onlyUserIndex !== undefined) {
    orderedStatisticsByMember = orderedStatisticsByMember.filter((stats:NameAndStats, index: number) => index === onlyUserIndex);
  }

  const getIconForState = () => {
    if (goal.state === GoalStates.ACTIVE) {
      return (<CheckCircleRoundedIcon style={{ color: oldColors.success, fontSize: '14px' }} />);
    }
    return (<CancelRoundedIcon style={{ fontSize: '14px' }} />);
  };
  return (
    <Box
      onClick={() => navigate(`goal/${goal.id}`)}
      padding="16px"
      border='1px solid #DBDFE3'
      marginBottom='16px'
      borderRadius='4px'
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ position: 'relative', top: '-25px', height: '15px' }}>
        <Box display="flex" flexDirection="row">
          { tag && <MemberTag sequenceNumber={tag.sequenceNumber} name={tag.text} /> }
        </Box>
        <Box display="flex" flexDirection="row" >
          { awaitingSubAccountCount > 0 && (
            <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
              <Badge label={awaitingSubAccountCount.toString()} color='negative'/>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" marginTop="-15px" marginBottom="15px">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Typography fontWeight={600} fontSize="16px">{goal.name ? goal.name : t(`goalType.${goal.type}`)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            {getIconForState()}
            <Typography fontWeight={600} sx={{ ml: '3px' }} fontSize="12px">{t(`goalsSummary.goalState.${goal.state}`)}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography fontWeight={600} fontSize="18px">{formatMoneyValue(shownMarketValue)}</Typography>
          <Typography color={(shownSimpleReturnAmount) >= 0 ? oldColors.success : 'error'} fontWeight={500} fontSize="12px">
            {formatMoneyValue(shownSimpleReturnAmount)} {`(${formatPercentValue(shownSimpleReturnPercent)})`}
          </Typography>
        </Box>
      </Box>
      <Box>
        { Object.keys(orderedStatisticsByMember).length < 2
          ? <BorderLinearProgress variant="determinate"
              value={percentageOfTarget * 100}
            />
          : <MultiLinearProgress
              maximum={targetValue}
              values={orderedStatisticsByMember.map((x) => x.statistics?.marketValueCents ?? 0)}
              names={orderedStatisticsByMember.map((x) => x.name)}
              onHover={setHoverIndex}
            />
        }
        <Box display="flex" justifyContent="space-between" marginTop="5px">
          <Typography color={oldColors.gray500} fontWeight={500} fontSize="12px">
            {t('goalsSummary.subAccountsLinked', { count: numSubAccountsLinked })}
          </Typography>
          <Typography color={oldColors.gray500} fontWeight={500} fontSize="12px">
            {formatPercentValue(percentageOfTarget, 0)} {t('goalsSummary.ofTargetAmount')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GoalItem;
