import {
  MenuItem, IconButton, Select, OutlinedInput, InputLabel, FormControl,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { BankAccount } from 'interfaces';
import { usePermissions } from '../../providers/userContextProvider';
import AddBankAccountModal from '../modals/addBankAccountModal';
import { colors } from '../../theme/colors';
import ConfirmationModal from '../modals/confirmationModal';
import { generateBankDetailString } from '../../util';

const FETCH_BANK_ACCOUNTS = gql`
  query fetchBankAccounts($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        bankAccounts {
          id
          name
          bankAccountNumber
          institutionNumber
        }
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($bankAccountId: ObjectID!) {
    deleteBankAccount(bankAccountId: $bankAccountId) {
      bankAccount {
        id
        name
      }
    }
  }
`;

type BankAccountSelectProps = {
  userId: string,
  setBankAccount: (event: string) => void, value: string, label: string, size?: 'small' | 'medium' | undefined, disabled?: boolean, readonly?: boolean, canEditBankAccount?: boolean,
};

export const BankAccountSelect = ({
  userId, setBankAccount, value, label, size, disabled = false, readonly = false, canEditBankAccount = true,
}: BankAccountSelectProps) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [addBankAccount, setAddBankAccount] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>();
  const selectedBankAccountId = useRef<string>('');
  const { loading, refetch } = useQuery(
    FETCH_BANK_ACCOUNTS,
    {
      skip: userId === '' || !permissions.includes('read:bank_account_basic'),
      variables: { userId },
      onCompleted: (data) => {
        setBankAccounts(data.fetchUser.user.bankAccounts);
      },
    },
  );

  const [deleteBankAccountMutation] = useMutation(DELETE_BANK_ACCOUNT, {
    variables: { bankAccountId: selectedBankAccountId.current },
    onCompleted: () => refetch(),
  });

  const deleteBankAccount = () => {
    deleteBankAccountMutation().then();
    setOpenConfirmationModal(false);
  };

  if (!permissions.includes('read:bank_account_basic')) return <></>;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="choose-bank">{label}</InputLabel>
        <Select
          value={bankAccounts ? value : ''}
          labelId="choose-bank"
          data-testid="bankAccount-dropdown"
          input={<OutlinedInput label={label} />}
          fullWidth
          onChange={(e) => {
            if (e.target.value === 'addBank') {
              setAddBankAccount(true);
            } else {
              setAddBankAccount(false);
              setBankAccount(e.target.value);
            }
          }}
          size={size}
          disabled={disabled}
          inputProps={{ readOnly: readonly }}
          renderValue={(selected) => {
            const foundBankAccount = bankAccounts?.find((item: Record<string, unknown>) => item.id === selected);
            if (foundBankAccount) {
              return generateBankDetailString(foundBankAccount);
            }
            return '';
          }}
        >
          {
            loading ? <MenuItem>...</MenuItem> : (
              bankAccounts || [])?.map((x: any) => (
                <MenuItem
                  onClick={() => setBankAccount(x.id)}
                  key={x.id}
                  value={x.id}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {x.name} {x.bankAccountNumber}
                  { canEditBankAccount && permissions.includes('write:bank_account_basic') ? <IconButton aria-label="comment" onClick={() => {
                    selectedBankAccountId.current = x.id;
                    setOpenConfirmationModal(true);
                  }}>
                    <DeleteForeverIcon />
                  </IconButton> : undefined }
                </MenuItem>
            ))
          }
          { canEditBankAccount && permissions.includes('write:bank_account_basic')
            && <MenuItem sx={{ color: colors.success }} key="addBank" value="addBank">{t('components:bankAccountSelect.addBank')}</MenuItem>
          }
        </Select>
      </FormControl>
      { canEditBankAccount && (
        <AddBankAccountModal
          handleClose={() => setAddBankAccount(false)}
          open={addBankAccount}
          afterCreate={(bank) => {
            setBankAccount(bank.id);
            refetch();
            setAddBankAccount(false);
          }}
          userId={userId}
        />
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        title={t('components:bankAccountSelect.confirmationDialogTitle')}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => deleteBankAccount()}
      />
    </>
  );
};

export const testables = {
  FETCH_BANK_ACCOUNTS,
};
