import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid, Typography,
} from '@mui/material';
import CreateNewModal from 'components/modals/createNewModal';
import {
  ClientGroup,
  Organization,
} from 'interfaces';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'providers/userContextProvider';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import OrganizationSelect from 'components/inputs/organizationSelect';
import { Button, ConfirmationModal } from 'ovComponents';
import { useGlobalToast } from 'providers/globalToastProvider';
import { FETCH_USER } from '..';

const UPDATE_USER_ORGANIZATION = gql`mutation updateUserOrganization($userId: ObjectID!, $newOrganizationId: ObjectID!) {
  updateUserOrganization(input:{
    userId: $userId
    newOrganizationId: $newOrganizationId
  }) {
    user {
      lastName firstName
      organization { name }
    }
  }
}`;

export const EditClientOrganization = ({
  userId,
  userHouseholds,
  clientName,
  currentOrganization,
  onClose,
}:{
  userId: string,
  userHouseholds?: ClientGroup[],
  clientName: string,
  currentOrganization: Organization,
  onClose: () => void
}) => {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const [organizationId, setOrganizationId] = useState<string>(currentOrganization.id);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { showToast } = useGlobalToast();

  const [updateUserOrganizationMutation, { loading }] = useMutation(UPDATE_USER_ORGANIZATION, {
    variables: {
      userId,
      newOrganizationId: organizationId,
    },
    refetchQueries: [FETCH_USER(permissions)],
  });

  const doUpdateUser = async () => {
    setConfirmationModalOpen(false);
    await updateUserOrganizationMutation();
    onClose();
    showToast({
      severity: 'info',
      message: t('editClientOrganization.updatedToastMessage', { clientName }),
    });
  };

  const organizationSelectValid = organizationId && organizationId !== currentOrganization.id;

  const allInputsValid = organizationSelectValid;

  if (userHouseholds !== undefined && userHouseholds.length > 0) {
    return (
    <Dialog open={true} onClose={onClose} >
      <DialogTitle>{t('editClientOrganization.moveClientToAnotherOrganization')}</DialogTitle>
      <DialogContent sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '8em',
      }}>
        <Typography>{t('editClientOrganization.cannotMoveHouseholdMembers')}</Typography>
        <Button label={t('shared:proceed')} fullWidth onClick={onClose}/>
      </DialogContent>
    </Dialog>);
  }

  if (confirmationModalOpen) {
    return (
      <ConfirmationModal
        open={confirmationModalOpen}
        title={t('editClientOrganization.confirmationTitle')}
        bodyText={t('editClientOrganization.confirmationSubtitle')}
        confirmButtonLabel={t('shared:yes')}
        cancelButtonLabel={t('shared:no')}
        onCancel={() => setConfirmationModalOpen(false)}
        onConfirm={() => doUpdateUser()}
      />
    );
  }

  return <CreateNewModal
    loading={false}
    forceOpen={!loading}
    buttonType="NONE"
    buttonText={t('buttons:save')}
    title={t('editClientOrganization.moveClientToAnotherOrganization')}
    onSubmit={() => setConfirmationModalOpen(true)}
    buttonVariant="contained"
    onClose={onClose}
    disabled={!allInputsValid}
  >
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography>
          {t('details.clientName')}: {clientName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t('editClientOrganization.currentOrganization')}: {currentOrganization.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrganizationSelect
          label='new organization'
          value={organizationId}
          onChange={(e) => { setOrganizationId(e.target.value); }}
          autoFocus
        />
      </Grid>
    </Grid>
  </CreateNewModal>;
};
