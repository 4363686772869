export interface ElevationTokens {
  none?: string;
  low?: string;
  medium?: string;
  high?: string;
  surface?: string;
  modal?: string;
}

export const elevation: ElevationTokens = {
  none: '0 0 0 0',
  low: '0 2px 4px 0 rgba(0,0,0,0.15)',
  medium: '0 2px 12px 0 rgba(0,0,0,0.15)',
  high: '0 2px 16px 0 rgba(0,0,0,0.15)',
  surface: '0 4px 25px 0 rgba(0,0,0,0.05)',
  modal: '0 4px 40px 16 rgba(0,0,0,0.25)',
};
