import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { useNavigate } from 'react-router-dom';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const CloseAccountModal = ({
  handleClose, open, accountId,
}: { accountId: string, handleClose: () => void, open: boolean }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const closeAccountData = (data: { closeAccount: { account: { id: string } } }): void => {
    handleClose();
    navigate(-1);
    showToast({ message: t('accountsDetail:closeAccountDialog.success'), severity: 'success' });
  };

  const [closeAccountMutation] = useMutation(CLOSE_ACCOUNT, {
    variables: {
      input: {
        accountId,
        inactiveReason: 'OTHER',
      },
      skipErrorHandler: true,
    },
    refetchQueries: [FETCH_ACCOUNTS(permissions), FETCH_USER(permissions)],
    onCompleted: closeAccountData,
  });

  return (
    <ConfirmationModal
      title={t('accountsDetail:closeAccountDialog.title')}
      bodyText={t('accountsDetail:closeAccountDialog.subtitle')}
      open={open}
      maxWidth='sm'
      confirmButton={t('accountsDetail:closeAccountDialog.continueButton')}
      cancelButton={t('accountsDetail:closeAccountDialog.cancelButton')}
      onConfirm={() => closeAccountMutation()}
      onCancel={handleClose}
    />
  );
};

export default CloseAccountModal;
