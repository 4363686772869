import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const NewsHeadlines = ({ options }: { options: any }) => {
  useRefinitivWidget('#news-headlines-widget', 'WNewsHeadlines', { symbols: options.symbols ?? [] });

  return (
    <Card>
      <div id='news-headlines-widget'></div>
    </Card>
  );
};
