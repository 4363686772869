import { translateBackend } from 'assets/i18n/config';
import { Box, Switch } from '../..';

export const ExtendedBooleanEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => (
  <Box sx={{ mb: 2 }}>
    <Switch label={translateBackend(option.label)} checked={option.value.value || option.value.enabled || (typeof option.value === 'boolean' && option.value)} onChange={(e: any) => {
      const newOptions = [...options];
      if (newOptions[i]?.options?.customLabelsTemp) {
        newOptions[i].value = {
          ...newOptions[i].options,
          value: e,
          enabled: e,
          customLabelsTemp: {
            goalLabel: {
              enabled: false,
            },
          },
        };
      } else {
        newOptions[i].value = { ...option.value, value: e };
      }
      setOptions(newOptions);
    }}></Switch>
  </Box>
);
