import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const styleBox = {
  display: 'flex',
  alignItems: 'left',
  borderRadius: '12px',
  padding: '16px',
  backgroundColor: '#fffff',
  border: '1px solid #e0e0e0',
  boxShadow: '0 4px 6px rgba(0,0,0.1))',
};

export const RiskLevelBox = ({
  testId,
  riskLevel,
  riskLevelDescription,
}: {
  testId?: string;
  riskLevel: string;
  riskLevelDescription?: string;
}) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation('riskProfile');

  return (
    <>
      <Box
        data-testid={testId ?? 'risk-level-box'}
        sx={styleBox}>
        <Box >
          <Typography display={'contents'} weight='bold' variant='bodyLarge' sx={{ mb: 3 }}>{t('riskProfileIs')}
            <span style={{ color: sys.color.primary, marginLeft: '5px' }}>{riskLevel}</span>
          </Typography>
        </Box>
      </Box>
      <Box margin={'20px 0'}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px' }}>{riskLevelDescription}</Typography>
      </Box>
    </>
  );
};
