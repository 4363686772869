import { TextField, ListItem } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';

export const UPDATE_CLIENT_GROUP = gql`
mutation updateClientGroup($input: UpdateClientGroupInput!) {
  updateClientGroup(input: $input) {
    clientGroup{
      id
    }
  }
}
`;

const EditHousehold = ({ houseHold, afterEdit }: { houseHold: any, afterEdit: () => void }) => {
  const { t } = useTranslation(['household']);
  const [householdName, setHouseholdName] = useState<string>(houseHold.name);

  const [updateHouseholdObject, { loading }] = useMutation(UPDATE_CLIENT_GROUP, {
    variables: {
      input: {
        clientGroupId: houseHold.id,
        name: householdName,
      },
    },
  });

  const updateName = (event: any) => {
    setHouseholdName(event.target.value);
  };

  const updateHousehold = async () => {
    await updateHouseholdObject();
    afterEdit();
  };

  const disabled = !(householdName);

  return (
    <CreateNewModal loading={loading}
      forceOpen
      buttonText={t('updateModal.update')}
      modalButton={t('updateModal.edit')}
      disabled={disabled} title={t('updateModal.title')}
      onSubmit={updateHousehold}
      onClose={afterEdit}
    >
      <ListItem>
        <TextField
          label={t('household:addModal.name')}
          fullWidth
          autoFocus
          value={householdName}
          onChange={updateName}
          data-testid="household-name"
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default EditHousehold;
