import { gql, useLazyQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../providers/userContextProvider';
import { ClientGroup } from '../../interfaces';
import { FulltextSearchSelect, SearchResults } from './fulltextSearchSelect';

const MAX_SEARCH_RESULTS = 12;

export const SEARCH_CORPORATIONS = gql`
  query searchCorporations($query: String!, $organizationId: ObjectID!) {
    fetchClientGroups(input:{
      filter:{
        type: BUSINESS
        searchText: $query
        organizationId: $organizationId
      }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      clientGroups {
        id name
      }
    }
  }
`;

export const CorporationSelect = ({
  setClientGroupId, autoFocus, fullWidth,
}: {
  setClientGroupId: (id: string | undefined) => void, autoFocus?: boolean,
  fullWidth?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation();
  const [selectedCG, setSelectedCG] = useState<ClientGroup>();
  const [searchCorporations] = useLazyQuery(SEARCH_CORPORATIONS, { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  const searchFn = (query:string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    searchCorporations({
      variables: {
        query,
        organizationId: activeOrganization.id,
      },
      onCompleted: (data) => {
        resolve({
          items: data.fetchClientGroups.clientGroups,
          count: Number(data.fetchClientGroups.totalCount),
        });
      },
      onError: () => {
        resolve({
          items: [],
          count: 0,
        });
      },
    });
  });

  return (
    <FulltextSearchSelect
      label={t('components:corporation')}
      item={selectedCG}
      setItem={(cg) => { setSelectedCG(cg as ClientGroup); setClientGroupId(cg?.id ?? undefined); }}
      searchFn={searchFn}
      primaryLabelFn={(x) => x.name}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
    />
  );
};
