import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const PriceYieldChart = ({ options }: { options?: any }) => {
  useRefinitivWidget('#fund-price-yield-chart', 'WPriceYieldChart', options);

  return (
    <Card>
      <div id='fund-price-yield-chart'></div>
    </Card>
  );
};
