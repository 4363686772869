export interface FontTokens {
  h1?: string;
  h2?: string;
  h3?: string;
  h4?: string;
  base?: string;
}

export const font: FontTokens = {
  h1: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h2: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h3: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h4: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  base: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
};
