/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Chip, Grid, Link as MuiLink, Button,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BulkTradeRunStates } from 'pages/bulkTrader';
import { DateTime } from '../../misc/dateTime/dateTime';
import { formatMoneyValue } from '../../../util';
import { usePermissions } from '../../../providers/userContextProvider';
import StateChangesModal from '../../modals/stateChangesModal';
import { usePageState } from '../../../util/usePageState';
import { NoPermissionAlert } from '../../misc/noPermissionAlert';

const FETCH_TRADES = (permissions: string[]) => gql`
  query fetchTradeRequests($input: FetchTradeRequestsInput!) {
    fetchTradeRequests(input: $input) {
      tradeRequests {
        id
        actualQuantity
        actualTradePriceCents
        expectedQuantity
        expectedTradePriceCents
        state
        type
        moneyAllocatedCents
        stateChanges
        settlementDate
        account {
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          }
        }
        financialProduct {
          ticker
          translatedName {
            en
          }
        }
        createdAt
        hasPairs
      }
      totalCount
    }
  }
`;

const REMOVE_TRADE_REQUEST = gql`
  mutation removeBulkTrade($tradeRequestId: ObjectID!) {
    removeBulkTrade(tradeRequestId: $tradeRequestId) {
      success
    }
  }
`;

const TradeRequestsTable = ({
  showClient = false, showExpected = false, filter, bulkTradeRunState, onTransition = () => { },
}: { showClient?: boolean, showExpected?: boolean, filter: any, bulkTradeRunState?: string, onTransition?: () => void }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'tra/page');
  const [state, setState] = usePageState('any', 'tra/state');
  const [activeTrade, setActiveTrade] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const pageSize = 15;

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_TRADES(permissions), {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          ...filter,
          tradeState: state === 'any' ? undefined : state,
        },
        pagination: {
          sortField: 'account', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [removeTradeRequest, { loading: cancelLoading }] = useMutation(REMOVE_TRADE_REQUEST, {
    onCompleted: () => {
      refetch();
      setModalOpen(false);
    },
  });

  const stateColor = (forState: any) => (forState === 'RECONCILED' ? 'success' : ['FAILED', 'CANCELED'].includes(forState) ? 'error' : 'warning');

  useEffect(() => function cleanupOnUnmount() {
    setState('any');
    setPage(1);
  }, []);

  useEffect(() => {
    if (bulkTradeRunState === BulkTradeRunStates.COMPLETED) {
      refetch();
    }
  }, [bulkTradeRunState, refetch]);

  if (!permissions.includes('read:trade_request')) return <NoPermissionAlert missing='read:trade_request' />;

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {showClient && (
                      <TableCell><Typography variant='overline'>{t('tradeTable.client')}</Typography></TableCell>
                    )}
                    <TableCell><Typography variant='overline'>{t('tradeTable.account')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('tradeTable.security')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('tradeTable.type')}</Typography></TableCell>
                    {showExpected ? (
                      <>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedQuantity')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedPrice')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.expectedTotalValue')}</Typography></TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell><Typography variant='overline'>{t('tradeTable.quantity')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.price')}</Typography></TableCell>
                        <TableCell><Typography variant='overline'>{t('tradeTable.totalValue')}</Typography></TableCell>
                      </>
                    )}
                    <TableCell><Typography variant='overline'>{t('tradeTable.state')}</Typography></TableCell>
                    {bulkTradeRunState === 'COMPLETED' && (
                      <>
                      <TableCell><Typography variant='overline'>{t('tradeTable.tradeDate')}</Typography></TableCell>
                      <TableCell><Typography variant='overline'>{t('tradeTable.settlementDate')}</Typography></TableCell>
                      </>
                    )}
                    <TableCell><Typography variant='overline'>{t('tradeTable.createdAt')}</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || previousData)?.fetchTradeRequests?.tradeRequests?.map((trade: any) => (
                    <TableRow
                      hover
                      onClick={() => {
                        setActiveTrade(trade);
                        setModalOpen(true);
                      }}
                      key={trade.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    >
                      {showClient && (
                        <TableCell component="th" scope="row">
                          <MuiLink component={Link} to={`/clients/${trade.account.user.id}`}>{trade.account.user.firstName} {trade.account.user.lastName}</MuiLink>
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">{t(`accountTypes:${trade.account.type}`)}</TableCell>
                      <TableCell component="th" scope="row">{trade.financialProduct.ticker}</TableCell>
                      <TableCell><Chip size='small' label={trade.type} /></TableCell>
                      {showExpected || trade.state === 'INITIATED' ? (
                        <>
                          <TableCell>{trade.expectedQuantity}</TableCell>
                          <TableCell>{formatMoneyValue(trade.expectedTradePriceCents)}</TableCell>
                          <TableCell>{formatMoneyValue(trade.moneyAllocatedCents)}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{trade.actualQuantity}</TableCell>
                          <TableCell>{formatMoneyValue(trade.actualTradePriceCents)}</TableCell>
                          <TableCell>{formatMoneyValue(trade.actualTradePriceCents * trade.actualQuantity)}</TableCell>
                        </>
                      )}

                      <TableCell>
                        <Chip
                          size='small'
                          label={trade.state}
                          color={stateColor(trade.state)}
                        />
                      </TableCell>
                      {bulkTradeRunState === 'COMPLETED' && (
                        <>
                        <TableCell>{trade.createdAt ? dayjs(trade.createdAt).utc().format('YYYY-MM-DD') : 'N/A'}</TableCell>
                        <TableCell>{trade.settlementDate || 'N/A'}</TableCell>
                        </>
                      )}
                      <TableCell><DateTime date={trade.createdAt} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchTradeRequests?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
          <StateChangesModal
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            stateChanges={[{ to: 'INITIATED', createdAt: activeTrade.createdAt }, ...(activeTrade?.stateChanges || [])]}
            title={`${activeTrade.type} - ${formatMoneyValue(activeTrade.actualTradePriceCents * activeTrade.actualQuantity)} - ${activeTrade?.financialProduct?.translatedName?.en}`}
            id={activeTrade.id}
          >
            {activeTrade.state === 'INITIATED' && !activeTrade.hasPairs && <Button
              variant='outlined'
              color='error'
              disabled={cancelLoading}
              onClick={() => {
                removeTradeRequest({
                  variables: {
                    tradeRequestId: activeTrade.id,
                  },
                });
              }}
            >
              {t('tradeTable.remove')}
            </Button>}
          </StateChangesModal>
        </>
      )}
    </Box>
  );
};

export default TradeRequestsTable;
