import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loop } from '@mui/icons-material';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Box,
  Skeleton,
} from '../../../..';
import { WorkflowContext } from '../../workflowCompletion';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';

export const WORKFLOW_COMPLETION = `
  workflowCompletion {
    id
    objectId
    context
    workflow {
      name {
        en
        fr
      }
      steps {
        id
        name {
          en
          fr
        }
        subSteps {
          id
          type
        }
      }
    }
    currentStep {
      id
      name {
        en
        fr
      }
      subSteps {
        id
        type
      }
    }
    currentSubStep {
      id
      options
      type
      canGoBack
      skippable
      rolesCompleteableBy {
        id
      }
    }
  }
`;

const FETCH_ACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        applyForGovFunds
        affiliations {
          id
          allocation
          relation
          type
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GO_TO_STEP = gql`
  mutation goToSubStep($input: GoToSubStepInput!) {
    goToSubStep(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

export const ReviewAccount = ({
  options, onNext, setActiveWorkflowCompletion, workflowCompletion,
}: {
  options: any, onNext: any, setActiveWorkflowCompletion: any, workflowCompletion: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [account, setAccount] = useState<any>({});
  const { t } = useTranslation(['workflowCompletions', 'accountsDetail', 'affiliationTypes', 'accountTypes']);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);

  const { data, loading } = useQuery(FETCH_ACCOUNT, {
    variables: { accountId: workflowData.currentAccountId },
    skip: !workflowData.currentAccountId,
    fetchPolicy: 'no-cache',
  });

  const [goToStep] = useMutation(GO_TO_STEP);

  useEffect(() => {
    if (data) {
      setAccount(data.fetchAccount.account);
    }
  }, [data]);

  const goToAccountCreate = () => {
    const subSteps = workflowCompletion.workflow.steps.map((x: any) => x.subSteps).flat();
    const accountCreateSubStep = subSteps.find((x: any) => x.type === 'CREATE_ACCOUNT_FOR_GOAL');

    goToStep({
      variables: {
        input: {
          workflowCompletionId: workflowCompletion.id,
          subStepId: accountCreateSubStep.id,
        },
      },
      onCompleted: (d: any) => {
        setWorkflowData({
          ...workflowData,
          currentAccountId: null,
          currentAccountType: null,
          currentAccountState: null,
          currentSubAccountId: null,
        });
        setActiveWorkflowCompletion(d.goToSubStep.workflowCompletion);
      },
    });
  };
  const onSubmit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsReviewAccountContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      { loading || !workflowData.currentAccountId ? (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150}/>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('reviewAccount.type')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`accountTypes:${account.type}`)}</Typography>
          </Box>
          {account.applyForGovFunds && account.applyForGovFunds.length > 0 && account.applyForGovFunds?.map((govFund: any) => (
            <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between' key={govFund}>
              <Typography variant='bodyLarge'>{t(`accountsDetail:govFundOptions.${govFund}`)}</Typography>
              <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t('applied')}</Typography>
            </Box>
          ))}
          {account.affiliations?.map((affiliation: any) => (
            <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between' key={affiliation.id}>
              <Typography variant='bodyLarge'>{t(`affiliationTypes:${affiliation.type === 'OTHER' ? affiliation.relation : affiliation.type}`)}</Typography>
              <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{affiliation.user.firstName} {affiliation.user.lastName}</Typography>
            </Box>
          ))}
        </>
      )}
      <Box display='flex' justifyContent='space-between'>
        {options.createAnotherAccount && (
          <Button label={t('createAnotherAccount')} variant='filled' sx={{ mt: 3, mr: 1 }} disabled={loading} onClick={goToAccountCreate} leadingIcon={Loop}/>
        )}
        <Button label={t('continue')} sx={{ mt: 3 }} disabled={loading} onClick={onSubmit} />
      </Box>
    </>
  );
};

export default ReviewAccount;
