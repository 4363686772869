import { useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Date } from '../../../components/misc/date/date';
import { usePermissions } from '../../../providers/userContextProvider';
import { NoPermissionAlert } from '../../../components/misc/noPermissionAlert';
import { generateClientNameString } from '../../../util';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

const FETCH_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        account {
          id
          type
          user {
            id
            firstName
            middleName
            lastName
          }
        }
        signedUrl
        referenceDate
        translatedFileName {
          en
        }
      }
    }
  }
`;

const Statement = ({ statement, onClick, showClients }: { statement: any, onClick: () => void, showClients?: boolean }) => (
  <ListItem disablePadding>
    <ListItemButton href={statement.signedUrl} component='a' target='_blank'>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item sx={{ width: '350px' }}>
          <ListItemText primary={statement.translatedFileName.en} secondary={<Date date={statement.referenceDate} />} />
        </Grid>
        {showClients && (<Grid item>
          <ListItemText primary={generateClientNameString(statement?.account?.user)} />
        </Grid>)}
        <Grid item>
          <OpenInNewIcon />
        </Grid>
      </Grid>
    </ListItemButton>
  </ListItem>
);

const Statements = ({ userIds, clientGroupIds, showClients }: { userIds?: string[], clientGroupIds?: string[], showClients?: boolean }) => {
  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const [filterStatements, SetFilterStatements] = useState({
    state: 'ANY',
    typeGroup: 'ANY',
    afterDate: '',
    beforeDate: '',
    accountType: 'ANY',
    period: 'ANY',
  });

  const { data, loading } = useQuery(FETCH_STATEMENTS, {
    variables: {
      input: {
        filter: {
          userIds,
          clientGroupIds,
          state: filterStatements.state === 'ANY' ? undefined : filterStatements.state,
          afterDate: filterStatements.afterDate === '' ? undefined : filterStatements.afterDate,
          beforeDate: filterStatements.beforeDate === '' ? undefined : filterStatements.beforeDate,
          typeGroup: filterStatements.typeGroup === 'ANY' ? undefined : filterStatements.typeGroup,
          accountType: filterStatements.accountType === 'ANY' ? undefined : filterStatements.accountType,
        },
        pagination: {
          perPage: 50,
          sortField: 'referenceDate',
          sortDesc: false,
        },
      },
    },
  });

  if (!permissions.includes('read:account_statement_basic')) return <NoPermissionAlert missing='read:account_statement_basic' />;

  const generateNewFromDate = (value: string) => {
    if (value === 'YEAR_TO_DATE') {
      return dayjs().startOf('year').format('YYYY-MM-DD');
    }
    return '';
  };

  return (
    <List>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Grid container>
            <Grid item>
              <List sx={{ minWidth: '150px' }}>
                <ListItem>
                  <TextField
                    select
                    label={t('statements.byPeriod')}
                    fullWidth
                    value={filterStatements?.period || ''}
                    onChange={(e) => {
                      SetFilterStatements({
                        ...filterStatements, period: e.target.value, afterDate: generateNewFromDate(e.target.value), beforeDate: dayjs().format('YYYY-MM-DD'),
                      });
                    }}>
                    <MenuItem value='ANY'>{t('statements.allTime')}</MenuItem>
                    <MenuItem value='YEAR_TO_DATE'>{t('statements.yearToDate')}</MenuItem>
                    <MenuItem value='CUSTOM'>{t('statements.custom')}</MenuItem>
                  </TextField>
                </ListItem>
              </List>
            </Grid>
            <Grid item sx={{ m: 2, width: '150px' }}>
              <LocalizedDatePicker
                label={t('statements.fromDate')}
                value={filterStatements?.afterDate || null}
                onChange={(date) => {
                  SetFilterStatements({ ...filterStatements, afterDate: dayjs(date?.toString()).format('YYYY-MM-DD'), period: 'CUSTOM' });
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item sx={{ m: 2, width: '150px' }}>
              <LocalizedDatePicker
                label={t('statements.toDate')}
                value={filterStatements?.beforeDate || null}
                onChange={(date) => {
                  SetFilterStatements({ ...filterStatements, beforeDate: dayjs(date?.toString()).format('YYYY-MM-DD'), period: 'CUSTOM' });
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item>
              <List sx={{ minWidth: '150px' }}>
                <ListItem>
                  <TextField select label={t('statements.state')} fullWidth value={filterStatements?.state || ''} onChange={(e) => SetFilterStatements({ ...filterStatements, state: e.target.value })}>
                    <MenuItem value='ANY'>{t('statements.any')}</MenuItem>
                    <MenuItem value='AWAITING_REVIEW'>{t('statements.awaitingReview')}</MenuItem>
                    <MenuItem value='RECONCILED'>{t('statements.reconciled')}</MenuItem>
                    <MenuItem value='FAILED'>{t('statements.failed')}</MenuItem>
                  </TextField>
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <List sx={{ minWidth: '150px' }}>
                <ListItem>
                  <TextField select label={t('statements.typeGroup')} fullWidth
                    value={filterStatements?.typeGroup || ''}
                    onChange={(e) => SetFilterStatements({ ...filterStatements, typeGroup: e.target.value })}
                  >
                    <MenuItem value='ANY'>{t('statements.any')}</MenuItem>
                    <MenuItem value='TAX'>{t('statements.tax')}</MenuItem>
                    <MenuItem value='STATEMENTS'>{t('statements.statements')}</MenuItem>
                    <MenuItem value='MANAGEMENT_FEES'>{t('statements.managementFees')}</MenuItem>
                  </TextField>
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <List sx={{ minWidth: '150px' }}>
                <ListItem>
                  <TextField select label={t('statements.accountType')} fullWidth
                    value={filterStatements?.accountType || ''}
                    onChange={(e) => SetFilterStatements({ ...filterStatements, accountType: e.target.value })}
                  >
                    <MenuItem value='ANY'>{t('statements.any')}</MenuItem>
                    <MenuItem value='PERSONAL'>{t('accountTypes:PERSONAL')}</MenuItem>
                    <MenuItem value='RRSP'>{t('accountTypes:RRSP')}</MenuItem>
                    <MenuItem value='TFSA'>{t('accountTypes:TFSA')}</MenuItem>
                    <MenuItem value='RESP'>{t('accountTypes:RESP')}</MenuItem>
                  </TextField>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      ) : (
        <>
          {data?.fetchAccountStatements.accountStatements.map((statement: any) => (
            <Statement statement={statement} key={statement.id} onClick={() => {
            }} showClients={showClients} />
          ))}
          {data?.fetchAccountStatements.accountStatements.length === 0
            && <Alert severity="info" variant="outlined" sx={{ margin: '16px' }}>
              {t('statements.thereAreNoStatements')}
            </Alert>
          }
        </>
      )}
    </List>
  );
};

export default Statements;
