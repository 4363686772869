import { useNavigate } from 'react-router-dom';
import ReportBuilder from '../../4-module/reportBuilder/reportBuilder';

export const NewReport = () => {
  useNavigate();

  return (
    <ReportBuilder
      initialColumns={['firstName', 'lastName', 'primaryEmail', 'province', 'createdAt']}
      initialFilters={[]}
      initialSortDesc={true}
      initialSortField={'createdAt'}
    />
  );
};

export default NewReport;
