import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SchedulesTable from './components/schedulesTable';

const Schedules = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%', typography: 'body1' }}>
      <SchedulesTable />
    </Paper>
  );
};

export default Schedules;
