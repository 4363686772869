import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { MenuItem, SelectField } from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';

export const FETCH_SCHEDULES = gql`
  query fetchSchedules($input: FetchSchedulesInput!) {
    fetchSchedules(input: $input) {
      schedules {
        id
        name
        dates
        nextDate
        organization { id }
      }
    }
  }
`;

export const ScheduleSelect = ({
  setSchedule, value, label, size, disabledWith = null, setScheduleData,
}: {
  setSchedule: (event: any) => void, value: string, label: string, size?: 'small' | 'medium' | undefined, disabledWith?: string | null, setScheduleData?: (event: any) => void,
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const {
    loading, data,
  } = useQuery(FETCH_SCHEDULES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: { perPage: 100 },
      },
    },
  });

  return (
    <>
      {permissions.includes('read:schedules') ? (
        <SelectField
          value={data ? value : ''}
          label={label}
          fullWidth
          onChange={(e: any) => {
            setSchedule(e);
            if (setScheduleData && data?.fetchSchedules?.schedules) {
              const schedule = data.fetchSchedules.schedules.find((s: any) => s.id === e.target.value);
              setScheduleData(schedule);
            }
          }}
          size={size}
          disabled={!isNull(disabledWith)}
        >
          {
            loading ? <MenuItem>...</MenuItem> : (
              disabledWith ? (
                <MenuItem key={value} value={value}><b>{disabledWith}</b></MenuItem>
              ) : (data?.fetchSchedules?.schedules || [])?.map((x: any) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name} - {x.id}
                </MenuItem>
              ))
            )
          }
        </SelectField>
      ) : (<></>)}
    </>
  );
};

export default ScheduleSelect;
