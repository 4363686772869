import CloseIcon from '@mui/icons-material/Close';
import {
  Box, IconButton, SxProps, Theme,
} from '@mui/material';
import { Typography } from '../../1-primative';
import './chip.css';
import { useChipTokens } from './chip.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

export type ChipProps = {
  label?: string
  text: string
  leadingIcon?: any
  small?: boolean
  onClick?: () => void
  onRemove?: () => void
  sx?: SxProps<Theme>
};

export const Chip = (props: ChipProps) => {
  const tokens = useThemeTokens(useChipTokens());
  const { comp } = tokens;
  const height = props.small ? 24 : 32;
  const clickable = props.onClick !== undefined;
  const chipStyle = {
    height: height - 2,
    margin: '2px',
    borderColor: comp.chip.borderColor,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: height / 2,
    backgroundColor: 'white',
    display: 'inline-flex',
    alignItems: 'center',
    width: 'max-content',
    paddingLeft: '8px',
    paddingRight: '0',
    ...clickable && {
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        backgroundColor: comp.chip.hoverColor,
        borderColor: comp.chip.hoverBorderColor,
      },
    },
  };

  return (
    <Box tabIndex={clickable ? 0 : undefined} sx={({ ...chipStyle, ...props.sx } as SxProps<Theme>)} onClick={props.onClick}>
      {props.leadingIcon
      && <span className='ov-chip-leading-icon' style={{ color: comp.chip.hoverBorderColor, transform: props.small ? 'scale(0.77)' : undefined, marginRight: 3 }}>
          {props.leadingIcon}
        </span>
      }
      {props.label && (
      <Typography variant='labelSmall' sx={{ color: comp.chip.labelColor, letterSpacing: '0px', paddingRight: '4px' }}>
        {props.label}
      </Typography>
      )}
      <Typography variant='labelSmall' sx={{ color: comp.chip.textColor, letterSpacing: '0px', paddingRight: '8px' }}>
        {props.text}
      </Typography>
      {props.onRemove && (
        <IconButton sx={{ marginLeft: '-8px', padding: props.small ? '6px' : '8px' }}
          onClick={(e) => { e.stopPropagation(); if (props.onRemove) props.onRemove(); }}
          >
          <CloseIcon sx={{ color: comp.chip.textColor, width: props.small ? '12px' : '16px', height: props.small ? '12px' : '16px' }} />
        </IconButton>
      )}
    </Box>
  );
};
