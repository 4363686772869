import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

export const FETCH_ORGANIZATION_NAME = gql`
  query fetchOrgName($id: String!) {
    fetchOrganization(organizationId: $id) {
      organization { name }
    }
  }
`;

export const OrgDetailsBreadcrumb = () => {
  const { id } = useParams();
  const { data } = useQuery(FETCH_ORGANIZATION_NAME, { variables: { id } });

  return data ? data.fetchOrganization.organization.name : id;
};
