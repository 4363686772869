import { FileDocument } from './fileDocument';
import { Organization } from './organization';
import { OrganizationUser } from './organizationUser';

export enum BulkImportStates {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum ImportFileTypes {
  USER = 'USER',
  GOAL = 'GOAL',
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_AFFILIATE = 'ACCOUNT_AFFILIATE',
  TRANSACTION = 'TRANSACTION',
  SECURITY_PRICE = 'SECURITY_PRICE',
  PRODUCT_SHELF = 'PRODUCT_SHELF',
  FOREIGN_EXCHANGE_RATE = 'FOREIGN_EXCHANGE_RATE',
}

export enum ImportFileStates {
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  PARTIALLY_FAILED = 'PARTIALLY_FAILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum ImportFileLineItemStates {
  QUEUED = 'QUEUED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export interface BulkImport {
  id?: string,
  name?: string,
  state?: BulkImportStates,
  organizationUser?: OrganizationUser,
  organization?: Organization,
  createdAt?: Date,
  updatedAt?: Date,
}

export interface ImportFile {
  id?: string,
  type?: ImportFileTypes,
  bulkImport?: BulkImport,
  fileDocument?: FileDocument,
  state?: ImportFileStates,
  order?: number,
  queuedLineItemsCount?: number,
  failedLineItemsCount?: number,
  processedLineItemsCount?: number,
  createdAt?: Date,
  updatedAt?: Date,
}

export interface ImportFileLineItem {
  id?: string,
  importFile?: ImportFile,
  externalId?: string,
  oneVestId?: string,
  state?: ImportFileLineItemStates,
  rawData?: any,
  outputErrors?: { code?: string, field?: string, description?: string }[],
  createdAt?: Date,
  updatedAt?: Date,
}

export const getStatusColor = (state?: string) => {
  if (state === 'COMPLETED' || state === 'PROCESSED') return 'success';
  if (state === 'FAILED' || state === 'PARTIALLY_FAILED') return 'error';
  return 'warning';
};
