import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modals/confirmationModal';

export const DELETE_ORGANIZATION_USER = gql`
mutation deleteOrganizationUser($organizationUserId: ObjectID!) {
  deleteOrganizationUser(organizationUserId: $organizationUserId) {
    deleted
  }
}
`;

const DeleteOrganizationUser = ({
  open,
  organizationUserToDelete,
  afterDelete,
  handleClose,
}: {
  open: boolean,
  organizationUserToDelete: any,
  afterDelete: () => void,
  handleClose: () => void
}) => {
  const { t } = useTranslation(['orgSettings']);

  const [deleteOrganizationUser, { loading }] = useMutation(DELETE_ORGANIZATION_USER, {
    variables: {
      organizationUserId: organizationUserToDelete?.id,
    },
  });

  const deleteUser = async () => {
    await deleteOrganizationUser();
    afterDelete();
  };

  if (!organizationUserToDelete) {
    return <></>;
  }
  const verifyName = (firstName: string, lastName:string, email:string) => {
    if ((!firstName && !lastName) || (lastName && !firstName)) {
      return email;
    }

    if (firstName && !lastName) {
      return firstName;
    }

    return `${firstName} ${lastName}`;
  };

  const userReadableName = verifyName(organizationUserToDelete.firstName, organizationUserToDelete.lastName, organizationUserToDelete.email);

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={deleteUser}
      open={open}
      loading={loading}
      title={`${t('userDeleteModal.deleteUser')} ${userReadableName}?`}
      bodyText={`${t('userDeleteModal.deletedCompletely')} ${t('userDeleteModal.wantToProceed')}`}
      confirmButton={`${t('userDeleteModal.yesDelete')} ${userReadableName}`}
    >
    </ConfirmationModal>
  );
};

export default DeleteOrganizationUser;
