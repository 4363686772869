import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface LayoutTokens {
  layout: {
    textColor?: string;
    backgroundColor?: string;
    sidebarColor?: string;
  },
}

export const useLayoutTokens = (): LayoutTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: LayoutTokens = {
    layout: {
      textColor: sys.color.surface,
      backgroundColor: sys.color.onSurface,
      sidebarColor: sys.color.background,
    },
  };

  return comp;
};
