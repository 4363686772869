import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, MenuItem,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  ExternalTransferTypes, Institution, SubAccount,
  TranslatedString,
} from 'interfaces';
import ExternalInstitutionSelect from 'components/inputs/externalInstitutionSelect';
import { Box, Icon, Typography } from 'ovComponents/1-primative';
import {
  Button, Form, SelectField, TextField,
} from 'ovComponents/2-component';
import { SubAccountSelect } from 'ovComponents/3-pattern/subaccountSelect/subAccountSelect';
import { AmountField } from 'ovComponents/3-pattern/amountField/amountField';
import { UserContext } from 'providers/userContextProvider';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useThemeTokens } from '../../../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../../../assets/i18n/config';

export interface ExternalTransfer {
  amountCents: number,
  institution?: Partial<Institution>,
  subAccount?: SubAccount,
  transferAccountNumber: string,
  transferMethod: string,
}
interface ExternalTransferDialogContentProps {
  onTransferCreated: (externalTransfer: ExternalTransfer) => void,
  userData: any,
  onDialogClose: () => void,
  disclaimer?: TranslatedString | null,
}

const ExternalTransferDialogContent = ({
  onTransferCreated, userData, onDialogClose, disclaimer,
}: ExternalTransferDialogContentProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [inboundAccount, setInboundAccount] = useState<SubAccount | undefined>();
  const [transferMethod, setTransferMethod] = useState<ExternalTransferTypes>(ExternalTransferTypes.AllInCash);
  const [institution, setInstitution] = useState<Partial<Institution> | undefined>();
  const [externalAccountNumber, setExternalAccountNumber] = useState('');
  const [amount, setAmount] = useState('');
  const { activeOrganization } = useContext(UserContext);
  const enableInKind: boolean = activeOrganization.availableFeatureFlags?.includes('IN_KIND_TRANSFERS') ?? false;
  const { sys } = useThemeTokens();
  const submit = () => {
    onTransferCreated({
      amountCents: Number(amount) * 100,
      institution,
      subAccount: inboundAccount,
      transferAccountNumber: externalAccountNumber,
      transferMethod,
    });
  };
  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:externalTransferCreation.title')}</Typography>
      <SubAccountSelect
        sx={{ mt: '24px' }}
        selectedSubAccount={inboundAccount}
        onSubAccountSelect={setInboundAccount}
        userId={userData.id}
        label={t('workflowCompletions:inboundAccount')}
      />
      <SelectField
          onChange={(e: any) => setTransferMethod(e.target.value)}
          label={t('workflowCompletions:transferMethod')}
          fullWidth
          sx={{ mt: '24px' }}
          value={transferMethod}
      >
        <MenuItem value='ALL_IN_CASH'>{t('workflowCompletions:transferMethodOptions.ALL_IN_CASH')}</MenuItem>
        <MenuItem value='PARTIAL_CASH'>{t('workflowCompletions:transferMethodOptions.PARTIAL_CASH')}</MenuItem>
        {enableInKind && (<MenuItem value='ALL_IN_KIND'>{t('workflowCompletions:transferMethodOptions.ALL_IN_KIND')}</MenuItem>)}
      </SelectField>
        <Grid
            container
            display='flex'
            flexDirection='row'
            sx={{ mt: '20px' }}
            justifyContent='space-between'
            spacing={1}
            alignItems='end'
        >
            <Grid item xs={8}>
              <ExternalInstitutionSelect
                setInstitution={(e) => setInstitution(e)}
                value={institution?.id ?? ''}
                label={t('workflowCompletions:externalFinancialInstitution')}
                useOvComponents
              />
            </Grid>
            <Grid item xs={4}>
                <TextField
                  onChange={(e: any) => setExternalAccountNumber(e.target.value)}
                  label={t('workflowCompletions:externalAccount')}
                  fullWidth
                  value={externalAccountNumber}
                />
            </Grid>
        </Grid>
        <Grid
            container
            display='flex'
            flexDirection='row'
            sx={{ mt: '20px' }}
            justifyContent='space-between'
            spacing={1}
            alignItems='end'
        >
            <Grid item xs={4}>
              <AmountField setAmount={(e: any) => setAmount(e)} amount={amount} />
            </Grid>
      </Grid>
      <br></br>
      {disclaimer && (
        <Box sx={{
          mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
        }}>
          <Typography variant='bodySmall'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(disclaimer)}</ReactMarkdown>
          </Typography>
        </Box>
      )
      }
        <Box width='100%' display='flex' justifyContent='end'>
          <Button
            color='primary'
            label={t('workflowCompletions:addTransfer')}
            disabled={false}
            sx={{ mt: 3 }}
            type='submit'
          />
        </Box>
    </Form>
  );
};

export default ExternalTransferDialogContent;
