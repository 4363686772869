import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { generateClientNameString } from '../../util';
import { Relationship } from '../../interfaces';

export const FETCH_CLIENT_GROUP_MEMBERS = gql`
  query fetchClientGroupMembers($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        relationships { type user { id firstName middleName lastName entityName } }
      }
    }
  }
`;

export const HouseholdMemberSelect = ({
  clientGroupId, label,
  onChange, value,
}: {
  clientGroupId: string,
  label: string,
  onChange: (event: any) => void, value: string,
}) => {
  const { loading, error, data } = useQuery(FETCH_CLIENT_GROUP_MEMBERS, {
    variables: { id: clientGroupId },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <TextField
        select
        value={data ? value : ''}
        label={label}
        fullWidth
        onChange={onChange}
      >
        {
          loading ? <MenuItem>...</MenuItem> : (
            data.fetchClientGroup.clientGroup.relationships.map((rel: Relationship, index: number) => (
              <MenuItem key={ index } value={ rel.user.id }>{ generateClientNameString(rel.user) }</MenuItem>
            ))
          )
        }
      </TextField>
    </>
  );
};
