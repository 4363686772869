import { useTranslation } from 'react-i18next';
import { Mail, Phone, PhoneIphone } from '@mui/icons-material';
import {
  Box, Grid, Typography,
} from '../../1-primative';
import { Avatar, Card, CardContent } from '../../2-component';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import Advisor from '../../../assets/images/articles/advisor.png';

export const Contacts = () => {
  const { t } = useTranslation(['components', 'shared']);
  const { ref, sys } = useThemeTokens();

  return (
    <>
      <Typography variant='headingSmall' mb={1}>{t('components:contacts.title')}</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ paddingBottom: '16px !important' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box display='flex' alignItems='center'>
                <div style={{
                  borderRadius: '99px',
                  border: `3px solid ${ref.palette.supportOne50}`,
                  display: 'inline-block',
                  marginRight: '16px',
                }}>
                  <Avatar size='xlarge'>
                    <img
                      alt='advisor'
                      src={Advisor}
                      style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                    />
                  </Avatar>
                </div>
                <Typography variant='titleLarge'>Jerome Bell</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box display='flex' alignItems='center'>
                <Phone sx={{ fontSize: '20px', mr: 1 }}/><Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>(403) 255-9837</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box display='flex' alignItems='center'>
                <PhoneIphone sx={{ fontSize: '20px', mr: 1 }}/><Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>(403) 647-2900</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
             <Box display='flex' alignItems='center'>
                <Mail sx={{ fontSize: '20px', mr: 1 }}/><Typography variant='bodyLarge' sx={{ color: sys.color.onSurfaceVariant }}>jbell@email.ca</Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
