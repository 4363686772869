/* eslint-disable react-hooks/exhaustive-deps */
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from '@draft-js-plugins/buttons';
import React, { ReactNode, useEffect, useRef } from 'react';
import {
  ContentBlock, convertFromRaw, convertToRaw, DraftHandleValue, EditorState, getDefaultKeyBinding, RichUtils,
} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { ToolbarChildrenProps } from '@draft-js-plugins/static-toolbar/lib/components/Toolbar';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createToolbarLinkPlugin from 'draft-js-toolbar-link-plugin';
import {
  Box, FormControl, IconButton, InputLabel, SxProps,
} from '@mui/material';
import { editorStyle as baseEditorStyle, toolbarStyle as baseToolbarStyle } from './style';
import { createLinkDecorator } from './linkDecorator';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import NoteTypeSelect from '../noteTypeSelect';
import { NoteTypes } from '../../../interfaces/note';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

interface RichTextEditorProps {
  onChange?: ((e: any) => void),
  value: string,
  label?: string,
  readonly?: boolean,
  customEditorStyle?: SxProps,
  customToolbarStyle?: SxProps,
  iconButton?: {
    icon: ReactNode,
    onClick: () => void,
  },
  noteType?: NoteTypes,
  onChangeNoteType?: ((e: any) => void),
  setContentLength?: ((length: any) => void),
}

const linkDecorator = createLinkDecorator();
const customDecoratorPlugin = {
  decorators: [
    linkDecorator,
  ],
};
const toolbarLinkPlugin = createToolbarLinkPlugin();
const linkifyPlugin = createLinkifyPlugin();
const staticToolbarPlugin = createToolbarPlugin();
const plugins = [staticToolbarPlugin, linkifyPlugin, customDecoratorPlugin];

const { Toolbar } = staticToolbarPlugin;
const { LinkButton } = toolbarLinkPlugin;

const RichTextEditor = ({
  onChange, value, label, readonly = false, customEditorStyle, customToolbarStyle, iconButton, noteType, onChangeNoteType, setContentLength,
}: RichTextEditorProps) => {
  const value2editorState = () => (value ? EditorState.createWithContent(
    convertFromRaw(markdownToDraft(value, { preserveNewlines: true })),
  ) : EditorState.createEmpty());

  const [editorState, setEditorState] = React.useState(value2editorState());
  const { sys } = useThemeTokens();

  useEffect(() => {
    if (readonly) { setEditorState(value2editorState()); }
  }, [value, readonly]);

  const editorRef = useRef<any>(null);
  const focus = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const getBlockStyle = (block: ContentBlock) => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      case 'left':
        return 'align-left';
      case 'center':
        return 'align-center';
      case 'right':
        return 'align-right';
      default:
        return '';
    }
  };

  const onTextEditorChange = (state: EditorState) => {
    setEditorState(state);
    const raw = convertToRaw(editorState.getCurrentContent());
    const markdown = draftToMarkdown(raw);
    const currentContentLength = state.getCurrentContent().getPlainText('').trim().length;
    if (setContentLength) setContentLength(currentContentLength || 0);
    if (onChange) onChange(markdown);
  };

  const mapKeyToEditorCommand = (e: any): string | null => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onTextEditorChange(newEditorState);
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (
    command: string,
    changedEditorState: EditorState,
  ): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(changedEditorState, command);
    if (newState) {
      onTextEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };
  const editorStyle: any = customEditorStyle ? { ...baseEditorStyle(sys), ...customEditorStyle } : baseEditorStyle(sys);
  const toolbarStyle: any = customToolbarStyle ? { ...baseToolbarStyle, ...customToolbarStyle } : baseToolbarStyle;

  return (
    <FormControl variant="outlined" id='editorBox' sx={editorStyle} onClick={focus}>
      {label && (<InputLabel shrink htmlFor="editorBox">{label}</InputLabel>)}
      <Editor
        readOnly={readonly}
        ref={editorRef}
        editorState={editorState}
        keyBindingFn={(e: any) => mapKeyToEditorCommand(e)}
        blockStyleFn={(block: ContentBlock) => getBlockStyle(block)}
        onChange={onTextEditorChange}
        spellCheck={true}
        handleKeyCommand={handleKeyCommand}
        plugins={plugins}
      />
      {!readonly && (
        <Box sx={{ ...toolbarStyle, ...customToolbarStyle } as SxProps}>
          <Toolbar>
            {(externalProps: ToolbarChildrenProps) => (
              <React.Fragment>
                {noteType && (
                  <>
                  <NoteTypeSelect
                    value={noteType}
                    onChange={(event: any) => {
                      if (onChangeNoteType) onChangeNoteType(event.target.value as string);
                    }
                    }
                  />
                   <Separator />
                  </>
                )}
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                {noteType && (<BlockquoteButton {...externalProps} />)}
                <Separator />
                {!noteType && (<>
                  <HeadlineOneButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <HeadlineThreeButton {...externalProps} />
                  <Separator />
                </>)}
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                {!noteType && (
                  <>
                    <BlockquoteButton {...externalProps} />
                    <CodeBlockButton {...externalProps} />
                  </>
                )}
                <LinkButton {...externalProps} />
                {iconButton && (
                  <>
                    <Separator />
                    <IconButton onClick={iconButton.onClick} style={{ height: '15px', marginBottom: '10px', borderRadius: 0 }}>
                      {iconButton.icon}
                    </IconButton>
                  </>
                )}
              </React.Fragment>
            )}
          </Toolbar>
        </Box>
      )}
    </FormControl>
  );
};

export default React.memo(RichTextEditor);
