import {
  Button,
  Paper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

/**
 * extension to input type='file' with drag-n-drop feature and some graphical design
 */
const DroppableFileInput = ({ onFileChosen, height }: { onFileChosen: (file:File) => void, height?: string }) => {
  const [file, setFile] = useState<File>();
  const [draggedOver, setDraggedOver] = useState(false);
  const { t } = useTranslation('client');

  const readableFileSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} bytes`;
    const kB = bytes / 1024;
    if (kB < 1024) return `${kB.toFixed()} kB`;
    return `${(kB / 1024).toFixed(1)} MB`;
  };

  const onFilesDropped = (files: FileList) => {
    if (files.length === 0) return;
    if (files.length > 1) {
      alert(t('droppableFileInput.oneFileAtATime'));
      return;
    }
    setFile(files[0]);
    onFileChosen(files[0]);
  };

  return (
    <Paper variant="outlined"
      sx={{
        minHeight: height ?? '13em',
        backgroundColor: draggedOver ? '#bec9d4' : '#E4ECF4',
        borderStyle: file ? 'solid' : 'dashed',
        borderWidth: '1px',
        borderColor: '#AAB4BF',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onDrop={(e) => {
        onFilesDropped(e.dataTransfer.files);
        e.preventDefault();
        setDraggedOver(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setDraggedOver(true);
      }}
      onDragStart={() => { setDraggedOver(true); }}
      onDragEnter={() => { setDraggedOver(true); }}
      onDragLeave={() => { setDraggedOver(false); }}
      onDragEnd={() => { setDraggedOver(false); }}
    >
      { !file
      && <>
        <CloudUploadIcon fontSize='large'/>
        <Typography>
          {t('droppableFileInput.dragFilesHere')}
        </Typography>
        <Typography sx={{ p: 1 }}>
          — {t('or')} —
        </Typography>
      </>
      }
      { file
        && <>
          <InsertDriveFileIcon fontSize='large'/>
          <Typography>
            {file.name}
          </Typography>
          <Typography variant="caption">
            {t('droppableFileInput.fileSize')}:&nbsp;
            {readableFileSize(file.size)}
          </Typography>
        </>
      }
      <Typography>
        <Button
          component="label"
        >
          {file ? t('droppableFileInput.chooseAnotherFile') : t('droppableFileInput.chooseAFile')}
          <input
            hidden
            type='file'
            onChange={(e:ChangeEvent<HTMLInputElement & FileList>) => {
              if (e.target.files) {
                setFile(e.target.files[0]);
                onFileChosen(e.target.files[0]);
              }
            }}
          />
        </Button>
      </Typography>
    </Paper>
  );
};

export default DroppableFileInput;
