import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface AlertTokenSeverity {
  success?: {
    color?: string,
    backgroundColor?: string,
  },
  info?: {
    color?: string,
    backgroundColor?: string,
  },
  warning?: {
    color?: string,
    backgroundColor?: string,
  },
  error?: {
    color?: string,
    backgroundColor?: string,
  },
}

export interface AlertTokens {
  alert: {
    standard?: AlertTokenSeverity
    filled?: AlertTokenSeverity
    outlined?: AlertTokenSeverity
  }
}

export const useAlertTokens = (): AlertTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: AlertTokens = {
    alert: {
      standard: {
        success: {
          color: sys.color.positive,
          backgroundColor: sys.color.positiveVariant,
        },
        info: {
          color: sys.color.primary,
          backgroundColor: sys.color.primaryVariant,
        },
        warning: {
          color: sys.color.warning,
          backgroundColor: sys.color.warningVariant,
        },
        error: {
          color: sys.color.negative,
          backgroundColor: sys.color.negativeVariant,
        },
      },
      filled: {
        success: {
          color: sys.color.positive,
          backgroundColor: sys.color.positiveVariant,
        },
        info: {
          color: sys.color.primary,
          backgroundColor: sys.color.primaryVariant,
        },
        warning: {
          color: sys.color.warning,
          backgroundColor: sys.color.warningVariant,
        },
        error: {
          color: sys.color.negative,
          backgroundColor: sys.color.negativeVariant,
        },
      },
      outlined: {
        success: {
          color: sys.color.positive,
          backgroundColor: sys.color.positiveVariant,
        },
        info: {
          color: sys.color.primary,
          backgroundColor: sys.color.primaryVariant,
        },
        warning: {
          color: sys.color.warning,
          backgroundColor: sys.color.warningVariant,
        },
        error: {
          color: sys.color.negative,
          backgroundColor: sys.color.negativeVariant,
        },
      },
    },
  };

  return comp;
};
