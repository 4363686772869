import {
  Typography, TextField as MuiTextField, Dialog, List, ListItem, Radio, Button, DialogTitle, DialogContent, Tooltip,
} from '@mui/material';
import { isNull } from 'lodash/fp';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField as OvTextField } from 'ovComponents/2-component';
import { CountryCodes } from '@onevesthq/ov-enums';
import AddressField from './addressField';
import { colors } from '../../theme/colors';
import { Institution, PhysicalAddress } from '../../interfaces';
import { generateAddressString } from '../../util';

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      institutions {
        id
        name
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
        }
      }
    }
  }
`;

export const CREATE_INSTITUTION = gql`
  mutation createInstitution($input: CreateInstitutionInput!) {
    createInstitution(input: $input) {
      institution {
        id
        name
      }
    }
  }
`;

const ExternalInstitutionSelect = ({
  setInstitution,
  value,
  label,
  size,
  disabledWith = null,
  readonly = false,
  useOvComponents = false,
}: {
  setInstitution: (institution: Partial<Institution>) => void,
  value: string,
  label: string,
  size?: 'small' | 'medium' | undefined,
  disabledWith?: string | null,
  readonly?: boolean
  useOvComponents?: boolean,
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [openCreateInstitutionDialog, setOpenCreateInstitutionDialog] = useState(false);
  const [physicalAddress, setPhysicalAddress] = useState<PhysicalAddress>({
    city: '',
    country: CountryCodes.CA,
    houseNumber: '',
    neighborhood: '',
    postal: '',
    province: '',
    streetName: '',
    unitNumber: '',
  });
  const {
    loading, error, data, refetch,
  } = useQuery(FETCH_INSTITUTIONS, {
    variables: {
      input: {
        pagination: {
          perPage: 10000,
          sortDesc: true,
          sortField: 'name',
        },
        filter: {
          state: 'APPROVED',
        },
      },
    },
  });

  const [createInstitution, options] = useMutation(CREATE_INSTITUTION, {
    variables: {
      input: {
        isDraft: false,
        name: institutionName,
        physicalAddress,
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  const TextField = useOvComponents ? OvTextField : MuiTextField;

  return (
    <>
      <TextField
        value={data?.fetchInstitutions?.institutions.find((e: { id: string }) => e.id === selectedId)?.name ?? ''}
        label={label}
        sx={{ width: '100%' }}
        onClick={() => {
          if (!readonly) setOpen(true);
        }}
        size={size}
        data-testid="institution-dropdown"
        disabled={!isNull(disabledWith)}
        InputProps={{
          readOnly: readonly,
        }}
        fullWidth
      />
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
      >
        <DialogTitle>{t('components:externalInstitutionSelect.selectInstitutionDialog.title')}</DialogTitle>
        <DialogContent>
        <TextField
          value={searchKey}
          label={label}
          sx={{ width: '100%', marginTop: '10px' }}
          onChange={(e: any) => { setSearchKey(e.target.value); }}
          size={size}
          data-testid="institution-search"
          disabled={!isNull(disabledWith)}
          InputProps={{
            readOnly: readonly,
          }}
          fullWidth
        />
        <List sx={{ height: window.innerHeight - 350, overflowY: 'scroll' }}>
          {
            loading ? <ListItem>...</ListItem> : (
              disabledWith ? (
                <ListItem key={value} value={value}><b>{disabledWith}</b></ListItem>
              ) : (data?.fetchInstitutions?.institutions.filter((e: { name: string, id: string }) => e.name.toLowerCase().includes(searchKey.toLowerCase())) || [])?.map((x: any) => (
                <ListItem
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  padding: '8px 0px',
                }}
                  key={x.id}
                  value={x.id}
                  onClick={() => {
                    setSelectedId(x.id);
                  }}
                >
                  <Tooltip title={`${x.name} - ${generateAddressString(x.physicalAddress)}`}>
                    <Typography noWrap>
                      {x.name} - {generateAddressString(x.physicalAddress)}
                    </Typography>
                  </Tooltip>
                  <Radio checked={selectedId === x.id} />
                </ListItem>
              ))
            )
          }
        </List>
        <ListItem
          style={{ cursor: 'pointer', color: colors.primary, padding: '16px 0px' }}
          onClick={() => {
            setOpen(false);
            setOpenCreateInstitutionDialog(true);
          }}
        >
          <Typography fontWeight={600}>{t('components:externalInstitutionSelect.selectInstitutionDialog.addManually')}</Typography>
        </ListItem>
        <Button
          data-testid="selectInstitutionButton"
          variant="contained"
          size="medium"
          fullWidth
          onClick={() => {
            setOpen(false);
            const foundInstitution = data?.fetchInstitutions?.institutions.find((e: { id: string }) => e.id === selectedId);
            if (foundInstitution) {
              setInstitution(foundInstitution);
            }
          }}
          disabled={!selectedId}
        >
          {t('components:externalInstitutionSelect.confirmButton')}
        </Button>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => setOpenCreateInstitutionDialog(false)} open={openCreateInstitutionDialog} fullWidth>
        <DialogTitle>{t('components:externalInstitutionSelect.addInstitutionDialog.title')}</DialogTitle>
        <DialogContent>
          <MuiTextField
            value={institutionName}
            label={t('components:externalInstitutionSelect.addInstitutionDialog.institutionNameLabel')}
            fullWidth
            onChange={(e: any) => setInstitutionName(e.target.value)}
            size={size}
            data-testid="institution-name"
            disabled={!isNull(disabledWith)}
            InputProps={{
              readOnly: readonly,
            }}
            sx={{ marginBottom: '20px', marginTop: '10px' }}
          />
          <AddressField address={physicalAddress} label="Address" onChange={(e) => setPhysicalAddress(e)} />
          <Button
            data-testid="updateInstitutionButton"
            variant="contained"
            fullWidth
            size="medium"
            disabled={options.loading}
            style={{ marginTop: '20px' }}
            onClick={async () => {
              setOpenCreateInstitutionDialog(false);
              const response = await createInstitution();
              if (response.data) {
                setSelectedId(response.data.createInstitution.institution.id);
                setInstitution(response.data.createInstitution.institution);
                refetch();
              }
            }}
          >
            {t('components:externalInstitutionSelect.confirmButton')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ExternalInstitutionSelect;
