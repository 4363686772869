import { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GoalRiskQuestionVisual } from './goalRiskQuestion.visual';
import { WorkflowContext } from '../../workflowCompletion';

const FETCH_USER_GOAL = gql`
  query fetchUserGoal($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        goals {
          id
          riskQuestion1
        }
      }
    }
  }
`;

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        riskQuestion1
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const GoalRiskQuestion = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { workflowData } = useContext(WorkflowContext);
  const isNewGoal = workflowData?.currentGoalId;
  const [goalData, setGoalData] = useState<any>({
    goalId: null,
    riskQuestion1: '',
  });

  const { data, loading } = useQuery(FETCH_GOAL, {
    variables: { goalId: workflowData?.currentGoalId },
    fetchPolicy: 'no-cache',
    skip: !isNewGoal,
  });

  const { data: userData } = useQuery(FETCH_USER_GOAL, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: isNewGoal,
  });

  const [updateGoal, { loading: updateGoalLoading }] = useMutation(UPDATE_GOAL, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...goalData,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setGoalData({
        goalId: data.fetchGoal.goal.id,
        // If it is a new goal, do not preselect the option used to stub out the goal creation
        riskQuestion1: isNewGoal ? undefined : (data.fetchGoal.goal.riskQuestion1 || undefined),
      });
    }
  }, [data, isNewGoal]);

  useEffect(() => {
    if (userData) {
      const firstGoal = userData.fetchUser.user.goals[0];
      if (!firstGoal) {
        return;
      }
      setGoalData({
        goalId: firstGoal.id,
        riskQuestion1: firstGoal.riskQuestion1 || undefined,
      });
    }
  }, [userData, onNext]);

  return (
    <GoalRiskQuestionVisual
      options={options}
      goalData={goalData}
      updateGoal={setGoalData}
      continueFunc={updateGoal}
      loading={loading || updateGoalLoading || stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default GoalRiskQuestion;
