import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useLocalization } from '../../../util/useLocalization';

export const Date = (props: any) => {
  let { date } = props;
  const { localizedDate } = useLocalization();

  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = date.substring(0, 10); // this effectively drops `+HHMM` timezone
  }

  const dayjsObject = dayjs.isDayjs(date) ? date : dayjs(date);

  return <Typography {...props}>
    {
      localizedDate(
        props.timezone
          ? dayjsObject.tz(props.timezone)
          : dayjsObject,
      )
    }
  </Typography>;
};
