import { merge } from 'lodash/fp';
import { Hexcode, OverrideTokens } from '../..';
import { RefTokens, useRefTokens } from '../../ref';

export interface ColorTokens {
  primary?: Hexcode;
  primaryHover?: Hexcode;
  primaryPressed?: Hexcode;
  onPrimary?: Hexcode;
  primaryContainer?: Hexcode;
  primaryVariant?: Hexcode;
  primaryVariantHover?: Hexcode;
  primaryVariantPressed?: Hexcode;
  onPrimaryVariant?: Hexcode;
  negative?: Hexcode;
  negativeHover?: Hexcode;
  negativePressed?: Hexcode;
  onNegative?: Hexcode;
  negativeVariant?: Hexcode;
  negativeVariantHover?: Hexcode;
  negativeVariantPressed?: Hexcode;
  onNegativeVariant?: Hexcode;
  negativeOutline?: Hexcode;
  positive?: Hexcode;
  positiveHover?: Hexcode;
  positivePressed?: Hexcode;
  onPositive?: Hexcode;
  positiveVariant?: Hexcode;
  positiveVariantHover?: Hexcode;
  positiveVariantPressed?: Hexcode;
  onPositiveVariant?: Hexcode;
  warning?: Hexcode;
  warningHover?: Hexcode;
  warningPressed?: Hexcode;
  onWarning?: Hexcode;
  warningVariant?: Hexcode;
  warningVariantHover?: Hexcode;
  warningVariantPressed?: Hexcode;
  onWarningVariant?: Hexcode;
  neutralHover?: Hexcode;
  neutralPressed?: Hexcode;
  outline?: Hexcode;
  outlineVariant?: Hexcode;
  surface?: Hexcode;
  onSurface?: Hexcode;
  onSurfaceVariant?: Hexcode;
  surfaceContainer?: Hexcode;
  surfaceContainerVariant?: Hexcode;
  background?: Hexcode;
  onBackground?: Hexcode;
  disabled?: Hexcode;
  onDisabled?: Hexcode;
  navigation?: Hexcode;
  onNavigation?: Hexcode;
  supportOneVariant?: Hexcode;
  supportOneOnVariant?: Hexcode;
  supportTwoVariant?: Hexcode;
  supportTwoOnVariant?: Hexcode;
  supportThreeVariant?: Hexcode;
  supportThreeOnVariant?: Hexcode;
  supportFourVariant?: Hexcode;
  supportFourOnVariant?: Hexcode;
}

export const useColorTokens = (override: OverrideTokens): ColorTokens => {
  const ref: RefTokens = useRefTokens(override);
  const refTokens: RefTokens = merge(ref, override.ref);

  return {
    // Primary
    primary: refTokens.palette?.primary50,
    primaryHover: refTokens.palette?.primary60,
    primaryPressed: refTokens.palette?.primary70,
    onPrimary: refTokens.palette?.neutral10,
    primaryContainer: refTokens.palette?.neutral30,

    primaryVariant: refTokens.palette?.primary20,
    primaryVariantHover: refTokens.palette?.primary30,
    primaryVariantPressed: refTokens.palette?.primary40,
    onPrimaryVariant: refTokens.palette?.primary70,

    // Negative
    negative: refTokens.palette?.negative50,
    negativeHover: refTokens.palette?.negative60,
    negativePressed: refTokens.palette?.negative70,
    onNegative: refTokens.palette?.neutral10,

    negativeVariant: refTokens.palette?.negative20,
    negativeVariantHover: refTokens.palette?.negative30,
    negativeVariantPressed: refTokens.palette?.negative40,
    onNegativeVariant: refTokens.palette?.negative70,
    negativeOutline: refTokens.palette?.negative60,

    // Positive
    positive: refTokens.palette?.positive50,
    positiveHover: refTokens.palette?.positive60,
    positivePressed: refTokens.palette?.positive70,
    onPositive: refTokens.palette?.neutral10,

    positiveVariant: refTokens.palette?.positive20,
    positiveVariantHover: refTokens.palette?.positive30,
    positiveVariantPressed: refTokens.palette?.positive40,
    onPositiveVariant: refTokens.palette?.positive70,

    // Warning
    warning: refTokens.palette?.warning50,
    warningHover: refTokens.palette?.warning60,
    warningPressed: refTokens.palette?.warning70,
    onWarning: refTokens.palette?.neutral10,

    warningVariant: refTokens.palette?.warning20,
    warningVariantHover: refTokens.palette?.warning30,
    warningVariantPressed: refTokens.palette?.warning40,
    onWarningVariant: refTokens.palette?.warning70,

    // Support
    supportOneVariant: refTokens.palette?.primary20,
    supportOneOnVariant: refTokens.palette?.primary60,

    // Neutral
    neutralHover: refTokens.palette?.neutral20,
    neutralPressed: refTokens.palette?.neutral30,

    // Outline
    outline: refTokens.palette?.neutral60,
    outlineVariant: refTokens.palette?.neutral40,

    // Surface
    surface: refTokens.palette?.neutral10,
    onSurface: refTokens.palette?.neutral90,
    onSurfaceVariant: refTokens.palette?.neutral70,
    surfaceContainer: refTokens.palette?.neutral20,
    surfaceContainerVariant: refTokens.palette?.neutral30,

    // Background
    background: refTokens.palette?.neutral20,
    onBackground: refTokens.palette?.neutral90,

    // Disabled
    disabled: refTokens.palette?.neutral30,
    onDisabled: refTokens.palette?.neutral60,

    // Navigation
    navigation: refTokens.palette?.primary50,
    onNavigation: refTokens.palette?.neutral10,
  };
};
