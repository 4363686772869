import { useContext, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { isEmpty } from 'lodash';
import { ApprovalVisual } from './approval.visual';
import {
  CustomField,
  CustomFieldObjectTypes,
  FETCH_CUSTOM_FIELDS,
  modifyUpdatingCustomFields,
} from '../../../../../interfaces/customField';
import { SubStepTypes, WorkflowObjectTypes } from '../../../../../interfaces';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { WorkflowContext } from '../../workflowCompletion';
import { getEnabledCustomFields } from '../utils';

const FETCH_USER = (derivedCustomFields?: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format objectType }
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;
const FETCH_ACCOUNT = (derivedCustomFields?: string[]) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format objectType }
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const FETCH_GOAL = (derivedCustomFields?: string[]) => gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format objectType }
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;
const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export const Approval = ({
  options,
  onNext,
  stepLoading,
  objectId,
  objectType,
}: {
  options: any,
  onNext: () => void,
  stepLoading: boolean,
  objectId?: string,
  objectType?: WorkflowObjectTypes,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData } = useContext(WorkflowContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>([]);
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>([]);
  const enabledCustomFields = getEnabledCustomFields(options);
  const { permissions } = usePermissions();
  const userId = workflowData?.currentUserId || (objectType === WorkflowObjectTypes.USER ? objectId : undefined);
  const goalId = workflowData?.currentGoalId || (objectType === WorkflowObjectTypes.GOAL ? objectId : undefined);
  const accountId = workflowData?.currentAccountId || (objectType === WorkflowObjectTypes.ACCOUNT ? objectId : undefined);
  const [approvalData, setApprovalData] = useState<any>({
    customFields: [],
    userId: '',
    goalId: '',
    accountId: '',
  });
  const { loading: CustomFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.APPROVAL,
          organizationId: activeOrganization?.id ?? undefined,
          keys: enabledCustomFields,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    fetchPolicy: 'no-cache',
    onCompleted: async (res: any) => {
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      const customOptions: any[] = [];
      const keyItems = res?.fetchCustomFields?.customFields.map((a: CustomField) => a.key);
      Object.keys(options).forEach((key: string) => {
        if (options[key]?.customField && options[key].enabled && keyItems?.includes(key)) {
          customOptions.push(options[key]);
        }
      });
      const entityCustomFieldKeys = customOptions.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.ENTITY).map((k: any) => k.customField.key) || [];
      const goalCustomFieldKeys = customOptions.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.GOAL).map((k: any) => k.customField.key) || [];
      const accountCustomFieldKeys = customOptions.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.ACCOUNT).map((k: any) => k.customField.key) || [];

      if (!isEmpty(entityCustomFieldKeys) && userId) {
        setDerivedCustomFieldsKeys([...derivedCustomFields, ...entityCustomFieldKeys]);
        await fetchUser({
          variables: {
            userId,
          },
          query: entityCustomFieldKeys ? FETCH_USER(entityCustomFieldKeys) : undefined,
          onCompleted: (userResponse: any) => {
            const currentCustomFields = approvalData.customFields ?? [];
            const customFields = currentCustomFields.concat(userResponse.fetchUser?.user?.customFields);
            setApprovalData({
              ...approvalData,
              userId: userResponse.fetchUser?.user.id,
              customFields,
            });
          },
        });
      }

      if (!isEmpty(goalCustomFieldKeys) && goalId) {
        setDerivedCustomFieldsKeys([...derivedCustomFields, ...goalCustomFieldKeys]);
        await fetchGoal({
          variables: {
            goalId,
          },
          query: goalCustomFieldKeys ? FETCH_GOAL(goalCustomFieldKeys) : undefined,
          onCompleted: (goalResponse: any) => {
            const currentCustomFields = approvalData.customFields ?? [];
            const customFields = currentCustomFields.concat(goalResponse.fetchGoal?.goal?.customFields);
            setApprovalData({
              ...approvalData,
              goalId: goalResponse.fetchGoal?.goal.id,
              customFields,
            });
          },
        });
      }

      if (!isEmpty(accountCustomFieldKeys) && accountId) {
        setDerivedCustomFieldsKeys([...derivedCustomFields, ...accountCustomFieldKeys]);
        await fetchAccount({
          variables: {
            accountId,
          },
          query: accountCustomFieldKeys ? FETCH_ACCOUNT(accountCustomFieldKeys) : undefined,
          onCompleted: (accountResponse: any) => {
            const currentCustomFields = approvalData.customFields ?? [];
            const customFields = currentCustomFields.concat(accountResponse.fetchAccount?.account?.customFields);
            setApprovalData({
              ...approvalData,
              accountId: accountResponse.fetchAccount?.account.id,
              customFields,
            });
          },
        });
      }
    },
  });

  const [fetchUser, { loading: userLoading }] = useLazyQuery(FETCH_USER(), {
    variables: { userId: workflowData.currentUserId },
    fetchPolicy: 'no-cache',
  });

  const [fetchGoal, { loading: goalLoading }] = useLazyQuery(FETCH_GOAL(), {
    variables: { goalId: workflowData.currentGoalId },
    fetchPolicy: 'no-cache',
  });

  const [fetchAccount, { loading: accountLoading }] = useLazyQuery(FETCH_ACCOUNT(), {
    variables: { accountId: workflowData.currentAccountId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser] = useMutation(UPDATE_USER);

  const [updateGoal] = useMutation(UPDATE_GOAL);

  const [updateAccount] = useMutation(UPDATE_ACCOUNT);

  return (
    <ApprovalVisual
      options={options}
      continueFunc={async () => {
        if (approvalData?.customFields) {
          const entityCustomFieldKeys = approvalData.customFields.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.ENTITY).map((k: any) => k.key) || [];
          const goalCustomFieldKeys = approvalData.customFields.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.GOAL).map((k: any) => k.key) || [];
          const accountCustomFieldKeys = approvalData.customFields.filter((i: any) => i.customField.objectType === CustomFieldObjectTypes.ACCOUNT).map((k: any) => k.key) || [];
          if (!isEmpty(entityCustomFieldKeys) && userId) {
            await updateUser({
              variables: {
                input: {
                  userId,
                  customFields: modifyUpdatingCustomFields(approvalData?.customFields, derivedCustomFields ?? [], entityCustomFieldKeys),
                },
              },
            });
          }

          if (!isEmpty(goalCustomFieldKeys) && goalId) {
            await updateGoal({
              variables: {
                input: {
                  goalId,
                  customFields: modifyUpdatingCustomFields(approvalData?.customFields, derivedCustomFields ?? [], goalCustomFieldKeys),
                },
              },
            });
          }

          if (!isEmpty(accountCustomFieldKeys) && accountId) {
            await updateAccount({
              variables: {
                input: {
                  accountId,
                  customFields: modifyUpdatingCustomFields(approvalData?.customFields, derivedCustomFields ?? [], accountCustomFieldKeys),
                },
              },
            });
          }
        }
        onNext();
      }}
      loading={stepLoading || userLoading || goalLoading || accountLoading || CustomFieldLoading}
      approvalData={approvalData}
      updateApproval={setApprovalData}
      activeCustomFields={derivedCustomFieldsKeys}
    />
  );
};

export default Approval;
