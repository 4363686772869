import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {
  FormControl, InputLabel, ListItemText, useTheme,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, permissions: readonly string[], theme: any) {
  return {
    fontWeight:
    permissions.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

/* TODO: remove GOALS_DETAIL, SUB_ACCOUNTS_DETAIL, ACCOUNTS_DETAIL types from the backend */
const FETCH_PAGE_TYPES = gql`
  query fetchPageTypes {
    __type(name:"Pages") {
    enumValues{
      name
    }
  }
}
`;

export default function AccessiblePagesSelect(
  { label, accessiblePages, onChange }:
  { label: string, accessiblePages: string[], onChange: (event: any) => void },
) {
  const theme = useTheme();
  const { data: fetchResponse } = useQuery(FETCH_PAGE_TYPES);
  const [pages, setPages] = useState<string[]>([]);

  useEffect(() => {
    if (fetchResponse?.__type) setPages(fetchResponse.__type.enumValues.map((v:Record<string, string>) => v.name));
  }, [fetchResponse]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink htmlFor="select-multiple-native" variant='outlined'>
      {label}
      </InputLabel>
      <Select
        multiple
        notched={true}
        value={accessiblePages}
        onChange={onChange}
        label={label}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {
          pages.map((x: any) => (
            <MenuItem key={x} value={ x } style={getStyles(x, accessiblePages, theme)}>
              <ListItemText primary={x} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}
