import { SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { AccessToEntityOptions, AccessToEntityVisual } from './accessToEntity.visual';
import {
  FETCH_ORG_USERS_WITH_ACCESS_TO_ENTITY, ADD_ENTITY_TO_ORG_USER, REMOVE_ENTITY_FROM_ORG_USER, SEARCH_ORG_USERS,
} from './accessToEntity.queries';
import { User } from '../../../../interfaces';

export const AccessToEntity = ({ entityId, options = {}, sx }: { entityId: string, options?: AccessToEntityOptions, sx?: SxProps }) => {
  const [entity, setEntity] = useState<User | undefined>();

  const { data: entityData, loading: entiyLoading, refetch } = useQuery(FETCH_ORG_USERS_WITH_ACCESS_TO_ENTITY, {
    variables: {
      userId: entityId,
    },
  });

  const [searchUsers] = useLazyQuery(SEARCH_ORG_USERS, {
    notifyOnNetworkStatusChange: true,
  });
  const [addEntityToOrganizationUser] = useMutation(ADD_ENTITY_TO_ORG_USER, { onCompleted: refetch });
  const [removeEntityFromOrganizationUser] = useMutation(REMOVE_ENTITY_FROM_ORG_USER, { onCompleted: refetch });

  useEffect(() => {
    setEntity(entityData?.fetchUser?.user);
  }, [entityData]);

  return (
    <AccessToEntityVisual
      entity={entity}
      options={options}
      loading={entiyLoading || !entity}
      sx={sx}
      addEntityToOrganizationUser={addEntityToOrganizationUser}
      removeEntityFromOrganizationUser={removeEntityFromOrganizationUser}
      searchUsers={searchUsers}
    />
  );
};
