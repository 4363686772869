import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  SelectField,
  TranslatableTextField,
} from '../..';

export const ScheduleFrequencyOptionsEdit = ({
  optionList, setOptionList,
}: {
  optionList: any, setOptionList: (x: any) => void,
}) => {
  const { t } = useTranslation('workflowCompletions');
  return (
    <>
      {optionList?.map((option: any, num: number) => (
        <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1}>
          <Grid item xs={8}>
            <TranslatableTextField
              label={t('name')}
              value={option.label}
              fullWidth
              onChange={(value) => {
                const newOptions = [...optionList];
                newOptions[num] = { ...option, label: value };
                setOptionList(newOptions);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField label={t('value')} value={option.value} fullWidth onChange={(e: any) => {
              const newOptions = [...optionList];
              newOptions[num] = { ...option, value: e.target.value };
              setOptionList(newOptions);
            }}>
              {['WEEKLY', 'BI_WEEKLY', 'MONTHLY', 'SEMI_MONTHLY', 'QUARTERLY'].map((y: string) => (
                <MenuItem value={y}>{t(`schedule.${y}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={1} justifyContent='center' display='flex' sx={{ mt: 2 }}>
            <IconButton onClick={() => {
              const newOptions = [...optionList];
              newOptions.splice(num, 1);
              setOptionList(newOptions);
            }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box display='flex' justifyContent='end'>
        <Button label={t('add')} variant='outlined' onClick={() => {
          setOptionList([...optionList, { label: { en: '' }, description: { en: '' }, value: 'NONE' }]);
        }} />
      </Box>
    </>
  );
};
