import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Box } from '../../../../1-primative';
import {
  MenuItem, Dialog, Form, DialogTitle, DialogContent, DialogFooter, SelectField,
  Button,
  ClientSelectField,
} from '../../../../2-component';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { generateClientNameString } from '../../../../../util';
import {
  RelatedEntity, RelatedEntityInput, RelatedEntityTypes, User, getRelatedEntityTypes,
} from '../../../../../interfaces';
import { FETCH_USER_RELATED_ENTITIES } from '../relationships';

export const UPDATE_USER_RELATED_ENTITIES = gql`
  mutation updateUserRelatedEntities($userId: ID!, $relatedEntities: [RelatedEntityInput]) {
    updateUser(input: {
      userId: $userId
      relatedEntities: $relatedEntities
    }) {
      user { id }
    }
  }
`;

export const NewRelatedEntity = ({
  user, existingRelatedEntities, onClose,
}: {
  user: User, existingRelatedEntities: RelatedEntity[], onClose: () => void
}) => {
  const { t } = useTranslation('nonIndividualClients');
  const { permissions } = usePermissions();

  const [relation, setRelation] = useState<RelatedEntityTypes>(RelatedEntityTypes.AuthorizedIndividual);
  const [client, setClient] = useState<any>({ id: '' });

  const [updateUserRelatedEntities] = useMutation(UPDATE_USER_RELATED_ENTITIES, {
    refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
  });

  const save = async () => {
    const updatedRelatedEntities: RelatedEntityInput[] = existingRelatedEntities.map((relEnt: RelatedEntity) => ({ entityId: relEnt.entity.id, relation: relEnt.relation }));
    await updateUserRelatedEntities({
      variables: {
        userId: user.id,
        relatedEntities: [...updatedRelatedEntities, { entityId: client.id, relation }],
      },
      onCompleted: onClose,
    });
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle onClose={onClose}>
        {t('editRelationship')}
      </DialogTitle>
      <Form
        onSubmit={save}
      >
        <DialogContent>
          <ClientSelectField
            label={t('shared:entity')}
            autoFocus
            fullWidth
            user={client}
            setUser={(newValue) => setClient(newValue)}
          />
          <SelectField fullWidth
            label={t('edit.relationshipTo', { whom: generateClientNameString(user) })}
            value={relation}
            onChange={(event: any) => setRelation(event.target.value as RelatedEntityTypes)}
            sx={{ mt: 2 }}
          >
            {getRelatedEntityTypes(user.type).map((relationType, index) => (
              <MenuItem key={index} value={relationType}>
                {t(`relatedEntityTypes:${relationType}`)}
              </MenuItem>
            ))}
          </SelectField>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end'>
            <Button label={t('shared:add')} type='submit' />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
