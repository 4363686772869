import { Typography } from '@mui/material';
import { householdColors, memberColors } from './memberColors';

export interface MemberTagArgs {
  sequenceNumber: number | 'HOUSEHOLD'
  name: string
}

export const MemberTag = ({ sequenceNumber, name }: MemberTagArgs) => {
  const colors = sequenceNumber === 'HOUSEHOLD'
    ? householdColors
    : memberColors(sequenceNumber);

  return (
  <Typography
    align={'center'}
    fontSize={11}
    fontWeight={600}
    sx={{
      backgroundColor: colors.bg,
      color: colors.fg,
      display: 'inline-block',
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '2px',
      height: '16.5px',
    }}
  >
    {name}
  </Typography>
  );
};
