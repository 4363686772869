import {
  Dialog, DialogContent, DialogTitle, MenuItem, Table, TableBody,
  TableCell, TableRow, TextField, TextFieldProps,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import i18n from '../../assets/i18n/config';
import { colors } from '../../theme/colors';

const styles = {
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: '0px 2px',
  border: 0,
  fontSize: '0.75rem',
  lineHeight: '1.66',
  width: '36px',
  height: '36px',
};

const selectedStyle = {
  backgroundColor: colors.primary,
  color: colors.white,
};

const nth = (d: number) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};
export const formatDay = (day?: number) => {
  if (!day) return '';
  const nthSuffix = nth(day);
  return `${day}${nthSuffix} ${i18n.t('components:scheduleDateSelect.suffix')}`;
};

const ScheduleDateSelect = ({
  day,
  setDate,
  title,
  label,
  readonly = false,
  ...textProps
}: {
  day?: number,
  setDate: (selectedDay: number) => void,
  title?: string,
  label?: string,
  readonly?: boolean
} & TextFieldProps) => {
  const { t } = useTranslation(['components']);
  const [open, setOpen] = useState(false);

  const onDaySelect = (newDay: number) => {
    setDate(newDay);
    setOpen(false);
  };

  const getDay = (row: number, column: number) => (((row - 1) * 7) + column);

  return (
    <>
      <TextField
        value={formatDay(day)}
        label={label ?? t('components:scheduleDateSelect.label')}
        onClick={() => {
          if (!readonly) setOpen(true);
        }}
        fullWidth
        disabled={readonly}
        inputProps={{ sx: { cursor: 'default' } }}
        {...textProps}
      >
      </TextField>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'xs'}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {title ?? t('components:scheduleDateSelect.title')}
          <CloseIcon onClick={() => setOpen(false)} sx={{ cursor: 'pointer' }}/>
        </DialogTitle>
        <DialogContent>
        <Table sx={{ width: '100%' }} aria-label="table">
          <TableBody>
            {[1, 2, 3, 4].map((row: number) => (
              <TableRow
                key={row}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((column: number) => (
                  <TableCell key={column} sx={{ ...styles, ...(getDay(row, column) === day ? selectedStyle : {}) }}>
                    <MenuItem onClick={(e: any) => onDaySelect(e.target.value)} value={getDay(row, column)} sx={{ borderRadius: '50%' }}>
                      {getDay(row, column)}
                    </MenuItem>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScheduleDateSelect;
