import InfoIcon from '@mui/icons-material/Info';
import Add from '@mui/icons-material/Add';
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import dayjs from 'dayjs';

import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { Exchanges } from '../../../../../interfaces/financialProduct';
import {
  ADJUSTED_COST_BASE_TYPES,
  Currencies,
  getValueSign,
  isAdjustmentTypeAvailable,
  QUANTITY_PRICE_MULTIPLIERS,
  SwitchTransactionTypes,
  SwitchTypes,
  TRADE_TYPES,
  TransactionObjectTypes,
  TransactionTypes,
} from '../../../../../interfaces/transaction';
import EditSwitchEquitiesModal from './editSwitchEquitiesModal';
import {
  MenuItem, TextField, Tooltip, DateField,
  Form,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  Button,
} from '../../../../2-component';
import {
  AmountField, NumberField, SecuritySelect, SubAccountSelect,
} from '../../../../3-pattern';
import { Box } from '../../../../1-primative';
import { PageObjectType } from '../../../../5-page';

export const CREATE_ADJUSTMENT_TRANSACTION = gql`
  mutation createAdjustmentTransaction($input: CreateAdjustmentTransactionInput!) {
    createAdjustmentTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const SWITCH_EQUITIES_AT_COST = gql`
  mutation switchEquitiesAtCost($input: SwitchEquitiesAtCostInput!) {
    switchEquitiesAtCost(input: $input) {
      transactions {
        id
        date
        type
        quantity
        priceCents
        valueCents
        description
        financialProduct {
          id
          ticker
          name
          translatedName {
            en
          }
        }
        subAccount {
          account {
            user {
              firstName
              lastName
            }
          }
          goal {
            name
          }
          account {
            type
          }
        }
      }
    }
  }
`;

export const SWITCH_EQUITIES_AT_MARKET = gql`
  mutation switchEquitiesAtMarket($input: SwitchEquitiesAtMarketInput!) {
    switchEquitiesAtMarket(input: $input) {
      transactions {
        id
        date
        type
        quantity
        priceCents
        valueCents
        description
        financialProduct {
          id
          ticker
          name
          translatedName {
            en
          }
        }
        subAccount {
          account {
            user {
              firstName
              lastName
            }
          }
          goal {
            name
          }
          account {
            type
          }
        }
      }
    }
  }
`;

interface TransactionInputPros {
  type: TransactionTypes;
  date?: string;
  subAccountId?: string;
  adjustmentType: 'DEPOSIT' | 'WITHDRAW';
  valueCents?: number;
  description?: string;
  quantity?: number;
  priceCents?: number;
  financialProductId?: string;
  currency?: Currencies;
}

interface SwitchInputPros {
  switchType?: SwitchTypes;
  financialProductOutId?: string;
  financialProductInId?: string;
  isExistingHolding: boolean;
}

const isMissingOrInvalidProducts = (values: any) => !values.financialProductInId || !values.financialProductOutId || values.isInvalidFinancialProduct;

const NewTransaction = ({
  afterCreate,
  forObject,
  forId,
  initialDate = dayjs().format('YYYY-MM-DD'),
}: {
  afterCreate: () => void;
  forObject: PageObjectType;
  forId: string;
  initialDate?: string;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const { availableCurrencies } = useContext(UserContext);

  const [transaction, setTransaction] = useState<TransactionInputPros>({
    date: initialDate,
    type: TransactionTypes.ADJUSTMENT,
    adjustmentType: 'DEPOSIT',
    currency: Currencies.CAD,
  });

  useEffect(() => {
    if (transaction.type === TransactionTypes.DIVIDEND) {
      setTransaction((prev) => ({
        ...prev,
        quantity: 0,
        priceCents: 0,
      }));
    }
  }, [transaction.type]);

  const [switchInput, setSwitchInput] = useState<SwitchInputPros>({
    isExistingHolding: false,
  });

  const [createAdjustmentTransaction, opt1] = useMutation(CREATE_ADJUSTMENT_TRANSACTION, {
    variables: {
      input: {
        ...pick(['description', 'date', 'subAccountId', 'currency'], transaction),
        valueCents: getValueSign(transaction.type, transaction.adjustmentType) * (transaction.valueCents ?? 0) * 100,
      },
    },
  });

  const calculateValueCents = (item: TransactionInputPros) => {
    if (QUANTITY_PRICE_MULTIPLIERS.includes(item.type)) {
      return (item.quantity ?? 0) * (item.priceCents ?? 0);
    }

    return getValueSign(item.type, item.adjustmentType) * (item.valueCents ?? 0) * 100;
  };

  const [createTransaction, opt2] = useMutation(CREATE_TRANSACTION, {
    variables: {
      input: {
        ...pick(['description', 'date', 'subAccountId', 'type'], transaction),
        ...(TRADE_TYPES.includes(transaction.type)
          ? {
            ...pick(['financialProductId'], transaction),
            priceCents: (transaction.priceCents ?? 0) * 100,
            quantity: getValueSign(transaction.type) * -1 * (transaction.quantity ?? 0),
          }
          : { currency: transaction.currency }),
        objectType: TransactionObjectTypes.ADJUSTMENT,
        valueCents: calculateValueCents(transaction),
      },
    },
  });

  const [switchEquitiesAtCostMutation, opt3] = useMutation(SWITCH_EQUITIES_AT_COST, {
    variables: {
      input: {
        subAccountId: transaction.subAccountId,
        date: transaction.date,
        description: transaction.description,
        financialProductInId: switchInput.financialProductInId,
        financialProductOutId: switchInput.financialProductOutId,
        isExistingHolding: transaction.type as any === 'SWITCH',
      },
    },
  });

  const [switchEquitiesAtMarketMutation, opt4] = useMutation(SWITCH_EQUITIES_AT_MARKET, {
    variables: {
      input: {
        subAccountId: transaction.subAccountId,
        date: transaction.date,
        description: transaction.description,
        financialProductInId: switchInput.financialProductInId,
        financialProductOutId: switchInput.financialProductOutId,
        isExistingHolding: transaction.type as any === 'SWITCH',
      },
    },
  });

  const canWriteOtherTransactionTypes = () => permissions.includes('write:transaction');

  const create = async (event: any) => {
    let response;

    if (transaction.type === TransactionTypes.ADJUSTMENT) {
      response = await createAdjustmentTransaction();
      if (response?.data) {
        showToast({ severity: 'info', message: t('components:transaction.newTransaction.createdToastMessage') });
      }
    } else if (switchInput?.switchType === SwitchTypes.AT_COST) {
      response = await switchEquitiesAtCostMutation();
      if (response?.data) {
        setOutTransaction(response.data.switchEquitiesAtCost.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWO_COST));
        setInTransaction(response.data.switchEquitiesAtCost.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWI_COST));
        showToast({ severity: 'info', message: t('components:transaction.switchTransaction.switchedToastMessage') });

        setTransactionsModalOpen(true);
        setSwitchInput({
          isExistingHolding: false,
        });
      } else {
        showToast({ severity: 'error', message: t('subaccount:switchEquitiesModal:errorToastMessage') });
      }
    } else if (switchInput.switchType === SwitchTypes.AT_MARKET) {
      response = await switchEquitiesAtMarketMutation();
      if (response?.data) {
        setOutTransaction(response.data.switchEquitiesAtMarket.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWO_MARKET));
        setInTransaction(response.data.switchEquitiesAtMarket.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWI_MARKET));
        showToast({ severity: 'info', message: t('components:transaction.switchTransaction.switchedToastMessage') });

        setTransactionsModalOpen(true);
        setSwitchInput({
          isExistingHolding: false,
        });
      } else {
        showToast({ severity: 'error', message: t('subaccount:switchEquitiesModal:errorToastMessage') });
      }
    } else {
      response = await createTransaction();
      if (response?.data) {
        showToast({ severity: 'info', message: t('components:transaction.newTransaction.createdToastMessage') });
      }
    }

    setTransaction({
      date: initialDate,
      type: TransactionTypes.ADJUSTMENT,
      adjustmentType: 'DEPOSIT',
      currency: Currencies.CAD,
    });
    afterCreate();
    setOpen(false);
  };

  const disabled = !(
    transaction.subAccountId
    && transaction.date
    && (ADJUSTED_COST_BASE_TYPES.includes(transaction.type) || (transaction.valueCents !== undefined && transaction.valueCents >= 0))
    && (!TRADE_TYPES.includes(transaction.type) || (
      transaction.financialProductId
      && (
        (transaction.type === TransactionTypes.DIVIDEND && transaction.quantity === 0 && transaction.priceCents === 0)
        || (transaction.quantity && transaction.priceCents)
      )
    ))
  );

  const loading = opt1.loading || opt2.loading || opt3.loading || opt4.loading;

  const [open, setOpen] = useState<boolean>(false);
  const [transactionsModalOpen, setTransactionsModalOpen] = useState<boolean>(false);
  const [outTransaction, setOutTransaction] = useState<any>(undefined);
  const [inTransaction, setInTransaction] = useState<any>(undefined);
  const [isInvalidFinancialProduct, setIsInvalidFinancialProduct] = useState<boolean>(false);

  const getPriceFieldLabel = () => t(`components:transaction.newTransaction.${ADJUSTED_COST_BASE_TYPES.includes(transaction.type) ? 'adjustedCostBase' : 'price'}`);

  const subAccountFilter = () => {
    switch (forObject) {
      case PageObjectType.INDIVIDUAL:
        return { userId: forId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: forId };
      case PageObjectType.ACCOUNT:
        return { accountId: forId };
      case PageObjectType.GOAL:
        return { goalId: forId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: forId };
      case PageObjectType.SUB_ACCOUNT:
        return { subAccountId: forId };
      default:
        return { userId: forId };
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} label={t('shared:add')} leadingIcon={Add} />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle onClose={() => setOpen(false)}>
        {t('components:transaction.newTransaction.title')}
        </DialogTitle>
        <Form onSubmit={create}>
          <DialogContent>
            {canWriteOtherTransactionTypes() && (
                <TextField select value={transaction.type} sx={{ mt: 2 }} label={t('components:type')} onChange={(e: any) => setTransaction((prev) => ({ ...prev, type: e.target.value }))} fullWidth>
                  {
                    [
                      ...Object.entries(t('components:transaction.types', { returnObjects: true })),
                      ...Object.entries(t('components:transaction.switchMenuItem', { returnObjects: true })),
                    ]
                      .sort((a, b) => a[1].localeCompare(b[1]))
                      .map(([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value}
                      </MenuItem>
                      ))}
                </TextField>
            )}
            <SubAccountSelect
              onSubAccountSelect={(e: any) => {
                setTransaction((prev) => ({ ...prev, subAccountId: e.id }));
              }}
              selectedSubAccount={{ id: transaction.subAccountId ?? '' }}
              label={t('components:transaction.newTransaction.portfolio')}
              filter={subAccountFilter()}
              sx={{ mt: 2 }}
            />
            <DateField
              label={t('components:transaction.newTransaction.date')}
              value={transaction.date}
              fullWidth
              onChange={(date: any) => setTransaction((prev) => ({ ...prev, date: dayjs(date).format('YYYY-MM-DD') }))}
              renderInput={(params: any) => <TextField fullWidth {...params} />}
              disableFuture={true}
              disabled={transaction.type as any === 'SWITCH'}
              sx={{ mt: 2 }}
            />
            {TRADE_TYPES.includes(transaction.type) && (
              <>
                <SecuritySelect
                  value={transaction.financialProductId ?? ''}
                  setSecurity={(e) => setTransaction((prev) => ({ ...prev, financialProductId: e.id }))}
                  label={t('components:transaction.newTransaction.financialProduct')}
                  sx={{ mt: 2 }}
                />
                {transaction.type !== TransactionTypes.DIVIDEND && (
                  <>
                    <NumberField
                      label={t('components:transaction.newTransaction.quantity')}
                      decimalPlaces={6}
                      number={transaction.quantity?.toString() ?? ''}
                      setNumber={(value: any) => setTransaction((prev) => ({ ...prev, quantity: value }))}
                      sx={{ mt: 2 }}
                    />
                    <AmountField
                      label={getPriceFieldLabel()}
                      amount={transaction.priceCents?.toString() ?? ''}
                      decimalPlaces={6}
                      fullWidth
                      setAmount={(e: any) => setTransaction((prev) => ({ ...prev, priceCents: e }))}
                      sx={{ mt: 2 }}
                    />
                  </>
                )}
              </>
            )}

            {(isAdjustmentTypeAvailable(transaction.type)) && (
                <TextField
                  select
                  value={transaction.adjustmentType}
                  label={t('components:transaction.newTransaction.adjustmentType')}
                  fullWidth
                  onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
                  sx={{ mt: 2 }}
                >
                  <MenuItem value='DEPOSIT'>{t('components:transferModal.deposit')}</MenuItem>
                  <MenuItem value='WITHDRAW'>{t('components:transferModal.withdraw')}</MenuItem>
                </TextField>
            )}

            {[TransactionTypes.INTEREST, TransactionTypes.ACCRUED_INTEREST].includes(transaction.type) && (
              <TextField
                select
                value={transaction.adjustmentType}
                label={t('components:transaction.newTransaction.interestType')}
                fullWidth
                onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
                sx={{ mt: 2 }}
              >
                <MenuItem value='DEPOSIT'>{t('components:transaction.newTransaction.interestTypes.DEPOSIT')}</MenuItem>
                <MenuItem value='WITHDRAW'>{t('components:transaction.newTransaction.interestTypes.WITHDRAW')}</MenuItem>
              </TextField>
            )}

            {[TransactionTypes.CANCEL_CHEQUE].includes(transaction.type) && (
              <TextField
                select
                value={transaction.adjustmentType}
                label={t('components:transaction.newTransaction.interestType')}
                fullWidth
                onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
                sx={{ mt: 2 }}
              >
                <MenuItem value='WITHDRAW'>{t('components:transferModal.deposit')}</MenuItem>
                <MenuItem value='DEPOSIT'>{t('components:transferModal.withdraw')}</MenuItem>
              </TextField>
            )}

            {[TransactionTypes.CANCEL_INTEREST].includes(transaction.type) && (
              <TextField
                select
                value={transaction.adjustmentType}
                label={t('components:transaction.newTransaction.interestType')}
                fullWidth
                onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
                sx={{ mt: 2 }}
              >
                <MenuItem value='WITHDRAW'>{t('components:transaction.newTransaction.interestTypes.DEPOSIT')}</MenuItem>
                <MenuItem value='DEPOSIT'>{t('components:transaction.newTransaction.interestTypes.WITHDRAW')}</MenuItem>
              </TextField>
            )}

            {!ADJUSTED_COST_BASE_TYPES.includes(transaction.type) && !([
              'SWITCH',
            ].includes(transaction.type)) && (
              <AmountField
                label={t('components:transaction.newTransaction.amount')}
                amount={transaction.valueCents?.toString() || ''}
                fullWidth
                setAmount={(e: any) => setTransaction((prev) => ({ ...prev, valueCents: e }))}
                sx={{ mt: 2 }}
              />
            )}
            {transaction.type as any === 'SWITCH' && (
              <>
                <TextField
                  select
                  sx={{ mt: 2 }}
                  value={switchInput.switchType ?? ''}
                  label={t('components:transaction:table.switchType')}
                  onChange={(e: any) => setSwitchInput((prev: any) => ({ ...prev, switchType: e.target.value }))}
                  fullWidth
                >
                  {Object.entries(t('components:transaction.switchTypes', { returnObjects: true }))
                    .sort((a, b) => a[1].localeCompare(b[1]))
                    .map(([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value}
                      </MenuItem>
                    ))}
                </TextField>
                {switchInput.switchType && (
                  <Tooltip
                    title={t(switchInput.switchType === SwitchTypes.AT_COST ? 'components:transaction.switchTransaction.atCostInfo' : 'components:transaction.switchTransaction.atMarketInfo')}
                  >
                    <InfoIcon fontSize='small' color='secondary' />
                  </Tooltip>
                )}
                <SecuritySelect
                  value={switchInput.financialProductOutId ?? ''}
                  sx={{ mt: 2 }}
                  setSecurity={(e) => {
                    setIsInvalidFinancialProduct(e.id === switchInput.financialProductInId);
                    setSwitchInput((prev: any) => ({ ...prev, financialProductOutId: e.id }));
                  }}
                  label={t('components:transaction.switchTransaction.outSecurity')}
                  exchange={Exchanges.FUNDSERV}
                />
                <SecuritySelect
                  value={switchInput.financialProductInId ?? ''}
                  sx={{ mt: 2 }}
                  setSecurity={(e) => {
                    setIsInvalidFinancialProduct(e.id === switchInput.financialProductOutId);
                    setSwitchInput((prev: any) => ({ ...prev, financialProductInId: e.id }));
                  }}
                  label={t('components:transaction.switchTransaction.inSecurity')}
                  inputError={isInvalidFinancialProduct}
                  errorText={t('subaccount:switchEquitiesModal:sameProductToastMessage')}
                  exchange={Exchanges.FUNDSERV}
                />
              </>
            )}
            <TextField
              label={t('components:transaction.newTransaction.details')}
              fullWidth
              sx={{ mt: 2 }}
              value={transaction.description ?? ''}
              onChange={(e: any) => setTransaction((prev) => ({ ...prev, description: e.target.value }))}
            />
        {![...TRADE_TYPES, 'SWITCH'].includes(transaction.type) && (
              <TextField
                select
                sx={{ mt: 2 }}
                label={t('components:transaction.table.currency')}
                fullWidth
                value={transaction.currency ?? ''}
                placeholder={transaction.currency ?? ''}
                onChange={(e: any) => setTransaction((prev) => ({
                  ...prev,
                  currency: e.target.value as Currencies,
                }))}
              >
                {availableCurrencies.map((currency: string) => (
                  <MenuItem
                    value={currency}
                    key={currency}
                  >
                    {t(`components:transaction.currencies.${currency}`)}
                  </MenuItem>
                ))}
              </TextField>
        )}
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='end'>
              <Button
                type='submit'
                label={t('shared:save')}
                disabled={(transaction.type as any === 'SWITCH' ? isMissingOrInvalidProducts({ ...switchInput, isInvalidFinancialProduct }) : disabled) || loading}
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
      <EditSwitchEquitiesModal
        open={transactionsModalOpen}
        handleClose={() => {
          setTransactionsModalOpen(false);
          afterCreate();
        }}
        outTransaction={outTransaction}
        inTransaction={inTransaction}
        title={switchInput.switchType === SwitchTypes.AT_COST ? t('subaccount:switchEquitiesModal:atCost') : t('subaccount:switchEquitiesModal:atMarket')}
      />
    </>
  );
};

export default NewTransaction;
