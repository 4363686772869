import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import Lottie from 'react-lottie';

const FETCH_FILE_DOCUMENT_URL = gql`
query fetchFileDocumentDownloadUrl($fileDocumentId:ObjectID!) {
  fetchFileDocument(fileDocumentId: $fileDocumentId) {
    fileDocument {
      downloadUrl fileName
    }
  }
}`;

interface InlineImageProps {
  fileDocumentId: string;
  style?: CSSProperties;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
};

export function InlineImage({ fileDocumentId, style }: InlineImageProps) {
  const [animationData, setAnimationData] = useState<any>(undefined);
  const { data, loading } = useQuery(FETCH_FILE_DOCUMENT_URL, {
    variables: { fileDocumentId },
    fetchPolicy: 'no-cache',
  });

  const fetchAnimationData = async () => {
    try {
      const response = await fetch(data?.fetchFileDocument?.fileDocument?.downloadUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch animation data');
      }
      const jsonData = await response.json();
      if (jsonData) setAnimationData(jsonData);
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Error fetching file:', error);
    }
  };

  useEffect(() => {
    if (data?.fetchFileDocument?.fileDocument?.fileName.split('.')[1] === 'json') {
      fetchAnimationData();
    }
    // eslint-disable-next-line
  }, [data]);

  const isJsonFile = data?.fetchFileDocument?.fileDocument?.fileName.split('.')[1] === 'json';

  return (
    <>
      {loading && <Typography>...</Typography>}
      {data?.fetchFileDocument?.fileDocument?.downloadUrl && !isJsonFile && (<>
        <img
          src={data?.fetchFileDocument?.fileDocument?.downloadUrl}
          alt={data?.fetchFileDocument?.fileDocument?.fileName}
          width="100%"
          style={style ?? { borderTop: '1px solid grey' }}
        />
      </>)
      }
      {isJsonFile && animationData && (
        <Lottie options={{ ...defaultOptions, animationData }} height={style?.height} width="100%" />
      )}
    </>
  );
}
