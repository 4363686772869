/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../../3-pattern/confirmationDialog/confirmationDialog';

export const TRANSITION_SUB_TRADE_REQUEST = gql`
  mutation transitionSubTradeRequest($input: TransitionSubTradeRequestInput!) {
    transitionSubTradeRequest(input: $input) {
      subTradeRequest {
        id
      }
    }
  }
`;

const CancelSubTradeRequestModal = ({ id, open, onClose, afterCancel }: { id: string; open: boolean; onClose: () => void; afterCancel?: () => void }) => {
  const { t } = useTranslation(['components']);

  const [transitionSubTradeRequest] = useMutation(TRANSITION_SUB_TRADE_REQUEST, {
    onCompleted: () => {
      if (afterCancel) {
        afterCancel();
      }

      onClose();
    },
  });

  const cancelSubTradeRequest = () =>
    transitionSubTradeRequest({
      variables: {
        input: {
          subTradeRequestId: id,
          transition: 'cancel',
        },
      },
    });

  return <ConfirmationModal open={open} title={t('components:subTradeRequestTableDialog.title')} onCancel={onClose} onConfirm={cancelSubTradeRequest} />;
};

export default CancelSubTradeRequestModal;
