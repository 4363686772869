import { gql } from '@apollo/client';

export const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        middleName
        lastName
        entityName
        households {
          id
          relationships {
            user {
              id
            }
            accessType
          }
          name
          organization {
            id
          }
        }
      }
    }
  }
`;

export const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
      }
    }
  }
`;

export const FETCH_ACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        nickName
        translatedNickName { en fr }
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        account {
          type
        }
        goal {
          name
        }
      }
    }
  }
`;

export const FETCH_HOUSEHOLD = gql`
  query fetchHousehold($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        id
        name
      }
    }
  }
`;
