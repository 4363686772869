import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, MenuItem,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Icon, Typography } from '../../../../../../1-primative';
import {
  Button, Checkbox, DateField, Form, SelectField,
} from '../../../../../../2-component';
import {
  BankAccount,
  ExternalTransferTypes, SubAccount,
  TranslatedString,
} from '../../../../../../../interfaces';
import { SubAccountSelect } from '../../../../../../3-pattern/subaccountSelect/subAccountSelect';
import { BankAccountSelectField } from '../../../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import { AmountField } from '../../../../../../3-pattern/amountField/amountField';
import { useThemeTokens } from '../../../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../../../assets/i18n/config';

export interface BankAccountTransfer {
  amountCents: number,
  bankAccount: {
    id: string,
    name: string,
    bankAccountNumber: string,
    bankAccountNumberDisclosed?: string,
    institutionNumber?: string,
    transitNumber?: string,
    transitNumberDisclosed?: string,
    accountNumber?: string,
  },
  frequency: string,
  scheduledDate: string,
  subAccount?: SubAccount,
  isRecurring?: boolean,
}

interface BankAccountTransferDialogContentProps {
  onTransferCreated: (transfer: BankAccountTransfer) => void,
  userData: any,
  onDialogClose: () => void,
  disclaimer?: TranslatedString | null,
}

const BankAccountTransferDialogContent = ({
  onTransferCreated, userData, onDialogClose, disclaimer,
}: BankAccountTransferDialogContentProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes', 'components']);
  const [inboundAccount, setInboundAccount] = useState<SubAccount | undefined>();
  const [frequency, setFrequency] = useState<ExternalTransferTypes>(ExternalTransferTypes.AllInCash);
  const [bank, setBank] = useState<BankAccount | null>(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [amount, setAmount] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const { sys } = useThemeTokens();
  const submit = () => {
    if (!bank) return;
    onTransferCreated({
      amountCents: Number(amount) * 100,
      bankAccount: {
        ...bank,
      },
      frequency,
      scheduledDate,
      subAccount: inboundAccount,
      isRecurring,
    });
  };

  const allFieldsValid = bank !== null && !!inboundAccount && amount !== '';

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:bankAccountTransferCreation.title')}</Typography>
        <SubAccountSelect
          sx={{ mt: '24px' }}
          selectedSubAccount={inboundAccount}
          onSubAccountSelect={setInboundAccount}
          userId={userData.id}
          label={t('components:transferModal.portfolio')}
        />
        <BankAccountSelectField
          userId={userData.id}
          bankAccount={bank}
          setBankAccount={setBank}
          discloseBankAccountNumber
          sx={{ mt: 3 }}
        />
        <Grid
            container
            display='flex'
            flexDirection='row'
            sx={{ mt: '20px', mb: '24px' }}
            justifyContent='space-between'
            spacing={1}
            alignItems='end'
        >
          <Grid item xs={4}>
            <AmountField amount={amount} setAmount={setAmount} />
          </Grid>
          {
            isRecurring ? (<Grid item xs={4}>
              <SelectField
                  onChange={(e: any) => setFrequency(e.target.value)}
                  label={t('components:transferModal.schedule')}
                  fullWidth
                  value={frequency}
              >
                <MenuItem value='ONE_TIME'>{t('components:transferModal.scheduleOptions.ONE_TIME')}</MenuItem>
                <MenuItem value='WEEKLY'>{t('components:transferModal.scheduleOptions.WEEKLY')}</MenuItem>
                <MenuItem value='BI_WEEKLY'>{t('components:transferModal.scheduleOptions.BI_WEEKLY')}</MenuItem>
                <MenuItem value='SEMI_MONTHLY'>{t('components:transferModal.scheduleOptions.SEMI_MONTHLY')}</MenuItem>
                <MenuItem value='MONTHLY'>{t('components:transferModal.scheduleOptions.MONTHLY')}</MenuItem>
                <MenuItem value='QUARTERLY'>{t('components:transferModal.scheduleOptions.QUARTERLY')}</MenuItem>
              </SelectField>
            </Grid>) : undefined
          }
          {
            isRecurring ? (<Grid item xs={4}>
              <DateField
                  label={t('components:transferModal.starting')}
                  value={scheduledDate}
                  onChange={(date: any) => setScheduledDate(dayjs(date?.toString()).format('YYYY-MM-DD'))}
                />
              </Grid>) : undefined
            }
        </Grid>
        <Checkbox label={t('workflowCompletions:bankAccountTransferCreation.makeRecurring')} checked={isRecurring} onChange={(value) => setIsRecurring(value)} />
      <br></br>
      {disclaimer && (
        <Box sx={{
          mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
        }}>
          <Typography variant='bodySmall'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(disclaimer)}</ReactMarkdown>
          </Typography>
        </Box>
      )
      }
        <Box width='100%' display='flex' justifyContent='end'>
          <Button
            color='primary'
            label={t('workflowCompletions:addTransfer')}
            disabled={!allFieldsValid}
            sx={{ mt: 3 }}
            type='submit'
          />
        </Box>
    </Form>
  );
};

export default BankAccountTransferDialogContent;
