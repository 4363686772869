import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { MenuItem, SelectField } from '../../2-component';
import RichTextEditor from '../../3-pattern/richTextEditor/richTextEditor';

enum Languages {
  en = 'en',
  fr = 'fr',
}

export const HtmlTemplate = ({
  option, options, setOptions, i,
}: { option: any; options: any; setOptions: (x: any) => void; i: number }) => {
  const { t } = useTranslation('components');
  const [language, setLanguage] = useState(getBackendLanguage());
  const [value, setValue] = useState(atob(option.value[language]));

  const renderEditor = () => <RichTextEditor value={value} setValue={setValue} />;

  useEffect(() => {
    const newOptions = [...options];
    newOptions[i] = {
      ...option,
      value: { ...option.value, [language]: btoa(value) },
    };
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box>
      <Typography variant='bodyMedium' weight='bold' mb={1}>
        {translateBackend(option.label)}
      </Typography>
      <SelectField label={t('languageSelection.language')} testId='languageSelectField' fullWidth sx={{ mb: 2 }} value={language} onChange={(event: any) => setLanguage(event.target.value)}>
        {Object.values(Languages).map((lang) => (
          <MenuItem value={lang}>{t(`pageConfiguration:htmlTemplate.languages.${lang}`)}</MenuItem>
        ))}
      </SelectField>
      {renderEditor()}
    </Box>
  );
};
