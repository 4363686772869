import {
  FormControlLabel,
  FormGroup,
  ListItem, Switch,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_ACCOUNT } from '../../pages/accountsDetail';
import { Account } from '../../interfaces';
import { usePermissions } from '../../providers/userContextProvider';
import FormModal from './formModal';

export const UPDATE_ACCOUNT_NUMBER = gql`
mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    account{
      id
    }
  }
}
`;

const UpdateTaxLosHarvestingModal = ({
  account, open, handleClose,
}: { account: Account, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['buildModels']);
  const { permissions } = usePermissions();
  const [localOpen, setLocalOpen] = useState(open);

  const [selectedTlh, setSelectedTlh] = useState(account.tlhEnabled ?? false);

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT_NUMBER, {
    variables: {
      input: {
        tlhEnabled: selectedTlh,
        accountId: account.id,
      },
    },
    refetchQueries: [FETCH_ACCOUNT(permissions)],
  });

  useEffect(() => {
    // setAccountNumber({ ...accountNumber, organizationId: activeOrganization.id, custodianAccountNumber: accNumber });
    setLocalOpen(open);
  }, [open]);

  const update = async (event: any) => {
    event.preventDefault();
    await updateAccount();
    handleClose();
  };

  return (
    <FormModal loading={loading} title={t('editTlh.title')} formButton={t('update')} onSubmit={update} open={localOpen} handleClose={handleClose}>
      <ListItem>
        <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
            control={<Switch
              checked={selectedTlh}
              onChange={async (event) => setSelectedTlh(event.target.checked)}
            />}
            label={t(`editTlh.${selectedTlh ?? false}`)}
          />
        </FormGroup>
      </ListItem>
    </FormModal>
  );
};

export default UpdateTaxLosHarvestingModal;
