import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { integrationConfig } from 'pages/devSettings/components/integrationConfig';
import { IntegrationType } from 'interfaces';

interface IntegrationsProviderSelectProps {
  value: string;
  label: string;
  disabled?: boolean;
  currentType: IntegrationType | string;
  integrationsList?: [any];
  setIntegrationProvider: (e: any) => void;
}

const IntegrationsProviderSelect = ({
  value, label, disabled, currentType, integrationsList, setIntegrationProvider,
}: IntegrationsProviderSelectProps) => {
  const { t } = useTranslation('devSettings');
  const filteredProviders = integrationConfig[currentType]?.integration?.filter((x: any) => (
    !integrationsList?.some((integration: any) => (
      integration.type === x.type && integration.provider === x.provider
    ))
  ));

  return (
    <TextField
      required
      select
      value={value ?? ''}
      label={label}
      fullWidth
      onChange={(e: any) => setIntegrationProvider(e)}
      disabled={!currentType || disabled}
    >
      {currentType ? (
        filteredProviders?.map((x: any) => (
          <MenuItem key={x.provider.toString()} value={x.provider}>
            {t(`integrationsTableOptions.provider.${x.provider}`)}
          </MenuItem>
        ))
      ) : (
      <MenuItem/>
      )}

    </TextField>
  );
};
export default IntegrationsProviderSelect;
