import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const MainIndex = ({ options }: { options: any }) => {
  useRefinitivWidget('#main-index-widget', 'WMainIndex', {
    ric: options.mainIndexRic,
    showHeader: options.mainIndexShowHeader,
    view: options.mainIndexViewType,
  });

  return (
    <Card>
      <div id='main-index-widget'></div>
    </Card>
  );
};
