import {
  TextField, MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';

const styles = {
  hover: {
    '&:hover': {
      color: colors.selectedType,
      background: colors.white,
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.selectedType,
      },
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: colors.selectedType,
    },
    '.Mui-selected': {
      backgroundColor: 'transparent',
      color: colors.selectedType,
    },
  },
  selectedOption: {
    position: 'relative',
    top: '-3px',
    border: 'none',
    background: colors.white,
    '.MuiOutlinedInput-root': {
      borderRadius: 0,
      border: 'none',
      outline: 'none',
      'fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    '& .Mui-selected': {
      background: colors.white,
      color: colors.selectedType,
    },
  },
};
const NoteTypeSelect = ({ onChange, value, label }: {
  onChange: (event: any) => void, value?: string, label?: string,
}) => {
  const { t } = useTranslation('client');
  return (
    <>
      <TextField
        select
        value={value}
        size="small"
        sx={styles.selectedOption}
        onChange={onChange}
      >
        <MenuItem sx={styles.hover} key='ORGANIZATIONAL' value='ORGANIZATIONAL'>{t('notes.types.ORGANIZATIONAL')}</MenuItem>
        <MenuItem sx={styles.hover} key='PUBLIC' value='PUBLIC'>{t('notes.types.PUBLIC')}</MenuItem>
        <MenuItem sx={styles.hover} key='PRIVATE' value='PRIVATE'>{t('notes.types.PRIVATE')}</MenuItem>
      </TextField>
    </>
  );
};

export default NoteTypeSelect;
