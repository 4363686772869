import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const CompanyProfile = ({ options }: { options?: any }) => {
  useRefinitivWidget('#company-profile-widget', 'WCompanyProfile', options);

  return (
    <Card>
      <div id='company-profile-widget'></div>
    </Card>
  );
};
