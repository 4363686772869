/* eslint-disable no-param-reassign */
import { Box } from 'ovComponents/1-primative';
import { useEffect } from 'react';
import { pdfjs } from 'react-pdf';

export const PdfViewer = ({
  pdfUrl,
  onError,
  onFulfilled,
}: {
  pdfUrl?: string,
  onError?: (error?: any) => void
  onFulfilled?: () => void,
}) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
  }, []);

  const renderPage = (pageNumber: number, canvas: any, pdf: any) => {
    pdf.getPage(pageNumber).then((page: any) => {
      const viewport = page.getViewport({ scale: 0.9 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({ canvasContext: canvas.getContext('2d'), viewport });
    });
  };

  const renderPdf = async () => {
    if (pdfUrl) {
      const loadingTask = pdfjs.getDocument(pdfUrl);
      loadingTask.promise.then((pdf) => {
        const viewer = document.getElementById('pdf-viewer');
        while (viewer?.firstChild) {
          viewer?.removeChild(viewer?.firstChild);
        }
        let canvas1 = null;
        for (let page = 1; page <= pdf.numPages; page++) {
          canvas1 = document.createElement('canvas');
          canvas1.className = 'pdf-page-canvas';
          viewer?.appendChild(canvas1);
          renderPage(page, canvas1, pdf);
        }
        if (onFulfilled) onFulfilled();
      }, (error) => {
        if (onError) onError(error);
      });
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      renderPdf();
    }
  // eslint-disable-next-line
  }, [pdfUrl]);

  return (
    <Box sx={{ lineHeight: 'inherit' }}>
      <div id='pdf-viewer'></div>
    </Box>
  );
};
