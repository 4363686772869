import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../util/useLocalization';

export const FETCH_TLH_JOB_DATE = gql`
  query fetchTLHJobDate($tlhJobId: ObjectID!) {
    fetchTLHJob(tlhJob: $tlhJobId) {
      createdAt
    }
  }
`;

export function TaxLossHarvestingBreadcrumb() {
  const { data } = useQuery(FETCH_TLH_JOB_DATE, { variables: { tlhJobId: useParams().id } });
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();

  return `${t('taxLossHarvesting:analysisOn')} ${data ? localizedDateTime(data.fetchTLHJob.createdAt) : ''}`;
}
