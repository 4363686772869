import {
  Box,
  Dialog, DialogTitle, Typography,
} from '@mui/material';
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../modals/confirmationModal';
import { colors } from '../../theme/colors';

export const styles = {
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '416px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '368px',
      title: {
        fontWeight: 700,
      },
    },
    cancelButton: {
      minWidth: '180px',
      fontWeight: 700,
      color: colors.gray800,
      background: colors.gray300,
    },
    dialogButton: {
      minWidth: '180px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
  titleText: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '34px',
  },
  subText: {
    color: colors.gray500,
    fontSize: '14px',
  },
};

type Step = string;

export type StepsDefinition = Partial<Record<Step, StepComponent>>;

export type WizardControlProps = {
  handleClose: () => void,
};

type Wizard = (params: any) => JSX.Element;

/* Each wizard step can ... */
export type GenericStepProps<TContext, TState> = {
  /* ... read the common context */
  context: TContext
  /* ... read and modify the state (shared among all steps) */
  state: TState
  setState: (newState: TState) => void
  /* ... proceed to another step, exit the wizard, switch to another Wizard */
  continueTo: (next: Step | null | Wizard, props?: any, returnPath?: ReturnPath) => void
  /* ... inform the WizardBase about ongoing GQL mutation */
  mutationEvent: (event: 'started' | 'succeeded' | 'failed') => void
};

export type StepComponent = ({
  context, state, setState, continueTo, mutationEvent,
}: GenericStepProps<any, any>) => JSX.Element;

export type ReturnPath = {
  callToAction: string,
  action: (returnValue: any) => void
};

const SHOW_WIZARD_INSPECTOR = true;

interface WizardBaseProps {
  /**
   * wizard name
   */
  title: string
  /**
   * Static context, ie. { userId: '12345' }
   */
  context?: Record<string, any>
  /**
   * The actual steps of this wizard
   */
  steps: Record<Step, StepComponent>
  /**
   * Initial step to show
   */
  firstStep: Step
  /**
   * Empty (inital) state
   */
  initialState: Record<string, any>
  /*
   * minimum dialog height in pixels (default: 400)
   */
  minimumHeight?: number
  /**
   * handler to exit the wizard
   */
  handleClose: () => void
}

export const ReturnPathContext = createContext<ReturnPath | null>(null);

export function WizardBase({
  title, context = {}, steps, firstStep, initialState, minimumHeight, handleClose,
}: WizardBaseProps) {
  const { t } = useTranslation('shared');
  const [step, setStep] = useState<Step>(firstStep);
  const [state, setState] = useState<Record<string, any>>(initialState);
  const [stepJourney, setStepJourney] = useState<Step[]>([firstStep]);
  const [allowBack, setAllowBack] = useState<boolean>(false);
  const [allowClose, setAllowClose] = useState<boolean>(true);
  const [nextWizard, setNextWizard] = useState<JSX.Element | null>(null);
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);

  useEffect(() => {
    setAllowBack(step !== firstStep);
  }, [step, firstStep, setAllowBack]);

  if (nextWizard !== null) return nextWizard;

  /**
   * continueTo('STEP_123') ... proceeds to a next step
   * continueTo(null) ... exits the wizard
   * continueTo(AnotherWizard, { foo: 123 }) ... exists the wizard and enters another one
   * continueTo(AnotherWizard, { foo: 123 }, {action, callToAction}) ... calls another sub-wizard
   */
  const continueTo = (next: Step | null | Wizard, props?: any, returnPath?: ReturnPath): void => {
    if (next === null) {
      handleClose();
    } else if (typeof next === 'string') {
      if (!stepJourney.includes(next)) {
        setStepJourney([...stepJourney, next]);
      }
      setStep(next);
    } else if (typeof next === 'function') {
      setStepJourney([]);

      const AnotherWizard = next; // must be CamelCase to properly render

      if (!returnPath) {
        setNextWizard(
          <AnotherWizard {...props} handleClose={handleClose} />,
        );
      } else {
        const returnPathFn = (backProps:any) => { setNextWizard(null); returnPath?.action(backProps); };
        setNextWizard(
          <ReturnPathContext.Provider value={{ callToAction: returnPath.callToAction, action: returnPathFn }}>
          <AnotherWizard {...props} handleClose={handleClose} />,
        </ReturnPathContext.Provider>,
        );
      }
    }
  };

  const goBack = (): void => {
    const goBackTo = stepJourney.length > 1 ? stepJourney[stepJourney.length - 2] : firstStep;
    const index = stepJourney.indexOf(goBackTo);
    if (index !== -1) {
      stepJourney.length = index + 1;
      stepJourney.splice(index, 1);
      setStepJourney(stepJourney);
    }
    setStep(goBackTo);
  };

  const deepEquality = (a: Record<string, any>, b: Record<string, any>) => JSON.stringify(a) === JSON.stringify(b);

  const handleExit = () => {
    if (!allowClose) return;

    if (deepEquality(state, initialState)) {
      handleClose();
    } else {
      setExitConfirmationOpen(true);
    }
  };

  const handleMutationEvent = (event: 'started' | 'succeeded' | 'failed') => {
    if (event === 'started') {
      setTimeout(() => {
        setAllowClose(false);
        setAllowBack(false);
      }, 0);
    }
    if (event === 'succeeded' || event === 'failed') {
      setAllowClose(true);
      setAllowBack(event === 'failed');
    }
    if (event === 'succeeded') {
      setState(initialState);
    }
  };

  const ActualStep: StepComponent = steps[step] as StepComponent;

  return (
    <>
      <Dialog onClose={handleExit} open fullWidth PaperProps={{
        sx: {
          minHeight: minimumHeight ?? 400,
          width: 448,
          maxWidth: 448,
        },
      }}>
        <DialogTitle sx={{
          display: 'flex', justifyContent: 'space-between', paddingBottom: '0px', marginTop: '8px',
        }}>
          <div>
            {allowBack && (
              <Box ml={0} sx={{ cursor: 'pointer' }} onClick={goBack}>
                <ArrowBackIosIcon data-testid='wizard-back-icon' fontSize='small' sx={{ cursor: 'pointer' }} />
                <Typography variant='caption' sx={{ position: 'relative', top: '-7px', left: '-3px' }}>{t('back')}</Typography>
              </Box>
            )}
          </div>
          <CloseIcon
            onClick={handleExit}
            data-testid="wizard-close-icon"
            sx={allowClose ? { cursor: 'pointer' } : { color: 'lightgray' }}
          />
        </DialogTitle>
        <Box
          p={1}
          sx={{
            minWidth: '400px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {ActualStep
            && <ActualStep context={context} state={state} setState={setState} continueTo={continueTo} mutationEvent={handleMutationEvent} />
          }
        </Box>
      </Dialog>
      <ConfirmationModal
        open={exitConfirmationOpen}
        title={t('shared:wizardTitle', { wizardTitle: title })}
        bodyText={t('shared:wizardInterruptionMsg')}
        onConfirm={() => {
          setExitConfirmationOpen(false);
          handleClose();
        }}
        onCancel={() => setExitConfirmationOpen(false)}
        confirmButton={t('shared:yesExit')}
        cancelButton={t('shared:cancel')}
        dialogStyles={styles.confirmation}
        confirmColor='error'
      />
      { SHOW_WIZARD_INSPECTOR && process.env.NODE_ENV === 'development'
        && <WizardInspector title={title} steps={steps} step={step} stepJourney={stepJourney} context={context} state={state}/>
      }
    </>
  );
}

const WizardInspector = ({
  title, steps, step, stepJourney, context, state,
}: any) => {
  const returnPath = useContext(ReturnPathContext);

  return (<Box sx={{
    backgroundColor: '#ddf', position: 'fixed', left: '10px', bottom: '10px', zIndex: '1299', padding: '3px',
  }}>
    <Typography sx={{ backgroundColor: '#eef' }}>wizard: <code>{title}</code></Typography>
    <Typography>steps: {Object.keys(steps).map((s) => <code key={s} style={s === step ? { color: 'green', fontWeight: 'bold' } : {}}>{s} </code>)}</Typography>
    <Typography>stepJourney: <code>{JSON.stringify(stepJourney)}</code></Typography>
    <hr/>
    <Typography>context: <code>{JSON.stringify(context).replaceAll(',', ', ')}</code></Typography>
    <Typography>state: <code>{JSON.stringify(state).replaceAll(',', ', ')}</code></Typography>
    <Typography>returnPath: <code>{JSON.stringify(returnPath)?.replaceAll(',', ', ')}</code></Typography>
  </Box>);
};
