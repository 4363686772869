import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OneoffTransfersTable } from '../../components/tables/transfersTable/oneoffTransfersTable';

const Transactions = () => {
  useNavigate();

  return (
    <Paper>
      <OneoffTransfersTable showClient={true} showReconciledAt={true}/>
    </Paper>
  );
};

export default Transactions;
