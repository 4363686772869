import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid, TextField, IconButton, Tooltip, Chip, MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import {
  BillingCycle, BillingCycleStates, stateColor,
} from '../../../interfaces/billingCycle';
import { Date } from '../../../components/misc/date/date';
import { formatMoneyValue } from '../../../util';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import BillingScheduleSelect from '../../../components/inputs/billingScheduleSelect';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

dayjs.extend(utc);

export const FETCH_BILLING_CYCLES = gql`
  query fetchBillingCycles($input: FetchBillingCyclesInput!) {
    fetchBillingCycles(input: $input) {
      totalCount
      billingCycles{
        id
        organization { id name }
        startDate
        endDate
        calculationDate
        billingDate
        billingSchedule{
          id
          frequency
        }
        marketValueCentsOnLastDay
        chargeableMarketValueCentsOnLastDay
        feeCents
        salesTaxCents
        projectedFeeAndTaxCents
        reconciledFees { feeCents salesTaxCents }
        calculatedAt
        state
      }
    }
  }
`;

const IncurredFeesTable = () => {
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [billingScheduleId, setBillingScheduleId] = usePageState('', 'billingSchedule');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [state, setState] = usePageState('', 'state');
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BILLING_CYCLES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          billingScheduleIds: billingScheduleId || undefined,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
          states: state || undefined,
        },
        pagination: {
          sortField: 'billingDate', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ p: 2 }} spacing={1}>
            <Grid item xs={2.5}>
              <OrganizationSelect
                label={t('feeAndBilling:feeReport.table.organization')}
                onChange={(event) => {
                  setOrganizationId(event.target.value);
                  setBillingScheduleId('');
                }}
                value={organizationId}
                childrenFor={activeOrganization.id}
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <BillingScheduleSelect
                label={t('feeAndBilling:feeReport.table.billingSchedule')}
                setValue={(newValue) => setBillingScheduleId(newValue)}
                value={billingScheduleId}
                organizationId={organizationId}
                size='small'
                includeAnyOption
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                value={state || 'any'}
                label={t('feeAndBilling:billingCycle.state')}
                fullWidth
                size='small'
                onChange={(e: any) => setState(e.target.value !== 'any' ? e.target.value : '')}
              >
                <MenuItem key='any' value='any'>{t('components:any')}</MenuItem>
                {Object.keys(BillingCycleStates).map((s) => (
                  <MenuItem key={s} value={s}>{t(`feeAndBilling:billingCycle.${s}`)}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <LocalizedDatePicker
                label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
                value={dateAfter || null}
                onChange={(date) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: dateAfter && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
              />
            </Grid>
            <Grid item xs={2}>
              <LocalizedDatePicker
                label={t('feeAndBilling:feeReport.filters.billingDateTo')}
                value={dateBefore || null}
                onChange={(date) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: dateBefore && (<IconButton onClick={() => setDateBefore('')}><ClearIcon /></IconButton>) }}
              />
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title={t('feeAndBilling:feeGrid.clearFilter')} placement="right">
                <IconButton sx={{ cursor: 'pointer' }} onClick={() => {
                  setOrganizationId(activeOrganization.id ?? '');
                  setBillingScheduleId('');
                  setDateAfter('');
                  setDateBefore('');
                  setPage(1);
                }} >
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.organization')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.calculationDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.billingDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingSchedule.table.frequency')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.startDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.endDate')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.billableAumLastDay')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.billableAumWithReductionsApplied')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.accruedFees')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.salesTax')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.totalFeesAndTax')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.collectedFees')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:billingCycle.projectedFeeAndTax')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('feeAndBilling:billingCycle.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchBillingCycles?.billingCycles?.map((billingCycle: BillingCycle) => (
                <TableRow
                  hover
                  onClick={() => {
                    navigate(`/billingManagement/${billingCycle.id}`);
                  }}
                  data-testid={billingCycle.id}
                  key={billingCycle.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell>
                    <Typography>{billingCycle.organization?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Date variant='subtitle2' date={billingCycle.calculationDate ?? billingCycle.calculatedAt} />
                  </TableCell>
                  <TableCell>
                    <Date variant='subtitle2' date={billingCycle.billingDate} />
                  </TableCell>
                  <TableCell>
                    <Typography>{t(`feeAndBilling:billingSchedule.frequencies.${billingCycle.billingSchedule?.frequency}`)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Date variant='subtitle2' date={billingCycle.startDate} />
                  </TableCell>
                  <TableCell>
                    <Date variant='subtitle2' date={dayjs.utc(billingCycle.endDate).startOf('day').subtract(1, 'day')} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(billingCycle.marketValueCentsOnLastDay ?? 0)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                    {formatMoneyValue((billingCycle.marketValueCentsOnLastDay ?? 0) - (billingCycle.chargeableMarketValueCentsOnLastDay ?? 0))}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(billingCycle.feeCents ?? 0)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(billingCycle.salesTaxCents ?? 0)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue((billingCycle.feeCents ?? 0) + (billingCycle.salesTaxCents ?? 0))}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(
                    (billingCycle.reconciledFees?.feeCents ?? 0) + (billingCycle.reconciledFees?.salesTaxCents ?? 0),
                  )}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">{formatMoneyValue(billingCycle.projectedFeeAndTaxCents ?? 0)}</TableCell>
                  <TableCell>
                    <Chip
                      size='small'
                      label={t(`feeAndBilling:billingCycle.${billingCycle.state}`)}
                      color={stateColor(billingCycle.state)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchBillingCycles?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />

        </>)}
    </>
  );
};

export default IncurredFeesTable;
