import { Jurisdictions } from '@onevesthq/ov-enums';
import {
  availableOperators, ObjectType, RuleDocument, ruleMapping, RuleOptions, ValueTypes,
} from '@onevesthq/ov-conditions';
import { Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '../../../1-primative';
import {
  DateField,
  IconButton, MenuItem, SelectField, TextField,
} from '../../../2-component';
import { AccountTypeSelect } from '../../accountTypeSelect/accountTypeSelect';

const Field = ({ rule, updateRule, objectType }: { rule: RuleDocument, updateRule: (rule: RuleDocument) => void, objectType: ObjectType }) => {
  const { t } = useTranslation('components');
  const jurisdictions: Jurisdictions[] = Object.values(Jurisdictions);

  switch (ruleMapping[objectType]?.find((x: RuleOptions) => x.name === rule.field)?.valueType) {
    case ValueTypes.TEXT:
      return (
        <TextField
          value={rule.value}
          onChange={(e: any) => {
            const r = { ...rule, value: e.target.value };
            updateRule(r);
          }}
          label=''
          fullWidth
        />
      );
    case ValueTypes.NUMBER:
      return (
        <TextField
          type='number'
          value={rule.value}
          onChange={(e: any) => {
            const r = { ...rule, value: e.target.value };
            updateRule(r);
          }}
          label=''
          fullWidth
        />
      );
    case ValueTypes.DATE:
      return (
        <DateField
          value={rule.value}
          onChange={(e: any) => {
            const r = { ...rule, value: e };
            updateRule(r);
          }}
          label=''
          fullWidth
        />
      );
    case ValueTypes.SELECT:
      if (['in', 'notIn'].includes(rule.operator)) {
        return (
          <SelectField
            multiple
            value={Array.isArray(rule.value) ? rule.value : [rule.value]}
            onChange={(e: any) => {
              const r = { ...rule, value: e.target.value };
              updateRule(r);
            }}
            label=''
            fullWidth
          >
            {
              ruleMapping[objectType]?.find((x: RuleOptions) => x.name === rule.field)?.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))
            }
          </SelectField>
        );
      }
      return (
        <SelectField
          value={rule.value}
          onChange={(e: any) => {
            const r = { ...rule, value: e.target.value };
            updateRule(r);
          }}
          label=''
          fullWidth
        >
          {
            ruleMapping[objectType]?.find((x: RuleOptions) => x.name === rule.field)?.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))
          }
        </SelectField>
      );
    case ValueTypes.BOOLEAN:
      return (
        <SelectField
          value={rule.value}
          onChange={(e: any) => {
            const r = { ...rule, value: e.target.value === 'true' };
            updateRule(r);
          }}
          label=''
          fullWidth
        >
          <MenuItem value='true'>
            {t('true')}
          </MenuItem>
          <MenuItem value='false'>
            {t('false')}
          </MenuItem>
        </SelectField>
      );
    case ValueTypes.ACCOUNT_TYPES:
      return (
        <AccountTypeSelect value={rule.value} onChange={(e: any) => {
          const r = { ...rule, value: e };
          updateRule(r);
        }}
          label=''
          size='medium'
        />
      );
    case ValueTypes.JURISDICTION:
      return (
        <SelectField value={rule.value} fullWidth onChange={(e: any) => {
          const r = { ...rule, value: e };
          updateRule(r);
        }}
          label=''
        >
          {
            jurisdictions.map((jurisdiction: string) => (
              <MenuItem key={jurisdiction} value={jurisdiction}>{t(`geoNames:${jurisdiction}`)}</MenuItem>
            ))
          }
        </SelectField>
      );
    default:
      return null;
  }
};

export const Rule = ({
  rule, updateRule, availableRules, objectType, removeRule,
}: {
  rule: RuleDocument, updateRule: (rule: RuleDocument) => void, availableRules: RuleOptions[], objectType: ObjectType, removeRule: () => void,
}) => {
  const { t } = useTranslation(['ruleFields', 'components']);

  return (
    <Box display='flex' flexDirection='row' gap={1} width='100%'>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectField value={rule.field} onChange={(e: any) => {
            const r = { ...rule, field: e.target.value, value: '' };
            updateRule(r);
          }} label='' fullWidth>
            {
              availableRules.map((ruleOption) => (
                <MenuItem key={ruleOption.name} value={ruleOption.name}>
                  {ruleOption.name.split('.').map((x) => t(x)).join(' ')}
                </MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        <Grid item xs={4}>
          <SelectField value={rule.operator} onChange={(e: any) => {
            const r = { ...rule, operator: e.target.value };
            updateRule(r);
          }} label='' fullWidth>
            {
              availableOperators(
                ruleMapping[objectType]?.find((x: RuleOptions) => x.name === rule.field)?.valueType || ValueTypes.TEXT,
              ).map((operator) => (
                <MenuItem key={operator} value={operator}>
                  {t(`components:ruleBuilder.operators.${operator}`)}
                </MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <Field objectType={objectType} rule={rule} updateRule={updateRule} />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={removeRule}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
