import { isNil, round, sum } from 'lodash/fp';
import { useContext } from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import {
  TableCell,
  TableTotalCell,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { currencySymbol } from '../../../../../util/currency';
import { UserContext } from '../../../../../providers/userContextProvider';
import { Holding } from '../../../../../interfaces';

export const SecurityTotalHoldingCell = ({
  holdings, type, history, useCustodianData, missesFxRate, hasMultipleCurrencies, isFirstColumn,
}: {
  holdings: Holding[], type: string, history: any[], useCustodianData?: boolean, missesFxRate?: boolean, hasMultipleCurrencies?: boolean, isFirstColumn?: boolean,
}) => {
  const find1Day = (hold: Holding) => history[history.length - 1]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find3Day = (hold: Holding) => history[history.length - 3]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find1Week = (hold: Holding) => history[history.length - 7]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);
  const find1Month = (hold: Holding) => history[0]?.holdings?.find((h: Holding) => h.financialProduct?.id === hold.financialProduct?.id);

  const { t } = useTranslation(['client', 'accountTypes']);
  const { sys } = useThemeTokens();
  const { activeCurrency } = useContext(UserContext);

  const oneDayTotal = () => round(sum(holdings.filter((x: Holding) => !x.financialProduct?.isCash).map((a: Holding) => (a.totalCents ?? 0) - (useCustodianData
    ? (a.eodPriceCents ?? 0) * (a.quantity ?? 0)
    : (find1Day(a)?.totalCents ?? 0)))));
  const threeDayTotal = () => round(sum(holdings.map((a: Holding) => (a.totalCents ?? 0) - (useCustodianData ? (a.eodPriceCents ?? 0) * (a.quantity ?? 0) : (find3Day(a)?.totalCents ?? 0)))));
  const oneWeekTotal = () => round(sum(holdings.map((a: Holding) => (a.totalCents ?? 0) - (useCustodianData ? (a.eodPriceCents ?? 0) * (a.quantity ?? 0) : (find1Week(a)?.totalCents ?? 0)))));
  const oneMonthTotal = () => round(sum(holdings.map((a: Holding) => (a.totalCents ?? 0) - (useCustodianData ? (a.eodPriceCents ?? 0) * (a.quantity ?? 0) : (find1Month(a)?.totalCents ?? 0)))));

  switch (type) {
    case 'symbol':
    case 'name':
    case 'securityName':
    case 'quantity':
    case 'bookCostNative':
    case 'bookCost':
    case 'currentPriceNative':
    case 'currentPrice':
    case 'unrealizedGainLoss':
      return (isFirstColumn
        ? <TableTotalCell isFirst={isFirstColumn}>{t('accountTable.totalSum')}</TableTotalCell>
        : <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
      );
    case 'oneDayChange':
      return (
        missesFxRate ? (
          <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
        ) : (
          <TableTotalCell number isFirst={isFirstColumn} sx={{
            textAlign: 'right',
            color: oneDayTotal() === 0 ? sys.color.onSurface : oneDayTotal() >= 0 ? sys.color.positive : sys.color.negative,
            backgroundColor: '#FBFBFB',
            borderBottom: 'none',
          }}>
            {`${formatMoneyValue(oneDayTotal(), currencySymbol[activeCurrency as CurrencyCodes])}
            (${formatPercentValue(oneDayTotal() / sum(holdings.map((a: Holding) => ((a.totalCents ?? 0)))))})`
            }
          </TableTotalCell>
        )
      );
    case 'threeDayChange':
      return (
        missesFxRate ? (
          <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
        ) : (
          <TableTotalCell number isFirst={isFirstColumn} sx={{
            textAlign: 'right',
            color: threeDayTotal() === 0 ? sys.color.onSurface : threeDayTotal() >= 0 ? sys.color.positive : sys.color.negative,
            backgroundColor: '#FBFBFB',
            borderBottom: 'none',
          }}>
            {`${formatMoneyValue(threeDayTotal(), currencySymbol[activeCurrency as CurrencyCodes])}
            (${formatPercentValue(threeDayTotal() / sum(holdings.map((a: Holding) => ((a.totalCents ?? 0)))))})`}
          </TableTotalCell>
        )
      );
    case 'oneWeekChange':
      return (
        missesFxRate ? (
          <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
        ) : (
          <TableTotalCell number isFirst={isFirstColumn} sx={{
            textAlign: 'right',
            color: oneWeekTotal() === 0 ? sys.color.onSurface : oneWeekTotal() >= 0 ? sys.color.positive : sys.color.negative,
            backgroundColor: '#FBFBFB',
            borderBottom: 'none',
          }}>
            {`${formatMoneyValue(oneWeekTotal(), currencySymbol[activeCurrency as CurrencyCodes])}
            (${formatPercentValue(oneWeekTotal() / sum(holdings.map((a: Holding) => ((a.totalCents ?? 0)))))})`}
          </TableTotalCell>
        )
      );
    case 'oneMonthChange':
      return (
        missesFxRate ? (
          <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
        ) : (
          <TableTotalCell number isFirst={isFirstColumn} sx={{
            textAlign: 'right',
            color: oneMonthTotal() === 0 ? sys.color.onSurface : oneMonthTotal() >= 0 ? sys.color.positive : sys.color.negative,
            backgroundColor: '#FBFBFB',
            borderBottom: 'none',
          }}>
            {`${formatMoneyValue(oneMonthTotal(), currencySymbol[activeCurrency as CurrencyCodes])}
            (${formatPercentValue(oneMonthTotal() / sum(holdings.map((a: Holding) => ((a.totalCents ?? 0)))))})`}
          </TableTotalCell>
        )
      );
    case 'percentOfTotal':
      return (
        <TableTotalCell number isFirst={isFirstColumn} right>{!missesFxRate ? '100%' : '-'}</TableTotalCell>
      );
    case 'currentValue':
    case 'marketValueNative':
      return (
        hasMultipleCurrencies ? (
          <TableTotalCell isFirst={isFirstColumn}></TableTotalCell>
        ) : (
          <TableTotalCell right bold number isFirst={isFirstColumn}>
            {formatMoneyValue(sum(holdings.map((a: Holding) => (a?.originalCurrency?.totalCents ?? a.totalCents) ?? 0)), currencySymbol[activeCurrency as CurrencyCodes])}
          </TableTotalCell>
        )
      );
    case 'marketValue':
      return (
        <TableTotalCell right bold number>
          {holdings.every((a: Holding) => !isNil(a?.originalCurrency)) ? formatMoneyValue(sum(holdings.map((a: Holding) => a.totalCents)), currencySymbol[activeCurrency as CurrencyCodes]) : '-'}
        </TableTotalCell>
      );
    default:
      return (
        <TableCell>
          {type}
        </TableCell>
      );
  }
};
