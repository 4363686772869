import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ConfirmationModal } from '../../../../3-pattern';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { RelatedEntity, RelatedEntityInput, User } from '../../../../../interfaces';
import { generateClientNameString } from '../../../../../util';
import { FETCH_USER_RELATED_ENTITIES } from '../relationships';
import { UPDATE_USER_RELATED_ENTITIES } from './relatedEntityEdit';

export const RelatedEntityRemoveDialog = ({
  entityToRemove, existingRelatedEntities, user, onClose,
}: {
  entityToRemove: User, existingRelatedEntities: RelatedEntity[], user: User, onClose: () => void
}) => {
  const { t } = useTranslation('nonIndividualClients');
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const [updateUserRelatedEntities, { loading: deletePending }] = useMutation(UPDATE_USER_RELATED_ENTITIES, {
    refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
  });

  const deleteMember = () => {
    const updatedRelatedEntities:RelatedEntityInput[] = existingRelatedEntities
      .filter((relEnt:RelatedEntity) => relEnt.entity.id !== entityToRemove.id)
      .map((relEnt:RelatedEntity) => ({ entityId: relEnt.entity.id, relation: relEnt.relation }));

    updateUserRelatedEntities({
      variables: {
        userId: user.id,
        relatedEntities: updatedRelatedEntities,
      },
      refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
      onCompleted: () => {
        showToast({ severity: 'success', message: t('remove.hasBeenRemoved') });
        onClose();
      },
    });
  };

  return (
    <ConfirmationModal
      open={true}
      title={t('remove.areYouSure', { removedWhoName: generateClientNameString(entityToRemove) })}
      bodyText={t('remove.thisWillNotDelete', { removedWhoName: generateClientNameString(entityToRemove), removedFromName: generateClientNameString(user) })}
      onConfirm={deleteMember}
      onCancel={onClose}
      loading={deletePending}
    />
  );
};
