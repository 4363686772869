/* eslint-disable no-restricted-globals */
import { useReducer } from 'react';

/**
 * Page-specific stateful variable storage. Persistent over browser reloads/navigation.
 * All variables of all components are two-way bound to page URL (#hash).
 *
 * Returns a stateful value, and a function to update it.
 *
 * Example URL with three variables stored in it:
 * ```
 * http://example.org/some-application#page:5,q:john%20doe,investMore
 *                                     ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 * ```
 *
 * @param initialValue sets the variable's value when there's no `key` in the URL
 * @param key the URL hash key
 *
 * @example
 *  const [page, setPage] = usePageState(1, 'page');
 *  const [searchQuery, setSearchQuery] = usePageState('', 'q');
 *  const [investMore, setInvestMore] = usePageState(false, 'investMore');
 *
 *  setPage(5);
 *  setSearchQuery('john doe');
 *  setInvestMore(true);
 */
export function usePageState(initialValue: boolean, key: string): [boolean, (newValue:boolean) => void];
export function usePageState(initialValue: number, key: string): [number, (newValue:number) => void];
export function usePageState(initialValue: string, key: string): [string, (newValue:string) => void];
export function usePageState<T extends string | undefined>(initialValue: T, key: string): [T, (newValue:T) => void];
export function usePageState<T>(initialValue: T, key: string):[ T, (newValue:T) => void] {
  if (typeof location === 'undefined') {
    throw new Error('The usePageState() hook only works within a browser');
  }
  const searchParams = new URLHashParams(location.hash.slice(1));
  const storedValue:T = searchParams.get(key, typeof (initialValue)) as T;
  const currentValue:T = storedValue !== undefined ? storedValue : initialValue;

  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

  const setFn = (newValue:T):void => {
    const params = new URLHashParams(location.hash.slice(1));
    if (newValue === initialValue) params.delete(key);
    else params.set(key, newValue);

    const serializedParams = params.toString();

    const noChange = (location.hash === '' && serializedParams === '')
      || location.hash === `#${serializedParams}`;

    if (noChange) return;

    if (serializedParams === '') {
      history.replaceState(null, '', ' '); // remove lonely '#' from URL
    } else {
      location.replace(`#${serializedParams}`);
    }
    forceUpdate();
  };

  return [currentValue, setFn];
}

class URLHashParams {
  storage: Record<string, string | null> = {};

  /* decompose: URI hash string -> chunks */
  constructor(urlHash:string) {
    urlHash.split(',').forEach((chunk:string) => {
      if (!chunk) return;
      const [k, v] = chunk.split(':');
      this.storage[k] = v !== undefined ? v : null;
    });
  }

  /* compose: chunks -> URI hash string */
  toString():string {
    const chunks:string[] = [];
    for (const [k, v] of Object.entries(this.storage)) {
      if (v !== null) chunks.push(`${k}:${v.toString()}`);
      else chunks.push(k);
    }
    return chunks.join(',');
  }

  /* serialize one number, string, or boolean */
  set(key: string, value: any) {
    if (typeof (value) === 'boolean') {
      if (value) this.storage[key] = null;
      else this.storage[key] = 'false';
    } else if (typeof (value) === 'number') {
      this.storage[key] = value.toString();
    } else if (typeof (value) === 'string') {
      this.storage[key] = encodeURIComponent(value);
    } else if (typeof (value) === 'undefined') {
      delete this.storage[key];
    }
  }

  /* deserialize one variable */
  get(key: string, asType: string): boolean | number | string | undefined {
    if (this.storage[key] === undefined) return undefined;

    if (asType === 'boolean') return this.storage[key] === null;
    if (asType === 'number') return Number(this.storage[key]);
    if (asType === 'string') return decodeURIComponent(this.storage[key] ?? '');
    if (asType === 'undefined') {
      if (this.storage[key] !== null) return decodeURIComponent(this.storage[key] ?? '');
      return undefined;
    }

    return undefined;
  }

  delete(key: string) {
    delete this.storage[key];
  }
}
