import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const FETCH_CLIENT_GROUP_NAME = gql`
  query fetchClientGroupName($id:ObjectID!) {
    fetchClientGroup(clientGroupId:$id) {
      clientGroup { id name type }
    }
  }
`;

const ClientGroupLabel = ({ clientGroupId }: { clientGroupId: string }) => {
  const { t } = useTranslation(['client']);
  const { data } = useQuery(FETCH_CLIENT_GROUP_NAME, { variables: { id: clientGroupId } });
  const clientGroup = data?.fetchClientGroup?.clientGroup;
  const name = clientGroup?.name || (clientGroup?.type ? t(`client:clientGroupType.${clientGroup.type}`) : '');
  return <>{t('clientGroup')}:&nbsp;{name}</>;
};

export default ClientGroupLabel;
