import { gql, useQuery } from '@apollo/client';
import {
  Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Table, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatsBlock from '../../../components/misc/statsBlock';
import TrueFalse from '../../../components/misc/trueFalse';
import { formatMoneyValue } from '../../../util';

const FETCH_REBALANCE_HOLDINGS = gql`
  query fetchRebalanceHoldingReports($input: FetchRebalanceHoldingReportsInput!) {
    fetchRebalanceHoldingReports(input: $input) {
      rebalanceHoldingReports {
        ticker
        quantity
        valueCents
        expectedPercentage
        expectedValueCents
        driftRebalanceRequired
        liquidateRebalanceRequired
        lastReconciledAt
      }
    }
  }
`;

const ReportModal = ({ open, handleClose, item }: { open: boolean, handleClose: () => void, item: any }) => {
  const { t } = useTranslation(['rebalanceReportV1']);
  const { loading, data } = useQuery(FETCH_REBALANCE_HOLDINGS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: { subAccountId: item.subAccount.id },
        pagination: {
          sortField: 'id', sortDesc: false, perPage: 100, offSet: 0,
        },
      },
    },
  });

  const percentDiff = (x: any) => {
    if (x.expectedValueCents === 0) {
      return '-100.00';
    }
    return (((x.expectedValueCents - x.valueCents) / x.expectedValueCents) * 100).toFixed(2);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>
      { item && (
          <>
            <Typography variant='h6'>{item.user.firstName} {item.user.lastName} - {item.account.type}</Typography>
            <Typography variant='subtitle1'>{item.subAccount.financialProduct.name}</Typography>
            <Grid container justifyContent='space-evenly'>
              <Grid item>
                <StatsBlock title={t('cash')} value={formatMoneyValue(item.cashCents)}/>
              </Grid>
              <Grid item>
                <StatsBlock title={t('expectedCash')} value={formatMoneyValue(item.expectedCashCents)}/>
              </Grid>
            </Grid>
          </>
      ) }
      </DialogTitle>
      <DialogContent>

        { loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
        ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='overline'>{t('holdingTable.ticker')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.currentQuantity')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.currentValue')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.expectedValue')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.differenceRequired')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.driftTrigger')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('holdingTable.liquidationTrigger')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          { data?.fetchRebalanceHoldingReports.rebalanceHoldingReports.filter((x: any) => x.expectedValueCents !== 0 || x.valueCents !== 0).map((x: any) => (
            <TableRow>
              <TableCell>{x.ticker}</TableCell>
              <TableCell>{x.quantity}</TableCell>
              <TableCell>{formatMoneyValue(x.valueCents)} ({((x.valueCents / item.marketValueCents) * 100).toFixed(2)}%)</TableCell>
              <TableCell>{formatMoneyValue(x.expectedValueCents)} ({(x.expectedPercentage * 100).toFixed(2)}%)</TableCell>
              <TableCell>{formatMoneyValue(x.expectedValueCents - x.valueCents)} ({percentDiff(x)}%)</TableCell>
              <TableCell><TrueFalse check={x.driftRebalanceRequired} /></TableCell>
              <TableCell><TrueFalse check={x.liquidateRebalanceRequired} /></TableCell>
            </TableRow>
          )) }
        </Table>
        ) }
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
