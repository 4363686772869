/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import {
  Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Box, Menu, MenuItem, IconButton, Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { ObjectType } from '../../../providers/statsHooks';
import { formatMoneyValue } from '../../../util';
import SubAccountsTable from '../../../components/tables/subAccountsTable';
import { Account, AccountStates, AccountStates as AccountStatesIndex } from '../../../interfaces';
import EditAccountNumber from './editAccountNumber';
import StateChangesModal from '../../../components/modals/stateChangesModal';
import { usePageState } from '../../../util/usePageState';
import { CreateSubAccountWizardButton } from '../../../components/wizards/createSubAccountWizard/button';

export const TRANSITION_ACCOUNT = gql`
  mutation transitionAccount($input: TransitionAccountInput!) {
    transitionAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const MUTATION_TLH_ENABLE_ON_ACCOUNT = gql`
mutation tlhEnableOnAccount($accountId: ObjectID!) {
  updateAccount(input:{ accountId: $accountId, tlhEnabled: true })
  {
    account { tlhEnabled }
  }
}`;

const MUTATION_TLH_DISABLE_ON_ACCOUNT = gql`
mutation tlhDisableOnAccount($accountId: ObjectID!) {
  updateAccount(input:{ accountId: $accountId, tlhEnabled: false })
  {
    account { tlhEnabled }
  }
}`;

const Accounts = ({
  accounts, setObject, defaultObject, object, refetch,
}: {
  accounts: Account[], setObject: any, defaultObject: { type: ObjectType, id: string }, object: any, refetch: () => void,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['client', 'shared']);
  const { activeOrganization } = useContext(UserContext);
  const [transitionAccount] = useMutation(TRANSITION_ACCOUNT);
  const [tlhEnableOnAccount] = useMutation(MUTATION_TLH_ENABLE_ON_ACCOUNT);
  const [tlhDisableOnAccount] = useMutation(MUTATION_TLH_DISABLE_ON_ACCOUNT);
  const [editOpen, setEditOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = usePageState<string | undefined>(undefined, 'acct');

  const transitions = [
    {
      name: 'activate',
      displayName: t('accountDetails.transitions.active'),
      from: [AccountStates.REQUESTED, AccountStates.FROZEN],
    },
    {
      name: 'request',
      displayName: t('accountDetails.transitions.request'),
      from: [AccountStates.INITIATED],
    },
    {
      name: 'fail',
      displayName: t('accountDetails.transitions.fail'),
      from: [AccountStates.REQUESTED],
    },
    {
      name: 'cancel',
      displayName: t('accountDetails.transitions.cancel'),
      from: [AccountStates.INITIATED, AccountStates.REQUESTED],
    },
    {
      name: 'freeze',
      displayName: t('accountDetails.transitions.freeze'),
      from: [AccountStates.INITIATED, AccountStates.ACTIVE, AccountStates.REQUESTED],
    },
  ];
  const handleChange = (accountId: string) => {
    if (expanded !== accountId) {
      setExpanded(accountId);
      setObject({ type: ObjectType.ACCOUNT, id: accountId });
    } else {
      setExpanded(undefined);
      setObject(defaultObject);
    }
  };
  const [activeAccount, setActiveAccount] = useState<Account>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const defineStatusColor = (state: AccountStatesIndex | undefined): 'error' | 'success' | 'warning' => {
    if (state === AccountStatesIndex.ACTIVE) {
      return 'success';
    } if (state === AccountStatesIndex.REQUESTED || state === AccountStatesIndex.INITIATED) {
      return 'warning';
    }
    return 'error';
  };
  const disableTaxLossHarvesting = () => {
    tlhDisableOnAccount({ variables: { accountId: activeAccount?.id } });
    refetch();
  };
  const enableTaxLossHarvesting = () => {
    tlhEnableOnAccount({ variables: { accountId: activeAccount?.id } });
    refetch();
  };
  useEffect(() => function cleanupOnUnmount() { setExpanded(undefined); }, []);

  return (
    <>
      {accounts.map((account: Account) => (
        <Accordion key={account.id} expanded={expanded === account.id} onChange={(e) => handleChange(account.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container justifyContent='space-between' sx={{ marginRight: 2 }}>
              <Grid item>
                <Typography variant='h6'>
                  {account.type === 'PERSONAL' ? t('cash') : account.type}
                  &nbsp;
                  <Chip size='small' color={defineStatusColor(account.state)} label={account.state}/>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justifyContent='space-between' sx={{ marginRight: 2 }}>
                  <Grid item>
                    <Typography variant='h6'>{formatMoneyValue(account?.stats?.marketValueCents)}</Typography>
                  </Grid>
                  <>
                    <IconButton
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setActiveAccount(account);
                        handleClick(event);
                      }}
                      size='small'
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box sx={{ p: 2 }}>
              {permissions.includes('read:account_number') && (
                <>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <Typography variant='overline' sx={{ textTransform: 'none' }}><b>ID:</b> {account.id}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant='overline' ><b>{t('accountNumber')}:</b> {account.custodianAccountNumber || 'N/A'}</Typography>
                    </Grid>
                    { account.type === 'PERSONAL'
                    && <Grid item xs={12} lg={4}>
                      <Typography variant='overline'>
                        <b>tax-loss harvesting:</b> { account.tlhEnabled
                          ? <Chip size='small' variant='outlined' color='success' label={t('shared:enabled')}/>
                          : <Chip size='small' variant='outlined' color='error' label={t('shared:disabled')}/>
                        }
                      </Typography>
                    </Grid>
                    }
                  </Grid>
                </>
              )}
            </Box>
            {permissions.includes('read:account_basic') && (
              <SubAccountsTable
                object={object}
                setObject={setObject}
                firstColumn='goal'
                subAccounts={account.subAccounts ?? []}
                refetch={refetch}
                actionElement={
                  permissions.includes('write:account_basic') ? (
                    <CreateSubAccountWizardButton forObject='ACCOUNT' forId={account.id} />
                  ) : undefined
                }
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {permissions.includes('write:account_number') && (<MenuItem
          key='updateAccNum'
          onClick={() => {
            setEditOpen(true);
          }}>
          {t('accountDetails.updateAccNo')}
        </MenuItem>)}
        <MenuItem
          key='viewStatus'
          onClick={async (e: any) => {
            setModalOpen(true);
          }}>
          {t('accountDetails.viewStates')}
        </MenuItem>
        {permissions.includes('transition:account') && transitions.map((transition: any) => (
          transition.from.includes(activeAccount?.state) && (transition.name !== 'activate' || activeAccount?.custodianAccountNumber) && (
            <MenuItem key={transition.name} onClick={async (e) => {
              await transitionAccount({ variables: { input: { accountId: activeAccount?.id, transition: transition.name } } });
              setModalOpen(false);
              refetch();
            }}>
              {transition.displayName}
            </MenuItem>
          )
        ))}
        {(activeOrganization.availableFeatureFlags?.includes('TAX_LOSS_HARVESTING') && activeAccount?.type === 'PERSONAL') && (
          activeAccount?.tlhEnabled
            ? <MenuItem key="disableTaxLossHarvesting" onClick={disableTaxLossHarvesting}>
            {t('shared:disable')} {t('taxLossHarvesting')}
          </MenuItem>
            : <MenuItem key="enableTaxLossHarvesting" onClick={enableTaxLossHarvesting}>
            {t('shared:enable')} {t('taxLossHarvesting')}
          </MenuItem>
        )}
      </Menu>
      <EditAccountNumber
        accountId={activeAccount?.id}
        accNumber={activeAccount?.custodianAccountNumber || ''}
        afterCreate={() => {
          setEditOpen(false);
          refetch();
        }}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
      />
      <StateChangesModal
        id={activeAccount?.id || ''}
        open={modalOpen}
        title={t('accountDetails.transitions.title')}
        handleClose={() => {
          setModalOpen(false);
        }}
        stateChanges={activeAccount?.stateMachineChanges || []}
      />

    </>
  );
};

export default Accounts;
