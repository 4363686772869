import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import { merge } from 'lodash';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useAlertTokens } from './alert.tokens';

interface AlertProps extends MuiAlertProps {
  comp?: any,
}

export const Alert = (props: AlertProps): JSX.Element => {
  const variant = props.variant || 'standard';
  const severity = props.severity || 'info';

  const { comp: compToken } = useThemeTokens(useAlertTokens());
  const comp = merge(compToken, props.comp);

  const internalSx = {
    ...comp?.alert[variant][severity],
    '& .MuiAlert-icon': {
      color: comp?.alert[variant][severity].color,
    },
  };

  const combinedSx = props.sx ? merge(props.sx, internalSx) : internalSx;

  return (
    <MuiAlert
    sx={combinedSx}
      {...props}>
      {props?.children || ''}
    </MuiAlert>
  );
};
