import i18n from 'assets/i18n/config';
import employmentInfo from 'assets/i18n/en/employmentInfo.json';
import { CountryCodes } from '@onevesthq/ov-enums';
import { Account } from './account';
import { Goal, GoalRiskLevels } from './goal';
import { Organization } from './organization';
import { Statistics } from './stats';
import { SubAccount } from './subAccount';
import { IdVerification } from './IdVerification';
import { FeeTier } from './feeTier';
import { BillingSchedule } from './billingSchedule';
import { ForeignTaxInformation } from './foreignTaxInformation';
import { FraudRisk } from './fraudRisk';
import { BankAccount } from './bankAccount';
import { ClientGroup } from './clientGroup';
import { PhysicalAddress } from './physicalAddress';
import { OrganizationUser, OrganizationUserEntityRelationTypes } from './organizationUser';
import { IncompleteFormAgreement } from './formAgreements';

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED',
  STUDENT = 'STUDENT',
  RETIRED = 'RETIRED',
}

export enum UserStates {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FROZEN = 'FROZEN',
  NEEDS_UPDATE = 'NEEDS_UPDATE',
}

export enum Languages {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
}

export enum DebtAssociated {
  STUDENT_LOAN = 'STUDENT_LOAN',
  MORTGAGE = 'MORTGAGE',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum InvestmentKnowledge {
  EXPERT = 'EXPERT',
  SOME = 'SOME',
  LIMITED = 'LIMITED',
  NONE = 'NONE',
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  COMMON_LAW = 'COMMON_LAW',
  DIVORCED = 'DIVORCED',
  SEPARATED = 'SEPARATED',
  WIDOWED = 'WIDOWED',
  CIVIL_UNION = 'CIVIL_UNION',
}

export enum TrustedContactRelationship {
  CHILD = 'CHILD',
  FRIEND = 'FRIEND',
  GRANDPARENT = 'GRANDPARENT',
  OTHER = 'OTHER',
  PARENT = 'PARENT',
  SIBLING = 'SIBLING',
  SPOUSE = 'SPOUSE',
}

export enum ComplianceStates {
  APPROVED = 'APPROVED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REJECTED = 'REJECTED',
}

export enum MethodOfCommunication {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  TEXT = 'TEXT',
  OTHER = 'OTHER',
}

export enum EntityTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  PUBLICLY_LISTED_ENTITY = 'PUBLICLY_LISTED_ENTITY',
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  TRUST = 'TRUST',
  INVESTMENT_FUND = 'INVESTMENT_FUND',
  REGULATED_ENTITY = 'REGULATED_ENTITY',
  OTHER = 'OTHER',
}

export type RelatedEntity = {
  entity: User;
  relation: RelatedEntityTypes;
};

export type RelatedEntityInput = {
  entityId: string;
  relation: RelatedEntityTypes;
};

/* keep this synchronized with user-service */
export enum RelatedEntityTypes {
  AuthorizedIndividual = 'AUTHORIZED_INDIVIDUAL',
  BeneficialOwner = 'BENEFICIAL_OWNER',
  Child = 'CHILD',
  Friend = 'FRIEND',
  Grandchild = 'GRANDCHILD',
  Grandparent = 'GRANDPARENT',
  Grantor = 'GRANTOR',
  NieceNephew = 'NIECE_NEPHEW',
  Other = 'OTHER',
  Parent = 'PARENT',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Protector = 'PROTECTOR',
  Settlor = 'SETTLOR',
  Sibling = 'SIBLING',
  Spouse = 'SPOUSE',
  ThirdParty = 'THIRD_PARTY',
  TrustedContact = 'TRUSTED_CONTACT',
  Trustee = 'TRUSTEE',
  Director = 'DIRECTOR',
}

export enum SourceOfFunds {
  CONTRIBUTIONS = 'CONTRIBUTIONS',
  DEBT = 'DEBT',
  DONATIONS = 'DONATIONS',
  EMPLOYMENT_INCOME = 'EMPLOYMENT_INCOME',
  INCOME_OR_WORKING_CAPITAL = 'INCOME_OR_WORKING_CAPITAL',
  INHERITANCES_AND_GIFT = 'INHERITANCES_AND_GIFT',
  LOTTERY_WINNING = 'LOTTERY_WINNING',
  OTHER = 'OTHER',
  PENSION_RELEASE = 'PENSION_RELEASE',
  PERSONAL_SAVINGS = 'PERSONAL_SAVINGS',
  PROCEEDS_FROM_INVESTMENTS = 'PROCEEDS_FROM_INVESTMENTS',
  PROPERTY_SALE = 'PROPERTY_SALE',
  SALE_OF_LARGE_ASSET = 'SALE_OF_LARGE_ASSET',
  SHARES_SALES_AND_DIVIDENDS = 'SHARES_SALES_AND_DIVIDENDS',
}

export enum FATCAStatus {
  PUBLICLY_TRADED_NFFE = 'PUBLICLY_TRADED_NFFE',
  INTERNATIONAL_ORGANIZATION = 'INTERNATIONAL_ORGANIZATION',
  GOVERNMENT = 'GOVERNMENT',
  CENTRAL_BANK = 'CENTRAL_BANK',
  ACTIVE_NFFE = 'ACTIVE_NFFE',
  PASSIVE_NFFE = 'PASSIVE_NFFE',
  OTHER = 'OTHER',
}

export enum TaxIdTypes {
  SSN = 'SSN',
  ITIN = 'ITIN',
  EIN = 'EIN',
  SIN = 'SIN',
  BN = 'BN',
}

export const getRelatedEntityTypes = (entityType: EntityTypes | string): RelatedEntityTypes[] => {
  let candidateRelatedEntityTypes: RelatedEntityTypes[];
  switch (entityType) {
    case EntityTypes.INDIVIDUAL:
      candidateRelatedEntityTypes = [
        RelatedEntityTypes.Spouse,
        RelatedEntityTypes.Child,
        RelatedEntityTypes.Parent,
        RelatedEntityTypes.Sibling,
        RelatedEntityTypes.Grandchild,
        RelatedEntityTypes.Grandparent,
        RelatedEntityTypes.NieceNephew,
        RelatedEntityTypes.Friend,
        RelatedEntityTypes.PowerOfAttorney,
        RelatedEntityTypes.ThirdParty,
        RelatedEntityTypes.TrustedContact,
        RelatedEntityTypes.Other,
      ];
      break;
    case EntityTypes.TRUST:
      candidateRelatedEntityTypes = [
        RelatedEntityTypes.Trustee,
        RelatedEntityTypes.BeneficialOwner,
        RelatedEntityTypes.Grantor,
        RelatedEntityTypes.Protector,
        RelatedEntityTypes.Settlor,
        RelatedEntityTypes.ThirdParty,
        RelatedEntityTypes.PowerOfAttorney,
        RelatedEntityTypes.TrustedContact,
      ];
      break;
    case EntityTypes.INVESTMENT_FUND:
    case EntityTypes.PARTNERSHIP:
    case EntityTypes.PRIVATE_COMPANY:
    case EntityTypes.PUBLICLY_LISTED_ENTITY:
    case EntityTypes.REGULATED_ENTITY:
    case EntityTypes.SOLE_PROPRIETORSHIP:
    case EntityTypes.OTHER:
      candidateRelatedEntityTypes = [
        RelatedEntityTypes.Director,
        RelatedEntityTypes.AuthorizedIndividual,
        RelatedEntityTypes.BeneficialOwner,
        RelatedEntityTypes.ThirdParty,
        RelatedEntityTypes.PowerOfAttorney,
      ];
      break;
    default:
      candidateRelatedEntityTypes = Object.values(RelatedEntityTypes);
  }
  return candidateRelatedEntityTypes;
};

export interface EntityOrganizationUser {
  organizationUser?: OrganizationUser,
  readOnly?: boolean,
  relation?: OrganizationUserEntityRelationTypes,
}

export interface User {
  id: string
  type: EntityTypes,
  firstName?: string
  middleName?: string
  lastName?: string
  entityName?: string
  primaryEmail?: string
  phone?: string
  dateOfBirth?: string
  establishedDate?: string
  createdAt?: string
  completedAt?: string
  suitabilityScore?: number
  maritalStatus?: MaritalStatus
  numberOfDependents?: number
  employmentStatus?: EmploymentStatus
  jobTitle?: string
  companyType?: string
  studentAreaOfStudy?: string
  sin?: string
  isOfficerOfPublicCompany?: boolean
  isOwnerOfPublicCompany?: boolean
  isMemberOfIiroc?: boolean
  politicallyExposedForeignPerson?: boolean
  politicallyExposedDomesticPerson?: boolean
  headOfInternationalOrganization?: boolean
  closeAssociateOfPEP?: boolean
  complianceState?: ComplianceStates
  complianceIssueSource?: string
  annualIncomeCents?: number
  annualIncomeStr?: string
  financialLiquidAssetsCents?: number
  financialFixedAssetsCents?: number
  totalDebtCents?: number
  annualDebtPaymentsCents?: number
  isAccreditedInvestor?: boolean
  investmentKnowledge?: InvestmentKnowledge
  riskQuestion1?: GoalRiskLevels
  riskQuestion2?: GoalRiskLevels
  statistics?: Statistics
  custodianStatistics?: Statistics
  subAccounts: SubAccount[]
  accounts: Account[]
  goals: Goal[]
  organization: Organization
  physicalAddress: any
  iDVerified?: boolean
  iDCheckCompleted?: boolean,
  iDCheckRetrigger?: boolean,
  affiliateOnly?: boolean
  state: UserStates,
  language?: Languages,
  preferredMethodOfCommunication?: MethodOfCommunication,
  latestIdVerification?: IdVerification,
  feeTier?: FeeTier,
  applicableFeeTier?: FeeTier,
  billingSchedule?: BillingSchedule,
  applicableBillingSchedule?: BillingSchedule,
  citizenships?: string[]
  countryOfTaxResidence?: CountryCodes,
  foreignTaxInformation?: ForeignTaxInformation[]
  lastSuitabilityReviewAt?: string
  incompleteFields?: string[]
  ssSymbols?: string[]
  insiderSymbols?: string[]
  powerOfAttorneyGranted?: boolean
  fraudRisk?: FraudRisk
  incompleteFormAgreements?: IncompleteFormAgreement[],
  allIncompleteFormAgreements?: IncompleteFormAgreement[],
  trustedContactPerson?: {
    name: string,
    email: string,
    phone: string,
    physicalAddress: PhysicalAddress,
    relation: TrustedContactRelationship,
  },
  bankAccounts?: BankAccount[]
  households?: ClientGroup[],
  employerName?: string,
  employmentSituation?: string,
  employmentSituationOtherDescription?: string,
  employerCompanyAddress?: PhysicalAddress,
  sourceOfWealth?: string[],
  sourceOfFunds?: string[],
  uniqueCircumstances?: string,
  sourceOfFundsOtherDescription?: string,
  sourceOfWealthOtherDescription?: string,
  readyToSignAgreement?: boolean,
  spouseSin?: string,
  spouseDateOfBirth?: string,
  spouseFirstName?: string,
  spouseMiddleName?: string,
  spouseLastName?: string,
  spouseJobTitle?: string,
  spouseCompanyType?: string,
  spouseEmploymentType?: string,
  spouseEmployerName?: string,
  spouseEmail?: string,
  spouseAddress?: PhysicalAddress,
  spouseTaxId?: string,
  spouseTaxIdType?: TaxIdTypes,
  spouseTaxIdExists?: boolean,
  inProvinceSince?: string,
  taxId?: string,
  taxIdType?: TaxIdTypes,
  taxIdExists?: boolean,
  sinExists?: boolean,
  relatedEntities?: RelatedEntity[],
  countryOfRegistration?: string
  registrationNumber?: string,
  charityRegistrationNumber?: string,
  taxNumber?: string,
  yearEnd?: string,
  isNonProfit?: boolean,
  receivesDonationsFromPublic?: boolean,
  isRegisteredWithCRA?: boolean,
  isEntityRegulated?: boolean,
  regulatorName?: string,
  firmNumber?: string,
  isReportingIssuer?: boolean
  issuerName?: string,
  issuerTicker?: string,
  issuerExchange?: string
  isAFinancialEntity?: boolean,
  isAffiliateOfFinancialEntity?: boolean,
  instructionsFromFinancialEntity?: boolean,
  isPublicBody?: boolean,
  isLargeCorporation?: boolean,
  isForThirdParty?: boolean,
  gender?: string,
  accessibleBy?: EntityOrganizationUser[],
  isVulnerablePerson?: boolean,
  isOwnerOfEntity?: boolean,
  fatcaStatus?: FATCAStatus,
  fatcaStatusOtherDescription?: string,
}

interface EmploymentDetail {
  id: string,
  title: string,
}

export type Profession = EmploymentDetail;
export type JobTitle = EmploymentDetail;
export type AreaOfStudy = EmploymentDetail;

export const getProfessions = (): Profession[] => {
  const professionsData = employmentInfo.professionOptions;

  return Object.keys(professionsData).map((professionId: string): Profession => ({
    id: professionId,
    title: i18n.t(`employmentInfo:professionOptions.${professionId}.title`),
  }));
};

export const getJobTitles = (
  {
    professionId,
    professionTitle,
  }: {
    professionId?: keyof typeof employmentInfo.professionOptions,
    professionTitle?: string,
  },
): JobTitle[] => {
  let jobs: JobTitle[] = [];
  // Return jobs list filtered in the following sequence:
  // professionId > professionTitle > All Jobs
  const professionKey = professionId
    ?? Object.keys(employmentInfo.professionOptions).find((key) => employmentInfo.professionOptions[key as keyof typeof employmentInfo.professionOptions].title === professionTitle)
    ?? undefined;
  if (professionKey) {
    const professionJobs = employmentInfo.professionOptions?.[(professionKey as keyof typeof employmentInfo.professionOptions)]?.titles;
    jobs = Object.keys(professionJobs).map((jobId: string): JobTitle => ({
      id: jobId,
      title: i18n.t(`employmentInfo:professionOptions.${professionKey}.titles.${jobId}`),
    }));
  } else {
    // If no profession info is provided, return all job titles available across all professions
    jobs = Object.keys(employmentInfo.professionOptions).reduce(
      (acc: JobTitle[], curProfessionId: string): JobTitle[] => {
        const curProfessionJobs = employmentInfo.professionOptions?.[(curProfessionId as keyof typeof employmentInfo.professionOptions)]?.titles;
        acc.push(
          ...Object.keys(curProfessionJobs).map((jobId: string): JobTitle => ({
            id: jobId,
            title: i18n.t(`employmentInfo:professionOptions.${curProfessionId}.titles.${jobId}`),
          })),
        );
        return acc;
      },
      [],
    );
  }
  return jobs;
};

export const getAreasOfStudy = (): AreaOfStudy[] => {
  const areasOfStudyData = employmentInfo.areasOfStudy;

  return Object.keys(areasOfStudyData).map((areaOfStudyId: string): AreaOfStudy => ({
    id: areaOfStudyId,
    title: i18n.t(`employmentInfo:areasOfStudy.${areaOfStudyId}`),
  }));
};

export const SUITABILITY_REVIEW_VALIDITY_MONTHS = 12;

export const encryptedTaxIdPlaceholder = (taxIdType?: TaxIdTypes): string => taxIdFormattingMask(taxIdType).replaceAll('#', '*');

export const taxIdFormattingMask = (taxIdType?: TaxIdTypes): string => {
  if (taxIdType === TaxIdTypes.SIN || taxIdType === TaxIdTypes.BN) return '### ### ###';
  if (taxIdType === TaxIdTypes.SSN || taxIdType === TaxIdTypes.ITIN) return '###-##-#####';
  if (taxIdType === TaxIdTypes.EIN) return '##-########';
  return '#########';
};

export const taxIdTypeFormatType = (type?: TaxIdTypes): '3-3-3' | '3-2-4' | '2-7' | undefined => {
  if (type === TaxIdTypes.SIN || type === TaxIdTypes.BN) return '3-3-3';
  if (type === TaxIdTypes.SSN || type === TaxIdTypes.ITIN) return '3-2-4';
  if (type === TaxIdTypes.EIN) return '2-7';
  return undefined;
};

export const eligibleTaxIdTypes = (country: CountryCodes, isIndividual: boolean): TaxIdTypes[] => {
  let types: TaxIdTypes[] = [];

  if (country === CountryCodes.CA) {
    types = isIndividual ? [TaxIdTypes.SIN] : [TaxIdTypes.BN];
  } else if (country === CountryCodes.US) {
    types = isIndividual ? [TaxIdTypes.SSN, TaxIdTypes.ITIN] : [TaxIdTypes.SSN, TaxIdTypes.ITIN, TaxIdTypes.EIN];
  }

  return types;
};
