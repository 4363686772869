import AddBankAccountVisual from './addBankAccountVisual';

export interface InputProp {
  hasError: boolean,
  message: string,
  value: string,
}

const AddBankAccount = ({
  afterCreate, userId, open, handleClose, bankAccounts, transactionType, options,
}: {
  afterCreate: (newBankAccountId: string) => void,
  userId: string,
  open: boolean,
  handleClose: () => void,
  bankAccounts: { id: string; name: string; bankAccountNumber: string; }[],
  transactionType?: 'DEPOSIT' | 'WITHDRAWAL',
  options?: any
}) => (
  <AddBankAccountVisual
    userId={userId}
    afterCreate={afterCreate}
    handleClose={handleClose}
    open={open}
    bankAccounts={bankAccounts}
    transactionType={transactionType}
    options={options}
  />
);

export default AddBankAccount;
