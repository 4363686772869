import {
  TextField, ListItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryCodes } from '@onevesthq/ov-enums';
import CreateNewModal from '../../../components/modals/createNewModal';
import { PhysicalAddress } from '../../../interfaces/physicalAddress';
import AddressField from '../../../components/inputs/addressField';
import { FETCH_INSTITUTIONS } from '../../../components/inputs/externalInstitutionSelect';
import { FETCH_AWAITING_INSTIUTIONS_COUNT } from '../../../providers/globalStatsHooks';

export const CREATE_INSTITUTION = gql`
  mutation createInstitution($input: CreateInstitutionInput!) {
    createInstitution(input: $input) {
      institution {
        id
        name
      }
    }
  }
`;

const NewInstitution = () => {
  const { t } = useTranslation(['institutionsReview']);
  const [institutionName, setInstitutionName] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState<PhysicalAddress>({
    city: '',
    country: CountryCodes.CA,
    houseNumber: '',
    neighborhood: '',
    postal: '',
    province: '',
    streetName: '',
    unitNumber: '',
  });
  const [createInstitution, options] = useMutation(CREATE_INSTITUTION, {
    variables: {
      input: {
        isDraft: true,
        name: institutionName,
        physicalAddress,
      },
    },
    refetchQueries: [FETCH_INSTITUTIONS, FETCH_AWAITING_INSTIUTIONS_COUNT],
  });
  const [initialState] = useState({
    institutionName: '',
    physicalAddress,
  });
  const onCreate = async (event: any) => {
    await createInstitution();
    setInstitutionName('');
    setPhysicalAddress({
      city: '',
      country: CountryCodes.CA,
      houseNumber: '',
      neighborhood: '',
      postal: '',
      province: '',
      streetName: '',
      unitNumber: '',
    });
  };
  const state = {
    institutionName,
    physicalAddress,
  };
  return (
    <CreateNewModal
      initialState={initialState}
      state={state}
      loading={options.loading} disabled={options.loading || !institutionName} title={t('institutionsReview:addModal.title')} onSubmit={onCreate} sx={{ float: 'right', m: 1 }}>
      <ListItem>
        <TextField
          value={institutionName}
          label={t('institutionsReview:filters.name')}
          fullWidth
          onChange={(e) => setInstitutionName(e.target.value)}
          size='medium'
          data-testid="institution-name"
          sx={{ marginBottom: '20px', marginTop: '10px' }}
        />
      </ListItem>
      <ListItem>
        <AddressField address={physicalAddress} label="Address" onChange={(e) => setPhysicalAddress(e)} />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewInstitution;
