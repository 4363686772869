import {
  Typography, Card, CardContent, Grid, TableHead, Table, TableCell, TableRow, TextField, InputAdornment, Button, IconButton, Chip, TableBody,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { round } from 'lodash';
import { mapValues, sum, groupBy } from 'lodash/fp';
import TranslatableString from '../../../components/inputs/translatableString';
import AssetClassSelect from '../../../components/inputs/assetClassSelect';
import { usePermissions } from '../../../providers/userContextProvider';
import { Branch, FinancialProduct, TargetAllocation } from '../../../interfaces';
import ResignIPS from './resignIPS';

const UPDATE_MODEL = gql`
  mutation updateModelPortfolio($input: UpdateModelPortfolioInput!) {
    updateModelPortfolio(input: $input) {
      modelPortfolio {
        id
      }
    }
  }
`;

const EditIPS = ({ model, setModel }: { model: FinancialProduct, setModel: (model: FinancialProduct) => void }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('buildModels');
  const [resignOpen, setResignOpen] = useState(false);

  const canWrite = (): boolean => (permissions.includes('write:model_portfolios') || false);
  // TODO: Add specific permission in the backend: write:resign_model_portfolios
  const canTriggerResignIPS = (): boolean => (permissions.includes('write:model_portfolios') || false);

  const [updateModel, { loading }] = useMutation(UPDATE_MODEL, {
    variables: {
      input: {
        modelPortfolioId: model.id,
        translatedPortfolioDescription: model.translatedPortfolioDescription?.en ? { en: model.translatedPortfolioDescription?.en, fr: model.translatedPortfolioDescription?.fr } : undefined,
        translatedInvestmentObjective: model.translatedInvestmentObjective?.en ? { en: model.translatedInvestmentObjective?.en, fr: model.translatedInvestmentObjective?.fr } : undefined,
        translatedLiquidity: model.translatedLiquidity?.en ? { en: model.translatedLiquidity?.en, fr: model.translatedLiquidity?.fr } : undefined,
        targetAllocations: model.targetAllocations?.map((x: TargetAllocation) => ({ secondaryAssetClassId: x.secondaryAssetClass?.id, min: x.min, max: x.max })),
      },
    },
  });

  const removeAllocation = (index: number) => {
    const a = [...(model?.targetAllocations || [])];
    a.splice(index, 1);
    setModel({ ...model, targetAllocations: a });
  };

  const addAllocation = () => {
    const a = [...(model?.targetAllocations || [])];
    a.push({
      secondaryAssetClass: {
        translatedName: { en: '' },
      },
      min: 0,
      max: 0,
    });
    setModel({ ...model, targetAllocations: a });
  };

  const secondaryAssetClasses = () => {
    const p = mapValues((v: Branch[]) => sum(v.map((m: Branch) => m.percentage)), groupBy(((x: Branch) => x.financialProduct?.primaryAssetClass?.id || t('unknown')), model.children));
    const s = mapValues((v: Branch[]) => sum(v.map((m: Branch) => m.percentage)), groupBy(((x: Branch) => x.financialProduct?.secondaryAssetClass?.id || t('unknown')), model.children));
    return mapValues((x: number) => round(x, 2), { ...p, ...s });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item xs={12}><Typography variant='subtitle1'>{t('investmentPolicyStatement')}</Typography></Grid>
            <Grid item xs={12}>
              <TranslatableString
                onChange={(value) => setModel({ ...model, translatedPortfolioDescription: value })}
                value={model.translatedPortfolioDescription || {}}
                label={t('edit.porfolioDescription')}
                rows={3}
                readonly={!canWrite()}
              />
            </Grid>
            <Grid item xs={12}>
              <TranslatableString
                onChange={(value) => setModel({ ...model, translatedInvestmentObjective: value })}
                value={model.translatedInvestmentObjective || {}}
                label={t('edit.investmentObjective')}
                rows={3}
                readonly={!canWrite()}
              />
            </Grid>
            <Grid item xs={12}>
              <TranslatableString
                onChange={(value) => setModel({ ...model, translatedLiquidity: value })}
                value={model.translatedLiquidity || {}}
                label={t('edit.liquidityInfo')}
                rows={3}
                readonly={!canWrite()}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '40%' }}><Typography variant='overline'>{t('secondaryAssetClass')}</Typography></TableCell>
                  <TableCell sx={{ width: '20%' }}><Typography variant='overline'>{t('minimum')}</Typography></TableCell>
                  <TableCell sx={{ width: '20%' }}><Typography variant='overline'>{t('maximum')}</Typography></TableCell>
                  <TableCell sx={{ width: '10%' }}><Typography variant='overline'>{t('actual')}</Typography></TableCell>
                  {canWrite() && (
                    <TableCell sx={{ width: '10%' }}>
                      <IconButton onClick={addAllocation}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(model.targetAllocations || []).map((child: TargetAllocation, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <AssetClassSelect
                        readonly={!canWrite}
                        value={child.secondaryAssetClass?.id || ''}
                        setAssetClass={(e) => {
                          const targetAllocations = [...(model?.targetAllocations || [])];
                          const fp = { ...targetAllocations[index] };
                          fp.secondaryAssetClass = { id: e.target.value };
                          targetAllocations[index] = fp;
                          setModel({ ...model, targetAllocations });
                        }}
                        label=''
                        size='small'
                        filteredAllocations={model.targetAllocations?.map((a: any) => a.secondaryAssetClass.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type='number'
                        size='small'
                        value={child.min}
                        onChange={(e) => {
                          const targetAllocations = [...(model?.targetAllocations || [])];
                          const fp = { ...targetAllocations[index] };
                          fp.min = round(parseFloat(e.target.value), 2);
                          targetAllocations[index] = fp;
                          setModel({ ...model, targetAllocations });
                        }}
                        InputProps={{
                          readOnly: !canWrite(),
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type='number'
                        size='small'
                        value={child.max}
                        onChange={(e) => {
                          const targetAllocations = [...(model?.targetAllocations || [])];
                          const fp = { ...targetAllocations[index] };
                          fp.max = round(parseFloat(e.target.value), 2);
                          targetAllocations[index] = fp;
                          setModel({ ...model, targetAllocations });
                        }}
                        InputProps={{
                          readOnly: !canWrite(),
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {secondaryAssetClasses()[child.secondaryAssetClass?.id || ''] && (
                        <Chip label={`${secondaryAssetClasses()[child.secondaryAssetClass?.id || '']}%`} color={
                          secondaryAssetClasses()[child.secondaryAssetClass?.id || ''] >= child.min && secondaryAssetClasses()[child.secondaryAssetClass?.id || ''] <= child.max ? 'success' : 'error'
                        } />
                      )}
                    </TableCell>
                    {canWrite() && (
                      <TableCell>
                        <IconButton onClick={() => removeAllocation(index)}>
                          <CancelIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              {canTriggerResignIPS() && (
                <Button variant='contained' onClick={() => setResignOpen(true)} disabled={loading}>
                  {t('edit.triggerReSign.button')}
                </Button>
              )}
            </Grid>
            <Grid item>
              {canWrite() && (
                <Button variant='contained' onClick={() => updateModel()} disabled={loading}>
                  {t('save')}
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {model.id && (
        <ResignIPS open={resignOpen} modelId={model.id} handleClose={() => {
          setResignOpen(false);
        }} />
      )}
    </>
  );
};

export default EditIPS;
