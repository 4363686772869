import {
  useState, useRef, useMemo, useEffect, SetStateAction,
} from 'react';
import throttle from 'lodash/throttle';
import {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { InputAdornment, SxProps } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { JSX } from 'react/jsx-runtime';
import { CountryCodes, Jurisdictions } from '@onevesthq/ov-enums';
import { kebabCase } from 'lodash';
import { PhysicalAddress } from '../../../interfaces';
import { Autocomplete } from '../autocomplete/autocomplete';
import { getCountryName } from '../../resources';
import { Icon, Typography } from '../../1-primative';
import { Tooltip } from '../tooltip/tooltip';
import { Radio, RadioGroup, colors } from '../..';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;

interface Props {
  label?: string,
  addressString?: string,
  setAddress: (address: Partial<PhysicalAddress>) => void,
  setAddressState?: (att: { address: string, error: string, open: boolean }) => void,
  openManualInput: () => void,
  apiKey?: string,
  error?: boolean,
  errorText?: string,
  disabled?: boolean,
  autoFocus?: any,
  onFocus?: any,
  onBlur?: any,
  sx?: SxProps;
  infoTooltip?: string;
  addressCountry?: CountryCodes,
  allowedCountries: CountryCodes[],
  lockMessage?: string;
  testId?: string;
}

const loadScript = (src: string, position: HTMLElement | null, id: string): void => {
  if (!position || !window) {
    return;
  }
  // Callback is needed even when emtpy.
  (window as any).initMap = () => { };
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
}

const GoogleAddress = ({
  setAddressState, setAddress, openManualInput, label, addressString, addressCountry, error, apiKey, errorText,
  disabled, onFocus, autoFocus, sx, onBlur, infoTooltip, allowedCountries, lockMessage, testId,
}: Props): JSX.Element => {
  const [restrictedCountry, setRestrictedCountry] = useState<CountryCodes>(allowedCountries[0]);
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);
  const { t } = useTranslation('pageConfiguration');
  const googleAPIKey = apiKey ?? GOOGLE_MAPS_API_KEY;
  const { sys } = useThemeTokens();

  useEffect(() => {
    if (googleAPIKey && typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places&callback=initMap`,
          document.querySelector('head'),
          'google-maps',
        );
      }
      loaded.current = true;
    }
  }, [googleAPIKey]);

  useEffect(() => {
    if (addressCountry) setRestrictedCountry(addressCountry);
  }, [addressCountry]);

  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string, componentRestrictions: { country: string, } },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      },
      200,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({
      input: inputValue,
      componentRestrictions: { country: restrictedCountry },
    }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, restrictedCountry]);

  if (!allowedCountries || allowedCountries.length === 0) return <></>;

  const handleSelect = (results: any[]): void => {
    const localAddress: Partial<PhysicalAddress> = {};
    let countryCode = '';
    let level1Code = '';
    if (results && results.length > 0) {
      const country: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('country')));
      if (country && country.length > 0) {
        countryCode = country[0].short_name;
      }
      const postal: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('postal_code')));
      if (postal && postal.length > 0) {
        localAddress.postal = postal[0].long_name;
      }
      // eslint-disable-next-line
      const streetNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('street_number');
      });
      if (streetNumber && streetNumber.length > 0) {
        localAddress.houseNumber = streetNumber[0].long_name;
      }
      // eslint-disable-next-line
      const unitNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('unit_number');
      });
      if (unitNumber && unitNumber.length > 0) {
        localAddress.unitNumber = unitNumber[0].long_name;
      }
      // eslint-disable-next-line
      const route: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('route');
      });
      if (route && route.length > 0) {
        localAddress.streetName = route[0].long_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel1: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_1');
      });
      if (administrativeAreaLevel1 && administrativeAreaLevel1.length > 0) {
        level1Code = administrativeAreaLevel1[0].short_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel2: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_2') || e.types.includes('locality');
      });
      if (administrativeAreaLevel2 && administrativeAreaLevel2.length > 0) {
        localAddress.city = administrativeAreaLevel2[0].long_name;
      }
    }
    localAddress.country = countryCode as CountryCodes;
    if (level1Code && (countryCode === 'CA' || countryCode === 'US')) {
      localAddress.jurisdiction = `${countryCode}_${level1Code}` as Jurisdictions;
    } else {
      localAddress.jurisdiction = undefined;
    }
    setAddress(localAddress);
    openManualInput();
  };

  return (
    <>
      <Typography variant='labelSmall' sx={{ mb: '4px', color: error ? colors.negative : sys.color.onSurfaceVariant }} >
        {label}
      </Typography>
      {allowedCountries.length > 1 && (
        <RadioGroup
          testId={testId ? `${testId}-radio-group` : 'google-address-radio-group'}
          value={restrictedCountry}
          onChange={(e: any) => { setRestrictedCountry(e.target.value); setValue(null); setInputValue(''); setAddress({}); }}
        >
          {allowedCountries.map((cc) => <>
            <Radio key={cc} value={cc} size='small' label={getCountryName(cc)} testId={testId ? `${testId}-radio-option-${cc.toLowerCase()}` : `google-address-radio-option-${cc}`}/>
          </>)}
        </RadioGroup>
      )}
      <Autocomplete
        testId={testId ?? 'google-address-autocomplete'}
        label=''
        infoTooltip={infoTooltip}
        error={error}
        errorText={errorText}
        disabled={disabled}
        onFocus={onFocus}
        autoFocus={autoFocus}
        blurOnSelect
        fullWidth
        getOptionLabel={(option) => ((typeof option === 'string') ? option : option.description)}
        freeSolo
        onBlur={onBlur}
        sx={sx}
        InputProps={{
          endAdornment: disabled ? (
            <InputAdornment position="end">
              <Tooltip title={lockMessage ?? t('notEditableMessage')}>
                <Icon icon={LockIcon} size='small' />
              </Tooltip>
            </InputAdornment>
          ) : null,
          disabled,
        }}
        options={[
          ...options,
        ]}
        value={addressString}
        renderOption={(props: any, option:any) => (
          <li {...props} data-testid={testId ? `${testId}-${kebabCase(option.description)}` : `google-address-autocomplete-${kebabCase(option.description)}`}>
            {option.description}
          </li>
        )}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (newValue && newValue.place_id) {
            geocodeByPlaceId(newValue.place_id).then((results: any[]) => {
              handleSelect(results);
            }).catch((err: any) => { });
          }
        }}
        onInputChange={(event: any, newInputValue: SetStateAction<string>) => {
          setInputValue(newInputValue);
        }}
      />
    </>
  );
};

GoogleAddress.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  setAddressState: undefined,
};

export default GoogleAddress;
