import { gql, useMutation, useQuery } from '@apollo/client';
import {
  ListItem,
  TextField,
  MenuItem,
  Box,
  Button,
  Modal,
  InputAdornment,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccountSelect } from '../../inputs/bankAccountSelect';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import AmountField from '../../inputs/amountField';
import FormModal from '../../modals/formModal';
import { FETCH_FUND_TRANSFER_INSTRUCTIONS } from './paymentIntructionsTable';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import { AffiliationTypes } from '../../../interfaces';

dayjs.extend(utc);

const UPDATE_SCEDULED_INCOME_FUND_TRANSFER = gql`
mutation updateScheduledIncomeFundTransfer($input: UpdateScheduledIncomeFundTransferInput!) {
  updateScheduledIncomeFundTransfer(input: $input) {
    scheduledIncomeFundTransfer {
      id
    }
  }
}
`;

const FETCH_ACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        user {
          id
          firstName
          dateOfBirth
          lastName
        }
        affiliations {
          user {
            firstName
            dateOfBirth
            lastName
          }
        }
        subAccounts {
          id
          state
        }
      }
    }
  }
`;

const EditScheduledIncomeFundTransfer = ({
  afterUpdate, scheduledIncomeFundTransferToUpdate, open, handleClose, accountId,
}: {
  afterUpdate: () => void,
  scheduledIncomeFundTransferToUpdate: any,
  open: boolean, handleClose: () => void,
  accountId: string,
}) => {
  const { t } = useTranslation(['orgSettings', 'components']);
  const [tempDate, setTempDate] = useState('');
  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const [scheduledIncomeFundTransfer, setScheduledIncomeFundTransfer] = useState(scheduledIncomeFundTransferToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const [openCalendar, setOpenCalendar] = useState(false);
  let affiliationusers: any[] = [];

  useEffect(() => {
    setLocalOpen(open);
    setScheduledIncomeFundTransfer({
      ...scheduledIncomeFundTransferToUpdate,
      typeOfAnnualPaymentAmount: scheduledIncomeFundTransferToUpdate.annualAmountCents === 0 ? 'MINIMUM_ANNUAL_PAYMENT' : 'CUSTOM',
    });
  }, [scheduledIncomeFundTransferToUpdate, open, setScheduledIncomeFundTransfer, setLocalOpen]);
  const [updateTransfer, opt1] = useMutation(UPDATE_SCEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        amountPayableType: scheduledIncomeFundTransfer.amountPayableType ?? undefined,
        dateOfBirth: scheduledIncomeFundTransfer.dateOfBirth ?? undefined,
        frequency: scheduledIncomeFundTransfer.frequency ?? undefined,
        annualAmountCents: scheduledIncomeFundTransfer.annualAmountCents ?? undefined,
        scheduledDate: scheduledIncomeFundTransfer.scheduledDate ?? undefined,
        bankAccountId: scheduledIncomeFundTransfer.bankAccountId ?? undefined,
        subAccountId: scheduledIncomeFundTransfer.subAccountId ?? undefined,
        transferredAmountCents: scheduledIncomeFundTransfer.annualAmountCents ?? undefined,
        scheduledIncomeFundTransferId: scheduledIncomeFundTransfer.id,
        taxOption: scheduledIncomeFundTransfer.taxOption ?? undefined,
        specialTaxRate: Number(scheduledIncomeFundTransfer.specialTaxRate) ?? undefined,
      },
    },
    refetchQueries: [FETCH_FUND_TRANSFER_INSTRUCTIONS],
  });

  const update = async (event: any) => {
    event.preventDefault();
    await updateTransfer();
    setLocalOpen(false);
    afterUpdate();
  };

  const generateClientNameWithDob = (client: any): string => {
    const clientDob = dayjs(client.dateOfBirth);
    return `${client.firstName} ${client.lastName} (${dayjs().diff(clientDob, 'year')} years)`;
  };

  const response = useQuery(FETCH_ACCOUNT, {
    variables: { accountId },
    skip: !accountId,
    fetchPolicy: 'no-cache',
  });

  affiliationusers = response?.data?.fetchAccount?.account?.affiliations.filter((x: any) => x?.type === AffiliationTypes.Other) || [];
  if (response?.data?.fetchAccount?.account?.user?.dateOfBirth) {
    affiliationusers = affiliationusers.concat([{
      user: response?.data?.fetchAccount?.account?.user,
    }]);
  }
  if (scheduledIncomeFundTransfer?.subAccount?.account?.affiliations) {
    affiliationusers = affiliationusers.concat(scheduledIncomeFundTransfer?.subAccount?.account?.affiliations);
  }
  const relatedSubAccounts = response?.data?.fetchAccount?.account?.subAccounts || [];
  const confirmDate = (): void => {
    setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, scheduledDate: tempDate });
    setOpenCalendar(false);
  };

  if (!scheduledIncomeFundTransfer) {
    return <></>;
  }

  return (
    <FormModal loading={opt1.loading} title={t('components:scheduledFundTransferModal.editTitle')} formButton={t('update')} onSubmit={update} open={localOpen} handleClose={handleClose} >
      <ListItem>
        <TextField
          select
          value={scheduledIncomeFundTransfer.dateOfBirth}
          label={t('components:scheduledFundTransferModal.dateOfBirth')}
          fullWidth
          onChange={(e: any) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, dateOfBirth: e.target.value });
          }}
        >
          {affiliationusers.map((a: any) => (<MenuItem value={a.user.dateOfBirth}>{generateClientNameWithDob(a.user)}</MenuItem>))}
        </TextField>
      </ListItem>
      {(relatedSubAccounts.length > 1) && (<ListItem>
        <SubAccountSelect
          label={t('components:scheduledFundTransferModal.subAccount')}
          value={scheduledIncomeFundTransfer.subAccountId}
          setSubAccount={(e) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, subAccountId: e.target.value });
          }}
          forObject='ACCOUNT'
          forId={accountId}
          selectFirst
        />
      </ListItem>)}

      <ListItem>
        <TextField
          select
          value={scheduledIncomeFundTransfer.typeOfAnnualPaymentAmount}
          label={t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmount')}
          fullWidth
          onChange={(e: any) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, typeOfAnnualPaymentAmount: e.target.value });
          }}
        >
          <MenuItem value='CUSTOM'>{t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmountSources.custom')}</MenuItem>
          <MenuItem value='MINIMUM_ANNUAL_PAYMENT'>{t('components:scheduledFundTransferModal.typeOfAnnualPaymentAmountSources.minimumAnnualPayment')}</MenuItem>
        </TextField>
      </ListItem>

      {scheduledIncomeFundTransfer.typeOfAnnualPaymentAmount === 'CUSTOM' && (
        <>
          <ListItem>
            <AmountField
              label={t('components:scheduledFundTransferModal.amount')}
              variant="outlined"
              value={scheduledIncomeFundTransfer.annualAmountCents}
              fullWidth
              onChange={(e: any) => {
                setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, annualAmountCents: e.target.valueCents });
              }}
            />
          </ListItem>

          <ListItem>
            <TextField
              select
              value={scheduledIncomeFundTransfer.amountPayableType}
              label={t('components:scheduledFundTransferModal.amountPayableType')}
              fullWidth
              onChange={(e: any) => {
                setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, amountPayableType: e.target.value });
              }}
            >
              <MenuItem value='GROSS'>{t('components:scheduledFundTransferModal.amountPayableTypeSources.gross')}</MenuItem>
              <MenuItem value='NET'>{t('components:scheduledFundTransferModal.amountPayableTypeSources.net')}</MenuItem>
            </TextField>
          </ListItem>
        </>
      )}

      <ListItem>
        <TextField
          select
          value={scheduledIncomeFundTransfer.frequency}
          label={t('components:scheduledFundTransferModal.schedule')}
          fullWidth
          data-testid="frequency-dropdown"
          onChange={(e: any) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, frequency: e.target.value });
          }}
        >
          <MenuItem value='MONTHLY'>{t('components:scheduledFundTransferModal.scheduleOptions.MONTHLY')}</MenuItem>
          <MenuItem value='QUARTERLY'>{t('components:scheduledFundTransferModal.scheduleOptions.QUARTERLY')}</MenuItem>
          <MenuItem value='SEMI_ANNUALLY'>{t('components:scheduledFundTransferModal.scheduleOptions.SEMI_ANNUALLY')}</MenuItem>
          <MenuItem value='ANNUALLY'>{t('components:scheduledFundTransferModal.scheduleOptions.ANNUALLY')}</MenuItem>
        </TextField>
      </ListItem>

      <ListItem>
        <TextField
          value={scheduledIncomeFundTransfer.scheduledDate}
          label={t('components:scheduledFundTransferModal.scheduledDate')}
          fullWidth
          onChange={(e: any) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, scheduledDate: e.target.value });
          }}
          onClick={() => setOpenCalendar(true)}
        >
        </TextField>
      </ListItem>

      <ListItem>
        <TextField
          select
          value={scheduledIncomeFundTransfer.taxOption}
          label={t('components:scheduledFundTransferModal.taxOptionType')}
          fullWidth
          onChange={(e: any) => {
            setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, taxOption: e.target.value });
          }}
        >
          <MenuItem key='NO_TAX_ON_MINIMUM' value='NO_TAX_ON_MINIMUM'>{t('components:scheduledFundTransferModal.taxOptions.NO_TAX_ON_MINIMUM')}</MenuItem>
          <MenuItem key='SPECIAL_TAX_RATE' value='SPECIAL_TAX_RATE'>{t('components:scheduledFundTransferModal.taxOptions.SPECIAL_TAX_RATE')}</MenuItem>
          <MenuItem key='TAX_ON_FULL' value='TAX_ON_FULL'>{t('components:scheduledFundTransferModal.taxOptions.TAX_ON_FULL')}</MenuItem>
        </TextField>
      </ListItem>
      {scheduledIncomeFundTransfer.taxOption === 'SPECIAL_TAX_RATE' && (
        <>
          <ListItem>
            <TextField
              label={t('components:scheduledFundTransferModal.specialTaxRate')}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, max: 100 },
              }}
              variant="outlined"
              value={scheduledIncomeFundTransfer.specialTaxRate}
              fullWidth
              onChange={(e: any) => {
                setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, specialTaxRate: e.target.value });
              }}
            />
          </ListItem>
        </>
      )}

      <ListItem>
        <BankAccountSelect
          setBankAccount={(value: string) => setScheduledIncomeFundTransfer({ ...scheduledIncomeFundTransfer, bankAccountId: value })}
          value={scheduledIncomeFundTransfer?.bankAccount?.id}
          label={t('components:scheduledFundTransferModal.bankAccount')}
          userId={(response?.data?.fetchAccount?.account?.user?.id || scheduledIncomeFundTransfer?.subAccount?.account?.user?.id) ?? ''}
        />
      </ListItem>

      <Modal
        open={openCalendar}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <Box display="flex" flexDirection="column">
          <LocalizedDatePicker
            open
            disablePast
            value={dayjs(scheduledIncomeFundTransfer.scheduledDate).utc().format('YYYY-MM-DD')}
            minDate={nextDay}
            onChange={(newValue: any) => { setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD')); }}
            onClose={confirmDate}
            renderInput={(params: any) => (
              <TextField
                sx={{ marginBottom: '32px' }}
                variant="standard"
                {...params}
                fullWidth
              />
            )}
          />
          <Box display="flex" style={{ backgroundColor: 'white' }}>
            <Button variant="outlined" onClick={() => setOpenCalendar(false)} style={{ width: '100%', margin: '0.25em' }}>{t('shared:cancel')}</Button>
            <Button variant="contained" onClick={() => confirmDate()} style={{ width: '100%', margin: '0.25em' }}>{t('shared:confirm')}</Button>
          </Box>
        </Box>
      </Modal>
    </FormModal>
  );
};

export default EditScheduledIncomeFundTransfer;
