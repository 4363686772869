/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField, ListItem, MenuItem, Typography,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import OrganizationSelect from '../../components/inputs/organizationSelect';
import CreateNewModal from '../../components/modals/createNewModal';
import { UserContext } from '../../providers/userContextProvider';
import RelationshipTable from '../households/components/relationshipsTable';
import { AffiliationTypes, ClientGroupTypes, CorporateBusinessTypes } from '../../interfaces';
import { FETCH_CORPORATIONS } from '.';
import { CountryName } from '../../components/fields/countryName';

export interface EditableRelation {
  type: string,
  primaryEmail: string,
  userId: string,
  gender?: string,
  allocation?: number,
}

export const CREATE_CLIENT_GROUP = gql`
mutation createClientGroup($input: CreateClientGroupInput!) {
  createClientGroup(input: $input) {
    clientGroup {
      id
    }
  }
}
`;

const DEFAULT_TYPE: string = CorporateBusinessTypes.Corporation;
const DEFAULT_COUNTRY = 'CA';

const NewCorporation = () => {
  const { t } = useTranslation('corporations');
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const [organizationId, setOrganizationId] = useState(activeOrganization.id);
  const [name, setName] = useState('');
  const [type, setType] = useState(DEFAULT_TYPE);
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [primaryUserId, setPrimaryUserId] = useState('');
  const [relationshipsList, setRelationshipsList] = useState<EditableRelation[]>([]);
  const [establishedDate, setEstablishedDate] = useState<string | null>(null);

  const [createCorporationObject, { loading }] = useMutation(CREATE_CLIENT_GROUP, {
    variables: {
      input: {
        name,
        type: ClientGroupTypes.Business,
        businessType: type,
        corporationCountry: country,
        establishedDate,
        primaryUserId,
        primaryEmail: relationshipsList.find((r) => r.userId === primaryUserId)?.primaryEmail,
        organizationId,
        relationships: relationshipsList.map(({ primaryEmail, ...rest }) => rest),
      },
    },
    refetchQueries: [FETCH_CORPORATIONS],
  });

  const createCorporation = async (event: any) => {
    const result = await createCorporationObject();
    navigate(`/corporations/${result.data.createClientGroup.clientGroup.id}`);
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setType(DEFAULT_TYPE);
    setCountry(DEFAULT_COUNTRY);
    setEstablishedDate(null);
    setPrimaryUserId('');
    setRelationshipsList([]);
    setOrganizationId(activeOrganization.id);
  };

  /* Ensure primary user stays one of the list */
  useEffect(() => {
    if (relationshipsList.length === 0) return;
    if (!primaryUserId) {
      setPrimaryUserId(relationshipsList[0].userId);
    }
    if (!relationshipsList.map((r) => r.userId).includes(primaryUserId)) {
      setPrimaryUserId(relationshipsList[0].userId);
    }
  }, [relationshipsList]);

  const disabled = !(name && primaryUserId && relationshipsList.length > 0 && establishedDate);

  useEffect(() => {
    setOrganizationId(activeOrganization.id);
  }, [activeOrganization]);

  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('addModal.title')} onSubmit={createCorporation}>
      <ListItem>
        <OrganizationSelect
          value={organizationId}
          label={t('addModal.organization')}
          onChange={(event: any) => setOrganizationId(event.target.value)}
        />
      </ListItem>
      <ListItem>
        <TextField
          autoFocus
          label={t('addModal.name')}
          sx={{ width: '100%' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </ListItem>
      <ListItem>
        <TextField select
          label={t('addModal.corporationType')}
          sx={{ width: '100%' }}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {Object.values(CorporateBusinessTypes).map((businessType) => <MenuItem key={businessType} value={businessType}>{t(`types.${businessType}`)}</MenuItem>)}
        </TextField>
        &nbsp;
        <TextField select
          label={t('addModal.corporationCountry')}
          sx={{ width: '100%' }}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <MenuItem key='CA' value='CA'><CountryName code='CA' /></MenuItem>
          {/* Only allow corporations in Canada (OV-5960) */}
        </TextField>
      </ListItem>
      <ListItem>
        <DatePicker
          views={['year', 'month', 'day']}
          openTo={establishedDate ? 'day' : 'year'}
          disableFuture={true}
          label={t('addModal.establishedDate')}
          inputFormat="YYYY-MM-DD"
          value={establishedDate}
          onChange={(date: any) => {
            setEstablishedDate(date?.isValid() ? date.format('YYYY-MM-DD') : null);
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </ListItem>
      <ListItem sx={{ display: 'inline-block' }}>
        <Typography variant='overline'>{t('addModal.createARelation')}</Typography>
        <RelationshipTable
          allowedTypes={[AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner, AffiliationTypes.Other]}
          organizationId={organizationId ?? ''}
          relationships={relationshipsList}
          onRelationshipChange={
            (newRelationshipUsers: EditableRelation[]) => setRelationshipsList(newRelationshipUsers)
          }
        />
      </ListItem>
      {relationshipsList.length > 0 ? (
        <ListItem>
          <TextField
            select
            value={primaryUserId}
            label={t('addModal.primaryUser')}
            fullWidth
            onChange={(e: any) => setPrimaryUserId(e.target.value)}
          >
            {(relationshipsList || [])?.map((x: EditableRelation) => (
              <MenuItem key={x.userId} value={x.userId}>{x.primaryEmail}</MenuItem>
            ))}
          </TextField>
        </ListItem>
      ) : null}
    </CreateNewModal>
  );
};

export default NewCorporation;
