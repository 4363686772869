import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Account,
  Goal as GoalInterface,
  DraftGoal as DraftGoalInterface,
  getAccountName,
} from 'interfaces';
import { formatMoneyValue } from 'util/index';
import { Box, Icon, Typography } from 'ovComponents/1-primative';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  IconButton,
} from 'ovComponents/2-component';

interface DraftGoalProps {
  goal: DraftGoalInterface,
  onDelete: () => void,
}
interface GoalDetail {
  label: React.ReactNode,
  value: React.ReactNode,
}

const useGoalTitle = (goal: GoalInterface | DraftGoalInterface) => {
  // Yield a title in the following order: goalName > goalTitle > "Goal"
  const { t } = useTranslation(['client', 'components', 'workflowCompletions']);
  return goal?.name ? goal.name : (goal?.type ? t(`client:goalType.${goal?.type}`) : t('workflowCompletions:goals.goal'));
};

const useGoalDetails = (goal: (GoalInterface | DraftGoalInterface)) => {
  const { t } = useTranslation(['accountType', 'client', 'workflowCompletions']);
  const getLabel = (field: string) => t(`workflowCompletions:goals.details.${field}`);

  const getGoalScope = () => {
    if (goal?.householdClientGroup) {
      return `${t('workflowCompletions:goals.scopes.HOUSEHOLD')} (${goal?.householdClientGroup?.name})`;
    }

    return t('workflowCompletions:goals.scopes.INDIVIDUAL');
  };

  const serializeAccounts = (accounts?: Account[]) => {
    if (!accounts) return '';
    const accountLabels = accounts.map((account) => getAccountName(account));
    const accountLabelsWithBreaks = accountLabels.flatMap((el) => [<br/>, el]).slice(1);

    return accountLabelsWithBreaks;
  };

  const goalDetails: GoalDetail[] = [];

  if (goal.type) goalDetails.push({ label: getLabel('type'), value: t(`client:goalType.${goal.type}`) });
  goalDetails.push({ label: getLabel('scope'), value: getGoalScope() });
  if (goal.timeHorizon) goalDetails.push({ label: getLabel('timeHorizon'), value: t(`client:timeHorizonOptions.${goal.timeHorizon}`) });
  if (goal.targetAmountCents) goalDetails.push({ label: getLabel('targetAmount'), value: formatMoneyValue(goal.targetAmountCents) });
  if (goal.riskQuestion1) goalDetails.push({ label: getLabel('riskTolerance'), value: t(`workflowCompletions:riskLevels.${goal.riskQuestion1}`) });
  if (goal.financialProduct?.theme?.name) goalDetails.push({ label: getLabel('portfolioTheme'), value: goal.financialProduct.theme.name });
  if (goal.financialProduct?.name) goalDetails.push({ label: getLabel('portfolioModel'), value: goal.financialProduct.name });
  goalDetails.push({ label: getLabel('taxOptimized'), value: t(`components:booleanOptions.${goal.taxOptimized ? 'yes' : 'no'}`) });
  if ((goal as DraftGoalInterface)?.accountsLinked?.length) {
    goalDetails.push({ label: getLabel('accountsLinked'), value: serializeAccounts((goal as DraftGoalInterface).accountsLinked) });
  }

  return goalDetails;
};

export const GoalDetails = ({ goal }: { goal: GoalInterface | DraftGoalInterface }) => {
  const parsedGoalDetails = useGoalDetails(goal);

  return (
    <>
      { parsedGoalDetails.map((item, index) => (
        <Box key={index} sx={{ mb: 1 }} display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='labelLarge' weight='bold'>{item.label}:</Typography>
          <Typography variant='bodyMedium' sx={{ textAlign: 'right' }}>{item.value}</Typography>
        </Box>
      ))}
    </>
  );
};

export const ExistingGoal = ({ goal }: { goal: GoalInterface }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <Typography variant='bodyLarge' weight='bold'>{useGoalTitle(goal)}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <GoalDetails goal={goal} />
    </AccordionDetails>
  </Accordion>
);

export const DraftGoal = ({ goal, onDelete }: DraftGoalProps) => (
  <Card variant='outlined'>
    <CardContent>
      <Box sx={{ mb: 2 }} display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant='bodyLarge' weight='bold' sx={{ mt: 1 }}>{useGoalTitle(goal)}</Typography>
        <IconButton onClick={() => onDelete()}><Icon icon={CloseRoundedIcon} size='small' /></IconButton>
      </Box>
      <GoalDetails goal={goal} />
    </CardContent>
  </Card>
);
