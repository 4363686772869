import {
  LocationOnOutlined, CalendarToday, MailOutline, AccountBalanceOutlined, Link as LinkIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ClientGroup } from '../../../interfaces';
import { generateAddressString } from '../../../util';
import { Summary, Pill } from './summary';
import { useLocalization } from '../../../util/useLocalization';

interface SummaryProps {
  clientGroup: ClientGroup;
  onHighlightClicked?: (prop: any) => void,
}

export function ClientGroupSummary({ clientGroup, onHighlightClicked }: SummaryProps) {
  const { t } = useTranslation('client');
  const { localizedDate } = useLocalization();
  if (!clientGroup) return <></>;

  const showPills:Pill[] = [];

  if (clientGroup.incompleteFields) {
    if (clientGroup.incompleteFields.length > 0) {
      showPills.push({
        label: t('corporations:notCompleted'),
        leadingIcon: 'problem',
        tooltip: clientGroup.incompleteFields.join(', '),
      });
    } else {
      showPills.push({
        label: t('corporations:completed'),
        leadingIcon: 'checkmark',
      });
    }
  }

  const physicalAddressString = generateAddressString(clientGroup.physicalAddress);

  return (
    <Summary
      title={clientGroup.name}
      subtitle={t(`corporations:types.${clientGroup.businessType}`)}
      pills={showPills}
      hideSubTitle
      highlights={
        [
          {
            icon: <CalendarToday/>,
            label: clientGroup.establishedDate
              ? localizedDate(clientGroup.establishedDate)
              : 'n/a',
            tooltip: t('corporations:tooltips.establishedDate'),
            onClick: () => {
              if (onHighlightClicked) {
                onHighlightClicked('establishedDate');
              }
            },
          },
          {
            icon: <MailOutline/>,
            label: clientGroup.primaryEmail ?? '',
            tooltip: t('corporations:tooltips.primaryEmail'),
            onClick: () => {
              if (onHighlightClicked) {
                onHighlightClicked('primaryEmail');
              }
            },
          },
          {
            icon: <LocationOnOutlined/>,
            label: physicalAddressString,
            tooltip: t('corporations:tooltips.physicalAddress'),
            onClick: () => {
              if (onHighlightClicked) {
                onHighlightClicked('physicalAddress');
              }
            },
          },
          {
            icon: <LinkIcon/>,
            label: `${clientGroup.relationships.length.toString()
            } ${
              clientGroup.relationships.length === 1 ? t('clientGroupSummary.client') : t('clientGroupSummary.clients')
            } ${
              t('clientGroupSummary.inThisGroup')}`,
          },
          {
            icon: <AccountBalanceOutlined/>,
            label: clientGroup.organization?.name ?? '',
          },
        ]
      }
      contextShowId={clientGroup.id}
    />
  );
}
