import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_REPORT_NAME = gql`
  query fetchReportName($reportId: ObjectID!, $organizationId: ObjectID!) {
    fetchReport(input: {reportId: $reportId, organizationId: $organizationId}) {
      report {
        name
      }
    }
  }
`;

export function CustomReportBreadcrumb() {
  const { activeOrganization } = useContext(UserContext);
  const { data } = useQuery(FETCH_REPORT_NAME, { variables: { reportId: useParams().id, organizationId: activeOrganization.id } });

  return data?.fetchReport?.report.name ?? 'Custom Report';
}
