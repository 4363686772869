import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Grid, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Date } from '../../../components/misc/date/date';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import NewSchedule from './newSchedule';
import EditSchedule from './editSchedule';
import { usePageState } from '../../../util/usePageState';

interface Schedule {
  id: string,
  name: string,
  dates: Date[],
  nextDate: Date,
  associatedFinancialProductsCount: number,
  organization: { id: string },
}

export const FETCH_SCHEDULES = gql`
query fetchSchedules($input: FetchSchedulesInput!) {
  fetchSchedules(input: $input) {
    totalCount
    schedules {
      id
      name
      dates
      nextDate
      associatedFinancialProductsCount
      organization { id }
    }
  }
}
`;
const SchedulesTable = () => {
  const { t } = useTranslation(['schedule']);
  const [editOpen, setEditOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const [activeSchedule, setActiveSchedule] = useState<any>({});
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const pageSize = 15;
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_SCHEDULES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offset: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end'>
            {permissions.includes('write:schedules') ? (<Grid item>
              <NewSchedule afterCreate={refetch} />
            </Grid>) : null}
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('schedule:table.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('schedule:table.nextDate')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('schedule:table.noOfSchedules')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('schedule:table.noOfAttachedProducts')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchSchedules?.schedules?.map((scheduleItem: Schedule) => (
                <TableRow
                  hover={permissions.includes('write:schedules')}
                  key={scheduleItem.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: permissions.includes('write:schedules') ? 'pointer' : 'auto' }}
                  onClick={() => {
                    if (permissions.includes('write:schedules')) {
                      setActiveSchedule(scheduleItem);
                      setEditOpen(true);
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    {scheduleItem.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Date variant='subtitle2' date={scheduleItem.nextDate} />
                  </TableCell>
                  <TableCell component="th" scope="row" align='right'>
                    {(scheduleItem.dates && scheduleItem.dates.length) || 0}
                  </TableCell>
                  <TableCell component="th" scope="row" align='right'>
                    {scheduleItem.associatedFinancialProductsCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <EditSchedule
              afterUpdate={() => {
                setActiveSchedule({});
                setEditOpen(false);
                refetch();
              }}
              scheduleToUpdate={activeSchedule}
              open={editOpen}
              handleClose={() => setEditOpen(false)}
            />
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchSchedules?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default SchedulesTable;
