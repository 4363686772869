import { gql } from '@apollo/client';

export const CREATE_DEPOSIT_TRANSFER = gql`
  mutation createDepositTransfer($input: CreateDepositTransferInput!) {
    createDepositTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_SCHEDULED_DEPOSIT_TRANSFER = gql`
  mutation createScheduledDepositTransfer($input: CreateScheduledDepositTransferInput!) {
    createScheduledDepositTransfer(input: $input) {
      scheduledTransfer {
        id
      }
    }
  }
`;

export const TRANSFER_BETWEEN_SUB_ACCOUNTS = gql`
  mutation transferBetweenSubAccounts($input: TransferBetweenSubAccountsInput!) {
    transferBetweenSubAccounts(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS = gql`
  mutation transferAllBetweenSubAccounts($input: TransferAllBetweenSubAccountsInput!) {
    transferAllBetweenSubAccounts(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_EXTERNAL_TRANSFER = gql`
  mutation createExternalTransfer($input: CreateExternalTransferInput!) {
    createExternalTransfer(input: $input) {
      manualProcess {
        id
      }
    }
  }
`;
