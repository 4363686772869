import { gql, useMutation, useQuery } from '@apollo/client';

import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import FilterIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'react-i18next';
import ColumnsSelect from './components/columnsSelect';
import EditFilters from './components/editFilters';
import SaveAsReport from './components/saveAsReport';
import {
  Box, Grid, Typography, Link as MuiLink,
  Skeleton,
} from '../../1-primative';
import {
  TablePagination, Card, CardContent,
  Table, TableBody, TableCell, TableRow, TableHeadCell,
  Button, SelectField, MenuItem, Dialog, DialogTitle, DialogContent,
  IconButton, DialogFooter, SegmentedControl,
} from '../../2-component';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import DownloadModal from './components/downloadModal';
import { usePageState } from '../../../util/usePageState';
import { DateTime } from '../../../components/misc/dateTime/dateTime';

const GENERATE_REPORT = gql`
  query generateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      data
      columns
      totalCount
    }
  }
`;

const FETCH_REPORT_OPTIONS = gql`
  query fetchReportOptions($input: FetchReportOptionsInput!) {
    fetchReportOptions(input: $input) {
      filters {
        field
        comparisons {
          comparison
          valueTypes
          options
        }
      }
      columns
    }
  }
`;

const SAVE_REPORT = gql`
  mutation updateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      report {
        id
      }
    }
  }
`;

const ReportBuilder = ({
  initialColumns, initialFilters, initialSortField, initialSortDesc, id, name, initialType,
}: {
  initialColumns: string[], initialFilters: any[], initialSortField: string, initialSortDesc: boolean, id?: string, name?: string, initialType?: string,
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['customReports', 'shared']);
  const { showToast } = useGlobalToast();
  const [drawer, setDrawer] = useState(false);
  const [sortField, setSortField] = usePageState(initialSortField, 'sort');
  const [sortDesc, setSortDesc] = usePageState(initialSortDesc as boolean, 'desc');
  const [type, setType] = useState(initialType || 'USER');
  const [page, setPage] = usePageState(0, 'page');
  const [pageSize, setPageSize] = usePageState(25, 'pageSize');
  const [tab, setTab] = useState('filters');
  const [filters, setFilters] = useState(initialFilters);
  const [columns, setColumns] = useState(initialColumns);

  const { loading, data, previousData } = useQuery(GENERATE_REPORT, {
    variables: {
      input: {
        filters: [{
          field: 'organization',
          comparison: 'EQUALS',
          value: activeOrganization.id,
        }, ...filters],
        columns,
        type,
        pagination: { perPage: pageSize, page: page + 1 },
        sorting: { sortField: type === 'DAILY_STATS' ? 'date' : sortField ?? 'createdAt', sortDesc },
      },
    },
  });

  const { loading: optionsLoading, data: optionsData } = useQuery(FETCH_REPORT_OPTIONS, {
    variables: {
      input: {
        type,
      },
    },
  });

  const [saveReport] = useMutation(SAVE_REPORT, {
    variables: {
      input: {
        reportId: id,
        type,
        columns,
        filters,
        sortDesc,
        sortField,
      },
    },
    onCompleted: () => {
      showToast({ severity: 'info', message: t('savedReport') });
    },
  });

  const handleRequestSort = (
    column: string,
  ) => {
    setSortDesc(!sortDesc);
    setSortField(column);
  };

  const generateLocalFormat = (time: string) => {
    const dayjsLocal = dayjs(time.replace('am', ' am').replace('pm', ' pm'));
    return dayjsLocal;
  };

  const isTimeValue = (val: string): boolean => {
    if (['am', 'pm'].includes(val.slice(-2))) {
      return isNumber(Date.parse(val.replace('am', '').replace('pm', '')));
    }
    if (val.match(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // re update type based on type change
    setType(type);
  }, [type]);

  return (
    <Card sx={{ width: '100%' }} loading={loading}>
      <>
        <CardContent>
          {
            !optionsLoading && (
              <>
                <Dialog
                  open={drawer}
                  fullWidth
                  maxWidth='sm'
                  onClose={() => {
                    setDrawer(false);
                  }}
                >
                  <DialogTitle>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      {t('editReport')}
                      <IconButton onClick={() => setDrawer(false)}><CloseIcon /></IconButton>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <SegmentedControl
                      sx={{ mb: 2 }}
                      value={tab}
                      segments={[{ label: t('filters'), value: 'filters' }, { label: t('columns'), value: 'columns' }]}
                      onChange={(val: any) => setTab(val)}
                    />
                    <Box display={ tab === 'filters' ? 'block' : 'none' }>
                      <EditFilters filters={filters} setFilters={setFilters} availableFilters={optionsData?.fetchReportOptions?.filters} />
                    </Box>
                    <Box display={ tab === 'columns' ? 'block' : 'none' }>
                      <ColumnsSelect
                        columns={columns}
                        setColumns={setColumns}
                        availableColumns={optionsData?.fetchReportOptions?.columns || []}
                        onChange={(e) => setColumns(e.target.value)}
                      />
                    </Box>
                  </DialogContent>
                  <DialogFooter>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button onClick={() => setDrawer(false)} label={t('shared:done')} />
                    </Box>
                  </DialogFooter>
                </Dialog>
                {permissions.includes('write:reports') ? (
                  <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
                    {!id ? (
                        <SelectField
                          value={type}
                          fullWidth
                          onChange={(e: any) => {
                            setType(e.target.value);
                            setColumns(['id']);
                            setFilters([]);
                          }}
                          sx={{ width: '200px' }}
                          label=''
                        >
                          <MenuItem value='USER'>{t('client')}</MenuItem>
                          <MenuItem value='ACCOUNT'>{t('account')}</MenuItem>
                          <MenuItem value='SUB_ACCOUNT'>{t('subAccount')}</MenuItem>
                          <MenuItem value='TRANSFER'>{t('transfer')}</MenuItem>
                          <MenuItem value='TRANSACTION'>{t('transaction')}</MenuItem>
                          <MenuItem value='GOAL'>{t('goal')}</MenuItem>
                          <MenuItem value='CLIENT_GROUP'>{t('clientGroup')}</MenuItem>
                          <MenuItem value='TRADE'>{t('trade')}</MenuItem>
                          <MenuItem value='DAILY_STATS'>{t('dailyStats')}</MenuItem>
                          <MenuItem value='SUB_TRADE_REQUEST'>{t('subTradeRequest')}</MenuItem>
                        </SelectField>
                    ) : (
                      <Typography variant='headingSmall'>{name}</Typography>
                    )}
                    <Box>
                      <IconButton onClick={() => setDrawer(true)} sx={{ mr: 1 }} ><FilterIcon /></IconButton>
                      <DownloadModal type={type} columns={columns} filters={filters} sortField={sortField} sortDesc={sortDesc} />
                      <SaveAsReport type={type} columns={columns} filters={filters} sortField={sortField} sortDesc={sortDesc} afterCreate={() => null} tonal={id !== undefined}/>
                      {id && (
                          <Button onClick={() => saveReport()} leadingIcon={SaveIcon} sx={{ ml: 1 }} label={t('shared:save')} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Grid container padding={1} justifyContent='end'>
                    <Grid item>
                      <DownloadModal type={type} columns={columns} filters={filters} sortField={sortField} sortDesc={sortDesc} />
                    </Grid>
                  </Grid>
                )}
              </>
            )
          }
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                {(data || previousData)?.generateReport.columns.map((column: string) => (
                  <TableHeadCell
                    key={column}
                    sortDirection={sortField === column ? (sortDesc ? 'desc' : 'asc') : false}
                    isSortable
                    onClick={() => handleRequestSort(column)}
                    >
                      {t(`column.${column}`)}
                  </TableHeadCell>
                ))}
              </TableRow>
              {loading && !previousData && [...Array(pageSize)].map((x: any, i: number) => (
                <TableRow key={i}>
                  {(data || previousData)?.generateReport.columns.map((column: string) => (
                    <TableCell key={column}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {(data || previousData)?.generateReport?.data?.map((row: string[], index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  {row.map((column: string, index2: number) => (
                    column.includes('http') ? (
                      <TableCell key={index2}><MuiLink href={column} target='blank'>{t('link')}</MuiLink></TableCell>
                    ) : isTimeValue(column) && /\d/.test(column) ? (<TableCell key={index2}>
                      <DateTime variant='body2' date={generateLocalFormat(column)} timezone='MST' />
                    </TableCell>)
                      : (<TableCell key={index2}>{column}</TableCell>)
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          count={((data || previousData)?.generateReport?.totalCount ?? 0)}
          onPageChange={(_e, newPage) => setPage(newPage)}
          page={page}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
          sx={{
            p: 1,
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'end',
          }}
        />
      </>
    </Card>
  );
};

export default ReportBuilder;
