import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { omit } from 'lodash/fp';
import { Box } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField, TextField,
} from '../../../2-component';
import { OrganizationSelect } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';

const CREATE_DASHBOARD = gql`
mutation createOrganizationDashboard($input: CreateOrganizationDashboardInput!) {
  createOrganizationDashboard(input: $input) {
    dashboard {
      id
    }
  }
}
`;

export const FETCH_DASHBOARDS = gql`
  query fetchOrganizationDashboards($input: FetchOrganizationDashboardsInput!) {
    fetchOrganizationDashboards(input: $input) {
      dashboards{
        id
        name
        organization {
          name
        }
        widgets {
          type
          columns
          filters {
            comparison
            field
            value
          }
          format
          grouping {
            field
            interval
            type
            valueAggregation
            valueField
          }
          height
          width
          x
          y
          reportType
          sortDesc
          sortField
          title
        }
      }
      totalCount
    }
  }
`;

export const NewDashboard = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['customReports']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>(activeOrganization.id ?? '');
  const [open, setOpen] = useState(false);
  const [dashboard, setDashboard] = useState<any>(null);
  const [dashboards, setDashboards] = useState<any>(null);

  const [createDashboardObject, { loading }] = useMutation(CREATE_DASHBOARD, {
    variables: {
      input: {
        name,
        organizationId,
        widgets: dashboard?.widgets ?? [],
      },
    },
  });

  const {
    data,
  } = useQuery(FETCH_DASHBOARDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'name', sortDesc: true, perPage: 100, offSet: 0,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const createDashboard = async (event: any) => {
    const response = await createDashboardObject();
    afterCreate();
    setName('');
    navigate(`editDashboard/${response.data.createOrganizationDashboard.dashboard.id}`);
  };

  useEffect(() => {
    if (data?.fetchOrganizationDashboards) {
      setDashboards(data.fetchOrganizationDashboards.dashboards.map((w: any) => ({
        ...w,
        widgets: w.widgets.map((x: any) => ({
          ...omit(['__typename'], x),
          filters: x.filters.map((filter:any) => omit(['__typename'], filter)),
          grouping: omit(['__typename'], x.grouping),
        })),
      })));
    }
  }, [data]);

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={createDashboard}>
          <DialogTitle display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            {t('addModal.title')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label={t('addModal.name')}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              data-testid='dashboard-name'
              sx={{ mb: 2 }}
              fullWidth
            />
            <SelectField
              label={t('addModal.copyFrom')}
              value={dashboard?.id}
              onChange={(e: any) => setDashboard(dashboards?.find((x: any) => x.id === e.target.value))}
              data-testid='dashboady-copy-from'
              sx={{ mb: 2 }}
              fullWidth
            >
              {
                dashboards?.map((d: any) => (
                  <MenuItem key={d.id} value={d.id}>
                    {d.name}
                  </MenuItem>
                ))
              }
            </SelectField>
            <OrganizationSelect
              value={organizationId}
              label={t('addModal.organization')}
              data-testid='dashboady-organization'
              onChange={(value: string) => setOrganizationId(value)}
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:create')}
                type='submit'
                data-testid='dashboard-create-button'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export const testables = {
  CREATE_DASHBOARD,
};
