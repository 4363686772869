import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress, Typography,
} from '@mui/material';
import { colors } from 'ovComponents';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../wizard';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: '64px',
    height: '64px',
  },

};

type ActionStepProps = {
  loading: boolean,
  succeeded: boolean,
  error: ApolloError | undefined
  memberDisplayName: string
};

export const AddHouseholdMemeberActionStepResult = ({
  context, state, continueTo,
  loading, succeeded, error, memberDisplayName,
}: Omit<StepProps, 'setState' | 'mutationEvent'> & ActionStepProps) => {
  const { t } = useTranslation('addHouseholdMemberWizard');

  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box m={2} mt={5}>
        <Typography align='center'>
          {loading && <CircularProgress size='50px'/>}
          {succeeded && <CheckCircleOutlineIcon sx={{ ...styles.icon, color: colors.positive }} />}
          {error && <HighlightOffIcon sx={{ ...styles.icon, color: colors.negative }} />}
        </Typography>
        {succeeded && (
          <Typography align='center'>
            {t('added', { memberName: memberDisplayName })}
          </Typography>
        )}
        {error && (
          <Typography align='center' sx={{ color: colors.negative }}>
            {t('failed')}
          </Typography>
        )}
      </Box>

      <Box m={2}>
        <Button type='submit' fullWidth variant='contained' onClick={() => continueTo(null)} disabled={!succeeded}>
          {t('done')}
        </Button>
      </Box>

    </Box>
  );
};
