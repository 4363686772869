import { FinancialProduct } from './financialProduct';
import { Goal } from './goal';
import { HoldingReport } from './holdingReport';
import { PortfolioOptimizer } from './portfolioOptimizer';
import { SubAccount } from './subAccount';
import { SubAccountReport } from './subAccountReport';

export enum ModelPortfolioTypes {
  GOAL = 'GOAL',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
}

export enum PortfolioReportRefreshStates {
  UPDATING = 'UPDATING',
  UPDATED = 'UPDATED',
}

export enum BulkTradeRunReasons {
  UNPAIRED = 'UNPAIRED',
  PAIRED = 'PAIRED',
  ILLIQUID = 'ILLIQUID',
  UNBLOCKED_BUYS = 'UNBLOCKED_BUYS',
}

export interface BulkTradeRunReason {
  reason: BulkTradeRunReasons;
  total: number;
  unblockedSince?: Date;
}

export interface PortfolioReport {
  id: string;
  modelPortfolio: FinancialProduct;
  modelPortfolioType: ModelPortfolioTypes;
  object: Goal | SubAccount;
  cashCents: number;
  expectedCashCents: number;
  expectedCashPercentage: number;
  holdings: HoldingReport[];
  driftRebalanceRequired: boolean;
  liquidateRebalanceRequired: boolean;
  transferRebalanceRequired: boolean;
  pendingSubTradeRequests: number;
  lastOptimizedBy: PortfolioOptimizer;
  cashAvailableForTradeCents: number;
  bulkTradeRunReasons: BulkTradeRunReason[];
  subAccounts: SubAccountReport[];
  updatedAt?: Date;
  refreshState: PortfolioReportRefreshStates,
  [key: string]: any;
}
