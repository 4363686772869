import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination, Chip, Tooltip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewRoleProfile from './newRoleProfile';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditRoleProfile from './editRoleProfile';
import { usePageState } from '../../../util/usePageState';

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      totalCount
      roleProfiles {
        id
        organization {
          id
          name
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
        roleCount
        template {
          accessiblePages
          dashboards {
            id
            name
          }
          permissions
          navigationStyle
          nonIndividualPageConfiguration {
            id
          }
          individualPageConfiguration {
            id
          }
          goalPageConfiguration {
            id
          }
          accountPageConfiguration {
            id
          }
          householdPageConfiguration {
            id
          }
          subAccountPageConfiguration {
            id
          }
        }
      }
    }
  }
`;

const RoleProfiles = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [selectedRoleProfile, setSelectedRoleProfile] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              {permissions.includes('write:roles') && (
                <NewRoleProfile afterCreate={refetch} defaultOrg={activeOrganization.id} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('roleProfilesTable.name')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('roleProfilesTable.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchRoleProfiles?.roleProfiles?.map((roleProfile: any) => (
                <TableRow
                  hover
                  key={roleProfile.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    if (roleProfile?.organization.id === activeOrganization.id && permissions.includes('write:role_profiles')) {
                      setSelectedRoleProfile(roleProfile);
                      setUpdateDialogOpen(true);
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    {roleProfile.translatedName.en}
                  </TableCell>
                  <TableCell align="right">
                    {roleProfile.organization.id !== activeOrganization.id ? (
                      <Tooltip title={t('shared:inheritedTooltip', { model: 'role profile', organization: roleProfile.organization?.name })}>
                        <Chip size='medium' label={t('roleProfilesTable.inherited')} />
                      </Tooltip>
                    ) : (
                      roleProfile?.organization?.name
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchRoleProfiles?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />

          {updateDialogOpen && (
            <EditRoleProfile
              roleProfileToUpdate={selectedRoleProfile}
              afterUpdate={() => {
                setUpdateDialogOpen(false);
                refetch();
              }}
              handleClose={() => setUpdateDialogOpen(false)}
              userPermissions={permissions}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default RoleProfiles;
