import { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CreateGoalVisual } from './createGoal.visual';
import { WorkflowContext } from '../../workflowCompletion';

const CREATE_GOAL = gql`
  mutation createGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      goal {
        id
        name
        type
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        name
        type
      }
    }
  }
`;

export type GoalData = {
  name: string | null,
  type: string
};

export const CreateGoal = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { t } = useTranslation('client');
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext);

  const [goalData, setGoalData] = useState<GoalData>({
    name: workflowData?.currentGoalName ?? '',
    type: workflowData?.currentGoalType ?? null,
  });

  const [createGoal, { loading: createLoading }] = useMutation(CREATE_GOAL, {
    onCompleted: async (d: any) => {
      setWorkflowData({
        currentGoalId: d.createGoal.goal.id,
        currentGoalType: d.createGoal.goal.type,
        currentGoalName: d.createGoal.goal.name,
        currentUserId: userId,
      });
      if (updateWorkflowContext) await updateWorkflowContext();
      onNext();
    },
    variables: {
      input: {
        type: goalData.type,
        name: goalData.name || t(`goalType.${goalData.type}`),
        riskQuestion1: 'LEVEL_1',
        timeHorizon: 'VERY_SHORT_TERM',
        userId,
      },
    },
  });

  const [updateGoal, { loading: updateLoading }] = useMutation(UPDATE_GOAL, {
    onCompleted: async (d: any) => {
      setWorkflowData({
        ...workflowData,
        currentGoalName: d.updateGoal.goal.name,
        currentGoalType: d.updateGoal.goal.type,
        currentUserId: userId,
      });
      if (updateWorkflowContext) await updateWorkflowContext();
      onNext();
    },
    variables: {
      input: {
        goalId: workflowData.currentGoalId,
        type: goalData.type,
        name: goalData.name || t(`goalType.${goalData.type}`),
      },
    },
  });

  const onContinue = async () => {
    if (workflowData.currentGoalId) {
      updateGoal();
    } else {
      createGoal();
    }
  };

  return (
    <CreateGoalVisual
      options={options}
      goalData={goalData}
      updateGoal={setGoalData}
      continueFunc={onContinue}
      loading={createLoading || updateLoading || stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default CreateGoal;
