/* eslint-disable react-hooks/exhaustive-deps */
import {
  ListItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import { UserContext } from '../../../providers/userContextProvider';

const CREATE_MODEL = gql`
mutation createModelPortfolio($input: CreateModelPortfolioInput!) {
  createModelPortfolio(input: $input) {
    modelPortfolio {
      id
    }
  }
}
`;

const NewModel = ({ afterCreate, themeId }: { afterCreate: (id: string) => void, themeId?: string }) => {
  const { t } = useTranslation(['buildModels']);
  const { activeOrganization } = useContext(UserContext);
  const [financialProduct, setFinancialProduct] = useState({
    translatedName: {
      en: '',
    },
    organizationId: activeOrganization.id,
  });

  const [createFinancialProduct, { loading }] = useMutation(CREATE_MODEL, {
    variables: {
      input: {
        ...financialProduct,
        themeId,
      },
    },
  });

  useEffect(() => {
    setFinancialProduct({ ...financialProduct, organizationId: activeOrganization.id });
  }, [activeOrganization]);

  const create = async (event: any) => {
    const response = await createFinancialProduct();
    afterCreate(response.data.createModelPortfolio.modelPortfolio.id);
  };

  return (
    <CreateNewModal loading={loading} title={t('modelModal.title')} onSubmit={create} fullWidth >
      <ListItem>
        <TranslatableString label={t('modelModal.name')} value={financialProduct.translatedName} onChange={(e) => setFinancialProduct({ ...financialProduct, translatedName: e })} />
      </ListItem>
      <ListItem>
      <OrganizationSelect
        value={financialProduct.organizationId}
        onChange={(e) => {
          setFinancialProduct({ ...financialProduct, organizationId: e.target.value });
        }}
        label={t('modelModal.organization')}
      />
    </ListItem>
    </CreateNewModal>
  );
};

export default NewModel;
