import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow,
  TableCell, TableBody, Grid, MenuItem, TextField, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { CurrentWebHookType } from 'interfaces/webHook';
import NewWebHook from './newWebHook';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditWebHook from './editWebHook';
import { usePageState } from '../../../util/usePageState';

export const FETCH_WEBHOOKS = gql`
  query fetchWebhooks($input: FetchWebhooksInput!) {
    fetchWebhooks(input: $input) {
      webhooks{
        id
        type
        eventType
        objectType
        gql
        mutationName
        mutationInputName
        url
        signedJwtHeader
        customHeaders
        customPayloadAttributes
        organization{
          id
          name
        }
      }
      totalCount
    }
  }
`;

const WebHooks = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [selectedWebHook, setSelectedWebHook] = useState<CurrentWebHookType>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const { t } = useTranslation(['devSettings']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_WEBHOOKS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'id',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleEditClose = () => {
    setOpenEditModal(false);
  };

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
    setPageSize(15);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-16px -16px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              { permissions.includes('write:webhook') && (
                <NewWebHook afterCreate={refetch} defaultOrg={activeOrganization.id ?? ''} />
              )}
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell ><Typography variant='overline'>{t('webHooksTable.type')}</Typography></TableCell>
                <TableCell ><Typography variant='overline'>{t('webHooksTable.objects')}</Typography></TableCell>
                <TableCell ><Typography variant='overline'>{t('webHooksTable.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchWebhooks?.webhooks?.map((webHook: any) => (
                <TableRow
                  hover
                  key={webHook.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedWebHook(webHook);
                    if (webHook.organization.id === activeOrganization.id && permissions.includes('write:integrations')) {
                      setOpenEditModal(true);
                    }
                  }}
                >
                  <TableCell >
                    <Typography>{t(`webHooksTableOptions.type.${webHook.type}`)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t(`webHooksTableOptions.objectType.${webHook.objectType}`)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{webHook.organization.name}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {selectedWebHook && <EditWebHook currentWebHook={selectedWebHook} open={openEditModal} handleEditClose={handleEditClose} />}
          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchWebhooks?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default WebHooks;
