import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { BillingSchedule, BillingScheduleFrequencies } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';
import BillingScheduleForm from './billingScheduleForm';
import { FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE } from './defaultOrgBillingScheduleBox';
import { FETCH_BILLING_SCHEDULES } from './billingScheduleTable';

export const CREATE_BILLING_SCHEDULE = gql`
  mutation createBillingSchedule($input: CreateBillingScheduleInput!) {
    createBillingSchedule(input: $input) {
      billingSchedule {
        id
      }
    }
  }
`;

const emptyState: BillingSchedule = {
  frequency: BillingScheduleFrequencies.MONTHLY,
  default: false,
};

const NewBillingSchedule = () => {
  const { t } = useTranslation(['feeAndBilling']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [billingSchedule, setBillingSchedule] = useState<BillingSchedule>(emptyState);
  const [valid, setValid] = useState(false);

  const [createBillingSchedule, { loading }] = useMutation(CREATE_BILLING_SCHEDULE, {
    refetchQueries: [FETCH_ORGANIZATION_DEFAULT_BILLING_SCHEDULE, FETCH_BILLING_SCHEDULES],
  });

  const create = async (event: any) => {
    event.preventDefault();
    const inputProps = {
      organizationId: activeOrganization.id,
      default: billingSchedule?.default ?? false,
      frequency: billingSchedule.frequency ?? BillingScheduleFrequencies.MONTHLY,
      nextBillingDate: billingSchedule.nextBillingDate,
      nextCalculationDate: billingSchedule.nextCalculationDate,
    };
    const response = await createBillingSchedule({
      variables: {
        input: inputProps,
      },
    });
    if (response?.data) {
      showToast({ severity: 'info', message: t('feeAndBilling:billingSchedule.new.createdToastMessage') });
      setBillingSchedule(emptyState);
    }
  };

  return (
    <CreateNewModal
      state={billingSchedule}
      initialState={emptyState}
      loading={loading} disabled={!valid} title={t('feeAndBilling:billingSchedule.new.title')} onSubmit={create} fullWidth sx={{ marginTop: 1 }} >
      <BillingScheduleForm billingSchedule={billingSchedule} setBillingSchedule={setBillingSchedule} setValid={setValid} organizationId={activeOrganization.id} />
    </CreateNewModal>
  );
};

export default NewBillingSchedule;
