import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Filler,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { formatMoneyValue } from '../../../util';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useBarChartTokens } from './barChart.tokens';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Filler,
  Tooltip,
);

const abbreviateNumber = (value: number) => {
  let newValue = value.toString();
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(((`${value}`).length - 1) / 3);
    let shortValue = 0;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / 1000 ** suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (`${shortValue}`).replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 3) { break; }
    }
    let shortValueString = shortValue.toString();
    if (shortValue % 1 !== 0) shortValueString = shortValue.toFixed(1);
    newValue = shortValueString + suffixes[suffixNum];
  }
  return newValue;
};

export const BarChart = ({ labels, datasets, format = 'CENTS' }: { labels: string[], datasets: any[], format?: 'CENTS' | 'FLOAT' }) => {
  const { comp } = useThemeTokens(useBarChartTokens());

  const INDEX = 'index';
  const NEAREST = 'nearest';
  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      tooltip: {
        position: 'nearest' as typeof NEAREST,
        callbacks: {
          label: (data: any) => `${format === 'CENTS' ? formatMoneyValue(data.raw) : data.raw}`,
        },
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      mode: 'index',
    },
    scales: {
      x: {
        display: comp.barChart.showAxis || true,
        grid: {
          display: false,
        },
        ticks: {
          enabled: comp.barChart.showAxis || true,
          maxRotation: 0,
        },
      },
      y: {
        display: comp.barChart.showAxis || true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value: any, index: number, all: any) => (format === 'CENTS' ? `$${abbreviateNumber(Math.floor(value / 100))}` : value),
          maxTicksLimit: 4,
        },
      },
    },
    layout: {
      padding: {
        right: comp.barChart.showAxis ? 16 : 0,
        bottom: comp.barChart.showAxis16 ? 16 : 0,
      },
    },
  };

  const fullData = {
    labels,
    datasets: (datasets || []).map((x: any, index: number) => ({
      fill: true,
      label: x.label,
      data: x.data,
      borderColor: comp.barChart.color,
      backgroundColor: comp.barChart.color,
      borderRadius: comp.barChart.radius,
    })),
  };

  return (
    <Bar options={options} data={fullData} />
  );
};
