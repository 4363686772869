import {
  Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import CreateNewModal from 'components/modals/createNewModal';
import dayjs from 'dayjs';
import {
  EntityTypes,
  Languages, MethodOfCommunication, User,
} from 'interfaces';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'providers/userContextProvider';
import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { pick } from 'lodash/fp';
import ConfirmationModal from 'components/modals/confirmationModal';
import { AddressField, colors } from 'ovComponents';
import { EntityTypeSelect } from 'components/inputs/entityTypeSelect';
import { FETCH_USER_AUDIT_LOG } from 'components/tables/auditLog';
import { CountryCodes } from '@onevesthq/ov-enums';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';
import { FETCH_USER, phoneValidator } from '..';
import { UPDATE_USER } from './clientDetails';

export const EditClientDetails = ({
  userOriginal,
  selectedHighlight,
  onClose,
}: {
  userOriginal: User,
  selectedHighlight: string
  onClose: () => void
}) => {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const [phone, setPhone] = useState<string>(userOriginal.phone ?? '');
  const [user, setUser] = useState<User>(userOriginal);
  const [authUpdateConfirmationOpen, setAuthUpdateConfirmationOpen] = useState(false);

  const [updateUserMutation] = useMutation(UPDATE_USER, {
    variables: {},
    refetchQueries: [FETCH_USER(permissions), FETCH_USER_AUDIT_LOG(permissions)],
  });

  const validPhysicalAddress: boolean = useMemo(() => {
    const address = user.physicalAddress;
    if (!address) return false;
    if (!address.country) return false;
    if (address.country === CountryCodes.CA && !address.jurisdiction) return false;
    if (address.country === CountryCodes.US && !address.jurisdiction) return false;
    if (!address.city || !address.streetName || !address.postal || !address.houseNumber) return false;
    return true;
  }, [user.physicalAddress]);

  const applicableJurisdictions = user?.organization?.jurisdictions;

  const physicalAddressOutOfJurisdiction: boolean = useMemo(() => {
    const address = user.physicalAddress;
    if (!address?.jurisdiction) return false;
    if (!applicableJurisdictions) return false;
    if (applicableJurisdictions.all === true) return false;
    if (!applicableJurisdictions.only || applicableJurisdictions.only.length === 0) return false;
    return !applicableJurisdictions.only.includes(address?.jurisdiction);
  }, [user.physicalAddress, applicableJurisdictions]);

  const validEmail = (email: string | undefined): boolean => {
    if (email === undefined) return false;
    const rule = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return rule.test(email);
  };

  const editingFormValid = (): boolean => {
    if (!user) return false;
    if (user.affiliateOnly) {
      return (
        (
          !permissions.includes('write:client_low_risk_pii') || (
            user.firstName !== ''
            && user.lastName !== ''
          )
        )
      );
    }
    return (
      (
        !permissions.includes('write:client_low_risk_pii') || (
          user.firstName !== ''
          && user.lastName !== ''
        )
      )
      && (!permissions.includes('write:client_high_risk_pii') || (
        user.dateOfBirth !== ''
      ))
      && validPhysicalAddress
      && !physicalAddressOutOfJurisdiction
      && phoneValidator(user.phone ?? '')
      && validEmail(user.primaryEmail)
    );
  };

  const isIndividual = user.type === 'INDIVIDUAL';
  const isReferralOrg = user?.organization.isReferral ?? false;
  const canEditAuthCredentials = permissions.includes('write:client_authentication');
  const diableAuthCredentialsFields = isReferralOrg ? !canEditAuthCredentials : false;
  const didEmailUpdate: boolean = user?.primaryEmail !== userOriginal?.primaryEmail;
  const didPhoneUpdate: boolean = user?.phone !== userOriginal?.phone;

  const getUpdatedAuthFields = (): string => {
    if (!isReferralOrg) return '';
    if (didEmailUpdate && didPhoneUpdate) return 'emailAndPhone';
    if (didEmailUpdate) return 'email';
    if (didPhoneUpdate) return 'phone';
    return '';
  };

  const onSubmitClicked = () => {
    if (getUpdatedAuthFields() !== '') {
      setAuthUpdateConfirmationOpen(true);
      return;
    }
    doUpdateUser();
  };

  const doUpdateUser = async () => {
    if (!user) return;
    const changedObjects = (Object.keys(user) as (keyof typeof user)[]).filter((key) => user[key] !== userOriginal[key]);
    await updateUserMutation({
      variables: {
        input: {
          userId: user?.id,
          ...pick(changedObjects, user),
        },
      },
    });
    onClose();
  };

  let addressFieldLabel = t('client:details.physicalAddress');
  let addressFieldError = false;
  if (!validPhysicalAddress) {
    addressFieldError = true;
    addressFieldLabel += `: ${t('client:profile.fieldValidations.noEmpty')}`;
  } else if (physicalAddressOutOfJurisdiction) {
    addressFieldError = true;
    addressFieldLabel += ': ';
    addressFieldLabel += t('client:profile.fieldValidations.addressOutOfJurisdictionMessage', {
      ALLOWED_JURISDICTIONS: (applicableJurisdictions?.only ?? [])?.map((j) => j.replace(/^(CA|US)_/, '')).join(', '),
    });
  }

  return (
  <>
  <CreateNewModal
    loading={false}
    forceOpen={true}
    buttonType="NONE"
    buttonText={t('buttons:save')}
    title={t('details.editClientDetail')}
    onSubmit={onSubmitClicked}
    buttonVariant="contained"
    onClose={onClose}
    disabled={!editingFormValid()}
  >
    <Grid container spacing={2} padding={2}>
      {permissions.includes('write:client_low_risk_pii') && <>
        {isIndividual && <>
          <Grid item xs={12}>
            <TextField
              label={t('details.firstName') + (!user?.firstName || user?.firstName === '' ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
              fullWidth
              required
              value={user?.firstName}
              error={!user?.firstName || user?.firstName === ''}
              onChange={(e) => {
                if (user) {
                  setUser({ ...user, firstName: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('details.middleName')}
              fullWidth
              value={user?.middleName}
              onChange={(e) => {
                if (user) {
                  setUser({ ...user, middleName: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('details.lastName') + (!user?.lastName || user?.lastName === '' ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
              fullWidth
              required
              value={user?.lastName}
              error={!user?.lastName || user?.lastName === ''}
              onChange={(e) => {
                if (user) {
                  setUser({ ...user, lastName: e.target.value });
                }
              }}
            />
          </Grid>
        </>
        }
        {!isIndividual && <>
          <Grid item xs={12}>
            <TextField
              label={t('details.entityName') + (!user?.entityName ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
              fullWidth
              required
              value={user?.entityName}
              error={!user?.entityName}
              onChange={(e) => {
                if (user) {
                  setUser({ ...user, entityName: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EntityTypeSelect
              nonIndividual
              value={user.type}
              setValue={(newValue) => setUser({ ...user, type: newValue as EntityTypes })}
              autoFocus={selectedHighlight === 'entityType'}
            />
          </Grid>
        </>
        }
        <Grid item xs={12}>
          <TextField
            label={t('details.primaryEmail') + (!validEmail(user?.primaryEmail) ? `: ${t('client:profile.fieldValidations.primaryEmail')}` : '')}
            fullWidth
            autoFocus={selectedHighlight === 'primaryEmail'}
            value={user?.primaryEmail}
            disabled={diableAuthCredentialsFields}
            error={!validEmail(user?.primaryEmail)}
            helperText={(isReferralOrg && !didEmailUpdate) ? t('client:profile.helpText.email') : undefined}
            FormHelperTextProps={{
              style: { color: colors.positive },
            }}
            onChange={(e) => {
              if (user) {
                setUser({ ...user, primaryEmail: e.target.value });
              }
            }} />
        </Grid>
        <Grid item xs={12}>
          <NumberFormat
            customInput={TextField}
            label={(phoneValidator(user?.phone ?? '') ? `${t('details.phone')}` : `${t('client:profile.fieldValidations.canadianNumberValidation')}`)}
            fullWidth
            format="+1 (###) ###-####"
            mask=""
            autoFocus={selectedHighlight === 'phone'}
            value={phone}
            disabled={diableAuthCredentialsFields}
            helperText={(isReferralOrg && !didPhoneUpdate) ? t('client:profile.helpText.phone') : undefined}
            FormHelperTextProps={{
              style: { color: colors.positive },
            }}
            error={!phoneValidator(user?.phone ?? '')}
            onValueChange={(values: any) => {
              const { formattedValue, value } = values;
              if (user) {
                setPhone(formattedValue);
                setUser({ ...user, phone: value });
              }
            }}
          />
        </Grid>
      </>
      }
      {isIndividual && permissions.includes('write:client_high_risk_pii') && (
        <Grid item xs={12}>
          <LocalizedDatePicker
            label={t('details.dateOfBirth') + ((!user?.dateOfBirth) ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
            value={user?.dateOfBirth}
            onChange={(date) => {
              if (user) {
                setUser({ ...user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') });
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} error={!(user?.dateOfBirth)} autoFocus={selectedHighlight === 'dateOfBirth'} />}
          />
        </Grid>
      )}
      {!isIndividual && (
        <Grid item xs={12}>
          <LocalizedDatePicker
            label={t('details.establishedDate') + ((!user?.establishedDate) ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
            value={user?.establishedDate}
            onChange={(date) => {
              if (user) {
                setUser({ ...user, establishedDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} error={!(user?.establishedDate)} autoFocus={selectedHighlight === 'establishedDate'} />}
          />
        </Grid>
      )}
      {permissions.includes('write:client_high_risk_pii') && (
        <Grid item xs={12}>
          <AddressField
            label={addressFieldLabel}
            manualAddressEntry
            address={user?.physicalAddress}
            onChange={(physicalAddress) => {
              if (user) {
                setUser({ ...user, physicalAddress });
              }
            }}
            autoFocus={selectedHighlight === 'physicalAddress'}
            error={addressFieldError}
          />
        </Grid>
      )}
      {isIndividual && permissions.includes('write:client_high_risk_pii') && user?.physicalAddress?.jurisdiction === 'CA_QC' && (
        <Grid item xs={12}>
          <LocalizedDatePicker
            label={t('details.inProvinceSince') + ((!user?.inProvinceSince) ? `: ${t('client:profile.fieldValidations.noEmpty')}` : '')}
            value={user?.inProvinceSince}
            onChange={(date) => {
              if (user) {
                setUser({ ...user, inProvinceSince: dayjs(date?.toString()).format('YYYY-MM-DD') });
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} error={!(user?.inProvinceSince)} autoFocus={selectedHighlight === 'inProvinceSince'} />}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl>
          <FormLabel color="secondary">{t('components:languages.label')}</FormLabel>
          <RadioGroup row
            value={user?.language ?? 'ENGLISH'}
            onChange={(e, value) => {
              if (user) {
                setUser({ ...user, language: value.toUpperCase() as Languages });
              }
            }}
          >
            <FormControlLabel value="ENGLISH" control={<Radio />} label={t('edit.languageOptions.ENGLISH')} />
            <FormControlLabel value="FRENCH" control={<Radio />} label={t('edit.languageOptions.FRENCH')} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel color="secondary">{t('components:preferredMethodOfCommunication.label')}</FormLabel>
          <RadioGroup row
            value={user?.preferredMethodOfCommunication}
            onChange={(e, value) => {
              if (user) {
                setUser({ ...user, preferredMethodOfCommunication: value.toUpperCase() as MethodOfCommunication });
              }
            }}
          >
            <FormControlLabel value="EMAIL" control={<Radio />} label={t('edit.preferredMethodOfCommunicationOptions.EMAIL')} />
            <FormControlLabel value="PHONE" control={<Radio />} label={t('edit.preferredMethodOfCommunicationOptions.PHONE')} />
            <FormControlLabel value="TEXT" control={<Radio />} label={t('edit.preferredMethodOfCommunicationOptions.TEXT')} />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  </CreateNewModal>
  <ConfirmationModal
    title={t(`client:authCredentialsUpdateConfirmation.${getUpdatedAuthFields()}.title`)}
    bodyText={t(`client:authCredentialsUpdateConfirmation.${getUpdatedAuthFields()}.subtitle`)}
    open={authUpdateConfirmationOpen}
    maxWidth='sm'
    confirmButton={t('shared:yes')}
    cancelButton={t('shared:no')}
    onConfirm={async () => {
      setAuthUpdateConfirmationOpen(false);
      doUpdateUser();
    }}
    onCancel={() => {
      setAuthUpdateConfirmationOpen(false);
    }}
  />
  </>
  );
};
