/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { Button, Selector } from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { AppBreadcrumbs } from '../../../components/layout/appBreadcrumbs';
import { NavigationStyles } from '../../../interfaces';
import filterHouseholdsWithAccess from '../../../util/filterHouseholdsWithAccess';
import { ClientReportWidget } from '../clientReportTemplate/components/pageConfiguration/clientReportWidget';
import { translateBackend } from '../../../assets/i18n/config';
import { useClientReportContext } from '../../../providers/clientReportContextProvider';
import { BetaBadge } from '../../3-pattern';

export const ViewClientReportVisual = ({
  clientReport, onPrint, printLoading,
}: {
  clientReport: any, onPrint: () => void, printLoading: boolean
}) => {
  const { t } = useTranslation('shared');
  const {
    timePeriods, setTimePeriod, timePeriod, setObjectContext, setReportFrequency,
  } = useClientReportContext();
  const {
    clientGroupId, goalId, accountId, subAccountId, objectId, userId,
  } = useParams<{
    clientGroupId: string, goalId: string, accountId: string, subAccountId: string, objectId: string, userId: string,
  }>();
  const { userContext, activeEntity, activeOrganization } = useContext(UserContext);
  const { frequency } = clientReport.scheduler;
  const { type } = clientReport.clientReportTemplatePageConfiguration;
  const navFull = userContext.role?.navigationStyle === NavigationStyles.FULL;

  const households = filterHouseholdsWithAccess((activeEntity?.households || []), activeEntity?.id);
  const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && households.length > 0;

  const id = () => {
    const baseId = subAccountId || accountId || goalId;
    return baseId || (isHouseholdDefaultView ? userId || objectId || clientGroupId : clientGroupId || objectId || userId);
  };

  useEffect(() => {
    setReportFrequency(frequency);
    setObjectContext({
      objectType: type,
      objectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, frequency, type]);

  return (
    <Box>
      {timePeriods && timePeriods.length > 0 && timePeriod && <Box display='flex' sx={{
        flexDirection: { xs: 'column', sm: 'row' },
      }} justifyContent={navFull ? 'start' : 'center'} alignItems='start'>
        <Box maxWidth={`${clientReport.options?.maxPageWidth || 1080}px`} width='100%' mt={2} ml={{ xs: 0, sm: 2 }}>
          {activeOrganization.displayMenuBar && userContext.role?.navigationStyle === NavigationStyles.SIMPLE && (
            <AppBreadcrumbs />
          )}
          <Box display='flex' justifyContent='space-between' alignItems='end'>
            <Box display='flex' justifyContent='start' alignItems='center' gap={2}>
              <Typography variant='displayLargeVariant'>{translateBackend(clientReport.translatedName)}</Typography>
              <Selector
                readOnly={frequency === 'ONE_TIME'}
                options={timePeriods.map((x: any) => ({ label: x.label, type: x.label }))}
                selectedOptionType={timePeriod.label}
                onChange={(e: any) => setTimePeriod(timePeriods.find((x: any) => x.label === e.target.value)!)}
              />
              <BetaBadge/>
            </Box>
            <Button label={t('print')} leadingIcon={Print} variant='tonal' onClick={onPrint} loading={printLoading} />
          </Box>
          <Typography colorVariant='variant' sx={{ mt: 2, mb: 3 }}>{translateBackend(clientReport.translatedDescription)}</Typography>
          {id() && clientReport.clientReportTemplatePageConfiguration.widgets?.map((item: any, i: number) => (
            <Box key={`${item.type}-${JSON.stringify(item.options)}-${i}`} sx={{
              marginBottom: '20px',
            }}>
              <ClientReportWidget
                type={item.type}
                i={i}
                objectId={id()!}
                viewOnly
                options={item.options}
                objectType={clientReport.clientReportTemplatePageConfiguration.type}
                widgetId={item.id}
                userId={objectId || userId || activeEntity?.id}
              />
            </Box>
          ))}
        </Box>
      </Box>}
    </Box>
  );
};
