import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { FETCH_ACCOUNT } from 'pages/accountsDetail';
import {
  ClientGroup, SubAccount, SubAccountStates, getSubAccountName,
} from 'interfaces';
import { useState } from 'react';
import { ListItem, Typography } from '@mui/material';
import { colors } from 'ovComponents';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';

const FETCH_SUBACCOUNTS_OF_ACCOUNT = gql`
  query fetchSubAccounts($accountId: ObjectID!) {
    fetchSubAccounts(input: { filter: { accountId: $accountId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state
        goal {
          id
          type
          name
          householdClientGroup { id }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

const ASSOCIATE_ACCOUNT_TO_HOUSEHOLD = gql`
  mutation associateAccountToHousehold($accountId: ObjectID!, $clientGroupId: ObjectID!) {
    updateAccount (
      input: {
        accountId: $accountId
        householdClientGroupId: $clientGroupId
      }
    ) {
      account {
        id
      }
    }
  }
`;

export const AssociateAccountToHouseholdModal = ({
  handleClose, accountId, household,
}: { accountId: string, handleClose: () => void, household: ClientGroup }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const [outOfHouseholdSubaccounts, setOutOfHouseholdSubaccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_ACCOUNT, {
    variables: { accountId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setOutOfHouseholdSubaccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
    },
  });

  const associationCompleted = (): void => {
    handleClose();
    showToast({ message: t('accountsDetail:associateAccountDialog.success'), severity: 'success' });
  };

  const [associateAccountMutation] = useMutation(ASSOCIATE_ACCOUNT_TO_HOUSEHOLD, {
    variables: {
      accountId,
      clientGroupId: household.id,
    },
    refetchQueries: [FETCH_ACCOUNT(permissions), FETCH_ACCOUNTS(permissions), FETCH_USER(permissions)],
    onCompleted: associationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('accountsDetail:associateAccountDialog.title', { name: household.name })}
      open={true}
      maxWidth='sm'
      confirmButton={t('accountsDetail:associateAccountDialog.continueButton')}
      cancelButton={t('accountsDetail:associateAccountDialog.cancelButton')}
      onConfirm={associateAccountMutation}
      onCancel={handleClose}
    >
      <Typography sx={{ minHeight: '6em' }}>
        {loading && <Typography color={colors.neutral400}>{t('accountsDetail:associateAccountDialog.checkingSubaccounts')}...</Typography>}
        {outOfHouseholdSubaccounts.length > 0
        && <>
          {t('accountsDetail:associateAccountDialog.thereAreSubAccounts')}
          {outOfHouseholdSubaccounts.map((sa) => <ListItem key={sa.id}>{getSubAccountName(sa)}</ListItem>)}
        </>
        }
        {!loading && outOfHouseholdSubaccounts.length === 0 && <>
          {t('accountsDetail:associateAccountDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
