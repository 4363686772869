import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import { UserContext } from 'providers/userContextProvider';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { kebabCase } from 'lodash';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, Form, Box, Grid, SelectionTile,
} from '../../../..';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { HouseholdSelection, ObjectTypes } from './components/householdSelection';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const EditGoalVisual = ({
  options, goalData, loading, updateGoal, continueFunc, grid = false, updateMode = false,
  activeCustomFields, workflowCompletion, ignoreCustomFieldTriggerRules,
}: {
  options: any, goalData: any, loading: boolean, updateGoal: any, continueFunc: any, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], workflowCompletion?: any,
  ignoreCustomFieldTriggerRules?: boolean,
}) => {
  const { sys } = useThemeTokens();
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'shared']);
  const [focused, setFocused] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const { activeOrganization } = useContext(UserContext);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  const submit = () => {
    const fields = invalidFields({
      ...options,
    }, goalData);
    setFocused(fields);
    if (fields.length === 0) {
      sendAnalytic(
        ovAnalyticsEvents.workflowsEditGoalContinueButtonSelect,
        {
          workflowStepTitle: options?.title,
          workflowId: workflowCompletion?.workflow?.id,
          workflowName: workflowCompletion?.workflow?.name,
          activeWorkflowCompletionId: workflowCompletion?.id,
          objectId: workflowCompletion?.objectId,
          objectType: workflowCompletion?.objectType,
        },
      );
      continueFunc();
    }
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateGoal(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const enableHousehold: boolean = activeOrganization.availableFeatureFlags?.includes('HOUSEHOLD') ?? false;
  const defaultLinkToHousehold = (enableHousehold && options?.defaultLinkToHousehold) ?? false;
  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        {options?.name?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="goal-name"
              onChange={(e: any) => update({ ...goalData, name: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.name?.label)}
              infoTooltip={additionalInfo(options?.name?.additionalInfo)}
              fullWidth
              sx={{ mr: 2 }}
              value={goalData.name}
              onBlur={() => setFocused([...focused, 'name'])}
              error={!goalData.name && focused.includes('name') && options?.name?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.targetAmountCents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              testId="goal-target-amount"
              type='dollar'
              onChange={(e: any) => update({ ...goalData, targetAmountCents: e.target.value * 100 })}
              disabled={loading}
              label={translateBackend(options?.targetAmountCents?.label)}
              infoTooltip={additionalInfo(options?.targetAmountCents?.additionalInfo)}
              fullWidth
              sx={{ mt: 2, mr: 2 }}
              value={goalData.targetAmountCents / 100}
              leadingIcon='dollar'
              onBlur={() => setFocused([...focused, 'targetAmountCents'])}
              error={!goalData.targetAmountCents && focused.includes('targetAmountCents') && options?.targetAmountCents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {(options?.timeHorizon?.enabled && options?.options) ? (
          <Grid item xs={12}>
            <Typography variant='labelSmall' sx={{ color: sys.color.onSurfaceVariant, mb: 1, mt: 2 }}>
              {translateBackend(options?.timeHorizon?.label)}
              {additionalInfo(options?.timeHorizon?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.timeHorizon?.additionalInfo) ?? ''} />)}
            </Typography>
            <SelectionTile
              testId="goal-time-horizon"
              onChange={(e: any) => updateGoal({ ...goalData, timeHorizon: e.target.value })}
              value={goalData.timeHorizon}
              options={options?.options?.map((option: any) => (
                { value: option.value, label: translateBackend(option.label) }
              ))}
            />
          </Grid>
        )
          : ((options?.timeHorizon?.enabled && !options?.options)
            && <Grid item xs={12}>
              <Typography variant='labelSmall' sx={{ color: sys.color.onSurfaceVariant, mb: 1, mt: 2 }}>{t('client:timeHorizon')}</Typography>
              <SelectionTile
                testId="goal-time-horizon"
                onChange={(e: any) => updateGoal({ ...goalData, timeHorizon: e.target.value })}
                value={goalData.timeHorizon}
                options={[
                  { value: 'VERY_SHORT_TERM', label: t('timeHorizonOptions.VERY_SHORT_TERM') },
                  { value: 'SHORT_TERM', label: t('timeHorizonOptions.SHORT_TERM') },
                  { value: 'MID_TERM', label: t('timeHorizonOptions.MID_TERM') },
                  { value: 'LONG_TERM', label: t('timeHorizonOptions.LONG_TERM') },
                ]}
                error={!goalData.timeHorizon && focused.includes('timeHorizon') && options?.timeHorizon?.required !== 'NOT_REQUIRED'}
                errorText={t('shared:required')}
              />
            </Grid>
          )}
        {defaultLinkToHousehold && <Grid item xs={12} md={12} sx={{
          mb: 1,
          mt: 2,
          mr: 2,
        }}>
          <HouseholdSelection
            data-testid="house-hold-select"
            data={goalData}
            infoTooltip={additionalInfo(options?.defaultLinkToHousehold?.additionalInfo)}
            updateData={updateGoal}
            objectType={ObjectTypes.GOAL}
          />
        </Grid>
        }
        {customOptionSelected && (<CustomFieldRenderer
          customOptions={customOptionSelected}
          customData={goalData}
          update={update}
          grid={grid}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
          ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
        />)}
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button dataTestId={`edit-goal-${kebabCase(translateBackend(options.title))}-continue-button`}
         label={t(updateMode ? 'update' : 'continue')} disabled={loading || (!updated && updateMode)} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default EditGoalVisual;
