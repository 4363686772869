import { circularProgressClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { Card, CardContent, CircularProgress } from '../../2-component';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

export const GoalCompletion = ({ totalMarketValue, goalValue }: { totalMarketValue: number, goalValue: number }) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation('shared');

  const progressValue = (totalMarketValue && goalValue) ? (totalMarketValue / goalValue) * 100 : 0;

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ p: 4 }}>
        <Box display='flex' justifyContent='center'>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant='determinate'
                value={100}
                size='200px'
                thickness={2}
                sx={{
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                  color: sys.color.disabled,
                }}
              />
              <CircularProgress
                variant='determinate'
                value={progressValue <= 100 ? progressValue : 100}
                size='200px'
                thickness={2}
                sx={{
                  position: 'absolute',
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant='headingLarge'
                sx={{ fontSize: '40px' }}
              >{`${Math.round(progressValue)}%`}</Typography>
              <Typography variant='labelLarge' sx={{ mt: 1 }}>{t('completed')}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
