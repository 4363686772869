/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { translateBackend } from 'assets/i18n/config';
import { round } from 'lodash';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { isNull, isUndefined } from 'lodash/fp';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, SubAccountSelect, AmountField, TextField, SelectField, MenuItem, AccountTypeSelect, DateField, PageObjectType, RadioGroup, Stack, Radio,
} from '../../../..';
import { BankAccountSelectField } from '../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import { BankSelectField } from '../../../../3-pattern/bankSelectField/bankSelect';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { validateTransfer, ValidateTransferResponse } from '../../../../accountRestrictions';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { TransferErrorBanner } from '../../../../transferErrorBanner';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { DepositTransferContext } from '../../../depositWorkflow/depositWorkflow';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';
import { Alert } from '../../../../2-component/alert/alert';
import { addBusinessDays, isBusinessDay } from '../../../../../util/businessDays';

interface DepositAccountConfigInterface {
  [key: string]: {
    endOfPriorFiscalYear: Dayjs;
  };
}

const iraEndOfPriorFiscalYear = dayjs().date(15).month(3).endOf('day');

export const depositAccountConfig: DepositAccountConfigInterface = {
  USA_INH_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_INH_RT_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_RO_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_RT_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_SEP_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
};

export const showFiscalYear = (accountType: string) => {
  const today = dayjs();
  const firstDayOfYear = dayjs().startOf('year');
  const config = depositAccountConfig[accountType];
  if (config) {
    return today.isBefore(config.endOfPriorFiscalYear) && today.isAfter(firstDayOfYear);
  }
  return false;
};

export const FETCH_TOTAL_TRANSFERS = gql`
  query fetchTransfers($input: FetchTransfersInput!) {
    fetchTransfers(input: $input) {
      totalCount
    }
  }
`;

export const FETCH_USER_LOCALIZATION = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        physicalAddress {
          country
        }
        countryOfTaxResidence
      }
    }
  }
`;
export const DepositForm = ({
  options, onNext, dummyDeposit, setDummyDeposit,
}: { options: any; onNext: any; dummyDeposit?: any; setDummyDeposit?: any }) => {
  const lockedInAccounts = ['LIRA', 'LRSP', 'RLSP'];
  const incomeFundAccounts = ['RRIF', 'RIF_SPOUSAL', 'LIF', 'PRIF', 'LRIF', 'RLIF'];
  const { t } = useTranslation('workflowCompletions');
  const { showToast } = useGlobalToast();
  const { activeOrganization, userContext } = useContext(UserContext);
  const enableInKind: boolean = activeOrganization.availableFeatureFlags?.includes('IN_KIND_TRANSFERS') ?? false;
  const { transferData, setTransferData } = useContext(DepositTransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const applicableData = dummyDeposit ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyDeposit ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;
  const { permissions } = usePermissions();
  const [focused, setFocused] = useState<string[]>([]);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const { userId: paramsUserId } = useParams();
  const { sys } = useThemeTokens();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const minRecurringDeposit = activeOrganization.minRecurringDepositCents;
  const minAmountDeposit = activeOrganization.minDepositCents;
  const minInitialDeposit = activeOrganization.minInitialDepositCents;
  const [starting, setStarting] = useState<'now' | 'futureDate'>('now');
  const hyperLinkColor = sys.color.negativeOutline;
  const supportUrl = userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new';
  const currentYear = dayjs().year();
  const buttonValidationAmountCents: boolean = transferData.amountCents === 0;
  const [totalTransfers, setTotalTransfers] = useState<number | null>(null);
  const [dateHasChanged, setDateHasChanged] = useState(false);
  const [validateErrorAmounts, setValidateErrorAmounts] = useState<{ hasError: boolean; errorMessage: string }>({
    hasError: false,
    errorMessage: '',
  });
  const { data: localizationData } = useQuery(FETCH_USER_LOCALIZATION, {
    variables: { userId },
    skip: !userId,
  });

  const transferDate = applicableData?.scheduledDate;
  const delayDays = options?.transferDelay?.number;
  const transferCountry = applicableData?.bankAccount?.bankAccountTransitPhysicalAddress?.country
    || localizationData?.fetchUser?.user?.physicalAddress?.country
    || localizationData?.fetchUser?.user?.countryOfTaxResidence
    || activeOrganization.applicableLocalization.countries[0];

  const clearValidateErrorAmountsState = () => {
    setValidateErrorAmounts({
      hasError: false,
      errorMessage: '',
    });
  };

  const [fetchTotal] = useLazyQuery(FETCH_TOTAL_TRANSFERS);
  const handleTotalTransfers = (subAccount: any) => {
    fetchTotal({
      variables: {
        input: {
          filter: {
            subAccountId: subAccount.id,
          },
        },
      },
      onCompleted: (data: any) => {
        setTotalTransfers(data.fetchTransfers?.totalCount);
        checkValidationErrors(data.fetchTransfers?.totalCount);
      },
    });
  };

  const isInitiatedByRepresentative = () => permissions?.includes('write:transfer_obo_clients') && !permissions?.includes('write:transfer_basic');

  const confirmTransferDelay = ({ scheduledDate, days, country }: { scheduledDate: Date; days: number; country: string }) => {
    const today = new Date();

    const minTransferDate = addBusinessDays(today, days, country);

    return {
      dateIsAfter: dayjs(scheduledDate).isSame(minTransferDate, 'day') || dayjs(scheduledDate).isAfter(minTransferDate),
      minTransferDate: dayjs(minTransferDate).format('MM/DD/YYYY'),
    };
  };

  const isEft = applicableData?.type === 'EFT';
  const isScheduled = starting !== 'now';
  const transferDelayCheck = confirmTransferDelay({ scheduledDate: transferDate, days: delayDays, country: transferCountry });
  const invalidTransferDelayCheck = (isEft && isScheduled && options?.transferDelay?.enabled
    && (!transferDelayCheck.dateIsAfter || !isBusinessDay({ date: dayjs(transferDate).utc().toDate(), country: transferCountry })));

  const scheduledDateError = (!transferDate && options?.scheduledDate?.required !== 'NOT_REQUIRED')
    || !isBusinessDay({ date: dayjs(transferDate).utc().toDate(), country: transferCountry })
    || (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter);

  const scheduledDateErrorMessage = () => {
    if ((options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter)) {
      return t('bankAccountTransferCreation.transferDelayError', {
        date: `${dayjs(transferDelayCheck.minTransferDate).format('MM/DD/YYYY')}`,
      });
    }
    return t('bankAccountTransferCreation.enterAValidDate');
  };

  const checkValidationErrors = (transfersCount: number | null) => {
    if (applicableData?.type !== 'EFT') {
      return;
    }

    const isRecurringDeposit = applicableData?.schedule !== 'ONE_TIME';
    const isLessThanMinRecurringDeposit = isRecurringDeposit && !!minRecurringDeposit && applicableData?.amountCents < minRecurringDeposit;

    if (isLessThanMinRecurringDeposit) {
      setValidateErrorAmounts({
        hasError: true,
        errorMessage: `${t('components:transferModal.minRecurringDepositAmount')} $${minRecurringDeposit / 100}`,
      });

      return;
    }

    if (isNull(transfersCount)) {
      return;
    }

    const isFirstDeposit = transfersCount === 0;
    const isLessThanMinInitialDeposit = !isRecurringDeposit && isFirstDeposit && !!minInitialDeposit && applicableData?.amountCents < minInitialDeposit;
    const isLessThanMinDeposit = !isRecurringDeposit && !isFirstDeposit && !!minAmountDeposit && applicableData?.amountCents < minAmountDeposit;

    if (isLessThanMinInitialDeposit) {
      setValidateErrorAmounts({
        hasError: true,
        errorMessage: `${t('components:transferModal.minInitialDeposit')} $${minInitialDeposit / 100}`,
      });

      return;
    }

    if (isLessThanMinDeposit) {
      setValidateErrorAmounts({
        hasError: true,
        errorMessage: `${t('components:transferModal.minAmountDeposit')} $${minAmountDeposit / 100}`,
      });

      return;
    }

    clearValidateErrorAmountsState();
  };

  useEffect(() => {
    if (isNull(totalTransfers) && applicableData?.subAccount?.id) {
      handleTotalTransfers(applicableData?.subAccount);

      return;
    }

    if (!isUndefined(applicableData?.amountCents)) {
      checkValidationErrors(totalTransfers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableData?.amountCents]);

  const defaultScheduleOption = options?.editDefaultScheduleFrequency && options?.defaultScheduleOption ? options?.defaultScheduleOption : undefined;

  useEffect(() => {
    if (applicableData?.schedule !== 'ONE_TIME') {
      updateContextTransfer({
        value: { ...applicableData, fiscalYear: undefined, schedule: applicableData?.schedule ?? defaultScheduleOption },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    if (applicableData.schedule === 'ONE_TIME' && starting === 'now') {
      updateContextTransfer({
        value: { ...applicableData, scheduledDate: undefined },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    if (['EXTERNAL_TRANSFER', 'INTERNAL_TRANSFER'].includes(applicableData?.type)) {
      // removing validations scheduledDate, schedule from EXTERNAL_TRANSFER and INTERNAL_TRANSFER
      updateContextTransfer({
        value: { ...applicableData, scheduledDate: dayjs().isBefore(dayjs(), 'day'), schedule: 'ONE_TIME' },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableData?.schedule, starting, options]);

  const submit = () => {
    const fields = invalidFields(
      {
        ...options,
        bankAccount: {
          ...options.bankAccount,
          requiredIf: (data: any) => data?.type === 'EFT',
        },
        fromSubAccount: {
          ...options.fromSubAccount,
          requiredIf: (data: any) => data?.type === 'INTERNAL_TRANSFER',
        },
        institution: {
          ...options.institution,
          requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
        },
        accountNumber: {
          ...options.accountNumber,
          requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
        },
        accountType: {
          ...options.accountType,
          requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
        },
        transferType: {
          ...options.transferType,
          requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
        },
        scheduledDate: {
          ...options.scheduledDate,
          requiredIf: (data: any) => data?.schedule !== 'ONE_TIME' || starting === 'futureDate',
        },
      },
      applicableData,
    );

    if (fields.length > 0 || invalidTransferDelayCheck) {
      setFocused([...fields]);
      return;
    }

    const transferValidation: ValidateTransferResponse = validateTransfer({
      accountTo: applicableData?.subAccount?.account?.type ?? '',
      transferType: applicableData?.type === 'EFT' ? 'electronicFundsTransferIn' : applicableData?.type === 'EXTERNAL_TRANSFER' ? 'externalTransfer' : 'internalTransferTo',
      accountFrom: applicableData?.fromSubAccount?.account?.type,
      canOverrideTransferRestrictions: permissions.includes('write:override_transfer_restrictions'),
    });
    if (!transferValidation.isValid) {
      showToast({ message: transferValidation.message, severity: 'error' });
      return;
    }
    if (fields.length === 0) {
      onNext();
    }
  };

  const subAccountFilter = () => {
    switch (applicableData?.objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: applicableData?.objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: applicableData?.objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: applicableData?.objectId };
      case PageObjectType.GOAL:
        return { goalId: applicableData?.objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: applicableData?.objectId };
      default:
        return { userId };
    }
  };
  const handleEftValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && applicableData?.type === 'EFT') {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const handleInternalTransferFromValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      setDisableForm(true);
    } else if ([...lockedInAccounts, ...incomeFundAccounts].includes(applicableData?.subAccount?.account?.type)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const handleInternalTransferToValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      setDisableForm(true);
    } else if ([...lockedInAccounts, ...incomeFundAccounts].includes(applicableData?.fromSubAccount?.account?.type)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const isNotComingFromSubAccount = options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT;

  const getErrorBanner = () => {
    if (lockedInAccounts.includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'EFT') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositEftLockedInAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if (incomeFundAccounts.includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'EFT') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositEftIncomeFundsAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(applicableData?.fromSubAccount?.account?.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositInternalTransferFromAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositInternalTransferToAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    return <></>;
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        {translateBackend(options?.subtitle)}
      </Typography>
      {applicableData?.type === 'EFT' && (
        <>
          {options?.bankAccount?.enabled && (
            <BankAccountSelectField
              sx={{ mb: 2 }}
              setBankAccount={(e: any) => updateContextTransfer({
                value: { ...applicableData, bankAccount: e },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              bankAccount={applicableData?.bankAccount}
              userId={userId!}
              onBlur={() => setFocused([...focused, 'bankAccount'])}
              error={!applicableData?.bankAccount?.id && focused.includes('bankAccount') && options?.bankAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              dataTestId='deposit-form-subaccount-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleEftValidation(e);
                updateContextTransfer({
                  value: { ...applicableData, subAccount: e },
                  existingContext: applicableData,
                  setApplicationContext,
                  setWorkflowCompletionContext,
                });
                handleTotalTransfers(e);
              }}
              selectedSubAccount={applicableData?.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!applicableData?.subAccount?.id && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.fiscalYear?.enabled && applicableData?.schedule === 'ONE_TIME' && showFiscalYear(applicableData?.subAccount?.account?.type ?? '') && (
            <SelectField
              testId='fiscal-year-select'
              label={translateBackend(options?.fiscalYear?.label)}
              onChange={(e: any) => updateContextTransfer({
                value: { ...applicableData, fiscalYear: e.target.value },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData?.fiscalYear}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem data-testid='fiscal-year-select-last-year' value={currentYear - 1}>{currentYear - 1}</MenuItem>
              <MenuItem data-testid='fiscal-year-select-current-year' value={currentYear}>{currentYear}</MenuItem>
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              testId='deposit-form-amount-cents'
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => updateContextTransfer({
                value: { ...applicableData, amountCents: round(parseFloat(e) * 100, 2) },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              amount={applicableData?.amountCents || 0}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={
                (!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED') || (applicableData?.amountCents && validateErrorAmounts.hasError)
              }
              errorText={validateErrorAmounts.errorMessage}
            />
          )}
          {options?.schedule?.enabled && (
            <SelectField
              testId='schedule-select'
              label={translateBackend(options?.schedule?.label)}
              onChange={(e: any) => updateContextTransfer({
                value: { ...applicableData, schedule: e.target.value },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData?.schedule ?? defaultScheduleOption}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'schedule'])}
              error={!applicableData?.schedule && focused.includes('schedule') && options?.schedule?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='schedule-select-one-time' value='ONE_TIME'>
                {t('schedule.ONE_TIME')}
              </MenuItem>
              <MenuItem data-testid='schedule-select-weekly' value='WEEKLY'>
                {t('schedule.WEEKLY')}
              </MenuItem>
              <MenuItem data-testid='schedule-select-bi-weekly' value='BI_WEEKLY'>
                {t('schedule.BI_WEEKLY')}
              </MenuItem>
              <MenuItem data-testid='schedule-select-semi-monthly' value='SEMI_MONTHLY'>
                {t('schedule.SEMI_MONTHLY')}
              </MenuItem>
              <MenuItem data-testid='schedule-select-monthly' value='MONTHLY'>
                {t('schedule.MONTHLY')}
              </MenuItem>
              <MenuItem data-testid='schedule-select-quarterly' value='QUARTERLY'>
                {t('schedule.QUARTERLY')}
              </MenuItem>
            </SelectField>
          )}
          {options?.scheduledDate?.enabled && applicableData?.schedule !== 'ONE_TIME' && (
            <DateField
              dataTestId='scheduled-date'
              sx={{ mb: 2 }}
              onChange={(date: any) => {
                updateContextTransfer({
                  value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                  existingContext: applicableData,
                  setApplicationContext,
                  setWorkflowCompletionContext,
                });
              }}
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDay({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              fullWidth
              value={applicableData?.scheduledDate}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
          {(options?.allowOneTimeTransfersFutureDate || options?.allowOneTimeTransfersNow) && applicableData?.schedule === 'ONE_TIME' && (
            <RadioGroup
              testId='deposit-form-starting'
              sx={{ pb: 0 }}
              label={t('components:transferModal.starting')}
              value={starting}
              onChange={(e: any) => {
                setStarting(e.target.value);
              }}
            >
              <Stack direction={'row'}>
                {!isInitiatedByRepresentative()
                  && options?.allowOneTimeTransfersNow
                  && <Radio testId='deposit-form-starting-now' value='now' label={t('components:transferModal.startingOptions.now')} />}
                {options?.allowOneTimeTransfersFutureDate
                  && (<Radio testId='deposit-form-starting-future' value='futureDate' label={t('components:transferModal.startingOptions.futureDate')} />)}
              </Stack>
            </RadioGroup>
          )}
          {starting === 'futureDate' && applicableData?.schedule === 'ONE_TIME' && (
            <DateField
              dataTestId='scheduled-date'
              sx={{ mb: 2 }}
              onChange={(date: any) => {
                updateContextTransfer({
                  value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                  existingContext: applicableData,
                  setApplicationContext,
                  setWorkflowCompletionContext,
                });
              }}
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDay({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              fullWidth
              value={applicableData?.scheduledDate}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
        </>
      )}
      {options?.transferDelay?.enabled && ((applicableData.schedule === 'ONE_TIME' && starting !== 'now') || applicableData.schedule !== 'ONE_TIME') && (
        <Alert severity='info' data-testid='transfer-delay-alert'>
          {translateBackend(options?.transferDelayDescription)}
        </Alert>
      )}
      {applicableData?.type === 'INTERNAL_TRANSFER' && (
        <>
          {options?.fromSubAccount?.enabled && (
            <SubAccountSelect
              label={translateBackend(options?.fromSubAccount?.label)}
              dataTestId='deposit-form-from-sub-account-select'
              // if this page is navigated from the subAccount page. The subAccount its coming from should have its
              // id assigned to the subAccountsToExclude, so it is not shown in the list of subAccount from, since it is
              // the subAccount money would be transferred into subAccountTo
              subAccountsToExclude={!isNotComingFromSubAccount ? [applicableData?.subAccount?.id] : []}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferFromValidation(e);
                if (applicableData?.subAccount?.id === e.id) {
                  updateContextTransfer({
                    value: { ...applicableData, subAccount: undefined, fromSubAccount: e },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, fromSubAccount: e },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              selectedSubAccount={applicableData?.fromSubAccount}
              userId={userId}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'fromSubAccount'])}
              error={!applicableData?.fromSubAccount && focused.includes('fromSubAccount') && options?.fromSubAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {isNotComingFromSubAccount && (
            <SubAccountSelect
              dataTestId='deposit-form-sub-account-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferToValidation(e);
                if (applicableData?.fromSubAccount?.id === e.id) {
                  updateContextTransfer({
                    value: { ...applicableData, fromSubAccount: undefined, subAccount: e },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, subAccount: e },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              selectedSubAccount={applicableData?.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!applicableData?.subAccount && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              testId='deposit-form-amount-cents'
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => updateContextTransfer({
                value: { ...applicableData, amountCents: round(parseFloat(e) * 100, 2) },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              amount={applicableData?.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          {applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>
              {t('depositReview.amountExceeds')}
            </Typography>
          ) : (
            <></>
          )}
        </>
      )}
      {applicableData?.type === 'EXTERNAL_TRANSFER' && (
        <>
          {options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              dataTestId='deposit-form-sub-account-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => updateContextTransfer({
                value: { ...applicableData, subAccount: e },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              selectedSubAccount={applicableData?.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!applicableData?.subAccount && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.institution?.enabled && (
            <BankSelectField
              label={translateBackend(options?.institution?.label)}
              setInstitution={(e: any) => updateContextTransfer({
                value: { ...applicableData, institution: e },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              institution={applicableData?.institution}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'institution'])}
              error={!applicableData?.institution && focused.includes('institution') && options?.institution?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountNumber?.enabled && (
            <TextField
              testId='deposit-form-account-number'
              label={translateBackend(options?.accountNumber?.label)}
              onChange={(e: any) => updateContextTransfer({
                value: { ...applicableData, accountNumber: e.target.value },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData?.accountNumber}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'accountNumber'])}
              error={!applicableData?.accountNumber && focused.includes('accountNumber') && options?.accountNumber?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountType?.enabled && (
            <AccountTypeSelect
              label={translateBackend(options?.accountType?.label)}
              onChange={(e: any) => updateContextTransfer({
                value: { ...applicableData, accountType: e },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData?.accountType}
              size='medium'
              onBlur={() => setFocused([...focused, 'institution'])}
              error={!applicableData?.institution && focused.includes('institution') && options?.institution?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.transferType?.enabled && (
            <SelectField
              testId='transfer-type-select'
              label={translateBackend(options?.transferType?.label)}
              onChange={(e: any) => updateContextTransfer({
                value: { ...applicableData, transferType: e.target.value },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData?.transferType}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              onBlur={() => setFocused([...focused, 'transferType'])}
              error={!applicableData?.transferType && focused.includes('transferType') && options?.transferType?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='transfer-type-select-all-cash' value='ALL_IN_CASH'>
                {t('depositReview.ALL_IN_CASH')}
              </MenuItem>
              <MenuItem data-testid='transfer-type-select-partial-cash' value='PARTIAL_CASH'>
                {t('depositReview.PARTIAL_CASH')}
              </MenuItem>
              {enableInKind && (
                <MenuItem data-testid='transfer-type-select-all-in-kind' value='ALL_IN_KIND'>
                  {t('depositReview.ALL_IN_KIND')}
                </MenuItem>
              )}
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              testId='deposit-form-amount-cents'
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => updateContextTransfer({
                value: { ...applicableData, amountCents: round(parseFloat(e) * 100, 2) },
                existingContext: applicableData,
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              amount={applicableData?.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          {applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>
              {t('depositReview.amountExceeds')}
            </Typography>
          ) : (
            <></>
          )}
        </>
      )}
      {getErrorBanner()}
      <Box display='flex' justifyContent='end'>
        <Button
          dataTestId='deposit-form-continue-button'
          disabled={buttonValidationAmountCents || disableForm || validateErrorAmounts.hasError}
          label={t('continue')}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default DepositForm;
