import { Organization } from './organization';

export enum PortfolioOptimizerStates {
  INITIATED = 'INITIATED',
  OPTIMAL_PORTFOLIOS_GENERATING = 'OPTIMAL_PORTFOLIOS_GENERATING',
  OPTIMAL_PORTFOLIOS_GENERATED = 'OPTIMAL_PORTFOLIOS_GENERATED',
  SOLVING_FOR_REDEMPTIONS = 'SOLVING_FOR_REDEMPTIONS',
  REDEMPTIONS_SOLVED = 'REDEMPTIONS_SOLVED',
  SOLVING_FOR_PURCHASES = 'SOLVING_FOR_PURCHASES',
  PURCHASES_SOLVED = 'PURCHASES_SOLVED',
  REBALANCING = 'REBALANCING',
  REBALANCED = 'REBALANCED',
  TAX_OPTIMIZING = 'TAX_OPTIMIZING',
  TAX_OPTIMIZED = 'TAX_OPTIMIZED',
  GENERATING_ALLOCATIONS = 'GENERATING_ALLOCATIONS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export const OPTIMIZER_PROCESSING_STATES = [
  PortfolioOptimizerStates.INITIATED,
  PortfolioOptimizerStates.OPTIMAL_PORTFOLIOS_GENERATING,
  PortfolioOptimizerStates.SOLVING_FOR_REDEMPTIONS,
  PortfolioOptimizerStates.SOLVING_FOR_PURCHASES,
  PortfolioOptimizerStates.REBALANCING,
  PortfolioOptimizerStates.TAX_OPTIMIZING,
  PortfolioOptimizerStates.GENERATING_ALLOCATIONS,
];

export const OPTIMIZER_COMPLETED_STATES = [
  PortfolioOptimizerStates.OPTIMAL_PORTFOLIOS_GENERATED,
  PortfolioOptimizerStates.REDEMPTIONS_SOLVED,
  PortfolioOptimizerStates.PURCHASES_SOLVED,
  PortfolioOptimizerStates.REBALANCED,
  PortfolioOptimizerStates.TAX_OPTIMIZED,
  PortfolioOptimizerStates.COMPLETED,
];

export const PausableStates = [
  PortfolioOptimizerStates.OPTIMAL_PORTFOLIOS_GENERATED,
  PortfolioOptimizerStates.REDEMPTIONS_SOLVED,
  PortfolioOptimizerStates.PURCHASES_SOLVED,
  PortfolioOptimizerStates.REBALANCED,
  PortfolioOptimizerStates.TAX_OPTIMIZED,
];

export const OPTIMIZER_PAUSABLE_STATES = [
  'COMPLETE_ALL_STEPS',
  ...PausableStates,
];

export enum PortfolioOptimizerTypes {
  DRIFT = 'DRIFT',
  TRANSFER = 'TRANSFER',
}

export type PausableState = typeof PausableStates[number];

export interface PortfolioOptimizer {
  id: string,
  type: PortfolioOptimizerTypes;
  state: PortfolioOptimizerStates;
  numPortfolios: number;
  pauseAt?: PausableState;
  createdAt?: Date;
  expectedTotalSubTradeRequests?: number;
  organization?: Organization;
  subTradeRequestsCreated?: number;
}
