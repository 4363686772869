import {
  Box,
  Button, CircularProgress, ListItem, Typography,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { colors } from 'ovComponents';
import { useNavigate } from 'react-router-dom';
import { StepProps } from '../wizard';
import { CreateSubAccountWizard } from '../../createSubAccountWizard/wizard';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: '64px',
    height: '64px',
  },
};
type ActionStepProps = {
  loading: boolean,
  succeeded: boolean,
  error: ApolloError | undefined,
  newGoalId: string
  newGoalName: string,
};

export function CreateGoalActionStepResult({
  context, state, continueTo,
  loading, succeeded, newGoalId, error, newGoalName,
}: Omit<StepProps, 'setState' | 'mutationEvent'> & ActionStepProps) {
  const { t } = useTranslation('createGoalWizard');
  const navigate = useNavigate();

  return (
    <>
      <ListItem sx={styles.center}>
        {loading && <CircularProgress size='50px' thickness={5}/>}
        {succeeded && <CheckCircleOutlineIcon sx={{ ...styles.icon, color: colors.positive }} />}
        {error && <HighlightOffIcon sx={{ ...styles.icon, color: colors.negative }} />}
      </ListItem>

      <Box>
        {succeeded && (
          <>
            <Typography variant='h5' sx={styles.center} color={colors.neutral600}>{t('added')}:</Typography>
            <Typography variant='h5' sx={styles.center} color={colors.neutral800}>
               {newGoalName}
            </Typography>
          </>
        )
        }
        {error && <Typography variant='h5' sx={styles.center} color={colors.error}>
          {t('failed')}
        </Typography>}
      </Box>

      <ListItem sx={{ ...styles.center, flex: 1, alignItems: 'end' }}>
        <Typography variant='body2'>{t('subAccMsg')}</Typography>
      </ListItem>

      <ListItem >
        <Button
          onClick={() => {
            continueTo(CreateSubAccountWizard, {
              forObject: 'GOAL',
              forId: newGoalId,
            }, {
              callToAction: t('createSubAccountWizard:actions.goToSubaccount'),
              action: (newSubAccountId) => {
                navigate(`goal/${newGoalId}/subaccount/${newSubAccountId}`);
              },
            });
          }}
          disabled={loading || !!error}
          data-testid='confirmButton' fullWidth variant='contained'
        >
          {t('createSubAccount')}
        </Button>
      </ListItem>
      <ListItem>
        <Button
          data-testid='confirmButton'
          onClick={() => continueTo(null)}
          fullWidth
          variant='text'
          disabled={loading || !!error}
        >
          {t('skipforNow')}
        </Button>
      </ListItem>
    </>
  );
}
