import { useState } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Box,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import CreateNewModal from 'components/modals/createNewModal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ID } from '../clientDetails';
import { LocalizedDatePicker } from '../../../../components/fields/localizedDatePicker';
import { useCountrySpecificBackendIdDocumentTypes, useCountrySpecificInformationSources } from '../../../../interfaces/IdVerification';

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: '40%',
      width: 200,
    },
  },
};

export const EditIdVerification = ({
  idVerification,
  setIdVerification,
  documentArray,
  onSubmit,
  onClose,
}: {
  idVerification: ID | undefined,
  setIdVerification: (ID: any) => void,
  documentArray?: any[],
  onSubmit: () => void,
  onClose: () => void,
}) => {
  const { t } = useTranslation(['client', 'base']);
  const [inputValue, setInputValue] = useState(idVerification?.documentIssuingJurisdication);
  const [value, setValue] = useState<string>(idVerification?.documentIssuingJurisdication || '');

  const [inputSecondaryValue, setInputSecondaryValue] = useState(idVerification?.secondaryDocumentIssuingJurisdication);
  const [secondaryValue, setSecondaryValue] = useState<string>(idVerification?.secondaryDocumentIssuingJurisdication || '');
  const generateJurisdictionString = (option: string): string => {
    if (informationSources.includes(option)) {
      return t(`client:edit.jurisdicationOptionsForUsandCanada.${option}`);
    }
    return option;
  };
  const { idDocumentTypes } = useCountrySpecificBackendIdDocumentTypes();
  const informationSources = useCountrySpecificInformationSources();

  return (<CreateNewModal
    onSubmit={onSubmit}
    loading={false}
    title={t('client:details.updateID')}
    forceOpen={true}
    onClose={onClose}
    buttonText={t('client:edit.buttons.save')}
  >
    {idVerification?.methodOfIDVerification !== 'DUAL_METHOD' && (<Grid container spacing={2} padding={2}>
      <Grid item xs={6}>
        <TextField
          label={t('client:methodOfIDVerification')}
          fullWidth
          disabled
          value={idVerification?.methodOfIDVerification}
          select
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, methodOfIDVerification: e.target.value });
            }
          }}
        >
          {
            ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'DUAL_METHOD', 'IN_PERSON_VALIDATION'].map((key: string) => (
              <MenuItem key={key} value={key}>{t(`client:edit.methodOfIDVerificationOptions.${key}`)}</MenuItem>
            ))
          }
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('client:documentType')}
          fullWidth
          value={idVerification?.documentType}
          select
          SelectProps={{
            MenuProps: menuProps,
          }}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, documentType: e.target.value });
            }
          }}
        >
          {
            idDocumentTypes.map((key: string) => (
              <MenuItem key={key} value={key} sx={{ whiteSpace: 'normal' }}>{t(`client:documentTypes.${key}`)}</MenuItem>
            ))
          }
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('client:documentID')}
          fullWidth
          value={idVerification?.documentID}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, documentID: e.target.value });
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('client:nameOnTheID')}
          fullWidth
          value={idVerification?.userName}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, userName: e.target.value });
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id='second-document-jusrisdiction'
          onChange={(event: any, newValue: string | null, reason: string) => {
            if (newValue) {
              setValue(newValue);
              setIdVerification({ ...idVerification, documentIssuingJurisdication: newValue });
            }
            if (reason === 'clear') {
              setValue('');
              setIdVerification({ ...idVerification, documentIssuingJurisdication: '' });
            }
          }}
          onBlur={() => {
            if (inputValue) {
              setValue(inputValue);
              setIdVerification({ ...idVerification, documentIssuingJurisdication: inputValue });
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          value={value}
          getOptionLabel={(option) => generateJurisdictionString(option)}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {generateJurisdictionString(option)}
            </Box>
          )}
          options={informationSources}
          fullWidth
          noOptionsText={t('noOptionsText')}
          renderInput={(params) => <TextField
            {...params}
            label={t('client:documentIssuingJurisdication')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter'
                && informationSources.findIndex((o) => o === inputValue) === -1
              ) {
                if (inputValue) {
                  setValue(inputValue);
                  setIdVerification({ ...idVerification, documentIssuingJurisdication: inputValue });
                }
              }
            }}
          />}
        />
      </Grid>
      <Grid item xs={6}>
        <LocalizedDatePicker
          label={t('client:verifiedDate')}
          value={idVerification?.verifiedDate || dayjs().format('YYYY-MM-DD')}
          onChange={(date) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, verifiedDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
            }
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </Grid>
      <Grid item xs={6}>
        <LocalizedDatePicker
          label={t('client:expiryDate')}
          value={idVerification?.documentExpiryDate}
          onChange={(date) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, documentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
            }
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </Grid>
      {(documentArray && documentArray.length > 0) && (<Grid item xs={12}>
        <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>{t('client:supportingDocument')}</Typography>
        <hr />
      </Grid>)}
      {(documentArray && documentArray.length > 0) && (<Grid item xs={12} sx={{ textAling: 'center' }}>
        <a
          href={documentArray[0].downloadUrl}
          style={{ color: '#2CA58D', fontStyle: 'underline' }}
          target="_blank"
          rel="noreferrer"
        >
          {documentArray[0].name}
        </a>
      </Grid>)}
    </Grid>)}
    {idVerification?.methodOfIDVerification === 'DUAL_METHOD' && (<Grid container spacing={2} padding={2}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={6}>
          <TextField
            label={t('client:methodOfIDVerification')}
            fullWidth
            disabled
            value={idVerification?.methodOfIDVerification}
            select
            onChange={(e) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, methodOfIDVerification: e.target.value });
              }
            }}
          >
            {
              ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'DUAL_METHOD', 'IN_PERSON_VALIDATION'].map((key: string) => (
                <MenuItem key={key} value={key}>{t(`client:edit.methodOfIDVerificationOptions.${key}`)}</MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <LocalizedDatePicker
            label={t('client:verifiedDate')}
            value={idVerification?.verifiedDate || dayjs().format('YYYY-MM-DD')}
            onChange={(date) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, verifiedDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>{t('client:primaryDocument')}</Typography>
          <hr />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label={t('client:documentType')}
            fullWidth
            value={idVerification?.documentType}
            select
            onChange={(e) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, documentType: e.target.value });
              }
            }}
          >
            {
              idDocumentTypes.map((key: string) => (
                <MenuItem key={key} value={key}>{t(`client:documentTypes.${key}`)}</MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('client:documentID')}
            fullWidth
            value={idVerification?.documentID}
            onChange={(e) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, documentID: e.target.value });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id='second-document-jusrisdiction'
            onChange={(event: any, newValue: string | null, reason: string) => {
              if (newValue) {
                setValue(newValue);
                setIdVerification({ ...idVerification, documentIssuingJurisdication: newValue });
              }
              if (reason === 'clear') {
                setValue('');
                setIdVerification({ ...idVerification, documentIssuingJurisdication: '' });
              }
            }}
            onBlur={() => {
              if (inputValue) {
                setValue(inputValue);
                setIdVerification({ ...idVerification, documentIssuingJurisdication: inputValue });
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            value={value}
            getOptionLabel={(option) => generateJurisdictionString(option)}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {generateJurisdictionString(option)}
              </Box>
            )}
            options={informationSources}
            fullWidth
            noOptionsText={t('noOptionsText')}
            renderInput={(params) => <TextField
              {...params}
              label={t('client:documentIssuingJurisdication')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter'
                  && informationSources.findIndex((o) => o === inputValue) === -1
                ) {
                  if (inputValue) {
                    setValue(inputValue);
                    setIdVerification({ ...idVerification, documentIssuingJurisdication: inputValue });
                  }
                }
              }}
            />}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizedDatePicker
            label={t('client:expiryDate')}
            value={idVerification?.documentExpiryDate}
            onChange={(date) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, documentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('client:nameOnTheID')}
            fullWidth
            value={idVerification?.userName}
            onChange={(e) => {
              if (idVerification) {
                setIdVerification({ ...idVerification, userName: e.target.value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>{t('client:secondaryDocument')}</Typography>
        <hr />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t('client:secondaryDocumentType')}
          fullWidth
          select
          value={idVerification?.secondaryDocumentType}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, secondaryDocumentType: e.target.value });
            }
          }}
        >
          {
            idDocumentTypes.map((key: string) => (
              <MenuItem key={key} value={key}>{t(`client:documentTypes.${key}`)}</MenuItem>
            ))
          }
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          id='second-document-jusrisdiction'
          onChange={(event: any, newValue: string | null, reason: string) => {
            if (newValue) {
              setSecondaryValue(newValue);
              setIdVerification({ ...idVerification, secondaryDocumentIssuingJurisdication: newValue });
            }
            if (reason === 'clear') {
              setSecondaryValue('');
              setIdVerification({ ...idVerification, secondaryDocumentIssuingJurisdication: '' });
            }
          }}
          onBlur={() => {
            if (inputSecondaryValue) {
              setSecondaryValue(inputSecondaryValue);
              setIdVerification({ ...idVerification, secondaryDocumentIssuingJurisdication: inputSecondaryValue });
            }
          }}
          inputValue={inputSecondaryValue}
          onInputChange={(event, newInputValue) => {
            setInputSecondaryValue(newInputValue);
          }}
          value={secondaryValue}
          getOptionLabel={(option) => generateJurisdictionString(option)}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {generateJurisdictionString(option)}
            </Box>
          )}
          options={informationSources}
          fullWidth
          noOptionsText={t('noOptionsText')}
          renderInput={(params) => <TextField
            {...params}
            label={t('client:secondaryDocumentIssuingJurisdication')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter'
                && informationSources.findIndex((o) => o === inputSecondaryValue) === -1
              ) {
                if (inputSecondaryValue) {
                  setSecondaryValue(inputSecondaryValue);
                  setIdVerification({ ...idVerification, secondaryDocumentIssuingJurisdication: inputSecondaryValue });
                }
              }
            }}
          />}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label={t('client:secondaryDocumentID')}
          fullWidth
          value={idVerification?.secondaryDocumentID}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, secondaryDocumentID: e.target.value });
            }
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <LocalizedDatePicker
          label={t('client:secondaryDocumentExpiryDate')}
          value={idVerification?.secondaryDocumentExpiryDate}
          onChange={(date) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, secondaryDocumentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
            }
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('client:nameOnTheID')}
          fullWidth
          value={idVerification.secondaryDocumentUserName}
          onChange={(e) => {
            if (idVerification) {
              setIdVerification({ ...idVerification, secondaryDocumentUserName: e.target.value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>{t('client:supportingDocuments')}</Typography>
        <hr />
      </Grid>
      {documentArray?.map((d: any) => (<Grid item xs={12} sx={{ textAling: 'center' }}>
        <a
          href={d.downloadUrl}
          style={{ color: '#2CA58D', fontStyle: 'underline' }}
          target="_blank"
          rel="noreferrer"
        >
          {d.name}
        </a>
      </Grid>))}
    </Grid>)}
  </CreateNewModal>);
};
