import { useTranslation } from 'react-i18next';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { ChooseAccountTypeStep } from './steps/chooseAccountTypeStep';
import { NewAccountActionStep } from './steps/newAccountActionStep';

type Step = 'CHOOSE_ACCOUNT_TYPE' | 'CREATE_NEW_ACCOUNT';

const steps:Record<Step, StepComponent> = {
  CHOOSE_ACCOUNT_TYPE: ChooseAccountTypeStep,
  CREATE_NEW_ACCOUNT: NewAccountActionStep,
};

interface WizardContext {
  forObject: 'USER' | 'CLIENT_GROUP',
  forId: string,
}

interface WizardState {
  accountType: string,
  sourceOfFunds: string,
  jurisdiction: string,
  applyForGovFunds: string[],
  isAdditionalCesg: boolean,
  isBasicCesg: boolean,
  isClb: boolean,
  corporationClientGroupId?: string,
  householdMember?: string,
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  accountType: '',
  sourceOfFunds: '',
  jurisdiction: '',
  applyForGovFunds: [],
  isAdditionalCesg: false,
  isBasicCesg: true,
  isClb: true,
  corporationClientGroupId: undefined,
};

export interface CreateNewAccountWizardProps {
  forObject: 'USER' | 'CLIENT_GROUP'
  forId: string,
}

export function CreateNewAccountWizard({
  forId, forObject,
  handleClose,
}: CreateNewAccountWizardProps & WizardControlProps) {
  const { t } = useTranslation('createAccountWizard');

  return (
    <WizardBase
      title={t('title')}
      context={{
        forObject,
        forId,
      }}
      steps={steps}
      firstStep={'CHOOSE_ACCOUNT_TYPE'}
      initialState={initial}
      handleClose={handleClose}
      minimumHeight={630}
    />
  );
}
