import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useMutation } from '@apollo/client';
import {
  AccountStates,
  AccountTypes,
  ApplyForGovFunds,
  DraftAccount,
  Goal,
} from 'interfaces';
import { UPDATE_USER } from 'pages/accountsDetail/components/newAffiliate';
import {
  Box, Icon, Typography, Grid,
} from 'ovComponents/1-primative';
import {
  Button, Checkbox, Form, SelectField, MenuItem, HelpText,
} from 'ovComponents/2-component';
import { colors } from 'theme/colors';
import {
  validateFields, FormErrors, FieldOptions,
} from 'ovComponents/4-module/workflowCompletion/subSteps/utils';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';

interface RespAdultDialogContentInterface {
  userData: any,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

export const RespAdultDialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: RespAdultDialogContentInterface) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [applyForGovFunds, setApplyForGovFunds] = useState<ApplyForGovFunds[]>([]);
  const [gender, setGender] = useState<string>(userData.gender ?? '');
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const [updateUser] = useMutation(UPDATE_USER);
  const goals = getValidGoals(userData);
  const handleApplyForGovFundSelect = (govFund: ApplyForGovFunds, checked: boolean) => {
    setFocused([...focused, 'applyForGovFunds']);
    if (checked) {
      setApplyForGovFunds((prevState) => ([...prevState, govFund]));
    } else {
      setApplyForGovFunds([...applyForGovFunds.filter((item) => item !== govFund)]);
    }
  };

  const fieldOptions: FieldOptions = useMemo(() => ({
    applyForGovFunds: { required: true },
    gender: { required: true },
    goalsLinked: { required: false },
  }), []);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const data = {
      applyForGovFunds,
      gender,
      goalsLinked,
    };
    const newErrors = validateFields(fieldOptions, data, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [applyForGovFunds, fieldOptions, gender, goalsLinked]);

  const submit = async () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    if (userData.gender !== gender) {
      await updateUser({
        variables: {
          input: {
            userId: userData.id,
            gender,
          },
        },
      }).then();
    }
    onAccountCreated({
      type: AccountTypes.RESP_ADULT,
      applyForGovFunds,
      id: '',
      state: AccountStates.ACTIVE,
      user: {
        ...userData,
        gender,
      },
      affiliations: [],
      goalsLinked,
    });
  };

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium'/></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:respAccountCreation.respAdultTitle')}</Typography>
      <Box display='flex' flexDirection='row' alignItems='center' mt={3}>
        <Typography variant='bodyLarge' sx={{ mr: 0.5 }}>{t('workflowCompletions:respAccountCreation.subtitle')}</Typography>
        <Icon icon={InfoOutlinedIcon} size='small'/>
      </Box>
      <Grid
        container
        display='flex'
        flexDirection='row'
        sx={{ mt: 2 }}
        justifyContent='space-between'
        spacing={1}
        alignItems='end'
      >
        <Grid item xs={7}>
          <Checkbox
            label={t('workflowCompletions:respAccountCreation.basicCanadaEducationSavingsGrant')}
            checked={applyForGovFunds.includes(ApplyForGovFunds.BASIC_CESG)}
            onChange={(checked) => handleApplyForGovFundSelect(ApplyForGovFunds.BASIC_CESG, checked)}
          />
        </Grid>
        <Grid item xs={5}>
          <Checkbox
            label={t('workflowCompletions:respAccountCreation.canadaLearningBond')}
            checked={applyForGovFunds.includes(ApplyForGovFunds.CLB)}
            onChange={(checked) => handleApplyForGovFundSelect(ApplyForGovFunds.CLB, checked)}
          />
        </Grid>
      </Grid>
      {errors?.applyForGovFunds && <HelpText tone='error' text={errors?.applyForGovFunds?.message} />}
      <Box height='1px' mt={3} bgcolor={colors.neutral40}/>
      <SelectField
        sx={{ mt: 3 }}
        onChange={(e: any) => setGender(e.target.value)}
        disabled={false}
        label={t('workflowCompletions:respAccountCreation.gender')}
        fullWidth
        onBlur={() => setFocused([...focused, 'gender'])}
        value={gender}
        errorText={errors?.gender?.message}
        error={!!errors?.gender}
      >
        <MenuItem value='Female'>{t('workflowCompletions:respAccountCreation.genderOption.Female')}</MenuItem>
        <MenuItem value='Male'>{t('workflowCompletions:respAccountCreation.genderOption.Male')}</MenuItem>
      </SelectField>
      <Box height='1px' mt={3} bgcolor={colors.neutral40}/>
      {showGoalLinkingSection && <Box sx={{ mt: 4 }}>
        <GoalLinkingInput
          goals={goals}
          goalsLinked={goalsLinked}
          setGoalsLinked={setGoalsLinked}
          errorText={errors?.goalsLinked?.message}
          error={!!errors?.goalsLinked}
        />
      </Box>}
      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:respAccountCreation.addAccount')}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default RespAdultDialogContent;
