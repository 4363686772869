import { useTranslation } from 'react-i18next';
import { TranslatedString } from 'interfaces';
import {
  Box,
} from 'ovComponents/1-primative';
import {
  Checkbox,
  TranslatableTextField,
} from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';

interface FieldEditProps {
  option: any,
  options: any,
  setOptions: (x: any) => void,
  i: number,
}
const I18N_PREFIX = 'workflowCompletions:subStepOptions.optionTypes.ADDITIONAL_INFO_FIELD';

export const additionalInfo = (additionalInfoOpts: any) => {
  if (additionalInfoOpts?.checked) return translateBackend(additionalInfoOpts);
  return undefined;
};

export const AdditionalInfoEdit = (props: FieldEditProps) => {
  const {
    option, options, setOptions, i,
  } = props;
  const { t } = useTranslation(['workflowCompletions']);

  const additionalInfoOpts = option?.value?.additionalInfo ?? {
    checked: false,
    en: '',
    fr: '',
  };

  // Helper function to encapsulate verbose references when
  // updating options[field].value.selectField
  const updateAdditionalInfoOptions = (opts: object) => {
    const newOptions = [...options];
    newOptions[i].value = {
      ...option.value,
      additionalInfo: {
        ...additionalInfoOpts,
        ...opts,
      },
    };
    setOptions(newOptions);
  };

  const handleChecked = (onoff: boolean) => {
    updateAdditionalInfoOptions({ checked: onoff });
  };

  const handleTextChange = (value: TranslatedString) => {
    updateAdditionalInfoOptions(value);
  };

  return (
    <Box>
      <Checkbox
        size='small'
        label={t(`${I18N_PREFIX}.checkboxLabel`)}
        checked={additionalInfoOpts.checked}
        onChange={handleChecked}
      />
      {additionalInfoOpts.checked
        && <TranslatableTextField
          value={option?.value?.additionalInfo}
          dialogLabel={t(`${I18N_PREFIX}.checkboxLabel`)}
          fullWidth
          multiline
          rows={3}
          onChange={handleTextChange}
          allowMarkdown={true}
        />
      }
    </Box>
  );
};
