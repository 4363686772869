import {
  Box,
  Button, CircularProgress, ListItem, Typography,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { find } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ReturnPathContext } from 'components/wizards/wizardBase';
import { useContext } from 'react';
import { generateClientNameString } from '../../../../util';
import { provinces } from '../../../../ovComponents/resources';
import { colors } from '../../../../theme/colors';
import { StepProps } from '../wizard';
import { CreateSubAccountWizard } from '../../createSubAccountWizard/wizard';
import { AccountTypes } from '../../../../interfaces';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subHeader: {
    maxWidth: '285px',
    textAlign: 'center',
  },
  divider: {
    color: colors.neutral80,
    width: '100%',
  },
  loader: {
    marginTop: '6px',
    float: 'right',
  },
  icon: {
    width: '64px',
    height: '64px',
  },
};
type ActionStepProps = {
  loading: boolean,
  succeeded: boolean,
  account: any
  error: ApolloError | undefined
};

export function NewAccountSummaryActionStepResult({
  context, state, continueTo,
  loading, succeeded, account, error,
}: Omit<StepProps, 'setState' | 'mutationEvent'> & ActionStepProps) {
  const { t } = useTranslation('createAccountWizard');
  const navigate = useNavigate();
  const isAdditionalCesg = account && account?.applyForGovFunds && account?.applyForGovFunds.includes('ADDITIONAL_CESG');
  const isBasicCesg = account && account?.applyForGovFunds && account?.applyForGovFunds.includes('BASIC_CESG');
  const isClb = account && account?.applyForGovFunds && account?.applyForGovFunds.includes('CLB');
  const isSetUpSubAccMsgAvailable = [AccountTypes.PERSONAL, AccountTypes.TFSA, AccountTypes.FHSA].includes(account?.type as AccountTypes);
  const isRRSP = account?.type === AccountTypes.RRSP;

  const returnPath = useContext(ReturnPathContext);

  return (
    <>
      <ListItem sx={styles.center}>
        {loading && <CircularProgress size='30px' sx={styles.loader} />}
        {succeeded && <CheckCircleOutlineIcon sx={{ ...styles.icon, color: colors.success }} />}
        {error && <HighlightOffIcon sx={{ ...styles.icon, color: colors.error }} />}
      </ListItem>
      <ListItem sx={styles.center}>
        {succeeded && <Typography variant='h5' sx={styles.subHeader}>
          {t('accountSummary.added')}</Typography>
        }
        {error && <Typography variant='h5' sx={{ ...styles.subHeader, color: colors.error }}>
          {t('accountSummary.failed')}
        </Typography>}
      </ListItem>
      <Box sx={{ minHeight: '300px' }}>
        <ListItem>
          <hr style={styles.divider} />
        </ListItem>
        {succeeded && (<>
          <ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('client:accountType')}</Typography>
            <Typography variant='body2' fontWeight={700}>{t(`accountTypes:${account.type}`)}</Typography>
          </ListItem>
          <ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('accountHolder')}</Typography>
            <Typography variant='body2' fontWeight={700}>{generateClientNameString(account.user)}</Typography>
          </ListItem>

          {account && account?.custodianAccountNumber && (<ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('client:custodianAccountNumber')}</Typography>
            <Typography variant='body2' fontWeight={700}>{account.custodianAccountNumber}</Typography>
          </ListItem>)}

          {account && account?.householdClientGroup && (<ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('householdClientGroup')}</Typography>
            <Typography variant='body2' fontWeight={700}>{account.householdClientGroup.name}</Typography>
          </ListItem>)}

          {account && account?.corporationClientGroup && (<ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('components:corporation')}</Typography>
            <Typography variant='body2' fontWeight={700}>{account.corporationClientGroup.name}</Typography>
          </ListItem>)}

          {account && account?.sourceOfFunds && (<ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('sourceOfFunds')}</Typography>
            <Typography variant='body2' fontWeight={700}>{t(`sourceOfFundsOptions.${account.sourceOfFunds}`)}</Typography>
          </ListItem>)}

          {account && account?.jurisdiction && (<ListItem sx={styles.listItem}>
            <Typography variant='body2'>{t('jurisdiction')}</Typography>
            <Typography variant='body2' fontWeight={700}>{find(provinces, { key: account?.jurisdiction })?.value}</Typography>
          </ListItem>)}

          {account && !isEmpty(account?.applyForGovFunds) && (
            <>
              <ListItem sx={styles.listItem}>
                <Typography variant='body2'>{t('govFundOptions.ADDITIONAL_CESG')}</Typography>
                <Typography variant='body2' fontWeight={700}>{isAdditionalCesg ? t('yes') : t('no')}</Typography>
              </ListItem>

              <ListItem sx={styles.listItem}>
                <Typography variant='body2'>{t('govFundOptions.BASIC_CESG')}</Typography>
                <Typography variant='body2' fontWeight={700}>{isBasicCesg ? t('yes') : t('no')}</Typography>
              </ListItem>

              <ListItem sx={styles.listItem}>
                <Typography variant='body2'>{t('govFundOptions.CLB')}</Typography>
                <Typography variant='body2' fontWeight={700}>{isClb ? t('yes') : t('no')}</Typography>
              </ListItem>
            </>
          )}
        </>)}
      </Box>
      {returnPath ? (
        <ListItem>
          <Button fullWidth variant='contained' onClick={() => returnPath.action(account.id)} >
            {returnPath.callToAction}
          </Button>
        </ListItem>
      ) : (
        <>
          <ListItem sx={styles.center}>
            {!isRRSP && (
              <Typography variant='body2'>
                {t(isSetUpSubAccMsgAvailable ? 'messages.setUpSubAccount' : 'messages.requireAffiliate')}
              </Typography>
            )}
          </ListItem>
          <ListItem>
            <Button
              fullWidth
              variant={isSetUpSubAccMsgAvailable ? 'contained' : 'text'}
              data-testid='confirmButton'
              onClick={() => {
                continueTo(CreateSubAccountWizard, {
                  forObject: 'ACCOUNT',
                  forId: account.id,
                }, {
                  callToAction: t('createSubAccountWizard:actions.goToSubaccount'),
                  action: (newSubAccountId) => {
                    navigate(`account/${account.id}/subaccount/${newSubAccountId}`);
                  },
                });
              }}
              disabled={loading}
            >
              {t((isSetUpSubAccMsgAvailable || isRRSP) ? 'actions.createSubAccount' : 'outlineText.skipForSubAccount')}
            </Button>
          </ListItem>
          { isSetUpSubAccMsgAvailable && (
            <ListItem>
              <Button
                data-testid='confirmButton'
                onClick={() => navigate(`account/${account.id}`)}
                fullWidth
                variant='text'
              >
                {t('outlineText.skipNow')}
              </Button>
            </ListItem>
          )}
        </>
      )}

      {error && (
        <ListItem sx={styles.center}>
        <Typography variant='body2'>{error?.graphQLErrors[0].message}</Typography>
      </ListItem>
      )}
    </>
  );
}
