import {
  Grid, CircularProgress, Paper,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import EditDetails from './editDetails';
import { EditAllocations } from './editAllocations';
import EditIPS from './editIPS';
import EditDisclaimer from './editDisclaimer';
import AuditLog from '../../../components/tables/auditLog';

export const FETCH_MODEL = gql`
  query fetchModelPortfolio($input: FetchModelPortfolioInput!) {
    fetchModelPortfolio(input: $input) {
      modelPortfolio {
        id
        translatedName {
          en
          fr
        }
        translatedInvestmentObjective {
          en
          fr
        }
        translatedLiquidity {
          en
          fr
        }
        translatedPortfolioDescription {
          en
          fr
        }
        translatedPortfolioDisclaimer {
          en
          fr
        }
        theme {
          id
          name
        }
        children {
          id
          percentage
          financialProduct {
            id
            isCash
            ticker
            translatedName {
              en
            }
            primaryAssetClass{
              id
              translatedName {
                en
              }
            }
            secondaryAssetClass{
              id
              translatedName {
                en
              }
            }
            taxRank
          }
        }
        exchange
        forecastedRateOfReturn
        forecastedStandardDeviation
        lowThresholdScore
        reasonForUpdate
        riskReturnLevel
        state
        suggestable
        suggestedMaxScore
        suggestedMinScore
        url
        targetAllocations {
          min
          max
          secondaryAssetClass{
            id
            translatedName {
              en
            }
          }
        }
      }
    }
  }
`;

const EditModel = ({ modelId }: { modelId: string }) => {
  const [model, setModel] = useState<any>(null);
  const { t } = useTranslation('buildModels');

  const { data, refetch } = useQuery(FETCH_MODEL, {
    variables: {
      input: {
        modelPortfolioId: modelId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setModel(data.fetchModelPortfolio.modelPortfolio);
    }
  }, [data]);

  useEffect(() => {
    setModel(null);
    refetch({ input: { modelPortfolioId: modelId } });
  }, [modelId, refetch]);

  return (
    <>
      { model?.id ? (
        <Grid container rowGap={2}>
          <Grid item xs={ 12 }>
            <EditDetails model={ model } setModel={ setModel } />
          </Grid>
          <Grid item xs={ 12 }>
            <EditAllocations model={ model } setModel={ setModel } />
          </Grid>
          <Grid item xs={ 12 }>
            <EditIPS model={ model } setModel={ setModel } />
          </Grid>
          <Grid item xs={ 12 }>
            <EditDisclaimer model={ model } setModel={ setModel } />
          </Grid>
          <Grid item xs={ 12 }>
            <Card>
              <CardContent>
                <Grid item xs={ 12 }>
                  <Typography variant='subtitle1'>{t('buildModels:auditLog')}</Typography>
                </Grid>
                <Grid item xs={ 12 }>
                  <AuditLog objectId={modelId} objectType="MODEL_PORTFOLIO" />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Paper sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 15,
        }}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditModel;
