import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isNull, isUndefined } from 'lodash/fp';
import { IncomeAssetsAndDebtsVisual } from './incomeAssetsAndDebts.visual';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        annualIncomeCents
        annualIncomeStr
        financialLiquidAssetsCents
        financialFixedAssetsCents
        totalDebtCents
        annualDebtPaymentsCents
        debtAssociated
        isAccreditedInvestor
        sourceOfWealth
        sourceOfFunds
        uniqueCircumstances
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const IncomeAssetsAndDebts = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const [userData, setUserData] = useState<any>({
    annualIncomeCents: null,
    annualIncomeStr: null,
    financialLiquidAssetsCents: null,
    financialFixedAssetsCents: null,
    totalDebtCents: null,
    annualDebtPaymentsCents: null,
    debtAssociated: null,
    isAccreditedInvestor: null,
    sourceOfWealth: null,
    sourceOfFunds: null,
    uniqueCircumstances: null,
  });

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        userId,
      },
    },
  });

  const handleCentsData = (value?: string) => {
    if (isUndefined(value) || isNull(value)) return undefined;
    return value;
  };

  useEffect(() => {
    if (data) {
      setUserData({
        annualIncomeCents: handleCentsData(data.fetchUser.user.annualIncomeCents),
        annualIncomeStr: data.fetchUser.user.annualIncomeStr || undefined,
        financialLiquidAssetsCents: handleCentsData(data.fetchUser.user.financialLiquidAssetsCents),
        financialFixedAssetsCents: handleCentsData(data.fetchUser.user.financialFixedAssetsCents),
        totalDebtCents: handleCentsData(data.fetchUser.user.totalDebtCents),
        annualDebtPaymentsCents: handleCentsData(data.fetchUser.user.annualDebtPaymentsCents),
        debtAssociated: data.fetchUser.user.debtAssociated || undefined,
        isAccreditedInvestor: (typeof data.fetchUser.user.isAccreditedInvestor === 'boolean') ? data.fetchUser.user.isAccreditedInvestor : undefined,
        sourceOfWealth: data.fetchUser.user.sourceOfWealth || undefined,
        sourceOfFunds: data.fetchUser.user.sourceOfFunds || undefined,
        uniqueCircumstances: data.fetchUser.user.uniqueCircumstances || undefined,
      });
    }
  }, [data]);

  return (
    <IncomeAssetsAndDebtsVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default IncomeAssetsAndDebts;
