/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AccountBalanceOutlined, BlockRounded, CheckCircle, DeleteForever, PlayCircleOutline,
} from '@mui/icons-material';
import {
  MenuItem,
} from '../../../../2-component';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { UserStates, WorkflowStates } from '../../../../../interfaces';
import { ConfirmationModal } from '../../../../3-pattern';
import { EditClientOrganization } from './components/editClientOrganization';
import { generateClientNameString } from '../../../../../util';

enum UserTransition {
  activate = 'activate',
  freeze = 'freeze',
  deactivate = 'deactivate',
}

const TRANSITION_USER = gql`
  mutation transitionUser($input: TransitionUserInput!) {
    transitionUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName
        lastName
        state
        households {
          id
        }
        organization {
          id
        }
      }
    }
  }
`;

export const FETCH_WORKFLOWS = gql`
  query fetchWorkflows($input: FetchWorkflowsInput!) {
    fetchWorkflows(input: $input) {
      workflows {
        id
        name {
          en
        }
      }
      totalCount
    }
  }
`;

export const CREATE_WORKFLOW_COMPLETION = gql`
  mutation createWorkflowCompletion($input: CreateWorkflowCompletionInput!) {
    createWorkflowCompletion(input: $input) {
      workflowCompletion {
        id
      }
    }
  }
`;

export const EditClient = ({
  objectId, onClose, options = {}, setActiveWorkflowCompletionId,
}: {
  objectId: string, onClose: () => void, options?: any, setActiveWorkflowCompletionId?: (id: string) => void,
}) => {
  const { t } = useTranslation(['pageConfiguration']);
  const { permissions } = usePermissions();
  const [user, setUser] = useState<any>(null);
  const [menuOptions, setMenuOptions] = useState<any[]>([]);
  const [workflowOptions, setWorkflowOptions] = useState<any[]>([]);
  const [transitionOptions, setTransitionOptions] = useState<any[]>([]);
  const [editOrganizationOpen, setEditOrganizationOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalProps, setWarningModalProps] = useState({
    open: false,
    onConfirm: () => {},
    onCancel: () => {},
    title: '',
    bodyText: '',
    showCancelButton: true,
  });

  const { data: userFetched, refetch } = useQuery(FETCH_USER, {
    variables: {
      userId: objectId,
    },
  });

  const [transitionUser] = useMutation(TRANSITION_USER, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const skipWorkflow = !permissions.includes('read:workflow') || !permissions.includes('write:workflow_completion');

  const { data: workflowsData } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: userFetched?.fetchUser?.user?.organization?.id,
          state: WorkflowStates.ACTIVE,
        },
        pagination: {
          perPage: 50,
        },
      },
    },
    skip: skipWorkflow || !userFetched?.fetchUser,
  });
  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      if (setActiveWorkflowCompletionId) {
        setActiveWorkflowCompletionId(data?.createWorkflowCompletion?.workflowCompletion?.id || '');
      }
      onClose();
    },
  });

  useEffect(() => {
    if (workflowsData && userFetched && options.canTriggerWorkflows) {
      setWorkflowOptions(workflowsData.fetchWorkflows.workflows.map((workflow: any) => ({
        prefixIcon: <PlayCircleOutline style={{ marginRight: '10px' }} />,
        label: `Start ${translateBackend(workflow.name)}`,
        onClick: () => {
          createWorkflowCompletion({
            variables: {
              input: {
                objectId: userFetched.fetchUser.user.id,
                objectType: 'USER',
                workflowId: workflow.id,
                organizationId: userFetched.fetchUser.user.organization.id,
              },
            },
          });
        },
      })));
    }
  }, [workflowsData, createWorkflowCompletion, userFetched]);

  useEffect(() => {
    const newMenu = [];
    if (options.canChangeOrganization) {
      newMenu.push({
        prefixIcon: <AccountBalanceOutlined style={{ marginRight: '10px' }} />,
        label: t('editClient.changeOrganization'),
        onClick: () => updateOrganization(),
      });
    }
    // if (options.canChangeHousehold) {
    //   newMenu.push({
    //     prefixIcon: <SupervisedUserCircle style={{ marginRight: '10px' }} />,
    //     label: t('editClient.changeHousehold'),
    //     onClick: () => { addClientToHousehold(); },
    //   });
    // }
    setMenuOptions(newMenu);
  }, [options]);

  useEffect(() => {
    if (userFetched?.fetchUser?.user) {
      setUser(userFetched.fetchUser.user);
      const newTransitionOptions = [];
      if (userFetched.fetchUser.user.state === UserStates.ACTIVE && options.canFreezeClient) {
        newTransitionOptions.push({
          prefixIcon: <BlockRounded style={{ marginRight: '10px' }} />,
          label: t('editClient.freezeClient'),
          onClick: () => freeze(),
        });
      }
      if ((userFetched.fetchUser.user.state === UserStates.FROZEN || userFetched.fetchUser.user.state === UserStates.INACTIVE) && (options.canFreezeClient || options.canDeactivateClient)) {
        newTransitionOptions.push({
          prefixIcon: <CheckCircle style={{ marginRight: '10px' }} />,
          label: t('editClient.activateClient'),
          onClick: () => activate(),
        });
      }
      if ((userFetched.fetchUser.user.state === UserStates.ACTIVE || userFetched.fetchUser.user.state === UserStates.FROZEN) && options.canDeactivateClient) {
        newTransitionOptions.push({
          prefixIcon: <DeleteForever style={{ marginRight: '10px' }} />,
          label: t('editClient.deactivateClient'),
          onClick: () => deactivate(),
        });
      }
      setTransitionOptions(newTransitionOptions);
    }
  }, [userFetched]);

  const updateOrganization = () => {
    setEditOrganizationOpen(true);
  };

  const activate = () => {
    setShowWarningModal(true);
    setWarningModalProps({
      open: true,
      onConfirm: () => {
        setShowWarningModal(false);
        transitionUser({
          variables: {
            input: {
              userId: objectId,
              transition: UserTransition.activate,
            },
          },
        });
      },
      onCancel: () => setShowWarningModal(false),
      title: t('editClient.reactivateClientModal.title'),
      bodyText: t('editClient.reactivateClientModal.bodyText'),
      showCancelButton: true,
    });
  };

  const deactivate = () => {
    setShowWarningModal(true);
    setWarningModalProps({
      open: true,
      onConfirm: () => {
        setShowWarningModal(false);
        transitionUser({
          variables: {
            input: {
              userId: objectId,
              transition: UserTransition.deactivate,
            },
          },
        });
      },
      onCancel: () => setShowWarningModal(false),
      title: t('editClient.deactivateClientModal.title'),
      bodyText: t('editClient.deactivateClientModal.bodyText'),
      showCancelButton: true,
    });
  };

  const freeze = () => {
    setShowWarningModal(true);
    setWarningModalProps({
      open: true,
      onConfirm: () => {
        setShowWarningModal(false);
        transitionUser({
          variables: {
            input: {
              userId: objectId,
              transition: UserTransition.freeze,
            },
          },
        });
      },
      onCancel: () => setShowWarningModal(false),
      title: t('editClient.freezeClientModal.title'),
      bodyText: t('editClient.freezeClientModal.bodyText'),
      showCancelButton: true,
    });
  };

  return (
    <>
     { [...workflowOptions, ...menuOptions, ...transitionOptions].map((item: any, index: number) => (
        <MenuItem key={index} onClick={(event: React.MouseEvent<HTMLElement>) => { item.onClick(event); }}>
          {item.prefixIcon}
          {item.label}
        </MenuItem>
     )) }
     {showWarningModal && <ConfirmationModal {...warningModalProps} />}
      { user?.id && (
        <EditClientOrganization
          userId={user?.id}
          userHouseholds={user?.households}
          currentOrganization={user?.organization}
          clientName={generateClientNameString(user)}
          onClose={() => {
            refetch();
            setEditOrganizationOpen(false);
          }}
          open={editOrganizationOpen}
        />
      )}
     </>
  );
};
