import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Exchanges } from '../../interfaces/financialProduct';

export const FETCH_SECURITIES = gql`
  query fetchFinancialProducts($input: FetchFinancialProductsInput!) {
    fetchFinancialProducts(input: $input) {
      financialProducts {
        id
        ticker
        translatedName {
          en
        }
        primaryAssetClass{
          id
          translatedName {
            en
          }
        }
        secondaryAssetClass{
          id
          translatedName {
            en
          }
        }
      }
    }
  }
`;

const SecuritySelect = ({
  setSecurity,
  value,
  label,
  size,
  disabledWith = null,
  readonly = false,
  exceptTickers = [],
  inputError,
  errorText,
  exchange,
  withAny,
}: {
  setSecurity: (event: any) => void;
  value: string;
  label: string;
  size?: 'small' | 'medium' | undefined;
  disabledWith?: string | null;
  readonly?: boolean;
  exchange?: Exchanges;
  exceptTickers?: any[];
  inputError?: boolean;
  errorText?: string;
  withAny?: boolean;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  let filter: any = { organizationId: activeOrganization.id };
  if (exchange) filter = { organizationId: activeOrganization.id, exchange };
  const { loading, error, data } = useQuery(FETCH_SECURITIES, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter,
        pagination: { perPage: 300, sortField: 'ticker' },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  const list = data?.fetchFinancialProducts?.financialProducts || [];
  const filteredList = exceptTickers.length === 0 ? list : list.filter((i: any) => i.id === value || !exceptTickers.includes(i.ticker));
  return (
    <>
      {permissions.includes('read:financial_products') ? (
        <TextField
          select
          value={data ? value : ''}
          label={label}
          sx={{ width: '100%' }}
          error={inputError}
          helperText={inputError && errorText}
          onChange={(e: any) => {
            const val = e.target.value;

            if (withAny && val === 'ANY') {
              setSecurity('ANY');
              return;
            }

            setSecurity(data?.fetchFinancialProducts?.financialProducts?.find((f: any) => f.id === val));
          }}
          size={size}
          disabled={!isNull(disabledWith)}
          InputProps={{
            readOnly: readonly,
          }}
        >
          {loading && (
            <MenuItem disabled>
              <em>Loading...</em>
            </MenuItem>
          )}

          {!loading && disabledWith && (
            <MenuItem key={value} value={value}>
              <strong>{disabledWith}</strong>
            </MenuItem>
          )}

          {!loading && !disabledWith && withAny && (
            <MenuItem key='ANY' value='ANY'>{t('components:any')}</MenuItem>
          )}

          {!loading && !disabledWith && filteredList.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              <strong>{item.ticker}</strong> - {item.translatedName.en}
            </MenuItem>
          ))}
        </TextField>
      ) : (<></>)}
    </>
  );
};

export default SecuritySelect;
