import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  useContext, useEffect, useState,
} from 'react';
import { groupBy, isUndefined, sum } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import {
  Typography, Box,
} from '../../../1-primative';
import {
  Card,
  CardContent,
  MenuItem,
  SelectField,
} from '../../../2-component';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import {
  Account, AccountStates, NavigationStyles, Relationship,
} from '../../../../interfaces';
import { usePageState } from '../../../../util/usePageState';
import { PageObjectType } from '../../../5-page';
import { AccountTable } from './components/accountTable';
import { translateBackend } from '../../../../assets/i18n/config';
import { ClientContext } from '../../../../pages/client';
import { HouseholdContext } from '../../../../pages/household';
import { generateClientInitials, generateClientNameString } from '../../../../util';
import { FilterModal } from '../../../3-pattern';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';
import { PAGE_HEIGHT, PrintContext, calculateTableSplit } from '../../../5-page/viewClientReport/components/printReport';
import { STATE_CHANGES_FIELD } from '../../../../util/reusableGraphQLFields';

const SHARED_CUSTODIAN_STATS = (currency: string | undefined = undefined) => `
  custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} {
    marketValueCents
    currency
    simpleReturnAmountCents
    simpleReturnPercent
    timeWeightedReturn
    moneyWeightedReturn
    originalCurrencies {
      currency
      marketValueCents
    }
    holdings {
      adjustedCostBaseCents
      quantity
      totalCents
      originalCurrency {
        currency
        totalCents
      }
    }
  }
`;
const SHARED_STATS = (forUserId = undefined) => `
  id
  statistics(input: {currency: $currency},${forUserId ? `forUserId:"${forUserId}"` : ''}) {
    historySnapshot(date: $date) {
      marketValueCents
      currency
      holdings {
        totalCents
        originalCurrency {
          currency
          totalCents
        }
      }
    }
    simpleReturnPercent(startDate: $startDate, endDate: $endDate)
    simpleReturnAmount(startDate: $startDate, endDate: $endDate)
    timeWeightedReturn(startDate: $startDate, endDate: $endDate)
    moneyWeightedReturn(startDate: $startDate, endDate: $endDate)
  }
`;

const SHARED_CONTEXT_CUSTODIAN_STATS = (currency: string | undefined = undefined) => `
  custodianStatistics ${currency ? `(input: {currency: ${currency}})` : ''} {
    marketValueCents
  }
`;

const SHARED_CONTEXT_STATS = `
  statistics(input: {currency: $currency}) {
    marketValueCents
  }
`;

export const FETCH_ACCOUNTS = (permissions: string[], useExternalStatisticsEnabled = undefined, currency: string | undefined = undefined) => gql`
  query fetchAccounts($input: FetchAccountsInput!, $currency: StatisticsCurrencyTypes, $date: Date!, $startDate: Date, $endDate: Date) {
    fetchAccounts(input: $input) {
      totalCount
      accounts {
        id
        type
        state
        baseCurrency
        availableCurrencies
        custodianSortingOrderPriority
        ${STATE_CHANGES_FIELD}
        tlhEnabled
        nickName
        translatedNickName { en fr }
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        ${SHARED_STATS()}
        ${useExternalStatisticsEnabled ? SHARED_CUSTODIAN_STATS(currency) : ''}
        subAccounts {
          id
          state
          theme {
            translatedName { en fr }
          }
        }
        affiliations {
          type
          user {
            firstName
          }
        }
        incompleteFormAgreements { id }
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          organization {
            availableFeatureFlags
          }
          ${SHARED_CONTEXT_STATS}
          ${useExternalStatisticsEnabled ? SHARED_CONTEXT_CUSTODIAN_STATS(currency) : ''}
        }
        householdClientGroup {
          id
          name
          ${SHARED_CONTEXT_STATS}
          ${useExternalStatisticsEnabled ? SHARED_CONTEXT_CUSTODIAN_STATS(currency) : ''}
          relationships {
            type
            user {
              id
              state
              ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName primaryEmail' : ''}
              ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
              ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents financialFixedAssetsCents totalDebtCents' : ''}
              ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
              type
          }
            accessType
        }
        }
      }
    }
  }
`;

interface AccountToSort extends Account {
  sortAccountNumber?: string
  holder?: string
}

export const Accounts = ({
  objectType,
  objectId,
  options,
  print = false,
  i = 0,
}: {
  objectType: PageObjectType,
  objectId: string
  options: any,
  print?: boolean,
  i?: number,
}) => {
  const PAGE_SIZE = 15;
  const { timePeriod } = useClientReportContext();
  const { custodianConnection, userContext } = useContext(UserContext);
  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const useExternalStatisticsEnabled = isUnifiedClientExperience ? options.useExternalStatisticsEnabled : options.useExternalStatisticsEnabled && custodianConnection?.enableFetchCustodianStatistics;
  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [printRowSplit, setPrintRowSplit] = useState<number[]>([]);
  const [startOnNew, setStartOnNew] = useState(false);
  const [previousPageHeight, setPreviousPageHeight] = useState(0);
  const [page, setPage] = usePageState(1, 'accountsPage');
  const { activeCurrency } = useContext(UserContext);
  const { setOptions, options: printOptions } = useContext(PrintContext);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>({
    [AccountStates.ACTIVE]: isUndefined(options.filterForActive) ? true : options.filterForActive,
    [AccountStates.REQUESTED]: isUndefined(options.filterForRequested) ? true : options.filterForRequested,
    [AccountStates.FROZEN]: isUndefined(options.filterForFrozen) ? true : options.filterForFrozen,
    [AccountStates.FAILED]: isUndefined(options.filterForFailed) ? true : options.filterForFailed,
    [AccountStates.INITIATED]: isUndefined(options.filterForInitiated) ? true : options.filterForInitiated,
    [AccountStates.READY]: isUndefined(options.filterForReady) ? true : options.filterForReady,
    [AccountStates.INACTIVE]: false,
    [AccountStates.CANCELED]: false,
  });
  const [filterBySimplifiedStatus, setFilterBySimplifiedStatus] = useState<Record<string, boolean>>({
    ACTIVE: true,
    PENDING: true,
    CLOSED: false,
  });

  const setSimplifiedFilter = (value: any) => {
    const newFilterBySimplifiedStatus = Object.fromEntries(Object.keys(filterBySimplifiedStatus).map((key) => [key, value.includes(key)]));
    setFilterBySimplifiedStatus({ ...newFilterBySimplifiedStatus });
    setFilterByState({
      ...{
        ACTIVE: newFilterBySimplifiedStatus.ACTIVE,
        REQUESTED: newFilterBySimplifiedStatus.PENDING,
        FROZEN: newFilterBySimplifiedStatus.PENDING,
        INITIATED: newFilterBySimplifiedStatus.PENDING,
        READY: newFilterBySimplifiedStatus.PENDING,
        FAILED: newFilterBySimplifiedStatus.CLOSED,
        INACTIVE: newFilterBySimplifiedStatus.CLOSED,
        CANCELED: newFilterBySimplifiedStatus.CLOSED,
      },
    });
  };

  const useSimplifiedAccountStatusFilter = [PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && !!options.simplifiedAccountStatusFilter;

  const { data, loading } = useQuery(FETCH_ACCOUNTS(permissions, useExternalStatisticsEnabled, activeCurrency), {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
    variables: {
      input: {
        filter: {
          ...([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && { userId: objectId }),
          ...(objectType === PageObjectType.HOUSEHOLD && { clientGroupId: objectId }),
          includeHousehold: objectType === PageObjectType.HOUSEHOLD,
          states: Object.keys(filterByState).filter((key) => filterByState[key]),
        },
        pagination: {
          perPage: 1000,
        },
      },
      currency: activeCurrency,
      date: dayjs(timePeriod.endDate).format('YYYY-MM-DD'),
      startDate: dayjs(timePeriod.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(timePeriod.endDate).format('YYYY-MM-DD'),
    },
    onCompleted: (e) => {
      setAccounts([...e.fetchAccounts.accounts]);
      setTotalCount(e.fetchAccounts.totalCount);
    },
  });

  useEffect(() => {
    const heightLeft = (i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0));
    if (heightLeft !== previousPageHeight) {
      setPreviousPageHeight(heightLeft);
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [printOptions]);

  useEffect(() => {
    if (data) {
      const copy = [...printOptions];
      // Title
      const titleHeight = 56;

      const heightLeft = (i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0));

      const rowSplit = calculateTableSplit({
        headerHeight: 48,
        rowHeight: 49,
        totalRows: data.fetchAccounts.totalCount,
        footerHeight: 49,
        pageHeight: heightLeft - titleHeight,
      });

      setPrintRowSplit(rowSplit.splitRows);
      setStartOnNew(rowSplit.startOnNew);

      copy[i] = {
        heightLeftOnLastPage: rowSplit.heightLeft - 40, // bottom margin
        loading: false,
      };

      setOptions(copy);
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data, previousPageHeight]);

  const getAccountHolders = (account: Account) => {
    const names = [
      account.user.firstName,
      ...(account?.affiliations ?? []).filter((aff: any) => ['JOINT', 'AUTHORIZED_INDIVIDUAL', 'TRUSTEE'].includes(aff.type)).map((affiliation: any) => affiliation.user.firstName),
    ];
    return names.join(', ');
  };

  let accountsSorted: AccountToSort[] = [];
  const hasCustodianSortingOrderPriority = accounts.every((acc) => acc.custodianSortingOrderPriority);

  if (hasCustodianSortingOrderPriority) {
    const accountsToSort: AccountToSort[] = accounts.map((account) => ({
      ...account,
      sortAccountNumber: account.custodianAccountNumber?.slice(0, -1),
      holder: getAccountHolders(account),
    }));
    const accountsGroupedByHolder = groupBy('holder', accountsToSort);
    const accountsGroupedByHolderAndNumber: any = {};
    Object.keys(accountsGroupedByHolder).forEach((key) => {
      accountsGroupedByHolderAndNumber[key] = groupBy('sortAccountNumber', accountsGroupedByHolder[key].sort((a, b) => (
        (a.custodianSortingOrderPriority ?? 0) - (b.custodianSortingOrderPriority ?? 0)
      )));
    });
    Object.keys(accountsGroupedByHolderAndNumber).forEach((outerKey) => {
      Object.keys(accountsGroupedByHolderAndNumber[outerKey]).forEach((innerKey) => {
        accountsGroupedByHolderAndNumber[outerKey][innerKey].forEach((elem: AccountToSort) => {
          accountsSorted.push(elem);
        });
      });
    });
  } else {
    accountsSorted = accounts.sort((a, b) => {
      if (a.custodianAccountNumber && b.custodianAccountNumber) {
        return a.custodianAccountNumber.localeCompare(b.custodianAccountNumber);
      }
      return (b.statistics?.historySnapshot?.marketValueCents ?? 0) - (a.statistics?.historySnapshot?.marketValueCents ?? 0);
    });
  }

  const accountsPaginated = accountsSorted.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByState]);

  const HeaderIcons = () => (
    <Box display='flex'>
      {options.filterEnabled && (
        <Box>
          <FilterModal filterExists={true}>
            <SelectField
              fullWidth
              label={t('accountsDetail:state')}
              value={useSimplifiedAccountStatusFilter
                ? Object.keys(filterBySimplifiedStatus).filter((key) => filterBySimplifiedStatus[key])
                : Object.keys(filterByState).filter((key) => filterByState[key])
              }
              multiple
              onChange={(e: any) => {
                useSimplifiedAccountStatusFilter
                  ? setSimplifiedFilter(e.target.value)
                  : setFilterByState(Object.fromEntries(Object.keys(filterByState).map((key) => [key, e.target.value.includes(key)])));
              }}
            >
              {useSimplifiedAccountStatusFilter
                ? (
                  Object.keys(filterBySimplifiedStatus).map((state, index) => (
                  <MenuItem key={index} value={state}>{t(`accountsDetail:accountState.${state}`)}</MenuItem>))
                ) : (
                  [
                    ...(isUndefined(options.filterForActive) || options.filterForActive) ? [AccountStates.ACTIVE] : [],
                    ...(isUndefined(options.filterForRequested) || options.filterForRequested) ? [AccountStates.REQUESTED] : [],
                    ...(isUndefined(options.filterForFrozen) || options.filterForFrozen) ? [AccountStates.FROZEN] : [],
                    ...(isUndefined(options.filterForFailed) || options.filterForFailed) ? [AccountStates.FAILED] : [],
                    ...(isUndefined(options.filterForInitiated) || options.filterForInitiated) ? [AccountStates.INITIATED] : [],
                    ...(isUndefined(options.filterForReady) || options.filterForReady) ? [AccountStates.READY] : [],
                    ...(isUndefined(options.filterForClosed) || options.filterForClosed) ? [AccountStates.INACTIVE] : [],
                    ...(isUndefined(options.filterForCanceled) || options.filterForCanceled) ? [AccountStates.CANCELED] : [],
                  ].map((state, index) => <MenuItem key={index} value={state}>{t(`accountsDetail:accountState.${state}`)}</MenuItem>))
              }
            </SelectField>
          </FilterModal>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      { startOnNew && (<Box sx={{ pageBreakBefore: 'always', mt: '40px' }} />)}
      <Box display="flex" justifyContent="space-between" pb={2} minHeight='40px'>
        <Box display="flex" alignItems={'center'}>
          <Typography variant='headingSmall'>
            {options.customTitle || options.accountsCustomTitle ? translateBackend(options.customTitle ?? options.accountsCustomTitle) : t('accountsSummary.header')}
          </Typography>
        </Box>
        {
          options.display === 'cards' && (
            <HeaderIcons />
          )
        }
      </Box>
      {loading ? (
        <Card sx={{ overflowX: 'auto' }}>
          {options.filterEnabled && (
            <CardContent>
              <Box display='flex' justifyContent='end'>
                <HeaderIcons />
              </Box>
            </CardContent>
          )}
          <AccountTable
            data-testid='accounts-table'
            allAccounts={accountsPaginated}
            accounts={accountsPaginated}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            loading={loading}
            options={options}
          />
        </Card>
      ) : (
        <ClientContext.Provider value={{
          orgSettings: accounts[0]?.user.organization,
          totalMarketValueCents: useExternalStatisticsEnabled ? accounts[0]?.user?.custodianStatistics?.marketValueCents ?? 0 : accounts[0]?.user?.statistics?.historySnapshot?.marketValueCents ?? 0,
        }}>
          <HouseholdContext.Provider value={{
            orgSettings: accounts[0]?.user.organization,
            totalMarketValueCents: useExternalStatisticsEnabled
              ? accounts[0]?.householdClientGroup?.custodianStatistics?.marketValueCents ?? 0
              : accounts[0]?.householdClientGroup?.statistics?.historySnapshot?.marketValueCents ?? 0,
            members: accounts[0]?.householdClientGroup ? accounts[0]?.householdClientGroup?.relationships.map((rel: Relationship) => ({
              id: rel.user.id,
              initials: generateClientInitials(rel.user),
              name: generateClientNameString(rel.user),
            })) : [],
            indexedMembers: accounts[0]?.householdClientGroup ? Object.fromEntries(accounts[0]?.householdClientGroup.relationships.map((rel: Relationship, index: number) => [rel.user.id, index]))
              : {},
          }}>
            {
              print
                ? printRowSplit.map((num: number, index: number) => (
                  <Box sx={{ pageBreakBefore: index !== 0 ? 'always' : undefined, marginTop: index !== 0 ? '40px' : undefined }} key={index}>
                    { index !== 0 && (
                      <Typography variant='headingXSmall' mb={2}>
                        {
                          options.customTitle || options.accountsCustomTitle
                            ? translateBackend(options.customTitle ?? options.accountsCustomTitle)
                            : t('accountsSummary.header')} {t('shared:continued')
                        }
                      </Typography>
                    )}
                    <Card variant='outlined'>
                      <AccountTable
                        data-testid='accounts-table'
                        allAccounts={accountsPaginated}
                        accounts={accountsPaginated.slice(sum(printRowSplit.slice(0, index)), sum(printRowSplit.slice(0, index)) + num)}
                        totalCount={totalCount}
                        page={page}
                        setPage={setPage}
                        loading={loading}
                        options={options}
                        showFooter={index === printRowSplit.length - 1}
                      />
                    </Card>
                  </Box>
                )) : (
                  <Card>
                    {options.filterEnabled && (
                      <CardContent>
                        <Box display='flex' justifyContent='end'>
                          <HeaderIcons />
                        </Box>
                      </CardContent>
                    )}
                    <AccountTable
                      data-testid='accounts-table'
                      allAccounts={accountsPaginated}
                      accounts={accountsPaginated}
                      totalCount={totalCount}
                      page={page}
                      setPage={setPage}
                      loading={loading}
                      options={options}
                    />
                  </Card>
                )
            }
          </HouseholdContext.Provider>
        </ClientContext.Provider>
      )}
    </>
  );
};
