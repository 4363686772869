/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, styled, Tab, Tabs, TextField, Typography,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  gql, useApolloClient, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FETCH_HOUSEHOLD } from 'pages/household';
import { EntityTypes } from 'interfaces';
import { FETCH_USER_AUDIT_LOG } from 'components/tables/auditLog';
import ConfirmationModal from '../../../../components/modals/confirmationModal';
import { colors } from '../../../../theme/colors';
import {
  FETCH_ALL_USER_AGREEMENTS, FETCH_ACCOUNT_AGREEMENTS, FETCH_DOCUMENTS, generateColor, CombinedDocumentAndAgreement,
} from '../documents';
import { DateTime } from '../../../../components/misc/dateTime/dateTime';
import { FileDownloaderButton } from './fileDownloaderButton';
import { FileDownloaderCard } from './fileDownloaderCard';
import { InlineImage } from './inlineImage';
import DocumentUpload from '../documentUpload';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { TabPanel } from '../../../../components/tabs/ovTabs';
import { FETCH_USER } from '../..';
import { FETCH_ALL_CLIENT_GROUP_AGREEMENTS } from '../householdDocuments';
import { PdfViewer } from '../../../../ovComponents/2-component/pdfViewer/pdfViewer';
import { downloadHtmlAsPdf } from '../../../../util/downloadHtmlAsPdf';
import { fetchAuthorizedIndividual } from '../../../../util/fetchAuthorizedIndividual';

export const styles = {
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '624px',
      },
    },
  },
  dialogButton: {
    minWidth: '160px',
    fontWeight: 600,
    fontSize: '14px',
  },
  closeButton: {
    float: 'right',
    width: '13.91px',
    height: '13.91px',
    fontWeight: 500,
    color: colors.black,
  },
  gridPanel: {
    height: '427px',
    overflowY: 'auto',
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral80}`,
  },
  tabPanel: {
    padding: 0,
    paddingTop: '15px',
  },
  fieldset: {
    height: '100%',
    overflow: 'auto',
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral80}`,
  },
  borderTitle: {
    fontSize: '12px',
    color: colors.gray500,
    fontWeight: 500,
  },
  downloadSection: {
    width: '250px',
    position: 'relative',
    top: '25%',
    padding: '10px',
    margin: '0 auto',
    borderRadius: '8px',
    background: 'white',
    height: '200px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#bec9d4',
    },
  },
  gridDownload: {
    bottom: '16px',
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    cursor: 'pointer',
    left: '0',
    color: colors.success,
  },
  regenerateGrid: {
    bottom: '-100px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    left: '0',
    color: colors.success,
  },
  gridFileName: {
    textAlign: 'center',
    color: '#2B4763',
    marginTop: '11.33px',
    fontSize: '16px',
    overflowWrap: 'break-word',
  },
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '424px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '376px',
      title: {
        fontWeight: 700,
      },
    },
    dialogButton: {
      minWidth: '160px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
  title: {
    border: 'none',
  },
  editIcon: {
    fontSize: '0.9rem',
    position: 'relative',
    top: '1.5px',
    marginLeft: '0.25rem',
    cursor: 'pointer',
  },
  titleStyle: {
    fontSize: '1.25rem',
    fontWeight: 700,
    marginTop: '13px',
    marginBottom: '13px',
  },
  checkIconGrid: {
    display: 'flex',
    alignItems: 'center',
  },
};

const RENDER_AGREEMENT = gql`
  query renderFormAgreement($input: RenderFormAgreementInput!) {
    renderFormAgreement(input: $input) {
      html
    }
  }
`;

const PREVIEW_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) {
      html
    }
  }
`;

const FETCH_FORM_TEMPLATE_URL = gql`
  query fetchFormTemplateURL($type: FormTypes!) {
    fetchFormTemplate(type: $type) {
      templateUrl
    }
  }
`;

const FETCH_PRE_FILLED_PDF_URL = gql`
  query previewFilledPdf($input: PreviewFilledPdfInput!) {
    previewFilledPdf(input: $input) {
      url
    }
  }
`;

const SIGN_FORM_AGREEMENTS = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
      }
    }
  }
`;

const UPDATE_FILE_DOCUMENT = gql`
  mutation updateFileDocument($input: UpdateFileDocumentInput!) {
    updateFileDocument(input: $input) {
      fileDocument {
        id
      }
    }
  }
`;

export const DELETE_FILE_DOCUMENT = gql`
  mutation deleteFileDocument($fileDocumentId: ObjectID!) {
    deleteFileDocument(input: { fileDocumentId: $fileDocumentId }) {
      fileDocument {
        id
      }
    }
  }
`;

const REGENERATE_PDF_DOCUMENT = gql`
  mutation regeneratePdf($input: RegeneratePdfInput!) {
    regeneratePdf(input: $input) {
      formAgreement {
        id
      }
    }
  }
`;

interface FormAgreement {
  type: string;
  version: number;
}

interface SignAgreement {
  formAgreements: [FormAgreement];
  language: string;
  subAccountId: string;
}

const showBothTabHeaders = (doc: any, previewData: any, agreementData: any): boolean => {
  /* We only need to show both tabs if both options are available otherwise Tab headers should be hide */
  if (
    ((doc.state === 'PENDING' && previewData) || (doc.state === 'SIGNED' && agreementData?.renderFormAgreement?.html))
    && doc.signedUrl
    && !['PAD_AGREEMENT', 'GOAL_INVESTMENT_POLICY_STATEMENT'].includes(doc.type)
  ) {
    return true;
  }
  return false;
};

const DocumentViewerModal = ({
  onModalClose,
  afterSigned,
  doc,
  title,
  userId,
}: {
  onModalClose: () => void;
  afterSigned: () => void;
  title: string;
  doc: CombinedDocumentAndAgreement;
  maxWidth?: any;
  loading?: boolean;
  userId: string;
}) => {
  const { userContext } = useContext(UserContext);
  const filter: any = {};
  if (doc.relatedObjectType === 'ACCOUNT') {
    filter.accountId = doc.relatedObjectId;
  } else if (doc.relatedObjectType === 'SUBACCOUNT') {
    filter.subAccountId = doc.relatedObjectId;
  } else if (doc.relatedObjectType === 'USER') {
    filter.userId = doc.relatedObjectId;
  } else if (doc.relatedObjectType === 'GOAL') {
    filter.goalId = doc.relatedObjectId;
  } else if (doc.relatedObjectType === 'SCHEDULED_TRANSFER') {
    filter.scheduledTransferId = doc.relatedObjectId;
  } else if (doc.relatedObjectType === 'CLIENT_GROUP') {
    filter.clientGroupId = doc.relatedObjectId;
  }
  const { t } = useTranslation(['shared', 'client']);
  const { showToast } = useGlobalToast();
  const [tabNumber, setTabNumber] = useState('1');
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [showsignConfirmation, setShowSignConfirmation] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const graphqlClient = useApolloClient();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabNumber(newValue);
  };
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const [showEditable, setShowEditable] = useState(false);
  const [newTitle, setNewTitle] = useState(title || '');
  const doNameChange = () => {
    updateFileDocument({
      variables: {
        input: {
          fileDocumentId: doc.id,
          name: newTitle,
        },
      },
    });
    setShowEditable(false);
  };
  const cancelNameChange = () => {
    setNewTitle(title);
    setShowEditable(false);
  };

  const [previewAgreement, { data: previewData, loading: previewLoading }] = useLazyQuery(PREVIEW_AGREEMENT, {
    variables: {
      input: {
        ...filter,
        type: doc?.type,
      },
    },
  });
  const [renderAgreement, { data: agreementData }] = useLazyQuery(RENDER_AGREEMENT, {
    variables: {
      input: {
        formAgreementId: doc?.id,
      },
    },
  });

  const [fetchFormTemplateURL] = useLazyQuery(FETCH_FORM_TEMPLATE_URL, {
    variables: {
      type: doc?.type,
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchPreFilledPdfURL, { loading: pdfLoading }] = useLazyQuery(FETCH_PRE_FILLED_PDF_URL, {
    variables: {
      input: {
        type: doc?.type,
        accountId: filter.accountId ?? undefined,
        subAccountId: filter.subAccountId ?? undefined,
        userId: filter.userId ?? undefined,
        goalId: filter.goalId ?? undefined,
        scheduledTransferId: filter.scheduledTransferId ?? undefined,
        clientGroupId: filter.clientGroupId ?? undefined,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const downloadTemplatePDF = async () => {
    const url = await generateTemplateUrl();
    window.open(url, '_blank');
  };

  const downloadPreFilledPDF = async () => {
    const url = await generatePreFilledPdfUrl();
    window.open(url, '_blank');
  };

  const generateTemplateUrl = async () => {
    const result = await fetchFormTemplateURL();
    return result?.data?.fetchFormTemplate.templateUrl;
  };

  const generatePreFilledPdfUrl = async () => {
    const result = await fetchPreFilledPdfURL();
    return result?.data?.previewFilledPdf.url;
  };
  const { permissions } = usePermissions();

  const [signFormAgreement, { loading: signMutationRunning }] = useMutation(SIGN_FORM_AGREEMENTS, {
    refetchQueries: [
      FETCH_ALL_CLIENT_GROUP_AGREEMENTS,
      FETCH_ALL_USER_AGREEMENTS(permissions),
      FETCH_ACCOUNT_AGREEMENTS,
      FETCH_USER(permissions),
      FETCH_HOUSEHOLD(permissions),
      FETCH_USER_AUDIT_LOG(permissions),
    ],
    onCompleted: () => {
      afterSigned();
    },
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT, {
    variables: {
      fileDocumentId: doc?.id,
    },
    refetchQueries: [FETCH_DOCUMENTS],
  });

  const [updateFileDocument, { loading: updateInProgress }] = useMutation(UPDATE_FILE_DOCUMENT, {
    refetchQueries: [FETCH_DOCUMENTS],
  });

  const [regeneratePdf] = useMutation(REGENERATE_PDF_DOCUMENT, {
    variables: {
      input: {
        formAgreementId: doc?.id,
      },
    },
    refetchQueries: [FETCH_DOCUMENTS],
  });

  useEffect(() => {
    if (doc && doc.state === 'PENDING') {
      if (!doc.isPrefilledPdfEnabled) {
        previewAgreement();
      } else {
        fetchPreFilledPdfURL().then((result) => {
          if (result?.data?.previewFilledPdf?.url) {
            setPdfUrl(result.data.previewFilledPdf.url);
          }
        });
      }
    } else if (doc && doc.state === 'SIGNED') {
      renderAgreement();
    }
  }, [doc, doc.state, previewAgreement, renderAgreement, fetchPreFilledPdfURL]);

  const onSign = () => {
    setShowSignConfirmation(true);
  };
  const previewNotAvailable = t('client:documents.previewNotAvailable');
  const agreementNotAvailable = t('client:documents.agreementNotAvailable');

  const setSharedWithCustodian = async () => {
    await updateFileDocument({
      variables: {
        input: {
          fileDocumentId: doc.id,
          sharedCustodian: true,
        },
      },
    });
    setMenuAnchorEl(null);
  };
  const setSharedWithClient = async (yesOrNo: boolean) => {
    await updateFileDocument({
      variables: {
        input: {
          fileDocumentId: doc.id,
          sharedClient: yesOrNo,
        },
      },
    });
    setMenuAnchorEl(null);
  };

  const documentIsImage = doc?.mediaType?.startsWith('image/');

  const LittleButton = styled(Button)({
    minWidth: 0,
    margin: '0.25em',
  });

  return (
    <Dialog onClose={onModalClose} open={true} fullWidth maxWidth='lg'>
      <DialogTitle
        sx={{
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!doc.isFileDocument && (
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography component='span' sx={styles.titleStyle}>
              {title}
            </Typography>
            <IconButton onClick={onModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {doc.isFileDocument && !showEditable && (
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography component='span' sx={styles.titleStyle}>
              {title}
              <EditIcon
                sx={styles.editIcon}
                onClick={() => {
                  setShowEditable(true);
                  setNewTitle(title);
                }}
              />
            </Typography>
            <IconButton onClick={onModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {showEditable && (
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                autoFocus
                style={styles.title}
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') doNameChange();
                  if (event.key === 'Escape') cancelNameChange();
                  event.stopPropagation();
                }}
                disabled={updateInProgress}
              />
            </Grid>
            <Grid item xs={3} sx={styles.checkIconGrid}>
              <LittleButton variant='outlined' size='small' onClick={doNameChange}>
                <CheckIcon />
              </LittleButton>
              <LittleButton variant='outlined' size='small' color='error' onClick={cancelNameChange}>
                <CloseIcon />
              </LittleButton>
            </Grid>
          </Grid>
        )}
        {doc && doc.isFileDocument && (
          <>
            <IconButton onClick={handleClick} size='small' sx={{ fontSize: '1.2rem' }}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={handleClose}
              sx={{ top: '-15px' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {doc.sharedCustodian === false && (
                <MenuItem onClick={setSharedWithCustodian} disabled={updateInProgress}>
                  {t('client:documents.sendToCustodian')}
                </MenuItem>
              )}
              {doc.sharedClient ? (
                <MenuItem onClick={() => setSharedWithClient(false)} disabled={updateInProgress}>
                  {t('client:documents.preventClientToView')}
                </MenuItem>
              ) : (
                <MenuItem onClick={() => setSharedWithClient(true)} disabled={updateInProgress}>
                  {t('client:documents.allowClientToView')}
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sx={{ display: 'flex' }}>
            <Typography>{t('client:documents.status')}:&nbsp;</Typography>
            <Chip label={t(`client:documents.${doc.state}`)} color={generateColor(doc.state)} />
          </Grid>
          <Grid item xs={6}>
            {(doc.updatedAt || doc.uploadedAt) && (
              <Typography>
                {t('client:documents.dateUpdated')}:&nbsp;
                <DateTime date={doc.updatedAt || doc.uploadedAt} component='span' />
              </Typography>
            )}
            {doc.signedAt && (
              <Typography>
                {t('client:documents.dateSigned')}:&nbsp;
                <DateTime date={doc.signedAt} component='span' />
              </Typography>
            )}
          </Grid>
        </Grid>

        {doc.isFileDocument && (
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <Typography>
                {t('client:documents.allowClientToView')}:&nbsp;
                {doc.sharedClient ? t('yes') : t('no')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t('client:documents.sendToCustodian')}:&nbsp;
                {doc.sharedCustodian ? t('yes') : t('no')}
              </Typography>
            </Grid>
          </Grid>
        )}

        {doc.isFileDocument && <Box sx={{ marginTop: '0.5em' }}>{documentIsImage ? <InlineImage fileDocumentId={doc.id} /> : <FileDownloaderCard fileDocument={doc} />}</Box>}

        {!doc.isFileDocument && (
          <>
            {showBothTabHeaders(doc, previewData, agreementData) && (
              <Box sx={{ marginTop: '32px' }}>
                <Tabs value={tabNumber} onChange={handleTabChange} aria-label='HTML and PDF VIEW'>
                  <Tab label='HTML' value='1' sx={{ ...styles.borderTitle, fontSize: '14px' }} />
                  {doc.signedUrl && !['PAD_AGREEMENT', 'GOAL_INVESTMENT_POLICY_STATEMENT'].includes(doc.type) && <Tab label='PDF' value='2' sx={{ ...styles.borderTitle, fontSize: '14px' }} />}
                </Tabs>
              </Box>
            )}

            {doc && doc.state === 'PENDING' && previewData && !doc.isPrefilledPdfEnabled && (
              <TabPanel value={tabNumber} index='1' sx={styles.tabPanel}>
                {previewData && (
                  <Typography>
                    <div
                      className='content'
                      dangerouslySetInnerHTML={{
                        __html: previewData?.previewFormAgreement?.html === '' ? previewNotAvailable : previewData?.previewFormAgreement?.html,
                      }}
                    ></div>
                  </Typography>
                )}
              </TabPanel>
            )}

            {doc && doc.state === 'PENDING' && doc.isPrefilledPdfEnabled && (
              <TabPanel value={tabNumber} index='1' sx={styles.tabPanel}>
                <fieldset style={styles.fieldset}>
                  <legend>
                    {' '}
                    <Typography sx={styles.borderTitle} variant='body2'>
                      {t('client:documents.pdfSection')}
                    </Typography>
                  </legend>
                  {pdfUrl && <PdfViewer pdfUrl={pdfUrl} />}
                  {!pdfUrl && pdfLoading && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress sx={{ margin: 5 }} />
                    </Box>
                  )}
                </fieldset>
              </TabPanel>
            )}

            {doc && doc.state === 'SIGNED' && agreementData?.renderFormAgreement?.html && (
              <TabPanel value={tabNumber} index='1' sx={styles.tabPanel}>
                {agreementData && (
                  <Typography>
                    <div
                      className='content'
                      dangerouslySetInnerHTML={{
                        __html: agreementData?.renderFormAgreement?.html === '' ? agreementNotAvailable : agreementData?.renderFormAgreement?.html,
                      }}
                    ></div>
                  </Typography>
                )}
              </TabPanel>
            )}

            {doc && doc.state === 'SIGNED' && !agreementData?.renderFormAgreement?.html && doc.signedUrl && (
              <TabPanel value={tabNumber} index='1' sx={styles.tabPanel}>
                <FileDownloaderCard
                  fileDocument={doc}
                  title={title}
                  showRegenerate={permissions.includes('write:re_generate_pdf')}
                  onRegenerate={() => {
                    regeneratePdf();
                    showToast({ severity: 'info', message: t('client:documents.regeneratedToastMessage') });
                    setTabNumber('1');
                  }}
                />
              </TabPanel>
            )}

            {doc.signedUrl && (
              <TabPanel value={tabNumber} index='2' sx={styles.tabPanel}>
                {/* PDF files aren't easy to render inline, offer a download instead */}
                <FileDownloaderCard
                  fileDocument={doc}
                  title={title}
                  showRegenerate={permissions.includes('write:re_generate_pdf')}
                  onRegenerate={() => {
                    regeneratePdf().then();
                    showToast({ severity: 'info', message: t('client:documents.regeneratedToastMessage') });
                    setTabNumber('1');
                  }}
                />
              </TabPanel>
            )}
          </>
        )}

        {doc && doc.state === 'PENDING' && (
          <Grid container justifyContent='right' paddingTop={5}>
            {tabNumber === '1' /* HTML */ && (
              <>
                {previewData?.previewFormAgreement?.html && tabNumber === '1' && (
                  <Grid item>
                    <Button onClick={() => downloadHtmlAsPdf(previewData?.previewFormAgreement?.html, title)}>{t('client:documents.downloadHtmlAsPdf')}</Button>
                  </Grid>
                )}
                {doc.templateUrl && (
                  <Grid item sx={{ marginRight: '16px' }}>
                    <Button
                      sx={styles.dialogButton}
                      type='button'
                      variant='outlined'
                      onClick={(e: any) => {
                        e.stopPropagation();
                        downloadPreFilledPDF();
                      }}
                      disabled={signMutationRunning}
                    >
                      {t('client:documents.downloadTemplate')}
                    </Button>
                  </Grid>
                )}
                <Grid item sx={{ marginRight: '16px' }}>
                  <Button sx={styles.dialogButton} type='button' variant='outlined' onClick={() => setShowUploadDocument(true)} disabled={signMutationRunning}>
                    {t('client:documents.uploadDocument')}
                  </Button>
                </Grid>
                {(previewData?.previewFormAgreement?.html || (doc.isPrefilledPdfEnabled && doc.digitalSignatureEnabled && pdfUrl)) && (
                  <Grid item>
                    <Button sx={styles.dialogButton} type='button' variant='contained' onClick={() => onSign()} disabled={previewLoading || signMutationRunning}>
                      {signMutationRunning ? <CircularProgress size={25} /> : t('client:documents.signDigitally')}
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {tabNumber === '2' /* PDF */ && (
              <>
                <Grid item sx={{ marginLeft: '16px' }}>
                  <Button sx={styles.dialogButton} type='button' variant='contained'>
                    {t('client:documents.uploadSignedDocument')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {doc && doc.state === 'SAVED' && userContext?.id === doc.creatorId && (
          <>
            <Grid container justifyContent='right' paddingTop={5}>
              {documentIsImage && (
                <Grid item>
                  <FileDownloaderButton fileDocument={doc} />
                </Grid>
              )}
              <Grid item sx={{ marginLeft: '16px' }}>
                <Button sx={styles.dialogButton} type='button' variant='contained' color='error' onClick={() => setShowDeleteConfirmation(true)}>
                  {t('client:documents.deleteDocument')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {agreementData?.renderFormAgreement?.html && tabNumber === '1' && (
          <Grid container justifyContent='right' paddingTop={5}>
            <Grid item>
              <Button onClick={() => downloadHtmlAsPdf(agreementData?.renderFormAgreement?.html, title)} variant='contained'>
                {t('client:documents.downloadHtmlAsPdf')}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <ConfirmationModal
        dialogStyles={styles.confirmation}
        open={showsignConfirmation}
        onCancel={() => setShowSignConfirmation(false)}
        title={t('client:documents.signConfimationTitile')}
        bodyText={t('client:documents.signConfirmationContent')}
        onConfirm={async () => {
          const responsibleUserId = await fetchAuthorizedIndividual({
            userId,
            apolloClient: graphqlClient,
            userContext,
          });
          const signFormAgreementsInput = {
            formAgreements: [
              {
                type: doc.type || '',
                version: doc?.minVersion ?? 1,
              },
            ],
            language: 'EN',
            signedByUserId: responsibleUserId,
            ...filter,
          };
          setShowSignConfirmation(false);
          signFormAgreement({ variables: { input: signFormAgreementsInput } });
        }}
      />
      <ConfirmationModal
        dialogStyles={styles.confirmation}
        open={showDeleteConfirmation}
        onCancel={() => setShowDeleteConfirmation(false)}
        title={t('client:documents.deleteConfimationTitile')}
        bodyText={t('client:documents.deleteConfirmationContent')}
        cancelButton={t('client:documents.cancelDelete')}
        confirmButton={t('client:documents.proceedDelete')}
        onConfirm={() => {
          deleteFileDocument();
          setShowDeleteConfirmation(false);
          afterSigned();
        }}
      />
      {showUploadDocument && (
        <DocumentUpload
          forUserId={userId}
          handleClose={() => {
            setShowUploadDocument(false);
            onModalClose();
          }}
          fixedObjectType={doc.relatedObjectType === 'CLIENT_GROUP' ? 'CLIENTGROUP' : doc.relatedObjectType}
          fixedObjectId={doc.relatedObjectId}
          docType={doc.type}
        />
      )}
    </Dialog>
  );
};

export default DocumentViewerModal;
