import { useTranslation } from 'react-i18next';
import {
  MenuItem,
  SelectField,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';

export const FilterHouseholds = ({
  state, setState,
}: {
  state: any, setState: (e: string) => void,
}) => {
  const { t } = useTranslation(['household', 'shared']);

  return (
    <FilterModal filterExists={state === 'ARCHIVED'}>
      <SelectField
        value={state}
        fullWidth
        label={t('household:householdStateLabel')}
        onChange={(e: any) => setState(e.target.value)}
      >
        <MenuItem key="ACTIVE" value="ACTIVE">{t('household:householdState.ACTIVE')}</MenuItem>
        <MenuItem key="ARCHIVED" value="ARCHIVED">{t('household:householdState.ARCHIVED')}</MenuItem>
      </SelectField>
    </FilterModal>
  );
};
