import {
  Dialog,
} from '../..';

export const FullScreenDialog = (props: any) => (
  <Dialog fullScreen={!props.isInnerWorkflow} open={props.open} onClick={props.onClose} {...props} sx={{
    margin: { xs: 0, md: '40px' }, height: { xs: '100%', md: 'calc( 100% - 80px )' },
  }}>
    {props.children}
  </Dialog>
);

export default FullScreenDialog;
