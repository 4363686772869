/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/material';
import {
  Box,
  Typography,
} from '../../1-primative';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../2-component';
import { AssetProgressBar } from '../../3-pattern/assetProgressBar/assetProgressBar';
import { translateBackend } from '../../../assets/i18n/config';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useSupportColors } from '../widgets/holdings/holdings.resources';

interface HoldingsItemProps {
  width?: number,
  primaryHoldingIndex: number,
  primaryAssetClassHoldings: any,
  onClick?: (secondaryAsset: any) => void,
  allPrimaryAssetClassHoldings: any[],
  testId?: string,
  sx?: SxProps,
}

export const HoldingItem = ({ primaryHoldingIndex, ...props }: HoldingsItemProps): JSX.Element => {
  const { sys } = useThemeTokens();
  const supportColors = useSupportColors();

  return (
    <Accordion
      data-testid={props.testId}
      variant='elevation'
      multiple
      disableGutters
      fullWidthOnMobile
      sx={{
        boxShadow: 'none !important',
        backgroundColor: sys.color.surface,
        '&:before': {
          backgroundColor: sys.color.surface,
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          marginTop: '35px',
          marginRight: '15px',
        },
        '.MuiAccordionSummary-root': {
          padding: '0px',
        },
        '.MuiAccordionDetails-root': {
          padding: '8px 0px 0px',
        },
        '.MuiCollapse-root': {
          backgroundColor: sys.color.surface,
        },
        ...props.sx,
      }}
    >
      <AccordionSummary
        data-testid={`${props.testId}-summary`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ width: '100%', paddingLeft: '15px' }}>
          <Box display="flex">
            <Typography variant='titleMedium'>{translateBackend(props.primaryAssetClassHoldings.assetClass.translatedName ?? '')}</Typography>
          </Box>
          <Box sx={{ width: '100%' }} mt={2} display="flex" alignItems="center" justifyContent="space-between">
            <AssetProgressBar
              sx={{ width: '100%' }}
              data={props.allPrimaryAssetClassHoldings.map((item, index) => ({
                key: `section-${item.assetClass.key}-${index}`,
                percentage: item?.percentage ?? 0,
                position: 1,
                colorCode: (item.assetClass.key === props.primaryAssetClassHoldings.assetClass.key
                  ? supportColors[primaryHoldingIndex]?.active : supportColors[index]?.inactive) || sys.color.primary!,
              }))}
            />
            <Typography sx={{ marginLeft: '10px' }}>{`${Math.round(props.primaryAssetClassHoldings.percentage)}%`}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '100%' }}>
        <Box mb={2} sx={{ paddingX: '15px' }}>
          <Typography variant='bodySmall'>{translateBackend(props.primaryAssetClassHoldings.assetClass.translatedDescription ?? '')}</Typography>
        </Box>
        <Box sx={{ paddingX: '15px' }}>
          {
            (props?.primaryAssetClassHoldings.secondaryAssetClassHoldings ?? []).map((item: any, i: number) => (
              <Box
                key={`${item.assetClass.key}-${i}`}
                onClick={() => props.onClick && props?.onClick(item)}
                display="flex"
                py={2}
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '100%', cursor: 'pointer' }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box minHeight={16} minWidth={16} bgcolor={supportColors[primaryHoldingIndex]?.active || sys.color.primary!} borderRadius={1} />
                  <Typography textAlign="left" ml={1.8} mb={0} weight='bold'>{translateBackend(item.assetClass.translatedName)}</Typography>
                </Box>
                <Typography paddingLeft={1}>{`${Math.round(item?.percentage ?? 0)}%`}</Typography>
              </Box>
            ))
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
