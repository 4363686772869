import { useState } from 'react';
import { ObjectType, RuleDocument, RuleGroupDocument } from '@onevesthq/ov-conditions';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import { Box } from '../../../1-primative';
import {
  Button,
  Card, CardContent,
} from '../../../2-component';
import { RuleBuilder } from '../../../3-pattern/ruleBuilder/ruleBuilder';
import { ClientReportTemplate } from '../../../../interfaces';
import { useGlobalToast } from '../../../../providers/globalToastProvider';

export const UPDATE_CLIENT_REPORT_TEMPLATE = gql`
  mutation updateClientReportTemplate($id: ObjectID!, $input: UpdateClientReportTemplateInput!) {
    updateClientReportTemplate(clientReportTemplateId: $id, input: $input) {
      clientReportTemplate {
        id
      }
    }
  }
`;

export const mapRules = (rules: (RuleDocument | RuleGroupDocument)[]): any => rules.map((r: any) => {
  if ('field' in r && r.field) {
    return pick(['field', 'value', 'operator', 'objectType'], r);
  }
  if ('rules' in r) {
    return {
      combinator: r.combinator,
      rules: mapRules(r.rules),
    };
  }
  return r;
});

export const Rules = ({ template }: { template: ClientReportTemplate }) => {
  const { t } = useTranslation('shared');
  const { showToast } = useGlobalToast();
  const [ruleGroup, setRuleGroup] = useState(template.condition?.ruleGroup ?? {
    combinator: 'or',
    rules: [],
  });

  const [updateClientReportTemplate] = useMutation(UPDATE_CLIENT_REPORT_TEMPLATE, {
    variables: {
      id: template.id,
      input: {
        condition: {
          ruleGroup: {
            rules: mapRules(ruleGroup.rules),
            combinator: ruleGroup.combinator,
          },
        },
      },
    },
    onCompleted: () => {
      showToast({ message: t('successfullySaved'), severity: 'success' });
    },
  });

  return (
    <Card>
      <CardContent>
        <RuleBuilder ruleGroup={ruleGroup} setRuleGroup={setRuleGroup} objectType={ObjectType.USER} />
        <Box display='flex' justifyContent='end'>
          <Button label={t('shared:save')} onClick={updateClientReportTemplate} />
        </Box>
      </CardContent>
    </Card>
  );
};
