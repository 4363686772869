/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { pick } from 'lodash/fp';
import { Chip, SxProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { generateClientNameString } from 'util/index';
import { User } from 'interfaces';
import { UserContext } from 'providers/userContextProvider';
import { Autocomplete } from 'ovComponents/2-component/autocomplete/autocomplete';

const MAX_SEARCH_RESULTS = 12;
export type Item = Required<{ id: string, firstName: string, middleName: string, lastName: string, entityName: string, primaryEmail?: string, }>;
export type SearchResults = { items: Item[], count: number };
export interface SearchFn {
  (query: string): Promise<SearchResults>
}

export const SEARCH_CLIENT = gql`
  query searchUsers($query: String!, $organizationId: ObjectID!, $affiliateUsersOnly: Boolean) {
    searchUsers(input: {
      filter: { query: $query, organizationId: $organizationId, affiliateUsersOnly: $affiliateUsersOnly }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      users {
        id
        firstName middleName lastName entityName
        dateOfBirth
        primaryEmail
        affiliateOnly
        sinExists
        type
      }
    }
  }
`;

const matchingFn = (item: Item, value: string) => (
  [
    item.id,
    item.firstName,
    item.middleName,
    item.lastName,
    item.entityName,
    item.primaryEmail,
    // This is required for when serach query extends across multiple name fragments
    generateClientNameString(item),
  ].some((str) => str?.toLowerCase()?.includes(value.toLowerCase()))
);

export const customEntitySearchFnFactory = (entities: User[]): SearchFn => ((query: string) => (new Promise<SearchResults>(
  (resolve) => {
    const items = entities.map((x) => (pick(['id', 'firstName', 'middlename', 'lastName', 'entityName', 'primaryEmail'], x) as Item));
    const matches = items.filter((item) => matchingFn(item, query));
    resolve({
      items: matches,
      count: matches.length,
    });
  },
)));

export const ClientSelectField = ({
  user,
  setUser,
  label,
  disabled,
  fullWidth,
  isJointAccount,
  labelSx,
  width,
  error,
  errorText,
  customSearchFn,
  onBlur,
  autoFocus,
}: {
  user: Partial<User> | undefined,
  setUser: (user: User | undefined) => void,
  label: string,
  disabled?: boolean,
  fullWidth?: boolean,
  isJointAccount?: boolean,
  labelSx?: SxProps,
  width?: string,
  error?: boolean,
  errorText?: string,
  customSearchFn?: SearchFn
  onBlur?: (e: React.ChangeEvent) => void,
  autoFocus?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const [searchResult, setSearchResult] = useState<SearchResults>({ items: [], count: 0 });
  const [searchUsers, { loading }] = useLazyQuery(SEARCH_CLIENT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const defaultSearchFn = (query: string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    if (disabled) return;
    searchUsers({
      variables: {
        query,
        organizationId: activeOrganization.id,
        affiliateUsersOnly: isJointAccount ? false : undefined,
      },
      onCompleted: (data) => {
        resolve({
          items: [...data.searchUsers.users, { firstName: '', lastName: '', id: '' }],
          count: Number(data.searchUsers.totalCount),
        });
      },
      onError: () => {
        resolve({
          items: [],
          count: 0,
        });
      },
    }).then();
  });

  const searchFn = customSearchFn ?? defaultSearchFn;

  // Initial query to prevent empty dropdown
  const initSearchResult = async () => {
    setSearchResult(await searchFn(''));
  };

  useEffect(() => {
    initSearchResult();
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && !disabled) {
      inputRef.current?.focus();
      initSearchResult();
    }
  }, [disabled, autoFocus]);

  return (
    <Autocomplete
      width={width}
      data-testid="userSelectField"
      freeSolo
      loading={loading}
      disabled={disabled}
      options={searchResult.items}
      fullWidth={fullWidth}
      label={label ?? ''}
      value={user}
      getOptionLabel={(option) => generateClientNameString(option)}
      isOptionEqualToValue={(option: Item, value: Item) => option.id === value.id}
      onInputChange={async (e: React.SyntheticEvent, value: string) => setSearchResult(await searchFn(value))}
      onChange={(e: React.SyntheticEvent, value: Item, reason: string) => {
        if (reason === 'clear') {
          setUser(undefined);
        } else if (value.id) {
          const result = searchResult.items.find((item) => item.id === value.id);
          setUser(result as User);
        }
      }}
      onBlur={onBlur}
      filterOptions={(options: Item[], state: any) => options.filter((item) => matchingFn(item, state?.inputValue))}
      renderTags={(tagValue: any, getTagProps: any) => tagValue.map((option: any, index: number) => (
        <Chip
          label={option}
          sx={{ marginTop: '-2px !important' }}
          {...getTagProps({ index })}
          size='small'
          onDelete={undefined}
        />
      ))
      }
      componentsProps={{ popupIndicator: { sx: { display: 'none' } } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        inputRef,
        disabled,
      }}
      labelSx={labelSx}
      error={error}
      errorText={errorText}
      autoFocus={autoFocus}
    />
  );
};
