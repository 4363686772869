import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { Box } from '../../../1-primative';
import { TranslatableTextField, TextField, MenuItem } from '../../../2-component';
import { FormModal } from '../../../3-pattern';
import { AssetClass, FinancialProductState } from '../../../../interfaces';

const UPDATE_ASSET_CLASS = gql`
mutation updateAssetClass($input: UpdateAssetClassInput!) {
  updateAssetClass(input: $input) {
    assetClass {
      id
    }
  }
}
`;

const TRANSITION_ASSET_CLASS = gql`
  mutation transitionAssetClass($input: TransitionAssetClassInput!) {
    transitionAssetClass(input: $input) {
      assetClass {
        id
      }
    }
  }
`;

const EditAssetClass = ({
  afterUpdate, assetClassToUpdate, open, handleClose,
}: { afterUpdate: () => void, assetClassToUpdate: AssetClass, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['configureModels', 'shared']);
  const { showToast } = useGlobalToast();
  const [assetClass, setAssetClass] = useState(assetClassToUpdate);
  const [initialState] = useState(assetClassToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const { permissions } = usePermissions();

  const [updateAssetClass, { loading }] = useMutation(UPDATE_ASSET_CLASS, {
    variables: {
      input: {
        assetClassId: assetClass?.id,
        key: assetClass?.key,
        translatedName: { en: assetClass?.translatedName?.en, fr: assetClass?.translatedName?.fr },
        translatedDescription: { en: assetClass?.translatedDescription?.en, fr: assetClass?.translatedDescription?.fr },
      },
    },
  });

  const [transitionAssetClass] = useMutation(TRANSITION_ASSET_CLASS);

  useEffect(() => {
    setLocalOpen(open);
    setAssetClass(assetClassToUpdate);
  }, [assetClassToUpdate, open]);

  const update = async (event: any) => {
    await updateAssetClass();
    afterUpdate();
  };

  if (!assetClass) {
    return <></>;
  }

  const transition = async (name: string) => {
    await transitionAssetClass({ variables: { input: { assetClassId: assetClass.id, transition: name } } });
    if (name === 'archive') showToast({ severity: 'info', message: t('assetClassModal.archiveToastMessage') });
    afterUpdate();
  };

  let menuItems: any[] | undefined = [];

  if (permissions.includes('transition:asset_classes')) {
    if (assetClass.state !== FinancialProductState.ARCHIVED) {
      menuItems.push(
        <MenuItem key='archive' onClick={async () => transition('archive')}>
          {t('assetClassModal.archive')}
        </MenuItem>,
      );
    }
  }

  if (!menuItems.length) {
    menuItems = undefined;
  }

  const disabled = !assetClass?.key || !assetClass?.translatedName?.en || !assetClass?.translatedDescription?.en;
  return (
    <FormModal
      loading={loading}
      disabled={disabled}
      title={t('assetClassModal.editTitle')}
      formButton={t('shared:update')}
      onSubmit={update}
      open={localOpen}
      handleClose={handleClose}
      menuItems={menuItems}
      initialState={initialState}
      state={assetClass}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label={t('assetClassModal.key')} fullWidth value={assetClass.key} onChange={(e: any) => setAssetClass({ ...assetClass, key: e.target.value })} />
        <TranslatableTextField
          fullWidth
          label={t('assetClassModal.name')}
          value={assetClass.translatedName || {}}
          onChange={(e) => setAssetClass({ ...assetClass, translatedName: e })}
        />
        <TranslatableTextField
          fullWidth
          label={t('assetClassModal.description')}
          value={assetClass.translatedDescription || {}}
          onChange={(e) => setAssetClass({ ...assetClass, translatedDescription: e })}
          rows={3}
        />
      </Box>
    </FormModal>
  );
};

export default EditAssetClass;
