import dayjs from 'dayjs';
import { pick, round } from 'lodash/fp';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery, useMutation } from '@apollo/client';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { Transaction } from '../../../../../interfaces/transaction';
import {
  FETCH_TRANSACTIONS, UPDATE_TRANSACTION, DELETE_TRANSACTION, FETCH_SUB_ACCOUNT, FETCH_FINANCIAL_PRODUCT,
} from './queries';
import {
  SecuritySelect, AmountField, NumberField,
} from '../../../../3-pattern';
import {
  Button, Dialog, DialogTitle, TextField, DialogContent, DateField,
} from '../../../../2-component';
import { Grid } from '../../../../1-primative';

const generateSubAccountName = (subAccount: any) => `${subAccount.account?.user?.firstName} - ${subAccount.goal?.name} - ${subAccount.account?.type}`;

const securityName = (financialProduct: any) => (financialProduct.ticker ? `${financialProduct.ticker} - ${financialProduct.name}` : financialProduct.name);

const EditSwitchEquitiesModal = ({
  open, title, outTransaction, inTransaction, handleClose,
}: {
  open: boolean, title: string, outTransaction?: Transaction, inTransaction?: Transaction,
  handleClose: () => void,
}) => {
  const today = dayjs().utc().format('YYYY-MM-DD');
  const { t } = useTranslation('subaccount');
  const { permissions } = usePermissions();

  const [updateOutTransactionInput, setUpdateOutTransactionInput] = useState<any>({
    date: outTransaction?.date ? dayjs(outTransaction?.date).format('YYYY-MM-DD') : undefined,
    valueCents: Math.abs(outTransaction?.valueCents ?? 0),
    description: outTransaction?.description,
    financialProductId: outTransaction?.financialProduct?.id,
    quantity: Math.abs(outTransaction?.quantity ?? 0),
    priceCents: outTransaction?.priceCents,
  });
  const [updateInTransactionInput, setUpdateInTransactionInput] = useState<any>({
    date: inTransaction?.date ? dayjs(inTransaction?.date).format('YYYY-MM-DD') : undefined,
    valueCents: Math.abs(inTransaction?.valueCents ?? 0),
    description: inTransaction?.description,
    financialProductId: inTransaction?.financialProduct?.id,
    quantity: Math.abs(inTransaction?.quantity ?? 0),
    priceCents: inTransaction?.priceCents,
  });

  const [outTransactionUpdated, setOutTransactionUpdated] = useState<boolean>(false);
  const [inTransactionUpdated, setInTransactionUpdated] = useState<boolean>(false);

  const outTransactionTotalCost = round(updateOutTransactionInput.priceCents * updateOutTransactionInput.quantity);
  const outTransactionMVPerUnit = round(updateOutTransactionInput.valueCents / updateOutTransactionInput.quantity);
  const validPriceCents = updateInTransactionInput.priceCents >= 1;
  const isAtMarket = title === t('subaccount:switchEquitiesModal:atMarket');
  const inTransactionTotalCost = isAtMarket ? round(updateInTransactionInput.priceCents * updateInTransactionInput.quantity) : outTransactionTotalCost;
  const validFinancialProduct = updateInTransactionInput.financialProductId !== updateOutTransactionInput.financialProductId;

  useEffect(() => {
    setUpdateOutTransactionInput({
      date: outTransaction?.date ? dayjs(outTransaction?.date).format('YYYY-MM-DD') : undefined,
      valueCents: Math.abs(outTransaction?.valueCents ?? 0),
      description: outTransaction?.description,
      financialProductId: outTransaction?.financialProduct?.id,
      quantity: Math.abs(outTransaction?.quantity ?? 0),
      priceCents: outTransaction?.priceCents,
    });

    setUpdateInTransactionInput({
      date: inTransaction?.date ? dayjs(inTransaction?.date).format('YYYY-MM-DD') : undefined,
      valueCents: Math.abs(inTransaction?.valueCents ?? 0),
      description: inTransaction?.description,
      financialProductId: inTransaction?.financialProduct?.id,
      quantity: Math.abs(inTransaction?.quantity ?? 0),
      priceCents: inTransaction?.priceCents,
    });
  }, [inTransaction, outTransaction]);

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION, {
    refetchQueries: [FETCH_TRANSACTIONS(permissions), FETCH_SUB_ACCOUNT(permissions)],
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
    refetchQueries: [FETCH_TRANSACTIONS(permissions), FETCH_SUB_ACCOUNT(permissions)],
  });

  const [fetchFinancialProduct] = useLazyQuery(FETCH_FINANCIAL_PRODUCT, {
    onCompleted: (data: any) => {
      if (data
        && data.fetchFinancialProduct
        && data.fetchFinancialProduct.financialProduct
      ) {
        if (isAtMarket) {
          const inTransactionQuantity = updateInTransactionInput.valueCents / data.fetchFinancialProduct.financialProduct.currentPriceCents;
          setUpdateInTransactionInput(() => ({
            ...updateInTransactionInput,
            priceCents: data.fetchFinancialProduct.financialProduct.currentPriceCents,
            quantity: inTransactionQuantity,
          }));
        } else {
          const inTransactionQuantity = outTransactionTotalCost / data.fetchFinancialProduct.financialProduct.currentPriceCents;
          setUpdateInTransactionInput(() => ({
            ...updateInTransactionInput,
            priceCents: data.fetchFinancialProduct.financialProduct.currentPriceCents,
            quantity: inTransactionQuantity,
          }));
        }
      }
    },
  });

  const setInTransactionValues = async (id: string) => {
    await fetchFinancialProduct({
      variables: {
        input: {
          financialProductId: id,
        },
      },
    });
  };

  const save = async () => {
    if (outTransactionUpdated) {
      await updateTransaction({
        variables: {
          input: {
            transactionId: outTransaction?.id,
            ...pick(['description', 'date'], updateOutTransactionInput),
          },
        },
      });
    }

    if (inTransactionUpdated) {
      await updateTransaction({
        variables: {
          input: {
            transactionId: inTransaction?.id,
            ...pick(['description', 'date', 'financialProductId', 'quantity', 'priceCents'], updateInTransactionInput),
          },
        },
      });
    }

    setUpdateOutTransactionInput({});
    setUpdateInTransactionInput({});
    setOutTransactionUpdated(false);
    setInTransactionUpdated(false);
    handleClose();
  };

  const cancel = async () => {
    if (outTransaction) {
      await deleteTransaction({
        variables: { transactionId: outTransaction.id },
      });
    }

    if (inTransaction) {
      await deleteTransaction({
        variables: { transactionId: inTransaction.id },
      });
    }

    setUpdateOutTransactionInput({});
    setUpdateInTransactionInput({});
    setOutTransactionUpdated(false);
    setInTransactionUpdated(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={() => cancel()}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <>
            {title}
            <div style={{ display: 'flex' }}>
              <CloseIcon onClick={() => cancel()} sx={{ cursor: 'pointer', padding: '5px' }} />
            </div>
          </>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={5}>
              <TextField
                label={t('subaccount:editSwitchEquitiesModal:subAccount')}
                fullWidth
                size='medium'
                value={(outTransaction && outTransaction.subAccount) ? generateSubAccountName(outTransaction?.subAccount) : ''}
                disabled
              />
              <TextField
                label={t('subaccount:editSwitchEquitiesModal:switchOutSecurity')}
                fullWidth
                size='medium'
                value={(outTransaction && outTransaction.financialProduct) ? securityName(outTransaction.financialProduct) : ''}
                disabled
              />
              <NumberField
                label={t('subaccount:editSwitchEquitiesModal:quantity')}
                decimalPlaces={4}
                number={outTransaction?.quantity?.toString() ?? ''}
                disabled
                setNumber={() => { }}
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:cost')}
                fullWidth
                amount={outTransaction?.priceCents ? round(outTransaction?.priceCents)?.toString() : ''}
                setAmount={() => { }}
                disabled
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:totalCost')}
                fullWidth
                amount={outTransactionTotalCost?.toString()}
                setAmount={() => { }}
                disabled
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:marketValuePerUnit')}
                fullWidth
                amount={outTransactionMVPerUnit?.toString()}
                setAmount={() => { }}
                disabled
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:marketValue')}
                fullWidth
                amount={outTransaction?.valueCents ? round(outTransaction?.valueCents)?.toString() : ''}
                setAmount={() => { }}
                disabled
              />
            </Grid>
            <Grid item>
              <DoubleArrowIcon />
            </Grid>
            <Grid item xs={5}>
              <DateField
                label={t('subaccount:editSwitchEquitiesModal:date')}
                minDate={today}
                value={dayjs(outTransaction?.date).format('YYYY-MM-DD') ?? null}
                disabled={true}
                onChange={() => {}}
                renderInput={(params: any) => <TextField {...params} />}
              />
              <SecuritySelect
                value={updateInTransactionInput.financialProductId ?? ''}
                label={t('subaccount:editSwitchEquitiesModal:switchInSecurity')}
                setSecurity={(chosenProduct) => {
                  setUpdateInTransactionInput((prev: any) => ({
                    ...prev,
                    financialProductId: chosenProduct.id,
                  }));
                  setInTransactionValues(chosenProduct.id);
                  setInTransactionUpdated(true);
                }}
                inputError={!validFinancialProduct}
                errorText={t('subaccount:switchEquitiesModal:sameProductToastMessage')}
              />
              <NumberField
                label={t('subaccount:editSwitchEquitiesModal:quantity')}
                decimalPlaces={4}
                number={updateInTransactionInput.quantity?.toString() ?? ''}
                disabled
                setNumber={() => { }}
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:cost')}
                fullWidth
                amount={updateInTransactionInput.priceCents ? round(updateInTransactionInput.priceCents)?.toString() : ''}
                disabled
                setAmount={() => { }}
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:totalCost')}
                fullWidth
                amount={inTransactionTotalCost?.toString()}
                disabled
                setAmount={() => { }}
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:marketValuePerUnit')}
                fullWidth
                amount={updateInTransactionInput.priceCents?.toString()}
                setAmount={(e: any) => {
                  const quantity = isAtMarket ? updateInTransactionInput.valueCents / e.target.valueCents : outTransactionTotalCost / e.target.valueCents;
                  setUpdateInTransactionInput((prev: any) => ({
                    ...prev,
                    priceCents: e,
                    quantity,
                  }));
                  setInTransactionUpdated(true);
                }}
                error={!validPriceCents}
              />
              <AmountField
                label={t('subaccount:editSwitchEquitiesModal:marketValue')}
                fullWidth
                amount={updateInTransactionInput.valueCents ? round(updateInTransactionInput.valueCents)?.toString() : ''}
                disabled
                setAmount={() => { }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={12}>
              <TextField
                label={t('subaccount:switchEquitiesModal:description')}
                value={updateOutTransactionInput.description}
                fullWidth
                onChange={(e: any) => {
                  setUpdateInTransactionInput((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                  setUpdateOutTransactionInput((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                  setOutTransactionUpdated(true);
                  setInTransactionUpdated(true);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction={'row'}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                sx={{ marginRight: 2 }}
                variant='outlined'
                onClick={() => cancel()}
                label={t('subaccount:editSwitchEquitiesModal:cancel')}
              />
              <Button
                onClick={() => save()}
                label={t('subaccount:editSwitchEquitiesModal:confirm')}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditSwitchEquitiesModal;
