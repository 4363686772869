import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import FormModal from 'components/modals/formModal';
import CloseGoalModal from 'components/modals/closeGoalModal';
import EditIcon from '@mui/icons-material/Edit';
import { RestoreFromTrash } from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useGlobalToast } from 'providers/globalToastProvider';
import { AssociateGoalToHouseholdModal } from '../../../components/modals/associateGoalToHouseholdModal';
import { DissociateGoalFromHouseholdModal } from '../../../components/modals/dissociateGoalFromHouseholdModal';
import BookMarkIcon from '../../../assets/images/icons-filled/bookmark.svg';
import DeleteForever from '../../../assets/images/icons-filled/delete-forever.svg';
import MetricsIcon from '../../../assets/images/icons-outline/metrics.svg';
import TrackChannelIcon from '../../../assets/images/icons-outline/track-channel.svg';
import { ClientGroup, Goal, GoalStates } from '../../../interfaces';
import { Highlight, Pill, Summary } from '../../../components/layout/client/summary';
import { formatMoneyValue } from '../../../util';

interface SummaryProps {
  goal: Goal;
  updateGoal: () => void;
  popNavigate: () => void;
  onHighlightClicked: (prop: any) => void;
  refetch?: () => void;
}

const REACTIVATE_GOAL = gql`
mutation reactivateGoal($input: ReactivateGoalInput!) {
  reactivateGoal(input: $input) {
    goal {
      id
      state
    }
  }
}
`;

export function GoalSummary({
  goal, updateGoal, popNavigate, onHighlightClicked, refetch,
}: SummaryProps) {
  const { t } = useTranslation(['accountsDetail', 'accountTypes', 'goalsDetails', 'client']);
  const { showToast } = useGlobalToast();
  const [closeGoal, setCloseGoal] = useState(false);
  const [closeGoalDisclaimer, setCloseGoalDisclaimer] = useState(false);
  const [associateToHouseholdModal, setAssociateToHouseholdModal] = useState<ClientGroup | null>(null);
  const [dissociateGoalModal, setDissociateGoalModal] = useState(false);
  const [reactivateGoalMutation] = useMutation(REACTIVATE_GOAL, {
    variables: {
      input: {
        goalId: goal?.id,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      showToast({ message: t('goalsDetails:toastMessage.restoreSuccess'), severity: 'success' });
    },
  });
  const closeGoalClicked = () => {
    const anyActiveSubAccount = goal.subAccounts?.some((x) => ['ACTIVE', 'AWAITING_APPROVAL'].includes(x.state ?? ''));
    const anyScheduledDeposit = (goal.statistics?.pendingContributionCents ?? 0) > 0;
    if (anyActiveSubAccount || anyScheduledDeposit) {
      setCloseGoalDisclaimer(true);
    } else {
      setCloseGoal(true);
    }
  };

  if (!goal) return <></>;

  const showPills: Pill[] = [
    {
      label: t('goalsDetails:highlights.suitabilityScore', { score: goal.suitabilityScore }),
      leadingIcon: 'checkmark',
    },
  ];
  if (goal.state === GoalStates.ACTIVE) {
    showPills.push({
      label: t('goalsDetails:activeGoal'),
      leadingIcon: 'checkmark',
    });
  }
  if (goal.state === GoalStates.COMPLETED) {
    showPills.push({
      label: t('goalsDetails:closedGoal'),
      leadingIcon: 'cancel',
    });
  }

  const highlights: Highlight[] = [
    {
      icon: <img src={BookMarkIcon} alt="goal-type-icon" />,
      label: t('goalsDetails:highlights.goalType', { goalType: t(`client:goalType.${goal.type}`) }),
      onClick: () => onHighlightClicked('goalType'),
    },
    {
      icon: <img src={TrackChannelIcon} alt="tine-horizon-icon" />,
      label: t('goalsDetails:highlights.timeHorizon', { timeHorizon: t(`goalsDetails:timeHorizonDescription.${goal.timeHorizon === 'VERY_LONG_TERM' ? 'LONG_TERM' : goal.timeHorizon}`) }),
      onClick: () => onHighlightClicked('goalTimeHorizon'),
    },
    {
      icon: <img src={TrackChannelIcon} alt="target-amount-icon" />,
      label: t('goalsDetails:highlights.targetAmount', { amount: formatMoneyValue(goal.targetAmountCents) }),
      onClick: () => onHighlightClicked('goalTargetAmount'),
    },
    {
      icon: <img src={MetricsIcon} alt="risk-level-icon" />,
      label: t('goalsDetails:highlights.riskPreference', { level: t(`goalsDetails:highlights.riskLevel.${goal.riskQuestion1}`) }),
      onClick: () => onHighlightClicked('goalRiskLevel'),
    },
  ];

  if (goal.organization?.allowPortfolioPerGoal || goal.financialProduct) {
    highlights.push({
      leadingLabel: t('goalsDetails:highlights.goalPortfolio'),
      label: goal.financialProduct ? (goal.financialProduct.translatedName?.en ?? '') : t('shared:no'),
      onClick: () => onHighlightClicked('goalPortfolio'),
    });
  }

  if (goal.financialProduct) {
    highlights.push({
      leadingLabel: t('goalsDetails:highlights.tradingProcess'),
      label: t(`client:tradingProcessItems.${goal.tradingProcess}`),
      onClick: () => onHighlightClicked('tradingProcess'),
    });
  }

  const financialSummary = (theGoal: Goal) => ({
    marketValue: theGoal.statistics?.marketValueCents || 0,
    returnAmount: theGoal.statistics?.simpleReturnAmount || 0,
    returnPercent: theGoal.statistics?.simpleReturnPercent || 0,
    targetAmmount: theGoal?.targetAmountCents || 0,
    circularProgressVisibility: true,
  });

  const menu = [
    {
      prefixIcon: <EditIcon sx={{ color: '#2B4763', mr: '10px', fontSize: '20px' }} />,
      label: t('goalsDetails:editGoal'),
      onClick: () => updateGoal(),
    },
  ];

  if (goal.householdClientGroup === null && goal.user.households) {
    for (const household of goal.user.households) {
      menu.push({
        prefixIcon: <SupervisedUserCircleIcon sx={{ color: '#2B4763', mr: '10px', fontSize: '20px' }}/>,
        label: t('goalsDetails:changeToHouseholdGoal', { name: goal.user.households.length > 1 ? household.name : '' }),
        onClick: () => setAssociateToHouseholdModal(household),
      });
    }
  }
  if (goal.householdClientGroup) {
    menu.push({
      prefixIcon: <SupervisedUserCircleIcon sx={{ color: '#2B4763', mr: '10px', fontSize: '20px' }}/>,
      label: t('goalsDetails:dissociateGoalFromHousehold'),
      onClick: () => setDissociateGoalModal(true),
    });
  }
  if (goal.state === 'ACTIVE') {
    menu.push({
      prefixIcon: <img src={DeleteForever} alt="delete-icon" style={{ color: '#2B4763', marginRight: '10px' }} />,
      label: t('goalsDetails:closeGoal'),
      onClick: () => closeGoalClicked(),
    });
  }

  if (goal.state === 'COMPLETED') {
    menu.unshift({
      prefixIcon: <RestoreFromTrash sx={{ color: '#2B4763', mr: '10px' }}/>,
      label: (t('goalsDetails:restoreGoal')),
      onClick: () => reactivateGoalMutation(),
    });
  }
  return (
    <>
      <Summary
        title={goal.name ? goal.name : t(`client:goalType.${goal.type}`)}
        householdLinks={goal.householdClientGroup ? [{ id: goal.householdClientGroup.id, name: goal.householdClientGroup.name }] : undefined}
        highlights={highlights}
        pills={showPills}
        contextShowId={goal.id}
        financialSummary={financialSummary(goal)}
        contextMenu={menu }
      />
      <FormModal
        title={t('goalsDetails:closeGoalConfirmationDialog.title')}
        subTitle={t('goalsDetails:closeGoalConfirmationDialog.subtitle')}
        open={closeGoalDisclaimer}
        maxWidth='sm'
        onSubmit={() => setCloseGoalDisclaimer(false)}
        handleClose={() => setCloseGoalDisclaimer(false)}
        loading={false}
        formButton={t('goalsDetails:closeGoalConfirmationDialog.continueButton')}
      >
      </FormModal>
      <CloseGoalModal
        open={closeGoal}
        goalId={goal.id}
        refetch={refetch}
        popNavigate={popNavigate}
        handleClose={() => setCloseGoal(false)}
      />
      {associateToHouseholdModal && (
        <AssociateGoalToHouseholdModal
          goalId={goal.id}
          household={associateToHouseholdModal}
          handleClose={() => setAssociateToHouseholdModal(null)}
        />
      )}
      {dissociateGoalModal && (
        <DissociateGoalFromHouseholdModal
          goalId={goal.id}
          householdName={goal.householdClientGroup?.name ?? ''}
          handleClose={() => setDissociateGoalModal(false)}
        />
      )}
    </>
  );
}
