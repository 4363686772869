import {
  Box, Card, CardContent, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Account, AffiliationTypes, EntityTypes } from '../../../interfaces';

const AccountHolderCard = ({ account }: { account: Account }) => {
  const { t } = useTranslation('affiliationTypes');
  const navigate = useNavigate();
  const secondaryAccountHolders = account.affiliations?.filter((x) => x.type === AffiliationTypes.Joint) ?? [];
  const isIndividualUser = account.user && (!account.user.type || account.user.type === EntityTypes.INDIVIDUAL);
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  return (
    <Box>
      <Card variant="outlined" sx={{ border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
        <Box display="flex" alignItems="center" sx={{
          width: '100%', mt: '20px', ml: '25px', mb: '10px',
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>{t('affiliationTypes:accountHolder')}</Typography>
        </Box>
        <CardContent>
          <Grid item container spacing={1} sx={{ mb: '10px' }} xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ paddingInline: '10px', color: '#667380' }}>{t('affiliationTypes:primaryHolder')}</Typography>
              <Box
                sx={{ p: '10px', '&:hover': { backgroundColor: '#f8f8f8', cursor: 'pointer' } }}
                onClick={() => navigate(`/${isIndividualUser ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${account.user.id}`)}>
                {isIndividualUser ? (
                  <Typography sx={{ fontWeight: 600, mt: '10px' }}>{account.user.firstName} {account.user.middleName} {account.user.lastName}</Typography>
                ) : (
                  account.user.type !== EntityTypes.INDIVIDUAL ? (
                    <Typography variant="subtitle2" noWrap>{account.user.entityName ?? ''}</Typography>
                  ) : (
                    <Typography sx={{ fontWeight: 600, mt: '10px' }}>{account.user.firstName} {account.user.middleName} {account.user.lastName}</Typography>
                  )
                )}
                <Typography variant="subtitle2" noWrap>{account.user.primaryEmail}</Typography>
              </Box>
            </Grid>
          </Grid>
          {secondaryAccountHolders.length > 0
            && <Grid item container spacing={1} xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ paddingInline: '10px', color: '#667380' }}>{t('affiliationTypes:secondaryHolder')}</Typography>
                {
                  secondaryAccountHolders.map(
                    (affiliate, index) => <Box key={index} sx={{ p: '10px', '&:hover': { backgroundColor: '#f8f8f8', cursor: 'pointer' } }} onClick={() => navigate(`/clients/${affiliate.user.id}`)}>
                      <Typography sx={{ fontWeight: 600 }}>{affiliate.user?.firstName} {affiliate.user?.middleName} {affiliate.user.lastName}</Typography>
                      <Typography variant="subtitle2" noWrap>{affiliate.user?.primaryEmail}</Typography>
                    </Box>,
                  )
                }
              </Grid>
            </Grid>
          }
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccountHolderCard;
