import { useState } from 'react';
import AddIcon from '@mui/icons-material/AddRounded';
import { RoundButton } from 'components/inputs/roundButton';
import { CreateSubAccountWizard, CreateSubAccountWizardProps } from './wizard';

export function CreateSubAccountWizardButton(props: CreateSubAccountWizardProps) {
  const [wizardShown, setWizardShown] = useState(false);
  return (
    <>
      <RoundButton variant='text' color='secondary' onClick={() => { setWizardShown(true); }}>
        <AddIcon/>
      </RoundButton>
      { wizardShown
        && <CreateSubAccountWizard {...props} handleClose={() => setWizardShown(false)}/>
      }
    </>
  );
}
