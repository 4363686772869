import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface NavSectionTokens {
  navSection: {
    color?: string;
    backgroundColor?: string;
    hoverColor?: string;
    selectedColor?: string;
    radius?: string;
  },
}

export const useNavSectionTokens = (): NavSectionTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: NavSectionTokens = {
    navSection: {
      color: sys.color.onSurface,
      backgroundColor: sys.color.surface,
      hoverColor: sys.color.primaryVariantHover,
      selectedColor: sys.color.primaryVariant,
      radius: '8px',
    },
  };

  return comp;
};
