import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateAccountWizardButton } from 'components/wizards/createAccountWizard/button';
import AccountsSummary from '../../../components/layout/accounts/AccountsSummary';
import AssetsOverview from '../../../components/layout/client/assetsOverview';
import CollapseHoldings from '../../../components/layout/client/collapseHoldings';
import GoalsSummary from '../../../components/layout/goals/goalsSummary';
import { ClientGroup, Relationship } from '../../../interfaces';
import { ObjectType } from '../../../providers/statsHooks';
import { usePermissions } from '../../../providers/userContextProvider';

export const HouseholdOverview = ({ clientGroup, onlyMemberId }: { clientGroup: ClientGroup, onlyMemberId?: string }) => {
  const { t } = useTranslation('household');
  const { permissions } = usePermissions();

  const showGoals = permissions.includes('read:goal_basic');
  const showAccounts = permissions.includes('read:account_basic');

  const memberNameById = (id:string) => clientGroup.relationships.find((rel:Relationship) => rel.user.id === id)?.user.firstName ?? '';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        { !onlyMemberId
          ? <AssetsOverview type={ObjectType.CLIENT_GROUP} id={clientGroup.id} title={t('overview.totalHouseholdAssets')} />
          // TODO member's assets
          : <AssetsOverview type={ObjectType.CLIENT_GROUP} id={clientGroup.id} onlyUserId={onlyMemberId}
              title={t('client:assetsOverview.totalSomeonesAssets', { name: memberNameById(onlyMemberId) })}
            />
        }
      </Grid>
      <Grid item xs={12}>
        <CollapseHoldings type={ObjectType.CLIENT_GROUP} id={clientGroup.id} />
      </Grid>

      { showGoals
        && <Grid item md={6} xs={12}>
          {!onlyMemberId
            ? <GoalsSummary objectType={ObjectType.CLIENT_GROUP} objectId={clientGroup.id} />
            : <GoalsSummary objectType={ObjectType.CLIENT_GROUP} objectId={clientGroup.id} onlyUserId={onlyMemberId} />
          }
        </Grid>
      }
      { showAccounts
        && <Grid item md={6} xs={12}>
          {!onlyMemberId
            ? <AccountsSummary objectType={ObjectType.CLIENT_GROUP} objectId={clientGroup.id} newAccountComponent={<CreateAccountWizardButton forObject='CLIENT_GROUP' forId={clientGroup.id}/>}/>
            : <AccountsSummary objectType={ObjectType.USER} objectId={onlyMemberId} newAccountComponent={<CreateAccountWizardButton forObject='CLIENT_GROUP' forId={clientGroup.id}/>}/>
          }
        </Grid>
      }
    </Grid>
  );
};
