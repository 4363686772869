import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { SxProps } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  EntityTypes,
  RelatedEntity,
  RelatedEntityTypes,
  User,
  UserStates,
} from 'interfaces';
import { generateClientNameString } from 'util/index';
import { RelatedEntityRemoveDialog } from 'pages/client/components/relatedEntityRemoveDialog';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Grid,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  CircularProgress,
  Dialog,
  Form,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'ovComponents/2-component';
import { RelatedEntitySelect } from 'ovComponents/3-pattern';
import { translateBackend } from 'assets/i18n/config';

const tableHeadSx: SxProps = {
  textTransform: 'uppercase',
  color: colors.neutral600,
};
const tableRowSx: SxProps = {
  border: 'none',
  py: 2,
};

export const RelatedEntitiesVisual = ({
  user,
  options,
  loading,
  onDraftSubmission,
  onContinue,
}: {
  user?: User,
  options: any,
  loading: boolean,
  onDraftSubmission?: (relatedEntity: RelatedEntity) => void,
  onContinue: () => void,
}) => {
  const { t } = useTranslation(['client', 'relatedEntityTypes', 'workflowCompletions']);
  const [draftRelatedEntity, setDraftRelatedEntity] = useState<Partial<RelatedEntity>>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deletionCandidate, setDeletionCandidate] = useState<null | RelatedEntity>(null);

  const relatedEntities: RelatedEntity[] = user?.relatedEntities ?? [];
  const isSubmissionDisabled = !(draftRelatedEntity?.entity && draftRelatedEntity?.relation);

  const handleStartAddRelation = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getHandleDeleteFn = (relatedEntity: RelatedEntity) => {
    const handlerFn = () => {
      setDeletionCandidate(relatedEntity);
      setDeleteConfirmationOpen(true);
    };
    return handlerFn;
  };

  const handleSubmit = () => {
    if (isSubmissionDisabled) return;
    onDraftSubmission?.(draftRelatedEntity as RelatedEntity);
    setIsModalOpen(false);
  };

  const AddRelationButton = () => (
    <Button
      variant='outlined'
      leadingIcon={AddRoundedIcon}
      label={t('workflowCompletions:relatedEntities.addRelation')}
      onClick={handleStartAddRelation}
      type='button'
    />
  );

  const LoadingAnimation = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );

  const NoRelatedEntities = () => (
    <Box display='flex' alignItems='center' flexDirection='column' gap={2}>
      <Icon icon={GroupsIcon} size='150px' sx={{ color: colors.neutral500 }} />
      <Typography variant='bodyLarge' sx={{ mt: -4 }}>{t('workflowCompletions:relatedEntities.noRelations')}</Typography>
      <AddRelationButton />
    </Box>
  );

  const RelatedEntitiesTable = () => (
    <Box display='flex' flexDirection='column' gap={2}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='labelSmall' sx={tableHeadSx}>{t('workflowCompletions:relatedEntities.summaryTable.name')}</Typography></TableCell>
            <TableCell><Typography variant='labelSmall' sx={tableHeadSx}>{t('workflowCompletions:relatedEntities.summaryTable.relation')}</Typography></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedEntities?.map((relatedEntity, idx) => (
            <TableRow
              key={idx}
              hover
            >
              <TableCell sx={tableRowSx}><Typography variant='bodyMedium'>{generateClientNameString(relatedEntity.entity)}</Typography></TableCell>
              <TableCell sx={tableRowSx}><Typography variant='bodyMedium'>{t(`relatedEntityTypes:${relatedEntity.relation}`)}</Typography></TableCell>
              <TableCell sx={tableRowSx} padding='checkbox'>
                <IconButton onClick={getHandleDeleteFn(relatedEntity)} aria-label="delete" size='small'><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box><AddRelationButton /></Box>
    </Box>
  );

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <Box display='flex' flexDirection='column' justifyContent='space-between' gap={3}>
        {loading
          ? <LoadingAnimation />
          : relatedEntities?.length === 0
            ? <NoRelatedEntities />
            : <RelatedEntitiesTable />
        }
        <Box display='flex' justifyContent='end'>
          <Button label={t('client:continue')} disabled={loading} onClick={onContinue} />
        </Box>
      </Box>

      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth
      >
        <Box display="flex" flexDirection="row" justifyContent="end">
          <Box sx={{ cursor: 'pointer' }} display="flex" onClick={handleModalClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Grid container gap={4}>
            <Grid item xs={12}>
              <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:relatedEntities.formTitle')}</Typography>
            </Grid>
            <Grid item xs={12}>
              {user
                && <RelatedEntitySelect
                  forUserType={user.type}
                  onEntityChange={(entity) => setDraftRelatedEntity({ ...draftRelatedEntity, entity })}
                  onRelationChange={(relation) => setDraftRelatedEntity({ ...draftRelatedEntity, relation })}
                />
              }
            </Grid>
            <Grid item xs={12} justifyContent='end' display='flex'>
              <Button
                type='submit'
                color='primary'
                disabled={isSubmissionDisabled}
                label={t('workflowCompletions:relatedEntities.addRelation')}
              />
            </Grid>
          </Grid>
        </Form>
      </Dialog>

      {/* Relation removal confirmation dialog */}
      {deleteConfirmationOpen && deletionCandidate && user && (
        <RelatedEntityRemoveDialog
          user={user}
          existingRelatedEntities={relatedEntities}
          entityToRemove={deletionCandidate.entity}
          onClose={() => setDeleteConfirmationOpen(false)}
        />
      )}
    </>
  );
};

const exampleUser: User = {
  id: 'user',
  subAccounts: [],
  goals: [],
  accounts: [],
  type: EntityTypes.INDIVIDUAL,
  organization: { id: 'org' },
  state: UserStates.ACTIVE,
  physicalAddress: '',
  firstName: 'Fred',
  lastName: 'Rogers',
};

export const relatedEntitesVisualPropsExample = {
  user: {
    ...exampleUser,
    relatedEntities: [
      { entity: { ...exampleUser, firstName: 'Joanne' }, relation: RelatedEntityTypes.Spouse },
      { entity: { ...exampleUser, type: EntityTypes.TRUST, entityName: 'Roger\'s Trust' }, relation: RelatedEntityTypes.Protector },
    ],
  },
  options: {},
  loading: false,
  onContinue: () => { },
};
