import { gql, useMutation } from '@apollo/client';
import { OrganizationUser } from 'interfaces';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modals/confirmationModal';

export const REVOKE_ORGANIZATION_USER_INVITATION = gql`
mutation revokeOrganizationUserInvitation($organizationUserId: ObjectID!) {
  revokeOrganizationUserInvitation(organizationUserId: $organizationUserId) {
    success
  }
}
`;

const RevokeOrganizationUserInvitation = ({
  open,
  organizationUser,
  onComplete,
  handleClose,
}: {
  open: boolean,
  organizationUser: OrganizationUser,
  onComplete: () => void,
  handleClose: () => void
}) => {
  const { t } = useTranslation(['orgSettings']);

  const [revokeOrganizationUserInvitation, { loading }] = useMutation(REVOKE_ORGANIZATION_USER_INVITATION, {
    variables: {
      organizationUserId: organizationUser?.id,
    },
  });

  const revokeUserInvitation = async () => {
    await revokeOrganizationUserInvitation();
    onComplete();
  };

  if (!organizationUser) {
    return <></>;
  }

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={revokeUserInvitation}
      open={open}
      loading={loading}
      title={t('userRevokeInvitationModal.title')}
      bodyText={t('userRevokeInvitationModal.body')}
    >
    </ConfirmationModal>
  );
};

export default RevokeOrganizationUserInvitation;
