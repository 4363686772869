import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const FETCH_SUBACCOUNT_NAME = gql`
  query fetchSubAccountName($id:ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount { financialProduct { name } theme { name }}
    }
  }
`;
const FETCH_PORTFOLIO_MODEL_NAME_AND_THEME = gql`
  query($id: ObjectID!) {
    fetchModelPortfolio(input:{modelPortfolioId: $id}) {
      modelPortfolio { name theme { name } }
    }
  }
`;

const SubAccountLabel = ({ financialProductId, subAccountId }: { financialProductId: string; subAccountId: string }) => {
  const { t } = useTranslation('client');

  const { data: dataSubAccount } = useQuery(FETCH_SUBACCOUNT_NAME, {
    variables: {
      id: subAccountId,
    },
  });
  const { data: dataPortfolio } = useQuery(FETCH_PORTFOLIO_MODEL_NAME_AND_THEME, {
    variables: {
      id: financialProductId,
    },
    skip: !financialProductId,
  });

  return <>
    {t('subAccount')}:
    &nbsp;
    {dataPortfolio ? dataPortfolio?.fetchModelPortfolio?.modelPortfolio?.name || dataPortfolio?.fetchModelPortfolio?.modelPortfolio?.theme.name
      : dataSubAccount?.fetchSubAccount?.subAccount?.name
      || dataSubAccount?.fetchSubAccount?.subAccount?.financialProduct?.name
      || dataSubAccount?.fetchSubAccount?.subAccount?.theme?.name
    }
  </>;
};

export default SubAccountLabel;
