export enum PageConfigurationTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  NON_INDIVIDUAL = 'NON_INDIVIDUAL',
  ACCOUNT = 'ACCOUNT',
  GOAL = 'GOAL',
  HOUSEHOLD = 'HOUSEHOLD',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
}

export enum OptionTypes {
  BOOLEAN = 'BOOLEAN',
  PLAIN_TEXT_FIELD = 'PLAIN_TEXT_FIELD',
  BASIC_FIELD = 'BASIC_FIELD',
  SELECT_FIELD = 'SELECT_FIELD',
  TRANSLATED_STRING = 'TRANSLATED_STRING',
  OPTIONAL_TRANSLATED_STRING = 'OPTIONAL_TRANSLATED_STRING',
  ARRAY = 'ARRAY',
  TABLE = 'TABLE',
  ATTACHMENT = 'ATTACHMENT',
  WORKFLOW_SELECT_FIELD = 'WORKFLOW_SELECT_FIELD',
  HTML_TEMPLATE = 'HTML_TEMPLATE',
  CHECKBOX = 'CHECKBOX',
}

export interface WidgetOptions {
  key?: string,
  label?: {
    en: string,
    fr?: string,
  },
  description?: {
    en: string,
    fr?: string,
  },
  info?: {
    en: string,
    fr?: string,
  },
  showIf?: any,
  showWith?: string,
  requiredIf?: any,
  optionType: OptionTypes,
  default: any,
  options?: any,
  removeFromPages?: PageConfigurationTypes[],
  showBasedOnCustodianConnection?: any[],
  showCustomField?: boolean,
  replaceCustomField?: boolean,
  customFieldFilter?: any,
  value?: any,
  mobileTableView?: WidgetOptions,
}
