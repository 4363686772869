import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { isUndefined, sum } from 'lodash/fp';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../../1-primative';
import {
  Card,
  CardContent,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { PieChart } from '../../../../2-component/pieChart/pieChart';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { SelectionChip } from '../../../../3-pattern';
import { currencySymbol } from '../../../../../util/currency';
import { UserContext } from '../../../../../providers/userContextProvider';

export const PieChartHoldings = ({
  holdings,
  loading,
  options,
}: {
  holdings: any[],
  loading: boolean,
  options: any,
}): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [groupBy, setGroupBy] = useState<'primary' | 'secondary' | 'tertiary'>('primary');
  const [activeItem, setActiveItem] = useState<any>(null);
  const { ref, sys } = useThemeTokens();
  const { t } = useTranslation(['client']);
  const { activeCurrency } = useContext(UserContext);

  useEffect(() => {
    if (groupBy === 'primary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (d[holding.financialProduct.primaryAssetClass.id]) {
          d[holding.financialProduct.primaryAssetClass.id].totalCents += holding.totalCents;
        } else {
          d[holding.financialProduct.primaryAssetClass.id] = {
            totalCents: holding.totalCents,
            translatedName: holding.financialProduct.primaryAssetClass.translatedName,
            currency: holding.currency,
            riskLevel: holding.financialProduct.primaryAssetClass.riskLevel || 100,
          };
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'secondary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (d[holding.financialProduct.secondaryAssetClass.id]) {
          d[holding.financialProduct.secondaryAssetClass.id].totalCents += holding.totalCents;
        } else {
          d[holding.financialProduct.secondaryAssetClass.id] = {
            totalCents: holding.totalCents,
            translatedName: holding.financialProduct.secondaryAssetClass.translatedName,
            currency: holding.currency,
            riskLevel: holding.financialProduct.secondaryAssetClass.riskLevel || 100,
          };
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'tertiary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (holding.financialProduct?.tertiaryAssetClass?.id) {
          if (d[holding.financialProduct.tertiaryAssetClass.id]) {
            d[holding.financialProduct.tertiaryAssetClass.id].totalCents += holding.totalCents;
          } else {
            d[holding.financialProduct.tertiaryAssetClass.id] = {
              totalCents: holding.totalCents,
              translatedName: holding.financialProduct.tertiaryAssetClass.translatedName,
              currency: holding.currency,
              riskLevel: holding.financialProduct.tertiaryAssetClass.riskLevel || 100,
            };
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (d.NONE) {
            d.NONE.totalCents += holding.totalCents;
          } else {
            d.NONE = {
              totalCents: holding.totalCents,
              translatedName: {
                en: t('none'),
                fr: t('none'),
              },
              currency: holding.currency,
            };
          }
        }
      });
      setData(Object.values(d));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdings, groupBy]);

  const colors = [
    ref.palette.supportOne50,
    ref.palette.supportTwo50,
    ref.palette.supportThree50,
    ref.palette.supportFour50,
    ref.palette.supportFive50,
    ref.palette.supportSix50,
  ];

  const primaryTitle = () => (options.primaryAssetClassTitle ? translateBackend(options.primaryAssetClassTitle) : t('primaryAssetClass'));
  const secondaryTitle = () => (options.secondaryAssetClassTitle ? translateBackend(options.secondaryAssetClassTitle) : t('secondaryAssetClass'));
  const tertiaryTitle = () => (options.tertiaryAssetClassTitle ? translateBackend(options.tertiaryAssetClassTitle) : t('tertiaryAssetClass'));

  const sortedData = () => (
    data.sort((a, b) => {
      if (options.sortAssetClassesBy === 'riskLevel' && groupBy === 'primary') {
        return (a.riskLevel - b.riskLevel);
      }
      return (b.totalCents - a.totalCents);
    })
  );

  return (
    <>
      {loading ? (
        <Skeleton width='100%' height='486px' variant='rectangular' sx={{ mt: 2 }}></Skeleton>
      ) : (
        <Card sx={{ mt: 2 }}>
          <CardContent sx={{ paddingBottom: '16px! important' }}>
            <Box sx={{ pt: 1, pl: 2 }}>
              <SelectionChip
                onChange={(e: any) => setGroupBy(e as 'primary' | 'secondary' | 'tertiary')}
                value={groupBy}
                options={[
                  { value: 'primary', label: primaryTitle(), dataTestId: 'holdings-table-selection-chip-primary' },
                  { value: 'secondary', label: secondaryTitle(), dataTestId: 'holdings-table-selection-chip-secondary' },
                  ...((holdings?.some((elem) => elem.financialProduct?.tertiaryAssetClass))
                    ? [{ value: 'tertiary', label: tertiaryTitle(), dataTestId: 'holdings-table-selection-chip-tertiary' }] : []),
                ]}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display='flex' position='relative' alignItems='center' justifyContent='center' pl={{ xs: 3, md: 10 }} pr={{ xs: 3, md: 10 }} pt={3} pb={3}>
                  <PieChart
                    datasets={[{
                      label: '',
                      data: sortedData().map((x: any) => x.totalCents),
                    }]}
                    labels={data.map((x: any) => translateBackend(x.translatedName))}
                    onHover={(index: number | undefined) => setActiveItem(!isUndefined(index) ? data[index] : null)}
                    customColors={sortedData().map((x: any, i: number) => colors[(x.riskLevel < 100 && options.sortAssetClassesBy === 'riskLevel' ? x.riskLevel : i) % 6])}
                  />
                  <Box textAlign='center' position='absolute' left='50%' top='50%' sx={{ transform: 'translate(-50%,-50%)' }} >
                    {
                      activeItem ? (
                        <>
                          <Typography sx={{ color: sys.color.onSurfaceVariant }} variant='bodyLarge'>{translateBackend(activeItem.translatedName)}</Typography>
                          <Typography variant='displayMedium'>{formatMoneyValue(activeItem?.totalCents, currencySymbol[activeItem?.currency as CurrencyCodes ?? CurrencyCodes.CAD])}</Typography>
                          <Typography weight='bold' variant='bodyLarge'>
                            {formatPercentValue((activeItem?.totalCents || 0) / sum((holdings || []).map((x: any) => x.totalCents)))} {t('ofTotal')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography sx={{ color: sys.color.onSurfaceVariant }} variant='bodyLarge'>
                            {groupBy === 'primary' ? primaryTitle() : groupBy === 'secondary' ? secondaryTitle() : tertiaryTitle()}
                          </Typography>
                          <Typography variant='displayMedium'>{formatMoneyValue(sum((holdings || []).map((x: any) => x.totalCents)), currencySymbol[activeCurrency as CurrencyCodes])}</Typography>
                          <Typography weight='bold' variant='bodyLarge'>100% {t('ofTotal')}</Typography>
                        </>
                      )
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' justifyContent='center' flexDirection='column' minHeight='100%'>
                  {sortedData().map((x: any, i: number) => (
                    <Box
                      key={x.translatedName?.en ? `${x.translatedName.en}-${i}` : i}
                      onMouseEnter={() => setActiveItem(x)}
                      onMouseLeave={() => setActiveItem(undefined)}
                      display='flex'
                      justifyContent='space-between'
                      sx={{
                        borderBottom: activeItem?.translatedName !== x.translatedName && i + 1 < data.length ? `2px solid ${sys.color.surfaceContainer}` : `2px solid ${sys.color.surface}`,
                        p: 1.5,
                        backgroundColor: activeItem?.translatedName === x.translatedName ? sys.color.surfaceContainer : 'none',
                        borderRadius: activeItem?.translatedName === x.translatedName ? sys.borderRadius.md : '0px',
                      }}
                    >
                      <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                        <Box sx={{
                          borderRadius: '10px', height: '8px', width: '8px', backgroundColor: colors[(x.riskLevel < 100 && options.sortAssetClassesBy === 'riskLevel' ? x.riskLevel : i) % 6], mr: 1,
                        }} />
                        <Typography variant='bodyLarge'>{translateBackend(x.translatedName)}</Typography>
                      </Box>
                      <Typography variant='bodyLarge' weight='bold'>{formatMoneyValue(x.totalCents, currencySymbol[x.currency as CurrencyCodes ?? CurrencyCodes.CAD])}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
