import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BulkUpsertRequest } from '../../../../interfaces/bulkUpsertRequest';
import { useLocalization } from '../../../../util/useLocalization';

export const FETCH_BULK_UPSERT_REQUEST = gql`
  query fetchBulkUpsertRequest($bulkUpsertRequestId: ObjectID!) {
    fetchBulkUpsertRequest(bulkUpsertRequestId: $bulkUpsertRequestId) {
      bulkUpsertRequest {
        id
        integration { id }
        state
        createdAt
        objectType
        queuedItemsCount
        failedItemsCount
        processedItemsCount
        organizationUser { id firstName lastName }
        apiToken { id name }
      }
    }
  }
`;

export const BulkUpsertItemsBreadcrumb = () => {
  const { data } = useQuery(FETCH_BULK_UPSERT_REQUEST, { variables: { bulkUpsertRequestId: useParams().bulkUpsertRequestId } });
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();
  const bulkUpsertRequest: BulkUpsertRequest = data?.fetchBulkUpsertRequest?.bulkUpsertRequest;

  return data ? `${t(`devSettings:integrationsModal.scopeOptions.DATA_INGESTION.${bulkUpsertRequest.objectType}`)} - ${localizedDateTime(bulkUpsertRequest.createdAt)}` : '';
};
