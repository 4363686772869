import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Chip, Grid,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Date } from '../../misc/date/date';
import { formatDecimalMoneyValue } from '../../../util';
import { usePermissions } from '../../../providers/userContextProvider';
import { NoPermissionAlert } from '../../misc/noPermissionAlert';
import { BulkTradeRunAllocation } from '../../../interfaces';

export const FETCH_BULK_TRADE_RUN_ALLOCATIONS = gql`
  query fetchBulkTradeRunAllocations($input: FetchBulkTradeRunAllocationsInput!) {
    fetchBulkTradeRunAllocations(input: $input) {
      bulkTradeRunAllocations {
        id
        externalId
        account
        settlementCurrency
        tradeCurrency
        side
        symbol
        market
        fillPrice
        shareQuantity
        tradeDate
        settlementDate
        tradeValue
        corporateOffsetAccount
        fXRate
      }
      totalCount
    }
  }
`;

interface BulkTradeRunAllocationsFilter {
  bulkTradeRunId: string;
  isPTF?: boolean;
}

const BulkTradeRunAllocationsTable = ({ filter }: { filter: BulkTradeRunAllocationsFilter }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [page, setPage] = useState(1);
  const pageSize = 15;

  const { loading, data, previousData } = useQuery(FETCH_BULK_TRADE_RUN_ALLOCATIONS, {
    skip: !permissions.includes('read:bulk_trade_run'),
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          ...filter,
        },
        pagination: {
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useEffect(
    () => function cleanupOnUnmount() {
      setPage(1);
    },
    [],
  );

  useEffect(() => setPage(1), [filter]);

  if (!permissions.includes('read:bulk_trade_run')) return <NoPermissionAlert missing='read:bulk_trade_run' />;

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label='table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.externalId')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.account')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.settlementCurrency')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.tradeCurrency')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.side')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.symbol')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.market')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.fillPrice')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.shareQuantity')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.tradeDate')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.settlementDate')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.tradeValue')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.corporateOffsetAccount')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='overline'>{t('bulkTradeRunAllocationsTable.fXRate')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || previousData)?.fetchBulkTradeRunAllocations?.bulkTradeRunAllocations?.map((trade: BulkTradeRunAllocation) => (
                    <TableRow hover key={trade.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}>
                      <TableCell scope='row'>{trade.externalId}</TableCell>
                      <TableCell scope='row'>{trade.account}</TableCell>
                      <TableCell scope='row'>{trade.settlementCurrency}</TableCell>
                      <TableCell scope='row'>{trade.tradeCurrency}</TableCell>
                      <TableCell>
                        <Chip size='small' label={trade.side} />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {trade.symbol}
                      </TableCell>
                      <TableCell scope='row'>{trade.market}</TableCell>
                      <TableCell scope='row'>{formatDecimalMoneyValue(trade.fillPrice, 6)}</TableCell>
                      <TableCell>{trade.shareQuantity}</TableCell>
                      <TableCell>
                        <Date date={trade.tradeDate} />
                      </TableCell>
                      <TableCell>
                        <Date date={trade.settlementDate} />
                      </TableCell>
                      <TableCell>{trade.tradeValue}</TableCell>
                      <TableCell>{trade.corporateOffsetAccount}</TableCell>
                      <TableCell>{trade.fXRate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchBulkTradeRunAllocations?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
        </>
      )}
    </Box>
  );
};

export default BulkTradeRunAllocationsTable;
