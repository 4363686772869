import {
  Box,
  Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CLOSE_SUB_ACCOUNT, TRANSITION_SUB_ACCOUNT } from 'pages/subAccountsDetail';
import { useGlobalToast } from 'providers/globalToastProvider';
import { FETCH_SUB_ACCOUNTS, FETCH_SUB_ACCOUNTS_LOW_RES } from './subAccounts';
import { SubAccount } from '../../interfaces';
import EditSubAccount from '../../pages/client/components/editSubAccount';
import { usePermissions } from '../../providers/userContextProvider';
import {
  formatPercentValue, formatMoneyValue, generateClientNameString, delay,
} from '../../util';
import ConfirmationModal from '../modals/confirmationModal';
import StatusChip from '../misc/statusChip';
import { ArrowTooltip } from '../layout/client/summary';

enum SubAccountStates {
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

interface SubAccountCardProps {
  /**
   * Sub-Account object to show
   */
  subaccount: SubAccount;
  /**
   * Of what object (Goal or Account) is this sub-account?
   */
  ofType: 'GOAL' | 'ACCOUNT';
  /**
   * Sum of all sibling sub-account's market value. The percentage shown
   * is computed against this.
   */
  totalMarketValue: number;
  hasClientGroup?: boolean;
  refetch?: any;
}

export const SubAccountCard = ({
  subaccount, ofType, totalMarketValue, hasClientGroup, refetch,
}: SubAccountCardProps) => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { showToast } = useGlobalToast();
  const [activeSubAccount, setActiveSubAccount] = useState<any>({});
  const { t } = useTranslation(['components', 'subaccount']);
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editOpen, setEditOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const [transitionSubAccount] = useMutation(TRANSITION_SUB_ACCOUNT, {
    refetchQueries: [FETCH_SUB_ACCOUNTS(permissions), FETCH_SUB_ACCOUNTS_LOW_RES(permissions)],
    awaitRefetchQueries: true,
  });
  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT, {
    onCompleted: async () => {
      await delay(250);
      refetch && refetch();
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };
  const state = (subAccount: any) => {
    if (subAccount.pendingWithdrawAll?.id) {
      return 'WITHDRAW_ALL';
    }

    return subAccount.state;
  };

  const transitions = [
    {
      name: 'activate',
      from: [SubAccountStates.AWAITING_APPROVAL],
    },
    {
      name: 'review',
      from: [SubAccountStates.ACTIVE],
    },
  ];

  const transitionSubAccountState = async (transitionName: string) => {
    await transitionSubAccount({
      variables: { input: { subAccountId: activeSubAccount.id, transition: transitionName } },
    });
  };

  const reactivate = async () => {
    await transitionSubAccountState('activate');
    showToast({ severity: 'success', message: t('subaccount:subAccountSuccessToast') });
  };

  const portfolioName = subaccount.goal?.financialProduct
    ? `${subaccount.goal?.financialProduct?.translatedName?.en}${t('components:inherited')}`
    : subaccount.financialProduct?.translatedName?.en;
  const suggestedPortfolioName = `${t('subaccount:recommended')} ${subaccount.suggestedFinancialProduct?.translatedName?.en}`;
  const financialProductId = subaccount.goal?.financialProduct?.id ?? subaccount.financialProduct?.id;
  const recommended: boolean = financialProductId === subaccount.suggestedFinancialProduct?.id;
  const percentage = totalMarketValue > 0 ? ((subaccount.statistics?.marketValueCents ?? 0) / totalMarketValue) : 0;
  const percentageValue = formatPercentValue(percentage);
  const goalName = subaccount.goal?.name ?? t(`client:goalType.${subaccount.goal.type}`);

  const styles = {
    boxStyle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      mr: '2px',
    },
    textStyle: {
      fontWeight: 600,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      m: '5px',
    },
  };

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        cursor: 'pointer', border: '2.25px solid #EAECEE', '&:hover': { backgroundColor: '#f8f8f8' },
      }}
    >
      <CardContent style={{ padding: '1px' }}>
        <Grid item alignItems="center" container spacing={1} style={{ padding: '15px' }} onClick={() => navigate(`subaccount/${subaccount.id}`)} xs={12}>
          {hasClientGroup && (<Grid item xs={2} sx={{ pr: '10px', pl: '10px' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <Typography sx={styles.textStyle}>{generateClientNameString(subaccount?.account?.user)}</Typography>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>)}
          <Grid item xs={hasClientGroup ? 2.5 : 3.0} sx={{ pr: '10px', pl: '10px' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <Typography sx={styles.textStyle}>{
                ofType === 'ACCOUNT' ? goalName
                  : t(`accountTypes:${subaccount.account.type}`)
              }</Typography>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
          <Grid item xs={hasClientGroup ? 2.5 : 3.5} sx={{ pr: '10px', pl: '10px' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <ArrowTooltip title={suggestedPortfolioName ?? ''}>
                <Typography sx={{ ...styles.textStyle, color: !recommended ? '#d50000' : null }}>{portfolioName}</Typography>
              </ArrowTooltip>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
          <Grid item xs={hasClientGroup ? 1.25 : 1.75} sx={{ pr: '10px', pl: '10px' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <Typography sx={styles.textStyle}>{percentageValue}</Typography>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
          <Grid item xs={1.25} style={{ display: 'flex', justifyContent: 'center' }} sx={{ pr: '10px', pl: '10px' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <Typography sx={styles.textStyle}>{formatMoneyValue(subaccount?.statistics?.marketValueCents)}</Typography>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
          <Grid item xs={2.5} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box display="flex" alignItems="center" sx={styles.boxStyle}>
              <StatusChip label={t(`components:subAccountState.${state(subaccount)}`)} color={state(subaccount) === 'ACTIVE' ? 'success' : 'pending'} />
              {permissions.includes('transition:account') && (
                <IconButton size='small'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveSubAccount(subaccount);
                    handleClick(e);
                  }}
                ><MoreVertIcon />
                </IconButton>
              )}
            </Box>

          </Grid>
        </Grid>
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant='caption' sx={{ p: 2 }}>{activeSubAccount.id}</Typography>
            {activeSubAccount?.state !== SubAccountStates.INACTIVE && (<MenuItem onClick={() => setEditOpen(true)}>{t('edit')}</MenuItem>)}
            {transitions.map((transition: any) => (
              transition.from.includes(activeSubAccount.state) && (
                <MenuItem key={transition.name} onClick={async (e) => {
                  await transitionSubAccountState(transition.name);
                }}>
                  {t(`subAccountTransitions.${transition.name}`)}
                </MenuItem>
              )
            ))}
            {activeSubAccount?.state !== SubAccountStates.INACTIVE && (<MenuItem onClick={() => setDeactivateConfirmationOpen(true)}>{t('subaccount:close')}</MenuItem>)}
            {activeSubAccount?.state === SubAccountStates.INACTIVE && (<MenuItem onClick={async () => { reactivate(); }}>{t('subaccount:reactivateSubAccount')}</MenuItem>)}
      </Menu>
      <EditSubAccount afterUpdate={() => {
        setEditOpen(false);
      }} subAccountToUpdate={activeSubAccount} open={editOpen} handleClose={() => setEditOpen(false)} />
      <ConfirmationModal
        open={deactivateConfirmationOpen}
        loading={false}
        title={t('subaccount:closeSubaccountModal.title')}
        bodyText={t('subaccount:closeSubaccountModal.body')}
        onConfirm={async () => {
          await closeSubAccount({
            variables: { input: { subAccountId: activeSubAccount.id, inactiveReason: 'OTHER' } },
          }).then();
          setDeactivateConfirmationOpen(false);
        }}
        onCancel={() => setDeactivateConfirmationOpen(false)}
        confirmButton={t('subaccount:closeSubaccountModal.close')}
        cancelButton={t('subaccount:closeSubaccountModal:cancel')}
      />
    </Card>
  );
};
