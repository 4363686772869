import { gql, useQuery } from '@apollo/client';
import {
  Box, CircularProgress,
} from '@mui/material';
import { pick } from 'lodash/fp';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../providers/userContextProvider';
import ReportBuilder from '../../4-module/reportBuilder/reportBuilder';

const FETCH_REPORT = gql`
  query fetchReport($input: FetchReportInput!) {
    fetchReport(input: $input) {
      report {
        name
        type
        columns
        filters {
          field
          comparison
          value
        }
        sortField
        sortDesc
      }
    }
  }
`;

export const ViewReport = () => {
  const { activeOrganization } = useContext(UserContext);
  const params = useParams();
  const reportId = params.id;

  const { loading, data } = useQuery(FETCH_REPORT, {
    variables: {
      input: {
        reportId,
        organizationId: activeOrganization.id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      {
        loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        ) : (
          <ReportBuilder
            initialColumns={data.fetchReport.report.columns}
            initialFilters={data.fetchReport.report.filters.map((x: any) => pick(['field', 'comparison', 'value'], x))}
            initialSortDesc={data.fetchReport.report.sortDesc}
            initialSortField={data.fetchReport.report.sortField}
            initialType={data.fetchReport.report.type}
            id={reportId}
            name={data.fetchReport.report.name}
          />
        )
      }
    </>

  );
};

export default ViewReport;
