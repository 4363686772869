import {
  Typography, Grid, IconButton, Menu, MenuItem, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Note } from '../../interfaces/note';
import { colors } from '../../theme/colors';

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
      }
    }
  }
`;

interface EditNoteTypesProps {
  note: Note,
  selectedType: string,
  setSelectedType: (s: string) => void,
  refetch: () => void,
  updateNoteType: boolean,
  notesStyles: any,
  setupdateNoteType: (b: boolean) => void,
  setSelectedNote: (n: any) => void,
  selectedNote: any
}

const EditNoteType = ({
  note, selectedType, setSelectedType, refetch, updateNoteType, notesStyles, setupdateNoteType, setSelectedNote, selectedNote,
}: EditNoteTypesProps) => {
  const { permissions } = usePermissions();
  const { userContext } = useContext(UserContext);
  const { t } = useTranslation(['client']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [updateNote] = useMutation(UPDATE_NOTE);

  const updateNoteTypeClick = (type: string) => {
    setSelectedType(type);
    handleClose();
    setupdateNoteType(true);
  };
  return (
    <Grid>
      {(note?.id === selectedNote?.id) ? (<Grid item sx={{ display: 'flex' }}>
        <Typography variant="notesFooter" sx={{ mt: 1 }}>{!updateNoteType ? t(`client:notes.types.${note.type}`) : t(`client:notes.types.${selectedType}`)} note </Typography>

        {(updateNoteType && permissions.includes('transition:notes'))
          ? (
            <IconButton
              data-testid="save-button"
              edge="end"
              size="small"
              onClick={async () => {
                await updateNote({
                  variables: {
                    input: {
                      noteId: note.id,
                      type: selectedType,
                    },
                  },
                });
                refetch();
                setupdateNoteType(false);
              }}
            >
              <Typography sx={notesStyles.saveText} variant="notesFooter"><u>{t('buildModels:edit.save')}</u></Typography>
            </IconButton>
          )
          : (
            <Tooltip title={(userContext?.id !== note.author.id) ? t('buildModels:newNote.updateWarning') : ''} placement="right-end">
              <IconButton
                edge="end"
                size="small"
                id="positioned-button"
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event1: any) => {
                  if (userContext?.id === note.author.id) {
                    setSelectedNote(note);
                    handleClick(event1);
                  }
                }}
              >
                <EditIcon sx={notesStyles.editIcon} />
              </IconButton>
            </Tooltip>
          )
        }
      </Grid>) : (
        <Grid item sx={{ display: 'flex' }}>
          <Typography variant="notesFooter" sx={{ mt: 1 }}>{t(`client:notes.types.${note.type}`)} note</Typography>
          {permissions.includes('transition:notes') && (
            <Tooltip title={(userContext?.id !== note.author.id) ? t('buildModels:newNote.updateWarning') : ''} placement="right-end">
              <IconButton
                edge="end"
                size="small"
                id="positioned-button"
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event1: any) => {
                  if (userContext?.id === note.author.id) {
                    setSelectedNote(note);
                    handleClick(event1);
                  }
                }}
              >
                <EditIcon sx={notesStyles.editIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem key='ORGANIZATIONAL' value='ORGANIZATIONAL' sx={{ color: selectedNote?.type === 'ORGANIZATIONAL' ? colors.selectedType : colors.black }} onClick={async () => {
          updateNoteTypeClick('ORGANIZATIONAL');
        }}><Typography variant="notesFooter">{t('client:notes.types.ORGANIZATIONAL')}</Typography></MenuItem>
        <MenuItem key='PUBLIC' value='PUBLIC' sx={{ color: selectedNote?.type === 'PUBLIC' ? colors.selectedType : colors.black }} onClick={async () => {
          updateNoteTypeClick('PUBLIC');
        }}><Typography variant="notesFooter">{t('client:notes.types.PUBLIC')}</Typography></MenuItem>
        <MenuItem key='PRIVATE' value='PRIVATE' sx={{ color: selectedNote?.type === 'PRIVATE' ? colors.selectedType : colors.black }} onClick={async () => {
          updateNoteTypeClick('PRIVATE');
        }}><Typography variant="notesFooter">{t('client:notes.types.PRIVATE')}</Typography></MenuItem>
      </Menu>
    </Grid>
  );
};

export default EditNoteType;
