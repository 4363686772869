import {
  Typography, Card, CardContent, Grid, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { usePermissions } from '../../../providers/userContextProvider';
import { FinancialProduct } from '../../../interfaces';
import TranslatableRichTextEditor from '../../../components/inputs/translatableRichTextEditor';

const UPDATE_MODEL = gql`
  mutation updateModelPortfolio($input: UpdateModelPortfolioInput!) {
    updateModelPortfolio(input: $input) {
      modelPortfolio {
        id
      }
    }
  }
`;

const EditDisclaimer = ({ model, setModel }: { model: FinancialProduct, setModel: (v: FinancialProduct) => void }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('buildModels');

  const canWrite = (): boolean => (permissions.includes('write:model_portfolios') || false);

  const [updateModel, { loading }] = useMutation(UPDATE_MODEL, {
    variables: {
      input: {
        modelPortfolioId: model.id,
        translatedPortfolioDisclaimer: model.translatedPortfolioDisclaimer?.en?.trim()
          ? { en: model.translatedPortfolioDisclaimer.en, fr: model.translatedPortfolioDisclaimer?.fr }
          : null,
      },
    },
  });
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} component='form' onSubmit={(e: any) => {
          e.preventDefault();
          updateModel();
        }} justifyContent='flex-end'>
          <Grid item xs={ 12 }><Typography variant='subtitle1'>{t('buildModels:disclaimer')}</Typography></Grid>
          <Grid item xs={ 12 }>
            <TranslatableRichTextEditor
              onChange={(value) => setModel({ ...model, translatedPortfolioDisclaimer: value })}
              value={model.translatedPortfolioDisclaimer}
              label={t('buildModels:edit.disclaimerMarkdownLabel')}
              readonly={!canWrite()}
              key={model.id}
            />
          </Grid>
          { canWrite() && (
            <Grid item>
              <Button type='submit' disabled={loading} fullWidth variant='contained'>
                { t('buildModels:edit.save') }
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EditDisclaimer;
