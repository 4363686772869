/* eslint-disable object-curly-newline */
import { Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash/fp';
import SubTradeRequestsTableRow from './subTradeRequestsTableRow';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { CircularProgress } from '../../../../ovComponents/2-component';

const SUB_TRADE_SPLITS = `
  splits {
    id
    financialProduct {
      ticker
      settlementDays
      exchange
      translatedName {
        en
      }
    }
    settlementDate
    state
    isSellAll
    moneyAllocatedCents
    type
    subAccount {
      account {
        type
        user {
          id
          firstName
        }
      }
    }
    pair {
      financialProduct {
        ticker
      }
    }
    bulkTradeRun {
      id
    }
    verifiedAt
    verifiedBy
  }
`;

export const FETCH_SUB_TRADE_REQUESTS_QUERY = (isWithSplits = true) => `
  query fetchSubTradeRequests($input: FetchSubTradeRequestsInput!) {
    fetchSubTradeRequests(input: $input) {
      subTradeRequests {
        id
        financialProduct {
          ticker
          settlementDays
          exchange
          translatedName {
            en
          }
        }
        settlementDate
        state
        isSellAll
        moneyAllocatedCents
        type
        subAccount {
          account {
            type
            custodianAccountNumber
            user {
              id
              firstName
            }
          }
        }
        preSplitMoneyAllocatedCents
        isProcessedForPair
        pair {
          id
          financialProduct {
            ticker
          }
        }
        bulkTradeRun {
          id
        }
        verifiedAt
        verifiedBy

        ${isWithSplits ? SUB_TRADE_SPLITS : ''}
      }
      totalCount
    }
  }
`;

export const FETCH_SUB_TRADES = (isWithSplits = true) => gql`${FETCH_SUB_TRADE_REQUESTS_QUERY(isWithSplits)}`;

export interface TradeToVerify {
  id: string;
  selected: boolean;
}

export const SubTradeRequestsTableHeader = ({
  nested,
  expandable,
  deletable,
  verifiable,
  isAllTradesToVerifySelected,
  onTradeVerifySelectAll,
}: {
  nested?: boolean;
  expandable?: boolean;
  deletable?: boolean;
  verifiable?: boolean;
  isAllTradesToVerifySelected?: boolean;
  onTradeVerifySelectAll?: (isChecked: boolean) => void;
}) => {
  const { t } = useTranslation(['components', 'shared']);

  return (
    <TableHead>
      <TableRow>
        {expandable && <TableCell />}

        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.account')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.type')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.amount')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.security')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.settlementDays')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.exchange')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.settlementDate')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.pairedWith')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='overline'>{t('components:generateTrades.table.state')}</Typography>
        </TableCell>

        {verifiable && !nested && (
          <TableCell>
            <Tooltip title={t('components:generateTrades.table.selectAll')} placement='top'>
              <Checkbox
                checked={isAllTradesToVerifySelected}
                onChange={(e) => {
                  if (onTradeVerifySelectAll) onTradeVerifySelectAll(e.target.checked);
                }}
              />
            </Tooltip>
          </TableCell>
        )}

        {verifiable && nested && <TableCell />}
        {deletable && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const SubTradeRequestsTable = ({
  id,
  isRefetch,
  sourceId,
  onTradesToVerifyUpdate,
  afterCancel,
}: {
  id: string;
  isRefetch?: boolean;
  sourceId?: string;
  onTradesToVerifyUpdate?: (trades: TradeToVerify[]) => void;
  afterCancel?: () => void;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const [expandable, setExpandable] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [verifiable, setVerifiable] = useState(false);

  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);
  const [initialTradesToVerifyState, setInitialTradesToVerifyState] = useState<TradeToVerify[]>([]);

  const { data, loading, refetch } = useQuery(FETCH_SUB_TRADES(), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          bulkTradeRequestId: id,
          ...(sourceId && { sourceId }),
        },
        pagination: {
          sortField: 'subAccount',
          perPage: 100,
        },
      },
    },
    skip: !id,
  });

  useEffect(() => {
    if (isRefetch) {
      refetch();
    }
  }, [isRefetch, refetch]);

  useEffect(() => {
    const { subTradeRequests } = data?.fetchSubTradeRequests ?? [];

    setExpandable(subTradeRequests?.some((trade: any) => !!trade?.splits?.length));
    setDeletable(
      activeOrganization?.allowViewSubTradeRequestPairs ? false : permissions.includes('transition:sub_trade_request') && subTradeRequests?.some((trade: any) => trade?.state === 'INITIATED'),
    );
    setVerifiable(permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false));

    const initialTradesToVerifyStateLocal = [];

    if (subTradeRequests?.length) {
      for (const subTradeRequest of subTradeRequests) {
        if (subTradeRequest?.splits?.length) {
          for (const split of subTradeRequest.splits) {
            initialTradesToVerifyStateLocal.push({ id: split?.id, selected: !!split?.verifiedAt && !!split?.verifiedBy });
          }
        }

        if (!subTradeRequest?.splits?.length) {
          initialTradesToVerifyStateLocal.push({ id: subTradeRequest?.id, selected: !!subTradeRequest?.verifiedAt && !!subTradeRequest?.verifiedBy });
        }
      }

      setTradesToVerify(cloneDeep(initialTradesToVerifyStateLocal));
      setInitialTradesToVerifyState(cloneDeep(initialTradesToVerifyStateLocal));
    }
  }, [data, permissions, activeOrganization]);

  const filterTradesToVerify = (items: TradeToVerify[]) => {
    const filteredTradesToVerify: TradeToVerify[] = [];

    items.forEach((item: TradeToVerify) => {
      const initialTradeToVerifyState = initialTradesToVerifyState.find((initTrade: TradeToVerify) => initTrade.id === item.id);

      if (initialTradeToVerifyState?.selected !== item?.selected) {
        filteredTradesToVerify.push(item);
      }
    });

    if (onTradesToVerifyUpdate) onTradesToVerifyUpdate(filteredTradesToVerify);
  };

  const onTradeVerify = (item: TradeToVerify) => {
    const index = tradesToVerify.findIndex((tradeToVerify: any) => item?.id === tradeToVerify?.id);
    tradesToVerify[index].selected = item?.selected;

    setTradesToVerify([...tradesToVerify]); // keeping the state of selected sub-trades
    filterTradesToVerify([...tradesToVerify]); // preparing an array of sub-trades whose state has changed
  };

  const selectAllTradesToVerify = (isChecked: boolean) => {
    const updatedTradesToVerify = tradesToVerify.map((trade: TradeToVerify) => ({ ...trade, selected: isChecked }));

    setTradesToVerify([...updatedTradesToVerify]);
    filterTradesToVerify([...updatedTradesToVerify]);
  };

  const isAllTradesToVerifySelected = tradesToVerify?.every((tradeToVerify: TradeToVerify) => tradeToVerify.selected) ?? false;

  if (loading) {
    return (
      <Grid container justifyContent='center' p={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <TableContainer>
      <Table sx={{ borderBottom: 'hidden' }}>
        <SubTradeRequestsTableHeader
          expandable={expandable}
          deletable={deletable}
          verifiable={verifiable}
          isAllTradesToVerifySelected={isAllTradesToVerifySelected}
          onTradeVerifySelectAll={(isChecked: boolean) => selectAllTradesToVerify(isChecked)}
        />

        <TableBody>
          {data?.fetchSubTradeRequests?.subTradeRequests?.map((trade: any) => (
            <SubTradeRequestsTableRow
              trade={trade}
              expandable={expandable}
              deletable={deletable}
              verifiable={verifiable}
              tradesToVerify={tradesToVerify}
              onTradeVerify={(item: TradeToVerify) => onTradeVerify(item)}
              afterCancel={() => {
                if (afterCancel) {
                  afterCancel();
                }
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubTradeRequestsTable;
