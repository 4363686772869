import {
  Card, CardContent, Grid, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AccountTypes, AffiliationTypes, User,
} from '../../../interfaces';

const AffiliatesCard = ({
  user,
  type,
  icon,
  isIndividual,
  mainAccountType,
  affiliateType,
  allocation,
}: {
  user: User,
  type: string,
  icon?: JSX.Element,
  isIndividual: boolean
  mainAccountType?: AccountTypes,
  affiliateType: AffiliationTypes,
  allocation?: number,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('affiliationTypes');

  const customFields: string = (mainAccountType && mainAccountType.includes('RESP') && affiliateType === AffiliationTypes.PrimaryBeneficiary)
    ? 'GENDER' : (affiliateType === AffiliationTypes.PrimaryBeneficiary)
      ? 'ALLOCATION' : '';

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        cursor: 'pointer', border: '2.25px solid #EAECEE', '&:hover': { backgroundColor: '#f8f8f8' }, boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)',
      }}
    >
      <CardContent style={{ padding: '1px' }}>
        <Grid item alignItems="center" container spacing={1}>
          <Grid item alignItems="center" container spacing={1} style={{ padding: '25px' }} onClick={() => navigate(`/clients/${user.id}`)} md={11} xs={12}>
            <Grid item md={5} xs={12}>
              <Typography variant="subtitle2">{t('affiliationTypes:name')}</Typography>
              <Typography sx={{ fontWeight: 600 }}>{user.firstName} {user.middleName} {user.lastName}</Typography>
            </Grid>
            {(!isIndividual) ? (<Grid item md={4} xs={12}>
              <Typography variant="subtitle2">{t('affiliationTypes:primaryEmail')}</Typography>
              <Typography sx={{ fontWeight: 600 }}>{user.primaryEmail ?? ''}</Typography>
            </Grid>) : (
              <>
                <Grid item md={5} xs={12}>
                  <Typography variant="subtitle2">{t('affiliationTypes:relationship')}</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{type}</Typography>
                </Grid>
                {(customFields === 'GENDER') ? (<Grid item md={2} xs={12}>
                  <Typography variant="subtitle2">{t('affiliationTypes:gender')}</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{t(`affiliationTypes:genderOptions.${user.gender}`) ?? ''}</Typography>
                </Grid>) : (customFields === 'ALLOCATION') ? (<Grid item md={2} xs={12}>
                  <Typography variant="subtitle2">{t('affiliationTypes:allocation')}</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{`${allocation} %` ?? 0}</Typography>
                </Grid>
                ) : (
                  <>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }} item md={1} xs={12}>
            {icon}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AffiliatesCard;
