import {
  Typography, Card, CardContent, Grid, TableHead, Table, TableCell, TableRow, TextField, InputAdornment, Button, IconButton, TableBody,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelRounded';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/AddRounded';
import { useEffect, useState } from 'react';
import { sum, groupBy, mapValues } from 'lodash/fp';
import { round } from 'lodash';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { graphColors } from '../../../theme/colors';
import SecuritySelect from '../../../components/inputs/securitySelect';
import { usePermissions } from '../../../providers/userContextProvider';
import { Branch, FinancialProduct } from '../../../interfaces';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import NumberField from '../../../components/inputs/numberField';
import CopyFromExcel from '../../client/components/pasteFromExcel';

Chart.register(ArcElement, Tooltip);

export const UPDATE_MODEL_PORTFOLIO_BRANCHES = gql`
mutation updateModelPortfolio($input: UpdateModelPortfolioInput!) {
  updateModelPortfolio(input: $input) {
    modelPortfolio {
      id
    }
  }
}
`;

export const EditAllocations = ({ model, setModel }: { model: FinancialProduct, setModel: (model: FinancialProduct) => void }) => {
  const { permissions } = usePermissions();
  const PERCENTAGE_PRECISION = 4;
  const { t } = useTranslation('buildModels');
  const { showToast } = useGlobalToast();
  const [openExcel, setOpenExcel] = useState(false);
  const [allocations, setAllocations] = useState<Branch[]>(model.children?.filter((x: Branch) => !x.financialProduct.isCash) || []);
  const [cashValue, setCashValue] = useState(round(100 - sum(allocations.map((x: Branch) => x.percentage)), 2));
  const cashProduct: FinancialProduct = model.children?.find((x: Branch) => x.financialProduct.isCash)?.financialProduct || {
    isCash: true,
    translatedName: { en: t('cash') },
  };

  const canWrite = (): boolean => (permissions.includes('write:model_portfolios') || false);

  const [updateModelPortfolio] = useMutation(UPDATE_MODEL_PORTFOLIO_BRANCHES);

  const updateModel = async () => {
    const resp = await updateModelPortfolio({
      variables: {
        input: {
          modelPortfolioId: model.id,
          children: allocations.map((a) => ({
            financialProductId: a.financialProduct.id,
            percentage: a.percentage,
          })),
        },
      },
    });

    if (resp?.data.updateModelPortfolio.modelPortfolio.id) {
      showToast({ severity: 'info', message: t('buildModels:editAllocation.updatedToastMessage') });
    }
  };

  const primaryAssetClasses = (): { [key: string]: number } => ({
    ...mapValues((v: Branch[]) => sum(v.map((m: Branch) => m.percentage)), groupBy(((x: Branch) => x.financialProduct.primaryAssetClass?.translatedName?.en || t('unknown')), allocations)),
    [cashProduct?.translatedName?.en ?? cashProduct.ticker ?? '']: cashValue,
  });

  const secondaryAssetClasses = () => ({
    ...mapValues((v: Branch[]) => sum(v.map((m: Branch) => m.percentage)), groupBy(((x: Branch) => x.financialProduct.secondaryAssetClass?.translatedName?.en || t('unknown')), allocations)),
    [cashProduct?.translatedName?.en ?? cashProduct.ticker ?? '']: cashValue,
  });

  const removeSecurity = (index: number) => {
    const a = [...allocations];
    a.splice(index, 1);
    const newCash = round(100 - sum(a.map((x: Branch) => x.percentage)), 2);
    setCashValue(newCash);
    const newBranch: Branch = { percentage: newCash, financialProduct: cashProduct };
    const children: Branch[] = [...a, newBranch];
    setModel({ ...model, children });
  };

  const addSecurity = () => {
    const a: Branch[] = [...allocations];
    const newBranch: Branch = {
      id: `new-${Math.random()}`,
      financialProduct: {
        translatedName: { en: '' },
        ticker: '',
        primaryAssetClass: { translatedName: { en: '' } },
        secondaryAssetClass: { translatedName: { en: '' } },
      },
      percentage: 0,
    };

    a.push(newBranch);
    setModel({ ...model, children: [...a, { percentage: cashValue, financialProduct: cashProduct }] });
  };

  const applyExcelCopiedSecurities = (copiedBranches: Branch[]) => {
    if (copiedBranches.length > 0) {
      setAllocations([...copiedBranches]);
    }
  };

  const [currentRows, setCurrentRows] = useState<Branch[]>(allocations);

  const handleDragStart = (e: any, rowIndex: number) => {
    e.dataTransfer.setData('rowIndex', rowIndex);
  };

  const allowDrop = (e: any, rowIndex: number) => {
    e.preventDefault();
    e.dataTransfer.setData('rowIndex', rowIndex);
  };

  const handleDragOver = (e: any, rowIndex: number) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData('rowIndex');
    if (dragIndex !== rowIndex) {
      const newRows = [...currentRows];
      const [draggedRow] = newRows.splice(dragIndex, 1);
      newRows.splice(rowIndex, 0, draggedRow);
      setCurrentRows(newRows);
    }
  };

  useEffect(() => {
    setAllocations(model.children?.filter((x: Branch) => !x.financialProduct.isCash) || []);
  }, [model]);

  useEffect(() => {
    setAllocations(currentRows);
  }, [currentRows]);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography variant='subtitle1'>{t('portfolioAllocation')}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setOpenExcel(true)}>
                    <BackupTableIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ paddingLeft: '40px !important', paddingRight: '40px !important' }}>
              <Typography variant='overline'>{t('primaryAssetClasses')}</Typography>
              <Doughnut
                data={{
                  labels: Object.keys(primaryAssetClasses()),
                  datasets: [{
                    label: 'Percentage',
                    data: Object.values(primaryAssetClasses()),
                    backgroundColor: graphColors,
                  }],
                }
                } />
            </Grid>
            <Grid item xs={4} sx={{ paddingLeft: '40px !important', paddingRight: '40px !important' }}>
              <Typography variant='overline'>{t('secondaryAssetClasses')}</Typography>
              <Doughnut
                data={{
                  labels: Object.keys(secondaryAssetClasses()),
                  datasets: [{
                    label: 'Percentage',
                    data: Object.values(secondaryAssetClasses()),
                    backgroundColor: graphColors,
                  }],
                }
                } />
            </Grid>
            <Grid item xs={4} sx={{ paddingLeft: '40px !important', paddingRight: '40px !important' }}>
              <Typography variant='overline'>{t('securities')}</Typography>
              <Doughnut
                data={{
                  labels: allocations.map((x: Branch) => x.financialProduct.ticker),
                  datasets: [{
                    label: 'Percentage',
                    data: allocations.map((x: Branch) => x.percentage),
                    backgroundColor: graphColors,
                  }],
                }
                } />
            </Grid>
          </Grid>
        </CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}></TableCell>
                  <TableCell sx={{ width: '5%' }}><Typography variant='overline'>{t('taxRank')}</Typography></TableCell>
                  <TableCell sx={{ width: '60%' }}><Typography variant='overline'>{t('name')}</Typography></TableCell>
                  <TableCell sx={{ width: '20%' }}><Typography variant='overline'>{t('percentage')}</Typography></TableCell>
                  {canWrite() && (
                    <TableCell sx={{ width: '10%' }}>
                      <IconButton onClick={addSecurity}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allocations.map((child: Branch, index: number) => (
                  <TableRow key={child.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => allowDrop(e, index)}
                    onDrop={(e) => handleDragOver(e, index)}
                  >
                    {canWrite() && (
                      <TableCell sx={{ width: '5%' }}>
                        <IconButton>
                          <DragHandleIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell sx={{ width: '10%' }}>
                      <TextField
                        size='small'
                        value={child.financialProduct.taxRank ?? ''}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '60%' }}>
                      <SecuritySelect
                        exceptTickers={allocations.map((a) => a.financialProduct.ticker)}
                        value={child.financialProduct.id ?? ''}
                        setSecurity={(e) => {
                          const a = [...allocations];
                          const fp = { ...a[index] };
                          fp.financialProduct = e;
                          a[index] = fp;
                          const newCash = round(100 - sum(a.map((x: Branch) => x.percentage)), 2);
                          setCashValue(newCash);
                          setModel({ ...model, children: [...a, { percentage: newCash, financialProduct: cashProduct }] });
                          setCurrentRows([...a]);
                        }}
                        label=''
                        size='small'
                        readonly={!canWrite()}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '20%' }}>
                      <NumberField
                        fullWidth
                        decimalPlaces={PERCENTAGE_PRECISION}
                        size='small'
                        value={child.percentage}
                        setNumberValue={(e) => {
                          const a = [...allocations];
                          const fp = { ...a[index] };
                          if (e != null) {
                            fp.percentage = round(e, PERCENTAGE_PRECISION);
                            a[index] = fp;
                            const newCash = round(100 - sum(a.map((x: Branch) => x.percentage)), 2);
                            setCashValue(newCash);
                            setModel({ ...model, children: [...a, { percentage: newCash, financialProduct: cashProduct }] });
                            setCurrentRows([...a]);
                          }
                        }}
                        InputProps={{
                          readOnly: !canWrite(),
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    {canWrite() && (
                      <TableCell sx={{ width: '5%' }}>
                        <IconButton onClick={() => removeSecurity(index)}>
                          <CancelIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {cashProduct && (
                  <TableRow>
                    <TableCell sx={{ width: '5%' }}></TableCell>
                    <TableCell sx={{ width: '10%' }}>
                      <TextField
                        size='small'
                      />
                    </TableCell>
                    <TableCell sx={{ width: '60%' }}>
                      <SecuritySelect
                        value={''}
                        label=''
                        size='small'
                        disabledWith={cashProduct.translatedName?.en}
                        setSecurity={() => {}}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '20%' }}>
                      <TextField
                        fullWidth
                        type='number'
                        size='small'
                        value={cashValue}
                        disabled
                        InputProps={{
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    {canWrite() && (
                      <TableCell sx={{ width: '5%' }}>
                        <IconButton disabled>
                          <CancelIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {canWrite() && (
          <CardContent>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button variant='contained'
                  onClick={updateModel} disabled={sum(allocations.map((x: Branch) => x.percentage)) > 100 || allocations.filter((a) => a.financialProduct?.translatedName?.en === '').length > 0}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <CopyFromExcel
        open={openExcel}
        handleClose={() => setOpenExcel(false)}
        afterUpdate={(copiedbranches: Branch[]) => {
          applyExcelCopiedSecurities(copiedbranches);
          setOpenExcel(false);
        }}
      />
    </>
  );
};
