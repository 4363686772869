import { gql, useQuery } from '@apollo/client';
import {
  Box, Typography, CircularProgress, Table, TableHead, TableCell, TableRow, TableBody, TextField, InputAdornment, Grid,
} from '@mui/material';
import { round } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FETCH_BULK_TRADE_RUN = gql`
  query fetchBulkTradeRun($bulkTradeRunId: ObjectID!) {
    fetchBulkTradeRun(bulkTradeRunId: $bulkTradeRunId) {
      bulkTradeRun {
        id
        bulkMutualFundTrades {
          id
          ticker
          exchange
          type
          currency
          moneyAllocatedCents
        }
      }
    }
  }
`;

export interface AccountBulkMutualFundTrade {
  id: string;
  ticker: string;
  exchange: string;
  type: string;
  currency: string;
  moneyAllocatedCents: number;
}

const UpdateMutualFundTradesTable = ({ type, bulkTradeRunId }: { type: 'BUY' | 'SELL'; bulkTradeRunId: string }) => {
  const { t } = useTranslation('bulkTrader');

  const [bulkTrades, setBulkTrades] = useState<AccountBulkMutualFundTrade[]>([]);

  const { data, loading } = useQuery(FETCH_BULK_TRADE_RUN, {
    variables: {
      bulkTradeRunId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setBulkTrades(data?.fetchBulkTradeRun?.bulkTradeRun?.bulkMutualFundTrades?.filter((x: any) => x.type === type));
    }
  }, [data, type]);

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : !bulkTrades.length ? (
        <></>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography sx={{ p: 2 }} variant='h6'>
                {t(`bulkMutualFund${type}Trades`)}
              </Typography>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='overline'>{t('security')}</Typography>
                </TableCell>

                <TableCell></TableCell>

                <TableCell>
                  <Typography variant='overline'>{t('amountValue')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkTrades.map((line: AccountBulkMutualFundTrade, index: number) => (
                <TableRow key={line.id}>
                  <TableCell>
                    <Typography fontWeight='bold'>{line.ticker}</Typography>
                  </TableCell>

                  <TableCell></TableCell>

                  <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      value={round(line.moneyAllocatedCents / 100, 6)}
                      sx={{ width: '12em' }}
                      size='small'
                      disabled
                      autoComplete='off'
                      inputMode='decimal'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>{line.currency === 'USD' ? 'US$' : '$'}</InputAdornment>,
                        inputProps: { min: 0, step: 'any' },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default UpdateMutualFundTradesTable;
