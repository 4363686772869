/* eslint-disable react-hooks/exhaustive-deps */
import {
  ListItem, TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import FormModal from '../../../components/modals/formModal';

export const UPDATE_ACCOUNT_NUMBER = gql`
mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    account{
      id
    }
  }
}
`;

const EditAccountNumber = ({
  afterCreate, accountId, accNumber, open, handleClose,
}: { afterCreate: () => void, accountId?: string, accNumber?: string, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['buildModels']);
  const { activeOrganization } = useContext(UserContext);
  const [localOpen, setLocalOpen] = useState(open);
  const [accountNumber, setAccountNumber] = useState({
    accountId: '',
    custodianAccountNumber: accNumber,
    organizationId: activeOrganization.id,
  });

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT_NUMBER, {
    variables: {
      input: {
        custodianAccountNumber: accountNumber.custodianAccountNumber,
        accountId,
      },
    },
  });

  useEffect(() => {
    setAccountNumber({ ...accountNumber, organizationId: activeOrganization.id, custodianAccountNumber: accNumber });
    setLocalOpen(open);
  }, [open, accNumber]);

  const create = async (event: any) => {
    event.preventDefault();
    await updateAccount();
    afterCreate();
  };

  return (
    <FormModal loading={loading} title={t('editAccount.title')} formButton={t('update')} onSubmit={create} open={localOpen} handleClose={handleClose}>
      <ListItem>
        <TextField fullWidth autoFocus
          label={t('editAccount.accountNumber')}
          value={accountNumber.custodianAccountNumber ?? ''}
          onChange={(e) => setAccountNumber({ ...accountNumber, custodianAccountNumber: e.target.value })} data-testid="accountNumber" id='accountNumber'
        />
      </ListItem>
      <ListItem>
      </ListItem>
    </FormModal>
  );
};

export default EditAccountNumber;
