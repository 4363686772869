import { gql } from '@apollo/client';

export const FETCH_TRADES_QUERY = (permissions: string[]) => `query fetchTrades($input: FetchTradesInput!) {
  fetchTrades(input: $input) {
    trades {
      id
      actualQuantity
      actualTradePriceCents
      expectedQuantity
      expectedTradePriceCents
      state
      type
      moneyAllocatedCents
      stateChanges
      rebalance { id }
      account {
        id
        type
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        }
        organization { id }
      }
      financialProduct {
        ticker
        currency
        translatedName {
          en
        }
      }
      createdAt
      reconciledAt
    }
    totalCount
  }
}`;

export const FETCH_SUB_TRADES_QUERY = (permissions: string[]) => `query fetchSubTrades($input: FetchSubTradesInput!) {
  fetchSubTrades(input: $input) {
    subTrades {
      id
      actualQuantity
      actualTradePriceCents
      state
      type
      moneyAllocatedCents
      stateChanges
      trade {
        expectedQuantity
        expectedTradePriceCents
      }
      rebalance { id }
      subAccount {
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          }
          organization { id }
        }
      }
      financialProduct {
        ticker
        currency
        translatedName {
          en
        }
      }
      createdAt
      reconciledAt
    }
    totalCount
  }
}`;

export const FETCH_TRADES = (permissions: string[]) => gql`${FETCH_TRADES_QUERY(permissions)}`;

export const FETCH_SUB_TRADES = (permissions: string[]) => gql`${FETCH_SUB_TRADES_QUERY(permissions)}`;

export const TRANSITION_TRADE = gql`
  mutation transitionTrade($input: TransitionTradeInput!) {
    transitionTrade(input: $input) {
      trade {
        id
      }
    }
  }
`;
