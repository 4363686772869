/* eslint-disable react-hooks/exhaustive-deps */
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LanguageIcon from '@mui/icons-material/Language';
import LaunchIcon from '@mui/icons-material/Launch';
import PersonIcon from '@mui/icons-material/AccountCircle';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
  type FC, useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThemeTokens } from 'providers/themeTokenProvider';
import ViewAllProfilesIcon from 'assets/images/icons-filled/view-all-profiles.svg';
import AddNewProfileIcon from 'assets/images/icons-filled/add-new-profile.svg';
import {
  Avatar, MenuItem, Menu, IconButton,
} from 'ovComponents/2-component';
import { Box, Typography } from 'ovComponents/1-primative';
import { ViewAllProfilesDialog } from 'ovComponents/3-pattern/viewAllProfilesDialog/viewAllProfilesDialog';
import { CreateEntityWorkflow } from 'ovComponents/4-module/createEntityWorkflow/createEntityWorkflow';
import { UserContext, ActiveEntityProps, usePermissions } from 'providers/userContextProvider';
import { Divider } from 'ovComponents/2-component/divider/divider';
import { usePageState } from 'util/usePageState';
import { EntityTypes, NavigationStyles } from 'interfaces';
import { FeatureFlagTypes } from 'interfaces/organizationFeatureFlag';
import filterHouseholdsWithAccess from 'util/filterHouseholdsWithAccess';
import { entityInitials, entityName, isFeatureEnabled } from '../../../util';
import { paletteBase } from '../../0-tokens';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { LanguageSelectionModal } from '../languageSelectionModal/languageSelectionModal';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { ChangeAvatarModal } from '../changeAvatarModal/changeAvatarModal';

export const colors = [
  paletteBase.supportOne60,
  paletteBase.supportTwo60,
  paletteBase.supportThree60,
  paletteBase.supportFour60,
  paletteBase.supportFive60,
  paletteBase.supportSix60,
];

export const colorsInner = [
  paletteBase.supportOne40,
  paletteBase.supportTwo40,
  paletteBase.supportThree40,
  paletteBase.supportFour40,
  paletteBase.supportFive40,
  paletteBase.supportSix40,
];

const MAX_PROFILES_IN_MENU = 4;

interface IEntityMenuProps {
  logout: () => void;
}

const EntityMenu: FC<IEntityMenuProps> = ({ logout }) => {
  const { t } = useTranslation(['entityMenu']);
  const {
    userContext, activeEntity, activeHousehold, activeOrganization, setActiveEntity, setActiveHousehold,
  } = useContext(UserContext);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { permissions } = usePermissions();
  const { sys } = useThemeTokens();
  const location = useLocation();
  const navigate = useNavigate();
  const [createEntityOpen, setCreateEntityOpen] = usePageState(false, 'createEntity');
  const [updateLanguageOpen, setUpdateLanguageOpen] = useState(false);
  const [updateAvatarOpen, setUpdateAvatarOpen] = useState(false);
  const [viewAllProfiles, setViewAllProfiles] = useState(false);
  const [autoOpenWorkflow, setAutoOpenWorkflow] = useState(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const nonAffiliateEntities = userContext?.entities?.filter((x) => !x.entity.affiliateOnly);

  let allowLogout = activeOrganization.allowLogout ?? true;
  if (userContext.organization && activeOrganization.id !== userContext.organization.id) {
    allowLogout = true;
  }

  const handleClick = (event: any) => {
    sendAnalytic(ovAnalyticsEvents.homepageProfileSelect);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!activeEntity || !activeEntity.id) return;
    if (userContext.role?.navigationStyle === NavigationStyles.SIMPLE) {
      if (activeEntity) {
        let path = '';
        const households = filterHouseholdsWithAccess(activeEntity?.households || [], activeEntity?.id);
        const isHouseholdDefaultView = households.length > 0 && userContext.role?.makeHouseholdDefaultView;

        if (isHouseholdDefaultView && activeHousehold?.id && (!location.pathname.includes('households') || !location.pathname.includes(activeHousehold?.id))) {
          path = `/households/${activeHousehold?.id}`;
        } else if (!isHouseholdDefaultView && !location.pathname.includes(activeEntity.id || '')) {
          path = `/clients/${activeEntity?.id}`;
        }

        if (path) {
          if (autoOpenWorkflow) {
            // Sets the autoOpenWorkflow only when initially loading the page for the first entity
            navigate(`${path}?autoOpenWorkflow=true`);
            setAutoOpenWorkflow(false);
          } else {
            navigate(path);
          }
        }
      } else if (!activeEntity) {
        setCreateEntityOpen(true);
      }
    }
  }, [activeEntity, navigate, activeHousehold, userContext]);

  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const profileName = useCallback((entity?: ActiveEntityProps) => entityName(entity) || entity?.primaryEmail, []);
  const profileInitials = useCallback((entity?: ActiveEntityProps) => entityInitials(entity), []);

  const menuItem = ({
    title, image, testId, onClick,
  }: {
    title: string; image: string; testId: string; onClick: () => void,
  }): JSX.Element => (
    <MenuItem
      key={title}
      data-testid={testId}
      onClick={(e) => {
        handleClose();
        onClick();
      }}
    >
      <Box marginRight={sys.spacing.md} display='flex' height='32px' width='32px' alignItems='center' justifyContent='center'>
        <img src={image} alt='view-profile-icon' height='24px' width='24px' />
      </Box>
      <Typography variant='bodyMedium' fontWeight='500'>
        {title}
      </Typography>
    </MenuItem>
  );

  const getEntitiesSortedByActive = (): {
    entity: {
      id: string,
      type?: EntityTypes,
      entityName?: string,
      firstName?: string,
      lastName?: string,
      primaryEmail?: string,
    }
  }[] => {
    const nonActiveEntities = nonAffiliateEntities?.filter((item) => item.entity.id !== activeEntity?.id) ?? [];
    nonActiveEntities.sort((item1: any, item2: any) => (profileName(item1.entity) > profileName(item2.entity) ? 1 : -1));
    return [
      ...(nonAffiliateEntities?.filter((item) => item.entity.id === activeEntity?.id) ?? []),
      ...nonActiveEntities,
    ];
  };

  return (
    <>
      <IconButton onClick={handleClick} data-testid='profile-menu-icon'>
        <span>
          <Avatar state='highlight' size='large' fgColor={paletteBase.supportOne60} bgColor={paletteBase.supportOne40} avatar={userContext.avatar}>
            {(activeEntity && isUnifiedClientExperience ? (profileInitials(activeEntity) ?? '') : `${userContext.firstName?.[0]}${userContext.lastName?.[0]}`)?.toUpperCase()}
          </Avatar>
        </span>
      </IconButton>
      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        sx={{
          '.MuiPaper-root': {
            boxShadow: '0 0 8px rgba(0,0,0,0.1)',
            width: '230px',
            borderRadius: sys.borderRadius.lg,
          },
        }}
      >
        {isUnifiedClientExperience && [
          ...getEntitiesSortedByActive()
            .slice(0, (nonAffiliateEntities?.length ?? 0) > MAX_PROFILES_IN_MENU ? 1 : nonAffiliateEntities?.length)
            .map((en: any, index: number) => {
              const entity = (nonAffiliateEntities?.length ?? 0) > MAX_PROFILES_IN_MENU ? activeEntity : en.entity;
              const colorIndex = index % colors.length;
              return (
                <MenuItem
                  key={entity.id}
                  sx={{ pv: 2, display: 'flex', justifyContent: 'space-between' }}
                  onClick={(e) => {
                    handleClose();
                    const households = filterHouseholdsWithAccess(entity?.households || [], entity?.id);
                    const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && households.length > 0;
                    if (isHouseholdDefaultView) {
                      const household = households[0];
                      setActiveHousehold(household);
                      navigate(`/households/${household?.id}/clients/${entity.id}`);
                    }
                    setActiveEntity(entity);
                  }}
                >
                  <Box display='flex' alignItems='center'>
                    <Box marginRight={sys.spacing.md} height='32px' width='32px'>
                      <Avatar state='highlight' size='large' fgColor={colors[colorIndex]} bgColor={colorsInner[colorIndex]}>
                        {profileInitials(entity)}
                      </Avatar>
                    </Box>
                    <Typography
                      variant={entity.id === activeEntity?.id ? 'bodyLarge' : 'bodyMedium'}
                      align='left'
                      weight={entity.id === activeEntity?.id ? 'bold' : 'regular'}
                      sx={{ maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {profileName(entity)}
                    </Typography>
                  </Box>
                  {entity.id === activeEntity?.id ? <CheckRoundedIcon style={{ fontSize: '16px' }} /> : undefined}
                </MenuItem>
              );
            }),
          (nonAffiliateEntities && nonAffiliateEntities.length > MAX_PROFILES_IN_MENU
            ? menuItem({
              image: ViewAllProfilesIcon,
              title: t('viewAllProfiles'),
              testId: 'view-all-profile',
              onClick: () => setViewAllProfiles(true),
            })
            : undefined
          ),
          (isFeatureEnabled(FeatureFlagTypes.ALLOW_ADDING_A_NEW_PROFILE)
            ? menuItem({
              image: AddNewProfileIcon,
              title: t('addNewEntity'),
              testId: 'add-new-entity',
              onClick: () => setCreateEntityOpen(true),
            })
            : undefined
          ),
          <Divider />,
        ]}
        {permissions.includes('write:organization_user_profile') && [
          <MenuItem
            key='menuitem-language'
            sx={{ pv: 2 }}
            onClick={(e) => {
              handleClose();
              setUpdateLanguageOpen(true);
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LanguageIcon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant='bodyMedium' sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {t('language')}
              </Typography>
            </Box>
          </MenuItem>,
          <MenuItem
            key='menuitem-updateAvatar'
            sx={{ pv: 2 }}
            onClick={(e) => {
              handleClose();
              setUpdateAvatarOpen(true);
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PersonIcon sx={{ mr: 1, fontSize: 18 }} />
              <Typography variant='bodyMedium' sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {t('updateAvatar')}
              </Typography>
            </Box>
          </MenuItem>,
        ]}
        <MenuItem
          key='menuitem-resourcesArticles'
          sx={{ pv: 2, display: 'flex', justifyContent: 'space-between' }}
          onClick={(e) => {
            sendAnalytic(ovAnalyticsEvents.homepageResourceArticlesSelect);
            handleClose();
            const helpCentreUrl = activeOrganization?.helpCentreUrl || 'https://onevest.zendesk.com/hc/en-us';
            window.open(helpCentreUrl, '_blank');
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MenuBookIcon sx={{ mr: 1, fontSize: 18 }} />
            <Typography variant='bodyMedium' sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {t('resourcesArticles')}
            </Typography>
          </Box>
          <LaunchIcon sx={{ fontSize: 18 }} />
        </MenuItem>
        <MenuItem
          key='menuitem-contactSupport'
          sx={{ pv: 2, display: 'flex', justifyContent: 'space-between' }}
          onClick={(e) => {
            sendAnalytic(ovAnalyticsEvents.homepageContactSupportSelect);
            handleClose();
            const supportUrl = activeOrganization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new';
            window.open(supportUrl, '_blank');
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SupportAgentIcon sx={{ mr: 1, fontSize: 18 }} />
            <Typography variant='bodyMedium' sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {t('contactSupport')}
            </Typography>
          </Box>
          <LaunchIcon sx={{ fontSize: 18 }} />
        </MenuItem>
        {allowLogout && (
          <MenuItem
            key='menuitem-logout'
            sx={{ pv: 2 }}
            onClick={() => {
              handleClose();
              logout();
            }}
          >
            <LogoutIcon sx={{ mr: 1, fontSize: 18 }} />
            <Typography variant='bodyMedium' sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {t('logout')}
            </Typography>
          </MenuItem>
        )}
      </Menu>
      <LanguageSelectionModal open={updateLanguageOpen} setOpen={setUpdateLanguageOpen} />
      <ChangeAvatarModal open={updateAvatarOpen} setOpen={setUpdateAvatarOpen} />
      <CreateEntityWorkflow open={createEntityOpen} setOpen={setCreateEntityOpen} />
      <ViewAllProfilesDialog
        open={viewAllProfiles}
        onAddNewProfile={() => {
          setViewAllProfiles(false);
          setCreateEntityOpen(true);
        }}
        onViewDetail={() => setViewAllProfiles(false)}
        onCancel={() => setViewAllProfiles(false)}
      />
    </>
  );
};

export default EntityMenu;
