import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocalization } from '../../../../util/useLocalization';
import { translateBackend } from '../../../../assets/i18n/config';
import { Skeleton, Typography } from '../../../1-primative';
import {
  Badge, Card, Pagination, Table, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { BulkTradeDialog } from './components/bulkTradeDialog';

const FETCH_BULK_TRADE_REQUESTS = gql`
  query fetchRebalances ($input: FetchBulkTradeRequestsInput!) {
    fetchBulkTradeRequests(input: $input) {
      bulkTradeRequests {
        id
        state
        createdAt
        stateChanges
      }
      totalCount
    }
  }
`;

const PER_PAGE = 15;

export const BulkTradeRequests = ({ objectType, objectId, options }: { objectType: PageObjectType, objectId: string, options: any }) => {
  const { t } = useTranslation(['bulkTrader']);
  const { localizedDateTime } = useLocalization();
  const [page, setPage] = useState(1);
  const [activeBulkTradeRequest, setActiveBulkTradeRequest] = useState(null);
  const [open, setOpen] = useState(false);

  const { data, loading, previousData } = useQuery(FETCH_BULK_TRADE_REQUESTS, {
    variables: {
      input: {
        filter: {
          source: objectType === PageObjectType.GOAL ? 'GOAL' : 'SUB_ACCOUNT',
          sourceId: objectId,
        },
        pagination: {
          perPage: PER_PAGE,
          offSet: (page - 1) * PER_PAGE,
        },
      },
    },
  });

  return (
    <>
      { options.customTitle && (<Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>) }
      <Card loading={loading}>
        <Table>
          <TableRow>
            <TableHeadCell>{t('table.createdAt')}</TableHeadCell>
            <TableHeadCell>{t('table.state')}</TableHeadCell>
            <TableHeadCell>{t('table.completedAt')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(PER_PAGE)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchBulkTradeRequests?.bulkTradeRequests?.map((bulkTradeRequest: any) => (
            <TableRow key={bulkTradeRequest.id} hover onClick={() => {
              setActiveBulkTradeRequest(bulkTradeRequest);
              setOpen(true);
            }}>
              <TableCell>{localizedDateTime(bulkTradeRequest.createdAt)}</TableCell>
              <TableCell>
                <Badge
                  label={t(`state.${bulkTradeRequest.state}`)}
                  color={bulkTradeRequest.state === 'COMPLETED' ? 'positive' : bulkTradeRequest.state === 'CANCELED' ? 'negative' : 'neutral'}
                />
              </TableCell>
              <TableCell>
                {
                  bulkTradeRequest.stateChanges?.find((x: any) => x.to === 'COMPLETED')
                    ? localizedDateTime(bulkTradeRequest.stateChanges?.find((x: any) => x.to === 'COMPLETED')?.createdAt)
                    : '-'
                }
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchBulkTradeRequests?.totalCount ?? 0) / PER_PAGE)}
          page={page}
          perPage={PER_PAGE}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
      <BulkTradeDialog bulkTradeRequest={activeBulkTradeRequest} open={open} setOpen={setOpen} />
    </>
  );
};
