/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, TextField, MenuItem, Paper, Box, InputAdornment, ClickAwayListener, FormGroup, Chip,
} from '@mui/material';
import {
  useEffect, useRef, useState,
} from 'react';
import Popper from '@mui/material/Popper';
import { Search } from '@mui/icons-material';
import { colors } from '../../ovComponents';

type Item = Required<{ id: string }>;
export type SearchResults = { items: Item[], count: number };

export const FulltextSearchSelect = ({
  item, setItem,
  label, autoFocus, disabled, error,
  fullWidth, size,
  searchFn,
  primaryLabelFn,
  secondaryLabelFn = () => '',
}: {
  item?: Item, setItem: (item: Item | undefined) => void,
  label: string, autoFocus?: boolean, disabled?: boolean, error?: boolean,
  fullWidth?: boolean, size?: 'small' | 'medium'
  searchFn: (query:string) => Promise<SearchResults>,
  primaryLabelFn: (item:any) => string
  secondaryLabelFn?: (item:any) => string
}) => {
  const [searchValue, setSearchValue] = useState<string>(item ? primaryLabelFn(item) : '');
  const [matchingItems, setMatchingItems] = useState<Item[]>([]);
  const [matchingItemCount, setMatchingItemCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [keyboardHighlightIndex, setKeyboardHighlightIndex] = useState<number | null>(null);
  const [foundItem, setFoundItem] = useState<Item | undefined>(item);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAnchorEl(textFieldRef.current);
  }, []);

  useEffect(() => {
    setKeyboardHighlightIndex(null);
    if (item) {
      setSearchValue(primaryLabelFn(item));
      handleSelectItem(item);
    }
  }, [item]);

  useEffect(() => {
    if (foundItem) {
      if (searchValue === primaryLabelFn(foundItem)) return;
      setFoundItem(undefined);
    }
    if (searchValue === '') {
      setMatchingItems([]);
      setFoundItem(undefined);
    }
    if (searchValue !== '') {
      setLoading(true);
      searchFn(searchValue).then(
        (found) => {
          setMatchingItems(found.items);
          setMatchingItemCount(found.count);
          setKeyboardHighlightIndex(null);
          setLoading(false);
        },
      );
    }
  }, [foundItem, searchValue]);

  useEffect(() => {
    setItem(foundItem);
  }, [foundItem]);

  const handleSelectItem = (i: Item) => {
    setItem(i);
    setFoundItem(i);
    setMatchingItems([]);
    setSearchValue(primaryLabelFn(i));
    setKeyboardHighlightIndex(null);
  };

  /* keyboard controls when drop-down is open */
  const onTextFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (matchingItems.length === 0) return;

    if (e.key === 'Escape') {
      setMatchingItems([]);
      e.stopPropagation();
    }
    if (e.key === 'ArrowDown') {
      setKeyboardHighlightIndex(Math.min((keyboardHighlightIndex ?? -1) + 1, matchingItems.length - 1));
    }
    if (e.key === 'ArrowUp') {
      setKeyboardHighlightIndex(Math.max((keyboardHighlightIndex ?? matchingItems.length) - 1, 0));
    }
    if (e.key === 'Enter' && keyboardHighlightIndex !== null) {
      handleSelectItem(matchingItems[keyboardHighlightIndex]);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setMatchingItems([])}>
      <FormGroup sx={{ width: fullWidth ? '100%' : '400px' }}>
        <TextField
          value={searchValue}
          autoFocus={autoFocus}
          disabled={disabled}
          error={error}
          label={label}
          fullWidth
          size={size}
          onChange={(e) => { setSearchValue(e.target.value); }}
          onKeyDown={onTextFieldKeyDown}
          ref={textFieldRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps= {{ 'data-testid': 'fulltextsearch-select' }}
        />

        <Popper open={matchingItems.length > 0} anchorEl={anchorEl} placement='bottom-start' style={{ zIndex: 1999, width: anchorEl?.clientWidth }}>
          <Paper elevation={4}>
            {
              matchingItems.map((x: any, index) => (
                <MenuItem key={ index } value={ x.id } disabled={loading}
                  onClick={() => handleSelectItem(x)}
                  onMouseOver={() => setKeyboardHighlightIndex(null)}
                  sx={{
                    whiteSpace: 'normal',
                    ...(keyboardHighlightIndex === index ? { backgroundColor: 'rgb(0, 0, 0, 0.04)' } : {}),
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography component={'span'}>
                      { primaryLabelFn(x) }
                      {x?.affiliateOnly && (<Chip sx={{ ml: 0.5 }} label='affiliate' size='small'></Chip>)}
                    </Typography>
                    <Typography style={{ color: colors.neutral500 }} ml={1}>
                      { secondaryLabelFn(x) }
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            }
            { matchingItemCount > matchingItems.length && (
              <Box p={2}>
                <Typography color={colors.neutral500}>... and {matchingItemCount - matchingItems.length} more</Typography>
              </Box>
            )}
          </Paper>
        </Popper>
      </FormGroup>
    </ClickAwayListener>
  );
};
