import i18n from 'i18next';
import { AccountTypes } from '../interfaces';

export enum TransferPermission {
  AllowedFull = 'Allowed-Full',
  AllowedLimited = 'Allowed - Limited',
  NotAllowedFull = 'Not allowed - Full',
  NotAllowedLimited = 'Not allowed - Limited',
}

export const transferRestrictions: Record<string, any> = {
  PERSONAL: {
    accountType: AccountTypes.PERSONAL,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  CASH_JOINT: {
    accountType: AccountTypes.CASH_JOINT,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  TFSA: {
    accountType: AccountTypes.TFSA,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RRSP: {
    accountType: AccountTypes.RRSP,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedLimited,
      errorMessage: i18n.t('transferRestrictions:RRSP.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RRSP_SPOUSAL: {
    accountType: AccountTypes.RRSP_SPOUSAL,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedLimited,
      errorMessage: i18n.t('transferRestrictions:RRSP_SPOUSAL.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  LIRA: {
    accountType: AccountTypes.LIRA,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:LIRA.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: i18n.t('transferRestrictions:LIRA.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:LIRA.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  LRSP: {
    accountType: AccountTypes.LRSP,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:LRSP.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: i18n.t('transferRestrictions:LRSP.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:LRSP.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RLSP: {
    accountType: AccountTypes.RLSP,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:RLSP.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: i18n.t('transferRestrictions:RLSP.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:RLSP.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RRIF: {
    accountType: AccountTypes.RRIF,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RIF_SPOUSAL: {
    accountType: AccountTypes.RIF_SPOUSAL,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  LIF: {
    accountType: AccountTypes.LIF,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  PRIF: {
    accountType: AccountTypes.PRIF,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  LRIF: {
    accountType: AccountTypes.LRIF,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RLIF: {
    accountType: AccountTypes.RLIF,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.NotAllowedFull,
      canInitiate: false,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferInMessage'),
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedFull,
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.electronicFundsTransferOutMessage'),
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: i18n.t('transferRestrictions:defaultLockedAccount.internalTransferToMessage'),
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP_FAMILY: {
    accountType: AccountTypes.RESP_FAMILY,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP_FAMILY_JOINT: {
    accountType: AccountTypes.RESP_FAMILY_JOINT,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP_SINGLE: {
    accountType: AccountTypes.RESP_SINGLE,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP: {
    accountType: AccountTypes.RESP,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP_SINGLE_JOINT: {
    accountType: AccountTypes.RESP_SINGLE_JOINT,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  RESP_ADULT: {
    accountType: AccountTypes.RESP_ADULT,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  FHSA: {
    accountType: AccountTypes.FHSA,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.NotAllowedLimited,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedLimited,
      acceptableAccounts: [AccountTypes.FHSA, AccountTypes.RRSP, AccountTypes.RRIF],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
  CORPORATE_CASH: {
    accountType: AccountTypes.CORPORATE_CASH,
    externalTransfer: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    electronicFundsTransferIn: {
      permission: TransferPermission.AllowedFull,
      canInitiate: true,
      errorMessage: '',
    },
    electronicFundsTransferOut: {
      permission: TransferPermission.AllowedFull,
      errorMessage: '',
    },
    internalTransferTo: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    internalTransferFrom: {
      permission: TransferPermission.AllowedFull,
      acceptableAccounts: [],
      errorMessage: '',
    },
    errorMessage: '',
  },
};

export interface ValidateTransferResponse {
  isValid: boolean,
  message: string,
}

interface ValidateTransferInput {
  accountTo: AccountTypes,
  transferType: string,
  accountFrom?: AccountTypes,
  canOverrideTransferRestrictions: boolean,
}

export const validateTransfer = ({
  accountTo, transferType, accountFrom, canOverrideTransferRestrictions,
}: ValidateTransferInput): ValidateTransferResponse => {
  if (!transferRestrictions[accountTo]) return { isValid: true, message: '' };
  const { permission, errorMessage } = transferRestrictions[accountTo][transferType];
  if (permission === TransferPermission.NotAllowedFull) return { isValid: false, message: errorMessage };
  if (permission === TransferPermission.NotAllowedLimited || permission === TransferPermission.AllowedLimited) {
    if (canOverrideTransferRestrictions) return { isValid: true, message: '' };
    return { isValid: false, message: errorMessage };
  }
  return { isValid: true, message: '' };
};
