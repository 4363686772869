import { gql } from '@apollo/client';

export const PAGE_CONFIGURATION = `
  clientReportTemplatePageConfiguration {
    id
    type
    options
    widgets {
      id
      type
      options
    }
  }
`;

export const FETCH_PAGE_CONFIGURATION = gql`
query fetchClientReportTemplatePageConfiguration($clientReportTemplatePageConfigurationId: ObjectID!) {
  fetchClientReportTemplatePageConfiguration (clientReportTemplatePageConfigurationId: $clientReportTemplatePageConfigurationId) {
    ${PAGE_CONFIGURATION}
  }
}
`;

export const UPDATE_PAGE_CONFIGURATION = gql`
  mutation updateClientReportTemplatePageConfiguration($input: UpdateClientReportTemplatePageConfigurationInput!) {
    updateClientReportTemplatePageConfiguration(input: $input) {
      ${PAGE_CONFIGURATION}
    }
  }
`;

export const FETCH_RELATED_OBJECTS = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName middleName lastName entityName
        goals {
          id
        }
        accounts {
          id
        }
        subAccounts {
          id
        }
        households {
          id
        }
        statistics {
          marketValueCents
        }
        organization {
          id
          displayCurrency
        }
      }
    }
  }
`;
