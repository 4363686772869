/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Add } from '@mui/icons-material';
import {
  Button, Table, TableRow, TableCell, TableHeadCell,
  Dialog,
  Form,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
  MenuItem,
  TextField,
  DialogFooter,
  Menu,
  Switch,
  Typography,
} from '../../../ovComponents';
import { AuthenticationConnections, Organization, OrganizationAuthenticationDomain } from '../../../interfaces';

const defaultValue: OrganizationAuthenticationDomain = { authenticationType: AuthenticationConnections.ENTERPRISE };
const EditAuthenticationDomain = ({
  onUpdate,
  onRemove,
  domainToUpdate,
  open,
  handleClose,
  existingDomainUrls,
}: {
  onUpdate: (d: OrganizationAuthenticationDomain) => void,
  onRemove: () => void,
  domainToUpdate?: OrganizationAuthenticationDomain,
  open: boolean;
  handleClose: () => void,
  existingDomainUrls: string[],
}) => {
  const { t } = useTranslation(['orgSettings']);
  const [domain, setDomain] = useState(domainToUpdate || defaultValue);

  const showConnectionName = domain.authenticationType && [AuthenticationConnections.ENTERPRISE, AuthenticationConnections.EMAIL_AND_PASSWORD].includes(domain.authenticationType);
  const showHostedLogin = domain.authenticationType && AuthenticationConnections.ENTERPRISE === domain.authenticationType;

  const disabled = !domain?.url || !domain?.authenticationType || existingDomainUrls.includes(domain.url);

  useEffect(() => {
    setDomain(domainToUpdate || defaultValue);
  }, [domainToUpdate]);

  const onUpdateDomain = () => {
    onUpdate({
      ...domain,
      ...(!showConnectionName ? { enterpriseConnectionName: undefined } : {}),
      ...(!showHostedLogin ? { useHostedLogin: undefined } : {}),
    });
    setDomain(defaultValue);
  };
  const onRemoveDomain = () => {
    onRemove();
    setDomain(defaultValue);
  };
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);
  const openContextMenu = (onElement: HTMLElement) => {
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);
  const menuItems = [
    <MenuItem key='menu1' onClick={onRemoveDomain}>
      {t('orgSettings:organizationDetails.authenticationDomain.removeDomain')}
    </MenuItem>,
  ];
  const title = t(`orgSettings:organizationDetails.authenticationDomain.${domainToUpdate ? 'editTitle' : 'addTitle'}`);

  return (
    <>
      <Dialog open={open} title={title} onClose={handleClose} maxWidth='sm' fullWidth>
        <Form>
          <DialogTitle onClose={(!menuItems) ? handleClose : undefined}>
            <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
              {title}
              {menuItems && (
                <IconButton size='small' onClick={(event: React.MouseEvent<HTMLElement>) => { openContextMenu(event.currentTarget); }}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent sx={{
            display: 'flex', flexDirection: 'column', gap: '1rem', overflow: 'hidden',
          }}>
            <Box>
              <TextField
                label={t('orgSettings:organizationDetails.authenticationDomain.url')}
                fullWidth
                value={domain.url || ''}
                sx={{ mb: 2 }}
                onChange={(e: any) => setDomain((s) => ({ ...s, url: e.target.value }))}
                required
                errorText={t('orgSettings:organizationDetails.authenticationDomain.urlAlreadyExistsError')}
                error={!!domain.url && existingDomainUrls.includes(domain.url)}
              />
              <TextField
                select
                sx={{ mb: 2 }}
                fullWidth
                value={domain.authenticationType}
                label={t('orgSettings:organizationDetails.authenticationDomain.authenticationType')}
                required
                onChange={(e: any) => setDomain((s) => ({ ...s, authenticationType: e.target.value as AuthenticationConnections }))}
              >
                {Object.values(AuthenticationConnections).map((x: any) => (
                  <MenuItem key={x} value={x}>
                    {t(`organizationDetails.defaultAuthenticationConnectionOptions.${x}`)}
                  </MenuItem>
                ))}
              </TextField>
              {showConnectionName && (
                <TextField
                  label={t('orgSettings:organizationDetails.authenticationDomain.enterpriseConnectionName')}
                  fullWidth
                  value={domain.enterpriseConnectionName || ''}
                  sx={{ mb: 2 }}
                  onChange={(e: any) => setDomain((s) => ({ ...s, enterpriseConnectionName: e.target.value }))}
                />
              )}
              {showHostedLogin && (
                <Switch
                  sx={{ mb: 2 }}
                  checked={domain.useHostedLogin || false}
                  label={t('orgSettings:organizationDetails.authenticationDomain.useHostedLogin')}
                  onChange={(e) => setDomain((s) => ({ ...s, useHostedLogin: e }))}
                />
              )}
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant='tonal' label={t('shared:cancel')} onClick={handleClose} sx={{ mr: 1 }} />
              <Button label={domainToUpdate ? t('shared:update') : t('shared:add')} onClick={onUpdateDomain} disabled={disabled} />
            </Box>
          </DialogFooter>
          {menuItems && (
            <Menu
              anchorEl={contextMenuAnchorEl}
              open={contextMenuOpen}
              onClose={closeContextMenu}
              onClick={closeContextMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ cursor: 'pointer' }}
            >
              {menuItems}
            </Menu>
          )}
        </Form>
      </Dialog>
    </>
  );
};

const AuthenticationDomainsTable = ({ organization, setOrganization }: { organization: Organization, setOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>> }) => {
  const { t } = useTranslation(['orgSettings']);
  const [selectedDomainIndex, setSelectedDomainIndex] = useState<number>(-1);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const domains = organization.authenticationDomains || [];

  const onUpdate = (domain?: OrganizationAuthenticationDomain) => {
    if (selectedDomainIndex > -1 && domain?.url) {
      domains[selectedDomainIndex] = domain;
      setOrganization({ ...organization, authenticationDomains: domains });
    } else if (domain?.url) {
      domains.push(domain);
      setOrganization({ ...organization, authenticationDomains: domains });
    }
    setSelectedDomainIndex(-1);
    setUpdateDialogOpen(false);
  };
  const onRemove = () => {
    if (selectedDomainIndex > -1) {
      setOrganization({ ...organization, authenticationDomains: domains.filter((_d, i) => i !== selectedDomainIndex) });
    }
    setSelectedDomainIndex(-1);
    setUpdateDialogOpen(false);
  };
  const onClose = () => {
    setSelectedDomainIndex(-1);
    setUpdateDialogOpen(false);
  };

  return (
    <Box width='100%'>
      <Box display='flex' justifyContent='space-between' flexWrap='wrap' width='100%'>
        <Box display='flex' alignItems='center'>
          <Typography variant='bodyMedium'>{t('organizationDetails.authenticationDomainsTable')}</Typography>
        </Box>
        <Box display='flex'>
          <IconButton data-testid='add-authentication-domain' onClick={() => setUpdateDialogOpen(true)}><Add /></IconButton>
        </Box>
      </Box>
      <Table>
        <TableRow>
          <TableHeadCell>{t('orgSettings:organizationDetails.authenticationDomain.url')}</TableHeadCell>
          <TableHeadCell>{t('orgSettings:organizationDetails.authenticationDomain.authenticationType')}</TableHeadCell>
          <TableHeadCell>{t('orgSettings:organizationDetails.authenticationDomain.enterpriseConnectionName')}</TableHeadCell>
          <TableHeadCell>{t('orgSettings:organizationDetails.authenticationDomain.useHostedLogin')}</TableHeadCell>
        </TableRow>
        {(domains).map((domain, index) => (
          <TableRow
            hover
            key={domain.url}
            sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={() => {
              setSelectedDomainIndex(index);
              setUpdateDialogOpen(true);
            }}
          >
            <TableCell>{domain.url}</TableCell>
            <TableCell>{t(`orgSettings:organizationDetails.defaultAuthenticationConnectionOptions.${domain.authenticationType}`)}</TableCell>
            <TableCell>{domain.enterpriseConnectionName}</TableCell>
            <TableCell>{domain.useHostedLogin ? t('shared:yes') : t('shared:no')}</TableCell>
          </TableRow>
        ))}
      </Table>
      <EditAuthenticationDomain
        domainToUpdate={domains[selectedDomainIndex]}
        open={updateDialogOpen}
        onUpdate={onUpdate}
        handleClose={onClose}
        onRemove={onRemove}
        existingDomainUrls={domains.filter((d, i) => i !== selectedDomainIndex && !!d.url).map((d) => d.url!)}
      />
    </Box>
  );
};

export default AuthenticationDomainsTable;
