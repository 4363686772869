import {
  ApolloClient, ApolloProvider,
  createHttpLink, from, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React, {
  createContext, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { auth0Logout } from './auth0Client';
import { useGlobalToast } from './globalToastProvider';
import { clientHostedLogout } from './CIAMProvider';

type AuthContextType = {
  authToken: string,
  setAuthToken: (token: string) => void
  appLogout: () => Promise<void>,
  settings: AuthContextSettings,
  setSettings: (settings: AuthContextSettings) => void
};

interface AuthContextSettings {
  clientHostedLoginRedirectUri?: string
  clientHostedLoginURL?: string
  auth0RedirectUri?: string
  enableInactivityTimeOut?: boolean
  inactivityTimeoutInMinutes?: number
}

const AuthContext = createContext<AuthContextType>({
  authToken: '',
  setAuthToken: () => { },
  appLogout: async () => { },
  settings: {},
  setSettings: () => { },
});

const OvApolloProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['shared']);
  const { showToast } = useGlobalToast();
  const [resetKey, setResetKey] = useState(0);
  const [authToken, setAuthToken] = useState('');
  const [settings, setSettings] = useState<AuthContextSettings>({});
  const tokenRef = useRef(authToken);

  useEffect(() => {
    if (authToken) tokenRef.current = authToken;
  }, [authToken]);

  const client = useMemo(() => new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (networkError) {
          showToast({ message: t('shared:validationError.sessionTimeout'), severity: 'error' });
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({
            message, locations, path, extensions,
          }) => {
            // eslint-disable-next-line max-len, no-console
            console.log(`[GraphQL error]: Code: ${extensions?.code}, Message: ${message}, Operation: ${JSON.stringify(operation.query)}, Variables: ${JSON.stringify(operation.variables)}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          });

          if (!operation.variables.skipErrorHandler && graphQLErrors.length > 0 && (graphQLErrors[0].message || operation.variables.errorMessage)) {
            const readableData: any = graphQLErrors[0].extensions?.readableData;
            let uniqueFields: any[] = [];
            if (readableData?.fields) {
              uniqueFields = readableData.fields.filter((f: any) => Object.prototype.hasOwnProperty.call(f, 'kind') && f.kind === 'unique');
            }
            if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.code === 11000) {
              showToast({ message: `${t('shared:validationError.unique')} ${Object.keys(readableData?.keyValue)[0]}.`, severity: 'error' });
            } else if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.fields && uniqueFields.length > 0) {
              showToast({ message: `${t('shared:validationError.unique')} ${uniqueFields.map((a) => a.path).join(', ').replace(/, ([^,]*)$/, ' or $1')}.`, severity: 'error' });
            } else {
              showToast({ message: operation.variables.errorMessage ?? graphQLErrors[0].message, severity: 'error' });
            }
          }
        }
      }),
      setContext((_, { headers }) => ({
        headers: { ...headers, ...(tokenRef.current && { authorization: `Bearer ${tokenRef.current}` }) },
      })).concat(createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_CLIENT })),
    ]),
  }), [showToast, t]);

  const appLogout = async () => {
    await auth0Logout(settings?.auth0RedirectUri);
    if (settings?.clientHostedLoginRedirectUri && settings?.clientHostedLoginURL) {
      await clientHostedLogout(settings?.clientHostedLoginURL, settings?.clientHostedLoginRedirectUri);
    }
    if (client) {
      await client.clearStore();
    }
    if (window && window.localStorage) {
      window.localStorage.clear();
    }
    tokenRef.current = '';
    setResetKey((prevKey) => prevKey + 1); // Increment the key to force re-mount
  };

  return (
    <AuthContext.Provider value={{
      authToken, setAuthToken, appLogout, settings, setSettings,
    }}>
      <ApolloProvider client={client}>
        <div key={resetKey}>
          {children}
        </div>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default OvApolloProvider;
