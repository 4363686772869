// import { useState } from 'react';
import { Box, Typography } from '../../../1-primative';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../assets/i18n/config';

const Step = ({ step, workflowCompletion }: { step: any, workflowCompletion: any }) => {
  const { sys } = useThemeTokens();
  const isActive = step?.id === workflowCompletion.currentStep.id;
  const currentActiveStepIndex = workflowCompletion?.workflow?.steps?.findIndex((s: any) => s?.id === workflowCompletion?.currentStep?.id) || 0;
  const currentStepIndex = workflowCompletion?.workflow?.steps?.findIndex((s: any) => s?.id === step?.id) || 0;
  const currentSubStepIndex = workflowCompletion?.currentStep?.subSteps?.findIndex((subStep: any) => subStep?.id === workflowCompletion?.currentSubStep?.id) || 0;
  const completionPercentage = (currentSubStepIndex) / step.subSteps.length;

  return (
    <>
      <div
        style={{
          width: '6px',
          height: '6px',
          marginRight: '8px',
          borderRadius: sys.borderRadius.round,
          backgroundColor: currentStepIndex < currentActiveStepIndex ? sys.color.primary : sys.color.primaryVariantHover,
          flex: isActive ? 1 : undefined,
          transition: 'all 0.5s ease-in-out',
        }}
      >
        { isActive && !workflowCompletion.workflow.complex && (
          <div
            style={{
              height: '100%',
              width: `${completionPercentage * 100}%`,
              backgroundColor: sys.color.primary,
              borderRadius: sys.borderRadius.round,
              transition: 'width 0.5s ease-in-out',
            }}
          >
          </div>
        )}
      </div>
    </>
  );
};
export const StepIndicator = ({ workflowCompletion }: { workflowCompletion: any }) => {
  const currentStepIndex = workflowCompletion?.workflow?.steps?.findIndex((s: any) => s?.id === workflowCompletion?.currentStep?.id) || 0;

  return (
    <Box display='flex' flexDirection='row' sx={{ width: '275px' }} alignItems='end' position='relative' mb={4}>
      <Typography variant='labelSmall' sx={{
        position: 'absolute',
        top: '-26px',
        left: `${currentStepIndex * 14}px`,
        transition: 'all 0.5s ease-in-out',
        whiteSpace: 'nowrap',
      }}>
        {translateBackend(workflowCompletion?.currentStep?.name || '')}
      </Typography>
      {workflowCompletion?.workflow?.steps?.map((step: any) => (
        <Step step={step} workflowCompletion={workflowCompletion} key={step.id} />
      ))}
    </Box>
  );
};
