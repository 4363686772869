import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  logEvent, initializeAnalytics, getAnalytics,
} from 'firebase/analytics';

export type FirebaseConfigType = {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string,
};

const apps: FirebaseApp[] = [];

const sendAnalyticsEvent = async (eventName: string, meta?: any, providerId?: string): Promise<void> => {
  const foundApp: FirebaseApp | undefined = apps.find((app) => app.name === providerId);
  if (foundApp) {
    logEvent(
      getAnalytics(foundApp),
      eventName,
      meta,
    );
  }
};

const initFirebase = ({ config, providerId }: { config: FirebaseConfigType, providerId?: string }): void => {
  const app: FirebaseApp = initializeApp(config, providerId);
  initializeAnalytics(app, { config: { cookie_flags: 'max-age=7200;secure;samesite=none' } });
  apps.push(app);
};

export { initFirebase, sendAnalyticsEvent };
