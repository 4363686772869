import { useTranslation } from 'react-i18next';
import { RelationshipInformation as ConfigRelationshipInformation } from '../workflowCompletion/subSteps';
import { Card, CardContent } from '../../2-component';
import { Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';

export const RelationshipInformation = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation('client');

  return (
    <>
      <Typography variant='headingSmall' mb={2}>
        {options.customTitle ? translateBackend(options.customTitle) : t('relationshipInformation.title')}
      </Typography>
      <Card>
        <CardContent>
          <ConfigRelationshipInformation userId={objectId} options={options} stepLoading={false} grid updateMode />
        </CardContent>
      </Card>
    </>
  );
};
