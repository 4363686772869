import {
  Button, FormControlLabel, FormGroup, ListItem, MenuItem, Switch, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  goalRiskLevels,
  GoalTimeHorizons,
  goalTypes,
  timeHorizons,
  GOAL_TRADING_PROCESS_LIST,
} from 'interfaces';
import AmountField from '../../../inputs/amountField';
import { StepProps } from '../wizard';

export function BasicsStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('createGoalWizard');
  const disabled = !(state.type && state.timeHorizon && state.targetAmountCents && state.riskQuestion1 && state.name);
  let { displayName } = context;

  if (context.forObject === 'CLIENT_GROUP') {
    if (context.clientGroupType === 'BUSINESS') {
      displayName += ` ${t('basics.corporation')}`;
    }
    if (context.clientGroupType === 'HOUSEHOLD') {
      displayName += ` ${t('basics.household')}`;
    }
  }

  return (
    <>
      <ListItem>
        <Typography variant='wizardTitle'>{t('basics.createGoalFor', { whom: displayName })}</Typography>
      </ListItem>
      <ListItem>
        <TextField required select label={t('client:goalTypes')} fullWidth value={state.type} onChange={(e) => setState({ ...state, type: e.target.value })}>
          {goalTypes.map((x: string) => (
            <MenuItem key={x} value={x}>{t(`client:goalType.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem>
        <TextField required label={t('client:goalName')} fullWidth value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} />
      </ListItem>
      <ListItem>
        <TextField required select label={t('client:timeHorizon')} fullWidth value={state.timeHorizon} onChange={(e) => setState({ ...state, timeHorizon: e.target.value })}>
          {timeHorizons.filter((a) => (a !== GoalTimeHorizons.VERY_LONG_TERM)).map((x: string) => (
            <MenuItem key={x} value={x}>{t(`client:timeHorizonOptions.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem>
        <AmountField
          label={t('client:targetAmount')}
          fullWidth
          data-testid="targetAmount"
          value={state.targetAmountCents ?? 0}
          onChange={(e) => setState({ ...state, targetAmountCents: Math.round(parseFloat(e.target.value) * 100) })}
        />
      </ListItem>
      <ListItem>
        <TextField required select label={t('client:details.riskQuestion1')} fullWidth value={state.riskQuestion1} onChange={(e) => setState({ ...state, riskQuestion1: e.target.value })}>
          {goalRiskLevels.map((x: string) => (
            <MenuItem key={x} value={x}>{t(`client:edit.riskQuestion1Options.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>

      {context.allowPortfolioPerGoal && (
        <ListItem>
          <FormGroup>
            <FormControlLabel
              control={<Switch
                checked={!!state.applyPortfolio}
                onChange={(e) => setState({ ...state, applyPortfolio: e.target.checked })}
              />}
              label={t('basics.applyPortfolioAtGoal')}
            />
          </FormGroup>
        </ListItem>
      )}

      { state.applyPortfolio && (
        <ListItem>
          <TextField
            select
            fullWidth
            value={state?.tradingProcess}
            label={t('tradingProcess')}
            onChange={(e) => setState({ ...state, tradingProcess: e.target.value })}
          >
            {GOAL_TRADING_PROCESS_LIST.map((x: any) => (
              <MenuItem key={x.value} value={x.value}>
                {x.name}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
      )}

      <ListItem sx={{ flex: 1, alignItems: 'end', marginBottom: 1 }}>
        <Button disabled={disabled} fullWidth variant='contained' sx={{ textTransform: 'none' }}
          onClick={() => continueTo(state.applyPortfolio ? 'PICK_PORTFOLIO' : 'SUMMARY')}
          data-testid="basics-step-continue-btn"
        >
          {t('continue')}
        </Button>
      </ListItem>
    </>
  );
}
