/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, TextField, MenuItem, Button,
  DialogTitle, Dialog, DialogContent, Link as MuiLink, Checkbox,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEqual, remove, union } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Date } from '../../../components/misc/date/date';
import { formatMoneyValue, generateClientNameString } from '../../../util';
import { TransactionTypes } from './transactionTypes';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import DownloadReconciliation from './downloadReconciliation';

export const FETCH_CUSTODIAN_ACTIVITIES_QUERY = `query fetchCustodianActivities($input: FetchCustodianActivitiesInput!) {
  fetchCustodianActivities(input: $input) {
    custodianActivities {
      id
      processDate
      symbol
      amountValueCents
      quantity
      priceCents
      securityType
      state
      transactionType
      transactionId
      transactionDesc
      processDate
      effectiveDate
      settleDate
      custodianAccountNumber
      clientName
      transactionId
      subTransactionId
      cusip
      transactionDesc
      transactionType
      commission
      accruedInterest
      securityType
      currency
      account {
        type
        user {
          id
          firstName
          lastName
          entityName
        }
        organization {
          id
          custodian {
            id
          }
        }
      }
    }
    totalCount
  }
}`;

const FETCH_CUSTODIAN_ACTIVITIES = gql`${FETCH_CUSTODIAN_ACTIVITIES_QUERY}`;

const RECONCILE_FLAG = gql`
  mutation reconcileCustodianActivity($custodianActivityId: ObjectID!) {
    reconcileCustodianActivity(custodianActivityId: $custodianActivityId) {
      custodianActivity {
        id
      }
    }
  }
`;

const FORCE_RECONCILE_FLAG = gql`
  mutation forceReconcileCustodianActivity($custodianActivityId: ObjectID!) {
    forceReconcileCustodianActivity(custodianActivityId: $custodianActivityId) {
      custodianActivity {
        id
      }
    }
  }
`;

const Activities = () => {
  const pageSize = 15;
  const { t } = useTranslation(['reconciliation']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [activeCustodianActivity, setActiveCustodianActivity] = useState<any | null>(null);
  const [custodianActivityState, setCustodianActivityState] = useState('CREATED');
  const [selectedTransactionType, setSelectedTransactionType] = useState('ANY');
  const [reconcileCustodianActivity, reconcileLoadingOptions] = useMutation(RECONCILE_FLAG);
  const [forceReconcileCustodianActivity, forceReconcileOptions] = useMutation(FORCE_RECONCILE_FLAG);
  const [selected, setSelected] = useState<string[]>([]);
  const [symbol, setSymbol] = usePageState<string | undefined>(undefined, 'symbol');
  const [custodianAccountNumber, setCustodianAccountNumber] = usePageState<string | undefined>(undefined, 'CAN');
  const [skip, setSkip] = useState(false);

  const queryFilter = {
    state: custodianActivityState,
    transactionType: selectedTransactionType === 'ANY' ? undefined : selectedTransactionType,
    organizationId: activeOrganization.id,
    symbol,
    custodianAccountNumber,
  };

  const { loading, error, data, refetch, previousData } = useQuery(FETCH_CUSTODIAN_ACTIVITIES, {
    errorPolicy: 'all',
    variables: {
      input: {
        filter: { ...queryFilter },
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    skip,
  });

  const reconcileMultipleCustodianActivities = async (type: 'RECONCILE' | 'FORCE_RECONCILE') => {
    for (const id of selected) {
      if (type === 'RECONCILE') {
        await reconcileCustodianActivity({
          variables: {
            custodianActivityId: id,
          },
        });
      } else {
        await forceReconcileCustodianActivity({
          variables: {
            custodianActivityId: id,
          },
        });
      }
    }
    setSelected([]);
    refetch();
  };

  useEffect(() => function cleanupOnUnmount() {
    setSymbol(undefined);
    setCustodianAccountNumber(undefined);
  }, []);

  const debounceQueryRequest = useCallback(
    debounce(() => {
      /**
       * This is workaround as several issue with a component re-rendering were found.
       *
       * For some reason the dobounce's reference is getting lost on the component re-rendering
       * when any of the vars present in the useQuery gets updated. The useQuery (useLazyQuery) has some
       * weird hooks behavior.
       *
       * @see https://github.com/apollographql/apollo-client/issues/7484
       * @see https://github.com/apollographql/apollo-client/issues/5912
       * @see https://github.com/apollographql/react-apollo/issues/3088
       */
      setSkip(false);
    }, 500),
    [],
  );

  const handleChange = (value: any, setter: any) => {
    setSkip(true);
    setter(value || undefined);
    debounceQueryRequest();
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end' spacing={2} sx={{ p: '0 8px' }}>
            <Grid item xs={3}>
              <Button variant='outlined' disabled={isEqual(selected, []) || reconcileLoadingOptions.loading} sx={{ margin: '2px' }} onClick={async () => {
                reconcileMultipleCustodianActivities('RECONCILE');
              }}>{t('flagState.ALL_RECONCILE')}</Button>

              <Button variant='outlined' disabled={isEqual(selected, []) || forceReconcileOptions.loading} sx={{ margin: '2px' }} onClick={async () => {
                reconcileMultipleCustodianActivities('FORCE_RECONCILE');
              }}>{t('flagState.ALL_FORCE_RECONCILE')}</Button>
            </Grid>

            <Grid item xs={4} display='flex'>
              <TextField fullWidth select value={selectedTransactionType} onChange={(e) => setSelectedTransactionType(e.target.value)} size='small' label={t('transactionType')}>
                {
                  TransactionTypes.map((transactionType: { key: string, value: string }) => (
                    <MenuItem key={transactionType.key} value={transactionType.key}>{transactionType.value}</MenuItem>
                  ))
                }
              </TextField>

              <TextField fullWidth select value={custodianActivityState} sx={{ ml: 1 }} onChange={(e) => setCustodianActivityState(e.target.value)} size='small' label={t('state')}>
                <MenuItem value={'CREATED'}>{t('activityState.CREATED')}</MenuItem>
                <MenuItem value={'RECONCILED'}>{t('activityState.RECONCILED')}</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                value={symbol}
                onChange={(e) => handleChange(e.target.value, setSymbol)}
                size='small'
                label={t('symbol')}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                value={custodianAccountNumber}
                onChange={(e) => handleChange(e.target.value, setCustodianAccountNumber)}
                size='small'
                label={t('custodianAccountNumber')}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Grid>

            {permissions.includes('read:api_exports') && (
              <Grid item xs={1} sx={{ textAlign: 'right' }}>
                <DownloadReconciliation tab='activities' queryFilter={queryFilter} />
              </Grid>
            )}
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isEqual(selected, (data?.fetchCustodianActivities?.custodianActivities || []).map((x: any) => x.id))}
                    onChange={((e: any) => {
                      if (e.target.checked) {
                        setSelected((data?.fetchCustodianActivities?.custodianActivities || []).map((x: any) => x.id));
                      } else {
                        setSelected([]);
                      }
                    })}
                  />
                </TableCell>
                <TableCell><Typography variant='overline'>{t('table.client')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.date')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.type')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.amount')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.accountType')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.ticker')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.quantity')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.price')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('table.actions')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchCustodianActivities?.custodianActivities?.map((custodianActivity: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setOpen(true);
                    setActiveCustodianActivity(custodianActivity);
                  }}
                  key={custodianActivity.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  selected={activeCustodianActivity?.id === custodianActivity.id}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selected.includes(custodianActivity.id)}
                      onClick={(e: any) => e.stopPropagation()}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected(union(selected, [custodianActivity.id]));
                        } else {
                          setSelected(remove((x: any) => x === custodianActivity.id, selected));
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiLink component={Link} to={`/clients/${custodianActivity.account?.user?.id}`}>
                    {generateClientNameString(custodianActivity.account?.user, false, true)}
                    </MuiLink>
                  </TableCell>
                  <TableCell><Date variant='subtitle2' date={custodianActivity.processDate} /></TableCell>
                  <TableCell><Chip size='small' label={custodianActivity.transactionType} /></TableCell>
                  <TableCell>{formatMoneyValue(custodianActivity.amountValueCents)}</TableCell>
                  <TableCell><Chip size='small' label={custodianActivity.account?.type} /></TableCell>
                  <TableCell>{custodianActivity.symbol}</TableCell>
                  <TableCell>{custodianActivity.quantity}</TableCell>
                  <TableCell>{formatMoneyValue(custodianActivity.priceCents)}</TableCell>
                  <TableCell><Chip size='small' label={custodianActivity.state} color={custodianActivity.state === 'CREATED' ? 'error' : 'success'} /></TableCell>
                  <TableCell align='right'>
                    <Grid container columnSpacing={1} justifyContent='flex-end'>
                      <Grid item>
                        <Button disabled={custodianActivity.state !== 'CREATED'} variant='outlined' size='small' onClick={async (e) => {
                          e.stopPropagation();
                          await reconcileCustodianActivity({
                            variables: {
                              custodianActivityId: custodianActivity.id,
                            },
                          });
                          refetch();
                        }}>{t('activityState.RECONCILE')}</Button>
                      </Grid>
                      <Grid item>
                        <Button disabled={custodianActivity.state !== 'CREATED'} variant='outlined' size='small' onClick={async (e) => {
                          e.stopPropagation();
                          await forceReconcileCustodianActivity({
                            variables: {
                              custodianActivityId: custodianActivity.id,
                            },
                          });
                          refetch();
                        }}>{t('activityState.FORCE_RECONCILE')}</Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchCustodianActivities?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{activeCustodianActivity?.message}</DialogTitle>
        <DialogContent>
          {activeCustodianActivity?.details?.account?.user && (
            <Button variant='outlined' sx={{ m: 1 }} fullWidth component={Link} target='_blank' to={`/clients/${activeCustodianActivity.details.account?.user}`}>
              {`${t('goTo')} ${activeCustodianActivity.details.clientName}`}
            </Button>
          )}
          <pre>{JSON.stringify(activeCustodianActivity, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Activities;
