import CORE_SINGLE from './core_single.svg';
import DISRUPTION_SINGLE from './disruption_single.svg';
import SOCIAL_SINGLE from './social_single.svg';
import ALPHA_SINGLE from './alpha_single.svg';
import PREMIUM_SINGLE from './premium_single.svg';
import DEFAULT_SINGLE from './default_single.svg';
import DELIVERY_SINGLE from './delivery_single.svg';
import HALAL_SINGLE from './halal_single.svg';
import POWERFUL_SINGLE from './powerful_single.svg';

const themeIcons = [
  {
    key: 'CORE',
    icon: CORE_SINGLE,

  },
  {
    key: 'DISRUPTION',
    icon: DISRUPTION_SINGLE,
  },
  {
    key: 'SOCIAL',
    icon: SOCIAL_SINGLE,
  },
  {
    key: 'ALPHA',
    icon: ALPHA_SINGLE,

  },
  {
    key: 'PREMIUM',
    icon: PREMIUM_SINGLE,
  },
  {
    key: 'DELIVERY',
    icon: DELIVERY_SINGLE,
  },
  {
    key: 'HALAL',
    icon: HALAL_SINGLE,
  },
  {
    key: 'POWERFUL',
    icon: POWERFUL_SINGLE,
  },
  {
    key: 'DEFAULT',
    icon: DEFAULT_SINGLE,
  },
];

export default themeIcons;
