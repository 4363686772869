/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useContext } from 'react';
import { getCountryName } from 'ovComponents/resources';
import { useNavigate } from 'react-router-dom';
import NewOrganization from './newOrganization';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';

export const FETCH_ORGANIZATIONS = gql`
  query fetchOrganizations($input: FetchOrganizationsInput!) {
    fetchOrganizations(input: $input) {
      totalCount
      organizations {
        id
        name
        subdomain
        faviconLink
        browserTabTitle
        parent {
          id
          name
        }
        localization { id countries }
        jurisdictions { all only }
      }
    }
  }
`;

const Organizations = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['orgSettings']);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_ORGANIZATIONS, {
    variables: {
      input: {
        filter: {
          childrenFor: activeOrganization.id,
        },
        pagination: {
          sortField: 'name', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useEffect(() => function cleanupOnUnmount() { setPage(1); }, []);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          {permissions.includes('write:organizations') && (
            <NewOrganization afterCreate={refetch} />
          )}
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('organizationTable.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('organizationTable.subdomain')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('organizationTable.countriesJurisdictions')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('organizationTable.parent')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchOrganizations?.organizations?.map((organization: any) => (
                <TableRow
                  key={organization.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  hover
                  onClick={() => {
                    navigate(`orgDetails/${organization.id}`);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {organization.name}
                  </TableCell>
                  <TableCell>{organization.subdomain}</TableCell>
                  <TableCell>
                    {
                      organization.localization?.countries && (<>
                        {organization.localization.countries.map(getCountryName).join(', ')}
                        <br />
                      </>
                      )
                    }
                    {
                      organization.jurisdictions?.all === false && (<>
                        <i>
                          {t('shared:only')}:&nbsp;
                          {organization.jurisdictions.only.map((code: string) => t(`geoNames:${code}`)).join(', ')}
                        </i>
                      </>)
                    }
                  </TableCell>
                  <TableCell align="right">{organization.parent?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchOrganizations?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default Organizations;
