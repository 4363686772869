import { gql } from '@apollo/client';
import { OrganizationUserAccessTypes } from 'interfaces';

export const FETCH_ORG_USERS_WITH_ACCESS_TO_ENTITY = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        organization { id }
        firstName
        lastName
        entityName
        organization { name }
        accessibleBy {
          organizationUser { id firstName lastName email }
          relation
        }
      }
    }
  }
`;

export const ADD_ENTITY_TO_ORG_USER = gql`
  mutation addEntityToOrganizationUser($input: AddEntityToOrganizationUserInput!) {
    addEntityToOrganizationUser(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

export const REMOVE_ENTITY_FROM_ORG_USER = gql`
  mutation removeEntityFromOrganizationUser($input: RemoveEntityFromOrganizationUserInput!) {
    removeEntityFromOrganizationUser(input: $input) {
      organizationUser {
        id
      }
    }
  }
`;

const MAX_SEARCH_RESULTS = 12;
export const SEARCH_ORG_USERS = gql`
  query searchOrgUsers($query: String!, $childrenForOrganizationId: ObjectID!, $parentForOrganizationId: ObjectID!) {
    fetchOrganizationUsers(input: {
      filter: { searchText: $query, childrenForOrganizationId: $childrenForOrganizationId, parentForOrganizationId: $parentForOrganizationId, accessType: ${OrganizationUserAccessTypes.ENTITY} }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      organizationUsers {
        id
        firstName
        lastName
        email
        role { id translatedName { en } }
      }
    }
  }
`;
