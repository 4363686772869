import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { formatDecimalNumber, generateClientNameString } from '../../../../../util';
import { Skeleton } from '../../../../1-primative';
import {
  Table, TableBody, TableCell, TableHeadCell, TableRow, TableTotalCell,
} from '../../../../2-component';
import { getSubAccountName } from '../../../../../interfaces/subAccount';
import { GroupOn } from '../rateOfReturns';
import { PageConfigurationTypes } from '../../../../../interfaces/pageConfiguration';
import { useClientReportContext } from '../../../../../providers/clientReportContextProvider';
import { getGoalName } from '../../../../../interfaces';

export const RateOfReturnsTable = ({
  statsLoading,
  statsData,
  objectPath,
  returnType,
  groupOn,
  objectType,
  objectCreatedAt,
  tableMap,
}: {
  statsLoading: boolean;
  statsData: any;
  objectPath: any;
  returnType: string;
  groupOn: string;
  objectType: string;
  objectCreatedAt: Date;
  tableMap: any;
}) => {
  const { t } = useTranslation('pageConfiguration');
  const objectIsArray = Array.isArray(objectPath);
  const { timePeriod } = useClientReportContext();

  const getPortfolioName = (object: any) => {
    switch (groupOn) {
      case GroupOn.USER:
        return generateClientNameString(object);
      case GroupOn.HOUSEHOLD:
        return `${object?.name}`;
      case GroupOn.ACCOUNT:
        return t(`accountTypes:${object?.type}`);
      case GroupOn.GOAL:
        return getGoalName(object);
      case GroupOn.SUB_ACCOUNT:
        return getSubAccountName(object);
      default:
        return '';
    }
  };

  const groupOnSelf = () => {
    switch (groupOn) {
      case GroupOn.USER:
        return objectType === (PageConfigurationTypes.INDIVIDUAL || PageConfigurationTypes.NON_INDIVIDUAL);
      case GroupOn.HOUSEHOLD:
        return objectType === PageConfigurationTypes.HOUSEHOLD;
      case GroupOn.ACCOUNT:
        return objectType === PageConfigurationTypes.ACCOUNT;
      case GroupOn.GOAL:
        return objectType === PageConfigurationTypes.GOAL;
      case GroupOn.SUB_ACCOUNT:
        return objectType === PageConfigurationTypes.SUB_ACCOUNT;
      default:
        return false;
    }
  };

  const totalRowPath = () => {
    switch (objectType) {
      case PageConfigurationTypes.INDIVIDUAL:
      case PageConfigurationTypes.NON_INDIVIDUAL:
        return statsData?.fetchUser?.user;
      case PageConfigurationTypes.HOUSEHOLD:
        return statsData?.fetchClientGroup?.clientGroup;
      case PageConfigurationTypes.ACCOUNT:
        return statsData?.fetchAccount?.account;
      case PageConfigurationTypes.GOAL:
        return statsData?.fetchGoal?.goal;
      case PageConfigurationTypes.SUB_ACCOUNT:
        return statsData?.fetchSubAccount?.subAccount;
      default:
        return '';
    }
  };

  const checkDates = (freq: string, value: string) => {
    switch (freq) {
      case 'oneMonth':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).date(1)) {
          return `${value}%`;
        }
        return '-';
      case 'threeMonth':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).subtract(2, 'months').date(1)) {
          return `${value}%`;
        }
        return '-';
      case 'sixMonth':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).subtract(5, 'months').date(1)) {
          return `${value}%`;
        }
        return '-';
      case 'oneYear':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).subtract(1, 'year').date(1)) {
          return `${value}%`;
        }
        return '-';
      case 'threeYear':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).subtract(3, 'year').date(1)) {
          return `${value}%`;
        }
        return '-';
      case 'fiveYear':
        if (dayjs(objectCreatedAt) < dayjs(timePeriod.endDate).subtract(5, 'year').date(1)) {
          return `${value}%`;
        }
        return '-';
      default:
        return `${value}%`;
    }
  };

  return (
    <Table>
      <TableBody>
        <TableRow>
          {!groupOnSelf() && <TableHeadCell isFirst>{t(`rateOfReturns.groupOn.${groupOn}`)}</TableHeadCell>}
          {tableMap.map(({ freq }: any) => (
            <TableHeadCell right key={freq}>
              {t(`rateOfReturns.frequency.${freq}`)}
            </TableHeadCell>
          ))}
        </TableRow>
        {statsLoading // Loading state
          && [...Array(4)].map((x, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton width='100%' height='16px' />
              </TableCell>
              {groupOnSelf() ? (tableMap.slice(0, -1).map((opt: any, idx: number) => (
                <TableCell key={idx}>
                  <Skeleton width='100%' height='16px' />
                </TableCell>
              ))) : (
                (tableMap.map((opt:any, idx: number) => (
                  <TableCell key={idx}>
                  <Skeleton width='100%' height='16px' />
                </TableCell>
                )))
              )}
            </TableRow>
          ))}
        {objectIsArray // array of items
          ? objectPath.map((object, index) => (
              <TableRow key={index}>
                <TableCell isFirst>{getPortfolioName(object)}</TableCell>
                {tableMap.map(({ freq }: any, idx: number) => {
                  const objectParse = parseFloat(object?.[returnType]?.[freq]) * 100;
                  const decimalParse = formatDecimalNumber(objectParse, 2);
                  return <TableCell number key={freq}>{`${checkDates(freq, decimalParse)}`}</TableCell>;
                })}
              </TableRow>
          ))
          : !statsLoading && ( // single line item non array
              <TableRow>
                {!groupOnSelf() && <TableCell>{getPortfolioName(objectPath)}</TableCell>}
                {tableMap.map(({ freq }: any) => {
                  const objectParse = parseFloat(objectPath?.[returnType]?.[freq]) * 100;
                  const decimalParse = formatDecimalNumber(objectParse, 2);
                  return <TableCell number key={freq}>{`${checkDates(freq, decimalParse)}`}</TableCell>;
                })}
              </TableRow>
          )}
        {objectIsArray
          && !statsLoading && ( // Total footer
            <TableRow>
              <TableTotalCell isFirst bold>
                {t('rateOfReturns.total')}
              </TableTotalCell>
              {tableMap.map(({ freq }: any) => {
                const objectParse = parseFloat(totalRowPath()?.[returnType]?.[freq]) * 100;
                const decimalParse = formatDecimalNumber(objectParse, 2);
                return <TableTotalCell bold number key={freq}>{`${decimalParse}%`}</TableTotalCell>;
              })}
            </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RateOfReturnsTable;
