import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  FormControl,
  SxProps,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SyntheticEvent } from 'react';
import { Typography, Box } from '../../1-primative';
import { useRadioTokens } from './radio.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import InfoDialog from '../infoDialog/infoDialog';

interface RadioFunctionalProps {
  value: string | number,
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
  disabled?: boolean
}
interface RadioVisualProps {
  label: string
  subLabel?: string
  size?: 'medium' | 'small',
  testId?: string,
}

export const Radio = (props: RadioFunctionalProps & RadioVisualProps) => {
  const tokens = useThemeTokens(useRadioTokens());
  const { comp } = tokens;

  const fontSizes = {
    small: '14px',
    medium: '16px',
  };
  const lineHeights = {
    small: '20px',
    medium: '24px',
  };
  return (
    <>
      <FormControlLabel
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        label={props.label}
        disableTypography
        sx={{
          fontFamily: comp.radio.fontFamily || 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
          fontSize: fontSizes[props.size ?? 'medium'],
          lineHeight: lineHeights[props.size ?? 'medium'],
          fontWeight: 400,
          color: !props.disabled ? comp.radio.defaultColor : comp.radio.disabledColor,
        }} control={<MuiRadio
          data-testid={props.testId}
          size={props.size}
          inputProps={{
            'data-testid': `${props.testId ?? 'radio'}-input`,
          } as React.InputHTMLAttributes<HTMLInputElement>}
          sx={{
            '&.MuiRadio-root': {
              color: comp.radio.defaultColor,
              '&.Mui-checked': { color: comp.radio.activeColor },
              '&.Mui-disabled': { color: comp.radio.disabledColor },
            },
          }} />}
      />
      { props.subLabel && <Typography variant='bodyMedium' onClick={(e: any) => props.onChange && props.onChange(e, true)} sx={{
        color: comp.radio.subLabelColor, ml: 4, mt: -1, cursor: 'pointer', mb: 1,
      }}>{props.subLabel}</Typography> }
    </>
  );
};

interface RadioGroupProps {
  label?: string,
  placeholder?: string,
  helpText?: string,
  error?: boolean,
  errorText?: string,
  success?: boolean,
  successText?: string,
  fullWidth?: boolean,
  disabled?: boolean,
  sx?: SxProps,
  children?: React.ReactNode[] | React.ReactNode,
  value?: string | number,
  onChange?: any,
  onFocus?: any,
  infoTooltip?: string,
  testId?: string,
}

export const RadioGroup = ({
  label, helpText, error = false, errorText, success = false,
  successText, fullWidth, disabled = false, sx, onChange, value,
  children, infoTooltip, testId,
}: RadioGroupProps) => {
  const tokens = useThemeTokens(useRadioTokens());
  const { comp } = tokens;

  const helpTextFunc = (isError: boolean, isSuccess: boolean) => {
    if (isError) {
      return errorText || helpText;
    }

    if (isSuccess) {
      return successText || helpText;
    }

    return helpText;
  };

  const helpIcon = (isError: boolean, isSuccess: boolean) => {
    if (isError) {
      return (<ErrorOutlineIcon sx={{ mr: '4px', color: comp.radio.errorColor, fontSize: '12px' }} />);
    }
    if (isSuccess) {
      return (<CheckCircleIcon sx={{ mr: '4px', color: comp.radio.activeColor, fontSize: '12px' }} />);
    }
    return (<HelpOutlineIcon sx={{ mr: '4px', color: comp.radio.disabledColor, fontSize: '12px' }} />);
  };

  return (
    <FormControl data-testid={testId ?? 'selection-tile'} sx={{ ...sx, width: fullWidth ? '100%' : 'default' }}>
      <Box display="flex" flexDirection="row" alignItems={'center'}>
        {label && (
          <Typography variant='labelSmall' sx={{ mb: '4px', color: error ? comp.radio.errorColor : comp.radio.labelColor }}>
            {label}
            {infoTooltip && (<InfoDialog information={infoTooltip} />)}
          </Typography>
        )}
      </Box>
      <MuiRadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={onChange}
        sx={{ paddingBottom: '32px' }}
      >
        {children}
      </MuiRadioGroup>
      {helpTextFunc(error, success) && (
        <Box alignItems='center' display='flex' mt={0.5}>
          {helpIcon(error, success)}
          <Typography variant='bodySmall' sx={{ color: error ? comp.radio.errorColor : success ? comp.radio.activeColor : comp.radio.disabledColor }}>{helpTextFunc(error, success) || ''}</Typography>
        </Box>
      )}
    </FormControl>
  );
};
