import { gql } from '@apollo/client';

const queryArgs = (options: any) => `
  ${options.oneMonth ? '$oneMonthStart: Date,' : ''}
  ${options.threeMonth ? '$threeMonthStart: Date,' : ''}
  ${options.sixMonth ? '$sixMonthStart: Date,' : ''}
  ${options.oneYear ? '$oneYearStart: Date,' : ''}
  ${options.threeYear ? '$threeYearStart: Date,' : ''}
  ${options.fiveYear ? '$fiveYearStart: Date,' : ''}
  ${options.yearToDate ? '$ytdStart: Date,' : ''}
  ${options.sinceInception ? '$sinceInceptionStart: Date,' : ''}
  $endDate: Date,
  $mwrr: Boolean!,
  $twrr: Boolean!
 `;

const SUBACCOUNT_STATS_FETCH = (statistics: string, options: any) => gql`
  query fetchSubAccount(
      $id: ObjectID!,
      ${queryArgs(options)}
    ) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        id
        goal { type }
        account { type }
        ${statistics}
      }
    }
  }
  `;

const ACCOUNT_STATS_FETCH = (statistics: string, options: any) => gql`
  query fetchAccount(
      $id: ObjectID!,
      ${queryArgs(options)}
    ) {
    fetchAccount(accountId: $id) {
      account {
        id
        ${statistics}
        subAccounts {
          id
          ${statistics}
          goal { type }
          account { type }
        }
      }
    }
  }
  `;

const GOAL_STATS_FETCH = (statistics: string, options: any) => gql`
query fetchGoal(
    $id: ObjectID!,
    ${queryArgs(options)}
  ) {
  fetchGoal(goalId: $id) {
    goal {
      id
      ${statistics}
      subAccounts {
          id
          ${statistics}
          goal { type }
          account { type }
        }
    }
  }
}
`;

const USER_STATS_FETCH = (statistics: string, options: any) => gql`
query fetchUser(
    $id: ObjectID!,
    ${queryArgs(options)}
  ) {
  fetchUser(userId: $id) {
    user {
      id
      ${statistics}
      goals {
        id
        type
        ${statistics}
      }
      accounts {
        id
        type
        ${statistics}
      }
      subAccounts {
        id
        name
        ${statistics}
        goal { type }
        account { type }
      }
    }
  }
}
`;

const CLIENTGROUP_STATS_FETCH = (statistics: string, options:any) => gql`
query fetchClientGroup(
    $id: ObjectID!,
    ${queryArgs(options)}
  ) {
  fetchClientGroup(clientGroupId: $id) {
    clientGroup {
      id
      name
      ${statistics}
      primaryUser {
        id
        firstName
        lastName
        ${statistics}
      }
      goals {
        id
        type
        ${statistics}
      }
      accounts {
        id
        type
        ${statistics}
      }
      subAccounts {
        id
        name
        ${statistics}
        goal { type }
        account { type }
      }
    }
  }
}
`;

export const chooseStatsQuery = ({ objectType, statistics, options }: { objectType: string, statistics: string, options: any }) => {
  switch (objectType) {
    case 'INDIVIDUAL':
    case 'NON_INDIVIDUAL':
      return USER_STATS_FETCH(statistics, options);
    case 'HOUSEHOLD':
      return CLIENTGROUP_STATS_FETCH(statistics, options);
    case 'ACCOUNT':
      return ACCOUNT_STATS_FETCH(statistics, options);
    case 'GOAL':
      return GOAL_STATS_FETCH(statistics, options);
    case 'SUB_ACCOUNT':
      return SUBACCOUNT_STATS_FETCH(statistics, options);
    default:
      return gql`
        query {
          __typename
        }
      `;
  }
};
