import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Typography, Button, CircularProgress, Table, TableHead,
  TableCell, TableRow, TableBody, TextField, InputAdornment, Grid, Dialog, DialogTitle, DialogContent, IconButton, Chip, styled,
} from '@mui/material';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { round } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { bulkPriceUpdate, limitDecimals } from '../../bulkTrader/components/updateTradesTable';
import { useGlobalToast } from '../../../providers/globalToastProvider';

const DISCREPANCY_THRESHOLD_PCT_PRICE = 3;
const DISCREPANCY_THRESHOLD_PCT_QUANTITY = 0;

const FETCH_REBALANCE = gql`
  query fetchRebalance($rebalanceId: ObjectID!) {
    fetchRebalance(rebalanceId: $rebalanceId) {
      rebalance {
        id
        bulkTrades {
          type
          bulkTrades {
            _id
            expectedPriceCents
            expectedPriceInCurrencyCents
            currency
            total
          }
        }
      }
    }
  }
`;

export type AccountBulkTrade = {
  _id: string;
  expectedPriceCents: number;
  expectedPriceInCurrencyCents: number;
  expectedPriceInCurrency: number;
  currency: string;
  total: number;
  originalPriceInCurrencyCents: number;
  originalPriceInCurrency: number;
  originalTotal: number;
};

const FETCH_ALL_PRODUCTS = gql`
  query fetchFinancialProducts($input: FetchFinancialProductsInput!) {
    fetchFinancialProducts(input: $input) {
      financialProducts {
        id
        ticker
      }
    }
  }
`;

const UPDATE_PRICE_AND_QUANTITY = gql`
  mutation updateQuantityAndPrice($input: UpdatePriceAndQuantityInput!){
    updatePriceAndQuantity(input: $input) {
      success
    }
  }
`;

const UpdateTradesTable = ({
  type,
  rebalanceId,
  setDiscrepancyWarning,
}: {
  type: 'BUY' | 'SELL',
  rebalanceId: string,
  setDiscrepancyWarning: any
}) => {
  const { t } = useTranslation(['rebalance', 'bulkTrader']);
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();

  const [bulkTrades, setBulkTrades] = useState<AccountBulkTrade[]>([]);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  const { data, loading } = useQuery(FETCH_REBALANCE, {
    variables: {
      rebalanceId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: productsData } = useQuery(FETCH_ALL_PRODUCTS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 200 },
      },
    },
  });

  const [updatePriceAndQuantity, { loading: updateLoading }] = useMutation(UPDATE_PRICE_AND_QUANTITY);

  useEffect(() => {
    if (data) {
      setBulkTrades(
        data.fetchRebalance.rebalance.bulkTrades
          .find((x: any) => x.type === type)
          .bulkTrades.map((bt: AccountBulkTrade) => {
            const expectedPriceInCurrencyCents = bt.expectedPriceInCurrencyCents || bt.expectedPriceCents;
            const expectedPriceInCurrency = round((expectedPriceInCurrencyCents ?? 0) / 100, 6);

            const originalPriceInCurrencyCents = expectedPriceInCurrencyCents;
            const originalPriceInCurrency = expectedPriceInCurrency;

            return {
              ...bt,
              currency: bt.currency || 'CAD',
              originalPriceCents: bt.expectedPriceCents,
              expectedPriceInCurrencyCents,
              expectedPriceInCurrency,
              originalPriceInCurrencyCents,
              originalPriceInCurrency,
              originalTotal: bt.total,
            };
          }),
      );
    }
  }, [data, type]);

  const bulkUpdate = () => {
    bulkPriceUpdate(bulkTrades, content, 'expectedPriceInCurrency', (updatedBulkTrades: any[], isHasErrors: boolean, notFoundSecurities: string[]) => {
      if (isHasErrors) {
        showToast({ message: t('bulkTrader:securitiesWereNotFound', { securities: notFoundSecurities.join(', ') }), severity: 'warning' });
      }

      if (!isHasErrors) {
        setBulkTrades(updatedBulkTrades);

        setOpen(false);
      }
    });
  };

  const percentualDifference = (a: number, b: number): number => {
    try {
      return Math.abs(100 * ((a - b) / b));
    } catch {
      return 0;
    }
  };

  useEffect(() => {
    let numWarnings = 0;
    bulkTrades.forEach((bt) => {
      if (percentualDifference(bt.expectedPriceInCurrency, bt.originalPriceInCurrency) > DISCREPANCY_THRESHOLD_PCT_PRICE) {
        numWarnings++;
      }
      if (percentualDifference(bt.total, bt.originalTotal) > DISCREPANCY_THRESHOLD_PCT_QUANTITY) {
        numWarnings++;
      }
    });
    setDiscrepancyWarning(numWarnings > 0);
  }, [bulkTrades, setDiscrepancyWarning]);

  const DifferenceChip = ({
    newValue, origValue, warningThresholdPct = 0,
  } : {
    newValue: number, origValue: number, warningThresholdPct?: number
  }):JSX.Element => {
    const delta = percentualDifference(newValue, origValue);
    const text = `${(newValue > origValue ? '+' : '-') + delta.toFixed(2)}%`;
    return (
      <Chip label={text} color={delta >= warningThresholdPct ? 'warning' : 'default'} sx={{ marginLeft: '0.5em' }}/>
    );
  };

  const NarrowTableCell = styled(TableCell)({ padding: 0, align: 'right' });

  /**
   * use regex to prevent non numeric values
   * avoiding using "type='number'" on an input
   * @see https://mui.com/material-ui/react-text-field/#type-quot-number-quot
   */
  const updateTradePrice = (value: string, index: number): void => {
    if (!/^(\d+(\.\d*)?|)$/.test(value)) return;

    bulkTrades[index].expectedPriceInCurrency = limitDecimals(value) as any;
    setBulkTrades([...bulkTrades]);
  };

  const sanitizeTradePrice = (value: string, index: number): void => {
    bulkTrades[index].expectedPriceInCurrency = (Number(value.toString().replace(/^0+|(?:\.|(\..*?))0+$/gm, '$1')) as any) || 0;
    setBulkTrades([...bulkTrades]);
  };

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography sx={{ p: 2 }} variant='h6'>{t(`bulk${type}Trades`)}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpen(true)} sx={{ m: 2 }}>
                <BackupTableIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('security')}</Typography></TableCell>
                <TableCell></TableCell>
                <TableCell><Typography variant='overline'>{t('price')}</Typography></TableCell>
                <TableCell></TableCell>
                <TableCell><Typography variant='overline'>{t('quantity')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkTrades.map((line: any, index: number) => (
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">{line._id}</Typography>
                  </TableCell>
                  <NarrowTableCell>
                    { line.expectedPriceInCurrency !== line.originalPriceInCurrency
                      && <Typography color="GrayText">{Number(line.originalPriceInCurrency).toFixed(2)}</Typography>
                    }
                  </NarrowTableCell>
                  <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      value={line.expectedPriceInCurrency}
                      onBlur={(e: any) => sanitizeTradePrice(e.target.value, index)}
                      sx={{ width: '12em' }}
                      onChange={(e: any) => updateTradePrice(e.target.value, index)}
                      size='small'
                      inputMode='decimal'
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{line.currency === 'USD' ? 'US$' : '$'}</InputAdornment>,
                        inputProps: { min: 0, step: 'any' },
                      }}
                    />
                    { line.expectedPriceInCurrency !== line.originalPriceInCurrency
                      && <DifferenceChip
                        newValue = {(line.expectedPriceInCurrency)}
                        origValue = {(line.originalPriceInCurrency)}
                        warningThresholdPct={DISCREPANCY_THRESHOLD_PCT_PRICE}
                      />
                    }
                  </TableCell>
                  <NarrowTableCell>
                    { line.total !== line.originalTotal
                      && <Typography color="GrayText">{line.originalTotal}</Typography>
                    }
                  </NarrowTableCell>
                  <TableCell>
                    <TextField
                      value={line.total}
                      sx={{ width: '9em' }}
                      size='small'
                      type='number'
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onChange={(e: any) => {
                        bulkTrades[index].total = round(parseFloat(e.target.value), 0);
                        setBulkTrades([...bulkTrades]);
                      }}
                    />
                    { line.total !== line.originalTotal
                      && <DifferenceChip
                        newValue = {line.total}
                        origValue = {line.originalTotal}
                        warningThresholdPct={DISCREPANCY_THRESHOLD_PCT_QUANTITY}
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent='flex-end'>
            <Grid item>
                <Button
                  variant='contained'
                  sx={{ m: 2 }}
                  disabled={updateLoading}
                  onClick={() => updatePriceAndQuantity({
                    variables: {
                      input: {
                        rebalanceId,
                        type,
                        bulkTrades: bulkTrades.map((bulkTrade: any) => {
                          const financialProductId = productsData.fetchFinancialProducts.financialProducts.find((x: any) => x.ticker === bulkTrade._id).id;
                          return {
                            actualPriceCents: Number(round(bulkTrade.expectedPriceInCurrency * 100, 6)),
                            totalQuantity: bulkTrade.total,
                            financialProductId,
                          };
                        }),
                      },
                    },
                  })}
                >{t('save')}</Button>
            </Grid>
          </Grid>
        </>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle>{t('pasteFromExcel')}</DialogTitle>
        <DialogContent>
          <Typography>{t('orderOfContent')}</Typography>
          <TextField multiline rows={10} value={content} onChange={(e) => setContent(e.target.value)} fullWidth/>
          <Button variant="contained" sx={{ marginTop: 1 }} onClick={bulkUpdate}>{t('update')}</Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UpdateTradesTable;
