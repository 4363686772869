import { Typography, Box, Paper } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { FeeTier } from '../../../interfaces';

export const FETCH_ORGANIZATION_DEFAULT_FEE_TIER = gql`
  query fetchOrganizationDefaultFeeTier($organizationId: String!) {
    fetchOrganization(organizationId: $organizationId) {
      organization {
        defaultFeeTier {
          id
          default
          name
          flatAnnualFeeBps
          blendedGrid
          organization { name }
        }
      }
    }
  }
`;

export const DefaultOrgFeeGridBox = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation();

  const [defaultFeeGrid, setDefaultFeeGrid] = useState<FeeTier | null>(null);

  const { loading } = useQuery(FETCH_ORGANIZATION_DEFAULT_FEE_TIER, {
    variables: { organizationId: activeOrganization.id },
    onCompleted: (res: any) => {
      setDefaultFeeGrid(res.fetchOrganization?.organization?.defaultFeeTier);
    },
  });

  return (
    <Box mb={2}>
      <Paper sx={{ padding: 2 }}>
        <Typography>
          {t('feeAndBilling:feeGrid.defaultFeeGridFor')}
          &nbsp;{activeOrganization?.name}:&nbsp;
        </Typography>
        <Typography>
          {!loading && (defaultFeeGrid === null ? 'none' : defaultFeeGrid.name)}
        </Typography>
      </Paper>
    </Box>
  );
};
