import { useTranslation } from 'react-i18next';
import {
  Pagination as MuiPagination, TablePagination as MuiTablePagination, TablePaginationProps, PaginationProps,
} from '@mui/material';
import { SelectField } from '../selectField/selectField';
import { Box } from '../../1-primative';
import { MenuItem } from '../menuItem/menuItem';

interface IPaginationProps extends PaginationProps {
  perPage?: number;
  onChangePerPage?: (perPage: number) => void;
}

export const Pagination = ({ perPage, onChangePerPage, ...props }: IPaginationProps) => {
  const { t } = useTranslation('shared');

  return (
    <Box display='flex' justifyContent='end' alignItems='center' overflow='hidden'>
      {
        perPage && onChangePerPage && (
          <SelectField
            label=''
            value={perPage}
            onChange={(e: any) => onChangePerPage(e.target.value)}
            size='small'
            sx={{ p: 0 }}
          >
            <MenuItem value={15}>15 {t('perPage')}</MenuItem>
            <MenuItem value={25}>25 {t('perPage')}</MenuItem>
            <MenuItem value={50}>50 {t('perPage')}</MenuItem>
            <MenuItem value={100}>100 {t('perPage')}</MenuItem>
          </SelectField>
        )
      }
      <MuiPagination {...props} />
    </Box>
  );
};

export const TablePagination = (props: TablePaginationProps) => (
  <MuiTablePagination {...props} />
);
