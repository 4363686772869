import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from 'ovComponents/2-component';
import { Box, Grid, Typography } from 'ovComponents/1-primative';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useRefinitivWidget } from '../refinitiv/useRefinitivWidget';

export const RefinitivSecurityModal = ({ onClose, ricCode }: { onClose: () => any, ricCode: string }) => {
  useRefinitivWidget('#instrument-info-security-widget', 'WInstrumentInfo', { ric: ricCode, buttons: [], showChart: false });
  useRefinitivWidget('#price-chart-security-widget', 'WPriceChart', { ric: ricCode });
  useRefinitivWidget('#news-headlines-security-widget', 'WNewsHeadlines', { symbols: [ricCode] });
  useRefinitivWidget('#estimates-ratios-security-widget', 'WEstimatesRatios', { ric: ricCode });
  useRefinitivWidget('#company-profile-security-widget', 'WCompanyProfile', { ric: ricCode });
  useRefinitivWidget('#fund-allocation-security-widget', 'WFundAllocation', { ric: ricCode });
  useRefinitivWidget('#fund-avg-annual-returns-security-widget', 'WFundAvgAnnualReturns', { ric: ricCode });
  useRefinitivWidget('#price-yield-chart-security-widget', 'WPriceYieldChart', { ric: ricCode });

  const { t } = useTranslation('pageConfiguration');

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='xl'>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {t('refinitiv.securityDetails')} - {ricCode}
          <IconButton onClick={onClose} aria-label='close'>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* Instrument Info (All, except index?) */}
            <div id='instrument-info-security-widget'></div>
            <div id='company-profile-security-widget'></div>
          </Grid>
          {/* TODO: Main Index (Index only) */}
          <Grid item xs={12} md={9}>
            {/* Price Chart (all, except index?) */}
            <div id='price-chart-security-widget'></div>
            {/* News headlines (all?) */}
            <Typography variant='headingSmall' mt={2} mb={1}>{t('refinitiv.newsHeadlines')}</Typography>
            <div id='news-headlines-security-widget'></div>
            {/* Estimates & Ratios (Equity?) */}
            <Typography variant='headingSmall' mt={2} mb={1}>{t('refinitiv.estimatesRatios')}</Typography>
            <div id='estimates-ratios-security-widget'></div>
            {/* TODO: Fund Details (ETF & MF) */}
            {/* Fund Allocation (ETF & MF) */}
            <Typography variant='headingSmall' mt={2} mb={1}>{t('refinitiv.fundAllocation')}</Typography>
            <div id='fund-allocation-security-widget'></div>
            {/* Fund Average Annual Returns (ETF & MF) */}
            <Typography variant='headingSmall' mt={2} mb={1}>{t('refinitiv.fundAverageAnnualReturns')}</Typography>
            <div id='fund-avg-annual-returns-security-widget'></div>
            {/* Price Yield Chart (FI) */}
            <Typography variant='headingSmall' mt={2} mb={1}>{t('refinitiv.priceYield')}</Typography>
            <div id='price-yield-chart-security-widget'></div>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  );
};
