import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ClientGroup, SubAccount, SubAccountStates, getSubAccountName,
} from 'interfaces';
import { FETCH_GOAL } from 'pages/goalsDetails';
import { FETCH_GOALS } from 'components/layout/goals/goalsSummary';
import { useState } from 'react';
import { ListItem, Typography } from '@mui/material';
import { colors } from 'ovComponents';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';

const FETCH_SUBACCOUNTS_OF_GOAL = gql`
  query fetchSubAccounts($goalId: ObjectID!) {
    fetchSubAccounts(input: { filter: { goalId: $goalId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state
        account {
          id
          type
          custodianAccountNumber
          householdClientGroup { id }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

export const ASSOCIATE_GOAL_TO_HOUSEHOLD = gql`
  mutation associateGoalToHousehold($goalId: ObjectID!, $clientGroupId: ObjectID!) {
    updateGoal (
      input: {
        goalId: $goalId
        householdClientGroupId: $clientGroupId
      }
    ) {
      goal {
        id
      }
    }
  }
`;

export const AssociateGoalToHouseholdModal = ({
  handleClose, goalId, household,
}: { goalId: string, handleClose: () => void, household: ClientGroup }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const [outOfHouseholdSubaccounts, setOutOfHouseholdSubaccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_GOAL, {
    variables: { goalId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setOutOfHouseholdSubaccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
    },
  });

  const associationCompleted = (): void => {
    handleClose();
    showToast({ message: t('goalsDetails:associateToHouseholdDialog.success'), severity: 'success' });
  };

  const [associateGoalMutation] = useMutation(ASSOCIATE_GOAL_TO_HOUSEHOLD, {
    variables: {
      goalId,
      clientGroupId: household.id,
    },
    refetchQueries: [FETCH_GOAL(permissions), FETCH_GOALS(), FETCH_USER(permissions)],
    onCompleted: associationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('goalsDetails:associateToHouseholdDialog.title', { name: household.name })}
      open={true}
      maxWidth='sm'
      confirmButton={t('goalsDetails:associateToHouseholdDialog.continueButton')}
      cancelButton={t('goalsDetails:associateToHouseholdDialog.cancelButton')}
      onConfirm={associateGoalMutation}
      onCancel={handleClose}
      loading={loading}
    >
      <Typography component='div' sx={{ minHeight: '6em' }}>
        {loading && <Typography color={colors.neutral400}>{t('goalsDetails:associateToHouseholdDialog.checkingSubaccounts')}...</Typography>}
        {outOfHouseholdSubaccounts.length > 0
        && <>
          {t('goalsDetails:associateToHouseholdDialog.thereAreSubAccounts')}
          {outOfHouseholdSubaccounts.map((sa) => <ListItem key={sa.id}>{getSubAccountName(sa)}</ListItem>)}
        </>
        }
        {!loading && outOfHouseholdSubaccounts.length === 0 && <>
          {t('goalsDetails:associateToHouseholdDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
