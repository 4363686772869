import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { RefinitivWidgetType } from './useRefinitivWidget';

// BCP 47 ISO language tags
export type LanguageTag = 'fr-CA';

interface RefinitivLocaleMessages {
  [key: string]: any
}

/**
 * Mapping OneHub i18n translations to Refinitiv widget.setLocaleMessages() method
 */
export const useTranslatedMessages = (type: RefinitivWidgetType, languageTag: LanguageTag | undefined): RefinitivLocaleMessages => {
  const { t } = useTranslation(['refinitivWidgets']);

  if (!languageTag) return {};

  const translatorFn = WIDGET_TRANSLATORS[type];

  return {
    ...translatorFn ? translatorFn(t) : {},
    dateTimeFormats: languageTag === 'fr-CA' ? frenchDateTimeFormats : undefined,
  };
};

type TranslatorFunction = (t: TFunction) => object;

const WIDGET_TRANSLATORS: Record<RefinitivWidgetType, TranslatorFunction> = {
  WMainIndex: (t: TFunction) => ({
    buttons: {
      chart: t('WMainIndex.chart'),
      table: t('WMainIndex.table'),
    },
    table: {
      trades: t('WMainIndex.trades'),
      open: t('common.open'),
      high: t('common.high'),
      low: t('common.low'),
      lastClose: t('WMainIndex.lastClose'),
      turnover: t('WMainIndex.turnover'),
      tradedVolume: t('WMainIndex.tradedVolume'),
      marketTrend: t('WMainIndex.marketTrend'),
      '1week': t('WMainIndex.1week'),
      '1month': t('common.1month'),
      '3months': t('common.3months'),
      '6months': t('common.6months'),
      '1year': t('common.1year'),
      yearToDate: t('WMainIndex.yearToDate'),
      captions: {
        trades: t('WMainIndex.captions.trades'),
        trend: t('WMainIndex.captions.trend'),
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WMarketList: (t: TFunction) => ({
    header: {
      ticker: t('WMarketList.header.ticker'),
      name: t('common.name'),
      price: t('common.price'),
      change: t('WMarketList.header.changePlusMinus'),
      changePercentage: t('WMarketList.header.changePercentage'),
      bid: t('common.bid'),
      ask: t('common.ask'),
      histClose: t('WMarketList.header.histClose'),
      time: t('common.time'),
      date: t('common.date'),
      exchange: t('common.exchange'),
      currency: t('common.currency'),
      country: t('common.country'),
      sortable: t('WMarketList.header.sortable'),
    },
    fieldValueTranslations: {
      'x._CURRENCY': {
        USD: t('common.currencies.USD'),
        CAD: t('common.currencies.CAD'),
        default: '-',
      },
      'x._COUNTRY': {
        US: t('geoNames:US'),
        CA: t('geoNames:CA'),
        default: '....',
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      norics: t('WMarketList.messages.norics'),
      error: t('common.messages.error'),
    },
  }),
  WMarketTiles: (t: TFunction) => ({
    quoteView: {
      price: t('price'),
      changePercentage: t('WMarketTiles.changePercentage'),
      change: t('WMarketTiles.changeAbsolute'),
      turnover: t('WMarketTiles.turnover'),
      open: t('common.open'),
      high: t('common.high'),
      low: t('common.low'),
    },
    messages: {
      nodata: t('common.messages.nodata'),
      norics: t('WMarketTiles.messages.norics'),
      error: t('common.messages.error'),
      invalidInput: t('WMarketTiles.messages.invalidInput'),
    },
  }),
  WTickerBand: (t: TFunction) => ({
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WNewsHeadlines: (t: TFunction) => ({
    placeholders: {
      symbols: t('WNewsHeadlines.placeholders.symbols'),
      topics: t('WNewsHeadlines.placeholders.topics'),
      keywords: t('WNewsHeadlines.placeholders.keywords'),
    },
    labels: {
      time: t('common.time'),
      date: t('common.date'),
      headline: t('WNewsHeadlines.labels.headline'),
      source: t('WNewsHeadlines.labels.source'),
      keywords: t('WNewsHeadlines.labels.keywords'),
      symbols: t('WNewsHeadlines.labels.symbols'),
      topics: t('WNewsHeadlines.labels.topics'),
    },
    messages: {
      nodata: t('WNewsHeadlines.messages.nodata'),
      incompletedata: t('WNewsHeadlines.messages.incompletedata'),
      error: t('common.messages.error'),
    },
  }),
  WInterestRate: (t: TFunction) => ({
    header: {
      country: t('common.country'),
      name: t('common.name'),
      type: t('common.type'),
      '1Month': '1M',
      '3Month': '3M',
      '6Month': '6M',
      '12Month': '12M',
      time: t('common.time'),
      date: t('common.date'),
    },
    messages: {
      paramsError: t('WInterestRate.messages.paramsError'),
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WInstrumentInfo: (t: TFunction) => ({
    custom: {
      header: {
        high: t('common.high'),
        low: t('common.low'),
        currency: t('common.currency'),
        open: t('common.open'),
        close: t('common.close'),
        totvol: t('WInstrumentInfo.header.totvol'),
        time: t('common.time'),
        bid: t('common.bid'),
        ask: t('common.ask'),
        bidsize: t('WInstrumentInfo.header.bidsize'),
        asksize: t('WInstrumentInfo.header.asksize'),
        roundlot: t('WInstrumentInfo.header.roundlot'),
      },
      buttons: {
        buy: t('common.buy'),
        sell: t('common.sell'),
      },
    },
    fieldValueTranslations: {
      'x._CURRENCY': {
        USD: t('common.currencies.USD'),
        CAD: t('common.currencies.CAD'),
        default: '-',
      },
      'q._COUNTRY': {
        US: t('geoNames:US'),
        CA: t('geoNames:CA'),
        default: '....',
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
    booleanFormats: {
      bool: {
        true: t('common.yes'),
        false: t('common.no'),
        null: '-',
      },
    },
  }),
  WPriceChart: (t: TFunction) => ({
    controls: {
      placeholder: t('WPriceChart.controls.placeholder'),
      dateFrom: t('WPriceChart.controls.dateFrom'),
      dateTo: t('WPriceChart.controls.dateTo'),
      buttons: {
        D: t('common.buttons.D'),
        '2D': t('common.buttons.2D'),
        W: t('common.buttons.W'),
        '2W': t('common.buttons.2W'),
        M: t('common.buttons.M'),
        '3M': t('common.buttons.3M'),
        YTD: t('common.buttons.YTD'),
        Y: t('common.buttons.Y'),
        '2Y': t('common.buttons.2Y'),
        '10Y': t('common.buttons.10Y'),
        Max: t('common.buttons.max'),
      },
    },
    tooltip: {
      Price: t('WPriceChart.tooltip.price'),
      Volume: t('WPriceChart.tooltip.volume'),
    },
    labels: {
      shortMonths: {
        January: t('common.shortMonths.January'),
        February: t('common.shortMonths.February'),
        March: t('common.shortMonths.March'),
        April: t('common.shortMonths.April'),
        May: t('common.shortMonths.May'),
        June: t('common.shortMonths.June'),
        July: t('common.shortMonths.July'),
        August: t('common.shortMonths.August'),
        September: t('common.shortMonths.September'),
        October: t('common.shortMonths.October'),
        November: t('common.shortMonths.November'),
        December: t('common.shortMonths.December'),
      },
      shortDays: {
        Sunday: t('WPriceChart.labels.shortDays.Sunday'),
        Monday: t('WPriceChart.labels.shortDays.Monday'),
        Tuesday: t('WPriceChart.labels.shortDays.Tuesday'),
        Wednesday: t('WPriceChart.labels.shortDays.Wednesday'),
        Thursday: t('WPriceChart.labels.shortDays.Thursday'),
        Friday: t('WPriceChart.labels.shortDays.Friday'),
        Saturday: t('WPriceChart.labels.shortDays.Saturday'),
      },
      inputLabel: {
        day: t('WPriceChart.labels.inputLabel.day'),
        month: t('WPriceChart.labels.inputLabel.month'),
        year: t('WPriceChart.labels.inputLabel.year'),
      },
      inputPlaceholder: {
        day: t('WPriceChart.labels.inputPlaceholder.day'),
        month: t('WPriceChart.labels.inputPlaceholder.month'),
        year: t('WPriceChart.labels.inputPlaceholder.year'),
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WEstimatesRatios: (t: TFunction) => ({
    analystConsensus: {
      title: t('WEstimatesRatios.analystConsensus.title'),
      strongsell: t('WEstimatesRatios.analystConsensus.strongsell'),
      sell: t('common.sell'),
      hold: t('WEstimatesRatios.analystConsensus.hold'),
      buy: t('common.buy'),
      strongbuy: t('WEstimatesRatios.analystConsensus.strongbuy'),
      targetTitle: t('WEstimatesRatios.analystConsensus.targetTitle'),
      low: t('WEstimatesRatios.analystConsensus.low'),
      high: t('WEstimatesRatios.analystConsensus.high'),
      price: t('common.price'),
      tp: t('WEstimatesRatios.analystConsensus.tp'),
      recommendation: t('WEstimatesRatios.analystConsensus.recommendation'),
      tpfull: t('WEstimatesRatios.analystConsensus.tpfull'),
      pltg: t('WEstimatesRatios.analystConsensus.pltg'),
    },
    titles: {
      companyInfo: t('WEstimatesRatios.titles.companyInfo'),
      total: t('WEstimatesRatios.titles.total'),
      valuationProfit: t('WEstimatesRatios.titles.valuationProfit'),
      forecast: t('WEstimatesRatios.titles.forecast'),
      current: t('WEstimatesRatios.titles.current'),
      perShareData: t('WEstimatesRatios.titles.perShareData'),
    },
    table: {
      marketCap: t('WEstimatesRatios.table.marketCap'),
      sharesOutstanding: t('WEstimatesRatios.table.sharesOutstanding'),
      commonshare: t('WEstimatesRatios.table.commonshare'),
      enterpriseValue: t('WEstimatesRatios.table.enterpriseValue'),
      numberEmployees: t('WEstimatesRatios.table.numberEmployees'),
      pe: t('WEstimatesRatios.table.pe'),
      ps: t('WEstimatesRatios.table.ps'),
      pb: t('WEstimatesRatios.table.pb'),
      pcf: t('WEstimatesRatios.table.pcf'),
      peg: t('WEstimatesRatios.table.peg'),
      earningYield: t('WEstimatesRatios.table.earningYield'),
      dividendYield: t('WEstimatesRatios.table.dividendYield'),
      revenue: t('WEstimatesRatios.table.revenue'),
      ebit: t('WEstimatesRatios.table.ebit'),
      netIncome: t('WEstimatesRatios.table.netIncome'),
      gpm: t('WEstimatesRatios.table.gpm'),
      roe: t('WEstimatesRatios.table.roe'),
      eps: t('WEstimatesRatios.table.eps'),
      rps: t('WEstimatesRatios.table.rps'),
      bps: t('WEstimatesRatios.table.bps'),
      cfps: t('WEstimatesRatios.table.cfps'),
      cps: t('WEstimatesRatios.table.cps'),
      dividendPerShare: t('WEstimatesRatios.table.dividendPerShare'),
      captions: {
        companyInfo: t('WEstimatesRatios.table.captions.dividendPerShare'),
        valuationProfit: t('WEstimatesRatios.table.captions.valuationProfit'),
        perShareData: t('WEstimatesRatios.table.captions.perShareData'),
        total: t('WEstimatesRatios.table.captions.total'),
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WCompanyProfile: (t: TFunction) => ({
    titles: {
      masterData: t('WCompanyProfile.titles.masterData'),
      clearingCodes: t('WCompanyProfile.titles.clearingCodes'),
      keyDates: t('WCompanyProfile.titles.keyDates'),
      activities: t('WCompanyProfile.titles.activities'),
      management: t('WCompanyProfile.titles.management'),
    },
    table: {
      name: t('common.name'),
      companyID: t('WCompanyProfile.table.companyID'),
      price: t('common.price'),
      currency: t('common.currency'),
      exchange: t('common.exchange'),
      parValue: t('WCompanyProfile.table.parValue'),
      sharesOutstanding: t('WCompanyProfile.table.sharesOutstanding'),
      marketCap: t('WCompanyProfile.table.marketCap'),
      freeFloat: t('WCompanyProfile.table.freeFloat'),
      employees: t('WCompanyProfile.table.employees'),
      headquarters: t('WCompanyProfile.table.headquarters'),
      phone: t('WCompanyProfile.table.phone'),
      fax: t('WCompanyProfile.table.fax'),
      internet: t('WCompanyProfile.table.internet'),
      isin: t('WCompanyProfile.table.isin'),
      valor: t('WCompanyProfile.table.valor'),
      cusip: t('WCompanyProfile.table.cusip'),
      finYearEnd: t('WCompanyProfile.table.finYearEnd'),
      reportingDateQ1: t('WCompanyProfile.table.reportingDateQ1'),
      reportingDateQ2: t('WCompanyProfile.table.reportingDateQ2'),
      reportingDateQ3: t('WCompanyProfile.table.reportingDateQ3'),
      reportingDateQ4: t('WCompanyProfile.table.reportingDateQ4'),
      reportingDateA: t('WCompanyProfile.table.reportingDateA'),
      captions: {
        masterData: t('WCompanyProfile.table.captions.masterData'),
        clearingCodes: t('WCompanyProfile.table.captions.clearingCodes'),
        keyDates: t('WCompanyProfile.table.captions.keyDates'),
        activities: t('WCompanyProfile.table.captions.activities'),
        management: t('WCompanyProfile.table.captions.management'),
      },
    },
    messages: {
      nodata: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WFundAllocation: (t: TFunction) => ({
    types: {
      CURR: t('common.currency'),
      HOLD: t('WFundAllocation.types.HOLD'),
      INVCNT: t('WFundAllocation.types.INVCNT'),
      ASSTALL: t('WFundAllocation.types.ASSTALL'),
      INDSECT: t('WFundAllocation.types.INDSECT'),
      DEBTQUAL: t('WFundAllocation.types.DEBTQUAL'),
      MATUR: t('WFundAllocation.types.MATUR'),
    },
    messages: {
      noData: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WFundAvgAnnualReturns: (t: TFunction) => ({
    tableHeaders: {
      description: t('WFundAvgAnnualReturns.tableHeaders.description'),
      type: t('common.type'),
      '1m': t('common.1month'),
      '3m': t('common.3months'),
      '6m': t('common.6months'),
      '1y': t('common.1year'),
      '3y': t('WFundAvgAnnualReturns.tableHeaders.3y'),
      '5y': t('WFundAvgAnnualReturns.tableHeaders.5y'),
      '10y': t('WFundAvgAnnualReturns.tableHeaders.10y'),
      ytd: t('WFundAvgAnnualReturns.tableHeaders.ytd'),
      inc: t('WFundAvgAnnualReturns.tableHeaders.inc'),
    },
    types: {
      lgcbenchmarkid: t('WFundAvgAnnualReturns.lgcbenchmarkid'),
    },
    messages: {
      noData: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WPriceYieldChart: (t: TFunction) => ({
    scaling: {
      absolute: t('WPriceYieldChart.scaling.absolute'),
      percent: t('WPriceYieldChart.scaling.percent'),
    },
    chartVariant: {
      price: t('WPriceYieldChart.price'),
      yield: t('WPriceYieldChart.yield'),
    },
    chart: {
      price: t('WPriceYieldChart.price'),
      yield: t('WPriceYieldChart.yield'),
      containerLabel: t('WPriceYieldChart.chart.containerLabel'),
      shortMonths: {
        January: t('common.shortMonths.January'),
        February: t('common.shortMonths.February'),
        March: t('common.shortMonths.March'),
        April: t('common.shortMonths.April'),
        May: t('common.shortMonths.May'),
        June: t('common.shortMonths.June'),
        July: t('common.shortMonths.July'),
        August: t('common.shortMonths.August'),
        September: t('common.shortMonths.September'),
        October: t('common.shortMonths.October'),
        November: t('common.shortMonths.November'),
        December: t('common.shortMonths.December'),
      },
    },
    buttons: {
      '1D': t('common.buttons.D'),
      '2D': t('common.buttons.2D'),
      '1W': t('common.buttons.W'),
      '2W': t('common.buttons.2W'),
      '1M': t('common.buttons.M'),
      '3M': t('common.buttons.3M'),
      YTD: t('common.buttons.YTD'),
      '1Y': t('common.buttons.Y'),
      '2Y': t('common.buttons.2Y'),
      '10Y': t('common.buttons.10Y'),
      '20Y': t('common.buttons.max'),
    },
    numberFormats: {
      float: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      },
      price: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      },
    },
    messages: {
      noData: t('common.messages.nodata'),
      error: t('common.messages.error'),
    },
  }),
  WSearchSecurities: (t: TFunction) => ({
    // TODO
  }),
};

const frenchDateTimeFormats = {
  time: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    hm: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    a11y: {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
};
