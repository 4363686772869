import { useState, useContext } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import {
  Account as AccountInterface, DraftAccount, Goal,
} from 'interfaces';
import { CREATE_ACCOUNT } from 'components/wizards/createAccountWizard/steps/newAccountActionStep';
import { CLOSE_ACCOUNT } from 'components/modals/closeAccountModal';
import { IncompleteAffiliation } from './utils';
import { AccountVisual } from './account.visual';
import { WorkflowContext } from '../../workflowCompletion';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        type
        firstName
        middleName
        lastName
        entityName
        primaryEmail
        phone
        dateOfBirth
        language
        preferredMethodOfCommunication
        inProvinceSince
        gender
        physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
        isForThirdParty
        relatedEntities {
          entity {
            id
            type
            firstName
            middleName
            lastName
            entityName
            primaryEmail
          }
          relation
        }
        accounts {
          id
          type
          user {
            id
            firstName
            middleName
            lastName
            entityName
          }
          applyForGovFunds
          state
          affiliations {
            id
            allocation
            relation
            signatureRequired
            type
            user {
              id
              firstName
              middleName
              lastName
              entityName
            }
          }
        }
        goals {
          id
          name
          type
          state
          householdClientGroup { id }
        }
      }
    }
  }
`;

const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        goal {
          id
          name
        }
        account {
          id
          type
        }
      }
    }
  }
`;

export const UPDATE_AFFILIATION = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!){
    updateAffiliations(input: $input){
      account{
        id
        type
      }
      incompleteAffiliations {
        user { id firstName middleName lastName entityName }
        type
        incompleteFields
      }
    }
  }
`;

export const Account = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const [userData, setUserData] = useState<any>({
    id: userId,
    firstName: '',
    middleName: '',
    lastName: '',
    entityName: '',
    primaryEmail: '',
    phone: '',
    dateOfBirth: '',
    language: '',
    preferredMethodOfCommunication: '',
    inProvinceSince: '',
    gender: '',
    physicalAddress: {
      city: '',
      province: '',
      streetName: '',
      postal: '',
      unitNumber: '',
      houseNumber: '',
      neighborhood: '',
      country: '',
    },
  });
  const [accounts, setAccounts] = useState<AccountInterface[]>([]);
  const [incompleteAffiliations, setIncompleteAffiliations] = useState<{ [accountId: string]: IncompleteAffiliation[] }>({});
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [updateAffiliations] = useMutation(UPDATE_AFFILIATION);
  const [closeAccountMutation] = useMutation(CLOSE_ACCOUNT);
  const [createSubAccount] = useMutation(CREATE_SUB_ACCOUNT);
  const { setWorkflowData } = useContext(WorkflowContext);

  const createSubAccountsforAccount = async (account:AccountInterface, goals: Goal[]) => {
    if (goals?.length ?? 0) {
      await Promise.all(goals.map(async (goal) => {
        const createSubAccountResponse = await createSubAccount({
          variables: {
            input: {
              userId,
              accountId: account.id,
              goalId: goal.id,
              accountType: account.type,
            },
          },
        });
        return createSubAccountResponse;
      }));
    }
  };

  const { loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserData({ id: userId, ...data.fetchUser.user });
      setAccounts(data.fetchUser.user.accounts.filter((account: AccountInterface) => (account.state !== 'INACTIVE') && (account.state !== 'CANCELED')));
    },
  });
  const closeCreateAccount = (items: Awaited<{ account: AccountInterface; status: boolean }>[]): void => {
    items.forEach((item) => {
      closeAccountMutation({
        variables: {
          input: {
            accountId: item.account.id,
            inactiveReason: 'OTHER',
          },
          skipErrorHandler: true,
        },
      }).then();
    });
  };

  const createAllAccounts = (): void => {
    let preventContinue = !isEmpty(incompleteAffiliations);
    let tempIncompleteAffiliations: { [accountId: string]: IncompleteAffiliation[] } = {};
    const newAccounts = (accounts.filter((account) => !account.id) as DraftAccount[]);
    if (newAccounts.length === 0) return;
    setIsCreatingAccount(true);
    Promise.all(newAccounts.map(async (account) => {
      let response = { account, status: true };
      const createAccountResponse = await createAccount({
        variables: {
          input: {
            userId: userData.id,
            applyForGovFunds: account.applyForGovFunds,
            type: account.type,
          },
        },
        onError: (error) => {
          response = { ...response, status: false };
        },
      });
      const accountCreated = createAccountResponse.data?.createAccount.account;
      setWorkflowData({
        currentAccountId: accountCreated.id,
      });
      if (!response.status) return response;
      if (account?.affiliations && account.affiliations.length > 0) {
        const draftAffiliations = account.affiliations.filter((x) => !x?.id);
        await updateAffiliations({
          variables: {
            input: {
              accountId: createAccountResponse.data.createAccount.account.id,
              affiliations: draftAffiliations.map((affiliation) => ({
                userId: affiliation.user.id,
                allocation: affiliation.allocation,
                relation: affiliation.relation,
                signatureRequired: affiliation.signatureRequired,
                type: affiliation.type,
              })),
            },
          },
          onCompleted: (data: any) => {
            const { account: accountData, incompleteAffiliations: incompleteAffiliationsData } = (data?.updateAffiliations ?? {});
            if (accountData && !isEmpty(incompleteAffiliationsData)) {
              preventContinue = true;
              tempIncompleteAffiliations = {
                ...tempIncompleteAffiliations,
                [accountData.id]: incompleteAffiliationsData.map((entry: any) => ({
                  account: accountData,
                  affiliation: entry,
                  incompleteFields: entry.incompleteFields,
                })),
              };
            }
          },
          onError: (error) => {
            response = { ...response, status: false };
          },
        });
      }
      if (account?.goalsLinked !== undefined) {
        createSubAccountsforAccount(accountCreated, account.goalsLinked);
      }
      return { ...response, account: accountCreated };
    })).then((r) => {
      if (r.filter((item) => !item.status).length > 0) {
        closeCreateAccount(r);
        return;
      }
      setIncompleteAffiliations(tempIncompleteAffiliations);
      setIsCreatingAccount(false);

      if (!preventContinue) onNext();
    });
  };

  return (
    <AccountVisual
      options={options}
      accounts={accounts}
      updateAccounts={setAccounts}
      updateUser={setUserData}
      userData={userData}
      incompleteAffiliations={incompleteAffiliations}
      onCreateAccounts={createAllAccounts}
      continueFunc={onNext}
      isLoading={loading || isCreatingAccount || stepLoading}
    />
  );
};

export default Account;
