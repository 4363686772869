import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box, Typography,
} from '../../../1-primative';
import {
  Button,
  Card, CardContent, CircularProgress, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { usePermissions } from '../../../../providers/userContextProvider';
import { RelatedEntity } from '../../../../interfaces';
import { entityLink, formatPhoneNumber, generateClientNameString } from '../../../../util';
import { RelatedEntityEdit } from './components/relatedEntityEdit';
import { RelatedEntityRemoveDialog } from './components/relatedEntityRemoveDialog';
import { NewRelatedEntity } from './components/newRelatedEntity';

export const FETCH_USER_RELATED_ENTITIES = (permissions: string[]) => gql`
  query fetchUserRelatedEntities($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id type state
        ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
        entityName
        relatedEntities {
          relation
          entity {
            id type state
            ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
            entityName
          }
        }
      }
    }
  }
`;

export const Relationships = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation('nonIndividualClients');
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const [relatedEntities, setRelatedEntities] = useState<[RelatedEntity]>();
  const [user, setUser] = useState(null);

  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenuEntity, setContextMenuEntity] = useState<null | RelatedEntity>(null);
  const [newOpen, setNewOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [relationEditorOpen, setRelationEditorOpen] = useState(false);

  // fetchUserRelatedEntities
  const { loading } = useQuery(FETCH_USER_RELATED_ENTITIES(permissions), {
    variables: { userId: objectId },
    onCompleted: ((data) => {
      setRelatedEntities(data.fetchUser.user.relatedEntities);
      setUser(data.fetchUser.user);
    }),
  });

  if (loading || !relatedEntities) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  const closeContextMenu = () => {
    setContextMenuAnchorEl(null);
  };

  return (
    <Card>
      { options.canEdit && user && (
        <CardContent>
          <Box display='flex' justifyContent='end'>
            <Button label={t('shared:add')} onClick={() => setNewOpen(true)} leadingIcon={Add} />
            { newOpen && (
              <NewRelatedEntity
                user={user}
                existingRelatedEntities={relatedEntities}
                onClose={() => setNewOpen(false)}
              />
            )}
          </Box>
        </CardContent>
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('table.name')}</TableHeadCell>
            <TableHeadCell>{t('shared:relationship')}</TableHeadCell>
            <TableHeadCell>{t('shared:phoneNumber')}</TableHeadCell>
            <TableHeadCell>{t('shared:email')}</TableHeadCell>
            <TableHeadCell>{t('shared:status')}</TableHeadCell>
            { options.canEdit && (<TableHeadCell />) }
          </TableRow>
          {relatedEntities.map((relatedEntity, index) => (
            <TableRow
              key={index}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '3.6em', cursor: 'pointer' }}
              onClick={() => navigate(entityLink(relatedEntity.entity))}
            >
              <TableCell>
                {generateClientNameString(relatedEntity.entity)}
              </TableCell>
              <TableCell>
                {t(`relatedEntityTypes:${relatedEntity.relation}`)}
              </TableCell>
              <TableCell>
                {formatPhoneNumber(relatedEntity.entity.phone)}
              </TableCell>
              <TableCell>
                {relatedEntity.entity.primaryEmail}
              </TableCell>
              <TableCell>
                {t(`client:details.state.${relatedEntity.entity.state}`)}
              </TableCell>
              { options.canEdit && (
                <TableCell
                  right
                  sx={{ cursor: 'default' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setContextMenuAnchorEl(event.currentTarget);
                    setContextMenuEntity(relatedEntity);
                  }}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Context drop-down menu */}
      <Menu
        anchorEl={contextMenuAnchorEl}
        open={contextMenuAnchorEl !== null}
        onClose={closeContextMenu}
        onClick={closeContextMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setRelationEditorOpen(true)}>
          <EditRoundedIcon/>
          <Typography ml='0.5em'>{t('editRelationship')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>
          <DeleteForeverIcon/>
          <Typography ml='0.5em'>{t('deleteRelationship')}</Typography>
        </MenuItem>
      </Menu>

      {/* Relation removal confirmation dialog */}
      { deleteConfirmationOpen && contextMenuEntity && user && (
        <RelatedEntityRemoveDialog
          user={user}
          existingRelatedEntities={relatedEntities}
          entityToRemove={contextMenuEntity.entity}
          onClose={() => setDeleteConfirmationOpen(false)}
        />
      )}

      {/* Member edit dialog */}
      { relationEditorOpen && contextMenuEntity && user && (
        <RelatedEntityEdit
          user={user}
          existingRelatedEntities={relatedEntities}
          entityToUpdate={contextMenuEntity}
          onClose={() => setRelationEditorOpen(false)}
        />
      )}

    </Card>
  );
};
