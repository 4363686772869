import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  addEdge, Background, Handle, Position, ReactFlow, useEdgesState, useNodesState,
} from '@xyflow/react';
import { translateBackend } from '../../../../assets/i18n/config';
import { Box, Typography } from '../../../1-primative';
import {
  Button, Card, CardContent, DialogFooter, DialogTitle,
  MenuItem,
  SelectField,
  TranslatableTextField,
} from '../../../2-component';
import { FullScreenDialog } from '../../../3-pattern';

import '@xyflow/react/dist/style.css';
import { EditSubStepDialog } from './editSubStepDialog';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { STEP_TYPES } from './basicWorkflowEditor';
import { BranchEditor, BranchProps } from './branchEditor';
import {
  Step, SubStep, Workflow, WorkflowBranch,
} from '../../../../interfaces';
import { UserContext } from '../../../../providers/userContextProvider';
import { updateWorkflowSubSteps } from '../utils';

const SubStepNode = ({ data }: { data: { subStep: SubStep } }) => {
  const { t } = useTranslation(['workflowCompletions', 'orgSettings']);
  const { activeOrganization } = useContext(UserContext);
  useEffect(() => {
    updateWorkflowSubSteps(STEP_TYPES, activeOrganization.availableFeatureFlags);
  }, [activeOrganization.availableFeatureFlags]);

  return (
    <>
      <Handle type="target" position={Position.Top} style={{ zIndex: 2 }} />
      <Card variant='outlined' sx={{ width: '270px' }}>
        <CardContent sx={{
          paddingBottom: '16px !important', display: 'flex', justifyContent: 'start', flexDirection: 'column',
        }}>
          <Typography variant='bodySmall' weight='bold'>{t(`subStepTypes.${data.subStep.type}`)}</Typography>
          <Typography variant='labelSmall' colorVariant='variant' sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
            {translateBackend(data.subStep.options?.title)}
          </Typography>
        </CardContent>
      </Card>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

const StartNode = () => {
  const { t } = useTranslation(['shared']);
  const { sys } = useThemeTokens();

  return (
    <>
      <Card variant='outlined' sx={{ width: '270px', background: sys.color.surfaceContainer }}>
        <CardContent sx={{
          paddingBottom: '16px !important', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <Typography variant='bodySmall' weight='bold'>{t('start')}</Typography>
        </CardContent>
      </Card>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

const EndNode = () => {
  const { t } = useTranslation(['shared']);
  const { sys } = useThemeTokens();

  return (
    <>
      <Handle type="target" position={Position.Top} style={{ zIndex: 2 }} />
      <Card variant='outlined' sx={{ width: '270px', background: sys.color.surfaceContainer }}>
        <CardContent sx={{
          paddingBottom: '16px !important', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <Typography variant='bodySmall' weight='bold'>{t('end')}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export const StepEditor = ({
  open, setOpen, step, updateStep, workflow, removeStep,
}: {
  open: boolean, setOpen: (o: boolean) => void, step: Step, updateStep: (s: Step) => void, workflow: Workflow, removeStep: () => void,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'orgSettings', 'shared']);
  const [subStepOpen, setSubStepOpen] = useState(false);
  const [activeSubStep, setActiveStep] = useState<SubStep | null>(null);
  const [branchOpen, setBranchOpen] = useState(false);
  const [activeBranch, setBranch] = useState<BranchProps | null>(null);
  const [localStep, setLocalStep] = useState<Step | null>(null);

  const initialNodes: any[] = [];
  const initialEdges: BranchProps[] = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  useEffect(() => {
    if (step) {
      setLocalStep(step);
    }
    if (step && step.subSteps) {
      const newNodes = step.subSteps.map((x, index: number) => ({
        id: x.key ?? window.crypto.randomUUID(),
        type: 'subStep',
        position: x.nodePosition ?? { x: 0, y: index * 100 },
        data: { label: x.type, subStep: x },
      }));
      setNodes([
        {
          id: 'start',
          type: 'start',
          position: step.startNodePosition ?? { x: 0, y: -50 },
          data: { label: 'start' },
        },
        ...newNodes,
        {
          id: 'end',
          type: 'end',
          position: step.endNodePosition ?? { x: 0, y: step.subSteps.length * 100 + 100 },
          data: { label: 'end' },
        },
      ]);
      const newEdges: any[] = [];
      (step.branches ?? []).forEach((branch: WorkflowBranch) => {
        newEdges.push({
          id: `start-${branch.subStepKey || 'end'}`,
          source: 'start',
          target: branch.subStepKey || 'end',
          animated: branch.condition,
          data: { condition: branch.condition, contextObject: branch.contextObject },
        });
      });
      step.subSteps.forEach((x: SubStep) => {
        (x.branches ?? []).forEach((branch: WorkflowBranch) => {
          newEdges.push({
            id: `${x.key}-${branch.subStepKey || 'end'}`,
            source: x.key,
            target: branch.subStepKey || 'end',
            animated: branch.condition,
            data: { condition: branch.condition, contextObject: branch.contextObject },
          });
        });
      });
      setEdges(newEdges);
    }
  }, [step, setEdges, setNodes]);

  const nodeTypes = {
    subStep: SubStepNode,
    start: StartNode,
    end: EndNode,
  };

  const onSave = () => {
    const subSteps = nodes.filter((node) => node.type !== 'start' && node.type !== 'end').map((node) => ({
      ...node.data.subStep,
      key: node.id,
      nodePosition: node.position,
      branches: edges.filter((e) => e.source === node.id).map((e) => ({
        subStepKey: e.target === 'end' ? null : e.target,
        condition: e.data?.condition,
        contextObject: e.data?.contextObject,
      })),
    }));
    updateStep({
      ...localStep,
      branches: edges.filter((e) => e.source === 'start').map((e) => ({
        subStepKey: e.target,
        condition: e.data?.condition,
        contextObject: e.data?.contextObject,
      })),
      startNodePosition: nodes.find((node) => node.type === 'start').position,
      endNodePosition: nodes.find((node) => node.type === 'end').position,
      subSteps,
    });
    setOpen(false);
  };

  const removeSubStep = () => {
    setEdges(edges.filter((edge) => edge.target !== activeSubStep?.key && edge.source !== activeSubStep?.key));
    setNodes(nodes.filter((node) => node.data?.subStep?.id !== activeSubStep?.id));
  };

  const updateSubStep = (s: SubStep) => {
    const index = nodes.findIndex((node) => node.data?.subStep?.id === s.id);

    const newNodes = [...nodes];
    newNodes[index] = {
      ...newNodes[index],
      data: {
        ...newNodes[index].data,
        subStep: s,
      },
    };
    setNodes(newNodes);
  };

  const updateBranch = (b: BranchProps) => {
    const newEdges = [...edges];
    const index = newEdges.findIndex((e) => b.id === e.id);
    newEdges[index] = b;
    setEdges(newEdges);
  };

  const removeBranch = () => {
    setEdges(edges.filter((edge) => edge.id !== activeBranch?.id));
    setBranchOpen(false);
  };

  return (
    <FullScreenDialog open={open}>
      <DialogTitle onClose={() => setOpen(false)}>
        <TranslatableTextField label='' value={localStep?.name ?? {}} fullWidth onChange={(e: any) => setLocalStep({ ...localStep, name: e })} />
      </DialogTitle>
      <EditSubStepDialog
        open={subStepOpen}
        setOpen={setSubStepOpen}
        activeSubStep={activeSubStep}
        updateStep={updateSubStep}
        removeStep={removeSubStep}
        organizationId={workflow?.organization?.id ?? ''}
      />
      {branchOpen && activeBranch && (
        <BranchEditor
          open={branchOpen}
          setOpen={setBranchOpen}
          branch={activeBranch}
          updateBranch={updateBranch}
          removeBranch={removeBranch}
        />
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        snapToGrid
        snapGrid={[16, 16]}
        onNodeClick={(_e, node) => {
          if (node.type !== 'start' && node.type !== 'end') {
            setActiveStep(node.data.subStep);
            setSubStepOpen(true);
          }
        }}
        onEdgeClick={(_e, edge) => {
          setBranch(edge);
          setBranchOpen(true);
        }}
        fitView
      >
        <Background offset={0.4} gap={16}/>
      </ReactFlow>
      <DialogFooter>
        <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
          <Box width='250px'>
            <SelectField label={t('addStep')} fullWidth sx={{ ml: 2 }} value='' onChange={(e: any) => {
              const newSubStep = {
                id: `subStep-${Math.random()}`,
                type: e.target.value,
                options: {},
              };
              setNodes([
                ...nodes,
                {
                  id: window.crypto.randomUUID(),
                  type: 'subStep',
                  position: { x: 400, y: 0 },
                  data: { label: e.target.type, subStep: newSubStep },
                },
              ]);
              setActiveStep(newSubStep);
              setSubStepOpen(true);
            }}>
              {STEP_TYPES.map((x) => (
                <MenuItem key={x} value={x}>{t(`subStepTypes.${x}`)}</MenuItem>
              ))}
            </SelectField>
          </Box>
          <Box>
            <Button onClick={removeStep} label={t('remove')} variant='tonal' color='destructive' sx={{ mr: 1 }} />
            <Button onClick={onSave} label={t('shared:save')} />
          </Box>
        </Box>
      </DialogFooter>
    </FullScreenDialog>
  );
};
