/* eslint-disable max-len */
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, CircularProgress, Card, Tooltip, Button,
} from '@mui/material';
import {
  findIndex, flatten, isEmpty, sum,
} from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import LockIcon from '@mui/icons-material/Lock';
import { round } from 'lodash';
import { useHoldingStats } from '../../../providers/portfolioStatsHooks';
import AmountField from '../../inputs/amountField';
import { colors } from '../../../theme/colors';
import GenerateTradesModal from './components/generateTradesModal';
import ChangeTooltip from './components/changeTooltip';
import BulkTradeRequests from './components/bulkTradeRequests';
import SmallDollarPercentage from './components/smallDollarPercentage';
import TaxRank from './components/taxRank';
import { delay, formatMoneyValue } from '../../../util';
import { ObjectType } from '../../../providers/statsHooks';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { PortfolioReport } from '../../../interfaces';
import { SubAccountReport } from '../../../interfaces/subAccountReport';
import SubTradeRequestPairsModal from './components/subTradeRequestPairsModal';
import { Switch } from '../../../ovComponents/2-component';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useLocalization } from '../../../util/useLocalization';

const FETCH_BULK_TRADES = gql`
  query fetchBulkTradeRequests($input: FetchBulkTradeRequestsInput!) {
    fetchBulkTradeRequests(input: $input) {
      bulkTradeRequests {
        id
        state
      }
      totalCount
    }
  }
`;

const FETCH_SUB_TRADES = gql`
  query fetchSubTradeRequests($input: FetchSubTradeRequestsInput!) {
    fetchSubTradeRequests(input: $input) {
      subTradeRequests {
        id
        financialProduct {
          id
          ticker
          currentPriceCents
          translatedName {
            en
          }
        }
        state
        moneyAllocatedCents
        preSplitMoneyAllocatedCents
        type
        isSellAll
        subAccount {
          id
          account {
            type
            user {
              firstName
            }
          }
        }
      }
      totalCount
    }
  }
`;

const PortfolioTable = ({
  id,
  type,
  setIsTradesUpdated,
  isCashRebalanceRequired,
  portfolioOptimizerId,
  portfolioReport,
  setIsRefetchRequired,
}: {
  id: string;
  type: ObjectType;
  setIsTradesUpdated?: (value: boolean) => void;
  setIsRefetchRequired?: (value: boolean) => void;
  isCashRebalanceRequired?: boolean;
  portfolioOptimizerId?: string;
  portfolioReport?: PortfolioReport;
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { sys } = useThemeTokens();
  const { localizedDateTime } = useLocalization();

  const [holdings, setHoldings] = useState<any[]>([]);
  const [subAccounts, setSubAccounts] = useState<any[]>([]);
  const [draftTrades, setDraftTrades] = useState<any[]>([]);
  const [initialDraftTrades, setInitialDraftTrades] = useState<any[]>([]);
  const [processingTrades, setProcessingTrades] = useState<any[]>([]);
  const [draftTradesToCancel, setDraftTradesToCancel] = useState<any[]>([]);
  const [initialSubTradeRequests, setInitialSubTradeRequests] = useState<any[]>([]);
  const [activeBulkTrade, setActiveBulkTrade] = useState<string | undefined>(undefined);
  const [activeBulkTradeObject, setActiveBulkTradeObject] = useState<Record<string, any> | undefined>(undefined);
  const [isLocalTradesUpdated, setIsLocalTradesUpdated] = useState<boolean>(false);
  const [isRefetchSubTradeRequests, setIsRefetchSubTradeRequests] = useState<boolean>(false);
  const [isErrorOccurred, setErrorOccurred] = useState<boolean>(false);
  const { stats, loading: statsLoading } = useHoldingStats({ type, id });
  const [localPortfolioOptimizerId, setLocalPortfolioOptimizerId] = useState<string | undefined>(portfolioOptimizerId || portfolioReport?.lastOptimizedBy?.id);
  const [isShowLastOptimizedBy, setIsShowLastOptimizedBy] = useState<boolean>(true);
  const [autoFilled, setAutoFilled] = useState<boolean>(false);
  const [isForceNewBulkTradeRequest, setIsForceNewBulkTradeRequest] = useState(false);
  const [openTradePairs, setOpenTradePairs] = useState<boolean>(false);
  const [viewPreTradePositions, setViewPreTradePositions] = useState<boolean>(false);

  const { loading, refetch: refetchBulkTrades } = useQuery(FETCH_BULK_TRADES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          sourceId: localPortfolioOptimizerId ?? id,
          ...(!localPortfolioOptimizerId && { state: 'REQUESTED' }),
        },
      },
    },
    skip: !localPortfolioOptimizerId && !id,
    onCompleted: (request: any) => {
      if (request?.fetchBulkTradeRequests?.bulkTradeRequests?.length > 0) {
        const bulkTradeRequest = request.fetchBulkTradeRequests.bulkTradeRequests[0];

        if (!portfolioOptimizerId && localPortfolioOptimizerId && ['CANCELED', 'COMPLETED'].includes(bulkTradeRequest.state)) {
          setLocalPortfolioOptimizerId(undefined);
          setIsRefetchSubTradeRequests(true);

          refetchBulkTrades({
            input: {
              filter: {
                sourceId: id,
                state: 'REQUESTED',
              },
            },
          });

          return;
        }

        setActiveBulkTrade(bulkTradeRequest.id);
        setActiveBulkTradeObject(bulkTradeRequest);

        if (isRefetchSubTradeRequests) {
          refetch();
          setIsRefetchSubTradeRequests(false);
        }
      } else if (portfolioOptimizerId) {
        setErrorOccurred(true);
      }
    },
  });

  const { refetch, loading: subTradeLoading } = useQuery(FETCH_SUB_TRADES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          bulkTradeRequestId: activeBulkTrade ?? null,
          ...(localPortfolioOptimizerId && { sourceId: id }),
        },
        pagination: {
          sortField: 'subAccount',
          perPage: 100,
        },
      },
    },
    skip: !activeBulkTrade,
    onCompleted: (request: any) => {
      if (request.fetchSubTradeRequests.subTradeRequests.length > 0) {
        const { subTradeRequests } = request.fetchSubTradeRequests;

        let draftTradesFromSubTradeRequests = subTradeRequests.map((trade: any) => ({
          id: trade.id,
          financialProductId: trade.financialProduct.id,
          ticker: trade.financialProduct.ticker,
          isSellAll: trade.isSellAll,
          subAccountId: trade.subAccount.id,
          accountName: `${trade.subAccount.account.user.firstName}'s ${t(`accountTypes:${trade.subAccount.account.type}`)}`,
          amountCents: (trade?.preSplitMoneyAllocatedCents ?? trade.moneyAllocatedCents) * (trade.type === 'SELL' ? -1 : 1),
          type: trade.type,
          state: trade.state,
        }));

        /* when a bulkTradeRequest is generated by an optimizer and all of the subTradeRequests that match { bulkTradeRequest: 'id', sourceId: 'id' }
          are either in CANCELED or RECONCILED states, we consider this bulkTradeRequest COMPLETED and will start a new one */
        if (draftTradesFromSubTradeRequests.filter((trade: any) => ['CANCELED', 'RECONCILED'].includes(trade.state)).length === draftTradesFromSubTradeRequests.length) {
          draftTradesFromSubTradeRequests = [];
          setIsForceNewBulkTradeRequest(true);

          setLocalPortfolioOptimizerId(undefined);
          setActiveBulkTrade(undefined);
          setActiveBulkTradeObject(undefined);
        }

        if (localPortfolioOptimizerId) {
          setDraftTradesToCancel([]);
        }

        setInitialSubTradeRequests([...draftTradesFromSubTradeRequests]);
        setProcessingTrades([...draftTradesFromSubTradeRequests.filter((trade: any) => ['REQUESTED'].includes(trade.state))]);
        draftTradesFromSubTradeRequests = draftTradesFromSubTradeRequests.filter((trade: any) => ['READY', 'INITIATED'].includes(trade.state));
        setInitialDraftTrades([...draftTradesFromSubTradeRequests]);

        setDraftTrades(draftTradesFromSubTradeRequests);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, activeBulkTradeObject]);

  const mapHoldings = useCallback(() => {
    const initHoldings = new Map();

    for (const child of stats.financialProduct.children) {
      if (!initHoldings.has(child.financialProduct.id)) {
        initHoldings.set(child.financialProduct.id, {
          id: child.financialProduct.id,
          ticker: child.financialProduct.ticker || t('portfolioTable.cash'),
          currentPriceCents: child.financialProduct.currentPriceCents,
          name: child.financialProduct.name || t('portfolioTable.cash'),
          translatedName: translateBackend(child.financialProduct.translatedName),
          isCash: child.financialProduct.isCash,
          targetPercentage: child.percentage / 100,
          taxRank: child.financialProduct.taxRank,
          childTaxRank: child.taxRank,
          expectedCashCents: portfolioReport?.expectedCashCents ?? 0, // for cash
          cashAvailableForTradeCents: portfolioReport?.cashAvailableForTradeCents ?? 0,
          subaccounts: flatten(
            (type === ObjectType.SUB_ACCOUNT ? [stats] : stats.subAccounts).map((sub: any) => ({
              id: sub.id,
              name: `${sub.account.user.firstName}'s ${t(`accountTypes:${sub.account.type}`)}`,
              taxRank: sub.account.taxRank,
              holding: sub.statistics.holdings.find((holding: any) => holding.financialProduct.id === child.financialProduct.id), // ?
              totalMarketValue: sub.statistics.marketValueCents,
              pendingContributionCents: sub.statistics.pendingContributionCents,
              cashOnHoldToTradeCents: sub.statistics.cashOnHoldToTradeCents,
              inactive: sub.state === 'INACTIVE',
            })),
          ).sort((a: any, b: any) => a.taxRank - b.taxRank),
        });
      }
    }

    const statsHoldings = stats.statistics.holdings.filter((holding: any) => !holding.financialProduct.isCash && holding.totalCents > 0);

    for (const statsHolding of statsHoldings) {
      if (!initHoldings.has(statsHolding.financialProduct.id)) {
        initHoldings.set(statsHolding.financialProduct.id, {
          id: statsHolding.financialProduct.id,
          ticker: statsHolding.financialProduct.ticker,
          currentPriceCents: statsHolding.financialProduct.currentPriceCents,
          name: statsHolding.financialProduct.name,
          translatedName: translateBackend(statsHolding.financialProduct.translatedName),
          isCash: false,
          targetPercentage: 0,
          taxRank: statsHolding.financialProduct.taxRank,
          expectedValueCents: 0, // updated form a portfolio report
          expectedPercentage: 0,
          subaccounts: flatten(
            (type === ObjectType.SUB_ACCOUNT ? [stats] : stats.subAccounts).map((sub: any) => ({
              id: sub.id,
              name: `${sub.account.user.firstName}'s ${t(`accountTypes:${sub.account.type}`)}`,
              taxRank: sub.account.taxRank,
              holding: sub.statistics.holdings.find((h: any) => h.financialProduct.id === statsHolding.financialProduct.id),
              totalMarketValue: sub.statistics.marketValueCents,
              pendingContributionCents: sub.statistics.pendingContributionCents,
              cashOnHoldToTradeCents: sub.statistics.cashOnHoldToTradeCents,
              inactive: sub.state === 'INACTIVE',
            })),
          ).sort((a: any, b: any) => a.taxRank - b.taxRank),
        });
      }
    }

    const portfolioReportHoldings = portfolioReport?.holdings?.filter((holding: any) => !holding.financialProduct.isCash && holding.expectedValueCents > 0) ?? [];

    for (const portfolioReportHolding of portfolioReportHoldings) {
      if (initHoldings.has(portfolioReportHolding.financialProduct.id)) {
        const localInitHoldings = initHoldings.get(portfolioReportHolding.financialProduct.id);

        initHoldings.set(portfolioReportHolding.financialProduct.id, {
          ...localInitHoldings,
          expectedValueCents: portfolioReportHolding.expectedValueCents,
          expectedPercentage: portfolioReportHolding.expectedPercentage,
        });
      }
    }

    initHoldings.forEach((holding: any, key: string) => {
      const subaccounts = flatten(
        holding.subaccounts.map((sub: any) => {
          const subAccountReport = portfolioReport?.subAccounts?.find((subReport: SubAccountReport) => sub.id === subReport.subAccount.id);

          return {
            ...sub,
            expectedCashCents: subAccountReport?.expectedCashCents,
            pendingWithdrawCents: subAccountReport?.pendingWithdrawCents,
            cashAvailableForTradeCents: subAccountReport?.cashAvailableForTradeCents,
          };
        }),
      );

      initHoldings.set(key, {
        ...holding,
        subaccounts,
        targetCents: holding.targetPercentage * (stats.statistics.marketValueCents - stats.statistics.cashOnHoldToTradeCents),
        totalCents: holding.subaccounts.reduce((acc: number, sub: any) => acc + (sub.holding?.totalCents || 0), 0),
      });
    });

    if (!Array.from(initHoldings.values()).find((holding: any) => holding.isCash)) {
      const cashSubAccounts = flatten(
        (type === ObjectType.SUB_ACCOUNT ? [stats] : stats.subAccounts).map((sub: any) => ({
          id: sub.id,
          name: `${sub.account.user.firstName}'s ${t(`accountTypes:${sub.account.type}`)}`,
          taxRank: sub.account.taxRank,
          holding: sub.statistics.holdings.find((holding: any) => holding.financialProduct.isCash),
          totalMarketValue: sub.statistics.marketValueCents,
          pendingContributionCents: sub.statistics.pendingContributionCents,
          cashOnHoldToTradeCents: sub.statistics.cashOnHoldToTradeCents,
          expectedCashCents: portfolioReport?.subAccounts?.find((subReport: SubAccountReport) => sub.id === subReport.subAccount.id)?.expectedCashCents,
          pendingWithdrawCents: portfolioReport?.subAccounts?.find((subReport: SubAccountReport) => sub.id === subReport.subAccount.id)?.pendingWithdrawCents,
          cashAvailableForTradeCents: portfolioReport?.subAccounts?.find((subReport: SubAccountReport) => sub.id === subReport.subAccount.id)?.cashAvailableForTradeCents,
          inactive: sub.state === 'INACTIVE',
        })),
      ).sort((a: any, b: any) => a.taxRank - b.taxRank);

      const cashTotalCents = cashSubAccounts.reduce((acc: number, sub: any) => acc + (sub.holding?.totalCents || 0), 0);

      const cashHolding = {
        id: 'cash',
        ticker: 'CASH',
        name: t('portfolioTable.cash'),
        isCash: true,
        targetPercentage: 0,
        targetCents: 0,
        totalCents: cashTotalCents,
        subaccounts: cashSubAccounts,
        expectedCashCents: portfolioReport?.expectedCashCents ?? 0, // for cash
        cashAvailableForTradeCents: portfolioReport?.cashAvailableForTradeCents ?? 0,
      };

      initHoldings.set(cashHolding.id, cashHolding);
    }

    const sortedHoldings = Array.from(initHoldings.values())
      .sort((a: any, b: any) => {
        const taxRankA = a.childTaxRank ?? a.taxRank;
        const taxRankB = b.childTaxRank ?? b.taxRank;

        if (taxRankA === taxRankB) {
          return a.ticker.localeCompare(b.ticker);
        }

        return taxRankA - taxRankB;
      })
      .sort((a: any, b: any) => b.isCash - a.isCash);

    setHoldings(sortedHoldings);
  }, [stats, t, type, portfolioReport]);

  useEffect(() => {
    if (!isEmpty(stats)) {
      mapHoldings();

      const initSubAccounts = flatten(
        (type === ObjectType.SUB_ACCOUNT ? [stats] : stats.subAccounts).map((sub: any) => ({
          id: sub.id,
          name: `${sub.account.user.firstName}'s ${t(`accountTypes:${sub.account.type}`)}`,
          marketValueCents: sub.statistics.marketValueCents,
          taxRank: sub.account.taxRank,
          inactive: sub.state === 'INACTIVE',
        })),
      ).sort((a: any, b: any) => a.taxRank - b.taxRank);

      setSubAccounts(initSubAccounts);
    }
  }, [stats, mapHoldings, t, type]);

  const isDraftTradesNotEmpty = (draftTrades?.filter((trade: any) => trade.amountCents !== 0)?.length ?? 0) > 0;

  const areTradesAmountCentsDifferent = (arr1: any[], arr2: any[]) => {
    const getKey = (obj: any) => `${obj.financialProductId}`;

    const map1 = new Map<string, number>();

    arr1.forEach((obj) => {
      const key = getKey(obj);
      map1.set(key, obj.amountCents);
    });

    return arr2.some((obj) => {
      const key = getKey(obj);
      if (map1.has(key) && map1.get(key) !== obj.amountCents) {
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    const isThereUpdates = localPortfolioOptimizerId ? areTradesAmountCentsDifferent(initialDraftTrades, draftTrades) || initialDraftTrades.length !== draftTrades.length : isDraftTradesNotEmpty;

    if (setIsTradesUpdated) {
      activeBulkTrade && !localPortfolioOptimizerId ? setIsTradesUpdated(false) : setIsTradesUpdated(isThereUpdates);
    }

    setIsLocalTradesUpdated(isThereUpdates);
  }, [draftTrades, initialDraftTrades, setIsTradesUpdated, isDraftTradesNotEmpty, activeBulkTrade, localPortfolioOptimizerId]);

  const calculateWidth = (subaccounts: any[]) => {
    if (subaccounts.length < 3) {
      return 'auto';
    }
    const width = 100 / subaccounts.length;
    return `${width}%`;
  };

  const handleAutoFillToTarget = (holdingsArray: any) => {
    const nonCashHoldings = holdingsArray.filter((h: any) => !h.isCash);
    if (nonCashHoldings.length > 0) {
      nonCashHoldings.map((h: any) => handleHoldingChange(h, h.subaccounts[0], `${(round(h.targetCents / 100, 2))}`));
    }
  };

  const handleHoldingChange = (holding: any, sub: any, value: string) => {
    const newValue = round(parseFloat(value) * 100, 2);
    const index = findIndex({ financialProductId: holding.id, subAccountId: sub.id }, draftTrades);
    const subTradeRequestId = initialDraftTrades[findIndex({ financialProductId: holding.id, subAccountId: sub.id }, initialDraftTrades)]?.id;

    const newDraftTrade = {
      ...(subTradeRequestId && { id: subTradeRequestId }),
      financialProductId: holding.id,
      ticker: holding.ticker,
      subAccountId: sub.id,
      accountName: sub.name,
      amountCents: round(newValue - (sub?.holding?.totalCents || 0), 2),
      type: newValue > (sub?.holding?.totalCents || 0) ? 'BUY' : 'SELL',
      isSellAll: value === '0',
      maxQuantity: sub?.holding?.quantity,
      isTemp: true,
    };

    if (index < 0) {
      if (newValue !== holding.totalCents) {
        const tradesToCancelIndex = findIndex({ id: subTradeRequestId, financialProductId: holding.id }, draftTradesToCancel);

        if (tradesToCancelIndex >= 0) {
          const newDraftTradesToCancel = [...draftTradesToCancel];
          newDraftTradesToCancel.splice(tradesToCancelIndex, 1);
          setDraftTradesToCancel(newDraftTradesToCancel);
        }

        setDraftTrades((oldArray: any[]) => [...oldArray, newDraftTrade]);
      }
    } else {
      const newTrades = [...draftTrades];
      if (newDraftTrade.amountCents === 0 && !sub?.holding?.totalCents) {
        newTrades.splice(index, 1);
      } else if (subTradeRequestId && sub?.holding?.totalCents) {
        if (newDraftTrade.amountCents === 0) {
          setDraftTradesToCancel((oldArray: any[]) => [...oldArray, draftTrades[index]]);
          newTrades.splice(index, 1);
        } else {
          newTrades.splice(index, 1, newDraftTrade);
        }
      } else {
        newTrades.splice(index, 1, newDraftTrade);
      }

      setDraftTrades(newTrades);
    }
  };

  const isDisableAmountField = (holding: any, subAccount: any): boolean => {
    if (subAccount.inactive) {
      return true;
    }

    if (localPortfolioOptimizerId && !activeOrganization.allowPostOptimizationEditing && !isForceNewBulkTradeRequest) {
      return true;
    }

    if (!portfolioOptimizerId && ['CANCELED', 'COMPLETED'].includes(activeBulkTradeObject?.state)) {
      return true;
    }

    const initialSubTradeRequest = initialSubTradeRequests[findIndex({ financialProductId: holding.id, subAccountId: subAccount.id }, initialSubTradeRequests)];

    return ['READY', 'REQUESTED', 'RECONCILED'].includes(initialSubTradeRequest?.state);
  };

  const findTrades = (holding: any, subAccount: any) => draftTrades.filter((trade: any) => {
    const found = trade.subAccountId === subAccount?.id && trade.financialProductId === holding?.id;
    return found;
  });

  const findProcessingTrades = (holding: any, subAccount: any) => processingTrades.filter((trade: any) => {
    const found = trade.subAccountId === subAccount?.id && trade.financialProductId === holding?.id;
    return found;
  });

  const totalTradeValue = (holding: any, subAccount: any) => round(sum([round(sum(findTrades(holding, subAccount).map((x: any) => x.amountCents)), 2), round(sum(findProcessingTrades(holding, subAccount).map((x: any) => x.amountCents)), 2)]), 2);

  const isSellAll = (holding: any, subAccount: any) => findTrades(holding, subAccount)[0]?.isSellAll;

  const totalCents = (holding: any): number => (holding?.totalCents || 0) + totalTrades(holding);

  const totalTrades = (holding: any): number => [...draftTrades, ...processingTrades].filter((trade: any) => trade.financialProductId === holding.id).reduce((acc: number, trade: any) => acc + trade.amountCents, 0);

  const subCashDiff = (sub: any, isTempOnly = false) => {
    const localDraftTrades = (draftTrades.length && draftTrades) || [];
    const localProcessingTrades = (processingTrades.length && processingTrades) || [];

    let trades = [...localDraftTrades, ...localProcessingTrades].filter((trade: any) => trade.subAccountId === sub.id);

    if (isTempOnly) {
      trades = trades.filter((trade: any) => trade.isTemp);
    }

    return trades.reduce((acc: number, trade: any) => acc + trade.amountCents, 0);
  };

  const totalCashDiff = (isTempOnly = false) => {
    const localDraftTrades = (draftTrades.length && draftTrades) || [];
    const localProcessingTrades = (processingTrades.length && processingTrades) || [];

    let trades = [...localDraftTrades, ...localProcessingTrades];

    if (isTempOnly) {
      trades = trades.filter((trade: any) => trade.isTemp);
    }

    return trades.reduce((acc: number, trade: any) => acc + trade.amountCents, 0);
  };

  const negativeCash = (): boolean => {
    const cashHolding = holdings.find((holding: any) => holding.isCash);
    const negativeCashes = cashHolding?.subaccounts?.map((sub: any) => (sub?.holding?.totalCents || 0) - subCashDiff(sub)).filter((cents: number) => cents < 0);
    return negativeCashes?.length > 0;
  };

  if (loading || statsLoading || subTradeLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ m: 18 }} />
      </Box>
    );
  }

  const getAmountFieldInputColor = (holding: any, sub: any) => {
    if (Math.abs(totalTradeValue(holding, sub)) <= 0) return colors.black;

    let foundTrades = findTrades(holding, sub);

    if (foundTrades.length <= 0) {
      foundTrades = findProcessingTrades(holding, sub);
    }

    return (foundTrades[0]?.type === 'BUY' ? colors.success : colors.error) ?? colors.black;
  };

  const isConfirmTradesDisabled = () => {
    if (localPortfolioOptimizerId && !activeOrganization.allowPostOptimizationEditing) {
      return true;
    }

    return localPortfolioOptimizerId && isDraftTradesNotEmpty && !negativeCash() ? false : !isDraftTradesNotEmpty || negativeCash() || !!activeBulkTrade;
  };

  const isExtendTableRow = !!processingTrades.length || !!draftTrades.length;

  const totalOfCurrent = holdings.reduce((prev: number, holding: any) => {
    if (!holding.isCash) {
      return prev + totalCents(holding);
    }

    return prev + (((holding?.cashAvailableForTradeCents ?? totalCents(holding)) || 0) - totalCashDiff(true));
  }, 0);

  const totalOfDeviation = holdings.reduce((prev: number, holding: any) => {
    if (!holding.isCash) {
      return prev + (Math.round(totalCents(holding)) - Math.round(holding?.expectedValueCents ?? holding.targetCents));
    }

    return prev + (holding?.cashAvailableForTradeCents && holding?.expectedCashCents ? ((holding.cashAvailableForTradeCents - totalCashDiff(true)) - holding.expectedCashCents) : (totalCents(holding) - totalCashDiff() - holding.targetCents));
  }, 0);

  return localPortfolioOptimizerId && isErrorOccurred ? (
    <>
      <Typography variant='body1' color={colors.error}>
        {t('bulkTradeRequestIsNotFound')}
      </Typography>
    </>
  ) : (
    <>
      <Card sx={{ overflowX: 'auto' }}>
        <Box p={2} justifyContent='space-between' display='flex'>
          <div>
            <Typography fontWeight={600} fontSize='20px'>
              {t('portfolio')}
            </Typography>

            <Switch size="small" label={t('viewPreTradePositions')} onChange={(e) => setViewPreTradePositions(e)} />
          </div>

          <div>
            <Grid>
              <Button
                variant='outlined'
                sx={{ mr: '10px' }}
                disabled={localPortfolioOptimizerId && isLocalTradesUpdated ? false : !isLocalTradesUpdated || !!activeBulkTrade}
                onClick={() => {
                  setAutoFilled(false);
                  setDraftTrades(localPortfolioOptimizerId ? initialDraftTrades : []);
                }}
              >
                {t('revert')}
              </Button>

              {subAccounts.length === 1 && (
                <Button
                  variant='outlined'
                  sx={{ mr: '10px' }}
                  disabled={autoFilled || !!activeBulkTrade}
                  onClick={() => {
                    if (!autoFilled) {
                      setAutoFilled(true);
                      mapHoldings();
                      handleAutoFillToTarget(holdings);
                    }
                  }}
                >
                  {t('autoFillToTarget')}
                </Button>
              )}

              <GenerateTradesModal
                trades={draftTrades.filter((trade: any) => trade.amountCents !== 0)}
                tradesToCancel={draftTradesToCancel}
                afterCreate={(bulkTradeId: string) => {
                  if (localPortfolioOptimizerId) {
                    refetchBulkTrades();
                    setIsRefetchSubTradeRequests(true);
                  } else {
                    refetchBulkTrades();
                    setActiveBulkTrade(bulkTradeId);

                    if (activeOrganization?.allowViewSubTradeRequestPairs && permissions.includes('write:sub_trade_request')) {
                      setOpenTradePairs(true);
                    }
                  }

                  if (setIsRefetchRequired) {
                    setIsRefetchRequired(true);
                  }
                }}
                type={type}
                id={id}
                modalOpenDisabled={isConfirmTradesDisabled()}
                bulkTradeId={localPortfolioOptimizerId && activeBulkTrade}
                portfolioOptimizerId={localPortfolioOptimizerId}
                bulkTradeRequest={activeBulkTradeObject}
                onNewBulkTradeRequestCreated={() => {
                  setIsShowLastOptimizedBy(false);
                }}
              />

              {activeOrganization?.allowViewSubTradeRequestPairs && permissions.includes('write:sub_trade_request') && (
                <Button variant='outlined' sx={{ ml: '10px' }} disabled={!initialSubTradeRequests.length} onClick={() => setOpenTradePairs(true)}>
                  {t('showPairs')}
                </Button>
              )}
            </Grid>

            <Grid pt={1} textAlign={'right'}>
              {!!portfolioReport?.updatedAt && (
                <Typography variant='body2' color={sys.color.onSurfaceVariant}>
                  {t('portfolioLastUpdated')} {localizedDateTime(portfolioReport?.updatedAt)}
                </Typography>
              )}
            </Grid>
          </div>
        </Box>
        <Grid container>
          <Grid item xs='auto'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='overline'>{t('portfolioTable.security')}</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography variant='overline'>{t('portfolioTable.target')}</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography variant='overline'>{t('portfolioTable.current')}</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderRight: '1px solid grey' }}>
                    <Typography variant='overline'>{t('portfolioTable.deviation')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {holdings.map((holding: any) => (
                  <TableRow key={holding.ticker} sx={{ height: viewPreTradePositions && isExtendTableRow ? '75px' : '60px' }}>
                    <TableCell>
                      {holding.taxRank && <TaxRank taxRank={holding.taxRank} />}

                      <Typography variant='caption'>
                        <Tooltip title={holding.translatedName}>{holding.ticker}</Tooltip>
                        <br />
                        {holding.currentPriceCents ? formatMoneyValue(holding.currentPriceCents) : null}
                      </Typography>
                    </TableCell>

                    {!holding.isCash ? (
                      <>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <SmallDollarPercentage cents={holding?.expectedValueCents ?? holding.targetCents} percentage={holding.expectedPercentage ?? holding.targetPercentage} />
                        </TableCell>

                        <TableCell sx={{ textAlign: 'right' }}>
                          <Grid container justifyContent='end' alignItems='center' wrap='nowrap'>
                            <Grid item>
                              <SmallDollarPercentage cents={totalCents(holding)} showPercentage={false} />
                            </Grid>

                            <ChangeTooltip change={totalTrades(holding)} original={holding.totalCents} total />
                          </Grid>
                        </TableCell>

                        <TableCell sx={{ textAlign: 'right', borderRight: '1px solid grey' }}>
                          <SmallDollarPercentage
                            showColor
                            cents={Math.round(totalCents(holding)) - Math.round(holding?.expectedValueCents ?? holding.targetCents)}
                            percentage={(totalCents(holding) - (holding?.expectedValueCents ?? holding.targetCents)) / (holding?.expectedValueCents ?? holding.targetCents)}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <SmallDollarPercentage cents={holding?.expectedCashCents ?? holding.targetCents} percentage={holding.expectedPercentage ?? holding.targetPercentage} />
                        </TableCell>

                        <TableCell sx={{ textAlign: 'right' }}>
                          <Grid container justifyContent='end' alignItems='center' wrap='nowrap'>
                            <Grid item>
                              <SmallDollarPercentage cents={(totalCents(holding) || 0) - totalCashDiff()} showPercentage={false} />
                            </Grid>

                            <ChangeTooltip change={totalCashDiff() * -1} original={holding?.cashAvailableForTradeCents} flip total />
                          </Grid>
                        </TableCell>

                        <TableCell sx={{ textAlign: 'right', borderRight: '1px solid grey' }}>
                          <SmallDollarPercentage
                            showColor
                            isRedColor={isCashRebalanceRequired}
                            cents={holding?.cashAvailableForTradeCents && holding?.expectedCashCents ? ((holding.cashAvailableForTradeCents - totalCashDiff(true)) - holding.expectedCashCents) : (totalCents(holding) - totalCashDiff() - holding.targetCents)}
                            percentage={holding?.cashAvailableForTradeCents && holding?.expectedCashCents ? (((holding.cashAvailableForTradeCents - totalCashDiff(true)) / holding.expectedCashCents) - 1) : ((totalCents(holding) - totalCashDiff() - holding.targetCents) / holding.targetCents)}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>{t('portfolioTable.total')}</TableCell>
                  <TableCell />
                  <TableCell sx={{ textAlign: 'right' }}> {/* sum of current */}
                    <SmallDollarPercentage bold cents={totalOfCurrent} showPercentage={false} />
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderRight: '1px solid grey' }}> {/* sum of deviation */}
                    <SmallDollarPercentage bold cents={totalOfDeviation} showPercentage={false} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item sx={{ overflowX: 'auto' }} xs>
            <Table>
              <TableHead>
                <TableRow>
                  {subAccounts.map((sub: any) => (
                    <TableCell key={sub.id} sx={{ width: calculateWidth(subAccounts), whiteSpace: 'nowrap' }}>
                      <TaxRank taxRank={sub.taxRank || 0} />

                      <Typography variant='overline' sx={{ whiteSpace: 'nowrap' }}>
                        {sub.name}
                      </Typography>

                      {!!sub.inactive && (
                        <Tooltip title={t('portfolioTable.subAccountIsInactive')}>
                          <LockIcon fontSize='small' sx={{ verticalAlign: 'text-top' }} />
                        </Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {holdings.map((holding: any) => (
                  <TableRow key={holding.ticker} sx={{ height: viewPreTradePositions && isExtendTableRow ? '75px' : '60px' }}>
                    {holding.subaccounts.map((sub: any) => (
                      <TableCell key={sub.id}>
                        {holding.isCash ? (
                          <Box justifyContent='start' flexDirection='row' display='flex' alignItems='center' mr={1}>
                            <Box>
                              {sub?.pendingWithdrawCents > 0 || sub?.pendingContributionCents < 0 ? (
                                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{`Pending withdraw: ${formatMoneyValue((sub?.pendingWithdrawCents ?? sub?.pendingContributionCents) || 0)}`}</div>}>
                                  <Typography variant='caption' sx={{ fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap', color: colors.warning }}>
                                    {t('portfolioTable.target')}:{' '}
                                    <b>{formatMoneyValue((sub?.expectedCashCents ?? (((sub?.totalMarketValue || 0) + (sub?.pendingContributionCents || 0)) * holding.targetPercentage - (sub?.pendingContributionCents || 0))) || 0)}</b>
                                    <br />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography variant='caption' sx={{ fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap' }}>
                                  {t('portfolioTable.target')}: <b>{formatMoneyValue((sub?.expectedCashCents ?? ((sub?.totalMarketValue || 0) * holding.targetPercentage)) || 0)}</b>
                                  <br />
                                </Typography>
                              )}

                              <Typography variant='caption' sx={{ fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap' }}>
                                {t('portfolioTable.current')}: <b>{sub?.cashAvailableForTradeCents ? formatMoneyValue((sub.cashAvailableForTradeCents - subCashDiff(sub, true)) || 0) : formatMoneyValue(((sub?.holding?.totalCents || 0) - subCashDiff(sub)) || 0)}</b>
                              </Typography>
                            </Box>

                            <ChangeTooltip change={subCashDiff(sub) * -1} original={sub?.holding?.totalCents || 0} flip total />
                          </Box>
                        ) : (
                          <AmountField
                            useFormatAmountValueCents
                            variant='outlined'
                            value={(isSellAll(holding, sub) ? 0 : (totalTradeValue(holding, sub) || 0) + (sub?.holding?.totalCents || 0) || 0)}
                            size='small'
                            disabled={localPortfolioOptimizerId ? isDisableAmountField(holding, sub) : (sub.inactive || !!activeBulkTrade)}
                            sx={{
                              border: `1px solid ${Math.abs(totalTradeValue(holding, sub)) > 0 ? colors.warning : 'default'}`,
                              borderRadius: '4px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '12px',
                                width: '65px',
                                color: getAmountFieldInputColor(holding, sub),
                                fontVariantNumeric: 'tabular-nums',
                              },
                            }}
                            InputProps={{
                              endAdornment: <ChangeTooltip change={totalTradeValue(holding, sub) || 0} original={sub?.holding?.totalCents || 0} isSellAll={isSellAll(holding, sub)} />,
                            }}
                            onChange={(e: any) => {
                              handleHoldingChange(holding, sub, e.target.value);
                            }}
                          />
                        )}

                        {!holding.isCash && viewPreTradePositions && Math.abs(totalTradeValue(holding, sub)) > 0 && <Typography variant="caption" display="block">Original: {formatMoneyValue(sub?.holding?.totalCents || 0)}</Typography>}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                <TableRow>
                  {subAccounts.map((sub: any) => (
                    <TableCell key={sub.id}>
                      <SmallDollarPercentage bold cents={sub.marketValueCents} showPercentage={false} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Card>

      <BulkTradeRequests
        bulkTradeRequestId={activeBulkTrade || undefined}
        bulkTradeRequest={activeBulkTradeObject}
        isRefetch={isRefetchSubTradeRequests}
        isCancelSubTradesPerPortfolioReport={!!localPortfolioOptimizerId}
        sourceId={localPortfolioOptimizerId ? id : undefined}
        isCancelDisabled={localPortfolioOptimizerId ? !initialDraftTrades.length : !!processingTrades.length}
        portfolioOptimizerCreatedAt={isShowLastOptimizedBy ? portfolioReport?.lastOptimizedBy?.createdAt : undefined}
        afterCancel={async () => {
          // await the update of a portfolio report to sync
          await delay(500);
          refetchBulkTrades();

          if (localPortfolioOptimizerId) {
            setIsRefetchSubTradeRequests(true);
          } else {
            setDraftTrades([]);
            setInitialSubTradeRequests([]);
            setActiveBulkTrade(undefined);
          }

          if (setIsRefetchRequired) {
            setIsRefetchRequired(true);
          }
        }}
      />

      <SubTradeRequestPairsModal
        open={openTradePairs}
        onClose={() => setOpenTradePairs(false)}
        bulkTradeRequestId={activeBulkTrade || undefined}
        sourceId={localPortfolioOptimizerId ? id : undefined}
        refetch={refetch}
      />
    </>
  );
};

export default PortfolioTable;
