import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_SUB_ACCOUNTS } from '../../../components/cards/subAccounts';
import { FETCH_SUB_ACCOUNT } from '../../subAccountsDetail';
import { usePermissions } from '../../../providers/userContextProvider';
import EditBillingAndFeeTier from './editBillingAndFeeTier';

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        skipIPS
        allowClientDeposits
      }
    }
  }
`;

const EditSubAccountBilling = ({
  afterUpdate, subAccountToUpdate, open, handleClose, highlight,
}: { afterUpdate: () => void, subAccountToUpdate: any, open: boolean, handleClose: () => void, highlight?: string }) => {
  const { permissions } = usePermissions();
  // const [subAccount, setSubAccount] = useState(subAccountToUpdate);
  const [localOpen, setLocalOpen] = useState(open);

  const [updateSubAccount, { loading }] = useMutation(UPDATE_SUB_ACCOUNT, {
    variables: {
      input: {},
    },
    refetchQueries: [FETCH_SUB_ACCOUNTS(permissions), FETCH_SUB_ACCOUNT(permissions)],
  });

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const update = async (event: any, changeableApplicableFeeTier: string, changeableApplicableBillingSchedule: string) => {
    event.preventDefault();
    await updateSubAccount({
      variables: {
        input: {
          subAccountId: subAccountToUpdate?.id,
          feeTierId: changeableApplicableFeeTier === 'inherited' ? null : changeableApplicableFeeTier,
          billingScheduleId: changeableApplicableBillingSchedule === 'inherited' ? null : changeableApplicableBillingSchedule,
        },
      },
    });
    afterUpdate();
  };

  if (!subAccountToUpdate) {
    return <></>;
  }

  return (
    <EditBillingAndFeeTier
      open={localOpen}
      setOpen={(value) => {
        setLocalOpen(value);
        handleClose();
      }}
      loading={loading}
      highlight={highlight}
      organizationId={subAccountToUpdate.account.user.organization.id}
      feeTierId={subAccountToUpdate.feeTier?.id ?? undefined}
      applicableFeeTierId={subAccountToUpdate.applicableFeeTier?.id}
      billingScheduleId={subAccountToUpdate.billingSchedule?.id ?? undefined}
      applicableBillingScheduleId={subAccountToUpdate.applicableBillingSchedule?.id}
      callback={(event, applicableFeeTierId, applicableBillingScheduleId) => update(event, applicableFeeTierId, applicableBillingScheduleId)}
    />
  );
};

export default EditSubAccountBilling;
