import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const FETCH_GOAL_NAME = gql`
  query fetchGoalName($id:ObjectID!) {
    fetchGoal(goalId:$id) {
      goal { id name type }
    }
  }
`;

const GoalLabel = ({ goalId }: { goalId: string }) => {
  const { t } = useTranslation(['client']);
  const { data } = useQuery(FETCH_GOAL_NAME, { variables: { id: goalId } });
  const goal = data?.fetchGoal?.goal;
  const name = goal?.name || (goal?.type ? t(`client:goalType.${goal.type}`) : '');
  return <>{t('goal')}:&nbsp;{name}</>;
};

export default GoalLabel;
