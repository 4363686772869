import {
  Menu, MenuItem, IconButton, Button, Table, Card, TableBody, TableCell, TableHeadCell, TableRow, CardContent, Pagination, Box, Skeleton,
  BetaBadge,
} from 'ovComponents';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { translateBackend } from 'assets/i18n/config';
import { ClientReportTemplate, ClientReportTransitions } from 'interfaces';
import AddIcon from '@mui/icons-material/AddRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import NewClientReportTemplate from './newClientReportTemplate';

const FETCH_CLIENT_REPORT_TEMPLATES = gql`
  query fetchClientReportTemplates($input: FetchClientReportTemplatesInput!) {
    fetchClientReportTemplates(input: $input) {
      clientReportTemplates {
        id
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        type
        scheduler {
          frequency
        }
        objectType
        state
        reportType
      }
      totalCount
    }
  }
`;

const TRANSITION_CLIENT_REPORT_TEMPLATE = gql`
  mutation transitionClientReportTemplate($clientReportTemplateId: ObjectID!, $transition: ClientReportTemplateTransitions!) {
    transitionClientReportTemplate(clientReportTemplateId: $clientReportTemplateId, transition: $transition) {
      clientReportTemplate {
        id
        state
      }
    }
  }
`;
const ClientReportTemplates = () => {
  const { t } = useTranslation(['orgSettings']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [sortField, setSortField] = usePageState('name', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const [selectedRow, setSelectedRow] = useState<null | ClientReportTemplate>(null);
  const [openNewReportTemplate, setOpenNewReportTemplate] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeOrganization } = useContext(UserContext);
  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_CLIENT_REPORT_TEMPLATES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          states: ['ACTIVE', 'DRAFT', 'INACTIVE'],
        },
        pagination: {
          sortField,
          sortDesc,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionClientReportTemplate] = useMutation(TRANSITION_CLIENT_REPORT_TEMPLATE, {
    onCompleted: () => refetch(),
  });

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? (sortDesc ? 'desc' : 'asc') : undefined);

  const isHtmlReport = selectedRow?.reportType === 'HTML';

  return (
    <>
      <Card>
        <CardContent>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
              <BetaBadge/>
              <Button variant='filled' leadingIcon={AddIcon} label={t('clientReportTemplates.new')} onClick={() => setOpenNewReportTemplate(true)} />
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableBody>
              <TableRow>
                <TableHeadCell isFirst isSortable onClick={() => onSort('translatedName')} sortDirection={sortDirection('translatedName')}>
                  {t('shared:name')}
                </TableHeadCell>
                <TableHeadCell>{t('shared:description')}</TableHeadCell>
                <TableHeadCell>{t('clientReportTemplates.frequency')}</TableHeadCell>
                <TableHeadCell isSortable onClick={() => onSort('objectType')} sortDirection={sortDirection('objectType')}>
                  {t('clientReportTemplates.association')}
                </TableHeadCell>
                <TableHeadCell>{t('shared:state')}</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
              {loading
                && !previousData
                && [...Array(15)].map((x: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                  </TableRow>
                ))}
              {data?.fetchClientReportTemplates?.clientReportTemplates?.map((clientReportTemplate: ClientReportTemplate, index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  hover
                  onClick={() => {
                    navigate(`clientReportTemplate/${clientReportTemplate.id}`);
                  }}
                >
                  <TableCell isFirst>{translateBackend(clientReportTemplate.translatedName)}</TableCell>
                  <TableCell>{translateBackend(clientReportTemplate.translatedDescription)}</TableCell>
                  <TableCell>{t(`clientReportTemplates.frequencies.${clientReportTemplate.scheduler.frequency}`)}</TableCell>
                  <TableCell>{t(`clientReportTemplates.associations.${clientReportTemplate.objectType}`)}</TableCell>
                  <TableCell>{t(`clientReportTemplates.states.${clientReportTemplate.state}`)}</TableCell>
                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        setSelectedRow(clientReportTemplate);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchClientReportTemplates?.totalCount ?? 0) / pageSize)}
              page={page}
              perPage={pageSize}
              onChange={(_e, newPage) => setPage(newPage)}
              onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Table>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {isHtmlReport && (
            <MenuItem disabled>
              <VisibilityIcon />
              &nbsp; Preview
            </MenuItem>
          )}
          {(selectedRow?.state === 'INACTIVE' || selectedRow?.state === 'DRAFT') && (
            <MenuItem onClick={() => transitionClientReportTemplate({ variables: { clientReportTemplateId: selectedRow?.id, transition: ClientReportTransitions.ACTIVATE } })}>
              {t('clientReportTemplate:updateState.activate')}
            </MenuItem>
          )}
          {(selectedRow?.state === 'ACTIVE' || selectedRow?.state === 'DRAFT') && (
            <MenuItem onClick={() => transitionClientReportTemplate({ variables: { clientReportTemplateId: selectedRow?.id, transition: ClientReportTransitions.DEACTIVATE } })}>
              {t('clientReportTemplate:updateState.deactivate')}
            </MenuItem>
          )}
          {selectedRow?.state === 'ACTIVE' && (
            <MenuItem onClick={() => transitionClientReportTemplate({ variables: { clientReportTemplateId: selectedRow?.id, transition: ClientReportTransitions.REVIEW } })}>
              {t('clientReportTemplate:updateState.review')}
            </MenuItem>
          )}
          <MenuItem onClick={() => transitionClientReportTemplate({ variables: { clientReportTemplateId: selectedRow?.id, transition: ClientReportTransitions.ARCHIVE } })}>
            {t('clientReportTemplate:updateState.archive')}
          </MenuItem>
        </Menu>
      </Card>
      <NewClientReportTemplate open={openNewReportTemplate} setOpen={setOpenNewReportTemplate} afterCreate={refetch} />
    </>
  );
};

export default ClientReportTemplates;
