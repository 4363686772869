import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface SelectionTileTokens {
  selectionTile: {
    borderColor?: string;
    borderRadius?: string;
    hoverBorderColor?: string;
    hoverBackgroundColor?: string;
    subtitleColor?: string;
  },
}

export const useSelectionTileTokens = (): SelectionTileTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: SelectionTileTokens = {
    selectionTile: {
      borderColor: sys.color.outline,
      borderRadius: sys.borderRadius.xl,
      hoverBorderColor: sys.color.outline,
      hoverBackgroundColor: sys.color.surfaceContainer,
      subtitleColor: sys.color.onSurfaceVariant,
    },
  };

  return comp;
};
