import {
  TextField, Grid, Box, Button, MenuItem, FormGroup, FormControlLabel, Switch,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  pick, filter, map, intersection, pickBy, isNull,
} from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { usePermissions } from '../../../providers/userContextProvider';
import { Field } from './profile';
import AddressField from '../../../components/inputs/addressField';
import TaxInformationField from '../../../components/inputs/taxInformationField';
import CitizenField from '../../../components/inputs/citizenField';
import AmountField from '../../../components/inputs/amountField';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const showField = (field: Field, permissions: string[], user: any) => {
  let show = true;
  if (!permissions.includes(`read:${field.permission}`)) { show = false; }
  (field.showIf ? Object.keys(field.showIf) : []).forEach((x: any) => {
    if (!field.showIf[x].includes(user[x])) {
      show = false;
    }
  });
  return show;
};

const UpdateUserSection = ({
  baseUser, refetch, fields, open, sectionName,
}: { baseUser: any, refetch: () => void, fields: Field[], open: boolean, sectionName?: 'details' | 'employment' | 'compliance' | 'suitability' }) => {
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const updateableFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));
  const emptyObject: { [key: string]: string | boolean | number | string[] | any } = {};
  fields.forEach((x: Field) => {
    if (x.type === 'text_array') {
      emptyObject[x.key] = [];
    }
    emptyObject[x.key] = '';
  });
  const { t } = useTranslation(['client']);

  const [user, setUser] = useState(emptyObject);
  const [isDisabled, setDisabled] = useState(false);
  const [fieldToShow, setFieldsToShow] = useState(fields.filter((field) => showField(field, permissions, user)));

  useEffect(() => {
    setUser(baseUser);
  }, [baseUser]);

  useEffect(() => {
    const failedValidation = fields.filter((field) => field.validation
      && field.validation?.rule && !field.validation?.rule.test(user[field.key] ?? '') && ((!field.dontFetch) || (field.dontFetch && user[field.key])));
    setDisabled(failedValidation.length !== 0);
  }, [user, fields]);

  useEffect(() => {
    updateFieldsToShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, permissions]);

  const handleToast = (): void => {
    if (sectionName === 'details') {
      showToast({ message: t('client:profile.successMessages.details'), severity: 'info' });
    } else if (sectionName === 'employment') {
      showToast({ message: t('client:profile.successMessages.employment'), severity: 'info' });
    } else if (sectionName === 'compliance') {
      showToast({ message: t('client:profile.successMessages.compliance'), severity: 'info' });
    } else if (sectionName === 'suitability') {
      showToast({ message: t('client:profile.successMessages.suitability'), severity: 'info' });
    }
  };

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        userId: baseUser.id,
        ...pick(intersection(updateableFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), user)),
        physicalAddress: user.physicalAddress ? {
          ...user.physicalAddress,
          __typename: undefined,
        } : undefined,
      },
    },
    onCompleted: () => handleToast(),
  });

  const updateFieldsToShow = (newUser?: any) => {
    const newFields = fields.filter((field) => showField(field, permissions, newUser || user));
    setFieldsToShow(newFields);
  };

  if (!user) (<></>);

  return (
    <Box
      sx={{ width: '100%' }}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={async (e: any) => {
        e.preventDefault();
        await updateUser();
        refetch();
      }
      }
    >
      <Grid container spacing={2}>
        {
          fieldToShow.map((field) => (
            <Grid item xs={field.column} key={field.key}>
              {field.type === 'date' ? (
                <LocalizedDatePicker
                  readOnly={!permissions.includes(`write:${field.permission}`)}
                  label={t(`details.${field.key}`)}
                  value={user[field.key]}
                  onChange={(date) => setUser({ ...user, [field.key]: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              ) : field.type === 'select' ? (
                <TextField
                  select
                  value={user[field.key] ?? ''}
                  label={t(`details.${field.key}`)}
                  fullWidth
                  InputProps={{
                    readOnly: !permissions.includes(`write:${field.permission}`),
                  }}
                  onChange={async (event) => {
                    const newUser = { ...user, [field.key]: event.target.value };
                    await setUser(newUser);
                    updateFieldsToShow(newUser);
                  }}
                >
                  {
                    field.options.map((key: string) => (
                      <MenuItem key={key} value={key}>{t(`edit.${field.key}Options.${key}`)}</MenuItem>
                    ))
                  }
                </TextField>
              ) : field.type === 'switch' ? (
                <FormGroup>
                  <FormControlLabel
                    control={<Switch
                      checked={Boolean(user[field.key]) || false}
                      onChange={async (event) => {
                        if (!permissions.includes(`write:${field.permission}`)) { return; }
                        const newUser = { ...user, [field.key]: event.target.checked };
                        await setUser(newUser);
                        updateFieldsToShow(newUser);
                      }}
                    />}
                    label={t(`details.${field.key}`)}
                  />
                </FormGroup>
              ) : field.type === 'text_array' ? (
                <TextField label={t(`details.${field.key}`)} variant="outlined" value={(user[field.key] ?? '')} fullWidth
                  onChange={async (e) => {
                    const newUser = { ...user, [field.key]: e.target.value.split(',') };
                    await setUser(newUser);
                    updateFieldsToShow(newUser);
                  }}
                  placeholder={field.placeholder}
                  type='text_array'
                />
              ) : field.type === 'address' ? (
                <AddressField
                  label={t(`client:details.${field.key}`)}
                  readonly={!permissions.includes(`write:${field.permission}`)}
                  address={user[field.key]}
                  onChange={async (e) => {
                    const newUser = { ...user, [field.key]: e };
                    await setUser(newUser as any);
                    updateFieldsToShow(newUser);
                  }}
                />
              ) : field.type === 'foreignTax' ? (
                <TaxInformationField
                  readonly={!permissions.includes(`write:${field.permission}`)}
                  taxInformation={user[field.key]}
                  countryOfTaxResidence={user.countryOfTaxResidence}
                  onChange={async (e) => {
                    const newUser = { ...user, [field.key]: e };
                    await setUser(newUser as any);
                    updateFieldsToShow(newUser);
                  }}
                />
              ) : field.type === 'citizenship' ? (
                <CitizenField
                  citizenships={user[field.key]}
                  readonly={!permissions.includes(`write:${field.permission}`)}
                  onChange={async (e) => {
                    const newUser = { ...user, [field.key]: e };
                    await setUser(newUser as any);
                    updateFieldsToShow(newUser);
                  }}
                />
              ) : field.type === 'cents' ? (
                <AmountField
                  label={t(`details.${field.key}`)}
                  variant="outlined"
                  value={user[field.key]}
                  fullWidth
                  onChange={(e: any) => {
                    setUser({ ...user, [field.key]: e.target.valueCents });
                  }}
                  placeholder={field.placeholder}
                  InputProps={{
                    readOnly: !permissions.includes(`write:${field.permission}`),
                  }}
                />
              ) : (
                <TextField
                  label={t(`details.${field.key}`)}
                  variant="outlined"
                  value={(user[field.key] ?? '')}
                  fullWidth
                  onChange={(e) => setUser({ ...user, [field.key]: (field.type === 'cents' ? (parseFloat(e.target.value) * 100) : e.target.value) })}
                  placeholder={field.placeholder}
                  error={field.validation
                    && field.validation?.rule && ((field.dontFetch && user[field.key]) || !field.dontFetch) ? !field.validation?.rule.test((user[field.key] ?? '')) : false}
                  helperText={
                    field.validation
                    && field.validation?.rule && ((field.dontFetch && user[field.key]) || !field.dontFetch) && !field.validation?.rule.test((user[field.key] ?? '')) ? t(field.validation.message) : ''
                  }
                  type='text'
                  InputProps={{
                    readOnly: !permissions.includes(`write:${field.permission}`),
                  }} />
              )}
            </Grid>
          ))
        }
      </Grid>
      {updateableFields.length > 0 && (
        <Button type='submit' disabled={loading || isDisabled} variant='contained' sx={{ float: 'right', m: ' 16px 0px' }}>
          {t('form.update')}
        </Button>
      )}
    </Box>
  );
};

export default UpdateUserSection;
