import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { ClientGroup, ClientGroupTypes } from 'interfaces/clientGroup';
import { WorkflowContext } from 'ovComponents/4-module/workflowCompletion/workflowCompletion';
import { first, isUndefined } from 'lodash/fp';
import { Typography } from '../../../../../1-primative';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import { useThemeTokens } from '../../../../../../providers/themeTokenProvider';
import { HouseholdSelectionModal } from './householdSelectionModal';

const FETCH_USER = gql`
  query fetchUserType($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        households {
          id
          type
          name
        }
      }
    }
  }
`;

export enum ObjectTypes {
  GOAL = 'GOAL',
  ACCOUNT = 'ACCOUNT',
}

export enum WorkflowActions {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export const HouseholdSelection = ({
  updateMode = false, editable = true,
  refetch,
  data,
  updateData,
  objectType,
  infoTooltip,
}: {
  refetch?: () => void,
  updateMode?: boolean, editable?: boolean,
  data?: any,
  updateData?: any,
  objectType: ObjectTypes,
  infoTooltip?: string,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<WorkflowActions>(WorkflowActions.CREATE);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { sys } = useThemeTokens();

  const { data: userData } = useQuery(FETCH_USER, {
    variables: {
      userId: workflowData.currentUserId,
    },
    fetchPolicy: 'no-cache',
    skip: !workflowData.currentUserId,
  });
  let households: Partial<ClientGroup>[] = [];
  if (userData?.fetchUser.user?.households) {
    households = userData?.fetchUser.user?.households.filter((a: any) => a.type === ClientGroupTypes.Household);
  }

  const hasHouseholdClientGroupAssigned = !isUndefined(data?.householdClientGroupId) && data?.householdClientGroupId !== '';

  let householdName = '';
  if (hasHouseholdClientGroupAssigned) {
    const currentHouseholds = households.filter((a: any) => (a.id === data?.householdClientGroupId));
    if (currentHouseholds && currentHouseholds.length > 0) {
      const selectedHouseholdName = first(currentHouseholds)?.name ?? '';
      householdName = selectedHouseholdName;
    }
  } else if (households && households.length === 1 && !workflowData.isHouseholdRemoved) {
    updateData({ ...data, householdClientGroupId: households[0].id });
    setWorkflowData({ ...workflowData, currentHouseholdClientGroupId: households[0].id });
  }

  return (
    <>
      <CompletionBox
        testId="household-completion"
        variant={updateMode ? 'view' : 'edit'}
        state={hasHouseholdClientGroupAssigned ? 'complete' : 'todo'}
        edittable={editable}
        infoTooltip={infoTooltip}
        title={t('householdSelection:title')}
        onEdit={() => {
          setAction(WorkflowActions.EDIT);
          setOpen(true);
        }}
        onRemove={() => {
          setWorkflowData({ ...workflowData, currentHouseholdClientGroupId: undefined, isHouseholdRemoved: true });
          updateData({ ...data, householdClientGroupId: undefined });
        }}
        onAdd={() => {
          setAction(WorkflowActions.CREATE);
          setOpen(true);
        }}
      >
        {hasHouseholdClientGroupAssigned ? (
          <>
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant }}>
                {t('householdSelection:name')}:
              </span>
              <b>{householdName ?? ''}</b>
            </Typography>
          </>
        ) : (
          <Typography variant='bodyMedium'>
            {t('householdSelection:body', { objectType: objectType.toLocaleLowerCase() })}
          </Typography>
        )}
      </CompletionBox>
      <HouseholdSelectionModal
        data-testid="household-selection"
        open={open}
        setOpen={setOpen}
        refetch={refetch}
        action={action}
        relatedHouseholds={households}
        data={data}
        updateData={updateData}
      />
    </>
  );
};
