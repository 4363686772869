import { gql, useMutation } from '@apollo/client';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  FinancialProduct, FinancialProductState, Theme, ThemeState,
} from '../../../interfaces';
import { usePermissions } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import ConfirmationModal from '../../../components/modals/confirmationModal';

const TRANSITION_THEME = gql`
  mutation transitionTheme($input: TransitionThemeInput!) {
    transitionTheme(input: $input) {
      theme {
        id
      }
    }
  }
`;

const TransitionTheme = ({ afterUpdate, theme, modelPortfolios }: { afterUpdate: () => void; theme: Theme; modelPortfolios: FinancialProduct[] }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('buildModels');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const { showToast } = useGlobalToast();

  const [transitionTheme] = useMutation(TRANSITION_THEME);

  const transition = (name: string) => {
    transitionTheme({
      variables: { input: { themeId: theme.id, transition: name } },
      onCompleted: () => {
        showToast({ message: t('transitionTheme.successfulTransition'), severity: 'success' });
        afterUpdate();
      },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleArchiveTransition = () => {
    if (modelPortfolios.length) {
      const activeModelPortfolios = modelPortfolios.filter((fp: FinancialProduct) => fp.state === FinancialProductState.ACTIVE);

      if (activeModelPortfolios.length) {
        showToast({ message: t('transitionTheme.unableToArchiveError', { numActivePortfolios: activeModelPortfolios.length }), severity: 'error' });

        return;
      }
    }

    setDeactivateConfirmationOpen(true);
  };

  return (
    <>
      {permissions.includes('transition:themes') && (
        <IconButton size='small' onClick={handleClick} sx={{ marginRight: 1 }}>
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {theme.state !== ThemeState.ACTIVE && <MenuItem onClick={() => transition('activate')}>{t('transition.activate')}</MenuItem>}
        {theme.state !== ThemeState.DRAFT && <MenuItem onClick={() => transition('draft')}>{t('transition.draft')}</MenuItem>}
        {theme.state !== ThemeState.ARCHIVED && <MenuItem onClick={() => handleArchiveTransition()}>{t('transition.archive')}</MenuItem>}
      </Menu>

      <ConfirmationModal
        open={deactivateConfirmationOpen}
        loading={false}
        title={t('transitionTheme.archiveConfirmationModal.title')}
        onConfirm={async () => {
          transition('archive');
          setDeactivateConfirmationOpen(false);
        }}
        onCancel={() => setDeactivateConfirmationOpen(false)}
        confirmButton={t('transitionTheme.archiveConfirmationModal.confirm')}
        cancelButton={t('transitionTheme.archiveConfirmationModal.cancel')}
      />
    </>
  );
};

export default TransitionTheme;
