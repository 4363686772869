/* eslint-disable no-param-reassign */
import { isNull } from 'lodash';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

const NavOrganizationList = ({
  treeItems, handleChange, selected, parentOrg,
}: { treeItems: any, handleChange: (event: any, nodeId: any) => void, selected: any, parentOrg: any }) => {
  const newData = treeItems.map((item: any) => ({ ...item }));

  const getTreeItemsFromData = (items: any) => items.map((treeItemData: any) => {
    let children;
    if (treeItemData.children && treeItemData.children.length > 0) {
      children = getTreeItemsFromData(treeItemData.children);
    }
    return (
        <TreeItem
          sx={{ p: 0.5 }}
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
        />
    );
  });

  const listToTree = (list: any) => {
    const hasParentNode: boolean = list.some((x: any) => isNull(x.parent));
    if (!hasParentNode) {
      const activeOrgIndex = list.findIndex(((x: any) => x.id === parentOrg));
      list[activeOrgIndex].parent = null;
    }

    const map: any = {}; let node: any; const roots = []; let
      i: number;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== null) {
        list[map[node.parent.id]]?.children?.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      selected={selected}
      defaultExpanded={[parentOrg]}
      onNodeSelect={handleChange}
    >
      {getTreeItemsFromData(listToTree(newData))}
    </TreeView>
  );
};

export default NavOrganizationList;
