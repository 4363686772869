import { gql, useLazyQuery } from '@apollo/client';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { generateClientNameString } from '../../util';
import { UserContext } from '../../providers/userContextProvider';
import { User } from '../../interfaces';
import { FulltextSearchSelect, SearchResults } from './fulltextSearchSelect';

const MAX_SEARCH_RESULTS = 12;

export const SEARCH_CLIENT = gql`
  query searchUsers($query: String!, $organizationId: ObjectID!, $affiliateUsersOnly: Boolean, $restrictToOrganizationId: Boolean) {
    searchUsers(input: {
      filter: { query: $query, organizationId: $organizationId, affiliateUsersOnly: $affiliateUsersOnly, restrictToOrganizationId: $restrictToOrganizationId }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      users {
        id
        type
        firstName middleName lastName entityName
        dateOfBirth establishedDate
        primaryEmail
        affiliateOnly
        sinExists
      }
    }
  }
`;

export const ClientSelect = ({
  user, setUser, label, autoFocus, fullWidth, isJointAccount, restrictToOrganizationId,
}: {
  user?: User, setUser: (user: User | undefined) => void, label: string, autoFocus?: boolean,
  fullWidth?: boolean, isJointAccount?: boolean, restrictToOrganizationId?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const [searchUsers] = useLazyQuery(SEARCH_CLIENT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const searchFn = (query:string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    searchUsers({
      variables: {
        query,
        organizationId: activeOrganization.id,
        affiliateUsersOnly: isJointAccount ? false : undefined,
        restrictToOrganizationId: !!restrictToOrganizationId,
      },
      onCompleted: (data) => {
        resolve({
          items: data.searchUsers.users,
          count: Number(data.searchUsers.totalCount),
        });
      },
      onError: () => {
        resolve({
          items: [],
          count: 0,
        });
      },
    });
  });

  const secondaryLabelYear = (u:User) => {
    const theDate = u.establishedDate ?? u.dateOfBirth;
    return theDate ? dayjs(theDate).format('YYYY') : '';
  };

  return (
    <FulltextSearchSelect
      label={label}
      item={user}
      setItem={(u) => setUser(u as User)}
      searchFn={searchFn}
      primaryLabelFn={generateClientNameString}
      secondaryLabelFn={secondaryLabelYear}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
    />
  );
};
