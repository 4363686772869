export enum BulkTradeRequestSources {
  GOAL = 'GOAL',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  REBALANCE = 'REBALANCE',
  PORTFOLIO_OPTIMIZER = 'PORTFOLIO_OPTIMIZER',
}

export enum BulkTradeRequestStates {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export interface BulkTradeRequest {
  id: string;
  source: BulkTradeRequestSources;
  sourceId: string;
  state: BulkTradeRequestStates;
  createdAt: Date;
  updatedAt: Date;
}
