import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  styled, tooltipClasses,
} from '@mui/material';
import { ReactNode } from 'react';
import { colors } from 'ovComponents/0-tokens';

interface TooltipFunctionalProps {
  placement?: MuiTooltipProps['placement']
  title: string | ReactNode
  children: React.ReactElement | React.ReactElement[] | string
  open?: boolean
}

interface TooltipVisualProps {
  resizing?: 'wrap' | 'fixedSmall' | 'fixedMedium' | 'fixedLarge';
}

export const Tooltip = (props: TooltipFunctionalProps & TooltipVisualProps) => (
  <ArrowTooltip {...props}>
    <div>
      {props.children}
    </div>
  </ArrowTooltip>
);

const ArrowTooltip = styled(({ className, ...props }: MuiTooltipProps & TooltipVisualProps) => (
  <MuiTooltip
    arrow
    {...props}
    classes={{ popper: className }}
    open={props.open}
  />
))((props) => {
  let width;
  if (props.resizing === 'fixedSmall') width = 150;
  if (props.resizing === 'fixedMedium') width = 300;
  if (props.resizing === 'fixedLarge') width = 500;

  return {
    [`& .${tooltipClasses.arrow}`]: {
      color: colors.neutral800,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '12px',
      backgroundColor: colors.neutral800,
      fontSize: 12,
      textAlign: 'center',
      width,
      maxWidth: width,
    },
  };
});
