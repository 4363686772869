import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Box, Skeleton } from '../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell,
  TableBody, Pagination, Button, IconButton, Card, CardContent,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';

const FETCH_REPORTS = gql`
  query fetchReports($input: FetchReportsInput!) {
    fetchReports(input: $input) {
      reports {
        id
        name
        accessType
        type
        author {
          firstName
          lastName
        }
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

const DELETE_REPORT = gql`
  mutation deleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      success
    }
  }
`;

export const Reports = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['customReports', 'shared']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_REPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [deleteReport] = useMutation(DELETE_REPORT, {
    onCompleted: refetch,
  });

  return (
    <Card loading={loading}>
      {permissions.includes('write:reports') && (
        <CardContent>
          <Box display='flex' justifyContent='end'>
            <Button
              component={Link}
              to='./new'
              leadingIcon={AddIcon}
              label={t('shared:add')}
            />
          </Box>
        </CardContent>
      )}
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('table.name')}</TableHeadCell>
              <TableHeadCell>{t('table.type')}</TableHeadCell>
              <TableHeadCell>{t('table.accessType')}</TableHeadCell>
              <TableHeadCell>{t('table.author')}</TableHeadCell>
              <TableHeadCell>{t('table.organization')}</TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
            {loading && [...Array(pageSize)].map((_, index) => (
              <TableRow key={index}>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchReports?.reports?.map((report: any) => (
              <TableRow
                hover
                key={report.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate(`/reports/${report.id}`)}
              >
                <TableCell component="th" scope="row">
                  {report.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {report.type}
                </TableCell>
                <TableCell component="th" scope="row">
                  {report.accessType}
                </TableCell>
                <TableCell component="th" scope="row">
                  {report.author.firstName} {report.author.lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {report.organization.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    deleteReport({
                      variables: {
                        input: {
                          reportId: report.id,
                        },
                      },
                    });
                  }} size='small'>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchReports?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};

export { CustomReportBreadcrumb } from './customReportBreadcrumb';
export default Reports;
