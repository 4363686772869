import {
  Box,
  Button,
  Checkbox, Chip, Dialog, DialogTitle, Grid, List, ListItem, TextField, Typography,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import _ from 'lodash';
import { countryList } from '../../ovComponents/resources';

const CitizenField = ({
  onChange, label, rows = 1, readonly = false, citizenships, singleSelect = false,
}: {
  onChange: ((citizenships: string[]) => void),
  label?: string,
  rows?: number,
  readonly?: boolean,
  singleSelect?: boolean,
  citizenships: string [],
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);
  const [citizenship, setCitizenship] = useState<string[]>(citizenships ? [...citizenships] : []);
  const [searchFilter, setSearchFilter] = useState('');

  const removeCountryFromList = (countryName: string | string[]): void => {
    const countryCode = countryList.find((country) => country.name === countryName)?.code ?? '';
    _.remove(citizenship, (item) => item === countryCode);
    setCitizenship([...citizenship]);
  };

  return (
    <>
      <Autocomplete
        multiple
        data-testid="citizenshipField"
        id="citizen-auto-complete"
        freeSolo
        value={citizenships.map((citizen) => countryList.find((country) => country.code === citizen)?.name ?? '')}
        options={[]}
        fullWidth
        readOnly={readonly}
        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              onDelete={undefined}
            />
        ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? t('components:citizenshipInput.label')}
            data-testid="citizenshipTextField"
            onClick={() => {
              if (!readonly) setOpen(true);
            }}
          />
        )}
      />
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>Citizenships</DialogTitle>
          <Grid container spacing={2} px={2}>
            <Grid
              item xs={12}
              key="citizenships">
              <Autocomplete
                multiple
                data-testid="selectedCitizenshipField"
                freeSolo
                value={citizenship.map((citizen) => countryList.find((country) => country.code === citizen)?.name ?? '')}
                options={[]}
                fullWidth
                readOnly={readonly}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      data-testid={`${option}-chip`}
                      onDelete={() => removeCountryFromList(option)}
                    />
                ))
                }
                onChange={(e, value) => {
                  if (value.length === 0) setCitizenship([]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    data-testid="selectedCitizenshipTextField"
                    label={label ?? t('components:citizenshipInput.label')}
                    onClick={() => {
                      if (!readonly) setOpen(true);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} key="country">
              <TextField
                fullWidth
                data-testid="countryTextField"
                value={searchFilter}
                label={t('components:citizenshipInput.searchLabel')}
                onChange={(e) => setSearchFilter(e.target.value)}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
          </Grid>
          <List sx={{ height: '500px', overflowY: 'auto' }}>
            {
              countryList.filter((country) => country.name.toLowerCase().includes(searchFilter.toLowerCase())).map((country) => (
                <ListItem key={country.code} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1'>{country.name}</Typography>
                  <Checkbox
                    data-testid={`${country.name}-checkbox`}
                    checked={citizenship.includes(country.code)}
                    onChange={(e) => {
                      const isSingleSelect = singleSelect ? citizenship.length <= 0 : true;
                      if (e.target.checked && isSingleSelect) {
                        setCitizenship((prevState) => [...prevState, country.code]);
                      } else {
                        removeCountryFromList(country.code);
                      }
                    }}
                  />
                </ListItem>
              ))
            }
          </List>
          <Box display="flex" justifyContent="end" my={2} mr={1.8}>
            <Button
              data-testid="updateButton"
              variant="contained"
              size="medium"
              onClick={() => {
                setOpen(false);
                onChange(citizenship);
              }}
            >
              {t('components:citizenshipInput.updateButton')}
            </Button>
          </Box>
      </Dialog>
    </>
  );
};

export default CitizenField;
