import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FeeReportTable from './components/feeReportTable';

const FeeReport = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <FeeReportTable />
    </Paper>
  );
};

export default FeeReport;
export { FeeReportBreadcrumb } from './feeReportBreadcrumb';
