import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface RadioTokens {
  radio: {
    defaultColor?: string;
    activeColor?: string;
    disabledColor?: string;
    errorColor?: string;
    labelColor?: string;
    subLabelColor?: string;
    fontFamily?: string;
  },
}

export const useRadioTokens = (): RadioTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: RadioTokens = {
    radio: {
      defaultColor: sys.color.onSurface,
      activeColor: sys.color.primary,
      disabledColor: sys.color.onDisabled,
      errorColor: sys.color.negative,
      labelColor: sys.color.onSurfaceVariant,
      subLabelColor: sys.color.onSurfaceVariant,
      fontFamily: sys.font.base,
    },
  };

  return comp;
};
