import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, IconButton, Menu, MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/AddRounded';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import {
  BulkImport, BulkImportStates, getStatusColor, ImportFile, ImportFileStates,
} from '../../../interfaces/bulkImport';
import AddImportFileModal from './addImportFileModal';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { FETCH_BULK_IMPORT, FETCH_QUEUED_FILES } from '../index';
import { FETCH_IMPORT_FILE, TRANSITION_IMPORT_FILE } from '../../bulkImportFileLineItems/index';

export const FETCH_IMPORT_FILES = gql`
  query fetchImportFiles($input: FetchImportFilesInput!) {
    fetchImportFiles(input: $input) {
      totalCount
      importFiles {
        id
        type
        state
        order
        failedLineItemsCount
        processedLineItemsCount
        queuedLineItemsCount
        createdAt
        fileDocument { id type name }
      }
    }
  }
`;

const DELETE_IMPORT_FILE = gql`
  mutation deleteImportFile($input: DeleteImportFileInput!){
    deleteImportFile(input: $input) {
      importFile {
        id
      }
    }
  }
`;

const ImportFilesTable = ({ bulkImport }: { bulkImport: BulkImport }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['bulkImport']);
  const { showToast } = useGlobalToast();
  const navigate = useNavigate();
  const [activeImportFile, setActiveImportFile] = useState<ImportFile>({});
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const {
    loading, error, data, previousData, refetch, startPolling, stopPolling,
  } = useQuery(FETCH_IMPORT_FILES, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          bulkImportId: bulkImport.id,
        },
        pagination: {
          sortField: 'order', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [deleteImportFile, { loading: deleteLoading }] = useMutation(DELETE_IMPORT_FILE, { refetchQueries: [FETCH_QUEUED_FILES] });
  const [transitionImportFile] = useMutation(TRANSITION_IMPORT_FILE, { refetchQueries: [FETCH_IMPORT_FILE, FETCH_IMPORT_FILES, FETCH_BULK_IMPORT] });

  const processFile = () => {
    transitionImportFile({
      variables: {
        input: {
          importFileId: activeImportFile.id,
          transition: 'process',
        },
      },
    });
  };

  const disabledAddFileButton = !bulkImport.state || [
    BulkImportStates.COMPLETED,
    BulkImportStates.CANCELED,
    BulkImportStates.PROCESSING,
  ].includes(bulkImport.state);

  useEffect(() => {
    if (bulkImport.state === BulkImportStates.PROCESSING) {
      startPolling(5000);
    } else {
      stopPolling();
      if (bulkImport.id) {
        refetch();
      }
    }
  }, [bulkImport.state, bulkImport.id, startPolling, stopPolling, refetch]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          {permissions.includes('write:bulk_import') && (
            <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
              <Grid item>
                <IconButton size='small' disabled={disabledAddFileButton} onClick={() => setAddFileModalOpen(true)}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
              <TableCell><Typography variant='overline'>{t('fileTable.order')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('fileTable.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('fileTable.type')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('fileTable.queuedLineItems')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('fileTable.failedLineItems')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('fileTable.processedLineItems')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
                {permissions.includes('write:bulk_import') && (
                  <TableCell align='right'><Typography variant='overline'>{t('fileTable.actions')}</Typography></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchImportFiles?.importFiles?.map((importFile: ImportFile) => (
                <TableRow
                  hover
                  key={importFile.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/bulkImport/${bulkImport.id}/file/${importFile.id}`)}
                >
                  <TableCell>{importFile.order}</TableCell>
                  <TableCell>{importFile.fileDocument?.name}</TableCell>
                  <TableCell>{t(`fileTypes.${importFile.type}`)}</TableCell>
                  <TableCell>{(importFile.queuedLineItemsCount || 0)}</TableCell>
                  <TableCell>{(importFile.failedLineItemsCount || 0)}</TableCell>
                  <TableCell>{(importFile.processedLineItemsCount || 0)}</TableCell>
                  <TableCell><Chip label={t(`bulkImport:states.${importFile.state}`)} size='small' color={getStatusColor(importFile.state)}/></TableCell>
                  {permissions.includes('write:bulk_import') && (
                    <TableCell align='right'>
                      <IconButton size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveImportFile(importFile);
                          setAnchorEl(e.currentTarget);
                        }}
                        disabled={disabledAddFileButton || !importFile.state || ![ImportFileStates.QUEUED, ImportFileStates.FAILED, ImportFileStates.PARTIALLY_FAILED].includes(importFile.state)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchBulkImports?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem key={1} onClick={() => setDeleteConfirmationOpen(true)}>{t('bulkImport:fileTable.menu.delete')}</MenuItem>
            {activeImportFile?.state && [ImportFileStates.FAILED, ImportFileStates.PARTIALLY_FAILED].includes(activeImportFile.state) && (
              <MenuItem key={2} onClick={() => processFile()}>{t('bulkImport:fileTable.menu.process')}</MenuItem>
            )}
          </Menu>
          <ConfirmationModal
            open={deleteConfirmationOpen}
            onCancel={() => setDeleteConfirmationOpen(false)}
            onConfirm={async () => {
              await deleteImportFile({
                variables: { input: { importFileId: activeImportFile.id } },
              });
              showToast({ severity: 'info', message: t('bulkImport:deleteFileMessage') });
              setDeleteConfirmationOpen(false);
              refetch();
            }}
            loading={deleteLoading}
            title={t('bulkImport:deleteFileConfirmationDialog.title')}
            bodyText={t('bulkImport:deleteFileConfirmationDialog.text')}
          />
          {addFileModalOpen && (
            <AddImportFileModal
              bulkImport={bulkImport}
              handleClose={() => {
                setAddFileModalOpen(false);
                refetch();
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ImportFilesTable;
