import { gql, useLazyQuery } from '@apollo/client';
import {
  Box,
  Grid,
  ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import { AccountTypes } from 'interfaces';
import { intersection } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { NoPermissionAlert } from '../../misc/noPermissionAlert';
import NewScheduledIncomeFundTransfer from './newScheduledIncomeFundTransfer';
import NewTransfer from './newTransfer';
import { OneoffTransfersTable } from './oneoffTransfersTable';
import { PaymentIntructionsTable } from './paymentIntructionsTable';
import { ScheduledTransfersTable } from './scheduledTransfersTable';
import './transfersTable.css';
import DownloadTransfers from './downloadTransfers';

const FETCH_SUB_ACCOUNTS = (permissions: string[], fetchUser?: boolean) => gql`
  query fetchSubAccounts($filter:SubAccountQueryFilter) {
    fetchSubAccounts(
      input: {
        filter: $filter
        pagination: { perPage: 1000 }
      }
    ) {
      subAccounts {
        account {
          type
        }
      }
    }
  }
`;
export const FETCH_GOAL = gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        id
        subAccounts {
          id
          account {
            type
          }
        }
      }
    }
  }
`;
export const FETCH_CLIENT_GROUP = gql`
  query fetchClientGroup($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        accounts {
          id
          type
        }
      }
    }
  }
`;
export const PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES = [
  AccountTypes.RRIF,
  AccountTypes.RIF_SPOUSAL,
  AccountTypes.PRIF,
  AccountTypes.LRIF,
  AccountTypes.LIF,
  AccountTypes.RLIF,
];

export const TransfersTable = ({
  showClient = false,
  filter,
  accountType,
  onTransition = () => {},
  onNewTransfer = () => {},
  disabled,
  level,
  subAccountMarketValueCents,
}: {
  showClient?: boolean;
  filter: any;
  accountType?: AccountTypes
  onTransition?: () => void;
  onNewTransfer?: () => void;
  disabled?: boolean;
  level?: 'CLIENT' | 'ACCOUNT' | 'SUB_ACCOUNT' | 'GOAL' | 'CLIENT_GROUP';
  subAccountMarketValueCents?: number;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [isInstructionsTabEnabled, setIsInstructionsTabEnabled] = useState(false);
  const [queryFilter, setQueryFilter] = useState({});

  const [fetchSubAccounts] = useLazyQuery(FETCH_SUB_ACCOUNTS(permissions), {
    variables: { filter: { userId: filter.userId } },
    onCompleted: (data: any) => {
      const accountTypes: string[] = [];
      if (data.fetchSubAccounts.subAccounts) {
        // eslint-disable-next-line
        data.fetchSubAccounts.subAccounts.map((a: any) => {
          accountTypes.push(a.account.type);
        });
        if (intersection(accountTypes, PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES).length > 0) {
          setIsInstructionsTabEnabled(true);
        }
      }
    },
  });

  const [fetchGoal] = useLazyQuery(FETCH_GOAL, {
    variables: { id: filter.goalId },
    onCompleted: (data: any) => {
      const accountTypes: string[] = [];
      if (data.fetchGoal.goal) {
        // eslint-disable-next-line
        data.fetchGoal.goal.subAccounts.map((a: any) => {
          accountTypes.push(a.account.type);
        });
        if (intersection(accountTypes, PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES).length > 0) {
          setIsInstructionsTabEnabled(true);
        }
      }
    },
  });

  const [fetchClientGroup] = useLazyQuery(FETCH_CLIENT_GROUP, {
    variables: { id: filter.clientGroupId },
    onCompleted: (data: any) => {
      const accountTypes: string[] = [];
      if (data.fetchClientGroup.clientGroup) {
        // eslint-disable-next-line
        data.fetchClientGroup.clientGroup.accounts.map((a: any) => {
          accountTypes.push(a.type);
        });
        if (intersection(accountTypes, PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES).length > 0) {
          setIsInstructionsTabEnabled(true);
        }
      }
    },
  });

  useEffect(() => {
    if (level === 'CLIENT') {
      fetchSubAccounts();
    }
    if (level === 'GOAL') {
      fetchGoal();
    }
    if (level === 'CLIENT_GROUP') {
      fetchClientGroup();
    }
  }, [level, fetchSubAccounts, fetchGoal, fetchClientGroup]);

  const [tabShown, setTabShown] = usePageState<'oneoff' | 'scheduled' | 'instructionsTab'>('oneoff', 'transferstab');

  if (!permissions.includes('read:transfer_basic')) return <NoPermissionAlert missing='read:transfer_basic' />;

  const isInstructionsTadVisible = accountType && PAYMENT_INSTRUCTIONS_ENABLED_ACCOUNT_TYPES.includes(accountType);

  return (
    <Box>
      <Grid display='flex' justifyContent='space-between' margin={2}>
        <Box sx={{ width: '5em' }}></Box>
        <ToggleButtonGroup
          value={tabShown}
          exclusive
          size='small'
          onChange={(_event, newValue) => {
            setTabShown(newValue);
          }}
        >
          <ToggleButton value='oneoff'>{t('transfersTable.tableHeader')}</ToggleButton>
          {(!isInstructionsTadVisible || isInstructionsTabEnabled) && <ToggleButton value='scheduled'>{t('transfersTable.scheduleTableHeader')}</ToggleButton>}
          {(isInstructionsTadVisible || isInstructionsTabEnabled) && <ToggleButton value='instructionsTab'>{t('transfersTable.paymentInstructions')}</ToggleButton>}
        </ToggleButtonGroup>
        <Box sx={{ width: tabShown !== 'instructionsTab' ? '9em' : '5em', textAlign: 'right' }}>
          {permissions.includes('read:api_exports') && tabShown !== 'instructionsTab' && (
            <Box sx={{ mr: 1 }} display='inline'>
              <DownloadTransfers tab={tabShown} filter={filter} queryFilter={queryFilter} />
            </Box>
          )}

          {(permissions.includes('write:transfer_basic') || permissions.includes('write:transfer_obo_clients')) && (tabShown !== 'instructionsTab' ? (
            <NewTransfer
              {...(filter.userId && { forObject: 'USER', forId: filter.userId })}
              {...(filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId })}
              presetSubaccount={filter.subAccountId}
              presetAccount={filter.accountId}
              presetSubAccountMarketValueCents={subAccountMarketValueCents}
              afterCreate={() => {
                onNewTransfer();
              }}
              disableButton={disabled}
            />
          ) : (
            <NewScheduledIncomeFundTransfer
              afterCreate={onNewTransfer}
              {...(filter.accountId && { forObject: 'ACCOUNT', forId: filter.accountId })}
              {...(filter.userId && level === 'CLIENT' && { forObject: 'USER', forId: filter.userId })}
              {...(filter.goalId && level === 'GOAL' && { forObject: 'GOAL', forId: filter.goalId })}
              {...(filter.clientGroupId && level === 'CLIENT_GROUP' && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId })}
              subAccountId={filter.subAccountId}
            />
          ))}
        </Box>
      </Grid>

      {tabShown === 'oneoff' && (
        <OneoffTransfersTable showClient={showClient} filter={filter} onTransition={onTransition} onNewTransfer={onNewTransfer} onFilterChange={setQueryFilter} />
      )}

      {tabShown === 'scheduled' && (
        <ScheduledTransfersTable showClient={showClient} filter={filter} onTransition={onTransition} onNewTransfer={onNewTransfer} onFilterChange={setQueryFilter} />
      )}

      {tabShown === 'instructionsTab' && <PaymentIntructionsTable level={level} filter={filter} onTransition={onTransition} onNewTransfer={onNewTransfer} />}
    </Box>
  );
};
