import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Grid, Chip, Dialog, DialogTitle, Switch, FormControlLabel,
} from '@mui/material';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { usePageState } from '../../util/usePageState';
import { usePermissions } from '../../providers/userContextProvider';
import { useLocalization } from '../../util/useLocalization';

export const FETCH_USER_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchUserAuditLog($userId: ObjectID!, $input: UserLogsInput!) {
    fetchUser(userId: $userId) {
      user {
        logs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

const FETCH_CLIENT_GROUP_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchClientGroupAuditLog($clientGroupId: ObjectID!, $input: ClientGroupLogsInput!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        logs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

const FETCH_MODEL_PORTFOLIO_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchModelPortfolioAuditLog($modelPortfolioId: ObjectID!, $input: ModelPortfolioLogsInput!) {
    fetchModelPortfolio(input: { modelPortfolioId: $modelPortfolioId }) {
      modelPortfolio {
        theme {
          id
        }
        modelPortfolioLogs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

export const FETCH_THEME_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchThemeAuditLog($themeId: ObjectID!, $input: ThemeLogsInput!) {
    fetchTheme(themeId: $themeId) {
      theme {
        logs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

const AuditLog = (
  { objectId, objectType }: { objectId: string, objectType: 'USER' | 'CLIENT_GROUP' | 'MODEL_PORTFOLIO' },
) => {
  const { t } = useTranslation(['client', 'household']);
  const { localizedDateTime } = useLocalization();
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'page');
  const [activeLog, setActiveLog] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [objectOnly, setObjectOnly] = usePageState(false, 'oo');
  const [logs, setLogs] = useState<{ logs: any[], totalCount: number }>();

  const pageSize = 15;

  const { loading, error, previousData } = useQuery(FETCH_USER_AUDIT_LOG(permissions), {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    skip: objectType !== 'USER',
    variables: {
      userId: objectId,
      input: {
        filter: { userOnly: objectOnly },
        pagination: {
          sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (data) setLogs(data?.fetchUser?.user?.logs);
    },
  });

  const { loading: loading2, error: error2, previousData: previousData2 } = useQuery(FETCH_CLIENT_GROUP_AUDIT_LOG(permissions), {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    skip: objectType !== 'CLIENT_GROUP',
    variables: {
      clientGroupId: objectId,
      input: {
        filter: { clientGroupOnly: objectOnly },
        pagination: {
          sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (data) setLogs(data?.fetchClientGroup?.clientGroup?.logs);
    },
  });

  const [fetchThemeLogs] = useLazyQuery(FETCH_THEME_AUDIT_LOG(permissions));

  const { loading: loading3, error: error3, previousData: previousData3 } = useQuery(FETCH_MODEL_PORTFOLIO_AUDIT_LOG(permissions), {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    skip: objectType !== 'MODEL_PORTFOLIO',
    variables: {
      modelPortfolioId: objectId,
      input: {
        filter: { },
        pagination: {
          sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (data) {
        const modelPortfolioLogs = data?.fetchModelPortfolio?.modelPortfolio?.modelPortfolioLogs;
        setLogs(modelPortfolioLogs);
        fetchThemeLogs({
          variables: {
            themeId: data?.fetchModelPortfolio?.modelPortfolio?.theme.id,
            input: {
              filter: { },
              pagination: {
                sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
              },
            },
          },
          onCompleted(themeLogData) {
            if (themeLogData) {
              const themeLogs = themeLogData?.fetchTheme?.theme?.logs;
              if (
                (themeLogs && themeLogs.totalCount !== 0)
              ) {
                const updatedLogs = {
                  logs: modelPortfolioLogs.logs.concat(themeLogs.logs),
                  totalCount: (modelPortfolioLogs.totalCount ?? 0) + (themeLogs?.totalCount ?? 0),
                };
                setLogs(updatedLogs);
              }
            }
          },
        });
      }
    },
  });

  const changedBy = (log: any) => {
    if (log.initiator === 'ORGANIZATION_USER') {
      return `${log.organizationUser?.firstName} ${log.organizationUser?.lastName}`;
    }
    if (log.initiator === 'API_TOKEN' && log.apiToken?.name) {
      const tokenCreator = log.apiToken.organizationUser;
      return `${log.apiToken?.name}${tokenCreator ? ` (${tokenCreator.firstName} ${tokenCreator.lastName})` : ''}`;
    }

    return log.initiator;
  };

  const changedText = (log: any) => {
    const keys = Object.keys(log.changes || {});

    if (keys.length === 1) {
      return keys[0];
    }

    if (keys.length === 2) {
      return `${keys[0]} & ${keys[1]}`;
    }

    return `${keys[0]}, ${keys[1]} & ${keys.length - 2} ${t('moreFields')}`;
  };

  const changeObjectType = (log: any) => {
    let changedObjectType = log.objectType;
    if (log.objectType === 'ModelPortfolio') {
      changedObjectType = 'Model';
    }
    return changedObjectType;
  };

  const isClientGroup = objectType === 'CLIENT_GROUP';

  if (error || error2 || error3) (<Typography>Error</Typography>);
  const label = isClientGroup ? t('household:houseHoldChangesOnly') : t('userChangesOnly');
  const isLoading = isClientGroup ? loading2 : loading;
  const hasPreviousData = isClientGroup ? previousData2 : previousData;

  return (
    <Box>
      {(loading3 || isLoading) && (!previousData3 || !hasPreviousData) ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='end'>
            {(objectType !== 'MODEL_PORTFOLIO') && (
              <Grid item p={1}>
                <FormControlLabel control={<Switch value={objectOnly} onChange={async (e) => {
                  setObjectOnly(e.target.checked);
                  setPage(1);
                }}
                />}
                  label={label}
                />
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant='overline'>{t('audit.changedBy')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('audit.type')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('audit.changedObject')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('audit.changedFields')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('audit.timestamp')}</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(logs?.logs || []).map((log: any) => (
                    <TableRow
                      hover
                      onClick={(e) => {
                        setActiveLog(log);
                        setOpen(true);
                      }}
                      selected={log.id === activeLog?.id}
                      key={log.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                    >
                      <TableCell component="th" scope="row">{changedBy(log)}</TableCell>
                      <TableCell><Chip label={log.type} size='small' /></TableCell>
                      <TableCell>{changeObjectType(log)}</TableCell>
                      <TableCell>{changedText(log)}</TableCell>
                      <TableCell>{localizedDateTime(log.timestamp)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil((logs?.totalCount || 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
        </>
      )}
      {activeLog && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
          <DialogTitle>
            <Typography>{activeLog.objectType} - {localizedDateTime(activeLog.timestamp)}</Typography>
            <Typography variant='caption'>{activeLog.traceId}</Typography>
          </DialogTitle>
          <Table sx={{ minWidth: 650, display: 'table', overflowX: 'scroll' }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('audit.field')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('audit.from')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('audit.to')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(activeLog.changes || {}).map((key: string) => (
                <TableRow
                  hover
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell component="th" scope="row" sx={{ fontWeight: '600' }}>{key}</TableCell>
                  <TableCell>{activeLog.changes[key][0] ? JSON.stringify(activeLog.changes[key][0]) : '-'}</TableCell>
                  <TableCell>{activeLog.changes[key][1] ? JSON.stringify(activeLog.changes[key][1]) : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Dialog>
      )}
    </Box>
  );
};

export default AuditLog;
