import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Responsive as GridLayout, WidthProvider } from 'react-grid-layout';
import Widget from './components/widget';
import ReportModal from './components/reportModal';
import { UserContext } from '../../../providers/userContextProvider';
import { Grid, Skeleton } from '../../1-primative';

const FETCH_DASHBOARD = gql`
  query fetchOrganizationDashboard($dashboardId: ObjectID!) {
    fetchOrganizationDashboard(dashboardId: $dashboardId) {
      dashboard {
        id
        name
        widgets {
          type
          columns
          filters {
            comparison
            field
            value
          }
          format
          grouping {
            field
            interval
            type
            valueAggregation
            valueField
          }
          height
          width
          x
          y
          reportType
          sortDesc
          sortField
          title
        }
      }
    }
  }
`;

const ResponsiveGridLayout = WidthProvider(GridLayout);

export const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [activeWidget, setActiveWidget] = useState(null);
  const params = useParams();
  const { dashboardId } = params;
  const { activeOrganization } = useContext(UserContext);
  const [dashboard, setDashboard] = useState([]);
  const [grid, setGrid] = useState<any[]>([]);

  const { data, loading } = useQuery(FETCH_DASHBOARD, {
    variables: { dashboardId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.fetchOrganizationDashboard) {
      setDashboard(data.fetchOrganizationDashboard.dashboard.widgets);
    }
  }, [data, activeOrganization.id]);

  useEffect(() => {
    let x = 0;
    let y = 0;
    const mapped = dashboard.map((w: any, index: number) => {
      if (x + w.width > 12) {
        x = 0;
        y += (dashboard[index - 1] as any ?? { height: 1 }).height ?? 0;
      }

      const map = {
        i: index,
        x: w.x ?? x,
        y: w.y ?? y,
        w: w.width,
        h: w.height,
        static: true,
      };

      x += w.width;
      return map;
    });
    setGrid(mapped);
  }, [dashboard]);

  if (loading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='rectangular' width='100%' height={128} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ResponsiveGridLayout
        rowHeight={128}
        layouts={{
          lg: grid,
        } as any}
        cols={{
          lg: 12, md: 12, sm: 12, xs: 12, xxs: 12,
        }}
        onLayoutChange={(layout: any) => setGrid(layout)}
        margin={[16, 16]}
      >
        {dashboard.map((w: any, index: number) => (
          <div key={index} data-grid={grid[index]}>
            <Widget
              widget={{ ...w, width: grid[index]?.w, height: grid[index]?.h }}
              setOpen={setOpen}
              setActiveWidget={setActiveWidget}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
      { activeWidget && (
        <ReportModal open={open} setOpen={setOpen} activeWidget={activeWidget} />
      )}
    </>
  );
};

export default Dashboard;
export { DashboardBreadcrumb } from './dashboardBreadcrumb';
