import { TextField, ListItem } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClientSelect } from 'components/inputs/clientSelect';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import NewClient from '../../clients/components/newClient';
import { UserContext } from '../../../providers/userContextProvider';
import { User } from '../../../interfaces';

const CREATE_CLIENT_GROUP = gql`
mutation createClientGroup($input: CreateClientGroupInput!) {
  createClientGroup(input: $input) {
    clientGroup {
      id
    }
  }
}
`;

export const NewHousehold = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['household']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>(activeOrganization.id ?? '');
  const [primaryUser, setPrimaryUser] = useState<User>();

  const [createHouseholdObject, { loading }] = useMutation(CREATE_CLIENT_GROUP, {
    variables: {
      input: {
        name,
        organizationId,
        type: 'HOUSEHOLD',
        primaryUserId: primaryUser?.id,
        relationships: [{
          type: 'Primary',
          userId: primaryUser?.id,
          accessType: 'EDIT',
        }],
      },
    },
  });

  const createHousehold = async (event: any) => {
    const response = await createHouseholdObject();
    afterCreate();
    setName('');
    setPrimaryUser(undefined);
    navigate(`/households/${response.data.createClientGroup.clientGroup.id}`);
  };
  const disabled = !(name && primaryUser && organizationId);
  const [initialState] = useState({
    name: '',
    organizationId: activeOrganization.id ?? '',
    primaryUser: undefined,
  });

  const state = {
    name,
    organizationId,
    primaryUser,
  };
  return (
    <CreateNewModal
      loading={loading}
      initialState={initialState}
      state={state}
      disabled={disabled} title={t('addModal.title')} onSubmit={createHousehold} sx={{ float: 'right', m: 1 }}>
      <ListItem>
        <TextField
          label={t('household:addModal.name')}
          sx={{ width: '100%' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          data-testid="household-name"
          id='name'
          autoFocus
        />
      </ListItem>
      <ListItem
       data-testid="organization"
      >
        <OrganizationSelect
          value={organizationId}
          label={t('household:addModal.organization')}
          onChange={(event: any) => setOrganizationId(event.target.value)}
        />
      </ListItem>
      <ListItem>
        <ClientSelect
          label={t('household:addModal.primaryUser')}
          fullWidth
          user={primaryUser}
          setUser={setPrimaryUser}
        />
      </ListItem>
      <ListItem>
        <NewClient
          buttonType='NONE'
          fullWidth
          modalButton={t('household:relationModal.createNewClient')}
          customMargin='8px 0px 0px 0px'
          forceOpen
          newClient={(u: User) => setPrimaryUser(u)}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export const testables = {
  CREATE_CLIENT_GROUP,
};
