import {
  TextField, ListItem, MenuItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import CreateNewModal from '../../../components/modals/createNewModal';
import ScheduleDates from './scheduleDates';
import { UserContext } from '../../../providers/userContextProvider';

export const CREATE_SCHEDULE = gql`
mutation createSchedule($input: CreateScheduleInput!) {
  createSchedule(input: $input) {
    schedule{
      id
    }
  }
}
`;

const NewSchedule = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['schedule']);
  const { activeOrganization } = useContext(UserContext);
  const [newSchedule, setNewSchedule] = useState<any>({
    name: '',
    dates: [],
    type: 'Manual',
  });

  const [createNewScheduleObject, { loading }] = useMutation(CREATE_SCHEDULE, {
    variables: {
      input: {
        name: newSchedule.name,
        dates: newSchedule.dates,
        organizationId: activeOrganization.id,
      },
    },
  });
  const updateField = (event: any) => {
    const key: 'name' = event.target.id;
    setNewSchedule({ ...newSchedule, [key]: event.target.value as string });
  };
  const createSchedule = async (event: any) => {
    await createNewScheduleObject();
    afterCreate();
    setNewSchedule({
      dates: [],
      name: '',
      type: 'Manual',
    });
  };
  const hasDuplicates = uniq(newSchedule.dates).length !== newSchedule.dates.length;
  const disabled = !(newSchedule.name && newSchedule.type && !hasDuplicates);
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('schedule:addModal.title')} onSubmit={createSchedule} buttonText={t('schedule:addModal.create')} sx={{ float: 'right', m: 1 }}>
      <ListItem>
        <TextField
          label={t('schedule:addModal.name')}
          sx={{ width: '100%' }}
          value={newSchedule.name}
          onChange={updateField}
          size='small'
          data-testid="schedule-name"
          id='name' />
      </ListItem>
      <ListItem>
        <TextField
          select
          value={newSchedule.type}
          label={t('schedule:addModal.type')}
          fullWidth
          data-testid="schedule-type"
          id='type'
          size='small'
          onChange={(event: any) => setNewSchedule({ ...newSchedule, type: event.target.value as string })}
        >
          <MenuItem key='manual' value='Manual'>{t('schedule:addModal.manual')}</MenuItem>
        </TextField>
      </ListItem>
      <ScheduleDates schedule={newSchedule} setSchedule={setNewSchedule} />
    </CreateNewModal>
  );
};

export default NewSchedule;
