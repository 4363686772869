import { useState } from 'react';
import {
  Autocomplete,
  Box, Button, Grid, InputAdornment, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DroppableFileInput from 'pages/client/components/droppableFileInput';
import { Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { IdDocumentType, StepProps } from '../wizard';
import { UploadedFile } from './uploadedFile';
import { LocalizedDatePicker } from '../../../fields/localizedDatePicker';
import { menuProps } from '../../../../pages/client/components/idVerifications/editIdVerification';
import { useCountrySpecificBackendIdDocumentTypes, useCountrySpecificInformationSources } from '../../../../interfaces/IdVerification';

export const FirstDocumentStep = ({
  context, state, setState, continueTo, mutationEvent,
}: StepProps) => {
  const { t } = useTranslation(['addIdVerificationWizard', 'client']);
  const doSaveFirstDocument = () => {
    if (state.methodOfIDVerification === 'DUAL_METHOD') {
      continueTo('SECOND_DOCUMENT_STEP');
    } else {
      continueTo('SUMMARY_STEP');
    }
  };
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<string | null>('');
  const isDisabled = (): boolean => {
    const disabled = !(state.documentOne?.documentID
      && state.documentOne.documentType);
    if (state.methodOfIDVerification === 'DUAL_METHOD') {
      return !(state.documentOne?.documentName
        && state.documentOne?.documentID
        && state.documentOne.documentType && state.documentOne.file);
    }
    return disabled;
  };
  const generateJurisdictionString = (option: string): string => {
    if (informationSources.includes(option)) {
      return t(`client:edit.jurisdicationOptionsForUsandCanada.${option}`);
    }
    return option;
  };
  const { idDocumentTypes } = useCountrySpecificBackendIdDocumentTypes();
  const informationSources = useCountrySpecificInformationSources();

  return (
    <Box sx={{
      height: '670px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem sx={{ marginBottom: '16px' }}>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('methodOfIDVerification')}
            value={state.methodOfIDVerification}
            onChange={(event) => setState({ ...state, methodOfIDVerification: event.target.value as IdDocumentType })}
          >
            <MenuItem key="DUAL_METHOD" value="DUAL_METHOD">{t('client:edit.methodOfIDVerificationOptions.DUAL_METHOD')}</MenuItem>
            <MenuItem key="IN_PERSON_VALIDATION" value="IN_PERSON_VALIDATION">{t('client:edit.methodOfIDVerificationOptions.IN_PERSON_VALIDATION')}</MenuItem>
          </TextField>
        </ListItem>
        <ListItem>
          <Typography variant='subtitle2'>{
            (state.methodOfIDVerification === 'DUAL_METHOD') ? t('firstPageStepDualMethod') : t('firstPageStepSingleMethod')
          }</Typography>
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label={t('documentName')}
            value={state?.documentOne?.documentName}
            onChange={(event) => {
              if (state.documentOne) {
                setState({
                  ...state,
                  documentOne: {
                    ...state.documentOne,
                    documentName: event.target.value,
                  },
                });
              }
            }}
          />
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={6}>
                  <Autocomplete
                    id='first-document-jusrisdiction'
                    onBlur={() => {
                      if (state.documentOne && inputValue) {
                        setValue(inputValue);
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            documentIssuingJurisdication: inputValue,
                          },
                        });
                      }
                    }}
                    onChange={(event: any, newValue: string | null, reason: string) => {
                      if (state.documentOne && newValue) {
                        setValue(newValue);
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            documentIssuingJurisdication: newValue,
                          },
                        });
                      }
                      if (reason === 'clear') {
                        if (state.documentOne) {
                          setValue(newValue);
                          setState({
                            ...state,
                            documentOne: {
                              ...state.documentOne,
                              documentIssuingJurisdication: '',
                            },
                          });
                        }
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    value={value}
                    getOptionLabel={(option) => generateJurisdictionString(option)}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {generateJurisdictionString(option)}
                      </Box>
                    )}
                    options={informationSources}
                    fullWidth
                    noOptionsText={t('noOptionsText')}
                    renderInput={(params) => <TextField
                      {...params}
                      label={t(`${state.methodOfIDVerification === 'DUAL_METHOD' ? 'informationSource' : 'informationSourceSingle'}`)}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'Enter'
                          && informationSources.findIndex((o) => o === inputValue) === -1
                        ) {
                          setValue(inputValue);
                          if (state.documentOne && inputValue) {
                            setState({
                              ...state,
                              documentOne: {
                                ...state.documentOne,
                                documentIssuingJurisdication: inputValue,
                              },
                            });
                          }
                        }
                      }}
                    />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField select fullWidth
                    label={t(`${state.methodOfIDVerification === 'DUAL_METHOD' ? 'informationType' : 'documentType'}`)}
                    value={state?.documentOne?.documentType}
                    SelectProps={{
                      MenuProps: menuProps,
                    }}
                    onChange={(event) => {
                      if (state.documentOne) {
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            documentType: event.target.value,
                          },
                        });
                      }
                    }}
                  >
                    {idDocumentTypes.map((b) => (<MenuItem key={b} value={b} sx={{ whiteSpace: 'normal' }}>{t(`client:documentTypes.${b}`)}</MenuItem>))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t(`${state.methodOfIDVerification === 'DUAL_METHOD' ? 'documentID' : 'documentIDSingle'}`)}
                    value={state?.documentOne?.documentID}
                    onChange={(event) => {
                      if (state.documentOne) {
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            documentID: event.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizedDatePicker
                    label={t('expiryDate')}
                    value={state?.documentOne?.documentExpiryDate || null}
                    onChange={(date) => {
                      if (state.documentOne) {
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            documentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD'),
                          },
                        });
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('userName')}
                    value={state?.documentOne?.userName}
                    onChange={(event) => {
                      if (state.documentOne) {
                        setState({
                          ...state,
                          documentOne: {
                            ...state.documentOne,
                            userName: event.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          {state.documentOne?.file && (<UploadedFile file={state.documentOne?.file} onDelete={() => {
            if (state.documentOne) {
              setState({
                ...state,
                documentOne: {
                  ...state.documentOne,
                  file: undefined,
                },
              });
            }
          }} />)}
          {!state.documentOne?.file && (<DroppableFileInput
            height='150px'
            onFileChosen={(dropFile: File) => {
              if (state.documentOne) {
                setState({
                  ...state,
                  documentOne: {
                    ...state.documentOne,
                    file: dropFile,
                    documentName: state.documentOne.documentName !== '' ? state.documentOne.documentName : dropFile.name,
                  },
                });
              }
            }}
          />)}
        </ListItem>
      </Box>

      <Box m={2}>
        <Button type='submit' disabled={isDisabled()}
          onClick={() => doSaveFirstDocument()} fullWidth variant='contained'>
          {state.methodOfIDVerification === 'DUAL_METHOD' ? t('saveDoc1') : t('continue')}
        </Button>
      </Box>
    </Box>
  );
};
