import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import ConfirmationModal from 'ovComponents/3-pattern/confirmationDialog/confirmationDialog';
import { colors } from 'theme/colors';

export const CANCEL_WORKFLOW = gql`
  mutation transitionWorkflowCompletion($input: TransitionWorkflowCompletionInput!) {
    transitionWorkflowCompletion(input: $input) {
      workflowCompletion {
        id
        state
      }
    }
  }
`;

const CancelWorkFlowDialog = ({
  worflowId, open, setCloseDialog,
}: {
  worflowId: string,
  open: boolean,
  setCloseDialog: (status: boolean) => void,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const [cancelWorkflow] = useMutation(CANCEL_WORKFLOW, {
    onCompleted: () => {
      setCloseDialog(false);
    },
    variables: {
      input: {
        transition: 'cancel',
        workflowCompletionId: worflowId,
      },
    },
  });
  return (
    <ConfirmationModal
      open={open}
      title={t('workflowCompletions:workflowCancelModal.title')}
      bodyText={t('workflowCompletions:workflowCancelModal.bodyText')}
      onConfirm={() => {
        cancelWorkflow().then();
      }}
      onCancel={() => setCloseDialog(false)}
      confirmButtonLabel={t('workflowCompletions:workflowCancelModal.confirmButtonText')}
      cancelButtonLabel={t('workflowCompletions:workflowCancelModal.cancelButtonText')}
      dialogStyles={{
        dialogTitleStyles: {
          fontWeight: '700',
        },
        dialogContainer: {
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '416px',
            },
          },
        },
        dialogContentStyles: {
          width: 'auto',
          maxWidth: '421px',
          minWidth: 'auto',
        },
        confirmButton: {
          color: colors.white,
        },
        cancelButton: {
          color: colors.neutral100,
          border: `2px solid ${colors.neutral100}`,
        },
      }}
    />
  );
};

export default CancelWorkFlowDialog;
