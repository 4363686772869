import {
  Box, Button, Dialog, DialogContent, DialogTitle, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Account, AccountTypes, getAccountName } from 'interfaces';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { colors } from 'ovComponents';
import { FETCH_HOUSEHOLD } from 'pages/household';
import i18n from 'assets/i18n/config';
import { useGlobalToast } from 'providers/globalToastProvider';
import './householdFeePaymentEdit.css';
import { usePermissions } from 'providers/userContextProvider';
import FormModal from 'components/modals/formModal';
import { generateClientNameString } from '../../../util';

const UPDATE_FEE_PAYMENT_ACCOUNT = gql`
  mutation updateFeePaymentAccount($accountId: ObjectID!, $feePaymentAccountId:ObjectID) {
      updateAccount(input: {
        accountId: $accountId
        feePaymentAccountId: $feePaymentAccountId
      }) {
      account {
        id
      }
    }
  }
`;

export const HouseholdFeePaymentEdit = ({
  accounts, handleClose,
}: {
  accounts?: Account[],
  handleClose: () => void
}) => (
  (accounts === undefined || accounts.length === 0)
    ? <NoAccountsInfoModal onClose={handleClose}/>
    : <HouseholdFeePaymentModal accounts={accounts} handleClose={handleClose}/>
);

const HouseholdFeePaymentModal = ({
  accounts, handleClose,
}: {
  accounts: Account[],
  handleClose: () => void
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('householdFeePaymentModal');
  const { showToast } = useGlobalToast();
  const client = useApolloClient();
  const [paymentAccounts, setPaymentAccounts] = useState<Record<string, string>>(
    accounts.reduce((accumulator, acc:Account) => (
      { ...accumulator, [acc.id]: acc.feePaymentAccount?.id ?? acc.id }
    ), {}) ?? {},
  );
  const [updating, setUpdating] = useState(false);
  const [updateFeePaymentAccount] = useMutation(UPDATE_FEE_PAYMENT_ACCOUNT);

  // Runs all UPDATE_FEE_PAYMENT_ACCOUNT mutations in parallel:
  const updateMultipleAccounts = async () => {
    setUpdating(true);
    const allUpdates = accounts.map((acc:Account, index) => new Promise((resolve, reject) => {
      updateFeePaymentAccount({
        variables: {
          accountId: acc.id,
          feePaymentAccountId: paymentAccounts[acc.id] !== acc.id ? paymentAccounts[acc.id] : null,
        },
        onCompleted: resolve,
        onError: reject,
      });
    }));
    await Promise.all(allUpdates).finally(() => setUpdating(false));
    client.refetchQueries({ include: [FETCH_HOUSEHOLD(permissions)] });
  };

  const onSave = async (event: any) => {
    event.preventDefault();
    await updateMultipleAccounts();
    showToast({ message: t('feePaymentAccountsUpdated'), severity: 'success' });
    handleClose();
  };

  return (
    <FormModal
      title={t('title')}
      subTitle={t('subTitle')}
      onSubmit={onSave}
      disabled={updating}
      loading={false}
      open={true}
      handleClose={handleClose}
      formButton={t('set')}
    >
      {
        accounts.map((acc: Account) => <ListItem key={acc.id}>
          <PaymentAccountSelect
            label={accountLabel(acc)}
            forAccountId={acc.id}
            accountList={accounts}
            value={paymentAccounts[acc.id] ?? ''}
            setAccount={ (chosenId): void => {
              setPaymentAccounts({ ...paymentAccounts, [acc.id]: chosenId });
            } }
          />
        </ListItem>)
      }
    </FormModal>
  );
};

const accountLabel = (account: Account) => {
  const whose = generateClientNameString(account.user);
  const what = getAccountName(account);
  return i18n.t('shared:somethingBelongingToSomeone', { whose, what });
};

const isRegistered = (account:Account): boolean => {
  if (account.type === AccountTypes.PERSONAL) return false;
  if (account.type === AccountTypes.CASH_JOINT) return false;
  return true;
};

const PaymentAccountSelect = ({
  label,
  forAccountId,
  accountList,
  value,
  setAccount,
}:{
  label: string
  forAccountId: string
  accountList: Account[]
  value: string
  setAccount: (event: any) => void
}) => {
  const { t } = useTranslation('householdFeePaymentModal');

  const thisAccount = accountList.find((a) => a.id === forAccountId);
  const otherAccounts = accountList.filter((a) => a.id !== forAccountId);
  const nonRegisteredAccounts = otherAccounts.filter((a) => !isRegistered(a));
  const registeredAccounts = otherAccounts.filter((a) => isRegistered(a));

  return (
    <TextField
      select
      value={value}
      label={label}
      fullWidth
      onChange={(e: any) => setAccount(e.target.value)}
    >
      { thisAccount && (
        <MenuItem key={thisAccount.id} value={thisAccount.id}>
          {accountLabel(thisAccount)}
          <CheckIcon/>
        </MenuItem>
      )}
      {
        nonRegisteredAccounts.map((a: any) => (
          <MenuItem key={a.id} value={a.id}>
            {accountLabel(a)}
          </MenuItem>
        ))
      }
      { registeredAccounts.length > 0 && (
        <MenuItem disabled>
          <Box sx={{
            width: '100%', borderBottomWidth: '1px', borderBottomColor: colors.neutral600, borderBottomStyle: 'solid',
          }}>
            <Typography variant='overline'>{t('registeredAccounts')}</Typography>
          </Box>
        </MenuItem>
      )}
      { registeredAccounts.map((a: any) => (
          <MenuItem key={a.id} value={a.id}>
            {accountLabel(a)}
          </MenuItem>
      ))}
    </TextField>
  );
};

const NoAccountsInfoModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('householdFeePaymentModal');

  return (
    <Dialog fullWidth
    onClose={onClose}
    open={true}
    >
    <DialogTitle>
      {t('householdFeePaymentModal:title')}
    </DialogTitle>
    <DialogContent >
      <Typography>{t('householdFeePaymentModal:noAccountsInHousehold')}</Typography>
    </DialogContent >
    <DialogContent >
      <Button type='button' variant='contained' fullWidth onClick={onClose}>
        {t('shared:okGotIt')}
      </Button>
    </DialogContent>
    </Dialog>
  );
};
