import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { useNavigate } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { Box, Skeleton, Typography } from '../../1-primative';
import {
  Table, TableHeadCell, TableRow, TableCell,
  TableBody, Pagination, Card, CardContent,
  TextField,
  Menu,
  MenuItem,
  Badge,
  Button,
} from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';
import { FilterModelPortfolios } from './components/filterModelPortfolios';
import { FinancialProductState } from '../../../interfaces/financialProduct';

const getStateBadgeColor = (state: FinancialProductState | undefined): 'warning' | 'positive' | 'negative' => {
  if (state === 'DRAFT') {
    return 'warning';
  }

  if (state === 'ACTIVE') {
    return 'positive';
  }

  return 'negative';
};
const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        theme {
          id
          translatedName {
            en
            fr
          }
          key
        }
        state
        translatedName {
          en
          fr
        }
        primaryAssetClass {
          translatedName {
            en
            fr
          }
        }
      }
      totalCount
    }
  }
`;

export const ModelPortfolios = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('modelPortfolios');
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const [themeIds, setThemeIds] = useState<string[] | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    loading, data, previousData,
  } = useQuery(FETCH_MODEL_PORTFOLIOS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeIds,
        },
        pagination: {
          perPage: pageSize,
          sortField: 'createdAt',
          sortDesc: true,
          offset: (themeIds && themeIds?.length === 1) ? 0 : page,
        },
      },
    },
  });

  return (
    <>
      <Card loading={loading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <TextField
              value={''}
              leadingIcon='search'
            />
            <Box display='flex'>
              <FilterModelPortfolios themeIds={themeIds} setThemeIds={setThemeIds} organizationId={activeOrganization.id} />
              <Button
                label={t('shared:add')}
                onClick={(event: any) => {
                  event.stopPropagation();
                  handleClick(event);
                }}
                leadingIcon={AddIcon} sx={{ ml: 1 }}
              />
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('modelPortfolioTable.name')}</TableHeadCell>
                <TableHeadCell>{t('modelPortfolioTable.theme')}</TableHeadCell>
                <TableHeadCell>{t('modelPortfolioTable.assetClass')}</TableHeadCell>
                <TableHeadCell>{t('modelPortfolioTable.state')}</TableHeadCell>
              </TableRow>
              {loading && [...Array(pageSize)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(data || previousData)?.fetchModelPortfolios?.modelPortfolios?.map((modelPortfolio: any) => (
                <TableRow
                  hover
                  key={modelPortfolio.id}
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/modelPortfolios/${modelPortfolio.id}`)}
                >
                  <TableCell component="th" scope="row">
                  {translateBackend(modelPortfolio?.translatedName)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {translateBackend(modelPortfolio?.theme?.translatedName)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {translateBackend(modelPortfolio?.primaryAssetClass?.translatedName)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Badge
                      label={modelPortfolio?.state}
                      color={getStateBadgeColor(modelPortfolio?.state)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchModelPortfolios?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>
    </Card>
    {open && (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {permissions.includes('write:model_portfolios') && (
          <MenuItem onClick={() => {}}>
            <AutoAwesomeMosaicIcon sx={{ fontSize: 15 }} />
            <Typography variant='bodySmall' sx={{ ml: '7px', mt: '1px', mr: '2px' }}>{t('modelPortfolioTable.createModel')}</Typography>
          </MenuItem>
        )}
        {permissions.includes('write:themes') && (
          <MenuItem onClick={() => {}}>
            <GroupWorkIcon sx={{ fontSize: 15 }} />
            <Typography variant='bodySmall' sx={{ ml: '7px', mt: '1px', mr: '2px' }}>{t('modelPortfolioTable.createTheme')}</Typography>
          </MenuItem>
        )}
      </Menu>
    )}
    </>
  );
};

export default ModelPortfolios;
