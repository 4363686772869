import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import fromGoalWorkflow from './createSubAccountWorkflow.workflow.json';
import fromAccountWorkflow from './createSubAccountFromAccountWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { PageObjectType } from '../../5-page';
import { WorkflowContext } from '../workflowCompletion/workflowCompletion';

const initialWorkflowCompletion = (objectType: PageObjectType) => ({
  id: '123',
  currentStep: objectType === PageObjectType.GOAL ? fromGoalWorkflow.steps[0] : fromAccountWorkflow.steps[0],
  currentSubStep: objectType === PageObjectType.GOAL ? fromGoalWorkflow.steps[0].subSteps[0] : fromAccountWorkflow.steps[0].subSteps[0],
  workflow: objectType === PageObjectType.GOAL ? fromGoalWorkflow : fromAccountWorkflow,
});

const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount { id }
    }
  }
`;

export const CreateSubAccountWorkflow = ({
  open, setOpen, objectId, objectType, refetch, goalId,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType, refetch: () => void, goalId: string,
}) => {
  const [workflowData, setWorkflowData] = useState<any>(objectType === PageObjectType.GOAL ? { currentGoalId: goalId } : { currentAccountId: goalId });
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({
    objectId,
    ...initialWorkflowCompletion(objectType),
  });

  const updateNext = () => {
    updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
      refetch();
      setOpen(false);
      setWorkflowCompletion({ objectId, ...initialWorkflowCompletion });
    });
  };

  const updatePrevious = () => {
    updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
  };

  const [closeSubAccount] = useMutation(CLOSE_SUB_ACCOUNT, {
    variables: {
      input: {
        subAccountId: workflowData.currentSubAccountId,
        inactiveReason: 'OTHER',
      },
    },
  });

  const close = async () => {
    if (workflowData.currentSubAccountId) {
      await closeSubAccount();
    }
    setOpen(false);
    setWorkflowCompletion({ objectId, ...initialWorkflowCompletion });
  };

  return (
    <WorkflowContext.Provider value={{ workflowData, setWorkflowData }}>
      <WorkflowCompletionVisual
        workflowCompletion={workflowCompletion}
        open={open}
        loading={false}
        onClose={close}
        onNext={updateNext}
        previousStep={updatePrevious}
      />
    </WorkflowContext.Provider>
  );
};
