import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

const countDecimals = (value?: any) => {
  const split = value?.toString().split('.');
  return split?.length === 2 ? split[1].length : 0;
};

interface NumberFieldProps {
  allowNegative?: boolean;
  decimalPlaces?: number;
  inputError?: boolean;
  errorText?: string;
  setNumberValue: (value?: number) => void;
}

const NumberField = ({
  allowNegative = false, decimalPlaces = 4, setNumberValue, inputError, errorText, ...props
}: TextFieldProps & NumberFieldProps) => (
  <TextField
    {...props}
    type='number'
    value={props.value ?? ''}
    onKeyPress={(event) => {
      if ((!allowNegative && (event?.key === '-' || event?.key === '+')) || event?.key === 'e') {
        event.preventDefault();
      }
      if (decimalPlaces > 0 && countDecimals(props.value) >= decimalPlaces && event?.key === '0') {
        event.preventDefault();
      }
    }}
    onChange={(e) => {
      if (e.target.value === undefined) return;

      const [integer, fractional] = e.target.value.split('.');

      if (fractional === undefined) {
        setNumberValue(integer === '' ? undefined : Number(integer));
      } else {
        setNumberValue(Number(`${integer}.${fractional.substring(0, decimalPlaces)}`));
      }
    }}
    error={inputError}
    helperText={inputError && errorText}
  />
);

export default NumberField;
