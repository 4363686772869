/* eslint-disable object-curly-newline */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { getAmountFieldInputColorByKey, getKey, getPendingAmountCentsByKey, isSellAllByKey } from './utils';
import { TableCell } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import { AmountField } from '../../../../3-pattern';
import ChangeTooltip from './changeTooltip';
import { formatMoneyValue } from '../../../../../util';

export const SubAccountHoldingTableCell = ({
  security,
  subAccount,
  pendingTrades,
  handleHoldingChange,
  isDisabled,
  viewPreTradePositions = false,
  isMakeHigher = false,
}: {
  security: PortfolioSecurity;
  subAccount: PortfolioSubAccount;
  pendingTrades: Map<string, DraftSubTradeRequest>[];
  handleHoldingChange: (security: PortfolioSecurity, subAccount: PortfolioSubAccount, amount: string) => void;
  isDisabled: boolean;
  viewPreTradePositions?: boolean;
  isMakeHigher?: boolean;
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { sys } = useThemeTokens();

  const key = getKey(security.id, subAccount.id);

  const pendingAmountCents = useMemo(() => getPendingAmountCentsByKey(key, pendingTrades), [key, pendingTrades]);
  const isSellAll = useMemo(() => isSellAllByKey(key, pendingTrades), [key, pendingTrades]);
  const fieldColor = useMemo(() => getAmountFieldInputColorByKey(key, pendingAmountCents, pendingTrades, sys), [key, pendingAmountCents, pendingTrades, sys]);

  const sx = {
    border: `1px solid ${Math.abs(pendingAmountCents) > 0 ? sys.color.warning : 'default'}`,
    borderRadius: '4px',
  };

  const inputProps = {
    style: {
      fontSize: '12px',
      color: fieldColor,
      fontVariantNumeric: 'tabular-nums',
      minWidth: '65px',
    },
  };

  return (
    <TableCell sx={{ height: isMakeHigher ? '50px' : '42px' }}>
      <Box sx={{ width: `${Math.max(subAccount.name.length * 10, 100)}px` }} justifyContent='start' display='flex' alignItems='center' gap={1}>
        <AmountField
          useFormatAmountValueCents
          fullWidth={false}
          size='small'
          label=''
          amount={`${(subAccount.holding?.valueCents ?? 0) + pendingAmountCents}`}
          disabled={isDisabled}
          sx={sx}
          inputProps={inputProps}
          setAmount={(e: any) => {
            handleHoldingChange(security, subAccount, e);
          }}
        />

        <ChangeTooltip change={pendingAmountCents} original={subAccount.holding?.valueCents ?? 0} isSellAll={isSellAll} />
      </Box>

      {viewPreTradePositions && Math.abs(pendingAmountCents) > 0 && (
        <Typography display='block'>
          {t('portfolioTable.original')}: {formatMoneyValue(subAccount.holding?.valueCents ?? 0)}
        </Typography>
      )}
    </TableCell>
  );
};
