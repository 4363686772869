import { useTranslation } from 'react-i18next';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { CreateSubAccountStep } from './steps/createSubAccountStep';
import { ChooseExistingAccountStep } from './steps/chooseExistingAccountStep';
import { SubAccountSummaryActionStep } from './steps/subAccountSummaryActionStep';

type Step = 'SELECT_EXISTING_ACCOUNT' | 'CREATE_SUB_ACCOUNT' | 'SUB_ACCOUNT_SUMMARY';

const steps:Record<Step, StepComponent> = {
  SELECT_EXISTING_ACCOUNT: ChooseExistingAccountStep,
  CREATE_SUB_ACCOUNT: CreateSubAccountStep,
  SUB_ACCOUNT_SUMMARY: SubAccountSummaryActionStep,
};

interface WizardContext {
  forObject: 'GOAL' | 'ACCOUNT'
  forId: string
}

interface WizardState {
  accountId: string,
  accountUserId?: string,
  goalId?: string,
  themeId: string,
  financialProductId: string,
  skipIPS: boolean,
  isPartial: boolean,
  allowClientDeposits: boolean,
  isPortfolioGoalAttached?: boolean,
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  accountId: '',
  goalId: '',
  themeId: '',
  financialProductId: '',
  skipIPS: true,
  isPartial: true,
  allowClientDeposits: true,
  isPortfolioGoalAttached: false,
};

export interface CreateSubAccountWizardProps {
  forObject: 'GOAL' | 'ACCOUNT',
  forId: string,
  disabled?: boolean,
}

export function CreateSubAccountWizard({
  forObject, forId, handleClose,
}: CreateSubAccountWizardProps & WizardControlProps) {
  const { t } = useTranslation('createSubAccountWizard');
  let firstStep:Step = 'SELECT_EXISTING_ACCOUNT';

  if (forObject === 'ACCOUNT') {
    initial.accountId = forId;
    firstStep = 'CREATE_SUB_ACCOUNT';
  }

  if (forObject === 'GOAL') {
    initial.goalId = forId;
  }

  return (
    <WizardBase
      title={t('title')}
      context={{
        forId, forObject,
      }}
      steps={steps}
      firstStep={firstStep}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
