import { TextField, ListItem, MenuItem } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_CORPORATIONS } from '../../corporations';
import { AffiliationRelations } from '../../../interfaces/affiliationRelations';
import { ADD_AFFILIATION } from '../../accountsDetail/components/Affiliates';
import CreateNewModal from '../../../components/modals/createNewModal';
import {
  AccountTypes,
  AffiliationTypes,
  ClientGroup,
  ClientGroupTypes,
  CORPORATE_ACCOUNTS_PRE_FIX,
  Relationship,
} from '../../../interfaces';
import { FETCH_CORPORATION } from '..';
import { validateForm } from '../../../util';
import { ValidateRule } from '../../../interfaces/validateRule';

const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        type
        id
      }
    }
  }
`;

export const FETCH_ACCOUNT_TYPES = gql`
  query fetchAccountTypes {
    fetchAccountTypes {
      accountTypes {
        value
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
      }
    }
  }
`;

const NewCorporateAccount = ({ clientGroup }: { clientGroup?: ClientGroup }) => {
  const { t } = useTranslation('client');
  const allRelationUsers: Relationship[] = clientGroup?.relationships || [];

  const [addAffiliation] = useMutation(ADD_AFFILIATION, {
    refetchQueries: [FETCH_CORPORATION, FETCH_CORPORATIONS],
  });

  if (allRelationUsers.length === 0 && clientGroup) {
    allRelationUsers.push({
      type: 'PRIMARY_BENEFICIARY',
      user: clientGroup.primaryUser,
    });
  }

  const [account, setAccount] = useState({
    userId: '',
    type: '',
  });

  const { data: accountTypes } = useQuery(FETCH_ACCOUNT_TYPES);
  const corporateAccountTypes = useMemo(() => accountTypes?.fetchAccountTypes?.accountTypes?.filter((a: any) => (a.value.startsWith(CORPORATE_ACCOUNTS_PRE_FIX))), [accountTypes]);

  const [createAccount, { loading }] = useMutation(CREATE_ACCOUNT, {
    variables: {
      input: { ...account, clientGroupId: clientGroup?.id },
    },
    refetchQueries: [FETCH_CORPORATION, FETCH_CORPORATIONS],
  });

  const formRules: ValidateRule[] = [
    {
      key: 'type',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'userId',
      rule: 'SHOULD_EXIST',
    },
  ];

  const CORPORATE_ACCOUNTS_WITH_AUTHORIZED_INDIVIDUALS = [
    AccountTypes.CORPORATE_CASH,
    AccountTypes.CORPORATE_CHARITY,
    AccountTypes.CORPORATE_ESTATE,
  ];

  return (
    <CreateNewModal
      loading={loading}
      disabled={!validateForm(formRules, account)}
      title={t('coporateAccounts.newAccount.createAccount')} onSubmit={async () => {
        await createAccount().then((res: any) => {
          if (clientGroup?.type === ClientGroupTypes.Business
            && CORPORATE_ACCOUNTS_WITH_AUTHORIZED_INDIVIDUALS.includes(res?.data?.createAccount?.account?.type as AccountTypes)) {
            addAffiliation({
              variables: {
                input: {
                  accountId: res?.data?.createAccount?.account?.id,
                  userId: clientGroup?.primaryUser.id,
                  relation: AffiliationRelations.Other,
                  type: AffiliationTypes.AuthorizedIndividual,
                },
              },
            });
          }
        });
      }} sx={{ marginTop: 1 }}
      buttonType='ICON'
    >
      <ListItem key='accountType'>
        <TextField select label={t('accountType')} fullWidth value={account.type} onChange={(e) => setAccount({ ...account, type: e.target.value })}>
          {corporateAccountTypes?.map((x: any) => (
            <MenuItem key={x.value} value={x.value}>{x.translatedName.en}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem key='corporateUser'>
        <TextField select label={t('coporateAccounts.newAccount.selectCorporateUser')} fullWidth value={account.userId} onChange={(e) => setAccount({ ...account, userId: e.target.value })}>
          {allRelationUsers.map((x: Relationship) => (
            <MenuItem key={x.user.id} value={x.user.id}>{`${x.user.firstName} ${x.user.lastName} (${x.user.primaryEmail}) - ${x.type.replace('_', ' ').toLocaleLowerCase()}`}</MenuItem>
          ))}
        </TextField>
      </ListItem>
    </CreateNewModal>
  );
};

export default NewCorporateAccount;
