import intersection from 'lodash/fp/intersection';
import { CustomField, CustomFieldConditionalRulesComparisonTypes } from '../interfaces/customField';

export const evaluateRule = (
  object: any,
  rule: { field: string, comparison: CustomFieldConditionalRulesComparisonTypes, value: any },
) => {
  let objectValue = object[rule.field];
  if (!objectValue && rule.field) {
    const relatedCustomObjs = object.customFields?.filter((a: CustomField) => a.key === rule.field) || [];
    if (relatedCustomObjs[0]) {
      objectValue = relatedCustomObjs[0].value;
    }
  }
  const expectedValue = rule.value;
  const { comparison } = rule;
  if (!objectValue) return false;
  switch (comparison) {
    case CustomFieldConditionalRulesComparisonTypes.EQUALS:
      return objectValue.toString() === expectedValue.toString();
    case CustomFieldConditionalRulesComparisonTypes.NOT_EQUALS:
      return objectValue.toString() !== expectedValue.toString();
    case CustomFieldConditionalRulesComparisonTypes.INCLUDES:
      if (Array.isArray(objectValue) && Array.isArray(expectedValue)) {
        const intersectionItems = intersection(objectValue, expectedValue) || [];
        return intersectionItems && intersectionItems.length > 0;
      }
      if (Array.isArray(objectValue)) {
        return objectValue.includes(expectedValue);
      }
      if (Array.isArray(expectedValue)) {
        return expectedValue.includes(objectValue);
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.NOT_INCLUDES:
      if (Array.isArray(objectValue) && Array.isArray(expectedValue)) {
        const intersectionItems = intersection(objectValue, expectedValue) || [];
        return intersectionItems && intersectionItems.length === 0;
      }
      if (Array.isArray(objectValue)) {
        return !objectValue.includes(expectedValue);
      }
      if (Array.isArray(expectedValue)) {
        return !expectedValue.includes(objectValue);
      }
      return true;
    case CustomFieldConditionalRulesComparisonTypes.IN:
      return !!expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.NOT_IN:
      return !expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN:
      return objectValue > expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN:
      return objectValue < expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds > expectedValue;
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds < expectedValue;
      }
      return false;
    default:
      return false;
  }
};

export const evaluateTriggerRules = (customField: CustomField, objectPayload?: any, ignoreCustomFieldTriggerRules?: boolean) => {
  if (!customField.conditionalRules || customField.conditionalRules.length === 0 || ignoreCustomFieldTriggerRules) {
    return true;
  }
  if (!objectPayload) {
    return false;
  }
  let result = true;
  for (const rule of customField.conditionalRules) {
    result = evaluateRule(objectPayload, rule);
    if (!result) break;
  }

  return result;
};
