/* eslint-disable max-len */
import {
  TextField, ListItem, MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';
import utc from 'dayjs/plugin/utc';
import CreateNewModal from '../../../components/modals/createNewModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

dayjs.extend(utc);

interface NewIdVerificationObject {
  methodOfIDVerification: string,
  documentType: string,
  documentID: string,
  userName: string,
  documentIssuingJurisdication: string,
  documentExpiryDate: string,
  verifiedDate: string,
}

export const CREATE_USER_ID_VERIFICATION_INFORMATION = gql`
  mutation createUserIDVerificationInformation($input: CreateUserIDVerificationInformationInput!) {
    createUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

const NewIdVerification = ({ afterCreate, userId }: { afterCreate: () => void, userId: string }) => {
  const { t } = useTranslation(['client']);
  const { showToast } = useGlobalToast();
  const [idVerificationObj, setIdVerificationObj] = useState<NewIdVerificationObject>({
    methodOfIDVerification: '',
    documentType: '',
    documentID: '',
    userName: '',
    documentIssuingJurisdication: '',
    documentExpiryDate: '',
    verifiedDate: dayjs(new Date()).format('YYYY-MM-DD'),
  });

  const [createUserIDVerificationInformation] = useMutation(CREATE_USER_ID_VERIFICATION_INFORMATION, {
    variables: {
      input: { ...idVerificationObj, userId },
    },
  });

  const idVerificationMethods = [
    'CREDIT_CHECK',
    'DIGITAL_DOCUMENT_CHECK',
    'DUAL_METHOD',
    'IN_PERSON_VALIDATION',
  ];

  const documentType = [
    'DRIVERS_LICENSE',
    'ID',
    'PASSPORT',
    'PERMANENT_RESIDENT_CARD',
    'PROVINCIAL_ID',
    'INDIAN_STATUS_CARD',
    'HEALTH_CARD',
    'CITIZENSHIP_CARD',
  ];

  const create = async () => {
    const response = await createUserIDVerificationInformation();
    if (response && response.data && !response.errors) {
      showToast({ message: t('client:profile.successMessages.id'), severity: 'info' });
      afterCreate();
    }
  };

  return (
    <CreateNewModal title={t('newIDVerification')} onSubmit={create} sx={{ marginTop: 1 }} buttonType="ICON" loading={false}>
      <ListItem>
        <TextField data-testid="methodOfIDVerification" select label={t('methodOfIDVerification')} fullWidth value={idVerificationObj.methodOfIDVerification} onChange={(e) => setIdVerificationObj({ ...idVerificationObj, methodOfIDVerification: e.target.value })}>
          {idVerificationMethods.map((x: string) => (
            <MenuItem key={x} value={x}>{t(`idVerificationMethods.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem>
        <TextField data-testid="documentType" select label={t('documentType')} fullWidth value={idVerificationObj.documentType} onChange={(e) => setIdVerificationObj({ ...idVerificationObj, documentType: e.target.value })}>
          {documentType.sort().map((x: string) => (
            <MenuItem key={x} value={x}>{t(`documentTypes.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem>
        <TextField data-testid="documentID" label={t('documentID')} fullWidth value={idVerificationObj.documentID} onChange={(e) => setIdVerificationObj({ ...idVerificationObj, documentID: e.target.value })} />
      </ListItem>
      <ListItem>
        <TextField data-testid="nameOnTheID" label={t('nameOnTheID')} fullWidth value={idVerificationObj.userName} onChange={(e) => setIdVerificationObj({ ...idVerificationObj, userName: e.target.value })} />
      </ListItem>
      <ListItem>
        <TextField data-testid="documentIssuingJurisdication" label={t('documentIssuingJurisdication')} fullWidth value={idVerificationObj.documentIssuingJurisdication} onChange={(e) => setIdVerificationObj({ ...idVerificationObj, documentIssuingJurisdication: e.target.value })} />
      </ListItem>
      <ListItem>
        <LocalizedDatePicker
          label={t('expiryDate')}
          value={dayjs(idVerificationObj.documentExpiryDate)}
          disablePast
          onChange={(date) => setIdVerificationObj({ ...idVerificationObj, documentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
          renderInput={(params) => <TextField fullWidth {...params} error={false} data-testid="expiryDate" />}
        />
      </ListItem>
      <ListItem>
        <LocalizedDatePicker
          label={t('verifiedDate')}
          value={dayjs(idVerificationObj.verifiedDate)}
          onChange={(date) => setIdVerificationObj({ ...idVerificationObj, verifiedDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewIdVerification;
