import {
  LocationOnOutlined, MailOutline, Phone,
} from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { colors } from 'theme/colors';
import { useHouseholdContext } from 'pages/household';
import { Box, Stack, Typography } from '@mui/material';
import { Avatar, Tooltip } from 'ovComponents';
import { memberColors } from 'pages/household/components/memberColors';
import { usePermissions } from 'providers/userContextProvider';
import dayjs from 'dayjs';
import { HouseholdFeePaymentEdit } from '../../../pages/household/components/householdFeePaymentEdit';
import {
  Account, ClientGroup, Relationship, SUITABILITY_REVIEW_VALIDITY_MONTHS,
} from '../../../interfaces';
import EditClientGroupBillingAndFeeTier from '../../../pages/client/components/editClientGroupBillingAndFeeTier';
import { formatPhoneNumber, generateClientNameString, generateShortAddressString } from '../../../util';
import { Summary, Pill, Highlight } from './summary';

interface SummaryProps {
  clientGroup: ClientGroup;
  updateProfile: () => void;
  archiveHousehold: () => void;
  onHighlightClicked: (prop: any) => void;
  showMemberOnly?: string,
  setShowMemberOnly: (id?: string) => void;
}

export function HouseholdSummary({
  clientGroup, updateProfile, archiveHousehold, showMemberOnly, setShowMemberOnly,
}: SummaryProps) {
  const { t } = useTranslation('household');
  const { permissions } = usePermissions();
  const [editBillingAndFees, setEditBillingAndFees] = useState(false);
  const [editFeePaymentAccounts, setEditFeePaymentAccounts] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  if (!clientGroup) return <></>;

  const showPills:Pill[] = []; // TODO labels as per OV-7610

  const relationships = !showMemberOnly
    ? clientGroup.relationships
    : clientGroup.relationships.filter((rel) => rel.user.id === showMemberOnly);

  if (relationships.map((rel) => rel.user.incompleteFields).flat().length === 0) {
    showPills.push({
      label: t('completed'),
      leadingIcon: 'checkmark',
    });
  } else {
    showPills.push({
      label: t('notCompleted'),
      leadingIcon: 'problem',
      tooltip: <>{t('incompleteFields')}{formatClientGroupIncompleteFields(relationships)}</>,
    });
  }

  const householdIFACount = clientGroup.allIncompleteFormAgreements?.length ?? 0;
  if (householdIFACount) {
    showPills.push({
      label: t('unsignedDocuments', { count: householdIFACount }),
      leadingIcon: 'problem',
      tooltip: t('unsignedDocumentsTooltip'),
    });
  } else {
    showPills.push({
      label: t('allDocumentSigned'),
      leadingIcon: 'checkmark',
    });
  }

  const usersWithoutIdVerified = relationships.filter((rel) => !rel.user.iDVerified).map((rel) => rel.user);
  showPills.push({
    label: usersWithoutIdVerified.length === 0 ? t('idVerified') : t('idNotVerified'),
    leadingIcon: usersWithoutIdVerified.length === 0 ? 'checkmark' : 'problem',
    ...(usersWithoutIdVerified.length > 0 && {
      tooltip: `${t('idVerificationNotCompletedTooltip')}: ${usersWithoutIdVerified.map((x) => generateClientNameString(x)).join(', ')}`,
    }),
  });

  if (permissions.includes('read:client_low_risk_pii')) {
    const usersWithoutComplianceApproved = relationships.filter((rel) => rel.user.complianceState !== 'APPROVED').map((rel) => rel.user);
    if (usersWithoutComplianceApproved.length === 0) {
      showPills.push({
        label: t('complianceApproved'),
        leadingIcon: 'checkmark',
      });
    } else {
      showPills.push({
        label: t('complianceNeedsReview'),
        leadingIcon: 'problem',
        tooltip: `${t('complianceNeedsReviewTooltip')}: ${usersWithoutComplianceApproved.map((x) => generateClientNameString(x)).join(', ')}`,
      });
    }
  }

  const usersWithReviewDate = relationships
    .filter((rel:Relationship) => rel.user.lastSuitabilityReviewAt !== null);
  const usersNeedSuitabilityReview = relationships
    .filter((rel:Relationship) => dayjs().diff(rel.user.lastSuitabilityReviewAt, 'month') >= SUITABILITY_REVIEW_VALIDITY_MONTHS)
    .map((rel) => rel.user);

  if (usersWithReviewDate.length > 0) {
    if (usersNeedSuitabilityReview.length === 0) {
      showPills.push({
        label: t('annualInfoUpToDate'),
        leadingIcon: 'checkmark',
      });
    } else {
      showPills.push({
        label: t('annualInfoNeedsReview'),
        leadingIcon: 'problem',
        tooltip: `${t('annualInfoNeedsReviewTooltip', { months: SUITABILITY_REVIEW_VALIDITY_MONTHS })}: ${usersNeedSuitabilityReview.map((x) => generateClientNameString(x)).join(', ')}`,
      });
    }
  }

  const allFeePaymentAccounts: string[] = clientGroup.accounts
    ?.map((acc:Account) => acc.feePaymentAccount ?? acc)
    .map((acc:Account) => `${acc.type} ${acc.custodianAccountNumber ?? ''}`)
    ?? [];
  const uniqueFeePaymentAccounts = uniq(allFeePaymentAccounts);

  const highlights: Highlight[] = [
    {
      leadingLabel: t('summary.members'),
      label: clientGroup.relationships.length.toString(),
    },
    {
      leadingLabel: t('summary.primaryContact'),
      label: generateClientNameString(clientGroup.primaryUser),
    },
    {
      icon: <Phone sx={{ color: !clientGroup.primaryUser.phone ? `${colors.error} !important` : 'inherit' }} />,
      label: formatPhoneNumber(`${clientGroup.primaryUser.phone}`),
    },
    {
      icon: <MailOutline />,
      label: clientGroup.primaryUser.primaryEmail ?? '',
    },

    {
      icon: <LocationOnOutlined />,
      label: generateShortAddressString(clientGroup.primaryUser.physicalAddress),
    },
    {
      leadingLabel: t('summary.feeGrid'),
      label: clientGroup.feeTier?.name ?? t('summary.inheritedDashWhat', { what: clientGroup.applicableFeeTier?.name }),
      onClick: () => {
        setEditBillingAndFees(true);
        setSelectedHighlight('feeGrid');
      },
    },
    {
      leadingLabel: t('summary.billingCycle'),
      label: clientGroup.billingSchedule?.frequency
        ? t(`feeAndBilling:billingSchedule.frequencies.${clientGroup.billingSchedule?.frequency}`)
        : t('summary.inheritedDashWhat', { what: t(`feeAndBilling:billingSchedule.frequencies.${clientGroup.applicableBillingSchedule?.frequency}`) }),
      onClick: () => {
        setEditBillingAndFees(true);
        setSelectedHighlight('billingCycle');
      },
    },
    {
      leadingLabel: t('summary.feePaymentAccount'),
      label: uniqueFeePaymentAccounts.length > 0
        ? uniqueFeePaymentAccounts.length === 1
          ? uniqueFeePaymentAccounts[0]
          : t('summary.multiple')
        : 'n/a',
      onClick: () => {
        setEditFeePaymentAccounts(true);
      },
    },
  ];

  return (
    <>
    <Summary
      title={<InteractiveTitle title={clientGroup.name} chosenMemberId={showMemberOnly} setChosenMemberId={setShowMemberOnly}/>}
      pills={showPills}
      highlights={highlights}
      contextShowId={clientGroup.id}
      contextMenu={[
        {
          label: t('editHousehold'),
          onClick: updateProfile,
        },
        {
          label: t('archiveHousehold'),
          onClick: archiveHousehold,
        },
      ]}
    />
    <EditClientGroupBillingAndFeeTier
      title={t('billingModal.title')}
      open={editBillingAndFees}
      clientGroupToUpdate={clientGroup}
      afterUpdate={() => setEditBillingAndFees(false)}
      highlight={selectedHighlight}
      handleClose={() => setEditBillingAndFees(false)}
    />
    {editFeePaymentAccounts && (
      <HouseholdFeePaymentEdit
        accounts={clientGroup.accounts}
        handleClose={() => setEditFeePaymentAccounts(false)}
      />
    )}
    </>
  );
}

const InteractiveTitle = ({ title, chosenMemberId, setChosenMemberId }: { title: string, chosenMemberId?:string, setChosenMemberId: (id?: string) => void }) => {
  const householdContext = useHouseholdContext();
  const [hoverIdx, setHoverIdx] = useState<number>();
  const [chosenIdx, setChosenIdx] = useState<number | undefined>(householdContext?.members.findIndex((m) => m.id === chosenMemberId));

  if (!householdContext) return null;

  const onAvatarClick = (index: number) => {
    if (index !== chosenIdx) {
      setChosenIdx(index);
      setChosenMemberId(householdContext.members[index].id);
    } else {
      setChosenIdx(undefined);
      setChosenMemberId(undefined);
    }
  };

  return (
    <Stack direction='row'>
      <Typography variant="h4" sx={{ fontWeight: 600, marginRight: '0.33em' }}>{title}</Typography>
        {householdContext.members.map((m:any, i:number) => <Box
          key={i}
          onClick={() => onAvatarClick(i)}
          onMouseOver={() => setHoverIdx(i)}
          onMouseOut={() => setHoverIdx(undefined)}
          sx={{
            width: 28,
            cursor: 'pointer',
            zIndex: i === hoverIdx ? 1001 : (i === chosenIdx ? 1000 : undefined),
            position: 'relative',
            bottom: -3,
          }}
        >
          <Tooltip title={m.name}>
            <Avatar
              size='large'
              withShadow
              bgColor={memberColors(i).bg}
              fgColor={memberColors(i).fg}
              state={ (i === chosenIdx) ? 'clear' : (i === hoverIdx ? 'highlight' : 'default') }
            >
              {m.initials}
            </Avatar>
          </Tooltip>
        </Box>)}
    </Stack>
  );
};

const formatClientGroupIncompleteFields = (relationships: Relationship[]): JSX.Element[] => {
  const formatted:JSX.Element[] = [];

  relationships.forEach((rel, index1) => {
    if (rel.user.incompleteFields && rel.user.incompleteFields.length > 0) {
      formatted.push(<h4 key={index1}>{generateClientNameString(rel.user)}</h4>);
      rel.user.incompleteFields.forEach((message, index2) => formatted.push(<li key={index2}>{message}</li>));
    }
  });

  return formatted;
};
