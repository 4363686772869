import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Icon,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
} from 'ovComponents/2-component';
import {
  AccountStates,
  AccountTypes,
  Affiliation,
  DraftAccount,
  Goal,
} from 'interfaces';
import { BeneficiariesCrud } from 'ovComponents/3-pattern/beneficiaryCrud/beneficiariesCrud';
import { Grid } from '@mui/material';
import { useTokens } from 'ovComponents/0-tokens';
import { HeadingWithCheckbox } from '../tfsa/tsfa.dialogContent';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';
import { FormErrors } from '../../../utils';

interface RRSPDialogContentInterface {
  userData: any,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

export const RRSPDialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: RRSPDialogContentInterface) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [beneficiariesEnabled, setBeneficiariesEnabled] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState<Affiliation[]>([]);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const goals = getValidGoals(userData);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [touched, setTouched] = useState<string[]>([]);
  const { sys } = useTokens({});

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    let newErrors:FormErrors = null;

    if (beneficiariesEnabled && (candidateFields?.includes('beneficiaries') || candidateFields === undefined)) {
      const totalAllocation = beneficiaries.reduce((value, prev) => value + (prev.allocation ?? 0), 0);
      if (totalAllocation !== 100) {
        newErrors = { beneficiaries: { active: true } };
      }
    }

    setErrors(newErrors);
    return newErrors;
  }, [beneficiariesEnabled, beneficiaries]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setTouched(Object.keys(formErrors));
      return;
    }

    const affiliations: Affiliation[] = [...beneficiaries];

    onAccountCreated({
      type: AccountTypes.RRSP,
      applyForGovFunds: [],
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      affiliations,
      goalsLinked,
    });
  };

  useEffect(() => {
    validate(touched);
  }, [validate, touched]);

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:rrspAccountCreation.title')}</Typography>

      <Box sx={{ my: 3 }} minHeight={'200px'}>
        <HeadingWithCheckbox
          label={t('workflowCompletions:tfsaAccountCreation.addBeneficiaries')}
          checked={beneficiariesEnabled}
          onChange={setBeneficiariesEnabled}
        />
        <Grid style={beneficiariesEnabled ? {} : { opacity: 0.5 }}>
          { errors?.beneficiaries && (
            <Typography variant='bodySmall' sx={{ color: sys.color.negative }}>
              {t('components:beneficiaryCrud.allocationError')}
            </Typography>
          )}
          <BeneficiariesCrud
            beneficiaries={beneficiaries}
            setBeneficiaries={setBeneficiaries}
            disabled={!beneficiariesEnabled}
          />
        </Grid>
        {showGoalLinkingSection && <Box sx={{ mt: 4 }}>
          <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
        </Box>}
      </Box>

      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:rrspAccountCreation.addAccount')}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default RRSPDialogContent;
