import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const FETCH_GOAL_NAME = gql`
  query fetchGoalName($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal { type name }
    }
  }
`;

export function GoalBreadcrumb() {
  const { t } = useTranslation();
  const { data } = useQuery(FETCH_GOAL_NAME, { variables: { id: useParams().goalId } });

  function goalName(goal: any) {
    return goal.name ? goal.name : t(`client:goalType.${goal.type}`);
  }

  return data ? goalName(data.fetchGoal?.goal) : t('client:goal');
}
