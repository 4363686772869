import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';
import AddNewProfileIcon from 'assets/images/icons-filled/add-new-profile.svg';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from 'ovComponents/2-component';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from 'ovComponents/1-primative';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { useCallback, useContext } from 'react';
import { ActiveEntityProps, UserContext } from 'providers/userContextProvider';
import { EntityTypes } from 'interfaces';
import { FeatureFlagTypes } from 'interfaces/organizationFeatureFlag';
import { entityInitials, entityName, isFeatureEnabled } from '../../../util';
import {
  colors,
  colorsInner,
} from '../entityMenu/entityMenu';
import filterHouseholdsWithAccess from '../../../util/filterHouseholdsWithAccess';

export const ViewAllProfilesDialog = ({
  open,
  onAddNewProfile,
  onViewDetail,
  onCancel,
}: {
  open: boolean,
  onAddNewProfile: () => void,
  onViewDetail: () => void,
  onCancel: () => void,
}) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation('components');
  const {
    userContext,
    activeEntity,
    setActiveEntity,
    setActiveHousehold,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const profileName = useCallback((entity?: ActiveEntityProps) => (entityName(entity) || entity?.primaryEmail), []);
  const profileInitials = useCallback((entity?: ActiveEntityProps) => entityInitials(entity), []);
  const getEntitiesSortedByName = (): {
    entity: {
      id: string,
      type?: EntityTypes,
      entityName?: string,
      firstName?: string,
      lastName?: string,
      primaryEmail?: string,
      households?: any,
    }
  }[] => {
    const entities = [
      ...(userContext?.entities?.filter((x) => !x.entity.affiliateOnly) ?? []),
    ];
    entities.sort((item1: any, item2: any) => (profileName(item1.entity) > profileName(item2.entity) ? 1 : -1));
    return entities;
  };
  const avatar = (value: string, index: number) => {
    const colorIndex = index % colors.length;
    return (
      <Avatar state='highlight' size='large' fgColor={colors[colorIndex]} bgColor={colorsInner[colorIndex]}>
        {value}
      </Avatar>
    );
  };
  return (
    <Dialog open={open}>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='headingLarge'>{t('viewAllProfilesDialog.switchProfile')}</Typography>
        <IconButton onClick={() => onCancel()}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' alignItems='center' flexDirection='row' justifyContent='space-between' marginBottom={sys.spacing.xxl}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <span style={{ marginRight: sys.spacing.lg }}>{avatar(profileInitials(activeEntity), 1)}</span>
            <Typography
              variant='bodyLarge'
              weight='bold'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                marginRight: sys.spacing.md,
              }}
            >
              {profileName(activeEntity)}
            </Typography>
            <CheckRoundedIcon style={{ fontSize: '14px' }} />
          </Box>
          <Typography
            variant='bodyMedium'
            onClick={() => onViewDetail()}
            sx={{
              cursor: 'pointer',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              marginRight: sys.spacing.md,
            }}
          >
            {t('viewAllProfilesDialog.viewDetails')}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {getEntitiesSortedByName().map((item, index: number) => (
            <Grid key={item.entity.id} item xs={6}>
              <Box
                onClick={() => {
                  const households = filterHouseholdsWithAccess(item.entity?.households || [], item.entity?.id);
                  const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && households.length > 0;
                  if (isHouseholdDefaultView) {
                    const household = households[0];
                    setActiveHousehold(household);
                    navigate(`/households/${household?.id}/clients/${item.entity.id}`);
                  }
                  setActiveEntity(item.entity);
                  onCancel();
                }}
                style={{
                  cursor: 'pointer',
                  height: '48px',
                  width: '256px',
                  border: `1px solid ${sys.color.outlineVariant}`,
                }}
                paddingLeft={sys.spacing.lg}
                display='flex'
                flexDirection='row'
                alignItems='center'
                borderRadius={sys.borderRadius.lg}
              >
                <span style={{ marginRight: sys.spacing.lg }}>{avatar(profileInitials(item.entity), index)}</span>
                <Typography
                  variant='bodyMedium'
                  align='left'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {profileName(item.entity)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box display='flex' alignItems='center' justifyContent='center' marginTop={sys.spacing.xxl}>
          {isFeatureEnabled(FeatureFlagTypes.ALLOW_ADDING_A_NEW_PROFILE) && (
            <Box display='flex' alignItems='center' onClick={onAddNewProfile} style={{ cursor: 'pointer' }}>
              <img src={AddNewProfileIcon} alt='view-profile-icon' style={{ marginRight: sys.spacing.lg }} />
              <Typography variant='bodyMedium'>{t('viewAllProfilesDialog.addNewEntity')}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewAllProfilesDialog;
