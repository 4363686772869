import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../util/useLocalization';

const FETCH_REBALANCE_TIMESTAMP = gql`
  query fetchRebalanceTimestamp($rebalanceId: ObjectID!) {
    fetchRebalance(rebalanceId: $rebalanceId) {
      rebalance {
        createdAt
      }
    }
  }
`;

export function RebalanceBreadcrumb() {
  const { data } = useQuery(FETCH_REBALANCE_TIMESTAMP, { variables: { rebalanceId: useParams().id } });
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();

  return `${t('rebalance:rebalanceOn')} ${data ? localizedDateTime(data.fetchRebalance?.rebalance.createdAt) : ''}`;
}
