import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  TextField,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';
import { isValidMongoId } from '../../../../util';

dayjs.extend(utc);

export interface BulkUpsertItemFilter {
  oneVestId?: string,
  externalId?: string,
  batchId?: string,
}

export const FilterBulkUpsertItems = ({
  filter, setFilter,
}: {
  filter: BulkUpsertItemFilter, setFilter: (filter: BulkUpsertItemFilter) => void,
}) => {
  const { t } = useTranslation(['devSettings', 'shared']);
  const filterExists = !!filter.oneVestId || !!filter.externalId || !!filter.batchId;
  const [filterState, setFilterState] = useState<BulkUpsertItemFilter>(filter);

  const onConfirm = () => {
    setFilter(filterState);
  };
  const onClose = () => {
    setFilterState(filter);
  };

  const disabled = (!!filterState.oneVestId && !isValidMongoId(filterState.oneVestId)) || (!!filterState.batchId && !isValidMongoId(filterState.batchId));

  return (
    <FilterModal filterExists={filterExists} onConfirm={onConfirm} onClose={onClose} disabled={disabled}>
      <TextField
        label={t('devSettings:bulkUpsertRequest.items.table.oneVestId')}
        fullWidth
        value={filterState.oneVestId ?? ''}
        onChange={(e: any) => setFilterState((f) => ({ ...f, oneVestId: e.target.value || undefined }))}
        sx={{ mb: 2 }}
        error={!!filterState.oneVestId && !isValidMongoId(filterState.oneVestId)}
        errorText={t('shared:invalidObjectId')}
      />
      <TextField
        label={t('devSettings:bulkUpsertRequest.items.table.externalId')}
        fullWidth
        value={filterState.externalId ?? ''}
        onChange={(e: any) => setFilterState((f) => ({ ...f, externalId: e.target.value || undefined }))}
        sx={{ mb: 2 }}
      />
      <TextField
        label={t('devSettings:bulkUpsertRequest.items.table.batchId')}
        fullWidth
        value={filterState.batchId ?? ''}
        onChange={(e: any) => setFilterState((f) => ({ ...f, batchId: e.target.value || undefined }))}
        sx={{ mb: 2 }}
        error={!!filterState.batchId && !isValidMongoId(filterState.batchId)}
        errorText={t('shared:invalidObjectId')}
      />
    </FilterModal>
  );
};
