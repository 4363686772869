import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import CreateNewModal from '../../../components/modals/createNewModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { FeeGridScopeTypes, FeeTier } from '../../../interfaces';
import FeeGridForm from './feeGridForm';
import { UserContext } from '../../../providers/userContextProvider';
import { FETCH_FEE_GRIDS } from './feeGridTable';
import { FETCH_ORGANIZATION_DEFAULT_FEE_TIER } from './defaultOrgFeeGridBox';

export const CREATE_FEE_GRID = gql`
  mutation createFeeTier($input: CreateFeeTierInput!) {
    createFeeTier(input: $input) {
      feeTier {
        id
      }
    }
  }
`;

const NewFeeGrid = () => {
  const { t } = useTranslation(['feeAndBilling', 'shared']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [feeTier, setFeeTier] = useState<FeeTier>({
    billableAUM: true,
  });
  const [type, setType] = useState<'FLAT' | 'TIERED'>('FLAT');

  const [createFeeTier, { loading }] = useMutation(CREATE_FEE_GRID, {
    refetchQueries: [FETCH_FEE_GRIDS, FETCH_ORGANIZATION_DEFAULT_FEE_TIER],
  });
  const [initialState] = useState(feeTier);
  const create = async (event: any) => {
    event.preventDefault();
    const inputProps = {
      organizationId: activeOrganization.id,
      name: feeTier?.name,
      chargeForCash: feeTier?.chargeForCash ?? true,
      chargeForCashOnHold: feeTier?.chargeForCashOnHold ?? true,
      default: feeTier?.default ?? false,
      ...(
        type === 'FLAT' ? {
          flatAnnualFeeBps: feeTier?.flatAnnualFeeBps,
        } : {
          blendedGrid: feeTier?.blendedGrid ?? true,
          gridScopeType: FeeGridScopeTypes.USER,
          grid: feeTier.grid?.map((g) => ({
            minMarketValueCents: g.minMarketValueCents,
            annualFeeBps: g.annualFeeBps,
            annualFeeCents: g.annualFeeCents,
          })),
        }
      ),
      gridScopeType: feeTier.billableAUM ? FeeGridScopeTypes.CLIENT_GROUP : FeeGridScopeTypes.USER,
    };
    const response = await createFeeTier({
      variables: {
        input: inputProps,
      },
    });
    if (response?.data) {
      showToast({ severity: 'info', message: t('feeAndBilling:feeGrid.new.createdToastMessage') });
      setFeeTier({});
    }
  };

  const disabled = !(
    feeTier.name && (
      (type === 'FLAT' && !isNil(feeTier.flatAnnualFeeBps))
      || (type === 'TIERED' && feeTier.grid && feeTier.grid.length > 1 && !feeTier.grid?.find((g) => isNil(g.annualFeeBps) && isNil(g.annualFeeCents)))
    )
  );
  return (
    <CreateNewModal
      state={feeTier}
      initialState={initialState}
      loading={loading} disabled={disabled} title={t('feeAndBilling:feeGrid.new.title')} onSubmit={create} fullWidth sx={{ marginTop: 1 }} >
      <FeeGridForm feeTier={feeTier} setFeeTier={setFeeTier} type={type} setType={setType} organizationId={activeOrganization.id} />
    </CreateNewModal>
  );
};

export default NewFeeGrid;
