import { Organization } from './organization';
import { BillingSchedule } from './billingSchedule';

export enum BillingCycleStates {
  CYCLE_IN_PROGRESS = 'CYCLE_IN_PROGRESS',
  CYCLE_COMPLETED = 'CYCLE_COMPLETED',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  APPROVED = 'APPROVED',
  PROCESSED = 'PROCESSED',
}

export interface BillingCycle {
  id?: string,
  billingSchedule?: BillingSchedule,
  billingDate?: Date,
  startDate?: Date,
  endDate?: Date,
  calculatedAt?: Date,
  calculationDate?: Date,
  organization?: Organization,
  marketValueCentsOnLastDay?: number,
  chargeableMarketValueCentsOnLastDay?: number,
  feeCents?: number,
  salesTaxCents?: number,
  projectedFeeAndTaxCents?: number,
  state?: BillingCycleStates,
  reconciledFees?: {
    feeCents?: number,
    salesTaxCents?: number,
  },
}

export const stateColor = (state?: BillingCycleStates) => (state && [BillingCycleStates.APPROVED, BillingCycleStates.PROCESSED].includes(state) ? 'success' : 'warning');
