import { useContext, useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { Card, CardContent } from '../../../2-component';
import { Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';
import { getEnabledCustomFields } from '../../workflowCompletion/subSteps/utils';
import {
  CustomField, CustomFieldObjectTypes, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields,
} from '../../../../interfaces/customField';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import GoalInformationVisual from './goalInformation.visual';
import { Goal } from '../../../../interfaces';

const FETCH_GOAL = (derivedCustomFields?: string[]) => gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        type
        name
        timeHorizon
        targetAmountCents
        riskQuestion1
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

const baseGoalData: Partial<Goal> = {
  type: undefined,
  name: undefined,
  timeHorizon: undefined,
  targetAmountCents: undefined,
  riskQuestion1: undefined,
  customFields: [],
};

export const GoalInformation = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation('client');
  const [goalData, setGoalData] = useState<Partial<Goal>>(baseGoalData);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const [dataTemplate, setDataTemplate] = useState<any>();
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const enabledCustomFields = getEnabledCustomFields(options);

  const [fetchGoal, { loading }] = useLazyQuery(FETCH_GOAL(), {
    variables: { goalId: objectId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => setDataTemplate(data),
  });

  const { loading: customFieldLoading, refetch: refetchCustomFields } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          objectType: CustomFieldObjectTypes.GOAL,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);
      await fetchGoal({
        variables: {
          goalId: objectId,
        },
        query: FETCH_GOAL(customFieldKeys),
      });
    },
  });

  const { refetch: refetchData } = useQuery(FETCH_GOAL(), {
    variables: { goalId: objectId },
    fetchPolicy: 'no-cache',
    skip: permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0,
    onCompleted: (data) => setDataTemplate(data),
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  useEffect(() => {
    if (dataTemplate) {
      const customFields = dataTemplate.fetchGoal?.goal?.customFields || [];

      setGoalData({
        type: dataTemplate.fetchGoal.goal.type || undefined,
        name: dataTemplate.fetchGoal.goal.name || undefined,
        timeHorizon: dataTemplate.fetchGoal.goal.timeHorizon || undefined,
        targetAmountCents: dataTemplate.fetchGoal.goal.targetAmountCents || undefined,
        riskQuestion1: dataTemplate.fetchGoal.goal.riskQuestion1 || undefined,
        customFields,
      });
    }
  }, [dataTemplate]);

  const [updateGoal, { loading: updateGoalLoading }] = useMutation(UPDATE_GOAL, {
    onCompleted: () => {
      if (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0) refetchCustomFields();
      else refetchData();
    },
    variables: {
      input: {
        ...goalData,
        customFields: modifyUpdatingCustomFields(goalData?.customFields, derivedCustomFields ?? [], derivedCustomFieldsKeys),
        goalId: objectId,
      },
    },
  });

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('goalInformation.title')}</Typography>
      <Card>
        <CardContent>
          <GoalInformationVisual
            options={options}
            goalData={goalData}
            updateGoal={setGoalData}
            continueFunc={updateGoal}
            loading={customFieldLoading || loading || updateGoalLoading}
            grid
            updateMode
            activeCustomFields={derivedCustomFieldsKeys}
          />
        </CardContent>
      </Card>
    </>
  );
};
