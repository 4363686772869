import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, Button,
} from '../../../../2-component';
import { UserContext } from '../../../../../providers/userContextProvider';

const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const EditSubAccount = ({ objectId, onClose }: { objectId: string, onClose: () => void }) => {
  const { t } = useTranslation('client');
  const [closeOpen, setCloseOpen] = useState(false);
  const { userId: paramsUserId, goalId, accountId } = useParams();
  const { activeEntity, activeOrganization } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const navigate = useNavigate();

  const [closeSubAccount, { loading }] = useMutation(CLOSE_SUB_ACCOUNT, {
    variables: {
      input: {
        subAccountId: objectId,
        inactiveReason: 'OTHER',
      },
    },
    onCompleted: () => {
      navigate(`/clients/${userId}/${goalId ? 'goal' : 'account'}/${goalId || accountId}`);
    },
  });

  const onCloseClose = () => {
    onClose();
    setCloseOpen(false);
  };

  return (
    <>
      { activeOrganization.availableFeatureFlags?.includes('CLOSE_SUB_ACCOUNT')
          && (<MenuItem onClick={() => setCloseOpen(true)}>{t('editSubAccount.close')}</MenuItem>)
      }
      <Dialog open={closeOpen} onClose={onCloseClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('editSubAccount.closeSubAccount')}</DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>{t('editSubAccount.closeSubAccountText')}</Typography>
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('editSubAccount.cancel')} onClick={onCloseClose} sx={{ mr: 1 }}/>
            <Button variant='filled' color='destructive' label={t('editSubAccount.close')} onClick={closeSubAccount} disabled={loading} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
