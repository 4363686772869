/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DialogContent } from '@mui/material';
import {
  Button,
  CircularProgress,
  DialogTitle,
  Form, IconButton,
} from 'ovComponents/2-component';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlagTypes } from 'interfaces/organizationFeatureFlag';
import { Box } from 'ovComponents/1-primative/box/box';
import { Typography } from 'ovComponents/1-primative';
import { useThemeTokens } from 'providers/themeTokenProvider';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { isFeatureEnabled } from '../../../../util';
import { UserContext } from '../../../../providers/userContextProvider';
import { IntegrationType } from '../../../../interfaces';

const FETCH_USER_BANK_ACCOUNT_IDS = gql`
  query fetchBankAccounts($input: FetchBankAccountsInput!) {
    fetchBankAccounts(input: $input) {
      bankAccounts {
        id
      }
    }
  }
`;

export const CREATE_BANK_CONNECTION = gql`
  mutation createBankConnection($input: CreateBankConnectionInput!) {
    createBankConnection(input: $input) {
      bankConnection {
        id
        loginId
        bankAccounts {
          id
          name
          institutionNumber
          transitNumber
          bankAccountNumber
        }
      }
    }
  }
`;

const Flinks = (
  {
    onNext,
    onCancel,
    bankAccounts,
    userId,
    afterCreate,
    transactionType,
    options,
  }: {
    onNext: (index: number) => void,
    onCancel: () => void,
    bankAccounts: { id: string; name: string; bankAccountNumber: string; }[],
    afterCreate: (newBankAccountId: string) => void,
    userId: string,
    transactionType?: 'DEPOSIT' | 'WITHDRAWAL',
    options?: any
  },
) => {
  const isDeposit = !transactionType || transactionType === 'DEPOSIT';
  const { t } = useTranslation(['components']);
  const { sys } = useThemeTokens();
  const { activeOrganization, integrations } = useContext(UserContext);
  const integrationClientIframeUrl = integrations?.find((a) => a?.type === IntegrationType.BANKING)?.configuration?.clientIframeUrl;
  const organizationClientIframeUrl = activeOrganization?.externalProvider?.bankingConnectorProvider.clientIframeUrl;
  /*
    in UCE, applicableIntegration will be the selected integration in the workflow configuration screen
    otherwise, the application will work as it was by getting activeOrganization integrations
  */
  const externalIframeUrl = options?.applicableIntegration ?? integrationClientIframeUrl ?? organizationClientIframeUrl;
  const getIframeUrl = (): string => {
    if (externalIframeUrl) return externalIframeUrl;
    return process.env.REACT_APP_FLINKS_URL ?? '';
  };
  const [credentials, setCredentials] = useState<{ loginId?: string; bankAccountRefId?: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetchingBank, setIsFetchinBank] = useState<boolean>(false);
  const enableManualBankAccount: boolean = isFeatureEnabled(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT) ?? false;
  const { refetch } = useQuery(FETCH_USER_BANK_ACCOUNT_IDS, {
    variables: {
      input: {
        filter: {
          userId,
        },
      },
    },
  });
  const fetchBanksAtInterval = async (): Promise<void> => {
    const interval = setInterval(async () => {
      const result = await refetch();
      const allBanks = result.data.fetchBankAccounts.bankAccounts ?? [];
      const distinctBankAccounts = allBanks.filter((ba: { id: string }) => !bankAccounts.some((old) => ba.id === old.id));
      if (distinctBankAccounts.length > 0) {
        clearInterval(interval);
        const latestAccount = distinctBankAccounts[distinctBankAccounts.length - 1];
        afterCreate(latestAccount.id);
        onCancel();
      }
    }, 5000);
    setTimeout(() => {
      onCancel();
      clearInterval(interval);
    }, 300000);
  };
  const [createBankConnectionMutation] = useMutation(CREATE_BANK_CONNECTION, {
    variables: {
      input: {
        loginId: credentials.loginId,
        bankAccountRefId: credentials.bankAccountRefId?.trim() ? credentials.bankAccountRefId.trim() : undefined,
        userId,
      },
    },
  });
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.step === 'REDIRECT') {
        setIsFetchinBank(true);
        fetchBanksAtInterval();
      }
      if (e.data.step === 'APP_MOUNTED') setIsLoading(false);
      if (e.origin === 'https://onevest9163.connect.flinks.dev' || e.origin === (externalIframeUrl ?? 'https://onevest-iframe.private.fin.ag')) {
        if (e.data.step && e.data.step.toLowerCase() === 'redirect') {
          setCredentials({
            loginId: e.data.loginId ?? '',
            bankAccountRefId: e.data.accountId,
          });
          fetchBanksAtInterval().then();
        }
      }
    });
  }, [onCancel]);
  useEffect(() => {
    if (credentials.loginId?.trim()) createBankConnectionMutation().then();
  }, [credentials.loginId, createBankConnectionMutation]);
  return (
    <Form>
        <DialogTitle sx={{ borderBottom: 'none' }} display="flex" justifyContent="end">
        <IconButton onClick={() => onCancel()}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '20px 0px',
              height: '550px',
              width: '550px',
            }}
          >
            {!isFetchingBank && (<iframe
              title='ov-flinks'
              style={{
                height: '550px',
                width: '550px',
                border: 0,
                visibility: isLoading ? 'hidden' : 'visible',
              }}
              src={getIframeUrl()}
            />)}
            {
              isLoading && (
                <Box display='flex' position='absolute' justifyContent='center' width='100%' top='45%' bottom='45%'>
                  <CircularProgress />
                </Box>
              )
            }
            {isFetchingBank && (<Box display='flex' flexDirection='column' justifyContent='center' height='550px' paddingX='40px'>
              <Box display='flex' flexDirection='column' alignItems='center' marginBottom='40px'>
                <CircularProgress style={{ marginBottom: sys.spacing.xl }} />
                <Typography variant='labelLarge' sx={{ mb: 3 }}>{t('components:bankAccountSelect.flinks.linkingTimeInfo')}</Typography>
              </Box>
              <Box display='flex' alignItems='center' borderRadius={sys.borderRadius.sm} padding='20px' border={`1px solid ${sys.color.outlineVariant}`}>
                  <InfoRoundedIcon color='primary' sx={{ marginRight: sys.spacing.xl }} />
                  <Typography variant='bodyMedium'>{t(`components:bankAccountSelect.flinks.linkingRedirectionInfo${isDeposit ? 'Deposit' : 'Withdrawal'}`)}</Typography>
              </Box>
            </Box>)}
            {
              enableManualBankAccount && !isFetchingBank && (
               <Button
                 onClick={() => onNext(3)}
                 variant='text'
                 label={t('components:bankAccountSelect.flinks.addManually')}
                 sx={{ fontSize: '14px', fontWeight: '500' }}
               />
              )
            }
          </DialogContent>
    </Form>
  );
};

export default Flinks;
