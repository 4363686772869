import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface ChipTokens {
  chip: {
    borderColor?: string;
    textColor?: string;
    labelColor?: string;
    hoverColor?: string;
    hoverBorderColor?: string;
  },
}

export const useChipTokens = (): ChipTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: ChipTokens = {
    chip: {
      borderColor: sys.color.outline,
      textColor: sys.color.onSurface,
      labelColor: sys.color.onSurfaceVariant,
      hoverColor: sys.color.primaryVariantHover,
      hoverBorderColor: sys.color.primary,
    },
  };

  return comp;
};
