import { Check } from '@mui/icons-material';
import {
  Grid, Box, Typography,
} from '../../1-primative';
import {
  RadioGroup,
} from '../../2-component';
import { useSelectionChipTokens } from './selectionChip.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

interface SelectionChipProps {
  type?: 'checkbox' | 'radio';
  value: string | number;
  options: Array<{
    label: string, subtitle?: string, value: string | number, dataTestId?: string
  }>;
  onChange: any,
}

export const SelectionChip = ({
  type = 'radio', value, options, onChange,
}: SelectionChipProps) => {
  const tokens = useThemeTokens(useSelectionChipTokens());
  const { comp } = tokens;

  if (type === 'radio') {
    return (
      <RadioGroup value={value} label='' onChange={onChange} fullWidth>
        <Grid container spacing={2} width='100%'>
          {(options || []).map((option, index) => (
            <Box data-testid={option?.dataTestId} key={index} justifyContent='center' textAlign='center' sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              color: value === option.value ? comp.selectionChip.text.active : comp.selectionChip.text.default,
              borderRadius: comp.selectionChip.borderRadius,
              backgroundColor: value === option.value ? comp.selectionChip.background.active : comp.selectionChip.background.default,
              padding: '8px',
              mt: 1,
              mr: 1.5,
              '&:hover': {
                color: comp.selectionChip.text.hover,
                cursor: 'pointer',
              },
            }}
              onClick={() => onChange(option.value)}>
              {value === option.value && (
                <Check sx={{ fontSize: '14px !important', mr: 0.5 }} />
              )}
              <Typography variant='bodySmall' weight='bold'>{option.label}</Typography>
            </Box>
          ))}
        </Grid>
      </RadioGroup>
    );
  }
  return (
    // TODO: Checkbox
    <></>
  );
};

export default SelectionChip;
