export interface FeatureFlagOption {
  type?: string,
  enabled?: boolean,
}

export interface OrganizationFeatureFlag {
  id?: string
  featureFlags?: FeatureFlagOption[],
}

export interface FeatureFlag {
  value?: string,
  group?: string,
  translatedDescription?: { en?: string },
}

export enum FeatureFlagTypes {
  CASH_JOINT = 'CASH_JOINT',
  CORPORATE_ACCOUNTS = 'CORPORATE_ACCOUNTS',
  GRSP = 'GRSP',
  LIF = 'LIF',
  LIRA = 'LIRA',
  PRIF = 'PRIF',
  RDSP = 'RDSP',
  RESP = 'RESP',
  RESP_SINGLE = 'RESP_SINGLE',
  RESP_FAMILY = 'RESP_FAMILY',
  RESP_ADULT = 'RESP_ADULT',
  RIF_SPOUSAL = 'RIF_SPOUSAL',
  RRIF = 'RRIF',
  RRSP_SPOUSAL = 'RRSP_SPOUSAL',
  TAX_LOSS_HARVESTING = 'TAX_LOSS_HARVESTING',
  FIX_TRADING = 'FIX_TRADING',
  MANUALLY_ADD_BANK_ACCOUNT = 'MANUALLY_ADD_BANK_ACCOUNT',
  DEPOSITS = 'DEPOSITS',
  WITHDRAWALS = 'WITHDRAWALS',
  AUTO_INVESTMENTS = 'AUTO_INVESTMENTS',
  CREATE_NEW_GOALS = 'CREATE_NEW_GOALS',
  CREATE_NEW_SUB_ACCOUNTS = 'CREATE_NEW_SUB_ACCOUNTS',
  UPDATE_GOAL = 'UPDATE_GOAL',
  UPDATE_SUB_ACCOUNT = 'UPDATE_SUB_ACCOUNT',
  CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  UPDATE_PERSONAL_DETAILS = 'UPDATE_PERSONAL_DETAILS',
  VIEW_HELP_CENTER = 'VIEW_HELP_CENTER',
  VIEW_STATEMENTS = 'VIEW_STATEMENTS',
  VIEW_TAX_DOCUMENTS = 'VIEW_TAX_DOCUMENTS',
  VIEW_AGREEMENTS = 'VIEW_AGREEMENTS',
  ID_VERIFICATION = 'ID_VERIFICATION',
  ACCOUNT_TO_ACCOUNT_DEPOSIT = 'ACCOUNT_TO_ACCOUNT_DEPOSIT',
  RESP_FAMILY_JOINT = 'RESP_FAMILY_JOINT',
  RESP_SINGLE_JOINT = 'RESP_SINGLE_JOINT',
  RLSP = 'RLSP',
  LRSP = 'LRSP',
  RLIF = 'RLIF',
  LRIF = 'LRIF',
  ANNUAL_INFORMATION_UPDATE = 'ANNUAL_INFORMATION_UPDATE',
  SUITABILITY_REVIEW_DUE_NOTIFICATIONS = 'SUITABILITY_REVIEW_DUE_NOTIFICATIONS',
  SUITABILITY_REVIEW_OVERDUE_BLOCK_TRANSFERS = 'SUITABILITY_REVIEW_OVERDUE_BLOCK_TRANSFERS',
  PAD_AGREEMENTS = 'PAD_AGREEMENTS',
  BLOCK_CLIENT_SIGN_UP = 'BLOCK_CLIENT_SIGN_UP',
  AUTOMATED_BANK_ACCOUNT_LINKING = 'AUTOMATED_BANK_ACCOUNT_LINKING',
  TFSA = 'TFSA',
  FHSA = 'FHSA',
  RRSP = 'RRSP',
  PERSONAL = 'PERSONAL',
  ADVISOR_APPROVAL_REQUIRED_FOR_CLIENTS_TO_SIGN_AGREEMENTS = 'ADVISOR_APPROVAL_REQUIRED_FOR_CLIENTS_TO_SIGN_AGREEMENTS',
  HOUSEHOLD = 'HOUSEHOLD',
  ASSET_CLASSES = 'ASSET_CLASSES',
  DOCUMENTS = 'DOCUMENTS',
  AUTHORIZED_ACCOUNT_ACCESS = 'AUTHORIZED_ACCOUNT_ACCESS',
  CUSTODIAN_STATEMENT = 'CUSTODIAN_STATEMENT',
  ONEVEST_STATEMENT = 'ONEVEST_STATEMENT',
  BYPASS_ID_VERIFICATION = 'BYPASS_ID_VERIFICATION',
  ALLOW_ADDING_A_NEW_PROFILE = 'ALLOW_ADDING_A_NEW_PROFILE',
}
