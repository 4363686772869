import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '../../2-component/dialog/dialog';
import { Button } from '../../2-component/button/button';
import { Typography } from '../../1-primative/typography/typography';
import { Box } from '../../1-primative/box/box';

export interface SessionTimeOutModalProps {
  open: boolean,
  onLogout: () => void,
  stayLoggedIn: () => void,
  onSessionTimeOut: () => void,
}

const InactiveLayoutModal = ({
  open, onLogout, stayLoggedIn, onSessionTimeOut,
}: SessionTimeOutModalProps): JSX.Element => {
  const { t } = useTranslation(['shared']);
  const [duration, setDuration] = useState(30);
  const ticker = useRef<NodeJS.Timer | undefined>();

  const startTimer = (): void => {
    ticker.current = setInterval(() => setDuration((prev) => prev - 1), 1000);
  };

  const handleClose = (): void => {
    if (ticker.current) clearInterval(ticker.current);
    stayLoggedIn();
    setDuration(30);
  };

  useEffect(() => {
    if (open) startTimer();
    return () => ticker.current && clearInterval(ticker.current);
  }, [open]);

  useEffect(() => {
    if (duration <= 0) {
      if (ticker.current) clearInterval(ticker.current);
      onSessionTimeOut();
    }
  }, [duration, onSessionTimeOut]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>{t('inactiveModal.headingText')}</DialogTitle>
      <DialogContent>
        <Typography variant='bodyLarge'>{`${t('inactiveModal.bodyText1')} ${duration} ${t('inactiveModal.bodyText2')}`}</Typography>
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <Button variant='text' color='destructive' sx={{ mr: 1 }} label={t('inactiveModal.suffixButton')} onClick={onLogout} />
          <Button variant='filled' label={t('inactiveModal.prefixButton')} onClick={handleClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default InactiveLayoutModal;
