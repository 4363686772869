import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const PriceChart = ({ options }: { options?: any }) => {
  useRefinitivWidget('#price-chart-widget', 'WPriceChart', options);

  return (
    <Card>
      <div id='price-chart-widget'></div>
    </Card>
  );
};
