import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { InvestmentKnowledgeVisual } from './investmentKnowledge.visual';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        investmentKnowledge
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const InvestmentKnowledge = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const [userData, setUserData] = useState<any>({
    investmentKnowledge: null,
  });

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        userId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        investmentKnowledge: data.fetchUser.user.investmentKnowledge || undefined,
      });
    }
  }, [data]);

  return (
    <InvestmentKnowledgeVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default InvestmentKnowledge;
