import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { usePermissions } from '../../../providers/userContextProvider';
import EditBillingAndFeeTier from './editBillingAndFeeTier';
import { FETCH_ACCOUNT } from '../../accountsDetail';

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const EditAccountBilling = ({
  afterUpdate, accountToUpdate, open, handleClose, highlight,
}: { afterUpdate: () => void, accountToUpdate: any, open: boolean, handleClose: () => void, highlight?: string }) => {
  const { permissions } = usePermissions();
  const [localOpen, setLocalOpen] = useState(open);

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      input: {},
    },
    refetchQueries: [FETCH_ACCOUNTS(permissions), FETCH_ACCOUNT(permissions)],
  });

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const update = async (event: any, changeableApplicableFeeTier: string, changeableApplicableBillingSchedule: string) => {
    event.preventDefault();
    await updateAccount({
      variables: {
        input: {
          accountId: accountToUpdate?.id,
          feeTierId: changeableApplicableFeeTier === 'inherited' ? null : changeableApplicableFeeTier,
          billingScheduleId: changeableApplicableBillingSchedule === 'inherited' ? null : changeableApplicableBillingSchedule,
        },
      },
    });
    afterUpdate();
  };

  if (!accountToUpdate) {
    return <></>;
  }

  return (
    <EditBillingAndFeeTier
      open={localOpen}
      setOpen={(value) => {
        setLocalOpen(value);
        handleClose();
      }}
      loading={loading}
      highlight={highlight}
      organizationId={accountToUpdate.user.organization.id}
      feeTierId={accountToUpdate.feeTier?.id ?? undefined}
      applicableFeeTierId={accountToUpdate.applicableFeeTier?.id}
      billingScheduleId={accountToUpdate.billingSchedule?.id ?? undefined}
      applicableBillingScheduleId={accountToUpdate.applicableBillingSchedule?.id}
      callback={(event, applicableFeeTierId, applicableBillingScheduleId) => update(event, applicableFeeTierId, applicableBillingScheduleId)}
    />
  );
};

export default EditAccountBilling;
