import {
  Alert,
  Box,
  Button, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HouseholdSelect } from 'components/inputs/householdSelect';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { generateClientNameString } from '../../../../util';
import { AffiliationRelations } from '../../../../interfaces/affiliationRelations';
import { StepProps } from '../wizard';
import { ClientGroup, ClientGroupMemberAccessType } from '../../../../interfaces';

const FETCH_USERS_HOUSEHOLD = gql`
  query fetchUsersClientgroup($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user { household { id } }
    }
  }
`;

export function ChooseHouseholdStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('addClientToHouseholdWizard');
  const [alreadyMemberOfSame, setAlreadyMemberOfSame] = useState(false);
  const [alreadyMemberOfAnother, setAlreadyMemberOfAnother] = useState(false);

  /* check: is the client member of any other household? */
  const { data: userData } = useQuery(FETCH_USERS_HOUSEHOLD, { fetchPolicy: 'no-cache', variables: { userId: context.userId } });

  useEffect(() => {
    if (!userData) return;
    if (!state.clientGroup) return;

    if (userData.fetchUser.user.household?.id) {
      setAlreadyMemberOfSame(userData.fetchUser.user.household?.id === state.clientGroup.id);
      setAlreadyMemberOfAnother(userData.fetchUser.user.household?.id !== state.clientGroup.id);
    } else {
      setAlreadyMemberOfAnother(false);
      setAlreadyMemberOfSame(false);
    }
  }, [userData, state]);

  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <HouseholdSelect
            label={t('client')}
            autoFocus
            clientGroup={state.clientGroup}
            setClientGroup={(newValue) => setState({ ...state, clientGroup: newValue as ClientGroup })}
          />
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('relationshipTo', { whom: generateClientNameString(state.clientGroup?.primaryUser) })}
            value={state.relationship}
            onChange={(event) => setState({ ...state, relationship: event.target.value })}
          >
            {Object.values(AffiliationRelations).map((relation, index) => (
              <MenuItem key={index} value={relation}>
                {t(`affiliationTypes:affiliationRelationship.${relation}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('accessType')}
            value={state.accessType}
            onChange={(event) => setState({ ...state, accessType: event.target.value as ClientGroupMemberAccessType })}
          >
            <MenuItem value="VIEW">{t('household:accessTypes.VIEW')}</MenuItem>
            <MenuItem value="NO_ACCESS">{t('household:accessTypes.NO_ACCESS')}</MenuItem>
          </TextField>
        </ListItem>
      </Box>

      <Box m={2}>
        {alreadyMemberOfSame && userData && (
          <Alert severity="error">
            {t('alreadyMemberOf')} {t('thisHousehold')}
          </Alert>
        )}
        {alreadyMemberOfAnother && userData && (
          <Alert severity="warning">
            {t('alreadyMemberOf')}
            &nbsp;
            <Link to={`/households/${userData.fetchUser.user.household?.id}#tab:members`}>
              {t('anotherHousehold')}
            </Link>
          </Alert>
        )}
      </Box>

      <Box m={2}>
        <Button disabled={!state.clientGroup || !state.relationship} type='submit'
          onClick={() => continueTo('SUMMARY')} fullWidth variant='contained'>
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
}
