/* keep this synchronized with holding-service - custodiaActivities.ts */
export const TransactionTypes = [
  {
    key: 'EFT',
    value: 'EFT',
  },
  {
    key: 'BUY',
    value: 'BUY',
  },
  {
    key: 'SELL',
    value: 'SELL',
  },
  {
    key: 'CXLBUY',
    value: 'CXLBUY',
  },
  {
    key: 'CXLSELL',
    value: 'CXLSELL',
  },
  {
    key: 'MGT',
    value: 'MGT',
  },
  {
    key: 'CXLMGT',
    value: 'CXLMGT',
  },
  {
    key: 'DIV',
    value: 'DIV',
  },
  {
    key: 'CXLDIV',
    value: 'CXLDIV',
  },
  {
    key: 'WDH',
    value: 'WDH',
  },
  {
    key: 'TFI',
    value: 'TFI',
  },
  {
    key: 'TFO',
    value: 'TFO',
  },

  {
    key: 'TFR',
    value: 'TFR',
  },
  {
    key: 'MFV',
    value: 'MFV',
  },
  {
    key: 'CXLEFT',
    value: 'CXLEFT',
  },
  {
    key: 'DEP',
    value: 'DEP',
  },

  {
    key: 'WDL',
    value: 'WDL',
  },
  {
    key: 'BOR',
    value: 'BOR',
  },
  {
    key: 'FEE',
    value: 'FEE',
  },
  {
    key: 'INT',
    value: 'INT',
  },
  {
    key: 'REF',
    value: 'REF',
  },
  {
    key: 'GST',
    value: 'GST',
  },
  {
    key: 'HST',
    value: 'HST',
  },
  {
    key: 'CST',
    value: 'CST',
  },
  {
    key: 'GRT',
    value: 'GRT',
  },
  {
    key: 'NCW',
    value: 'NCW',
  },
  {
    key: 'PSE',
    value: 'PSE',
  },
  {
    key: 'EAP',
    value: 'EAP',
  },
  {
    key: 'WOF',
    value: 'WOF',
  },
  {
    key: 'LCG',
    value: 'LCG',
  },
  {
    key: 'SCG',
    value: 'SCG',
  },
  {
    key: 'CXLPSE',
    value: 'CXLPSE',
  },
  {
    key: 'CXLEAP',
    value: 'CXLEAP',
  },
  {
    key: 'DIS',
    value: 'DIS',
  },
  {
    key: 'CHQ',
    value: 'CHQ',
  },
  {
    key: 'ANY',
    value: 'Any',
  },
];
