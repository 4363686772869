/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField, ListItem, FormGroup, FormControlLabel, Switch,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import { UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import ThemeIconSelect from '../../../components/inputs/themeIconSelect';

const CREATE_THEME = gql`
mutation createTheme($input: CreateThemeInput!) {
  createTheme(input: $input) {
    theme {
      id
    }
  }
}
`;

const NewTheme = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['buildModels']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [theme, setTheme] = useState({
    key: '',
    translatedName: {
      en: '',
    },
    translatedShortDescription: {
      en: '',
    },
    translatedDescription: {
      en: '',
    },
    organizationId: activeOrganization.id,
    core: false,
    iconKey: 'DEFAULT',
  });
  const [initialState] = useState({
    key: '',
    translatedName: {
      en: '',
    },
    translatedShortDescription: {
      en: '',
    },
    translatedDescription: {
      en: '',
    },
    organizationId: activeOrganization.id,
    core: false,
    iconKey: 'DEFAULT',
  });
  const [createTheme, { loading }] = useMutation(CREATE_THEME, {
    variables: {
      input: theme,
      errorMessage: t('buildModels:themeModal.errorMessages.create'),
    },
  });

  useEffect(() => {
    setTheme({ ...theme, organizationId: activeOrganization.id });
  }, [activeOrganization]);

  const close = () => {
    setTheme(initialState);
    afterCreate();
  };

  const create = async (event: any) => {
    const response = await createTheme();

    if (response.data) {
      showToast({ severity: 'info', message: t('buildModels:themeModal.successMessages.create') });
    }

    close();
  };

  const validation = /^.*\S.*$/;

  const isValidModal = validation.test(theme?.key ?? '')
    && validation.test(theme?.translatedName?.en ?? '')
    && validation.test(theme?.translatedShortDescription?.en ?? '')
    && validation.test(theme?.translatedDescription?.en ?? '');

  return (
    <CreateNewModal
      initialState={initialState}
      state={theme}
      loading={loading} title={t('themeModal.title')} onSubmit={create} fullWidth sx={{ marginTop: 2 }}
      disabled={!isValidModal}
      onClose={() => setTheme(initialState)}>
      <ListItem>
        <TextField label={t('themeModal.key')} fullWidth value={theme.key} onChange={(e) => setTheme({ ...theme, key: e.target.value })} id='key' />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('themeModal.name')} value={theme.translatedName} onChange={(e) => setTheme({ ...theme, translatedName: e })} />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('themeModal.shortDescription')} value={theme.translatedShortDescription} onChange={(e) => setTheme({ ...theme, translatedShortDescription: e })} rows={ 2 } />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('themeModal.description')} value={theme.translatedDescription} onChange={(e) => setTheme({ ...theme, translatedDescription: e })} rows={ 3 } />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={theme.organizationId}
          onChange={(e) => {
            setTheme({ ...theme, organizationId: e.target.value });
          }}
          label={t('themeModal.organization')}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
            control={<Switch
              checked={theme?.core}
              onChange={async (e: any) => setTheme({ ...theme, core: e.target.checked })}
            />}
            label={t('themeModal.defaultRecommendation')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <ThemeIconSelect
          onChange={(event: any) => {
            setTheme({ ...theme, iconKey: event.target.value });
          }}
          value={theme.core ? 'CORE' : theme.iconKey}
          label={t('themeModal.iconKey')}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewTheme;
