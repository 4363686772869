import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Icon,
  Typography,
  Grid,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
  SelectField,
  MenuItem,
} from 'ovComponents/2-component';
import {
  AccountStates,
  AccountTypes,
  Affiliation,
  AffiliationRelations,
  AffiliationTypes,
  DraftAccount,
  Goal,
} from 'interfaces';
import { ClientSelectField } from 'ovComponents/2-component/clientSelectField/clientSelect';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';

interface JointCashDialogContentInterface {
  userData: any,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

const initAffiliation: Partial<Affiliation> = {
  user: undefined,
  relation: undefined,
  type: AffiliationTypes.Joint,
};

export const JointCashDialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: JointCashDialogContentInterface) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [jointHolder, setJointHolder] = useState<Partial<Affiliation>>(initAffiliation);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const goals = getValidGoals(userData);
  const isSubmissionDisabled = (
    !jointHolder.user
    || !jointHolder.relation
    || !jointHolder.type
  );
  const submit = () => {
    onAccountCreated({
      type: AccountTypes.CASH_JOINT,
      applyForGovFunds: [],
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      affiliations: [jointHolder as Affiliation],
      goalsLinked,
    });
  };
  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium'/></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('workflowCompletions:jointCashCreation.title')}</Typography>
      <Typography variant='headingXSmall' sx={{ mt: 3 }}>{t('workflowCompletions:jointAccountHolder.label')}</Typography>
      <Typography variant='bodyLarge' sx={{ mt: 1 }}>{t('workflowCompletions:jointAccountHolder.subLabel')}</Typography>
      <Grid
        container
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        spacing={1}
      >
        <Grid item xs={8}>
          <ClientSelectField
            label={t('workflowCompletions:jointCashCreation.name')}
            user={jointHolder.user as any}
            setUser={(item) => setJointHolder((prevState) => ({ ...prevState, user: item }))}
            fullWidth
            labelSx={{ mt: 3 }}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            sx={{ mt: 3 }}
            onChange={(e: any) => setJointHolder((prevState) => ({ ...prevState, relation: e.target.value }))}
            disabled={false}
            label={t('workflowCompletions:relationship')}
            fullWidth
            value={jointHolder.relation}
            error={false}
          >
            {
              Object.values(AffiliationRelations).filter((item) => item !== 'PRIMARY_CAREGIVER' && item !== 'GUARDIAN').map((relation, index) => (
                <MenuItem key={index}
                          value={relation}>{t(`affiliationTypes:affiliationRelationship.${relation}`)}</MenuItem>
              ))
            }
          </SelectField>
        </Grid>
      </Grid>
      {showGoalLinkingSection && <Box sx={{ mt: 4 }}>
        <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
      </Box>}
      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:jointCashCreation.addAccount')}
          disabled={isSubmissionDisabled}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default JointCashDialogContent;
