export interface TranslatedString {
  en?: string
  fr?: string
}

export enum TranslatedLanguage {
  en = 'en',
  fr = 'fr',
}

export type Language = 'EN' | 'FR';
