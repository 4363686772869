import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { EntityTypes } from '../../../../interfaces';
import { Box } from '../../../1-primative';
import {
  TextField, Button,
  Dialog,
  Form,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogFooter,
  SelectField,
  MenuItem,
} from '../../../2-component';
import { OrganizationSelect } from '../../../3-pattern';
import { FETCH_USERS } from '../nonIndividuals';

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
    }
  }
`;

const allTypesExceptIndividual: EntityTypes[] = Object.values(EntityTypes).filter((t) => t !== EntityTypes.INDIVIDUAL);

const NewNonIndividual = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['nonIndividualClients', 'entityTypes']);
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const emptyState = {
    entityName: '',
    type: '' as EntityTypes | '',
    organizationId: activeOrganization.id,
  };
  const [client, setClient] = useState(emptyState);
  const [createClient, { loading }] = useMutation(CREATE_USER, {
    variables: {
      input: {
        ...client,
      },
    },
    refetchQueries: [FETCH_USERS(permissions)],
  });

  const create = async (event: any) => {
    const response = await createClient();
    if (response && response.data && !response.errors) {
      setClient(emptyState);
      navigate(`/nonIndividualClients/${response.data.createUser.user.id}`);
    }
  };

  const allFieldsValid = client.entityName !== '' && client.type !== '';

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={create}>
          <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {t('nonIndividualClients:createNewNonIndividualEntity')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField label={t('entityName')} fullWidth value={client.entityName} onChange={(e: any) => setClient({ ...client, entityName: e.target.value })} sx={{ mb: 2 }} />
            <SelectField fullWidth label={t('entityType')} value={client.type} onChange={(e: any) => setClient({ ...client, type: e.target.value })} sx={{ mb: 2 }}>
              {allTypesExceptIndividual.map((type) => (
                <MenuItem key={type} value={type}>{t(`entityTypes:${type}`)}</MenuItem>
              ))}
            </SelectField>
            <OrganizationSelect
              value={client.organizationId}
              label={t('shared:organization')}
              onChange={(event: any) => setClient({ ...client, organizationId: event.target.value as string })}
            />
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading || !allFieldsValid}
                label={t('shared:create')}
                type='submit'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default NewNonIndividual;
