import { ApiToken } from './apiToken';
import { Integration } from './integrations';
import { OrganizationUser } from './organizationUser';

export enum BulkUpsertRequestObjectTypes {
  ORGANIZATION = 'ORGANIZATION',
  HOUSEHOLD = 'HOUSEHOLD', // ClientGroup
  ENTITY = 'ENTITY', // User
  ORGANIZATION_USER = 'ORGANIZATION_USER',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  GOAL = 'GOAL',
  TRANSACTION = 'TRANSACTION',
  CASH_AND_HOLDINGS = 'CASH_AND_HOLDINGS',
  ASSET_CLASS = 'ASSET_CLASS',
  FINANCIAL_PRODUCT = 'FINANCIAL_PRODUCT',
  MODEL_PORTFOLIO = 'MODEL_PORTFOLIO',
  STATS_HISTORY = 'STATS_HISTORY',
  PRICE_HISTORY = 'PRICE_HISTORY',
  PERFORMANCE_HISTORY = 'PERFORMANCE_HISTORY',
}

export enum BulkUpsertRequestStates {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  PARTIALLY_FAILED = 'PARTIALLY_FAILED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export interface BulkUpsertRequest {
  id: string,
  integration?: Integration,
  initiatorId?: string,
  initiatorType?: string,
  state?: BulkUpsertRequestStates,
  objectType?: BulkUpsertRequestObjectTypes,
  queuedItemsCount?: number,
  failedItemsCount?: number,
  processedItemsCount?: number,
  createdAt?: Date,
  organizationUser?: OrganizationUser,
  apiToken?: ApiToken,
}

export enum BulkUpsertItemStates {
  QUEUED = 'QUEUED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export interface BulkUpsertItemOutputError {
  code?: string,
  field?: string,
  description?: string,
}

export interface IntegrationDoc {
  externalId?: string,
  externalData?: any,
  integrationId?: string,
  iid?: string,
}

export interface BulkUpsertItem {
  id?: string,
  bulkUpsertRequest?: BulkUpsertRequest,
  state?: BulkUpsertItemStates,
  oneVestId?: string,
  batchId?: string,
  outputErrors?: BulkUpsertItemOutputError[],
  integrations?: IntegrationDoc[],
  bulkUpsertIntegration?: string,
  rawData: any,
  createdAt: Date,
  updatedAt: Date,
}

export const getExternalId = (bulkUpsertItem: BulkUpsertItem) => (
  (bulkUpsertItem.bulkUpsertIntegration && bulkUpsertItem.integrations ? bulkUpsertItem.integrations.find((i) => i.integrationId === bulkUpsertItem.bulkUpsertIntegration)?.externalId : '-')
);

export const mapBulkUpsertItemGqlType = (objectType?: string) => {
  if (!objectType) return undefined;
  if (([
    BulkUpsertRequestObjectTypes.ACCOUNT,
    BulkUpsertRequestObjectTypes.GOAL,
    BulkUpsertRequestObjectTypes.SUB_ACCOUNT,
  ] as string[]).includes(objectType)) {
    return 'Account';
  }
  if (([
    BulkUpsertRequestObjectTypes.ORGANIZATION,
    BulkUpsertRequestObjectTypes.ORGANIZATION_USER,
  ] as string[]).includes(objectType)) {
    return 'Organization';
  }
  if (([
    BulkUpsertRequestObjectTypes.HOUSEHOLD,
    BulkUpsertRequestObjectTypes.ENTITY,
  ] as string[]).includes(objectType)) {
    return 'User';
  }
  if (([
    BulkUpsertRequestObjectTypes.PRICE_HISTORY,
    BulkUpsertRequestObjectTypes.CASH_AND_HOLDINGS,
    BulkUpsertRequestObjectTypes.TRANSACTION,
  ] as string[]).includes(objectType)) {
    return 'Holding';
  }
  if (([
    BulkUpsertRequestObjectTypes.STATS_HISTORY,
    BulkUpsertRequestObjectTypes.PERFORMANCE_HISTORY,
  ] as string[]).includes(objectType)) {
    return 'History';
  }
  if (([
    BulkUpsertRequestObjectTypes.ASSET_CLASS,
    BulkUpsertRequestObjectTypes.MODEL_PORTFOLIO,
    BulkUpsertRequestObjectTypes.FINANCIAL_PRODUCT,
  ] as string[]).includes(objectType)) {
    return 'FinancialProduct';
  }
  return undefined;
};
