import { useThemeTokens } from '../../../providers/themeTokenProvider';

interface ButtonPropTokens {
  container?: {
    color?: string;
    borderColor?: string;
  },
  text?: {
    color?: string;
  },
}

interface ButtonStateTokens {
  enabled?: ButtonPropTokens,
  disabled?: ButtonPropTokens,
  hovered?: ButtonPropTokens,
  focused?: ButtonPropTokens,
  pressed?: ButtonPropTokens,
}

interface ButtonVariantTokens {
  primary?: ButtonStateTokens,
  destructive?: ButtonStateTokens,
}

export interface ButtonTokens {
  button: {
    borderRadius?: string;
    xs?: {
      height?: string;
      padding?: string;
      fontSize?: string;
    },
    sm?: {
      height?: string;
      padding?: string;
      fontSize?: string;
    },
    lg?: {
      height?: string;
      padding?: string;
      fontSize?: string;
    },
    filled?: ButtonVariantTokens,
    tonal?: ButtonVariantTokens,
    outlined?: ButtonVariantTokens,
    text?: ButtonVariantTokens,
  },
}

export const useButtonTokens = (): ButtonTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: ButtonTokens = {
    button: {
      borderRadius: sys.borderRadius.round,
      xs: {
        height: '24px',
        padding: '0px 12px',
        fontSize: '12px',
      },
      sm: {
        height: '32px',
        padding: '0px 24px',
        fontSize: '14px',
      },
      lg: {
        height: '40px',
        padding: '0px 24px',
        fontSize: '16px',
      },
      filled: {
        primary: {
          enabled: {
            container: {
              color: sys.color.primary,
            },
            text: {
              color: sys.color.onPrimary,
            },
          },
          disabled: {
            container: {
              color: sys.color.disabled,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.primaryHover,
            },
            text: {
              color: sys.color.onPrimary,
            },
          },
          focused: {
            container: {
              color: sys.color.primaryHover,
            },
            text: {
              color: sys.color.onPrimary,
            },
          },
          pressed: {
            container: {
              color: sys.color.primaryPressed,
            },
            text: {
              color: sys.color.onPrimary,
            },
          },
        },
        destructive: {
          enabled: {
            container: {
              color: sys.color.negative,
            },
            text: {
              color: sys.color.onNegative,
            },
          },
          disabled: {
            container: {
              color: sys.color.disabled,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.negativeHover,
            },
            text: {
              color: sys.color.onNegative,
            },
          },
          focused: {
            container: {
              color: sys.color.negativeHover,
            },
            text: {
              color: sys.color.onNegative,
            },
          },
          pressed: {
            container: {
              color: sys.color.negativePressed,
            },
            text: {
              color: sys.color.onNegative,
            },
          },
        },
      },
      tonal: {
        primary: {
          enabled: {
            container: {
              color: sys.color.primaryVariant,
            },
            text: {
              color: sys.color.onPrimaryVariant,
            },
          },
          disabled: {
            container: {
              color: sys.color.disabled,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.primaryVariantHover,
            },
            text: {
              color: sys.color.onPrimaryVariant,
            },
          },
          focused: {
            container: {
              color: sys.color.primaryVariantHover,
            },
            text: {
              color: sys.color.onPrimaryVariant,
            },
          },
          pressed: {
            container: {
              color: sys.color.primaryVariantPressed,
            },
            text: {
              color: sys.color.onPrimaryVariant,
            },
          },
        },
        destructive: {
          enabled: {
            container: {
              color: sys.color.negativeVariant,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          disabled: {
            container: {
              color: sys.color.disabled,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.negativeVariantHover,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          focused: {
            container: {
              color: sys.color.negativeVariantHover,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          pressed: {
            container: {
              color: sys.color.negativeVariantPressed,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
        },
      },
      outlined: {
        primary: {
          enabled: {
            container: {
              color: 'rgba(0,0,0,0)',
              borderColor: sys.color.outline,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          disabled: {
            container: {
              color: 'rgba(0,0,0,0)',
              borderColor: sys.color.outline,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.neutralHover,
              borderColor: sys.color.outline,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          focused: {
            container: {
              color: sys.color.neutralHover,
              borderColor: sys.color.outline,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          pressed: {
            container: {
              color: sys.color.neutralPressed,
              borderColor: sys.color.outline,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
        },
        destructive: {
          enabled: {
            container: {
              color: 'rgba(0,0,0,0)',
              borderColor: sys.color.negativeOutline,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          disabled: {
            container: {
              color: sys.color.disabled,
              borderColor: sys.color.negativeOutline,
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.negativeVariantHover,
              borderColor: sys.color.negativeOutline,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          focused: {
            container: {
              color: sys.color.negativeVariantHover,
              borderColor: sys.color.negativeOutline,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          pressed: {
            container: {
              color: sys.color.negativeVariantPressed,
              borderColor: sys.color.negativeOutline,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
        },
      },
      text: {
        primary: {
          enabled: {
            container: {
              color: 'rgba(0,0,0,0)',
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          disabled: {
            container: {
              color: 'rgba(0,0,0,0)',
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.neutralPressed,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          focused: {
            container: {
              color: sys.color.neutralHover,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
          pressed: {
            container: {
              color: sys.color.neutralPressed,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
        },
        destructive: {
          enabled: {
            container: {
              color: 'rgba(0,0,0,0)',
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          disabled: {
            container: {
              color: 'rgba(0,0,0,0)',
            },
            text: {
              color: sys.color.onDisabled,
            },
          },
          hovered: {
            container: {
              color: sys.color.negativeVariantHover,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          focused: {
            container: {
              color: sys.color.negativeVariantHover,
            },
            text: {
              color: sys.color.onNegativeVariant,
            },
          },
          pressed: {
            container: {
              color: sys.color.negativeVariantPressed,
            },
            text: {
              color: sys.color.onSurface,
            },
          },
        },
      },
    },
  };

  return comp;
};
