import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Typography, Box, Grid } from '../../../../1-primative';
import { Button, Form } from '../../../../2-component';
import { SelectionTile } from '../../../../3-pattern';
import { NonIndividualSetupConfirmationDialog } from './components/NonIndividualSetupConfirmationDialog';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const NonIndividualSetupVisual = ({
  options, loading, onNext, createNonIndividualEntity, workflowCompletion,
}: {
  options: any, loading: boolean, onNext: () => void, createNonIndividualEntity?: () => void,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'entityTypes', 'shared']);
  const [shouldCreateNonIndividualEntity, setShouldCreateNonIndividualEntity] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsNonIndividualSetupContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion.objectType,
      },
    );
    if (shouldCreateNonIndividualEntity) {
      setIsModalOpen(true);
    } else {
      onNext();
    }
  };

  return (
    <>
      <Form onSubmit={submit}>
        { options?.title && (<Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>) }
        {options?.subtitle && (<Typography variant='bodyLarge' sx={{ mb: 3 }}>
          <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
        </Typography>)}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectionTile
              onChange={(e: any) => setShouldCreateNonIndividualEntity(e.target.value === 'Y')}
              value={shouldCreateNonIndividualEntity ? 'Y' : 'N'}
              direction='row'
              options={[
                { value: 'Y', label: t('shared:yes') },
                { value: 'N', label: t('shared:no') },
              ]}
            />
          </Grid>
        </Grid>
        <Box display='flex' justifyContent='end'>
          <Button label={t('shared:next')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
        </Box>
      </Form>
      <NonIndividualSetupConfirmationDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        createNonIndividualEntity={createNonIndividualEntity}
        title={translateBackend(options.confirmationTitle)}
        text={translateBackend(options.confirmationText)}
        loading={loading}
      />
    </>
  );
};

export default NonIndividualSetupVisual;
