/* eslint-disable no-param-reassign */
import {
  Dispatch, ForwardedRef, forwardRef, SetStateAction, useEffect, useRef,
} from 'react';
import * as QuillTableUI from 'quill-table-ui';
import Quill, { QuillOptions } from 'quill';

Quill.register({
  'modules/tableUI': QuillTableUI.default,
}, true);

export interface RichTextEditorArgs extends QuillOptions {
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  id?: string;
}

/**
 * Possible options for a Full Style Toolbar:
 *
 * const toolbarOptions = [
    [{ font: [] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['table'],
    [{ script: 'super' }, { script: 'sub' }],
    [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['direction', { align: [] }],
    ['link', 'image', 'video', 'formula'],
    ['clean'],
  ];
 */
const RichTextEditor = forwardRef((props: RichTextEditorArgs, ref: ForwardedRef<any>) => {
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['table'],
    [{ color: [] }, { background: [] }],
    [{ script: 'super' }, { script: 'sub' }],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }, { align: [] }],
    ['link', 'image'],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
    table: true,
    tableUI: true,
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const container: any = containerRef.current;
    const editorContainer = container?.appendChild(container?.ownerDocument.createElement('div'));
    const quill = new Quill(editorContainer, {
      theme: 'snow',
      modules,
    });

    if (ref) {
      (ref as any).current = quill;
    }

    if (props.value) {
      props.setValue(props.value);

      const delta = quill.clipboard.convert({ html: props.value });
      quill.setContents(delta, 'silent');
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      props.setValue(quill.root.innerHTML);
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      props.setValue(quill.root.innerHTML);
    });

    return () => {
      container.innerHTML = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={props.id} ref={containerRef}></div>;
});

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
