import { useNavigate } from 'react-router-dom';
import FeeGridTable from './components/feeGridTable';
import { DefaultOrgFeeGridBox } from './components/defaultOrgFeeGridBox';

const FeeGrids = () => {
  useNavigate();

  return (
    <>
      <DefaultOrgFeeGridBox />
      <FeeGridTable />
    </>
  );
};

export default FeeGrids;
