/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField, ListItem, MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import { ClientSelect } from '../../../components/inputs/clientSelect';
import { User } from '../../../interfaces/user';
import { usePermissions } from '../../../providers/userContextProvider';
import NewClient from '../../clients/components/newClient';

interface Relationship {
  type: string,
  primaryEmail: string,
  userId: string,
}

const NewClientGroupRelationUser = (
  {
    onUserVerify, organizationId, allowedTypes, buttonType, modalButton, icon,
  }:
  {
    onUserVerify: (relationship?: Relationship | undefined) => void,
    organizationId: string,
    allowedTypes?: string[],
    modalButton?: string,
    buttonType?: 'BUTTON' | 'ICON',
    icon?: JSX.Element,
  },
) => {
  const { t } = useTranslation(['clients', 'household']);
  const { permissions } = usePermissions();
  const [relationUserUser, setRelationUserUser] = useState<User>();
  const [user, setUser] = useState({
    primaryEmail: '',
    organizationId,
    relationType: allowedTypes ? allowedTypes[0] : '',
    firstName: '',
    lastName: '',
    userId: '',
    language: 'ENGLISH',
    timezone: 'America/Edmonton',
  });

  const updateType = (event: any) => {
    setUser({ ...user, relationType: event.target.value as string });
  };

  const addRelationship = async () => {
    onUserVerify({
      primaryEmail: user.primaryEmail,
      userId: user.userId,
      type: user.relationType,
    });
    setUser({
      primaryEmail: '',
      organizationId,
      relationType: allowedTypes ? allowedTypes[0] : '',
      firstName: '',
      lastName: '',
      userId: '',
      language: 'ENGLISH',
      timezone: 'America/Edmonton',
    });
  };

  useEffect(() => {
    if (relationUserUser) {
      setUser({
        ...user,
        userId: relationUserUser.id,
        primaryEmail: relationUserUser.primaryEmail ?? '',
        organizationId,
        firstName: relationUserUser.firstName ?? '',
        lastName: relationUserUser.lastName ?? '',
      });
    } else {
      setUser({
        primaryEmail: '',
        organizationId,
        relationType: allowedTypes ? allowedTypes[0] : '',
        firstName: '',
        lastName: '',
        userId: '',
        language: 'ENGLISH',
        timezone: 'America/Edmonton',
      });
    }
  }, [relationUserUser, setUser, allowedTypes]);

  const disabled = !(user.relationType && user.organizationId && user.primaryEmail);

  return (
    <CreateNewModal
      loading={false}
      disabled={disabled}
      title={t('household:relationModal.relationTitle')}
      buttonText={t('household:relationModal.addRelation')}
      onSubmit={addRelationship}
      modalButton={modalButton}
      sx={{ float: 'right', m: 1 }}
      icon={icon}
      buttonType={buttonType ?? 'ICON'}
    >
      <ListItem>
        {/* free text relationship type */}
        {allowedTypes === undefined
          && <TextField
            autoFocus
            label={t('household:relationModal.relationship')}
            data-testid="relation-user-type"
            sx={{ width: '100%' }}
            value={user.relationType}
            onChange={updateType}
          />
        }
        {/* relationship type on of set */}
        {allowedTypes !== undefined
          && <TextField
            select
            autoFocus
            label={t('household:relationModal.relationship')}
            data-testid="relation-user-type"
            sx={{ width: '100%' }}
            value={user.relationType}
            onChange={updateType}
          >
            {allowedTypes.map((type) => <MenuItem key={type} value={type}>{t(`affiliationTypes:${type}`)}</MenuItem>)}
          </TextField>
        }
      </ListItem>

      <ListItem>
        <ClientSelect
          label={t('household:relationModal.client')}
          fullWidth
          user={relationUserUser}
          setUser={setRelationUserUser}
          isJointAccount
        />
      </ListItem>
      {permissions.some((permission) => ['write:client_basic', 'write:client_low_risk_pii', 'write:client_high_risk_pii', 'write:client_suitability'].includes(permission))
        && <NewClient buttonType='NONE' modalButton={t('household:relationModal.createUser')} icon="" fullWidth />
      }
    </CreateNewModal>
  );
};

export default NewClientGroupRelationUser;
