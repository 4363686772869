import { gql, useMutation } from '@apollo/client';
import {
  Box, Card, Grid, Stack, Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { AffiliationTypes, ClientGroup } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';
import { EditableRelation } from '../../corporations/newCorporation';
import NewRelationshipUser from '../../households/components/newRelationshipUser';
import AffiliatesCard from './AffiliatesCard';
import { FETCH_CORPORATION } from '..';
import EditRelationshipUser from '../../households/components/editRelationshipUser';
import InfoIcon from '../../../assets/images/icons-filled/information-rounded.svg';

export const UPDATE_CLIENT_GROUP = gql`
mutation updateClientGroup($input: UpdateClientGroupInput!) {
  updateClientGroup(input: $input) {
    clientGroup {
      id
    }
  }
}
`;

const AffiliatesTab = ({ clientGroup, afterCreate }: { clientGroup: ClientGroup, afterCreate?: () => void }) => {
  const { t } = useTranslation('affiliationTypes');
  const { activeOrganization } = useContext(UserContext);
  const relationships: EditableRelation[] = clientGroup.relationships.map((x) => ({
    type: x.type ?? '',
    primaryEmail: x.user.primaryEmail ?? '',
    userId: x.user.id ?? '',
  }));
  const [relationshipsList, setRelationshipsList] = useState<EditableRelation[]>(relationships || []);
  const affiliationTypesList = Array.from(new Set(clientGroup?.relationships?.map((x) => x.type))).sort();

  const [updateClientGroup] = useMutation(UPDATE_CLIENT_GROUP, {
    refetchQueries: [FETCH_CORPORATION],
    onCompleted: () => {
      if (afterCreate) afterCreate();
    },
  });

  const update = (newList: EditableRelation[]): void => {
    setRelationshipsList(newList);
    updateClientGroup({
      variables: {
        input: {
          clientGroupId: clientGroup.id,
          relationships: newList.map(({ primaryEmail, ...rest }) => rest),
        },
      },
    });
  };

  const updateAction = (relationship: EditableRelation | undefined, relation: any, isDelete: boolean): void => {
    if (relationship) {
      const updatedRelationshipList: Array<EditableRelation> = relationshipsList;
      const currentItem = updatedRelationshipList.find((x) => x.primaryEmail === relation.user.primaryEmail
        && x.type === relation.type && x.userId === relation.user.id);
      if (currentItem) {
        const i = updatedRelationshipList.indexOf(currentItem);
        if (isDelete) {
          updatedRelationshipList.splice(i, 1);
        } else {
          updatedRelationshipList[i] = { ...currentItem, type: relationship.type };
        }
        update(updatedRelationshipList);
      }
    }
  };
  const affiliationStates = [AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner];
  const affiliationTypes = [AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner, AffiliationTypes.Other];
  const getAllowedTypes = (type: string): Array<AffiliationTypes> => {
    if (type === AffiliationTypes.BeneficialOwner) return [AffiliationTypes.BeneficialOwner, AffiliationTypes.AuthorizedIndividual, AffiliationTypes.Other];
    return affiliationTypes;
  };

  return (
    <Box>
      {
        affiliationTypesList.map((type, index: number) => <Card key={index} variant="outlined" sx={{ mb: '20px', border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
          <Box key={type} sx={{ margin: '15px 15px' }}>
            <Box key={index} display="flex" alignItems="center" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} mr="24px">
              <Typography variant="h6" sx={{ fontWeight: 600, mr: 0.7 }}>{t(`affiliationTypes:${type}`)}</Typography>
              <Box>
                <NewRelationshipUser
                  allowedTypes={getAllowedTypes(type)}
                  organizationId={activeOrganization.id ?? ''}
                  buttonType={affiliationTypes.some((x) => x === type) ? 'BUTTON' : 'ICON'}
                  icon={<></>}
                  modalButton={t(`affiliationTypes:button.${type}`)}
                  onUserVerify={(relationship: EditableRelation | undefined) => {
                    if (relationship) {
                      const newRelationshipList: EditableRelation[] = [...relationshipsList, relationship];
                      update(newRelationshipList);
                    }
                  }}
                />
              </Box>
            </Box>
            {
              clientGroup?.relationships?.filter((x) => x.type === type).map((relation: any, index2: number) => <Grid key={index2} item md={12} xs={12}>
                <Box sx={{ margin: '10px 0px' }}>
                  <AffiliatesCard
                    key={index}
                    user={relation.user}
                    isIndividual= {false}
                    type={t(`affiliationTypes:${type}`)}
                    mainAccountType={relation.accountType}
                    affiliateType={relation.type}
                    icon={
                      <EditRelationshipUser
                        allowedTypes={getAllowedTypes(type)}
                        affiliationType={type as AffiliationTypes}
                        email={relation.user.primaryEmail}
                        icon={<EditIcon sx={{ color: '#2B4763' }} data-testid="edit-icon" />}
                        onUserVerify={(relationship: EditableRelation | undefined) => {
                          updateAction(relationship, relation, false);
                        }}
                        onUserDelete={(relationship: EditableRelation | undefined) => {
                          updateAction(relationship, relation, true);
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>)
            }
          </Box>
        </Card>)
      }
      {
        affiliationStates.map((type, index: number) => !affiliationTypesList.includes(type)
          && <Card key={index} variant="outlined" sx={{ mb: '20px', border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
            <Box key={type} sx={{ margin: '15px 15px' }}>
              <Box key={index} display="flex" alignItems="center" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} mr="24px">
                <Typography variant="h6" sx={{ fontWeight: 600, mr: 0.7 }}>{t(`affiliationTypes:${type}`)}</Typography>
              </Box>
              <Stack direction="column" alignItems="center" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={InfoIcon} alt="risk-level-icon" />
                <Typography variant="subtitle2">{t('affiliationTypes:noLinkedAffiliate', { type: t(`affiliationTypes:${type}`) })}</Typography>
              </Stack>
              <Box display="flex" alignItems="center" sx={{
                mb: '20px', width: '100%', display: 'flex', justifyContent: 'center',
              }}>
                <NewRelationshipUser
                  allowedTypes={getAllowedTypes(type)}
                  organizationId={activeOrganization.id ?? ''}
                  buttonType={affiliationTypes.some((x) => x === type) ? 'BUTTON' : 'ICON'}
                  icon={<></>}
                  modalButton={t(`affiliationTypes:button.${type}`)}
                  onUserVerify={(relationship: EditableRelation | undefined) => {
                    if (relationship) {
                      const newRelationshipList: EditableRelation[] = [...relationshipsList, relationship];
                      update(newRelationshipList);
                    }
                  }}
                />
              </Box>
            </Box>
          </Card>)
      }
    </Box>
  );
};

export default AffiliatesTab;
