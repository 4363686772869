import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StatementsTable from './components/statementsTable';

const AccountsReview = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <StatementsTable showClient />
    </Paper>
  );
};

export default AccountsReview;
