import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useClientContext } from 'pages/client';
import {
  FinancialProduct,
  FinancialProductState,
  SubAccount,
  SubAccountStates,
  Theme,
} from 'interfaces';
import { UserContext } from 'providers/userContextProvider';
import { DraftFinancialProduct } from './interfaces';
import { SubAccountsVisual } from './subAccounts.visual';

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        id
        name
        state
        account {
          id
          state
          type
          user {
            firstName
            suitabilityScore
          }
          affiliations {
            type
            user {
              firstName
              suitabilityScore
            }
          }
        }
        goal {
          id
          state
          name
          type
          suitabilityScore
        }
        financialProduct {
          id
          translatedName { en }
        }
      }
    }
  }
`;

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount { id }
    }
  }
`;

const FETCH_PORTFOLIO_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        name
      }
    }
  }
`;

const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        name
        theme { name }
      }
    }
  }
`;

export const SubAccounts = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const organizationId = activeOrganization.id ?? clientContext?.orgSettings.id;
  const [subAccounts, setSubAccounts] = useState<SubAccount[]>([]);
  const [draftFinancialProducts, setDraftFinancialProducts] = useState<{ [subAccountId: string]: DraftFinancialProduct }>({});
  const [activeSubAccountId, setActiveSubAccountId] = useState<string>('');
  const [portfolioThemes, setPortfolioThemes] = useState<Theme[]>([]);
  const [modelPortfolios, setModelPortfolios] = useState<FinancialProduct[]>([]);

  const initDraftFinancialProduct = (subAccount: SubAccount): DraftFinancialProduct => ({
    subAccount,
    theme: undefined,
    financialProduct: undefined,
    allowClientDeposits: false,
    isPartial: false,
    skipIPS: false,
  });

  const [updateSubAccount, { loading: updateSubAccountLoading }] = useMutation(UPDATE_SUB_ACCOUNT, {
    refetchQueries: [FETCH_SUB_ACCOUNTS],
    awaitRefetchQueries: true,
  });

  const [fetchPortfolioThemes, { loading: portfolioThemesLoading }] = useLazyQuery(FETCH_PORTFOLIO_THEMES);
  const fetchPortfolioThemesFn = (): Promise<Theme[]> => new Promise<Theme[]>((resolve) => {
    fetchPortfolioThemes({
      variables: {
        input: {
          filter: { organizationId },
          pagination: { perPage: 1000 },
        },
      },
      onCompleted: (data) => resolve(data.fetchThemes.themes),
    });
  });

  const [fetchModelPortfolios] = useLazyQuery(FETCH_MODEL_PORTFOLIOS);
  const fetchModelPortfoliosFn = useCallback((themeId:string): Promise<FinancialProduct[]> => new Promise<FinancialProduct[]>((resolve) => {
    fetchModelPortfolios({
      variables: {
        input: {
          filter: {
            organizationId,
            themeId,
            state: FinancialProductState.ACTIVE,
          },
          pagination: { perPage: 1000 },
        },
      },
      onCompleted: (data) => resolve(data.fetchModelPortfolios.modelPortfolios),
    });
  }), [fetchModelPortfolios, organizationId]);

  const handleDraftSubmission = async () => {
    const draftData = draftFinancialProducts[activeSubAccountId];
    if (!draftData?.subAccount?.id) return;

    await updateSubAccount({
      variables: {
        input: {
          allowClientDeposits: draftData.allowClientDeposits,
          financialProductId: draftData.financialProduct?.id,
          isPartial: draftData.isPartial,
          skipIPS: draftData.skipIPS,
          subAccountId: draftData.subAccount.id,
          themeId: draftData.theme?.id,
        },
      },
    });
  };

  const handleSubAccountSelection = async (subAccount: SubAccount) => {
    if (!(subAccount.id in draftFinancialProducts)) {
      setDraftFinancialProducts({ ...draftFinancialProducts, [subAccount.id]: initDraftFinancialProduct(subAccount) });
    }
    setActiveSubAccountId(subAccount.id);
    setPortfolioThemes(await fetchPortfolioThemesFn());
  };

  const handlePortfolioThemeChange = async (theme: Theme) => {
    if (fetchModelPortfoliosFn && theme.id) {
      setModelPortfolios(await fetchModelPortfoliosFn(theme.id));
    }
  };

  const updateDraft = (subAccountId: string, draft: DraftFinancialProduct) => {
    setDraftFinancialProducts({ ...draftFinancialProducts, [subAccountId]: draft });
  };

  useEffect(() => {
    // Ensure model portfolios are correctly updated when switching between multiple drafts
    const updateModelPortfolios = async (themeId: string) => {
      setModelPortfolios(await fetchModelPortfoliosFn(themeId));
    };

    const activeSubAccountThemeId = draftFinancialProducts[activeSubAccountId]?.theme?.id;

    if (activeSubAccountThemeId) {
      updateModelPortfolios(activeSubAccountThemeId);
    }
  }, [activeSubAccountId, draftFinancialProducts, fetchModelPortfoliosFn]);

  const { loading: subAccountsLoading } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          userId,
        },
        pagination: { perPage: 1000 },
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: ((subAccountsData) => {
      const candidateStates = [SubAccountStates.ACTIVE, SubAccountStates.AWAITING_APPROVAL];
      const candidateSubAccounts = subAccountsData.fetchSubAccounts.subAccounts.filter(
        (item: SubAccount) => candidateStates.includes(item.state),
      );
      setSubAccounts(candidateSubAccounts);
    }),
  });

  const loading = stepLoading || subAccountsLoading || portfolioThemesLoading || updateSubAccountLoading;

  return (
    <SubAccountsVisual
      options={options}
      loading={loading}
      subAccounts={subAccounts}
      draftFinancialProducts={draftFinancialProducts}
      activeSubAccountId={activeSubAccountId}
      updateDraft={updateDraft}
      portfolioThemes={portfolioThemes}
      modelPortfolios={modelPortfolios}
      onSubAccountSelection={handleSubAccountSelection}
      onPortfolioThemeChange={handlePortfolioThemeChange}
      onDraftSubmission={handleDraftSubmission}
      onContinue={onNext}
    />
  );
};

export default SubAccounts;
