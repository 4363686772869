/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { reject } from 'lodash';
import NewClientGroupRelationUser from './newClientGroupRelationUser';

interface EditableRelation {
  type: string,
  primaryEmail: string,
  userId: string,
}

const RelationshipTable = (
  {
    organizationId, relationships, actionElement, onRelationshipChange, allowedTypes,
  }:
  { organizationId: string, relationships?: EditableRelation[], actionElement?: JSX.Element, onRelationshipChange: (relationList: EditableRelation[]) => void, allowedTypes?: string[] },
) => {
  const { t } = useTranslation(['household']);
  const [relationshipsList, setRelationshipsList] = useState<EditableRelation[]>(relationships || []);
  useEffect(() => {
    onRelationshipChange(relationshipsList);
  }, [relationshipsList]);
  const removeFromRelations = (relation: EditableRelation, relationList: EditableRelation[]): void => {
    const newRelations = reject(relationList, (o: EditableRelation) => o.userId === relation.userId);
    setRelationshipsList(newRelations);
  };
  return (
    <Table sx={{ width: '100%' }} aria-label="table">
      <TableHead>
        <TableRow>
          <TableCell><Typography variant='overline'>{t('household:relationModal.userEmail')}</Typography></TableCell>
          <TableCell><Typography variant='overline'>{t('household:relationModal.relationship')}</Typography></TableCell>
          <TableCell>
            <NewClientGroupRelationUser
              allowedTypes={allowedTypes}
              organizationId={organizationId}
              onUserVerify={(relationship: EditableRelation | undefined) => {
                if (relationship) {
                  setRelationshipsList((newList) => [...newList, relationship]);
                }
              }}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {relationshipsList.map((relationshipItem: EditableRelation) => (
          <TableRow key={relationshipItem.userId}>
            <TableCell width="60%" component="th" scope="row">
              {relationshipItem.primaryEmail}
            </TableCell>
            <TableCell width="35%" component="th" scope="row">
              {relationshipItem.type}
            </TableCell>
            <TableCell width="5%" component="th" scope="row">
              <DeleteIcon
                onClick={
                  () => {
                    removeFromRelations(relationshipItem, relationshipsList);
                  }
                } />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RelationshipTable;
