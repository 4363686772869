import {
  Box,
  Button, Typography,
} from '@mui/material';
import { Date } from 'components/misc/date/date';
import { colors } from 'ovComponents';
import { useTranslation } from 'react-i18next';
import { EntityTypes } from 'interfaces';
import { generateClientNameString } from '../../../../util';
import { StepProps } from '../wizard';

const styles = {
  listItem: {
    marginTop: '1em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export function SummaryStep({
  state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('addHouseholdMemberWizard');
  const isIndividual = state.user?.type === EntityTypes.INDIVIDUAL;
  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box m={2}>
        <Typography align='center' variant='wizardSubTitle' mb={1}>{t('memberSummary')}</Typography>
        <Typography align='center' variant='subtitle1' mb={2}>{t('pleaseReview')}</Typography>
        <hr style={{ height: 0, borderColor: colors.neutral300 }} />
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('name')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {generateClientNameString(state.user)}
          </Typography>
        </Box>
        <Box sx={styles.listItem}>
          {isIndividual
            ? <>
              <Typography variant='body2'>{t('dateOfBirth')}</Typography>
              <Date variant='body2' fontWeight={700} date={state.user?.dateOfBirth}/>
            </>
            : <>
              <Typography variant='body2'>{t('establishedDate')}</Typography>
              <Date variant='body2' fontWeight={700} date={state.user?.establishedDate}/>
            </>
          }
        </Box>
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('relationship')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {t(`affiliationTypes:affiliationRelationship.${state.relationship}`)}
          </Typography>
        </Box>
        <Box sx={styles.listItem}>
          <Typography variant='body2'>{t('accessType')}</Typography>
          <Typography variant='body2' fontWeight={700}>
            {t(`household:accessTypes.${state.accessType}`)}
          </Typography>
        </Box>
      </Box>

      <Box m={2}>
        <Button type='submit' fullWidth variant='contained'
          onClick={() => continueTo('ADD_HOUSEHOLD_MEMBER')}
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
}
