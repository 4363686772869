import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colors } from '../../theme/colors';
import { formatMoneyValue } from '../../util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
);

const LineChart = ({ labels, datasets }: { labels: string[], datasets: any[] }) => {
  const INDEX = 'index';
  const NEAREST = 'nearest';
  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      tooltip: {
        position: 'nearest' as typeof NEAREST,
        callbacks: {
          label: (data: any) => `${data.dataset.label} - ${formatMoneyValue(data.raw)}`,
        },
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      mode: 'index',
    },
    scales: {
      xAxes: {
        display: false,
      },
      x: {
        display: false,
      },
      y: {
        display: false,
        ticks: {
          callback: (value: any) => formatMoneyValue(value),
        },
      },
    },
    pointRadius: 0,
    lineTension: 0.3,
    borderCapStyle: 'round',
  };

  const fullData = {
    labels,
    datasets: (datasets || []).map((x: any, index: number) => ({
      label: x.label,
      data: x.data,
      borderColor: colors.secondary,
      backgroundColor: colors.secondary,
    })),
  };

  return (
    <Line options={options} data={fullData} />
  );
};

export default LineChart;
