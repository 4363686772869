import { merge } from 'lodash/fp';
import convert from 'color-convert';
import { PaletteTokens, palette } from './palette/palette';
import { borderRadius } from '../sys/borderRadius/borderRadius';
import { OverrideTokens } from '..';

export interface RefTokens {
  palette: PaletteTokens;
}

export interface OverrideRefTokens {
  palette?: PaletteTokens;
}

export const ref = {
  palette,
  borderRadius,
};

function lightenDarkenColor(col: string, amt: number) {
  const hsl = convert.hex.hsl(col);
  if (hsl[2] + amt > 100) {
    hsl[2] = 100;
  } else {
    hsl[2] += amt;
  }
  return `#${convert.hsl.hex(hsl)}`;
}

export const useRefTokens = (override: OverrideTokens): RefTokens => {
  let newPalette: PaletteTokens = palette;

  if (override.ref?.palette?.primary50) {
    const primaryCalcs = {
      primary70: lightenDarkenColor(override.ref?.palette?.primary50, -30),
      primary60: lightenDarkenColor(override.ref?.palette?.primary50, -15),
      primary50: override.ref?.palette?.primary50,
      primary40: lightenDarkenColor(override.ref?.palette?.primary50, 15),
      primary30: lightenDarkenColor(override.ref?.palette?.primary50, 30),
      primary20: lightenDarkenColor(override.ref?.palette?.primary50, 45),
    };
    newPalette = merge(newPalette, primaryCalcs);
  }

  if (override.ref?.palette?.positive50) {
    const positiveCalcs = {
      positive70: lightenDarkenColor(override.ref?.palette?.positive50, -30),
      positive60: lightenDarkenColor(override.ref?.palette?.positive50, -15),
      positive50: override.ref?.palette?.positive50,
      positive40: lightenDarkenColor(override.ref?.palette?.positive50, 15),
      positive30: lightenDarkenColor(override.ref?.palette?.positive50, 30),
      positive20: lightenDarkenColor(override.ref?.palette?.positive50, 45),
    };
    newPalette = merge(newPalette, positiveCalcs);
  }

  if (override.ref?.palette?.warning50) {
    const warningCalcs = {
      warning70: lightenDarkenColor(override.ref?.palette?.warning50, -30),
      warning60: lightenDarkenColor(override.ref?.palette?.warning50, -15),
      warning50: override.ref?.palette?.warning50,
      warning40: lightenDarkenColor(override.ref?.palette?.warning50, 15),
      warning30: lightenDarkenColor(override.ref?.palette?.warning50, 30),
      warning20: lightenDarkenColor(override.ref?.palette?.warning50, 45),
    };
    newPalette = merge(newPalette, warningCalcs);
  }

  if (override.ref?.palette?.negative50) {
    const negativeCalcs = {
      negative70: lightenDarkenColor(override.ref?.palette?.negative50, -30),
      negative60: lightenDarkenColor(override.ref?.palette?.negative50, -15),
      negative50: override.ref?.palette?.negative50,
      negative40: lightenDarkenColor(override.ref?.palette?.negative50, 15),
      negative30: lightenDarkenColor(override.ref?.palette?.negative50, 30),
      negative20: lightenDarkenColor(override.ref?.palette?.negative50, 45),
    };
    newPalette = merge(newPalette, negativeCalcs);
  }

  return {
    palette: newPalette,
  };
};
