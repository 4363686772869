import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const FETCH_ACCOUNT_TYPE_AND_NUMBER = gql`
  query fetchAccountTypeAndNumber($id:ObjectID!) {
    fetchAccount(accountId:$id) {
      account { type custodianAccountNumber }
    }
  }
`;

const AccountLabel = ({ accountId }: { accountId: string }) => {
  const { t } = useTranslation(['client', 'accountTypes']);
  const { t: tt } = useTranslation('accountTypes');
  const { data } = useQuery(FETCH_ACCOUNT_TYPE_AND_NUMBER, { variables: { id: accountId } });
  return <>
    { data?.fetchAccount?.account?.type ? tt(data?.fetchAccount?.account?.type) : t('account')}
    &nbsp;
    #{ data?.fetchAccount?.account?.custodianAccountNumber ? data?.fetchAccount?.account?.custodianAccountNumber : accountId}
  </>;
};

export default AccountLabel;
