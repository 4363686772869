import { useTranslation } from 'react-i18next';
import { TranslatedString } from 'interfaces';
import { Box } from 'ovComponents/1-primative';
import { Checkbox, TranslatableTextField } from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';

interface FieldEditProps {
  option: any,
  options: any,
  setOptions: (x: any) => void,
  i: number,
}
export const disclaimerInfo = (disclaimerInfoOpts: any) => {
  if (disclaimerInfoOpts?.checked) return translateBackend(disclaimerInfoOpts);
  return undefined;
};

export const CustomLabelsEditTemp = ({
  option, options, setOptions, i,
}: FieldEditProps) => {
  const { t } = useTranslation(['workflowCompletions']);

  if (!options) return <></>;
  const { customLabelsTemp } = option.options;
  if (!customLabelsTemp) return <></>;

  const updateCustomLabelsOptions = (labelKey: string, opts: any) => {
    const newOptions = [...options];
    newOptions[i].value = {
      ...option.value,
      customLabelsTemp: {
        ...option.value.customLabelsTemp,
        [labelKey]: opts,
      },
      enabled: true,
    };
    setOptions(newOptions);
  };

  return (
    <>
      {Object.keys(customLabelsTemp).map((labelKey, index: number) => {
        const label = `${t('subStepOptions.optionTypes.CUSTOM_LABELS_FIELD.checkboxLabel')} ${t('shared:on')} ${t(`subStepOptions.optionTypes.CUSTOM_LABELS_FIELD.${labelKey}`)}`;

        const value = options[i].value.customLabelsTemp?.[labelKey]
          ?? options[i].default?.customLabelsTemp?.[labelKey];

        const enabled = options[i].value.customLabelsTemp?.[labelKey]?.enabled;

        const handleChecked = (onoff: boolean) => {
          updateCustomLabelsOptions(labelKey, { ...value, enabled: onoff });
        };

        const handleTextChange = (newValue: TranslatedString) => {
          updateCustomLabelsOptions(labelKey, { ...newValue, enabled: true });
        };

        return <Box sx={{ mb: 2 }} key={index}>
          <Checkbox
            size='small'
            label={label}
            checked={enabled}
            onChange={handleChecked}
          />
          {enabled && (
            <TranslatableTextField
              value={value}
              fullWidth
              onChange={handleTextChange}
              disabled={!enabled}
            />
          )}
        </Box>;
      })}
    </>
  );
};
