import { SxProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Hexcode,
  Typography,
} from 'ovComponents';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { useHelpTextTokens } from './helpText.tokens';

type HelpTextTone = 'success' | 'warning' | 'error' | 'neutral' ;
interface HelpTextProps {
  text?: string,
  tone?: HelpTextTone,
}

/**
 * Component to render help text in the UI when it is not possible to
 * show help text bound to individual inputs (e.g. reporting errors
 * for an entire form section or for inputs with insufficient space to
 * bind error messages, like Radio or Checkbox)
 */
export const HelpText = ({ text = '', tone = 'neutral' }: HelpTextProps) => {
  const tokens = useThemeTokens(useHelpTextTokens());
  const { comp } = tokens;

  const getColor = (): Hexcode => {
    switch (tone) {
      case 'success':
        return comp.helpText.successColor;
      case 'warning':
        return comp.helpText.warningColor;
      case 'error':
        return comp.helpText.errorColor;
      case 'neutral':
      default:
        return comp.helpText.neutralColor;
    }
  };

  const iconSx: SxProps = { color: getColor(), mr: 0.5, fontSize: '0.875em' };
  const getIcon = (): JSX.Element => {
    switch (tone) {
      case 'success':
        return <CheckCircleIcon sx={iconSx} />;
      case 'warning':
        return <WarningAmberOutlinedIcon sx={iconSx} />;
      case 'error':
        return <ErrorOutlineIcon sx={iconSx} />;
      case 'neutral':
      default:
        return <InfoOutlinedIcon sx={iconSx} />;
    }
  };

  return (
    <Box alignItems='center' display='flex' my={0.5}>
      {getIcon()}
      <Typography variant='bodySmall' sx={{ color: getColor() }}>{text}</Typography>
    </Box>
  );
};
