import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Account } from '../../../../../interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { currencySymbol } from '../../../../../util/currency';
import { Box } from '../../../../1-primative';
import { TableCell } from '../../../../2-component';
import { CurrencyFlag, CurrencyIndicator } from '../../../../3-pattern';

export const AccountCell = ({
  account, type, totalMarketValue, useExternalStatisticsEnabled, displayCurrency, isFirst,
}: { account: Account, type: string, totalMarketValue: number, useExternalStatisticsEnabled?: boolean, displayCurrency?: boolean, isFirst?: boolean }) => {
  const { t } = useTranslation('accountTypes');
  const { sys } = useThemeTokens();

  const accountHolders = () => {
    const names = [
      account.user.firstName,
      ...(account?.affiliations ?? []).filter((aff: any) => ['JOINT', 'AUTHORIZED_INDIVIDUAL', 'TRUSTEE'].includes(aff.type)).map((affiliation: any) => affiliation.user.firstName),
    ];
    return names.join(', ');
  };

  const leadingComponent = useMemo(() => (
    displayCurrency && account?.baseCurrency ? <CurrencyFlag baseCurrency={account.baseCurrency} /> : undefined
  ), [displayCurrency, account?.baseCurrency]);

  const getColorByValue = (value: number) => {
    if (value === 0) return sys.color.onSurface;
    return value >= 0 ? sys.color.positive : sys.color.negative;
  };

  const getUnrealizedGainsAndLoss = () => {
    if (useExternalStatisticsEnabled && account?.custodianStatistics) {
      return (
        <>
          {`${formatMoneyValue(account?.custodianStatistics?.simpleReturnAmountCents ?? 0)}
            (${formatPercentValue(account?.custodianStatistics?.simpleReturnPercent ?? 0)})`}
        </>
      );
    }
    return (
      <>
        {`${formatMoneyValue(account?.statistics?.simpleReturnAmount ?? 0)}
        (${formatPercentValue(account?.statistics?.simpleReturnPercent ?? 0)})`}
      </>
    );
  };

  const getTwrr = (): number => {
    if (useExternalStatisticsEnabled && account?.custodianStatistics) {
      return account?.custodianStatistics?.timeWeightedReturn ?? 0;
    }
    return account?.statistics?.timeWeightedReturn ?? 0;
  };

  const getMwrr = (): number => {
    if (useExternalStatisticsEnabled && account?.custodianStatistics) {
      return account?.custodianStatistics?.moneyWeightedReturn ?? 0;
    }
    return account?.statistics?.moneyWeightedReturn ?? 0;
  };

  const getPercentageValue = () => {
    let value = '';
    if (useExternalStatisticsEnabled && account?.custodianStatistics) {
      value = (((account?.custodianStatistics?.marketValueCents ?? 0) / totalMarketValue) * 100).toFixed(1);
    } else {
      value = (((account?.statistics?.historySnapshot?.marketValueCents ?? 0) / totalMarketValue) * 100).toFixed(1);
    }
    return (
      <TableCell number isFirst={isFirst} leadingComponent={leadingComponent}>
        {`${value}%`}
      </TableCell>
    );
  };

  const getCurrentValue = () => {
    if (useExternalStatisticsEnabled && account?.custodianStatistics) {
      return formatMoneyValue(account?.custodianStatistics?.marketValueCents ?? 0, currencySymbol[CurrencyCodes.CAD]);
    }
    return formatMoneyValue(account?.statistics?.historySnapshot?.marketValueCents ?? 0, currencySymbol[account?.statistics?.historySnapshot?.currency as CurrencyCodes ?? CurrencyCodes.CAD]);
  };

  switch (type) {
    case 'holder':
      return (
        <TableCell isFirst={isFirst} leadingComponent={leadingComponent}>{accountHolders()}</TableCell>
      );
    case 'nickname':
      return (
        <TableCell isFirst={isFirst} leadingComponent={leadingComponent}>
          {translateBackend(account.translatedNickName) || account?.nickName || '-'}
        </TableCell>
      );
    case 'name':
      return (
        <TableCell isFirst={isFirst} leadingComponent={leadingComponent}>{t(`accountTypes:${account.type}`)}</TableCell>
      );
    case 'accountNumber':
      return (
        <TableCell isFirst={isFirst} leadingComponent={leadingComponent}>{account.custodianAccountNumber}</TableCell>
      );
    case 'program':
      return (
        <TableCell isFirst={isFirst} leadingComponent={leadingComponent}>
          {translateBackend((account?.subAccounts ?? [])[0]?.theme?.translatedName)}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(account?.statistics?.simpleReturnAmount ?? 0),
        }}>
          {getUnrealizedGainsAndLoss()}
        </TableCell>
      );
    case 'twrr':
      return (
        <TableCell number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(getTwrr()),
        }}>
          {formatPercentValue(getTwrr())}
        </TableCell>
      );
    case 'mwrr':
      return (
        <TableCell number isFirst={isFirst} leadingComponent={leadingComponent} sx={{
          textAlign: 'right', color: getColorByValue(getMwrr()),
        }}>
          {formatPercentValue(getMwrr())}
        </TableCell>
      );
    case 'percentOfTotal':
      return getPercentageValue();
    case 'currentValue':
      return (
        <TableCell right bold isFirst={isFirst} number leadingComponent={
          <Box display='flex' alignItems='center' gap={1}>
            {leadingComponent}
            <CurrencyIndicator
              statistics={useExternalStatisticsEnabled ? account?.custodianStatistics : account?.statistics}
              useExternalStatisticsEnabled={useExternalStatisticsEnabled}
              titleSx={{ color: sys.color.surface }}
              readFromSnapshot
            />
          </Box>
        }>
          {getCurrentValue()}
        </TableCell>
      );
    default:
      return (
        <TableCell isFirst={isFirst}>-</TableCell>
      );
  }
};
