import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '../../../../1-primative';
import {
  Button,
  DateField,
  Dialog, DialogContent, DialogFooter, DialogTitle,
  TextField,
} from '../../../../2-component';

const CREATE_TAX_LOT = gql`
  mutation createTaxLot($input: CreateTaxLotInput!) {
    createTaxLot(input: $input) {
      taxLot {
        id
        quantity
        priceCents
        date
      }
    }
  }
`;

export const NewTaxLot = ({
  open, setOpen, onCreate, financialProductId, accountId,
}: {
  open: boolean, setOpen: (open: boolean) => void, onCreate: (newLot: any) => void, financialProductId: string, accountId: string,
}) => {
  const { t } = useTranslation(['client', 'shared']);
  const [newLot, setNewLot] = useState({
    quantity: 0,
    price: 0,
    date: new Date(),
  });

  const [createTaxLot, { loading }] = useMutation(CREATE_TAX_LOT, {
    variables: {
      input: {
        accountId,
        financialProductId,
        quantity: newLot.quantity,
        priceCents: newLot.price * 100,
        date: dayjs(newLot.date).format('YYYY-MM-DD'),
      },
    },
    onCompleted: (d: any) => {
      onCreate({
        ...newLot,
        priceCents: newLot.price * 100,
        id: d.createTaxLot.taxLot.id,
      });
      setNewLot({
        quantity: 0,
        price: 0,
        date: new Date(),
      });
      setOpen(false);
    },
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xs'>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('newTaxLotTitle')}
      </DialogTitle>
      <DialogContent>
        <Box gap={2} display='flex' flexDirection='column'>
          <TextField
            label={t('client:newTaxLot:quantity')}
            type='number'
            fullWidth
            value={newLot.quantity}
            onChange={(e: any) => setNewLot({ ...newLot, quantity: e.target.valueAsNumber })}
          />
          <TextField
            label={t('client:newTaxLot:price')}
            type='dollar'
            fullWidth
            leadingIcon='dollar'
            value={newLot.price }
            onChange={(e: any) => setNewLot({ ...newLot, price: e.target.value })}
          />
          <DateField
            label={t('client:newTaxLot:date')}
            fullWidth
            value={newLot.date}
            onChange={(e: any) => setNewLot({ ...newLot, date: e })}
          />
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button label={t('shared:create')} onClick={createTaxLot} disabled={loading} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};
