import { merge } from 'lodash/fp';
import { OverrideTokens } from '..';
import { BorderRadiusTokens, borderRadius } from './borderRadius/borderRadius';
import { FontTokens, font } from './font/font';
import { ColorTokens, useColorTokens } from './color/color';
import { ElevationTokens, elevation } from './elevation/elevation';
import { SpacingTokens, spacing } from './layout/layout';

export interface SysTokens {
  color: ColorTokens;
  borderRadius: BorderRadiusTokens;
  elevation: ElevationTokens;
  spacing: SpacingTokens;
  font: FontTokens;
}

export interface OverrideSysTokens {
  color?: ColorTokens;
  borderRadius?: BorderRadiusTokens;
  elevation?: ElevationTokens;
  spacing?: SpacingTokens;
}

export const useSysTokens = (override: OverrideTokens): SysTokens => {
  const base: SysTokens = {
    color: useColorTokens(override),
    borderRadius,
    elevation,
    spacing,
    font,
  };

  const tokens = merge(base, override.sys);

  return tokens;
};
