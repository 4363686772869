import {
  Box, Button, Grid, ListItem, TextField, Typography,
} from '@mui/material';
import {
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from 'providers/globalToastProvider';
import dayjs from 'dayjs';
import {
  StepProps,
} from '../wizard';
import { FETCH_IDS } from '../../../../pages/client/components/iDs';
import { UploadedFile } from './uploadedFile';
import { FETCH_USER } from '../../../../pages/client/components/profile';
import { usePermissions } from '../../../../providers/userContextProvider';
import { fileUploader, FileUploaderProps } from '../../../../util/fileUploader';
import { FETCH_DOCUMENTS } from '../../../../pages/client/components/documents';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../../../../interfaces/fileDocument';
import { LocalizedDatePicker } from '../../../fields/localizedDatePicker';

export const CREATE_USER_ID_VERIFICATION_INFORMATION = gql`
  mutation createUserIDVerificationInformation($input: CreateUserIDVerificationInformationInput!) {
    createUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

export const SummaryStep = ({
  context, state, setState, continueTo, mutationEvent,
}: StepProps) => {
  const { t } = useTranslation(['addIdVerificationWizard', 'client']);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const isDisabled = (): boolean => {
    const disabledForDocumentOne = !(state.documentOne?.documentName
      && state.documentOne?.documentID
      && state.documentOne.documentType && state.verifiedDate);

    if (state.methodOfIDVerification === 'DUAL_METHOD') {
      return !(state.documentOne?.documentName
        && state.documentOne?.documentID
        && state.documentOne.documentType
        && state.documentOne.file
        && state.documentTwo?.documentName
        && state.documentTwo?.secondaryDocumentID
        && state.documentTwo.secondaryDocumentType
        && state.documentTwo.file
        && state.verifiedDate
      );
    }
    return disabledForDocumentOne;
  };
  const graphqlClient = useApolloClient();
  const [createUserIdVerification] = useMutation(CREATE_USER_ID_VERIFICATION_INFORMATION, {
    refetchQueries: [FETCH_IDS],
  });

  const doDocumentUpload = async (verificationId: string, document: any) => {
    const fileUploadProps: FileUploaderProps = {
      file: document.file,
      createFileInput: {
        objectType: FileDocumentObjectTypes.USER,
        objectId: context.userId,
        userId: context.userId,
        fileName: document.file?.name,
        type: FileDocumentTypes.ID_VERIFICATION,
        name: document.documentName,
        mediaType: document.file?.type,
        permissionType: 'PUBLIC',
        sourceType: document?.secondaryDocumentType ?? 'ID_DOCUMENT',
        sourceId: verificationId,
      },
      onSuccess: () => {
        showToast({ severity: 'success', message: `ID document uploaded successfully: ${document.file.name}` });
        mutationEvent('succeeded');
      },
      refetchQueries: [
        FETCH_DOCUMENTS,
        FETCH_IDS,
        FETCH_USER(permissions),
      ],
      apolloClient: graphqlClient,
    };

    await fileUploader(fileUploadProps);
  };
  const doUpload = async (document: any) => {
    // 1. create idVerification
    const idResponse1 = await createUserIdVerification({
      variables: {
        input: {
          methodOfIDVerification: state.methodOfIDVerification,
          documentType: document.documentType,
          documentID: document.documentID,
          userName: document.userName,
          documentIssuingJurisdication: document.documentIssuingJurisdication,
          documentExpiryDate: document.documentExpiryDate === '' ? undefined : document.documentExpiryDate,
          verifiedDate: state.verifiedDate,
          userId: context.userId,
        },
      },
    });
    if (idResponse1?.data.createUserIDVerificationInformation.idVerification
      && document.file) {
      const sourceId1 = idResponse1?.data.createUserIDVerificationInformation.idVerification.id;
      const fileUploadProps: FileUploaderProps = {
        file: document.file,
        createFileInput: {
          objectType: FileDocumentObjectTypes.USER,
          objectId: context.userId,
          userId: context.userId,
          fileName: document.file?.name,
          type: FileDocumentTypes.ID_VERIFICATION,
          name: document.file?.name,
          mediaType: document.file?.type,
          permissionType: 'PUBLIC',
          sourceType: document?.secondaryDocumentType ?? 'ID_DOCUMENT',
          sourceId: sourceId1,
        },
        refetchQueries: [
          FETCH_DOCUMENTS,
          FETCH_IDS,
          FETCH_USER(permissions),
        ],
        apolloClient: graphqlClient,
      };
      await fileUploader(fileUploadProps);
      showToast({ severity: 'success', message: `ID document uploaded successfully: ${document.file.name}` });
      mutationEvent('succeeded');
    }
  };
  const doDualUpload = async () => {
    // 1. create idVerification for dual method
    const idResponse = await createUserIdVerification({
      variables: {
        input: {
          methodOfIDVerification: state.methodOfIDVerification,
          documentType: state.documentOne?.documentType,
          documentID: state.documentOne?.documentID ?? undefined,
          userName: state.documentOne?.userName,
          documentIssuingJurisdication: state.documentOne?.documentIssuingJurisdication,
          documentExpiryDate: state.documentOne?.documentExpiryDate === '' ? undefined : state.documentOne?.documentExpiryDate,
          secondaryDocumentID: state.documentTwo?.secondaryDocumentID ?? undefined,
          secondaryDocumentIssuingJurisdication: state.documentTwo?.secondaryDocumentIssuingJurisdication,
          secondaryDocumentExpiryDate: state.documentTwo?.secondaryDocumentExpiryDate === '' ? undefined : state.documentTwo?.secondaryDocumentExpiryDate,
          secondaryDocumentType: state.documentTwo?.secondaryDocumentType,
          secondaryDocumentUserName: state.documentTwo?.secondaryDocumentUserName,
          verifiedDate: state.verifiedDate,
          userId: context.userId,
        },
      },
    });
    if (idResponse?.data.createUserIDVerificationInformation.idVerification
      && state.documentOne?.file && state.documentTwo?.file) {
      const idVerification = idResponse?.data.createUserIDVerificationInformation.idVerification;
      // upload document 1
      await doDocumentUpload(idVerification.id, state.documentOne);
      // upload document 2
      await doDocumentUpload(idVerification.id, state.documentTwo);
      mutationEvent('succeeded');
    }
  };
  const finalized = async () => {
    if (state.methodOfIDVerification === 'DUAL_METHOD') {
      mutationEvent('started');
      await doDualUpload();
    } else {
      mutationEvent('started');
      await doUpload(state.documentOne);
    }
    continueTo(null);
  };

  const goToStep = (stepName: 'FIRST_DOCUMENT_STEP' | 'SECOND_DOCUMENT_STEP') => continueTo(stepName);

  return (
    <Box sx={{
      height: '670px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem sx={{ marginBottom: '16px' }}>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={12}>
                  <LocalizedDatePicker
                    label={t('verifiedDate')}
                    value={state?.verifiedDate}
                    onChange={(date) => {
                      setState({
                        ...state,
                        verifiedDate: dayjs(date?.toString()).format('YYYY-MM-DD'),
                      });
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          {state.methodOfIDVerification === 'DUAL_METHOD' && (<Typography variant='subtitle2'>{t('documentsUploaded')}</Typography>)}
          {state.methodOfIDVerification !== 'DUAL_METHOD' && (<Typography variant='subtitle2'>{t(`${state.documentOne?.file ? 'documentUploaded' : 'noDocumentUploaded'}`)}</Typography>)}
        </ListItem>
        <ListItem>
          {state.documentOne?.file && (<UploadedFile file={state.documentOne?.file} onDelete={() => {
            if (state.documentOne) {
              setState({
                ...state,
                documentOne: {
                  ...state.documentOne,
                  file: undefined,
                },
              });
            }
            goToStep('FIRST_DOCUMENT_STEP');
          }} />)}
        </ListItem>
        <ListItem>
          {state.documentTwo?.file && (<UploadedFile file={state.documentTwo?.file} onDelete={() => {
            if (state.documentTwo) {
              setState({
                ...state,
                documentTwo: {
                  ...state.documentTwo,
                  file: undefined,
                },
              });
            }
            goToStep('SECOND_DOCUMENT_STEP');
          }} />)}
        </ListItem>
      </Box>
      <Box m={2}>
        <Button type='submit' disabled={isDisabled()}
          onClick={() => finalized()} fullWidth variant='contained'>
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};
