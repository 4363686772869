import DeleteIcon from '@mui/icons-material/RemoveCircleOutline';
import NumberIcon from '@mui/icons-material/Numbers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { isDate, isNaN } from 'lodash/fp';
import NewFilter from './newFilter';
import {
  Box,
  Grid, Typography,
} from '../../../1-primative';
import {
  Card, CardContent, IconButton,
} from '../../../2-component';
import { useLocalization } from '../../../../util/useLocalization';

export enum ReportComparison {
  TEXT_SEARCH = 'TEXT_SEARCH',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_AGO = 'GREATER_THAN_AGO',
  LESS_THAN_AGO = 'LESS_THAN_AGO',
}

interface Filter {
  field: string,
  comparison: ReportComparison,
  value: string
}

const iconForValue = (value: any) => {
  if (isDate(value)) {
    return <CalendarTodayIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.54)' }} />;
  }

  if (!isNaN(parseFloat(value))) {
    return <NumberIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.54)' }} />;
  }

  return <FilterAltIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.54)' }} />;
};
const EditFilters = ({ filters, setFilters, availableFilters }: { filters: Filter[], setFilters: (filters: any) => void, availableFilters: any[] }) => {
  const { t } = useTranslation('customReports');
  const { localizedDateTime } = useLocalization();

  const valueOrDate = (value: string) => (isDate(value) ? localizedDateTime(value) : value);
  const filterText = (filter: Filter) => `${t(`column.${filter.field}`)} ${filter.comparison.toLowerCase().replaceAll('_', ' ')} ${valueOrDate(filter.value)}`;

  return (
    <Box>
      { filters.map((filter: Filter, index: number) => (
        <Card sx={{ mb: 1 }} variant='outlined'>
          <CardContent sx={{ padding: '8px 16px !important' }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Grid container alignItems='center'>
                  <Grid item sx={{ display: 'flex', mr: 1 }}>
                    { iconForValue(filter.value) }
                  </Grid>
                  <Grid item>
                    <Typography >{ filterText(filter) }</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  size='small'
                  onClick={() => {
                    const items = [...filters];
                    items.splice(index, 1);
                    setFilters(items);
                  }}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <NewFilter availableFilters={availableFilters} filters={filters} setFilters={setFilters} />
    </Box>
  );
};

export default EditFilters;
