import { gql, useLazyQuery } from '@apollo/client';

const FETCH_FILE_DOCUMENT_URL = gql`
query fetchFileDocumentDownloadUrl($fileDocumentId:ObjectID!) {
  fetchFileDocument(fileDocumentId: $fileDocumentId) {
    fileDocument {
      downloadUrl fileName
    }
  }
}`;

const FETCH_AGREEMENT_URL = gql`
  query fetchFormAgreementURL($formAgreementId: ObjectID!) {
    fetchFormAgreement(input:{formAgreementId: $formAgreementId}) {
      signedUrl
    }
  }
`;

export function FileDownloader({ fileDocument, children }: { fileDocument: any, children: any }) {
  const [fetchFileDocumentUrl] = useLazyQuery(FETCH_FILE_DOCUMENT_URL, {
    variables: { fileDocumentId: fileDocument.id },
    fetchPolicy: 'no-cache',
  });

  const downloadFile = async (): Promise<void> => {
    const result = await fetchFileDocumentUrl();
    const url = result?.data?.fetchFileDocument?.fileDocument?.downloadUrl;
    if (window && url) {
      window.open(url, '_blank');
    }
  };

  const downloadAgreementPDF = async () => {
    const url: string = await generateAgreementUrl();
    window.open(url, '_blank');
  };

  const generateAgreementUrl = async () => {
    const result = await fetchAgreementURL();
    return result?.data?.fetchFormAgreement.signedUrl || '';
  };

  const [fetchAgreementURL] = useLazyQuery(FETCH_AGREEMENT_URL, {
    variables: {
      formAgreementId: fileDocument?.id,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <div style={{ margin: '0 auto', wordBreak: 'break-all' }} onClick={fileDocument.isFileDocument ? downloadFile : downloadAgreementPDF}>
      {children}
    </div>
  );
}
