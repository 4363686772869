import {
  Box, Grid, Typography,
} from '@mui/material';
import RichTextEditor from 'components/inputs/richTextEditor';
import cautionRedIcon from '../../../assets/images/icons-filled/caution-red.svg';
import { colors } from '../../../theme/colors';
import { Date } from '../../../components/misc/date/date';

const infoStyle = {
  fontWeight: 'Bold',
  fontSize: '16px',
};

const DetailItem = ({
  title, value, subtitle, checker, valueType = 'text', grid = 6, hideElement = false, errorMessage, showValue,
}: {
  title: string,
  value?: any,
  subtitle?: string,
  checker?: () => boolean,
  valueType?: 'text' | 'date' | 'mark-down', grid?: number, hideElement?: boolean, showValue?: boolean, errorMessage?: string
}): JSX.Element => {
  if (hideElement) return <></>;

  const hasValue = ():boolean => {
    if (checker) {
      return checker();
    }
    return !(value === undefined || value === '' || value === null);
  };

  let detailBody = <></>;
  if (hasValue() || showValue) {
    switch (valueType) {
      case 'text':
        detailBody = <Typography style={infoStyle}>{value}</Typography>;
        break;
      case 'mark-down':
        detailBody = <RichTextEditor
          value={value}
          readonly
          customEditorStyle={{ minHeight: 0, borderWidth: 0, padding: 0 }}
        />;
        break;
      case 'date':
        detailBody = <Date style={infoStyle} date={value} />;
        break;
    }
  }
  return (
    <Grid item xs={grid}>
      <Box display='flex' flexDirection='row'>
        <Typography style={{ fontSize: '12px', color: hasValue() ? colors.gray500 : colors.error }}>{title}</Typography>
        {hasValue() ? undefined : <img style={{ marginLeft: '5px', marginTop: '-3px' }} src={cautionRedIcon} alt='caution' />}
      </Box>
      {!hasValue() && errorMessage ? <Typography style={{ ...infoStyle, color: colors.error }}>{errorMessage}</Typography> : undefined}

      {detailBody}

      {subtitle
        && <Typography fontWeight="500" sx={{ fontSize: 12 }}>{subtitle}</Typography>
      }
    </Grid>
  );
};

export default DetailItem;
