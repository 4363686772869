import { Paper } from '@mui/material';
import BulkImportsTable from './components/bulkImportsTable';

const BulkImports = () => (
    <Paper sx={{ width: '100%' }}>
      <BulkImportsTable />
    </Paper>
);

export default BulkImports;
