import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import {
  EmptyState,
  IconButton,
} from '../../../2-component';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';
import { IdCard } from './components/idCard';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { IdDialog } from './components/idDialog';
import { usePermissions } from '../../../../providers/userContextProvider';

const FETCH_IDS = gql`
  query fetchUserIdVerifications($input: FetchUserIdVerificationsInput!) {
    fetchUserIdVerifications(input: $input) {
      idVerifications {
        id
        creditFileSource
        documentExpiryDate
        documentID
        documentIssuingJurisdication
        documentType
        methodOfIDVerification
        secondaryDocumentExpiryDate
        secondaryDocumentID
        secondaryDocumentIssuingJurisdication
        secondaryDocumentType
        secondaryDocumentUserName
        verifiedDate
        userName
      }
    }
  }
`;

export const IdVerification = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation(['client', 'shared']);
  const { sys } = useThemeTokens();
  const [activeId, setActiveId] = useState<any>({});
  const { permissions } = usePermissions();
  const [activeImages, setActiveImages] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [updateNumber, setUpdateNumber] = useState(0);

  const { data, loading, refetch } = useQuery(FETCH_IDS, {
    variables: {
      input: {
        filter: {
          userId: objectId,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !permissions.includes('read:id_verification'),
  });

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='headingSmall' mb={2} data-testid='verification-title'>{options.customTitle ? translateBackend(options.customTitle) : t('suitability.title')}</Typography>
        {permissions.includes('write:id_verification') && options.addNewIdVerification && (
          <IconButton onClick={() => {
            setActiveId({
              creditFileSource: '',
              documentExpiryDate: null,
              documentID: '',
              documentIssuingJurisdication: '',
              documentType: '',
              methodOfIDVerification: '',
              secondaryDocumentExpiryDate: null,
              secondaryDocumentID: '',
              secondaryDocumentIssuingJurisdication: '',
              secondaryDocumentType: null,
              secondaryDocumentUserName: '',
              verifiedDate: null,
              userName: '',
            });
            setActiveImages([]);
            setOpen(true);
          }}>
            <Add />
          </IconButton>
        )}
      </Box>
      <Grid container spacing={2}>
        {loading && (
          <>
            <Grid item xs={12} md={6}>
              <Skeleton variant='rectangular' height={202} sx={{ borderRadius: sys.borderRadius.md }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton variant='rectangular' height={202} sx={{ borderRadius: sys.borderRadius.md }} />
            </Grid>
          </>
        )}
        { data?.fetchUserIdVerifications?.idVerifications?.map((idVerification: any, index: number) => (
          <IdCard idVerification={idVerification} index={index} key={index} options={options} setActiveImages={setActiveImages} updateNumber={updateNumber} onClick={() => {
            setActiveId(idVerification);
            setOpen(true);
          }} />
        ))}
        {!loading && data?.fetchUserIdVerifications?.idVerifications.length === 0 && (
          <Grid item xs={12}>
            <EmptyState data-testid='accounts-card-empty-state'>
              <Typography variant='bodyLarge'>{t('noIds')}</Typography>
            </EmptyState>
          </Grid>
        )}
      </Grid>
      <IdDialog open={open} setOpen={setOpen} idVerification={activeId} activeImages={activeImages} userId={objectId} refetch={() => {
        refetch();
        setUpdateNumber(updateNumber + 1);
      }} />
    </>
  );
};
