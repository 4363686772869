import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, TextField, Grid,
  InputAdornment,
  Paper,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Date } from '../../components/misc/date/date';
import { formatMoneyValue } from '../../util';
import { usePageState } from '../../util/usePageState';

const FETCH_USERS = (permissions: string[]) => gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      users {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        dateOfBirth
        subAccounts{
          id
          financialProduct {
            name
          }
          account {
            custodianAccountNumber
          }
        }
        goals {
          id,
          type
          name
        }
        statistics {
          marketValueCents
        }
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

const ElderlyClients = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reports']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const pageSize = 15;
  const { loading, data, previousData } = useQuery(FETCH_USERS(permissions), {
    variables: {
      input: {
        filter: {
          searchText, dateOfBirthBefore: dayjs().subtract(65, 'years').utc().format(), isComplete: true, organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  return (
    <Paper sx={{ width: '100%' }}>
      <Box>
        <Grid container>
          <Grid>
            <TextField
              label={t('search')}
              sx={{ width: '300px', m: 1 }}
              size='small'
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                setPage(1);
              }}
              id='searchText'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1}>
            {loading && previousData ? <CircularProgress size='30px' sx={{ marginTop: '6px', float: 'right' }}/> : <></>}
          </Grid>
        </Grid>
        {loading && !previousData ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        ) : (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant='overline'>{t('table.name')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.custodianAccountNumbers')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.goals')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.portfolioRiskLevels')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.marketValue')}</Typography></TableCell>
                  <TableCell align="right"><Typography variant='overline'>{t('table.dateOfBirth')}</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || previousData)?.fetchUsers?.users?.map((user: any) => (
                  <TableRow
                    hover
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => navigate(`/clients/${user.id}`)}
                  >
                    <TableCell component="th" scope="row">
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.subAccounts.map((x: any) => x.account.custodianAccountNumber).join(', ')}</TableCell>
                    <TableCell>{user.goals.map((x: any) => x.name || x.type).join(', ')}</TableCell>
                    <TableCell>{user.subAccounts.map((x: any) => x.financialProduct?.name).join(', ')}</TableCell>
                    <TableCell>{formatMoneyValue(user.statistics.marketValueCents)}</TableCell>
                    <TableCell align='right'><Date variant='subtitle2' date={user.dateOfBirth} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchUsers?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default ElderlyClients;
