import { Typography, TextField, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { usePermissions } from '../../providers/userContextProvider';

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
        organization {
          id
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
        template {
          permissions
          accessiblePages
          dashboards {
            id
            name
          }
          navigationStyle
          nonIndividualPageConfiguration {
            id
          }
          individualPageConfiguration {
            id
          }
          goalPageConfiguration {
            id
          }
          accountPageConfiguration {
            id
          }
          householdPageConfiguration {
            id
          }
          subAccountPageConfiguration {
            id
          }
        }
      }
    }
  }
`;

const RoleProfileSelect = ({
  onChange,
  value,
  label,
  size,
  white = false,
  organizationId,
  readonly = false,
  parent,
}: {
  onChange: (event: any) => void;
  value: string | undefined;
  label: string;
  size?: 'small' | 'medium' | undefined;
  white?: boolean;
  organizationId?: any;
  readonly?: boolean;
  parent?: any;
}) => {
  const { permissions } = usePermissions();
  const {
    loading, error, data, refetch,
  } = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: {
          organizationId,
        },
      },
    },
    skip: !organizationId,
  });

  useEffect(() => {
    if (organizationId) {
      refetch({
        input: {
          filter: {
            organizationId,
          },
        },
      });
    }
  }, [organizationId, refetch]);

  if (error) <Typography>Error</Typography>;
  const handleSelectChange = (event: any) => {
    const selectedId = event.target.value;
    const selectedProfile = data?.fetchRoleProfiles?.roleProfiles.find((x: any) => x.id === selectedId);
    if (selectedProfile) {
      const profileData = {
        id: selectedProfile.id,
        permissions: selectedProfile.template.permissions,
        dashboards: selectedProfile.template.dashboards,
        accessiblePages: selectedProfile.template.accessiblePages,
        navigationStyle: selectedProfile.template.navigationStyle,
        nonIndividualPageConfigurationId: selectedProfile.template.nonIndividualPageConfiguration?.id,
        individualPageConfigurationId: selectedProfile.template.individualPageConfiguration?.id,
        goalPageConfigurationId: selectedProfile.template.goalPageConfiguration?.id,
        accountPageConfigurationId: selectedProfile.template.accountPageConfiguration?.id,
        householdPageConfigurationId: selectedProfile.template.householdPageConfiguration?.id,
        subAccountPageConfigurationId: selectedProfile.template.subAccountPageConfiguration?.id,
      };
      onChange(profileData);
    } else {
      onChange({
        id: '',
        permissions: [],
        dashboards: [],
        accessiblePages: [],
        nonIndividualPageConfigurationId: undefined,
        individualPageConfigurationId: undefined,
        goalPageConfigurationId: undefined,
        accountPageConfigurationId: undefined,
        householdPageConfigurationId: undefined,
        subAccountPageConfigurationId: undefined,
      });
    }
  };

  return (
    <>
      {permissions.includes('read:organizations') ? (
        <TextField
          select
          disabled={readonly}
          value={data && value !== undefined ? value : ''}
          label={label}
          sx={{ width: '100%' }}
          onChange={handleSelectChange}
          size={size}
          className={white ? 'white' : ''}
          data-testid='RoleProfileSelect-TextField'
        >
          {loading ? (
            <MenuItem>...</MenuItem>
          ) : (
            (data?.fetchRoleProfiles?.roleProfiles || [])?.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.translatedName.en}
              </MenuItem>
            ))
          )}
          {parent && (
            <MenuItem key={parent.id} value={parent.id}>
              {parent.translatedName.en}
            </MenuItem>
          )}
        </TextField>
      ) : (
        <></>
      )}
    </>
  );
};

export default RoleProfileSelect;
