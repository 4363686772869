import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { AddTwoTone } from '@mui/icons-material';
import { kebabCase } from 'lodash';
import { Typography } from '../../../../../1-primative';
import { Button, EmptyState } from '../../../../../2-component';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import type { Affiliation } from '../../../../../../interfaces';
import { UserContext } from '../../../../../../providers/userContextProvider';
import { AffiliateModal, UPDATE_AFFILIATIONS } from './affiliateModal';
import { RelationType } from '../accountConfig';
import { AffiliateCompletionBox } from './affiliateCompletionBox';

export enum AffiliateType {
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CONTINGENT_BENEFICIARY = 'CONTINGENT_BENEFICIARY',
  CONTRIBUTOR = 'CONTRIBUTOR',
  DIRECTOR = 'DIRECTOR',
  GRANTOR = 'GRANTOR',
  JOINT = 'JOINT',
  OTHER = 'OTHER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PRIMARY_BENEFICIARY = 'PRIMARY_BENEFICIARY',
  PROTECTOR = 'PROTECTOR',
  SETTLOR = 'SETTLOR',
  SUCCESSOR = 'SUCCESSOR',
  THIRD_PARTY = 'THIRD_PARTY',
  TRUSTEE = 'TRUSTEE',
  DECEDENT = 'DECEDENT',
}

export const Affiliate = ({
  type, account, affiliates = [], multi = false, showAllocation = false, showRelationship = false, fields = [], defaultRelationType,
  refetch, updateAccount, allAffiliates, onEdit, updateMode = false, edittable = true, title, useAccountHoldersAddress, error,
  errorText, supportText,
}: {
  type: AffiliateType; account: any; affiliates: Affiliation[]; multi: boolean; showAllocation?: boolean; showRelationship?: boolean;
  showMissingAffiliate?: boolean; fields?: any[]; defaultRelationType?: RelationType; refetch?: any; updateAccount?: any;
  allAffiliates: Affiliation[]; onEdit: () => void; updateMode?: boolean; edittable?: boolean; title?: string; useAccountHoldersAddress?: boolean;
  error?: boolean; errorText?: string, supportText?: string,
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { userContext } = useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'create' | 'edit'>('create');
  const [activeAffiliate, setActiveAffiliate] = useState<any>(null);

  const [updateAffiliations] = useMutation(UPDATE_AFFILIATIONS);

  const createOrEditAffiliate = (actionType: 'create' | 'edit', aff?: Affiliation) => {
    setActiveAffiliate(aff ?? { relation: defaultRelationType || undefined, user: {} });
    setAction(actionType);
    setOpen(true);
  };

  const removeAffiliate = (affiliateId: string) => {
    const newAff = allAffiliates.filter((item: any) => item.id !== affiliateId);

    updateAffiliations({
      variables: {
        input: {
          accountId: account.accountId,
          affiliations: newAff.map((item: any) => ({
            allocation: item.type === type ? Math.floor(100 / ((allAffiliates.filter((x: any) => x.type === item.type).length || 0) - 1)) : item.allocation,
            relation: item.relation,
            type: item.type,
            userId: item.user.id,
          })),
        },
      },
      onCompleted: refetch,
    });
  };

  const onChange = (event: any, affiliateId: string) => {
    const newAff = allAffiliates.map((item: any) => {
      if (item.id === affiliateId) {
        return { ...item, allocation: parseInt(event.target.value, 10) };
      }
      return item;
    });

    onEdit();
    updateAccount({ ...account, affiliations: newAff });
  };

  const defaultAffiliateType = type === 'OTHER' && defaultRelationType ? defaultRelationType : type;

  if (affiliates.length === 0 && !edittable) {
    return (
      <EmptyState>
        <Typography variant='bodyLarge'>{t('noLinkedAffiliate', { type: t(defaultAffiliateType) })}</Typography>
      </EmptyState>
    );
  }

  return (
    <>
      {affiliates.length > 0 ? affiliates.map((affiliate: any) => (
        <AffiliateCompletionBox
          testId={`affiliate-completion-box-${kebabCase(type)}`}
          updateMode={updateMode}
          affiliate={affiliate}
          title={title ?? t(defaultAffiliateType)}
          edittable={edittable}
          error={error}
          errorText={errorText}
          showRelationship={showRelationship}
          showAllocation={showAllocation}
          supportText={supportText}
          supportUrl={userContext.organization?.supportUrl}
          createOrEditAffiliate={createOrEditAffiliate}
          removeAffiliate={removeAffiliate}
          onChange={onChange}
        />
      )) : (
        <CompletionBox
          testId={`affiliate-completion-box-${type}`}
          variant={updateMode ? 'view' : 'edit'}
          state='todo'
          edittable
          title={title ?? t(`button.${defaultAffiliateType}`)}
          onAdd={() => createOrEditAffiliate('create')}
          error={error}
          errorText={errorText}
        >
          <Typography variant='bodyMedium'>{t(`description.${account.type}.${defaultAffiliateType}`)}</Typography>
        </CompletionBox>
      )}

      {multi && edittable && affiliates.filter((x: any) => x.type === type).length >= 1 && (
        <Button variant='text' label={t(`button.${type}`)} leadingIcon={AddTwoTone} onClick={() => createOrEditAffiliate('create')} sx={{ mb: 1 }} />
      )}

      <AffiliateModal
        data-testid='affiliate-modal'
        open={open}
        setOpen={setOpen}
        type={type}
        accountId={account.accountId}
        fields={fields}
        affiliate={activeAffiliate || { relation: defaultRelationType || undefined, user: {} }}
        allAffiliates={allAffiliates}
        affiliates={affiliates}
        refetch={refetch}
        action={action}
        useAccountHoldersAddress={useAccountHoldersAddress}
        account={account}
        title={title}
      />
    </>
  );
};
