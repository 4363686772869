/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-curly-newline */
import { useMutation } from '@apollo/client';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { SelectFieldEditor } from 'ovComponents/4-module/configurableOptionFields/basicOrSelectField';
import {
  CUSTOM_FIELDS_FORMAT_MAP,
  CUSTOM_FIELDS_WORKFLOW_STEP_MAP,
  CustomFieldFormats,
  CustomFieldObjectTypes,
  CustomFieldTypes,
  CustomFieldWorkflowSteps,
  FETCH_CUSTOM_FIELDS,
} from '../../../../interfaces/customField';
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, MenuItem, Switch, TextField, TranslatableTextField } from '../../../2-component';
import BuildCustomFieldRules from './buildCustomFieldRules';
import { FieldOptions, FormErrors, validateFields } from '../../../4-module/workflowCompletion/subSteps/utils';
import { Box } from '../../../1-primative';
import { CREATE_CUSTOM_FIELD } from './customFields.queries';
import { CustomFieldType, ObjectType, customFieldTypes, objectTypes, customFieldSource, FieldSource } from './objectTypeMenuBuilder';

export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

const NewCustomField = ({ afterCreate, defaultOrg }: { afterCreate: () => void; defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const [allOptions, setAllOptions] = useState([
    {
      displayText: {
        en: '',
        fr: '',
      },
      value: '',
    },
  ]);
  const initialCustomField = {
    format: '',
    type: '',
    key: '',
    objectType: '',
    translatedDescription: {
      en: '',
      fr: '',
    },
    translatedName: {
      en: '',
      fr: '',
    },
    workflowStep: '',
    organizationId: defaultOrg ?? '',
    conditionalRules: [],
    isTriggerRulesEnabled: false,
    externalField: '',
  };
  const initialOptions = [
    {
      value: {
        selectField: {
          options: [
            {
              value: '',
              displayText: { fr: '', en: '' },
            },
          ],
          checked: true,
        },
      },
      displayText: { fr: '', en: '' },
    },
  ];
  const [customField, setCustomField] = useState(initialCustomField);
  const [options, setOptions] = useState<any[]>(initialOptions);

  useEffect(() => {
    const val: any = options[0].value;
    setAllOptions(val.selectField?.options ?? []);
  }, [options, setAllOptions]);

  const [createCustomField, { loading }] = useMutation(CREATE_CUSTOM_FIELD, {
    variables: {
      input: {
        ...customField,
        selectOptions: [CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes)
          ? allOptions.map((opt: any) => ({
            displayText: {
              en: opt?.displayText?.en,
              fr: opt?.displayText?.fr,
            },
            value: opt.value,
          }))
          : undefined,
        translatedDescription: customField?.translatedDescription?.en || customField?.translatedDescription?.fr ? customField?.translatedDescription : undefined,
        workflowStep: customField.workflowStep || undefined,
        isTriggerRulesEnabled: undefined,
        externalField: customField.externalField || undefined,
      },
    },
    refetchQueries: [FETCH_CUSTOM_FIELDS],
  });

  const fieldOptions: FieldOptions = useMemo(
    () => ({
      key: { required: true },
      translatedName: { required: true },
      format: { required: true },
      objectType: { required: true },
      type: { required: true },
    }),
    [customField],
  );

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, customField, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [customField, fieldOptions],
  );

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  const checkOptionsAvailable = () => {
    if ([CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes)) {
      if (allOptions && allOptions.length > 0) {
        if (allOptions[0].value) return true;
      }
      return false;
    }
    return true;
  };

  const create = async (event: any) => {
    const formErrors = validate();
    if (formErrors && checkOptionsAvailable()) {
      setFocused(Object.keys(formErrors));
      return;
    }
    await createCustomField();
    setCustomField(initialCustomField);
    setOptions(initialOptions);
    setFocused([]);
    setErrors(null);
    setOpen(false);
    afterCreate();
  };

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' title={t('customFieldModal.title')} fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={create}>
          <DialogTitle onClose={() => setOpen(false)}>
            {t('customFieldModal.title')}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              overflow: 'hidden',
            }}
          >
            <Box>
              <TextField
                fullWidth
                value={customField?.key}
                label={t('customFieldModal.key')}
                onChange={(e: any) => {
                  setCustomField({ ...customField, key: e.target.value.toLowerCase().trim() });
                }}
                onBlur={() => {
                  setFocused([...focused, 'key']);
                }}
                helpText={t('customFieldModal.keyHelp')}
                required={true}
                placeholder={t('customFieldModal.keyPlaceholder')}
                onKeyDown={(event: any) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={!!errors?.key}
                errorText={errors?.key?.message}
              />
            </Box>
            <Box>
              <TranslatableTextField
                onChange={(value: any) => {
                  const translatedNameVal = !value.en && !value.fr;
                  setCustomField({ ...customField, translatedName: translatedNameVal ? undefined : value });
                }}
                value={customField.translatedName || {}}
                label={t('customFieldModal.translatedName')}
                rows={3}
                fullWidth
                required={true}
                error={!!errors?.translatedName}
                errorText={errors?.translatedName?.message}
                onBlur={() => {
                  setFocused([...focused, 'translatedName']);
                }}
              />
            </Box>
            <Box>
              <TranslatableTextField
                onChange={(value: any) => setCustomField({ ...customField, translatedDescription: value })}
                value={customField.translatedDescription || {}}
                fullWidth
                label={t('customFieldModal.translatedDescription')}
                rows={4}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                select
                data-testid='customFieldType'
                value={customField.type}
                required={true}
                label={t('customFieldModal.type')}
                onBlur={() => {
                  setFocused([...focused, 'type']);
                }}
                error={!!errors?.type}
                onChange={(e: any) => setCustomField({ ...customField, type: e.target.value })}
                errorText={errors?.type?.message}
              >
                {customFieldTypes.map((customFieldType: CustomFieldType) => (
                  <MenuItem value={customFieldType.value} key={customFieldType.key}>
                    {customFieldType.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {customField.type !== '' && (
              <Box>
                <TextField
                  fullWidth
                  select
                  data-testid='formatField'
                  value={customField.format}
                  required={true}
                  label={t('customFieldModal.format')}
                  onBlur={() => {
                    setFocused([...focused, 'format']);
                  }}
                  error={!!errors?.format}
                  errorText={errors?.format?.message}
                  onChange={(e: any) => setCustomField({ ...customField, format: e.target.value as CustomFieldFormats })}
                >
                  {CUSTOM_FIELDS_FORMAT_MAP[customField.type as CustomFieldTypes].map((field: string) => (
                    <MenuItem value={field} key={field}>
                      {t(`customFieldModal.formats.${field}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            {[CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) && (
              <>
                <Box>
                  {options.map((x: any, i: number) => (
                    <SelectFieldEditor option={x} key={i} options={options} setOptions={setOptions} i={i} enableTranslation displayTextLabel={t('customFieldModal.displayText')} />
                  ))}
                </Box>
              </>
            )}
            <Box>
              <TextField
                fullWidth
                select
                data-testid='objectTypeField'
                value={customField.objectType}
                required={true}
                label={t('customFieldModal.objectType')}
                onBlur={() => {
                  setFocused([...focused, 'objectType']);
                }}
                error={!!errors?.objectType}
                errorText={errors?.objectType?.message}
                onChange={(e: any) => setCustomField({ ...customField, objectType: e.target.value })}
              >
                {objectTypes.map((objectType: ObjectType) => (
                  <MenuItem value={objectType.value} key={objectType.key}>
                    {objectType.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box>
              <TextField
                fullWidth
                select
                data-testid='customFieldSource'
                value={customField.externalField}
                required={false}
                label={t('customFieldModal.customFieldSource')}
                onBlur={() => {
                  setFocused([...focused, 'customFieldSource']);
                }}
                error={!!errors?.externalField}
                errorText={errors?.externalField?.message}
                onChange={(e: any) => setCustomField({ ...customField, externalField: e.target.value })}
              >
                {customFieldSource.map((source: FieldSource) => (
                  <MenuItem value={source.value} key={source.key}>
                    {source.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {customField.objectType !== '' && (
              <Box>
                <TextField
                  fullWidth
                  select
                  data-testid='workflowStepField'
                  value={customField.workflowStep}
                  label={t('customFieldModal.workflowStep')}
                  onChange={(e: any) => setCustomField({ ...customField, workflowStep: e.target.value as CustomFieldWorkflowSteps })}
                >
                  {CUSTOM_FIELDS_WORKFLOW_STEP_MAP[customField.objectType as CustomFieldObjectTypes].map((workflow: string) => (
                    <MenuItem value={workflow} key={workflow}>
                      {t(`customFieldModal.workflowOptions.${workflow}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            <Box>
              <Switch
                key='isTriggerRulesEnabled'
                checked={customField.isTriggerRulesEnabled}
                onChange={(e: any) => {
                  setCustomField({ ...customField, isTriggerRulesEnabled: e });
                }}
                label={t('customFieldModal.enableConditionalRules')}
              />
            </Box>
            {customField.isTriggerRulesEnabled && (
              <Box>
                <BuildCustomFieldRules customField={customField} setCustomField={setCustomField} />
              </Box>
            )}
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:create')}
                type='submit'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default NewCustomField;
