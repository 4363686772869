import { CurrencyCodes } from '@onevesthq/ov-enums';
import { flatten, uniq } from 'lodash/fp';
import CAD from '../assets/images/country-flags/Canada.svg';
import USD from '../assets/images/country-flags/US.svg';
import CNY from '../assets/images/country-flags/China.svg';
import EUR from '../assets/images/country-flags/Europe.svg';
import GBP from '../assets/images/country-flags/UK.svg';
import JPY from '../assets/images/country-flags/Japan.svg';

export const currencyFlags = {
  CAD,
  USD,
  CNY,
  EUR,
  GBP,
  JPY,
};

export const currencySymbol: { [key in CurrencyCodes ]?: string } = {
  CAD: '$',
  USD: '$',
  CNY: '￥',
  EUR: '€',
  GBP: '£',
  JPY: '￥',
};

export const getCurrencyFlag = (currencyCode: CurrencyCodes): string | undefined => {
  const flags = Object.keys(currencyFlags);
  if (flags.includes(currencyCode)) return currencyFlags[currencyCode as keyof typeof currencyFlags];
  return undefined;
};

export const isCurrencyCode = (currencyCode: any): currencyCode is CurrencyCodes => {
  const currency = CurrencyCodes[currencyCode as keyof typeof CurrencyCodes];
  return currency !== undefined;
};

export const hasCurrencyFlag = (currency: string) => Object.keys(currencyFlags).includes(currency);

/**
 * Combines and deduplicates currencies from base currency, user currencies and household currencies
 */
export const processAvailableCurrencies = (
  baseCurrency: string,
  currencies: string[] = [],
  households: Array<{ availableCurrencies?: string[] }> = [],
): string[] => {
  const householdCurrencies = flatten(
    households
      .filter((household) => Array.isArray(household?.availableCurrencies))
      .map((household) => household.availableCurrencies ?? []),
  );

  const allCurrencies = [
    baseCurrency,
    ...currencies,
    ...householdCurrencies,
  ].filter((currency): currency is string => typeof currency === 'string' && currency.length > 0);

  return uniq(allCurrencies).sort();
};

/**
 * Combines and deduplicates currencies from base currency, client group and related users
 */
export const processClientGroupCurrencies = (
  baseCurrency: string,
  clientGroup: {
    availableCurrencies?: string[],
    relationships?: Array<{
      user?: {
        availableCurrencies?: string[]
      }
    }>
  } = {},
): string[] => {
  const clientGroupCurrencies = [
    ...(Array.isArray(clientGroup?.availableCurrencies) ? clientGroup.availableCurrencies : []),
    ...flatten(
      (clientGroup?.relationships ?? [])
        .map((rel) => {
          if (!rel?.user) return [];
          return Array.isArray(rel.user.availableCurrencies) ? rel.user.availableCurrencies : [];
        }),
    ),
  ];

  return uniq([
    baseCurrency,
    ...clientGroupCurrencies,
  ]).filter((currency): currency is string => typeof currency === 'string' && currency.length > 0).sort();
};
