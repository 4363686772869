import { gql, useLazyQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../providers/userContextProvider';
import { ClientGroup } from '../../interfaces';
import { FulltextSearchSelect, SearchResults } from './fulltextSearchSelect';

const MAX_SEARCH_RESULTS = 12;

export const SEARCH_CLIENT_GROUP = gql`
  query searchClientGroups($searchText: String!, $organizationId: ObjectID!) {
    fetchClientGroups(input: {
      filter: { searchText: $searchText, organizationId: $organizationId, type: HOUSEHOLD }
      pagination: { perPage: ${MAX_SEARCH_RESULTS} },
    }) {
      totalCount
      clientGroups {
        id
        name
        primaryUser { firstName middleName lastName }
      }
    }
  }
`;

export const HouseholdSelect = ({
  clientGroup, setClientGroup, label, autoFocus, fullWidth,
}: {
  clientGroup?: ClientGroup, setClientGroup: (clientGroup: ClientGroup | undefined) => void, label: string, autoFocus?: boolean,
  fullWidth?: boolean
}) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation();

  const [searchUsers] = useLazyQuery(SEARCH_CLIENT_GROUP, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const searchFn = (query:string): Promise<SearchResults> => new Promise<SearchResults>((resolve) => {
    searchUsers({
      variables: {
        searchText: query,
        organizationId: activeOrganization.id,
      },
      onCompleted: (data) => {
        resolve({
          items: data.fetchClientGroups.clientGroups,
          count: Number(data.fetchClientGroups.totalCount),
        });
      },
      onError: () => {
        resolve({
          items: [],
          count: 0,
        });
      },
    });
  });

  return (
    <FulltextSearchSelect
      label={t('components:household')}
      item={clientGroup}
      setItem={(cg) => setClientGroup(cg as ClientGroup)}
      searchFn={searchFn}
      primaryLabelFn={(x) => x.name}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
    />
  );
};
