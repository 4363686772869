import {
  Account,
  Affiliation,
  AffiliationRelations,
  AffiliationTypes,
  DraftAccount,
} from 'interfaces';

type AccountType = Account | DraftAccount;

export interface IncompleteAffiliation {
  account: Account,
  affiliation: Affiliation,
  incompleteFields: string[],
}

export const getJointAffiliate = (account: AccountType): Affiliation | undefined => {
  const affiliate = account.affiliations?.find((item) => item.type === AffiliationTypes.Joint);
  return affiliate;
};

/**
 * Function which modifies the membership of an entry in a list of affiliations.
 *   - If an affiliation exists in the list with the same user, all other field values
 *     of that affiliation is updated with those of the entry
 *   - Otherwise, a new affiliation is appended to the list
 */
export const upsertAffiliation = (entry: Affiliation, affiliations: Affiliation[]): Affiliation[] => {
  const checkEquality = (affiliation1: Affiliation, affiliation2: Affiliation): boolean => (
    !!(affiliation1?.user?.id && affiliation1?.user?.id === affiliation2?.user?.id)
  );
  if (!entry?.user?.id) return affiliations;
  const userExists = affiliations.some((affiliation) => checkEquality(entry, affiliation));

  if (!userExists) return [...affiliations, entry];
  return affiliations.map((affiliation) => (checkEquality(entry, affiliation) ? entry : affiliation));
};

// The relationships to be shown first should be lower in
// the list (higher index, higher rank) because any
// relationships not in the list will be undefined, and
// will naturally rank lower in the sorting function
export const sortedAffiliationRelations = [
  AffiliationRelations.PrimaryCaregiver,
  AffiliationRelations.Guardian,
  AffiliationRelations.CommonLaw,
  AffiliationRelations.Spouse,
];

export const getSortedAffiliations = (affiliations: Affiliation[], field: keyof Affiliation, orderedValues: string[]) => {
  const sortingFn = (aff1: Affiliation, aff2: Affiliation): number => {
    const aff1Rank = orderedValues.indexOf(aff1[field] as string);
    const aff2Rank = orderedValues.indexOf(aff2[field] as string);
    return aff1Rank < aff2Rank ? 1 : aff1Rank > aff2Rank ? -1 : 0;
  };

  return affiliations.sort(sortingFn);
};
