import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../util/useLocalization';

const FETCH_REBALANCE_TIMESTAMP = gql`
  query fetchBulkTraderTimestamp($bulkTraderId: ObjectID!) {
    fetchBulkTradeRun(bulkTradeRunId: $bulkTraderId) {
      bulkTradeRun {
        createdAt
      }
    }
  }
`;

export function BulkTraderBreadcrumb() {
  const { data } = useQuery(FETCH_REBALANCE_TIMESTAMP, { variables: { bulkTraderId: useParams().id } });
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();

  return `${t('bulkTrader:bulkTraderOn')} ${data ? localizedDateTime(data.fetchBulkTradeRun?.bulkTradeRun.createdAt) : ''}`;
}
