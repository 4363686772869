import { SxProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { entityName } from '../../../../util';
import { Box, Skeleton, Typography } from '../../../1-primative';
import {
  Card, IconButton, Table,
  TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { usePermissions } from '../../../../providers/userContextProvider';
import { OrganizationUser, User } from '../../../../interfaces';
import { GrantAccessToEntityButton } from './components/grantAccessToEntityButton';
import { ConfirmationModal } from '../../../3-pattern';

export interface AccessToEntityOptions {
  showTitle?: boolean,
  editAccessEnabled?: boolean,
}

export const AccessToEntityVisual = ({
  entity,
  options = {},
  loading,
  sx,
  addEntityToOrganizationUser,
  removeEntityFromOrganizationUser,
  searchUsers,
}: {
  entity?: User,
  options?: AccessToEntityOptions,
  showTitle?: boolean,
  loading: boolean,
  sx?: SxProps,
  addEntityToOrganizationUser: (options: any) => void,
  removeEntityFromOrganizationUser: (options: any) => void,
  searchUsers: (options: any) => void,
}) => {
  const { t } = useTranslation(['components']);
  const [activeOrganizationUser, setActiveOrganizationUser] = useState<OrganizationUser>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { permissions } = usePermissions();

  const canWrite = options.editAccessEnabled && permissions.includes('write:organization_user_entities');
  const showHeader = canWrite || options.showTitle;

  const removeAccess = async () => {
    removeEntityFromOrganizationUser({
      variables: {
        input: {
          entityId: entity?.id,
          organizationUserId: activeOrganizationUser?.id,
        },
      },
    });
    setShowConfirmationModal(false);
    setActiveOrganizationUser(undefined);
  };

  const onDelete = (organizationUser?: OrganizationUser) => {
    setActiveOrganizationUser(organizationUser);
    setShowConfirmationModal(true);
  };

  return (
    <Box sx={{ p: 2, ...(sx ?? {}) }}>
      {showHeader && (
        <Box display="flex" justifyContent="space-between" marginBottom="9px" minHeight='40px'>
          <Typography variant='headingSmall' mb={1}>{options.showTitle && t('components:accessToEntity.title')}</Typography>
          {canWrite && (<GrantAccessToEntityButton entity={entity} searchUsers={searchUsers} addEntityToOrganizationUser={addEntityToOrganizationUser} />)}
        </Box>
      )}
      <Card sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow sx={{ height: '40px' }}>
              <TableHeadCell>{t('components:accessToEntity.table.name')}</TableHeadCell>
              <TableHeadCell>{t('components:accessToEntity.table.email')}</TableHeadCell>
              <TableHeadCell>{t('components:accessToEntity.table.relation')}</TableHeadCell>
              {canWrite && (<TableHeadCell></TableHeadCell>)}
            </TableRow>
            { loading ? (
              <>
                {[...Array(5)].map((x, i) => (
                  <TableRow key={i}>
                    <TableCell><Skeleton width='100%' height='16px' /></TableCell>
                    <TableCell><Skeleton width='100%' height='16px' /></TableCell>
                    <TableCell><Skeleton width='100%' height='16px' /></TableCell>
                    <TableCell><Skeleton width='100%' height='16px' /></TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'left', p: 2 }}>{t('components:accessToEntity.oraganizationAccessRow', { org: entity?.organization?.name })}</TableCell>
                </TableRow>
                {entity?.accessibleBy && entity?.accessibleBy.map((access) => (
                  <TableRow key={access.organizationUser?.id} hover>
                    <TableCell>{access.organizationUser?.firstName} {access.organizationUser?.lastName}</TableCell>
                    <TableCell>{access.organizationUser?.email}</TableCell>
                    <TableCell>{t(`components:accessToEntity.relations.${access.relation}`)}</TableCell>
                    {canWrite && (
                      <TableCell align='right' sx={{ maxWidth: 10 }}>
                        <IconButton onClick={() => onDelete(access?.organizationUser)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </Card>
      {showConfirmationModal && (
        <ConfirmationModal
          title={t('components:accessToEntity.confirmationModal.title')}
          bodyText={t('components:accessToEntity.confirmationModal.bodyText', {
            entityName: entityName(entity),
            orgUserName: `${activeOrganizationUser?.firstName} ${activeOrganizationUser?.lastName}`,
          })}
          open={showConfirmationModal}
          onConfirm={removeAccess}
          onCancel={() => setShowConfirmationModal(false)}
          showCancelButton={true}
        />
      )}
    </Box>
  );
};
