import {
  Typography, TextField, MenuItem, Box,
} from '@mui/material';
import themeIcons from '../../assets/images/themes';

const ThemeIconSelect = ({
  onChange, value, label, disabled = false, readonly = false,
}: {
  onChange: (event: any) => void, value: string, label: string, disabled?: boolean, readonly?: boolean
}) => (
    <>
      <TextField
        select
        value={value || 'DEFAULT'}
        label={label}
        fullWidth
        onChange={onChange}
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
        }}
      >
        {
          themeIcons.map((x: any) => (
            <MenuItem key={x.key} value={x.key}>
              <Box sx={{ display: 'flex' }}>
                <img alt={x.key} key={`${x.key}_${x.icon}`} src={x.icon} height="35" />
                <Typography pl={'10px'} pt={'5px'} fontSize="small">{x.key}</Typography>
              </Box>
            </MenuItem>
          ))
        }
      </TextField>
    </>
);

export default ThemeIconSelect;
