import {
  Box, Typography, Grid, CircularProgress, Card, CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import EditModel from './components/editModel';
import ThemeModels from './components/themeModels';
import NewTheme from './components/newTheme';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Theme } from '../../interfaces';
import { usePageState } from '../../util/usePageState';

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        key
        translatedName {
          en
        }
        translatedShortDescription {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        core
        iconKey
        state
      }
    }
  }
`;

const BuildModels = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('buildModels');
  const params = useParams();
  const financialProductId = params.id;
  const [expanded, setExpanded] = usePageState<string | undefined>(undefined, 'theme');

  const { loading, data, refetch } = useQuery(FETCH_THEMES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          perPage: 25,
          sortField: 'createdAt',
          sortDesc: false,
        },
      },
    },
  });

  const handleChange = (panel: string) => {
    setExpanded(expanded === panel ? undefined : panel);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          { loading ? <CircularProgress /> : data.fetchThemes.themes.map((theme: Theme) => (
            <ThemeModels key={theme.id} theme={theme} expanded={expanded} handleChange={handleChange} refetchThemes={refetch} />
          ))}
          { permissions.includes('write:themes') && (
            <NewTheme afterCreate={refetch} />
          )}
        </Grid>
        <Grid item md={8}>
          { !financialProductId ? (
            <Card>
              <CardContent>
                <Typography variant='h5'>{t('selectModel')}</Typography>
              </CardContent>
            </Card>
          ) : (<EditModel modelId={financialProductId} />)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuildModels;
export { PortfolioModelBreadcrumb } from './portfolioModelBreadcrumb';
