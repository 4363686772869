import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IncurredFeesTable from './components/incurredFeesTable';

const IncurredFees = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%' }}>
      <IncurredFeesTable />
    </Paper>
  );
};

export default IncurredFees;
