import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { isNull } from 'lodash/fp';
import { useMutation } from '@apollo/client';
import { Box, Typography } from '../../../../1-primative';
import {
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../../2-component';
import { CopyId, StateStepper } from '../../../../3-pattern';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatMoneyValue } from '../../../../../util';
import { translateBackend } from '../../../../../assets/i18n/config';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { TRANSITION_TRADE } from '../tradeOrders.queries';

enum TradeStates {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  RECONCILED = 'RECONCILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export const TradeModal = ({
  trade, setOpen, open, onTransition,
}: {
  trade: any, setOpen: any, open: any, onTransition: () => void,
}) => {
  const { t } = useTranslation(['components', 'shared', 'transfer']);
  const [activeStep, setActiveStep] = useState(0);
  const { sys } = useThemeTokens();
  const { permissions } = usePermissions();
  const [transition] = useMutation(TRANSITION_TRADE, {
    onCompleted: onTransition,
  });

  const coreStates = [
    {
      name: TradeStates.INITIATED,
    },
    {
      name: TradeStates.PROCESSING,
    },
    {
      name: TradeStates.RECONCILED,
    },
  ];

  const activeStepFinder = (state: string) => {
    switch (state) {
      case TradeStates.INITIATED:
        return 0;
      case TradeStates.PROCESSING:
        return 1;
      case TradeStates.RECONCILED:
        return 2;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (trade && trade.stateChanges) {
      let step = activeStepFinder(trade.state);
      if (isNull(step)) {
        step = activeStepFinder(trade.stateChanges.slice(-1)[0].from);
      }
      setActiveStep(step || 0);
    }
  }, [trade]);

  const transitions = [
    {
      name: 'process',
      from: [TradeStates.INITIATED],
      error: false,
    },
    {
      name: 'reconcile',
      from: [TradeStates.PROCESSING],
      error: false,
    },
    {
      name: 'fail',
      from: [TradeStates.PROCESSING, TradeStates.INITIATED],
      error: true,
    },
    {
      name: 'forceCancel',
      from: [TradeStates.INITIATED, TradeStates.PROCESSING],
      error: true,
    },
    {
      name: 'cancelReconciled',
      from: [TradeStates.RECONCILED],
      error: true,
    },
  ];

  if (!trade?.id || !trade.state) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {t('components:trades.tradeDetails')}
          <Box display='flex' justifyContent='end' alignItems='center'>
            <CopyId id={trade.id} />
            <Box ml={1} />
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant='bodyMedium' weight='bold'>{translateBackend(trade.financialProduct.translatedName)}</Typography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' alignItems='center'>
              <Typography variant='displayMedium'>{formatMoneyValue(trade.actualTradePriceCents * trade.actualQuantity)}</Typography>
              <Box sx={{
                background: sys.color.background, borderRadius: sys.borderRadius.round, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '32px', height: '32px',
              }}>
                {
                  trade.type === 'BUY' ? (
                    <ArrowDownwardIcon sx={{ color: sys.color.onSurfaceVariant }} />
                  ) : (
                    <ArrowUpwardIcon sx={{ color: sys.color.onSurfaceVariant }} />
                  )
                }
              </Box>
            </Box>
            <Typography variant='bodySmall' colorVariant='variant'>{t(`transaction.types.${trade.type}`)}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <Box display='flex' justifyContent='space-between' minWidth='170px'>
              <Typography variant='bodySmall'>{t('components:trades.symbol')}</Typography>
              <Typography variant='bodySmall' weight='bold'>{trade.financialProduct.ticker}</Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' minWidth='170px'>
              <Typography variant='bodySmall'>{trade.actualQuantity ? t('components:trades.quantity') : t('components:trades.estimatedQuantity')}</Typography>
              <Typography variant='bodySmall' weight='bold'>{trade.actualQuantity || trade.expectedQuantity}</Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' minWidth='170px'>
              <Typography variant='bodySmall'>{trade.actualTradePriceCents ? t('components:trades.price') : t('components:trades.estimatedPrice')}</Typography>
              <Typography variant='bodySmall' weight='bold'>{formatMoneyValue(trade.actualTradePriceCents || trade.expectedTradePriceCents)}</Typography>
            </Box>
          </Box>
        </Box>
        <Box borderTop={`1px solid ${sys.color.outlineVariant}`} pt={2} mt={2}>
          <Typography variant='bodyMedium' weight='bold' sx={{ mb: 2 }}>{t('components:trades.tradeProgress')}</Typography>
          <StateStepper
            steps={coreStates}
            failed={[TradeStates.CANCELED, TradeStates.FAILED].includes(trade.state)}
            activeStep={activeStep}
            currentState={trade.state}
            stateChanges={[{ to: TradeStates.INITIATED, createdAt: trade.createdAt }, ...trade.stateChanges]}
          />
        </Box>
      </DialogContent>
      {
        permissions.includes('transition:trade') && transitions.filter((tr) => tr.from.includes(trade.state)).length > 0 && (
          <DialogFooter>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Box display='flex'>
                {transitions.filter((tr) => tr.from.includes(trade.state) && tr.error).map((tr) => (
                  <Button key={tr.name} variant='tonal' color='destructive' label={t(`tradeTransitions.${tr.name}`)} onClick={() => transition({
                    variables: {
                      input: {
                        tradeId: trade.id,
                        transition: tr.name,
                      },
                    },
                  })} sx={{ mr: 1 }} />
                ))}
              </Box>
              <Box display='flex'>
                {transitions.filter((tr) => tr.from.includes(trade.state) && !tr.error).map((tr) => (
                  <Button key={tr.name} label={t(`tradeTransitions.${tr.name}`)} onClick={() => transition({
                    variables: {
                      input: {
                        tradeId: trade.id,
                        transition: tr.name,
                      },
                    },
                  })} sx={{ ml: 1 }} />
                ))}
              </Box>
            </Box>
          </DialogFooter>
        )
      }
    </Dialog>
  );
};
