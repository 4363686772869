import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box } from '../../1-primative';
import { useStatusBarTokens } from './statusBar.tokens';

interface StatusBarProps {
  percentage?: number,
  testId?: string,
  children?: React.ReactNode,
  onClick?: () => void,
}

export const StatusBar = ({
  percentage = 0, children, onClick, testId,
}: StatusBarProps) => {
  const tokens = useThemeTokens(useStatusBarTokens());
  const { comp } = tokens;

  return (
    <Box
      data-testid={testId}
      sx={{
        width: '100%',
        height: '48px',
        cursor: onClick !== undefined ? 'pointer' : 'default',
        borderRadius: comp.statusBar.borderRadius,
        border: `1px solid ${comp.statusBar.borderColor}`,
        boxShadow: comp.statusBar.elevation,
        backgroundColor: comp.statusBar.containerColor,
        overflow: 'hidden',
      }}
      onClick={onClick}
      display='flex'
      flexDirection='column'
    >
      <Box
        sx={{
          width: `${percentage}%`,
          height: '4px',
          backgroundColor: comp.statusBar.activeColor,
          transition: 'width 0.5s',
          position: 'relative',
          borderRadius: `${comp.statusBar.borderRadius} 0px 0px 0px`,
        }}
      />
      <Box display='flex' flex='1' pl={2} pr={2} alignItems='center'>
        {children}
      </Box>
    </Box>
  );
};
