import { Grid } from '@mui/material';
import CollapseHoldings from 'components/layout/client/collapseHoldings';
import { usePermissions } from 'providers/userContextProvider';
import { useTranslation } from 'react-i18next';
import AccountsSummary from '../../../components/layout/accounts/AccountsSummary';
import AssetsOverview from '../../../components/layout/client/assetsOverview';
import { ClientGroup } from '../../../interfaces';
import { ObjectType } from '../../../providers/statsHooks';
import NewCorporateAccount from './newCorporateAccount';

const CorporationOverview = ({ clientGroup }: { clientGroup: ClientGroup }) => {
  const { t } = useTranslation('corporations');
  const { permissions } = usePermissions();
  const newCorporateAccount = <NewCorporateAccount clientGroup={clientGroup} />;

  const showAccounts = permissions.includes('read:account_basic');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AssetsOverview id={clientGroup.id} type={ObjectType.CLIENT_GROUP} title={t('overview.totalCorporationAssets')} />
      </Grid>
      <Grid item xs={12}>
        <CollapseHoldings type={ObjectType.CLIENT_GROUP} id={clientGroup.id} />
      </Grid>
      { showAccounts
         && <Grid item md={12} xs={12}>
           <AccountsSummary objectType={ObjectType.CLIENT_GROUP} objectId={clientGroup.id} newAccountComponent={newCorporateAccount}/>
        </Grid>
        }
    </Grid>
  );
};

export default CorporationOverview;
