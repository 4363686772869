import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { useNavigate } from 'react-router-dom';
import {
  Box, Skeleton, Typography,
} from '../../../1-primative';
import { FETCH_MEMBERS } from './householdMembers.queries';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { Avatar, Card, CardContent } from '../../../2-component';
import { formatMoneyValue } from '../../../../util';
import { UserContext } from '../../../../providers/userContextProvider';
import { currencySymbol } from '../../../../util/currency';
import { NavigationStyles, OrganizationUserAccessTypes } from '../../../../interfaces';

const BASE_TYPES = [
  'CHILD',
  'COMMON_LAW',
  'GRANDCHILD',
  'GRANDPARENT',
  'GUARDIAN',
  'NEPHEW_NIECE',
  'OTHER',
  'PARENT',
  'PRIMARY_CAREGIVER',
  'SIBLING',
  'SPOUSE',
];

export const HouseholdMembers = ({ objectId, useCustodianData, options }: { objectId: string, useCustodianData?: boolean, options?: any }) => {
  const { ref } = useThemeTokens();
  const navigate = useNavigate();
  const {
    userContext, setActiveEntity, activeCurrency,
  } = useContext(UserContext);
  const { t } = useTranslation('affiliationTypes');
  const { loading, data } = useQuery(FETCH_MEMBERS(useCustodianData, activeCurrency), {
    variables: {
      clientGroupId: objectId,
      currency: activeCurrency,
    },
    fetchPolicy: 'no-cache',
  });

  const color = [
    { bgColor: ref.palette.supportOne40, color: ref.palette.supportOne60 },
    { bgColor: ref.palette.supportTwo40, color: ref.palette.supportTwo60 },
    { bgColor: ref.palette.supportThree40, color: ref.palette.supportThree60 },
    { bgColor: ref.palette.supportFour40, color: ref.palette.supportFour60 },
    { bgColor: ref.palette.supportFive40, color: ref.palette.supportFive60 },
    { bgColor: ref.palette.supportSix40, color: ref.palette.supportSix60 },
  ];

  const getHouseholdValidEntity = (userId: any) => {
    const entities = userContext?.entities?.filter((x) => !x.entity.affiliateOnly);
    const validEntity = entities?.find((elem) => elem.entity.id === userId);
    const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && (validEntity?.entity.households?.length ?? 0) > 0;
    if (isHouseholdDefaultView && validEntity) {
      return validEntity;
    }
    return undefined;
  };

  if (loading) {
    return (
      <Skeleton width='100%' height='80px' variant='rectangular'></Skeleton>
    );
  }

  const getAvatarChar = (user: any) => {
    if (user.entityName) {
      return user.entityName[0];
    }
    if (user.firstName) {
      return user.firstName[0];
    }
    if (user.lastName) {
      return user.lastName[0];
    }
    return '-';
  };

  return (
    <>
      {(data?.fetchClientGroup?.clientGroup?.relationships ?? []).map((member: any, index: number) => (
        <Card
          key={index}
          sx={{ mb: 2, cursor: userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION || getHouseholdValidEntity(member.user.id) ? 'pointer' : null }}
          onClick={() => {
            if (userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION) {
              if (userContext.role?.makeHouseholdDefaultView && userContext.role.navigationStyle === NavigationStyles.SIMPLE) {
                navigate(`/households/${objectId}/clients/${member.user.id}`);
              } else {
                navigate(`/clients/${member.user.id}`);
              }
            } else {
              const validEntity = getHouseholdValidEntity(member.user.id);
              if (validEntity) {
                if (validEntity?.entity.households) {
                  navigate(`/households/${objectId}/clients/${member.user.id}`);
                  setActiveEntity(validEntity.entity);
                }
              }
            }
          }}
        >
          <CardContent sx={{ paddingBottom: '16px !important' }}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems={options?.hideHouseholdMemberTypes ? 'center' : 'top'}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Avatar size='large' bgColor={color[index % 6].bgColor} fgColor={color[index % 6].color}>{getAvatarChar(member.user)}</Avatar>
                <Box ml={1}>
                  <Typography variant='bodyLarge' weight='bold'>{member.user.entityName ? member.user.entityName : `${member.user.firstName} ${member.user.lastName}`}</Typography>
                  {!options?.hideHouseholdMemberTypes && (
                    <Typography variant='bodyMedium'>{BASE_TYPES.includes(member.type) ? t(`affiliationRelationship.${member.type}`) : member.type}</Typography>
                  )}
                </Box>
              </Box>
              <Typography variant='bodyLarge' weight='bold'>
                {useCustodianData
                  ? formatMoneyValue(member.user.custodianStatistics?.marketValueCents, currencySymbol[activeCurrency as CurrencyCodes ?? CurrencyCodes.CAD])
                  : formatMoneyValue(member.user.statistics?.marketValueCents, currencySymbol[activeCurrency as CurrencyCodes ?? CurrencyCodes.CAD])}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
