import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Box } from '../../1-primative';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
  Button, Checkbox, Tooltip,
} from '../../2-component';
import { Column } from './downloadButton';

export interface Configuration {
  selectedColumns: Column[];
  useAdditionalFilter: boolean;
}

const ConfigurationModal = ({
  open,
  columns,
  additionalColumns,
  onClose,
  onExport,
}: {
  open: boolean;
  columns: Column[];
  additionalColumns?: Column[];
  onClose: () => void;
  onExport: (configuration: Configuration) => void;
}) => {
  const { t } = useTranslation('components');
  const [selectedColumns, setSelectedColumns] = useState<number[]>(Object.keys(columns).map((key) => Number(key)));
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState<number[]>([]);
  const [useAdditionalFilter, setUseAdditionalFilter] = useState(false);

  const getSelectedColumns = () => {
    const mainColumnsArr = selectedColumns.sort((a, b) => a - b).map((index) => columns[index]);
    const additionalColumnsArr = additionalColumns?.length ? selectedAdditionalColumns.sort((a, b) => a - b).map((index) => additionalColumns[index]) : [];

    return [...mainColumnsArr, ...additionalColumnsArr];
  };

  const onModalClose = () => {
    setSelectedColumns(Object.keys(columns).map((key) => Number(key)));
    setSelectedAdditionalColumns([]);
    setUseAdditionalFilter(false);

    onClose();
  };

  const onExportLocal = () => {
    onExport({
      selectedColumns: getSelectedColumns(),
      useAdditionalFilter,
    });
    onModalClose();
  };

  const onToggle = (index: number, array: number[], setter: (array: number[]) => void) => () => {
    const currentIndex = array.indexOf(index);
    const newChecked = [...array];

    currentIndex === -1 ? newChecked.push(index) : newChecked.splice(currentIndex, 1);

    setter(newChecked);
  };

  const onSelectAllMainColumns = (value: boolean) => {
    value ? setSelectedColumns(Object.keys(columns).map((key) => Number(key))) : setSelectedColumns([]);
  };

  const onSelectAllAdditionalColumns = (value: boolean) => {
    value ? setSelectedAdditionalColumns(Object.keys(additionalColumns ?? []).map((key) => Number(key))) : setSelectedAdditionalColumns([]);
  };

  return (
    <Dialog onClose={onModalClose} open={open} fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {t('exportConfiguration.title')}
          <IconButton onClick={onModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {!!additionalColumns?.length && <Checkbox onChange={(e) => setUseAdditionalFilter(e)} label={t('exportConfiguration.useAdditionalFilter')} />}

        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Tooltip title={t('exportConfiguration.selectAllColumns')} placement='top'>
              <Checkbox checked onChange={(e) => onSelectAllMainColumns(e)} label={t('exportConfiguration.mainColumns')} />
            </Tooltip>
            <Box ml={1}>
              {columns.map((value, index) => (
                <Checkbox key={index} checked={selectedColumns.indexOf(index) !== -1} label={value.header} onChange={onToggle(index, selectedColumns, setSelectedColumns)} />
              ))}
            </Box>
          </Box>
          {!!additionalColumns?.length && (
            <Box>
              <Tooltip title={t('exportConfiguration.selectAllColumns')} placement='top'>
                <Checkbox onChange={(e) => onSelectAllAdditionalColumns(e)} label={t('exportConfiguration.additionalColumns')} />
              </Tooltip>

              <Box ml={1}>
                {additionalColumns?.map((value, index) => (
                  <Checkbox checked={selectedAdditionalColumns.indexOf(index) !== -1} onChange={onToggle(index, selectedAdditionalColumns, setSelectedAdditionalColumns)} label={value.header} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button data-testid='confirmButton' type='submit' onClick={onExportLocal} label={t('exportConfiguration.exportButton')} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ConfigurationModal;
