import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { FETCH_ACCOUNTS } from 'components/layout/accounts/AccountsSummary';
import { FETCH_GOALS } from 'components/layout/goals/goalsSummary';
import { StepProps } from '../wizard';
import { usePermissions } from '../../../../providers/userContextProvider';
import { SubAccountSummaryActionStepResult } from './subAccountSummaryActionStepResult';
import { FETCH_SUB_ACCOUNTS } from '../../../cards/subAccounts';
import { SUB_ACCOUNT_STATS } from '../../../../providers/globalStatsHooks';
import { FETCH_ACCOUNT } from './createSubAccountStep';

const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        goal {
          id
          name
          financialProduct {
            id
            translatedName {
              en
            }
            theme {
              id
              translatedName {
                en
              }
            }
          }
        }
        skipIPS
        allowClientDeposits
        isPartial
        theme {
          id
          translatedName {
            en
          }
        }
        financialProduct {
          id
          translatedName {
            en
          }
        }
      }
    }
  }
`;

const FETCH_ACCOUNT_OWNER = gql`
  query fetchAccountOwner($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        user {
          id
        }
      }
    }
  }
`;

export function SubAccountSummaryActionStep({
  context, state, continueTo, mutationEvent,
}: StepProps) {
  const { permissions } = usePermissions();
  const [userId, setUserId] = useState();

  /* lookup account type */
  const accountQuery = useQuery(FETCH_ACCOUNT, { variables: { id: state.accountId }, skip: !state.accountId });

  /* lookup userId by account */
  useQuery(FETCH_ACCOUNT_OWNER, {
    skip: !state.accountId,
    variables: { id: state.accountId },
    onCompleted: (data) => {
      setUserId(data.fetchAccount.account.user.id);
    },
  });

  const [createSubAccount, {
    loading, called, data, error,
  }] = useMutation(CREATE_SUB_ACCOUNT, {
    refetchQueries: [FETCH_SUB_ACCOUNTS(permissions), SUB_ACCOUNT_STATS, FETCH_ACCOUNTS(permissions), FETCH_GOALS(), FETCH_GOALS(userId)],
  });

  if (!called && userId !== undefined && accountQuery.data?.fetchAccount.account.type) {
    mutationEvent('started');
    createSubAccount({
      variables: {
        input: {
          userId,
          accountType: accountQuery.data.fetchAccount.account.type,
          goalId: state.goalId,
          accountId: state.accountId ?? undefined,
          themeId: state.isPortfolioGoalAttached ? undefined : state.themeId,
          financialProductId: state.isPortfolioGoalAttached ? undefined : state.financialProductId,
          skipIPS: state.skipIPS,
          isPartial: state.isPartial,
          allowClientDeposits: state.allowClientDeposits,
        },
      },
      onCompleted: () => mutationEvent('succeeded'),
      onError: () => mutationEvent('failed'),
    });
  }

  return (
    <SubAccountSummaryActionStepResult
      state={state}
      context={context}
      continueTo={continueTo}
      loading={loading}
      succeeded={!!data}
      error={error}
      newSubAccount={data?.createSubAccount?.subAccount}
    />
  );
}
