import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const FETCH_ACCOUNT_NAME = gql`
  query fetchAccount($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account { type }
    }
  }
`;

export function AccountBreadcrumb() {
  const { t } = useTranslation();
  const { data } = useQuery(FETCH_ACCOUNT_NAME, { variables: { id: useParams().accountId } });

  return data ? t(`accountTypes:${data.fetchAccount.account.type}`) : t('client:account');
}
