import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { translateBackend } from 'assets/i18n/config';
import { MenuItem, SelectField } from 'ovComponents/2-component';
import { WorkflowStates } from '../../../interfaces';
import { FETCH_WORKFLOWS } from '../../../components/layout/client/clientSummary';
import { UserContext } from '../../../providers/userContextProvider';

export const WorkflowSelectFieldEdit = ({
  option,
  options,
  setOptions,
  i,
}: {
  option: any;
  options: any;
  setOptions: (x: any) => void;
  i: number;
}) => {
  const { activeOrganization } = useContext(UserContext);
  const [opts, setOpts] = useState([]);
  const { data } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          state: WorkflowStates.ACTIVE,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const workflows = data.fetchWorkflows.workflows.map((workflow: any) => ({ label: workflow.name, value: workflow.id }));
      setOpts(workflows);
    }
  }, [data, option]);

  return (
    <SelectField
      label={translateBackend(option?.label)}
      value={option?.value}
      sx={{ mb: 2 }}
      fullWidth
      onChange={(e: any) => {
        const newOptions = [...options];
        newOptions[i] = { ...option, value: e.target.value };
        setOptions(newOptions);
      }}
    >
      {[...option.options, ...opts].map((item: any, idx: number) => (
        <MenuItem key={idx} value={item.value}>
          {translateBackend(item.label)}
        </MenuItem>
      ))}
    </SelectField>
  );
};
