import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit, Delete, MoreVert } from '@mui/icons-material';
import { BankAccount } from '../../../interfaces';
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from '../../2-component';
import { Box, Grid, Typography } from '../../1-primative';
import tdBank from '../../../assets/images/bank-logo/004_TD.png';
import bmoBank from '../../../assets/images/bank-logo/001_BMO.png';
import scotiaBank from '../../../assets/images/bank-logo/002_Scotiabank.png';
import rbcBank from '../../../assets/images/bank-logo/003_RBC.jpg';
import nationalBankOfCanada from '../../../assets/images/bank-logo/006_National_Bank_of_Canada.png';
import cibc from '../../../assets/images/bank-logo/010_CIBC.png';
import hsbcBank from '../../../assets/images/bank-logo/016_HSBC_Bank_Canada.jpg';
import canadaWesternTrustBank from '../../../assets/images/bank-logo/030_Canadian_Western_Trust_Company.jpg';
import LauentianBank from '../../../assets/images/bank-logo/039_Laurentian_Bank_Of_Canada.jpg';
import atbBank from '../../../assets/images/bank-logo/219_ATB_Financial.jpg';
import manulifeBank from '../../../assets/images/bank-logo/540_Manulife_Bank_of_Canada.png';
import eqLifeBank from '../../../assets/images/bank-logo/623_EQ_Bank.png';
import tangerineBank from '../../../assets/images/bank-logo/612_Tangerine.png';
import flinksBank from '../../../assets/images/bank-logo/777_Flinks.png';
import coastCapitalBank from '../../../assets/images/bank-logo/809_Coast_Capital.png';
import desjardinsBank from '../../../assets/images/bank-logo/815_Desjardins.png';
import meridianBank from '../../../assets/images/bank-logo/837_Meridian.png';
import defaultBank from '../../../assets/images/bank-logo/000_default.png';
import { CancelButtonColors, ConfirmButtonColors, ConfirmationModal } from '../../3-pattern/confirmationDialog/confirmationDialog';

const BankAccountCard = ({
  bankAccount,
  onEdit,
  onRemove,
  options,
}: {
  bankAccount: BankAccount,
  onEdit: (newName: string) => void,
  onRemove: () => void,
  options: any,
}) => {
  const { t } = useTranslation(['client', 'workflowCompletions']);
  const [showDeleteBankAccount, setShowDeleteBankAccount] = useState(false);
  const [showEditBankAccount, setShowEditBankAccount] = useState(false);
  const [bankAccountName, setBankAccountName] = useState(bankAccount?.name ?? '');
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);

  const generateIcon = (institutionNumber: string) => {
    const bankInfo = {
      icon: defaultBank,
      name: 'defaultBank',
    };
    switch (institutionNumber) {
      case '001':
        bankInfo.icon = bmoBank;
        bankInfo.name = 'bmoBank';
        break;

      case '002':
        bankInfo.icon = scotiaBank;
        bankInfo.name = 'scotiaBank';
        break;

      case '003':
        bankInfo.icon = rbcBank;
        bankInfo.name = 'rbcBank';
        break;

      case '004':
        bankInfo.icon = tdBank;
        bankInfo.name = 'tdBank';
        break;

      case '006':
        bankInfo.icon = nationalBankOfCanada;
        bankInfo.name = 'nationalBankOfCanada';
        break;

      case '010':
        bankInfo.icon = cibc;
        bankInfo.name = 'cibc';
        break;

      case '016':
        bankInfo.icon = hsbcBank;
        bankInfo.name = 'hsbcBank';
        break;

      case '039':
        bankInfo.icon = LauentianBank;
        bankInfo.name = 'LauentianBank';
        break;

      case '030':
        bankInfo.icon = canadaWesternTrustBank;
        bankInfo.name = 'canadaWesternTrustBank';
        break;

      case '219':
        bankInfo.icon = atbBank;
        bankInfo.name = 'atbBank';
        break;

      case '540':
        bankInfo.icon = manulifeBank;
        bankInfo.name = 'manulifeBank';
        break;

      case '612':
        bankInfo.icon = tangerineBank;
        bankInfo.name = 'tangerineBank';
        break;

      case '623':
        bankInfo.icon = eqLifeBank;
        bankInfo.name = 'eqLifeBank';
        break;

      case '777':
        bankInfo.icon = flinksBank;
        bankInfo.name = 'flinksBank';
        break;

      case '809':
        bankInfo.icon = coastCapitalBank;
        bankInfo.name = 'coastCapitalBank';
        break;

      case '815':
        bankInfo.icon = desjardinsBank;
        bankInfo.name = 'desjardinsBank';
        break;

      case '837':
        bankInfo.icon = meridianBank;
        bankInfo.name = 'meridianBank';
        break;

      default:
        break;
    }
    return bankInfo;
  };

  const allowEdit = options.editBankAccount;
  const allowDelete = options.deleteBankAccount;

  const editButton = <IconButton onClick={() => setShowEditBankAccount(true)} size='small'>
    <Edit data-testid={bankAccount?.id ? `${bankAccount.id}-edit-button` : 'edit-button'} sx={{ fontSize: '16px' }} />
  </IconButton >;

  const deleteButton = <IconButton onClick={() => setShowDeleteBankAccount(true)} size='small'>
    <Delete data-testid={bankAccount?.id ? `${bankAccount.id}-remove-button` : 'remove-button'} sx={{ fontSize: '16px' }} />
  </IconButton>;

  return (
    <>
      <Card>
        <CardContent sx={{ paddingBottom: '16px !important' }}>
          <Grid container spacing={2}>
            <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box display='flex' alignItems='center'>
                <div
                  style={{
                    borderRadius: '99px',
                    display: 'inline-block',
                    marginRight: '16px',
                  }}
                >
                  <Avatar size='xlarge'>
                    <img alt='advisor' src={generateIcon(bankAccount.institutionNumber).icon} style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                  </Avatar>
                </div>
                <Box>
                  <Typography variant='bodyLarge' fontWeight={800}>
                    {bankAccount?.name && <>{bankAccount?.name}&nbsp;</>}
                    {bankAccount.bankAccountNumber}
                  </Typography>
                  <Typography variant='bodyMedium'>{t(`client:bankAccount.${generateIcon(bankAccount.institutionNumber).name}`)}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <Box display='flex' alignItems='center' sx={{ position: 'relative', top: '-8px' }}>
                {allowEdit && !allowDelete && (
                  editButton
                )}
                {allowDelete && !allowEdit && (
                  deleteButton
                )}
                {allowEdit && allowDelete && (
                  <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setContextMenuAnchorEl(event.currentTarget);
                  }}>
                    <MoreVert />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Context drop-down menu */}
      <Menu
        open={contextMenuAnchorEl !== null}
        anchorEl={contextMenuAnchorEl}
        onClose={() => setContextMenuAnchorEl(null)}
        onClick={() => setContextMenuAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setShowEditBankAccount(true)}>
          {editButton}
          <Typography variant='labelLarge' ml='0.5em'>{t('shared:edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setShowDeleteBankAccount(true)}>
          {deleteButton}
          <Typography variant='labelLarge' ml='0.5em'>{t('shared:delete')}</Typography>
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        open={showDeleteBankAccount}
        loading={false}
        title={t('client:bankAccount.deleteBankAccountTitle')}
        bodyText={t('client:bankAccount.deleteBankAccountDesc')}
        onConfirm={async () => {
          onRemove();
          setShowDeleteBankAccount(false);
        }}
        onCancel={() => setShowDeleteBankAccount(false)}
        confirmButtonLabel={t('client:bankAccount.confirm')}
        showCancelButton={true}
      />

      {/* Editing Modal */}
      <ConfirmationModal
        open={showEditBankAccount}
        loading={false}
        title={t('client:bankAccount.editBankAccount')}
        onConfirm={async () => {
          onEdit(bankAccountName);
          setShowEditBankAccount(false);
        }}
        onCancel={() => {
          setShowEditBankAccount(false);
        }}
        confirmButtonLabel={t('client:bankAccount.save')}
        showCancelButton={true}
        confirmButtonColor={ConfirmButtonColors.primary}
        cancelButtonColor={CancelButtonColors.destructive}
      >
        <TextField
          fullWidth
          value={bankAccountName}
          data-testid="bankAccountNameTextField"
          label={t('client:bankAccount.bankAccountName')}
          onChange={(e: any) => {
            setBankAccountName(e.target.value);
          }}
        />
      </ConfirmationModal>
    </>
  );
};

export default BankAccountCard;
