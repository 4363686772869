import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { SxProps } from '@mui/material';
import { translateBackend, getBackendLanguage } from 'assets/i18n/config';
import { TextField } from '../textField/textField';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../dialog/dialog';
import { Button } from '../button/button';
import { TranslatedLanguage, TranslatedString } from '../../../interfaces';
import { Box, Grid, Typography } from '../../1-primative';

export const TranslatableTextField = ({
  onChange, value, label, rows = 1, error, errorText, sx, fullWidth, multiline, disabled, dialogLabel, allowMarkdown,
  required, onBlur, locked, lockMessage, fallbackLanguage,
}: {
  onChange: ((e: TranslatedString) => void),
  value: TranslatedString,
  label?: string,
  sx?: SxProps,
  fullWidth?: boolean,
  rows?: number,
  error?: boolean,
  errorText?: string,
  multiline?: boolean,
  disabled?: boolean,
  dialogLabel?: string,
  allowMarkdown?: boolean,
  required?: boolean,
  onBlur?: any,
  locked?: boolean,
  lockMessage?: string,
  fallbackLanguage?: TranslatedLanguage,
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(false);

  if (!value) return <></>;
  const dialogTitleSuffix = dialogLabel ? ` - ${dialogLabel}` : label ? ` - ${label}` : '';

  return (
    <>
      <TextField
        type='multiLanguage'
        label={label}
        required={required}
        fullWidth={fullWidth}
        sx={sx}
        onBlur={onBlur}
        error={error}
        errorText={errorText ?? t('translatableString.errorText')}
        value={translateBackend(value, fallbackLanguage)}
        onChange={(e: any) => {
          if (getBackendLanguage() === TranslatedLanguage.fr) {
            onChange({ ...value, fr: e.target.value.trimStart() });
          } else {
            onChange({ ...value, en: e.target.value.trimStart() });
          }
        }}
        multiline={multiline}
        rows={rows > 1 ? rows : undefined}
        onTranslate={() => setOpen(!open)}
        disabled={disabled}
        locked={locked}
        lockMessage={lockMessage}
      />
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{t('translate')}{dialogTitleSuffix}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                value={value?.en}
                label={t('languages.english')}
                onChange={(e: any) => onChange({ ...value, en: e.target.value.trimStart() })}
                multiline={multiline}
                rows={rows > 1 ? rows : undefined}
                locked={locked}
                lockMessage={lockMessage}
              />
              {allowMarkdown
                && <Typography>
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{''}</ReactMarkdown>
                </Typography>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={value?.fr}
                label={t('languages.french')}
                onChange={(e: any) => onChange({ ...value, fr: e.target.value.trimStart() })}
                multiline={multiline}
                rows={rows > 1 ? rows : undefined}
                locked={locked}
                lockMessage={lockMessage}
              />
              {allowMarkdown
                && <Typography>
                  <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{''}</ReactMarkdown>
                </Typography>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end'>
            <Button variant='filled' onClick={() => setOpen(false)} label={t('done')} />
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default TranslatableTextField;
