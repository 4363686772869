import i18n from 'assets/i18n/config';
import { formatMoneyValue, generateClientNameString } from '../util';
import { Account } from './account';
import { BillingSchedule } from './billingSchedule';
import { FeeTier } from './feeTier';
import { FinancialProduct, Theme } from './financialProduct';
import { getGoalName, Goal } from './goal';
import { Statistics } from './stats';
import { StateMachineChanges } from '../util/reusableGraphQLFields';

export enum SubAccountStates {
  ACTIVE = 'ACTIVE',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  INACTIVE = 'INACTIVE',
}

export interface SubAccount {
  id: string;
  name?: string;
  account: Account;
  state: SubAccountStates;
  tradingProcess?: string;
  goal: Goal;
  theme?: Theme;
  financialProductId?: string;
  financialProduct?: FinancialProduct;
  suggestedFinancialProduct?: FinancialProduct;
  acceptableFinancialProduct?: boolean;
  suitabilityScore?: number;
  calculatedSuitabilityScore?: number;
  createdAt?: Date;
  signedAllFormsAt?: Date;
  completedAt?: Date;
  incompleteFields?: [string];
  approvedAt?: Date;
  lastUpdatedBy?: string;
  statistics?: Statistics;
  skipIPS?: boolean;
  allowClientDeposits?: boolean;
  isPartial?: boolean;
  applicableBillingSchedule?: BillingSchedule;
  applicableFeeTier?: FeeTier;
  incompleteFormAgreements?: [];
  /** @deprecated use stateMachineChanges */
  stateChanges?: any[];
  stateMachineChanges?: StateMachineChanges[];
  liquidated?: boolean;
  feeTier?: FeeTier;
  billingSchedule?: BillingSchedule;
  [key: string]: any;
}

export const getSubAccountName = (subAccount?: SubAccount, withClientName?: boolean): string => {
  if (subAccount?.name) return subAccount.name;
  let name = '';
  if (subAccount?.account?.user && withClientName) name = generateClientNameString(subAccount.account.user);
  if (subAccount?.goal) name = `${name ? `${name} - ` : ''}${getGoalName(subAccount.goal)}`;
  if (subAccount?.account?.type) name = `${name ? `${name} - ` : ''}${i18n.t(`accountTypes:${subAccount.account?.type}`)}`;
  if (subAccount?.financialProduct?.translatedName?.en) name = `${name ? `${name} - ` : ''}${subAccount.financialProduct?.translatedName?.en}`;
  if (subAccount?.statistics?.marketValueCents) name = `${name ? `${name} ` : ''}${`(${formatMoneyValue(subAccount?.statistics?.marketValueCents)})`}`;
  return name;
};

export const TRADING_PROCESS_LIST = [
  {
    value: 'NO_AUTOMATION',
    name: i18n.t('client:tradingProcessItems.NO_AUTOMATION'),
  },
  {
    value: 'PORTFOLIO_OPTIMIZER',
    name: i18n.t('client:tradingProcessItems.PORTFOLIO_OPTIMIZER'),
  },
  {
    value: 'REBALANCE_V1',
    name: i18n.t('client:tradingProcessItems.REBALANCE_V1'),
  },
  {
    value: 'REBALANCE_V2',
    name: i18n.t('client:tradingProcessItems.REBALANCE_V2'),
  },
];
