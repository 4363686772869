import { useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link as MuiLink,
  Pagination,
  Chip,
  IconButton,
  Menu,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

export const FETCH_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        state
        account {
          id
          type
        }
        user {
          id
          firstName
          lastName
          organization {
            name
          }
        }
      }
      totalCount
    }
  }
`;
export const TRANSITION_STATEMENT = gql`
  mutation transitionAccountStatement($input: TransitionAccountStatementInput!) {
    transitionAccountStatement(input: $input) {
      accountStatement {
        id
      }
    }
  }
`;
enum StatementStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  FAILED = 'FAILED',
  RECONCILED = 'RECONCILED',
}
const transitions = [
  {
    name: 'fail',
    from: [StatementStates.AWAITING_REVIEW],
  },
  {
    name: 'reconcile',
    from: [StatementStates.AWAITING_REVIEW],
  },
];
const StatementsTable = ({ showClient = false, onTransition = () => { } }: { showClient?: boolean, onTransition?: () => void }) => {
  const { permissions } = usePermissions();
  const { statementsRefresh } = useGlobalStats();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['statementsReview', 'client']);
  const [page, setPage] = usePageState(1, 'page');
  const [currentStatement, setCurrentStatement] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [transitionStatement] = useMutation(TRANSITION_STATEMENT);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterTypeGroup, setFilterTypeGroup] = usePageState('ANY', 'typeGroup');
  const [filterAfterDate, setFilterAfterDate] = usePageState('', 'afterDate');
  const [filterBeforeDate, setFilterBeforeDate] = usePageState('', 'beforeDate');
  const [filterAccountType, setFilterAccountType] = usePageState('ANY', 'accountType');
  const [filterPeriod, setFilterPeriod] = usePageState('ANY', 'period');

  const {
    data, loading, previousData, refetch,
  } = useQuery(FETCH_STATEMENTS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          state: 'AWAITING_REVIEW',
          organizationId: activeOrganization.id,
          afterDate: filterAfterDate === '' ? undefined : filterAfterDate,
          beforeDate: filterBeforeDate === '' ? undefined : filterBeforeDate,
          typeGroup: filterTypeGroup === 'ANY' ? undefined : filterTypeGroup,
          accountType: filterAccountType === 'ANY' ? undefined : filterAccountType,
        },
        pagination: {
          sortField: 'referenceDate',
          sortDesc: false,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });
  const generateNewFromDate = (value: string) => {
    if (value === 'YEAR_TO_DATE') {
      return dayjs().startOf('year').format('YYYY-MM-DD');
    }
    return '';
  };

  return (
    <List>
      <Grid container>
        <Grid item>
          <List sx={{ minWidth: '150px' }}>
            <ListItem>
              <TextField
                select
                label={t('client:statements.byPeriod')}
                fullWidth
                value={filterPeriod || ''}
                onChange={(e) => {
                  setFilterPeriod(e.target.value);
                  setFilterAfterDate(generateNewFromDate(e.target.value));
                  setFilterBeforeDate(dayjs().format('YYYY-MM-DD'));
                }}>
                <MenuItem value='ANY'>{t('client:statements.allTime')}</MenuItem>
                <MenuItem value='YEAR_TO_DATE'>{t('client:statements.yearToDate')}</MenuItem>
                <MenuItem value='CUSTOM'>{t('client:statements.custom')}</MenuItem>
              </TextField>
            </ListItem>
          </List>
        </Grid>
        <Grid item sx={{ m: 2, width: '150px' }}>
          <LocalizedDatePicker
            label={t('client:statements.fromDate')}
            value={filterAfterDate || null}
            onChange={(date) => {
              setFilterPeriod('CUSTOM');
              setFilterAfterDate(dayjs(date?.toString()).format('YYYY-MM-DD'));
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item sx={{ m: 2, width: '150px' }}>
          <LocalizedDatePicker
            label={t('client:statements.toDate')}
            value={filterBeforeDate || null}
            onChange={(date) => {
              setFilterBeforeDate(dayjs(date?.toString()).format('YYYY-MM-DD'));
              setFilterPeriod('CUSTOM');
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item>
          <List sx={{ minWidth: '150px' }}>
            <ListItem>
              <TextField select label={t('client:statements.typeGroup')} fullWidth
                value={filterTypeGroup || ''}
                onChange={(e) => setFilterTypeGroup(e.target.value)}
              >
                <MenuItem value='ANY'>{t('client:statements.any')}</MenuItem>
                <MenuItem value='TAX'>{t('client:statements.tax')}</MenuItem>
                <MenuItem value='STATEMENTS'>{t('client:statements.statements')}</MenuItem>
                <MenuItem value='MANAGEMENT_FEES'>{t('client:statements.managementFees')}</MenuItem>
              </TextField>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <List sx={{ minWidth: '150px' }}>
            <ListItem>
              <TextField select label={t('client:statements.accountType')} fullWidth
                value={filterAccountType || ''}
                onChange={(e) => setFilterAccountType(e.target.value)}
              >
                <MenuItem value='ANY'>{t('client:statements.any')}</MenuItem>
                <MenuItem value='PERSONAL'>{t('accountTypes:PERSONAL')}</MenuItem>
                <MenuItem value='RRSP'>{t('accountTypes:RRSP')}</MenuItem>
                <MenuItem value='TFSA'>{t('accountTypes:TFSA')}</MenuItem>
                <MenuItem value='RESP'>{t('accountTypes:RESP')}</MenuItem>
              </TextField>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          {loading && previousData ? (<CircularProgress size='30px' sx={{ marginTop: '2em', float: 'right' }} />) : <></>}
        </Grid>
      </Grid>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {showClient && (
                      <TableCell><Typography variant='overline'>{t('statementsReview:table.client')}</Typography></TableCell>
                    )}
                    <TableCell><Typography variant='overline'>{t('statementsReview:table.organization')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('statementsReview:table.account')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('statementsReview:table.statementState')}</Typography></TableCell>
                    {permissions.includes('transition:account_statement') && (
                      <TableCell align='right'><Typography variant='overline'>{t('statementsReview:table.actions')}</Typography></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || previousData)?.fetchAccountStatements?.accountStatements?.map((statement: any) => (
                    <TableRow
                      hover
                      key={statement.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    >
                      {showClient && (
                        <TableCell component="th" scope="row">
                          <MuiLink component={Link} to={`/clients/${statement.user.id}`}>{statement.user.firstName} {statement.user.lastName}</MuiLink>
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {statement.user.organization.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {/* {statement.account.type} */}
                        <Chip size='small' label={statement.account.type} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {statement.state}
                      </TableCell>
                      {permissions.includes('transition:account_statement') && (
                        <TableCell align='right'><IconButton size='small'
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentStatement(statement);
                            handleClick(e);
                          }}
                          disabled={transitions.filter((x: any) => x.from.includes(statement.state)).length === 0}
                        ><MoreVertIcon /></IconButton></TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchAccountStatements?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="statements-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {transitions.map((transition: any) => (
              transition.from.includes(currentStatement.state) && (
                <MenuItem key={transition.name} onClick={async (e) => {
                  await transitionStatement({ variables: { input: { accountStatementId: currentStatement.id, transition: transition.name } } });
                  refetch();
                  onTransition();
                  statementsRefresh();
                }}>
                  {t(`statementsReview:statementTransitions.${transition.name}`)}
                </MenuItem>
              )
            ))}
          </Menu>
        </>
      )}
    </List>
  );
};

export default StatementsTable;
