import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { translateBackend } from 'assets/i18n/config';
import {
  Box, IconButton, MenuItem, SelectField, Switch,
} from '../..';

export const DataFieldsEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => {
  const [fields, setFields] = useState<any>(options[i].value || {});
  return (
    <Box display='flex' flexDirection='column' sx={{ mb: 2 }}>
      {Object.keys(fields).map((field: string) => (
        <Box sx={{ mt: 1, mb: 1 }} display='flex' flexDirection='row' justifyContent='space-between' >
          <Switch checked={fields[field]} label={field} onChange={(e: any) => {
            const newFields = { ...fields, [field]: e };
            setFields(newFields);
            const newOptions = [...options];
            newOptions[i] = { ...option, value: newFields };
            setOptions(newOptions);
          }}></Switch>
          <IconButton size='small' onClick={() => {
            const newFields = { ...fields };
            delete newFields[field];
            setFields(newFields);
            const newOptions = [...options];
            newOptions[i] = { ...option, value: newFields };
            setOptions(newOptions);
          }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <SelectField label={translateBackend(option?.label)} value='' sx={{ mt: 2 }} fullWidth onChange={(e: any) => {
        setFields({ ...fields, [e.target.value]: false });
      }}>
        {
          Object.keys(option?.options || {}).map((x: string) => (
            <MenuItem value={x}>{x}</MenuItem>
          ))
        }
      </SelectField>
    </Box>
  );
};
