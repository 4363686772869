import { createContext, useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ClientBase from './base';
import { Box, CircularProgress, ViewPage } from '../../ovComponents';
import { UserContext } from '../../providers/userContextProvider';
import { EntityTypes, NavigationStyles, Organization } from '../../interfaces';
import { useGlobalToast } from '../../providers/globalToastProvider';

interface ClientContextType {
  orgSettings: Organization;
  totalMarketValueCents: number;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const CLIENTS_PATH = 'clients/';
const NON_INDIVIDUAL_CLIENTS_PATH = 'nonIndividualClients/';

export const phoneValidator = (value: string): boolean => {
  if (value === '' || value === undefined) return true;
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'CA');
    const numberUS = phoneUtil.parseAndKeepRawInput(value, 'US');
    return phoneUtil.isValidNumberForRegion(phoneNumber, 'CA') || phoneUtil.isValidNumberForRegion(numberUS, 'US');
  } catch (e) {
    return false;
  }
};

export const FETCH_USER = (permissions: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        type
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'phone' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'inProvinceSince' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'physicalAddress { city country houseNumber jurisdiction neighborhood postal streetName unitNumber }' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'gender' : ''}
        language
        state
        fatcaStatus
        fatcaStatusOtherDescription
        affiliateOnly
        organization {
          id
          name
          isReferral
          allowPortfolioPerGoal
          blockSuspiciousTransactions
          reviewTransactions
          availableFeatureFlags
          applicableLocalization { countries }
          jurisdictions { all only }
        }
        statistics { marketValueCents }
        allIncompleteFormAgreements { id }
        ${permissions.includes('read:client_billing') ? 'feeTier { id name}' : ''}
        ${permissions.includes('read:client_billing') ? 'applicableFeeTier { id name}' : ''}
        ${permissions.includes('read:client_billing') ? 'billingSchedule { id frequency}' : ''}
        ${permissions.includes('read:client_billing') ? 'applicableBillingSchedule { id frequency}' : ''}
        incompleteFields
        preferredMethodOfCommunication
        households {
          id
          name
          relationships {
            accessType
            user { id }
          }
        }

        # following attributes are used by <ClientDetails>
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseFirstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseMiddleName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseLastName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseJobTitle' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseCompanyType' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseEmail' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseEmploymentType' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseEmployerName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'numberOfDependents' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'spouseAddress { city country houseNumber jurisdiction neighborhood postal streetName unitNumber }' : ''}
        iDVerified
        iDCheckCompleted
        iDCheckRetrigger
        ${permissions.includes('read:client_low_risk_pii') ? 'complianceState' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'employmentStatus' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'companyType' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'jobTitle' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'studentAreaOfStudy' : ''}
        ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
        ${permissions.includes('read:client_suitability') ? 'employerName' : ''}
        ${permissions.includes('read:client_suitability') ? 'employmentSituation' : ''}
        ${permissions.includes('read:client_suitability') ? 'employmentSituationOtherDescription' : ''}
        ${permissions.includes('read:client_suitability') ? 'employerCompanyAddress { city country houseNumber jurisdiction neighborhood postal streetName unitNumber }' : ''}
        ${permissions.includes('read:id_verification') ? 'latestIdVerification { documentType documentIssuingJurisdication }' : ''}
        ${permissions.includes('read:client_suitability') ? 'annualIncomeCents' : ''}
        ${permissions.includes('read:client_suitability') ? 'annualIncomeStr' : ''}
        ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents' : ''}
        ${permissions.includes('read:client_suitability') ? 'financialFixedAssetsCents' : ''}
        ${permissions.includes('read:client_suitability') ? 'totalDebtCents' : ''}
        ${permissions.includes('read:client_suitability') ? 'annualDebtPaymentsCents' : ''}
        ${permissions.includes('read:client_suitability') ? 'isAccreditedInvestor' : ''}
        ${permissions.includes('read:client_suitability') ? 'investmentKnowledge' : ''}
        ${permissions.includes('read:client_suitability') ? 'riskQuestion1' : ''}
        ${permissions.includes('read:client_suitability') ? 'riskQuestion2' : ''}
        ${permissions.includes('read:client_suitability') ? 'suitabilityScore' : ''}
        ${permissions.includes('read:client_suitability') ? 'sourceOfWealth sourceOfFunds sourceOfWealthOtherDescription sourceOfFundsOtherDescription uniqueCircumstances' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'maritalStatus' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'citizenships' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'foreignTaxInformation { foreignTaxCountry foreignTaxNumber }' : ''}
        ${permissions.includes('read:fraud_risk_basic') ? 'fraudRisk { id lastUpdatedBy notes score riskReasons { translatedDescription { en } } }' : ''}
        countryOfTaxResidence
        ${permissions.includes('read:client_low_risk_pii') ? 'headOfInternationalOrganization' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'complianceIssueSource' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'isOfficerOfPublicCompany' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'isOwnerOfPublicCompany' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'ssSymbols' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'isMemberOfIiroc' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'insiderSymbols' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'politicallyExposedDomesticPerson' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'politicallyExposedForeignPerson' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'closeAssociateOfPEP' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'powerOfAttorneyGranted' : ''}
        ${permissions.includes('read:client_low_risk_pii')
    ? `trustedContactPerson {
        name email relation phone
        physicalAddress { city country houseNumber jurisdiction neighborhood postal streetName unitNumber }
      }`
    : ''}
        readyToSignAgreement
        isVulnerablePerson
        isOwnerOfEntity
        sinExists
        taxIdType
        taxIdExists

        # related to Non-individual entities
        entityName
        relatedEntities { relation }
        establishedDate
        countryOfRegistration
        registrationNumber
        taxNumber
        yearEnd
        isNonProfit
        receivesDonationsFromPublic
        charityRegistrationNumber
        isRegisteredWithCRA
        isEntityRegulated regulatorName firmNumber
        isReportingIssuer issuerName issuerTicker issuerExchange
        isAFinancialEntity isAffiliateOfFinancialEntity instructionsFromFinancialEntity
        isForThirdParty
        isPublicBody
        isLargeCorporation
      }
    }
  }
`;

const FETCH_BASE_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user { id type }
    }
  }
`;

export const ClientContext = createContext<ClientContextType | undefined>(undefined);
export const useClientContext = () => useContext(ClientContext);

const Client = () => {
  const { t } = useTranslation('client');
  const { showToast } = useGlobalToast();
  const { userContext, activeEntity } = useContext(UserContext);
  const { clients: paramsClientsPath, userId: paramsUserId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userId = paramsUserId ?? activeEntity?.id;
  const { data, loading } = useQuery(FETCH_BASE_USER, { variables: { userId } });

  useEffect(() => {
    const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
    if (isUnifiedClientExperience || !data?.fetchUser?.user) return;

    if (location.pathname.includes(CLIENTS_PATH) && data.fetchUser.user.type !== EntityTypes.INDIVIDUAL) {
      navigate(location.pathname.replace(CLIENTS_PATH, NON_INDIVIDUAL_CLIENTS_PATH), { replace: true });
    }

    if (location.pathname.includes(NON_INDIVIDUAL_CLIENTS_PATH) && data.fetchUser.user.type === EntityTypes.INDIVIDUAL) {
      navigate(location.pathname.replace(NON_INDIVIDUAL_CLIENTS_PATH, CLIENTS_PATH), { replace: true });
    }

    if (paramsClientsPath && ![CLIENTS_PATH, NON_INDIVIDUAL_CLIENTS_PATH].includes(`${paramsClientsPath}/`)) {
      const newClientPath = data?.fetchUser?.user?.type === EntityTypes.INDIVIDUAL ? CLIENTS_PATH : NON_INDIVIDUAL_CLIENTS_PATH;
      navigate(location.pathname.replace(`${paramsClientsPath}/`, newClientPath), { replace: true });
    }
  }, [data?.fetchUser?.user, userContext.role?.navigationStyle, paramsClientsPath, location, t, showToast, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  if (data?.fetchUser?.user?.type === EntityTypes.INDIVIDUAL && userContext.role?.individualPageConfiguration) {
    return <ViewPage pageConfiguration={userContext.role.individualPageConfiguration} />;
  }

  if (data?.fetchUser?.user?.type !== EntityTypes.INDIVIDUAL && userContext.role?.nonIndividualPageConfiguration) {
    return <ViewPage pageConfiguration={userContext.role.nonIndividualPageConfiguration} />;
  }

  return <ClientBase />;
};

export default Client;

export { ClientBreadcrumb } from './clientBreadcrumb';
export { EntityBreadcrumb } from './entityBreadcrumb';
