import { useTranslation } from 'react-i18next';
import { ClientGroupMemberAccessType, User } from '../../../interfaces';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { AddHouseholdMemeberActionStep } from './steps/addHouseholdMemeberActionStep';
import { ChooseClientStep } from './steps/chooseClientStep';
import { SummaryStep } from './steps/summaryStep';

type Step = 'CHOOSE_CLIENT' | 'SUMMARY' | 'ADD_HOUSEHOLD_MEMBER';

const steps:Record<Step, StepComponent> = {
  CHOOSE_CLIENT: ChooseClientStep,
  SUMMARY: SummaryStep,
  ADD_HOUSEHOLD_MEMBER: AddHouseholdMemeberActionStep,
};

interface WizardContext {
  clientGroupId: string,
  primaryUserName: string,
}

interface WizardState {
  user?: User,
  relationship: string,
  accessType: ClientGroupMemberAccessType
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  user: undefined,
  relationship: '',
  accessType: 'NO_ACCESS',
};

export interface AddHouseholdMemberWizardProps {
  clientGroupId: string,
  primaryUserName: string,
}

export function AddHouseholdMemberWizard({
  clientGroupId, primaryUserName, handleClose,
}: AddHouseholdMemberWizardProps & WizardControlProps) {
  const { t } = useTranslation('addHouseholdMemberWizard');

  return (
    <WizardBase
      title={t('title')}
      context={{
        clientGroupId,
        primaryUserName,
      }}
      steps={steps}
      firstStep={'CHOOSE_CLIENT'}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
