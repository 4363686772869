import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/userContextProvider';

const FETCH_ENTITY_NAME = gql`
  query fetchUserEntityName($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        entityName
      }
    }
  }
`;

export function EntityBreadcrumb() {
  const { t } = useTranslation();
  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const { data, loading } = useQuery(FETCH_ENTITY_NAME, { variables: { userId } });

  if (loading) return '';

  return data?.fetchUser?.user.entityName ?? t('client:entity');
}
