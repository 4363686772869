import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination, ListItem, TextField,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import NewNotificationGroup from './newNotificationGroup';
import { translateBackend } from '../../../assets/i18n/config';
import FormModal from '../../../components/modals/formModal';

const FETCH_NOTIFICATION_GROUPS = gql`
  query fetchNotificationGroups($input: FetchNotificationGroupsInput!) {
    fetchNotificationGroups(input: $input) {
      notificationGroups {
        id
        name { en fr }
      }
    }
  }
`;

const UPDATE_NOTIFICATION_GROUP = gql`
  mutation updateNotificationGroup($input: UpdateNotificationGroupInput!) {
    updateNotificationGroup(input: $input) {
      notificationGroup {
        id
      }
    }
  }
`;

const NotificationGroups = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);
  const [activeNotificationGroup, setActiveNotificationGroup] = useState({
    id: '',
    name: { en: '', fr: '' },
  });
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_NOTIFICATION_GROUPS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [updateNotificationGroup] = useMutation(UPDATE_NOTIFICATION_GROUP, {
    variables: {
      input: {
        notificationGroupId: activeNotificationGroup?.id,
        name: { en: activeNotificationGroup?.name?.en, fr: activeNotificationGroup?.name?.fr },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              { permissions.includes('write:notification_group') && (
                <NewNotificationGroup afterCreate={refetch} defaultOrg={activeOrganization.id} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('notificationGroupTable.name')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchNotificationGroups?.notificationGroups?.map((notificationGroup: any) => (
                <TableRow
                  key={notificationGroup.id}
                  sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setActiveNotificationGroup(notificationGroup);
                    setOpen(true);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {translateBackend(notificationGroup.name)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchNotificationGroups?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
      <FormModal loading={loading} title={t('editNotificationGroupModal.title')} onSubmit={async () => {
        await updateNotificationGroup();
        await refetch();
        setOpen(false);
      }} maxWidth='xs' open={open} handleClose={() => setOpen(false)} formButton={t('save')}>
        <ListItem>
          <TextField
            fullWidth
            label={t('notificationGroupModal.name')}
            value={activeNotificationGroup.name.en}
            onChange={(e) => setActiveNotificationGroup({ ...activeNotificationGroup, name: { en: e.target.value as string, fr: activeNotificationGroup?.name?.fr } })} />
        </ListItem>
      </FormModal>
    </Box>
  );
};

export default NotificationGroups;
