import { translateBackend } from 'assets/i18n/config';
import {
  Grid, MenuItem, SelectField,
} from 'ovComponents';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DepositTransferContext } from '../depositWorkflow/depositWorkflow';

export const TransferTypeSelectField = ({
  option, options, setOptions, i, type, dummyDeposit, setDummyDeposit,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number, type?: string, dummyDeposit?: any, setDummyDeposit?: any,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { transferData, setTransferData } = useContext(DepositTransferContext);

  useEffect(() => {
    setTransferData({ ...transferData, type: 'EFT' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1} alignItems='end'>
      <Grid item xs={12}>
        <SelectField
          label={translateBackend(option.label)}
          value={option.value}
          fullWidth
          onChange={(e: any) => {
            setTransferData({ ...transferData, type: e.target.value });
            const newOptions = [...options];
            newOptions[i] = { ...option, value: e.target.value };
            if (dummyDeposit) {
              setDummyDeposit({ ...dummyDeposit, type: e.target.value });
            }
            setOptions(newOptions);
          }}
        >
          <MenuItem value='EFT'>{t('depositTypeOptions.EFT')}</MenuItem>
          <MenuItem value='INTERNAL_TRANSFER'>{t('depositTypeOptions.INTERNAL_TRANSFER')}</MenuItem>
          <MenuItem value='EXTERNAL_TRANSFER'>{t('depositTypeOptions.EXTERNAL_TRANSFER')}</MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};
