import { useNavigate } from 'react-router-dom';
import BillingScheduleTable from './components/billingScheduleTable';
import { DefaultOrgBillingScheduleBox } from './components/defaultOrgBillingScheduleBox';

const BillingSchedules = () => {
  useNavigate();

  return (
    <>
      <DefaultOrgBillingScheduleBox />
      <BillingScheduleTable />
    </>
  );
};

export default BillingSchedules;
