import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';

const Link = ({ entityKey, contentState, children }: any) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      style={{ color: '#2CA58D', fontStyle: 'underline' }}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const findLinkEntities = (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null
      && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const createLinkDecorator = () => new CompositeDecorator([{
  strategy: findLinkEntities,
  component: Link,
}]);
