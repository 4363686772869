import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  availableOperators,
  ObjectType, RuleDocument, RuleGroupDocument, ruleMapping,
  RuleOptions,
  ValueTypes,
} from '@onevesthq/ov-conditions';
import { Add, Clear } from '@mui/icons-material';
import { Box } from '../../1-primative';
import {
  Button, Card, Selector, CardContent,
} from '../../2-component';
import { Rule } from './components/rule';

export const conditionGraphql = `
  condition {
    ruleGroup {
      combinator
      rules {
        combinator
        field
        operator
        value
        objectType
        rules {
          combinator
          field
          operator
          value
          objectType
          rules {
            combinator
            field
            operator
            value
            objectType
          }
        }
      }
    }
  }
`;

const MAX_DEPTH = 3;

export const RuleBuilder = ({
  ruleGroup, setRuleGroup, objectType, removeRuleGroup, depth = 0,
}: {
  ruleGroup: RuleGroupDocument, setRuleGroup: (r: RuleGroupDocument) => void, objectType: ObjectType, removeRuleGroup?: () => void, depth?: number,
}) => {
  const { t } = useTranslation('components');
  const [availableRules, setAvailableRules] = useState<RuleOptions[]>([]);
  const [localRuleGroup, setLocalRuleGroup] = useState<RuleGroupDocument>(ruleGroup);

  useEffect(() => {
    setLocalRuleGroup(ruleGroup);
  }, [ruleGroup]);

  useEffect(() => {
    const rs: RuleOptions[] = ruleMapping[objectType];
    setAvailableRules(rs);
  }, [objectType]);

  const addRule = () => {
    setRuleGroup({
      ...localRuleGroup,
      rules: [...localRuleGroup.rules, {
        field: availableRules[0].name,
        operator: availableOperators(
          ruleMapping[objectType]?.find((x: RuleOptions) => x.name === availableRules[0].name)?.valueType || ValueTypes.TEXT,
        )[0],
        value: '',
        objectType,
      }],
    });
  };

  const removeRule = (index: number) => {
    const rs = [...localRuleGroup.rules];
    rs.splice(index, 1);
    setRuleGroup({ ...localRuleGroup, rules: rs });
  };

  const addRuleGroup = () => {
    const newRules = [...localRuleGroup.rules];
    newRules.push({
      combinator: 'and',
      rules: [],
    });
    setRuleGroup({ ...localRuleGroup, rules: newRules });
  };

  if (!localRuleGroup) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box display='flex' gap={1} justifyContent='space-between'>
        <Box display='flex' gap={1}>
          <Selector
            options={[
              { label: t('ruleBuilder.and'), type: 'and' },
              { label: t('ruleBuilder.or'), type: 'or' },
            ]}
            selectedOptionType={localRuleGroup.combinator}
            onChange={(event) => {
              setRuleGroup({ ...localRuleGroup, combinator: event.target.value });
            }}
          />
          <Button label={t('ruleBuilder.addRule')} leadingIcon={Add} variant='text' size='sm' onClick={addRule} />
          { depth < MAX_DEPTH && (
            <Button label={t('ruleBuilder.addGroup')} leadingIcon={Add} variant='text' size='sm' onClick={addRuleGroup} />
          )}
        </Box>
        {removeRuleGroup && (
          <Button onClick={removeRuleGroup} label={t('ruleBuilder.removeRuleGroup')} variant='text' leadingIcon={Clear} size='sm' />
        )}
      </Box>
      {(localRuleGroup.rules || []).length > 0 && (
        (localRuleGroup.rules || []).map((rule, index) => (
          <Box key={index} display='flex'>
            { 'field' in rule && rule.field && (
              <Rule rule={rule} updateRule={(r: RuleDocument) => {
                const rs = [...localRuleGroup.rules];
                rs[index] = r;
                setRuleGroup({ ...localRuleGroup, rules: rs });
              }} availableRules={availableRules} objectType={objectType} removeRule={() => removeRule(index)} />
            )}
            { 'rules' in rule && rule.rules && (
              <Card variant='outlined' sx={{ width: '100%' }}>
                <CardContent>
                  <RuleBuilder ruleGroup={rule} objectType={objectType} removeRuleGroup={() => removeRule(index)} setRuleGroup={(r: RuleGroupDocument) => {
                    const rs = [...localRuleGroup.rules];
                    rs[index] = r;
                    setRuleGroup({ ...localRuleGroup, rules: rs });
                  }} depth={depth + 1}/>
                </CardContent>
              </Card>
            )}
          </Box>
        ))
      )}
    </Box>
  );
};
