import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditBillingAndFeeTier from './editBillingAndFeeTier';
import { FETCH_HOUSEHOLD } from '../../household';

const UPDATE_CLIENT_GROUP = gql`
  mutation updateClientGroup($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup {
        id
      }
    }
  }
`;

const EditClientGroupBillingAndFeeTier = ({
  afterUpdate, clientGroupToUpdate, open, handleClose, highlight, title,
}: {
  afterUpdate: () => void,
  clientGroupToUpdate: any,
  title: string,
  open: boolean, handleClose: () => void,
  highlight?: string }) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [localOpen, setLocalOpen] = useState(open);

  const [updateClientGroup, { loading }] = useMutation(UPDATE_CLIENT_GROUP, {
    variables: {
      input: {},
    },
    refetchQueries: [FETCH_HOUSEHOLD(permissions)],
  });

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const update = async (event: any, changeableApplicableFeeTier: string, changeableApplicableBillingSchedule: string) => {
    event.preventDefault();
    await updateClientGroup({
      variables: {
        input: {
          clientGroupId: clientGroupToUpdate?.id,
          feeTierId: changeableApplicableFeeTier === 'inherited' ? null : changeableApplicableFeeTier,
          billingScheduleId: changeableApplicableBillingSchedule === 'inherited' ? null : changeableApplicableBillingSchedule,
        },
      },
    });
    afterUpdate();
  };

  if (!clientGroupToUpdate) {
    return <></>;
  }

  return (
    <EditBillingAndFeeTier
      title={title}
      open={localOpen}
      setOpen={(value) => {
        setLocalOpen(value);
        handleClose();
      }}
      loading={loading}
      highlight={highlight}
      organizationId={activeOrganization?.id || ''}
      feeTierId={clientGroupToUpdate.feeTier?.id ?? undefined}
      applicableFeeTierId={clientGroupToUpdate.applicableFeeTier?.id}
      billingScheduleId={clientGroupToUpdate.billingSchedule?.id ?? undefined}
      applicableBillingScheduleId={clientGroupToUpdate.applicableBillingSchedule?.id}
      callback={(event, applicableFeeTierId, applicableBillingScheduleId) => update(event, applicableFeeTierId, applicableBillingScheduleId)}
    />
  );
};

export default EditClientGroupBillingAndFeeTier;
