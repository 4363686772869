import {
  Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import CreateNewModal from '../../../modals/createNewModal';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { formatMoneyValue } from '../../../../util';
import { colors } from '../../../../theme/colors';
import { ObjectType } from '../../../../providers/statsHooks';
import { UserContext } from '../../../../providers/userContextProvider';

const CREATE_BULK_TRADE = gql`
  mutation createBulkTradeRequest($input: CreateBulkTradeRequestInput!) {
    createBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CREATE_SUB_TRADE_REQUESTS = gql`
  mutation createSubTradeRequests($input: CreateSubTradeRequestsInput!) {
    createSubTradeRequests(input: $input)
  }
`;

const UPDATE_SUB_TRADE_REQUESTS = gql`
  mutation updateSubTradeRequests($input: UpdateSubTradeRequestsInput!) {
    updateSubTradeRequests(input: $input)
  }
`;

export const TRANSITION_SUB_TRADE_REQUEST = gql`
  mutation transitionSubTradeRequest($input: TransitionSubTradeRequestInput!) {
    transitionSubTradeRequest(input: $input) {
      subTradeRequest {
        id
      }
    }
  }
`;

export const PAIR_SUB_TRADE_REQUESTS = gql`
  mutation pairSubTradeRequests($input: PairSubTradeRequestsInput!) {
    pairSubTradeRequests(input: $input)
  }
`;

const GenerateTradesModal = ({
  afterCreate,
  trades,
  type,
  id,
  modalOpenDisabled,
  bulkTradeId,
  tradesToCancel,
  portfolioOptimizerId,
  bulkTradeRequest,
  onNewBulkTradeRequestCreated,
}: {
  afterCreate: (tradeId: string) => void;
  trades: any[];
  type: ObjectType;
  id: string;
  modalOpenDisabled: boolean;
  bulkTradeId?: string;
  tradesToCancel?: any[];
  portfolioOptimizerId?: string,
  bulkTradeRequest?: Record<string, any>;
  onNewBulkTradeRequestCreated?: () => void;
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);

  const [createSubTradeRequests] = useMutation(CREATE_SUB_TRADE_REQUESTS);
  const [updateSubTradeRequests] = useMutation(UPDATE_SUB_TRADE_REQUESTS);
  const [transitionSubTradeRequest] = useMutation(TRANSITION_SUB_TRADE_REQUEST);
  const [createBulkTrade, { loading }] = useMutation(CREATE_BULK_TRADE, {
    variables: {
      input: {
        source: type === 'Goal' ? 'GOAL' : 'SUB_ACCOUNT',
        sourceId: id,
      },
    },
    onCompleted: () => {
      if (onNewBulkTradeRequestCreated) {
        onNewBulkTradeRequestCreated();
      }
    },
  });
  const [pairSubTradeRequests] = useMutation(PAIR_SUB_TRADE_REQUESTS);

  const create = async (event: any) => {
    let bulkTradeRequestId = null;

    if (bulkTradeId && !['CANCELED', 'COMPLETED'].includes(bulkTradeRequest?.state)) {
      bulkTradeRequestId = bulkTradeId;
    } else {
      const response = await createBulkTrade();

      if (!response?.data?.createBulkTradeRequest?.bulkTradeRequest?.id) {
        showToast({ severity: 'error', message: t('shared:someErrorHasOccurred') });
        return;
      }

      bulkTradeRequestId = response?.data?.createBulkTradeRequest?.bulkTradeRequest?.id;
    }

    const subTradeRequestsToCreate = [];
    const subTradeRequestsToUpdate = [];

    for (let i = 0; i < trades.length; i++) {
      const trade = trades[i];

      if (!bulkTradeId || ['CANCELED', 'COMPLETED'].includes(bulkTradeRequest?.state)) {
        delete trade.id;
      }

      const subTradeRequest = {
        ...(trade.id && { subTradeRequestId: trade.id }),
        ...(!trade.id && { subAccountId: trade.subAccountId, financialProductId: trade.financialProductId }),
        ...(!trade.id && portfolioOptimizerId && { sourceId: id }),

        maxQuantity: trade.maxQuantity,
        moneyAllocatedCents: Math.abs(trade.amountCents),
        type: trade.type,
        isSellAll: trade.isSellAll,
      };

      trade.id ? subTradeRequestsToUpdate.push(subTradeRequest) : subTradeRequestsToCreate.push(subTradeRequest);
    }

    if (subTradeRequestsToCreate.length > 0) {
      await createSubTradeRequests({
        variables: {
          input: {
            bulkTradeRequestId,
            subTradeRequests: subTradeRequestsToCreate,
          },
        },
      });

      if (activeOrganization?.allowViewSubTradeRequestPairs) await pairSubTradeRequests({ variables: { input: { bulkTradeRequestId } } });
    }

    if (subTradeRequestsToUpdate.length > 0) {
      await updateSubTradeRequests({
        variables: {
          input: {
            subTradeRequests: subTradeRequestsToUpdate,
          },
        },
      });
    }

    if (tradesToCancel && tradesToCancel.length > 0) {
      const tasks = tradesToCancel
        .filter((trade) => trade.id)
        .map((trade) => transitionSubTradeRequest({
          variables: {
            input: {
              subTradeRequestId: trade.id,
              transition: 'cancel',
            },
          },
        }));

      if (tasks.length > 0) {
        await Promise.all(tasks);
      }
    }

    afterCreate(bulkTradeRequestId);

    showToast({ severity: 'info', message: t('components:generateTrades.tradesSent') });
  };

  return (
    <CreateNewModal
      loading={loading}
      title={t('components:generateTrades.confirmTrades')}
      onSubmit={create}
      buttonText={t('components:generateTrades.confirm')}
      modalButton={`${t('components:generateTrades.confirmTrades')}${modalOpenDisabled ? '' : ` (${trades.length + (tradesToCancel?.length ?? 0)})`}`}
      disableButton={modalOpenDisabled}
      icon={null}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.gray200, borderRadius: '25px 25px 0px 0px' }}>
              <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.account')}</TableCell>
              <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.type')}</TableCell>
              <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.amount')}</TableCell>
              <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.security')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades.map((trade, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }} component='th' scope='row'>
                  {trade.accountName}
                </TableCell>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{trade.type}</TableCell>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{formatMoneyValue(Math.abs(trade.amountCents))}</TableCell>
                <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{trade.ticker}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {(tradesToCancel?.length ?? 0) > 0 && (
          <>
            <Grid container>
              <Grid item m={2}>
                <Typography variant='body1'>{t('components:generateTrades.tradesToBeCanceled')}:</Typography>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: colors.gray200, borderRadius: '25px 25px 0px 0px' }}>
                  <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.account')}</TableCell>
                  <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.type')}</TableCell>
                  <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('components:generateTrades.table.security')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tradesToCancel?.map((trade, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell style={{ fontSize: '14px', fontWeight: '500' }} component='th' scope='row'>
                      {trade.accountName}
                    </TableCell>
                    <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{trade.type}</TableCell>
                    <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{trade.ticker}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </CreateNewModal>
  );
};

export default GenerateTradesModal;
