import { useThemeTokens } from 'providers/themeTokenProvider';

export interface HelpTextTokens {
  helpText: {
    successColor?: string;
    warningColor?: string;
    errorColor?: string;
    neutralColor?: string;
  }
}

export const useHelpTextTokens = (overrides?: HelpTextTokens): HelpTextTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: HelpTextTokens = {
    helpText: {
      successColor: sys.color.positive,
      errorColor: sys.color.negative,
      warningColor: sys.color.warning,
      neutralColor: sys.color.onSurfaceVariant,
      ...(overrides?.helpText ?? {}),
    },
  };
  return comp;
};
