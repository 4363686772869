import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../../../../util';
import {
  TableCell,
} from '../../../../2-component';
import { PageObjectType } from '../../../../5-page';
import { useLocalization } from '../../../../../util/useLocalization';

export const LedgerCell = ({
  transaction, type, view, isFirst,
}: {
  transaction: any, type: string, view: string, isFirst?: boolean,
}) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { localizedDate } = useLocalization();

  const addTypeDetails = () => {
    if (transaction.financialProduct) {
      return ` - ${transaction.financialProduct.ticker}`;
    }
    return '';
  };

  switch (type) {
    case 'account':
      return (
        <TableCell isFirst={isFirst} dense>
          {view === PageObjectType.HOUSEHOLD ? `${transaction.account?.user?.firstName} ${transaction.account?.user?.lastName} - ` : ''}{t(`accountTypes:${transaction.account?.type}`)}
        </TableCell>
      );
    case 'type':
      return (
        <TableCell isFirst={isFirst} dense>{t(`components:transaction.types.${transaction.type}`)}<b>{addTypeDetails()}</b></TableCell>
      );
    case 'currency':
      return (
        <TableCell isFirst={isFirst} dense>{transaction.currency ?? ''}</TableCell>
      );
    case 'costPrice':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.priceCents ? formatMoneyValue(transaction.priceCents) : '-'}</TableCell>
      );
    case 'quantity':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.quantity === 0 ? '-' : transaction.quantity}</TableCell>
      );
    case 'security':
      return (
        <TableCell isFirst={isFirst} dense>{transaction.financialProduct?.ticker ? transaction.financialProduct?.ticker : '-'}</TableCell>
      );
    case 'value':
      return (
        <TableCell isFirst={isFirst} dense number>{formatMoneyValue(transaction.valueCents)}</TableCell>
      );
    case 'date':
      return (
        <TableCell isFirst={isFirst} dense>{localizedDate(transaction.date)}</TableCell>
      );
    case 'bookValue':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.bookValueCents ? formatMoneyValue(transaction.bookValueCents) : '-'}</TableCell>
      );
    case 'description':
      return (
          <TableCell isFirst={isFirst} dense >{transaction.description ?? ''}</TableCell>
      );
    case 'custodianType':
      return (
            <TableCell isFirst={isFirst} dense >{transaction.custodianTransactionType ?? ''}</TableCell>
      );
    default:
      return (
        <TableCell isFirst={isFirst}>
          {type}
        </TableCell>
      );
  }
};
