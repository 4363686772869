import { translateBackend } from 'assets/i18n/config';
import { useState } from 'react';
import TranslatableTextField from 'ovComponents/2-component/translatableTextField/translatableTextField';
import { FieldTypes } from './optionEditComponent';

export const TranslatedStringEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => {
  const [error, setError] = useState(false);
  const markdownProps = {
    multiline: true,
    rows: 10,
  };

  return (
    <TranslatableTextField
      label={translateBackend(option.label)}
      value={option.value}
      fullWidth
      sx={{ mb: 2 }}
      {...(option.options?.isMarkdown ? markdownProps : {})}
      {...(option.options?.rows ? {
        multiline: true,
        rows: option.options.rows,
      } : {})}
      error={error}
      onChange={(value) => {
        const newOptions = [...options];
        newOptions[i] = { ...option, value };
        setOptions(newOptions);
        if (option.optionType === FieldTypes.TRANSLATED_STRING && !value?.en) {
          setError(true);
        } else {
          setError(false);
        }
      }}
    />
  );
};
