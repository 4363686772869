import { translateBackend } from 'assets/i18n/config';
import { MenuItem, SelectField } from 'ovComponents/2-component';

export const SelectFieldEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number,
}) => (
  <SelectField label={translateBackend(option.label)} value={option.value} sx={{ mb: 2 }} fullWidth onChange={(e: any) => {
    const newOptions = [...options];
    newOptions[i] = { ...option, value: e.target.value };
    setOptions(newOptions);
  }}>
    {option.options.map((item: any, idx: number) => (<MenuItem key={idx} value={item.value}>{translateBackend(item.label)}</MenuItem>))}
  </SelectField>
);
