import { gql } from '@apollo/client';

export const PAGE_CONFIGURATION = `
  pageConfiguration {
    id
    name
    type
    options
    tabs {
      id
      icon
      url
      label {
        en
        fr
      }
      widgets {
        id
        type
        options
      }
    }
  }
`;

export const FETCH_PAGE_CONFIGURATION = gql`
query fetchPageConfiguration($pageConfigurationId: ObjectID!) {
  fetchPageConfiguration (pageConfigurationId: $pageConfigurationId) {
    ${PAGE_CONFIGURATION}
  }
}
`;

export const UPDATE_PAGE_CONFIGURATION = gql`
  mutation updatePageConfiguration($input: UpdatePageConfigurationInput!) {
    updatePageConfiguration(input: $input) {
      ${PAGE_CONFIGURATION}
    }
  }
`;

export const FETCH_RELATED_OBJECTS = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        firstName middleName lastName entityName
        goals {
          id
        }
        accounts {
          id
        }
        subAccounts {
          id
        }
        households {
          id
          statistics {
            marketValueCents
          }
          relationships{
            type
            user {
              id
            }
          }
        }
        statistics {
          marketValueCents
        }
        organization {
          id
          displayCurrency
          availableFeatureFlags
        }
      }
    }
  }
`;
