import { useTranslation } from 'react-i18next';
import {
  MenuItem, SelectField,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';

export const FilterNonIndividuals = ({
  filterByType, setFilterByType, types,
}: {
  filterByType: string[], setFilterByType: (e: string[]) => void, types: string[],
}) => {
  const { t } = useTranslation(['nonIndividualClients', 'shared', 'entityTypes']);

  return (
    <FilterModal filterExists={filterByType.length > 0}>
      <SelectField fullWidth multiple label={t('entityType')} value={filterByType} onChange={(e: any) => setFilterByType(e.target.value)} sx={{ mb: 2 }}>
        {types.map((type) => (
          <MenuItem key={type} value={type}>{t(`entityTypes:${type}`)}</MenuItem>
        ))}
      </SelectField>
    </FilterModal>
  );
};
