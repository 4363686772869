import {
  TextField, ListItem, Typography, Button, MenuItem,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import { colors } from '../../../theme/colors';

interface Relationship {
  type: string,
  primaryEmail: string,
  userId: string,
}

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const FETCH_USER_IDS = gql`
query fetchUsers($input: FetchUsersInput!) {
  fetchUsers(input: $input) {
      users {
        id
      }
    }
  }
`;

const NewRelationshipUser = (
  {
    onUserVerify, organizationId, allowedTypes, buttonType, modalButton, icon,
  }:
  {
    onUserVerify: (relationship?: Relationship | undefined) => void,
    organizationId: string,
    allowedTypes?: string[],
    modalButton?: string,
    buttonType?: 'BUTTON' | 'ICON',
    icon?: JSX.Element,
  },
) => {
  const { t } = useTranslation(['clients', 'household']);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showVerificationFail, setShowVerificationFail] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [user, setUser] = useState({
    primaryEmail: '',
    organizationId,
    relationType: allowedTypes ? allowedTypes[0] : '',
    firstName: '',
    lastName: '',
    userId: '',
    language: 'ENGLISH',
    timezone: 'America/Edmonton',
  });

  const [createUser] = useMutation(CREATE_USER, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
        organizationId,
        firstName: user.firstName,
        lastName: user.lastName,
        language: 'ENGLISH',
        timezone: 'America/Edmonton',
      },
    },
  });

  const [fetchUsers, { loading, error }] = useLazyQuery(FETCH_USER_IDS, {
    fetchPolicy: 'no-cache',
  });

  const updateField = (event: any) => {
    const key: 'primaryEmail' | 'firstName' | 'lastName' = event.target.id;
    setUser({ ...user, [key]: event.target.value as string });
  };

  const updateType = (event: any) => {
    setUser({ ...user, relationType: event.target.value as string });
  };

  const addRelationship = async () => {
    onUserVerify({
      primaryEmail: user.primaryEmail,
      userId: user.userId,
      type: user.relationType,
    });
    setIsNewUser(false);
    setHideSubmitButton(true);
    setShowVerificationFail(false);
    setUser({
      primaryEmail: '',
      organizationId,
      relationType: allowedTypes ? allowedTypes[0] : '',
      firstName: '',
      lastName: '',
      userId: '',
      language: 'ENGLISH',
      timezone: 'America/Edmonton',
    });
  };
  const verifyUser = async () => {
    const response = await fetchUsers({
      variables: {
        input: {
          filter: {
            primaryEmail: user.primaryEmail,
            organizationId,
          },
        },
      },
    });
    if (response?.data.fetchUsers.users && response?.data.fetchUsers.users.length > 0) {
      setHideSubmitButton(false);
      setIsNewUser(false);
      setUser({
        primaryEmail: user.primaryEmail,
        userId: response.data.fetchUsers.users[0].id || '',
        organizationId,
        relationType: user.relationType,
        firstName: '',
        lastName: '',
        language: 'ENGLISH',
        timezone: 'America/Edmonton',
      });
    } else {
      // No Any user!
      setShowVerificationFail(true);
    }
  };
  const addNewUser = async () => {
    const response = await createUser();
    if (response?.data.createUser.user.id) {
      setHideSubmitButton(false);
      setUser({ ...user, userId: response.data.createUser.user.id });
    }
  };
  if (error) (<Typography>Error</Typography>);
  const disabled = !(user.relationType && user.organizationId && user.primaryEmail);
  const addUserDisabled = !(user.relationType && user.organizationId && user.primaryEmail && user.firstName && user.lastName);
  return (
    <CreateNewModal
      loading={loading}
      disabled={disabled}
      title={t('household:relationModal.relationTitle')}
      buttonText={t('household:relationModal.addRelation')}
      onSubmit={addRelationship}
      modalButton={modalButton}
      sx={{ float: 'right', m: 1 }}
      icon={icon}
      buttonType={buttonType ?? 'ICON'}
      hideSubmit={hideSubmitButton}
    >
      <ListItem>
        {/* free text relationship type */}
        {allowedTypes === undefined
          && <TextField
            autoFocus
            label={t('household:relationModal.relationship')}
            data-testid="relation-user-type"
            sx={{ width: '100%' }}
            value={user.relationType}
            onChange={updateType}
          />
        }
        {/* relationship type on of set */}
        {allowedTypes !== undefined
          && <TextField
            select
            autoFocus
            label={t('household:relationModal.relationship')}
            data-testid="relation-user-type"
            sx={{ width: '100%' }}
            value={user.relationType}
            onChange={updateType}
          >
            {allowedTypes.map((type) => <MenuItem key={type} value={type}>{t(`affiliationTypes:${type}`)}</MenuItem>)}
          </TextField>
        }
      </ListItem>
      <ListItem>
        <TextField
          label={t('household:relationModal.userEmail')}
          sx={{ width: '100%' }}
          data-testid="relation-user-email"
          value={user.primaryEmail}
          onChange={updateField}
          id='primaryEmail'
          type='email'
        />
      </ListItem>

      {!isNewUser && hideSubmitButton ? (<ListItem>
        <Button variant="outlined" disabled={disabled} onClick={verifyUser} fullWidth>{t('household:relationModal.verifyUser')}</Button>
      </ListItem>) : null}

      {showVerificationFail && !isNewUser ? (<ListItem>
        <Typography color='error'>
          {t('household:relationModal.errorMessage')}
          &nbsp;
          <u data-testid="error-message" style={{ cursor: 'pointer', color: colors.primary }} onClick={() => setIsNewUser(true)}>
            {t('household:relationModal.here')}
          </u>
          &nbsp;
          {t('household:relationModal.createMessage')}
        </Typography>
      </ListItem>) : null}
      {isNewUser ? (
        <>
          <ListItem>
            <TextField
              label={t('household:relationModal.firstName')}
              sx={{ width: '100%' }}
              data-testid="relation-user-firstName"
              value={user.firstName}
              onChange={updateField}
              id='firstName'
            />
          </ListItem>
          <ListItem>
            <TextField
              label={t('household:relationModal.lastName')}
              sx={{ width: '100%' }}
              data-testid="relation-user-lastName"
              value={user.lastName}
              onChange={updateField}
              id='lastName'
            />
          </ListItem>
        </>
      ) : null}
      {isNewUser && hideSubmitButton ? (<ListItem>
        <Button disabled={addUserDisabled} variant="outlined" onClick={addNewUser} fullWidth>{t('household:relationModal.createAUser')}</Button>
      </ListItem>) : null}
    </CreateNewModal>
  );
};

export default NewRelationshipUser;
