import { User } from './user';
import { Organization } from './organization';
import { OrganizationUser } from './organizationUser';

export enum FileDocumentObjectTypes {
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  SUBACCOUNT = 'SUBACCOUNT',
  CLIENTGROUP = 'CLIENTGROUP',
  ORGANIZATION = 'ORGANIZATION',
  GOAL = 'GOAL',
  SCHEDULED_TRANSFER = 'SCHEDULED_TRANSFER',
  BULK_IMPORT = 'BULK_IMPORT',
  ORGANIZATION_USER = 'ORGANIZATION_USER',
}

export enum FileDocumentTypes {
  FORM = 'FORM',
  INVOICE = 'INVOICE',
  NOTES = 'NOTES',
  OTHER = 'OTHER',
  STATEMENT = 'STATEMENT',
  BANKING = 'BANKING',
  LOGO = 'LOGO',
  BULK_IMPORT = 'BULK_IMPORT',
  ID_VERIFICATION = 'ID_VERIFICATION',
  WORKFLOW = 'WORKFLOW',
  ERROR_PAGE_BACKGROUND_IMAGE = 'ERROR_PAGE_BACKGROUND_IMAGE',
}

export enum FileDocumentPermissionTypes {
  ORGANIZATIONAL = 'ORGANIZATIONAL',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export interface FileDocument {
  id?: string,
  name: string,
  fileName: string,
  objectType: FileDocumentObjectTypes,
  mediaType: string,
  type: FileDocumentTypes,
  permissionType: FileDocumentPermissionTypes,
  requestId?: string,
  uploadedAt: Date,
  s3Key: string,
  sharedCustodian?: boolean,
  sharedClient?: boolean,
  signed?: boolean,
  objectId: string,
  sourceId?: string,
  creator?: OrganizationUser,
  creatorOrganization?: Organization,
  user?: User,
  sourceType?: string,
  requestIpAddress?: string,
}
