export interface CurrentWebHookType {
  objectType: EventObject;
  type: WebHookType;
  id: string;
  eventType: string;
  gql: string;
  mutationInputName: string;
  mutationName: string;
  signedJwtHeader: string;
  url: string;
  customHeaders: object;
  customPayloadAttributes: object;
}

export interface NewWebhook {
  objectType: EventObject | string;
  type: WebHookType | string;
  organizationId: string;
  eventType: string;
  gql: string;
  mutationInputName: string;
  mutationName: string;
  signedJwtHeader: string;
  url: string;
  customHeaders: object;
  customPayloadAttributes: object;
}

export interface Attributes {
  [key: string]: string;
}

export enum WebHookType {
  GRAPHQL = 'GRAPHQL',
  REST = 'REST',
}

export enum EventObject {
  Account = 'Account',
  AccountStatement = 'AccountStatement',
  AdministrativeAnnualFeeReport = 'AdministrativeAnnualFeeReport',
  ManagementAnnualFeeReport = 'ManagementAnnualFeeReport',
  ScheduledTransfer = 'ScheduledTransfer',
  SubAccount = 'SubAccount',
  TaxSlip = 'TaxSlip',
  Transfer = 'Transfer',
  User = 'User',
}
