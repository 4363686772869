import AddIcon from '@mui/icons-material/AddRounded';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  OrganizationUserEntityRelationTypes, User, OrganizationUser, Organization,
  OrganizationUserAccessTypes,
} from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { entityName } from '../../../../../util';
import {
  Box, Grid, Icon, Typography,
} from '../../../../1-primative';
import {
  Button, Dialog, Form, IconButton, MenuItem, SelectField, DataSearchSelect,
  Segment,
  SegmentedControl,
  SegmentValue,
  TextField,
  Switch,
} from '../../../../2-component';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { translateBackend } from '../../../../../assets/i18n/config';

enum GrantAccessActions {
  CREATE = 'createNewUser',
  INVITE = 'inviteUser',
}

const SEARCH_ORGANIZATIONS = gql`
query searchOrganizations($query: String!, $childrenFor: ObjectID) {
  fetchOrganizations(input: { filter: { searchText: $query, childrenFor: $childrenFor }}) {
    organizations {
      id
      name
    }
    totalCount
  }
}
`;

const FETCH_ROLES = gql`
query fetchRoles($input: FetchRolesInput!) {
  fetchRoles(input: $input) {
    roles {
      id
      translatedName {
        en
        fr
      }
      organization {
        name
      }
    }
  }
}
`;

const CREATE_ORGANIZATION_USER = gql`
mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
  createOrganizationUser(input: $input) {
    organizationUser {
      id
    }
  }
}
`;

const mapViewItem = (data: any) => (
  data?.fetchOrganizationUsers?.organizationUsers?.map(
    (o: OrganizationUser) => ({
      id: o.id,
      label: `${o.firstName} ${o.lastName ?? ''}${o.email && o.email !== o.firstName ? ` (${o.email})` : ''} - ${o.role?.translatedName?.en}`,
    }),
  )
);

const mapOrganization = (data: any) => (
  data?.fetchOrganizations?.organizations?.map((org: Organization) => ({
    id: org.id,
    label: org.name ?? '',
  }))
);

export const GrantAccessToEntityButton = ({
  entity,
  searchUsers,
  addEntityToOrganizationUser,
}: {
  entity?: User,
  searchUsers: (options: any) => void,
  addEntityToOrganizationUser: (options: any) => void,
}) => {
  const { t } = useTranslation(['components']);
  const [open, setOpen] = useState<boolean>(false);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [grantAccessAction, setGrantAccessAction] = useState<GrantAccessActions>(GrantAccessActions.CREATE);
  const emptyUser = {
    firstName: '',
    lastName: '',
    email: '',
    organizationId: '',
    roleId: '',
    autoInviteUser: true,
  };
  const [organizationUser, setOrganizationUser] = useState(emptyUser);
  const [newOrgUserRelation, setNewOrgUserRelation] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [orgUserAccess, setOrgUserAccess] = useState<{
    organizationUserId?: string,
    relation?: OrganizationUserEntityRelationTypes,
  }>({});
  const canCreateAndInvite = ['write:organization_users', 'write:invite_organization_users'].some((elem) => permissions.includes(elem));

  const handleClose = () => {
    setOpen(false);
    setOrgUserAccess({});
    setOrganizationUser(emptyUser);
    setNewOrgUserRelation('');
    setEmailError(false);
  };

  const { loading, data } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: organizationUser.organizationId || undefined },
        pagination: { perPage: 50 },
      },
    },
  });

  useEffect(() => {
    if (!organizationUser.organizationId) setOrganizationUser({ ...organizationUser, roleId: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUser.organizationId]);

  const [searchOrganizations] = useLazyQuery(SEARCH_ORGANIZATIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const [createOrganizationUser] = useMutation(CREATE_ORGANIZATION_USER, {
    variables: {
      input: {
        ...organizationUser,
        accessType: OrganizationUserAccessTypes.ENTITY,
      },
    },
    onCompleted: (dataOrgUser) => {
      const organizationUserId = dataOrgUser.createOrganizationUser.organizationUser.id;
      addEntityToOrganizationUser({
        variables: {
          input: {
            entityId: entity?.id,
            organizationUserId,
            relation: newOrgUserRelation,
            readOnly: false,
          },
        },
      });
      handleClose();
    },
    onError: (error) => {
      if (['duplicate key error', organizationUser.email].every((elem) => error.message.includes(elem))) setEmailError(true);
    },
  });

  const segments: Segment[] = [
    { value: GrantAccessActions.CREATE, label: t('components:accessToEntity.addButton.toggle.create') },
    { value: GrantAccessActions.INVITE, label: t('components:accessToEntity.addButton.toggle.invite') },
  ];

  const handleSubmit = () => {
    if (!canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE) {
      addEntityToOrganizationUser({
        variables: {
          input: {
            entityId: entity?.id,
            organizationUserId: orgUserAccess.organizationUserId,
            relation: orgUserAccess.relation,
            readOnly: false,
          },
        },
      });
      handleClose();
    } else {
      createOrganizationUser().then();
    }
  };

  const isSubmissionDisabled = !canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE
    ? !(orgUserAccess.organizationUserId && orgUserAccess.relation)
    : !(!Object.values(organizationUser).includes('') && newOrgUserRelation);

  return (
    <>
      <IconButton onClick={() => { setOpen(true); }}>
        <AddIcon data-testid='initiate-goal-wizard' />
      </IconButton>
      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <Box display="flex" flexDirection="row" justifyContent="end">
          <Box sx={{ cursor: 'pointer' }} display="flex" onClick={handleClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Grid container gap={canCreateAndInvite ? 2 : 4}>
            <Grid item xs={12}>
              <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('components:accessToEntity.addButton.formTitle', { entityName: entityName(entity) })}</Typography>
            </Grid>
            {canCreateAndInvite && (
              <SegmentedControl
                sx={{ mb: 4 }}
                value={grantAccessAction}
                segments={segments}
                exclusive={true}
                fullWidth
                enforceActive
                onChange={(value: SegmentValue) => {
                  if (grantAccessAction === GrantAccessActions.CREATE) {
                    setOrganizationUser(emptyUser);
                    setNewOrgUserRelation('');
                    setEmailError(false);
                  }
                  if (grantAccessAction === GrantAccessActions.INVITE) setOrgUserAccess({});
                  setGrantAccessAction(value as GrantAccessActions);
                }}
              />
            )}
            {canCreateAndInvite && grantAccessAction === GrantAccessActions.CREATE && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.firstName')}
                    fullWidth
                    value={organizationUser.firstName}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, firstName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.lastName')}
                    fullWidth
                    value={organizationUser.lastName}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, lastName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    label={t('components:accessToEntity.addButton.createTab.relation')}
                    value={newOrgUserRelation || ''}
                    onChange={(e: any) => setNewOrgUserRelation(e.target.value)}
                    fullWidth
                  >
                    {Object.values(OrganizationUserEntityRelationTypes).map((relation) => (
                      <MenuItem key={relation} value={relation}>{t(`components:accessToEntity.relations.${relation}`)}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.email')}
                    fullWidth
                    value={organizationUser.email}
                    onChange={(e: any) => {
                      setOrganizationUser({ ...organizationUser, email: e.target.value.trim() });
                      setEmailError(false);
                    }}
                    error={emailError}
                    errorText={t('components:accessToEntity.addButton.createTab.emailError')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataSearchSelect
                    label={t('components:accessToEntity.addButton.createTab.organization')}
                    dataId={organizationUser.organizationId}
                    setData={(id?: string) => setOrganizationUser({ ...organizationUser, organizationId: id ?? '' })}
                    searchData={searchOrganizations}
                    mapViewItem={mapOrganization}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    value={organizationUser.roleId}
                    label={t('components:accessToEntity.addButton.createTab.role')}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, roleId: e.target.value })}
                    fullWidth
                    disabled={organizationUser.organizationId === ''}
                  >
                    {
                      loading ? <MenuItem>...</MenuItem> : (
                        data.fetchRoles.roles.map((x: any) => (
                          <MenuItem key={x.id} value={ x.id }>{ translateBackend(x.translatedName) } - { x.organization.name }</MenuItem>
                        ))
                      )
                    }
                  </SelectField>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    checked={organizationUser?.autoInviteUser ?? true}
                    onChange={(checked) => setOrganizationUser({ ...organizationUser, autoInviteUser: checked })}
                    label={t('components:accessToEntity.addButton.createTab.autoInviteUser')}
                  />
                </Grid>
              </>
            )}
            {(!canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE) && (
              <>
                <Grid item xs={12}>
                  <SelectField
                    label={t('components:accessToEntity.addButton.relationLabel')}
                    value={orgUserAccess.relation || ''}
                    onChange={(e: any) => setOrgUserAccess((prev) => ({ ...prev, relation: e.target.value }))}
                    fullWidth
                  >
                    {Object.values(OrganizationUserEntityRelationTypes).map((relation) => (
                      <MenuItem key={relation} value={relation}>{t(`components:accessToEntity.relations.${relation}`)}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12}>
                  <DataSearchSelect
                    otherFilters={{
                      childrenForOrganizationId: activeOrganization.id ?? '',
                      parentForOrganizationId: entity?.organization.id ?? '',
                    }}
                    label={t('components:accessToEntity.addButton.orgUserSearchText')}
                    searchData={searchUsers}
                    dataId={orgUserAccess.organizationUserId || ''}
                    setData={(id?: string) => setOrgUserAccess((prev) => ({ ...prev, organizationUserId: id }))}
                    mapViewItem={mapViewItem}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={3} justifyContent='end' display='flex'>
              <Button
                type='submit'
                color='primary'
                disabled={isSubmissionDisabled}
                label={t('components:accessToEntity.addButton.grantAccessButton')}
              />
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
};
