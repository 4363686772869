import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewDashboard } from './components/newDashboard';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  CardContent,
  Card,
} from '../../2-component';

interface Dashboard {
  id: string,
  name: string,
  organization: {
    name: string,
  },
}
export const FETCH_DASHBOARDS = gql`
  query fetchOrganizationDashboards($input: FetchOrganizationDashboardsInput!) {
    fetchOrganizationDashboards(input: $input) {
      dashboards{
        id
        name
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

export const Dashboards = () => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const { t } = useTranslation(['orgSettings']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [sortField, setSortField] = usePageState('name', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_DASHBOARDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='end'>
          <Box display='flex'>
            { permissions.includes('write:dashboards') && <NewDashboard afterCreate={refetch} /> }
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell
                isSortable
                onClick={() => onSort('name')}
                sortDirection={sortDirection('name')}
              >{t('dashboardsTable.name')}</TableHeadCell>
              <TableHeadCell right>{t('dashboardsTable.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchOrganizationDashboards?.dashboards?.map((dashboard: Dashboard) => (
              <TableRow
                hover
                pointer
                key={dashboard.id}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate(`editDashboard/${dashboard.id}`)}
              >
                <TableCell>
                  {dashboard.name}
                </TableCell>
                <TableCell right>
                  {dashboard.organization.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchOrganizationDashboards?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};
