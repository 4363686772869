import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface EmptyStateTokens {
  emptyState: {
    borderColor?: string;
    backgroundColor?: string;
    borderRadius?: string;
  },
}

export const useEmptyStateTokens = (): EmptyStateTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: EmptyStateTokens = {
    emptyState: {
      borderColor: sys.color.outline,
      backgroundColor: sys.color.surface,
      borderRadius: sys.borderRadius.xl,
    },
  };

  return comp;
};
