import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { gql, useMutation, useQuery } from '@apollo/client';
import { IconButton } from '../../2-component';
import {
  Grid, Skeleton, Stack, Typography,
} from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import { BankAccount } from '../../../interfaces';
import BankAccountCard from '../bankAccountCard/bankAccountCard';
import { usePermissions } from '../../../providers/userContextProvider';
import AddBankAccount from '../../3-pattern/addBankAccount/addBankAccount';
import BankAccountCardSkeleton from '../bankAccountCard/bankAccountCardSkeleton';

const FETCH_BANK_ACCOUNTS = gql`
  query fetchBankAccounts($userId: ObjectID!) {
    fetchBankAccounts(input:{filter:{userId: $userId}}) {
      bankAccounts {
        id
        name
        bankAccountNumber
        institutionNumber
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($bankAccountId: ObjectID!) {
    deleteBankAccount(bankAccountId: $bankAccountId) {
      bankAccount {
        id
      }
    }
  }
`;

const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      bankAccount {
        id
      }
    }
  }
`;

export const LinkedBankAccounts = ({ userId, options }: { userId: string, options: any }) => {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[] | undefined>(undefined);
  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] = useState(false);

  const [updateBankAccount] = useMutation(UPDATE_BANK_ACCOUNT, { refetchQueries: [FETCH_BANK_ACCOUNTS] });
  const [deleteBankAccountMutation] = useMutation(DELETE_BANK_ACCOUNT, { refetchQueries: [FETCH_BANK_ACCOUNTS] });
  const { refetch } = useQuery(FETCH_BANK_ACCOUNTS, {
    skip: userId === '' || !permissions.includes('read:bank_account_basic'),
    variables: { userId },
    onCompleted: (data) => {
      setBankAccounts(data.fetchBankAccounts.bankAccounts);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <Stack direction={'row'} width={'100%'} justifyContent={'space-between'}>
        <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('reviewBankAccountInformation.title')}</Typography>
        {options.createAnotherBankAccount && (
          <IconButton label={t('components:bankAccountSelect:addBank')} onClick={() => setAddBankAccountDialogOpen(true)}>
            <AddIcon />
          </IconButton>
        )}
      </Stack>

      {bankAccounts === undefined && <Skeleton height={100} />}

      {bankAccounts && bankAccounts.length === 0 && <BankAccountCardSkeleton />}

      {bankAccounts
        && <Grid container spacing={2}>
          {(bankAccounts).map((acct, i) => {
            const onRemove = () => {
              deleteBankAccountMutation({ variables: { bankAccountId: acct.id } });
            };
            const onEdit = (newName: string) => {
              updateBankAccount({ variables: { input: { bankAccountId: acct.id, name: newName } } });
            };
            return (
              <Grid item key={i} xs={12} md={(bankAccounts.length > 1 ? 6 : 12)}>
                <BankAccountCard bankAccount={acct} options={options} onRemove={onRemove} onEdit={onEdit} />
              </Grid>);
          })}
        </Grid>
      }

      {addBankAccountDialogOpen
        && <AddBankAccount
          data-testid='add-bank-account'
          open={true}
          bankAccounts={bankAccounts ?? []}
          userId={userId}
          options={options}
          handleClose={() => {
            setAddBankAccountDialogOpen(false);
            refetch();
          }}
          afterCreate={async () => {
            setAddBankAccountDialogOpen(false);
            refetch();
          }}
        />
      }
    </>
  );
};
