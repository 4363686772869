import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '../../../../1-primative';
import { Card, CardContent, IconButton } from '../../../../2-component';
import { MarketValueChart } from '../../../../4-module/clientReportWidgets/marketValueChart/marketValueChart';
import { Accounts } from '../../../../4-module/clientReportWidgets/accounts/accounts';
import { Ledger } from '../../../../4-module/clientReportWidgets/ledger/ledger';
import { Holdings } from '../../../../4-module/clientReportWidgets/holdings/holdings';
import ErrorBoundary from './errorBoundary';
import { PageObjectType } from '../../../pageConfiguration/pageConfiguration';
import { HtmlTemplate } from '../../../../4-module/widgets/htmlTemplate';
import { RateOfReturns } from '../../../../4-module/clientReportWidgets/rateOfReturns/rateOfReturns';

export const ClientReportWidget = ({
  type,
  i,
  onDelete,
  onEdit,
  objectId,
  viewOnly = false,
  options,
  objectType,
  widgetId,
  userId,
}: {
  type: string;
  i: number;
  onDelete?: (id: number) => void;
  onEdit?: () => void;
  objectId: string;
  viewOnly?: boolean;
  options: any;
  objectType: PageObjectType;
  widgetId?: string;
  userId?: string;
}) => {
  const widget = (widgetType: string) => {
    switch (widgetType) {
      case 'MARKET_VALUE_CHART':
        return <MarketValueChart data-testid='market-value-chart' type={objectType} id={objectId} options={options} />;
      case 'ACCOUNTS':
        return <Accounts data-testid='accounts-widget' objectType={objectType} objectId={objectId} options={options} />;
      case 'LEDGER':
        return <Ledger data-testid='accounts-widget' objectType={objectType} objectId={objectId} options={options} />;
      case 'HOLDINGS':
        return <Holdings data-testid='holdings-widget' objectType={objectType} objectId={objectId} options={options} />;
      case 'HTML_TEMPLATE':
        return <HtmlTemplate data-testid='html-template-widget' objectType={objectType} objectId={objectId} options={options} />;
      case 'RATE_OF_RETURNS':
        return <RateOfReturns data-testid='rate-of-returns-widget' objectType={objectType} objectId={objectId} options={options} />;
      default:
        return (
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ justifyContent: 'space-between', display: 'flex' }}>
              <Typography variant='headingXSmall'>{type}</Typography>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <ErrorBoundary>
      <Box height='100%' sx={{ position: 'relative', mb: 4 }}>
        {widget(type)}
        {!viewOnly && onEdit && (
          <IconButton
            onClick={() => onEdit()}
            sx={{
              position: 'absolute',
              right: '54px',
              top: '8px',
              zIndex: '2',
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {!viewOnly && onDelete && (
          <IconButton
            onClick={() => onDelete(i)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
              zIndex: '2',
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ErrorBoundary>
  );
};
