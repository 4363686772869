import DownloadIcon from '@mui/icons-material/Download';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import ExportLoader from './exportLoader';
import { Box } from '../../../1-primative';
import {
  CircularProgress, Dialog, DialogTitle, IconButton,
} from '../../../2-component';
import { UserContext } from '../../../../providers/userContextProvider';

const EXPORT_REPORT = gql`
  mutation exportReport($input: ExportReportInput!) {
    exportReport(input: $input) {
      export {
        id
      }
    }
  }
`;

const DownloadModal = ({
  columns, filters, sortField, sortDesc, type,
}: { columns: string[], filters: any[], sortField: string, sortDesc: boolean, type: string }) => {
  const { t } = useTranslation(['customReports']);
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [exportId, setExportId] = useState<string | null>(null);

  const [exportReport, { data: exportData }] = useMutation(EXPORT_REPORT, {
    variables: {
      input: {
        type,
        columns,
        filters: [{
          field: 'organization',
          comparison: 'EQUALS',
          value: activeOrganization.id,
        }, ...filters],
        sortDesc,
        sortField,
      },
    },
  });

  useEffect(() => {
    if (exportData?.exportReport?.export?.id) {
      setExportId(exportData?.exportReport?.export?.id);
    }
  }, [exportData]);

  const handleClose = () => {
    setOpen(false);
  };

  const download = () => {
    exportReport();
    setOpen(true);
  };

  return (
    <>
      <IconButton onClick={download} sx={{ mr: 1 }}>
        <DownloadIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{ t('downloadCsv') }</DialogTitle>
        { exportId ? (
          <ExportLoader exportId={exportId} />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default DownloadModal;
