import { useState } from 'react';
import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';
import { RefinitivSecurityModal } from '../../../refinitivSecurityModal/refinitivSecurityModal';

export const MarketTiles = ({ options }: { options: any }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ricCode, setRicCode] = useState('');

  useRefinitivWidget('#market-tiles-widget', 'WMarketTiles', {
    rics: options.marketTilesRics,
    onClick: (event: any) => {
      setRicCode(event.ric);
      setModalOpen(true);
    },
  });

  return (
    <Card>
      <div id='market-tiles-widget'></div>
      {modalOpen && (
        <RefinitivSecurityModal onClose={() => setModalOpen(false)} ricCode={ricCode} />
      )}
    </Card>
  );
};
