import { useState } from 'react';
import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { ReviewPortfolioChangesVisual } from './reviewPortfolioChanges.visual';
import { Goal, GoalStates } from '../../../../../interfaces';

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        financialProduct {
          id
          url
          riskReturnLevel
          theme {
            translatedName { en fr}
            translatedDescription { en fr}
          }
        }
        suggestedFinancialProduct {
          id
          url
          riskReturnLevel
          theme {
            translatedName { en fr}
            translatedDescription { en fr}
          }
        }
        subAccounts {
          id
          state
          account {
            type
          }
          financialProduct {
            id
            url
            riskReturnLevel
            theme {
              translatedName { en fr}
              translatedDescription { en fr}
            }
          }
          suggestedFinancialProduct {
            id
            url
            riskReturnLevel
            theme {
              translatedName { en fr}
              translatedDescription { en fr}
            }
          }
        }
      }
    }
  }
`;

export const ReviewPortfolioChanges = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const { loading } = useQuery(FETCH_GOALS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          userId,
          states: GoalStates.ACTIVE,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    onCompleted: (e) => {
      setGoals([...e.fetchGoals.goals]);
    },
  });

  const [updateSubAccount, { loading: subAccountLoading }] = useMutation(UPDATE_SUB_ACCOUNT);
  const [updateGoal, { loading: goalLoading }] = useMutation(UPDATE_GOAL);

  const onContinue = async () => {
    if (goals && goals.length > 0) {
      for await (const goal of goals) {
        if (goal.financialProduct) {
          if (goal?.suggestedFinancialProduct?.id) {
            await updateGoal({
              variables: {
                input: {
                  goalId: goal.id,
                  financialProductId: goal?.suggestedFinancialProduct?.id,
                },
              },
            });
          }
        }
        if (!goal.financialProduct && goal.subAccounts && goal.subAccounts.length > 0) {
          for await (const subAccount of goal.subAccounts) {
            if (subAccount?.suggestedFinancialProduct?.id) {
              await updateSubAccount({
                variables: {
                  input: {
                    subAccountId: subAccount.id,
                    financialProductId: subAccount?.suggestedFinancialProduct?.id,
                  },
                },
              });
            }
          }
        }
      }
    }
    onNext();
  };

  return (
    <ReviewPortfolioChangesVisual
      options={options}
      continueFunc={onContinue}
      loading={loading || stepLoading || subAccountLoading || goalLoading}
      goals={goals}
    />
  );
};

export default ReviewPortfolioChanges;
