import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Link as MuiLink, TextField, MenuItem, Grid,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatMoneyValue } from '../../../util';
import ReportModal from './modal';
import TrueFalse from '../../../components/misc/trueFalse';
import { usePageState } from '../../../util/usePageState';
import { UserContext } from '../../../providers/userContextProvider';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import ModelPortfoliosSelect from '../../../components/inputs/modelPortfoliosSelect';
import AccountTypeSelect from '../../../components/inputs/accountTypeSelect';
import Metrics from './metrics';

const FETCH_REBALANCE_REPORTS = gql`
  query fetchRebalanceReports($input: FetchRebalanceReportsInput!) {
    fetchRebalanceReports(input: $input) {
      rebalanceReports {
        subAccount {
          id
          financialProduct {
            name
          }
        }
        account {
          type
        }
        user {
          id
          firstName
          lastName
          organization{
            name
          }
        }
        marketValueCents
        cashCents
        expectedPercentage
        expectedCashCents
        cashOnHoldToTradeCents
        contributionRebalanceRequired
        driftRebalanceRequired
        liquidateRebalanceRequired
        lastReconciledAt
        lastRebalancedAt
      }
      totalCount
    }
  }
`;

const RebalancesTable = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['rebalanceReportV1']);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedPortfolioId, setSelectedPortfolioId] = usePageState('', 'product');
  const [selectedAccountType, setSelectedAccountType] = usePageState('ANY', 'accType');
  const [rebalanceType, setRebalanceType] = usePageState('ANY', 'filter');
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_REBALANCE_REPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          rebalanceType: rebalanceType !== 'ANY' ? rebalanceType : undefined,
          modelPortfolioIds: selectedPortfolioId !== '' ? [selectedPortfolioId] : undefined,
          organizationId: organizationId && organizationId?.length > 0 ? organizationId : activeOrganization.id,
          accountType: selectedAccountType !== 'ANY' ? selectedAccountType : undefined,
        },
        pagination: {
          sortField: 'id', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const percentDiff = (line: any) => {
    if (line.expectedCashCents === 0) {
      return '-100';
    }
    const value = ((line.expectedCashCents - line.cashCents) / ((1 / line.expectedPercentage) * line.expectedCashCents)) * 100;
    return value.toFixed(2);
  };

  const cashPercentage = (line: any) => {
    if (line.marketValueCents === 0) {
      return '0';
    }
    const value = (line.cashCents / line.marketValueCents) * 100;
    return value.toFixed(2);
  };

  if (error) (<Typography>Error</Typography>);

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id]);

  return (
    <Box sx={{ width: '100%', overflowY: 'auto' }}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ p: 2 }} spacing={1}>
            <Grid item xs={3}>
              <OrganizationSelect
                label={t('filters.selectOrganization')}
                onChange={(event: any) => setOrganizationId(event.target.value)}
                value={organizationId}
                childrenFor={activeOrganization.id}
                size='small'
              />
            </Grid>
            <Grid item xs={3}>
              <ModelPortfoliosSelect
                label={t('filters.selectPortfolio')}
                value={selectedPortfolioId}
                onChange={(e) => setSelectedPortfolioId(e.target.value)}
                size='small'
              />
            </Grid>
            <Grid item xs={3}>
              <AccountTypeSelect
              userType=''
                label={t('filters.selectAccount')}
                value={selectedAccountType}
                onChange={(value) => setSelectedAccountType(value)}
              />
            </Grid>
            <Grid item xs={3} display='flex' justifyContent='end'>
              <TextField
                select
                size='small'
                label={t('filters.rebalanceType')}
                value={rebalanceType}
                onChange={(e) => {
                  setRebalanceType(e.target.value);
                }}>
                <MenuItem value='ANY'>{t('any')}</MenuItem>
                <MenuItem value='FULL'>{t('full')}</MenuItem>
                <MenuItem value='CONTRIBUTION'>{t('contribution')}</MenuItem>
                <MenuItem value='DRIFT'>{t('drift')}</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container sx={{ p: 2 }} spacing={1} display='flex' justifyContent='space-between'>
            <Metrics organizationId={organizationId} />
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.client')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.account')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.portfolio')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.accountValue')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.cash')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.expectedCash')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.difference')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.cashPercentage')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.contributionRebalanceRequired')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.driftRebalanceRequired')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.liquidateRebalanceRequired')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchRebalanceReports?.rebalanceReports?.map((line: any) => (
                <TableRow
                  hover
                  key={line.subAccount.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => {
                    setActiveItem(line);
                    setModalOpen(true);
                  }}
                >
                  <TableCell>
                    <MuiLink component={Link} to={`/clients/${line.user?.id}`} target='_blank'>{line.user?.firstName} {line.user?.lastName}</MuiLink>
                  </TableCell>
                  <TableCell>{line.account.type}</TableCell>
                  <TableCell>{line.subAccount.financialProduct?.name}</TableCell>
                  <TableCell>{formatMoneyValue(line.marketValueCents)}</TableCell>
                  <TableCell>{formatMoneyValue(line.cashCents)}</TableCell>
                  <TableCell>{formatMoneyValue(line.expectedCashCents)}</TableCell>
                  <TableCell>{formatMoneyValue(line.expectedCashCents - line.cashCents)} ({percentDiff(line)}%)</TableCell>
                  <TableCell>{cashPercentage(line)}%</TableCell>
                  <TableCell><TrueFalse check={line.contributionRebalanceRequired} /></TableCell>
                  <TableCell><TrueFalse check={line.driftRebalanceRequired} /></TableCell>
                  <TableCell><TrueFalse check={line.liquidateRebalanceRequired} /></TableCell>
                  <TableCell>{line.user.organization.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchRebalanceReports?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          {activeItem && (
            <ReportModal item={activeItem} open={modalOpen} handleClose={() => setModalOpen(false)} />
          )}
        </>
      )}
    </Box>
  );
};

export default RebalancesTable;
