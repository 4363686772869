import {
  Box, Tabs, Tab, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../providers/userContextProvider';
import { usePageState } from '../../util/usePageState';
import ApiTokens from './components/apiTokens';
import OrgEmbeddedWebApp from './components/orgEmbeddedWebApp';
import { Integrations } from './components/integrations';
import WebHooks from './components/webHooks';

const TabPanel = (props: any) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  key: `tab-${index}`,
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const OrgSettings = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['devSettings']);
  const [value, setValue] = usePageState(0, 'tab');
  useNavigate();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const buildTabs = () => {
    const tabHeaders = [];
    const tabContents = [];
    let index = 0;
    if (permissions.includes('read:api_tokens')) {
      tabHeaders.push(<Tab label={t('tabs.apiTokens')} {...a11yProps(index)} disableRipple />);
      tabContents.push(
        <TabPanel key={`tab-${index}`} value={value} index={index}>
          <ApiTokens />
        </TabPanel>,
      );
      index += 1;
    }
    if (permissions.includes('read:organizations')) {
      tabHeaders.push(<Tab label={t('tabs.embeddedWebApp')} {...a11yProps(index)} disableRipple />);
      tabContents.push(
        <TabPanel key={`tab-${index}`} value={value} index={index}>
          <OrgEmbeddedWebApp />
        </TabPanel>,
      );
      index += 1;
    }
    if (permissions.includes('read:integrations')) {
      tabHeaders.push(<Tab label={t('tabs.integrations')} {...a11yProps(index)} disableRipple />);
      tabContents.push(
        <TabPanel key={`tab-${index}`} value={value} index={index}>
          <Integrations />
        </TabPanel>,
      );
      index += 1;
    }

    if (permissions.includes('read:webhook')) {
      tabHeaders.push(<Tab label={t('tabs.webHooks')} {...a11yProps(index)} disableRipple />);
      tabContents.push(
        <TabPanel key={`tab-${index}`} value={value} index={index}>
          <WebHooks />
        </TabPanel>,
      );
    }
    return {
      tabHeaders,
      tabContents,
    };
  };
  const { tabHeaders, tabContents } = buildTabs();

  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabHeaders}
        </Tabs>
      </Box>
      {tabContents}
    </Paper>
  );
};

export default OrgSettings;
