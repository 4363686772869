import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from 'providers/ovApolloProvider';
import { auth0RefreshTokenSilently } from 'providers/auth0Client';
import { CountryCodes } from '@onevesthq/ov-enums';
import { kebabCase } from 'lodash';
import {
  Account, Affiliation, eligibleTaxIdTypes, EmploymentStatus, encryptedTaxIdPlaceholder, OrganizationUserAccessTypes,
  taxIdTypeFormatType, TaxIdTypes, User,
} from '../../../../../../interfaces';
import { AffiliateType } from './affiliate';
import { Box, Grid, Typography } from '../../../../../1-primative';
import {
  AddressField, Button, Checkbox, DateField, Dialog, DialogContent, DialogFooter, DialogTitle,
  Form, IconButton, MenuItem, Radio, RadioGroup, SelectField, TextField,
} from '../../../../../2-component';
import { SelectionTile } from '../../../../../3-pattern';
import { RelationType } from '../accountConfig';
import { delay } from '../../../../../../util';
import { UserContext } from '../../../../../../providers/userContextProvider';

export const RELATION_TYPES = [
  RelationType.CHILD, RelationType.COMMON_LAW, RelationType.GRANDCHILD, RelationType.GRANDPARENT,
  RelationType.GUARDIAN, RelationType.NEPHEW_NIECE, RelationType.OTHER, RelationType.PARENT,
  RelationType.PRIMARY_CAREGIVER, RelationType.SIBLING, RelationType.SPOUSE,
];

const EMPLOYED_EMPLOYMENT_STATUSES = [EmploymentStatus.RETIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED];

const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      user { id }
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user { id }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user { id }
    }
  }
`;

export const UPDATE_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account {
        id
        affiliations { id allocation relation type }
      }
      incompleteAffiliations {
        user { id firstName lastName }
        type
        relation
        incompleteFields
      }
    }
  }
`;

export const AffiliateModal = ({
  open, setOpen, affiliate, action = 'create', fields = [], type, title, accountId, affiliates, refetch, allAffiliates, account, useAccountHoldersAddress,
}: {
  open: boolean, setOpen: (open: boolean) => void, affiliate?: Affiliation, action?: string, fields?: any[], type: AffiliateType, title?: string,
  accountId: string, affiliates: Affiliation[], refetch?: any, allAffiliates: any[], account: Partial<Account>, useAccountHoldersAddress?: boolean,
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { setAuthToken } = useAuthContext();
  const { userContext } = useContext(UserContext);
  const [affiliateData, setAffiliateData] = useState<any>(affiliate);
  const [focused, setFocused] = useState<string[]>([]);

  const [createAffiliate, { loading: createAffiliateLoading }] = useMutation(CREATE_AFFILIATE);
  const [createUser, { loading: createUserLoading }] = useMutation(CREATE_USER);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [updateAffiliations, { loading }] = useMutation(UPDATE_AFFILIATIONS);

  useEffect(() => {
    if (affiliate?.user && Object.keys(affiliate?.user).length !== 0) setAffiliateData(affiliate);
  }, [affiliate]);

  const calculateAllocation = (affType: AffiliateType) => Math.floor(100 / ((affiliates.filter((x: any) => x.type === affType).length || 0) + 1));

  const refreshAuthToken = async (): Promise<void> => {
    await delay(2000);
    const newToken = await auth0RefreshTokenSilently({ orgSubdomain: userContext.organization?.subdomain });
    if (newToken) setAuthToken(newToken);
  };

  const updateAccountAffiliations = (userId: string) => {
    updateAffiliations({
      variables: {
        input: {
          accountId,
          affiliations: [
            ...(allAffiliates || []).map((item: any) => ({
              allocation: item.type === type ? calculateAllocation(item.type) : item.allocation,
              relation: item.relation,
              type: item.type,
              userId: item.user.id,
            })),
            {
              userId,
              relation: affiliateData.relation || RelationType.OTHER,
              type,
              allocation: fieldTypes.includes('allocation') ? calculateAllocation(type) : 0,
            },
          ],
        },
      },
      onCompleted: async () => {
        refetch && refetch();
        setOpen(false);
        if (userContext.accessType === OrganizationUserAccessTypes.ENTITY) {
          await refreshAuthToken();
        }
      },
    });
  };

  const submit = () => {
    if (!validate()) return;

    if (action === 'create') {
      const userData = { ...affiliateData.user };
      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;

      if (type === AffiliateType.JOINT) {
        createUser({ variables: { input: userData }, onCompleted: (res) => updateAccountAffiliations(res?.createUser?.user.id) });
      } else {
        createAffiliate({ variables: { input: userData }, onCompleted: (res) => updateAccountAffiliations(res?.createAffiliate?.user.id) });
      }
    } else {
      const userData = { ...affiliateData.user };

      delete userData?.id;
      delete userData?.taxIdExists;
      delete userData?.__typename;
      delete userData?.physicalAddress?.__typename;

      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;

      updateUser({
        variables: { input: { userId: affiliateData.user.id, ...userData } },
        onCompleted: () => {
          const newAff = allAffiliates.filter((item: any) => item.id !== affiliateData.id);

          updateAffiliations({
            variables: {
              input: {
                accountId,
                affiliations: [
                  ...newAff.map((item: Affiliation) => ({
                    allocation: item.allocation,
                    relation: item.relation,
                    type: item.type,
                    userId: item.user.id,
                  })),
                  {
                    userId: affiliateData.user.id,
                    relation: affiliateData.relation || RelationType.OTHER,
                    type,
                    allocation: affiliateData.allocation,
                  },
                ],
              },
            },
            onCompleted: () => {
              refetch && refetch();
              setOpen(false);
            },
          });
        },
      });
    }
  };

  const fieldTypes = fields.map((field: any) => (field.type));
  const isDisabled = createAffiliateLoading || createUserLoading || updateLoading || loading;

  const validate = () => {
    const invalidFields: string[] = [];

    if (fieldTypes.includes('fullName') && !affiliateData.user.firstName) invalidFields.push('firstName');
    if (fieldTypes.includes('fullName') && !affiliateData.user.lastName) invalidFields.push('lastName');
    if (fieldTypes.includes('primaryEmail') && !affiliateData.user.primaryEmail) invalidFields.push('primaryEmail');
    if (fieldTypes.includes('physicalAddress') && !affiliateData.user.physicalAddress?.streetName) invalidFields.push('physicalAddress');
    if (fieldTypes.includes('dateOfBirth') && !affiliateData.user.dateOfBirth) invalidFields.push('dateOfBirth');
    if (fieldTypes.includes('dateOfDeath') && !affiliateData.user.dateOfDeath) invalidFields.push('dateOfDeath');
    if (fieldTypes.includes('gender') && !affiliateData.user.gender) invalidFields.push('gender');
    if (fieldTypes.includes('relation') && !affiliateData.relation) invalidFields.push('relation');

    const isTaxIdInvalid = !affiliateData.user.taxId || (affiliateData.user.taxId && affiliateData.user.taxId.length !== 9);
    if (fieldTypes.includes('taxId') && !affiliateData.user.taxIdExists && isTaxIdInvalid) invalidFields.push('taxId');

    if (fieldTypes.includes('employmentStatus')) {
      if (!affiliateData.user.employmentStatus) invalidFields.push('employmentStatus');

      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.companyType) invalidFields.push('companyType');
      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.jobTitle) invalidFields.push('jobTitle');
      if (affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && !affiliateData.user.studentAreaOfStudy) invalidFields.push('studentAreaOfStudy');
    }

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{title ?? t(`button.${type}`)}</Typography>
          <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Form onSubmit={submit}>
        <DialogContent>
          {fieldTypes.includes('fullName') && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-first-name"
                  label={t('client:details.firstName')}
                  value={affiliateData.user.firstName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, firstName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'firstName'])}
                  error={!affiliateData.user.firstName && focused.includes('firstName')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-middle-name"
                  label={t('client:details.middleName')}
                  value={affiliateData.user.middleName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, middleName: e.target.value } })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  testId="affiliate-last-name"
                  label={t('client:details.lastName')}
                  value={affiliateData.user.lastName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, lastName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'lastName'])}
                  error={!affiliateData.user.lastName && focused.includes('lastName')}
                />
              </Grid>
            </Grid>
          )}
          {fieldTypes.includes('physicalAddress') && (
            <AddressField
              testId="affiliate-physical-address"
              sx={{ mt: 2 }}
              address={affiliateData.user.physicalAddress || {}}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: e } })}
              label={t('client:details.physicalAddress')}
              onBlur={() => setFocused([...focused, 'physicalAddress'])}
              error={!affiliateData.user.physicalAddress?.streetName && focused.includes('physicalAddress')}
              manualAddressEntry={true}
            />
          )}
          {useAccountHoldersAddress && (
            <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
              <Checkbox
                testId="assign-account-holder-address"
                chip
                label={t('affiliationTypes:assignAccountHoldersAddress')}
                checked={false}
                onChange={(checked: boolean) => {
                  if (checked) {
                    const accountHolderAddress = account.user?.physicalAddress;
                    delete accountHolderAddress.__typename;
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: accountHolderAddress } });
                  } else {
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: '' } });
                  }
                }}
              />
            </Box>
          )}
          {fieldTypes.includes('dateOfBirth') && (
            <DateField
              dataTestId="affiliate-date-of-birth"
              sx={{ mt: 2 }}
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfBirth')}
              fullWidth
              value={affiliateData.user.dateOfBirth || ''}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              error={!affiliateData.user.dateOfBirth && focused.includes('dateOfBirth')}
            />
          )}
          {fieldTypes.includes('dateOfDeath') && (
            <DateField
              dataTestId="affiliate-date-of-death"
              sx={{ mt: 2 }}
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfDeath: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfDeath')}
              fullWidth
              value={affiliateData.user.dateOfDeath || ''}
              onBlur={() => setFocused([...focused, 'dateOfDeath'])}
              error={!affiliateData.user.dateOfDeath && focused.includes('dateOfDeath')}
            />
          )}
          {fieldTypes.includes('taxId') && (
            <Box sx={{ mt: 2 }}>
              <TaxIdInputs
                theUser={account?.user}
                affiliateUser={affiliateData.user}
                setAffiliateUser={(user) => setAffiliateData({ ...affiliateData, user })}
                onBlur={() => setFocused([...focused, 'taxId'])}
                error={(!affiliateData.user.taxId || (affiliateData.user.taxId && affiliateData.user.taxId.length !== 9)) && focused.includes('taxId')}
              />
            </Box>
          )}
          {fieldTypes.includes('primaryEmail') && (
            <TextField
              testId="affiliate-primary-email"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.primaryEmail')}
              value={affiliateData.user.primaryEmail || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, primaryEmail: e.target.value } })}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={!affiliateData.user.primaryEmail && focused.includes('primaryEmail')}
            />
          )}
          {fieldTypes.includes('gender') && (
            <Box mt={2}>
              <Typography variant='labelSmall' colorVariant='variant' sx={{ mb: 1 }}>{t('client:details.gender')}</Typography>
              <SelectionTile
                testId="affiliate-gender"
                direction='row'
                value={affiliateData.user.gender || ''}
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, gender: e.target.value } })}
                options={[{ value: 'Male', label: t('client:details.Male') }, { value: 'Female', label: t('client:details.Female') }]}
              />
            </Box>
          )}
          {fieldTypes.includes('employmentStatus') && (
            <SelectField
              testId="affiliate-employment-status"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.employmentStatus')}
              value={affiliateData.user.employmentStatus || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, employmentStatus: e.target.value } })}
              onBlur={() => setFocused([...focused, 'employmentStatus'])}
              error={!affiliateData.user.employmentStatus && focused.includes('employmentStatus')}
            >
              <MenuItem data-testid="affiliate-employment-status-employed" value='EMPLOYED'>{t('client:edit.employmentStatusOptions.EMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-self-employed" value='SELF_EMPLOYED'>{t('client:edit.employmentStatusOptions.SELF_EMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-unemployed" value='UNEMPLOYED'>{t('client:edit.employmentStatusOptions.UNEMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-student" value='STUDENT'>{t('client:edit.employmentStatusOptions.STUDENT')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-retired" value='RETIRED'>{t('client:edit.employmentStatusOptions.RETIRED')}</MenuItem>
            </SelectField>
          )}
          {EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && (
            <>
              <TextField
                testId="affiliate-company-type"
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, companyType: e.target.value } })}
                label={t('client:details.companyType')}
                fullWidth
                sx={{ mt: 2 }}
                value={affiliateData.user.companyType}
                onBlur={() => setFocused([...focused, 'companyType'])}
                error={!affiliateData.user.companyType && focused.includes('companyType')}
              />
              <TextField
                testId="affiliate-job-title"
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, jobTitle: e.target.value } })}
                label={t('client:details.jobTitle')}
                fullWidth
                sx={{ mt: 2 }}
                value={affiliateData.user.jobTitle}
                onBlur={() => setFocused([...focused, 'jobTitle'])}
                error={!affiliateData.user.jobTitle && focused.includes('jobTitle')}
              />
            </>
          )}
          {affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && (
            <TextField
              testId="affiliate-area-of-study"
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, studentAreaOfStudy: e.target.value } })}
              label={t('client:details.studentAreaOfStudy')}
              fullWidth
              sx={{ mt: 2 }}
              value={affiliateData.user.studentAreaOfStudy}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              error={!affiliateData.user.studentAreaOfStudy && focused.includes('studentAreaOfStudy')}
            />
          )}
          {fieldTypes.includes('relation') && (
            <SelectField
              testId="affiliate-relation"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.relation')}
              value={affiliateData.relation || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, relation: e.target.value })}
              onBlur={() => setFocused([...focused, 'relation'])}
              error={!affiliateData.relation && focused.includes('relation')}
            >
              {fields.find((field) => field.type === 'relation').options
                ? fields.find((field) => field.type === 'relation').options.map((item: any, idx: number) => (
                  <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
                ))
                : RELATION_TYPES.map((item: any, idx: number) => (
                  <MenuItem data-testid={`affiliate-relation-${item}`} key={idx} value={item}>{t(`client:details.relationOptions.${item}`)}</MenuItem>
                ))}
            </SelectField>
          )}
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button dataTestId="create-btn" label={t(`client:form.${action === 'create' ? 'add' : 'update'}`)} type='submit' variant='tonal' disabled={isDisabled} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

const TaxIdInputs = ({
  theUser, affiliateUser, error, setAffiliateUser, onBlur,
}: {
  theUser: Partial<User> | undefined, affiliateUser: Partial<User>, error: boolean
  setAffiliateUser: (user: Partial<User>) => void, onBlur: () => void,
}) => {
  const { t } = useTranslation();
  const [showEncryptedTaxId, setShowEncryptedTaxId] = useState<string | undefined>();
  const [taxIdType, setTaxIdType] = useState<TaxIdTypes | undefined>(affiliateUser.taxIdType);
  const [taxId, setTaxId] = useState<string | undefined>();

  const countryOfTaxResidence = theUser?.countryOfTaxResidence ?? CountryCodes.CA;
  const taxIdTypesShown = eligibleTaxIdTypes(countryOfTaxResidence, true);
  const defaultTaxIdType = taxIdTypesShown[0];

  // no taxIdType selected yet - pick default
  useEffect(() => {
    if (!taxIdType && defaultTaxIdType) {
      setTaxIdType(defaultTaxIdType);
    }
  }, [taxIdType, defaultTaxIdType, setTaxIdType]);

  // show encrypted "*** *** ***" until touched
  useEffect(() => {
    if (affiliateUser.taxIdExists !== undefined && !taxId) {
      setShowEncryptedTaxId(encryptedTaxIdPlaceholder(taxIdType));
    }
  }, [affiliateUser.taxIdExists, taxId, taxIdType]);

  // propagate upwards
  useEffect(() => {
    if (taxId !== undefined) setAffiliateUser({ ...affiliateUser, taxIdType, taxId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxId]);

  const taxIdLabel = `${t(`taxId:${taxIdType}`)} (${t(`taxId:abbreviated.${taxIdType}`)})`;

  return (
    <>
      {taxIdTypesShown.length > 1 && (
        <RadioGroup
          testId='tax-id-types'
          label={t('workflowCompletions:residencyInformation.taxIdType')}
          value={taxIdType ?? ''}
          onChange={(e: any) => setTaxIdType(e.target.value)}
          data-testid="tax-id-type"
        >
          {taxIdTypesShown.map((type) => <Radio key={type} testId={`tax-id-type-${kebabCase(type)}`} label={type} value={type} size='small' />)}
        </RadioGroup>
      )}

      <TextField
        testId="affiliate-taxId"
        fullWidth
        sx={{ mt: 2 }}
        type={taxIdTypeFormatType(taxIdType)}
        label={taxIdLabel}
        value={showEncryptedTaxId ? '' : taxId}
        placeholder={showEncryptedTaxId}
        onChange={(e: any) => {
          if (showEncryptedTaxId) setShowEncryptedTaxId(undefined);
          setTaxId(e.target.value);
        }}
        onBlur={onBlur}
        error={error}
      />
    </>
  );
};
