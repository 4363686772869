/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import fileUploadIcon from 'assets/images/icons-outline/file-upload-icon.svg';
import { useThemeTokens } from 'providers/themeTokenProvider';
import {
  Typography, Box,
} from 'ovComponents/1-primative';
import {
  Button,
} from 'ovComponents/2-component';

export enum FileTypes {
  PDF = 'pdf',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  HEIC = 'heic',
  CSV = 'csv',
  GIF = 'gif',
  JSON = 'json',
}

interface Props {
  onFileChosen: (file: File) => void,
  fileMaxSize?: number,
  acceptedTypes?: FileTypes[],
  error?: boolean,
  thumbNail?: boolean,
  hide?: boolean,
}

/**
 * extension to input type='file' with drag-n-drop feature and some graphical design
 */
export const DroppableFileInput = ({
  onFileChosen, fileMaxSize = 10, acceptedTypes = Object.values(FileTypes), error = false, thumbNail = false, hide,
}: Props): JSX.Element | null => {
  const [file, setFile] = useState<File>();
  const [draggedOver, setDraggedOver] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<false | number>(false);
  const [fileTypeError, setFileTypeError] = useState<false | string>(false);
  const { t } = useTranslation('document');
  const { sys } = useThemeTokens();

  const readableFileSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} bytes`;
    const kB = bytes / 1024;
    const fileSizeMb = (kB / 1024).toFixed(1);
    if (kB < 1024) return `${kB.toFixed()} kB`;
    return `${fileSizeMb} MB`;
  };

  const fileSizeOverLimit = (choosenFile: File): boolean => {
    const { size } = choosenFile;
    return Number(size) > fileMaxSize * 1024 * 1024;
  };

  const fileTypeMatch = (chosenFile: File): boolean => {
    const { type } = chosenFile;
    return acceptedTypes!.some((fileType) => type.includes(fileType));
  };

  const onFilesDropped = (files: FileList): void => {
    if (files.length === 0) return;
    const droppedFile = files[0];

    setFile(undefined);
    setFileTypeError(false);
    setFileSizeError(false);

    if (!fileTypeMatch(droppedFile)) {
      setFileTypeError(droppedFile.type);
      return;
    }
    if (fileSizeOverLimit(droppedFile)) {
      setFileSizeError(droppedFile.size);
      return;
    }
    setFile(droppedFile);
    onFileChosen(droppedFile);
  };

  return (
    <Box
      sx={{
        minHeight: thumbNail ? '90px' : '136px',
        height: thumbNail ? '90px' : '136px',
        backgroundColor: draggedOver ? sys.color.neutralHover : sys.color.surface,
        borderStyle: file ? 'solid' : 'dashed',
        borderWidth: '1px',
        borderColor: error || fileSizeError ? sys.color.negative : sys.color.outline,
        borderRadius: sys.borderRadius.md,
        display: hide ? 'none' : 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        marginBottom: thumbNail ? '1px' : '16px',
      }}
      onDrop={(e) => {
        onFilesDropped(e.dataTransfer.files);
        e.preventDefault();
        setDraggedOver(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setDraggedOver(true);
      }}
      onDragStart={() => { setDraggedOver(true); }}
      onDragEnter={() => { setDraggedOver(true); }}
      onDragLeave={() => { setDraggedOver(false); }}
      onDragEnd={() => { setDraggedOver(false); }}
    >
      {!file
        && (
          <>
            <img src={fileUploadIcon} alt="file-upload" />
          </>
        )}
      {file
        && (
          <>
            <InsertDriveFileIcon fontSize="large" color="secondary" sx={{ marginTop: '16px', color: sys.color.onSurfaceVariant }} />
            <Typography variant="bodyMedium" sx={{ marginBottom: '0px' }}>
              {file.name}
            </Typography>
            <Typography variant="bodyMedium" sx={{ marginBottom: '0px' }}>
              {t('droppableFileInput.fileSize')}
              :
              &nbsp;
              {readableFileSize(file.size)}
            </Typography>
          </>
        )}
      <Typography variant="bodyMedium">
        <Button
          variant='text'
          component='label'
          label={file ? t('droppableFileInput.chooseAnotherFile') : t(`droppableFileInput.${thumbNail ? 'browse' : 'clickToBrowse'}`)}
          sx={{
            background: 'none',
            textDecoration: 'underline',
            cursor: 'pointer',
            m: thumbNail ? 0.5 : 1,
          }}
        >
          <input
            hidden
            type="file"
            accept={`.${acceptedTypes?.join(', .')}`}
            data-testid="fileDropzone"
            onInput={(e: ChangeEvent<HTMLInputElement & FileList>) => {
              if (e.target.files) {
                onFilesDropped(e.target.files);
              }
            }}
          />
        </Button>
      </Typography>
      {!file && (
        <Typography fontSize="small" colorVariant='variant' variant='bodySmall'>
          {t(`droppableFileInput.${thumbNail ? 'max' : 'maxDocumentSize'}`, { fileMaxSize: fileMaxSize!.toString() })}
        </Typography>

      )}
      {error && (
        <Typography fontSize="small" color={sys.color.negative} variant='bodySmall'>
          {t('droppableFileInput.documentRequired')}
        </Typography>
      )}
      {fileSizeError && (
        <Typography fontSize="small" color={sys.color.negative} variant='bodySmall'>
          {t('droppableFileInput.fileIsAboveMaxSize', { size: readableFileSize(fileSizeError) })}
        </Typography>
      )}
      {fileTypeError && (
        <Typography fontSize="small" color={sys.color.negative} variant='bodySmall'>
          {t('droppableFileInput.fileIsUnsupportedType', { type: fileTypeError, acceptedTypes })}
        </Typography>
      )}
    </Box>
  );
};

export default DroppableFileInput;
