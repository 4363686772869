import {
  Button, IconButton, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { Delete } from '@mui/icons-material';

const SelectableFileInput = ({ title, onFileChosen }: { title: string, onFileChosen: (file: File | undefined) => void }) => {
  const [file, setFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>();
  const { t } = useTranslation('client');

  const textStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    m: '10px',
  };

  return (
    <>
      {!file
        && <>
          <Button
            variant='outlined'
            size='large'
            fullWidth
            component="label"
          >
            {title ?? t('droppableFileInput.chooseAFile')}
            <input
              hidden
              type='file'
              accept='image/*'
              onChange={(e: ChangeEvent<HTMLInputElement & FileList>) => {
                if (e.target.files) {
                  setFile(e.target.files[0]);
                  onFileChosen(e.target.files[0]);
                  setImageUrl(URL.createObjectURL(e.target.files[0]));
                }
              }}
            />
          </Button>
        </>
      }
      {file
        && <>
          <ListItem >
            <ListItemIcon sx={{ maxHeight: '50px', maxWidth: '50px' }}>{
              <img src={imageUrl} alt='logo' width="100%" style={{ borderTop: '1px solid grey' }} />}
            </ListItemIcon>
            <ListItemText sx={textStyle} primary={file.name} />
            <IconButton sx={{ color: '#1D1D1D' }} onClick={() => {
              setFile(undefined);
              onFileChosen(undefined);
              setImageUrl(undefined);
            }}>
              <Delete />
            </IconButton>
          </ListItem>
        </>
      }
    </>
  );
};

export default SelectableFileInput;
