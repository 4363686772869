import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Box, Button, Chip, CircularProgress, Grid, Paper, Typography,
} from '@mui/material';
import {
  BulkImport, BulkImportStates, getStatusColor, ImportFileStates,
} from '../../interfaces/bulkImport';
import { getOrganizationUserName } from '../../interfaces/organizationUser';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ImportFilesTable, { FETCH_IMPORT_FILES } from './components/importFilesTable';
import { useLocalization } from '../../util/useLocalization';

export const FETCH_BULK_IMPORT = gql`
  query fetchBulkImport($bulkImportId: ObjectID!) {
    fetchBulkImport(bulkImportId: $bulkImportId) {
      bulkImport {
        id
        organization { id name }
        organizationUser { id firstName lastName }
        state
        createdAt
      }
    }
  }
`;

export const FETCH_QUEUED_FILES = gql`
  query fetchQueuedImportFiles($bulkImportId: ObjectID!) {
    fetchImportFiles(input: {
      filter:{
        bulkImportId: $bulkImportId,
        states: ${ImportFileStates.QUEUED},
      },
      pagination: { perPage:0 },
    }) {
      totalCount
    }
  }
`;

const TRANSITION_BULK_IMPORT = gql`
  mutation transitionBulkImport($input: TransitionBulkImportInput!){
    transitionBulkImport(input: $input) {
      bulkImport {
        id
        state
      }
    }
  }
`;

const BulkImportFiles = () => {
  const { t } = useTranslation('bulkImport');
  const { localizedDateTime } = useLocalization();
  const params = useParams();
  const { bulkImportId } = params;
  const [bulkImport, setBulkImport] = useState<BulkImport>({});
  const [hasQueuedFiles, setHasQueuedFiles] = useState(false);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const {
    data, loading, startPolling, stopPolling, refetch,
  } = useQuery(FETCH_BULK_IMPORT, {
    variables: {
      bulkImportId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: queuedFilesCountData, refetch: refetchQueuedFilesCount } = useQuery(FETCH_QUEUED_FILES, {
    variables: {
      bulkImportId,
    },
    fetchPolicy: 'no-cache',
  });

  const [transitionBulkImport] = useMutation(TRANSITION_BULK_IMPORT, { refetchQueries: [FETCH_BULK_IMPORT, FETCH_IMPORT_FILES] });

  const processBulkImport = async () => {
    await transitionBulkImport({
      variables: {
        input: {
          bulkImportId,
          transition: 'process',
        },
      },
    });
    showToast({ severity: 'info', message: t('bulkImport:processBulkImportMessage') });
    refetch();
  };

  const disabledProcessButton = !hasQueuedFiles || !bulkImport.state || ![BulkImportStates.FAILED, BulkImportStates.INITIATED].includes(bulkImport.state);

  useEffect(() => {
    if (data) {
      setBulkImport(data.fetchBulkImport.bulkImport);
      if (data.fetchBulkImport.bulkImport.state !== BulkImportStates.PROCESSING) {
        stopPolling();
        refetchQueuedFilesCount();
      } else {
        startPolling(5000);
      }
    }
  }, [data, stopPolling, startPolling, refetchQueuedFilesCount]);

  useEffect(() => {
    setHasQueuedFiles(!!queuedFilesCountData?.fetchImportFiles?.totalCount);
  }, [queuedFilesCountData]);

  return (
    <Box>
      {loading || !bulkImport ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container spacing={1} sx={{ paddingRight: 1 }}>
            <Grid item xs={8}>
              <Box sx={{ width: '100%' }}>
                <Typography>{t('table.organization')}: &nbsp; {bulkImport.organization?.name}</Typography>
                <Typography>{t('table.createdAt')}: &nbsp; {localizedDateTime(bulkImport.createdAt)}</Typography>
                <Typography>{t('table.createdBy')}: &nbsp; {getOrganizationUserName(bulkImport.organizationUser)}</Typography>
                <Chip label={t(`bulkImport:states.${bulkImport.state}`)} size='small' color={getStatusColor(bulkImport.state)} sx={{ mt: 1 }} />
              </Box>
            </Grid>
            {permissions.includes('write:bulk_import') && (
              <Grid item xs={4} alignSelf='end' >
                <Grid container justifyContent='flex-end' spacing={1} sx={{ paddingRight: 1 }}>
                  <Grid item>
                    <Button disabled={disabledProcessButton} variant='contained' onClick={processBulkImport}>{t('process')}</Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Paper sx={{ width: '100%', marginTop: 5, marginBottom: 10 }}>
            <ImportFilesTable bulkImport={bulkImport} />
          </Paper>
        </>
      )}
    </Box>
  );
};

export default BulkImportFiles;
export { BulkImportBreadcrumb } from './components/bulkImportBreadcrumb';
