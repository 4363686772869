import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import EntityRedirectIcon from '../../../../../../assets/images/icons-outline/arrow-entity-redirect.svg';
import { Box, Typography } from '../../../../../1-primative';
import { Button, Dialog, DialogContent } from '../../../../../2-component';

export const NonIndividualSetupConfirmationDialog = ({
  isModalOpen,
  setIsModalOpen,
  createNonIndividualEntity,
  title,
  text,
  loading,
}: {
  isModalOpen: boolean,
  setIsModalOpen: (value: boolean) => void,
  createNonIndividualEntity?: () => void,
  title: string,
  text: string,
  loading: boolean,
}) => {
  const { t } = useTranslation(['client', 'entityTypes', 'shared']);
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      maxWidth='sm'
      fullWidth
    >
      <DialogContent style={{ padding: 20 }}>
        <Box display='flex' alignItems='center' flexDirection='column' gap={4}>
          <img src={EntityRedirectIcon} width={130} height={120} alt='entityRedirectIcon' />
          <Typography variant='headingLarge' sx={{ mt: -4 }}>{title}</Typography>
          <Typography variant='bodyLarge' component={'div'} sx={{ mb: 3, width: '454px', textAlign: 'center' }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
          </Typography>
          <Box width={'364px'}>
            <Button fullWidth label={t('shared:continue')} disabled={loading} sx={{ mb: 3, textAlign: 'center', width: '364px' }} type='button' onClick={createNonIndividualEntity} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
