import { Typography } from '@mui/material';
import { colors } from '../../../../theme/colors';

const TaxRank = ({ taxRank }: { taxRank: number }) => (
  <span style={{
    background: colors.black, width: '12px', height: '12px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', borderRadius: '2px', marginRight: '4px',
  }}>
    <Typography sx={{ color: colors.white, fontSize: '10px' }}>{taxRank}</Typography>
  </span>
);

export default TaxRank;
