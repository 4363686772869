/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { Date } from '../../components/misc/date/date';
import { formatMoneyValue } from '../../util';
import { usePageState } from '../../util/usePageState';
import OrganizationSelect from '../../components/inputs/organizationSelect';
import { colors } from '../../theme/colors';

dayjs.extend(utc);

const FETCH_USERS = (permissions: string[]) => gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      users {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
        completedAt
        statistics {
          marketValueCents
        }
        organization {
          name
        }
      }
      totalCount
    }
  }
`;

const styles = {
  arrow: {
    fontSize: '14px',
    color: colors.selectedType,
    position: 'relative',
    top: '3px',
  },
};

const AnnualSuitabilityReview = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['annualSuitabilityReview']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [sortField, setSortField] = usePageState('lastSuitabilityReviewAt', 's');
  const [sortDesc, setSortDesc] = usePageState(true, 'desc');
  const [activeSort, setActiveSort] = useState('');
  const { loading, data } = useQuery(FETCH_USERS(permissions), {
    variables: {
      input: {
        filter: {
          suitabilityReviewDue: true,
          searchText,
          organizationId: organizationId && organizationId?.length > 0 ? organizationId : activeOrganization.id,
        },
        pagination: {
          sortField: sortField ?? 'lastSuitabilityReviewAt',
          sortDesc,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const daysPastDue = (date: Date | undefined): string => {
    const suitabilityReviewDate: Date = dayjs().utc().subtract(12, 'month').toDate();
    if (!date) return '';
    return dayjs(suitabilityReviewDate).diff(date, 'day').toString();
  };

  useEffect(() => {
    if (!organizationId && activeOrganization.id) setOrganizationId(activeOrganization.id);
  }, [activeOrganization.id]);

  const sortByField = (field: string) => {
    setActiveSort(field);
    if (field === 'ReverselastSuitabilityReviewAt') {
      setSortField('lastSuitabilityReviewAt');
    } else {
      setSortField(field);
    }
    setSortDesc(!sortDesc);
  };
  const cellPrefix = (field: string) => {
    if (field === 'ReverselastSuitabilityReviewAt' && field === activeSort) {
      return (sortDesc ? <ArrowDownwardIcon sx={styles.arrow} /> : <ArrowUpwardIcon sx={styles.arrow} />);
    }
    return (sortField === field && field === activeSort) ? (!sortDesc ? <ArrowDownwardIcon sx={styles.arrow} /> : <ArrowUpwardIcon sx={styles.arrow} />) : '';
  };
  return (
    <Paper sx={{ width: '100%' }}>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item xs={4}>
          <TextField
            label={t('search')}
            sx={{ width: '100%' }}
            size='small'
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            id='searchText'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <OrganizationSelect
            label={t('table.organization')}
            onChange={(event) => setOrganizationId(event.target.value)}
            value={organizationId}
            childrenFor={activeOrganization.id}
            size='small'
          />
        </Grid>
      </Grid>
      <Box>
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        ) : (
          <>
            <Grid container sx={{ p: 2 }} spacing={1}>
              <Grid item xs={1}>
                {loading ? (<CircularProgress size='30px' sx={{ marginTop: '6px', float: 'right' }} />) : <></>}
              </Grid>
            </Grid>
            <Grid container sx={{ overflow: 'hidden' }}>
              <Grid item xs={12} sx={{ overflow: 'auto' }}>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        onClick={() => sortByField('firstName')}
                        sx={{ cursor: 'pointer' }}
                      >
                        {cellPrefix('firstName')}
                        <Typography variant='overline'>{t('client')}</Typography></TableCell>
                      <TableCell
                        onClick={() => sortByField('completedAt')}
                        sx={{ cursor: 'pointer' }}
                      >
                        {cellPrefix('completedAt')}
                        <Typography variant='overline'>{t('completedAt')}</Typography>
                      </TableCell>
                      <TableCell
                        onClick={() => sortByField('lastSuitabilityReviewAt')}
                        sx={{ cursor: 'pointer' }}
                      >
                        {cellPrefix('lastSuitabilityReviewAt')}
                        <Typography variant='overline'>{t('previousInformationUpdate')}</Typography>
                      </TableCell>

                      <TableCell
                        onClick={() => sortByField('ReverselastSuitabilityReviewAt')}
                        sx={{ cursor: 'pointer' }}
                      >
                        {cellPrefix('ReverselastSuitabilityReviewAt')}
                        <Typography variant='overline'>{t('daysPastDue')}</Typography>
                      </TableCell>

                      <TableCell><Typography variant='overline'>{t('marketValue')}</Typography></TableCell>
                      <TableCell>
                        <Typography variant='overline'>{t('organization')}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.fetchUsers?.users?.map((user: any) => (
                      <TableRow
                        hover
                        key={user.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                        onClick={() => navigate(`/clients/${user.id}`)}
                      >
                        <TableCell component="th" scope="row">
                          {user.firstName} {user.lastName}
                        </TableCell>
                        <TableCell><Date variant='subtitle2' date={user.completedAt} /></TableCell>
                        <TableCell><Date variant='subtitle2' date={user.lastSuitabilityReviewAt} /></TableCell>
                        <TableCell>{daysPastDue(user.lastSuitabilityReviewAt)}</TableCell>
                        <TableCell>{formatMoneyValue(user.statistics?.marketValueCents)}</TableCell>
                        <TableCell>{user.organization?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Pagination
                count={Math.ceil((data?.fetchUsers?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default AnnualSuitabilityReview;
