import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Chip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { generateAddressString } from '../../../util';
import InstitutionViewer from './institutionViewer';
import NewInstitution from './newInstitution';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      institutions {
        id
        name
        physicalAddress{
          streetName
          city
          houseNumber
          neighborhood
          postal
          province
          unitNumber
        }
        isDraft
        state
        stateChanges
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;
export const stateInstitutionStateColor = (state: any) => (state === 'APPROVED' ? 'success' : ['REJECTED'].includes(state) ? 'error' : 'warning');
const calculateDaysWaiting = (bankAccount: any): number => {
  const createdDate = dayjs(bankAccount.createdAt).utc();
  const toDay = dayjs().utc();
  return toDay.diff(createdDate, 'days') || 0;
};

const InstitutionsTable = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['institutionsReview', 'client']);
  const { localizedDate } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const [currentInstitution, setCurrentInstitution] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [filterState, setFilterState] = usePageState('AWAITING_REVIEW', 'state');
  const [openViewer, setOpenViewer] = useState(false);
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_INSTITUTIONS, {
    variables: {
      input: {
        filter: {
          state: filterState === 'ANY' ? undefined : filterState,
          searchText: searchText === '' ? undefined : searchText,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: filterState === 'AWAITING_REVIEW', perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <>
      <Grid container display='flex' justifyContent='space-between'>
        <Grid item display='flex'>
          <TextField
            label={t('search')}
            sx={{ width: '300px', m: 2 }}
            size='small'
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            id='searchText'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <List sx={{ minWidth: '250px' }}>
            <ListItem>
              <TextField
                select
                size='small'
                label={t('institutionsReview:filters.state')}
                fullWidth
                value={filterState || ''}
                onChange={(e) => {
                  setPage(1);
                  setFilterState(e.target.value);
                }}>
                <MenuItem value='ANY'>{t('institutionsReview:filters.any')}</MenuItem>
                <MenuItem value='AWAITING_REVIEW'>{t('institutionsReview:filters.awaitingReview')}</MenuItem>
                <MenuItem value='APPROVED'>{t('institutionsReview:filters.approved')}</MenuItem>
                <MenuItem value='REJECTED'>{t('institutionsReview:filters.rejected')}</MenuItem>
              </TextField>
            </ListItem>
          </List>
        </Grid>
        <Grid item m={1}>
          {permissions.includes('write:institution_basic') && <NewInstitution />}
        </Grid>
      </Grid>

      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      ) : (
        <>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.state')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.requestedDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.approvedDate')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.daysWaiting')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('institutionsReview:filters.address')}</Typography></TableCell>
                {permissions.includes('transition:bank_account_basic') && (<TableCell><Typography variant='overline'>{t('institutionsReview:filters.actions')}</Typography></TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchInstitutions?.institutions?.map((institution: any) => (
                <TableRow
                  hover
                  key={institution.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell component="th" scope="row">
                    {institution.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip size='small' label={t(`bankAccountsReview:filters.stateOptions.${institution.state}`)} color={stateInstitutionStateColor(institution.state)} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography fontSize='small'>{localizedDate(institution.createdAt)}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {institution.state !== 'APPROVED' ? '-' : (<Typography fontSize='small'>{localizedDate(institution.updatedAt)}</Typography>)}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ color: calculateDaysWaiting(institution) > 3 ? 'red' : 'inherit' }}>
                    {['APPROVED', 'REJECTED'].includes(institution.state) && '-'}
                    {institution.state === 'AWAITING_REVIEW'
                      && (<Chip size='small' label={calculateDaysWaiting(institution)} color={calculateDaysWaiting(institution) > 3 ? 'error' : 'success'} />)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {generateAddressString(institution.physicalAddress)}
                  </TableCell>
                  {permissions.includes('write:institution_basic') && (
                    <TableCell align='right'>
                      <IconButton onClick={() => {
                        setCurrentInstitution(institution);
                        setOpenViewer(true);
                      }}>
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchInstitutions?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </Grid>
          <InstitutionViewer
            open={openViewer}
            handleClose={() => setOpenViewer(false)}
            institution={currentInstitution}
          />
        </>
      )}
    </>
  );
};

export default InstitutionsTable;
