import { Typography } from '@mui/material';
import { memberColors } from './memberColors';

interface MemberBadgeArgs {
  sequenceNumber: number
  initials: string
}

export const MemberBadge = ({ sequenceNumber, initials }: MemberBadgeArgs) => {
  const colors = memberColors(sequenceNumber);

  return (
  <Typography
    width='1.66em'
    height='1.66em'
    align={'center'}
    mr={1}
    sx={{
      backgroundColor: colors.bg, color: colors.fg, borderRadius: '100px', display: 'inline-block',
    }}
  >
    {initials}
  </Typography>
  );
};
