import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstitutionsTable from './components/institutionsTable';

const InstitutionsToReview = () => {
  useNavigate();
  return (
    <Paper sx={{ width: '100%' }}>
      <InstitutionsTable />
    </Paper>
  );
};

export default InstitutionsToReview;
