import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../providers/userContextProvider';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton,
  TextField, MenuItem,
} from '../../../2-component';
import { Box } from '../../../1-primative';

const CREATE_REPORT = gql`
  mutation createReport($input: CreateReportInput!) {
    createReport(input: $input) {
      report {
        id
      }
    }
  }
`;

const SaveAsReport = ({
  afterCreate, columns, filters, sortField, sortDesc, type, tonal,
}: { afterCreate: () => void, columns: string[], filters: any[], sortField: string, sortDesc: boolean, type: string, tonal?: boolean }) => {
  const { t } = useTranslation(['customReports', 'shared']);
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [report, setReport] = useState({
    name: '',
    accessType: 'ORGANIZATIONAL',
    organizationId: activeOrganization.id,
    columns,
    filters,
    type,
    sortField,
    sortDesc,
  });

  const [saveAsReport, { loading }] = useMutation(CREATE_REPORT, {
    variables: {
      input: report,
    },
  });

  useEffect(() => {
    setReport({
      ...report, organizationId: activeOrganization.id, columns, filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, columns, filters]);

  const saveAs = async (event: any) => {
    const response = await saveAsReport();
    if (response && response.data && !response.errors) {
      navigate(`/reports/${response.data.createReport.report.id}`);
    }
    afterCreate();
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} leadingIcon={SaveAsIcon} variant={tonal ? 'tonal' : 'filled'} label={t('saveAs.button')} />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            {t('saveAs.title')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={saveAs}>
            <TextField label={t('saveAs.name')} fullWidth value={report.name} onChange={(e: any) => setReport({ ...report, name: e.target.value })} sx={{ mb: 2 }} />
            <TextField
              select
              value={report.accessType}
              label={t('saveAs.accessType')}
              fullWidth
              onChange={(e: any) => setReport({ ...report, accessType: e.target.value })}
            >
              <MenuItem value='ORGANIZATIONAL'>{ t('ORGANIZATIONAL') }</MenuItem>
              <MenuItem value='PUBLIC'>{ t('PUBLIC') }</MenuItem>
              <MenuItem value='PRIVATE'>{ t('PRIVATE') }</MenuItem>
            </TextField>
          </Form>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='space-between'>
            <Button label={t('shared:cancel')} variant='tonal' onClick={() => setOpen(false)} sx={{ mr: 1 }} />
            <Button label={t('shared:save')} onClick={saveAs} disabled={loading} />
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default SaveAsReport;
