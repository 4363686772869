import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { Box, Typography } from '@mui/material';
import { formatPercentValue } from '../../../util';

const CircularProgressIndicator = ({ value }: { value: number }) => (
  <Box width="7em">
    <CircularProgressbarWithChildren
      value={Math.min(value, 100)}
      strokeWidth={10}
      styles={buildStyles({
        strokeLinecap: 'round',
        pathColor: '#2CA58D',
        textColor: '#233950',
        trailColor: '#d6d6d6',
        backgroundColor: '#3e98c7',
      })}
    >
      <Box sx={{ p: 1, marginTop: -2 }}>
        <Typography variant="h6">{`${formatPercentValue(value / 100)}`}</Typography>
      </Box>
    </CircularProgressbarWithChildren>
  </Box>
);

export default CircularProgressIndicator;
