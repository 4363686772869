/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid, TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../providers/userContextProvider';

export const SimpleDateRangePicker = ({
  date1, date2, setDates,
}: {
  date1: Dayjs | null, date2: Dayjs | null, setDates: (d1: Dayjs, d2: Dayjs) => void
}) => {
  const { t } = useTranslation('components');
  const [d1, setd1] = useState<Dayjs | null>(null);
  const [d2, setd2] = useState<Dayjs | null>(null);

  const { activeOrganization } = useContext(UserContext);
  const format = activeOrganization.applicableLocalization?.dateFormat ?? 'YYYY-MM-DD';

  useEffect(() => {
    setd1(date1);
    setd2(date2);
  }, []);

  return (
    <Paper sx={{ height: '30em', width: '50em', p: 2 }}>
      <Grid container display="flex" justifyContent="space-evenly">
        <StaticDatePicker
          label={t('simpleDateRangePicker.fromDate')}
          disableFuture
          disableOpenPicker
          minDate={dayjs('2020-01-02')}
          maxDate={dayjs().subtract(1, 'day')}
          value={d1}
          onChange={(newDate: Dayjs | null) => { setd1(newDate); if (newDate && d2) setDates(newDate, d2); }}
          renderInput={(params) => <TextField {...params} />}
          inputFormat={format}
          toolbarFormat={format}
        />
        <StaticDatePicker
          label={t('simpleDateRangePicker.toDate')}
          disableFuture
          minDate={d1 ?? dayjs('2020-01-02')}
          maxDate={dayjs().subtract(1, 'day')}
          value={d2}
          onChange={(newDate: Dayjs | null) => { setd2(newDate); if (newDate && d1) setDates(d1, newDate); }}
          renderInput={(params) => <TextField {...params} />}
          inputFormat={format}
          toolbarFormat={format}
        />
      </Grid>
    </Paper>
  );
};
