import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const InterestRate = ({ options }: { options: any }) => {
  useRefinitivWidget('#interest-rate-widget', 'WInterestRate', {});

  return (
    <Card>
      <div id='interest-rate-widget'></div>
    </Card>
  );
};
