import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { usePermissions, UserContext } from '../../providers/userContextProvider';

export const FETCH_ASSET_CLASSES = gql`
  query fetchAssetClasses($input: FetchAssetClassesInput) {
    fetchAssetClasses(input: $input) {
      assetClasses {
        id
        translatedName {
          en
        }
      }
    }
  }
`;

const AssetClassSelect = ({
  setAssetClass, value, label, size, disabledWith = null, readonly = false, filteredAllocations = [],
}: {
  setAssetClass: (event: any) => void, value: string, label: string, size?: 'small' | 'medium' | undefined,
  disabledWith?: string | null, readonly?: boolean, filteredAllocations?: any[]
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { loading, error, data } = useQuery(FETCH_ASSET_CLASSES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  const list = data?.fetchAssetClasses?.assetClasses || [];
  const filteredList = filteredAllocations.length === 0 ? list : list.filter((i: any) => i.id === value || !filteredAllocations.includes(i.id));

  return (
    <>
      { permissions.includes('read:asset_classes') ? (
        <TextField
          select
          value={data ? value : ''}
          label={label}
          sx={{ width: '100%' }}
          onChange={setAssetClass}
          size={size}
          disabled={!isNull(disabledWith)}
          InputProps={{
            readOnly: readonly,
          }}
        >
          {
            loading ? <MenuItem>...</MenuItem> : (
              disabledWith ? (
                <MenuItem key={value} value={ value }><b>{disabledWith}</b></MenuItem>
              ) : filteredList?.map((x: any) => (
                <MenuItem key={x.id} value={ x.id }>{ x.translatedName.en }</MenuItem>
              ))
            )
          }
        </TextField>
      ) : (<></>)}
    </>
  );
};

export default AssetClassSelect;
