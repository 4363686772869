import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface SwitchTokens {
  switch: {
    offColor?: string;
    onColor?: string;
    onContainer?: string;
    labelColor?: string;
    errorColor?: string;
    disabledColor?: string;
    onDisabled?: string;
  },
}

export const useSwitchTokens = (): SwitchTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: SwitchTokens = {
    switch: {
      offColor: sys.color.surfaceContainerVariant,
      onColor: sys.color.primary,
      onContainer: sys.color.onSurface,
      labelColor: sys.color.onSurfaceVariant,
      errorColor: sys.color.negative,
      disabledColor: sys.color.disabled,
      onDisabled: sys.color.onDisabled,
    },
  };

  return comp;
};
