import { useTranslation } from 'react-i18next';
import { ClientGroup, ClientGroupMemberAccessType } from '../../../interfaces';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { SummaryStep } from './steps/summaryStep';
import { ChooseHouseholdStep } from './steps/chooseHouseholdStep';
import { AddClientToHouseholdActionStep } from './steps/addClientToHouseholdActionStep';

type Step = 'CHOOSE_HOUSEHOLD' | 'SUMMARY' | 'ADD_CLIENT_TO_HOUSEHOLD';

const steps:Record<Step, StepComponent> = {
  CHOOSE_HOUSEHOLD: ChooseHouseholdStep,
  SUMMARY: SummaryStep,
  ADD_CLIENT_TO_HOUSEHOLD: AddClientToHouseholdActionStep,
};

interface WizardContext {
  userId: string,
  userName: string,
}

interface WizardState {
  clientGroup?: ClientGroup,
  relationship: string,
  accessType: ClientGroupMemberAccessType
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  clientGroup: undefined,
  relationship: '',
  accessType: 'NO_ACCESS',
};

export interface AddClientToHouseholdWizardProps {
  userId: string,
  userName: string
}

export function AddClientToHouseholdWizard({
  userId, userName, handleClose,
}: AddClientToHouseholdWizardProps & WizardControlProps) {
  const { t } = useTranslation('addClientToHouseholdWizard');

  return (
    <WizardBase
      title={t('title')}
      context={{
        userId,
        userName,
      }}
      steps={steps}
      firstStep={'CHOOSE_HOUSEHOLD'}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
