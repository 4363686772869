import { ListItemText, Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { UserContext } from '../../providers/userContextProvider';
import { InlineImage } from '../../pages/client/components/documents/inlineImage';

export const FETCH_LOGO = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {
      perPage: 1
      sortDesc: false,
      sortField: "createdAt"
    }
  }) {
    fileDocuments {
      id
      name
    }
  }
}`;

const OrganizationLogo = ({ textColor }: { textColor: string }) => {
  const { activeOrganization } = useContext(UserContext);
  const [documents, setDocuments] = useState<any[]>([]);
  const navigate = useNavigate();
  const { loading } = useQuery(FETCH_LOGO, {
    variables: {
      filter:
      {
        organizationId: activeOrganization.id,
        types: ['LOGO'],
        objectTypes: ['ORGANIZATION'],
      },
    },
    onCompleted(data) {
      setDocuments(data.fetchFileDocuments.fileDocuments);
    },
  });
  const goHome = () => navigate('/');

  if (loading) return <Box></Box>;

  return (
    <>
      {documents.length > 0 ? (
        <Box sx={{ height: '24px', maxWidth: '250px', cursor: 'pointer' }} onClick={goHome}>
          <InlineImage fileDocumentId={documents[0].id} style={{ maxHeight: '24px' }} />
        </Box>
      ) : (
        <ListItemText primary={activeOrganization.name} sx={{ color: textColor, margin: 0, cursor: 'pointer' }} onClick={goHome}/>
      )}
    </>
  );
};

export default OrganizationLogo;
