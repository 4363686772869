import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface SelectionChipTokens {
  selectionChip: {
    borderRadius?: string;
    active?: string;
    background?: {
      active?: string;
      default?: string;
      hover?: string;
    },
    text?: {
      active?: string;
      default?: string;
      hover?: string;
    },
  },
}

export const useSelectionChipTokens = (): SelectionChipTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: SelectionChipTokens = {
    selectionChip: {
      active: sys.color.primary,
      borderRadius: sys.borderRadius.md,
      background: {
        active: sys.color.primaryVariant,
        default: sys.color.surfaceContainerVariant,
        hover: sys.color.onSurface,
      },
      text: {
        active: sys.color.onSurface,
        default: sys.color.onSurface,
        hover: sys.color.onSurface,
      },
    },
  };

  return comp;
};
