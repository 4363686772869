export const colors = {
  primary: '#3A51C2',
  secondary: '#2B4763',
  success: '#12873D',
  warning: '#F59E0B',
  error: '#DC2626',
  neutral30: '#ECEDEE',
  neutral40: '#DCDDE0',
  neutral80: '#4B4E58',
  neutral100: '#9C9DA3',
  neutral600: '#9A9DA5',
  neutral700: '#35373F',
  neutral800: '#4B4E58',
  white: '#ffffff',
  selectedType: '#2CA58D',
  black: '#000000',
  noteTagLine: '#86939F',
  noteBackground: '#EAECEE',
  noteAuthor: '#282D32',
  gray100: '#EAECEE',
  gray200: '#DBDFE3',
  gray300: '#F7F7F7',
  gray500: '#667380',
  gray800: '#111315',
  boxBorder: '#E4ECF4',
  goalsProgressBar: '#3A6085',
  errorBar: '#FADEE0',
  blue100: '#E4ECF4',
  blue400: '#3A6085',
  blue500: '#2B4763',
  blue800: '#0B1219',
  background: '#F7F7F7',
  wizardTitle: '#233950',
};

export const adjust = (color: string, amount: number) => {
  const adjustedColor = color.replace(/^#/, '').replace(/../g, (rgb) => (`0${Math.min(255, Math.max(0, parseInt(rgb, 16) + amount)).toString(16)}`).slice(-2));
  return `#${adjustedColor}`;
};

export const graphColors = [
  adjust(colors.primary, -40),
  adjust(colors.primary, -20),
  colors.primary,
  adjust(colors.primary, 20),
  adjust(colors.primary, 40),
  adjust(colors.secondary, 40),
  adjust(colors.secondary, 20),
  colors.secondary,
  adjust(colors.secondary, -20),
  adjust(colors.secondary, -40),
  adjust(colors.warning, -40),
  adjust(colors.warning, -20),
  colors.warning,
  adjust(colors.warning, 20),
  adjust(colors.warning, 40),
  adjust(colors.error, 40),
  adjust(colors.error, 20),
  colors.error,
  adjust(colors.error, -20),
  adjust(colors.error, -40),
];

export const simpleGraphColors = [
  adjust(colors.primary, -40),
  adjust(colors.primary, -20),
  colors.primary,
  adjust(colors.primary, 20),
  adjust(colors.primary, 40),
  adjust(colors.secondary, 40),
  adjust(colors.secondary, 20),
  colors.secondary,
  adjust(colors.secondary, -20),
  adjust(colors.secondary, -40),
  adjust(colors.warning, -40),
  adjust(colors.warning, -20),
  colors.warning,
  adjust(colors.warning, 20),
  adjust(colors.warning, 40),
  adjust(colors.error, 40),
  adjust(colors.error, 20),
  colors.error,
  adjust(colors.error, -20),
  adjust(colors.error, -40),
];
