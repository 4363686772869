/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Widget } from '../pageConfiguration/components/widget';
import { Box } from '../../1-primative';
import { ActiveWorkflows } from '../../4-module/activeWorkflows/activeWorkflows';
import { TabNav } from '../../3-pattern/tabNav/tabNav';
import { usePageState } from '../../../util/usePageState';
import { UserContext } from '../../../providers/userContextProvider';
import { ClientName } from '../../4-module/widgets/clientName/clientName';
import { AppBreadcrumbs } from '../../../components/layout/appBreadcrumbs';
import { NavigationStyles } from '../../../interfaces';
import filterHouseholdsWithAccess from '../../../util/filterHouseholdsWithAccess';
import { MultiCurrencySelector } from '../../3-pattern';

export const ViewPageVisual = ({
  page,
}: {
  page: any,
}) => {
  const [activeTab, setActiveTab] = usePageState(0, 'tab');
  const {
    clientGroupId, goalId, accountId, subAccountId, objectId, userId,
  } = useParams<{
    clientGroupId: string, goalId: string, accountId: string, subAccountId: string, objectId: string, userId: string,
  }>();
  const [activeWorkflowCompletionId, setActiveWorkflowCompletionId] = useState<string | undefined>(undefined);
  const { userContext, activeEntity, activeOrganization } = useContext(UserContext);
  const navFull = userContext.role?.navigationStyle === NavigationStyles.FULL;

  const households = filterHouseholdsWithAccess((activeEntity?.households || []), activeEntity?.id);
  const isHouseholdDefaultView = !!userContext.role?.makeHouseholdDefaultView && households.length > 0;

  const id = () => {
    const baseId = subAccountId || accountId || goalId;
    return baseId || (isHouseholdDefaultView ? userId || objectId || clientGroupId : clientGroupId || objectId || userId);
  };
  return (
    <Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' } }} justifyContent={navFull ? 'start' : 'center'} alignItems='start'>
        <Box maxWidth={`${page.options?.maxPageWidth || 1080}px`} width='100%' mt={2} ml={{ xs: 0, sm: 2 }}>
          {activeOrganization.displayMenuBar && userContext.role?.navigationStyle === NavigationStyles.SIMPLE && (
            <AppBreadcrumbs />
          )}
          <ClientName objectId={id()!} objectType={page.type} options={page.options} setActiveWorkflowCompletionId={setActiveWorkflowCompletionId} />
          <ActiveWorkflows userId={(objectId || userId || activeEntity?.id)!} activeWorkflowCompletionId={activeWorkflowCompletionId} />
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <TabNav tabs={page.tabs} activeTab={activeTab} setActiveTab={(tab: number) => {
              setActiveTab(tab);
            }} />
            {activeOrganization.displayCurrency && <MultiCurrencySelector userId={(objectId || userId || activeEntity?.id)} clientGroupId={clientGroupId} />}
          </Box>
          {id() && (page.tabs || [])[activeTab]?.widgets?.map((item: any, i: number) => (
            <Box key={`${item.type}-${i}`} sx={{ marginBottom: '20px' }}>
              <Widget type={item.type} i={i} objectId={id()!} viewOnly options={item.options} objectType={page.type} widgetId={item.id} userId={objectId || userId || activeEntity?.id} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
