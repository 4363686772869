/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, Table, TableHead,
  TableRow, TableCell, TableBody,
  Chip, Grid, MenuItem, IconButton,
  Menu, Link as MuiLink, Stack,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { ScheduleTransferState } from 'interfaces';
import { useCorporateContext } from '../../../pages/corporation';
import { useHouseholdContext } from '../../../pages/household';
import { Date } from '../../misc/date/date';
import { formatMoneyValue, generateBankDetailString, generateClientNameString } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import NewTransfer from './newTransfer';
import { useClientContext } from '../../../pages/client';

export const FETCH_SCHEDULED_TRANSFERS_QUERY = (permissions: string[]) => `
  query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
    fetchScheduledTransfers(input: $input) {
      scheduledTransfers {
        id
        amountCents
        state
        type
        subAccount {
          id
          account {
            id
            type
            user {
              id
              ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
            }
          }
          goal {
            name
          }
        }
        toSubAccount {
          id
          goal {
            name
          }
          account {
            type
          }
        }
        bankAccount {
          name
          institutionNumber
          bankAccountNumber
        }
        scheduledDate
        frequency
      }
      totalCount
    }
  }
`;

export const FETCH_SCHEDULED_TRANSFERS = (permissions: string[]) => gql`${FETCH_SCHEDULED_TRANSFERS_QUERY(permissions)}`;

export const CANCEL_SCHEDULED_TRANSFER = gql`
  mutation cancelScheduledTransfer($scheduledTransferId: ObjectID!) {
    cancelScheduledTransfer(scheduledTransferId: $scheduledTransferId) {
      scheduledTransfer {
        id
      }
    }
  }
`;

export const ScheduledTransfersTable = ({
  showClient = false,
  filter,
  onTransition = () => {},
  onNewTransfer = () => {},
  onFilterChange,
}: {
  showClient?: boolean;
  filter: any;
  onTransition?: () => void;
  onNewTransfer?: any;
  onFilterChange?: (filter: any) => void;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [activeScheduleTransfer, setActiveScheduleTransfer] = useState<any>({});
  const [scheduledTransfersAnchorEl, setScheduledTransfersAnchorEl] = useState<null | HTMLElement>(null);
  const scheduledTransferMenuOpen = Boolean(scheduledTransfersAnchorEl);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();
  const [cancelScheduledTransfer] = useMutation(CANCEL_SCHEDULED_TRANSFER);

  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);

  const queryFilter = { accountType: undefined, organizationId: organizationId ?? activeOrganization.id, states: [ScheduleTransferState.Active, ScheduleTransferState.Draft] };

  const response = useQuery(FETCH_SCHEDULED_TRANSFERS(permissions), {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
      },
    },
  });

  const emitFilterChange = () => {
    if (onFilterChange) {
      onFilterChange(queryFilter);
    }
  };

  useEffect(() => emitFilterChange(), []);

  const handleScheduleTransferMenuClose = () => {
    setScheduledTransfersAnchorEl(null);
  };
  const generateSourceText = (transfer: any): string => {
    if (transfer?.toSubAccount) {
      const accType = t(`accountTypes:${transfer?.toSubAccount?.account?.type}`);
      return t('transferTo', { subAccount: accType });
    }
    return '';
  };
  return (
    <Box>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            {showClient && (
              <TableCell><Typography variant='overline'>{t('activityTable.client')}</Typography></TableCell>
            )}
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.account')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.type')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.source')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.amount')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.frequency')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.state')}</Typography></TableCell>
            <TableCell><Typography fontWeight={500} variant='overline'>{t('activityTable.nextScheduledDate')}</Typography></TableCell>
            {permissions.includes('transition:transfer') && (
              <TableCell align='right'><Typography variant='overline'>{t('activityTable.actions')}</Typography></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(response.data || response.previousData)?.fetchScheduledTransfers?.scheduledTransfers?.map((transfer: any) => (
            <TableRow
              hover
              onClick={() => {
                setActiveScheduleTransfer(transfer);
              }}
              selected={transfer.id === activeScheduleTransfer.id}
              key={transfer.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
            >
              {showClient && (
                <TableCell component="th" scope="row">
                  <MuiLink component={Link} to={`/clients/${transfer.subAccount.account.user.id}`}>
                    {generateClientNameString(transfer.subAccount.account.user)}
                  </MuiLink>
                </TableCell>
              )}
              <TableCell component="th" scope="row">
                {transfer.subAccount.goal.name} - {t(`accountTypes:${transfer.subAccount.account.type}`)}
              </TableCell>
              <TableCell><Chip size='small' label={transfer.type} color='default' /></TableCell>
              <TableCell>{generateSourceText(transfer)}<b>{transfer?.bankAccount ? generateBankDetailString(transfer?.bankAccount) : ''}</b></TableCell>
              <TableCell>{formatMoneyValue(transfer.amountCents)}</TableCell>
              <TableCell>{t(`transfersTable.frequencyOption.${transfer.frequency}`)}</TableCell>
              <TableCell><Chip size='small' label={transfer.state} color='default' /></TableCell>
              <TableCell><Date variant='subtitle2' date={transfer.scheduledDate} /></TableCell>
              {permissions.includes('transition:transfer') && (
                <TableCell align='right'>
                  <IconButton
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      setScheduledTransfersAnchorEl(e.currentTarget);
                      setActiveScheduleTransfer(transfer);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(response.data || response.previousData)?.fetchScheduledTransfers?.scheduledTransfers?.length === 0
        && (
        <Stack sx={{ marginTop: '5em', marginBottom: '5em' }}>
          <Grid display="flex" justifyContent="center">
            <InfoIcon/>
          </Grid>
          <Grid display="flex" justifyContent="center">
          <Typography sx={{ fontWeight: '600' }}>
            {t('activityTable.noTransfersExist')}
          </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" sx={{ marginTop: '1em' }}>
            <NewTransfer
              afterCreate={onNewTransfer}
              {...filter.userId && { forObject: 'USER', forId: filter.userId }}
              {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
              longLabel
              presetSourceEFT
              presetSubaccount={filter.subAccountId}
              presetAccount={filter.accountId}
            />
          </Grid>
        </Stack>
        )
      }

      <Menu
        anchorEl={scheduledTransfersAnchorEl}
        id="scheduled-transfer-menu"
        open={scheduledTransferMenuOpen}
        onClose={handleScheduleTransferMenuClose}
        onClick={handleScheduleTransferMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={async () => {
            cancelScheduledTransfer({ variables: { scheduledTransferId: activeScheduleTransfer.id } }).then(() => response.refetch());
          }}
        >
          Cancel
        </MenuItem>
      </Menu>
    </Box>
  );
};
