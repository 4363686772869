import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { Box } from '../../../1-primative';
import {
  CircularProgress, Button,
} from '../../../2-component';

const FETCH_EXPORT = gql`
  query fetchExport($input: FetchExportInput!) {
    fetchExport(input: $input) {
      export {
        id
        state
        fileUrl
      }
    }
  }
`;

const ExportLoader = ({ exportId }: { exportId: string }) => {
  const { t } = useTranslation(['customReports']);

  const { data, startPolling } = useQuery(FETCH_EXPORT, {
    variables: {
      input: {
        exportId,
      },
    },
  });

  startPolling(3000);

  return (
    <>
      { !data || data?.fetchExport?.export?.state === 'GENERATING' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', p: 3,
        }}>
          <Button href={data.fetchExport.export.fileUrl} target='_blank' fullWidth label={ t('download') } leadingIcon={DownloadIcon}/>
        </Box>
      )}
    </>
  );
};

export default ExportLoader;
