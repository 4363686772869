import { ReactElement, useState } from 'react';
import {
  Box, Grid, IconButton, Link as MuiLink, ListItem, ListItemText, Menu, MenuItem, Stack, styled, Typography,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from 'ovComponents';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import AlertChip from '../../misc/alertChip';
import SummaryChip from '../../misc/summaryChip';
import { BorderLinearProgress } from '../goals/goalItem';
import CircularProgressIndicator from '../../charts/dashboard/circularProgressIndicator';

export interface Pill {
  label: string
  leadingIcon?: 'checkmark' | 'problem' | 'cancel' | ReactElement
  tooltip?: string | JSX.Element
}

export interface Highlight {
  leadingLabel?: string,
  label: string;
  link?: string;
  tooltip?: string;
  icon?: ReactElement;
  onClick?: () => void,
  isAlert?: boolean,
}

export interface ContextMenuItem {
  style?: React.CSSProperties,
  prefixIcon?: JSX.Element,
  label: string;
  subtitle?: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

interface FinancialSummaryProps {
  marketValue: number,
  returnAmount?: number,
  returnPercent?: number,
  targetAmmount?: number,
  showFinaincialSummary?: boolean,
  progressBarVisibility?: boolean,
  circularProgressVisibility?: boolean,
}

interface SummaryProps {
  title: string | React.ReactElement;
  subtitle?: string;
  financialSummary?: FinancialSummaryProps,
  contextShowId?: string;
  contextMenu?: ContextMenuItem[];
  pills?: Pill[];
  highlights: Highlight[];
  householdLinks?: { id: string, name: string }[];
  onHighlightClicked?: (prop: any) => void,
  hideSubTitle?: boolean,
}

const FinancialSummary = ({ financialSummary }: { financialSummary: FinancialSummaryProps }) => (
  <Box display="flex" flexDirection="column" alignItems="end">
    <Typography variant="h4">
      {formatMoneyValue(financialSummary.marketValue)}
    </Typography>
    <Typography variant='body2' color={(financialSummary.returnAmount ?? 0) >= 0 ? 'primary' : 'error'}>
      {formatMoneyValue(financialSummary.returnAmount ?? 0)} ({formatPercentValue(financialSummary.returnPercent ?? 0)})
    </Typography>
  </Box>
);

export const Summary = ({
  title, subtitle, financialSummary, contextMenu, contextShowId, pills,
  highlights, householdLinks, hideSubTitle,
}: SummaryProps) => {
  const { t } = useTranslation();

  const showCircularProgress = financialSummary && financialSummary.circularProgressVisibility;
  return (
    <>
      {householdLinks && (
        <Stack direction='row' spacing={2} mb={1}>
          {householdLinks.map((h) => <Stack key={h.id} direction='row' spacing={0.5}>
            <SupervisedUserCircleIcon sx={{ fontSize: '16px' }} />
            <Link to={`/households/${h.id}`} style={{ textDecoration: 'none' }}>
              <Typography sx={{
                fontSize: '12px', fontWeight: '600', color: colors.neutral800, '&:hover': { textDecoration: 'underline' },
              }}>
                {h.name} {t('shared:household')}
              </Typography>
            </Link>
          </Stack>)}
        </Stack>
      )}
      <Box display={'flex'} alignItems="start">
        {showCircularProgress
          && <Box pr={2}>
            <CircularProgressIndicator value={
              (financialSummary.targetAmmount && financialSummary.marketValue)
                ? ((financialSummary?.marketValue ?? 0) / financialSummary.targetAmmount * 100)
                : 0
            }
            />
          </Box>
        }
        <Box width='100%'>
          <Grid container justifyContent='space-between'>
            <Grid item>
              {typeof (title) === 'string'
                ? <Typography variant="h4" sx={{ fontWeight: 600 }}>{title}</Typography>
                : title
              }
              {!hideSubTitle && (<Typography variant="caption">{subtitle}</Typography>)}
            </Grid>
            <Grid item>
              <Grid container>
                {financialSummary && financialSummary.showFinaincialSummary
                  && <Grid item>
                    <FinancialSummary financialSummary={financialSummary} />
                  </Grid>
                }
                {(contextMenu || contextShowId)
                  && <Grid item>
                    <ContextMenu items={contextMenu} showId={contextShowId} />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          {financialSummary && financialSummary.progressBarVisibility && (<Grid marginTop={1} justifyContent='space-between'>
            <BorderLinearProgress
              sx={{ width: '100%' }}
              variant="determinate"
              value={(financialSummary.marketValue ?? 0) > (financialSummary?.targetAmmount ?? 0) ? 100 : ((financialSummary?.marketValue ?? 0) / (financialSummary?.targetAmmount ?? 0)) * 100}
            />
          </Grid>)}
          <Stack direction="row" spacing={2} marginTop={1}>
            {pills?.map((pill, index) => (pill.tooltip
              ? (<ArrowTooltip key={index} title={pill.tooltip}>
                <Box>
                  <AlertChip leadingIcon={pill.leadingIcon} label={pill.label} />
                </Box>
              </ArrowTooltip>)
              : <AlertChip key={index} leadingIcon={pill.leadingIcon} label={pill.label} />))}
          </Stack>
          <Grid container marginTop={2}>
            {highlights?.map((highlight, index) => <Grid key={index} item mb={1}>
              <HighlightItem key={index} {...highlight} />
            </Grid>)}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const HighlightItem = (props: Highlight) => {
  if (props.link) {
    return <HighlightWithLink {...props} />;
  } if (props.tooltip) {
    return <HighlightWithTooltip {...props} />;
  }
  return <PlainHighlight {...props} />;
};

const PlainHighlight = (props: Highlight) => <SummaryChip
  isAlert={props.isAlert} icon={props.icon} leadingLabel={props.leadingLabel} label={props.label} onClick={props.onClick}
/>;

const HighlightWithLink = (props: Highlight) => <MuiLink href={props.link} color="#000" underline="none">
  <PlainHighlight {...props} />
</MuiLink>;

export const ArrowTooltip = styled(({ className, ...props2 }: TooltipProps) => (
  <Tooltip {...props2} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 15,
  },
}));

const HighlightWithTooltip = (props: Highlight) => (
  <Stack direction="row" spacing={1} sx={{ display: 'inline' }}>
    <ArrowTooltip title={props.tooltip ?? ''}>
      <Box>
        <SummaryChip isAlert={props.isAlert} icon={props.icon} label={props.label} onClick={props.onClick} />
      </Box>
    </ArrowTooltip>
  </Stack>
);

const ContextMenu = ({ items, showId }: { items?: ContextMenuItem[], showId?: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (items?.length === 0 && !showId) return <></>;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showId
          && <ListItem>
            <Typography variant="caption">{showId}</Typography>
          </ListItem>
        }
        {items?.map((item, index) => (
          <MenuItem key={index} style={item.style} onClick={(event: React.MouseEvent<HTMLElement>) => { item.onClick(event); handleClose(); }}>
            {item.prefixIcon}
            <ListItemText primary={item.label} secondary={item.subtitle} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
