import { Step, Stepper } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useLocalization } from '../../../util/useLocalization';

interface IStep {
  name: string;
}

export const StateStepper = ({
  steps, failed, activeStep, currentState, stateChanges,
}: {
  steps: IStep[], failed: boolean, activeStep: number, currentState: string, stateChanges: any,
}) => {
  const { sys } = useThemeTokens();
  const { localizedDateTime } = useLocalization();
  const { t } = useTranslation('transfer');
  const StepIcon = ({ index }: { index: number }) => {
    if (failed && index === activeStep) {
      return <CancelIcon sx={{ color: sys.color.negative }} />;
    }
    if (activeStep >= index) {
      return <CheckIcon sx={{ color: index + 1 === steps.length ? sys.color.positive : sys.color.primary }} />;
    }
    return <CircleIcon sx={{ color: sys.color.background }} />;
  };

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((state, index) => (
        <Step key={index}>
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <StepIcon index={index} />
            <Typography variant='bodyMedium' weight='bold' sx={{ mt: 1.5, mb: 0.5 }}>{t(`states.${failed && activeStep === index ? currentState : state.name}`)}</Typography>
            { stateChanges.filter((change: any) => change.to === state.name).length > 0 && (
              <Typography
                variant='bodySmall'
                colorVariant='variant'
                sx={{ textAlign: 'center' }}
              >
                {localizedDateTime(stateChanges.filter((change: any) => change.to === state.name).slice(-1)[0].createdAt)}
              </Typography>
            )}
          </Box>
        </Step>
      ))}
    </Stepper>
  );
};
