import {
  useState, useMemo, useEffect, SetStateAction, useRef,
} from 'react';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { InputAdornment, SxProps } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { JSX } from 'react/jsx-runtime';
import { PhysicalAddress } from 'interfaces/physicalAddress';
import { getBackendLanguage } from 'assets/i18n/config';
import { useGlobalToast } from 'providers/globalToastProvider';
import { Jurisdictions } from '@onevesthq/ov-enums';
import { kebabCase } from 'lodash';
import { Autocomplete } from '../autocomplete/autocomplete';
import { Icon } from '../../1-primative';
import { Tooltip } from '../tooltip/tooltip';

interface Props {
  label?: string;
  addressString?: string;
  setAddress?: (address: Partial<PhysicalAddress>) => void;
  setAddressState?: (att: { address: string; error: string; open: boolean }) => void;
  error?: boolean;
  apiKey?: string,
  modalOpen: boolean;
  errorText?: string;
  disabled?: boolean;
  onFocus?: any;
  onBlur?: any;
  sx?: SxProps;
  infoTooltip?: string;
  onLookupFault?: () => void
  lockMessage?: string;
  testId?: string;
}

interface PlaceType {
  text: string;
  id: string;
}

const CanadaPostAddress = ({
  setAddressState,
  setAddress,
  label,
  addressString,
  error,
  apiKey,
  modalOpen = false,
  errorText,
  disabled,
  onFocus,
  sx,
  onBlur,
  infoTooltip,
  onLookupFault,
  lockMessage,
  testId,
}: Props): JSX.Element => {
  const { t } = useTranslation(['pageConfiguration', 'components']);
  const { showToast } = useGlobalToast();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState<string>(addressString ?? '');
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const inputRef = useRef<any>(null);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  const CANADA_POST_BASE_URL = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive';
  const SUPPORTED_COUNTRIES: string[] = ['CAN']; // todo: OV-12255
  const SUPPORTED_PROVINCE: string[] = []; // todo: OV-11858
  const countryFilter = SUPPORTED_COUNTRIES.length === 1 ? `&Country=${encodeURIComponent(SUPPORTED_COUNTRIES[0])}` : '';

  const fetchAddressSuggestion = useMemo(
    () => throttle(
      async (input: string, callback: (results?: readonly PlaceType[]) => void, lastId?: string) => {
        let requestUrl = `${CANADA_POST_BASE_URL}/Find/v2.10/json3.ws?Key=${apiKey}`;
        requestUrl += `&SearchTerm=${encodeURIComponent(input)}${countryFilter}`;
        requestUrl += `&LanguagePreference=${getBackendLanguage()}`;
        requestUrl += `&MaxResults=${encodeURIComponent(8)}&LastId=${lastId}`;

        try {
          const response = await fetch(requestUrl);

          if (!response.ok) {
            showToast({ message: `Failed to fetch address suggestions from ${CANADA_POST_BASE_URL}. Fallback to manual input.`, severity: 'error' });
            if (onLookupFault) onLookupFault();
          }
          const data = await response.json();
          if (data?.Items[0] && data.Items[0].Error) {
            showToast({ message: `Failed to fetch address suggestions from ${CANADA_POST_BASE_URL}. ${data.Items[0].Cause} Fallback to manual input.`, severity: 'error' });
            if (onLookupFault) onLookupFault();
            callback([]);
            return;
          }
          const suggestions = data.Items.map((item: any) => ({ id: item.Id, text: `${item.Text} ${item.Description}` })); // add desc
          callback(suggestions);
        } catch (e) { callback([]); }
      },
      200,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiKey, countryFilter],
  );

  useEffect(() => {
    let active = true;
    if (!inputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (!hasBeenFocused) return undefined;

    fetchAddressSuggestion(inputValue, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    }, value?.id);
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchAddressSuggestion, hasBeenFocused]);

  const fetchAddressById = async (selectedOption: PlaceType): Promise<any> => {
    let requestUrl = `${CANADA_POST_BASE_URL}/Retrieve/v2.10/json3.ws?Key=${apiKey}`;
    requestUrl += `&Id=${encodeURIComponent(selectedOption.id)}`;
    requestUrl += `&LanguagePreference=${getBackendLanguage()}`;

    const response = await fetch(requestUrl);

    if (!response.ok) {
      throw new Error('Failed to fetch address suggestions');
    }

    const data = await response.json();
    return data;
  };

  const validateLocationRestriction = async (result: any): Promise<boolean> => {
    const countryCode = [result.CountryIso2, result.CountryIso3, result.CountryName];
    const provinceCode = [result.Province, result.ProvinceCode, result.ProvinceCode];

    if ((SUPPORTED_COUNTRIES ?? []).length > 0 && !SUPPORTED_COUNTRIES.some((x) => countryCode.includes(x))) {
      showToast({ message: t('components:address.error.unSupportedCountry'), severity: 'error' });
      return false;
    }

    if ((SUPPORTED_PROVINCE ?? []).length > 0 && !SUPPORTED_PROVINCE.some((x) => provinceCode.includes(x))) {
      showToast({ message: t('components:address.error.unSupportedProvince'), severity: 'error' });
      return false;
    }

    return true;
  };

  const setLocalAddress = (result: any): Partial<PhysicalAddress> => {
    const localAddress: Partial<PhysicalAddress> = {};
    localAddress.country = result.CountryIso2;
    localAddress.jurisdiction = `${result.CountryIso2}_${result.ProvinceCode}` as Jurisdictions;
    localAddress.houseNumber = result.BuildingNumber;
    localAddress.unitNumber = result.SubBuilding;
    localAddress.streetName = result.Street;
    localAddress.city = result.City;
    localAddress.postal = result.PostalCode;
    localAddress.neighborhood = result.Neighbourhood;
    return localAddress;
  };

  const handleSelect = async (selectedOption: PlaceType): Promise<void> => {
    if (selectedOption.text.includes('Addresses')) {
      const regex = / - \d+ Addresses$/;
      const newText = selectedOption.text.replace(regex, '');
      setValue({ text: newText, id: selectedOption.id });
      setInputValue(newText);
      setOpen(true);
      return;
    }
    setValue({ text: selectedOption.text, id: '' });
    const result = await fetchAddressById(selectedOption);
    const validate: boolean = await validateLocationRestriction(result?.Items[0]);

    if (!validate) {
      setInputValue('');
      if (setAddressState) setAddressState({ address: '', error: '', open: true });
      return;
    }

    const localAddress = setLocalAddress(result?.Items[0]);
    if (setAddress) setAddress(localAddress);
    setOpen(false);
  };

  const fieldValue = modalOpen ? `${value} ` : value;

  return (
    <Autocomplete
      testId={testId ?? 'canada-post-autocomplete'}
      open={open ?? false}
      label={label ?? ''}
      infoTooltip={infoTooltip}
      error={error}
      errorText={errorText}
      disabled={disabled}
      onFocus={() => { setHasBeenFocused(true); if (onFocus) onFocus(); }}
      blurOnSelect
      fullWidth
      getOptionLabel={(option) => ((typeof option === 'string') ? option : option.text)}
      freeSolo
      onBlur={onBlur}
      sx={sx}
      InputProps={{
        inputRef,
        endAdornment: disabled ? (
          <InputAdornment position="end">
            <Tooltip title={lockMessage ?? t('notEditableMessage')}>
              <Icon icon={LockIcon} size='small' />
            </Tooltip>
          </InputAdornment>
        ) : null,
        disabled,
      }}
      options={[
        ...options,
      ]}
      value={open ? fieldValue : addressString}
      onChange={(event: any, newValue: PlaceType | null) => {
        setValue(newValue);
        if (newValue) {
          handleSelect(newValue);
        }
      }}
      renderOption={(props: any, option:any) => (
        <li {...props} data-testid={testId ? `${testId}-${kebabCase(option.text)}` : `canada-post-autocomplete-${kebabCase(option.text)}`}>
          {option.text}
        </li>
      )}
      onInputChange={(event: any, newInputValue: SetStateAction<string>) => {
        setOpen(!modalOpen);
        setInputValue(newInputValue);
      }}
    />
  );
};

CanadaPostAddress.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  setAddressState: undefined,
};

export default CanadaPostAddress;
