import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewIndividual from './components/newNonIndividual';
import { FilterNonIndividuals } from './components/filterNonIndividuals';
import { Box, Skeleton } from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination, TextField,
  CardContent, TableHeadCell,
  Card,
} from '../../2-component';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import {
  Account, AccountStates, EntityTypes, User,
} from '../../../interfaces';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_USERS = (permissions: string[]) => gql`
  query searchNonIndividualUsers($input: UserSearchInput!, $currency: StatisticsCurrencyTypes) {
    searchUsers(input: $input) {
      users {
        id
        entityName
        ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail firstName middleName lastName' : ''}
        type
        ${permissions.includes('read:organizations') ? 'organization { name }' : ''}
        statistics(input: { currency: $currency }) { marketValueCents }
        accounts { state }
        completedAt
      }
      totalCount
    }
  }
`;

const allTypesExceptIndividual: EntityTypes[] = Object.values(EntityTypes).filter((t) => t !== EntityTypes.INDIVIDUAL);

const numAccounts = (accounts:Account[]):number => {
  const typesIncluded = [AccountStates.ACTIVE, AccountStates.REQUESTED, AccountStates.INITIATED, AccountStates.READY, AccountStates.FROZEN, AccountStates.FAILED];
  return accounts.filter((a) => typesIncluded.includes(a.state)).length;
};

export const NonIndividuals = () => {
  const { permissions } = usePermissions();
  const { activeOrganization, activeCurrency } = useContext(UserContext);
  const { t } = useTranslation(['nonIndividualClients']);
  const { localizedDateTime } = useLocalization();
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [sortField, setSortField] = usePageState('createdAt', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [filterByType, setFilterByType] = useState<string[]>(allTypesExceptIndividual);
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading, data, previousData,
  } = useQuery(FETCH_USERS(permissions), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          query: searchText,
          organizationId: activeOrganization.id,
          types: filterByType,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, page,
        },
      },
      currency: activeCurrency || 'CAD',
    },
  });

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <TextField
            sx={{ width: '300px' }}
            value={searchText}
            onChange={(event: any) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            leadingIcon='search'
          />
          <Box display='flex'>
            <FilterNonIndividuals filterByType={filterByType} setFilterByType={setFilterByType} types={allTypesExceptIndividual}/>
            {permissions.some((permission) => ['write:client_basic', 'write:client_low_risk_pii', 'write:client_high_risk_pii', 'write:client_suitability'].includes(permission))
              && <NewIndividual />
            }
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell
                isSortable
                onClick={() => onSort('entityName')}
                sortDirection={sortDirection('entityName')}
              >
                {t('table.name')}
              </TableHeadCell>
              <TableHeadCell
                isSortable
                onClick={() => onSort('type')}
                sortDirection={sortDirection('type')}
              >
                {t('entityType')}
              </TableHeadCell>
              {permissions.includes('read:client_low_risk_pii') && (
                <TableHeadCell>
                  {t('table.primaryContact')}
                </TableHeadCell>
              )}
              <TableHeadCell
                isSortable
                onClick={() => onSort('completedAt')}
                sortDirection={sortDirection('completedAt')}
              >
                {t('table.completedAt')}
              </TableHeadCell>
              <TableHeadCell right>{t('table.noOfAccounts')}</TableHeadCell>
              <TableHeadCell
                right
                isSortable
                onClick={() => onSort('marketValueCents')}
                sortDirection={sortDirection('marketValueCents')}
              >
                {t('table.totalAssets')}
              </TableHeadCell>
              <TableHeadCell
                right
                isSortable
                onClick={() => onSort('organizationName')}
                sortDirection={sortDirection('organizationName')}
              >
                {t('shared:organization')}
              </TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                {permissions.includes('read:client_low_risk_pii') && (
                  <>
                    <TableCell><Skeleton width='100%' /></TableCell>
                  </>
                )}
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.searchUsers?.users?.map((user: User) => (
              <TableRow
                hover
                key={user.id}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate(`/nonIndividualClients/${user.id}`)}
              >
                <TableCell>
                  {user.entityName}
                </TableCell>
                <TableCell>{t(`entityTypes:${user.type}`)}</TableCell>
                {permissions.includes('read:client_low_risk_pii') && (
                  <TableCell>{user.primaryEmail}</TableCell>
                )}
                <TableCell>
                  {user.completedAt ? localizedDateTime(user.completedAt) : t('table.notCompleted')}
                </TableCell>
                <TableCell number>{numAccounts(user.accounts)}</TableCell>
                <TableCell number>{formatMoneyValue(user.statistics?.marketValueCents)}</TableCell>
                <TableCell right>{user.organization?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box >
      <Pagination
        count={Math.ceil(((data || previousData)?.searchUsers?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};

export default NonIndividuals;
