import { getBackendLanguage } from 'assets/i18n/config';
import { TextField } from 'ovComponents/2-component';

export const PlainTextField = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => {
  const markdownProps = {
    multiline: true,
    rows: 10,
  };

  return (
    <TextField
      label={option.label[getBackendLanguage()]}
      value={option.value}
      fullWidth
      sx={{ mb: 2 }}
      {...(option.options?.isMarkdown ? markdownProps : {})}
      onChange={(e: any) => {
        const newOptions = [...options];
        newOptions[i] = { ...option, value: e.target.value };
        setOptions(newOptions);
      }}
    />
  );
};
