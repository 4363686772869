import { Box } from '../../1-primative';

const style = {
  outerBox: {
    position: 'relative' as const,
    display: 'flex' as const,
    overflow: 'hidden',
    height: '6px',
    zIndex: '0',
    borderRadius: '3px',
    backgroundColor: '#D5EDE8',
    width: '90%',
  },
  innerBox: () => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'block',
    height: '6px',
    zIndex: '0',
    backgroundColor: '#125ac1',
    width: '90%',
    transform: 'translateX(0.0%)',
  }),
};

export interface CustomAssetProgressBarProps {
  data: {
    key?: string,
    percentage: number,
    colorCode: string,
  }[],
  sx?: any
}

export const AssetProgressBar = ({ data, sx }: CustomAssetProgressBarProps): JSX.Element => (
  <Box sx={{ ...style.outerBox, ...sx }}>
    {
      data.map((item) => (
        <Box
          key={item.key}
          sx={{ ...style.innerBox(), backgroundColor: item.colorCode, width: `${item.percentage}%` }}
        />
      ))
    }
  </Box>
);
