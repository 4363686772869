/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
  DroppableFileInput,
  DialogFooter,
  Button,
  FileTypes,
} from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { base64ToFile } from '../../4-module/workflowCompletion/subSteps/utils';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../addBankAccount/addBankAccountManually/addBankAccountManually';
import { InlineImage } from '../../2-component/inlineImage/inlineImage';
import { DELETE_FILE_DOCUMENT } from '../../5-page/manageNewsAndInsights/components/editArticle';

const UPDATE_ORGANIZATION_USER_AVATAR = gql`
  mutation updateOrganizationUserProfile($input: UpdateOrganizationUserProfileInput!) {
    updateOrganizationUserProfile(input: $input) {
      organizationUser {
        id
        avatar
      }
    }
  }
`;

export const ChangeAvatarModal = ({
  open, setOpen,
}: {
  open: boolean, setOpen: (open: boolean) => void,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { sys } = useThemeTokens();
  const [newAvatar, setNewAvatar] = useState<string | null>(null);
  const { userContext, refetch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT);

  const [updateOrganizationUserAvatar] = useMutation(UPDATE_ORGANIZATION_USER_AVATAR);

  const doUpload = async (): Promise<any> => {
    setLoading(true);
    const uploadingFile = base64ToFile(newAvatar!, 'avatar');
    const createFileInput: any = {
      objectType: 'ORGANIZATION_USER',
      objectId: userContext.id,
      fileName: 'avatar',
      type: 'AVATAR',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      return;
    }
    /* (2) do the upload */
    try {
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      return;
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ORGANIZATION_USER';

    await createFileDocument({
      variables: { input: createFileInput },
      onCompleted(data: any) {
        updateOrganizationUserAvatar({
          variables: {
            input: {
              avatar: data.createFileDocument.fileDocument.id,
            },
          },
          onCompleted: async () => {
            setLoading(false);
            setOpen(false);
          },
        });
      },
    });
  };

  const setAvatar = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      setNewAvatar(base64String);
    };
  };

  const deleteFile = async () => {
    await deleteFileDocument({
      variables: { fileDocumentId: userContext.avatar },
      onCompleted: async () => {
        await updateOrganizationUserAvatar({
          variables: {
            input: {
              avatar: null,
            },
          },
        });
        refetch();
      },
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: `1px ${sys.color.primaryContainer} solid` }}>
        <Typography variant='headingSmall'>{t('updateAvatar.title')}</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!userContext.avatar && <DroppableFileInput onFileChosen={(file: File) => setAvatar(file)} acceptedTypes={[FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.HEIC]} fileMaxSize={3} />}
        {userContext.avatar && (
          <Box display='flex' justifyContent='space-between' alignItems='center' flexDirection='column'>
            <InlineImage fileDocumentId={userContext.avatar} style={{
              height: '100px', width: '100px', borderRadius: sys.borderRadius.round, marginBottom: '8px', objectFit: 'cover',
            }} />
            <Button onClick={() => deleteFile()} label={t('shared:delete')} variant='text' color='destructive' />
          </Box>
        )}
      </DialogContent>
      <DialogFooter>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => doUpload()} label={t('shared:save')} disabled={loading} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};
