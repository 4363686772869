import {
  Typography, Table, TableHead,
  TableRow, TableCell, TableBody,
  Alert,
  Grid,
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Transfer, TransferSources, TransferState } from '../../interfaces';
import { usePermissions } from '../../providers/userContextProvider';
import StateChangesModal from './stateChangesModal';

import { formatMoneyValue } from '../../util';
import StatsBlock from '../misc/statsBlock';

const FETCH_TRANSFER_SIGNED_FORM_URL = gql`
query fetchTransferSignedFormURL($manualProcessId: ID!) {
  fetchManualProcess(id: $manualProcessId) {
    manualProcess {
      signedUrl
    }
  }
}
`;

const TransferModal = ({ transfer, handleClose }: {
  transfer: Transfer, handleClose: () => void
}) => {
  const { t } = useTranslation('components');
  const { permissions } = usePermissions();

  const [fetchFileDocumentUrl] = useLazyQuery(FETCH_TRANSFER_SIGNED_FORM_URL, {
    variables: { manualProcessId: transfer.manualProcess?.id },
    fetchPolicy: 'no-cache',
  });

  const downloadFile = async () => {
    const result = await fetchFileDocumentUrl();
    const url = result?.data?.fetchManualProcess?.manualProcess?.signedUrl;
    if (window && url) {
      window.open(url, '_blank');
    }
  };

  if (!transfer) return <></>;

  return (
    <>
      <StateChangesModal
        open
        handleClose={handleClose}
        stateChanges={[{ to: TransferState.Initiated, createdAt: transfer.createdAt }, ...(transfer?.stateChanges || [])]}
        title={`${formatMoneyValue(transfer.amountCents)} ${transfer.type}`}
        id={transfer.id}
      >
        { transfer.source === TransferSources.EXTERNAL_TRANSFER_IN
          && <Box mb={2}>
            <Alert severity='info' icon={false}>
              <Typography variant="h6" marginBottom={1}>
                {transfer.manualProcess?.transferMethod && t(`transferModal.sources.${transfer.manualProcess?.transferMethod}`)} &nbsp;
                {t('transferSources.EXTERNAL_TRANSFER_IN')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant={'overline'} sx={{ lineHeight: 1 }}>{ t('transferModal.externalTransfer.relinquishingInstitution') }</Typography>
                  <Typography variant='h6' sx={{ lineHeight: 1 }}>{ transfer.manualProcess?.institution?.name ?? '' }</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'overline'} sx={{ lineHeight: 1 }}>{ t('transferModal.externalTransfer.accountTypeAndNumber') }</Typography>
                  <Typography variant='h6' sx={{ lineHeight: 1 }}>{ `${transfer.manualProcess?.transferAccount ?? ''} ${transfer.manualProcess?.transferAccountNumber ?? ''}` }</Typography>
                </Grid>
                { transfer.manualProcess?.signedUrl
                  && <Grid item xs={12}>
                    <Button variant='outlined' size='small' onClick={downloadFile}>
                      {t('transferModal.externalTransfer.downloadSignedTransferForm')}
                    </Button>
                  </Grid>
                }
              </Grid>
            </Alert>
          </Box>
          }
        <Grid container justifyContent='space-evenly' spacing={2}>
          <Grid item sx={{ textAlign: 'center' }} xs={4}>
            <StatsBlock title={t('marketValue')} value={formatMoneyValue(transfer.subAccount?.statistics?.marketValueCents)} />
          </Grid>
          <Grid item sx={{ textAlign: 'center' }} xs={4}>
            <StatsBlock title={t('moneyAvailable')} value={formatMoneyValue(transfer.subAccount?.statistics?.moneyAvailableCents)} />
          </Grid>
          <Grid item sx={{ textAlign: 'center' }} xs={4}>
            <StatsBlock title={t('availableFundsToWithdraw')} value={formatMoneyValue(transfer.subAccount?.statistics?.availableFundsToWithdrawCents)} />
          </Grid>
        </Grid>
        {permissions.includes('read:alert_report_basic') && (
          !transfer.isSuspicious ? (
            <Alert severity='success' sx={{ marginTop: 3, marginBottom: 3 }}>
              { t('notSuspicious') }
            </Alert>
          ) : (
            <Alert
              severity="error"
              sx={{ marginTop: 3, marginBottom: 3 }}
            >
              { t('suspicious') }
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='overline'>
                        {t('activityTable.alert')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='overline'>
                          {t('activityTable.alertDescription')}
                        </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transfer.alertReport?.rules?.map((rule: any) => (
                    <TableRow key={rule.type}>
                      <TableCell component="th" scope="row">
                        {t(`ruleTypes.${rule.type}`)}
                      </TableCell>
                      <TableCell>
                        {rule.translatedDescription.en}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Alert>
          )
        )}
        <hr />
      </StateChangesModal>
    </>
  );
};

export default TransferModal;
