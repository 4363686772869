import { Badge } from '../../2-component';

interface BetaBadgeProps {
  variant?: 'small' | 'medium' | 'large';
  sx?: Record<string, any>;
}

const variantStyles = {
  small: {
    fontSize: '12px',
    padding: '1px 3px',
  },
  medium: {
    fontSize: '16px',
    padding: '2px 4px',
  },
  large: {
    fontSize: '20px',
    padding: '3px 6px',
  },
};

export const BetaBadge = ({ variant = 'medium', sx } : BetaBadgeProps) => (
  <Badge
  label='BETA'
  sx={{
    fontSize: (variantStyles[variant].fontSize),
    padding: (variantStyles[variant].padding),
    fontWeight: '800',
    ...sx,
  }}
  />
);
