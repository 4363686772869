import { Typography, Grid, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import { styles } from './documentViewer';
import { colors } from '../../../../theme/colors';
import { FileDownloader } from './fileDownloader';

interface FileDownloaderProps {
  fileDocument: any;
  title?: string;
  width?: string,
  maxHeight?: string,
  onRegenerate?: () => void;
  showRegenerate?: boolean,
}

export function FileDownloaderCard({
  fileDocument,
  title,
  width,
  maxHeight,
  onRegenerate,
  showRegenerate,
}: FileDownloaderProps) {
  const { t } = useTranslation('client');
  return (
    <Box display='flex' justifyContent='center' width='100%'>
      <fieldset style={{
        ...styles.fieldset,
        background: colors.boxBorder,
        width: '100%',
        height: '400px',
        maxHeight,
        maxWidth: '500px',
      }}>
        <legend >
          <Typography sx={styles.borderTitle} variant="body2">
            {fileDocument.mediaType === 'application/pdf'
              ? t('client:documents.pdfSection')
              : fileDocument.mediaType
            }
          </Typography>
        </legend>
        <Grid container sx={styles.downloadSection}>
          <FileDownloader fileDocument={fileDocument}>
            <Grid item sx={{ marginTop: '11.33px' }}>
              <DescriptionIcon fontSize='large' />
            </Grid>
            <Grid item sx={styles.gridFileName}>
              <Typography>{title || fileDocument.fileName || fileDocument.displayName}</Typography>
            </Grid>
            <Grid item sx={styles.gridDownload}>
              <u>
                <Typography variant="caption">
                  {t('client:documents.download')}
                </Typography>
              </u>
            </Grid>
          </FileDownloader>
        </Grid>
        {showRegenerate && (<Grid item sx={styles.regenerateGrid}>
          <u>
            <Typography variant="caption" onClick={() => {
              if (onRegenerate) onRegenerate();
            }}>
              {t('client:documents.regenerate')}
            </Typography>
          </u>
        </Grid>)}
      </fieldset>
    </Box>
  );
}
