export interface BorderRadiusTokens {
  none?: string;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
  round?: string;
}

export const borderRadius: BorderRadiusTokens = {
  none: '0px',
  xs: '2px',
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '28px',
  round: '100px',
};
