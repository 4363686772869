import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewAssetClass from './components/newAssetClass';
import EditAssetClass from './components/editAssetClass';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { AssetClass } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';
import {
  Typography, Box, Skeleton,
} from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination,
  Card, CardContent, TableHeadCell, Badge,
} from '../../2-component';

const FETCH_ASSET_CLASSES = gql`
  query fetchAssetClasses($input: FetchAssetClassesInput) {
    fetchAssetClasses(input: $input) {
      assetClasses {
        id
        key
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        state
      }
    }
  }
`;

export const AssetClasses = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['configureModels']);
  const [assetClassOpen, setAssetClassOpen] = useState(false);
  const [selectedAssetClass, setSelectedAssetClass] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ASSET_CLASSES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      { permissions.includes('write:asset_classes') && (
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NewAssetClass afterCreate={refetch} />
          </Box>
        </CardContent>
      )}
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('table.key')}</TableHeadCell>
            <TableHeadCell>{t('table.name')}</TableHeadCell>
            <TableHeadCell>{t('table.description')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchAssetClasses?.assetClasses?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map((assetClass: AssetClass) => (
            <TableRow
              hover
              key={assetClass.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => {
                if (permissions.includes('write:asset_classes')) {
                  setSelectedAssetClass(assetClass);
                  setAssetClassOpen(true);
                }
              }}
            >
              <TableCell component="th" scope="row">
                {assetClass.key && (<Badge label={assetClass.key} />) }
              </TableCell>
              <TableCell>{assetClass.translatedName?.en}</TableCell>
              <TableCell>{assetClass.translatedDescription?.en}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchAssetClasses?.assetClasses.length ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      { permissions.includes('write:asset_classes') && (
        <EditAssetClass afterUpdate={() => {
          setAssetClassOpen(false);
          refetch();
        }} assetClassToUpdate={selectedAssetClass} open={assetClassOpen} handleClose={() => setAssetClassOpen(false)} />
      )}
    </Card >
  );
};

export default AssetClasses;
