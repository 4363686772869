import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  SelectField,
  TextField,
  TranslatableTextField,
} from '../..';
import { Attachment } from './attachment';

export const ListTileOptionsEdit = ({
  optionList, setOptionList,
}: {
  optionList: any, setOptionList: (x: any) => void,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'pageConfiguration']);
  const emptyState = {
    title: { en: '' },
    subtitle: { en: '' },
    state: 'ENABLED',
    icon: '',
    value: {},
  };

  return (
    <>
      {optionList?.map((option: any, num: number) => (
        <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1}>
          <Grid item xs={2}>
            <Attachment thumbNail option={option} setOptions={setOptionList} i={num} options={optionList} />
          </Grid>
          <Grid item xs={7}>
            <Box>
              <TranslatableTextField
                label={t('title')}
                value={option.title}
                fullWidth
                sx={{ mb: 1 }}
                onChange={(value) => {
                  const newOptions = [...optionList];
                  newOptions[num] = { ...option, title: value };
                  setOptionList(newOptions);
                }}
              />
              <TranslatableTextField
                label={t('subtitle')}
                value={option.subtitle}
                fullWidth
                onChange={(value) => {
                  const newOptions = [...optionList];
                  newOptions[num] = { ...option, subtitle: value };
                  setOptionList(newOptions);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <SelectField label={t('state')} sx={{ mb: 1 }} value={option.state} fullWidth onChange={(e: any) => {
              const newOptions = [...optionList];
              newOptions[num] = { ...option, state: e.target.value };
              setOptionList(newOptions);
            }}>
              {['ENABLED', 'DISABLED'].map((y: string) => (
                <MenuItem value={y}>{t(`listTileStates.${y}`)}</MenuItem>
              ))}
            </SelectField>
            <TextField
              fullWidth
              label={t('icon')}
              value={option.icon}
              error={!/^(?:[a-z]+(?:_[a-z]+)*)?$/.test(option.icon)}
              errorText={t('pageConfiguration:error.invalidIconErrorText')}
              onChange={(e: any) => {
                const newOptions = [...optionList];
                newOptions[num] = { ...option, icon: e.target.value };
                setOptionList(newOptions);
              }}
              placeholder='check_circle_icon'
            />
          </Grid>
          <Grid item xs={1} justifyContent='center' display='flex' sx={{ mt: 2 }}>
            <IconButton onClick={() => {
              const newOptions = [...optionList];
              newOptions.splice(num, 1);
              setOptionList(newOptions);
            }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box display='flex' justifyContent='end'>
        <Button label={t('add')} variant='outlined' onClick={() => {
          setOptionList([...optionList, emptyState]);
        }} />
      </Box>
    </>
  );
};
