import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Badge } from '@mui/material';
import { Close, Notifications as NB } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Button, Pagination,
} from '../../2-component';
import { Box, Skeleton, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { translateBackend } from '../../../assets/i18n/config';
import { useLocalization } from '../../../util/useLocalization';

const FETCH_UNREAD_NOTIFICATIONS = gql`
  query fetchNotifications($input: FetchNotificationsInput!) {
    fetchNotifications(input: $input) {
      totalCount
    }
  }
`;

const FETCH_NOTIFICATIONS = gql`
  query fetchNotifications($input: FetchNotificationsInput!) {
    fetchNotifications(input: $input) {
      notifications {
        id
        read
        title { en fr }
        body { en fr }
        linkText { en fr }
        link
        createdAt
      }
      totalCount
    }
  }
`;

const MARK_READ = gql`
  mutation markNotificationAsRead($input: MarkNotificationAsReadInput!) {
    markNotificationAsRead(input: $input)
  }
`;

const MARK_ALL_READ = gql`
  mutation markAllNotificationsAsRead {
    markAllNotificationsAsRead
  }
`;

const PAGE_SIZE = 5;

export const Notifications = ({ color }: { color?: string }) => {
  const { sys } = useThemeTokens();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeNotification, setActiveNotification] = useState<any>({});
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('shared');
  const { localizedDateTime } = useLocalization();

  const { data, refetch } = useQuery(FETCH_UNREAD_NOTIFICATIONS, {
    variables: {
      input: {
        filter: { read: false },
      },
    },
  });

  const { data: notificationsData, refetch: notificationsRefetch, loading } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      input: {
        pagination: {
          perPage: PAGE_SIZE,
          offSet: (page - 1) * PAGE_SIZE,
        },
      },
    },
  });

  const [markRead] = useMutation(MARK_READ);
  const [markAllRead] = useMutation(MARK_ALL_READ);

  const handleClick = (event: any) => {
    notificationsRefetch();
    refetch();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    notificationsRefetch();
    refetch();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ mr: 2, color }} onClick={handleClick}>
        <Badge invisible={data?.fetchNotifications?.totalCount === 0} variant='dot' color="error">
          <NB />
        </Badge>
      </IconButton>
      <Menu
        id='notification-menu'
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: '350px' },
        }}
      >
        <Box display='flex' justifyContent='end' mr={1}>
          <Button sx={{ mb: 1 }} size='xs' label={t('markAllAsRead')} variant='text' onClick={ async () => {
            await markAllRead();
            await refetch();
            await notificationsRefetch();
          }} />
        </Box>
        {loading ? (
          <Skeleton variant='rectangular' height='100px' width='calc(100% - 32px)' sx={{ m: 2 }} />
        ) : (
          <Box>
            {notificationsData?.fetchNotifications?.notifications?.map((notification: any) => (
              <MenuItem key={notification.id} onClick={() => {
                setActiveNotification(notification);
                setDialogOpen(true);
                markRead({ variables: { input: { notificationId: notification.id } } });
                refetch();
                handleClose();
              }}>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Badge invisible={notification.read} variant='dot' color="error" sx={{ mr: 2, mt: 1.5 }}>
                  </Badge>
                  <Box display='flex' flexDirection='column' whiteSpace='initial'>
                    <Typography variant='bodyMedium' weight='bold'>{translateBackend(notification.title)}</Typography>
                    <Typography variant='bodySmall' sx={{ color: sys.color.onSurfaceVariant }}>
                      {localizedDateTime(notification.createdAt)}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
            { (notificationsData?.fetchNotifications?.totalCount || 0) > PAGE_SIZE && (
              <Box display='flex' justifyContent='end' mr={1} mt={1}>
                <Pagination size='small' count={Math.ceil((notificationsData?.fetchNotifications?.totalCount || 0) / PAGE_SIZE)} page={page} onChange={(e, p) => setPage(p)} />
              </Box>
            )}
            {(notificationsData?.fetchNotifications?.totalCount ?? 0) === 0 && (
              <Box display='flex' justifyContent='center' alignItems='center' height='100px'>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant }}>{t('noNotifications')}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Menu>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Box sx={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
          }}>
            <Typography variant='bodyLarge' weight='bold'>{translateBackend(activeNotification.title)}</Typography>
            <IconButton onClick={() => setDialogOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge' sx={{ mb: 2 }}>{translateBackend(activeNotification.body)}</Typography>
          { activeNotification.linkText && activeNotification.link && (
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
              <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant }}>
                {localizedDateTime(activeNotification.createdAt)}
              </Typography>
              <Button size='sm' label={translateBackend(activeNotification.linkText)} onClick={() => {
                window.location.href = activeNotification.link;
                setDialogOpen(false);
              }} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
