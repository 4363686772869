import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const FETCH_PORTFOLIO_MODEL_NAME = gql`
  query($id: ObjectID!) {
    fetchModelPortfolio(input:{modelPortfolioId: $id}) {
      modelPortfolio { name }
    }
  }
`;

export function PortfolioModelBreadcrumb() {
  const { data } = useQuery(FETCH_PORTFOLIO_MODEL_NAME, { variables: { id: useParams().id } });

  return data ? data.fetchModelPortfolio?.modelPortfolio.name : 'Model';
}
