import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import { useTranslation } from 'react-i18next';

const FETCH_CLIENT_REPORT_TEMPLATE_NAME = gql`
  query fetchClientReportTemplateName($id: ObjectID!) {
    fetchClientReportTemplate(clientReportTemplateId: $id) {
      clientReportTemplate {
        translatedName { ${getBackendLanguage()} }
      }
    }
  }
`;

export function ClientReportTemplateBreadcrumb() {
  const { data } = useQuery(FETCH_CLIENT_REPORT_TEMPLATE_NAME, { variables: { id: useParams().id } });
  const { t } = useTranslation();
  const templateName = data?.fetchClientReportTemplate?.clientReportTemplate
    ? translateBackend(data.fetchClientReportTemplate.clientReportTemplate.translatedName)
    : '';

  return t('clientReportTemplate:nameTemplate', { name: templateName });
}
