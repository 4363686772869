import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box, Button, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FETCH_HOUSEHOLD } from 'pages/household';
import { useGlobalToast } from 'providers/globalToastProvider';
import { AffiliationRelations } from '../../../../interfaces/affiliationRelations';
import { usePermissions } from '../../../../providers/userContextProvider';
import { StepProps } from '../wizard';
import { ClientGroupMemberAccessType, RelationshipInput } from '../../../../interfaces';
import { FETCH_CLIENTGROUP_RELATIONSHIPS, UPDATE_CLIENTGROUP_RELATIONSHIPS } from '../../addHouseholdMember/steps/addHouseholdMemeberActionStep';

export function EditMemberStep({
  context, state, setState, continueTo, mutationEvent,
}: StepProps) {
  const { t } = useTranslation('editHouseholdMemberWizard');
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();

  const [fetchClientGroupRelationships, { loading }] = useLazyQuery(FETCH_CLIENTGROUP_RELATIONSHIPS, { variables: { clientGroupId: context.clientGroupId } });
  const [updateRelationships, { loading: updating }] = useMutation(UPDATE_CLIENTGROUP_RELATIONSHIPS);

  const doEditMember = () => {
    fetchClientGroupRelationships({
      fetchPolicy: 'no-cache',
      onCompleted: (existingData) => {
        mutationEvent('started');

        const existingRelationships = existingData.fetchClientGroup.clientGroup.relationships.map((r: any) => ({
          type: r.type,
          userId: r.user.id,
          accessType: r.accessType,
        }));
        const updatedReltionships = existingRelationships.map((rel:RelationshipInput) => (rel.userId === context.memberUserId
          ? {
            userId: rel.userId,
            type: state.relationType,
            accessType: state.accessType,
          }
          : rel
        ));
        updateRelationships({
          variables: {
            clientGroupId: context.clientGroupId,
            relationships: updatedReltionships,
          },
          refetchQueries: [FETCH_HOUSEHOLD(permissions)],
          onCompleted: () => {
            mutationEvent('succeeded');
            continueTo(null);
            showToast({ message: t('updated', { memberName: context.memberUserName }), severity: 'info' });
          },
          onError: () => mutationEvent('failed'),
        });
      },
    });
  };

  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <TextField disabled fullWidth
            label={t('client')}
            value={context.memberUserName}
          />
        </ListItem>
        <ListItem>
          <TextField select fullWidth autoFocus
            label={t('relationshipTo', { whom: context.primaryUserName })}
            value={state.relationType}
            onChange={(event) => setState({ ...state, relationType: event.target.value })}
          >
            {Object.values(AffiliationRelations).map((relation, index) => (
              <MenuItem key={index} value={relation}>
                {t(`affiliationTypes:affiliationRelationship.${relation}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('accessType')}
            value={state.accessType}
            onChange={(event) => setState({ ...state, accessType: event.target.value as ClientGroupMemberAccessType })}
          >
            <MenuItem value="VIEW">{t('household:accessTypes.VIEW')}</MenuItem>
            <MenuItem value="NO_ACCESS">{t('household:accessTypes.NO_ACCESS')}</MenuItem>
          </TextField>
        </ListItem>
      </Box>

      <Box m={2}>
        <Button disabled={loading || updating} type='submit'
          onClick={() => doEditMember()} fullWidth variant='contained'>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
}
