import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import NumberFormat from 'react-number-format';
import React from 'react';
import { formatAmountValueCents } from '../../util';

interface FormatterProps {
  onChange: (event: { target: { valueCents: undefined | number; name: string; value: string } }) => void;
  name: string;
  decimalPlaces?: number;
  placeholder?: string;
}

type AmountFieldProps = TextFieldProps & {
  useFormatAmountValueCents?: boolean;
  decimalPlaces?: number;
  placeholder?: string;
};

const CurrencyFormatter = React.forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const {
      onChange, decimalPlaces = 2, placeholder, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        placeholder={placeholder}
        getInputRef={ref}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.event?.type !== 'change') return;

          if (Number.isNaN(Number(values.value))) {
            return;
          }

          let formattedValue: string;
          if (values.value) {
            const num = Number(values.value);

            formattedValue = num % 1 === 0
              ? Math.abs(num).toString()
              : num.toFixed(decimalPlaces);
            const valueCents = Math.round(Number(values.value) * 10 ** decimalPlaces * 100) / 10 ** decimalPlaces;

            onChange({
              target: {
                name: props.name,
                value: formattedValue,
                valueCents: formattedValue === '' ? undefined : valueCents,
              },
            });
          } else {
            onChange({
              target: {
                name: props.name,
                value: '',
                valueCents: undefined,
              },
            });
          }
        }}
        decimalScale={decimalPlaces}
        thousandSeparator
        isNumericString
        allowLeadingZeros={false}
      />
    );
  },
);

const AmountField = ({
  useFormatAmountValueCents = false, decimalPlaces = 2, placeholder, ...props
}: AmountFieldProps) => {
  const parseValue = (value: unknown): string => {
    if (value === undefined || value === '') {
      return '';
    }

    return useFormatAmountValueCents ? formatAmountValueCents(Number(value)) : (Number(value) / 100).toString();
  };

  const defaultPlaceholder = `0.${'0'.repeat(decimalPlaces)}`;

  return (
    <TextField
      {...props}
      inputProps={{
        ...(props.inputProps ?? {}),
        decimalPlaces,
        placeholder: placeholder ?? defaultPlaceholder,
      }}
      InputProps={{
        ...props.InputProps,
        inputComponent: CurrencyFormatter as any,
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }}
      value={parseValue(props.value)}
    />
  );
};

export default AmountField;
