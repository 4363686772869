import { useContext } from 'react';
import { AccessToEntity } from './accessToEntity/accessToEntity';
import { UserContext } from '../../../providers/userContextProvider';
import { OrganizationUserAccessTypes } from '../../../interfaces';

const Access = ({ objectId, options = {} }: { objectId: string, options?: any }) => {
  const { userContext } = useContext(UserContext);
  return (
    <AccessToEntity
      entityId={objectId}
      options={{ showTitle: false, editAccessEnabled: options.editAccess && userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION }}
      sx={{ paddingX: 0 }}
    />
  );
};

export default Access;
