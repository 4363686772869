import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';

export const FETCH_TLH_ANALYSES = gql`
  query fetchTLHAnalyses($input: FetchTLHAnalysesInput!) {
    fetchTLHAnalyses(input: $input) {
      analyses { state createdAt updatedAt id numEvents organization { name } }
      totalCount
    }
  }
`;

const TaxLossHarvestsTable = (props: { refetchWhenChanged: string }) => {
  const { t } = useTranslation(['taxLossHarvesting']);
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_TLH_ANALYSES, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        organizationId: activeOrganization.id,
        pagination: {
          perPage: pageSize, offSet: (page - 1) * pageSize, sortField: 'createdAt', sortDesc: true,
        },
      },
    },
  });

  useEffect(() => {
    if (props.refetchWhenChanged !== '') {
      refetch();
    }
  }, [props.refetchWhenChanged, refetch]);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="table" data-testid="the-analyses-table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.ranAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.numEvents')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchTLHAnalyses?.analyses?.map((analysis: any) => (
                <TableRow
                  hover
                  key={analysis.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/taxLossHarvesting/${analysis.id}`)}
                >
                  <TableCell>
                    <DateTime variant='subtitle2' date={analysis.createdAt} />
                  </TableCell>
                  <TableCell>
                    {analysis.numEvents}
                  </TableCell>
                  <TableCell>
                    {analysis.organization.name}
                  </TableCell>
                  <TableCell align="right">
                    <Chip label={analysis.state} size='small' color={analysis.state === 'COMPLETE' ? 'success' : 'warning'}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchTLHAnalyses?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default TaxLossHarvestsTable;
