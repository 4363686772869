/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { translateBackend } from 'assets/i18n/config';
import { round } from 'lodash';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, SubAccountSelect, AmountField, Grid, SelectField, MenuItem, PageObjectType, DateField, RadioGroup, Stack, Radio,
} from '../../../..';
import { TransferContext } from '../../../withdrawWorkflow/withdrawWorkflow';
import { BankAccountSelectField } from '../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import WithdrawalBreakdown from './withdrawalBreakdown';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { validateTransfer, ValidateTransferResponse } from '../../../../accountRestrictions';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { TransferErrorBanner } from '../../../../transferErrorBanner';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';
import { BankAccount } from '../../../../../interfaces';
import { Alert } from '../../../../2-component/alert/alert';
import { FETCH_USER_LOCALIZATION } from '../depositForm/depositForm';
import { addBusinessDays, isBusinessDay } from '../../../../../util/businessDays';

export const WithdrawForm = ({
  options, onNext, dummyWithdraw, setDummyWithdraw,
}: { options: any; onNext: any; dummyWithdraw?: any; setDummyWithdraw?: any }) => {
  const { t } = useTranslation(['workflowCompletions', 'transfer']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const { transferData, setTransferData } = useContext(TransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const applicableData = dummyWithdraw ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyWithdraw ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;
  const [starting, setStarting] = useState<'now' | 'futureDate'>('now');
  const [focused, setFocused] = useState<string[]>([]);
  const [dateHasChanged, setDateHasChanged] = useState(false);
  const { userId: paramsUserId } = useParams();
  const { activeEntity, userContext, activeOrganization } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const { sys } = useThemeTokens();
  const hyperLinkColor = sys.color.negativeOutline;
  const supportUrl = userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new';
  const IS_RRSP = ['RRSP', 'RRSP_SPOUSAL'].includes(applicableData.subAccount?.account?.type);
  const IS_RRSP_AND_LLP_OR_HBP_SELECTED = IS_RRSP && ['HOME_BUYERS_PLAN', 'LIFELONG_LEARNING_PLAN'].includes(applicableData.withdrawalReason);
  const IS_RESP = ['RESP', 'RESP_ADULT', 'RESP_FAMILY', 'RESP_FAMILY_JOINT', 'RESP_SINGLE', 'RESP_SINGLE_JOINT'].includes(applicableData.subAccount?.account?.type);
  const IS_LOCKED_IN = ['LIRA', 'LRSP', 'RLSP'].includes(applicableData.subAccount?.account?.type);
  const IS_INCOME_FUND_ACCOUNT = ['RRIF', 'RIF_SPOUSAL', 'LIF', 'PRIF', 'LRIF', 'RLIF'].includes(applicableData.subAccount?.account?.type);
  const disableForm = (IS_RRSP && applicableData.withdrawalReason === 'LIFELONG_LEARNING_PLAN')
    || (IS_RRSP && applicableData.withdrawalReason === 'HOME_BUYERS_PLAN')
    || IS_RESP
    || IS_LOCKED_IN
    || IS_INCOME_FUND_ACCOUNT;

  const { data: localizationData } = useQuery(FETCH_USER_LOCALIZATION, {
    variables: { userId },
    skip: !userId,
  });
  const defaultScheduleOption = options?.editDefaultScheduleFrequency && options?.defaultScheduleOption ? options?.defaultScheduleOption : undefined;
  const transferDate = applicableData?.scheduledDate;
  const delayDays = options?.transferDelay?.number;
  const transferCountry = applicableData?.bankAccount?.bankAccountTransitPhysicalAddress?.country
    || localizationData?.fetchUser?.user?.physicalAddress?.country
    || localizationData?.fetchUser?.user?.countryOfTaxResidence
    || activeOrganization.applicableLocalization.countries[0];

  const confirmTransferDelay = ({ scheduledDate, days, country }: { scheduledDate: Date; days: number; country: string }) => {
    const today = new Date();

    const minTransferDate = addBusinessDays(today, days, country);

    return {
      dateIsAfter: dayjs(scheduledDate).isSame(minTransferDate, 'day') || dayjs(scheduledDate).isAfter(minTransferDate),
      minTransferDate: dayjs(minTransferDate).format('MM/DD/YYYY'),
    };
  };

  const isScheduled = starting !== 'now';
  const transferDelayCheck = confirmTransferDelay({ scheduledDate: transferDate, days: delayDays, country: transferCountry });
  const invalidTransferDelayCheck = isScheduled
  && options?.transferDelay?.enabled && (!transferDelayCheck.dateIsAfter || !isBusinessDay({ date: dayjs(transferDate).utc().toDate(), country: transferCountry }));

  const scheduledDateError = (!transferDate && options?.scheduledDate?.required !== 'NOT_REQUIRED')
    || !isBusinessDay({ date: dayjs(transferDate).utc().toDate(), country: transferCountry })
    || (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter);

  const scheduledDateErrorMessage = () => {
    if ((options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter)) {
      return t('bankAccountTransferCreation.transferDelayError', {
        date: `${dayjs(transferDelayCheck.minTransferDate).format('MM/DD/YYYY')}`,
      });
    }
    return t('bankAccountTransferCreation.enterAValidDate');
  };
  const isInitiatedByRepresentative = () => permissions?.includes('write:transfer_obo_clients') && !permissions?.includes('write:transfer_basic');

  useEffect(() => {
    if (applicableData?.frequency && applicableData?.frequency !== 'ONE_TIME') {
      updateContextTransfer({
        value: { ...applicableData, frequency: applicableData?.frequency ?? defaultScheduleOption },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    if (applicableData.frequency === 'ONE_TIME' && starting === 'now') {
      updateContextTransfer({
        value: { ...applicableData, scheduledDate: undefined },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    if (!applicableData?.frequency && options.editDefaultScheduleFrequency && defaultScheduleOption) {
      updateContextTransfer({
        value: { ...applicableData, frequency: defaultScheduleOption },
        existingContext: applicableData,
        setApplicationContext,
        setWorkflowCompletionContext,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableData?.frequency, starting, options]);

  const submit = () => {
    if (IS_RRSP_AND_LLP_OR_HBP_SELECTED) return;
    const fields = invalidFields(
      {
        ...options,
        scheduledDate: {
          ...options.scheduledDate,
          requiredIf: (data: any) => data?.frequency !== 'ONE_TIME' || starting === 'futureDate',
        },
        schedule: false,
      },
      applicableData,
    );
    setFocused(fields);
    const transferValidation: ValidateTransferResponse = validateTransfer({
      accountTo: applicableData?.subAccount?.account?.type,
      transferType: 'electronicFundsTransferOut',
      canOverrideTransferRestrictions: permissions.includes('write:override_transfer_restrictions'),
    });
    if (!transferValidation.isValid) {
      showToast({ message: transferValidation.message, severity: 'error' });
      return;
    }
    if (invalidTransferDelayCheck) {
      return;
    }
    if (fields.length === 0) {
      onNext();
    }
  };

  const subAccountFilter = () => {
    switch (applicableData.objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: applicableData.objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: applicableData.objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: applicableData.objectId };
      case PageObjectType.GOAL:
        return { goalId: applicableData.objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: applicableData.objectId };
      default:
        return { userId };
    }
  };
  useEffect(() => {
    if (applicableData.amountCents > applicableData.subAccount?.statistics?.availableFundsToWithdrawCents) {
      if (setApplicationContext) {
        setTransferData({ ...applicableData, amountCents: applicableData.subAccount?.statistics?.availableFundsToWithdrawCents });
      }
      if (setWorkflowCompletionContext) {
        setWorkflowData({ ...applicableData, amountCents: applicableData.subAccount?.statistics?.availableFundsToWithdrawCents });
      }
    }
  }, [applicableData, setApplicationContext, setTransferData, setWorkflowCompletionContext, setWorkflowData]);

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        {translateBackend(options?.subtitle)}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {options?.subAccount?.enabled && (
            <SubAccountSelect
              dataTestId='withdraw-form-subaccount-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => updateContextTransfer({
                existingContext: applicableData,
                value: { ...applicableData, subAccount: e },
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              selectedSubAccount={applicableData.subAccount}
              filter={subAccountFilter()}
              filterNotAvailableWithdraw
              sx={{ mt: 2, mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!applicableData.subAccount?.id && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.bankAccount?.enabled && (
            <BankAccountSelectField
              bankAccount={applicableData.bankAccount}
              setBankAccount={(bankAccount: BankAccount | null) => updateContextTransfer({
                existingContext: applicableData,
                value: { ...applicableData, bankAccount },
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              userId={userId!}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'bankAccount'])}
              error={!applicableData.bankAccount?.id && focused.includes('bankAccount') && options?.bankAccount?.required !== 'NOT_REQUIRED'}
              transactionType='WITHDRAWAL'
            />
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              testId='withdraw-form-amount-cents'
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(newAmount: any) => updateContextTransfer({
                existingContext: applicableData,
                value: { ...applicableData, amountCents: newAmount ? round(parseFloat(newAmount) * 100, 2) : 0 },
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              amount={applicableData.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!applicableData.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          <SelectField
            label='Frequency'
            testId='withdrawl-form-dropdown-frequency'
            onChange={(e: any) => updateContextTransfer({
              existingContext: applicableData,
              value: { ...applicableData, frequency: e.target.value },
              setApplicationContext,
              setWorkflowCompletionContext,
            })
            }
            value={applicableData.frequency ?? defaultScheduleOption}
            fullWidth
            sx={{ mb: 2 }}
            onBlur={() => setFocused([...focused, 'frequency'])}
            error={!applicableData.frequency && focused.includes('frequency') && options?.frequency?.required !== 'NOT_REQUIRED'}
          >
            <MenuItem value='ONE_TIME' data-testid='frequency-onetime'>
              {t('transfer:frequencyOption.ONE_TIME')}
            </MenuItem>
            <MenuItem value='WEEKLY' data-testid='frequency-weekly'>
              {t('transfer:frequencyOption.WEEKLY')}
            </MenuItem>
            <MenuItem value='BI_WEEKLY' data-testid='frequency-biweekly'>
              {t('transfer:frequencyOption.BI_WEEKLY')}
            </MenuItem>
            <MenuItem value='MONTHLY' data-testid='frequency-monthly'>
              {t('transfer:frequencyOption.MONTHLY')}
            </MenuItem>
            <MenuItem value='SEMI_MONTHLY' data-testid='frequency-semi-monthly'>
              {t('transfer:frequencyOption.SEMI_MONTHLY')}
            </MenuItem>
            <MenuItem value='QUARTERLY' data-testid='frequency-quarterly'>
              {t('transfer:frequencyOption.QUARTERLY')}
            </MenuItem>
          </SelectField>
          {['WEEKLY', 'BI_WEEKLY', 'MONTHLY', 'SEMI_MONTHLY', 'QUARTERLY'].includes(applicableData.frequency) && (
            <DateField
              onChange={(date: any) => updateContextTransfer({
                existingContext: applicableData,
                value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              label={t('transfer:withdrawal.starting')}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDay({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              fullWidth
              sx={{ mb: 2 }}
              value={applicableData.scheduledDate ?? defaultScheduleOption}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
          {applicableData?.frequency === 'ONE_TIME' && (
            <RadioGroup
              testId='withdraw-form-starting'
              label={t('components:transferModal.starting')}
              value={starting}
              onChange={(e: any) => {
                setStarting(e.target.value);
              }}
            >
              <Stack direction={'row'}>
                {!isInitiatedByRepresentative() && <Radio value='now' label={t('components:transferModal.startingOptions.now')} />}
                <Radio value='futureDate' label={t('components:transferModal.startingOptions.futureDate')} />
              </Stack>
            </RadioGroup>
          )}
          {starting === 'futureDate' && applicableData?.frequency === 'ONE_TIME' && (
            <DateField
              sx={{ mb: 2 }}
              dataTestId='scheduled-date'
              onChange={(date: any) => {
                updateContextTransfer({
                  value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                  existingContext: applicableData,
                  setApplicationContext,
                  setWorkflowCompletionContext,
                });
              }}
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: undefined },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                } else {
                  updateContextTransfer({
                    value: { ...applicableData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') },
                    existingContext: applicableData,
                    setApplicationContext,
                    setWorkflowCompletionContext,
                  });
                }
              }}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDay({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              fullWidth
              value={applicableData?.scheduledDate}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
          {options?.transferDelay?.enabled && ((applicableData.frequency === 'ONE_TIME' && starting !== 'now')
          || (applicableData.frequency !== 'ONE_TIME' && applicableData.frequency !== undefined)) && (
            <Alert sx={{ mb: 2 }} severity='info' data-testid='transfer-delay-alert'>
              {translateBackend(options?.transferDelayDescription)}
            </Alert>
          )}
          {options?.withdrawalReason?.enabled && (
            <SelectField
              testId='reason-dropdown'
              label={translateBackend(options?.withdrawalReason?.label)}
              onChange={(e: any) => updateContextTransfer({
                existingContext: applicableData,
                value: { ...applicableData, withdrawalReason: e.target.value },
                setApplicationContext,
                setWorkflowCompletionContext,
              })
              }
              value={applicableData.withdrawalReason}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'withdrawalReason'])}
              error={!applicableData.withdrawalReason && focused.includes('withdrawalReason') && options?.withdrawalReason?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem value='COMPLETED_MY_GOAL' data-testid='completed-my-goal'>
                {t('transfer:withdrawal.withdrawalReasonOptions.COMPLETED_MY_GOAL')}
              </MenuItem>
              <MenuItem value='DISSATISFIED_WITH_ONEVEST' data-testid='dissatisified-with-onevest'>
                {t('transfer:withdrawal.withdrawalReasonOptions.DISSATISFIED_WITH_ONEVEST')}
              </MenuItem>
              <MenuItem value='INVESTING_IS_NOT_FOR_ME' data-testid='investing-not-for-me'>
                {t('transfer:withdrawal.withdrawalReasonOptions.INVESTING_IS_NOT_FOR_ME')}
              </MenuItem>
              <MenuItem value='POOR_RETURNS' data-testid='poor-returns'>
                {t('transfer:withdrawal.withdrawalReasonOptions.POOR_RETURNS')}
              </MenuItem>
              {['RRSP', 'RRSP_SPOUSAL'].includes(applicableData.subAccount?.account?.type) && (
                <MenuItem value='HOME_BUYERS_PLAN'>{t('transfer:withdrawal.withdrawalReasonOptions.HOME_BUYERS_PLAN')}</MenuItem>
              )}
              {['RRSP', 'RRSP_SPOUSAL'].includes(applicableData.subAccount?.account?.type) && (
                <MenuItem value='LIFELONG_LEARNING_PLAN'>{t('transfer:withdrawal.withdrawalReasonOptions.LIFELONG_LEARNING_PLAN')}</MenuItem>
              )}
              <MenuItem value='OTHER' data-testid='others'>
                {t('transfer:withdrawal.withdrawalReasonOptions.OTHER')}
              </MenuItem>
            </SelectField>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <WithdrawalBreakdown
            accountId={applicableData.subAccount?.account?.id}
            availableToWithdrawCents={applicableData.subAccount?.statistics?.availableFundsToWithdrawCents ?? 0}
            withdrawAmountCents={applicableData.amountCents ?? 0}
          />
          {IS_RRSP && applicableData.withdrawalReason === 'LIFELONG_LEARNING_PLAN' && (
            <TransferErrorBanner sys={sys} htmlString={t('transfer:withdrawal.withdrawRrspAccountLlpPlanError', { color: hyperLinkColor, supportUrl })} />
          )}
          {IS_RRSP && applicableData.withdrawalReason === 'HOME_BUYERS_PLAN' && (
            <TransferErrorBanner sys={sys} htmlString={t('transfer:withdrawal.withdrawRrspAccountHbpPlanError', { color: hyperLinkColor, supportUrl })} />
          )}
          {IS_RESP && <TransferErrorBanner sys={sys} htmlString={t('transfer:withdrawal.withdrawRespAccountError', { color: hyperLinkColor, supportUrl })} />}
          {IS_LOCKED_IN && <TransferErrorBanner sys={sys} htmlString={t('transfer:withdrawal.withdrawLockedInAccountError', { color: hyperLinkColor, supportUrl })} />}
          {IS_INCOME_FUND_ACCOUNT && (
            <TransferErrorBanner
              sys={sys}
              htmlString={t('transfer:withdrawal.withdrawIncomeFundsAccountError', { color: hyperLinkColor, supportUrl, accountType: t(`accountTypes:${applicableData.subAccount?.account?.type}`) })}
            />
          )}
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='withdraw-form-continue-button' disabled={disableForm} label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default WithdrawForm;
