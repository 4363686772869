import { SvgIconComponent } from '@mui/icons-material';
import { colors } from '../../0-tokens';
import {
  Box, Icon, Typography,
  TypographyPropsVariants,
} from '../../1-primative';

interface StatusTagProps extends TypographyPropsVariants {
  size?: 'small' | 'medium',
  label: string,
  color?: 'primary' | 'negative' | 'warning' | 'positive' | 'neutral' | 'informative',
  icon: SvgIconComponent,
  weight?: 'regular' | 'bold',
}

export const StatusTag = ({
  size = 'small', label, color = 'positive', icon, variant, weight,
}: StatusTagProps) => (
  <Box
    display='flex'
    flexDirection='row'
    alignItems='center'
  >
    <Icon icon={icon} size={size} sx={{
      color: (color === 'neutral' ? colors.neutral600 : colors[color]),
      mr: '4px',
    }}/>
    <Typography variant={variant || (size === 'small' ? 'labelSmall' : 'labelLarge')} weight={weight} sx={{ whiteSpace: 'nowrap' }}>{label}</Typography>
  </Box>
);
