interface BaseTypographyTokens {
  fontFamily: string,
  fontSize: any,
  fontWeight: number,
  lineHeight: any,
  letterSpacing: string,
  textTransform?: string,
}

export interface TypographyTokens {
  typography: {
    [key: string]: BaseTypographyTokens,
  },
}

export const useTypographyTokens = (weight: 'bold' | 'regular'): TypographyTokens => {
  const comp: TypographyTokens = {
    typography: {
      displayLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: { xs: '32px', sm: '40px' },
        fontWeight: 500,
        lineHeight: { xs: '40px', sm: '48px' },
        letterSpacing: '0px',
      },
      displayMedium: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: { xs: '24px', sm: '24px', md: '32px' },
        fontWeight: 500,
        lineHeight: '40px',
        letterSpacing: '0px',
      },
      displayLargeVariant: {
        fontFamily: 'Tiempos Headline',
        fontSize: '40px',
        fontWeight: 500,
        lineHeight: '48px',
        letterSpacing: '0px',
      },
      displayMediumVariant: {
        fontFamily: 'Tiempos Headline',
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '40px',
        letterSpacing: '0px',
      },
      displaySmall: {
        fontFamily: 'Tiempos Headline',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0px',
      },
      headingLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '1px',
      },
      headingMedium: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '1px',
      },
      headingSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '1px',
      },
      headingXSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '1px',
      },
      headingOverline: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '2px',
        textTransform: 'uppercase',
      },
      titleLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '28px',
        letterSpacing: '0.5px',
      },
      titleMedium: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0.5px',
      },
      bodyLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: weight === 'bold' ? 600 : 400,
        lineHeight: '24px',
        letterSpacing: '0.25px',
      },
      bodyMedium: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
      bodySmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '12px',
        fontWeight: weight === 'bold' ? 600 : 400,
        lineHeight: '16px',
        letterSpacing: '0.25px',
      },
      labelLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '20px',
        letterSpacing: '1px',
      },
      labelSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '11px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '16px',
        letterSpacing: '1px',
      },
      button: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '1px',
      },
      trimmedXSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '8px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '12px',
        letterSpacing: '1px',
      },
      trimmedSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '12px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '16px',
        letterSpacing: '1px',
      },
      trimmedNormal: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '1px',
      },
      trimmedMedium: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '1px',
      },
      trimmedLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '1px',
      },
      monospacedXlarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '24px',
        letterSpacing: '1px',
      },
      monospacedLarge: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '20px',
        letterSpacing: '1px',
      },
      monospacedSmall: {
        fontFamily: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
        fontSize: '12px',
        fontWeight: weight === 'bold' ? 600 : 500,
        lineHeight: '16px',
        letterSpacing: '1px',
      },
    },
  };

  return comp;
};
