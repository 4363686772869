import {
  useContext, useEffect, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash/fp';
import { Typography, Box } from '../../../1-primative';
import {
  CircularProgress, Card, SelectField, MenuItem,
} from '../../../2-component';
import { UserContext } from '../../../../providers/userContextProvider';
import { formatMoneyValue } from '../../../../util';
import { useLocalization } from '../../../../util/useLocalization';
import { PieChart } from '../../../2-component/pieChart/pieChart';
import { BarChart } from '../../../2-component/barChart/barChart';
import { LineChart } from '../../../2-component/lineChart/lineChart';

dayjs.extend(utc);

const RenderWidget = (widget: any, grouping: any) => {
  const { localizedDate } = useLocalization();

  const dateFormat = (date: string, interval: string) => {
    switch (interval) {
      case 'YEAR':
        return dayjs(new Date(date)).utc().format('YYYY');
      case 'MONTH':
        return dayjs(new Date(date)).utc().format('MM/YYYY');
      default:
        return localizedDate(dayjs(new Date(date)).utc());
    }
  };

  let values = grouping;
  if (isArray(grouping) && !['DATE', 'FIRST_DATE_IN_INTERVAL'].includes(widget.grouping.type)) {
    values = grouping.slice().sort((a: any, b: any) => b.value - a.value);
  }
  switch (widget.type) {
    case 'NUMBER':
      return (
        <Box display='flex' justifyContent='end' height='100%' flexDirection='column'>
          <Typography variant='displayLarge'>
            {
              widget.format === 'CENTS' ? formatMoneyValue(values.value) : values.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          </Typography>
        </Box>
      );
    case 'PIE':
      return (
        <Box sx={{
          p: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <PieChart
            tooltip
            labels={values.map((x: any) => x.key)}
            datasets={[{ label: widget.title, data: values.map((x: any) => x.value) }]}
            // format={widget.format}
          />
        </Box>
      );
    case 'BAR':
      return (
        <Box sx={{
          p: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <BarChart
            labels={values.map((x: any) => (widget.grouping.type === 'DATE' ? dateFormat(x.key, widget.grouping.interval) : x.key))}
            datasets={[{ label: widget.title, data: values.map((x: any) => x.value) }]}
            format={widget.format}
          />
        </Box>
      );
    case 'LINE':
      return (
        <Box sx={{
          p: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
          <LineChart
            showAxis
            yAxisPosition='left'
            labels={values.map((x: any) => (['DATE', 'FIRST_DATE_IN_INTERVAL'].includes(widget.grouping.type) ? dateFormat(x.key, widget.grouping.interval) : x.key))}
            datasets={[{ label: widget.title, data: values.map((x: any) => x.value) }]}
            format={widget.format}
          />
        </Box>
      );
    // no default
  }

  return <></>;
};

const Widget = ({
  widget, setOpen, setActiveWidget,
}: {
  widget: {
    title: string,
    filters: any[],
    formatMoneyValue: string,
    format: string,
    type: string,
    reportType: string,
    grouping: any,
    height: number,
    sortField?: string,
  },
  setOpen: (open: boolean) => void,
  setActiveWidget: (widget: any) => void,
  ref?: any,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const [report, setReport] = useState(widget);
  const { t } = useTranslation(['customReports']);
  const GENERATE_REPORT = gql`
    query generateReport($input: GenerateReportInput!) {
      generateReport(input: $input) {
        grouping
        totalCount
      }
    }
  `;

  const { loading, data } = useQuery(GENERATE_REPORT, {
    variables: {
      input: {
        filters: [{
          field: 'organization',
          comparison: 'EQUALS',
          value: activeOrganization.id,
        }, ...report.filters.map((x: any) => ({ ...x, __typename: undefined }))],
        columns: [],
        type: report.reportType,
        grouping: { ...report.grouping, __typename: undefined },
        pagination: { perPage: 0, page: 1 },
        sorting: { sortField: report.reportType === 'DAILY_STATS' ? report.sortField : 'createdAt', sortDesc: true },
      },
    },
  });

  useEffect(() => {
    setReport(widget);
  }, [widget]);

  return (
    <>
      <Card sx={{
        height: '100%',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }} onClick={() => {
        setOpen(true);
        setActiveWidget(report);
      }}>
        <Box p={2} height='calc(100% - 32px)' justifyContent='space-between' display='flex' flexDirection='column'>
          <Box display='flex' justifyContent='space-between'>
            <Typography sx={{
              color: '#667380 !important', fontSize: '14px !important', letterSpacing: '0.05em !important', fontWeight: 500, lineHeight: '24px',
            }}>
              {report.title.toUpperCase()}
            </Typography>
            {['DATE', 'FIRST_DATE_IN_INTERVAL'].includes(report.grouping.type) && (
              <SelectField
                label=''
                sx={{ '.MuiSelect-select': { pt: '3px', pb: '3px' } }}
                size='small'
                value={report.grouping.interval}
                onClick={(e: any) => e.stopPropagation()}
                onChange={(e: any) => {
                  setReport({ ...report, grouping: { ...report.grouping, interval: e.target.value } });
                  e.preventDefault();
                }}
              >
                <MenuItem value="DAY">{t('day')}</MenuItem>
                <MenuItem value="WEEK">{t('week')}</MenuItem>
                <MenuItem value="MONTH">{t('month')}</MenuItem>
                <MenuItem value="YEAR">{t('year')}</MenuItem>
              </SelectField>
            )}
          </Box>
          <Box sx={{ height: `calc(${report.height * 128}px - 61px)` }}>
            {
              loading ? (
                <Box sx={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                }}>
                  <CircularProgress />
                </Box>
              ) : (
                data && RenderWidget(report, data.generateReport.grouping)
              )
            }
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Widget;
