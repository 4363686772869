import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../../providers/userContextProvider';

export const FETCH_PRODUCTS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        name
        state
      }
    }
  }
`;
const ModelPortfoliosSelect = ({
  onChange, value, label, disabled = false, readonly = false, themeId, size, focused, inheritedText, includeAnyOption, suggestableOnly,
}: {
  onChange: (event: any) => void, value: string, label: string, disabled?: boolean, readonly?: boolean, themeId?: string, inheritedText?: string, includeAnyOption?: boolean, suggestableOnly?: boolean
  size?: 'small' | 'medium' | undefined, focused?: boolean,
}) => {
  const { t } = useTranslation(['components']);
  const { activeOrganization } = useContext(UserContext);
  const { loading, error, data } = useQuery(FETCH_PRODUCTS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeId,
          suggestable: suggestableOnly,
        },
        pagination: { perPage: 100 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <TextField
        select
        focused={focused}
        value={data ? value : ''}
        label={label}
        size={size}
        fullWidth
        onChange={onChange}
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
        }}
      >
        { includeAnyOption && (
          <MenuItem value="ANY">{t('components:any')}</MenuItem>
        )}
        {
          loading ? <MenuItem>...</MenuItem> : (
            data.fetchModelPortfolios.modelPortfolios.filter((x: any) => x.state !== 'INACTIVE').map((x: any) => (
              <MenuItem key={Math.random()} value={x.id}>{`${x.name}${inheritedText ?? ''}`}</MenuItem>
            ))
          )
        }
      </TextField>
    </>
  );
};

export default ModelPortfoliosSelect;
