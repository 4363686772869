import {
  ListItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from '../../../components/modals/formModal';
import FeeGridSelect from '../../../components/inputs/feeGridSelect';
import BillingScheduleSelect from '../../../components/inputs/billingScheduleSelect';

const EditBillingAndFeeTier = ({
  loading, open, setOpen, highlight, organizationId, applicableFeeTierId, feeTierId, applicableBillingScheduleId,
  billingScheduleId, callback, title,
}: {
  loading: boolean, open: boolean, highlight?: string, organizationId: string, feeTierId?: string, applicableFeeTierId: string, setOpen: (value: boolean) => void,
  applicableBillingScheduleId: string, billingScheduleId?: string, callback: (event: any, applicableFeeTierId: string,
    applicableBillingScheduleId: string) => void, title?: string,
}) => {
  const { t } = useTranslation('client');
  const [changeableApplicableFeeTier, setChangeableApplicableFeeTier] = useState(!feeTierId ? 'inherited' : applicableFeeTierId);
  const [changeableApplicableBillingSchedule, setChangeableApplicableBillingSchedule] = useState(!billingScheduleId ? 'inherited' : applicableBillingScheduleId);
  return (
    <FormModal
      loading={loading}
      title={title ?? t('billingModal.title')}
      formButton={t('update')}
      onSubmit={(e) => callback(e, changeableApplicableFeeTier, changeableApplicableBillingSchedule)}
      open={open}
      handleClose={() => setOpen(false)}
    >
      <ListItem>
        <FeeGridSelect
          label={t('feeAndBilling:feeReport.table.feeGrid')}
          focused={highlight === 'feeGrid'}
          setValue={(newValue) => setChangeableApplicableFeeTier(newValue)}
          value={changeableApplicableFeeTier || ''}
          organizationId={organizationId}
          showInherited
        />
      </ListItem>
      <ListItem>
        <BillingScheduleSelect
          label={t('billingModal.billingCycle')}
          focused={highlight === 'billingCycle'}
          setValue={(newValue) => setChangeableApplicableBillingSchedule(newValue)}
          value={changeableApplicableBillingSchedule}
          organizationId={organizationId}
          showInherited
        />
      </ListItem>
    </FormModal>
  );
};

export default EditBillingAndFeeTier;
