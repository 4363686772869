import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Account,
  DraftAccount,
  Goal,
  GoalStates,
  User,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import {
  Typography,
} from 'ovComponents/1-primative';
import {
  Autocomplete,
} from 'ovComponents/2-component';
import { getGoalTitle } from 'ovComponents/resources';
import { WorkflowContext } from '../../workflowCompletion';

// Select only active goals related to the an individual (not houeholds)
export const getValidGoals = (userData: User) => (userData?.goals ?? []).filter((goal: Goal) => (goal.state === GoalStates.ACTIVE && !goal?.householdClientGroup?.id));

interface GoalLinkingInputProps {
  goals: Goal[],
  goalsLinked: Goal[],
  helpText?: string,
  error?: boolean,
  errorText?: string,
  success?: boolean,
  successText?: string,
  setGoalsLinked: (goalsLinked: Goal[]) => void,
  onBlur?: (e: React.ChangeEvent) => void,
}

export const GoalLinkingInput = (props: GoalLinkingInputProps) => {
  const {
    goals, goalsLinked, setGoalsLinked, ...restProps
  } = props;
  const { t } = useTranslation(['client', 'workflowCompletions']);
  const { workflowData } = useContext(WorkflowContext);

  useEffect(() => {
    if (workflowData.currentGoalId) {
      setGoalsLinked([{ id: workflowData.currentGoalId, name: workflowData.currentGoalName, user: {} as User }]);
    }
  });

  return (
    <>
      <Typography variant='headingXSmall' sx={{ mb: 1, color: colors.neutral800 }}>
        {t('workflowCompletions:goalLinking.title')}
      </Typography>
      <Typography variant='bodyMedium' sx={{ mb: 1, color: colors.neutral600 }}>
        {t('workflowCompletions:goalLinking.info')}
      </Typography>
      <Autocomplete
        label={t('workflowCompletions:goalLinking.inputLabel')}
        options={goals}
        value={goalsLinked ?? []}
        multiple
        fullWidth
        getOptionLabel={(option: any) => getGoalTitle(option)}
        onChange={(e: React.SyntheticEvent, value: any) => setGoalsLinked(value) }
        {...restProps}
      />
    </>
  );
};

export const GoalLinkingReview = ({ account }: { account: Account & DraftAccount }) => {
  const { t } = useTranslation(['client', 'workflowCompletions']);
  if (!account?.goalsLinked?.length) return <></>;
  const goalLabels = account.goalsLinked.map((goal) => getGoalTitle(goal));
  const goalLabelsWithBreaks = goalLabels.flatMap((el) => [<br/>, el]).slice(1);
  return (
    <>
      <Typography variant='labelLarge' weight='bold'>{t('workflowCompletions:goalLinking:goalsLinked')}:</Typography>
      <Typography variant='bodyMedium' sx={{ textAlign: 'right' }}>{goalLabelsWithBreaks}</Typography>
    </>
  );
};
