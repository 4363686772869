import { gql, useQuery } from '@apollo/client';
import {
  Checkbox, CircularProgress, Table, TableBody, TableCell, TableRow, Tooltip, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const FETCH_PERMISSIONS = gql`
  query fetchPermissions {
    fetchPermissions {
      permissions {
        value
        translatedDescription {
          en
        }
      }
    }
  }
`;

export default function MultipleSelectChip({
  permissions,
  onChange,
  scopedPermissions,
}: {
  permissions: string[],
  onChange: (event: any) => void,
  scopedPermissions?: string[],
}) {
  const { t } = useTranslation('orgSettings');
  const { loading, error, data } = useQuery(FETCH_PERMISSIONS);
  const [grouping, setGrouping] = useState<any>({});
  if (error) (<Typography>Error</Typography>);
  if (loading) (<CircularProgress />);

  useEffect(() => {
    if (data) {
      const ps = scopedPermissions || data.fetchPermissions.permissions.map(({ value }: { value: string }) => value);
      const groups: any = {};

      ps.forEach((permission: string) => {
        const permSplit = permission.split(':');
        if (!groups[permSplit[1].replace('_basic', '')]) {
          groups[permSplit[1].replace('_basic', '')] = [];
        }
        groups[permSplit[1].replace('_basic', '')].push(permission);
      });
      setGrouping(groups);
    }
  }, [data, scopedPermissions]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>{ t('orgSettings:roleModal.permissionsTable.read') }</TableCell>
          <TableCell>{ t('orgSettings:roleModal.permissionsTable.write') }</TableCell>
          <TableCell>{ t('orgSettings:roleModal.permissionsTable.transition') }</TableCell>
        </TableRow>
        {Object.keys(grouping).map((key: string) => (
          <TableRow key={ key }>
            <TableCell>
              { key }
              {(key === 'form_template')
                && (
                  <Tooltip title={t('orgSettings:roleModal.formTemplateWarning')} placement="right-end">
                    <InfoIcon sx={{ position: 'relative', top: '5px', cursor: 'pointer' }} fontSize='small' />
                  </Tooltip>
                )}
            </TableCell>
            <TableCell>
              <Checkbox
                value={grouping[key].find((x: string) => x.includes('read'))}
                onChange={onChange}
                disabled={!grouping[key].find((x: string) => x.includes('read'))}
                checked={permissions.includes(grouping[key].find((x: string) => x.includes('read')))}
              />
              </TableCell>
            <TableCell>
              <Checkbox
                value={grouping[key].find((x: string) => x.includes('write'))}
                onChange={onChange}
                disabled={!grouping[key].find((x: string) => x.includes('write'))}
                checked={permissions.includes(grouping[key].find((x: string) => x.includes('write')))}
              />
              </TableCell>
            <TableCell>
              <Checkbox
                value={grouping[key].find((x: string) => x.includes('transition'))}
                onChange={onChange}
                disabled={!grouping[key].find((x: string) => x.includes('transition'))}
                checked={permissions.includes(grouping[key].find((x: string) => x.includes('transition')))}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
