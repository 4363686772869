/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import {
  Button,
  MenuItem,
  SelectField,
  TextField,
} from 'ovComponents/2-component';
import { Affiliation, AffiliationRelations, AffiliationTypes } from 'interfaces';
import { Box, Grid } from 'ovComponents/1-primative';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect, useState } from 'react';
import { ClientSelectField } from '../../2-component/clientSelectField/clientSelect';
import { BeneficiaryTable } from './beneficiaryTable';

const initBeneficiary = {
  allocation: 0,
  id: '',
  relation: AffiliationRelations.Child,
  signatureRequired: false,
  type: AffiliationTypes.PrimaryBeneficiary,
};

export const BeneficiariesCrud = ({
  beneficiaries, setBeneficiaries, disabled,
}: {
  beneficiaries: Affiliation[],
  setBeneficiaries: (beneficiaries: Affiliation[]) => void,
  hideAllocation?: boolean,
  disabled?: boolean
}) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes', 'components']);

  const [beneficiary, setBeneficiary] = useState<Partial<Affiliation>>(initBeneficiary);

  /* clean when disabled */
  useEffect(() => {
    if (disabled === true) {
      setBeneficiaries([]);
    }
  }, [disabled]);

  const validateBeneficiary = (): boolean => {
    if (beneficiary && beneficiary.user?.id && beneficiary.relation) return true;
    return false;
  };

  const addOrUpdateBeneficiary = (): void => {
    if (!validateBeneficiary()) return;
    if (beneficiaries.some((item) => beneficiary.user && beneficiary.user.id === item.user?.id)) {
      const updatedBeneficiaries = beneficiaries.map((item) => {
        if (beneficiary.user && beneficiary.user.id === item.user?.id) return beneficiary as Affiliation;
        return item;
      });

      setBeneficiaries([...updatedBeneficiaries]);
    } else {
      setBeneficiaries([...beneficiaries, (beneficiary as Affiliation)]);
    }
  };
  return (
    <>
      <BeneficiaryTable
        beneficiaries={beneficiaries as Affiliation[]}
        setBeneficiaries={setBeneficiaries}
      />
      <Grid
        container
        display='flex'
        flexDirection='row'
        sx={{ mb: 2, mt: 1 }}
        justifyContent='space-between'
        spacing={1}
        alignItems='baseline'
      >
        <Grid item xs={4}>
          <ClientSelectField
            label={t('components:beneficiaryCrud.beneficiaryName')}
            user={beneficiary.user}
            setUser={(item) => setBeneficiary({ ...beneficiary, user: item })}
            fullWidth
            disabled={disabled}
            autoFocus
          />
        </Grid>
        <Grid item xs={5}>
          <SelectField
            onChange={(e: any) => setBeneficiary({ ...beneficiary, relation: e.target.value })}
            label={t('components:beneficiaryCrud.relationshipToAccountHolder')}
            fullWidth
            value={beneficiary.relation}
            disabled={disabled}
          >
            {
              Object.values(AffiliationRelations).filter((item) => item !== 'PRIMARY_CAREGIVER' && item !== 'GUARDIAN').map((relation, index) => (
                <MenuItem key={index} value={relation}>{t(`affiliationTypes:affiliationRelationship.${relation}`)}</MenuItem>
              ))
            }
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            type='number'
            onChange={(e: any) => setBeneficiary({ ...beneficiary, allocation: Number(e.target.value) })}
            label={t('components:beneficiaryCrud.allocation')}
            fullWidth
            sx={{ mt: 1 }}
            value={beneficiary.allocation}
            trailingIcon='percent'
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Box width='100%' display='flex' justifyContent='start'>
        <Button
          variant='outlined'
          leadingIcon={AddRoundedIcon}
          label={t('components:beneficiaryCrud.addAffiliate')}
          disabled={!validateBeneficiary()}
          onClick={() => addOrUpdateBeneficiary()}
          type='button'
        />
      </Box>
    </>
  );
};
