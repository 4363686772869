import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Languages } from '../../../interfaces';
import {
  Dialog, DialogTitle, IconButton,
} from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const UPDATE_ORGANIZATION_USER_LANGUAGE = gql`
  mutation updateOrganizationUserProfile($input: UpdateOrganizationUserProfileInput!) {
    updateOrganizationUserProfile(input: $input) {
      organizationUser {
        id
        language
      }
    }
  }
`;

export const LanguageSelectionModal = ({
  open, setOpen,
}: {
  open: boolean, setOpen: (open: boolean) => void,
}) => {
  const { t } = useTranslation('components');
  const { sys } = useThemeTokens();
  const { activeLanguage, setActiveLanguage } = useContext(UserContext);
  const languages = Object.values(Languages);

  const [updateOrganizationUserLanguage] = useMutation(UPDATE_ORGANIZATION_USER_LANGUAGE);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: `1px ${sys.color.primaryContainer} solid` }}>
        <Typography variant='headingSmall'>{t('languageSelection.language')}</Typography>
        <IconButton size='small' onClick={() => setOpen(false)}>
          <CloseIcon sx={{ color: sys.color.onSurface }} />
        </IconButton>
      </DialogTitle>
      {languages.map((lang: Languages) => (
        <Box
          key={lang}
          sx={{
            paddingY: 2.5,
            paddingX: 3,
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: activeLanguage === lang ? `${sys.color.primaryContainer}` : undefined,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: sys.color.surfaceContainer,
            },
          }}
          onClick={() => {
            updateOrganizationUserLanguage({
              variables: {
                input: {
                  language: lang,
                },
              },
              onCompleted: (data: any) => setActiveLanguage(data.updateOrganizationUserProfile.organizationUser.language),
            });
            setOpen(false);
          }}
        >
          <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>
            {t(`languageSelection.languageList.${lang}`)}
          </Typography>
          {activeLanguage === lang && <CheckIcon />}
        </Box>
      ))}
    </Dialog>
  );
};
