import { translateBackend } from 'assets/i18n/config';
import { Grid, Switch, NumberField } from '../..';

export const NumberFieldEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => (
  <Grid container sx={{ mb: 2 }} display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
    <Grid item xs={10}>
   <NumberField
   sx={{ p: 0 }}
   label={translateBackend(option.label)}
   number={option?.value?.number.toString()}
   disabled={!option.value?.enabled}
   setNumber={(value) => {
     const newOptions = [...options];
     newOptions[i].value = { ...option.value, number: value };
     setOptions(newOptions);
   }}
   />
    </Grid>
    <Grid item xs={2} justifyContent='center' display='flex' sx={{ mt: 2 }}>
   <Switch
   sx={{ mb: 1 }}
   checked={option.value?.enabled}
   onChange={(e:any) => {
     const newOptions = [...options];
     newOptions[i].value = { ...option.value, enabled: e };
     setOptions(newOptions);
   }}
   ></Switch>
    </Grid>
  </Grid>
);
