import {
  Typography, Box, CircularProgress, Grid, Button, TextField, InputAdornment, IconButton,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const FETCH_ORGANIZATION = (organizationId: string) => gql`
  query fetchOrganization {
    fetchOrganization(organizationId: "${organizationId}") {
      organization {
        id
        jwtKeyUrl
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        jwtKeyUrl
      }
    }
  }
`;

const OrgEmbeddedWebApp = ({ afterCreate }: { afterCreate?: () => void }) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['devSettings']);
  const { showToast } = useGlobalToast();
  const [loadJwtKeyUrl, setJwtKeyUrl] = useState('');
  const { loading, error, refetch } = useQuery(FETCH_ORGANIZATION(activeOrganization.id ?? ''), {
    onCompleted: (e) => setJwtKeyUrl(e.fetchOrganization.organization.jwtKeyUrl),
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION);

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Box display='flex' flexDirection="row" justifyContent='start' mb={1}>
            <Typography variant='subtitle1' sx={{ mr: '5px' }}>{t('devSettings:orgInfo.organizationId')}:</Typography>
            <Typography variant='subtitle1'>{activeOrganization.id}</Typography>
          </Box>
          <Box display='flex' flexDirection="row" justifyContent='start' mb={2}>
            <Typography variant='subtitle1' sx={{ mr: '5px' }}>{t('devSettings:orgInfo.webAppURL')}:</Typography>
            <Typography variant='subtitle1'>{process.env.REACT_APP_EMBEDDED_WEB_APP_URL}</Typography>
          </Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs="auto">
              <Typography variant='subtitle1'>{t('devSettings:orgInfo.partnerJWK')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField data-testid="jwk-input-field" fullWidth variant="outlined" value={(loadJwtKeyUrl || '')}
                onChange={(e) => setJwtKeyUrl(e.target.value)}
                InputProps={{
                  readOnly: !permissions.includes('write:organizations'),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        disabled
                        data-testid="modify-button"
                        edge="end"
                      >
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
            </Grid>
          </Grid>
          {permissions.includes('write:organizations') && (
            <Box display='flex' flexDirection="row" justifyContent='end' mt={1}>
              <Button data-testid='update-btn' size='small' variant='contained' color='primary' disabled={loading || loadJwtKeyUrl == null || loadJwtKeyUrl.length <= 0} onClick={async (e) => {
                e.stopPropagation();
                await updateOrganization({
                  variables: { input: { organizationId: activeOrganization.id, jwtKeyUrl: loadJwtKeyUrl } },
                  onCompleted(result) {
                    setJwtKeyUrl(result.updateOrganization.organization.jwtKeyUrl);
                    showToast({ severity: 'success', message: t('devSettings:successToastMessage') });
                    if (afterCreate) afterCreate();
                  },
                });
                refetch();
              }}>
                {t('update')}
              </Button>
            </Box>
          )}
        </>
      )
      }
    </Box >
  );
};

export default OrgEmbeddedWebApp;
