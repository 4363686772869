import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button } from '../../2-component';
import { Box } from '../../1-primative';
import { DepositWorkflow } from '../depositWorkflow/depositWorkflow';
import { WithdrawWorkflow } from '../withdrawWorkflow/withdrawWorkflow';
import { ActionContext, PageObjectType } from '../../5-page';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { CREATE_WORKFLOW_COMPLETION } from '../../../components/layout/client/clientSummary';
import { UserContext } from '../../../providers/userContextProvider';

export const InitiateTransfer = ({ objectId, objectType, options = {} }: { objectId: string, objectType: PageObjectType, options?: any }) => {
  const { t } = useTranslation('pageConfiguration');
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { activeWorkflows, refetch: refetchWorkflow } = useContext(ActionContext);
  const [depositOpen, setDepositOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [params, setSearchParams] = useSearchParams();
  const { activeOrganization } = useContext(UserContext);
  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      refetchWorkflow();
      updateWorkflowCompletion(data.createWorkflowCompletion?.workflowCompletion?.id);
    },
  });

  const updateWorkflowCompletion = (workflowCompletionId?: string) => {
    if (!workflowCompletionId) return;
    const newParams = new URLSearchParams(params);
    newParams.set('workflowCompletion', workflowCompletionId);
    setSearchParams(newParams);
  };

  const initiateWorkflow = (type: 'DEPOSIT' | 'WITHDRAW', workflowId?: string) => {
    if (!workflowId) {
      if (type === 'DEPOSIT') { setDepositOpen(true); }
      if (type === 'WITHDRAW') { setWithdrawOpen(true); }
      return;
    }
    const activeWorkflow = activeWorkflows.find((x: any) => x?.workflow?.id === workflowId);
    if (activeWorkflow) {
      updateWorkflowCompletion(activeWorkflow?.id);
      return;
    }
    createWorkflowCompletion({
      variables: {
        input: {
          objectId,
          objectType: ['INDIVIDUAL', 'NON_INDIVIDUAL'].includes(objectType) ? 'USER' : objectType,
          workflowId,
          organizationId: activeOrganization.id,
        },
      },
    });
  };

  return (
    <Box display='flex' flex={{ xs: 1, md: 0 }}>
      { !options?.hideWithdraw && (
        <>
          <Button
            variant='outlined'
            dataTestId='withdraw-button'
            onClick={() => {
              if (objectType === 'INDIVIDUAL') {
                sendAnalytic(ovAnalyticsEvents.homepageWithdrawButtonSelect);
              } else if (objectType === PageObjectType.GOAL) {
                sendAnalytic(ovAnalyticsEvents.goalDetailsWithdrawButtonSelect);
              }
              initiateWorkflow('WITHDRAW', options?.withdrawWorkflow?.value);
            }}
            label={t('withdraw')}
            sx={{ mr: 1, flex: 1 }}
          />
          <WithdrawWorkflow open={withdrawOpen} setOpen={setWithdrawOpen} objectId={objectId} objectType={objectType} />
        </>
      )}
      { !options?.hideDeposit && (
        <>
          <Button
            onClick={() => {
              if (objectType === 'INDIVIDUAL') {
                sendAnalytic(ovAnalyticsEvents.homepageDepositButtonSelect);
              } else {
                sendAnalytic(ovAnalyticsEvents.goalDetailsDepositButtonSelect);
              }
              initiateWorkflow('DEPOSIT', options?.depositWorkflow?.value);
            }}
            label={t('deposit')}
            dataTestId='deposit-button'
            sx={{ flex: 1 }}
          />
          <DepositWorkflow
            open={depositOpen}
            setOpen={setDepositOpen}
            objectId={objectId}
            objectType={objectType}
          />
        </>
      )}
    </Box>
  );
};
