import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocalization } from 'util/useLocalization';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DateTime = (props: any) => {
  const { localizedDateTime } = useLocalization();

  if (!props.date) {
    return null;
  }

  const dayjsObject = dayjs.isDayjs(props.date) ? props.date : dayjs(props.date);

  return <Typography {...props}>
    {
      localizedDateTime(
        props.timezone
          ? dayjsObject.tz(props.timezone)
          : dayjsObject,
      )
    }
  </Typography>;
};
