import { ApolloError, gql, useMutation } from '@apollo/client';
import { FETCH_GOAL } from 'pages/goalsDetails';
import { useTranslation } from 'react-i18next';
import { FETCH_USER } from '../../pages/client';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { usePermissions } from '../../providers/userContextProvider';
import ConfirmationModal from './confirmationModal';

export const COMPLETE_GOAL = gql`
  mutation completeGoal($input: CompleteGoalInput!) {
    completeGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

const CloseGoalModal = ({
  handleClose, open, goalId, popNavigate, refetch,
}: { goalId: string, handleClose: () => void, popNavigate: () => void, open: boolean, refetch?: () => void, }) => {
  const { t } = useTranslation(['goalsDetails']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();

  const completeGoalError = (error: ApolloError): void => {
    showToast({ message: error.graphQLErrors[0].message, severity: 'error' });
  };

  const onCompleted = (data: { closeGoal: { goal: { id: string } } }): void => {
    handleClose();
    if (refetch) {
      refetch();
    }
    popNavigate();
    showToast({ message: t('goalsDetails:toastMessage.success'), severity: 'success' });
  };

  const [completeGoalMutation] = useMutation(COMPLETE_GOAL, {
    variables: {
      input: {
        goalId,
      },
      skipErrorHandler: true,
    },
    refetchQueries: [FETCH_USER(permissions), FETCH_GOAL(permissions)],
    onCompleted,
    onError: (e) => completeGoalError(e),
  });

  return (
    <>
      <ConfirmationModal
        title={t('goalsDetails:closeGoalDialog.title')}
        bodyText={t('goalsDetails:closeGoalDialog.subtitle')}
        open={open}
        maxWidth='sm'
        confirmButton={t('goalsDetails:closeGoalDialog.continueButton')}
        cancelButton={t('goalsDetails:closeGoalDialog.cancelButton')}
        onConfirm={() => completeGoalMutation()}
        onCancel={handleClose}
      />
    </>
  );
};

export default CloseGoalModal;
