import {
  FormControlLabel, FormGroup, InputAdornment, ListItem, MenuItem, Switch, TextField,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { round } from 'lodash';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import CreateNewModal from '../../modals/createNewModal';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import NumberField from '../../inputs/numberField';
import SecuritySelect from '../../inputs/securitySelect';

export const CREATE_TRADE = gql`
  mutation createTrade($input: CreateTradeInput!) {
    createTrade(input: $input) {
      trade {
        id
      }
    }
  }
`;

export const FETCH_FINANCIAL_PRODUCT = gql`
  query fetchFinancialProduct($input: FetchFinancialProductInput!) {
    fetchFinancialProduct(input: $input) {
      financialProduct {
        isPartial
        currentPriceCents
        priceHistoryUrl
      }
    }
  }
`;

const checkForFractionalTrade = (isPartial: boolean, quantity?: number): boolean => {
  if (!isPartial && hasDecimalPlaces(quantity)) return true;
  return false;
};

interface FormatterProps {
  onChange: (event: { target: { valueCents: undefined | number; name: string; value: string } }) => void;
  name: string;
}

const PriceFormatter = forwardRef<NumberFormat<string>, FormatterProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      decimalScale={6}
      onValueChange={(values) => {
        if (Number.isNaN(Number(values.value))) {
          return;
        }
        const { formattedValue, value } = values;
        onChange({
          target: {
            name: props.name,
            value: formattedValue,
            valueCents: value === '' ? undefined : round(Number(value) * 100, 4),
          },
        });
      }}
      thousandSeparator
      isNumericString
      defaultValue={0}
    />
  );
});

interface TradeProps {
  subAccountId?: string;
  financialProductId?: string;
  type: 'BUY' | 'SELL';
  actualQuantity?: number;
  actualTradePriceCents?: number;
  effectiveDate: string;
  description?: string;
  createExternalTransfer?: boolean;
}

const hasDecimalPlaces = (quantity?: any): boolean => {
  if (quantity) return quantity - Math.floor(quantity) !== 0;
  return false;
};

const NewTrade = ({ afterCreate, forObject, forId }: { afterCreate: () => void; forObject: 'USER' | 'CLIENT_GROUP' | 'ACCOUNT'; forId: string }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const [trade, setTrade] = useState<TradeProps>({
    type: 'BUY',
    effectiveDate: dayjs().format('YYYY-MM-DD'),
  });
  const [isReconciled, setIsReconciled] = useState(true);
  const [initialState] = useState({
    type: 'BUY',
    effectiveDate: dayjs().format('YYYY-MM-DD'),
  });

  const [avoidFractionalTrade, setAvoidFractionaltrade] = useState<boolean>(false);
  const [isPartial, setIsPartial] = useState<boolean>(true);
  const [priceToDisplay, setPriceToDisplay] = useState<string>('');

  const [createTrade, { loading }] = useMutation(CREATE_TRADE, {
    variables: {
      input: {
        ...trade,
        effectiveDate: isReconciled ? trade.effectiveDate : undefined,
        createExternalTransfer: isReconciled ? trade.createExternalTransfer : undefined,
      },
    },
  });

  const [fetchProduct] = useLazyQuery(FETCH_FINANCIAL_PRODUCT, {
    onCompleted: (data: any) => {
      setIsPartial(data?.fetchFinancialProduct?.financialProduct?.isPartial);
    },
  });

  useEffect(() => {
    if (trade.financialProductId && trade.actualQuantity) {
      fetchProduct({
        variables: {
          input: {
            financialProductId: trade.financialProductId,
          },
        },
      });
      setAvoidFractionaltrade(checkForFractionalTrade(isPartial, trade.actualQuantity));
    }
  }, [isPartial, trade.actualQuantity, trade.financialProductId, fetchProduct]);

  const create = async (event: any) => {
    const response = await createTrade();
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:tradeModal.createdToastMessage') });
    }
    setTrade({
      type: 'BUY',
      effectiveDate: dayjs().format('YYYY-MM-DD'),
    });
    afterCreate();
  };

  const disabled = !(trade.subAccountId && trade.financialProductId && trade.actualQuantity && trade.actualTradePriceCents);
  return (
    <CreateNewModal state={trade} initialState={initialState} loading={loading} disabled={disabled} title={t('components:tradeModal.title')} onSubmit={create}>
      <ListItem>
        <TextField select value={trade.type} label={t('components:tradeModal.type')} fullWidth onChange={(e: any) => setTrade((prev) => ({ ...prev, type: e.target.value }))}>
          <MenuItem value='BUY'>{t('components:tradeModal.types.buy')}</MenuItem>
          <MenuItem value='SELL'>{t('components:tradeModal.types.sell')}</MenuItem>
        </TextField>
      </ListItem>
      <ListItem>
        <SubAccountSelect
          setSubAccount={(e) => setTrade((prev) => ({ ...prev, subAccountId: e.target.value }))}
          value={trade.subAccountId ?? ''}
          label={t('components:tradeModal.portfolio')}
          forObject={forObject}
          forId={forId}
        />
      </ListItem>
      <ListItem>
        <SecuritySelect value={trade.financialProductId ?? ''} setSecurity={(e) => setTrade((prev) => ({ ...prev, financialProductId: e.id }))} label={t('components:tradeModal.financialProduct')} />
      </ListItem>
      <ListItem>
        <NumberField
          label={t('components:tradeModal.quantity')}
          fullWidth
          decimalPlaces={6}
          value={trade.actualQuantity ?? ''}
          setNumberValue={(value) => {
            setTrade((prev) => ({ ...prev, actualQuantity: value }));
            setAvoidFractionaltrade(checkForFractionalTrade(isPartial, trade.actualQuantity));
          }}
          inputError={avoidFractionalTrade}
          errorText={t('components:tradeModal.fractionalToastMessage')}
        />
      </ListItem>
      <ListItem>
        <TextField
          label={t('components:tradeModal.price')}
          fullWidth
          variant='outlined'
          InputProps={{
            inputComponent: PriceFormatter as any,
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          value={priceToDisplay}
          onChange={(e: any) => {
            setPriceToDisplay(e.target.name);
            setTrade((prev) => ({ ...prev, actualTradePriceCents: e.target.valueCents }));
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('components:tradeModal.description')}
          variant='outlined'
          value={trade.description}
          onChange={(e) => setTrade((prev) => ({ ...prev, description: e.target.value }))}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel control={<Switch checked={isReconciled} onChange={(event) => setIsReconciled(event.target.checked)} />} label={t('components:tradeModal.isReconciled')} />
        </FormGroup>
      </ListItem>
      {isReconciled && (
        <>
          <ListItem>
            <LocalizedDatePicker
              label={t('components:tradeModal.effectiveDate')}
              value={trade.effectiveDate}
              onChange={(date) => setTrade((prev) => ({ ...prev, effectiveDate: dayjs(date).format('YYYY-MM-DD') }))}
              renderInput={(params) => <TextField fullWidth {...params} />}
              disableFuture={true}
            />
          </ListItem>
          <ListItem>
            <FormGroup sx={{ height: '100%' }}>
              <FormControlLabel
                control={<Switch checked={trade.createExternalTransfer ?? false} onChange={(event) => setTrade((prev) => ({ ...prev, createExternalTransfer: event.target.checked }))} />}
                label={t('components:tradeModal.createExternalTransfer')}
              />
            </FormGroup>
          </ListItem>
        </>
      )}
    </CreateNewModal>
  );
};

export default NewTrade;
