export const updateNextWorkflowStep = (workflowCompletion: any, setWorkflowCompletion: (o: any) => void, onComplete: () => void) => {
  const currentSubStepIndex = workflowCompletion?.currentStep?.subSteps?.findIndex((subStep: any) => subStep.id === workflowCompletion.currentSubStep.id) || 0;
  const currentStepIndex = workflowCompletion?.workflow?.steps?.findIndex((step: any) => step.id === workflowCompletion.currentStep.id) || 0;

  if (currentSubStepIndex + 1 < workflowCompletion.currentStep.subSteps.length) {
    setWorkflowCompletion({
      ...workflowCompletion,
      currentSubStep: workflowCompletion?.currentStep?.subSteps[currentSubStepIndex + 1],
      direction: 'FORWARD',
    });
  } else if (currentStepIndex + 1 < workflowCompletion.workflow.steps.length) {
    setWorkflowCompletion({
      ...workflowCompletion,
      currentStep: workflowCompletion?.workflow?.steps[currentStepIndex + 1],
      currentSubStep: workflowCompletion?.workflow?.steps[currentStepIndex + 1]?.subSteps[0],
      direction: 'FORWARD',
    });
  } else {
    onComplete();
  }
};

export const updatePreviousWorkflowStep = (workflowCompletion: any, setWorkflowCompletion: (o: any) => void) => {
  const currentSubStepIndex = workflowCompletion?.currentStep?.subSteps?.findIndex((subStep: any) => subStep.id === workflowCompletion.currentSubStep.id) || 0;
  const currentStepIndex = workflowCompletion?.workflow?.steps?.findIndex((step: any) => step.id === workflowCompletion.currentStep.id) || 0;

  if (currentSubStepIndex - 1 >= 0) {
    setWorkflowCompletion({
      ...workflowCompletion,
      currentSubStep: workflowCompletion?.currentStep?.subSteps[currentSubStepIndex - 1],
      direction: 'BACKWARD',
    });
  } else {
    setWorkflowCompletion({
      ...workflowCompletion,
      currentStep: workflowCompletion?.workflow?.steps[currentStepIndex - 1],
      currentSubStep: workflowCompletion?.workflow?.steps[currentStepIndex - 1]?.subSteps[workflowCompletion.workflow.steps[currentStepIndex - 1].subSteps.length - 1],
      direction: 'BACKWARD',
    });
  }
};
