import { useState } from 'react';
import {
  Autocomplete,
  Box, Button, Grid, InputAdornment, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DroppableFileInput from 'pages/client/components/droppableFileInput';
import dayjs from 'dayjs';
import { IdDocumentType, StepProps } from '../wizard';
import { UploadedFile } from './uploadedFile';
import { LocalizedDatePicker } from '../../../fields/localizedDatePicker';
import { menuProps } from '../../../../pages/client/components/idVerifications/editIdVerification';
import { useCountrySpecificBackendIdDocumentTypes, useCountrySpecificInformationSources } from '../../../../interfaces/IdVerification';

export const SecondDocumentStep = ({
  context, state, setState, continueTo, mutationEvent,
}: StepProps) => {
  const { t } = useTranslation(['addIdVerificationWizard', 'client']);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<string | null>('');
  const doSaveSecondDocument = () => {
    continueTo('SUMMARY_STEP');
  };
  const disabled = !(state.documentTwo?.secondaryDocumentID && state.documentTwo.secondaryDocumentIssuingJurisdication
    && state.documentTwo.secondaryDocumentType && state.documentTwo.file);
  const generateJurisdictionString = (option: string): string => {
    if (informationSources.includes(option)) {
      return t(`client:edit.jurisdicationOptionsForUsandCanada.${option}`);
    }
    return option;
  };
  const { idDocumentTypes } = useCountrySpecificBackendIdDocumentTypes();
  const informationSources = useCountrySpecificInformationSources();

  return (
    <Box sx={{
      height: '670px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem sx={{ marginBottom: '16px' }}>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <TextField select fullWidth disabled
            label={t('methodOfIDVerification')}
            value={state.methodOfIDVerification}
            onChange={(event) => setState({ ...state, methodOfIDVerification: event.target.value as IdDocumentType })}
          >
            <MenuItem key="DUAL_METHOD" value="DUAL_METHOD">{t('client:edit.methodOfIDVerificationOptions.DUAL_METHOD')}</MenuItem>
            <MenuItem key="IN_PERSON_VALIDATION" value="IN_PERSON_VALIDATION">{t('client:edit.methodOfIDVerificationOptions.IN_PERSON_VALIDATION')}</MenuItem>
          </TextField>
        </ListItem>
        <ListItem>
          <Typography variant='subtitle2'>{t('secondPageStepDualMethod')}</Typography>
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label={t('documentName')}
            value={state?.documentTwo?.documentName}
            onChange={(event) => {
              if (state.documentTwo) {
                setState({
                  ...state,
                  documentTwo: {
                    ...state.documentTwo,
                    documentName: event.target.value,
                  },
                });
              }
            }}
          />
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={6}>
                  <Autocomplete
                    id='second-document-jusrisdiction'
                    onChange={(event: any, newValue: string | null, reason: string) => {
                      if (state.documentTwo && newValue) {
                        setValue(newValue);
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentIssuingJurisdication: newValue,
                          },
                        });
                      }
                      if (reason === 'clear') {
                        if (state.documentTwo) {
                          setValue('');
                          setState({
                            ...state,
                            documentTwo: {
                              ...state.documentTwo,
                              secondaryDocumentIssuingJurisdication: '',
                            },
                          });
                        }
                      }
                    }}
                    onBlur={() => {
                      if (state.documentTwo && inputValue) {
                        setValue(inputValue);
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentIssuingJurisdication: inputValue,
                          },
                        });
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    value={value}
                    getOptionLabel={(option) => generateJurisdictionString(option)}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {generateJurisdictionString(option)}
                      </Box>
                    )}
                    options={informationSources}
                    fullWidth
                    noOptionsText={t('noOptionsText')}
                    renderInput={(params) => <TextField
                      {...params}
                      label={t('informationSource')}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'Enter'
                          && informationSources.findIndex((o) => o === inputValue) === -1
                        ) {
                          setValue(inputValue);
                          if (state.documentTwo && inputValue) {
                            setState({
                              ...state,
                              documentTwo: {
                                ...state.documentTwo,
                                secondaryDocumentIssuingJurisdication: inputValue,
                              },
                            });
                          }
                        }
                      }}
                    />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label={t('informationType')}
                    value={state?.documentTwo?.secondaryDocumentType}
                    SelectProps={{
                      MenuProps: menuProps,
                    }}
                    onChange={(event) => {
                      if (state.documentTwo) {
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentType: event.target.value,
                          },
                        });
                      }
                    }}
                  >
                    {idDocumentTypes.map((b) => (<MenuItem key={b} value={b} sx={{ whiteSpace: 'normal' }}>{t(`client:documentTypes.${b}`)}</MenuItem>))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t('documentID')}
                    value={state?.documentTwo?.secondaryDocumentID}
                    onChange={(event) => {
                      if (state.documentTwo) {
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentID: event.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizedDatePicker
                    label={t('expiryDate')}
                    value={state?.documentTwo?.secondaryDocumentExpiryDate || null}
                    onChange={(date) => {
                      if (state.documentTwo) {
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD'),
                          },
                        });
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('secondaryUserName')}
                    value={state?.documentTwo?.secondaryDocumentUserName}
                    onChange={(event) => {
                      if (state.documentTwo) {
                        setState({
                          ...state,
                          documentTwo: {
                            ...state.documentTwo,
                            secondaryDocumentUserName: event.target.value,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          {state.documentTwo?.file && (<UploadedFile file={state.documentTwo?.file} onDelete={() => {
            if (state.documentTwo) {
              setState({
                ...state,
                documentTwo: {
                  ...state.documentTwo,
                  file: undefined,
                },
              });
            }
          }} />)}
          {!state.documentTwo?.file && (<DroppableFileInput
            height='150px'
            onFileChosen={(dropFile: File) => {
              if (state.documentTwo) {
                setState({
                  ...state,
                  documentTwo: {
                    ...state.documentTwo,
                    file: dropFile,
                    documentName: state.documentTwo.documentName !== '' ? state.documentTwo.documentName : dropFile.name,
                  },
                });
              }
            }}
          />)}
        </ListItem>
      </Box>

      <Box m={2}>
        <Button type='submit' disabled={disabled}
          onClick={() => doSaveSecondDocument()} fullWidth variant='contained'>
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};
