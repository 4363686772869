import {
  TextField, ListItem,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import CreateNewModal from '../../../components/modals/createNewModal';
import { UserContext } from '../../../providers/userContextProvider';
import { User } from '../../../interfaces';

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
    }
  }
`;

const NewClient = ({
  afterCreate,
  buttonType,
  modalButton,
  fullWidth,
  customMargin,
  forceOpen,
  newClient,
  icon,
}: {
  afterCreate?: () => void,
  newClient?: (user: User) => void,
  buttonType?: 'BUTTON' | 'ICON' | 'NONE',
  modalButton?: string,
  fullWidth?: boolean,
  customMargin?: string,
  forceOpen?: boolean,
  icon?: any,
}) => {
  const { t } = useTranslation(['clients']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState({
    firstName: '',
    lastName: '',
    primaryEmail: '',
    organizationId: activeOrganization.id,
    language: 'ENGLISH',
    timezone: 'America/Edmonton',
  });
  const [initialState] = useState({
    firstName: '',
    lastName: '',
    primaryEmail: '',
    organizationId: activeOrganization.id,
    language: 'ENGLISH',
    timezone: 'America/Edmonton',
  });
  const [createClient, { loading }] = useMutation(CREATE_USER, {
    variables: {
      input: {
        ...client,
      },
    },
  });

  useEffect(() => {
    setClient({ ...client, organizationId: activeOrganization.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  const updateField = (event: any) => {
    const key: 'firstName' | 'lastName' | 'primaryEmail' = event.target.id;
    setClient({ ...client, [key]: event.target.value as string });
  };

  const createOrg = async (event: any) => {
    const response = await createClient();
    if (forceOpen) {
      setOpen(forceOpen);
    } else if (response && response.data && !response.errors) {
      navigate(`/clients/${response.data.createUser.user.id}`);
    }
    if (afterCreate) {
      afterCreate();
    }
    if (newClient) {
      newClient(response.data.createUser.user);
    }
  };

  return (
    <CreateNewModal
      loading={loading}
      title={t('addModal.title')}
      onSubmit={createOrg}
      buttonType={buttonType}
      modalButton={modalButton}
      icon={icon}
      forceOpen={open}
      fullWidth={fullWidth}
      state={client}
      initialState={initialState}
      sx={customMargin ?? { float: 'right', m: 1 }}>
      <ListItem>
        <TextField label={t('addModal.firstName')} sx={{ width: '100%' }} value={client.firstName} onChange={updateField} id='firstName' autoFocus/>
      </ListItem>
      <ListItem>
        <TextField label={t('addModal.lastName')} sx={{ width: '100%' }} value={client.lastName} onChange={updateField} id='lastName' />
      </ListItem>
      <ListItem>
        <TextField label={t('addModal.email')} sx={{ width: '100%' }} value={client.primaryEmail} onChange={updateField} id='primaryEmail' type='email' />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={client.organizationId}
          label={t('addModal.organization')}
          onChange={(event: any) => setClient({ ...client, organizationId: event.target.value as string })}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewClient;
