import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Date } from '../../../components/misc/date/date';
import NewIdVerification from './newIdVerification';
import { usePermissions } from '../../../providers/userContextProvider';

export const FETCH_IDS = gql`
  query fetchUserIdVerifications($input: FetchUserIdVerificationsInput!) {
    fetchUserIdVerifications(input: $input) {
      idVerifications {
        id
        documentExpiryDate
        documentID
        documentIssuingJurisdication
        documentType
        methodOfIDVerification
        verifiedDate
        userName
        secondaryDocumentExpiryDate
        secondaryDocumentID
        secondaryDocumentIssuingJurisdication
        secondaryDocumentType
        secondaryDocumentUserName
      }
      totalCount
    }
  }
`;

export const UPDATE_USER_ID = gql`
  mutation updateUserIDVerificationInformation($input: UpdateUserIDVerificationInformationInput!) {
    updateUserIDVerificationInformation(input: $input) {
      idVerification {
        id
        documentExpiryDate
        documentID
        documentIssuingJurisdication
        documentType
        methodOfIDVerification
        verifiedDate
        userName
      }
    }
  }
`;

const IDs = ({ userId }: { userId: string }) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['client']);
  const [page, setPage] = useState(1);
  const pageSize = 15;
  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_IDS, {
    variables: {
      input: {
        filter: { userId },
        pagination: {
          sortField: 'documentExpiryDate', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box style={{ overflowX: 'auto' }}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid>
          </Grid>
          <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell width="10%"><Typography variant='overline'>{t('idTable.documentId')}</Typography></TableCell>
                <TableCell width="20%"><Typography variant='overline'>{t('idTable.documentType')}</Typography></TableCell>
                <TableCell width="20%"><Typography variant='overline'>{t('idTable.methodOfIDVerification')}</Typography></TableCell>
                <TableCell width="15%"><Typography variant='overline'>{t('idTable.documentIssuingJurisdication')}</Typography></TableCell>
                <TableCell width="15%" align="right"><Typography variant='overline'>{t('idTable.documentExpiryDate')}</Typography></TableCell>
                <TableCell width="15%" align="right"><Typography variant='overline'>{t('idTable.verifiedDate')}</Typography></TableCell>
                <TableCell width="5%" align="right">
                  {permissions.includes('write:client_high_risk_pii') && (
                    <NewIdVerification
                      afterCreate={refetch}
                      userId={userId}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchUserIdVerifications?.idVerifications?.map((idV: any) => (
                <TableRow
                  hover
                  key={idV.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell>{idV.documentID}</TableCell>
                  <TableCell><Chip label={idV.documentType} size='small' /></TableCell>
                  <TableCell><Chip label={idV.methodOfIDVerification} size='small' /></TableCell>
                  <TableCell>{idV.documentIssuingJurisdication}</TableCell>
                  <TableCell align="right"><Date variant='subtitle2' date={idV.documentExpiryDate} /></TableCell>
                  <TableCell align="right"><Date variant='subtitle2' date={idV.verifiedDate} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchUsers?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default IDs;
