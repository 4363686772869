/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { FETCH_SUB_TRADES } from './subTradeRequestsTable';
import { Box, Grid, Typography } from '../../../../1-primative';
import { Dialog, DialogContent, DialogTitle, CircularProgress, Badge, IconButton, Table, TableBody, TableCell, TableRow, TableHeadCell, TableTotalCell } from '../../../../2-component';
import { formatMoneyValue } from '../../../../../util';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { GenerateSourceLink } from './subTradeRequestsTableRow';
import CancelSubTradeRequestModal from './cancelSubTradeRequestModal';
import { PAIR_SUB_TRADE_REQUESTS } from './generateTradesModal';
import { SubTradeRequest, SubTradeRequestStates, SubTradeRequestTypes } from '../../../../../interfaces/subTradeRequest';

type RemappedSubTradeRequest = SubTradeRequest | null;

interface PreparedData {
  buys: RemappedSubTradeRequest[];
  totalAmountBuys: number;
  sells: RemappedSubTradeRequest[];
  totalAmountSells: number;
}

const getItemsByType = (items: SubTradeRequest[], type: SubTradeRequestTypes) => items.filter((item: SubTradeRequest) => item?.type === type);

const calculateTotalAmountCents = (items: SubTradeRequest[]) => {
  const clearedItems = items.filter((item: SubTradeRequest) => item?.state !== SubTradeRequestStates.CANCELED);

  return clearedItems.reduce((cents: number, item: SubTradeRequest) => (item?.moneyAllocatedCents ?? 0) + cents, 0);
};

const prepareData = (data: SubTradeRequest[]): PreparedData => {
  const buys = getItemsByType(data, SubTradeRequestTypes.BUY);
  const sells = getItemsByType(data, SubTradeRequestTypes.SELL);

  const totalAmountBuys = calculateTotalAmountCents(buys);
  const totalAmountSells = calculateTotalAmountCents(sells);

  const remappedBuys: RemappedSubTradeRequest[] = [];
  const remappedSells: RemappedSubTradeRequest[] = [];

  buys.forEach((buy: SubTradeRequest) => {
    if (buy?.pair) {
      const pairIndex = sells?.findIndex((sell: SubTradeRequest) => sell?.id === buy?.pair?.id);

      if (pairIndex === -1) return;

      const pair = sells?.splice(pairIndex, 1)[0];

      remappedSells.push(pair);
    } else {
      remappedSells.push(null);
    }

    remappedBuys.push(buy);
  });

  // rest of the sells not having a buy pair
  sells.forEach((sell: SubTradeRequest) => {
    remappedBuys.push(null);
    remappedSells.push(sell);
  });

  return {
    buys: remappedBuys,
    totalAmountBuys,
    sells: remappedSells,
    totalAmountSells,
  };
};

const PairTableHead = ({ deletable }: { deletable: boolean }) => {
  const { t } = useTranslation(['components']);

  return (
    <TableRow>
      <TableHeadCell>{t('components:generateTrades.table.account')}</TableHeadCell>
      <TableHeadCell>{t('components:generateTrades.table.type')}</TableHeadCell>
      <TableHeadCell>{t('components:generateTrades.table.amount')}</TableHeadCell>
      <TableHeadCell>{t('components:generateTrades.table.security')}</TableHeadCell>
      <TableHeadCell>{t('components:generateTrades.table.settlementDays')}</TableHeadCell>
      <TableHeadCell>{t('components:generateTrades.table.state')}</TableHeadCell>

      {deletable && <TableHeadCell></TableHeadCell>}
    </TableRow>
  );
};

const NoPairTableRow = ({ deletable }: { deletable: boolean }) => {
  const { t } = useTranslation(['components']);

  return (
    <TableRow>
      <TableCell colSpan={deletable ? 7 : 6} sx={{ p: 1, textAlign: 'center' }}>
        <Typography>{t('tradePairsModal.noPair')}</Typography>
      </TableCell>
    </TableRow>
  );
};

const PairTableRow = ({ item, deletable, haveDeletableItems, onDelete }: { item: SubTradeRequest; deletable: boolean; haveDeletableItems: boolean; onDelete: (itemId: string) => void }) => {
  const { t } = useTranslation(['components']);

  return (
    <TableRow>
      <TableCell>
        <GenerateSourceLink item={item} />
      </TableCell>
      <TableCell>{item?.isSellAll ? t('tradePairsModal.sellAll') : item?.type}</TableCell>
      <TableCell>{formatMoneyValue(item?.moneyAllocatedCents)}</TableCell>
      <TableCell>{item?.financialProduct?.ticker}</TableCell>
      <TableCell>{item?.financialProduct?.settlementDays}</TableCell>
      <TableCell>
        <Badge label={item?.state} color={item?.state === SubTradeRequestStates.RECONCILED ? 'positive' : 'warning'} />
      </TableCell>

      {deletable && (
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => onDelete(item?.id)}>
            <DeleteIcon sx={{ fontSize: '1.2rem' }} />
          </IconButton>
        </TableCell>
      )}

      {!deletable && haveDeletableItems && <TableCell></TableCell>}
    </TableRow>
  );
};

const SubTradeRequestPairsModal = ({
  open,
  onClose,
  bulkTradeRequestId,
  sourceId,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  bulkTradeRequestId?: string;
  sourceId?: string;
  refetch?: () => void;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const [data, setData] = useState<PreparedData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [subTradeRequestIdToDelete, setSubTradeRequestIdToDelete] = useState<string>('');
  const [isShouldRefetchTrades, setIsShouldRefetchTrades] = useState<boolean>(false);

  const [pairSubTradeRequests] = useMutation(PAIR_SUB_TRADE_REQUESTS, {
    onCompleted: () => {
      setIsShouldRefetchTrades(true);
      fetchSubTradeRequestPairs();
    },
  });

  const [fetchSubTradeRequestPairs] = useLazyQuery(FETCH_SUB_TRADES(false), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          showSplits: true,
          bulkTradeRequestId,
          ...(sourceId && { sourceId }),
        },
        pagination: {
          sortField: 'subAccount',
          perPage: 100,
        },
      },
    },
    onCompleted: (response: any) => {
      const subTradeRequests: SubTradeRequest[] = response?.fetchSubTradeRequests?.subTradeRequests ?? [];

      const areTradesProcessedForPair = subTradeRequests?.filter((item: SubTradeRequest) => item?.state !== SubTradeRequestStates.CANCELED)?.every((item: SubTradeRequest) => item?.isProcessedForPair);

      if (!areTradesProcessedForPair) {
        pairSubTradeRequests({ variables: { input: { bulkTradeRequestId } } });
      } else {
        setData(prepareData(subTradeRequests));
        setLoading(false);
      }
    },
  });

  const isAllowedToOpen = useCallback(() => activeOrganization?.allowViewSubTradeRequestPairs && permissions.includes('write:sub_trade_request'), [activeOrganization, permissions]);

  useEffect(() => {
    if (!isAllowedToOpen()) return;

    if (open && bulkTradeRequestId) {
      setLoading(true);
      fetchSubTradeRequestPairs();
    }
  }, [open, bulkTradeRequestId, isAllowedToOpen, fetchSubTradeRequestPairs]);

  const isDeletableItemPresent = (items: RemappedSubTradeRequest[]) =>
    items?.some((item: RemappedSubTradeRequest) => (item ? [SubTradeRequestStates.INITIATED, SubTradeRequestStates.READY].includes(item?.state) : false)) &&
    permissions.includes('transition:sub_trade_request');
  const isDeletableItem = (item: SubTradeRequest) => [SubTradeRequestStates.INITIATED, SubTradeRequestStates.READY].includes(item?.state) && permissions.includes('transition:sub_trade_request');

  const buysHaveDeletable = data ? isDeletableItemPresent(data?.buys) : false;
  const sellsHaveDeletable = data ? isDeletableItemPresent(data?.sells) : false;

  const onDelete = (itemId: string) => {
    setSubTradeRequestIdToDelete(itemId);
    setOpenCancelModal(true);
  };

  const onCancelModalClose = () => {
    setOpenCancelModal(false);
  };

  const afterCancel = () => {
    fetchSubTradeRequestPairs();
    setLoading(true);
    setIsShouldRefetchTrades(true);
  };

  const onModalClose = () => {
    if (isShouldRefetchTrades && refetch) refetch();
    onClose();
    setIsShouldRefetchTrades(false);
  };

  if (!isAllowedToOpen()) {
    return <></>;
  }

  return (
    <>
      <Dialog open={open} onClose={onModalClose} maxWidth='xl' fullWidth>
        <DialogTitle onClose={onModalClose}>{t('tradePairsModal.modalTitle')}</DialogTitle>
        {loading && (
          <Grid container justifyContent='center' p={3}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        {!loading && (
          <>
            <DialogContent sx={{ overflow: 'unset' }}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='bodyLarge' weight='bold'>
                  {t('tradePairsModal.buys')}
                </Typography>
                <Typography>{t('tradePairsModal.pairedWith')}</Typography>
                <Typography variant='bodyLarge' weight='bold'>
                  {t('tradePairsModal.sells')}
                </Typography>
              </Box>
            </DialogContent>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6} sx={{ borderRight: '2px solid', borderRightColor: 'rgba(0, 0, 0, 0.12)', overflowX: 'auto' }}>
                  <Table sx={{ whiteSpace: 'nowrap' }}>
                    <TableBody>
                      <PairTableHead deletable={buysHaveDeletable} />

                      {data?.buys?.map((buy: RemappedSubTradeRequest) => {
                        if (!buy) return <NoPairTableRow key={uniqueId()} deletable={buysHaveDeletable} />;

                        return <PairTableRow key={buy?.id} item={buy} deletable={isDeletableItem(buy)} haveDeletableItems={buysHaveDeletable} onDelete={(itemId: string) => onDelete(itemId)} />;
                      })}

                      <TableRow>
                        <TableTotalCell colSpan={5}>{t('tradePairsModal.totalBuys')}:</TableTotalCell>
                        <TableTotalCell colSpan={buysHaveDeletable ? 2 : 1} number>
                          {formatMoneyValue(data?.totalAmountBuys)}
                        </TableTotalCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={6} sx={{ overflowX: 'auto' }}>
                  <Table sx={{ whiteSpace: 'nowrap' }}>
                    <TableBody>
                      <PairTableHead deletable={sellsHaveDeletable} />

                      {data?.sells?.map((sell: RemappedSubTradeRequest) => {
                        if (!sell) return <NoPairTableRow key={uniqueId()} deletable={sellsHaveDeletable} />;

                        return <PairTableRow key={sell?.id} item={sell} deletable={isDeletableItem(sell)} haveDeletableItems={sellsHaveDeletable} onDelete={(itemId: string) => onDelete(itemId)} />;
                      })}

                      <TableRow>
                        <TableTotalCell colSpan={5}>{t('tradePairsModal.totalSells')}:</TableTotalCell>
                        <TableTotalCell colSpan={sellsHaveDeletable ? 2 : 1} number>
                          {formatMoneyValue(data?.totalAmountSells)}
                        </TableTotalCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>

      <CancelSubTradeRequestModal id={subTradeRequestIdToDelete} open={openCancelModal} onClose={onCancelModalClose} afterCancel={afterCancel} />
    </>
  );
};

export default SubTradeRequestPairsModal;
