import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
} from '../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, Form,
  IconButton,
  Menu,
} from '../../2-component';
import { ConfirmationModal } from '../confirmationDialog/confirmationDialog';

export const FormModal = ({
  onSubmit,
  loading,
  children,
  title,
  open,
  handleClose,
  formButton,
  maxWidth = 'sm',
  disabled = false,
  state,
  initialState,
  menuItems,
}: {
  onSubmit: (event: any) => void;
  loading: boolean;
  children: any | any[];
  title: string;
  open: boolean;
  handleClose: () => void;
  formButton: string;
  maxWidth?: any;
  disabled?: boolean;
  state?: any;
  initialState?: any;
  menuItems?: any[];
}) => {
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const { t } = useTranslation('shared');
  const deepEquality = (a: Record<string, any>, b: Record<string, any>) => JSON.stringify(a) === JSON.stringify(b);
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);
  const openContextMenu = (onElement: HTMLElement) => {
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);

  const onClose = () => {
    if (!state || !initialState) {
      handleClose();
    } else if (state && initialState && deepEquality(state, initialState)) {
      handleClose();
    } else {
      setExitConfirmationOpen(true);
    }
  };
  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth={maxWidth} fullWidth>
        <DialogTitle onClose={onClose}>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            {title}
            {menuItems && (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  openContextMenu(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
        <Form onSubmit={onSubmit}>
          <DialogContent>
            {children}
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='end'>
              <Button label={formButton} data-testid='confirmButton' type='submit' disabled={loading || disabled} />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
      {menuItems && (
        <Menu
          anchorEl={contextMenuAnchorEl}
          open={contextMenuOpen}
          onClose={closeContextMenu}
          onClick={closeContextMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ cursor: 'pointer' }}
        >
          {menuItems}
        </Menu>
      )}
      <ConfirmationModal
        open={exitConfirmationOpen}
        title={t('shared:wizardTitle', { wizardTitle: title })}
        bodyText={t('shared:wizardInterruptionMsg')}
        onConfirm={() => {
          setExitConfirmationOpen(false);
          handleClose();
        }}
        onCancel={() => setExitConfirmationOpen(false)}
      />
    </>
  );
};

export default FormModal;
