import {
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ReactElement } from 'react';

const AlertChip = ({
  leadingIcon, label, actionItem,
}: {
  leadingIcon?: 'checkmark' | 'problem' | 'cancel' | ReactElement,
  label: string,
  actionItem?: any,
}) => {
  let icon;
  if (leadingIcon === 'checkmark') {
    icon = <CheckCircleIcon color='success' sx={{ fontSize: '16px' }} />;
  } else if (leadingIcon === 'problem') {
    icon = <ReportProblemIcon color='error' sx={{ fontSize: '16px' }} />;
  } else if (leadingIcon === 'cancel') {
    icon = <CancelRoundedIcon color='error' sx={{ fontSize: '16px' }} />;
  } else {
    icon = leadingIcon;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <Typography sx={{
        display: 'inline', fontSize: '12px', fontWeight: '600', ml: '4px !important',
      }}>{label}</Typography>
      {actionItem}
    </Box>
  );
};
export default AlertChip;
