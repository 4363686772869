import { gql, useQuery } from '@apollo/client';
import {
  Box, Typography, Paper, CircularProgress, Table, TableHead, TableCell, TableRow, TableBody, Chip, Link, Alert, Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatMoneyValue } from '../../util';
import { usePageState } from '../../util/usePageState';
import { useLocalization } from '../../util/useLocalization';

export const FETCH_TLH_JOB_AND_EVENTS = gql`
  query fetchTLHJobAndEvents($tlhJobId: ObjectID!, $pagination: PaginationAndSorting) {
    fetchTLHJob(tlhJob: $tlhJobId) {
      state createdAt organization { name }
    }
    fetchTLHEvents(input: { filter: { tlhJob: $tlhJobId }, pagination: $pagination }) {
      events {
        id
        account { type user { id firstName lastName } }
        financialProduct { ticker }
        adjustedCostBaseCents
        sellQuantity
        currentPriceCents
        quotient
        substituteFinancialProduct { ticker }
      }
      totalCount
    }
  }
`;

const TaxLossHarvestingEvents = () => {
  useNavigate();
  const params = useParams();
  const jobId = params.id;
  const { t } = useTranslation('taxLossHarvesting');
  const { localizedDate } = useLocalization();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const { data, loading } = useQuery(FETCH_TLH_JOB_AND_EVENTS, {
    variables: {
      tlhJobId: jobId,
      pagination: { perPage: pageSize, offSet: (page - 1) * pageSize },
    },
  });

  const calculateGainLoss = (event: any): string => {
    if (event.adjustedCostBaseCents === 0) {
      return '0.00';
    }
    return (100 * ((event.currentPriceCents / event.adjustedCostBaseCents) - 1)).toFixed(2);
  };

  return (
    <Box sx={{ minWidth: '65em' }}>
      {loading && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      )}
      {data?.fetchTLHJob && (
        <>
          <Typography>
            {t('organization')}: &nbsp; {data.fetchTLHJob.organization.name}
          </Typography>
          <Typography>
            {t('state')}: &nbsp;
            <Chip label={data.fetchTLHJob.state} size='small' color={data.fetchTLHJob.state === 'COMPLETE' ? 'success' : 'warning'} />
          </Typography>
          <Paper sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table.user')}</TableCell>
                  <TableCell>{t('table.account')}</TableCell>
                  <TableCell>{t('table.symbol')}</TableCell>
                  <TableCell>{t('table.sellQuantity')}</TableCell>
                  <TableCell>
                    <abbr title={t('table.adjustedCostBase')}>
                      {t('table.costBase')}
                    </abbr>
                  </TableCell>
                  <TableCell>
                    <abbr title={`${t('table.assetValueAsOf')} ${localizedDate(data.fetchTLHJob.createdAt)}`}>
                      {t('table.price')}
                    </abbr>
                  </TableCell>
                  <TableCell>{t('table.gainLossPercentage')}</TableCell>
                  <TableCell>{t('table.bookValue')}</TableCell>
                  <TableCell>{t('table.expectedSellValue')}</TableCell>
                  <TableCell>{t('table.gainLossValue')}</TableCell>
                  <TableCell>{t('table.substituteSymbol')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.fetchTLHEvents.events.map((event: any) => (
                  <TableRow key={event.id}>
                    <TableCell>
                      <Link href={`/clients/${event.account.user.id}`}>
                        {event.account.user.firstName} {event.account.user.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>{event.account.type}</TableCell>
                    <TableCell>{event.financialProduct.ticker}</TableCell>
                    <TableCell>{event.sellQuantity}</TableCell>
                    <TableCell>{formatMoneyValue(event.adjustedCostBaseCents)}</TableCell>
                    <TableCell>{formatMoneyValue(event.currentPriceCents)}
                    </TableCell>
                    <TableCell>
                      {/* Gain (Loss) %’ = ‘[(price / cost) - 1] * 100' % */}
                      <Chip size='small' label={`${calculateGainLoss(event)}%`} variant="outlined" color="secondary"></Chip>
                    </TableCell>
                    <TableCell>
                      {/* Book Value = Total Quantity * ACB/Cost Base */}
                      {formatMoneyValue(event.sellQuantity * event.adjustedCostBaseCents)}
                    </TableCell>
                    <TableCell>{formatMoneyValue(event.currentPriceCents * event.sellQuantity)}</TableCell>
                    <TableCell>
                      {/* Gain (Loss) $’ = ‘Expected Sale Value’ - 'Book Value' */}
                      {formatMoneyValue(
                        (event.currentPriceCents * event.sellQuantity) - (event.sellQuantity * event.adjustedCostBaseCents),
                      )}
                    </TableCell>
                    <TableCell>{event.substituteFinancialProduct.ticker}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data)?.fetchTLHEvents?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Paper>
        </>
      )}
      {data?.fetchTLHJob && data?.fetchTLHJob.job === null && (
        <Alert severity="error">{t('errors.noSuchTLHJob')} (id={jobId}).</Alert>
      )}
    </Box>
  );
};

export default TaxLossHarvestingEvents;
export { TaxLossHarvestingBreadcrumb } from './taxLossHarvestingBreadcrumb';
