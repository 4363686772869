import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface TextFieldTokens {
  textField: {
    textColor?: string;
    borderColor?: string;
    hoverBorderColor?: string;
    focusBorderColor?: string;
    disabledColor?: string;
    errorColor?: string;
    successColor?: string;
    labelColor?: string;
  },
}

export const useTextFieldTokens = (): TextFieldTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const comp: TextFieldTokens = {
    textField: {
      textColor: sys.color.onSurface,
      borderColor: sys.color.outline,
      hoverBorderColor: sys.color.onSurface,
      focusBorderColor: sys.color.onSurface,
      disabledColor: sys.color.disabled,
      errorColor: sys.color.negative,
      successColor: sys.color.positive,
      labelColor: sys.color.onSurfaceVariant,
    },
  };

  return comp;
};
