import { gql, useMutation } from '@apollo/client';
import { OrganizationUser } from 'interfaces';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modals/confirmationModal';

export const RESET_ORGANIZATION_USER_MULTIFACTOR = gql`
mutation resetOrganizationUserMultifactor($input: ResetOrganizationUserMultifactorInput!) {
  resetOrganizationUserMultifactor(input: $input) {
    organizationUser {
      id
    }
  }
}
`;

const ResetOrganizationUserMultifactor = ({
  open,
  organizationUserToReset,
  afterReset,
  handleClose,
}: {
  open: boolean,
  organizationUserToReset: OrganizationUser,
  afterReset: () => void,
  handleClose: () => void
}) => {
  const { t } = useTranslation(['orgSettings']);

  const [resetOrganizationUserMultifactor, { loading }] = useMutation(RESET_ORGANIZATION_USER_MULTIFACTOR, {
    variables: {
      input: {
        organizationUserId: organizationUserToReset?.id,
      },
    },
  });

  const resetUserMultifactor = async () => {
    await resetOrganizationUserMultifactor();
    afterReset();
  };

  if (!organizationUserToReset) {
    return <></>;
  }

  return (
    <ConfirmationModal
      onCancel={handleClose}
      onConfirm={resetUserMultifactor}
      open={open}
      loading={loading}
      title={t('userResetMultifactorModal.title')}
      bodyText={t('userResetMultifactorModal.body')}
    >
    </ConfirmationModal>
  );
};

export default ResetOrganizationUserMultifactor;
