import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewRole from './newRole';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import EditRole from './editRole';
import { usePageState } from '../../../util/usePageState';
import { AuthAudienceTypes } from '../../../interfaces/organizationUser';

const FETCH_ROLES = gql`
  query fetchRoles($input: FetchRolesInput!) {
    fetchRoles(input: $input) {
      totalCount
      roles {
        id
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        organization {
          id
          name
        }
        roleProfile {
          id
          translatedDescription {
            en
          }
          translatedName {
            en
          }
          template {
            permissions
            accessiblePages
            dashboards {
              id
              name
            }
          }
        }
        nonIndividualPageConfiguration {
          id
        }
        individualPageConfiguration{
          id
        }
        goalPageConfiguration {
          id
        }
        accountPageConfiguration {
          id
        }
        householdPageConfiguration {
          id
        }
        makeHouseholdDefaultView
        subAccountPageConfiguration {
          id
        }
        permissions
        accessiblePages
        dashboards {
          id
          name
        }
        navigationStyle
        defaultAuthenticationConnection
      }
    }
  }
`;

const Role = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const { t } = useTranslation(['orgSettings', 'shared']);
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          authAudience: AuthAudienceTypes.ONEHUB, // exclude Client Role
        },
        pagination: {
          sortField: 'translatedName.en', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);
  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              {permissions.includes('write:roles') && (
                <NewRole afterCreate={refetch} defaultOrg={activeOrganization.id} />
              )}
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('roleTable.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('roleTable.navigationStyle')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('roleTable.noPermissions')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('roleTable.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchRoles?.roles?.map((role: any) => (
                <TableRow
                  hover
                  key={role.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setSelectedRole(role);
                    setUpdateDialogOpen(true);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {role.translatedName.en}
                  </TableCell>
                  <TableCell>{role.navigationStyle}</TableCell>
                  <TableCell align="right">{role.permissions.length}</TableCell>
                  <TableCell align="right">{role.organization.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchRoles?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />

          {updateDialogOpen && (
            <EditRole
              roleToUpdate={selectedRole}
              afterUpdate={() => {
                setUpdateDialogOpen(false);
                refetch();
              }}
              handleClose={() => setUpdateDialogOpen(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Role;
