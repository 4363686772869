import { Card } from 'ovComponents/2-component';
import { useRefinitivWidget } from '../../useRefinitivWidget';

export const TickerBand = ({ options }: { options: any }) => {
  useRefinitivWidget('#ticker-band-widget', 'WTickerBand', { tickerElements: options.tickerBandRics.map((ric: string) => ({ ric, name: ric })) });

  return (
    <Card>
      <div id='ticker-band-widget'></div>
    </Card>
  );
};
