import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { UserContext } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import AddBankAccountManuallyVisual from './addBankAccountManuallyVisual';
import { uploadWithRetry } from '../../../../util/fileUploader';
import { PhysicalAddress } from '../../../../interfaces/physicalAddress';

export interface InputProp {
  hasError: boolean,
  message: string,
  value: string,
}
export const FETCH_DOCUMENTS = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {perPage: 1000}
  }) {
    fileDocuments {
      id type name fileName mediaType uploadedAt updatedAt
      objectType objectId
      sharedClient sharedCustodian
      creator { id }
      user { id firstName lastName }
    }
  }
}`;

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input:CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      bankAccount {
        id
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($bankAccountId: ObjectID!) {
    deleteBankAccount(bankAccountId: $bankAccountId) {
      bankAccount {
        id
        name
      }
    }
  }
`;

const AddBankAccountManuallyModal = ({
  afterCreate, userId, open, handleClose, onNext,
  options,
}: {
  afterCreate: (newBankAccountId: string) => void,
  userId: string,
  open: boolean,
  handleClose: () => void,
  onNext: (index: number) => void,
  options?: any
}) => {
  const { showToast } = useGlobalToast();
  const { activeOrganization, userContext } = useContext(UserContext);
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteBankAccountMutation] = useMutation(DELETE_BANK_ACCOUNT);
  const [loading, setLoading] = useState<boolean>(false);
  const [voidChequeFile, setVoidChequeFile] = useState<File>();
  const [bankAccountName, setBankAccountName] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [institutionNumber, setInstitutionNumber] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [transitNumber, setTransitNumber] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [bankAccountNumber, setBankAccountNumber] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [bankAccountHolderName, setBankAccountHolderName] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [bankAccountTransitPhysicalAddress, setBankAccountTransitPhysicalAddress] = useState<PhysicalAddress>({
    city: '',
    country: undefined,
    houseNumber: '',
    postal: '',
    jurisdiction: '',
    streetName: '',
    unitNumber: '',
  });
  const resetFields = () => {
    setBankAccountName({ value: '', hasError: false, message: '' });
    setInstitutionNumber({ value: '', hasError: false, message: '' });
    setTransitNumber({ value: '', hasError: false, message: '' });
    setBankAccountNumber({ value: '', hasError: false, message: '' });
    setBankAccountHolderName({ value: '', hasError: false, message: '' });
  };
  const doUpload = async (uploadingFile: File, bankId: string): Promise<boolean> => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'BANKING',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: 'File upload failed: Failed to fetch upload url' });
      deleteBankAccount(bankId);
      datadogLogs.logger.error('File upload failed: Failed to fetch upload url', { step: 'fetchFileUploadUrl - Step 1' }, queryResult?.error);
      return false;
    }
    /* (2) do the upload */
    try {
      // setUploadPending(true);
      const uploaded: Response = await uploadWithRetry(uploadUrl, uploadingFile, 'Banking Document');
      if (!uploaded.ok) throw (new Error(`File upload failed ${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      datadogLogs.logger.error('File upload failed', { step: 'Do Upload - Step 2' }, e);
      showToast({ severity: 'error', message: `File upload failed: ${e}` });
      deleteBankAccount(bankId);
      return false;
    } finally {
      // setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.sourceId = bankId;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'BANKING_SOURCE';

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        refetchQueries: [FETCH_DOCUMENTS],
      });
    } catch (e: any) {
      showToast({ severity: 'error', message: 'File upload failed: Failed to create file document' });
      deleteBankAccount(bankId);
      datadogLogs.logger.error('File upload failed: Failed to create file document', { step: 'createFileDocument - Step 3' }, e);
      return false;
    }
    showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
    return true;
  };

  const handleOnComplete = async (data: { createBankAccount: { bankAccount: { id: string } } }) => {
    let didUpload = true;
    if (voidChequeFile) {
      didUpload = await doUpload(voidChequeFile, data.createBankAccount.bankAccount.id);
      setLoading(false);
    }
    if (didUpload) {
      resetFields();
      afterCreate(data.createBankAccount.bankAccount.id);
    }
  };

  const deleteBankAccount = (bankAccountId: string) => {
    deleteBankAccountMutation({ variables: { bankAccountId } });
  };

  const isInCompleteAddress = (address: PhysicalAddress) => (!(address.country && address.city && address.jurisdiction && address.postal && address.houseNumber && address.streetName));

  const [createBankAccountMutation] = useMutation(CREATE_BANK_ACCOUNT, {
    variables: {
      input: {
        bankAccountNumber: bankAccountNumber.value,
        institutionNumber: institutionNumber.value,
        name: bankAccountName.value,
        transitNumber: transitNumber.value,
        userId,
        bankAccountHolderName: bankAccountHolderName.value,
        bankAccountTransitPhysicalAddress: isInCompleteAddress(bankAccountTransitPhysicalAddress) ? undefined : bankAccountTransitPhysicalAddress,
      },
    },
    onCompleted: (data) => handleOnComplete(data),
  });
  const createBankAccount = () => {
    setLoading(true);
    createBankAccountMutation().then();
  };

  return (
    <AddBankAccountManuallyVisual
      loading={loading}
      onCreateBank={() => {
        createBankAccount();
      }}
      handleClose={handleClose}
      open={open}
      bankAccount={bankAccountNumber}
      setBankAccount={setBankAccountNumber}
      bankAccountName={bankAccountName}
      setBankAccountName={setBankAccountName}
      institutionNumber={institutionNumber}
      setInstitutionNumber={setInstitutionNumber}
      setTransitNumber={setTransitNumber}
      setVoidChequeFile={setVoidChequeFile}
      transitNumber={transitNumber}
      voidChequeFile={voidChequeFile}
      bankAccountHolderName={bankAccountHolderName}
      setBankAccountHolderName={setBankAccountHolderName}
      bankAccountTransitPhysicalAddress={bankAccountTransitPhysicalAddress}
      setBankAccountTransitPhysicalAddress={setBankAccountTransitPhysicalAddress}
      onNext={onNext}
      options={options}
    />
  );
};

export default AddBankAccountManuallyModal;
