const baseColors = {
  neutral800: '#0A0C13',
  neutral700: '#35373F',
  neutral600: '#6D7078',
  neutral500: '#9C9DA3',
  neutral400: '#C9CACF',
  neutral300: '#E9EAEC',
  neutral200: '#F6F7F8',
  neutral100: '#FFFFFF',
  primary700: '#0F3434',
  primary600: '#196666',
  primary500: '#268282',
  primary400: '#A8CDCD',
  primary300: '#D4E6E6',
  primary200: '#EFFBFB',
  negative700: '#420B0B',
  negative600: '#9A9DA5',
  negative500: '#DC2626',
  negative400: '#EE9393',
  negative300: '#F8D4D4',
  negative200: '#FEF2F2',
  warning700: '#492F03',
  warning600: '#AC6F08',
  warning500: '#F59E0B',
  warning400: '#FACF85',
  warning300: '#FDECCE',
  warning200: '#FFFBEB',
  positive700: '#073116',
  positive600: '#0D622C',
  positive500: '#12873D',
  positive400: '#8BD1A5',
  positive300: '#D0EDDB',
  positive200: '#F0FDF4',
  informative700: '#0B1E46',
  informative600: '#163B8D',
  informative500: '#2563EB',
  informative400: '#92B1F5',
  informative300: '#D3E0FB',
  informative200: '#EFF6FF',
  supportY800: '#534B2D',
  supportY100: '#FEF8CD',
  supportO800: '#7D4B1C',
  supportO100: '#FAF2EA',
  supportR800: '#831831',
  supportR100: '#FDF2F6',
  supportP800: '#611A75',
  supportP100: '#FBF4FF',
  supportV800: '#371D95',
  supportV100: '#F3F3FF',
  supportB800: '#1E4D8A',
  supportB100: '#EFF9FF',
  supportT800: '#145252',
  supportT100: '#EFFBF8',
  supportFocus: '#3388FF',
};

export type Hexcode = `#${string}`;

export interface PaletteTokens {
  primary70?: Hexcode;
  primary60?: Hexcode;
  primary50?: Hexcode;
  primary40?: Hexcode;
  primary30?: Hexcode;
  primary20?: Hexcode;
  positive70?: Hexcode;
  positive60?: Hexcode;
  positive50?: Hexcode;
  positive40?: Hexcode;
  positive30?: Hexcode;
  positive20?: Hexcode;
  warning70?: Hexcode;
  warning60?: Hexcode;
  warning50?: Hexcode;
  warning40?: Hexcode;
  warning30?: Hexcode;
  warning20?: Hexcode;
  negative70?: Hexcode;
  negative60?: Hexcode;
  negative50?: Hexcode;
  negative40?: Hexcode;
  negative30?: Hexcode;
  negative20?: Hexcode;
  neutral90?: Hexcode;
  neutral80?: Hexcode;
  neutral70?: Hexcode;
  neutral60?: Hexcode;
  neutral50?: Hexcode;
  neutral40?: Hexcode;
  neutral30?: Hexcode;
  neutral20?: Hexcode;
  neutral10?: Hexcode;
  supportOne60?: Hexcode;
  supportOne50?: Hexcode;
  supportOne40?: Hexcode;
  supportTwo60?: Hexcode;
  supportTwo50?: Hexcode;
  supportTwo40?: Hexcode;
  supportThree60?: Hexcode;
  supportThree50?: Hexcode;
  supportThree40?: Hexcode;
  supportFour60?: Hexcode;
  supportFour50?: Hexcode;
  supportFour40?: Hexcode;
  supportFive60?: Hexcode;
  supportFive50?: Hexcode;
  supportFive40?: Hexcode;
  supportSix60?: Hexcode;
  supportSix50?: Hexcode;
  supportSix40?: Hexcode;
}

export const paletteBase: PaletteTokens = {
  primary70: '#12163B',
  primary60: '#24306B',
  primary50: '#465CC8',
  primary40: '#99A6EB',
  primary30: '#BBC4F1',
  primary20: '#D5DAF6',
  positive70: '#073116',
  positive60: '#0D622C',
  positive50: '#12873D',
  positive40: '#8BD1A5',
  positive30: '#D0EDDB',
  positive20: '#F0FDF4',
  negative70: '#6D1212',
  negative60: '#AE2B31',
  negative50: '#DC2626',
  negative40: '#EE9393',
  negative30: '#F8D4D4',
  negative20: '#FEF2F2',
  warning70: '#492F03',
  warning60: '#AC6F08',
  warning50: '#F59E0B',
  warning40: '#FACF85',
  warning30: '#FDECCE',
  warning20: '#FFFBEB',
  neutral90: '#17181C',
  neutral80: '#4B4E58',
  neutral70: '#6A6F7B',
  neutral60: '#9A9DA5',
  neutral50: '#C4C5CA',
  neutral40: '#DCDDE0',
  neutral30: '#ECEDEE',
  neutral20: '#F6F7F8',
  neutral10: '#FFFFFF',
  supportOne60: '#21496B',
  supportOne50: '#297AA3',
  supportOne40: '#C2DDF0',
  supportTwo60: '#00707A',
  supportTwo50: '#70BBC2',
  supportTwo40: '#D5EFF1',
  supportThree60: '#8A6116',
  supportThree50: '#F0BD42',
  supportThree40: '#F8E9A5',
  supportFour60: '#994000',
  supportFour50: '#E27F36',
  supportFour40: '#FAD3B8',
  supportFive60: '#9E2E53',
  supportFive50: '#D65C85',
  supportFive40: '#F6C0D2',
  supportSix60: '#782D86',
  supportSix50: '#B670C2',
  supportSix40: '#E5B9F9',
};

export const colors: any = {
  ...baseColors,
  white: baseColors.neutral100,
  primary: baseColors.primary500,
  negative: baseColors.negative500,
  warning: baseColors.warning500,
  positive: baseColors.positive500,
  neutral: baseColors.neutral500,
  focus: baseColors.supportFocus,
  informative: baseColors.informative500,
};

export const palette = {
  ...paletteBase,
  white: paletteBase.neutral10,
  primary: paletteBase.primary50,
  negative: paletteBase.negative50,
  warning: paletteBase.warning50,
  positive: paletteBase.positive50,
  neutral: paletteBase.neutral50,
};
