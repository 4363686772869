import { CircularProgress } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PageConfigurationVisual } from './pageConfiguration.visual';
import { UPDATE_PAGE_CONFIGURATION } from './pageConfiguration.queries';
import { Box } from '../../../../1-primative';

export enum PageObjectType {
  INDIVIDUAL = 'INDIVIDUAL',
  NON_INDIVIDUAL = 'NON_INDIVIDUAL',
  GOAL = 'GOAL',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  HOUSEHOLD = 'HOUSEHOLD',
}
export interface ClientPageInterface {
  widgets: WidgetInterface[];
  type?: string;
}

export interface WidgetInterface {
  type: string;
  options: any;
}

export const PageConfiguration = ({ widgets, type, clientReport }: { widgets?: any[], type?: string, clientReport?: any }) => {
  const [page, setPage] = useState<ClientPageInterface | undefined>(undefined);

  const [update, { data: updateData }] = useMutation(UPDATE_PAGE_CONFIGURATION);

  useEffect(() => {
    if (widgets) {
      setPage({ widgets, type });
    }
  }, [widgets, type]);

  useEffect(() => {
    if (updateData) {
      setPage(updateData.updateClientReportTemplatePageConfiguration.clientReportTemplatePageConfiguration);
    }
  }, [updateData]);

  if (!page) {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
      <PageConfigurationVisual
        page={page}
        setPage={setPage}
        update={update}
        clientReport={clientReport}
      />
  );
};
