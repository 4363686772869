import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Checkbox as MuiCheckbox,
  SxProps,
  createSvgIcon,
} from '@mui/material';
import { Typography, Box, TypographyVariantOptions } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useCheckboxTokens } from './checkbox.tokens';
import InfoDialog from '../infoDialog/infoDialog';

interface CheckboxFunctionalProps {
  checked?: boolean
  indeterminate?: boolean
  onChange?: (onoff: boolean) => void
  onClick?: (e: any) => void
  disabled?: boolean,
  infoTooltip?: string,
  testId?: string,
}
interface CheckboxVisualProps {
  label?: string
  size?: 'medium' | 'small',
  chip?: boolean,
  customStyle?: SxProps,
  checkBoxStyle?: SxProps,
  labelVariant?: TypographyVariantOptions,
  centerInfoToolTip?: boolean,
}

export const Checkbox = ({
  checked = false, indeterminate = false, onChange, disabled = false,
  label, size = 'medium', chip = false, customStyle, checkBoxStyle, infoTooltip,
  labelVariant, centerInfoToolTip, onClick, testId,
}: CheckboxFunctionalProps & CheckboxVisualProps) => {
  const tokens = useThemeTokens(useCheckboxTokens());
  const { comp, sys } = tokens;
  const [on, setOn] = useState<boolean>(checked);
  const variant = labelVariant || (size === 'small' ? 'bodySmall' : 'bodyLarge');

  useEffect(() => {
    if (checked !== undefined) setOn(checked);
  }, [checked]);

  const setOnAndPropagate = (newOn: boolean) => {
    setOn(newOn);
    if (onChange) onChange(newOn);
  };

  return (
    <>
    <Box display='flex' flexDirection='row' sx={{
      background: chip ? checked ? sys.color.supportOneVariant : sys.color.surfaceContainerVariant : 'transparent',
      padding: chip ? '4px 8px' : undefined,
      width: chip ? 'fit-content' : undefined,
      borderRadius: sys.borderRadius.sm,
      mb: chip ? 0.5 : undefined,
      mr: chip ? 0.5 : undefined,
      ...customStyle,
    }}>
      <StyledMuiCheckbox
        data-testid={testId ?? 'checkbox'}
        checked={on}
        indeterminate={indeterminate}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setOnAndPropagate(event.target.checked); }}
        size={size}
        disabled={disabled}
        checkedIcon={<CheckBoxIcon/>}
        indeterminateIcon={<IndeterminateCheckBoxIcon/>}
        onClick={onClick}
        sx={{
          padding: 0,
          ...checkBoxStyle,
        }}
        inputProps={{ 'data-testid': `${testId ?? 'checkbox'}-input` } as React.InputHTMLAttributes<HTMLInputElement>}
      />
      { label !== undefined && (
        <div onClick={() => { if (!disabled) { setOnAndPropagate(!on); } }}>
          <Typography
            variant={variant}
            sx={{ color: !disabled ? comp.checkbox.defaultColor : comp.checkbox.disabledColor, cursor: !disabled ? 'pointer' : 'default', ml: 0.5 }}
          >
            {label}
          </Typography>
        </div>
      )}
    </Box>
    {infoTooltip && (<InfoDialog information={infoTooltip} center={centerInfoToolTip} />)}
    </>
  );
};

const StyledMuiCheckbox = styled(MuiCheckbox)((props) => {
  const tokens = useThemeTokens(useCheckboxTokens());
  const { comp } = tokens;

  return ({
    '& svg.MuiSvgIcon-root': {
      color: comp.checkbox.defaultColor,
      fontSize: props.size === 'small' ? 12 : 16,
    },
    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
      '& svg.MuiSvgIcon-root': {
        color: comp.checkbox.activeColor,
      },
    },
    '&.Mui-disabled svg.MuiSvgIcon-root': {
      color: comp.checkbox.disabledColor,
    },
  });
});

/* Like CheckBoxIcon from @mui/icons-material, with rounded corners */
const CheckBoxIcon = createSvgIcon(
  <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-10 13-3.5-3.5a1 1 0 011.41-1.41L10 14.17l6.56-6.67a1 1 0 011.44 1.5l-7 7a1.5 1.5 0 01-2 0"/>,
  'CheckBoxIcon',
);

/* Like IndeterminateCheckBoxIcon from @mui/icons-material, with rounded corners */
const IndeterminateCheckBoxIcon = createSvgIcon(
  <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm-2 10h-10c-1 0-1-1-1-1 0-1 1-1 1-1h10c1 0 1 1 1 1 0 1-1 1-1 1z"/>,
  'IndeterminateCheckBoxIcon',
);
