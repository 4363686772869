import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Chip, Link as MuiLink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { usePageState } from '../../../util/usePageState';
import {
  ImportFile, ImportFileStates, ImportFileLineItem, getStatusColor, ImportFileTypes,
} from '../../../interfaces/bulkImport';
import LineItemModal from './lineItemModal';

export const FETCH_IMPORT_FILE_LINE_ITEMS = gql`
  query fetchImportFileLineItems($input: FetchImportFileLineItemsInput!) {
    fetchImportFileLineItems(input: $input) {
      totalCount
      importFileLineItems {
        id
        state
        externalId
        outputErrors { code field description }
        oneVestId
        rawData
      }
    }
  }
`;

const ImportFileLineItemsTable = ({ importFile }: { importFile: ImportFile }) => {
  const { t } = useTranslation(['bulkImport']);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const [activeLineItem, setActiveLineItem] = useState<ImportFileLineItem>({});
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading, error, data, previousData,
    startPolling, stopPolling, refetch,
  } = useQuery(FETCH_IMPORT_FILE_LINE_ITEMS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          importFileId: importFile.id,
        },
        pagination: {
          sortField: 'order', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useEffect(() => {
    if (importFile.state === ImportFileStates.PROCESSING) {
      startPolling(5000);
    } else {
      stopPolling();
      if (importFile.id) {
        refetch();
      }
    }
  }, [importFile.state, importFile.id, startPolling, stopPolling, refetch]);

  const disabledEditLineItemButton = !importFile.state || [
    ImportFileStates.COMPLETED,
    ImportFileStates.CANCELED,
    ImportFileStates.PROCESSING,
  ].includes(importFile.state);

  const hasLinkColumn = importFile.type === ImportFileTypes.USER;

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('lineItemTable.externalId')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('lineItemTable.oneVestId')}</Typography></TableCell>
                <TableCell align="right"><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchImportFileLineItems?.importFileLineItems?.map((importFileLineItem: ImportFileLineItem) => (
                <TableRow
                  hover
                  key={importFileLineItem.id}
                  onClick={() => {
                    setActiveLineItem(importFileLineItem);
                    setModalOpen(true);
                  }}
                  selected={importFileLineItem.id === activeLineItem.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell>{importFileLineItem.externalId}</TableCell>
                  <TableCell>
                    {hasLinkColumn ? (
                      <MuiLink component={Link} to={`/clients/${importFileLineItem.oneVestId}`}>
                        {importFileLineItem.oneVestId}
                      </MuiLink>
                    ) : importFileLineItem.oneVestId}
                  </TableCell>
                  <TableCell align="right"><Chip label={t(`bulkImport:states.${importFileLineItem.state}`)} size='small' color={getStatusColor(importFileLineItem.state)}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchImportFileLineItems?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          {modalOpen && (
            <LineItemModal
              lineItem={activeLineItem}
              handleClose={() => setModalOpen(false)}
              disabledEditLineItemButton={disabledEditLineItemButton}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ImportFileLineItemsTable;
