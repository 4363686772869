import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Icon,
  Typography,
  Grid,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
  SelectField,
  MenuItem,
  Checkbox,
} from 'ovComponents/2-component';
import { ClientSelectField } from 'ovComponents/2-component/clientSelectField/clientSelect';
import {
  AccountStates,
  AccountTypes,
  Affiliation,
  AffiliationRelations,
  AffiliationTypes,
  DraftAccount,
  Goal,
  JointAccountHolderRelations,
  User,
} from 'interfaces';
import { BeneficiariesCrud } from 'ovComponents/3-pattern/beneficiaryCrud/beneficiariesCrud';
import { useTokens } from 'ovComponents/0-tokens';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';
import { FieldOptions, FormErrors, validateFields } from '../../../utils';

interface FHSADialogContentInterface {
  userData: any,
  showGoalLinkingSection: boolean,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

export const FHSADialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: FHSADialogContentInterface) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [successorEnabled, setSuccessorEnabled] = useState<boolean>(false);
  const [successor, setSuccessor] = useState<User>();
  const [successorRelationship, setSuccessorRelationship] = useState<string>('');
  const [beneficiariesEnabled, setBeneficiariesEnabled] = useState<boolean>(false);
  const [beneficiaries, setBeneficiaries] = useState<Affiliation[]>([]);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const goals = getValidGoals(userData);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [touched, setTouched] = useState<string[]>([]);
  const { sys } = useTokens({});

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const fieldOptions: FieldOptions = {
      successor: { required: successorEnabled },
      successorRelationship: { required: successorEnabled },
    };

    const data = {
      successor,
      successorRelationship,
    };
    let newErrors = validateFields(fieldOptions, data, candidateFields);

    if (beneficiariesEnabled && (candidateFields?.includes('beneficiaries') || candidateFields === undefined)) {
      const totalAllocation = beneficiaries.reduce((value, prev) => value + (prev.allocation ?? 0), 0);
      if (totalAllocation !== 100) {
        if (!newErrors) newErrors = {};
        newErrors.beneficiaries = { active: true };
      }
    }

    setErrors(newErrors);
    return newErrors;
  }, [successorEnabled, successor, successorRelationship, beneficiariesEnabled, beneficiaries]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setTouched(Object.keys(formErrors));
      return;
    }

    const affiliations: Affiliation[] = [...beneficiaries];

    if (successor) {
      affiliations.push({
        relation: successorRelationship as AffiliationRelations,
        type: AffiliationTypes.Successor,
        user: successor,
      });
    }

    onAccountCreated({
      type: AccountTypes.FHSA,
      applyForGovFunds: [],
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      affiliations,
      goalsLinked,
    });
  };

  useEffect(() => {
    validate(touched);
  }, [validate, touched]);

  useEffect(() => {
    if (beneficiaries.length > 0) { setTouched([...touched, 'beneficiaries']); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries]);

  /* reset conditional fields when checkbox is unchecked */
  useEffect(() => {
    if (successorEnabled === false) {
      setSuccessor(undefined);
      setSuccessorRelationship('');
    }
  }, [successorEnabled]);

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Typography variant='headingMedium' sx={{ mt: 1, mb: 2 }}>{t('workflowCompletions:fhsaAccountCreation.title')}</Typography>

      <Typography>
        {t('workflowCompletions:fhsaAccountCreation.subtitle')} &nbsp;
        <Icon icon={InfoOutlinedIcon} size='xSmall' sx={{ marginBottom: '-1px' }}/>
      </Typography>

      {/* Add a successor */}
      <Box sx={{ mt: 3, mb: 1 }} minHeight={'100px'}>
        <HeadingWithCheckbox
          label={t('workflowCompletions:fhsaAccountCreation.addASuccessor')}
          checked={successorEnabled}
          onChange={setSuccessorEnabled}
        />
        <Grid
          container
          display='flex'
          flexDirection='row'
          sx={{ my: 1 }}
          justifyContent='space-between'
          spacing={1}
          alignItems='end'
          style={successorEnabled ? {} : { opacity: 0.5 }}
        >
          <Grid item xs={7}>
            <ClientSelectField
              label={t('workflowCompletions:fhsaAccountCreation.successorName')}
              user={successor}
              setUser={(item) => setSuccessor(item)}
              fullWidth
              disabled={!successorEnabled}
              autoFocus
              onBlur={() => setTouched([...touched, 'successor'])}
              error={!!errors?.successor}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectField
              onChange={(e: any) => setSuccessorRelationship(e.target.value)}
              label={t('workflowCompletions:fhsaAccountCreation.relationshipToAccountHolder')}
              fullWidth
              value={successorRelationship}
              onBlur={() => setTouched([...touched, 'successorRelationship'])}
              error={!!errors?.successorRelationship}
              disabled={!successorEnabled}
            >
              {JointAccountHolderRelations.map((value: string, index: number) => (
                <MenuItem value={value} key={index}>{t(`affiliationTypes:affiliationRelationship.${value}`)}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
      </Box>

      {/* Add beneficiaries */}
      <Box sx={{ my: 3 }} minHeight={'200px'}>
        <HeadingWithCheckbox
          label={t('workflowCompletions:fhsaAccountCreation.addBeneficiaries')}
          checked={beneficiariesEnabled}
          onChange={setBeneficiariesEnabled}
        />
        <Grid style={beneficiariesEnabled ? {} : { opacity: 0.5 }}>
          { errors?.beneficiaries && (
            <Typography variant='bodySmall' sx={{ color: sys.color.negative }}>
              {t('components:beneficiaryCrud.allocationError')}
            </Typography>
          )}
          <BeneficiariesCrud
            beneficiaries={beneficiaries}
            setBeneficiaries={setBeneficiaries}
            disabled={!beneficiariesEnabled}
          />
        </Grid>
      </Box>

      <Box sx={{ mt: 4 }}>
        <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
      </Box>

      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:fhsaAccountCreation.addAccount')}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default FHSADialogContent;

export const HeadingWithCheckbox = (props:Parameters<typeof Checkbox>[0]) => {
  const propsWithoutLabel = { ...props, label: '' };
  return (
    <Box display='flex' flexDirection='row'>
      <Checkbox {...propsWithoutLabel}/>
      <Typography variant='headingXSmall' onClick={() => { if (props.onChange) props.onChange(!props.checked); }} style={{ cursor: 'pointer', userSelect: 'none' }}>
        {props.label}
      </Typography>
    </Box>
  );
};
