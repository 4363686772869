import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { Dialog, DialogContent, DialogTitle } from '../dialog/dialog';
import { IconButton } from '../iconButton/iconButton';

interface InfoDialogProps {
  title?: string,
  information: string,
  center?: boolean
}

const InfoDialog = ({ title, information, center }: InfoDialogProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['components']);

  const handleIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleOnClose = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <span style={{ verticalAlign: 'middle', ...center ? { display: 'flex', alignItems: 'center' } : {} }}>
        <InfoOutlinedIcon
          data-testid="info-icon"
          sx={{
            fontSize: '14px', cursor: 'pointer', marginLeft: '6px', ...center ? {} : { marginTop: '2px' },
          }}
          onClick={handleIconClick}
        />
      </span>
      <Dialog open={open} onClose={handleOnClose} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Typography variant='headingSmall' sx={{ mr: 0.5 }}>{title ?? t('components:additionalInfoModal.title')}</Typography>
            <IconButton onClick={handleOnClose} aria-label="close" size='medium'><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge' component='div' sx={{ mb: 3, mt: 3, table: { width: '100%' } }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{information}</ReactMarkdown>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialog;
