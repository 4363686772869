import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import NumberFormat from 'react-number-format';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { round } from 'lodash';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import {
  MenuItem, Switch, TextField, SelectField,
  Dialog, DialogTitle, IconButton, DialogContent, Form,
  Button,
  DialogFooter,
} from '../../../../2-component';
import { Box } from '../../../../1-primative';
import { SubAccountSelect, SecuritySelect, NumberField } from '../../../../3-pattern';

// TODO: Replace with a new ovComponent
import { LocalizedDatePicker } from '../../../../../components/fields/localizedDatePicker';

export const CREATE_TRADE = gql`
  mutation createTrade($input: CreateTradeInput!) {
    createTrade(input: $input) {
      trade {
        id
      }
    }
  }
`;

export const FETCH_FINANCIAL_PRODUCT = gql`
  query fetchFinancialProduct($input: FetchFinancialProductInput!) {
    fetchFinancialProduct(input: $input) {
      financialProduct {
        isPartial
        currentPriceCents
        priceHistoryUrl
      }
    }
  }
`;

const checkForFractionalTrade = (isPartial: boolean, quantity?: number): boolean => {
  if (!isPartial && hasDecimalPlaces(quantity)) return true;
  return false;
};

interface FormatterProps {
  onChange: (event: { target: { valueCents: undefined | number; name: string; value: string } }) => void;
  name: string;
}

const PriceFormatter = forwardRef<NumberFormat<string>, FormatterProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      decimalScale={6}
      onValueChange={(values) => {
        if (Number.isNaN(Number(values.value))) {
          return;
        }
        const { formattedValue, value } = values;
        onChange({
          target: {
            name: props.name,
            value: formattedValue,
            valueCents: value === '' ? undefined : round(Number(value) * 100, 4),
          },
        });
      }}
      thousandSeparator
      isNumericString
      defaultValue={0}
    />
  );
});

interface TradeProps {
  subAccountId?: string;
  financialProductId?: string;
  type: 'BUY' | 'SELL';
  actualQuantity?: number;
  actualTradePriceCents?: number;
  effectiveDate: string;
  description?: string;
  createExternalTransfer?: boolean;
}

const hasDecimalPlaces = (quantity?: any): boolean => {
  if (quantity) return quantity - Math.floor(quantity) !== 0;
  return false;
};

const NewTrade = ({ afterCreate, forObject, forId }: { afterCreate: () => void; forObject: 'USER' | 'CLIENT_GROUP' | 'ACCOUNT' | 'GOAL'; forId: string }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const [open, setOpen] = useState(false);
  const [trade, setTrade] = useState<TradeProps>({
    type: 'BUY',
    effectiveDate: dayjs().format('YYYY-MM-DD'),
  });
  const [isReconciled, setIsReconciled] = useState(true);
  const [avoidFractionalTrade, setAvoidFractionaltrade] = useState<boolean>(false);
  const [isPartial, setIsPartial] = useState<boolean>(true);
  const [priceToDisplay, setPriceToDisplay] = useState<string>('');

  const [createTrade, { loading }] = useMutation(CREATE_TRADE, {
    variables: {
      input: {
        ...trade,
        effectiveDate: isReconciled ? trade.effectiveDate : undefined,
        createExternalTransfer: isReconciled ? trade.createExternalTransfer : undefined,
      },
    },
  });

  const [fetchProduct] = useLazyQuery(FETCH_FINANCIAL_PRODUCT, {
    onCompleted: (data: any) => {
      setIsPartial(data?.fetchFinancialProduct?.financialProduct?.isPartial);
    },
  });

  useEffect(() => {
    if (trade.financialProductId && trade.actualQuantity) {
      fetchProduct({
        variables: {
          input: {
            financialProductId: trade.financialProductId,
          },
        },
      });
      setAvoidFractionaltrade(checkForFractionalTrade(isPartial, trade.actualQuantity));
    }
  }, [isPartial, trade.actualQuantity, trade.financialProductId, fetchProduct]);

  const create = async (event: any) => {
    const response = await createTrade();
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:tradeModal.createdToastMessage') });
    }
    setTrade({
      type: 'BUY',
      effectiveDate: dayjs().format('YYYY-MM-DD'),
    });
    afterCreate();
  };

  const subAccountFilter = () => {
    if (forObject === 'USER') return { userId: forId };
    if (forObject === 'CLIENT_GROUP') return { clientGroupId: forId };
    if (forObject === 'ACCOUNT') return { accountId: forId };
    if (forObject === 'GOAL') return { goalId: forId };
    return {};
  };

  const disabled = !(trade.subAccountId && trade.financialProductId && trade.actualQuantity && trade.actualTradePriceCents);
  return (
    <>
      <Button onClick={() => setOpen(true)} label={t('shared:add')} leadingIcon={AddIcon}/>
      <Form onSubmit={create}>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {t('components:tradeModal.title')}
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <SelectField value={trade.type} label={t('components:tradeModal.type')} fullWidth onChange={(e: any) => setTrade((prev) => ({ ...prev, type: e.target.value }))} sx={{ mb: 2 }}>
              <MenuItem value='BUY'>{t('components:tradeModal.types.buy')}</MenuItem>
              <MenuItem value='SELL'>{t('components:tradeModal.types.sell')}</MenuItem>
            </SelectField>
            <SubAccountSelect
              onSubAccountSelect={(v) => setTrade((prev) => ({ ...prev, subAccountId: v.id }))}
              selectedSubAccount={{ id: trade.subAccountId ?? '' }}
              label={t('components:tradeModal.portfolio')}
              sx={{ mb: 2 }}
              filter={subAccountFilter()}
            />
            <SecuritySelect
              value={trade.financialProductId ?? ''}
              setSecurity={(e) => setTrade((prev) => ({ ...prev, financialProductId: e.id }))}
              label={t('components:tradeModal.financialProduct')}
            />
            <NumberField
              label={t('components:tradeModal.quantity')}
              sx={{ mb: 2, mt: 2 }}
              // TODO: Introduce decimal places into number field
              // decimalPlaces={6}
              number={trade.actualQuantity?.toString() ?? ''}
              setNumber={(num: any) => {
                setTrade((prev) => ({ ...prev, actualQuantity: parseFloat(num) }));
                setAvoidFractionaltrade(checkForFractionalTrade(isPartial, trade.actualQuantity));
              }}
              error={avoidFractionalTrade}
            />
            <TextField
              label={t('components:tradeModal.price')}
              fullWidth
              sx={{ mb: 2 }}
              leadingIcon='dollar'
              InputProps={{
                inputComponent: PriceFormatter as any,
              }}
              value={priceToDisplay}
              onChange={(e: any) => {
                setPriceToDisplay(e.target.value);
                setTrade((prev) => ({ ...prev, actualTradePriceCents: parseFloat(e.target.value) * 100 }));
              }}
            />
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              label={t('components:tradeModal.description')}
              value={trade.description}
              onChange={(e: any) => setTrade((prev) => ({ ...prev, description: e.target.value }))}
            />
            <Switch checked={isReconciled} onChange={(event) => setIsReconciled(event)} label={t('components:tradeModal.isReconciled')} sx={{ mb: 2 }} />
            {isReconciled && (
              <>
                <LocalizedDatePicker
                  value={trade.effectiveDate}
                  onChange={(date: Date) => setTrade((prev) => ({ ...prev, effectiveDate: dayjs(date).format('YYYY-MM-DD') }))}
                  renderInput={(params: any) => <TextField fullWidth {...params} type='default' label={t('components:tradeModal.effectiveDate')} />}
                  disableFuture={true}
                />
                <Switch
                  sx={{ mt: 2 }}
                  checked={trade.createExternalTransfer ?? false}
                  onChange={(event) => setTrade((prev) => ({ ...prev, createExternalTransfer: event }))}
                  label={t('components:tradeModal.createExternalTransfer')}
                />
              </>
            )}
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='space-between'>
              <Button onClick={() => setOpen(false)} label={t('shared:cancel')} variant='tonal' />
              <Button disabled={disabled || loading} type='submit' label={t('shared:create')} sx={{ ml: 1 }} onClick={create} />
            </Box>
          </DialogFooter>
        </Dialog>
      </Form>
    </>
  );
};

export default NewTrade;
