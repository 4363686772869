import {
  Box, Button, ListItem, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ClientGroupTypes } from 'interfaces';
import AccountSelect from '../../../inputs/accountSelect';
import { CreateNewAccountWizard } from '../../createAccountWizard/wizard';
import { StepProps } from '../wizard';

const FETCH_GOAL = gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        user { id }
        clientGroup { id type }
      }
    }
  }
`;

export function ChooseExistingAccountStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('createSubAccountWizard');
  const [accountCount, setAccountCount] = useState<number | null>(null);

  const [goalBoundObject, setGoalBoundObject] = useState<'USER' | 'CLIENT_GROUP'>();
  const [groupType, setGroupType] = useState<ClientGroupTypes>();
  const [goalBoundId, setGoalBoundId] = useState();

  useQuery(FETCH_GOAL, {
    skip: context.forObject !== 'GOAL',
    variables: { id: context.forId },
    onCompleted: (data) => {
      if (data.fetchGoal.goal.clientGroup) {
        setGoalBoundObject('CLIENT_GROUP');
        setGoalBoundId(data.fetchGoal.goal.clientGroup.id);
        setGroupType(data.fetchGoal.goal.clientGroup.type);
      } else {
        setGoalBoundObject('USER');
        setGoalBoundId(data.fetchGoal.goal.user.id);
      }
    },
  });

  return (
    <>
    <ListItem>
      <Typography variant='wizardTitle'>{t('topics.createSubAccount')}</Typography>
      </ListItem>
      <ListItem sx={{ height: '6em', verticalAlign: 'top' }}>
        { accountCount !== null && (
          <Typography variant='subtitle1'>
            {t('theyHaveActiveAccounts', {
              entity: goalBoundObject === 'USER' ? t('client') : (groupType === ClientGroupTypes.Household ? t('household') : t('corporation')),
              count: accountCount,
            })}
            &nbsp;
            {t(accountCount ? 'useExistingOrCreateNew' : 'createAccountFirst')}
          </Typography>
        )}
      </ListItem>
      <Box sx={{ minHeight: '320px' }}>
        <ListItem>
          { goalBoundObject && goalBoundId && (
          <AccountSelect
            forObject={goalBoundObject}
            forId={goalBoundId}
            setAccount={(id) => {
              setState({ ...state, accountId: id === 'ANY' ? '' : id });
            }}
            accountIdValue={state.accountId || 'ANY'}
            getActiveAccountCount={(num: number) => {
              setAccountCount(num);
              if (num === 0) {
                continueTo(
                  CreateNewAccountWizard,
                  {
                    forObject: goalBoundObject,
                    forId: goalBoundId,
                  },
                  {
                    callToAction: t('actions.createSubAccount'),
                    action: (newAccountId: string) => {
                      setState({ ...state, accountId: newAccountId });
                      continueTo('CREATE_SUB_ACCOUNT');
                    },
                  },
                );
              }
            }}
            anyOptionLabel={t('selectAccount')}
          />
          )}
        </ListItem>
      </Box>

      <Box sx={{ flex: 1 }}></Box>
      <ListItem>
        <Button disabled={!state.accountId} type='submit' onClick={() => continueTo('CREATE_SUB_ACCOUNT')} data-testid='use-existing-account' fullWidth variant='contained'>
          {t('actions.useExsistingAccount')}
        </Button>
      </ListItem>
      <ListItem>
        <Button
          fullWidth type='submit' variant='outlined'
          data-testid='create-new-account'
          disabled={!goalBoundObject}
          onClick={() => {
            continueTo(
              CreateNewAccountWizard,
              {
                forObject: goalBoundObject,
                forId: goalBoundId,
              },
              {
                callToAction: t('actions.createSubAccount'),
                action: (newAccountId: string) => {
                  setState({ ...state, accountId: newAccountId });
                  continueTo('CREATE_SUB_ACCOUNT');
                },
              },
            );
          }}
        >
          {t('actions.createNewAccount')}
        </Button>
      </ListItem>
    </>
  );
}
