import { Organization } from './organization';

export enum BillingScheduleStates {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum BillingScheduleFrequencies {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMESTRAL = 'SEMESTRAL',
  ANNUAL = 'ANNUAL',
}

export interface BillingSchedule {
  id?: string,
  frequency: BillingScheduleFrequencies,
  nextBillingDate?: string,
  nextCalculationDate?: string,
  state?: BillingScheduleStates,
  default: boolean;
  createdAt?: Date,
  updatedAt?: Date,
  organization?: Organization,
  startDate?: Date,
  endDate?: Date,
}
