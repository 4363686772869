import { ComponentStory, ComponentMeta } from '@storybook/react';

import { Typography as TypographyComponent } from './typography';

export default {
  title: '1-primative/Typography',
  component: TypographyComponent,
} as ComponentMeta<typeof TypographyComponent>;

const Template: ComponentStory<typeof TypographyComponent> = (args:any) => <TypographyComponent {...args} />;

export const Typography = Template.bind({});
Typography.args = {
  variant: 'displayLarge',
  children: 'Lorem ipsum $1,246,371.89 dolor sit amet',
};
