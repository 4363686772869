import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WebHookType } from 'interfaces';

const WebHookTypeSelect = ({
  value, label, disabled, setWebHookType,
}: { value: string; label: string; disabled?: boolean; setWebHookType: (event: any) => void }) => {
  const { t } = useTranslation('devSettings');

  return (
    <TextField
    required
    disabled={disabled}
      select
      value={value ?? ''}
      label={label}
      fullWidth
      onChange={(e: any) => {
        setWebHookType(e);
      }}
    >
      {Object.keys(WebHookType).map((x: any) => (
        <MenuItem key={x.toString()} value={x}>
          {t(`webHooksTableOptions.type.${x}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};
export default WebHookTypeSelect;
