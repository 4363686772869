/* eslint-disable object-curly-newline */
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, IconButton, TableCell, TableRow, Link as MuiLink, Collapse, Box, Table, TableBody, Grid, Checkbox, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../../../util';
import { SubTradeRequestsTableHeader, TradeToVerify } from './subTradeRequestsTable';
import { useLocalization } from '../../../../util/useLocalization';
import CancelSubTradeRequestModal from './cancelSubTradeRequestModal';

const SUB_TRADE_REQUEST_STATES_ORDERED: Record<string, number> = {
  INITIATED: 1,
  READY: 2,
  REQUESTED: 3,
  RECONCILED: 4,
  CANCELED: 5,
};

export const GenerateSourceLink = ({ item }: { item: any }) => {
  const { t } = useTranslation(['components', 'shared']);

  const user = item?.subAccount?.account?.user ?? null;
  const type = item?.subAccount?.account?.type ?? null;

  if (!user || !type) return <></>;

  return (
    <MuiLink component={Link} to={`/clients/${user?.id}#tab:trades`} target='_blank'>
      {user?.firstName}'s {t(`accountTypes:${type}`)}
    </MuiLink>
  );
};

const SubTradeRequestsTableRow = ({
  trade,
  expandable,
  deletable,
  verifiable,
  tradesToVerify,
  onTradeVerify,
  afterCancel,
}: {
  trade: any;
  expandable: boolean;
  deletable: boolean;
  verifiable: boolean;
  tradesToVerify: TradeToVerify[];
  onTradeVerify: (trade: TradeToVerify) => void;
  afterCancel?: () => void;
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { localizedDate } = useLocalization();

  const [open, setOpen] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [subTradeRequestIdToDelete, setSubTradeRequestIdToDelete] = useState<string>('');

  const onCancelModalClose = () => {
    setOpenCancelModal(false);
    setSubTradeRequestIdToDelete('');
  };

  const getLowestTradeStatus = (row: any): string => {
    if (row?.splits?.length) {
      const states = row.splits.map((split: any) => split.state);

      return states.sort((a: string, b: string) => SUB_TRADE_REQUEST_STATES_ORDERED[a] - SUB_TRADE_REQUEST_STATES_ORDERED[b])[0];
    }

    return row.state;
  };

  const LocalTableRow = ({ row, nested }: { row: any; nested?: boolean }) => {
    const isShowCancel = deletable && !nested && ['INITIATED'].includes(row?.state); // the permission is already checked by {deletable}
    const isShowCancelButState = deletable && !nested && !['INITIATED'].includes(row?.state);
    const isShowVerification = verifiable && ['INITIATED', 'READY'].includes(row?.state) && !row?.bulkTradeRun?.id; // the permission is already checked by {verifiable}
    const isShowVerificationButState = verifiable && !['INITIATED', 'READY'].includes(row?.state) && !row?.bulkTradeRun?.id;

    const isTradeSelected = (item: any) => tradesToVerify?.find((tradeToVerify: TradeToVerify) => item?.id === tradeToVerify?.id)?.selected ?? false;

    const isTradeSelectedToVerify = isTradeSelected(row);
    const isAllSplitsVerified = row?.splits?.every((split: any) => isTradeSelected(split)) ?? false;

    return (
      <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {expandable && !nested && (
          <TableCell sx={{ width: 0 }}>
            {!!row?.splits?.length && (
              <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}

        <TableCell>
          <GenerateSourceLink item={row} />
        </TableCell>
        <TableCell>{row.isSellAll ? 'SELL ALL' : row.type}</TableCell>
        <TableCell>{formatMoneyValue(row?.preSplitMoneyAllocatedCents ?? row?.moneyAllocatedCents)}</TableCell>
        <TableCell>{row.financialProduct?.ticker}</TableCell>
        <TableCell>{row.financialProduct?.settlementDays}</TableCell>
        <TableCell>{row.financialProduct?.exchange}</TableCell>
        <TableCell>{row?.settlementDate && localizedDate(row?.settlementDate)}</TableCell>
        <TableCell>{row?.pair?.financialProduct?.ticker}</TableCell>
        <TableCell>
          <Chip label={getLowestTradeStatus(row)} size='small' color={getLowestTradeStatus(row) === 'RECONCILED' ? 'success' : 'warning'} />
        </TableCell>

        {isShowVerification && (
          <TableCell>
            {!!row?.splits?.length && (
              <Tooltip title={isAllSplitsVerified ? t('components:generateTrades.allSplitsAreVerified') : t('components:generateTrades.someSplitsAreNotVerified')} placement='top'>
                <span>
                  <Checkbox checked={isAllSplitsVerified} disabled />
                </span>
              </Tooltip>
            )}

            {!row?.splits?.length && <Checkbox checked={isTradeSelectedToVerify} onClick={() => onTradeVerify({ id: row?.id, selected: !isTradeSelectedToVerify })} />}
          </TableCell>
        )}

        {isShowVerificationButState && <TableCell sx={{ borderTop: '1px #e0e0e0 solid;' }}></TableCell>}

        {isShowCancel && (
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => {
                setSubTradeRequestIdToDelete(row?.id);
                setOpenCancelModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}

        {isShowCancelButState && <TableCell sx={{ borderTop: '1px #e0e0e0 solid;' }}></TableCell>}
      </TableRow>
    );
  };

  return (
    <>
      <LocalTableRow row={trade} />

      {!!trade?.splits?.length && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Grid container sx={{ justifyContent: 'right' }}>
                <Grid item xs={11}>
                  <Box p={2}>
                    <Table>
                      <SubTradeRequestsTableHeader nested verifiable={verifiable} />

                      <TableBody>
                        {trade?.splits.map((split: any) => (
                          <LocalTableRow key={split?.id} row={split} nested />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <CancelSubTradeRequestModal id={subTradeRequestIdToDelete} open={openCancelModal} onClose={onCancelModalClose} afterCancel={afterCancel} />
    </>
  );
};

export default SubTradeRequestsTableRow;
