import { useMemo } from 'react';
import { PortfolioReport } from '../../../../../interfaces';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './interfaces';
import { sumPendingTradesCentsByCondition, sumSubAccountsCurrentCents } from './utils';
import { TableCell, TableRow, Tooltip } from '../../../../2-component';
import { Box, Typography, Grid } from '../../../../1-primative';
import { formatMoneyValue } from '../../../../../util';
import SmallDollarPercentage from './smallDollarPercentage';
import ChangeTooltip from './changeTooltip';
import TaxRank from './taxRank';

export const SecurityTableRow = ({
  isCash = false,
  security,
  subAccounts,
  portfolioReport,
  pendingTrades,
  isMakeHigher = false,
}: {
  isCash?: boolean;
  security: PortfolioSecurity;
  subAccounts: PortfolioSubAccount[];
  portfolioReport?: PortfolioReport;
  pendingTrades: Map<string, DraftSubTradeRequest>[];
  isMakeHigher?: boolean;
}) => {
  const subAccountsCurrentCents = useMemo(() => sumSubAccountsCurrentCents(subAccounts, pendingTrades), [subAccounts, pendingTrades]);
  const pendingTradesCents = useMemo(
    () => sumPendingTradesCentsByCondition(pendingTrades, isCash ? undefined : (trade) => trade.financialProductId === security.id),
    [pendingTrades, security, isCash],
  );

  const { ticker, financialProductTaxRank, translatedName } = security;
  const currentPriceCents = isCash ? 100 : security.currentPriceCents;
  const currentValueCents = isCash ? subAccountsCurrentCents : security.valueCents + pendingTradesCents;
  const currentValueCentsOriginal = isCash ? portfolioReport?.cashCents ?? 0 : security.valueCents;
  const currentValueCentsChange = isCash ? pendingTradesCents * -1 : pendingTradesCents;
  const deviationCents = isCash ? subAccountsCurrentCents - (portfolioReport?.expectedCashCents ?? 0) : security.valueCents + pendingTradesCents - security.expectedValueCents;
  const deviationPercentage = isCash ? deviationCents / (portfolioReport?.expectedCashCents ?? 0) : deviationCents / security.expectedValueCents;
  const expectedValueCents = isCash ? portfolioReport?.expectedCashCents ?? 0 : security.expectedValueCents;
  const expectedPercentage = isCash ? portfolioReport?.expectedCashPercentage ?? 0 : security.expectedPercentage;

  const isCashRebalanceRequired = isCash && (portfolioReport?.transferRebalanceRequired || portfolioReport?.liquidateRebalanceRequired);

  return (
    <TableRow sx={{ height: isMakeHigher ? '75px' : '60px' }}>
      {/* security */}
      <TableCell sx={{ minWidth: '60px', ...(!isCash ? { height: isMakeHigher ? '50px' : '42px' } : {}) }}>
        <Box display='flex' justifyContent='start' alignItems='center' gap='4px'>
          {financialProductTaxRank && <TaxRank taxRank={financialProductTaxRank} />}

          <Typography>
            <Box display='flex' justifyContent='start' flexDirection='column'>
              <Tooltip title={translatedName}>{ticker}</Tooltip>

              {formatMoneyValue(currentPriceCents)}
            </Box>
          </Typography>
        </Box>
      </TableCell>

      {/* target */}
      <TableCell right sx={{ minWidth: '60px' }}>
        <SmallDollarPercentage cents={expectedValueCents} percentage={expectedPercentage} />
      </TableCell>

      {/* current */}
      <TableCell right sx={{ minWidth: '60px' }}>
        <Grid container justifyContent='end' alignItems='center' wrap='nowrap'>
          <Grid item>
            <SmallDollarPercentage cents={currentValueCents} showPercentage={false} />
          </Grid>

          <ChangeTooltip change={currentValueCentsChange} original={currentValueCentsOriginal} flip={isCash} total />
        </Grid>
      </TableCell>

      {/* deviation */}
      <TableCell right sx={{ borderRight: '1px solid grey', minWidth: '60px' }}>
        <SmallDollarPercentage showColor cents={deviationCents} percentage={deviationPercentage} isRedColor={isCashRebalanceRequired} />
      </TableCell>
    </TableRow>
  );
};
