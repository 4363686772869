/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import {
  Alert,
  Box,
  Button, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClientSelect } from '../../../inputs/clientSelect';
import { AffiliationRelations } from '../../../../interfaces/affiliationRelations';
import NewClient from '../../../../pages/clients/components/newClient';
import { usePermissions } from '../../../../providers/userContextProvider';
import { StepProps } from '../wizard';
import { ClientGroupMemberAccessType, User } from '../../../../interfaces';

const FETCH_USERS_HOUSEHOLD = gql`
  query fetchUsersClientgroup($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user { household { id } }
    }
  }
`;

export function ChooseClientStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('addHouseholdMemberWizard');
  const [fetchUsersHousehold, { data: userData }] = useLazyQuery(FETCH_USERS_HOUSEHOLD, { fetchPolicy: 'no-cache' });
  const [alreadyMemberOfSame, setAlreadyMemberOfSame] = useState(false);
  const [alreadyMemberOfAnother, setAlreadyMemberOfAnother] = useState(false);
  const { permissions } = usePermissions();
  /* check: is the chosen client member of any other household? */
  useEffect(() => {
    if (state.user && state.user.id) {
      fetchUsersHousehold({ variables: { userId: state.user.id } });
    }
  }, [state.user]);
  useEffect(() => {
    if (!userData) return;

    if (userData.fetchUser.user.household?.id) {
      setAlreadyMemberOfSame(userData.fetchUser.user.household?.id === context.clientGroupId);
      setAlreadyMemberOfAnother(userData.fetchUser.user.household?.id !== context.clientGroupId);
    } else {
      setAlreadyMemberOfAnother(false);
      setAlreadyMemberOfSame(false);
    }
  }, [userData]);
  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <ClientSelect
            label={t('client')}
            restrictToOrganizationId
            autoFocus
            user={state.user}
            setUser={(newValue) => setState({ ...state, user: newValue as User })}
          />
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('relationshipTo', { whom: context.primaryUserName })}
            value={state.relationship}
            onChange={(event) => setState({ ...state, relationship: event.target.value })}
          >
            {Object.values(AffiliationRelations).map((relation, index) => (
              <MenuItem key={index} value={relation}>
                {t(`affiliationTypes:affiliationRelationship.${relation}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('accessType')}
            value={state.accessType}
            onChange={(event) => setState({ ...state, accessType: event.target.value as ClientGroupMemberAccessType })}
          >
            <MenuItem value="VIEW">{t('household:accessTypes.VIEW')}</MenuItem>
            <MenuItem value="NO_ACCESS">{t('household:accessTypes.NO_ACCESS')}</MenuItem>
          </TextField>
        </ListItem>
      </Box>

      <Box m={2}>
        {alreadyMemberOfSame && userData && (
          <Alert severity="error">
            {t('alreadyMemberOf')} {t('thisHousehold')}
          </Alert>
        )}
        {alreadyMemberOfAnother && userData && (
          <Alert severity="warning">
            {t('alreadyMemberOf')}
            &nbsp;
            <Link to={`/households/${userData.fetchUser.user.household?.id}#tab:members`}>
              {t('anotherHousehold')}
            </Link>
          </Alert>
        )}
      </Box>

      <Box m={2}>
        {!state.user && permissions.some((permission) => ['write:client_basic', 'write:client_low_risk_pii', 'write:client_high_risk_pii', 'write:client_suitability'].includes(permission)) && (
          <Box mb={2}>
            <NewClient
              buttonType='NONE'
              fullWidth
              modalButton={t('createNewClient')}
              customMargin='8px 0px 0px 0px'
              forceOpen
              newClient={(u: User) => setState({ ...state, user: u })}
            />
          </Box>
        )}
        <Button disabled={!state.user || !state.relationship || alreadyMemberOfSame} type='submit'
          onClick={() => continueTo('SUMMARY')} fullWidth variant='contained'>
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
}
