import {
  Account, ClientGroup, Goal, Language, ScheduledTransfer, SubAccount, TranslatedString, User,
} from 'interfaces';

export enum FormTypes {
  ArticlesOfIncorporationOrEquivalent = 'ARTICLES_OF_INCORPORATION_OR_EQUIVALENT',
  BeneficiaryDesignationForm = 'BENEFICIARY_DESIGNATION_FORM',
  ClbForAdults = 'CLB_FOR_ADULTS',
  ClientAccountAgreement = 'CLIENT_ACCOUNT_AGREEMENT',
  CorporateAccountAgreement = 'CORPORATE_ACCOUNT_AGREEMENT',
  CorporateResolution = 'CORPORATE_RESOLUTION',
  DeathCertificate = 'DEATH_CERTIFICATE',
  DeclarationOfTaxDisclosure = 'DECLARATION_OF_TAX_DISCLOSURE',
  DeclarationOfTaxDisclosureE = 'DECLARATION_OF_TAX_DISCLOSURE_E',
  DiscretionaryManagementAgreement = 'DISCRETIONARY_MANAGEMENT_AGREEMENT',
  EducationSavingsGrantsApplication = 'EDUCATION_SAVINGS_GRANTS_APPLICATION',
  ElectronicDeliveryAgreement = 'ELECTRONIC_DELIVERY_AGREEMENT',
  FeeRedirection = 'FEE_REDIRECTION',
  GoalInvestmentPolicyStatement = 'GOAL_INVESTMENT_POLICY_STATEMENT',
  InvestmentPolicyStatement = 'INVESTMENT_POLICY_STATEMENT',
  JointClientAccountAgreement = 'JOINT_CLIENT_ACCOUNT_AGREEMENT',
  LastWill = 'LAST_WILL',
  Lif = 'LIF',
  LifAddendum = 'LIF_ADDENDUM',
  Lira = 'LIRA',
  LiraAddendum = 'LIRA_ADDENDUM',
  LodQesi = 'LOD_QESI',
  Lrif = 'LRIF',
  LrifAddendum = 'LRIF_ADDENDUM',
  Lrsp = 'LRSP',
  LrspAddendum = 'LRSP_ADDENDUM',
  NonIndividualEntityAccountAgreement = 'NON_INDIVIDUAL_ENTITY_ACCOUNT_AGREEMENT',
  OnevestRelationshipDisclosureBrochure = 'ONEVEST_RELATIONSHIP_DISCLOSURE_BROCHURE',
  PadAgreement = 'PAD_AGREEMENT',
  Prif = 'PRIF',
  PrifAddendum = 'PRIF_ADDENDUM',
  RespFamilyPlan = 'RESP_FAMILY_PLAN',
  RespSinglePlan = 'RESP_SINGLE_PLAN',
  RifSpousal = 'RIF_SPOUSAL',
  Rlif = 'RLIF',
  RlifAddendum = 'RLIF_ADDENDUM',
  Rlsp = 'RLSP',
  RlspAddendum = 'RLSP_ADDENDUM',
  Rrif = 'RRIF',
  Rrsp = 'RRSP',
  RrspSpousal = 'RRSP_SPOUSAL',
  Tfsa = 'TFSA',
  TrustAgreement = 'TRUST_AGREEMENT',
  TrustDeed = 'TRUST_DEED',
  TrustParticipantIdentificationForm = 'TRUST_PARTICIPANT_IDENTIFICATION_FORM',
  W8Ben = 'W8BEN',
  W9 = 'W9',
  WaiverOfConfidentiality = 'WAIVER_OF_CONFIDENTIALITY',
  W_8BenE = 'W_8BEN_E',
  W_8Imy = 'W_8IMY',
}

enum ChangeTypes {
  AffiliateAdded = 'AFFILIATE_ADDED',
  AffiliateChanged = 'AFFILIATE_CHANGED',
  AgreementChange = 'AGREEMENT_CHANGE',
  FileDocumentDelete = 'FILE_DOCUMENT_DELETE',
  NewSubAccount = 'NEW_SUB_ACCOUNT',
  Other = 'OTHER',
  PiiUpdated = 'PII_UPDATED',
  PortfolioChange = 'PORTFOLIO_CHANGE',
  PortfolioUpdated = 'PORTFOLIO_UPDATED',
  ScheduledTransferUpdated = 'SCHEDULED_TRANSFER_UPDATED',
  UsersRequiredToSignUpdated = 'USERS_REQUIRED_TO_SIGN_UPDATED',
}

export type FormAgreement = {
  id: string
  user?: User
  account?: Account
  subAccount?: SubAccount
  goal?: Goal
  scheduledTransfer?: ScheduledTransfer
  type?: FormTypes
  version?: number
  signedAt?: Date
  signedUrl?: string
  language?: Language
  displayName?: string
  templateUrl?: string
  translatedDisplayName?: TranslatedString
  usersRequiredToSign?: [{ id: string, user: User, signedAt: Date }]
  allUsersSignedAt?: Date
};

export type IncompleteFormAgreement = {
  id: string
  user?: User
  account?: Account
  subAccount?: SubAccount
  goal?: Goal
  scheduledTransfer?: ScheduledTransfer
  clientGroup?: ClientGroup,
  type?: FormTypes
  minVersion?: number
  changeType?: ChangeTypes
  changeReason?: string
  translatedDisplayName?: TranslatedString
  translatedChangeReason?: TranslatedString
  templateUrl?: string
  usersRequiredToSign?: [{ id: string, user: User, signedAt: Date }]
  digitalSignatureEnabled?: boolean
  isPrefilledPdfEnabled?: boolean
  hasPdfTemplate?: boolean
};
