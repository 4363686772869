import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterModal } from '../../../3-pattern/filterModal/filterModal';
import { CircularProgress, MenuItem, SelectField } from '../../../2-component';
import { Theme } from '../../../../interfaces';
import { translateBackend } from '../../../../assets/i18n/config';

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        id
      }
      totalCount
    }
  }
`;

interface ThemeItem {
  themeId?: string;
  themeName?: string;
}

const sortThemeItems = (themeItems: ThemeItem[]) => {
  const collator = new Intl.Collator('en');
  const sorted = themeItems.sort((a, b) => collator.compare(a.themeName ?? '', b.themeName ?? ''));
  return sorted;
};

export const FilterModelPortfolios = ({ setThemeIds, themeIds, organizationId }: { setThemeIds: (thIds?: string[]) => void; themeIds?: string[]; organizationId?: string }) => {
  const { t } = useTranslation('modelPortfolios');
  const [options, setOptions] = useState<ThemeItem[]>([]);
  const { loading } = useQuery(FETCH_THEMES, {
    variables: {
      input: {
        filter: {
          organizationId,
        },
      },
    },
    onCompleted: (data) => {
      const newOptions = data.fetchThemes.themes.map((th: Theme) => ({
        themeId: th.id,
        themeName: translateBackend(th?.translatedName),
      }));
      setOptions(sortThemeItems(newOptions));
    },
  });

  return (
    <FilterModal filterExists={themeIds && (themeIds.length > 0)} title={t('modelPortfolioTable.filterModal.modalTitle')}>
      {loading ? (
        <CircularProgress />
      ) : (
        <SelectField
          value={themeIds ?? []}
          fullWidth
          multiple
          label={t('modelPortfolioTable.filterModal.groupTitle')}
          onChange={(e: any) => setThemeIds(e.target.value)}
        >
          {options.map((th: ThemeItem, index) => (
            <MenuItem key={index} value={th.themeId}>
              {th.themeName}
            </MenuItem>
          ))}
        </SelectField>
      )}
    </FilterModal>
  );
};
