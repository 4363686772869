import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Divider from '@mui/material/Divider';
import {
  AccountStates,
  AccountTypes,
  Affiliation,
  AffiliationRelations,
  AffiliationTypes,
  DraftAccount,
  Goal,
  User,
  EntityTypes,
} from 'interfaces';
import { generateClientNameString } from 'util/index';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Icon,
  Typography,
  Grid,
} from 'ovComponents/1-primative';
import {
  Button,
  Form,
  HelpText,
  TextField,
} from 'ovComponents/2-component';
import {
  customEntitySearchFnFactory,
  ClientSelectField,
} from 'ovComponents/2-component/clientSelectField/clientSelect';
import {
  validateFields, FormError, FormErrors, FieldOptions,
} from 'ovComponents/4-module/workflowCompletion/subSteps/utils';
import { GoalLinkingInput, getValidGoals } from '../../goalLinking';
import { AffiliationTable } from '../../affiliationTable';

interface CorporateCashDialogContentProps {
  showGoalLinkingSection: boolean,
  userData: User,
  onAccountCreated: (account: DraftAccount) => void,
  onDialogClose: () => void,
}

type AffiliateSelectFactoryResponse = [
  (props: { entity: User | undefined, allocation: number, error?: FormError }) => JSX.Element,
  User | undefined,
  number,
];
interface AffiliateSelectFactoryProps {
  affiliationType: AffiliationTypes,
  title: string,
  inputLabel: string,
}

interface AffiliateInputConfig {
  type: AffiliationTypes,
  required: boolean,
}
export const checkAffiliationMatch = (candidate: Affiliation, deleteTarget: Affiliation) => (
  candidate?.user?.id === deleteTarget?.user?.id
  && candidate?.type === deleteTarget?.type
  && candidate?.relation === deleteTarget?.relation
);

const getAffiliateInputConfig = (entityType: EntityTypes): AffiliateInputConfig[] => {
  let config: AffiliateInputConfig[];
  switch (entityType) {
    case EntityTypes.INVESTMENT_FUND:
    case EntityTypes.PARTNERSHIP:
    case EntityTypes.PRIVATE_COMPANY:
    case EntityTypes.PUBLICLY_LISTED_ENTITY:
    case EntityTypes.REGULATED_ENTITY:
    case EntityTypes.SOLE_PROPRIETORSHIP:
    case EntityTypes.OTHER:
      config = [
        { type: AffiliationTypes.AuthorizedIndividual, required: true },
        { type: AffiliationTypes.BeneficialOwner, required: true },
        { type: AffiliationTypes.ThirdParty, required: false },
      ];
      break;
    case EntityTypes.TRUST:
      config = [
        { type: AffiliationTypes.Trustee, required: true },
        { type: AffiliationTypes.BeneficialOwner, required: true },
        { type: AffiliationTypes.Settlor, required: true },
        { type: AffiliationTypes.Protector, required: false },
        { type: AffiliationTypes.ThirdParty, required: false },
      ];
      break;
    default:
      config = [];
      break;
  }
  return config;
};

export const CorporateCashDialogContent = ({
  userData,
  showGoalLinkingSection,
  onAccountCreated,
  onDialogClose,
}: CorporateCashDialogContentProps) => {
  const { t } = useTranslation(['workflowCompletions', 'affiliationTypes']);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const [goalsLinked, setGoalsLinked] = useState<Goal[]>([]);
  const [affiliations, setAffiliations] = useState<Affiliation[]>([]);
  const goals = getValidGoals(userData);
  const affiliateInputConfig = getAffiliateInputConfig(userData.type);
  const visibleAffiliateInputTypes = (
    affiliateInputConfig
      .map((config) => config.type)
      .filter((type) => {
        if (type === AffiliationTypes.ThirdParty) return userData.isForThirdParty;
        return true;
      })
  );

  const fieldOptions: FieldOptions = useMemo(() => (
    affiliateInputConfig.reduce((acc: FieldOptions, entry: AffiliateInputConfig) => ({ ...acc, [entry.type]: entry }), {})
  ), [affiliateInputConfig]);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const data = Object.fromEntries(
      (Object.keys(fieldOptions) as AffiliationTypes[]).map((type) => (
        [type, affiliations.filter((aff) => aff.type === type)]
      )),
    );
    const newErrors = validateFields(fieldOptions, data, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [affiliations, fieldOptions]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    onAccountCreated({
      type: AccountTypes.CORPORATE_CASH,
      applyForGovFunds: [],
      id: '',
      state: AccountStates.ACTIVE,
      user: userData,
      affiliations,
      goalsLinked,
    });
  };

  const searchableEntities = (userData?.relatedEntities ?? []).map((x) => x.entity);
  const relatedEntitySearchFn = customEntitySearchFnFactory(searchableEntities);
  const handleDeleteAffiliation = (affiliation: Affiliation) => {
    const newAffiliations = affiliations.filter((x) => !checkAffiliationMatch(x, affiliation));
    setAffiliations(newAffiliations);
    validate([affiliation.type]);
  };

  const useCustomEntitySelect = (inputLabel: string): [() => JSX.Element, User | undefined] => {
    const [entity, setEntity] = useState<User>();
    const CustomEntitySelectField = useCallback(() => (
      <ClientSelectField
        user={entity}
        setUser={setEntity}
        label={inputLabel}
        customSearchFn={relatedEntitySearchFn}
        fullWidth
      />
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);
    return [CustomEntitySelectField, entity];
  };

  const useAllocationField = (): [() => JSX.Element, number, (allocation: number) => void ] => {
    const [allocation, setAllocation] = useState<number>(0);
    const AllocationField = useCallback(() => (
      <TextField
        onChange={(e: any) => setAllocation(Number(e.target.value))}
        label={t('components:beneficiaryCrud.allocation')}
        fullWidth
        trailingIcon='percent'
        defaultValue={0}
        errorText={t('components:beneficiaryCrud.allocationError')}
        inputProps={{ type: 'number' }}
      />
    ), []);
    return [AllocationField, allocation, setAllocation];
  };

  useEffect(() => {
    validate(focused);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliations]);

  const useAffiliateSelect = (props: AffiliateSelectFactoryProps): AffiliateSelectFactoryResponse => {
    const [EntitySelectField, boundEntity] = useCustomEntitySelect(props.inputLabel);
    const [AllocationField, boundAllocation, setBoundAllocation] = useAllocationField();

    const handleAdd = (entity: User | undefined, allocation: number) => {
      if (entity) {
        const draftAffiliate: Affiliation = {
          user: entity,
          relation: AffiliationRelations.Other,
          type: props.affiliationType,
          allocation,
        };
        setAffiliations((prevState) => {
          const doesExist = prevState.some((affiliate) => (
            affiliate.user.id === draftAffiliate.user.id
            && affiliate.type === draftAffiliate.type
          ));

          if (doesExist) return prevState;
          return [...prevState, draftAffiliate];
        });
        setBoundAllocation(0);
      }
      validate([props.affiliationType]);
      setFocused([...focused, props.affiliationType]);
    };

    const PreviewTable = () => {
      const affiliatesOfType = affiliations.filter((affiliation) => affiliation.type === props.affiliationType);
      const showAllocation = props.affiliationType === AffiliationTypes.BeneficialOwner;
      return (
        <AffiliationTable
          affiliations={affiliatesOfType}
          onDelete={handleDeleteAffiliation}
          showAllocation={showAllocation}
          allowDelete
        />
      );
    };

    const InputRow = () => {
      const isBeneficiary = props.affiliationType === AffiliationTypes.BeneficialOwner;
      return (
        <Grid container spacing={1}>
          <Grid item xs={isBeneficiary ? 9 : 12}><EntitySelectField /></Grid>
          {isBeneficiary && <Grid item xs={3}><AllocationField /></Grid>}
        </Grid>
      );
    };

    const AffiliateSelect = useCallback((affiliateSelectProps: {
      entity: User | undefined,
      allocation: number,
      error?: FormError,
    }) => (
      <Grid container gap={2}>
        <Typography variant='headingXSmall' sx={{ color: colors.neutral800 }}>{props.title}</Typography>
        <PreviewTable />
        <InputRow />
        <Button
          variant='outlined'
          leadingIcon={AddRoundedIcon}
          label={t('workflowCompletions:baseAccountCreation.addAffiliate')}
          onClick={() => handleAdd(affiliateSelectProps.entity, affiliateSelectProps.allocation)}
          type='button'
        />
        {affiliateSelectProps.error && <Grid item xs={12}><HelpText tone='error' text={affiliateSelectProps.error.message} /></Grid>}
        <Grid item xs={12}><Divider variant='fullWidth' /></Grid>
      </Grid>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [affiliations, focused]);

    return [AffiliateSelect, boundEntity, boundAllocation];
  };

  const affiliateTypeInputMap = {
    [AffiliationTypes.Trustee]: useAffiliateSelect({
      affiliationType: AffiliationTypes.Trustee,
      title: t('workflowCompletions:corporateCashAccountCreation.trustee.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.trustee.inputLabel'),
    }),
    [AffiliationTypes.AuthorizedIndividual]: useAffiliateSelect({
      affiliationType: AffiliationTypes.AuthorizedIndividual,
      title: t('workflowCompletions:corporateCashAccountCreation.authorizedIndividual.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.authorizedIndividual.inputLabel'),
    }),
    [AffiliationTypes.BeneficialOwner]: useAffiliateSelect({
      affiliationType: AffiliationTypes.BeneficialOwner,
      title: t('workflowCompletions:corporateCashAccountCreation.beneficialOwner.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.beneficialOwner.inputLabel'),
    }),
    [AffiliationTypes.Settlor]: useAffiliateSelect({
      affiliationType: AffiliationTypes.Settlor,
      title: t('workflowCompletions:corporateCashAccountCreation.settlor.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.settlor.inputLabel'),
    }),
    [AffiliationTypes.Protector]: useAffiliateSelect({
      affiliationType: AffiliationTypes.Protector,
      title: t('workflowCompletions:corporateCashAccountCreation.protector.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.protector.inputLabel'),
    }),
    [AffiliationTypes.ThirdParty]: useAffiliateSelect({
      affiliationType: AffiliationTypes.ThirdParty,
      title: t('workflowCompletions:corporateCashAccountCreation.thirdParty.title'),
      inputLabel: t('workflowCompletions:corporateCashAccountCreation.thirdParty.inputLabel'),
    }),
  };

  return (
    <Form onSubmit={submit}>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Box sx={{ cursor: 'pointer' }} display="flex" onClick={onDialogClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
      </Box>
      <Grid container sx={{ mt: 1 }} gap={3}>
        <Grid item xs={12}>
          <Typography variant='headingMedium'>{t('workflowCompletions:corporateCashAccountCreation.title', { entityName: generateClientNameString(userData) })}</Typography>
        </Grid>
        {
          // This section programmatically parses the `affiliateInputConfig` to determine if
          // a field is shown, based on the account creator's entityType
          Object.entries(affiliateTypeInputMap).map((mapping, index) => {
            const [affiliateType, [InputComponent, boundEntity, boundAllocation]] = mapping;
            if (!visibleAffiliateInputTypes.includes(affiliateType as AffiliationTypes)) return null;
            return (
              <Grid item xs={12} key={index}>
                <InputComponent
                  entity={boundEntity}
                  allocation={boundAllocation}
                  error={(errors ?? {})[affiliateType]}
                />
              </Grid>
            );
          })
        }
        { showGoalLinkingSection && <Grid item xs={12}>
          <GoalLinkingInput goals={goals} goalsLinked={goalsLinked} setGoalsLinked={setGoalsLinked} />
        </Grid>}
      </Grid>
      <Box width='100%' display='flex' justifyContent='end'>
        <Button
          color='primary'
          label={t('workflowCompletions:baseAccountCreation.addAccount')}
          sx={{ mt: 3 }}
          type='submit'
        />
      </Box>
    </Form>
  );
};
