import { gql } from '@apollo/client';
import {
  Account,
  Affiliation,
  AffiliationTypes,
  EntityTypes,
  User,
} from 'interfaces';
import { first, flatten, intersection } from 'lodash/fp';

export interface FormProps {
  userId: string,
  apolloClient: any,
  userContext: any,
}

export const FETCH_USER_RELATED_ENTITIES = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        type
        accounts {
          id
          affiliations {
            type
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const fetchAuthorizedIndividualForNonIndividuals = (fetchUser: User, userContext: any) => {
  // logics to find corresponding Authorized Individual
  const authorizedIndividualsIds = flatten(fetchUser.accounts
    .map((u: Account) => u.affiliations && u.affiliations
      .filter((a: Affiliation) => a.type === AffiliationTypes.AuthorizedIndividual)
      .map((k: Affiliation) => k.user.id))) || [];

  if (authorizedIndividualsIds && authorizedIndividualsIds.length === 0) return null;

  if (authorizedIndividualsIds && userContext?.accessType === 'ENTITY') {
    /* TODO should include a dialog to choose the correct authorized individual */
    if (userContext.entities && userContext.entities.length > 0) {
      const entitiesIdlist: string[] = userContext.entities.map((k: { id: string, entity: User }) => k.entity).map((a: any) => a.id) || [];
      const userIntersectionsIds = intersection(authorizedIndividualsIds, entitiesIdlist) || [];
      if ((userIntersectionsIds && userIntersectionsIds.length === 0)) return null;
      return first(userIntersectionsIds);
    }
    return null; // return null if there are no related entities
  }
  // when accessType is ORGANIZATIONAL
  return first(authorizedIndividualsIds);
};

export const fetchAuthorizedIndividual = async ({ userId, apolloClient, userContext }: FormProps): Promise<string | null | undefined> => {
  if (!userId) return null;
  // Get User and related ENTITIES
  const queryResult = await apolloClient.query({
    query: FETCH_USER_RELATED_ENTITIES,
    variables: {
      userId,
    },
    fetchPolicy: 'no-cache',
  });
  const fetchUser: User = queryResult?.data?.fetchUser?.user;
  if (!fetchUser || queryResult?.error) {
    return null;
  }
  if (fetchUser.type !== EntityTypes.INDIVIDUAL) {
    return fetchAuthorizedIndividualForNonIndividuals(fetchUser, userContext);
  }
  return fetchUser?.id;
};
