import { Square } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

interface ColorPickerFieldProps {
  label: string,
  value: string,
  onChange: (event: any) => void,
}
const ColorPickerField = (props: ColorPickerFieldProps) => {
  const [open, setOpen] = useState(false);
  const style = {
    popover: {
      position: 'absolute',
      zIndex: '2',
      left: '10px',
      bottom: '100px',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TextField id='name'
        label={props.label}
        sx={{ width: '100%' }} value={props.value}
        onClick={handleClickOpen}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Square style={{ color: props.value }} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
      {open && <Box sx={style.popover}>
        <Box sx={style.cover} onClick={handleClose} />
        <ChromePicker
          color={props.value}
          onChangeComplete={(e) => {
            props.onChange(e.hex);
          }}
        />
      </Box>}
    </>
  );
};

export default ColorPickerField;
