import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme/colors';
import { InlineImage } from '../../client/components/documents/inlineImage';
import { FileDownloaderCard } from '../../client/components/documents/fileDownloaderCard';
import { FETCH_AWAITING_BANK_ACCOUNTS_COUNT } from '../../../providers/globalStatsHooks';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { FETCH_BANK_ACCOUNTS, stateBankAccountStateColor } from './bankAccountsTable';
import { usePermissions } from '../../../providers/userContextProvider';
import { useLocalization } from '../../../util/useLocalization';
import { generateClientNameString } from '../../../util';

export const FETCH_FILE_DOCUMENTS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        downloadUrl
        mediaType
        fileName
        name
        type
      }
      totalCount
    }
  }
`;
export const TRANSITION_BANK_ACCOUNT = gql`
  mutation transitionBankAccount($input: TransitionBankAccountInput!) {
    transitionBankAccount(input: $input) {
      bankAccount {
        id
      }
    }
  }
`;

const FETCH_BANK_ACCOUNT = (permissions: string[]) => gql`
  query fetchBankAccount($id: ObjectID!) {
    fetchBankAccount(bankAccountId: $id) {
      bankAccount {
        id
        name
        state
        source
        failReason
        bankAccountNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'transitNumberDisclosed' : ''}
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed' : ''}
        transitNumber
        institutionNumber
        user{
          id
          firstName
          lastName
          entityName
          organization{
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
enum BankAccountStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
const transitions = [
  {
    name: 'approve',
    from: [BankAccountStates.AWAITING_REVIEW],
  },
  {
    name: 'reject',
    from: [BankAccountStates.AWAITING_REVIEW, BankAccountStates.APPROVED],
  },
];
export const styles = {
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '624px',
      },
    },
  },
  dialogButton: {
    minWidth: '160px',
    fontWeight: 600,
    fontSize: '14px',
    marginLeft: '16px',
  },
  closeButton: {
    float: 'right',
    width: '13.91px',
    height: '13.91px',
    fontWeight: 500,
    color: colors.black,
  },
  fileCard: {
    width: '100%',
    maxHeight: '350px',
  },
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '424px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '376px',
      title: {
        fontWeight: 700,
      },
    },
    dialogButton: {
      minWidth: '160px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
};
const BankAccountDocumentViewer = ({
  bankAccount, open, handleClose,
}: { bankAccount: any, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['components', 'bankAccountsReview']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [transitionStatement] = useMutation(TRANSITION_BANK_ACCOUNT, {
    refetchQueries: [FETCH_BANK_ACCOUNTS, FETCH_AWAITING_BANK_ACCOUNTS_COUNT],
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentTransition, setCurrentTransition] = useState('');

  const [fetchAccount, { data: accountData, error }] = useLazyQuery(FETCH_BANK_ACCOUNT(permissions));

  const { data } = useQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          sourceIds: [bankAccount.id],
        },
      },
    },
  });

  useEffect(() => {
    if (bankAccount.id) {
      fetchAccount({
        variables: {
          id: bankAccount.id,
        },
      });
    }
  }, [bankAccount.id, fetchAccount]);

  if (error) (<Typography>Error</Typography>);

  const confirmTransition = async () => {
    await transitionStatement({ variables: { input: { bankAccountId: bankAccount.id, transition: currentTransition } } });
    setOpenConfirmationModal(false);
    handleClose();
  };

  if (!bankAccount) return <></>;

  const bankAccountData = accountData?.fetchBankAccount?.bankAccount || bankAccount;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      sx={styles.dialogContainer}
    >
      <DialogTitle sx={{
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        {`${generateClientNameString(bankAccountData.user, false, true)}${t('bankAccountsReview:bankAccountPostfix')}`}
      </DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sx={{ display: 'flex' }}>
            <Typography fontSize='small'>
              {t('bankAccountsReview:filters.state')}:&nbsp;
            </Typography>
            <Chip size='small' label={t(`bankAccountsReview:filters.stateOptions.${bankAccountData.state}`)} color={stateBankAccountStateColor(bankAccountData.state)} />
          </Grid>
          {bankAccountData.state === 'APPROVED' && (<Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.approvedDate')}:&nbsp; </Typography>
            <Typography fontSize='small'>{localizedDate(bankAccountData.updatedAt)}</Typography>
          </Grid>)}
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.source')}:&nbsp;</Typography>
            <Typography fontSize='small'>{t(`bankAccountsReview:filters.sourceOptions.${bankAccountData.source}`)}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.name')}:&nbsp;</Typography>
            <Typography fontSize='small'>{bankAccountData.name}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.failReason')}:&nbsp;</Typography>
            <Typography fontSize='small'>{bankAccountData.failReason}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.requestedDate')}:&nbsp; </Typography>
            <Typography fontSize='small'>{localizedDate(bankAccountData.createdAt)}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.transitNumber')}:&nbsp;</Typography>
            <Typography fontSize='small'>{bankAccountData.transitNumberDisclosed ?? bankAccountData.transitNumber}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.institutionNumber')}:&nbsp;</Typography>
            <Typography fontSize='small'>{bankAccountData.institutionNumber}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Typography fontSize='small'>{t('bankAccountsReview:filters.bankAccountNumber')}:&nbsp;</Typography>
            <Typography fontSize='small'>{bankAccountData.bankAccountNumberDisclosed ?? bankAccountData.bankAccountNumber}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {(data?.fetchFileDocuments?.totalCount !== 0) ? (
            data?.fetchFileDocuments?.fileDocuments.map((doc: any) => {
              const modifiedFile = { ...doc, isFileDocument: true };
              const isImage = doc?.mediaType?.startsWith('image/');
              return (
                <Grid item key={doc.fileName} xs={12} sx={{ display: 'flex' }} paddingTop={2}>
                  {isImage
                    ? <InlineImage fileDocumentId={doc.id} />
                    : <FileDownloaderCard fileDocument={modifiedFile} {...styles.fileCard} />
                  }
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} sx={{ display: 'flex' }} paddingTop={5}>
              <Alert severity="warning">
                <Typography fontSize='small'>{t('bankAccountsReview:noFile')}</Typography>
              </Alert>
            </Grid>
          )}
        </Grid>

        <Grid container sx={{ display: 'flex', justifyContent: 'end' }} paddingTop={5}>
          {transitions.map((transition: any) => (
            transition.from.includes(bankAccountData.state) && (
              <Button sx={styles.dialogButton} key={transition.name} type='button' color={transition.name === 'reject' ? 'error' : 'success'} variant='contained' onClick={async () => {
                setCurrentTransition(transition.name);
                setOpenConfirmationModal(true);
              }}>
                {t(`bankAccountsReview:bankAccountTransitions.${transition.name}`)}
              </Button>
            )
          ))}
        </Grid>

      </DialogContent>
      <ConfirmationModal
        dialogStyles={styles.confirmation}
        open={openConfirmationModal}
        title={t('bankAccountsReview:moderateBankAccount')}
        bodyText={t('bankAccountsReview:confirmationDialogTitle', { transition: currentTransition })}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => confirmTransition()}
      />
    </Dialog>
  );
};

export default BankAccountDocumentViewer;
