import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid } from '../../1-primative';
import { Popover } from '../../2-component';
import { useLocalization } from '../../../util/useLocalization';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useLineChartTokens } from '../../2-component/lineChart/lineChart.tokens';

// TODO: Replace with new components
import { SimpleDateRangePicker } from '../../../components/fields/simpleDateRangePicker';

export type HistoricalTimeFrame = 'custom' | '1M' | '3M' | '6M' | '1Y' | '5Y' | 'YTD' | 'MAX';

interface IMarketValueTimeFramesProps {
  id: string;
  timeFrame?: HistoricalTimeFrame;
  startDate?: string;
  endDate?: string;
  availableTimeFrames: HistoricalTimeFrame[];
  showCustom?: boolean;
  setTimeFrame: (tf: HistoricalTimeFrame) => void;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}

export const MarketValueTimeFrames: React.FC<IMarketValueTimeFramesProps> = ({
  id, timeFrame, startDate, endDate, availableTimeFrames, showCustom, setTimeFrame, setStartDate, setEndDate,
}) => {
  const { t } = useTranslation(['client', 'components']);
  const { localizedDate } = useLocalization();
  const { sys, comp } = useThemeTokens(useLineChartTokens());
  const [customDateRangePopover, setCustomDateRangePopover] = useState<HTMLButtonElement | null>(null);

  const isCustomTF = timeFrame === 'custom';

  const onTimeFrameClick = (tf: HistoricalTimeFrame) => {
    setTimeFrame(tf);
    setStartDate('');
    setEndDate('');
  };

  return (
    <>
      <Grid container justifyContent={{ xs: 'center', sm: 'end' }} spacing={1}>
        {showCustom && (
          <Grid item display={{ xs: 'none', sm: 'block' }}>
            <Box
              data-testid='market-value-chart-date-range-custom'
              onClick={(event: any) => {
                setCustomDateRangePopover(event.currentTarget);
              }}
              sx={{
                height: comp.lineChart?.timeFrameButtonHeight ?? '22px',
                borderRadius: sys.borderRadius.sm,
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: isCustomTF ? sys.color.surface : sys.color.onSurface,
                backgroundColor: isCustomTF ? sys.color.onSurface : sys.color.surface,
                ...isCustomTF ? {} : {
                  '&:hover': { backgroundColor: `${sys.color.outlineVariant} !important` },
                },
              }}
            >
              <Typography margin='0.2em 1em' sx={{ color: isCustomTF ? sys.color.surface : undefined }}>
                {isCustomTF ? (<>{localizedDate(startDate)} - {localizedDate(endDate)}</>) : t('assetsOverview.customDateRange')}
              </Typography>
            </Box>
          </Grid>
        )}

        {availableTimeFrames.map((tf) => (
          <Grid item key={tf}>
            <Box
              data-testid={`market-value-chart-date-range-${tf}${timeFrame === tf ? '-selected' : ''}`}
              onClick={() => onTimeFrameClick(tf)}
              sx={{
                height: comp.lineChart?.timeFrameButtonHeight ?? '22px',
                borderRadius: sys.borderRadius.sm,
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: timeFrame === tf ? sys.color.surface : sys.color.onSurface,
                backgroundColor: timeFrame === tf ? sys.color.onSurface : sys.color.surface,
                '&:hover': { backgroundColor: timeFrame === tf ? sys.color.onSurface : `${sys.color.outlineVariant} !important` },
              }}
            >
              <Typography key={tf} sx={{ margin: '0.2em 1em', color: timeFrame === tf ? sys.color.surface : sys.color.onSurface }}>
                {t(`assetsOverview.${tf}`)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Popover
        id={id}
        data-testid='market-value-chart-custom-date-range-popover'
        open={!!customDateRangePopover}
        anchorEl={customDateRangePopover}
        onClose={() => setCustomDateRangePopover(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SimpleDateRangePicker
          date1={startDate ? dayjs(startDate) : null}
          date2={endDate ? dayjs(endDate) : null}
          setDates={(date1, date2) => {
            setCustomDateRangePopover(null);
            setStartDate(date1.format('YYYY-MM-DD'));
            setEndDate(date2.format('YYYY-MM-DD'));
            setTimeFrame('custom');
          }}
        />
      </Popover>
    </>
  );
};
