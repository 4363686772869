import { useThemeTokens } from '../../../providers/themeTokenProvider';

export interface CheckboxTokens {
  checkbox: {
    defaultColor?: string;
    activeColor?: string;
    disabledColor?: string;
  },
}

export const useCheckboxTokens = (): CheckboxTokens => {
  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;
  const comp: CheckboxTokens = {
    checkbox: {
      defaultColor: sys.color.onSurface,
      activeColor: sys.color.primary,
      disabledColor: sys.color.onDisabled,
    },
  };

  return comp;
};
