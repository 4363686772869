import { TextField, MenuItem } from '@mui/material';
import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubAccount, User, getSubAccountName } from 'interfaces';
import { usePermissions } from '../../providers/userContextProvider';

export const FETCH_SUB_ACCOUNTS = (permissions: string[], fetchUser?: boolean) => gql`
  query fetchSubAccounts($filter:SubAccountQueryFilter) {
    fetchSubAccounts(
      input: {
        filter: $filter
        pagination: { perPage: 1000 }
      }
    ) {
      subAccounts {
        id
        state
        goal {
          name
        }
        account {
          id
          type
          ${fetchUser ? `
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
          ` : ''}
        }
        financialProduct {
          translatedName {
            en
          }
        }
        statistics {
          marketValueCents
        }
        allowClientDeposits
      }
    }
  }
`;

export interface SubAccountSelectData {
  id: string,
  goal: { name: string },
  account: { type: string, id: string, user?: User },
  financialProduct?: { translatedName?: { en?: string } },
  statistics: { marketValueCents: number },
}

export const SubAccountSelect = ({
  setSubAccount,
  value,
  label,
  size,
  disabledWith = null,
  readonly = false,
  forObject,
  forId,
  onlyAccountType,
  setSubAccountData,
  excludedIds,
  disabled = false,
  selectFirst = false,
  includeAnyOption = false,
}: {
  setSubAccount: (event: any) => void,
  value: string,
  label: string,
  size?: 'small' | 'medium' | undefined,
  disabledWith?: string | null,
  readonly?: boolean,
  forObject: 'USER' | 'CLIENT_GROUP' | 'ACCOUNT',
  forId: string,
  onlyAccountType?: string,
  setSubAccountData?: (data: SubAccountSelectData) => void,
  excludedIds?: string[],
  disabled?: boolean
  selectFirst?: boolean,
  includeAnyOption?: boolean,
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const showClientNames = forObject === 'CLIENT_GROUP';
  const [subAccounts, setSubAccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUB_ACCOUNTS(permissions, showClientNames), {
    variables: {
      filter: {
        ...(forObject === 'USER' && { userId: forId }),
        ...(forObject === 'CLIENT_GROUP' && { clientGroupId: forId }),
        ...(forObject === 'ACCOUNT' && { accountId: forId }),
        ...(onlyAccountType && { accountType: onlyAccountType }),
      },
    },
    onCompleted: ((data) => {
      const flatSubAccounts = Object.keys(data).map((index) => data[index].subAccounts)
        .flat()
        .sort((a, b) => a.account.type.localeCompare(b.account.type));
      setSubAccounts(flatSubAccounts);
    }),
  });

  const subAccountsShown = subAccounts.filter((x: any) => (
    (x.state !== 'INACTIVE' && (!excludedIds || !excludedIds.includes(x.id)))
  ));

  useEffect(() => {
    if (selectFirst && !loading && value === '' && subAccountsShown.length > 0) {
      setSubAccount({ target: { value: subAccountsShown[0].id } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFirst, loading, subAccounts]);

  return (
    <>
      { permissions.includes('read:account_basic') ? (
        <TextField
          select
          value={loading ? '' : value }
          label={label}
          sx={{ width: '100%' }}
          onChange={(e: any) => {
            setSubAccount(e);
            if (setSubAccountData && subAccounts) {
              const subAccount = subAccounts.find((s: any) => s.id === e.target.value);
              setSubAccountData(subAccount as SubAccountSelectData);
            }
          }}
          size={size}
          disabled={!isNull(disabledWith) || disabled}
          InputProps={{
            readOnly: readonly,
          }}
        >
          {
            loading ? <MenuItem>...</MenuItem> : (
              disabledWith ? (
                <MenuItem key={value} value={ value }>
                  <b>{disabledWith}</b>
                </MenuItem>
              ) : [
                includeAnyOption && (
                  <MenuItem key='any' value='any'>
                    {t('any')}
                  </MenuItem>
                ),
                subAccountsShown.map((sa: any) => (
                  <MenuItem key={sa.id} value={sa.id}>
                    {getSubAccountName(sa, showClientNames)}
                  </MenuItem>
                )),
              ]
            )
          }
        </TextField>
      ) : (<></>)}
    </>
  );
};
