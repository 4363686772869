import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const FETCH_CLIENT_GROUP_NAME = gql`
  query fetchClientGroupName($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        name
      }
    }
  }
`;

export function ClientGroupBreadcrumb() {
  const { data } = useQuery(FETCH_CLIENT_GROUP_NAME, { variables: { id: useParams().clientGroupId } });

  return data ? data.fetchClientGroup?.clientGroup.name : 'Client Group';
}
