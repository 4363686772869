import { MarketList } from './widgets/marketList/marketList';
import { MarketTiles } from './widgets/marketTiles/marketTiles';
import { TickerBand } from './widgets/tickerBand/tickerBand';
import { NewsHeadlines } from './widgets/newsHeadlines/newsHeadlines';
import { InterestRate } from './widgets/interestRate/interestRate';
import { MainIndex } from './widgets/mainIndex/mainIndex';
import { Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';
import { SearchSecurities } from './widgets/searchSecurities/searchSecurities';
import { InstrumentInfo } from './widgets/instrumentInfo/instrumentInfo';
import { PriceChart } from './widgets/priceChart/priceChart';
import { EstimatesRatios } from './widgets/estimatesRatios/estimatesRatios';
import { CompanyProfile } from './widgets/companyProfile/companyProfile';
import { FundAllocation } from './widgets/fundAllocation/fundAllocation';
import { FundAvgAnnualReturns } from './widgets/fundAvgAnnualReturns/fundAvgAnnualReturns';
import { PriceYieldChart } from './widgets/priceYieldChart/priceYieldChart';
import { RefinitivWidgetType } from './useRefinitivWidget';

export type RefinitivOptions = {
  widgetType: RefinitivWidgetType;
  [key: string]: any;
};

export const Refinitiv = ({ options }: { options: RefinitivOptions }) => {
  const CustomTitle = () => options.customTitle && (
    <Typography variant='titleLarge' sx={{ my: 1 }}>
      {translateBackend(options.customTitle)}
    </Typography>
  );

  switch (options.widgetType) {
    case 'WMainIndex':
      return (
        <>
          <CustomTitle />
          <MainIndex options={options} />
        </>
      );
    case 'WMarketList':
      return (
        <>
          <CustomTitle />
          <MarketList options={options} />
        </>
      );
    case 'WMarketTiles':
      return (
        <>
          <CustomTitle />
          <MarketTiles options={options} />
        </>
      );
    case 'WTickerBand':
      return (
        <>
          <CustomTitle />
          <TickerBand options={options} />
        </>
      );
    case 'WNewsHeadlines':
      return (
        <>
          <CustomTitle />
          <NewsHeadlines options={options} />
        </>
      );
    case 'WInterestRate':
      return (
        <>
          <CustomTitle />
          <InterestRate options={options} />
        </>
      );
    case 'WInstrumentInfo':
      return (
        <>
          <CustomTitle />
          <InstrumentInfo options={options} />
        </>
      );
    case 'WSearchSecurities':
      return (
        <>
          <CustomTitle />
          <SearchSecurities />
        </>
      );
    case 'WPriceChart':
      return (
        <>
          <CustomTitle />
          <PriceChart options={options} />
        </>
      );
    case 'WEstimatesRatios':
      return (
        <>
          <CustomTitle />
          <EstimatesRatios options={options} />
        </>
      );
    case 'WCompanyProfile':
      return (
        <>
          <CustomTitle />
          <CompanyProfile options={options} />
        </>
      );
    case 'WFundAllocation':
      return (
        <>
          <CustomTitle />
          <FundAllocation options={options} />
        </>
      );
    case 'WFundAvgAnnualReturns':
      return (
        <>
          <CustomTitle />
          <FundAvgAnnualReturns options={options} />
        </>
      );
    case 'WPriceYieldChart':
      return (
        <>
          <CustomTitle />
          <PriceYieldChart options={options} />
        </>
      );
    default:
      return <></>;
  }
};
