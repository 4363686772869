import { useState } from 'react';
import { Stack } from '@mui/material';
import { Refinitiv } from '../../ovComponents/4-module/widgets/refinitiv/refinitiv';
import { Switch } from '../../ovComponents';
import { RefinitivWidgetType } from '../../ovComponents/4-module/widgets/refinitiv/useRefinitivWidget';

export const RefinitivShowcase = () => <Stack spacing={2}>
  <ExperimentalWidgetWithSwitch widgetType='WMainIndex' opts={{ mainIndexRic: 'AAPL.OQ', mainIndexViewType: 'chart' }} />
  <ExperimentalWidgetWithSwitch widgetType='WMarketTiles' opts={{ marketTilesRics: ['AAPL.OQ', 'AKER.OL', 'BAKKA.OL'] }} />
  <ExperimentalWidgetWithSwitch widgetType='WMarketList' opts={{ marketListRics: ['AAPL.OQ', 'AKER.OL', 'BAKKA.OL'] }} />
  <ExperimentalWidgetWithSwitch widgetType='WInterestRate' />
  <ExperimentalWidgetWithSwitch widgetType='WNewsHeadlines' />
  <ExperimentalWidgetWithSwitch widgetType='WTickerBand' opts={{ tickerBandRics: ['AAPL.OQ', 'AKER.OL', 'BAKKA.OL'] }} />
  <hr />
  <ExperimentalWidgetWithSwitch widgetType='WInstrumentInfo' opts={{ instrumentInfoRic: 'AAPL.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WPriceChart' opts={{ ric: 'AAPL.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WEstimatesRatios' opts={{ ric: 'AAPL.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WCompanyProfile' opts={{ ric: 'AAPL.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WFundAllocation' opts={{ ric: 'MCHI.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WFundAvgAnnualReturns' opts={{ ric: 'MCHI.OQ' }} />
  <ExperimentalWidgetWithSwitch widgetType='WPriceYieldChart' opts={{ ric: 'AAPL.OQ' }} />
</Stack>;

export const ExperimentalWidgetWithSwitch = ({ widgetType, opts }: { widgetType: RefinitivWidgetType, opts?: any }) => {
  const [on, setOn] = useState(false);

  return <div>
    <Switch checked={on} onChange={setOn} label={`render ${widgetType}`} />
    {on && (
      <>
        <small>
          opts: {JSON.stringify(opts)}
        </small>
        <Refinitiv options={{ widgetType, ...opts }} />
      </>
    )}
  </div>;
};
