import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box } from '../../1-primative';
import { useEmptyStateTokens } from './emptyState.tokens';

export const EmptyState = ({ children }: { children: any }) => {
  const { comp } = useThemeTokens(useEmptyStateTokens());
  return (
    <Box
      p={2}
      sx={{
        backgroundColor: comp.emptyState.backgroundColor,
        borderRadius: comp.emptyState.borderRadius,
        borderColor: comp.emptyState.borderColor,
        borderWidth: '1px',
        borderStyle: 'dashed',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
};
